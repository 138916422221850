define('ir-app/controllers/ir-widgets/news-widgets/news-detail', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/models/price/price-protocols/mix-web/mix-request-generator', 'ir-app/utils/utils', 'ir-app/models/shared/shared-service'], function (exports, Ember, BaseController, PriceService, RequestGenerator, Utils, SharedService) {

    'use strict';

    /**
     * Created by udaras on 10/25/2016.
     */
    exports['default'] = BaseController['default'].extend({
        newsData: undefined,
        newsId: undefined,
        newsDetailRouter: 'news-detail',

        onPrepareData: function onPrepareData() {
            this.startLoadingProgress();
            this.setErrorMessage();
            this.sendNewsDetailRequest();
        },
        onCheckDataAvailability: function onCheckDataAvailability() {
            return this.get('newsData') !== undefined;
        },
        sendNewsDetailRequest: function sendNewsDetailRequest() {
            var that = this;
            var newsId = this.get('exg');

            PriceService['default'].sendNewDetailRequest({
                newsId: newsId,
                lan: SharedService['default'].userSettings.currentLanguage,
                reqSuccessFn: function reqSuccessFn(newsData) {
                    if (newsData) {
                        that.set('newsData', newsData);
                    } else {
                        Utils['default'].logger.logError('No Data Available');
                    }
                    that.onDataSuccess();
                },
                reqFailureFn: function reqFailureFn() {
                    that.onDataError();
                }
            });
        },
        onLanguageChanged: function onLanguageChanged() {
            this.onPrepareData();
        }
    });

    Ember['default'].Handlebars.helper('getImgUrl', function (id, lang, imageSize) {
        return RequestGenerator['default'].generateNewsResourceDownloadUrl(id, lang, imageSize);
    });

});