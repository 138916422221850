define('ir-app/controllers/ir-widgets/announcement-widgets/announcements-full-list', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/models/price/price-constants', 'ir-app/models/shared/shared-service', 'ir-app/utils/utils'], function (exports, Ember, BaseController, priceService, PriceConstant, sharedService, utils) {

    'use strict';

    /**
     * Created by lakmaln on 6/9/2016.
     */

    exports['default'] = BaseController['default'].extend({

        model: Ember['default'].A(),
        clientAnnouncementPage: '',
        exchange: '',
        lang: '',
        symbol: '',
        contentType: PriceConstant['default'].NewsAnnouncementsContentType.Announcements,
        pageSize: PriceConstant['default'].AnnouncementSearchPageSize,
        fromDate: null,
        toDate: null,
        noOfRecentDays: null,
        count: 0,
        current: 1,
        isLoadInPopup: false,
        isShowPopup: false,
        isLoadingPopup: false,
        annObj: null,

        onLoadWidget: function onLoadWidget() {
            this.setErrorMessage();

            if (this.get('showCustomTitle')) {
                this.setValueToBaseSymbolContainer('showBaseTitle', true);

                if (this.get('contentType') === PriceConstant['default'].NewsAnnouncementsContentType.Announcements) {
                    this.setValueToBaseSymbolContainer('baseTitle', this.get('app').lang.labels.announcement);
                } else {
                    this.setValueToBaseSymbolContainer('baseTitle', this.get('app').lang.labels.news);
                }
            }
        },

        onPrepareData: function onPrepareData() {
            this.set('exchange', this.get('exg'));
            this.set('lang', sharedService['default'].userSettings.currentLanguage.toLowerCase());
            this.set('pageSize', this.get('pageSize'));
            this.set('contentType', this.get('contentType'));
            this.set('isLoadInPopup', this.get('loadInPopup'));
            this.set('clientAnnouncementPage', this.get('clientAnnouncementPage'));

            if (!this.get('isForExchange')) {
                this.set('symbol', this.get('sym'));
            }

            this.startLoadingProgress();
            this.set('model', priceService['default'].announcementDS.getAnnCollectionBySymbol(this.get('exg'), this.get('symbol')));
            this.set('count', Math.ceil(priceService['default'].announcementDS.getAnnCount() / this.get('pageSize')));

            var that = this;
            var successFn = function successFn() {
                var loadAnn = function loadAnn() {
                    that.sendAnnouncementRequest();
                };
                // Set both success and failure functions to the same function as in case of a failure we at least show the symbol code instead of the symbol name
                priceService['default'].downloadParamsSymbolMasterForExchange({
                    exchange: that.get('exg'),
                    language: sharedService['default'].userSettings.currentLanguage,
                    successFn: loadAnn,
                    errorFn: loadAnn
                });
            };

            var errorFn = function errorFn() {};

            priceService['default'].exchangeDS.getExchange(that.get('exg'));
            priceService['default'].downloadExchangeProperty(that.get('exg'), sharedService['default'].userSettings.currentLanguage, successFn, errorFn);
        },

        sortedContent: (function () {
            return Ember['default'].ArrayProxy.extend(Ember['default'].SortableMixin).create({
                sortProperties: ['dt'],
                sortAscending: false,
                content: this.get('model')
            });
        }).property('model'),

        setErrorMessage: function setErrorMessage() {
            this.set('errorMessage', this.get('app').lang.messages.dataNotAvailable);
        },

        onCheckDataAvailability: function onCheckDataAvailability() {
            this.set('count', Math.ceil(priceService['default'].announcementDS.getAnnCount() / this.get('pageSize')));
            var announcements = priceService['default'].announcementDS.getAnnCollectionBySymbol(this.get('exg'), this.get('symbol'));
            return announcements && announcements.length > 0;
        },

        onLanguageChanged: function onLanguageChanged() {
            this._reset();
            this.onLoadWidget();
            this.onPrepareData();
        },

        clearDateSelection: function clearDateSelection() {
            if (this.get('fromDate') !== null && this.get('toDate') !== null) {
                this.onLanguageChanged();
            } else {
                this.set('fromDate', null);
                this.set('toDate', null);
                this.set('showError', false);
                this.setErrorMessage();
            }
        },

        _setErrorMessage: (function () {
            if (this.get('sortedContent').length > 0) {
                this.set('showError', false);
            } else {
                this.set('showError', true);
            }
        }).observes('sortedContent'),

        _reset: function _reset() {
            this.set('model', []);
            this.set('fromDate', null);
            this.set('toDate', null);
            this.set('current', 1);
        },

        sendAnnouncementRequest: function sendAnnouncementRequest() {
            var that = this;
            priceService['default'].announcementDS.getAnnCollectionBySymbol(this.get('exg'), this.get('symbol')).clear();

            if (this.get('contentType') === PriceConstant['default'].NewsAnnouncementsContentType.Announcements) {
                var startDate = null;
                var endDate = this.get('toDate');
                if (this.get('fromDate') !== null) {
                    startDate = new Date(this.get('fromDate'));
                    startDate.setDate(startDate.getDate() - 1);
                } else if (this.get('noOfRecentDays') !== null) {
                    // if fromDate is not specified, announcements will load for no of days that specified in 'noOfRecentDays', this is configurable
                    endDate = new Date();
                    startDate = new Date();
                    startDate.setDate(startDate.getDate() - this.get('noOfRecentDays'));
                }

                priceService['default'].sendAnnouncementSearchRequest({
                    exchange: this.get('exg'),
                    symbol: this.get('symbol'),
                    pageSize: this.get('pageSize'),
                    startDate: utils['default'].formatters.convertDateToyyyymmdd(startDate),
                    endDate: utils['default'].formatters.convertDateToyyyymmdd(endDate),
                    pageIndex: this.get('current') - 1,
                    reqSuccessFn: function reqSuccessFn() {
                        that.set('stopLoadingAnnouncement', true);
                        that.onDataSuccess();
                    },
                    reqFailureFn: function reqFailureFn() {
                        that.set('stopLoadingAnnouncement', true);
                        that.onDataError();
                    }
                }, priceService['default'].announcementDS.getAnnCollectionBySymbol(this.get('exg'), this.get('symbol')));
            } else {
                priceService['default'].sendNewsSearchRequest({
                    exchange: this.get('exg'),
                    symbol: this.get('symbol'),
                    pageSize: this.get('pageSize'),
                    startDate: utils['default'].formatters.convertDateToyyyymmdd(this.get('fromDate')),
                    endDate: utils['default'].formatters.convertDateToyyyymmdd(this.get('toDate')),
                    pageIndex: this.get('current') - 1,
                    reqSuccessFn: function reqSuccessFn() {
                        that.set('stopLoadingAnnouncement', true);
                        that.onDataSuccess();
                    },
                    reqFailureFn: function reqFailureFn() {
                        that.set('stopLoadingAnnouncement', true);
                        that.onDataError();
                    }
                }, priceService['default'].announcementDS.getAnnCollectionBySymbol(this.get('exg'), this.get('symbol')));
            }
        },

        sendNewsAnnouncementBodyRequest: function sendNewsAnnouncementBodyRequest(annId) {
            var that = this;
            that.set('isShowPopup', true);
            var annObj = priceService['default'].announcementDS.getAnnouncement(annId);
            this.set('annObj', annObj);

            if (!annObj.get('isBodyUpdated')) {
                that.set('isLoadingPopup', true);
                // that.annObj.set('bod', that.get('app').lang.messages.loading);

                priceService['default'].sendNewsAnnBodyRequest({
                    id: annObj.get('id'),
                    type: annObj.get('type'),
                    reqSuccessFn: function reqSuccessFn() {
                        that.set('isLoadingPopup', false);
                    },
                    reqFailureFn: function reqFailureFn() {
                        that.annObj.set('isBodyUpdated', false);
                        that.set('isShowPopup', false);
                    },
                    lan: sharedService['default'].userSettings.currentLanguage
                });
            }
        },

        closePopup: function closePopup() {
            var that = this;
            that.set('isShowPopup', false);
            this.set('annObj', null);
        },

        actions: {
            searchByDateSelection: function searchByDateSelection() {
                if (this.get('fromDate') !== null && this.get('toDate') !== null) {
                    this.setErrorMessage();
                    this.set('current', 1);
                    this.startLoadingProgress();
                    this.sendAnnouncementRequest();
                } else {
                    this.set('errorMessage', this.get('app').lang.messages.selectFromToDates);
                    this.set('showError', true);
                }
            },

            resetDateSelection: function resetDateSelection() {
                this.clearDateSelection();
            },

            pageChanged: function pageChanged(current, previous) {
                // jshint ignore:line
                this.startLoadingProgress();
                this.set('current', current);
                this.sendAnnouncementRequest();

                if (this.get('fromDate') === null || this.get('toDate') === null) {
                    this.set('fromDate', null);
                    this.set('toDate', null);
                }
            },

            loadPopup: function loadPopup(annId) {
                this.sendNewsAnnouncementBodyRequest(annId);
            },

            closePopup: function closePopup() {
                this.closePopup();
            }
        }
    });

});