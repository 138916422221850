define('ir-app/models/price/price-business-entities/file-meta-item', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        sym: '', // Symbol
        exg: '', // Exchange
        cat: '', // Category
        scat: '', // Sub Category
        did: '', // Document Id
        dTitle: '', // Doc Title
        tsr: '', // Ticker Serial
        cid: '', // Company Id
        rdt: '', // Report Date
        lan: '', // Language
        period: '', // Period
        fgd: '', // File Guid
        year: '', // Year
        dtyp: '', // Doc Type
        furl: '', // File Download URL

        dispTitle: (function () {
            var title = this.get('dTitle');
            var period = this.get('period');
            var year = this.get('year');
            var scat = parseInt(this.get('scat'), 10);
            var dispTle = [];

            switch (scat) {
                case 9:
                    dispTle.push(year, ' Annual');
                    break;

                case 10:
                    dispTle.push(year, ' ', 'Q', period);
                    break;

                default:
                    dispTle.push(title);
                    break;
            }

            return dispTle.join('');
        }).property('dTitle', 'year', 'period'), // Display Title

        setData: function setData(message) {
            var that = this;

            Ember['default'].$.each(message, function (key, value) {
                that.set(key, value);
            });
        }
    });

});