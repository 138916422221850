define('ir-app/models/price/price-data-stores/event-calendar-data-store', ['exports', 'ember', 'ir-app/utils/utils', 'ir-app/models/price/price-business-entities/event-calendar-record'], function (exports, Ember, utils, EventCalendarRecord) {

    'use strict';

    /**
     * Created by User on 2/16/2016.
     */
    exports['default'] = Ember['default'].Object.extend({

        eventStore: {},
        eventCount: undefined,

        getNewEvent: function getNewEvent(exchange, symbol, language) {
            var eventObj = EventCalendarRecord['default'].create({ sym: symbol, exg: exchange, lang: language });
            this.addToCollections(eventObj);
            return eventObj;
        },

        getEventList: function getEventList(exchange, symbol, language) {
            var key = this._getKey(exchange, symbol, language);
            var currentStore = this.get('eventStore');
            var eventCollection = currentStore[key];

            if (!eventCollection) {
                eventCollection = Ember['default'].A([]);
                currentStore[key] = eventCollection;
            }

            return eventCollection;
        },

        addToCollections: function addToCollections(eventObj) {
            var eventCollection = this.getEventList(eventObj.exg, eventObj.sym, eventObj.lang);
            eventCollection.pushObject(eventObj);
        },

        clearData: function clearData(exchange, symbol, language) {
            var eventCollection = this.getEventList(exchange, symbol, language);
            if (eventCollection) {
                eventCollection.clear();
            }
        },

        _getKey: function _getKey(exchange, symbol, language) {
            return [exchange, utils['default'].Constants.StringConst.Tilde, symbol, utils['default'].Constants.StringConst.Tilde, language].join('');
        },

        setEventCount: function setEventCount(evntCount) {
            this.set('eventCount', evntCount);
        },

        getEventCount: function getEventCount() {
            return this.get('eventCount');
        }

    }).create();

});