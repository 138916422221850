define('ir-app/controllers/ir-mobile/company-widgets/company-owners-info', ['exports', 'ember', 'ir-app/controllers/base-array-controller', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/models/price/price-constants', 'ir-app/utils/utils'], function (exports, Ember, BaseArrayController, priceService, sharedService, PriceConstants, utils) {

    'use strict';

    exports['default'] = BaseArrayController['default'].extend({

        // Parameters for sorting
        sortProperties: ['sherPrs'],
        sortAscending: false,

        // Default values. This can be overridden via the config
        pcntgLimit: PriceConstants['default'].WidgetDefaults.CompanyOwnersPecentageLimit,
        ownerType: undefined,
        ownerComType: {},
        langKey: 'majorShareholders',
        applyNaFlag: false,
        showCompanyLinks: false,

        widgetTitle: {},
        lang: sharedService['default'].userSettings.currentLanguage.toLowerCase(),

        onLoadWidget: function onLoadWidget() {
            this.setErrorMessage();
        },

        onPrepareData: function onPrepareData() {
            var that = this;
            this.set('widgetTitle', this.get('app').lang.labels[this.get('langKey')]);
            this.set('showCompanyLinks', this.get('showCompanyLinks') && this.get('ownerType') === PriceConstants['default'].OwnershipType.Company);

            that.startLoadingProgress();
            var owners;
            if (this.utils.validators.isAvailable(this.get('companyId'))) {
                owners = priceService['default'].companyProfileDS.getStoredObjectByCompanyId(this.get('companyId'), sharedService['default'].userSettings.currentLanguage, function () {
                    that.onDataSuccess();
                }, function () {
                    that.onDataError();
                }, PriceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyManagementInfo).compOwners;
            } else {
                owners = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, function () {
                    that.onDataSuccess();
                }, function () {
                    that.onDataError();
                }, PriceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyManagementInfo).compOwners;
            }

            this.set('model', owners);
        },

        onCheckDataAvailability: function onCheckDataAvailability() {
            var companyProfObj;
            if (this.utils.validators.isAvailable(this.get('companyId'))) {
                companyProfObj = priceService['default'].companyProfileDS.getStoredObjectByCompanyId(this.get('companyId'), sharedService['default'].userSettings.currentLanguage);
            } else {
                companyProfObj = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage);
            }

            return companyProfObj && companyProfObj.compOwners && companyProfObj.compOwners.length > 0;
        },

        onLanguageChanged: function onLanguageChanged() {
            this.onPrepareData();
        },

        // remove less than 5% owners
        filteredContent: (function () {
            this.setErrorMessage();
            return this.getFilteredContent();
        }).property('arrangedContent'),

        getFilteredContent: function getFilteredContent() {
            var that = this;
            var content = this.get('arrangedContent');
            var ownerType = this.get('ownerType');
            var ownerComType = this.get('ownerComType');
            var showCompanyLinks = this.get('showCompanyLinks');

            var filteredContent = content.filter(function (item) {
                if (!ownerType) {
                    return Number(item.sherPrs) * 100 > that.get('pcntgLimit');
                } else {
                    if (ownerType === PriceConstants['default'].OwnershipType.Company) {
                        if (ownerComType === 1) {
                            return item.ownerComType === 1 && item.ownerType === PriceConstants['default'].OwnershipType.Company && Number(item.sherPrs) !== 0;
                        } else {
                            return item.ownerComType !== 1 && item.ownerType === PriceConstants['default'].OwnershipType.Company && Number(item.sherPrs) !== 0;
                        }
                    } else {
                        return item.ownerType === PriceConstants['default'].OwnershipType.Individual && Number(item.sherPrs) !== 0;
                    }
                }
            });

            if (showCompanyLinks) {
                filteredContent.forEach(function (item) {
                    Ember['default'].set(item, 'companyName', utils['default'].formatters.formatString(item.ownerName) + '-' + item.compId);
                });
            }

            return filteredContent;
        },
        setErrorMessage: function setErrorMessage() {
            if (this.getFilteredContent() && this.getFilteredContent().length > 0) {
                this.hideDataErrorMessage();
            } else if (!this.get('isLoading')) {
                this.showDataErrorMessage();
                this.set('errorMessage', this.get('app').lang.messages.dataNotAvailable);
            }
        }

    });

});