define('ir-app/controllers/ir-widgets/financial-dividend-widgets/overview', ['exports', 'ember', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/controllers/base-array-controller', 'ir-app/models/price/price-constants', 'ir-app/models/chart/chart-constants', 'ir-app/models/shared/language/language-data-store', 'ir-app/utils/utils', 'ir-app/components/stk-specific-components/high-chart/dynamic-high-chart'], function (exports, Ember, priceService, sharedService, BaseArrayController, priceConstants, chartConstants, languageDataStore, utils, DynamicHighChart) {

    'use strict';

    exports['default'] = BaseArrayController['default'].extend({

        needs: ['application'],

        count: undefined,
        searchParams: undefined,
        capActObj: undefined,
        detailList: Ember['default'].A(),
        actionTypes: Ember['default'].A(),
        isDtlTbl: false,
        isCapChgTbl: false,
        isActionTypes: false,
        isCapActData: false,
        isCachedData: false, // if object need to be cached or not
        customView: false,
        current: 1,
        actTypeId: 7,
        pageSize: 20,
        isCalendar: 1,
        latestDvd: undefined,
        threeYrReturnSeries: undefined,
        chartData: [],
        chartData3years: [],
        optionsCounter: false,
        optionsCounterthree: false,

        onLoadWidget: function onLoadWidget() {
            this.setErrorMessage();
        },

        onPrepareData: function onPrepareData() {
            var that = this;
            var stock = priceService['default'].stockDS.getStock(this.get('exg'), this.get('sym'));
            this.set("stock", stock);
            that.startLoadingProgress();
            that.set('pageSize', this.get('pageSize'));
            that._loadCapitalActionPage();
            // that._downloadHistory();
            priceService['default'].addSymbolRequest(this.get('exg'), this.get('sym'));
        },

        onCheckDataAvailability: function onCheckDataAvailability() {
            var today = new Date();
            today.setHours(0);
            today.setMinutes(0);
            today.setSeconds(0);

            //12 month
            var date = new Date();
            date.setFullYear(date.getFullYear() - 1);
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);

            var date3Year = new Date();
            date3Year.setFullYear(date3Year.getFullYear() - 3);
            date3Year.setHours(0);
            date3Year.setMinutes(0);
            date3Year.setSeconds(0);

            var corporateActObj = priceService['default'].capitalActionsDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage);
            var yearsListForBarChart = this.generateArrayOfYears(5);
            var dividendListForBarChart = [];
            var dividendYieldListForBarChart = [];
            var ohlcSeries = priceService['default'].ohlcDS.getOHLCSeries(this.get('exg'), this.get('sym'), chartConstants['default'].ChartCategory.History);

            //filter Cash Dividents
            var divdntList = Ember['default'].A();
            corporateActObj.capitalAction.forEach(function (item) {
                if (item.typeId == 7) {
                    divdntList.push(item);
                }
            });
            //Latest Divident irrespctive of Action Status
            //Value per share
            //ex date
            //pay date
            this.set('latestDvd', divdntList[0]);

            var anulaDivid = 0;
            var threeYearDivident = 0;
            var dividendListForTotalReturn = {};

            //Calculations of Dividend and dividend Yield for bar chart
            yearsListForBarChart.reverse();
            yearsListForBarChart.forEach(function (dateInChart) {
                var curDividend = 0;
                var divYield = 0;
                var first_date = new Date(dateInChart, 0, 1);
                var last_date = new Date(dateInChart, 11, 31);
                var historyData = ohlcSeries.queryPointsForRange(first_date.getTime(), last_date.getTime());
                var date = '';
                divdntList.forEach(function (dividend) {
                    if (dividend.acStatus === 'Executed') {
                        var dividendDate = utils['default'].formatters.convertStringToDate(dividend.coDate, 0);
                        if (dividendDate != '' && dividendDate != undefined) {
                            if (dateInChart === dividendDate.getFullYear()) {
                                curDividend = curDividend + parseFloat(dividend.dividend_amount);
                                date = utils['default'].formatters.convertStringToDate(dividend.efDate, 0);
                            }
                        }
                    }
                });
                divYield = parseFloat((curDividend / historyData[historyData.length - 1].Close * 100).toFixed(2));
                dividendListForBarChart.push([utils['default'].formatters.formatDateToDisplayOnlyDate(date), curDividend]);
                dividendYieldListForBarChart.push([utils['default'].formatters.formatDateToDisplayOnlyDate(date), divYield]);
            });

            this.set("yearsListForBarChart", yearsListForBarChart);
            this.set("dividendYieldListForBarChart", dividendYieldListForBarChart);
            this.set("dividendListForBarChart", dividendListForBarChart);

            this.set("annualDividend", anulaDivid);

            var threeYrReturnSeries = Ember['default'].A();
            var anulaThreeReturnSeriesDates = Ember['default'].A();
            var tempDividendList = Ember['default'].A();
            //calculate acumolitave divident

            tempDividendList = divdntList;
            tempDividendList.reverse();

            tempDividendList.forEach(function (item) {
                // if(item.acStatus==='Executed'){
                //   var newDate=utils.formatters.convertStringToDate(item.coDate, 0);
                //   if( date.getTime() < newDate.getTime() ){
                //    anulaDivid=anulaDivid+parseFloat(item.dividend_amount);
                //   }
                //   if( date3Year.getTime() < newDate.getTime() ){
                //    threeYearDivident =threeYearDivident+parseFloat(item.dividend_amount,2);
                //   }
                //  }
                if (item.acStatus === 'Executed') {
                    dividendListForTotalReturn[utils['default'].formatters.convertStringToDate(item.efDate).getTime()] = parseFloat(item.dividend_amount);
                    // console.log(utils.formatters.convertStringToDate(item.efDate).getTime()),parseFloat(item.dividend_amount)
                    // dividendListForTotalReturn[item.efDate] = item.dividend_amount;
                }
            });

            //anula serise data
            var anulaData = ohlcSeries.queryPointsForRange(date.getTime(), today.getTime());
            var annualTotalReturnSeries = this.generateTotalReturnSeries(anulaData, dividendListForTotalReturn);
            this.set("annulaReturnDates", annualTotalReturnSeries[0]);
            this.set("anulaReturnSeries", annualTotalReturnSeries[1]);
            this.set("annualReturn", annualTotalReturnSeries[2]);

            //three Year Serise Date
            var thrYrData = ohlcSeries.queryPointsForRange(date3Year.getTime(), today.getTime());
            var threeYearTotalReturnSeries = this.generateTotalReturnSeries(thrYrData, dividendListForTotalReturn);
            this.set("anulaThreeReturnSeriesDates", threeYearTotalReturnSeries[0]);
            this.set("threeYrReturnSeries", threeYearTotalReturnSeries[1]);
            this.set("threeReturn", threeYearTotalReturnSeries[2]);

            divdntList.length = 5;
            var annualList = divdntList;
            return true;
        },

        generateTotalReturnSeries: function generateTotalReturnSeries(data, dividendList) {
            var j = 0;
            var dividend = 0;
            // var tempData=[];
            var returnSeries = Ember['default'].A();
            var returnSeriesDates = Ember['default'].A();
            Ember['default'].$.each(data, function (k, item) {

                var excluedDividendDate = new Date(parseInt(Object.keys(dividendList)[j]));

                if (excluedDividendDate.getFullYear() < item.DT.getFullYear()) {
                    j += 1;
                }
                // var obj = [item.DT, (anulaDivid+item.Close-anulaStartValue)/anulaStartValue];
                returnSeriesDates.push(utils['default'].formatters.formatDateToDisplayOnlyDate(item.DT));
                if (Object.keys(dividendList).length > 0 && parseInt(Object.keys(dividendList)[j]) <= item.DT.getTime() && parseInt(Object.keys(dividendList)[j]) >= data[0].DT.getTime() && data[data.length - 1].DT.getTime() >= parseInt(Object.keys(dividendList)[j]) && !isNaN(parseInt(Object.keys(dividendList)[j]))) {

                    dividend += dividendList[Object.keys(dividendList)[j]];
                    j += 1;
                }
                // var dataa = {
                //     "Date" : utils.formatters.formatDateToDisplayOnlyDate(item.DT),
                //     "Dividend" : dividend,
                //     "Condition": parseInt(Object.keys(dividendList)[0]) + ">=" + item.DT.getTime()
                //    + " and " + parseInt(Object.keys(dividendList)[0]) + ">=" + data[0].DT.getTime()
                //    + " and " + data[data.length-1].DT.getTime() + ">=" + parseInt(Object.keys(dividendList)[0]) ,
                //     "Close Price" : item.Close,
                //     "Start Value" : data[0].Close,
                //     "Total Return" : parseFloat((((dividend+item.Close-data[0].Close)/data[0].Close)*100).toFixed(2))
                // }
                // tempData.push(dataa);
                returnSeries.push(parseFloat(((dividend + item.Close - data[0].Close) / data[0].Close * 100).toFixed(2)));
            });
            // console.log(tempData);
            var totalReturn = (dividend + data[data.length - 1].Close - data[0].close) / data[0].close;
            return [returnSeriesDates, returnSeries, totalReturn];
        },

        generateArrayOfYears: function generateArrayOfYears(minYear) {
            var max = new Date().getFullYear();
            var min = max - (minYear - 1);
            var years = [];

            for (var i = max; i >= min; i--) {
                years.push(i);
            }
            return years;
        },

        onLanguageChanged: function onLanguageChanged() {
            this.onPrepareData();
        },

        filteredContent: (function () {
            var content = this.get('arrangedContent');
            if (content) {
                return content;
            }
        }).property('arrangedContent'),

        _loadCapitalActionPage: function _loadCapitalActionPage() {
            var that = this;
            var searchParams = this.get('searchParams');
            if (!searchParams) {
                var searchPara = {
                    pageSize: this.get('pageSize'),
                    pageIdx: 0,
                    actType: this.get('actTypeId'),
                    custView: this.get('customView'),
                    idList: this.get('typeIds')

                };
                this.set('searchParams', searchPara);
                this.set('current', 1);
            }

            var corporateAction = priceService['default'].capitalActionsDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, priceConstants['default'].DataTypes.CorporateActionDataStore.CapitalActionInfo, that.get('searchParams'), function () {
                that.onDataSuccess();
            }, function () {
                that.onDataError();
            }, this.get('isCachedData')).capitalAction;
        },

        _downloadHistory: function _downloadHistory() {
            var that = this;
            priceService['default'].downloadHistoryOHLCData({
                exchange: this.get('exg'),
                symbol: this.get('sym'),
                begin: undefined,

                reqSuccessFn: function reqSuccessFn() {},
                reqFailureFn: function reqFailureFn() {
                    if (that.onErrorFn && Ember['default'].$.isFunction(that.onErrorFn)) {
                        that.onErrorFn();
                    }
                    utils['default'].logger.logDebug('Data Unavailable For History');
                }
            });
        },
        sendDataRequest: function sendDataRequest(exchange, symbol, view) {
            var that = this;
            var postSuccess = function postSuccess() {

                // that.dataDisplay(exchange, symbol, view);
                // that.stopLoadingProgress();
            };

            var error = function error() {
                // that.showDataErrorMessage();
                // that.stopLoadingProgress();
                console.log("DATA Not Available");
            };

            priceService['default'].sendFundamentalDataRequest(exchange, symbol, sharedService['default'].userSettings.currentLanguage, 'X', postSuccess, error, '5', this.get('isCalendar'));
        },
        setErrorMessage: (function () {
            if (this.get('filteredContent').length > 0) {
                this.hideDataErrorMessage();
            } else if (!this.get('isLoading')) {
                this.showDataErrorMessage();
                this.set('errorMessage', this.get('app').lang.messages.dataNotAvailable);
            }
        }).observes('filteredContent'),

        onAfterRender: function onAfterRender() {
            // this._renderChartView();
        },

        dataSeries12Months: (function () {
            this.set("dataSeries", this.get("anulaReturnSeries"));

            var chartData = [{
                type: 'column',
                data: this.get("dividendListForBarChart"),
                name: "Dividend",
                color: "#dae2e7"
            }];

            this.set("chartData", chartData);

            this._renderChartView('chartView');
            return true;
        }).observes("anulaReturnSeries"),

        dataSeries3years: (function () {
            this.set("dataSeries", this.get("threeYrReturnSeries"));

            var chartData = [{
                type: 'column',
                data: this.get("dividendYieldListForBarChart"),
                name: "Dividend yeild",
                color: '#dae2e7'
            }];

            this.set("chartData3years", chartData);

            this._renderChartView('chartView3years');
            return true;
        }).observes("threeYrReturnSeries"),

        chartOptions: (function () {
            var that = this;
            return {
                chart: {
                    // height: 150,
                    backgroundColor: "#fff",
                    pinchType: '',
                    zoomType: ''

                },
                title: {
                    text: ' '
                },
                plotOptions: {
                    column: {
                        dataLabels: {
                            enabled: true
                        }
                    }

                },
                tickLength: 0,
                xAxis: {
                    type: 'datetime',
                    categories: this.generateArrayOfYears(5).reverse(),
                    // gridLineWidth: that.get('isToolTipChart') ? '1' : '0',
                    // labels: {
                    //     enabled: that.get('isToolTipChart') ? true : false
                    // },
                    title: {
                        text: null
                    }
                },
                yAxis: {
                    labels: {
                        enabled: false
                    },
                    stackLabels: {
                        style: {
                            color: 'black'
                        },
                        enabled: true,
                        verticalAlign: 'top'
                    },
                    gridLineColor: '#ffffff',
                    lineColor: '#ffffff',
                    title: {
                        text: null
                    }
                },
                rangeSelector: {
                    enabled: false
                },
                navigator: {
                    enabled: false
                },
                scrollbar: {
                    enabled: false
                },
                tooltip: {
                    enabled: true,
                    useHTML: false
                },
                //valueSuffix: ' %',
                exporting: {
                    enabled: false
                } };
        }).property(),

        chartOptionsDivYeild: (function () {
            var that = this;
            return {
                chart: {
                    // height: 150,
                    backgroundColor: "#fff",
                    pinchType: '',
                    zoomType: ''

                },
                title: {
                    text: ' '
                },
                plotOptions: {
                    column: {
                        dataLabels: {
                            enabled: true,
                            formatter: function formatter() {
                                return this.y + "%";
                            }
                        }
                    }

                },
                tickLength: 0,
                xAxis: {
                    type: 'datetime',
                    categories: this.generateArrayOfYears(5).reverse(),
                    // gridLineWidth: that.get('isToolTipChart') ? '1' : '0',
                    // labels: {
                    //     enabled: that.get('isToolTipChart') ? true : false
                    // },
                    title: {
                        text: null
                    }
                },
                yAxis: {
                    labels: {
                        enabled: false
                    },
                    gridLineColor: '#ffffff',
                    lineColor: '#ffffff',
                    title: {
                        text: null
                    }
                },
                rangeSelector: {
                    enabled: false
                },
                navigator: {
                    enabled: false
                },
                scrollbar: {
                    enabled: false
                },
                tooltip: {
                    valueDecimals: 2,
                    valueSuffix: ' %',
                    enabled: true,
                    // formatter: function () {
                    //     return ' <b>' + this.y + '  </b>';
                    // },
                    useHTML: false
                },
                exporting: {
                    enabled: false
                } };
        }).property(),

        chartOptionsAnnualReturn: (function () {

            return {
                chart: {
                    height: 150,
                    backgroundColor: "#fff",
                    pinchType: '',
                    zoomType: ''

                },
                title: {
                    text: ' '
                },
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: false
                        },
                        marker: {
                            enabled: false
                        }
                    }
                },
                tickLength: 0,
                xAxis: {
                    categories: this.get("annulaReturnDates"),
                    tickWidth: 0,
                    tickLength: 0,
                    labels: {
                        enabled: false,
                        style: {
                            color: '#fff'
                        }
                    },
                    gridLineWidth: '0',

                    // gridLineWidth: that.get('isToolTipChart') ? '1' : '0',
                    // labels: {
                    //     enabled: that.get('isToolTipChart') ? true : false
                    // },
                    title: {
                        text: null
                    }
                },
                tooltip: {
                    enabled: true,
                    valueSuffix: ' %'
                },
                yAxis: {
                    labels: {
                        enabled: false
                    },
                    stackLabels: {
                        style: {
                            color: 'black'
                        },
                        enabled: true,
                        verticalAlign: 'top'
                    },
                    gridLineColor: '#ffffff',
                    lineColor: '#ffffff',
                    title: {
                        text: null
                    }
                },
                rangeSelector: {
                    enabled: false
                },
                navigator: {
                    enabled: false
                },
                scrollbar: {
                    enabled: false
                },
                exporting: {
                    enabled: false
                } };
        }).property(),

        chartOptionsThreeYears: (function () {

            return {
                chart: {
                    height: 150,
                    backgroundColor: "#fff",
                    pinchType: '',
                    zoomType: ''

                },
                title: {
                    text: ' '
                },
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: false
                        }

                    }
                },
                tickLength: 0,
                xAxis: {
                    categories: this.get("anulaThreeReturnSeriesDates"),
                    tickWidth: 0,
                    tickLength: 0,
                    labels: {
                        enabled: false,
                        style: {
                            color: '#fff'
                        }
                    },
                    gridLineWidth: '0',

                    // gridLineWidth: that.get('isToolTipChart') ? '1' : '0',
                    // labels: {
                    //     enabled: that.get('isToolTipChart') ? true : false
                    // },
                    title: {
                        text: null
                    }
                },
                tooltip: {
                    enabled: true,
                    valueSuffix: ' %'
                },
                yAxis: {
                    labels: {
                        enabled: false
                    },
                    stackLabels: {
                        style: {
                            color: 'black'
                        },
                        enabled: true,
                        verticalAlign: 'top'
                    },
                    gridLineColor: '#ffffff',
                    lineColor: '#ffffff',
                    title: {
                        text: null
                    }
                },
                rangeSelector: {
                    enabled: false
                },
                navigator: {
                    enabled: false
                },
                scrollbar: {
                    enabled: false
                },
                exporting: {
                    enabled: false
                } };
        }).property(),

        dataThreeSeriesAnnualReturn: (function () {
            var that = this;
            var chartData = [{
                showInLegend: false,
                type: 'area',
                data: this.get("threeYrReturnSeries"),
                name: that.stock.lDes,
                color: '#dae2e7'
            }];

            this.set("annualThreeRetrunDataSeries", chartData);
        }).observes("threeYrReturnSeries"),

        dataSeriesAnnualReturn: (function () {
            var that = this;
            var chartData = [{
                showInLegend: false,
                type: 'area',
                data: this.get("anulaReturnSeries"),
                name: that.stock.lDes,
                color: '#dae2e7'
            }];

            this.set("annualRetrunDataSeries", chartData);
        }).observes("anulaReturnSeries"),

        setCumulativeOptions: (function () {
            if (this.get("annulaReturnDates").length > 0) {
                this.set("optionsCounter", true);
            }
        }).observes("annulaReturnDates"),

        setCumulativeOptionstwo: (function () {
            if (this.get("anulaThreeReturnSeriesDates").length > 0) {
                this.set("optionsCounterthree", true);
            }
        }).observes("anulaThreeReturnSeriesDates"),

        _renderChartView: function _renderChartView(view) {
            var detailViewController = 'ir-mobile/chart-widgets/dividend-overview-chart';
            var controllerString = 'controller:' + detailViewController;
            var widgetController = this.container.lookupFactory(controllerString).create();
            widgetController.set('dataSeries', this.get("dataSeries"));
            widgetController.set('isPortalTopBarChart', true);
            widgetController.set('chartHeight', this.get('chartHeight'));

            widgetController.set('sym', this.get('sym'));
            widgetController.set('exg', this.get('exg'));
            widgetController.initializeWidget({ wn: controllerString.split('/').pop() });

            var route = this.container.lookup('route:' + this.get('controllers.application.currentRouteName'));
            route.render(detailViewController, {
                into: 'ir-widgets/financial-dividend-widgets/overview',
                outlet: view,
                controller: widgetController
            });

            widgetController.onUnloadWidget();
        }

    });
    Ember['default'].Handlebars.helper('dynamic-high-chart', DynamicHighChart['default']);

});