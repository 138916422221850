define('ir-app/components/custom-watchlist-dialog', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        layoutName: 'components/custom-watchlist-dialog',

        click: function click(event) {
            var target = Ember['default'].$(event.target);

            if (!target.hasClass('btn')) {
                event.stopPropagation();
            }
        },

        actions: {
            saveWL: function saveWL(name) {
                this.sendAction('saveAction', name);
            }
        }
    });

});