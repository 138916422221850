define('ir-app/controllers/ir-mobile/quote-widgets/default-quote', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/components/stk-specific-components/cash-map-slider', 'ir-app/components/stk-specific-components/fifty-two-wk-hl-slider', 'ir-app/components/stk-specific-components/days-range-slider', 'ir-app/models/shared/shared-service', 'ir-app/models/price/price-constants'], function (exports, Ember, BaseController, priceService, CashMapSlider, FiftyTwoWkHlSlider, DaysRangeSlider, sharedService, priceConstants) {

    'use strict';

    /**
     * Created by User on 2/5/2016.
     */

    exports['default'] = BaseController['default'].extend({
        stock: {},
        exchange: {},
        basicInfo: {},

        // Base overrides
        onLoadWidget: function onLoadWidget() {
            var stock = priceService['default'].stockDS.getStock(this.get('exg'), this.get('sym'));
            this.set('stock', stock);

            var exchange = priceService['default'].exchangeDS.getExchange(this.get('exg'));
            this.set('exchange', exchange);
        },

        onPrepareData: function onPrepareData() {
            this._loadCompanyProfile();
            priceService['default'].addSymbolRequest(this.get('exg'), this.get('sym'));

            // request symbol meta
            priceService['default'].sendSymbolValidationRequest(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, undefined, undefined);

            // request exchange meta
            priceService['default'].downloadExchangeProperty(this.get('exg'), sharedService['default'].userSettings.currentLanguage);

            this.utils.analyticsService.trackEvent(this.get('gaKey'), this.utils.Constants.GAActions.show, ['sym:', this.get('sym'), '~', this.get('exg')].join(''));
        },

        onClearData: function onClearData() {
            this._reset();
            priceService['default'].removeSymbolRequest(this.get('exg'), this.get('sym'));
        },

        onLanguageChanged: function onLanguageChanged() {
            this._reset();
            this._loadCompanyProfile();
            priceService['default'].downloadExchangeProperty(this.get('exg'), sharedService['default'].userSettings.currentLanguage);
        },

        onCheckDataAvailability: function onCheckDataAvailability() {
            var companyProfObj = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage);

            if (companyProfObj) {
                return this.utils.validators.isAvailable(companyProfObj.compID);
            }

            return false;
        },

        _loadCompanyProfile: function _loadCompanyProfile() {
            var that = this;

            var basicInfo = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, function () {
                that.onDataSuccess();
            }, function () {
                that.onDataError();
            }, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyBasicInfo);

            this.set('basicInfo', basicInfo);
        },

        _reset: function _reset() {
            this.set('basicInfo', null);
            this.set('stock', null);
            this.set('exchange', null);
        }
    });

    Ember['default'].Handlebars.helper('cash-map-slider', CashMapSlider['default']);
    Ember['default'].Handlebars.helper('fifty-two-wk-hl-slider', FiftyTwoWkHlSlider['default']);
    Ember['default'].Handlebars.helper('days-range-slider', DaysRangeSlider['default']);
    Ember['default'].Handlebars.helper('getPositiveNegativeStyle', function (value) {
        if (value >= 0) {
            return 'padding-medium snap-period-wrapper-green';
        } else {
            return 'padding-medium snap-period-wrapper-red';
        }
    });

});