define('ir-app/utils/logger', ['exports', 'ember', 'ir-app/config/app-config', 'ir-app/models/shared/communication-adapters/web-http-connection', 'ir-app/models/shared/shared-service', 'ir-app/config/environment', 'ir-app/utils/utils'], function (exports, Ember, appConfig, webConnection, sharedService, environmentConfig, utils) {

    'use strict';

    /* global moment */
    /**
     * Created by Amila on 6/8/15.
     */
    exports['default'] = (function () {
        var logBuffer = [];

        // Constants
        var logLevel = {
            disableLogs: 0,
            logError: 1,
            logWarning: 2,
            logInfo: 3,
            logDebug: 4,
            logDebug2: 5
        };

        var logPrefix = {
            error: 'ERROR: ',
            warn: 'WARN: ',
            info: 'INFO: ',
            debug: 'DEBUG: '
        };

        var loggerUrl = 'logger';
        var isRequestInProgress = false;
        var lastSentIndex = 0;
        var isPeriodicUpdateStarted = false;

        var _browser = {};

        var logError = function logError(logEntry) {
            _amendLog(logEntry, logLevel.logError);
        };

        var logWarning = function logWarning(logEntry) {
            _amendLog(logEntry, logLevel.logWarning);
        };

        var logInfo = function logInfo(logEntry) {
            _amendLog(logEntry, logLevel.logInfo);
        };

        var logDebug = function logDebug(logEntry) {
            _amendLog(logEntry, logLevel.logDebug);
        };

        var logDebug2 = function logDebug2(logEntry) {
            _amendLog(logEntry, logLevel.logDebug2);
        };

        var _amendLog = function _amendLog(logEntry, logType) {
            try {
                if (appConfig['default'].loggerConfig.consoleLogLevel >= logType) {
                    _amendLogConsole(logEntry, logType);
                }

                if (appConfig['default'].loggerConfig.serverLogLevel >= logType) {
                    _amendLogToBuffer(logEntry, logType);
                }
            } catch (e) {
                window.console.error(['Logger error: ', e].join(''));
            }
        };

        var _amendLogConsole = function _amendLogConsole(logEntry, logType) {
            switch (logType) {
                case logLevel.logError:
                    window.console.error([logPrefix.error, logEntry].join(''));
                    break;

                case logLevel.logWarning:
                    window.console.warn([logPrefix.warn, logEntry].join(''));
                    break;

                case logLevel.logInfo:
                    window.console.info([logPrefix.info, logEntry].join(''));
                    break;

                case logLevel.logDebug:
                case logLevel.logDebug2:
                    window.console.log([logPrefix.debug, logEntry].join(''));
                    break;

                default:
                    window.console.log(logEntry);
                    break;
            }
        };

        var _amendLogToBuffer = function _amendLogToBuffer(logEntry, logType) {
            if (!isPeriodicUpdateStarted) {
                _browser = utils['default'].browser.getBrowserInfo();

                setTimeout(function () {
                    _periodicServerUpdate();
                }, appConfig['default'].loggerConfig.logUpdateTimeout);

                isPeriodicUpdateStarted = true;
            }

            var messageType = '';
            var hasValidLogType = true;

            switch (logType) {
                case logLevel.logError:
                    messageType = logPrefix.error;
                    break;

                case logLevel.logWarning:
                    messageType = logPrefix.warn;
                    break;

                case logLevel.logInfo:
                    messageType = logPrefix.info;
                    break;

                case logLevel.logDebug:
                case logLevel.logDebug2:
                    messageType = logPrefix.debug;
                    break;

                default:
                    hasValidLogType = false;

                    window.console.log(logEntry);
                    break;
            }

            if (hasValidLogType) {
                var userName = sharedService['default'].userSettings.username;
                var appVersion = environmentConfig['default'].APP.version;
                var logTime = moment(new Date()).format('YYYYMMDDHHmmss');

                var serverLogEntry = {
                    'message': logEntry,
                    'message_type': messageType,
                    'user_id': userName,
                    'browser': _browser.name,
                    'browser_version': _browser.version,
                    'app_version': appVersion,
                    'log_time': logTime,
                    'env': appConfig['default'].customisation.clientPrefix
                };

                logBuffer.push(serverLogEntry);
            }

            if (logBuffer.length > appConfig['default'].loggerConfig.logBufferSize) {
                if (!isRequestInProgress) {
                    _sendLogsToServer();
                }
            }
        };

        var _sendLogsToServer = function _sendLogsToServer() {
            isRequestInProgress = true;
            lastSentIndex = logBuffer.length;
            var data = utils['default'].jsonHelper.convertToJson({ messages: logBuffer });

            _sendLogRequest(loggerUrl, 'POST', 'application/json', data, _onSuccess, _onError);
        };

        var _onSuccess = function _onSuccess() {
            logBuffer = logBuffer.slice(lastSentIndex);
            isRequestInProgress = false;
        };

        var _onError = function _onError() {
            if (logBuffer.length > appConfig['default'].loggerConfig.maxLogBufferSize) {
                logBuffer = [];
            }

            // If a network error is occurred, application will report many error logs and it will trigger the logger to
            // send another request to server immediately. So here a delay is added. So the requests will have a time between them.
            Ember['default'].run.later(this, function () {
                isRequestInProgress = false;
            }, 10000);
        };

        var _sendLogRequest = function _sendLogRequest(url, type, contentType, data, onSuccess, onError) {
            webConnection['default'].sendAjaxRequest({
                url: url,
                type: type,
                contentType: contentType,
                data: data,
                onSuccess: onSuccess,
                onError: onError
            });
        };

        var _periodicServerUpdate = function _periodicServerUpdate() {
            if (!isRequestInProgress && logBuffer.length > 0) {
                _sendLogsToServer();
            }

            setTimeout(function () {
                _periodicServerUpdate();
            }, appConfig['default'].loggerConfig.logUpdateTimeout);
        };

        return {
            logError: logError,
            logWarning: logWarning,
            logInfo: logInfo,
            logDebug: logDebug,
            logDebug2: logDebug2
        };
    })();

});