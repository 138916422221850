define('ir-app/models/price/price-business-entities/fundamentals/estimate-data-holder', ['exports', 'ember', 'ir-app/utils/utils', 'ir-app/models/price/price-business-entities/fundamentals/estimate-field-object', 'ir-app/models/price/price-business-entities/fundamentals/fundamental-style-object'], function (exports, Ember, utils, fieldObj, styleObj) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        styleTable: {},
        dataTable: {},
        symbol: undefined,
        exchange: undefined,

        setDataTable: function setDataTable(type, key, data) {
            if (type === undefined || key === undefined || data === undefined) {
                return;
            }
            var currentDataTable = this.get('dataTable');
            var dataObject = currentDataTable[type];

            var object;

            if (!dataObject) {
                object = fieldObj['default'].create();
                object.setFieldForecastsTable(key, data['DUR_YEAR'], data['PROVIDER_NAME'], data['PROVIDED_DATE'], data[key]);
                object.fieldID = key;

                dataObject = {};
                dataObject[key] = object;
                currentDataTable[type] = dataObject;
            } else {
                if (!dataObject[key]) {
                    object = fieldObj['default'].create();
                    object.setFieldForecastsTable(key, data['DUR_YEAR'], data['PROVIDER_NAME'], data['PROVIDED_DATE'], data[key]);
                    object.fieldID = key;

                    dataObject[key] = object;
                } else {
                    object = dataObject[key];
                    object.setFieldForecastsTable(key, data['DUR_YEAR'], data['PROVIDER_NAME'], data['PROVIDED_DATE'], data[key]);
                }
            }
        },

        setStyleTable: function setStyleTable(type, fieldID, styleString) {
            if (type === undefined || fieldID === undefined || styleString === undefined) {
                return;
            }

            var currentStyleTable = this.get('styleTable');
            var styleObject = currentStyleTable[type];
            var splittedArr = [];
            splittedArr.push(styleString.split(utils['default'].Constants.StringConst.Comma)[0]);
            splittedArr.push(styleString.split(utils['default'].Constants.StringConst.Comma).slice(1).join(','));

            if (splittedArr && splittedArr.length >= 2) {
                var object = styleObj['default'].create({
                    level: splittedArr[0].split(utils['default'].Constants.StringConst.Tilde)[0],
                    orderInGroup: splittedArr[0].split(utils['default'].Constants.StringConst.Tilde)[1] !== undefined ? splittedArr[0].split(utils['default'].Constants.StringConst.Tilde)[1] : '',
                    decimalPlcaces: splittedArr[0].split(utils['default'].Constants.StringConst.Tilde)[2] !== undefined ? splittedArr[0].split(utils['default'].Constants.StringConst.Tilde)[2] : '',
                    des: splittedArr[1].split(utils['default'].Constants.StringConst.Tilde)[0],
                    groupID: splittedArr[1].split(utils['default'].Constants.StringConst.Tilde)[1] !== undefined ? splittedArr[1].split(utils['default'].Constants.StringConst.Tilde)[1] : '',
                    groupHeadDesc: splittedArr[1].split(utils['default'].Constants.StringConst.Tilde)[2] !== undefined ? splittedArr[1].split(utils['default'].Constants.StringConst.Tilde)[2] : '',
                    filedID: fieldID,
                    type: type
                });
                if (object.get('level') !== '0') {
                    // add data objects there level grater than 0
                    if (!styleObject) {
                        styleObject = Ember['default'].A([]);
                        currentStyleTable[type] = styleObject;
                        styleObject.pushObject(object);
                    } else {
                        styleObject.pushObject(object);
                    }
                }
            }
        }
    });

});