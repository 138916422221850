define('ir-app/components/smart-search', ['exports', 'ember', 'ir-app/config/app-config', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/models/price/price-constants', 'ir-app/utils/utils'], function (exports, Ember, AppConfig, priceService, sharedService, PriceConstants, utils) {

    'use strict';

    /**
     * Created by Channa on 10/11/2016.
     *
     * This component can be used to place a smart-search for different types of companies.
     * This can be viewed by placing {{smart-search}} inside any hbs file.
     * Different route names are configurable when user click on search results. In that case use
     * {{smart-search equityRote="your-route-name"}} (when user click on equity companies it will load "your-route-name" route
     */

    exports['default'] = Ember['default'].Component.extend({
        searchText: null,
        searchResults: null,
        supportedExchanges: '',
        assertTypes: null, // please refer price-constants.SmartSearch for other categories
        language: sharedService['default'].userSettings.currentLanguage.toLowerCase(),
        equityRoute: '',
        companyProfileRoute: '',
        display: 'block',
        searchTimeInterval: PriceConstants['default'].TimeIntervals.SmartSearchAutoCompleteInterval,
        noResults: false,
        previousSearchKey: '',
        exchanges: null,
        searchType: null,

        smartSearch: function smartSearch() {
            var searchKey = this.get('searchText');
            var that = this;

            if (this.get('searchResults')) {
                this.get('searchResults').clear();
            }

            var onSuccess = function onSuccess(isResultsAvailable, result) {
                if (isResultsAvailable) {
                    that.set('searchResults', result);
                    that.set('noResults', false);
                } else {
                    that.set('previousSearchKey', searchKey);
                    that.set('noResults', true);
                }
            };

            if (searchKey && searchKey.length >= AppConfig['default'].searchConfig.minCharLenForSymbol && this.previousKeyHasResults(searchKey)) {
                priceService['default'].sendSmartSearchRequest(searchKey, sharedService['default'].userSettings.currentLanguage, PriceConstants['default'].SymbolSearchPageSize, onSuccess, this.assertTypes, this.exchanges, this.searchType);
            }
        },
        searchKeyDidChange: (function () {
            // Every time a key is pressed, this event fires, and that event will start the filter in given time interval
            Ember['default'].run.debounce(this, this.smartSearch, this.get('searchTimeInterval'));
        }).observes('searchText'),

        getExchange: function getExchange(head, dataItem) {
            var exchangeIndex = Ember['default'].$.inArray('E', head);
            return exchangeIndex !== -1 && this.exgToDispExg && this.exgToDispExg[dataItem[exchangeIndex]] ? this.exgToDispExg[dataItem[exchangeIndex]] : dataItem[exchangeIndex];
        },
        previousKeyHasResults: function previousKeyHasResults(searchKey) {
            var previousKey = this.get('previousSearchKey');
            var previousKeyHasResults = true;

            if (!utils['default'].validators.isAvailable(previousKey)) {
                previousKeyHasResults = true;
            } else if (this.get('noResults') === true && searchKey.lastIndexOf(previousKey, 0) === 0) {
                previousKeyHasResults = false;
            }

            return previousKeyHasResults;
        },
        actions: {
            hideSearchBar: function hideSearchBar() {
                Ember['default'].$('div[ref="smart_search_bar"]').slideUp();
                this.set('searchText', '');
                var divToHide = Ember['default'].$('div[ref="document_mask"]');
                if (divToHide) {
                    divToHide.hide();
                }
            }
        }
    });

});