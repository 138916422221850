define('ir-app/components/chart/pie-chart', ['exports', 'd3'], function (exports, d3) {

    'use strict';

    exports['default'] = (function () {

        var svg;
        var pie;
        var arc;
        var outerArc;
        var width = 125;
        var height = 95;
        var radius;
        var color;
        var indexPctChange;

        var key = function key(d) {
            return d.data.label;
        };

        var key1 = function key1(d) {
            return d.data.labelDes;
        };

        var indexSym = function indexSym(d) {
            return d.data.indexVal;
        };

        var indexChg = function indexChg(d) {
            return d.data.indexVal;
        };

        var _setColor = function _setColor(labels, colours) {
            color = d3['default'].scale.ordinal().domain(labels).range(colours);
        };

        var _setData = function _setData(val, labelDes, indexVal, indexPctChg) {
            var labels = color.domain();
            var labelsLength = labels.length;
            indexPctChange = indexPctChg;
            var data = [];
            for (var i = 0; i < labelsLength; i++) {
                data.push({
                    label: labels[i],
                    value: val[i],
                    labelDes: labelDes[i],
                    indexVal: indexVal,
                    indexPctChg: indexPctChg
                });
            }

            return data;
        };

        var _midAngle = function _midAngle(d) {
            return d.startAngle + (d.endAngle - d.startAngle) / 2;
        };

        var init = function init() {

            svg = d3['default'].select('#sector_chart').append('svg');
            svg.attr('width', '100%');
            svg.attr('height', '100%');
            svg.attr('viewBox', '0 0 ' + Math.min(width, height) + ' ' + Math.min(width, height));
            svg.attr('preserveAspectRatio', 'xMinYMin');
            svg = svg.append('g');
            svg.append('g').attr('class', 'slices');
            svg.append('g').attr('class', 'labels');
            svg.append('g').attr('class', 'labelsDes');
            svg.append('g').attr('class', 'lines');
            svg.append('g').attr('class', 'indexVal');
            svg.append('g').attr('class', 'indexChg');

            radius = Math.min(width, height) / 2.5;

            pie = d3['default'].layout.pie().sort(null).value(function (d) {
                return d.value;
            });

            arc = d3['default'].svg.arc().outerRadius(radius * 0.8).innerRadius(radius * 0.6);
            outerArc = d3['default'].svg.arc().innerRadius(radius * 0.9).outerRadius(radius * 0.9);
            svg.attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');
        };

        var deInit = function deInit() {
            svg = null;
            pie = null;
            arc = null;
            outerArc = null;
        };

        var _update = function _update(data) {
            /* ------- PIE SLICES -------*/
            var slice = svg.select('.slices').selectAll('path.slice').data(pie(data), key);
            slice.enter().insert('path').style('fill', function (d) {
                return color(d.data.label);
            }).attr('class', 'slice');
            slice.transition().duration(1000).attrTween('d', function (d) {
                this._current = this._current || d;
                var interpolate = d3['default'].interpolate(this._current, d);
                this._current = interpolate(0);
                return function (t) {
                    return arc(interpolate(t));
                };
            });

            slice.exit().remove();
            /* ------- TEXT LABELS -------*/
            var text = svg.select('.labels').selectAll('text').data(pie(data), key);
            text.enter().append('text').attr('dy', '1.10em').text(function (d) {
                return d.data.label;
            });

            text.transition().duration(1000).attrTween('transform', function (d) {
                this._current = this._current || d;
                var interpolate = d3['default'].interpolate(this._current, d);
                this._current = interpolate(0);
                return function (t) {
                    var d2 = interpolate(t);
                    var pos = outerArc.centroid(d2);
                    pos[0] = radius * (_midAngle(d2) < Math.PI ? 1 : -1);
                    return 'translate(' + pos + ')';
                };
            }).styleTween('text-anchor', function (d) {
                this._current = this._current || d;
                var interpolate = d3['default'].interpolate(this._current, d);
                this._current = interpolate(0);
                return function (t) {
                    var d2 = interpolate(t);
                    return _midAngle(d2) < Math.PI ? 'start' : 'end';
                };
            });

            text.exit().remove();

            var indexCss = '';
            var indexValCss = '';

            if (indexPctChange < 0) {
                indexCss = 'red-dark-chart chart-font-12';
                indexValCss = 'red-chart chart-font-12';
            } else if (indexPctChange > 0) {
                indexCss = 'green-dark-chart chart-font-12';
                indexValCss = 'green-chart chart-font-12';
            }

            /* ------- MAIN INDEX VALUE -------*/
            var idxVal = svg.select('.indexVal').selectAll('text').data(pie(data), indexSym);
            idxVal.enter().append('text').attr('dy', '0.20em').style('text-anchor', 'middle').style('font-size', '11px').attr('class', indexValCss).text(function (d) {
                return d.data.indexVal;
            });

            idxVal.exit().remove();

            /* ------- MAIN INDEX CHANGE-------*/
            var idxChg = svg.select('.indexChg').selectAll('text').data(pie(data), indexChg);
            idxChg.enter().append('text').attr('dy', '1em').style('text-anchor', 'middle').style('font-size', '10px').attr('class', indexCss).text(function (d) {
                return d.data.indexPctChg;
            });

            idxChg.exit().remove();

            /* ------- SHORT DESCRIPTION LABEL  -------*/
            var ldes = svg.select('.labelsDes').selectAll('text').data(pie(data), key1);
            ldes.enter().append('text').attr('dy', '-0.3em').text(function (d) {
                return d.data.labelDes;
            });

            ldes.transition().duration(1000).attrTween('transform', function (d) {
                this._current = this._current || d;
                var interpolate = d3['default'].interpolate(this._current, d);
                this._current = interpolate(0);
                return function (t) {
                    var d2 = interpolate(t);
                    var pos = outerArc.centroid(d2);
                    pos[0] = radius * (_midAngle(d2) < Math.PI ? 1 : -1);
                    return 'translate(' + pos + ')';
                };
            }).styleTween('text-anchor', function (d) {
                this._current = this._current || d;
                var interpolate = d3['default'].interpolate(this._current, d);
                this._current = interpolate(0);
                return function (t) {
                    var d2 = interpolate(t);
                    return _midAngle(d2) < Math.PI ? 'start' : 'end';
                };
            });

            ldes.exit().remove();

            /* ------- SLICE TO TEXT POLYLINES -------*/
            var polyline = svg.select('.lines').selectAll('polyline').data(pie(data), key);
            polyline.enter().append('polyline');

            polyline.transition().duration(1000).attrTween('points', function (d) {
                this._current = this._current || d;
                var interpolate = d3['default'].interpolate(this._current, d);
                this._current = interpolate(0);
                return function (t) {
                    var d2 = interpolate(t);
                    var pos = outerArc.centroid(d2);
                    pos[0] = radius * 0.95 * (_midAngle(d2) < Math.PI ? 1 : -1);
                    return [arc.centroid(d2), outerArc.centroid(d2), pos];
                };
            });

            polyline.exit().remove();
        };

        var change = function change(val, label, arcColor, labelDes, indexVal, indexPctChg) {
            _setColor(label, arcColor);
            _update(_setData(val, labelDes, indexVal, indexPctChg));
        };

        /**
         * Exposed functions to public
         */
        return {
            init: init,
            deInit: deInit,
            update: change
        };
    })();

});