define('ir-app/models/shared/theme/theme-loader', ['exports', 'ir-app/config/user-settings', 'ir-app/models/shared/shared-data-stores/theme-data-store', 'ir-app/utils/utils'], function (exports, userSettings, themeDataStore, utils) {

    'use strict';

    /**
     * Created by banushas on 5/5/2016.
     */

    exports['default'] = (function () {
        // TODO: [Banusha] refactor the chart theme loading
        var loadThemeData = function loadThemeData() {
            try {
                themeDataStore['default'].setThemeObj(userSettings['default'].chartTheme.lightThemeName, userSettings['default'].chartTheme.lightThemeObj);
                themeDataStore['default'].setThemeObj(userSettings['default'].chartTheme.darkThemeName, userSettings['default'].chartTheme.darkThemeObj);
            } catch (e) {
                utils['default'].logger.logError('Error in loading theme data : ' + e);
            }
        };

        return {
            loadThemeData: loadThemeData
        };
    })();

});