define('ir-app/components/stk-specific-components/days-range-slider', ['exports', 'ember', 'ir-app/utils/utils'], function (exports, Ember, utils) {

    'use strict';

    /**
     * Created by User on 2/5/2016.
     */
    exports['default'] = Ember['default'].Component.extend({
        layoutName: 'components/stk-specific-components/days-range-slider',

        calculateTodaysRange: (function () {
            var stock = this.get('stock');
            var value = 0;

            if (stock) {
                var lowValue = stock.low;
                var highValue = stock.high;
                var currentValue = stock.ltp;
                var diff = highValue - lowValue;

                if (diff !== 0) {
                    value = (currentValue - lowValue) / diff * 100;
                    value = value > 0 && value <= 100 ? value : 0;
                }

                return value;
            }
        }).property('stock.ltp', 'stock.low', 'stock.high'),
        showValue: (function () {
            return utils['default'].formatters.formatNumber(this.get('calculateTodaysRange'), 2) + '%';
        }).property('calculateTodaysRange'),

        styles: (function () {
            var progress = parseInt(this.get('calculateTodaysRange'), 10);
            return 'width:' + progress + '%;'; // Used inline styles since width is dynamically and changed rapidly
        }).property('calculateTodaysRange')

    });

});