define('ir-app/components/announcement-context-menu', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        layoutName: 'components/announcement-context-menu',

        click: function click(event) {
            var target = Ember['default'].$(event.target);

            if (!target.hasClass('btn')) {
                event.stopPropagation();
            }
        },

        actions: {
            save: function save() {
                this.sendAction('saveAction');
            },

            cancel: function cancel() {
                this.sendAction('cancelAction');
            }
        }
    });

});