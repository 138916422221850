define('ir-app/components/infinite-scroll', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    /**
     * Created by udaras on 10/18/2016.
     *  Each time the user scrolls if End of the document reached next page will fetch
     */
    exports['default'] = Ember['default'].Component.extend({
        current: 0,
        count: 0,
        pageSize: 0,
        paginationEleRef: undefined,
        scrollContainerRef: undefined,

        init: function init() {
            Ember['default'].run.scheduleOnce('afterRender', this, this._bindInfiniteScroll);
        },
        willDestroyElement: function willDestroyElement() {
            this._unBindInfiniteScroll();
        },
        _unBindInfiniteScroll: function _unBindInfiniteScroll() {
            var that = this;
            if (this.get('scrollContainerRef')) {
                Ember['default'].$('div[ref="' + that.get('scrollContainerRef') + '"]').unbind('scroll');
            } else {
                Ember['default'].$(window).unbind('scroll');
            }
        },
        _bindInfiniteScroll: function _bindInfiniteScroll() {
            var that = this;

            if (this.get('scrollContainerRef')) {
                var container = Ember['default'].$('div[ref="' + that.get('scrollContainerRef') + '"]');

                container.scroll(function () {
                    if (container[0].scrollHeight - container.height() === container.scrollTop()) {
                        var currentPageNum = that.get('current') + 1;
                        var pageSize = that.get('pageSize');
                        var count = that.get('count');
                        var isNextPage = count > pageSize * currentPageNum;

                        if (isNextPage) {
                            that.sendAction('loadNextPage', currentPageNum);
                        } else {
                            Ember['default'].$('div[ref="' + that.get('paginationEle') + '"]').hide();
                        }
                    }
                });
            } else {
                var win = Ember['default'].$(window);

                win.scroll(function () {
                    if (Ember['default'].$(document).height() - win.height() === win.scrollTop()) {
                        var currentPageNum = that.get('current') + 1;
                        var pageSize = that.get('pageSize');
                        var count = that.get('count');
                        var isNextPage = count > pageSize * currentPageNum;

                        if (isNextPage) {
                            that.sendAction('loadNextPage', currentPageNum);
                        } else {
                            Ember['default'].$('div[ref="' + that.get('paginationEle') + '"]').hide();
                        }
                    }
                });
            }
        }
    });

});