define('ir-app/templates/ir-widgets/watchlist-widget/stock-screener', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("tr");
          var el2 = dom.createTextNode("\n                            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"class","col-one");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"class","col-two");
          var el3 = dom.createElement("p");
          dom.setAttribute(el3,"class","text-align-left");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"class","col-four");
          var el3 = dom.createElement("p");
          dom.setAttribute(el3,"class","text-align-left");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"class","col-five");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"class","col-six");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"class","col-seven");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"class","col-eight");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"class","col-nine");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, content = hooks.content, get = hooks.get, inline = hooks.inline;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [7]);
          var element2 = dom.childAt(element0, [9]);
          var morph0 = dom.createMorphAt(dom.childAt(element0, [1]),0,0);
          var morph1 = dom.createMorphAt(dom.childAt(element0, [3, 0]),0,0);
          var morph2 = dom.createMorphAt(dom.childAt(element0, [5, 0]),0,0);
          var morph3 = dom.createMorphAt(element1,0,0);
          var morph4 = dom.createMorphAt(element1,2,2);
          var morph5 = dom.createMorphAt(element2,0,0);
          var morph6 = dom.createMorphAt(element2,2,2);
          var morph7 = dom.createMorphAt(dom.childAt(element0, [11]),0,0);
          var morph8 = dom.createMorphAt(dom.childAt(element0, [13]),0,0);
          var morph9 = dom.createMorphAt(dom.childAt(element0, [15]),0,0);
          content(env, morph0, context, "stk.sym");
          content(env, morph1, context, "stk.lDes");
          inline(env, morph2, context, "getIndustry", [get(env, context, "exchange"), get(env, context, "stk.sec")], {});
          inline(env, morph3, context, "formatNumber", [get(env, context, "stk.ltp"), get(env, context, "stk.deci")], {});
          content(env, morph4, context, "stk.cur");
          inline(env, morph5, context, "formatNumber", [get(env, context, "stk.mktCapMillion"), 0], {});
          content(env, morph6, context, "stk.cur");
          inline(env, morph7, context, "formatNumber", [get(env, context, "stk.dDYield"), 2], {});
          inline(env, morph8, context, "formatNumber", [get(env, context, "stk.pbr"), get(env, context, "stk.deci")], {});
          inline(env, morph9, context, "formatNumber", [get(env, context, "stk.per"), get(env, context, "stk.deci")], {});
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","row margin-medium-bottom");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col-xs-12");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","margin-medium-bottom");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","row");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","container-fluid main-content-wrapper full-height");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col-xs-12 full-height");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","row alt-row");
        var el8 = dom.createTextNode("\n                                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col-xs-6 col-sm-3 padding-medium-top padding-medium-bottom border-top");
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col-xs-6 col-sm-3 padding-medium-top padding-medium-bottom border-top");
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n                                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col-xs-6 col-sm-3 padding-medium-top padding-medium-bottom border-top");
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col-xs-6 col-sm-3 padding-medium-top padding-medium-bottom border-top");
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n                                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col-xs-6 col-sm-3 padding-medium-top padding-medium-bottom border-top");
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col-xs-6 col-sm-3 padding-medium-top padding-medium-bottom border-top");
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n                                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col-xs-6 col-sm-3 padding-medium-top padding-medium-bottom border-top");
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col-xs-6 col-sm-3 padding-medium-top padding-medium-bottom border-top");
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n                                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col-xs-6 col-sm-3 padding-medium-top padding-medium-bottom border-top");
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col-xs-6 col-sm-3 padding-medium-top padding-medium-bottom border-top");
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n                                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col-xs-6 col-sm-3 padding-medium-top padding-medium-bottom border-top");
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col-xs-6 col-sm-3 padding-medium-top padding-medium-bottom border-top");
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n                                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col-xs-3 padding-medium-top padding-medium-bottom border-top");
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col-xs-3 padding-medium-top padding-medium-bottom border-top");
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n                                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col-xs-12 col-sm-6 padding-medium-top padding-medium-bottom border-top text-align-right");
        var el9 = dom.createTextNode("\n                                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("button");
        dom.setAttribute(el9,"class","primary-btn border-none padding-medium padding-large-left padding-large-right margin-medium-right animation-wrap");
        var el10 = dom.createTextNode("\n                                        ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","padding-small-left font-x-large");
        var el11 = dom.createTextNode("\n                                            ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("i");
        dom.setAttribute(el11,"class","fa fa-search scale");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                                            ");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                                        ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                    ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("button");
        dom.setAttribute(el9,"class","secondary-btn padding-medium padding-large-left padding-large-right animation-wrap");
        var el10 = dom.createTextNode("\n                                        ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","padding-small-left font-x-large");
        var el11 = dom.createTextNode("\n                                            ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("i");
        dom.setAttribute(el11,"class","fa fa-repeat spinner");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                                            ");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                                        ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                    ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                    ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","clearfix");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n    ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","col-xs-12");
        var el2 = dom.createTextNode("\n        ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n            ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","tbl-large-wrapper");
        var el4 = dom.createTextNode("\n                ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("table");
        dom.setAttribute(el4,"class","table table-striped table-condensed table-bordered numeric-table large-tbl allstocks-tbl");
        dom.setAttribute(el4,"width","100%");
        var el5 = dom.createTextNode("\n                    ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("thead");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("tr");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7,"class","col-one");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7,"class","col-two");
        var el8 = dom.createElement("p");
        dom.setAttribute(el8,"class","text-align-left");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7,"class","col-four");
        var el8 = dom.createElement("p");
        dom.setAttribute(el8,"class","text-align-left");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7,"class","col-five");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7,"class","col-six");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7,"class","col-seven");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7,"class","col-eight");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7,"class","col-nine");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("tbody");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("                    ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, inline = hooks.inline, element = hooks.element, content = hooks.content, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element3 = dom.childAt(fragment, [0, 1, 1, 1, 1, 1, 1]);
        var element4 = dom.childAt(element3, [29]);
        var element5 = dom.childAt(element4, [1]);
        var element6 = dom.childAt(element4, [3]);
        var element7 = dom.childAt(fragment, [2, 1, 1, 1]);
        var element8 = dom.childAt(element7, [1, 1]);
        var morph0 = dom.createMorphAt(dom.childAt(element3, [1]),1,1);
        var morph1 = dom.createMorphAt(dom.childAt(element3, [3]),1,1);
        var morph2 = dom.createMorphAt(dom.childAt(element3, [5]),1,1);
        var morph3 = dom.createMorphAt(dom.childAt(element3, [7]),1,1);
        var morph4 = dom.createMorphAt(dom.childAt(element3, [9]),1,1);
        var morph5 = dom.createMorphAt(dom.childAt(element3, [11]),1,1);
        var morph6 = dom.createMorphAt(dom.childAt(element3, [13]),1,1);
        var morph7 = dom.createMorphAt(dom.childAt(element3, [15]),1,1);
        var morph8 = dom.createMorphAt(dom.childAt(element3, [17]),1,1);
        var morph9 = dom.createMorphAt(dom.childAt(element3, [19]),1,1);
        var morph10 = dom.createMorphAt(dom.childAt(element3, [21]),1,1);
        var morph11 = dom.createMorphAt(dom.childAt(element3, [23]),1,1);
        var morph12 = dom.createMorphAt(dom.childAt(element3, [25]),1,1);
        var morph13 = dom.createMorphAt(dom.childAt(element3, [27]),1,1);
        var morph14 = dom.createMorphAt(dom.childAt(element5, [1]),3,3);
        var morph15 = dom.createMorphAt(dom.childAt(element6, [1]),3,3);
        var morph16 = dom.createMorphAt(dom.childAt(element8, [1]),0,0);
        var morph17 = dom.createMorphAt(dom.childAt(element8, [3, 0]),0,0);
        var morph18 = dom.createMorphAt(dom.childAt(element8, [5, 0]),0,0);
        var morph19 = dom.createMorphAt(dom.childAt(element8, [7]),0,0);
        var morph20 = dom.createMorphAt(dom.childAt(element8, [9]),0,0);
        var morph21 = dom.createMorphAt(dom.childAt(element8, [11]),0,0);
        var morph22 = dom.createMorphAt(dom.childAt(element8, [13]),0,0);
        var morph23 = dom.createMorphAt(dom.childAt(element8, [15]),0,0);
        var morph24 = dom.createMorphAt(dom.childAt(element7, [3]),1,1);
        inline(env, morph0, context, "view", ["select"], {"content": get(env, context, "exchangeList"), "value": get(env, context, "filterExchange"), "optionValuePath": "content.exg", "optionLabelPath": "content.exg", "class": "select-style-1"});
        inline(env, morph1, context, "input", [], {"type": "text", "value": get(env, context, "filterCompany"), "class": "search-query form-control font-family-arial", "placeholder": get(env, context, "app.lang.labels.company")});
        inline(env, morph2, context, "view", ["select"], {"content": get(env, context, "industryList"), "value": get(env, context, "filterIndustry"), "optionValuePath": "content.sec", "optionLabelPath": "content.des", "class": "select-style-1"});
        inline(env, morph3, context, "view", ["select"], {"content": get(env, context, "currencyList"), "value": get(env, context, "filterCurrency"), "optionValuePath": "content.cur", "optionLabelPath": "content.cur", "class": "select-style-1"});
        inline(env, morph4, context, "input", [], {"type": "number", "value": get(env, context, "filterMktCapMin"), "class": "input-text-style-1", "placeholder": "Market Cap Min"});
        inline(env, morph5, context, "input", [], {"type": "number", "value": get(env, context, "filterMktCapMax"), "class": "input-text-style-1", "placeholder": "Market Cap Max"});
        inline(env, morph6, context, "input", [], {"type": "number", "value": get(env, context, "filterPriceMin"), "class": "input-text-style-1", "placeholder": "Price Min"});
        inline(env, morph7, context, "input", [], {"type": "number", "value": get(env, context, "filterPriceMax"), "class": "input-text-style-1", "placeholder": "Price Max"});
        inline(env, morph8, context, "input", [], {"type": "number", "value": get(env, context, "filterDYldMin"), "class": "input-text-style-1", "placeholder": "D Yld Min"});
        inline(env, morph9, context, "input", [], {"type": "number", "value": get(env, context, "filterDYldMax"), "class": "input-text-style-1", "placeholder": "D Yld Max"});
        inline(env, morph10, context, "input", [], {"type": "number", "value": get(env, context, "filterPEMin"), "class": "input-text-style-1", "placeholder": "P/E Min"});
        inline(env, morph11, context, "input", [], {"type": "number", "value": get(env, context, "filterPEMax"), "class": "input-text-style-1", "placeholder": "P/E Max"});
        inline(env, morph12, context, "input", [], {"type": "number", "value": get(env, context, "filterPBMin"), "class": "input-text-style-1", "placeholder": "P/B Min"});
        inline(env, morph13, context, "input", [], {"type": "number", "value": get(env, context, "filterPBMax"), "class": "input-text-style-1", "placeholder": "P/B Max"});
        element(env, element5, context, "action", ["applyFilters"], {});
        content(env, morph14, context, "app.lang.labels.search");
        element(env, element6, context, "action", ["resetFilters"], {});
        content(env, morph15, context, "app.lang.labels.btnClear");
        content(env, morph16, context, "app.lang.labels.symbol");
        content(env, morph17, context, "app.lang.labels.company");
        content(env, morph18, context, "app.lang.labels.industry");
        content(env, morph19, context, "app.lang.labels.price");
        content(env, morph20, context, "app.lang.labels.mktCapMillion");
        content(env, morph21, context, "app.lang.labels.divYieldPer");
        content(env, morph22, context, "app.lang.labels.pbRatio");
        content(env, morph23, context, "app.lang.labels.peRatio");
        block(env, morph24, context, "each", [get(env, context, "filteredContent")], {"keyword": "stk"}, child0, null);
        return fragment;
      }
    };
  }()));

});