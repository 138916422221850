define('ir-app/models/decypha/decypha-service-constants', ['exports'], function (exports) {

    'use strict';

    /**
     * Created by udaras on 10/14/2016.
     */
    exports['default'] = {

        NewsSearchPageSize: 10,
        CompanyScreenerPageSize: 10,
        UrlTypes: {
            Content: 'decyphacontent',
            Login: 'decyphalogin',
            UserStatus: 'decyphastatus',
            RequestTrial: 'decyphaRequestTrial',
            ResetPassword: 'decyphaResetPassword'
        },
        DecyphaRequest: {
            NewsSearch: {
                RT: 4001,
                SDT: {
                    TOP: 'TOP',
                    AGG: 'AGG',
                    MDJ: 'MDJ'
                }
            },
            CompanyScreener: {
                RT: 4002,
                SDT: {
                    COMPANY_SCREENER_DATA: 'COMPSCR',
                    COMPANY_SCREENER_EXCEL: 'COMPSCREX'
                },
                COMPANY_NAME: 'COMPANY_NAME'
            }
        },
        ClientType: {
            INTERNAL: 'I',
            EXTERNAL: 'X'
        },
        LoginStatus: {
            STATUS_FAILED: 0,
            STATUS_SUCCESS: 1,
            STATUS_USER_EXPIRED: 2,
            STATUS_INVALID_INPUTS: 3,
            STATUS_USER_NOT_ACTIVATED: 4,
            STATUS_USER_PENDING_APPROVAL: 5,
            STATUS_USER_SESSION_EXPIRED: 6,
            STATUS_USER_SESSION_ACTIVE: 7
        },
        resetPasswordStatus: {
            STATUS_SUCCESS: 1,
            STATUS_FAILED: 0
        },

        TrialStatus: {
            STATUS_ZOHO_SUCCESS: 2
        },

        ZOHOParameters: {
            url: 'https://crm.zoho.com/crm/WebToLeadForm',
            xnQsjsdp: 'd1f3c92d665acb2a6812be1eae3be2725f73be53c3d92211abf8864796a3cf4c',
            zc_gad: '',
            xmIwtLD: 'c0b21d5f901f40af48086b35a92f2b45a83ad5741cf24274c2063779ee86341a',
            actionType: 'TGVhZHM=',
            returnURL: '/'
        },

        CacheKeys: {
            DECYPHA_USER_SESSION: 'Decypha_user_cache'
        }
    };

});