define('ir-app/controllers/ir-widgets/financial-widgets/advance-financials-comparison', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/models/price/price-business-entities/fundamentals/fundamental-constants', 'ir-app/components/stk-specific-components/financial-chart-comparison', 'ir-app/components/stk-specific-components/financial-statement-comparison', 'ir-app/models/price/price-constants'], function (exports, Ember, BaseController, priceService, sharedService, FundamentalConstants, FinancialChart, FinancialStatement, priceConstants) {

    'use strict';

    exports['default'] = BaseController['default'].extend({
        viewDropdownListView: [],
        viewDropdownListFromDate: [],
        viewDropdownListToDate: [],
        viewDropdownListPeriods: [],

        viewType: FundamentalConstants['default'].ViewTypes.VIEW_TYPE_QUARTER,

        styleObject: undefined,
        dataObjectIS: undefined,
        dataCompareObjectIS1: undefined,
        dataCompareObjectIS2: undefined,
        dataCompareObjectIS3: undefined,
        dataCompareObjectIS4: undefined,

        dataObjectMR: undefined,
        dataCompareObjectMR1: undefined,
        dataCompareObjectMR2: undefined,
        dataCompareObjectMR3: undefined,

        dataObjectBS: undefined,
        dataCompareObjectBS1: undefined,
        dataCompareObjectBS2: undefined,
        dataCompareObjectBS3: undefined,
        dataCompareObjectBS4: undefined,

        dataObjectCF: undefined,
        dataCompareObjectCF1: undefined,
        dataCompareObjectCF2: undefined,
        dataCompareObjectCF3: undefined,
        dataCompareObjectCF4: undefined,

        dataKeySet: undefined,

        displayDataObjectIS: {},
        displayDataObjectBS: {},
        displayDataObjectCF: {},
        displayDataObjectMR: {},

        displayCompareDataObjectIS1: {},
        displayCompareDataObjectIS2: {},
        displayCompareDataObjectIS3: {},
        displayCompareDataObjectIS4: {},

        displayCompareDataObjectBS1: {},
        displayCompareDataObjectBS2: {},
        displayCompareDataObjectBS3: {},
        displayCompareDataObjectBS4: {},

        displayCompareDataObjectCF1: {},
        displayCompareDataObjectCF2: {},
        displayCompareDataObjectCF3: {},
        displayCompareDataObjectCF4: {},

        displayCompareDataObjectMR1: {},
        displayCompareDataObjectMR2: {},
        displayCompareDataObjectMR3: {},

        displayStyleObject: Ember['default'].A([]),

        breakDown: FundamentalConstants['default'].BreakDown.ExpandView,
        isExpand: 1,
        isCalendar: 1,
        viewTypeForHelper: FundamentalConstants['default'].ViewTypes.VIEW_TYPE_QUARTER,

        fromDate: undefined,
        toDate: undefined,

        dataSet1: Ember['default'].A(),
        dataSet2: Ember['default'].A(),
        dataSet3: Ember['default'].A(),
        dataSet4: Ember['default'].A(),
        dataSet5: Ember['default'].A(),
        dataSet6: Ember['default'].A(),
        dataSet7: Ember['default'].A(),
        dataSet8: Ember['default'].A(),
        dataSet9: Ember['default'].A(),

        compareExg1: undefined,
        compareExg2: undefined,
        compareExg3: undefined,
        compareExg4: undefined,

        compareSym1: undefined,
        compareSym2: undefined,
        compareSym3: undefined,
        compareSym4: undefined,

        compareSym1Des: undefined,
        compareSym2Des: undefined,
        compareSym3Des: undefined,
        compareSym4Des: undefined,

        statementType: 'IS',
        filterFromDate: undefined,
        filterToDate: undefined,
        defSelectFrom: undefined,
        defSelectTo: undefined,
        defSelectView: undefined,

        minMaxObject: undefined,
        stock: undefined,
        reRender: false,
        tempID: undefined,

        onPrepareData: function onPrepareData() {
            var compObj = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, undefined, undefined, undefined);
            this.set('compObj', compObj);
            priceService['default'].sendCompanyProfileRequest(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, undefined, undefined);
            priceService['default'].sendSymbolValidationRequest(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage);
            this.set('tempID', this.get('compObj').templateId);

            var exchange = this.get('exg');
            var symbol = this.get('sym');
            var view = this.get('viewType');
            this.startLoadingProgress();
            this.sendDataRequest(exchange, symbol, view);
        },

        onClearData: function onClearData() {
            priceService['default'].removeSymbolRequest(this.get('exg'), this.get('sym'));
        },

        _defaultSelectionMaker: function _defaultSelectionMaker() {
            var that = this;
            var keyListDate,
                i,
                j,
                k = 4;
            that.set('minMaxObject', priceService['default'].advanceFundamentalDS.getMinMaxObject(that.get('exg'), that.get('sym'), that.get('statementType'), that.get('viewType'), this.get('isCalendar')));
            var minMaxObj = this.get('minMaxObject');

            keyListDate = [];
            this.set('viewType', 'Q');

            for (i = parseInt(minMaxObj['MIN_CALENDER_YEAR'], 10); i <= parseInt(minMaxObj['MAX_CALENDER_YEAR'], 10); i++) {
                for (j = 1; j <= k; j++) {
                    if (i === parseInt(minMaxObj['MIN_CALENDER_YEAR'], 10)) {
                        j = parseInt(minMaxObj['MIN_CAL_QUARTER_REQUESTED'], 10);
                    } else if (i === parseInt(minMaxObj['MAX_CALENDER_YEAR'], 10)) {
                        k = parseInt(minMaxObj['MAX_CAL_QUARTER_REQUESTED'], 10);
                    }

                    if (j === 1) {
                        keyListDate.push({ view: i + '~' + j, des: 'Q' + j + ' ' + i });
                    } else {
                        keyListDate.push({ view: i + '~' + '1' + j, des: 'Q' + j + ' ' + i });
                    }
                }
            }
            var keyListView = [{ view: 1, des: this.get('app').lang.labels.yearly }, { view: 2, des: this.get('app').lang.labels.quarterly }, { view: 3, des: this.get('app').lang.labels.interim }, { view: 4, des: this.get('app').lang.labels.ttm }];
            keyListDate.reverse(); // to invert the upper most value of the dropdown
            this.set('viewDropdownListView', keyListView);
            this.set('viewDropdownListFromDate', keyListDate);
            this.set('viewDropdownListToDate', keyListDate);

            this.set('defSelectFrom', keyListDate[3]);
            this.set('defSelectTo', keyListDate[0]);
            this.set('defSelectView', keyListView[1]);
            this.set('filterFromDate', keyListDate[3].view);
            this.set('filterToDate', keyListDate[0].view);
        },

        sendDataRequest: function sendDataRequest(exchange, symbol, view) {
            var that = this;
            var postSuccess = function postSuccess() {
                if (!that.get('reRender')) {
                    that._defaultSelectionMaker();
                }
                that.dataDisplay(exchange, symbol, view);
                that.stopLoadingProgress();
            };

            var error = function error() {
                that.stopLoadingProgress();
            };

            priceService['default'].sendFundamentalDataRequest(exchange, symbol, sharedService['default'].userSettings.currentLanguage, view, postSuccess, error, '5', this.get('isCalendar'));
            priceService['default'].addSymbolRequest(exchange, symbol);
        },

        getValue: function getValue(key, obj) {

            return obj[this.get('filterToDate')][key];
        },

        dataDisplay: function dataDisplay(exchange, symbol, view) {
            var templateID = this.get('compObj').templateId;
            var filterTo;
            filterTo = this.get('filterToDate');
            this.set('dataObjectIS', priceService['default'].advanceFundamentalDS.getDataObject(this.get('exg'), this.get('sym'), 'IS', view, this.get('isCalendar')));
            this.set('dataObjectBS', priceService['default'].advanceFundamentalDS.getDataObject(this.get('exg'), this.get('sym'), 'BS', view, this.get('isCalendar')));
            this.set('dataObjectCF', priceService['default'].advanceFundamentalDS.getDataObject(this.get('exg'), this.get('sym'), 'CF', view, this.get('isCalendar')));
            this.set('dataObjectMR', priceService['default'].advanceFundamentalDS.getDataObject(this.get('exg'), this.get('sym'), 'MR', view, this.get('isCalendar')));
            this.set('stock', priceService['default'].stockDS.getStock(this.get('exg'), this.get('sym')));
            this.set('isStockAvailable', this.get('stock') === undefined ? false : true);

            if (this.get('compareExg1') !== undefined && this.get('compareSym1')) {
                this.set('dataCompareObjectIS1', priceService['default'].advanceFundamentalDS.getDataObject(this.get('compareExg1'), this.get('compareSym1'), 'IS', view, this.get('isCalendar')));
                this.set('dataCompareObjectBS1', priceService['default'].advanceFundamentalDS.getDataObject(this.get('compareExg1'), this.get('compareSym1'), 'BS', view, this.get('isCalendar')));
                this.set('dataCompareObjectCF1', priceService['default'].advanceFundamentalDS.getDataObject(this.get('compareExg1'), this.get('compareSym1'), 'CF', view, this.get('isCalendar')));
                this.set('dataCompareObjectMR1', priceService['default'].advanceFundamentalDS.getDataObject(this.get('compareExg1'), this.get('compareSym1'), 'MR', view, this.get('isCalendar')));
                this.set('comp1Stock', priceService['default'].stockDS.getStock(this.get('compareExg1'), this.get('compareSym1')));
                this.set('isComp1StockAvailable', this.get('comp1Stock') === undefined ? false : true);
            }
            if (this.get('compareExg2') !== undefined && this.get('compareSym2')) {
                this.set('dataCompareObjectIS2', priceService['default'].advanceFundamentalDS.getDataObject(this.get('compareExg2'), this.get('compareSym2'), 'IS', view, this.get('isCalendar')));
                this.set('dataCompareObjectBS2', priceService['default'].advanceFundamentalDS.getDataObject(this.get('compareExg2'), this.get('compareSym2'), 'BS', view, this.get('isCalendar')));
                this.set('dataCompareObjectCF2', priceService['default'].advanceFundamentalDS.getDataObject(this.get('compareExg2'), this.get('compareSym2'), 'CF', view, this.get('isCalendar')));
                this.set('dataCompareObjectMR2', priceService['default'].advanceFundamentalDS.getDataObject(this.get('compareExg2'), this.get('compareSym2'), 'MR', view, this.get('isCalendar')));
                this.set('comp2Stock', priceService['default'].stockDS.getStock(this.get('compareExg2'), this.get('compareSym2')));
                this.set('isComp2StockAvailable', this.get('comp2Stock') === undefined ? false : true);
            }
            if (this.get('compareExg3') !== undefined && this.get('compareSym3')) {
                this.set('dataCompareObjectIS3', priceService['default'].advanceFundamentalDS.getDataObject(this.get('compareExg3'), this.get('compareSym3'), 'IS', view, this.get('isCalendar')));
                this.set('dataCompareObjectBS3', priceService['default'].advanceFundamentalDS.getDataObject(this.get('compareExg3'), this.get('compareSym3'), 'BS', view, this.get('isCalendar')));
                this.set('dataCompareObjectCF3', priceService['default'].advanceFundamentalDS.getDataObject(this.get('compareExg3'), this.get('compareSym3'), 'CF', view, this.get('isCalendar')));
                this.set('dataCompareObjectMR3', priceService['default'].advanceFundamentalDS.getDataObject(this.get('compareExg3'), this.get('compareSym3'), 'MR', view, this.get('isCalendar')));
                this.set('comp3Stock', priceService['default'].stockDS.getStock(this.get('compareExg3'), this.get('compareSym3')));
                this.set('isComp3StockAvailable', this.get('comp3Stock') === undefined ? false : true);
            }
            if (this.get('compareExg4') !== undefined && this.get('compareSym4')) {
                this.set('dataCompareObjectIS4', priceService['default'].advanceFundamentalDS.getDataObject(this.get('compareExg4'), this.get('compareSym4'), 'IS', view, this.get('isCalendar')));
                this.set('dataCompareObjectBS4', priceService['default'].advanceFundamentalDS.getDataObject(this.get('compareExg4'), this.get('compareSym4'), 'BS', view, this.get('isCalendar')));
                this.set('dataCompareObjectCF4', priceService['default'].advanceFundamentalDS.getDataObject(this.get('compareExg4'), this.get('compareSym4'), 'CF', view, this.get('isCalendar')));
                this.set('dataCompareObjectMR4', priceService['default'].advanceFundamentalDS.getDataObject(this.get('compareExg4'), this.get('compareSym4'), 'MR', view, this.get('isCalendar')));
                this.set('comp4Stock', priceService['default'].stockDS.getStock(this.get('compareExg4'), this.get('compareSym4')));
                this.set('isComp4StockAvailable', this.get('comp4Stock') === undefined ? false : true);
            }
            var baseNetInc, baseTotalRev, baseGrossProf, baseTotalAsset, baseTotalEquity, baseTotalDeposits, baseOpAvtv, baseInvActv, baseFinActv;
            var comp1TotalAsset, comp1TotalEquity, comp1TotalDeposits, comp1OpAvtv, comp1InvActv, comp1FinActv, comp1NetInc, comp1TotalRev, comp1GrossProf;
            var comp2TotalAsset, comp2TotalEquity, comp2TotalDeposits, comp2OpAvtv, comp2InvActv, comp2FinActv, comp2GrossProf, comp2TotalRev, comp2NetInc;
            var comp3TotalAsset, comp3TotalEquity, comp3TotalDeposits, comp3OpAvtv, comp3InvActv, comp3FinActv, comp3GrossProf, comp3TotalRev, comp3NetInc;
            var comp4TotalAsset, comp4TotalEquity, comp4TotalDeposits, comp4OpAvtv, comp4InvActv, comp4FinActv, comp4GrossProf, comp4TotalRev, comp4NetInc;

            if (this.get('dataObjectIS')) {
                this.filterDataValues(filterTo, filterTo, this.get('dataObjectIS'), this.get('displayDataObjectIS'));

                if (templateID === priceConstants['default'].TemplateID.Industrial) {
                    baseNetInc = this.getValue('IS_NetInc', this.get('displayDataObjectIS'));
                    baseTotalRev = this.getValue('IS_TotRvn', this.get('displayDataObjectIS'));
                    baseGrossProf = this.getValue('IS_GrsPft', this.get('displayDataObjectIS'));
                } else if (templateID === priceConstants['default'].TemplateID.Banks) {
                    baseNetInc = this.getValue('IS_NetInc', this.get('displayDataObjectIS'));
                    baseTotalRev = this.getValue('IS_OprIncBnkrv', this.get('displayDataObjectIS'));
                    baseGrossProf = this.getValue('IS_NETOPERINLO', this.get('displayDataObjectIS'));
                } else if (templateID === priceConstants['default'].TemplateID.Insurance) {
                    baseNetInc = this.getValue('IS_NetInc', this.get('displayDataObjectIS'));
                    baseTotalRev = this.getValue('IS_TotUWGRv', this.get('displayDataObjectIS'));
                    baseGrossProf = this.getValue('IS_GLIOP', this.get('displayDataObjectIS'));
                }
            }

            if (this.get('dataObjectBS')) {
                this.filterDataValues(filterTo, filterTo, this.get('dataObjectBS'), this.get('displayDataObjectBS'));

                if (templateID === priceConstants['default'].TemplateID.Industrial) {
                    baseTotalAsset = this.getValue('BS_TotAst', this.get('displayDataObjectBS'));
                    baseTotalEquity = this.getValue('BS_TOEAMINT', this.get('displayDataObjectBS'));
                    baseTotalDeposits = this.getValue('BS_CshShoTerInv', this.get('displayDataObjectBS'));
                } else if (templateID === priceConstants['default'].TemplateID.Banks) {
                    baseTotalAsset = this.getValue('BS_TotAst', this.get('displayDataObjectBS'));
                    baseTotalEquity = this.getValue('BS_TOEAMINT', this.get('displayDataObjectBS'));
                    baseTotalDeposits = this.getValue('BS_TotDps', this.get('displayDataObjectBS'));
                } else if (templateID === priceConstants['default'].TemplateID.Insurance) {
                    baseTotalAsset = this.getValue('BS_TotAst', this.get('displayDataObjectBS'));
                    baseTotalEquity = this.getValue('BS_TOEAMINT', this.get('displayDataObjectBS'));
                    baseTotalDeposits = this.getValue('BS_CshEqv', this.get('displayDataObjectBS'));
                }
            }

            if (this.get('dataObjectCF')) {
                this.filterDataValues(filterTo, filterTo, this.get('dataObjectCF'), this.get('displayDataObjectCF'));

                if (templateID === priceConstants['default'].TemplateID.Industrial) {
                    baseOpAvtv = this.getValue('CF_CshOprAcv', this.get('displayDataObjectCF'));
                    baseInvActv = this.getValue('CF_CshInvAcv', this.get('displayDataObjectCF'));
                    baseFinActv = this.getValue('CF_CshFinAcv', this.get('displayDataObjectCF'));
                } else if (templateID === priceConstants['default'].TemplateID.Banks) {
                    baseOpAvtv = this.getValue('CF_CshOprAcv', this.get('displayDataObjectCF'));
                    baseInvActv = this.getValue('CF_CshInvAcv', this.get('displayDataObjectCF'));
                    baseFinActv = this.getValue('CF_CshFinAcv', this.get('displayDataObjectCF'));
                } else if (templateID === priceConstants['default'].TemplateID.Insurance) {
                    baseOpAvtv = this.getValue('CF_CshOprAcv', this.get('displayDataObjectCF'));
                    baseInvActv = this.getValue('CF_CshInvAcv', this.get('displayDataObjectCF'));
                    baseFinActv = this.getValue('CF_CshFinAcv', this.get('displayDataObjectCF'));
                }
            }

            if (this.get('dataCompareObjectIS1') !== undefined) {
                this.filterDataValues(filterTo, filterTo, this.get('dataCompareObjectIS1'), this.get('displayCompareDataObjectIS1'));

                if (templateID === priceConstants['default'].TemplateID.Industrial) {
                    comp1NetInc = this.getValue('IS_NetInc', this.get('displayCompareDataObjectIS1'));
                    comp1TotalRev = this.getValue('IS_TotRvn', this.get('displayCompareDataObjectIS1'));
                    comp1GrossProf = this.getValue('IS_GrsPft', this.get('displayCompareDataObjectIS1'));
                } else if (templateID === priceConstants['default'].TemplateID.Banks) {
                    comp1NetInc = this.getValue('IS_NetInc', this.get('displayCompareDataObjectIS1'));
                    comp1TotalRev = this.getValue('IS_OprIncBnkrv', this.get('displayCompareDataObjectIS1'));
                    comp1GrossProf = this.getValue('IS_NETOPERINLO', this.get('displayCompareDataObjectIS1'));
                } else if (templateID === priceConstants['default'].TemplateID.Insurance) {
                    comp1NetInc = this.getValue('IS_NetInc', this.get('displayCompareDataObjectIS1'));
                    comp1TotalRev = this.getValue('IS_TotUWGRv', this.get('displayCompareDataObjectIS1'));
                    comp1GrossProf = this.getValue('IS_GLIOP', this.get('displayCompareDataObjectIS1'));
                }
            }

            if (this.get('dataCompareObjectBS1') !== undefined) {
                this.filterDataValues(filterTo, filterTo, this.get('dataCompareObjectBS1'), this.get('displayCompareDataObjectBS1'));

                if (templateID === priceConstants['default'].TemplateID.Industrial) {
                    comp1TotalAsset = this.getValue('BS_TotAst', this.get('displayCompareDataObjectBS1'));
                    comp1TotalEquity = this.getValue('BS_TOEAMINT', this.get('displayCompareDataObjectBS1'));
                    comp1TotalDeposits = this.getValue('BS_CshShoTerInv', this.get('displayCompareDataObjectBS1'));
                } else if (templateID === priceConstants['default'].TemplateID.Banks) {
                    comp1TotalAsset = this.getValue('BS_TotAst', this.get('displayCompareDataObjectBS1'));
                    comp1TotalEquity = this.getValue('BS_TOEAMINT', this.get('displayCompareDataObjectBS1'));
                    comp1TotalDeposits = this.getValue('BS_TotDps', this.get('displayCompareDataObjectBS1'));
                } else if (templateID === priceConstants['default'].TemplateID.Insurance) {
                    comp1TotalAsset = this.getValue('BS_TotAst', this.get('displayCompareDataObjectBS1'));
                    comp1TotalEquity = this.getValue('BS_TOEAMINT', this.get('displayCompareDataObjectBS1'));
                    comp1TotalDeposits = this.getValue('BS_CshEqv', this.get('displayCompareDataObjectBS1'));
                }
            }

            if (this.get('dataCompareObjectCF1') !== undefined) {
                this.filterDataValues(filterTo, filterTo, this.get('dataCompareObjectCF1'), this.get('displayCompareDataObjectCF1'));

                if (templateID === priceConstants['default'].TemplateID.Industrial) {
                    comp1OpAvtv = this.getValue('CF_CshOprAcv', this.get('displayCompareDataObjectCF1'));
                    comp1InvActv = this.getValue('CF_CshInvAcv', this.get('displayCompareDataObjectCF1'));
                    comp1FinActv = this.getValue('CF_CshFinAcv', this.get('displayCompareDataObjectCF1'));
                } else if (templateID === priceConstants['default'].TemplateID.Banks) {
                    comp1OpAvtv = this.getValue('CF_CshOprAcv', this.get('displayCompareDataObjectCF1'));
                    comp1InvActv = this.getValue('CF_CshInvAcv', this.get('displayCompareDataObjectCF1'));
                    comp1FinActv = this.getValue('CF_CshFinAcv', this.get('displayCompareDataObjectCF1'));
                } else if (templateID === priceConstants['default'].TemplateID.Insurance) {
                    comp1OpAvtv = this.getValue('CF_CshOprAcv', this.get('displayCompareDataObjectCF1'));
                    comp1InvActv = this.getValue('CF_CshInvAcv', this.get('displayCompareDataObjectCF1'));
                    comp1FinActv = this.getValue('CF_CshFinAcv', this.get('displayCompareDataObjectCF1'));
                }
            }

            if (this.get('dataCompareObjectIS2') !== undefined) {
                this.filterDataValues(filterTo, filterTo, this.get('dataCompareObjectIS2'), this.get('displayCompareDataObjectIS2'));

                if (templateID === priceConstants['default'].TemplateID.Industrial) {
                    comp2NetInc = this.getValue('IS_NetInc', this.get('displayCompareDataObjectIS2'));
                    comp2TotalRev = this.getValue('IS_TotRvn', this.get('displayCompareDataObjectIS2'));
                    comp2GrossProf = this.getValue('IS_GrsPft', this.get('displayCompareDataObjectIS2'));
                } else if (templateID === priceConstants['default'].TemplateID.Banks) {
                    comp2NetInc = this.getValue('IS_NetInc', this.get('displayCompareDataObjectIS2'));
                    comp2TotalRev = this.getValue('IS_OprIncBnkrv', this.get('displayCompareDataObjectIS2'));
                    comp2GrossProf = this.getValue('IS_NETOPERINLO', this.get('displayCompareDataObjectIS2'));
                } else if (templateID === priceConstants['default'].TemplateID.Insurance) {
                    comp2NetInc = this.getValue('IS_NetInc', this.get('displayCompareDataObjectIS2'));
                    comp2TotalRev = this.getValue('IS_TotUWGRv', this.get('displayCompareDataObjectIS2'));
                    comp2GrossProf = this.getValue('IS_GLIOP', this.get('displayCompareDataObjectIS2'));
                }
            }

            if (this.get('dataCompareObjectBS2') !== undefined) {
                this.filterDataValues(filterTo, filterTo, this.get('dataCompareObjectBS2'), this.get('displayCompareDataObjectBS2'));

                if (templateID === priceConstants['default'].TemplateID.Industrial) {
                    comp2TotalAsset = this.getValue('BS_TotAst', this.get('displayCompareDataObjectBS2'));
                    comp2TotalEquity = this.getValue('BS_TOEAMINT', this.get('displayCompareDataObjectBS2'));
                    comp2TotalDeposits = this.getValue('BS_CshShoTerInv', this.get('displayCompareDataObjectBS2'));
                } else if (templateID === priceConstants['default'].TemplateID.Banks) {
                    comp2TotalAsset = this.getValue('BS_TotAst', this.get('displayCompareDataObjectBS2'));
                    comp2TotalEquity = this.getValue('BS_TOEAMINT', this.get('displayCompareDataObjectBS2'));
                    comp2TotalDeposits = this.getValue('BS_TotDps', this.get('displayCompareDataObjectBS2'));
                } else if (templateID === priceConstants['default'].TemplateID.Insurance) {
                    comp2TotalAsset = this.getValue('BS_TotAst', this.get('displayCompareDataObjectBS2'));
                    comp2TotalEquity = this.getValue('BS_TOEAMINT', this.get('displayCompareDataObjectBS2'));
                    comp2TotalDeposits = this.getValue('BS_CshEqv', this.get('displayCompareDataObjectBS2'));
                }
            }

            if (this.get('dataCompareObjectCF2') !== undefined) {
                this.filterDataValues(filterTo, filterTo, this.get('dataCompareObjectCF2'), this.get('displayCompareDataObjectCF2'));

                if (templateID === priceConstants['default'].TemplateID.Industrial) {
                    comp2OpAvtv = this.getValue('CF_CshOprAcv', this.get('displayCompareDataObjectCF2'));
                    comp2InvActv = this.getValue('CF_CshInvAcv', this.get('displayCompareDataObjectCF2'));
                    comp2FinActv = this.getValue('CF_CshFinAcv', this.get('displayCompareDataObjectCF2'));
                } else if (templateID === priceConstants['default'].TemplateID.Banks) {
                    comp2OpAvtv = this.getValue('CF_CshOprAcv', this.get('displayCompareDataObjectCF2'));
                    comp2InvActv = this.getValue('CF_CshInvAcv', this.get('displayCompareDataObjectCF2'));
                    comp2FinActv = this.getValue('CF_CshFinAcv', this.get('displayCompareDataObjectCF2'));
                } else if (templateID === priceConstants['default'].TemplateID.Insurance) {
                    comp2OpAvtv = this.getValue('CF_CshOprAcv', this.get('displayCompareDataObjectCF2'));
                    comp2InvActv = this.getValue('CF_CshInvAcv', this.get('displayCompareDataObjectCF2'));
                    comp2FinActv = this.getValue('CF_CshFinAcv', this.get('displayCompareDataObjectCF2'));
                }
            }

            if (this.get('dataCompareObjectIS3') !== undefined) {
                this.filterDataValues(filterTo, filterTo, this.get('dataCompareObjectIS3'), this.get('displayCompareDataObjectIS3'));

                if (templateID === priceConstants['default'].TemplateID.Industrial) {
                    comp3NetInc = this.getValue('IS_NetInc', this.get('displayCompareDataObjectIS3'));
                    comp3TotalRev = this.getValue('IS_TotRvn', this.get('displayCompareDataObjectIS3'));
                    comp3GrossProf = this.getValue('IS_GrsPft', this.get('displayCompareDataObjectIS3'));
                } else if (templateID === priceConstants['default'].TemplateID.Banks) {
                    comp3NetInc = this.getValue('IS_NetInc', this.get('displayCompareDataObjectIS3'));
                    comp3TotalRev = this.getValue('IS_OprIncBnkrv', this.get('displayCompareDataObjectIS3'));
                    comp3GrossProf = this.getValue('IS_NETOPERINLO', this.get('displayCompareDataObjectIS3'));
                } else if (templateID === priceConstants['default'].TemplateID.Insurance) {
                    comp3NetInc = this.getValue('IS_NetInc', this.get('displayCompareDataObjectIS3'));
                    comp3TotalRev = this.getValue('IS_TotUWGRv', this.get('displayCompareDataObjectIS3'));
                    comp3GrossProf = this.getValue('IS_GLIOP', this.get('displayCompareDataObjectIS3'));
                }
            }

            if (this.get('dataCompareObjectBS3') !== undefined) {
                this.filterDataValues(filterTo, filterTo, this.get('dataCompareObjectBS3'), this.get('displayCompareDataObjectBS3'));

                if (templateID === priceConstants['default'].TemplateID.Industrial) {
                    comp3TotalAsset = this.getValue('BS_TotAst', this.get('displayCompareDataObjectBS3'));
                    comp3TotalEquity = this.getValue('BS_TOEAMINT', this.get('displayCompareDataObjectBS3'));
                    comp3TotalDeposits = this.getValue('BS_CshShoTerInv', this.get('displayCompareDataObjectBS3'));
                } else if (templateID === priceConstants['default'].TemplateID.Banks) {
                    comp3TotalAsset = this.getValue('BS_TotAst', this.get('displayCompareDataObjectBS3'));
                    comp3TotalEquity = this.getValue('BS_TOEAMINT', this.get('displayCompareDataObjectBS3'));
                    comp3TotalDeposits = this.getValue('BS_TotDps', this.get('displayCompareDataObjectBS3'));
                } else if (templateID === priceConstants['default'].TemplateID.Insurance) {
                    comp3TotalAsset = this.getValue('BS_TotAst', this.get('displayCompareDataObjectBS3'));
                    comp3TotalEquity = this.getValue('BS_TOEAMINT', this.get('displayCompareDataObjectBS3'));
                    comp3TotalDeposits = this.getValue('BS_CshEqv', this.get('displayCompareDataObjectBS3'));
                }
            }

            if (this.get('dataCompareObjectCF3') !== undefined) {
                this.filterDataValues(filterTo, filterTo, this.get('dataCompareObjectCF3'), this.get('displayCompareDataObjectCF3'));

                if (templateID === priceConstants['default'].TemplateID.Industrial) {
                    comp3OpAvtv = this.getValue('CF_CshOprAcv', this.get('displayCompareDataObjectCF3'));
                    comp3InvActv = this.getValue('CF_CshInvAcv', this.get('displayCompareDataObjectCF3'));
                    comp3FinActv = this.getValue('CF_CshFinAcv', this.get('displayCompareDataObjectCF3'));
                } else if (templateID === priceConstants['default'].TemplateID.Banks) {
                    comp3OpAvtv = this.getValue('CF_CshOprAcv', this.get('displayCompareDataObjectCF3'));
                    comp3InvActv = this.getValue('CF_CshInvAcv', this.get('displayCompareDataObjectCF3'));
                    comp3FinActv = this.getValue('CF_CshFinAcv', this.get('displayCompareDataObjectCF3'));
                } else if (templateID === priceConstants['default'].TemplateID.Insurance) {
                    comp3OpAvtv = this.getValue('CF_CshOprAcv', this.get('displayCompareDataObjectCF3'));
                    comp3InvActv = this.getValue('CF_CshInvAcv', this.get('displayCompareDataObjectCF3'));
                    comp3FinActv = this.getValue('CF_CshFinAcv', this.get('displayCompareDataObjectCF3'));
                }
            }

            if (this.get('dataCompareObjectIS4') !== undefined) {
                this.filterDataValues(filterTo, filterTo, this.get('dataCompareObjectIS4'), this.get('displayCompareDataObjectIS4'));

                if (templateID === priceConstants['default'].TemplateID.Industrial) {
                    comp4NetInc = this.getValue('IS_NetInc', this.get('displayCompareDataObjectIS4'));
                    comp4TotalRev = this.getValue('IS_TotRvn', this.get('displayCompareDataObjectIS4'));
                    comp4GrossProf = this.getValue('IS_GrsPft', this.get('displayCompareDataObjectIS4'));
                } else if (templateID === priceConstants['default'].TemplateID.Banks) {
                    comp4NetInc = this.getValue('IS_NetInc', this.get('displayCompareDataObjectIS4'));
                    comp4TotalRev = this.getValue('IS_OprIncBnkrv', this.get('displayCompareDataObjectIS4'));
                    comp4GrossProf = this.getValue('IS_NETOPERINLO', this.get('displayCompareDataObjectIS4'));
                } else if (templateID === priceConstants['default'].TemplateID.Insurance) {
                    comp4NetInc = this.getValue('IS_NetInc', this.get('displayCompareDataObjectIS4'));
                    comp4TotalRev = this.getValue('IS_TotUWGRv', this.get('displayCompareDataObjectIS4'));
                    comp4GrossProf = this.getValue('IS_GLIOP', this.get('displayCompareDataObjectIS4'));
                }
            }

            if (this.get('dataCompareObjectBS4') !== undefined) {
                this.filterDataValues(filterTo, filterTo, this.get('dataCompareObjectBS4'), this.get('displayCompareDataObjectBS4'));

                if (templateID === priceConstants['default'].TemplateID.Industrial) {
                    comp4TotalAsset = this.getValue('BS_TotAst', this.get('displayCompareDataObjectBS4'));
                    comp4TotalEquity = this.getValue('BS_TOEAMINT', this.get('displayCompareDataObjectBS4'));
                    comp4TotalDeposits = this.getValue('BS_CshShoTerInv', this.get('displayCompareDataObjectBS4'));
                } else if (templateID === priceConstants['default'].TemplateID.Banks) {
                    comp4TotalAsset = this.getValue('BS_TotAst', this.get('displayCompareDataObjectBS4'));
                    comp4TotalEquity = this.getValue('BS_TOEAMINT', this.get('displayCompareDataObjectBS4'));
                    comp4TotalDeposits = this.getValue('BS_TotDps', this.get('displayCompareDataObjectBS4'));
                } else if (templateID === priceConstants['default'].TemplateID.Insurance) {
                    comp4TotalAsset = this.getValue('BS_TotAst', this.get('displayCompareDataObjectBS4'));
                    comp4TotalEquity = this.getValue('BS_TOEAMINT', this.get('displayCompareDataObjectBS4'));
                    comp4TotalDeposits = this.getValue('BS_CshEqv', this.get('displayCompareDataObjectBS4'));
                }
            }

            if (this.get('dataCompareObjectCF4') !== undefined) {
                this.filterDataValues(filterTo, filterTo, this.get('dataCompareObjectCF4'), this.get('displayCompareDataObjectCF4'));

                if (templateID === priceConstants['default'].TemplateID.Industrial) {
                    comp4OpAvtv = this.getValue('CF_CshOprAcv', this.get('displayCompareDataObjectCF4'));
                    comp4InvActv = this.getValue('CF_CshInvAcv', this.get('displayCompareDataObjectCF4'));
                    comp4FinActv = this.getValue('CF_CshFinAcv', this.get('displayCompareDataObjectCF4'));
                } else if (templateID === priceConstants['default'].TemplateID.Banks) {
                    comp4OpAvtv = this.getValue('CF_CshOprAcv', this.get('displayCompareDataObjectCF4'));
                    comp4InvActv = this.getValue('CF_CshInvAcv', this.get('displayCompareDataObjectCF4'));
                    comp4FinActv = this.getValue('CF_CshFinAcv', this.get('displayCompareDataObjectCF4'));
                } else if (templateID === priceConstants['default'].TemplateID.Insurance) {
                    comp4OpAvtv = this.getValue('CF_CshOprAcv', this.get('displayCompareDataObjectCF4'));
                    comp4InvActv = this.getValue('CF_CshInvAcv', this.get('displayCompareDataObjectCF4'));
                    comp4FinActv = this.getValue('CF_CshFinAcv', this.get('displayCompareDataObjectCF4'));
                }
            }

            this.set('dataSet1', ['TotalRevenue', baseTotalRev, comp1TotalRev, comp2TotalRev, comp3TotalRev, comp4TotalRev, true]);
            this.set('dataSet2', ['Gross Income or Loss', baseGrossProf, comp1GrossProf, comp2GrossProf, comp3GrossProf, comp4GrossProf, false]);
            this.set('dataSet3', ['Net Income', baseNetInc, comp1NetInc, comp2NetInc, comp3NetInc, comp4NetInc, false]);

            this.set('dataSet4', ['Total Assets', baseTotalAsset, comp1TotalAsset, comp2TotalAsset, comp3TotalAsset, comp4TotalAsset, false]);
            this.set('dataSet5', ['Total Equity', baseTotalEquity, comp1TotalEquity, comp2TotalEquity, comp3TotalEquity, comp4TotalEquity, false]);
            this.set('dataSet6', ['Total Deposits', baseTotalDeposits, comp1TotalDeposits, comp2TotalDeposits, comp3TotalDeposits, comp4TotalDeposits, false]);

            this.set('dataSet7', ['Operating Activities', baseOpAvtv, comp1OpAvtv, comp2OpAvtv, comp3OpAvtv, comp4OpAvtv, false]);
            this.set('dataSet8', ['Investing Activities', baseInvActv, comp1InvActv, comp2InvActv, comp3InvActv, comp4InvActv, false]);
            this.set('dataSet9', ['Financial Activities', baseFinActv, comp1FinActv, comp2FinActv, comp3FinActv, comp4FinActv, false]);

            this.set('xDataSet', [this.get('sym'), this.get('compareSym1Des'), this.get('compareSym2Des'), this.get('compareSym3Des'), this.get('compareSym4Des')]);
        },

        /**
         * To get filtered data list in between from date and the to date
         * and set in to the displayDataObject.
         * @param fromDate and toDate
         */

        filterDataValues: function filterDataValues(fromDate, toDate, dataObj, displayDataObj) {
            var key;
            var valueObject;
            var keySet = Object.keys(dataObj);
            keySet.reverse(); // to change the display format of the columns from left to right
            for (var j = 0; j < keySet.length; j++) {
                key = keySet[j];
                valueObject = dataObj[key];
                if (this._compareDate(fromDate, key, toDate)) {
                    displayDataObj[key] = valueObject;
                }
            }
        },

        /**
         * To get filtered style list. Iterate the filtered data list and get only the styles that having data for
         * all the fields and filter the styles according to the breakdown views.
         */

        filterStyleValue: function filterStyleValue() {
            var styleObject;
            var keySet = this.get('dataKeySet');
            this.set('displayStyleObject', Ember['default'].A([]));
            for (var j = 0; j < this.get('styleObject').length; j++) {
                styleObject = this.get('styleObject')[j];
                var dataObject;
                var key;
                for (var i = 0; i < keySet.length; i++) {
                    key = keySet[i];
                    dataObject = this.get('displayDataObject')[key];
                    if (dataObject[styleObject.filedID]) {
                        if (this.get('breakDown') === FundamentalConstants['default'].BreakDown.CollapsedView) {
                            if (styleObject.level === '1') {
                                this.get('displayStyleObject').pushObject(styleObject);
                            }
                        } else {
                            this.get('displayStyleObject').pushObject(styleObject);
                        }
                        break;
                    }
                }
            }
        },

        /**
         * This is a support function for filter data objects between two periods.
         * @param fromDate, date and toDate
         * @return {boolean}
         */

        _compareDate: function _compareDate(fromDate, date, toDate) {
            var splitFrom = fromDate.split('~');
            var splitDate = date.split('~');
            var splitTo = toDate.split('~');

            if (this.get('viewType') === FundamentalConstants['default'].ViewTypes.VIEW_TYPE_ANNUAL) {
                if (parseInt(splitDate[0], 10) >= parseInt(fromDate, 10) && splitDate[0].localeCompare(toDate) <= 0) {
                    return true;
                }
            } else {
                if (splitDate[0].concat(this._compareQuarter(splitDate[1])).localeCompare(splitFrom[0].concat(this._compareQuarter(splitFrom[1]))) >= 0 && splitDate[0].concat(this._compareQuarter(splitDate[1])).localeCompare(splitTo[0].concat(this._compareQuarter(splitTo[1]))) <= 0) {
                    return true;
                }
            }
            return false;
        },

        _compareQuarter: function _compareQuarter(quarter) {
            try {
                if ('5' === quarter[quarter.length - 1]) {
                    return '4';
                }
                return quarter[quarter.length - 1];
            } catch (e) {
                return quarter;
            }
        },

        getSearchSymbol: (function () {

            var selectedExgSym = this.get('selectedSymbol');
            var values = selectedExgSym.split('~');

            this.set('compareSym1Des', values[2]);
            this.set('compareSym1', values[1]);
            this.set('compareExg1', values[0]);
        }).observes('selectedSymbol'),

        getSearchSymbol2: (function () {

            var selectedExgSym = this.get('selectedSymbol2');
            var values = selectedExgSym.split('~');
            this.set('compareSym2Des', values[2]);
            this.set('compareSym2', values[1]);
            this.set('compareExg2', values[0]);
        }).observes('selectedSymbol2'),

        getSearchSymbol3: (function () {

            var selectedExgSym = this.get('selectedSymbol3');
            var values = selectedExgSym.split('~');
            this.set('compareSym3Des', values[2]);
            this.set('compareSym3', values[1]);
            this.set('compareExg3', values[0]);
        }).observes('selectedSymbol3'),

        getSearchSymbol4: (function () {

            var selectedExgSym = this.get('selectedSymbol4');
            var values = selectedExgSym.split('~');
            this.set('compareSym4Des', values[2]);
            this.set('compareSym4', values[1]);
            this.set('compareExg4', values[0]);
        }).observes('selectedSymbol4'),

        actions: {

            onSelectingView: function onSelectingView(item) {
                var keyListDate,
                    i,
                    j,
                    k = 4;
                var minMaxObj = this.get('minMaxObject');

                if (item.view === 1) {
                    keyListDate = [];
                    this.set('viewType', 'A');
                    for (i = parseInt(minMaxObj['MIN_CALENDER_YEAR'], 10); i <= parseInt(minMaxObj['MAX_CALENDER_YEAR'], 10) - 1; i++) {
                        // one subtracted from max calender year as we dont know when the BE will add the annual data to max year
                        keyListDate.push({ view: i + '~' + '5', des: i });
                    }
                    keyListDate.reverse(); // to invert the upper most value of the dropdown
                    this.set('viewDropdownListFromDate', keyListDate);
                    this.set('viewDropdownListToDate', keyListDate);
                    this.set('defSelectFrom', keyListDate[3]);
                    this.set('defSelectTo', keyListDate[0]);
                    this.set('filterFromDate', keyListDate[3].view);
                    this.set('filterToDate', keyListDate[0].view);
                } else if (item.view === 2) {
                    keyListDate = [];
                    this.set('viewType', 'Q');
                    for (i = parseInt(minMaxObj['MIN_CALENDER_YEAR'], 10); i <= parseInt(minMaxObj['MAX_CALENDER_YEAR'], 10); i++) {
                        for (j = 1; j <= k; j++) {
                            if (i === parseInt(minMaxObj['MIN_CALENDER_YEAR'], 10)) {
                                j = parseInt(minMaxObj['MIN_CAL_QUARTER_REQUESTED'], 10);
                            } else if (i === parseInt(minMaxObj['MAX_CALENDER_YEAR'], 10)) {
                                k = parseInt(minMaxObj['MAX_CAL_QUARTER_REQUESTED'], 10);
                            }

                            if (j === 1) {
                                keyListDate.push({ view: i + '~' + j, des: 'Q' + j + ' ' + i });
                            } else {
                                keyListDate.push({ view: i + '~' + '1' + j, des: 'Q' + j + ' ' + i });
                            }
                        }
                    }
                    keyListDate.reverse();
                    this.set('viewDropdownListFromDate', keyListDate);
                    this.set('viewDropdownListToDate', keyListDate);
                    this.set('defSelectFrom', keyListDate[3]);
                    this.set('defSelectTo', keyListDate[0]);
                    this.set('filterFromDate', keyListDate[3].view);
                    this.set('filterToDate', keyListDate[0].view);
                } else if (item.view === 3) {
                    keyListDate = [];
                    this.set('viewType', 'T');
                    for (i = parseInt(minMaxObj['MIN_CALENDER_YEAR'], 10); i <= parseInt(minMaxObj['MAX_CALENDER_YEAR'], 10); i++) {
                        for (j = 1; j <= k; j++) {
                            if (i === parseInt(minMaxObj['MIN_CALENDER_YEAR'], 10)) {
                                j = parseInt(minMaxObj['MIN_CAL_QUARTER_REQUESTED'], 10);
                            } else if (i === parseInt(minMaxObj['MAX_CALENDER_YEAR'], 10)) {
                                k = parseInt(minMaxObj['MAX_CAL_QUARTER_REQUESTED'], 10);
                            }
                            keyListDate.push({ view: i + '~' + '2' + j, des: 'Q' + j + ' ' + i });
                        }
                    }
                    keyListDate.reverse();
                    this.set('viewDropdownListFromDate', keyListDate);
                    this.set('viewDropdownListToDate', keyListDate);
                    this.set('defSelectFrom', keyListDate[3]);
                    this.set('defSelectTo', keyListDate[0]);
                    this.set('filterFromDate', keyListDate[3].view);
                    this.set('filterToDate', keyListDate[0].view);
                } else if (item.view === 4) {
                    keyListDate = [];
                    this.set('viewType', 'C');
                    for (i = parseInt(minMaxObj['MIN_CALENDER_YEAR'], 10); i <= parseInt(minMaxObj['MAX_CALENDER_YEAR'], 10); i++) {
                        for (j = 1; j <= k; j++) {
                            if (i === parseInt(minMaxObj['MIN_CALENDER_YEAR'], 10)) {
                                j = parseInt(minMaxObj['MIN_CAL_QUARTER_REQUESTED'], 10);
                            } else if (i === parseInt(minMaxObj['MAX_CALENDER_YEAR'], 10)) {
                                k = parseInt(minMaxObj['MAX_CAL_QUARTER_REQUESTED'], 10);
                            }

                            if (i === 4) {
                                keyListDate.push({ view: i + '~' + '5', des: 'Q' + j + ' ' + i });
                            } else {
                                keyListDate.push({ view: i + '~' + j, des: 'Q' + j + ' ' + i });
                            }
                        }
                    }
                    keyListDate.reverse();
                    this.set('viewDropdownListFromDate', keyListDate);
                    this.set('viewDropdownListToDate', keyListDate);
                    this.set('defSelectFrom', keyListDate[3]);
                    this.set('defSelectTo', keyListDate[0]);
                    this.set('filterFromDate', keyListDate[3].view);
                    this.set('filterToDate', keyListDate[0].view);
                }
            },

            onSelectingFromDate: function onSelectingFromDate(item) {
                this.set('filterFromDate', item.view);
            },

            onSelectingToDate: function onSelectingToDate(item) {
                this.set('filterToDate', item.view);
            },

            onClickView: function onClickView() {
                this.set('displayDataObject', Ember['default'].A([]));

                this.startLoadingProgress();
                this.set('reRender', true);
                this.sendDataRequest(this.get('exg'), this.get('sym'), this.get('viewType'));

                if (this.get('compareExg1') !== undefined && this.get('compareSym1')) {
                    this.sendDataRequest(this.get('compareExg1'), this.get('compareSym1'), this.get('viewType'));
                }
                if (this.get('compareExg2') !== undefined && this.get('compareSym2')) {
                    this.sendDataRequest(this.get('compareExg2'), this.get('compareSym2'), this.get('viewType'));
                }
                if (this.get('compareExg3') !== undefined && this.get('compareSym3')) {
                    this.sendDataRequest(this.get('compareExg3'), this.get('compareSym3'), this.get('viewType'));
                }
                if (this.get('compareExg4') !== undefined && this.get('compareSym4')) {
                    this.sendDataRequest(this.get('compareExg4'), this.get('compareSym4'), this.get('viewType'));
                }
                this.set('viewTypeForHelper', this.get('viewType'));
            },

            onSymbolSelected: function onSymbolSelected(item) {
                var that = this;
                var exchange;
                if (item.exg) {
                    exchange = item.exg;
                } else {
                    exchange = this.get('exg');
                }
                var key = exchange + '~' + item.sym + '~' + item.lDes;

                var successFn = function successFn() {
                    that.set('selectedSymbol', key);
                    that.set('searchSymbol', item.lDes);
                };
                var errorFn = function errorFn() {};

                priceService['default'].downloadExchangeProperty(exchange, sharedService['default'].userSettings.currentLanguage, successFn, errorFn);
            },
            showSearchPopup: function showSearchPopup() {
                var modal = this.get('symbolSearch');
                modal.send('showModalPopup');
            },
            closeSearchPopup: function closeSearchPopup() {
                var modal = this.get('symbolSearch');
                modal.send('closeModalPopup');
            },

            onSymbol2Selected: function onSymbol2Selected(item) {
                var that = this;
                var exchange;
                if (item.exg) {
                    exchange = item.exg;
                } else {
                    exchange = this.get('exg');
                }
                var key = exchange + '~' + item.sym + '~' + item.lDes;

                var successFn = function successFn() {
                    that.set('selectedSymbol2', key);
                    that.set('searchSymbol2', item.lDes);
                };

                var errorFn = function errorFn() {};

                priceService['default'].downloadExchangeProperty(exchange, sharedService['default'].userSettings.currentLanguage, successFn, errorFn);
            },
            showSearchPopup2: function showSearchPopup2() {
                var modal = this.get('symbolSearch2');
                modal.send('showModalPopup');
            },
            closeSearchPopup2: function closeSearchPopup2() {
                var modal = this.get('symbolSearch2');
                modal.send('closeModalPopup');
            },

            onSymbol3Selected: function onSymbol3Selected(item) {
                var that = this;
                var exchange;
                if (item.exg) {
                    exchange = item.exg;
                } else {
                    exchange = this.get('exg');
                }
                var key = exchange + '~' + item.sym + '~' + item.lDes;

                var successFn = function successFn() {
                    that.set('selectedSymbol3', key);
                    that.set('searchSymbol3', item.lDes);
                };

                var errorFn = function errorFn() {};

                priceService['default'].downloadExchangeProperty(exchange, sharedService['default'].userSettings.currentLanguage, successFn, errorFn);
            },
            showSearchPopup3: function showSearchPopup3() {
                var modal = this.get('symbolSearch3');
                modal.send('showModalPopup');
            },
            closeSearchPopup3: function closeSearchPopup3() {
                var modal = this.get('symbolSearch3');
                modal.send('closeModalPopup');
            },

            onSymbol4Selected: function onSymbol4Selected(item) {
                var that = this;
                var exchange;
                if (item.exg) {
                    exchange = item.exg;
                } else {
                    exchange = this.get('exg');
                }
                var key = exchange + '~' + item.sym + '~' + item.lDes;

                var successFn = function successFn() {
                    that.set('selectedSymbol4', key);
                    that.set('searchSymbol4', item.lDes);
                };

                var errorFn = function errorFn() {};

                priceService['default'].downloadExchangeProperty(exchange, sharedService['default'].userSettings.currentLanguage, successFn, errorFn);
            },
            showSearchPopup4: function showSearchPopup4() {
                var modal = this.get('symbolSearch4');
                modal.send('showModalPopup');
            },
            closeSearchPopup4: function closeSearchPopup4() {
                var modal = this.get('symbolSearch4');
                modal.send('closeModalPopup');
            },
            clear1: function clear1() {
                this.set('searchSymbol', '');
                this.set('dataCompareObjectIS1', undefined);
                this.set('dataCompareObjectBS1', undefined);
                this.set('dataCompareObjectCF1', undefined);
                this.set('dataCompareObjectMR1', undefined);
                this.set('compareExg1', undefined);
                this.set('compareSym1', undefined);
                this.set('isComp1StockAvailable', false);
            },
            clear2: function clear2() {
                this.set('searchSymbol2', '');
                this.set('dataCompareObjectIS2', undefined);
                this.set('dataCompareObjectBS2', undefined);
                this.set('dataCompareObjectCF2', undefined);
                this.set('dataCompareObjectMR2', undefined);
                this.set('compareExg2', undefined);
                this.set('compareSym2', undefined);
                this.set('isComp2StockAvailable', false);
            },
            clear3: function clear3() {
                this.set('searchSymbol3', '');
                this.set('dataCompareObjectIS3', undefined);
                this.set('dataCompareObjectBS3', undefined);
                this.set('dataCompareObjectCF3', undefined);
                this.set('dataCompareObjectMR3', undefined);
                this.set('compareExg3', undefined);
                this.set('compareSym3', undefined);
                this.set('isComp3StockAvailable', false);
            },

            clear4: function clear4() {
                this.set('searchSymbol4', '');
                this.set('dataCompareObjectIS4', undefined);
                this.set('dataCompareObjectBS4', undefined);
                this.set('dataCompareObjectCF4', undefined);
                this.set('dataCompareObjectMR4', undefined);
                this.set('compareExg4', undefined);
                this.set('compareSym4', undefined);
                this.set('isComp4StockAvailable', false);
            },
            onClickReset: function onClickReset() {

                this.set('searchSymbol', '');
                this.set('searchSymbol2', '');
                this.set('searchSymbol3', '');
                this.set('searchSymbol4', '');

                this.set('dataCompareObjectIS1', undefined);
                this.set('dataCompareObjectIS2', undefined);
                this.set('dataCompareObjectIS3', undefined);
                this.set('dataCompareObjectIS4', undefined);

                this.set('dataCompareObjectBS1', undefined);
                this.set('dataCompareObjectBS2', undefined);
                this.set('dataCompareObjectBS3', undefined);
                this.set('dataCompareObjectBS4', undefined);

                this.set('dataCompareObjectCF1', undefined);
                this.set('dataCompareObjectCF2', undefined);
                this.set('dataCompareObjectCF3', undefined);
                this.set('dataCompareObjectCF4', undefined);

                this.set('compareExg1', undefined);
                this.set('compareExg2', undefined);
                this.set('compareExg3', undefined);
                this.set('compareExg4', undefined);

                this.set('compareSym1', undefined);
                this.set('compareSym2', undefined);
                this.set('compareSym3', undefined);
                this.set('compareSym4', undefined);

                this.set('isComp1StockAvailable', false);
                this.set('isComp2StockAvailable', false);
                this.set('isComp3StockAvailable', false);
                this.set('isComp4StockAvailable', false);

                this.startLoadingProgress();
                this.set('reRender', true);
                this.sendDataRequest(this.get('exg'), this.get('sym'), this.get('viewType'));

                this.set('viewTypeForHelper', this.get('viewType'));
            }
        }
    });

    Ember['default'].Handlebars.helper('financial-chart-comparison', FinancialChart['default']);
    Ember['default'].Handlebars.helper('financial-statement-comparison', FinancialStatement['default']);

});