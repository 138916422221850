define('ir-app/controllers/ir-mobile/news-announcement-widget/news-announcement', ['exports', 'ember', 'ir-app/models/price/price-service', 'ir-app/models/price/price-constants', 'ir-app/controllers/base-array-controller', 'ir-app/models/shared/shared-service'], function (exports, Ember, priceService, PriceConstant, BaseArrayController, sharedService) {

    'use strict';

    /**
     * Created by lakmaln on 2/25/2016.
     */

    exports['default'] = BaseArrayController['default'].extend({

        annObj: null,
        selectedTab: 1,
        theFilter: PriceConstant['default'].ResponseType.Data.ResponseAnnouncement,
        slideDirection: 'from-right',

        // TODO:[Banusha] Rename below into a meaningful name
        minifiedVersion: false,
        portalMinifiedVersion: false,
        minifiedSliceNumber: 3,
        stopLoadingAnnouncement: false,
        stopLoadingNews: false,
        pageSize: PriceConstant['default'].AnnouncementSearchPageSize,

        Tabs: {
            Announcements: 1,
            News: 2
        },

        onLoadWidget: function onLoadWidget() {
            this.setErrorMessage();
            this._setSlideDirection();
        },

        onPrepareData: function onPrepareData() {
            var exchange = this.get('exg');
            var symbol = this.get('sym');

            this.startLoadingProgress();
            this.set('model', priceService['default'].announcementDS.getAnnCollectionBySymbol(exchange, symbol));
            var that = this;

            var successFn = function successFn() {
                priceService['default'].sendAnnouncementSearchRequest({
                    exchange: exchange,
                    symbol: symbol,
                    pageSize: that.get('pageSize'),

                    reqSuccessFn: function reqSuccessFn() {
                        that.set('stopLoadingAnnouncement', true);
                        that.onDataSuccess();
                    },

                    reqFailureFn: function reqFailureFn() {
                        that.set('stopLoadingAnnouncement', true);
                        that.onDataError();
                    }
                }, priceService['default'].announcementDS.getAnnCollectionBySymbol(exchange, symbol));

                priceService['default'].sendNewsSearchRequest({
                    exchange: exchange,
                    symbol: symbol,
                    pageSize: PriceConstant['default'].NewsSearchPageSize,

                    reqSuccessFn: function reqSuccessFn() {
                        that.set('stopLoadingNews', true);
                        that.onDataSuccess();
                    },

                    reqFailureFn: function reqFailureFn() {
                        that.set('stopLoadingNews', true);
                        that.onDataError();
                    }
                }, priceService['default'].announcementDS.getAnnCollectionBySymbol(exchange, symbol));
            };

            var errorFn = function errorFn() {};

            priceService['default'].exchangeDS.getExchange(exchange);
            priceService['default'].downloadExchangeProperty(exchange, sharedService['default'].userSettings.currentLanguage, successFn, errorFn);
        },

        onCheckDataAvailability: function onCheckDataAvailability() {
            return this.get('filteredContent').length > 0;
        },

        startLoadingProgress: function startLoadingProgress() {
            if (this.get('selectedTab') === 1 && !this.get('stopLoadingAnnouncement') || this.get('selectedTab') === 2 && !this.get('stopLoadingNews')) {
                this.set('isDataAvailable', false);
                this.hideDataErrorMessage();
                this.set('isLoading', true);
            }
        },

        stopLoadingProgress: (function () {
            if (this.get('selectedTab') === 1 && this.get('stopLoadingAnnouncement') || this.get('selectedTab') === 2 && this.get('stopLoadingNews')) {
                this.set('isLoading', false);
            }
        }).observes('selectedTab'),

        onUnloadWidget: function onUnloadWidget() {
            priceService['default'].announcementDS.removeAnnCollectionBySymbol(this.get('exg'), this.get('sym'));
        },

        onClearData: function onClearData() {
            this._reset();
        },

        onLanguageChanged: function onLanguageChanged() {
            this._setSlideDirection();
        },

        _setSlideDirection: function _setSlideDirection() {
            if (sharedService['default'].userSettings.currentLanguage === 'AR') {
                this.set('slideDirection', 'from-left');
            } else {
                this.set('slideDirection', 'from-right');
            }
        },

        _onTabChange: function _onTabChange(tabId) {
            this.startLoadingProgress();
            this.set('selectedTab', tabId);

            if (this.get('selectedTab') === this.Tabs.Announcements) {
                this.set('theFilter', PriceConstant['default'].ResponseType.Data.ResponseAnnouncement);
            } else {
                this.set('theFilter', PriceConstant['default'].ResponseType.Data.ResponseNews);
            }
        },

        _reset: function _reset() {
            this.set('model', Ember['default'].A());
        },

        _setErrorMessage: (function () {
            if (this.get('filteredContent').length > 0) {
                this.set('showError', false);
            } else {
                this.set('showError', true);
            }
        }).observes('filteredContent'),

        filteredContent: (function () {
            return this.get('sortedContent').filter((function (that) {
                //eslint-disable-line
                return function (theObject) {

                    if (that.get('theFilter')) {
                        return that.checkFilterMatch(theObject, that.get('theFilter'));
                    } else {
                        return true;
                    }
                };
            })(this));
        }).property('theFilter', 'sortedContent.@each'),

        filteredSliceContent: (function () {
            var that = this;
            if (this.get('filteredContent').length > that.minifiedSliceNumber) {
                return this.get('filteredContent').slice(0, that.minifiedSliceNumber);
            } else {
                return this.get('filteredContent');
            }
        }).property('filteredContent'),

        displayContent: (function () {
            this.sortAnnouncements();
            if (this.get('minifiedVersion') || this.get('portalMinifiedVersion')) {
                return this.get('filteredSliceContent');
            } else {
                return this.get('filteredContent');
            }
        }).property('filteredContent'),

        sortAnnouncements: function sortAnnouncements() {
            var that = this;
            if (that.get('filteredContent').length > 0) {
                that.get('filteredContent').sort(that.compare);
            }
        },

        compare: function compare(a, b) {
            if (a.dt > b.dt) {
                return -1;
            }
            if (a.dt < b.dt) {
                return 1;
            }
            return 0;
        },

        sortedContent: (function () {
            return this.get('arrangedContent');
        }).property('arrangedContent'),

        checkFilterMatch: function checkFilterMatch(theObject, typeFilter) {
            var checkTypeFilter = typeFilter !== -1 ? true : false;
            var isMatchedTypeFilter = true;

            if (checkTypeFilter && theObject.get('type')) {
                isMatchedTypeFilter = theObject.get('type') === typeFilter;
            }
            return isMatchedTypeFilter;
        },

        actions: {
            onTabSelected: function onTabSelected(tabId) {
                this._onTabChange(tabId);
            },

            onItemClicked: function onItemClicked(annId) {
                var annObj = priceService['default'].announcementDS.getAnnouncement(annId);
                var that = this;

                this.set('annObj', annObj);

                if (!annObj.get('isBodyUpdated')) {
                    that.annObj.set('bod', that.get('app').lang.messages.loading);

                    priceService['default'].sendNewsAnnBodyRequest({
                        id: annObj.get('id'),
                        type: annObj.get('type'),
                        reqSuccessFn: function reqSuccessFn() {},
                        reqFailureFn: function reqFailureFn() {
                            that.annObj.set('bod', 'No Data Found');
                            that.annObj.set('isBodyUpdated', false);
                        },
                        lan: annObj.get('ln')
                    });
                }
            }
        }
    });

});