define('ir-app/controllers/ir-widgets/tab-layouts/index-overview-layout', ['exports', 'ir-app/controllers/base-controller'], function (exports, BaseController) {

    'use strict';

    /**
     * Created by lakmaln on 5/31/2016.
     */

    exports['default'] = BaseController['default'].extend({

        init: function init() {
            this.setValueToBaseSymbolContainer('showBaseTitle', true);
        }

    });

});