define('ir-app/templates/ir-mobile/chart-widgets/old-chart-widget', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1,"role","presentation");
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2,"role","tab");
            dom.setAttribute(el2,"data-toggle","tab");
            dom.setAttribute(el2,"style","background-color: transparent; color: white");
            dom.setAttribute(el2,"class","font-large");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, concat = hooks.concat, attribute = hooks.attribute, element = hooks.element, content = hooks.content;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element32 = dom.childAt(fragment, [1, 1]);
            var morph0 = dom.createMorphAt(element32,0,0);
            var attrMorph0 = dom.createAttrMorph(element32, 'href');
            attribute(env, attrMorph0, element32, "href", concat(env, [get(env, context, "tabItem.ID")]));
            element(env, element32, context, "action", ["setChartPeriod", get(env, context, "tabItem")], {});
            content(env, morph0, context, "tabItem.DisplayName");
            return fragment;
          }
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            isHTMLBars: true,
            revision: "Ember@1.11.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var morph0 = dom.createMorphAt(fragment,1,1,contextualElement);
              inline(env, morph0, context, "global-search", [], {"enableContentSettings": false, "searchKey": get(env, context, "searchSymbol"), "closePopup": "closeSearchPopup", "openPopup": "showSearchPopup", "clickAction": "onSymbolSelected", "stopGlobalNotification": true, "exchangeList": get(env, context, "portalExchangeList"), "wkey": get(env, context, "wkey")});
              return fragment;
            }
          };
        }());
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("ul");
            dom.setAttribute(el1,"class","symbol-comparison");
            var el2 = dom.createTextNode("\n                                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            dom.setAttribute(el2,"class","full-width");
            var el3 = dom.createTextNode("\n                                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"id","symbolSearchKey");
            dom.setAttribute(el3,"class","input-group full-width");
            var el4 = dom.createTextNode("\n                                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            dom.setAttribute(el2,"class","full-width");
            var el3 = dom.createTextNode("\n                                        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","modal-symbol");
            dom.setAttribute(el3,"style","display:table-cell; position: absolute;");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                                        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, inline = hooks.inline, block = hooks.block;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element31 = dom.childAt(fragment, [1]);
            var morph0 = dom.createMorphAt(dom.childAt(element31, [1, 1]),1,1);
            var morph1 = dom.createMorphAt(dom.childAt(element31, [3, 1]),1,1);
            inline(env, morph0, context, "input", [], {"type": "text", "value": get(env, context, "searchSymbol"), "key-down": "showSearchPopup", "placeholder": get(env, context, "placeHolder"), "class": "full-width p-relative search-field"});
            block(env, morph1, context, "modal-popup", [], {"isEnabled": false, "id": "symbolSearch"}, child0, null);
            return fragment;
          }
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                isHTMLBars: true,
                revision: "Ember@1.11.1",
                blockParams: 0,
                cachedFragment: null,
                hasRendered: false,
                build: function build(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                                                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("li");
                  var el2 = dom.createElement("a");
                  dom.setAttribute(el2,"href","#");
                  dom.setAttribute(el2,"class","checked ltr-text");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                                                    ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                render: function render(context, env, contextualElement) {
                  var dom = env.dom;
                  var hooks = env.hooks, get = hooks.get, element = hooks.element, content = hooks.content;
                  dom.detectNamespace(contextualElement);
                  var fragment;
                  if (env.useFragmentCache && dom.canClone) {
                    if (this.cachedFragment === null) {
                      fragment = this.build(dom);
                      if (this.hasRendered) {
                        this.cachedFragment = fragment;
                      } else {
                        this.hasRendered = true;
                      }
                    }
                    if (this.cachedFragment) {
                      fragment = dom.cloneNode(this.cachedFragment, true);
                    }
                  } else {
                    fragment = this.build(dom);
                  }
                  var element24 = dom.childAt(fragment, [1, 0]);
                  var morph0 = dom.createMorphAt(element24,0,0);
                  element(env, element24, context, "action", ["setIndicator", get(env, context, "item")], {});
                  content(env, morph0, context, "item.DisplayName");
                  return fragment;
                }
              };
            }());
            var child1 = (function() {
              return {
                isHTMLBars: true,
                revision: "Ember@1.11.1",
                blockParams: 0,
                cachedFragment: null,
                hasRendered: false,
                build: function build(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                                                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("li");
                  var el2 = dom.createElement("a");
                  dom.setAttribute(el2,"href","#");
                  dom.setAttribute(el2,"class","ltr-text");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                                                    ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                render: function render(context, env, contextualElement) {
                  var dom = env.dom;
                  var hooks = env.hooks, get = hooks.get, element = hooks.element, content = hooks.content;
                  dom.detectNamespace(contextualElement);
                  var fragment;
                  if (env.useFragmentCache && dom.canClone) {
                    if (this.cachedFragment === null) {
                      fragment = this.build(dom);
                      if (this.hasRendered) {
                        this.cachedFragment = fragment;
                      } else {
                        this.hasRendered = true;
                      }
                    }
                    if (this.cachedFragment) {
                      fragment = dom.cloneNode(this.cachedFragment, true);
                    }
                  } else {
                    fragment = this.build(dom);
                  }
                  var element23 = dom.childAt(fragment, [1, 0]);
                  var morph0 = dom.createMorphAt(element23,0,0);
                  element(env, element23, context, "action", ["setIndicator", get(env, context, "item")], {});
                  content(env, morph0, context, "item.DisplayName");
                  return fragment;
                }
              };
            }());
            return {
              isHTMLBars: true,
              revision: "Ember@1.11.1",
              blockParams: 0,
              cachedFragment: null,
              hasRendered: false,
              build: function build(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              render: function render(context, env, contextualElement) {
                var dom = env.dom;
                var hooks = env.hooks, get = hooks.get, block = hooks.block;
                dom.detectNamespace(contextualElement);
                var fragment;
                if (env.useFragmentCache && dom.canClone) {
                  if (this.cachedFragment === null) {
                    fragment = this.build(dom);
                    if (this.hasRendered) {
                      this.cachedFragment = fragment;
                    } else {
                      this.hasRendered = true;
                    }
                  }
                  if (this.cachedFragment) {
                    fragment = dom.cloneNode(this.cachedFragment, true);
                  }
                } else {
                  fragment = this.build(dom);
                }
                var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, null);
                dom.insertBoundary(fragment, 0);
                block(env, morph0, context, "if", [get(env, context, "item.IsAdded")], {}, child0, child1);
                return fragment;
              }
            };
          }());
          var child1 = (function() {
            return {
              isHTMLBars: true,
              revision: "Ember@1.11.1",
              blockParams: 0,
              cachedFragment: null,
              hasRendered: false,
              build: function build(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                                                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                var el2 = dom.createElement("a");
                dom.setAttribute(el2,"href","#");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                                                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              render: function render(context, env, contextualElement) {
                var dom = env.dom;
                var hooks = env.hooks, get = hooks.get, element = hooks.element, content = hooks.content;
                dom.detectNamespace(contextualElement);
                var fragment;
                if (env.useFragmentCache && dom.canClone) {
                  if (this.cachedFragment === null) {
                    fragment = this.build(dom);
                    if (this.hasRendered) {
                      this.cachedFragment = fragment;
                    } else {
                      this.hasRendered = true;
                    }
                  }
                  if (this.cachedFragment) {
                    fragment = dom.cloneNode(this.cachedFragment, true);
                  }
                } else {
                  fragment = this.build(dom);
                }
                var element22 = dom.childAt(fragment, [1, 0]);
                var morph0 = dom.createMorphAt(element22,0,0);
                element(env, element22, context, "action", ["setChartStyle", get(env, context, "item")], {});
                content(env, morph0, context, "item.DisplayName");
                return fragment;
              }
            };
          }());
          return {
            isHTMLBars: true,
            revision: "Ember@1.11.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              dom.setAttribute(el1,"class","chart-fig-btn text-align-center");
              var el2 = dom.createTextNode("\n                                                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","dropdown padding-small-right");
              var el3 = dom.createTextNode("\n                                                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("button");
              dom.setAttribute(el3,"class","btn btn-default dropdown-toggle border-none");
              dom.setAttribute(el3,"type","button");
              dom.setAttribute(el3,"id","indicatorDropDown");
              dom.setAttribute(el3,"data-toggle","dropdown");
              dom.setAttribute(el3,"aria-haspopup","true");
              dom.setAttribute(el3,"aria-expanded","true");
              var el4 = dom.createTextNode("\n                                                            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                                            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"class","caret");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                                        ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("ul");
              dom.setAttribute(el3,"class","indicator-menu dropdown-menu y-scroll");
              dom.setAttribute(el3,"aria-labelledby","dropdownMenu1");
              dom.setAttribute(el3,"id","dropDwnIndicatorId");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("                                                        ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              dom.setAttribute(el1,"class","chart-fig-btn");
              var el2 = dom.createTextNode("\n                                                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","dropdown padding-small-right");
              var el3 = dom.createTextNode("\n                                                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("button");
              dom.setAttribute(el3,"class","btn btn-default dropdown-toggle border-none");
              dom.setAttribute(el3,"type","button");
              dom.setAttribute(el3,"id","areaDropDown");
              dom.setAttribute(el3,"data-toggle","dropdown");
              dom.setAttribute(el3,"aria-haspopup","true");
              dom.setAttribute(el3,"aria-expanded","true");
              var el4 = dom.createTextNode("\n                                                            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                                            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"class","caret");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                                        ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("ul");
              dom.setAttribute(el3,"class","dropdown-menu y-scroll");
              dom.setAttribute(el3,"aria-labelledby","dropdownMenu1");
              dom.setAttribute(el3,"id","dropDwnArea");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("                                                        ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks, get = hooks.get, concat = hooks.concat, attribute = hooks.attribute, content = hooks.content, block = hooks.block;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var element25 = dom.childAt(fragment, [1, 1]);
              var element26 = dom.childAt(element25, [1]);
              var element27 = dom.childAt(fragment, [3, 1]);
              var element28 = dom.childAt(element27, [1]);
              var morph0 = dom.createMorphAt(element26,1,1);
              var attrMorph0 = dom.createAttrMorph(element26, 'title');
              var morph1 = dom.createMorphAt(dom.childAt(element25, [3]),1,1);
              var morph2 = dom.createMorphAt(element28,1,1);
              var attrMorph1 = dom.createAttrMorph(element28, 'title');
              var morph3 = dom.createMorphAt(dom.childAt(element27, [3]),1,1);
              attribute(env, attrMorph0, element26, "title", concat(env, [get(env, context, "app.lang.labels.selectInd")]));
              content(env, morph0, context, "app.lang.labels.selectInd");
              block(env, morph1, context, "each", [get(env, context, "getDisplayIndicators")], {"keyword": "item"}, child0, null);
              attribute(env, attrMorph1, element28, "title", concat(env, [get(env, context, "app.lang.labels.chartStyleTitle")]));
              content(env, morph2, context, "chartStyle.DisplayName");
              block(env, morph3, context, "each", [get(env, context, "chartStyles")], {"keyword": "item"}, child1, null);
              return fragment;
            }
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              isHTMLBars: true,
              revision: "Ember@1.11.1",
              blockParams: 0,
              cachedFragment: null,
              hasRendered: false,
              build: function build(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                                                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                var el2 = dom.createElement("a");
                dom.setAttribute(el2,"href","#");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                                                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              render: function render(context, env, contextualElement) {
                var dom = env.dom;
                var hooks = env.hooks, get = hooks.get, element = hooks.element, content = hooks.content;
                dom.detectNamespace(contextualElement);
                var fragment;
                if (env.useFragmentCache && dom.canClone) {
                  if (this.cachedFragment === null) {
                    fragment = this.build(dom);
                    if (this.hasRendered) {
                      this.cachedFragment = fragment;
                    } else {
                      this.hasRendered = true;
                    }
                  }
                  if (this.cachedFragment) {
                    fragment = dom.cloneNode(this.cachedFragment, true);
                  }
                } else {
                  fragment = this.build(dom);
                }
                var element19 = dom.childAt(fragment, [1, 0]);
                var morph0 = dom.createMorphAt(element19,0,0);
                element(env, element19, context, "action", ["setChartInterval", get(env, context, "item")], {});
                content(env, morph0, context, "item.DisplayName");
                return fragment;
              }
            };
          }());
          return {
            isHTMLBars: true,
            revision: "Ember@1.11.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              dom.setAttribute(el1,"class","chart-fig-btn interval-dropdown");
              var el2 = dom.createTextNode("\n                                                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","dropdown");
              var el3 = dom.createTextNode("\n                                                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("button");
              dom.setAttribute(el3,"class","btn btn-default dropdown-toggle border-none single-line-text");
              dom.setAttribute(el3,"type","button");
              dom.setAttribute(el3,"id","minutesDropDown");
              dom.setAttribute(el3,"data-toggle","dropdown");
              dom.setAttribute(el3,"aria-haspopup","true");
              dom.setAttribute(el3,"aria-expanded","true");
              var el4 = dom.createTextNode("\n                                                            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                                            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"class","caret");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                                        ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("ul");
              dom.setAttribute(el3,"class","dropdown-menu y-scroll");
              dom.setAttribute(el3,"aria-labelledby","dropdownMenu1");
              dom.setAttribute(el3,"id","dropInterval");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("                                                        ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks, get = hooks.get, concat = hooks.concat, attribute = hooks.attribute, content = hooks.content, block = hooks.block;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var element20 = dom.childAt(fragment, [1, 1]);
              var element21 = dom.childAt(element20, [1]);
              var morph0 = dom.createMorphAt(element21,1,1);
              var attrMorph0 = dom.createAttrMorph(element21, 'title');
              var morph1 = dom.createMorphAt(dom.childAt(element20, [3]),1,1);
              attribute(env, attrMorph0, element21, "title", concat(env, [get(env, context, "app.lang.labels.chartInterval")]));
              content(env, morph0, context, "chartInterval.DisplayName");
              block(env, morph1, context, "each", [get(env, context, "getChartInterval")], {"keyword": "item"}, child0, null);
              return fragment;
            }
          };
        }());
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","row");
            var el2 = dom.createTextNode("\n                                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","col-xs-12");
            var el3 = dom.createTextNode("\n                                        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("ul");
            dom.setAttribute(el3,"class","horizontal-list-left chart-btns history-fields");
            var el4 = dom.createTextNode("\n                                            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("li");
            dom.setAttribute(el4,"class","chart-fig-btn");
            var el5 = dom.createTextNode("\n                                                ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","dropdown padding-small-right");
            var el6 = dom.createTextNode("\n                                                    ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("button");
            dom.setAttribute(el6,"value","Volume");
            dom.setAttribute(el6,"id","volume-btn");
            dom.setAttribute(el6,"type","button");
            dom.setAttribute(el6,"onclick","changeClass(this)");
            var el7 = dom.createTextNode("\n                                                        ");
            dom.appendChild(el6, el7);
            var el7 = dom.createElement("span");
            var el8 = dom.createComment("");
            dom.appendChild(el7, el8);
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n                                                    ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                                                ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                                            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                                            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("li");
            dom.setAttribute(el4,"class","from-date");
            var el5 = dom.createTextNode("\n                                                ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","chart-date");
            var el6 = dom.createTextNode("\n                                                    ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("div");
            dom.setAttribute(el6,"class","form-group has-feedback");
            var el7 = dom.createTextNode("\n                                                        ");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n                                                        ");
            dom.appendChild(el6, el7);
            var el7 = dom.createElement("i");
            dom.setAttribute(el7,"class","fa fa-calendar form-control-feedback");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n                                                    ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                                                ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                                            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                                            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("li");
            dom.setAttribute(el4,"class","to-date");
            var el5 = dom.createTextNode("\n                                                ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","chart-date");
            var el6 = dom.createTextNode("\n                                                    ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("div");
            dom.setAttribute(el6,"class","form-group has-feedback");
            var el7 = dom.createTextNode("\n                                                        ");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n                                                        ");
            dom.appendChild(el6, el7);
            var el7 = dom.createElement("i");
            dom.setAttribute(el7,"class","fa fa-calendar form-control-feedback");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n                                                    ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                                                ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                                            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n\n                                        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("<div class=\"col-sm-4 col-xs-12\">\n                                        <div class=\"row\">\n\n                                        </div>\n                                    </div>");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, concat = hooks.concat, attribute = hooks.attribute, subexpr = hooks.subexpr, element = hooks.element, content = hooks.content, block = hooks.block, inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element29 = dom.childAt(fragment, [1, 1, 1]);
            var element30 = dom.childAt(element29, [1, 1, 1]);
            var attrMorph0 = dom.createAttrMorph(element30, 'title');
            var attrMorph1 = dom.createAttrMorph(element30, 'class');
            var morph0 = dom.createMorphAt(dom.childAt(element30, [1]),0,0);
            var morph1 = dom.createMorphAt(element29,3,3);
            var morph2 = dom.createMorphAt(element29,5,5);
            var morph3 = dom.createMorphAt(dom.childAt(element29, [7, 1, 1]),1,1);
            var morph4 = dom.createMorphAt(dom.childAt(element29, [9, 1, 1]),1,1);
            attribute(env, attrMorph0, element30, "title", concat(env, [get(env, context, "app.lang.labels.chartShowVol")]));
            attribute(env, attrMorph1, element30, "class", subexpr(env, context, "volumeEnabled", [get(env, context, "volumeViewEnabled")], {}));
            element(env, element30, context, "action", ["onToggleVolume"], {});
            content(env, morph0, context, "app.lang.labels.volume");
            block(env, morph1, context, "unless", [get(env, context, "compareSymbolMode")], {}, child0, null);
            block(env, morph2, context, "if", [get(env, context, "chartIntervalEnabled")], {}, child1, null);
            inline(env, morph3, context, "bootstrap-datepicker", [], {"readonly": "readonly", "language": get(env, context, "lan"), "placeholder": get(env, context, "app.lang.labels.from"), "class": "form-control date-picker", "value": get(env, context, "fromDate"), "startDate": get(env, context, "oldestDate"), "endDate": "+0d", "autoclose": true, "todayHighlight": true});
            inline(env, morph4, context, "bootstrap-datepicker", [], {"readonly": "readonly", "language": get(env, context, "lan"), "placeholder": get(env, context, "app.lang.labels.to"), "class": "form-control date-picker", "value": get(env, context, "toDate"), "autoclose": true, "startDate": get(env, context, "fromDate"), "endDate": "+0d", "todayHighlight": true, "orientation": "right"});
            return fragment;
          }
        };
      }());
      var child3 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                isHTMLBars: true,
                revision: "Ember@1.11.1",
                blockParams: 0,
                cachedFragment: null,
                hasRendered: false,
                build: function build(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                                            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("li");
                  var el2 = dom.createElement("a");
                  dom.setAttribute(el2,"href","#");
                  dom.setAttribute(el2,"class","checked ltr-text");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                                            ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                render: function render(context, env, contextualElement) {
                  var dom = env.dom;
                  var hooks = env.hooks, get = hooks.get, element = hooks.element, content = hooks.content;
                  dom.detectNamespace(contextualElement);
                  var fragment;
                  if (env.useFragmentCache && dom.canClone) {
                    if (this.cachedFragment === null) {
                      fragment = this.build(dom);
                      if (this.hasRendered) {
                        this.cachedFragment = fragment;
                      } else {
                        this.hasRendered = true;
                      }
                    }
                    if (this.cachedFragment) {
                      fragment = dom.cloneNode(this.cachedFragment, true);
                    }
                  } else {
                    fragment = this.build(dom);
                  }
                  var element12 = dom.childAt(fragment, [1, 0]);
                  var morph0 = dom.createMorphAt(element12,0,0);
                  element(env, element12, context, "action", ["setIndicator", get(env, context, "item")], {});
                  content(env, morph0, context, "item.DisplayName");
                  return fragment;
                }
              };
            }());
            var child1 = (function() {
              return {
                isHTMLBars: true,
                revision: "Ember@1.11.1",
                blockParams: 0,
                cachedFragment: null,
                hasRendered: false,
                build: function build(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                                            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("li");
                  var el2 = dom.createElement("a");
                  dom.setAttribute(el2,"href","#");
                  dom.setAttribute(el2,"class","ltr-text");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                                            ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                render: function render(context, env, contextualElement) {
                  var dom = env.dom;
                  var hooks = env.hooks, get = hooks.get, element = hooks.element, content = hooks.content;
                  dom.detectNamespace(contextualElement);
                  var fragment;
                  if (env.useFragmentCache && dom.canClone) {
                    if (this.cachedFragment === null) {
                      fragment = this.build(dom);
                      if (this.hasRendered) {
                        this.cachedFragment = fragment;
                      } else {
                        this.hasRendered = true;
                      }
                    }
                    if (this.cachedFragment) {
                      fragment = dom.cloneNode(this.cachedFragment, true);
                    }
                  } else {
                    fragment = this.build(dom);
                  }
                  var element11 = dom.childAt(fragment, [1, 0]);
                  var morph0 = dom.createMorphAt(element11,0,0);
                  element(env, element11, context, "action", ["setIndicator", get(env, context, "item")], {});
                  content(env, morph0, context, "item.DisplayName");
                  return fragment;
                }
              };
            }());
            return {
              isHTMLBars: true,
              revision: "Ember@1.11.1",
              blockParams: 0,
              cachedFragment: null,
              hasRendered: false,
              build: function build(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              render: function render(context, env, contextualElement) {
                var dom = env.dom;
                var hooks = env.hooks, get = hooks.get, block = hooks.block;
                dom.detectNamespace(contextualElement);
                var fragment;
                if (env.useFragmentCache && dom.canClone) {
                  if (this.cachedFragment === null) {
                    fragment = this.build(dom);
                    if (this.hasRendered) {
                      this.cachedFragment = fragment;
                    } else {
                      this.hasRendered = true;
                    }
                  }
                  if (this.cachedFragment) {
                    fragment = dom.cloneNode(this.cachedFragment, true);
                  }
                } else {
                  fragment = this.build(dom);
                }
                var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, null);
                dom.insertBoundary(fragment, 0);
                block(env, morph0, context, "if", [get(env, context, "item.IsAdded")], {}, child0, child1);
                return fragment;
              }
            };
          }());
          var child1 = (function() {
            return {
              isHTMLBars: true,
              revision: "Ember@1.11.1",
              blockParams: 0,
              cachedFragment: null,
              hasRendered: false,
              build: function build(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                var el2 = dom.createElement("a");
                dom.setAttribute(el2,"href","#");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                                        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              render: function render(context, env, contextualElement) {
                var dom = env.dom;
                var hooks = env.hooks, get = hooks.get, element = hooks.element, content = hooks.content;
                dom.detectNamespace(contextualElement);
                var fragment;
                if (env.useFragmentCache && dom.canClone) {
                  if (this.cachedFragment === null) {
                    fragment = this.build(dom);
                    if (this.hasRendered) {
                      this.cachedFragment = fragment;
                    } else {
                      this.hasRendered = true;
                    }
                  }
                  if (this.cachedFragment) {
                    fragment = dom.cloneNode(this.cachedFragment, true);
                  }
                } else {
                  fragment = this.build(dom);
                }
                var element10 = dom.childAt(fragment, [1, 0]);
                var morph0 = dom.createMorphAt(element10,0,0);
                element(env, element10, context, "action", ["setChartStyle", get(env, context, "item")], {});
                content(env, morph0, context, "item.DisplayName");
                return fragment;
              }
            };
          }());
          return {
            isHTMLBars: true,
            revision: "Ember@1.11.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              dom.setAttribute(el1,"class","chart-fig-btn text-align-center");
              var el2 = dom.createTextNode("\n                                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","dropdown padding-small-right");
              var el3 = dom.createTextNode("\n                                                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("button");
              dom.setAttribute(el3,"class","btn btn-default dropdown-toggle border-none");
              dom.setAttribute(el3,"type","button");
              dom.setAttribute(el3,"id","indicatorDropDown");
              dom.setAttribute(el3,"data-toggle","dropdown");
              dom.setAttribute(el3,"aria-haspopup","true");
              dom.setAttribute(el3,"aria-expanded","true");
              var el4 = dom.createTextNode("\n                                                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"class","caret");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("ul");
              dom.setAttribute(el3,"class","indicator-menu dropdown-menu y-scroll");
              dom.setAttribute(el3,"aria-labelledby","dropdownMenu1");
              dom.setAttribute(el3,"id","dropDwnIndicatorId");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("                                                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              dom.setAttribute(el1,"class","chart-fig-btn");
              var el2 = dom.createTextNode("\n                                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","dropdown padding-small-right");
              var el3 = dom.createTextNode("\n                                                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("button");
              dom.setAttribute(el3,"class","btn btn-default dropdown-toggle border-none");
              dom.setAttribute(el3,"type","button");
              dom.setAttribute(el3,"id","areaDropDown");
              dom.setAttribute(el3,"data-toggle","dropdown");
              dom.setAttribute(el3,"aria-haspopup","true");
              dom.setAttribute(el3,"aria-expanded","true");
              var el4 = dom.createTextNode("\n                                                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"class","caret");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("ul");
              dom.setAttribute(el3,"class","dropdown-menu y-scroll");
              dom.setAttribute(el3,"aria-labelledby","dropdownMenu1");
              dom.setAttribute(el3,"id","dropDwnArea");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("                                                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks, get = hooks.get, concat = hooks.concat, attribute = hooks.attribute, content = hooks.content, block = hooks.block;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var element13 = dom.childAt(fragment, [1, 1]);
              var element14 = dom.childAt(element13, [1]);
              var element15 = dom.childAt(fragment, [3, 1]);
              var element16 = dom.childAt(element15, [1]);
              var morph0 = dom.createMorphAt(element14,1,1);
              var attrMorph0 = dom.createAttrMorph(element14, 'title');
              var morph1 = dom.createMorphAt(dom.childAt(element13, [3]),1,1);
              var morph2 = dom.createMorphAt(element16,1,1);
              var attrMorph1 = dom.createAttrMorph(element16, 'title');
              var morph3 = dom.createMorphAt(dom.childAt(element15, [3]),1,1);
              attribute(env, attrMorph0, element14, "title", concat(env, [get(env, context, "app.lang.labels.selectInd")]));
              content(env, morph0, context, "app.lang.labels.selectInd");
              block(env, morph1, context, "each", [get(env, context, "getDisplayIndicators")], {"keyword": "item"}, child0, null);
              attribute(env, attrMorph1, element16, "title", concat(env, [get(env, context, "app.lang.labels.chartStyleTitle")]));
              content(env, morph2, context, "chartStyle.DisplayName");
              block(env, morph3, context, "each", [get(env, context, "chartStyles")], {"keyword": "item"}, child1, null);
              return fragment;
            }
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              isHTMLBars: true,
              revision: "Ember@1.11.1",
              blockParams: 0,
              cachedFragment: null,
              hasRendered: false,
              build: function build(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                var el2 = dom.createElement("a");
                dom.setAttribute(el2,"href","#");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                                        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              render: function render(context, env, contextualElement) {
                var dom = env.dom;
                var hooks = env.hooks, get = hooks.get, element = hooks.element, content = hooks.content;
                dom.detectNamespace(contextualElement);
                var fragment;
                if (env.useFragmentCache && dom.canClone) {
                  if (this.cachedFragment === null) {
                    fragment = this.build(dom);
                    if (this.hasRendered) {
                      this.cachedFragment = fragment;
                    } else {
                      this.hasRendered = true;
                    }
                  }
                  if (this.cachedFragment) {
                    fragment = dom.cloneNode(this.cachedFragment, true);
                  }
                } else {
                  fragment = this.build(dom);
                }
                var element7 = dom.childAt(fragment, [1, 0]);
                var morph0 = dom.createMorphAt(element7,0,0);
                element(env, element7, context, "action", ["setChartInterval", get(env, context, "item")], {});
                content(env, morph0, context, "item.DisplayName");
                return fragment;
              }
            };
          }());
          return {
            isHTMLBars: true,
            revision: "Ember@1.11.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              dom.setAttribute(el1,"class","chart-fig-btn interval-dropdown");
              var el2 = dom.createTextNode("\n                                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","dropdown");
              var el3 = dom.createTextNode("\n                                                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("button");
              dom.setAttribute(el3,"class","btn btn-default dropdown-toggle border-none single-line-text");
              dom.setAttribute(el3,"type","button");
              dom.setAttribute(el3,"id","minutesDropDown");
              dom.setAttribute(el3,"data-toggle","dropdown");
              dom.setAttribute(el3,"aria-haspopup","true");
              dom.setAttribute(el3,"aria-expanded","true");
              var el4 = dom.createTextNode("\n                                                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"class","caret");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("ul");
              dom.setAttribute(el3,"class","dropdown-menu y-scroll");
              dom.setAttribute(el3,"aria-labelledby","dropdownMenu1");
              dom.setAttribute(el3,"id","dropInterval");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("                                                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks, get = hooks.get, concat = hooks.concat, attribute = hooks.attribute, content = hooks.content, block = hooks.block;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var element8 = dom.childAt(fragment, [1, 1]);
              var element9 = dom.childAt(element8, [1]);
              var morph0 = dom.createMorphAt(element9,1,1);
              var attrMorph0 = dom.createAttrMorph(element9, 'title');
              var morph1 = dom.createMorphAt(dom.childAt(element8, [3]),1,1);
              attribute(env, attrMorph0, element9, "title", concat(env, [get(env, context, "app.lang.labels.chartInterval")]));
              content(env, morph0, context, "chartInterval.DisplayName");
              block(env, morph1, context, "each", [get(env, context, "getChartInterval")], {"keyword": "item"}, child0, null);
              return fragment;
            }
          };
        }());
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("ul");
            dom.setAttribute(el1,"class","horizontal-list-left chart-btns");
            var el2 = dom.createTextNode("\n                                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            dom.setAttribute(el2,"class","chart-fig-btn");
            var el3 = dom.createTextNode("\n                                        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","dropdown padding-small-right");
            var el4 = dom.createTextNode("\n                                            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("button");
            dom.setAttribute(el4,"value","Volume");
            dom.setAttribute(el4,"id","volume-btn");
            dom.setAttribute(el4,"type","button");
            dom.setAttribute(el4,"onclick","changeClass(this)");
            var el5 = dom.createTextNode("\n                                                ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("span");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                                            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                                        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, concat = hooks.concat, attribute = hooks.attribute, subexpr = hooks.subexpr, element = hooks.element, content = hooks.content, block = hooks.block;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element17 = dom.childAt(fragment, [1]);
            var element18 = dom.childAt(element17, [1, 1, 1]);
            var attrMorph0 = dom.createAttrMorph(element18, 'title');
            var attrMorph1 = dom.createAttrMorph(element18, 'class');
            var morph0 = dom.createMorphAt(dom.childAt(element18, [1]),0,0);
            var morph1 = dom.createMorphAt(element17,3,3);
            var morph2 = dom.createMorphAt(element17,5,5);
            attribute(env, attrMorph0, element18, "title", concat(env, [get(env, context, "app.lang.labels.chartShowVol")]));
            attribute(env, attrMorph1, element18, "class", subexpr(env, context, "volumeEnabled", [get(env, context, "volumeViewEnabled")], {}));
            element(env, element18, context, "action", ["onToggleVolume"], {});
            content(env, morph0, context, "app.lang.labels.volume");
            block(env, morph1, context, "unless", [get(env, context, "compareSymbolMode")], {}, child0, null);
            block(env, morph2, context, "if", [get(env, context, "chartIntervalEnabled")], {}, child1, null);
            return fragment;
          }
        };
      }());
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","full-width font-medium");
          dom.setAttribute(el1,"id","chartToolbar");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("ul");
          dom.setAttribute(el2,"class","horizontal-list-left stk-interval");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("ul");
          dom.setAttribute(el4,"id","tabs");
          dom.setAttribute(el4,"class","chartTabs nav nav-tabs right chart-navtabs padding-small-left");
          dom.setAttribute(el4,"data-tabs","tabs");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("                    ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","row");
          dom.setAttribute(el2,"style","clear: both");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col-xs-12");
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","row");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","col-md-2 col-lg-2 col-sm-2 col-xs-12 margin-medium-bottom compare-symbol");
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","col-md-10 col-lg-10 col-sm-10 col-xs-12 no-padding-left-web margin-stk-top");
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("                        ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, block = hooks.block;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element33 = dom.childAt(fragment, [1]);
          var element34 = dom.childAt(element33, [3, 1, 1]);
          var morph0 = dom.createMorphAt(dom.childAt(element33, [1, 1, 1]),1,1);
          var morph1 = dom.createMorphAt(dom.childAt(element34, [1]),1,1);
          var morph2 = dom.createMorphAt(dom.childAt(element34, [3]),1,1);
          block(env, morph0, context, "each", [get(env, context, "periods")], {"keyword": "tabItem"}, child0, null);
          block(env, morph1, context, "if", [get(env, context, "compareSymbolEnabled")], {}, child1, null);
          block(env, morph2, context, "if", [get(env, context, "isHistory")], {}, child2, child3);
          return fragment;
        }
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","row");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","col-xs-12");
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("p");
            dom.setAttribute(el3,"id","price15minDelayLabel");
            dom.setAttribute(el3,"class","snap-note padding-small-top padding-small-bottom text-align-right");
            var el4 = dom.createTextNode("\n                            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" | ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","bold");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" | * ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("<p id=\"trailingTwelveMonthsLabel\" class=\"snap-note padding-small-top padding-small-bottom text-align-right\">* </p>");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, content = hooks.content;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element0 = dom.childAt(fragment, [1, 1, 1]);
            var morph0 = dom.createMorphAt(element0,1,1);
            var morph1 = dom.createMorphAt(element0,3,3);
            var morph2 = dom.createMorphAt(dom.childAt(element0, [5]),0,0);
            var morph3 = dom.createMorphAt(element0,7,7);
            content(env, morph0, context, "app.lang.labels.price15minDelay");
            content(env, morph1, context, "app.lang.labels.allPricesIn");
            content(env, morph2, context, "stock.cur");
            content(env, morph3, context, "app.lang.labels.trailingTwelveMonths");
            return fragment;
          }
        };
      }());
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"id","fourBox");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","row");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col-xs-6 col-sm-3 margin-medium-bottom");
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"id","pctChgWeekLabel");
          dom.setAttribute(el5,"style","padding: 10px;");
          dom.setAttribute(el5,"class","text-align-left left full-width margin-small-bottom dir-rtl radius-style-upper");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"id","pctChgW");
          dom.setAttribute(el5,"class","text-align-right right full-width snap-font-size-3 radius-style-lower");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","clearfix");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col-xs-6 col-sm-3 margin-medium-bottom");
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"id","pctChgMonthLabel");
          dom.setAttribute(el5,"style","padding: 10px;");
          dom.setAttribute(el5,"class","text-align-left left full-width margin-small-bottom dir-rtl radius-style-upper");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"id","pctChgM");
          dom.setAttribute(el5,"class","text-align-right right full-width snap-font-size-3 radius-style-lower");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","clearfix");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col-xs-6 col-sm-3 margin-medium-bottom");
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"id","pctChg3MLabel");
          dom.setAttribute(el5,"style","padding: 10px;");
          dom.setAttribute(el5,"class","text-align-left left full-width margin-small-bottom dir-rtl radius-style-upper");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"id","pctChg3M");
          dom.setAttribute(el5,"class","text-align-right right full-width snap-font-size-3 radius-style-lower");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","clearfix");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col-xs-6 col-sm-3 margin-medium-bottom");
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"id","pctYtdLabel");
          dom.setAttribute(el5,"style","padding: 10px;");
          dom.setAttribute(el5,"class","text-align-left left full-width margin-small-bottom dir-rtl radius-style-upper");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"id","pctYtd");
          dom.setAttribute(el5,"class","text-align-right right full-width snap-font-size-3 radius-style-lower");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","clearfix");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, subexpr = hooks.subexpr, concat = hooks.concat, attribute = hooks.attribute, content = hooks.content, inline = hooks.inline, block = hooks.block;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element1 = dom.childAt(fragment, [1]);
          var element2 = dom.childAt(element1, [1]);
          var element3 = dom.childAt(element2, [1, 1]);
          var element4 = dom.childAt(element2, [3, 1]);
          var element5 = dom.childAt(element2, [5, 1]);
          var element6 = dom.childAt(element2, [7, 1]);
          var attrMorph0 = dom.createAttrMorph(element3, 'class');
          var morph0 = dom.createMorphAt(dom.childAt(element3, [1]),0,0);
          var morph1 = dom.createMorphAt(dom.childAt(element3, [3]),0,0);
          var attrMorph1 = dom.createAttrMorph(element4, 'class');
          var morph2 = dom.createMorphAt(dom.childAt(element4, [1]),0,0);
          var morph3 = dom.createMorphAt(dom.childAt(element4, [3]),0,0);
          var attrMorph2 = dom.createAttrMorph(element5, 'class');
          var morph4 = dom.createMorphAt(dom.childAt(element5, [1]),0,0);
          var morph5 = dom.createMorphAt(dom.childAt(element5, [3]),0,0);
          var attrMorph3 = dom.createAttrMorph(element6, 'class');
          var morph6 = dom.createMorphAt(dom.childAt(element6, [1]),0,0);
          var morph7 = dom.createMorphAt(dom.childAt(element6, [3]),0,0);
          var morph8 = dom.createMorphAt(element1,3,3);
          attribute(env, attrMorph0, element3, "class", concat(env, ["radius-style full-width left ", subexpr(env, context, "getPositiveNegativeStyle", [get(env, context, "stock.pctChgW")], {})]));
          content(env, morph0, context, "app.lang.labels.pctChgWeek");
          inline(env, morph1, context, "formatNumberPercentage", [get(env, context, "stock.pctChgW")], {});
          attribute(env, attrMorph1, element4, "class", concat(env, [" radius-style full-width left ", subexpr(env, context, "getPositiveNegativeStyle", [get(env, context, "stock.pctChgM")], {})]));
          content(env, morph2, context, "app.lang.labels.pctChgMonth");
          inline(env, morph3, context, "formatNumberPercentage", [get(env, context, "stock.pctChgM")], {});
          attribute(env, attrMorph2, element5, "class", concat(env, ["radius-style full-width left ", subexpr(env, context, "getPositiveNegativeStyle", [get(env, context, "stock.pctChg3M")], {})]));
          content(env, morph4, context, "app.lang.labels.pctChg3Month");
          inline(env, morph5, context, "formatNumberPercentage", [get(env, context, "stock.pctChg3M")], {});
          attribute(env, attrMorph3, element6, "class", concat(env, ["radius-style full-width left ", subexpr(env, context, "getPositiveNegativeStyle", [get(env, context, "stock.pctYtd")], {})]));
          content(env, morph6, context, "app.lang.labels.ytd");
          inline(env, morph7, context, "formatNumberPercentage", [get(env, context, "stock.pctYtd")], {});
          block(env, morph8, context, "unless", [get(env, context, "hideFootNote")], {}, child0, null);
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","chartWidget");
        dom.setAttribute(el1,"class","chart-widget stk-ovw");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"id","chartContainer");
        dom.setAttribute(el2,"class","chartContainer chart-widget dir-ltr margin-medium-bottom");
        dom.setAttribute(el2,"style","height:100%;width:100%; position:relative");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","clearfix");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("</div>");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        dom.setAttribute(el1,"type","text/javascript");
        var el2 = dom.createTextNode("\n    function changeClass(button) {\n        var className = button.getAttribute(\"class\");\n        if (className == \"btn btn-default border-none normal\") {\n            button.className = \"btn btn-default border-none activeVol\";\n        }\n        else {\n            button.className = \"btn btn-default border-none normal\";\n        }\n    }\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, block = hooks.block, inline = hooks.inline;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element35 = dom.childAt(fragment, [0]);
        var morph0 = dom.createMorphAt(element35,1,1);
        var morph1 = dom.createMorphAt(dom.childAt(element35, [3]),1,1);
        var morph2 = dom.createMorphAt(element35,7,7);
        block(env, morph0, context, "unless", [get(env, context, "includeControls")], {}, child0, null);
        inline(env, morph1, context, "dynamic-high-chart", [], {"mode": get(env, context, "chartMode"), "content": get(env, context, "chartData"), "chartOptions": get(env, context, "chartOptions"), "volumeChartHeightPctg": get(env, context, "volumeChartHeightPctg"), "minValue": get(env, context, "minValue"), "maxValue": get(env, context, "maxValue"), "volumeViewEnabled": get(env, context, "volumeViewEnabled"), "chartHeight": get(env, context, "chartContainerHeight"), "chartWidth": get(env, context, "chartContainerWidth"), "indicatorList": get(env, context, "getIndicators"), "closeMode": get(env, context, "closeMode"), "dataLoadingComplete": get(env, context, "dataLoadingComplete"), "compareSymbolContent": get(env, context, "compareSymbolContent"), "chartCompareSymbolKeyList": get(env, context, "chartCompareSymbolKeyList"), "compareViewEnabled": get(env, context, "compareSymbolMode"), "compareSymbolNeedsToUpdate": get(env, context, "compareSymbolNeedsToUpdate"), "chartInterval": get(env, context, "chartInterval"), "chartCategory": get(env, context, "chartCategory"), "prevChartCategory": get(env, context, "initialChartCategory")});
        block(env, morph2, context, "unless", [get(env, context, "includeFourBoxes")], {}, child1, null);
        return fragment;
      }
    };
  }()));

});