define('ir-app/controllers/title-bar', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/shared/language/language-data-store', 'ir-app/models/shared/shared-data-stores/theme-data-store', 'ir-app/models/price/price-service', 'ir-app/models/price/price-constants', 'ir-app/config/user-settings', 'ir-app/config/app-config', 'ir-app/config/extended-settings', 'ir-app/models/shared/shared-service'], function (exports, Ember, BaseController, LanguageDataStore, ThemeDataStore, priceService, priceConstants, userConfigSettings, appConfig, Config, sharedService) {

  'use strict';

  exports['default'] = BaseController['default'].extend({
    userSettings: null,
    msgArray: Ember['default'].A(),
    gaKey: 'title-bar',
    menuContent: {},
    basicInfo: {},
    currentMenu: null,
    slideDirection: 'from-left',
    siteUrl: '',
    hideHomeButton: true,

    settings: [{
      code: 'lang:' + LanguageDataStore['default'].getChangeLanguageObj().lang.code,
      des: LanguageDataStore['default'].getChangeLanguageObj().lang.desc
    }, {
      code: 'theme:' + ThemeDataStore['default'].getChangeThemeObj().theme.code,
      des: ThemeDataStore['default'].getChangeThemeObj().theme.desc
    }],
    exchangeList: [],
    showExchangeSelection: false,
    isMobile: false,

    init: function init() {
      this._super();

      if (sharedService['default'].userSettings.currentAppMode === appConfig['default'].applicationMode.ir_mobile.identifier) {
        this.set('userSettings', sharedService['default'].userSettings);
        this.set('siteUrl', Config['default'].appConfig.customisation.siteUrl);

        this._setSlideMenu();
        this.set('isMobile', true);
      }

      this._loadData();

      Ember['default'].run.later(function () {
        Ember['default'].$('.nano').nanoScroller();
      }, 1000);

      Ember['default'].run.later(function () {
        try {
          if (window.Android) {

            // if Android Host do nothing

          } else {
              var retrievedObject = localStorage.getItem('host_version');

              if (JSON.parse(retrievedObject).os === 'ios') {

                window.location = 'settings-theme' + '/' + sharedService['default'].userSettings.currentTheme;
              }
            }
        } catch (err) {

          // if error
        }
      }, 5000);
    },

    onClearData: function onClearData() {
      this.set('basicInfo', null);
    },

    image: (function () {
      if (this.utils.validators.isAvailable(this.get('basicInfo.logo'))) {
        return this.utils.imageHelper.getImage(this.get('basicInfo.logo'));
      }
    }).property('basicInfo.logo'),

    onCheckDataAvailability: function onCheckDataAvailability() {
      var companyProfObj = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage);

      // TODO: [ASANKA] stock data availability should check
      if (companyProfObj) {
        return this.utils.validators.isAvailable(companyProfObj.compID);
      }

      return false;
    },

    onLanguageChanged: (function () {
      // update menu structure and current menu displayTitle
      this.setActiveMenu(this.get('currentMenu'));
      this.updateSettings();
      this._setSlideMenu();

      this.set('basicInfo', null);
      this._loadData();
    }).observes('userSettings.currentLanguage'),

    _loadData: function _loadData() {
      var that = this;
      var basicInfo = priceService['default'].companyProfileDS.getStoredObject(sharedService['default'].userSettings.currentExchange, sharedService['default'].userSettings.currentSymbol, sharedService['default'].userSettings.currentLanguage, function () {
        that.onDataSuccess();
      }, function () {
        that.onDataError();
      }, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyBasicInfo);

      this.set('basicInfo', basicInfo);
    },

    _setSlideMenu: function _setSlideMenu() {
      var that = this;
      if (sharedService['default'].userSettings.currentLanguage === 'AR') {
        this.set('slideDirection', 'from-right');
      } else {
        this.set('slideDirection', 'from-left');
      }

      var exgList = [];
      userConfigSettings['default'].customisation.exchangeList.forEach(function (item) {
        var exgItem = { displayExg: that.get('app').lang.labels[item.displayLabel], exg: item.exg, sym: item.sym };
        exgList.push(exgItem);
      });
      this.set('exchangeList', exgList);

      if (exgList.length > 0) {
        that.set('showExchangeSelection', true);
      }
    },

    updateSettings: function updateSettings() {
      var changeToLang = LanguageDataStore['default'].getChangeLanguageObj();
      var changeToTheme = ThemeDataStore['default'].getChangeThemeObj();

      Ember['default'].set(this, 'settings', [{ code: 'lang:' + changeToLang.lang.code, des: changeToLang.lang.desc }, { code: 'theme:' + changeToTheme.theme.code, des: changeToTheme.theme.desc }]);
    },
    /**
     * when application main menu change....
     * @param menuContent
     */
    onChangeMenu: function onChangeMenu(menuContent) {
      Ember['default'].set(this, 'currentMenu', menuContent);
      this.setActiveMenu(menuContent);
    },
    actions: {
      changeSettings: function changeSettings(selectedSetting) {
        var codeKeys = selectedSetting.code.split(this.utils.Constants.StringConst.Colon);

        if (codeKeys.length > 1) {
          if (codeKeys[0] === 'theme') {
            ThemeDataStore['default'].changeTheme(codeKeys[1]);
            this.utils.analyticsService.trackEvent(this.get('gaKey'), this.utils.Constants.GAActions.settingsChanged, ['theme:', codeKeys[1]].join(''));
          } else if (codeKeys[0] === 'lang') {
            LanguageDataStore['default'].changeLanguage(codeKeys[1]);
            this.utils.analyticsService.trackEvent(this.get('gaKey'), this.utils.Constants.GAActions.settingsChanged, ['language:', codeKeys[1]].join(''));
          }

          this.updateSettings();
        }
      },
      shareScreen: function shareScreen() {
        if (window.Android) {
          Android.share(); // jshint ignore:line
        } else {

            var retrievedObject = localStorage.getItem('host_version');

            if (JSON.parse(retrievedObject).os === 'ios') {

              window.location = 'share';
            }
          }
      },

      navigateHome: function navigateHome() {
        this.renderMenuWidget(1);
      },

      navigateMainWebSite: function navigateMainWebSite() {
        // window.open(
        //     this.get('siteUrl'),
        //     '_blank'
        // );
        var lang = this.get('basicInfo').lan;
        if (window.Android) {
          // Android.openSite();// jshint ignore:line
          if (lang == 'EN') {
            window.open(this.get('siteUrl').en, '_blank');
          } else {
            window.open(this.get('siteUrl').ar, '_blank');
          }
        } else {

          var retrievedObject = localStorage.getItem('host_version');

          if (JSON.parse(retrievedObject).os === 'ios') {
            if (lang == 'EN') {
              window.location = 'website?site=' + this.get('siteUrl').en;
            } else {
              window.location = 'website?site=' + this.get('siteUrl').ar;
            }
          }
        }
      }
    },

    renderMenuWidget: function renderMenuWidget(menuId) {

      var route = this.container.lookup('route:application');
      var menuContent = route.get('appLayout').layout.mainContent[menuId - 1];
      route.controllerFor('title-bar').onChangeMenu(menuContent);

      route.set('menuContent', menuContent);

      route.widgetContainer = route.controllerFor(menuContent.layoutTemplate.wn);

      route.render(menuContent.layoutTemplate.wn, {
        into: 'application',
        outlet: 'mainContainerOutlet'
      });

      if (route.widgetContainer && route.widgetContainer.closeContainer) {
        route.widgetContainer.closeContainer();
      }

      // set container level configuration args
      var widgetArgs = route.get('appLayout').args;
      if (widgetArgs) {
        var containerArgs = widgetArgs[menuContent.id] ? widgetArgs[menuContent.id].args : undefined;

        if (containerArgs) {
          route.widgetContainer.setArgs(containerArgs);
        }
      }

      route.widgetContainer.menuView(route, menuContent, route.get('appLayout').args, route.get('storedArgs'), true);
    },

    setMenuStructure: function setMenuStructure(menuStructure) {
      Ember['default'].set(this, 'menuContent', menuStructure);
    },

    setActiveMenu: function setActiveMenu(currentMenu) {
      var menuArray = this.get('menuContent');
      var that = this;
      if (currentMenu.id != 1) {
        Ember['default'].set(this, 'hideHomeButton', false);
      } else {
        Ember['default'].set(this, 'hideHomeButton', true);
      }
      // TODO: [Bashitha] At first time, object behaves as a normal javascript object
      // TODO: [Bashitha] After that object is an ember object
      // TODO: [Bashitha] Still we cannot call .set() directly on the object
      // TODO: [Bashitha] Need to call Ember.set() instead

      Ember['default'].$.each(menuArray, function (key, menuObj) {
        try {

          Ember['default'].set(menuObj, 'css', '');
          Ember['default'].set(menuObj, 'displayTitle', that.get('app').lang.labels[menuObj.title]);
        } catch (e) {
          menuObj.css = '';
          menuObj.displayTitle = that.get('app').lang.labels[menuObj.title];
        }
        if (menuObj.id === currentMenu.id) {
          try {
            Ember['default'].set(menuObj, 'css', 'sidebar-active');
            Ember['default'].set(this, 'currentMenu', menuObj);
          } catch (e) {
            menuObj.css = 'sidebar-active';
            this.currentMenu = menuObj;
          }
        }
      });
    }
  });

});