define('ir-app/controllers/ir-mobile/company-widgets/company-management-info', ['exports', 'ember', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/controllers/base-array-controller', 'ir-app/models/price/price-constants', 'ir-app/utils/utils'], function (exports, Ember, priceService, sharedService, BaseArrayController, priceConstants, utils) {

  'use strict';

  exports['default'] = BaseArrayController['default'].extend({

    // Parameters for sorting
    sortProperties: ['sortOrder'],
    sortAscending: true,
    showCompanyLinks: false,
    noShowAppointmentDate: false,

    onLoadWidget: function onLoadWidget() {
      this.setErrorMessage();
    },

    onPrepareData: function onPrepareData() {
      var that = this;
      this.startLoadingProgress();

      var managementInfo;
      if (this.utils.validators.isAvailable(this.get('companyId'))) {
        managementInfo = priceService['default'].companyProfileDS.getStoredObjectByCompanyId(this.get('companyId'), sharedService['default'].userSettings.currentLanguage, function () {
          that.onDataSuccess();
        }, function () {
          that.onDataError();
        }, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyManagementInfo).compManagement;
      } else {
        managementInfo = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, function () {
          that.onDataSuccess();
        }, function () {
          that.onDataError();
        }, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyManagementInfo).compManagement;
      }

      this.set('model', managementInfo);
    },

    onCheckDataAvailability: function onCheckDataAvailability() {
      var companyProfObj;
      if (this.utils.validators.isAvailable(this.get('companyId'))) {
        companyProfObj = priceService['default'].companyProfileDS.getStoredObjectByCompanyId(this.get('companyId'), sharedService['default'].userSettings.currentLanguage);
      } else {
        companyProfObj = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage);
      }

      return companyProfObj && companyProfObj.compManagement && companyProfObj.compManagement.length > 0;
    },

    onLanguageChanged: function onLanguageChanged() {
      this.onPrepareData();
    },

    // consider entries without resignation date as current members
    filteredContent: (function () {
      var content = this.get('arrangedContent');
      var showCompanyLinks = this.get('showCompanyLinks');

      if (content) {
        if (showCompanyLinks) {
          content.forEach(function (item) {
            Ember['default'].set(item, 'mngCompNameFormatted', utils['default'].formatters.formatString(item.mngCompName) + '-' + item.mngCompId);
          });
        }
        return content.filter(function (item) {
          return !item.resignDate;
        });
      }
    }).property('arrangedContent')
  });

});