define('ir-app/models/shared/communication-adapters/web-socket-connection', ['exports', 'ir-app/models/price/price-constants', 'ir-app/utils/utils'], function (exports, PriceConstants, utils) {

    'use strict';

    /* global Queue */
    exports['default'] = function (socketParams) {
        this.socketParams = socketParams;
        this.responseHandler = undefined; // Local instance created for this web socket connection
        this.webSocket = undefined;

        this.authQueue = new Queue();
        this.dataQueue = new Queue();

        this.isConnectedToServer = false;
        this.isAuthenticated = false;
        this.isAttemptRetry = true;
        this.missedHeartbeats = 1;

        var that = this;

        var initialize = function initialize(responseHandler, callbacks) {
            if (responseHandler) {
                that.responseHandler = responseHandler.create(callbacks); // Local instance created for this web socket connection
            } else {
                    that.responseHandler.callbacks.auth.successFn = callbacks;
                    that.responseHandler.callbacks.auth.postSuccessFn = undefined;
                }
        };

        var sendAuth = function sendAuth(authRequest) {
            that.authQueue.enqueue(authRequest);

            _openConnection();
        };

        var sendData = function sendData(dataRequest) {
            that.dataQueue.enqueue(dataRequest);
        };

        var isConnected = function isConnected() {
            return that.isAuthenticated;
        };

        var _openConnection = function _openConnection() {
            if ('WebSocket' in window) {
                utils['default'].logger.logInfo('WebSocket is supported by the browser!');
                var path = [that.socketParams.secure ? 'wss://' : 'ws://', that.socketParams.ip, that.socketParams.port ? ':' : '', that.socketParams.port].join('');

                try {
                    // Open a web socket
                    utils['default'].logger.logInfo('Connecting to path : ' + path);

                    that.webSocket = new WebSocket(path);

                    that.webSocket.onopen = _onOpen;
                    that.webSocket.onmessage = _onMessage;
                    that.webSocket.onclose = _onClose;
                    that.webSocket.onerror = _onError;
                } catch (ex) {
                    _closeConnection(true);

                    utils['default'].logger.logError('Connection failure to path : ' + path);
                }
            } else {
                utils['default'].logger.logInfo('WebSocket is not supported by the Browser!');
            }
        };

        var _closeConnection = function _closeConnection(retry) {
            that.isAttemptRetry = that.isAttemptRetry && retry;

            if (that.isConnectedToServer) {
                that.isConnectedToServer = false;
                that.isAuthenticated = false;

                if (that.webSocket) {
                    that.webSocket.onclose = function () {}; // Disable onclose handler first
                    that.webSocket.close();
                    that.webSocket = undefined;
                }

                _flushOutQueues();
            }

            that.socketParams.onDisconnect(that, that.isAttemptRetry);
        };

        var _onOpen = function _onOpen() {
            that.isConnectedToServer = true;
            that.socketParams.onConnect(that);

            utils['default'].logger.logInfo('Web socket connected.');

            _sendQueuedData();
        };

        var _onClose = function _onClose(event) {
            utils['default'].logger.logError('_onClose(): ' + _getErrorMessage(event));

            _closeConnection(true);
        };

        var _onError = function _onError() {
            utils['default'].logger.logError('_onError(): web-socket-connection');
        };

        var _onMessage = function _onMessage(event) {
            that.missedHeartbeats = 0;
            that.responseHandler.onMessage(event.data, _onSocketReady);
        };

        var _sendQueuedData = function _sendQueuedData() {
            if (that.webSocket && that.webSocket.readyState === 1) {
                if (that.isConnectedToServer) {
                    _sendMessage(that.authQueue);

                    if (that.isAuthenticated) {
                        _sendMessage(that.dataQueue);
                    }
                }
            }

            if (that.isConnectedToServer) {
                setTimeout(_sendQueuedData, PriceConstants['default'].TimeIntervals.WebSocketOutQueueProcessingInterval);
            }
        };

        var _sendMessage = function _sendMessage(messageQueue) {
            while (messageQueue.getLength() > 0) {
                var message = messageQueue.dequeue();
                that.webSocket.send(message);

                utils['default'].logger.logDebug('Message sent to server : ' + message);
            }
        };

        var _getErrorMessage = function _getErrorMessage(event) {
            var reason;
            // See http://tools.ietf.org/html/rfc6455#section-7.4.1
            switch (event.code) {
                case 1000:
                    reason = 'Normal closure, meaning that the purpose for which the connection was established has been fulfilled.';
                    break;

                case 1001:
                    reason = 'An endpoint is \"going away\", such as a server going down or a browser having navigated away from a page.';
                    break;

                case 1002:
                    reason = 'An endpoint is terminating the connection due to a protocol error';
                    break;

                case 1003:
                    reason = 'An endpoint is terminating the connection because it has received a type of data it cannot accept (e.g., an endpoint that understands only text data MAY send this if it receives a binary message).';
                    break;

                case 1004:
                    reason = 'Reserved. The specific meaning might be defined in the future.';
                    break;

                case 1005:
                    reason = 'No status code was actually present.';
                    break;

                case 1006:
                    reason = 'The connection was closed abnormally, e.g., without sending or receiving a Close control frame';
                    break;

                case 1007:
                    reason = 'An endpoint is terminating the connection because it has received data within a message that was not consistent with the type of the message (e.g., non-UTF-8 [http://tools.ietf.org/html/rfc3629] data within a text message).';
                    break;

                case 1008:
                    reason = 'An endpoint is terminating the connection because it has received a message that \"violates its policy\". This reason is given either if there is no other sutible reason, or if there is a need to hide specific details about the policy.';
                    break;

                case 1009:
                    reason = 'An endpoint is terminating the connection because it has received a message that is too big for it to process.';
                    break;

                case 1010:
                    // Note that this status code is not used by the server, because it can fail the WebSocket handshake instead.
                    reason = 'An endpoint (client) is terminating the connection because it has expected the server to negotiate one or more extension, but the server didn\'t return them in the response message of the WebSocket handshake. <br /> Specifically, the extensions that are needed are: ' + event.reason;
                    break;

                case 1011:
                    reason = 'A server is terminating the connection because it encountered an unexpected condition that prevented it from fulfilling the request.';
                    break;

                case 1015:
                    reason = 'The connection was closed due to a failure to perform a TLS handshake (e.g., the server certificate can\'t be verified).';
                    break;

                default:
                    reason = 'Unknown reason';
                    break;
            }

            return reason;
        };

        var _flushOutQueues = function _flushOutQueues() {
            // Flush the out queues
            // TODO: [Bashitha] Implement the flush feature in the Queue library
            while (that.dataQueue.getLength() > 0) {
                that.dataQueue.dequeue();
            }

            while (that.authQueue.getLength() > 0) {
                that.authQueue.dequeue();
            }
        };

        var _onSocketReady = function _onSocketReady(authenticated) {
            that.isAuthenticated = authenticated;

            if (that.isAuthenticated) {
                that.isAttemptRetry = true;
                setTimeout(_sendPulse, PriceConstants['default'].Pulse.PulseInterval);
            } else {
                _closeConnection(false);
            }
        };

        var _sendPulse = function _sendPulse() {
            sendData(_getPulseMessage());

            that.missedHeartbeats++;

            if (that.missedHeartbeats > PriceConstants['default'].Pulse.MissedPulseCount) {
                _closeConnection(true);
            } else {
                setTimeout(_sendPulse, PriceConstants['default'].Pulse.PulseInterval);
            }
        };

        var _getPulseMessage = function _getPulseMessage() {
            var reqElements = ['{"0":', that.missedHeartbeats, '}\n'];
            var req = reqElements.join('');

            return req.length + req;
        };

        return {
            initialize: initialize,
            sendAuth: sendAuth,
            sendData: sendData,
            isConnected: isConnected
        };
    }

});