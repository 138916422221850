define('ir-app/controllers/ir-widgets/international-indices-widget/international-indices', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/utils/utils'], function (exports, Ember, BaseController, priceService, sharedService, utils) {

    'use strict';

    exports['default'] = BaseController['default'].extend({

        idxDetails: [],
        tblHeaders: [],
        middleEast: undefined,
        showMarket: undefined,
        commodities: undefined,
        lang: undefined,
        commodityFootNote: true,

        onLoadWidget: function onLoadWidget() {
            this.set('idxDetails', []);
            this.set('tblHeaders', []);
        },

        onPrepareData: function onPrepareData() {
            var keys = this.get('keys');
            var mode = parseInt(this.get('mode'), 10);
            var array = this.get('idxDetails');
            var exchange = null;
            var that = this;
            this.set('clientStkPage', this.get('clientStkPage'));
            this.set('lang', sharedService['default'].userSettings.currentLanguage.toLowerCase());

            Ember['default'].$.each(keys, function (index, key) {
                var idx = null;
                priceService['default'].downloadExchangeProperty(key.exg, that.get('lang'));
                exchange = priceService['default'].exchangeDS.getExchange(key.exg);
                idx = priceService['default'].stockDS.getStock(key.exg, key.idxCode);
                idx.indexCode = key.idxCode;
                if (key.isIndex) {
                    priceService['default'].addIndexRequest(key.exg, key.idxCode);
                } else {
                    priceService['default'].addSymbolRequest(key.exg, key.idxCode);
                }

                if (mode === 0) {
                    priceService['default'].addExchangeRequest(key.exg);
                } else if (mode === 3 && that.get('currencyFlag')) {
                    idx.flag1 = key.flag1;
                    idx.flag2 = key.flag2;
                }

                var prop = ['idxDisplayName', sharedService['default'].userSettings.currentLanguage].join('');
                idx.index = key[prop];
                idx.exchange = exchange;

                prop = ['mktLabel', sharedService['default'].userSettings.currentLanguage].join('');
                idx.mktLabel = key[prop];

                idx.customDecimalPlaces = 2;
                if (mode === 3) {
                    idx.customDecimalPlaces = key.deci;
                }

                array.push(idx);
            });

            if (mode === 0) {
                this.set('middleEast', true);
                this.set('showMarket', true);
                this.set('commodities', false);
            } else if (mode === 1) {
                this.set('middleEast', false);
                this.set('showMarket', true);
                this.set('commodities', false);
            } else if (mode === 2) {
                this.set('middleEast', false);
                this.set('showMarket', false);
                this.set('commodities', true);
            } else {
                this.set('middleEast', false);
                this.set('showMarket', false);
                this.set('commodities', false);
            }

            this.populateTableHeaderArray(mode);
        },

        populateTableHeaderArray: function populateTableHeaderArray(mode) {
            var array = this.get('tblHeaders');

            switch (mode) {
                case 0:
                    array.push({ text: this.get('app').lang.labels.indexName, css: 'tbl-col-7', alignment: '' });
                    array.push({ text: this.get('app').lang.labels.market, css: 'tbl-col-7', alignment: 'text-align-left' });
                    array.push({ text: this.get('app').lang.labels.lastUpdated, css: 'tbl-col-7', alignment: '' });
                    array.push({ text: this.get('app').lang.labels.change, css: 'tbl-col-7', alignment: '' });
                    array.push({ text: this.get('app').lang.labels.perChange, css: 'tbl-col-7', alignment: '' });
                    array.push({ text: this.get('app').lang.labels.volume, css: 'tbl-col-7', alignment: '' });
                    array.push({ text: this.get('app').lang.labels.marketStatus, css: 'tbl-col-7', alignment: '' });
                    break;

                case 1:
                    array.push({ text: this.get('app').lang.labels.indexName, css: 'tbl-col-5', alignment: '' });
                    array.push({ text: this.get('app').lang.labels.market, css: 'tbl-col-5', alignment: 'text-align-left' });
                    array.push({ text: this.get('app').lang.labels.lastUpdated, css: 'tbl-col-5', alignment: '' });
                    array.push({ text: this.get('app').lang.labels.change, css: 'tbl-col-5', alignment: '' });
                    array.push({ text: this.get('app').lang.labels.perChange, css: 'tbl-col-5', alignment: '' });
                    break;

                case 2:
                    array.push({ text: this.get('app').lang.labels.market, css: 'tbl-col-4', alignment: '' });
                    array.push({ text: sharedService['default'].userSettings.currentLanguage === 'AR' ? '*' + this.get('app').lang.labels.lastUpdated : this.get('app').lang.labels.lastUpdated + '*', css: 'tbl-col-4', alignment: '' });
                    array.push({ text: this.get('app').lang.labels.change, css: 'tbl-col-4', alignment: '' });
                    array.push({ text: this.get('app').lang.labels.perChange, css: 'tbl-col-4', alignment: '' });
                    break;

                case 3:
                    array.push({ text: this.get('app').lang.labels.currency, css: 'tbl-col-4', alignment: '' });
                    array.push({ text: this.get('app').lang.labels.lastUpdated, css: 'tbl-col-4', alignment: '' });
                    array.push({ text: this.get('app').lang.labels.change, css: 'tbl-col-4', alignment: '' });
                    array.push({ text: this.get('app').lang.labels.perChange, css: 'tbl-col-4', alignment: '' });
                    break;

                default:
                    utils['default'].logger.logError('Invalid mode in International Indices widget');
                    break;
            }
        },

        onClearData: function onClearData() {
            var keys = this.get('keys');
            Ember['default'].$.each(keys, function (index, key) {
                if (key.isIndex) {
                    priceService['default'].removeExchangeRequest(key.exg);
                    priceService['default'].removeIndexRequest(key.exg, key.idxCode);
                } else {
                    priceService['default'].removeSymbolRequest(key.exg, key.idxCode);
                }
            });
        },

        onLanguageChanged: function onLanguageChanged() {
            var array = this.get('idxDetails');
            Ember['default'].$.each(array, function (index, key) {
                priceService['default'].sendSymbolValidationRequest(key.exg, key.idxCode, sharedService['default'].userSettings.currentLanguage, undefined, undefined);
            });
        }
    });

});