define('ir-app/models/price/price-business-entities/company-ownership-limits', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        seriesId: '',
        seriesName: '',
        eligibility: '',
        direction: '',
        limit: '',
        valueDate: '',
        value: '',
        weeklyPctChg: '',
        monthlyPctChg: '',
        threeMonthsPctChg: '',
        ytdPctChg: '',
        yearPctChg: '',
        setData: function setData(ownershipLimits) {
            var that = this;
            Ember['default'].$.each(ownershipLimits, function (key, value) {
                that.set(key, value);
            });
        }
    });

});