define('ir-app/controllers/ir-mobile/peer-widgets/peer-table', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/components/stk-specific-components/fifty-two-wk-hl-slider', 'ir-app/models/shared/shared-service', 'ir-app/components/stk-specific-components/portal-range-display-slider'], function (exports, Ember, BaseController, priceService, FiftyTwoWkHlSlider, sharedService, PortalRangeSlider) {

    'use strict';

    /**
     * Created by User on 17/2/2016.
     */

    exports['default'] = BaseController['default'].extend({
        stock: {},
        stockList: Ember['default'].A(),
        //dataAvailable: false,

        // Base overrides
        onPrepareData: function onPrepareData() {
            var that = this;
            that.loadContent();
            this.loadSectorInfo();
        },

        loadSectorInfo: function loadSectorInfo() {
            var that = this;

            priceService['default'].downloadParamsSymbolMasterForExchange({
                exchange: that.get('exg'),
                language: sharedService['default'].userSettings.currentLanguage,
                successFn: function successFn() {
                    //that.loadContent();
                    priceService['default'].addIndexRequest(that.get('exg'), that.get('stock').sec);
                    that.set('sector', priceService['default'].stockDS.getStock(that.get('exg'), that.get('stock').sec));
                    that.get('stockList').push(that.get('sector'));
                    that.set('sectorDataAvailable', true);
                    that.loadWLSectorContent();
                },
                errorFn: function errorFn() {
                    that.onError();
                }
            });
        },

        loadContent: function loadContent() {

            priceService['default'].addSymbolRequest(this.get('exg'), this.get('sym'));
            this.set('stock', priceService['default'].stockDS.getStock(this.get('exg'), this.get('sym')));
            this.get('stockList').push(this.get('stock'));

            this.loadExchangeInfo();
        },

        dataAvailable: (function () {

            if (this.get('additionalList')) {

                return this.get('sectorDataAvailable') && this.get('mainIndexDataAvailable') && this.get('sectorWLDataAvailable') && this.get('additionalDataAvailable');
            } else {
                return this.get('sectorDataAvailable') && this.get('mainIndexDataAvailable') && this.get('sectorWLDataAvailable');
            }
        }).property('sectorDataAvailable', 'mainIndexDataAvailable'),

        getList: (function () {
            return this.get('stockList');
        }).property('stockList', 'stockList.@each'),

        loadExchangeInfo: function loadExchangeInfo() {

            var exchange = priceService['default'].exchangeDS.getExchange(this.get('exg'));
            this.set('exchange', exchange);

            var that = this;
            priceService['default'].downloadExchangeProperty(this.get('exg'), sharedService['default'].userSettings.currentLanguage, function () {
                if (that.get('exchange').mainIdx) {
                    var index = priceService['default'].stockDS.getStock(that.get('exg'), that.get('exchange').mainIdx);
                    that.set('index', index);
                    priceService['default'].addIndexRequest(that.get('exg'), that.get('exchange').mainIdx);
                    that.get('stockList').push(that.get('index'));
                    that.set('mainIndexDataAvailable', true);
                }
            });
        },

        loadWLSectorContent: function loadWLSectorContent() {
            var exchange = this.get('exg');
            var sector = this.get('sector').sym;
            var store = priceService['default'].stockDS.get('indexTickerSymbols');
            var id = exchange + '_' + sector;

            if (!store[id]) {
                store[id] = Ember['default'].A();
                priceService['default'].stockDS.set('indexTickerSymbols', store);
            }
            var symbolList = [];
            this.set('symList', store[id]);
            for (var i = 0; i < this.get('symList').length; i++) {
                symbolList.push(this.get('symList')[i].sym);
                priceService['default'].addSymbolRequest(this.get('exg'), this.get('symList')[i].sym);
            }
            this.get('stockList').push.apply(this.get('stockList'));
            var arr;
            //remove duplicate
            arr = this.get('stockList').filter(function (item, index, inputArray) {
                return inputArray.indexOf(item) === index;
            });
            this.set('stockList', arr);
            this.set('sectorWLDataAvailable', true);
            this.loadAdditionalList();
        },

        loadAdditionalList: function loadAdditionalList() {
            var list = this.get('additionalList');

            for (var i = 0; i < list.length; i++) {
                if (!list[i].isIndex) {
                    priceService['default'].addSymbolRequest(list[i].exg, list[i].sym);
                } else {
                    priceService['default'].addIndexRequest(list[i].exg, list[i].sym);
                }
                priceService['default'].downloadParamsSymbolMasterForExchange({
                    exchange: list[i].exg,
                    language: sharedService['default'].userSettings.currentLanguage });
                this.get('stockList').push(priceService['default'].stockDS.getStock(list[i].exg, list[i].sym));
                this.set('additionalDataAvailable', true);
            }
        },

        onClearData: function onClearData() {
            priceService['default'].removeIndexRequest(this.get('exg'), this.get('sym'));
            this.set('stock', []);
        }
    });
    Ember['default'].Handlebars.helper('portal-range-display-slider', PortalRangeSlider['default']);
    Ember['default'].Handlebars.helper('fifty-two-wk-hl-slider', FiftyTwoWkHlSlider['default']);

    Ember['default'].Handlebars.helper('getPositiveNegativeStyle', function (value) {
        if (value >= 0) {
            return 'padding-medium snap-period-wrapper-green';
        } else if (value < 0) {
            return 'padding-medium snap-period-wrapper-red';
        }
    });

});