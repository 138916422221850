define('ir-app/models/price/price-protocols/mix-web/mix-request-generator', ['exports', 'ember', 'ir-app/models/price/price-constants', 'ir-app/utils/web-storage', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/models/chart/chart-constants', 'ir-app/utils/utils', 'ir-app/config/extended-settings', 'ir-app/models/shared/language/language-data-store'], function (exports, Ember, PriceConstants, WebStorage, priceService, sharedService, ChartConstants, utils, Config, LanguageDataStore) {

    'use strict';

    exports['default'] = (function () {

        var generateSystemDefinitionsDataUrl = function generateSystemDefinitionsDataUrl(language) {
            var queryParams = {
                RT: PriceConstants['default'].MixRequest.SymbolMetaDetails.RT,
                H: PriceConstants['default'].MixRequestParameters.EnableHeaderTag,
                UNC: PriceConstants['default'].MixRequestParameters.None,
                M: PriceConstants['default'].MixRequestParameters.EnableMetaTag,
                L: language
            };

            return _generateQueryString(PriceConstants['default'].UrlTypes.Announcement, queryParams);
        };
        //
        // Exchange specific requests
        //
        var generateExchangeMasterDataUrl = function generateExchangeMasterDataUrl(exchange, language) {
            var wlVer = WebStorage['default'].getString(WebStorage['default'].getKey(PriceConstants['default'].MetaVersionKeys.WatchList, language));
            var srcVer = WebStorage['default'].getString(WebStorage['default'].getKey(PriceConstants['default'].MetaVersionKeys.ExchangeDefinitions, language));

            var queryParams = {
                RT: PriceConstants['default'].MixRequest.ExchangeFullMeta.RT,
                SRC: exchange,
                MOD: PriceConstants['default'].MetaVersionKeys.WatchList + ':' + (wlVer ? wlVer : '0') + ',' + PriceConstants['default'].MetaVersionKeys.ExchangeDefinitions + ':' + (srcVer ? srcVer : '0'),
                L: language
            };

            return _generateQueryString(PriceConstants['default'].UrlTypes.PRICE, queryParams);
        };

        var generateExchangeDataUrl = function generateExchangeDataUrl(exg) {
            var queryParams = {
                RT: PriceConstants['default'].MixRequest.ExchangeDetails.RT,
                MT: PriceConstants['default'].MixRequest.ExchangeDetails.MT, // Meta types  EXG - Exchange, SUBM - Sub market, IDX - Indices
                AE: PriceConstants['default'].MixRequestParameters.AllExchange,
                INC: 'IM', // Get the main index data only
                UE: exg
            };

            return _generateQueryString(PriceConstants['default'].UrlTypes.IntegratedBackend, queryParams);
        };

        //
        // Symbol specific requests
        //
        var generateSymbolMetaDataUrl = function generateSymbolMetaDataUrl(exchange, language) {
            var queryParams = {
                RT: PriceConstants['default'].MixRequest.FullSymbolDescription.RT,
                SRC: exchange,
                L: language,
                AS: 1
            };

            return _generateQueryString(PriceConstants['default'].UrlTypes.IntegratedBackend, queryParams);
        };

        var generateExchangeMetaDataUrl = function generateExchangeMetaDataUrl(exchange, language) {
            var queryParams = {
                RT: PriceConstants['default'].MixRequest.ExchangeMetaDetails.RT,
                SRC: exchange,
                L: language
            };

            return _generateQueryString(PriceConstants['default'].UrlTypes.IntegratedBackend, queryParams);
        };

        var generateSymbolSearchUrl = function generateSymbolSearchUrl(searchKey, language, pageSize, exchangeList, tempID) {
            var queryParams = {
                RT: PriceConstants['default'].MixRequest.SymbolSearch.RT,
                ST: PriceConstants['default'].MixRequest.SymbolSearch.ST,
                PGI: '0',
                PGS: pageSize,
                IFLD: PriceConstants['default'].MixRequest.SymbolSearch.IFLD,
                XFLD: PriceConstants['default'].MixRequest.SymbolSearch.XFLD,
                SK: searchKey,
                UE: exchangeList !== undefined ? exchangeList : sharedService['default'].userSettings.currentExchange,
                AE: PriceConstants['default'].MixRequestParameters.AllExchange,
                L: language,
                TI: tempID !== undefined ? tempID : '',
                INS: PriceConstants['default'].InstrumentTypes.Equity + utils['default'].Constants.StringConst.Comma + PriceConstants['default'].InstrumentTypes.Indices
            };

            return _generateQueryString(PriceConstants['default'].UrlTypes.Search, queryParams);
        };

        var generateSmartSearchUrl = function generateSmartSearchUrl(searchKey, language, assertTypes, exchanges, searchType) {
            var queryParams = {
                RT: PriceConstants['default'].MixRequest.SmartSearch.RT,
                ST: searchType ? searchType : PriceConstants['default'].MixRequest.SmartSearch.ST,
                PGI: '0',
                PGS: PriceConstants['default'].MixRequest.SmartSearch.PGS,
                IFLD: PriceConstants['default'].MixRequest.SmartSearch.IFLD,
                XFLD: PriceConstants['default'].MixRequest.SmartSearch.XFLD,
                SK: searchKey,
                UE: exchanges ? exchanges : PriceConstants['default'].MixRequest.SmartSearch.UE,
                AE: PriceConstants['default'].MixRequestParameters.AllExchange,
                L: language,
                H: PriceConstants['default'].MixRequestParameters.EnableHeaderTag,
                UNC: PriceConstants['default'].MixRequestParameters.None,
                M: PriceConstants['default'].MixRequestParameters.EnableMetaTag,
                SYMT: assertTypes ? assertTypes : PriceConstants['default'].MixRequest.SmartSearch.SYMT,
                TS: !Date.now ? Ember['default'].$.now() : Date.now()
            };

            return _generateQueryString(PriceConstants['default'].UrlTypes.Search, queryParams);
        };

        var generateSymbolValidationUrl = function generateSymbolValidationUrl(exchange, symbol, instrumentType) {
            var validationSymbol = _getValidationSymbol(exchange, symbol, instrumentType);

            var queryParams = {
                RT: PriceConstants['default'].MixRequest.SymbolValidation.RT,
                E: exchange,
                S: validationSymbol,
                AE: PriceConstants['default'].MixRequestParameters.AllExchange,
                AS: PriceConstants['default'].MixRequestParameters.AllSymbol
            };

            // return _generateQueryString(PriceConstants.UrlTypes.PRICE, queryParams);
            return _generateQueryString(PriceConstants['default'].UrlTypes.IntegratedBackend, queryParams);
        };

        var generateSymbolValidationBulkUrl = function generateSymbolValidationBulkUrl(symbolObjArray) {
            var symArray = [];

            Ember['default'].$.each(symbolObjArray, function (index, valueObj) {
                symArray[symArray.length] = _getValidationSymbol(valueObj.exg, valueObj.sym, valueObj.inst);
            });

            var queryParams = {
                RT: PriceConstants['default'].MixRequest.SymbolValidation.RT,
                S: symArray.join(utils['default'].Constants.StringConst.Comma), // Symbol list
                AE: PriceConstants['default'].MixRequestParameters.AllExchange,
                AS: PriceConstants['default'].MixRequestParameters.AllSymbol,
                UNC: '0'
            };

            return _generateQueryString(PriceConstants['default'].UrlTypes.PRICE, queryParams);
        };

        //
        // News & announcement specific requests
        //
        var generateAnnouncementBodyUrl = function generateAnnouncementBodyUrl(annID, language) {
            var queryParams = {
                RT: PriceConstants['default'].MixRequest.AnnouncementBody.RT,
                AI: annID, // Announcement Id
                L: language + ',' + LanguageDataStore['default'].getChangeToLanguage(language).code
            };

            return _generateQueryString(PriceConstants['default'].UrlTypes.Announcement, queryParams);
        };

        var generateNewsBodyUrl = function generateNewsBodyUrl(newsID, language) {
            var queryParams = {
                RT: PriceConstants['default'].MixRequest.NewsBody.RT,
                NI: newsID, // News Id
                L: language + ',' + LanguageDataStore['default'].getChangeToLanguage(language).code
            };

            return _generateQueryString(PriceConstants['default'].UrlTypes.PRICE, queryParams);
        };

        var generateAnnouncementSearchUrl = function generateAnnouncementSearchUrl(params) {
            var queryParams = {
                RT: PriceConstants['default'].MixRequest.AnnouncementSearch.RT,
                AE: PriceConstants['default'].MixRequestParameters.None,
                UNC: PriceConstants['default'].MixRequestParameters.None,
                PGS: params.pageSize,
                L: sharedService['default'].userSettings.currentLanguage + ',' + LanguageDataStore['default'].getChangeToLanguage(sharedService['default'].userSettings.currentLanguage).code
            };

            if (params.AllExchange) {
                queryParams.AE = params.AllExchange;
            }

            if (params.exgList) {
                queryParams.UE = params.exgList;
            }

            if (params.exchange) {
                queryParams.E = params.exchange;
            }

            if (params.searchKey) {
                queryParams.SK = encodeURI(params.searchKey);
            }

            if (params.symbol) {
                queryParams.S = params.symbol;
            }

            if (params.startDate) {
                queryParams.SD = params.startDate;
            }

            if (params.endDate) {
                queryParams.ED = params.endDate;
            }

            if (params.pageIndex) {
                queryParams.PGI = params.pageIndex;
            }

            return _generateQueryString(PriceConstants['default'].UrlTypes.Announcement, queryParams);
        };

        var generateNewsSearchUrl = function generateNewsSearchUrl(params) {
            var queryParams = {
                RT: PriceConstants['default'].MixRequest.NewsSearch.RT,
                AE: PriceConstants['default'].MixRequestParameters.None,
                UNC: PriceConstants['default'].MixRequestParameters.None,
                PGS: params.pageSize,
                L: sharedService['default'].userSettings.currentLanguage + ',' + LanguageDataStore['default'].getChangeToLanguage(sharedService['default'].userSettings.currentLanguage).code

            };

            if (params.AllExchange) {
                queryParams.AE = params.AllExchange;
            }

            if (params.exchange) {
                queryParams.E = params.exchange;
            }

            if (params.searchKey) {
                queryParams.SK = encodeURI(params.searchKey);
            }

            if (params.symbol) {
                queryParams.S = params.symbol;
            }

            if (params.provider) {
                queryParams.PRV = params.provider;
            }

            if (params.startDate) {
                queryParams.SD = params.startDate;
            }

            if (params.endDate) {
                queryParams.ED = params.endDate;
            }

            if (params.pageIndex) {
                queryParams.PGI = params.pageIndex;
            }

            return _generateQueryString(PriceConstants['default'].UrlTypes.Content, queryParams);
        };

        var generateNewsResourceDownloadUrl = function generateNewsResourceDownloadUrl(id, language, imageSize) {
            var queryParams = {
                RT: PriceConstants['default'].MixRequest.NewsResource.RT,
                MIMG: PriceConstants['default'].MixRequest.NewsResource.MIMG,
                L: language,
                FILEID: id,
                ITC: imageSize !== undefined ? imageSize : ''
            };

            return _generateQueryString(PriceConstants['default'].UrlTypes.Content, queryParams);
        };

        //
        // Company Profile specific requests
        //
        var generateCompanyProfileUrl = function generateCompanyProfileUrl(exchange, symbol) {
            var symbolKey = _getValidationSymbol(exchange, symbol);
            var queryParams = {
                RT: PriceConstants['default'].MixRequest.CompanyProfile.RT,
                S: symbolKey,
                CIT: PriceConstants['default'].MixRequest.CompanyProfile.CIT,
                FC: '1',
                UNC: '0'
            };
            return _generateQueryString(PriceConstants['default'].UrlTypes.IntegratedBackend, queryParams);
        };

        //generate segmentation URL
        var generateSegmentationUrl = function generateSegmentationUrl(exchange, symbol, lan) {
            // var symbolKey = _getValidationSymbol(exchange, symbol);
            var queryParams = {
                RT: PriceConstants['default'].MixRequest.Segmentation.RT,
                L: lan,
                SID: '',
                UID: '',
                DBR: 3,
                SYM: symbol,
                EX: exchange
            };
            return _generateQueryString(PriceConstants['default'].UrlTypes.IntegratedBackend, queryParams);
        };

        var generateCompanyProfileUrlByCompanyId = function generateCompanyProfileUrlByCompanyId(companyId) {
            var queryParams = {
                RT: PriceConstants['default'].MixRequest.CompanyProfile.RT,
                CID: companyId,
                CIT: PriceConstants['default'].MixRequest.CompanyProfile.CIT,
                FC: '1',
                UNC: '0'
            };
            return _generateQueryString(PriceConstants['default'].UrlTypes.IntegratedBackend, queryParams);
        };

        var generateCompanyMgmntUrl = function generateCompanyMgmntUrl(exchange, symbol) {
            var symbolKey = _getValidationSymbol(exchange, symbol);
            var queryParams = {
                RT: PriceConstants['default'].MixRequest.CompanyProfile.RT,
                S: symbolKey,
                CIT: PriceConstants['default'].MixRequest.CompanyMgmnt.CIT,
                FC: '1',
                UNC: '0'
            };
            // return _generateQueryString(PriceConstants.UrlTypes.Content, queryParams);
            return _generateQueryString(PriceConstants['default'].UrlTypes.IntegratedBackend, queryParams);
        };

        var generateCompanyMgmntUrlByCompanyId = function generateCompanyMgmntUrlByCompanyId(companyId) {
            var queryParams = {
                RT: PriceConstants['default'].MixRequest.CompanyProfile.RT,
                CID: companyId,
                CIT: PriceConstants['default'].MixRequest.CompanyMgmnt.CIT,
                FC: '1',
                UNC: '0'
            };
            // return _generateQueryString(PriceConstants.UrlTypes.Content, queryParams);
            return _generateQueryString(PriceConstants['default'].UrlTypes.IntegratedBackend, queryParams);
        };

        //
        // Company Profile request by company ID
        //
        var generateCompanyProfileByIdUrl = function generateCompanyProfileByIdUrl(companyId) {

            var queryParams = {
                RT: PriceConstants['default'].MixRequest.CompanyProfile.RT,
                CIT: PriceConstants['default'].MixRequest.CompanyProfile.CIT,
                FC: '1',
                UNC: '0',
                UE: '',
                CID: companyId
            };
            return _generateQueryString(PriceConstants['default'].UrlTypes.IntegratedBackend, queryParams);
        };

        var generateMajorSharhoderUrl = function generateMajorSharhoderUrl(companyId) {

            var queryParams = {
                RT: PriceConstants['default'].MixRequest.MajorShareholder.RT,
                SCDT: PriceConstants['default'].MixRequest.MajorShareholder.SCDT,
                // SF: 'OWNERSHIP_DATE',
                // SO: 'DESC',
                FC: '1',
                UNC: '0',
                ITK: 'COMPANY_ID' + utils['default'].Constants.StringConst.Colon + companyId
            };
            return _generateQueryString(PriceConstants['default'].UrlTypes.IntegratedBackend, queryParams);
        };

        // Fundamental Data
        var generateFundamentalDataUrl = function generateFundamentalDataUrl(exchange, symbol, mode, language, level, isCal) {

            var symbolKey = _getValidationSymbol(exchange, symbol);
            var queryParams = {
                RT: PriceConstants['default'].MixRequest.FundamentalData.RT,
                S: symbolKey,
                CFT: PriceConstants['default'].MixRequest.FundamentalData.CFT,
                UNC: '0',
                M: '1',
                H: '1',
                L: language,
                DES: '1',
                FY: '1900',
                TY: '2100',
                FQ: '1',
                TQ: '4',
                FC: '1',
                FRM: '1',
                GRP: '1',
                UE: exchange,

                LVL: level !== undefined ? level : '1',
                VT: 'X', // VT:'Q'//Quarterly
                CAL: isCal === undefined ? 1 : isCal

            };
            return _generateQueryString(PriceConstants['default'].UrlTypes.IntegratedBackend, queryParams);
        };

        // Client Fundamental Data
        var generateClientFundamentalDataUrl = function generateClientFundamentalDataUrl(exchange, symbol) {

            var queryParams = {
                RT: PriceConstants['default'].MixRequest.ClientFundamentalData.RT,
                SYM: symbol,
                EX: exchange
            };
            return _generateQueryString(PriceConstants['default'].UrlTypes.IntegratedBackend, queryParams);
        };

        // Fundamental Etstimate Data
        var generateFundamentalEstimateDataUrl = function generateFundamentalEstimateDataUrl(exchange, symbol, language, level) {

            var symbolKey = _getValidationSymbol(exchange, symbol);
            var queryParams = {
                RT: PriceConstants['default'].MixRequest.FundamentalEstData.RT,
                S: symbolKey,
                CFT: PriceConstants['default'].MixRequest.FundamentalEstData.CFT,
                UNC: '0',
                M: '1',
                H: '1',
                L: language,
                DES: '1',
                FY: '1900',
                TY: '2100',
                FQ: '1',
                TQ: '4',
                FC: '1',
                FRM: '1',
                GRP: '1',
                UE: exchange,
                FST: '3',

                LVL: level !== undefined ? level : '5',
                VT: 'X'

            };
            return _generateQueryString(PriceConstants['default'].UrlTypes.IntegratedBackend, queryParams);
        };

        var generateSymbolTimeAndSalesBacklogUrl = function generateSymbolTimeAndSalesBacklogUrl(exchange, symbol, endSequence, startSequence) {

            var queryParams = {
                RT: PriceConstants['default'].MixRequest.TimeAndSalesBacklog.RT,
                E: exchange,
                S: symbol,
                AS: PriceConstants['default'].MixRequestParameters.None,
                CT: '1' // TODO : [Rasike] Need to use relevant constant.
            };

            if (endSequence) {
                queryParams = Ember['default'].$.extend(queryParams, { ENDTSI: endSequence });
            }

            if (startSequence) {
                queryParams = Ember['default'].$.extend(queryParams, { SSTTSI: startSequence });
            }

            return _generateQueryString(PriceConstants['default'].UrlTypes.Content, queryParams);
        };

        //
        // Chart specific requests
        //
        var generateIntradayDataUrl = function generateIntradayDataUrl(exchange, symbol, numberOfDays) {
            var queryParams = {
                RT: PriceConstants['default'].MixRequest.Chart.RT,
                E: exchange,
                S: symbol,
                AE: PriceConstants['default'].MixRequestParameters.AllExchange,
                CM: ChartConstants['default'].ChartDataRequestMode.IntradayActiveStock,
                NOD: numberOfDays, // No of active days
                CT: ChartConstants['default'].ChartDataType.Basic, // Chart type
                UE: exchange + utils['default'].Constants.StringConst.Star // TODO [Akila and Banusha] 15 min delay is hard coded, think of a better way
            };

            return _generateQueryString(PriceConstants['default'].UrlTypes.Chart, queryParams);
        };

        var generateChartDataUrl = function generateChartDataUrl(exchange, symbol, charCategory, begin, isLookupChart) {
            // Todo [Ravindu] CT value needs to be change for get more data in history mode [5] - Corporate action and News
            var beginDate;
            if (charCategory.ID === ChartConstants['default'].ChartCategory.History.ID && begin !== undefined) {
                beginDate = utils['default'].formatters.generateHistoryBeginDateString(begin, 0);
            } else if (charCategory.ID === ChartConstants['default'].ChartCategory.Intraday.ID && begin !== undefined) {
                beginDate = utils['default'].formatters.generateIntradayBeginDateString(begin);
            } else {
                beginDate = utils['default'].formatters.generateChartBeginDateString(charCategory);
            }

            var queryParams = {
                RT: PriceConstants['default'].MixRequest.Chart.RT,
                E: exchange,
                S: symbol,
                AE: PriceConstants['default'].MixRequestParameters.AllExchange,
                CM: charCategory.ID === ChartConstants['default'].ChartCategory.History.ID ? ChartConstants['default'].ChartDataRequestMode.HistoryData : ChartConstants['default'].ChartDataRequestMode.IntradayActiveStock,
                CT: isLookupChart ? ChartConstants['default'].ChartDataType.Advanced : ChartConstants['default'].ChartDataType.Basic, // Chart type
                SD: beginDate,
                ED: utils['default'].formatters.generateChartEndDateString(charCategory)
            };

            return _generateQueryString(PriceConstants['default'].UrlTypes.Chart, queryParams);
        };

        var generateAllLoadDocumentSearchDataUrl = function generateAllLoadDocumentSearchDataUrl(companyId, tickerSerial, scat, history, language) {
            // var tslData = [PriceConstants.MixRequest.FileMetaSearch.TSL, tickerSerial].join(utils.Constants.StringConst.Tilde);
            var cmpData = [PriceConstants['default'].MixRequest.FileMetaSearch.CIL, companyId].join(utils['default'].Constants.StringConst.Tilde);
            var docHistoryTo = ['REPORT_DATE', 4, history[0]].join(utils['default'].Constants.StringConst.Tilde);
            var docHistoryFrom = ['REPORT_DATE', 1, history[1]].join(utils['default'].Constants.StringConst.Tilde);
            //var docHistory = [docHistoryTo,docHistoryFrom].join(utils.Constants.StringConst.Comma);
            // var fdkData = [tslData, cmpData].join(utils.Constants.StringConst.Comma);
            var docHistory = ['REPORT_DATE', 4, '2016'].join(utils['default'].Constants.StringConst.Tilde);
            // Removed 'tslData' for get full list of documents because some documents are tags only with the company.
            var fdkData = [cmpData, docHistory].join(utils['default'].Constants.StringConst.Comma);

            var queryParams = {
                RT: PriceConstants['default'].MixRequest.FileMetaSearch.RT,
                CAT: PriceConstants['default'].MixRequest.FileMetaSearch.CAT,
                SCAT: scat,
                UID: Config['default'].appConfig.customisation.dcmsUser,
                // [TODO] Rasike Need to update with authenticated UID, SID - now invalid user prompt.
                // UID: priceService.userDS.userId,
                // SID: priceService.userDS.sessionId,
                PGI: '0',
                PGS: PriceConstants['default'].MixRequest.FileMetaSearch.PGS,
                L: language,
                SID: Config['default'].appConfig.customisation.dcmsSessionId,
                FDK: fdkData,
                FC: PriceConstants['default'].MixRequest.FileMetaSearch.FC,
                IFC: PriceConstants['default'].MixRequest.FileMetaSearch.IFC,
                SF: PriceConstants['default'].MixRequest.FileMetaSearch.SF,
                SO: PriceConstants['default'].MixRequest.FileMetaSearch.SO
            };

            var queryArray = [];
            for (var prop in queryParams) {
                if (queryParams.hasOwnProperty(prop)) {
                    var encoded = encodeURIComponent(queryParams[prop]);
                    queryArray[queryArray.length] = [prop, encoded].join(utils['default'].Constants.StringConst.Equal);
                }
            }
            var queryString = queryArray.join(utils['default'].Constants.StringConst.Ampersand);
            return [PriceConstants['default'].UrlTypes.Content, queryString].join(utils['default'].Constants.StringConst.Question);
        };

        var generateLoadDocumentSearchDataUrl = function generateLoadDocumentSearchDataUrl(companyId, tickerSerial, scat, history, language) {
            // var tslData = [PriceConstants.MixRequest.FileMetaSearch.TSL, tickerSerial].join(utils.Constants.StringConst.Tilde);
            var cmpData = [PriceConstants['default'].MixRequest.FileMetaSearch.CIL, companyId].join(utils['default'].Constants.StringConst.Tilde);
            var docHistory = [PriceConstants['default'].MixRequest.FileMetaSearch.HIS, history].join(utils['default'].Constants.StringConst.Tilde);
            // var fdkData = [tslData, cmpData].join(utils.Constants.StringConst.Comma);
            // Removed 'tslData' for get full list of documents because some documents are tags only with the company.
            var fdkData = [cmpData, docHistory].join(utils['default'].Constants.StringConst.Comma);

            var queryParams = {
                RT: PriceConstants['default'].MixRequest.FileMetaSearch.RT,
                CAT: PriceConstants['default'].MixRequest.FileMetaSearch.CAT,
                SCAT: scat,
                UID: Config['default'].appConfig.customisation.dcmsUser,
                // [TODO] Rasike Need to update with authenticated UID, SID - now invalid user prompt.
                // UID: priceService.userDS.userId,
                // SID: priceService.userDS.sessionId,
                PGI: '0',
                PGS: PriceConstants['default'].MixRequest.FileMetaSearch.PGS,
                L: language,
                SID: Config['default'].appConfig.customisation.dcmsSessionId,
                FDK: fdkData,
                FC: PriceConstants['default'].MixRequest.FileMetaSearch.FC,
                IFC: PriceConstants['default'].MixRequest.FileMetaSearch.IFC,
                SF: PriceConstants['default'].MixRequest.FileMetaSearch.SF,
                SO: PriceConstants['default'].MixRequest.FileMetaSearch.SO
            };

            var queryArray = [];
            for (var prop in queryParams) {
                if (queryParams.hasOwnProperty(prop)) {
                    var encoded = encodeURIComponent(queryParams[prop]);
                    queryArray[queryArray.length] = [prop, encoded].join(utils['default'].Constants.StringConst.Equal);
                }
            }
            var queryString = queryArray.join(utils['default'].Constants.StringConst.Ampersand);
            return [PriceConstants['default'].UrlTypes.Content, queryString].join(utils['default'].Constants.StringConst.Question);
        };

        var generateEventCalenderUrl = function generateEventCalenderUrl(params) {
            var queryParams = {
                RT: PriceConstants['default'].MixRequest.EventCalendar.RT,
                SCDT: PriceConstants['default'].MixRequest.EventCalendar.SCDT,
                SF: '', // Start Date
                SO: PriceConstants['default'].MixRequest.EventCalendar.SO,
                FC: PriceConstants['default'].MixRequest.EventCalendar.FC,
                L: params.language,
                PGS: PriceConstants['default'].MixRequest.EventCalendar.PGS,
                S: [params.exchange, params.symbol].join(utils['default'].Constants.StringConst.Tilde),
                E: params.exchange,
                FDK: [['12459', '4', params.startDate].join(utils['default'].Constants.StringConst.Tilde), [params.secSortField, '0', params.categoryId].join(utils['default'].Constants.StringConst.Tilde)].join(utils['default'].Constants.StringConst.Comma),
                PGI: ''
            };

            if (params.sortField !== undefined) {
                queryParams.SF = params.sortField;
            }
            if (params.pageIndex !== undefined) {
                queryParams.PGI = params.pageIndex;
            }
            if (params.pageSize !== undefined) {
                queryParams.PGS = params.pageSize;
            }

            if (params.startDate && params.endDate) {
                queryParams.FDK = ['12459', '4', params.startDate].join(utils['default'].Constants.StringConst.Tilde) + ',' + ['12459', '2', params.endDate].join(utils['default'].Constants.StringConst.Tilde) + ',' + [params.secSortField, '0', params.categoryId].join(utils['default'].Constants.StringConst.Tilde);
            } else if (params.startDate) {
                queryParams.FDK = ['12459', '4', params.startDate].join(utils['default'].Constants.StringConst.Tilde) + ',' + [params.secSortField, '0', params.categoryId].join(utils['default'].Constants.StringConst.Tilde);
            } else if (params.endDate) {
                queryParams.FDK = ['12459', '2', params.endDate].join(utils['default'].Constants.StringConst.Tilde) + ',' + [params.secSortField, '0', params.categoryId].join(utils['default'].Constants.StringConst.Tilde);
            }

            if (params.symbol === 'A') {
                queryParams.S = '';
            }
            return _generateQueryString(PriceConstants['default'].UrlTypes.CalenderEvents, queryParams);
        };

        var generateDividendUrl = function generateDividendUrl(exchange, symbol, startDate, endDate) {
            var queryParams = {
                RT: PriceConstants['default'].MixRequest.Dividend.RT,
                SCDT: PriceConstants['default'].MixRequest.Dividend.SCDT,
                PGI: PriceConstants['default'].MixRequest.Dividend.PGI,
                FC: PriceConstants['default'].MixRequest.Dividend.FC,
                E: exchange,
                S: exchange + utils['default'].Constants.StringConst.Tilde + symbol,
                FDK: '12419' + utils['default'].Constants.StringConst.Tilde + '4' + utils['default'].Constants.StringConst.Tilde + startDate + utils['default'].Constants.StringConst.Comma + '12419' + utils['default'].Constants.StringConst.Tilde + '2' + utils['default'].Constants.StringConst.Tilde + endDate,
                UNC: 0
            };
            return _generateQueryString(PriceConstants['default'].UrlTypes.Content, queryParams);
        };

        var generateInsiderTradesUrl = function generateInsiderTradesUrl(exchange, symbol) {
            var queryParams = {
                RT: PriceConstants['default'].MixRequest.InsiderTrades.RT,
                SCDT: PriceConstants['default'].MixRequest.InsiderTrades.SCDT,
                PGI: PriceConstants['default'].MixRequest.InsiderTrades.PGI,
                FC: PriceConstants['default'].MixRequest.InsiderTrades.FC,
                E: exchange,
                S: exchange + utils['default'].Constants.StringConst.Tilde + symbol,
                FDK: 'EXCHANGE' + utils['default'].Constants.StringConst.Tilde + '0' + utils['default'].Constants.StringConst.Tilde + exchange + utils['default'].Constants.StringConst.Comma + 'SYMBOL' + utils['default'].Constants.StringConst.Tilde + '0' + utils['default'].Constants.StringConst.Tilde + symbol,
                UNC: 0,
                SF: 'INS_TRD_DATE',
                SO: 'DESC'
            };
            return _generateQueryString(PriceConstants['default'].UrlTypes.Content, queryParams);
        };

        var generateCurrencyDataUrl = function generateCurrencyDataUrl(pairCode) {
            var queryParams = {
                RT: PriceConstants['default'].MixRequest.Currency.RT,
                CRP: pairCode,
                M: PriceConstants['default'].MixRequest.Currency.M,
                H: PriceConstants['default'].MixRequest.Currency.H
            };

            return _generateQueryString(PriceConstants['default'].UrlTypes.PRICE, queryParams);
        };

        //
        // private functions
        //

        var _generateQueryString = function _generateQueryString(urlPrefix, params) {
            var url,
                queryParams = _getQueryParam(params),
                queryArray = [];

            for (var prop in queryParams) {
                if (queryParams.hasOwnProperty(prop)) {
                    var encoded = encodeURIComponent(queryParams[prop]);
                    queryArray[queryArray.length] = [prop, encoded].join(utils['default'].Constants.StringConst.Equal);
                }
            }

            var queryString = queryArray.join(utils['default'].Constants.StringConst.Ampersand);
            url = [urlPrefix, queryString].join(utils['default'].Constants.StringConst.Question);
            return url;
        };

        var _getQueryParam = function _getQueryParam(params) {
            // Add general parameters
            var queryParams = {
                UNC: PriceConstants['default'].MixRequestParameters.None,
                M: PriceConstants['default'].MixRequestParameters.EnableMetaTag,
                H: PriceConstants['default'].MixRequestParameters.EnableHeaderTag,
                UID: priceService['default'].userDS.userId,
                SID: priceService['default'].userDS.sessionId,
                UE: sharedService['default'].userSettings.currentExchange,
                L: sharedService['default'].userSettings.currentLanguage // User current Language
            };

            if (params) {
                for (var prop in params) {
                    if (params.hasOwnProperty(prop)) {
                        queryParams[prop] = params[prop];
                    }
                }
            }

            return queryParams;
        };

        var _getValidationSymbol = function _getValidationSymbol(exchange, symbol, instrumentType) {
            if (utils['default'].validators.isAvailable(instrumentType)) {
                return [exchange, instrumentType, symbol].join(utils['default'].Constants.StringConst.Tilde);
            } else {
                return [exchange, symbol].join(utils['default'].Constants.StringConst.Tilde);
            }
        };

        var generateExchangeDataBulkUrl = function generateExchangeDataBulkUrl(exchangeArray, language) {
            var queryParams = {
                RT: PriceConstants['default'].MixRequest.ExchangeDetails.RT,
                UE: exchangeArray.join(utils['default'].Constants.StringConst.Comma), // Exchange Code list
                AE: PriceConstants['default'].MixRequestParameters.AllExchange,
                L: language,
                UNC: '0',
                MT: PriceConstants['default'].MixRequest.ExchangeDetails.MT,
                INC: 'IM'
            };

            return _generateQueryString(PriceConstants['default'].UrlTypes.PRICE, queryParams);
        };

        var generateCapitalActionDataUrl = function generateCapitalActionDataUrl(exchange, symbol, language, dataType, params) {

            var queryParams = {
                RT: PriceConstants['default'].MixRequest.CapitalAction.RT,
                UID: priceService['default'].userDS.userId,
                SID: priceService['default'].userDS.sessionId,
                H: PriceConstants['default'].MixRequest.CorporateAction.H,
                M: PriceConstants['default'].MixRequest.CorporateAction.M,
                L: language,
                UNC: '0',
                IFC: '1',
                FC: '1',
                SCDT: PriceConstants['default'].MixRequest.CapitalAction.SCDT,
                SO: 'DESC',
                SF: 'ANNOUNCE_DATE'
            };

            if (params.custView && params.actType > 0) {
                queryParams.ITK = 'EXCHANGE' + utils['default'].Constants.StringConst.Colon + exchange + utils['default'].Constants.StringConst.Comma + 'SYMBOL' + utils['default'].Constants.StringConst.Colon + symbol + utils['default'].Constants.StringConst.Comma + 'SYMBOL_STATUS' + utils['default'].Constants.StringConst.Colon + '1' + utils['default'].Constants.StringConst.Comma + 'ACTION_TYPE' + utils['default'].Constants.StringConst.Colon + params.actType;
            } else if (params.custView && params.actType === 0) {
                var actTypes = params.idList.join('" OR "');
                queryParams.ITK = 'ACTION_TYPE' + utils['default'].Constants.StringConst.Colon + utils['default'].Constants.StringConst.OpenBracket + utils['default'].Constants.StringConst.Quote + actTypes + utils['default'].Constants.StringConst.Quote + utils['default'].Constants.StringConst.CloseBracket;
                queryParams.S = [exchange, symbol].join(utils['default'].Constants.StringConst.Tilde);
            } else if (!params.custView && params.actType > 0) {
                queryParams.ITK = 'EXCHANGE' + utils['default'].Constants.StringConst.Colon + exchange + utils['default'].Constants.StringConst.Comma + 'SYMBOL' + utils['default'].Constants.StringConst.Colon + symbol + utils['default'].Constants.StringConst.Comma + 'SYMBOL_STATUS' + utils['default'].Constants.StringConst.Colon + '1' + utils['default'].Constants.StringConst.Comma + 'ACTION_TYPE' + utils['default'].Constants.StringConst.Colon + params.actType;
            } else if (!params.custView && params.actType === 0) {
                queryParams.ITK = 'EXCHANGE' + utils['default'].Constants.StringConst.Colon + exchange + utils['default'].Constants.StringConst.Comma + 'SYMBOL' + utils['default'].Constants.StringConst.Colon + symbol + utils['default'].Constants.StringConst.Comma + 'SYMBOL_STATUS' + utils['default'].Constants.StringConst.Colon + '1';
            }
            queryParams.PGS = params.pageSize;
            queryParams.PGI = params.pageIdx;

            return _generateQueryString(PriceConstants['default'].UrlTypes.Content, queryParams);
        };

        var generateNonCapitalActionDataUrl = function generateNonCapitalActionDataUrl(exchange, symbol, language, dataType, companyId) {

            var itk = '';
            if (dataType === PriceConstants['default'].DataTypes.CorporateActionDataStore.SymbolBasedCall) {
                itk = 'EXCHANGE' + utils['default'].Constants.StringConst.Colon + exchange + utils['default'].Constants.StringConst.Comma + 'SYMBOL' + utils['default'].Constants.StringConst.Colon + symbol + utils['default'].Constants.StringConst.Comma + 'SYMBOL_STATUS' + utils['default'].Constants.StringConst.Colon + '1';
            } else {
                itk = 'COMPANY_ID' + utils['default'].Constants.StringConst.Colon + companyId;
            }
            // get only previous five years data (year filtered as per the EFFECTIVE_DATE column)
            var dt = new Date();
            dt.setFullYear(dt.getFullYear() - 5);
            var tmpDate = utils['default'].formatters.convertDateToyyyymmdd(dt);

            var queryParams = {
                RT: PriceConstants['default'].MixRequest.CorporateAction.RT,
                UID: priceService['default'].userDS.userId,
                SID: priceService['default'].userDS.sessionId,
                H: PriceConstants['default'].MixRequest.CorporateAction.H,
                M: PriceConstants['default'].MixRequest.CorporateAction.M,
                L: language,
                UNC: '0',
                PGS: '1000',
                IFC: '1',
                FC: '1',
                SCDT: PriceConstants['default'].DataTypes.CorporateActionDataStore.NonCapitalActionInfo,
                SO: 'DESC',
                SF: 'EFFECTIVE_DATE',
                FDK: 'EFFECTIVE_DATE' + utils['default'].Constants.StringConst.Tilde + 4 + utils['default'].Constants.StringConst.Tilde + tmpDate,
                ITK: itk

            };

            return _generateQueryString(PriceConstants['default'].UrlTypes.Content, queryParams);
        };

        var generateCapOrNonCapDataUrl = function generateCapOrNonCapDataUrl(type, language) {

            var queryParams = {
                RT: PriceConstants['default'].MixRequest.CorporateAction.RT,
                L: language,
                SCDT: type,
                FC: '1',
                IFC: '1',
                PGS: '50'
            };

            return _generateQueryString(PriceConstants['default'].UrlTypes.Content, queryParams);
        };

        var generateCapActDetailUrl = function generateCapActDetailUrl(actionId, language) {

            var queryParams = {
                RT: PriceConstants['default'].MixRequest.CorporateAction.RT,
                UID: priceService['default'].userDS.userId,
                SID: priceService['default'].userDS.sessionId,
                H: PriceConstants['default'].MixRequest.CorporateAction.H,
                M: PriceConstants['default'].MixRequest.CorporateAction.M,
                L: language,
                UNC: '0',
                PGS: '1000',
                IFC: '1',
                SCDT: PriceConstants['default'].DataTypes.CorporateActionDataStore.CapitalActionInfo,
                FC: '1',
                PGI: '0',
                ITK: 'ACTION_ID' + utils['default'].Constants.StringConst.Colon + actionId

            };

            return _generateQueryString(PriceConstants['default'].UrlTypes.Content, queryParams);
        };

        var generateForeignOwnershipUrl = function generateForeignOwnershipUrl(exchange, symbol) {

            var queryParams = {
                RT: PriceConstants['default'].MixRequest.OwnershipLimit.RT,
                L: PriceConstants['default'].MixRequest.OwnershipLimit.L, // IBE Ignore the Language Code when data request
                SCDT: PriceConstants['default'].MixRequest.OwnershipLimit.SCDT,
                FC: '1',
                IFC: '1',
                PGI: '0',
                PGS: '2',
                SF: PriceConstants['default'].MixRequest.OwnershipLimit.SF,
                ITK: 'EXCHANGE' + utils['default'].Constants.StringConst.Colon + exchange + utils['default'].Constants.StringConst.Comma + 'SYMBOL' + utils['default'].Constants.StringConst.Colon + symbol
            };

            return _generateQueryString(PriceConstants['default'].UrlTypes.IntegratedBackend, queryParams);
        };

        var generateIpoDataUrl = function generateIpoDataUrl(requestType, language, params, dataType) {

            var queryParams = {
                RT: PriceConstants['default'].MixRequest.IpoData.RT,
                L: language,
                PL: language,
                SCDT: PriceConstants['default'].MixRequest.IpoData.SCDT,
                FC: '1',
                IFC: '1',
                UNC: '0',
                H: '1',
                SO: 'DESC',
                SF: 'ANNOUNCE_DATE',
                PI: PriceConstants['default'].MixRequest.IpoData.PI
            };

            if (dataType !== PriceConstants['default'].DataTypes.IpoDataList.IpoById) {
                if (params && params.startDate && params.endDate) {
                    queryParams.SQ = 'ANNOUNCE_DATE' + utils['default'].Constants.StringConst.Colon + utils['default'].Constants.StringConst.OpenBracket + params.startDate + utils['default'].Constants.StringConst.Space + 'TO' + utils['default'].Constants.StringConst.Space + params.endDate + utils['default'].Constants.StringConst.CloseBracket;
                }

                if (params.ipoType > 0) {
                    if (requestType === PriceConstants['default'].DataTypes.IpoDataList.AllData) {
                        queryParams.ITK = 'STATUS' + utils['default'].Constants.StringConst.Colon + params.ipoType;
                    } else {
                        queryParams.ITK = 'EXCHANGE' + utils['default'].Constants.StringConst.Colon + requestType + utils['default'].Constants.StringConst.Comma + 'STATUS' + utils['default'].Constants.StringConst.Colon + params.ipoType;
                    }
                } else if (requestType !== PriceConstants['default'].DataTypes.IpoDataList.AllData) {
                    if (params.ipoType === 0 && params.statusIdList && params.statusIdList.length > 0) {
                        var statusTypes = params.statusIdList.join('" OR "');
                        queryParams.ITK = 'EXCHANGE' + utils['default'].Constants.StringConst.Colon + requestType + utils['default'].Constants.StringConst.Comma + 'STATUS' + utils['default'].Constants.StringConst.Colon + utils['default'].Constants.StringConst.OpenBracket + utils['default'].Constants.StringConst.Quote + statusTypes + utils['default'].Constants.StringConst.Quote + utils['default'].Constants.StringConst.CloseBracket;
                    } else {
                        queryParams.ITK = 'EXCHANGE' + utils['default'].Constants.StringConst.Colon + requestType;
                    }
                } else if (params.ipoType === 0 && requestType === PriceConstants['default'].DataTypes.IpoDataList.AllData && !params.isCustomize) {
                    var statusList = params.statusIdList.join('" OR "');
                    queryParams.ITK = 'STATUS' + utils['default'].Constants.StringConst.Colon + utils['default'].Constants.StringConst.OpenBracket + utils['default'].Constants.StringConst.Quote + statusList + utils['default'].Constants.StringConst.Quote + utils['default'].Constants.StringConst.CloseBracket;
                }

                if (params.companyId && queryParams.ITK) {
                    queryParams.ITK = queryParams.ITK + utils['default'].Constants.StringConst.Comma + 'COMPANY_ID' + utils['default'].Constants.StringConst.Colon + params.companyId;
                } else if (params.companyId > 0 && !queryParams.ITK) {
                    queryParams.ITK = 'COMPANY_ID' + utils['default'].Constants.StringConst.Colon + params.companyId;
                }

                if (params.isCustomize) {
                    if (params.ipoIndstryId > 0 && queryParams.ITK) {
                        queryParams.ITK = queryParams.ITK + utils['default'].Constants.StringConst.Comma + 'INDUSTRY' + utils['default'].Constants.StringConst.Colon + params.ipoIndstryId;
                    } else if (params.ipoIndstryId > 0 && !queryParams.ITK) {
                        queryParams.ITK = 'INDUSTRY' + utils['default'].Constants.StringConst.Colon + params.ipoIndstryId;
                    }

                    if (params.ipoCategory && queryParams.ITK) {
                        queryParams.ITK = queryParams.ITK + utils['default'].Constants.StringConst.Comma + 'IPO_SUBSCRIPTION_TYPE' + utils['default'].Constants.StringConst.Colon + params.ipoCategory;
                    } else if (params.ipoCategory && !queryParams.ITK) {
                        queryParams.ITK = 'IPO_SUBSCRIPTION_TYPE' + utils['default'].Constants.StringConst.Colon + params.ipoCategory;
                    }
                }
                if (params.sortMixColumn) {
                    queryParams.SF = params.sortMixColumn;
                    queryParams.SO = params.isAscSort ? 'ASC' : 'DESC';
                }
            } else {
                queryParams.ITK = 'IPO_ID' + utils['default'].Constants.StringConst.Colon + params.ipoId;
            }

            queryParams.PGS = params.pageSize;
            queryParams.PGI = params.pageIdx;

            return _generateQueryString(PriceConstants['default'].UrlTypes.IntegratedBackend, queryParams);
        };

        var generateIPOCompanyUrl = function generateIPOCompanyUrl(language, dataType) {

            var queryParams = {
                RT: PriceConstants['default'].MixRequest.IpoData.RT,
                L: language,
                PL: language,
                SCDT: PriceConstants['default'].DataTypes.IpoDataList.IPOCompList,
                FC: '1',
                IFC: '1',
                UNC: '0',
                H: '1',
                PGS: '1000',
                PGI: '0'
            };

            if (dataType === PriceConstants['default'].DataTypes.IpoDataList.IPOCompList) {
                queryParams.SCDT = PriceConstants['default'].DataTypes.IpoDataList.IPOCompList;
            } else {
                queryParams.SCDT = PriceConstants['default'].DataTypes.IpoDataList.IPOIndustryList;
            }

            return _generateQueryString(PriceConstants['default'].UrlTypes.IntegratedBackend, queryParams);
        };

        var generateFairValuesUrl = function generateFairValuesUrl(exchange, symbol) {

            var queryParams = {
                RT: PriceConstants['default'].MixRequest.FairValues.RT,
                SCDT: PriceConstants['default'].MixRequest.FairValues.SCDT,
                FC: '1',
                IFC: '1',
                UNC: '0',
                H: '1',
                M: '1',
                PGS: '1000',
                SO: 'DESC',
                SF: 'FV_DATE',
                ITK: 'EXCHANGE' + utils['default'].Constants.StringConst.Colon + exchange + utils['default'].Constants.StringConst.Comma + 'SYMBOL' + utils['default'].Constants.StringConst.Colon + symbol
            };

            return _generateQueryString(PriceConstants['default'].UrlTypes.IntegratedBackend, queryParams);
        };

        var generateDocumentDownloadUrl = function generateDocumentDownloadUrl(docId) {

            var queryParams = {
                RT: PriceConstants['default'].MixRequest.DocumentDownload.RT,
                DOC: docId
            };

            return _generateQueryString(PriceConstants['default'].UrlTypes.IntegratedBackend, queryParams);
        };

        var generateRatioComparisonUrl = function generateRatioComparisonUrl(exchange, symbol, instType, dataType) {

            var symbolKey = _getValidationSymbol(exchange, symbol, instType);
            var queryParams = {
                RT: PriceConstants['default'].MixRequest.RatioData.RT,
                P: dataType,
                UNC: '0',
                S: symbolKey
            };

            return _generateQueryString(PriceConstants['default'].UrlTypes.IntegratedBackend, queryParams);
        };

        var generateContactPersonUrl = function generateContactPersonUrl(individualId, companyId) {
            var queryParams = {
                RT: PriceConstants['default'].MixRequest.IndProfile.RT,
                PRTCL: 'N',
                FC: '1',
                IFC: '1',
                UNC: '0',
                CIT: PriceConstants['default'].MixRequest.IndProfile.CIT,
                ITK: PriceConstants['default'].MixRequest.IndProfile.ITK + utils['default'].Constants.StringConst.Colon + individualId + utils['default'].Constants.StringConst.Comma + 'COMPANY_ID' + utils['default'].Constants.StringConst.Colon + companyId,
                UE: ''
            };

            return _generateQueryString(PriceConstants['default'].UrlTypes.Content, queryParams);
        };

        var generateSendEmailUrl = function generateSendEmailUrl(emailObj) {
            var queryParams = {
                RT: PriceConstants['default'].MixRequest.EmailData.RT,
                ID: emailObj.clientId,
                DATA: 'email|' + emailObj.email + ',tel|' + emailObj.phone + ',name|' + emailObj.name + ',message|' + emailObj.message
            };

            return _generateQueryString(PriceConstants['default'].UrlTypes.IntegratedBackend, queryParams);
        };

        var generateCompanyCreditRatingsUrl = function generateCompanyCreditRatingsUrl(exchange, symbol, language) {
            var queryParams = {
                RT: PriceConstants['default'].MixRequest.CreditRatings.RT,
                ID: PriceConstants['default'].MixRequest.CreditRatings.ID,
                SYM: symbol,
                EX: exchange,
                LN: language
            };
            return _generateQueryString(PriceConstants['default'].UrlTypes.IntegratedBackend, queryParams);
        };

        var generateCompanyAnalystContactsUrl = function generateCompanyAnalystContactsUrl(exchange, symbol, language) {
            var queryParams = {
                RT: PriceConstants['default'].MixRequest.AnalystContacts.RT,
                ID: PriceConstants['default'].MixRequest.AnalystContacts.ID,
                SYM: symbol,
                EX: exchange,
                LN: language
            };
            return _generateQueryString(PriceConstants['default'].UrlTypes.IntegratedBackend, queryParams);
        };

        var generateCompanyShareholderSegmentationUrl = function generateCompanyShareholderSegmentationUrl(exchange, symbol, companyId, language) {
            var symbolKey = _getValidationSymbol(exchange, symbol);
            var queryParams = {

                RT: PriceConstants['default'].MixRequest.ShareholderSegmentation.RT,
                S: symbolKey,
                ITK: 'COMPANY_ID:' + companyId,
                SCDT: PriceConstants['default'].MixRequest.ShareholderSegmentation.SCDT,
                LN: language,
                PGS: '10000'
            };
            return _generateQueryString(PriceConstants['default'].UrlTypes.IntegratedBackend, queryParams);
        };

        var generateCompanyOwnershipLimitsUrl = function generateCompanyOwnershipLimitsUrl(exchange, symbol) {
            var symbolKey = _getValidationSymbol(exchange, symbol);
            var queryParams = {
                RT: PriceConstants['default'].MixRequest.CompanyOwnerLmts.RT,
                S: symbolKey,
                SCDT: PriceConstants['default'].MixRequest.CompanyOwnerLmts.SCDT,
                FC: '1',
                UNC: '0'
            };
            return _generateQueryString(PriceConstants['default'].UrlTypes.IntegratedBackend, queryParams);
        };
        var generateVideoFileUrl = function generateVideoFileUrl(exchange, symbol) {
            var queryParams = {
                RT: 5311,
                SYM: symbol,
                EX: exchange
            };
            return _generateQueryString(PriceConstants['default'].UrlTypes.IntegratedBackend, queryParams);
        };

        var generateDocumentUrl = function generateDocumentUrl(exchange, symbol) {
            var queryParams = {
                RT: 5313,
                SYM: symbol,
                EX: exchange
            };
            return _generateQueryString(PriceConstants['default'].UrlTypes.IntegratedBackend, queryParams);
        };
        var generateAlertUrl = function generateAlertUrl(exchange, symbol, action, criteria, fname, lname, email, sid, uid) {
            var queryParams = {
                RT: 83,
                FNAME: fname,
                LNAME: lname,
                EML: email,
                UID: uid,
                SID: sid,
                AT: action,
                AC: criteria,
                SYM: symbol,
                EXG: exchange
            };
            return _generateQueryString(PriceConstants['default'].UrlTypes.IntegratedBackend, queryParams);
        };

        var generateCalEventSubUrl = function generateCalEventSubUrl(exchange, symbol, criteria, fName, lName, email, profession, company, sid, uid) {
            var queryParams = {
                RT: 83,
                FNAME: fName,
                LNAME: lName,
                EML: email,
                PRF: profession,
                COMP: company,
                UID: uid,
                SID: sid,
                AC: criteria,
                SYM: symbol,
                EXG: exchange,
                AT: 'S'
            };
            return _generateQueryString(PriceConstants['default'].UrlTypes.IntegratedBackend, queryParams);
        };
        var generateAlertUserDataUrl = function generateAlertUserDataUrl(id, action) {
            var queryParams = {
                RT: 83,
                AT: action,
                ID: id,
                EXG: 'TDWL',
                SYM: '1010'
            };
            return _generateQueryString(PriceConstants['default'].UrlTypes.IntegratedBackend, queryParams);
        };
        var generateServerVersionUrl = function generateServerVersionUrl() {
            var queryParams = {
                UID: priceService['default'].userDS.userId,
                SID: priceService['default'].userDS.sessionId,
                RT: PriceConstants['default'].MixRequest.ServerVersion.RT
            };
            return _generateQueryString(PriceConstants['default'].UrlTypes.IntegratedBackend, queryParams);
        };

        return {
            generateAllLoadDocumentSearchDataUrl: generateAllLoadDocumentSearchDataUrl,
            generateExchangeMasterDataUrl: generateExchangeMasterDataUrl,
            generateExchangeDataUrl: generateExchangeDataUrl,
            generateSymbolMetaDataUrl: generateSymbolMetaDataUrl,
            generateSymbolValidationUrl: generateSymbolValidationUrl,
            generateSymbolValidationBulkUrl: generateSymbolValidationBulkUrl,
            generateSymbolSearchUrl: generateSymbolSearchUrl,
            generateSmartSearchUrl: generateSmartSearchUrl,
            generateIntradayDataUrl: generateIntradayDataUrl,
            generateChartDataUrl: generateChartDataUrl,
            generateAnnouncementBodyUrl: generateAnnouncementBodyUrl,
            generateNewsBodyUrl: generateNewsBodyUrl,
            generateAnnouncementSearchUrl: generateAnnouncementSearchUrl,
            generateNewsSearchUrl: generateNewsSearchUrl,
            generateNewsResourceDownloadUrl: generateNewsResourceDownloadUrl,
            generateCompanyProfileUrl: generateCompanyProfileUrl,
            generateCompanyProfileUrlByCompanyId: generateCompanyProfileUrlByCompanyId,
            generateCompanyMgmntUrl: generateCompanyMgmntUrl,
            generateCompanyMgmntUrlByCompanyId: generateCompanyMgmntUrlByCompanyId,
            generateSymbolTimeAndSalesBacklogUrl: generateSymbolTimeAndSalesBacklogUrl,
            generateFundamentalDataUrl: generateFundamentalDataUrl,
            generateClientFundamentalDataUrl: generateClientFundamentalDataUrl,
            generateLoadDocumentSearchDataUrl: generateLoadDocumentSearchDataUrl,
            generateEventCalenderUrl: generateEventCalenderUrl,
            generateCurrencyDataUrl: generateCurrencyDataUrl,
            generateDividendUrl: generateDividendUrl,
            generateInsiderTradesUrl: generateInsiderTradesUrl,
            generateExchangeDataBulkUrl: generateExchangeDataBulkUrl,
            generateExchangeMetaDataUrl: generateExchangeMetaDataUrl,
            generateCapitalActionDataUrl: generateCapitalActionDataUrl,
            generateNonCapitalActionDataUrl: generateNonCapitalActionDataUrl,
            generateCapOrNonCapDataUrl: generateCapOrNonCapDataUrl,
            generateCapActDetailUrl: generateCapActDetailUrl,
            generateForeignOwnershipUrl: generateForeignOwnershipUrl,
            generateIpoDataUrl: generateIpoDataUrl,
            generateIPOCompanyUrl: generateIPOCompanyUrl,
            generateCompanyProfileByIdUrl: generateCompanyProfileByIdUrl,
            generateFairValuesUrl: generateFairValuesUrl,
            generateDocumentDownloadUrl: generateDocumentDownloadUrl,
            generateMajorSharhoderUrl: generateMajorSharhoderUrl,
            generateRatioComparisonUrl: generateRatioComparisonUrl,
            generateContactPersonUrl: generateContactPersonUrl,
            generateSendEmailUrl: generateSendEmailUrl,
            generateFundamentalEstimateDataUrl: generateFundamentalEstimateDataUrl,
            generateCompanyCreditRatingsUrl: generateCompanyCreditRatingsUrl,
            generateCompanyAnalystContactsUrl: generateCompanyAnalystContactsUrl,
            generateSegmentationUrl: generateSegmentationUrl,
            generateCompanyShareholderSegmentationUrl: generateCompanyShareholderSegmentationUrl,
            generateCompanyOwnershipLimitsUrl: generateCompanyOwnershipLimitsUrl,
            generateSystemDefinitionsDataUrl: generateSystemDefinitionsDataUrl,
            generateVideoFileUrl: generateVideoFileUrl,
            generateDocumentUrl: generateDocumentUrl,
            generateAlertUrl: generateAlertUrl,
            generateCalEventSubUrl: generateCalEventSubUrl,
            generateServerVersionUrl: generateServerVersionUrl,
            generateAlertUserDataUrl: generateAlertUserDataUrl

        };
    })();

});