define('ir-app/components/stk-specific-components/candle-chart', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    /**
     * Created by User on 2/27/2016.
     */

    exports['default'] = Ember['default'].Component.extend({

        ohlcObject: null,
        ohlcObjectList: [],
        tagName: 'canvas',
        width: 200,
        height: 300,
        attributeBindings: ['width', 'height'],
        colorPositive: '#00FF00',
        colorNegative: '#FF0000',
        heightFactor: 20,
        widthFactor: 5,
        candleGap: 40,
        candleBarWidth: 15,
        hilightColor: '#000000',

        didInsertElement: function didInsertElement() {
            // gotta set ctxf here instead of in init because
            // the element might not be in the dom yet in init
            this.set('ctx', this.get('element').getContext('2d'));
            this._empty();
            this.draw();
        },

        draw: (function () {
            this._empty();
            var startX = 40;
            var startY = this._getStartingPoint();
            var prvClose = 0;
            // draw the candle
            for (var x = 0; x < this.get('ohlcObjectList').length; x++) {
                if (prvClose !== 0) {
                    startY = startY + (prvClose - this.get('ohlcObjectList')[x].Close) * this.get('widthFactor');
                }
                this.drawCandleChartPoint(startX, startY, this.get('ohlcObjectList')[x], this._isSelectedOHLCPoint(this.get('ohlcObjectList')[x]));
                prvClose = this.get('ohlcObjectList')[x].Close;
                startX += this.get('candleGap');
            }
        }).observes('ohlcObject'),

        _empty: function _empty() {
            var ctx = this.get('ctx');
            ctx.fillStyle = '#fff';
            ctx.fillRect(0, 0, this.get('width'), this.get('height'));
        },

        drawCandleChartPoint: function drawCandleChartPoint(x, y, ohlcObject, isSelectedCandle) {
            var ctx = this.get('ctx');
            var open = ohlcObject.Open;
            var high = ohlcObject.High;
            var low = ohlcObject.Low;
            var close = ohlcObject.Close;
            var color = this.get('colorNegative');
            var barHeight = 0;
            var barWidth = this.get('candleBarWidth');
            var bottomLineHeight = 0;
            var topLineHeight = 0;
            if (open > close) {
                // red candle
                color = this.get('colorNegative');
                barHeight = (open - close) * this.get('heightFactor');
                bottomLineHeight = (close - low) * this.get('heightFactor');
                topLineHeight = (high - open) * this.get('heightFactor');
            } else {
                // green candle
                color = this.get('colorPositive');
                barHeight = (close - open) * this.get('heightFactor');
                bottomLineHeight = (open - low) * this.get('heightFactor');
                topLineHeight = (high - close) * this.get('heightFactor');
            }
            if (isSelectedCandle) {
                ctx.strokeStyle = this.get('hilightColor');
            } else {
                ctx.strokeStyle = color;
            }
            this._drawMiddleBar(x, y, color, barHeight, barWidth);
            this._drawBottomLine(x, y, barHeight, bottomLineHeight, barWidth);
            this._drawTopLine(x, y, topLineHeight, barWidth);
        },

        _drawMiddleBar: function _drawMiddleBar(x, y, color, barHeight, barWidth) {
            var ctx = this.get('ctx');
            // middle bar
            ctx.beginPath();
            ctx.fillStyle = color;
            ctx.moveTo(x, y);
            ctx.lineTo(x + barWidth, y);
            ctx.lineTo(x + barWidth, y + barHeight);
            ctx.lineTo(x, y + barHeight);
            ctx.closePath();
            ctx.stroke();
            ctx.fill();
            ctx.restore();
        },

        _drawBottomLine: function _drawBottomLine(x, y, barHeight, bottomLineHeight, barWidth) {
            var ctx = this.get('ctx');
            // bottom Line
            ctx.beginPath();
            ctx.moveTo(x + barWidth / 2, y + barHeight);
            ctx.lineTo(x + barWidth / 2, y + barHeight + bottomLineHeight);
            ctx.stroke();
        },

        _drawTopLine: function _drawTopLine(x, y, topLineHeight, barWidth) {
            var ctx = this.get('ctx');
            // Top Line
            ctx.beginPath();
            ctx.moveTo(x + barWidth / 2, y);
            ctx.lineTo(x + barWidth / 2, y - topLineHeight);
            ctx.stroke();
        },

        _isSelectedOHLCPoint: function _isSelectedOHLCPoint(ohlcObject) {
            return this.get('ohlcObject') === ohlcObject;
        },

        _getStartingPoint: function _getStartingPoint() {
            var maxHigh = 0;
            var minimumStartPoint = 40;
            var firstCandleHigh = this.get('ohlcObjectList')[0].High;
            for (var x = 0; x < this.get('ohlcObjectList').length; x++) {
                if (this.get('ohlcObjectList')[x].High > maxHigh) {
                    maxHigh = this.get('ohlcObjectList')[x].High;
                }
            }
            return Math.round(minimumStartPoint * firstCandleHigh / maxHigh);
        }

    });

});