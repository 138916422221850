define('ir-app/controllers/price/initializers/price-ui-module-initializer', ['exports', 'ir-app/models/shared/shared-initializers/base-module-initializer', 'ir-app/controllers/authentication/price-retail-authenticator', 'ir-app/controllers/authentication/free-login-authenticator', 'ir-app/controllers/authentication/price-sso-authenticator', 'ir-app/config/app-config', 'ir-app/controllers/shared/initializer/language-initializer', 'ir-app/controllers/shared/initializer/theme-initializer'], function (exports, BaseModuleInitializer, priceRetailController, freeLoginController, priceSsoController, appConfig, languageInitializer, themeInitializer) {

    'use strict';

    exports['default'] = BaseModuleInitializer['default'].extend({
        authController: undefined,

        preInitialize: function preInitialize() {},

        postInitialize: function postInitialize() {},

        manualInitializations: function manualInitializations() {
            languageInitializer['default'].prepareLanguageView();
            themeInitializer['default'].prepareThemeView();
            this._setAuthController();
            this.authController.authenticateUser(appConfig['default'].customisation.userName, appConfig['default'].customisation.password, false, null, null);
        },

        _setAuthController: function _setAuthController() {
            var authController;

            switch (appConfig['default'].customisation.authenticationMode) {
                case this.authModes.priceRetail:
                    authController = priceRetailController['default'];
                    break;

                case this.authModes.priceSso:
                case this.authModes.priceSsoTradeSso:
                    authController = priceSsoController['default'];
                    break;

                case this.authModes.freeUser:
                    authController = freeLoginController['default'];
                    break;

                default:
                    break;
            }

            this.authController = authController;
        }
    }).create();

});