define('ir-app/models/price/price-business-entities/panel-field', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        lanKey: '',
        field: '',
        value: '',
        valueObj: null,
        style: '',
        numOfDeci: '',

        notifyChange: function notifyChange() {
            this.set('value', this.valueObj.get(this.field));
        }

    });

});