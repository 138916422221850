define('ir-app/models/decypha/decypha-protocols/decypha-request-generator', ['exports', 'ember', 'ir-app/utils/utils', 'ir-app/models/decypha/decypha-service-constants', 'ir-app/models/shared/shared-service', 'ir-app/models/decypha/decypha-extended-service'], function (exports, Ember, Utils, DecyphaConstant, SharedService, DecyphaExtendedService) {

    'use strict';

    /**
     * Created by udaras on 10/13/2016.
     */
    exports['default'] = (function () {

        var generateNewsSearchUrl = function generateNewsSearchUrl(params) {
            var queryParams = {
                RT: DecyphaConstant['default'].DecyphaRequest.NewsSearch.RT,
                L: SharedService['default'].userSettings.currentLanguage,
                PL: SharedService['default'].userSettings.currentLanguage,
                UID: Utils['default'].validators.isAvailable(DecyphaExtendedService['default'].decyphaUser.get('username')) ? DecyphaExtendedService['default'].decyphaUser.get('username') : 'FREE',
                SID: Utils['default'].validators.isAvailable(DecyphaExtendedService['default'].decyphaUser.get('username')) ? DecyphaExtendedService['default'].decyphaUser.get('sessionId') : 'FREE',
                TS: new Date().getTime()
            };

            if (params.pageIndex !== undefined) {
                queryParams.PGI = params.pageIndex;
            }

            if (params.pageSize) {
                queryParams.PGS = params.pageSize;
            }

            if (params.newsType) {
                queryParams.SDT = params.newsType;
            } else {
                queryParams.SDT = DecyphaConstant['default'].DecyphaRequest.NewsSearch.SDT.MDJ;
            }
            return _generateQueryString(DecyphaConstant['default'].UrlTypes.Content, queryParams);
        };

        var generateCompanyScreenerUrl = function generateCompanyScreenerUrl(params) {
            var queryParams = {
                RT: DecyphaConstant['default'].DecyphaRequest.CompanyScreener.RT,
                L: SharedService['default'].userSettings.currentLanguage,
                UID: Utils['default'].validators.isAvailable(DecyphaExtendedService['default'].decyphaUser.get('username')) ? DecyphaExtendedService['default'].decyphaUser.get('username') : 'FREE',
                SID: Utils['default'].validators.isAvailable(DecyphaExtendedService['default'].decyphaUser.get('username')) ? DecyphaExtendedService['default'].decyphaUser.get('sessionId') : 'FREE',
                TS: new Date().getTime()
            };

            if (params.pageIndex !== undefined) {
                queryParams.PGI = params.pageIndex;
            }

            if (params.pageSize) {
                queryParams.PGS = params.pageSize;
            }

            if (params.screenerDataType) {
                queryParams.SDT = params.screenerDataType;
            } else {
                queryParams.SDT = DecyphaConstant['default'].DecyphaRequest.CompanyScreener.SDT.COMPANY_SCREENER_DATA;
            }

            if (params.countries !== undefined) {
                queryParams.CC = params.countries;
            }

            if (params.listingStatus !== undefined) {
                queryParams.CPLS = params.listingStatus;
            }

            if (params.industries !== undefined) {
                queryParams.INDST = params.industries;
            }

            if (params.filters !== undefined) {
                queryParams.ITK = params.filters;
            }

            return _generateQueryString(DecyphaConstant['default'].UrlTypes.Content, queryParams);
        };

        var _generateQueryString = function _generateQueryString(urlPrefix, params) {
            var queryStringArray = [];

            Ember['default'].$.each(params, function (param, paramVal) {
                queryStringArray.push([param, encodeURIComponent(paramVal)].join(Utils['default'].Constants.StringConst.Equal));
            });

            return [urlPrefix, queryStringArray.join(Utils['default'].Constants.StringConst.Ampersand)].join(Utils['default'].Constants.StringConst.Question);
        };

        return {
            generateNewsSearchUrl: generateNewsSearchUrl,
            generateCompanyScreenerUrl: generateCompanyScreenerUrl
        };
    })();

});