define('ir-app/models/price/price-business-entities/sector', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].ArrayController.extend({
        sec: '', // Sector Code
        des: '', // Description
        sortKey: '', // Sort Key
        sortProperties: ['sDes'],
        sortAscending: true,

        setData: function setData(sectorMessage) {
            var that = this;

            Ember['default'].$.each(sectorMessage, function (key, value) {
                that.set(key, value);
            });
        },

        addSectorSymbolObjects: function addSectorSymbolObjects(object) {
            if (!this.get('model').contains(object)) {
                this.get('model').pushObject(object);
            }
        },

        sortedContent: (function () {
            return this.get('arrangedContent');
        }).property('arrangedContent'),

        getSymbolList: (function () {
            return this.get('sortedContent');
        }).property('sortedContent', 'sortedContent.@each'),

        dataAvailable: (function () {
            return this.get('getSymbolList').length > 0;
        }).property('getSymbolList', 'sortedContent.@each')
    });

});