define('ir-app/models/price/price-business-entities/cash-flow', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        operatingActivities: '', // Operating Activities
        investingActivities: '', // Investing Activities

        setData: function setData(cashFlow) {
            var that = this;

            Ember['default'].$.each(cashFlow, function (key, value) {
                that.set(key, value);
            });
        }
    });

});