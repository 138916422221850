define('ir-app/models/shared/communication-adapters/web-socket-manager', ['exports', 'ember', 'ir-app/models/shared/communication-adapters/web-socket-connection', 'ir-app/config/price-settings', 'ir-app/models/price/price-constants', 'ir-app/utils/utils', 'ir-app/models/price/price-subscription-manager'], function (exports, Ember, WebSocketConnection, priceSettings, PriceConstants, utils, subscriptionManager) {

    'use strict';

    exports['default'] = function (requestHandler, responseHandler, service) {
        this.requestHandler = requestHandler; // Singleton request generator object
        this.responseHandler = responseHandler; // Class blue-print
        this.socketMap = {};
        this.service = service;

        var that = this;

        var sendAuth = function sendAuth(authRequest, connectionType, authParams) {
            _sendAuthRequest(authRequest, connectionType, that.responseHandler, {
                callbacks: {
                    auth: {
                        successFn: authParams.authSuccess,
                        errorFn: authParams.authFailed,
                        postSuccessFn: function postSuccessFn() {
                            if (authParams.resendSubscriptions) {
                                _resendCurrentSubscriptions(connectionType);
                            }
                        }
                    }
                }
            });
        };

        var sendData = function sendData(dataRequest, connectionType) {
            Ember['default'].$.each(dataRequest, function (index, req) {
                _getSocketConnection(connectionType).sendData(req);
            });
        };

        var isConnected = function isConnected(connectionType) {
            return that.socketMap[connectionType] && that.socketMap[connectionType].isConnected();
        };

        var _sendReconnectAuth = function _sendReconnectAuth(authRequest, connectionType, authSuccess) {
            _sendAuthRequest(authRequest, connectionType, undefined, authSuccess);
        };

        var _sendAuthRequest = function _sendAuthRequest(authRequest, connectionType, respHandler, callbacks) {
            var socketConnection = _getSocketConnection(connectionType);

            socketConnection.initialize(respHandler, callbacks);
            socketConnection.sendAuth(authRequest);
        };

        var _onSocketConnect = function _onSocketConnect() {
            // Notify socket connection
            utils['default'].messageService.showMessage('', utils['default'].Constants.MessageTypes.Info, true); // Message text is empty since, only indicator shows it
            that.service.setConnectionStatus(true);
        };

        var _onSocketDisconnect = function _onSocketDisconnect(socketConnection, retry) {
            // Notify socket disconnection
            utils['default'].messageService.showMessage('priceDisconnected', utils['default'].Constants.MessageTypes.Error, true);
            that.service.setConnectionStatus(false);

            if (retry) {
                setTimeout(function () {
                    var authReq = that.requestHandler.generateReconnectionAuthRequest();

                    _sendReconnectAuth(authReq, socketConnection.socketParams.type, function () {
                        _resendCurrentSubscriptions(socketConnection.socketParams.type);
                    });
                }, PriceConstants['default'].Pulse.ReconnectionTimeInterval); // TODO: [Bashitha] PriceConstants should not be accessed from here
            }
        };

        var _resendCurrentSubscriptions = function _resendCurrentSubscriptions(connectionType) {
            var currentSubs = subscriptionManager['default'].getCurrentSubscriptions();
            sendData(currentSubs, connectionType);
        };

        var _getSocketConnection = function _getSocketConnection(connectionType) {
            if (!that.socketMap[connectionType]) {
                // TODO: [Bashitha] Get connection parameters in a general way
                var socketParams = {
                    ip: priceSettings['default'].connectionParameters.primary.ip,
                    port: priceSettings['default'].connectionParameters.primary.port,
                    secure: priceSettings['default'].connectionParameters.primary.secure,
                    type: connectionType,
                    onConnect: _onSocketConnect,
                    onDisconnect: _onSocketDisconnect
                };

                that.socketMap[connectionType] = new WebSocketConnection['default'](socketParams);
            }

            return that.socketMap[connectionType];
        };

        return {
            sendAuth: sendAuth,
            sendData: sendData,
            isConnected: isConnected
        };
    }

});