define('ir-app/views/table/dual-cells/progress-cell', ['exports', 'ir-app/views/table/dual-cells/dual-cell'], function (exports, DualCell) {

    'use strict';

    exports['default'] = DualCell['default'].extend({
        templateName: 'table/views/progress-cell',

        calculateCash: (function () {
            var value;
            var cashIn = this.get('firstValue');
            var cashOut = this.get('secondValue');

            if (cashIn && cashOut && cashIn + cashOut) {
                value = cashIn / (cashIn + cashOut);
            }

            return isNaN(value) ? 0 : value * 100;
        }).property('cellContent'),

        showValue: (function () {
            return this.addPercentageFormat(this.get('calculateCash'), 2);
        }).property('cellContent'),

        styles: (function () {
            var progress = parseInt(this.get('calculateCash'), 10);
            return 'width:' + progress + '%;'; // Used inline styles since width is dynamically and changed rapidly
        }).property('cellContent')
    });

});