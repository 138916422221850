define('ir-app/models/shared/profile/component', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        // Below properties are pascal case according to profile service protocol.
        Id: '',
        ComponentTypeId: '',
        Status: '',
        ProfileId: '',
        Version: '',
        Name: '',
        Type: '',
        Contents: '',
        PermissionList: [],

        setData: function setData(componentData) {
            var that = this;

            Ember['default'].$.each(componentData, function (key, value) {
                that.set(key, value);
            });
        }
    });

});