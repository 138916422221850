define('ir-app/controllers/ir-mobile/common-widgets/stock-chart', ['exports', 'ir-app/controllers/base-array-controller', 'ir-app/models/chart/chart-data-stores/graph-data-manager', 'ir-app/models/chart/chart-constants'], function (exports, BaseController, graphDM, ChartConstants) {

    'use strict';

    /* global c3 */

    exports['default'] = BaseController['default'].extend({
        mainChart: null, // main chart object
        graphDataManager: null,

        // Base overrides
        onLoadWidget: function onLoadWidget() {
            var that = this;
            // Create the graph data manager
            var gDM = graphDM['default'].create({
                chartCategory: ChartConstants['default'].ChartCategory.Intraday,
                chartDataLevel: ChartConstants['default'].ChartDataLevel.IntradayCurrentDay,
                chartViewPeriod: ChartConstants['default'].ChartViewPeriod.OneDay,

                onData: function onData(ohlcPoint, exg, sym) {
                    that.amendChart(ohlcPoint, exg, sym);
                },

                onDataChunk: function onDataChunk(chartSymbolObj) {
                    that.updateChart(chartSymbolObj);
                },

                onErrorFn: function onErrorFn() {
                    // TODO: [Amila] implement this
                }
            });

            gDM.addChartSymbol(that.get('exg'), that.get('sym'), true);

            that.set('graphDataManager', gDM);
            that.get('graphDataManager').addChartDataSubscription();
        },

        /**
         * onMarketStatusChanged: function () {
            var that = this;
            var stat = this.exchange.get('stat');
            var count = that.get('marketStatusChangeCount');
              if (stat === PriceConstants.MarketStatus.Open && that.get('marketStatusChangeCount') > 0) {
                that.drawChart([]);
            }
              that.set('marketStatusChangeCount', count + 1);
        }.observes('exchange.stat'),*/

        updateChart: function updateChart() {
            var that = this;
            var chartDataArray = that.get('graphDataManager').getDataArray();
            this.set('lastDrawnIndex', chartDataArray.length - 1);

            if (chartDataArray.length > 1) {
                if (that.mainChart === null) {
                    that.drawChart();
                } else {
                    that.mainChart.load({
                        json: chartDataArray,
                        keys: {
                            x: 'DT',
                            value: ['Close']
                        }
                    });
                }
            }
        },

        amendChart: function amendChart(ohlcPoint) {
            var that = this;

            if (that.mainChart === null) {
                that.drawChart();
            } else {
                if (ohlcPoint) {
                    that.mainChart.flow({
                        json: [ohlcPoint],
                        keys: {
                            x: 'DT',
                            value: ['Close']
                        },
                        length: 0
                    });
                }
            }
        },

        /**
         * Draw main chart
         */
        drawChart: function drawChart(chartDataArray) {
            var that = this;
            var chartData = chartDataArray ? chartDataArray : that.get('graphDataManager').getDataArray();

            this.mainChart = c3.generate({
                data: {
                    json: chartData,
                    keys: {
                        x: 'DT',
                        value: ['Close']
                    },
                    types: {
                        Close: 'area'
                    }
                    /** ,
                    colors: {
                        Close: that.getChartColor()
                    }*/
                },
                area: {
                    zerobased: false
                },
                point: {
                    show: false
                },
                size: {
                    width: 400,
                    height: 200
                },
                padding: {
                    top: 7,
                    right: 15,
                    bottom: 1,
                    left: 40
                },
                // TODO [Pathum] Need to fix alignment issues before implementing this.
                // bindto: '#mainIndexChart',
                axis: {
                    x: {
                        type: 'timeseries',
                        tick: {
                            // format: '%m %d',
                            format: '%H:%M',
                            culling: {
                                max: 8
                            },
                            count: 10
                        }
                    },
                    y: {
                        tick: {
                            format: function format(val) {
                                return that.utils.formatters.formatNumber(val, 2);
                            },
                            culling: {
                                max: 6
                            },
                            count: 5
                        }
                    }
                },
                legend: {
                    show: false
                },
                tooltip: {
                    show: true
                }
            });
        }
        /**
            getChartColor: function (pctChgVal) {
                var pctChg = pctChgVal || this.index.get('pctChg');
                var lineColor = '#559fd6';
        
                if (pctChg > this.topPanelSettings.intZero) {
                    lineColor = '#19703e';
                } else if (pctChg < this.topPanelSettings.intZero) {
                    lineColor = '#842424';
                } else {
                    lineColor = '#559fd6';
                }
        
                return lineColor;
            }*/
    });

});