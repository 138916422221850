define('ir-app/models/price/price-business-entities/company-analyst-contacts', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        symbol: '',
        exchange: '',
        firm: '',
        analyst: '',
        email: '',
        phone: '',
        setData: function setData(creditRatings) {
            var that = this;

            Ember['default'].$.each(creditRatings, function (key, value) {
                that.set(key, value);
            });
        }
    });

});