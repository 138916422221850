define('ir-app/templates/ir-mobile/quote-widgets/default-quote', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-xs-12 col-md-4 margin-medium-bottom");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","left snap-font-color-2");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","bold right");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-xs-12 col-md-4 margin-medium-bottom");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","left snap-font-color-2");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","bold right");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-xs-12 col-md-4 margin-medium-bottom");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","left snap-font-color-2");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","bold right");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-xs-12 col-md-4 margin-medium-bottom");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","left snap-font-color-2");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","bold right");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-xs-12 col-md-4 margin-medium-bottom");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","left snap-font-color-2");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","bold right");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-xs-12 col-md-4 margin-medium-bottom");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","left snap-font-color-2");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","bold right dir-ltr");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" %");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-xs-12 col-md-4 margin-medium-bottom");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","left snap-font-color-2");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","bold right");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-xs-12 col-md-4 margin-medium-bottom");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","left snap-font-color-2");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","bold right");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-xs-12 col-md-4 margin-medium-bottom");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","left snap-font-color-2");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","bold right");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, content = hooks.content, get = hooks.get, inline = hooks.inline;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element0, [3]);
          var element3 = dom.childAt(element0, [5]);
          var element4 = dom.childAt(element0, [7]);
          var element5 = dom.childAt(element0, [9]);
          var element6 = dom.childAt(element0, [11]);
          var element7 = dom.childAt(element0, [13]);
          var element8 = dom.childAt(element0, [15]);
          var element9 = dom.childAt(element0, [17]);
          var morph0 = dom.createMorphAt(dom.childAt(element1, [1]),0,0);
          var morph1 = dom.createMorphAt(dom.childAt(element1, [3]),0,0);
          var morph2 = dom.createMorphAt(dom.childAt(element2, [1]),0,0);
          var morph3 = dom.createMorphAt(dom.childAt(element2, [3]),0,0);
          var morph4 = dom.createMorphAt(dom.childAt(element3, [1]),0,0);
          var morph5 = dom.createMorphAt(dom.childAt(element3, [3]),0,0);
          var morph6 = dom.createMorphAt(dom.childAt(element4, [1]),0,0);
          var morph7 = dom.createMorphAt(dom.childAt(element4, [3]),0,0);
          var morph8 = dom.createMorphAt(dom.childAt(element5, [1]),0,0);
          var morph9 = dom.createMorphAt(dom.childAt(element5, [3]),0,0);
          var morph10 = dom.createMorphAt(dom.childAt(element6, [1]),0,0);
          var morph11 = dom.createMorphAt(dom.childAt(element6, [3]),0,0);
          var morph12 = dom.createMorphAt(dom.childAt(element7, [1]),0,0);
          var morph13 = dom.createMorphAt(dom.childAt(element7, [3]),0,0);
          var morph14 = dom.createMorphAt(dom.childAt(element8, [1]),0,0);
          var morph15 = dom.createMorphAt(dom.childAt(element8, [3]),0,0);
          var morph16 = dom.createMorphAt(dom.childAt(element9, [1]),0,0);
          var morph17 = dom.createMorphAt(dom.childAt(element9, [3]),0,0);
          content(env, morph0, context, "app.lang.labels.symbol");
          content(env, morph1, context, "stock.sym");
          content(env, morph2, context, "app.lang.labels.exchange");
          content(env, morph3, context, "exchange.des");
          content(env, morph4, context, "app.lang.labels.sector");
          content(env, morph5, context, "basicInfo.sectorDes");
          content(env, morph6, context, "app.lang.labels.currency");
          content(env, morph7, context, "stock.cur");
          content(env, morph8, context, "app.lang.labels.outShr");
          inline(env, morph9, context, "formatNumber", [get(env, context, "basicInfo.outStdShr"), 0], {});
          content(env, morph10, context, "app.lang.labels.freeFltShr");
          inline(env, morph11, context, "formatNumber", [get(env, context, "basicInfo.freeFltShr"), 3], {});
          content(env, morph12, context, "app.lang.labels.parVal");
          inline(env, morph13, context, "formatNumber", [get(env, context, "basicInfo.parVal")], {});
          content(env, morph14, context, "app.lang.labels.pdCap");
          inline(env, morph15, context, "formatNumber", [get(env, context, "basicInfo.paidCap"), 0], {});
          content(env, morph16, context, "app.lang.labels.authCap");
          inline(env, morph17, context, "formatNumber", [get(env, context, "basicInfo.authCap"), 0], {});
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","margin-medium-top");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","row");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col-xs-12");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","row");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col-xs-6 col-md-3 col-lg-2 text-align-left");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","snap-font-size-2 snap-font-color-2");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","snap-font-size-1 snap-font-color-1 margin-medium-bottom");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col-xs-6 col-md-3 col-lg-2 text-align-right");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","snap-font-size-2 snap-font-color-2");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","snap-font-size-1 snap-font-color-1 margin-medium-bottom");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col-xs-6 col-md-3 col-lg-2 text-align-left");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","snap-font-size-2 snap-font-color-2");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","snap-font-size-1 snap-font-color-1 margin-medium-bottom");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col-xs-6 col-md-3 col-lg-2 text-align-right");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","snap-font-size-2 snap-font-color-2");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","snap-font-size-1 snap-font-color-1 margin-medium-bottom");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col-xs-6 col-md-3 col-lg-2 text-align-left");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","snap-font-size-2 snap-font-color-2");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","snap-font-size-1 snap-font-color-1 margin-medium-bottom");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col-xs-6 col-md-3 col-lg-2 text-align-right");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","snap-font-size-2 snap-font-color-2");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","snap-font-size-1 snap-font-color-1 margin-medium-bottom");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col-xs-6 col-md-3 col-lg-2 text-align-left");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","snap-font-size-2 snap-font-color-2");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","snap-font-size-1 snap-font-color-1 margin-medium-bottom");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col-xs-6 col-md-3 col-lg-2 text-align-right");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","snap-font-size-2 snap-font-color-2");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","snap-font-size-1 snap-font-color-1 margin-medium-bottom");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col-xs-6 col-md-3 col-lg-2 text-align-left");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","snap-font-size-2 snap-font-color-2");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","snap-font-size-1 snap-font-color-1 margin-medium-bottom");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col-xs-6 col-md-3 col-lg-2 text-align-right");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","snap-font-size-2 snap-font-color-2");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","snap-font-size-1 snap-font-color-1 snap-lbl-2 margin-medium-bottom");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col-xs-6 col-md-3 col-lg-2 text-align-left");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","snap-font-size-2 snap-font-color-2");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(" - ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","snap-font-size-1 snap-font-color-1 margin-medium-bottom");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col-xs-6 col-md-3 col-lg-2 text-align-right");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","snap-font-size-2 snap-font-color-2");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","snap-font-size-1 snap-font-color-1 margin-medium-bottom");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","horizontal-sep-line margin-medium-top margin-medium-bottom");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","row");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col-xs-12 col-sm-4");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","snap-font-size-2 snap-font-color-2 text-center margin-small-bottom");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","snap-font-color-1 margin-medium-bottom text-center");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col-xs-12 col-sm-4");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","snap-font-size-2 snap-font-color-2 text-center margin-small-bottom");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","snap-font-color-1 margin-medium-bottom text-center");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col-xs-12 col-sm-4");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","snap-font-size-2 snap-font-color-2 text-center margin-small-bottom");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","snap-font-color-1 margin-medium-bottom text-center");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","horizontal-sep-line margin-medium-bottom");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","row");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col-xs-6 col-sm-3 margin-medium-bottom");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","text-align-left left full-width margin-small-bottom radius-style-upper");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","text-align-right right full-width snap-font-size-3 radius-style-lower");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","clearfix");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col-xs-6 col-sm-3 margin-medium-bottom");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","text-align-left left full-width margin-small-bottom radius-style-upper");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","text-align-right right full-width snap-font-size-3 radius-style-lower");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","clearfix");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col-xs-6 col-sm-3 margin-medium-bottom");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","text-align-left left full-width margin-small-bottom radius-style-upper");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","text-align-right right full-width snap-font-size-3 radius-style-lower");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","clearfix");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col-xs-6 col-sm-3 margin-medium-bottom");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","text-align-left left full-width margin-small-bottom radius-style-upper");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","text-align-right right full-width snap-font-size-3 radius-style-lower");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","clearfix");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","horizontal-sep-line margin-medium-bottom");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, inline = hooks.inline, content = hooks.content, subexpr = hooks.subexpr, concat = hooks.concat, attribute = hooks.attribute, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element10 = dom.childAt(fragment, [1]);
        var element11 = dom.childAt(element10, [1, 1, 1]);
        var element12 = dom.childAt(element11, [1]);
        var element13 = dom.childAt(element11, [3]);
        var element14 = dom.childAt(element11, [5]);
        var element15 = dom.childAt(element11, [7]);
        var element16 = dom.childAt(element11, [9]);
        var element17 = dom.childAt(element11, [11]);
        var element18 = dom.childAt(element11, [13]);
        var element19 = dom.childAt(element11, [15]);
        var element20 = dom.childAt(element11, [17]);
        var element21 = dom.childAt(element11, [19]);
        var element22 = dom.childAt(element11, [21]);
        var element23 = dom.childAt(element22, [1]);
        var element24 = dom.childAt(element11, [23]);
        var element25 = dom.childAt(element10, [5]);
        var element26 = dom.childAt(element25, [1]);
        var element27 = dom.childAt(element25, [3]);
        var element28 = dom.childAt(element25, [5]);
        var element29 = dom.childAt(element10, [9]);
        var element30 = dom.childAt(element29, [1, 1]);
        var element31 = dom.childAt(element29, [3, 1]);
        var element32 = dom.childAt(element29, [5, 1]);
        var element33 = dom.childAt(element29, [7, 1]);
        var morph0 = dom.createMorphAt(dom.childAt(element12, [1]),0,0);
        var morph1 = dom.createMorphAt(dom.childAt(element12, [3]),0,0);
        var morph2 = dom.createMorphAt(dom.childAt(element13, [1]),0,0);
        var morph3 = dom.createMorphAt(dom.childAt(element13, [3]),0,0);
        var morph4 = dom.createMorphAt(dom.childAt(element14, [1]),0,0);
        var morph5 = dom.createMorphAt(dom.childAt(element14, [3]),0,0);
        var morph6 = dom.createMorphAt(dom.childAt(element15, [1]),0,0);
        var morph7 = dom.createMorphAt(dom.childAt(element15, [3]),0,0);
        var morph8 = dom.createMorphAt(dom.childAt(element16, [1]),0,0);
        var morph9 = dom.createMorphAt(dom.childAt(element16, [3]),0,0);
        var morph10 = dom.createMorphAt(dom.childAt(element17, [1]),0,0);
        var morph11 = dom.createMorphAt(dom.childAt(element17, [3]),0,0);
        var morph12 = dom.createMorphAt(dom.childAt(element18, [1]),0,0);
        var morph13 = dom.createMorphAt(dom.childAt(element18, [3]),0,0);
        var morph14 = dom.createMorphAt(dom.childAt(element19, [1]),0,0);
        var morph15 = dom.createMorphAt(dom.childAt(element19, [3]),0,0);
        var morph16 = dom.createMorphAt(dom.childAt(element20, [1]),0,0);
        var morph17 = dom.createMorphAt(dom.childAt(element20, [3]),0,0);
        var morph18 = dom.createMorphAt(dom.childAt(element21, [1]),0,0);
        var morph19 = dom.createMorphAt(dom.childAt(element21, [3]),0,0);
        var morph20 = dom.createMorphAt(element23,0,0);
        var morph21 = dom.createMorphAt(element23,2,2);
        var morph22 = dom.createMorphAt(dom.childAt(element22, [3]),0,0);
        var morph23 = dom.createMorphAt(dom.childAt(element24, [1]),0,0);
        var morph24 = dom.createMorphAt(dom.childAt(element24, [3]),0,0);
        var morph25 = dom.createMorphAt(dom.childAt(element26, [1]),0,0);
        var morph26 = dom.createMorphAt(dom.childAt(element26, [3]),0,0);
        var morph27 = dom.createMorphAt(dom.childAt(element27, [1]),0,0);
        var morph28 = dom.createMorphAt(dom.childAt(element27, [3]),0,0);
        var morph29 = dom.createMorphAt(dom.childAt(element28, [1]),0,0);
        var morph30 = dom.createMorphAt(dom.childAt(element28, [3]),0,0);
        var attrMorph0 = dom.createAttrMorph(element30, 'class');
        var morph31 = dom.createMorphAt(dom.childAt(element30, [1]),0,0);
        var morph32 = dom.createMorphAt(dom.childAt(element30, [3]),0,0);
        var attrMorph1 = dom.createAttrMorph(element31, 'class');
        var morph33 = dom.createMorphAt(dom.childAt(element31, [1]),0,0);
        var morph34 = dom.createMorphAt(dom.childAt(element31, [3]),0,0);
        var attrMorph2 = dom.createAttrMorph(element32, 'class');
        var morph35 = dom.createMorphAt(dom.childAt(element32, [1]),0,0);
        var morph36 = dom.createMorphAt(dom.childAt(element32, [3]),0,0);
        var attrMorph3 = dom.createAttrMorph(element33, 'class');
        var morph37 = dom.createMorphAt(dom.childAt(element33, [1]),0,0);
        var morph38 = dom.createMorphAt(dom.childAt(element33, [3]),0,0);
        var morph39 = dom.createMorphAt(element10,13,13);
        inline(env, morph0, context, "formatNumber", [get(env, context, "stock.filteredOpen"), get(env, context, "stock.filteredDeci")], {});
        content(env, morph1, context, "app.lang.labels.open");
        inline(env, morph2, context, "formatNumber", [get(env, context, "stock.filteredHigh"), get(env, context, "stock.filteredDeci")], {});
        content(env, morph3, context, "app.lang.labels.high");
        inline(env, morph4, context, "formatNumber", [get(env, context, "stock.filteredVwap"), get(env, context, "stock.filteredDeci")], {});
        content(env, morph5, context, "app.lang.labels.vWAP");
        inline(env, morph6, context, "formatNumber", [get(env, context, "stock.filteredLow"), get(env, context, "stock.filteredDeci")], {});
        content(env, morph7, context, "app.lang.labels.low");
        inline(env, morph8, context, "formatNumber", [get(env, context, "stock.filteredPrvCls"), get(env, context, "stock.filteredDeci")], {});
        content(env, morph9, context, "app.lang.labels.preClosed");
        inline(env, morph10, context, "formatNumber", [get(env, context, "stock.trades"), 0], {});
        content(env, morph11, context, "app.lang.labels.trades");
        inline(env, morph12, context, "formatNumber", [get(env, context, "stock.vol"), 0], {});
        content(env, morph13, context, "app.lang.labels.volume");
        inline(env, morph14, context, "formatNumber", [get(env, context, "stock.tovr"), 0], {});
        content(env, morph15, context, "app.lang.labels.turnover");
        inline(env, morph16, context, "formatNumber", [get(env, context, "stock.avgVol30D"), 0], {});
        content(env, morph17, context, "app.lang.labels.avgVol30Days");
        inline(env, morph18, context, "formatNumber", [get(env, context, "stock.avgTovr30D"), 0], {});
        content(env, morph19, context, "app.lang.labels.avgTovr30Days");
        inline(env, morph20, context, "formatNumber", [get(env, context, "stock.filteredH52"), get(env, context, "stock.filteredDeci")], {});
        inline(env, morph21, context, "formatNumber", [get(env, context, "stock.filteredL52"), get(env, context, "stock.filteredDeci")], {});
        content(env, morph22, context, "app.lang.labels.w52highLow");
        inline(env, morph23, context, "formatNumber", [get(env, context, "stock.mktCapMillion"), 0], {});
        content(env, morph24, context, "app.lang.labels.mktCapMillion");
        inline(env, morph25, context, "days-range-slider", [], {"stock": get(env, context, "stock")});
        content(env, morph26, context, "app.lang.labels.daysRange");
        inline(env, morph27, context, "fifty-two-wk-hl-slider", [], {"stock": get(env, context, "stock")});
        content(env, morph28, context, "app.lang.labels.weeksRange52");
        inline(env, morph29, context, "cash-map-slider", [], {"stock": get(env, context, "stock")});
        content(env, morph30, context, "app.lang.labels.cashMap");
        attribute(env, attrMorph0, element30, "class", concat(env, ["full-width left radius-style ", subexpr(env, context, "getPositiveNegativeStyle", [get(env, context, "stock.pctChgW")], {})]));
        content(env, morph31, context, "app.lang.labels.pctChgWeek");
        inline(env, morph32, context, "formatNumberPercentage", [get(env, context, "stock.pctChgW")], {});
        attribute(env, attrMorph1, element31, "class", concat(env, ["radius-style full-width left ", subexpr(env, context, "getPositiveNegativeStyle", [get(env, context, "stock.pctChgM")], {})]));
        content(env, morph33, context, "app.lang.labels.pctChgMonth");
        inline(env, morph34, context, "formatNumberPercentage", [get(env, context, "stock.pctChgM")], {});
        attribute(env, attrMorph2, element32, "class", concat(env, ["radius-style full-width left ", subexpr(env, context, "getPositiveNegativeStyle", [get(env, context, "stock.pctChg3M")], {})]));
        content(env, morph35, context, "app.lang.labels.pctChg3Month");
        inline(env, morph36, context, "formatNumberPercentage", [get(env, context, "stock.pctChg3M")], {});
        attribute(env, attrMorph3, element33, "class", concat(env, ["radius-style full-width left ", subexpr(env, context, "getPositiveNegativeStyle", [get(env, context, "stock.pctChgY")], {})]));
        content(env, morph37, context, "app.lang.labels.pctChgYear");
        inline(env, morph38, context, "formatNumberPercentage", [get(env, context, "stock.pctChgY")], {});
        block(env, morph39, context, "unless", [get(env, context, "hideBottomQuote")], {}, child0, null);
        return fragment;
      }
    };
  }()));

});