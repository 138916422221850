define('ir-app/models/price/price-data-stores/company-profile-data-store', ['exports', 'ember', 'ir-app/models/price/price-business-entities/company-profile', 'ir-app/models/price/price-business-entities/company-management', 'ir-app/models/price/price-business-entities/company-shareholder-owners', 'ir-app/models/price/price-business-entities/company-individuals', 'ir-app/models/price/price-business-entities/company-funds', 'ir-app/models/price/price-business-entities/company-owners', 'ir-app/models/price/price-business-entities/company-subsidiaries', 'ir-app/models/price/price-business-entities/company-key-officers', 'ir-app/models/price/price-business-entities/company-credit-ratings', 'ir-app/models/price/price-business-entities/company-analyst-contacts', 'ir-app/models/price/price-business-entities/company-ownership-limits', 'ir-app/models/price/price-service', 'ir-app/models/price/price-constants', 'ir-app/utils/utils', 'ir-app/models/price/price-data-stores/base-data-store'], function (exports, Ember, companyProfile, companyManagement, companyShareholderOwners, companyIndividuals, companyFunds, companyOwners, companySubsidiaries, companyKeyOfficers, companyCreditRatings, companyAnalystContacts, companyOwnershipLimits, priceService, priceConstants, utils, BaseStore) {

    'use strict';

    exports['default'] = BaseStore['default'].extend({

        /**
         * Override base method
         * get stored object... if dataType is available initialize data for the DS object.
         * @param exchange
         * @param symbol
         * @param language
         * @param successFn functions to run on success data loading
         * @param errorFn   functions to run on error
         * @param dataType {optional} if available initialize given data type to the store.
         * @returns {*}
         */
        getStoredObject: function getStoredObject(exchange, symbol, language, successFn, errorFn, dataType) {
            return this.getStoredObjectByType(exchange, symbol, undefined, language, successFn, errorFn, dataType);
        },

        /**
         * get stored object by companyId... if dataType is available initialize data for the DS object.
         * @param companyId
         * @param language
         * @param successFn functions to run on success data loading
         * @param errorFn   functions to run on error
         * @param dataType {optional} if available initialize given data type to the store.
         * @returns {*}
         */
        getStoredObjectByCompanyId: function getStoredObjectByCompanyId(companyId, language, successFn, errorFn, dataType) {
            return this.getStoredObjectByType(undefined, undefined, companyId, language, successFn, errorFn, dataType);
        },

        /**
         * Override Method
         * @param exchange
         * @param symbol
         * @param companyId
         * @param requestBy ,request is from companyId or exg/symbol
         * @param language
         */
        createStoreCacheObj: function createStoreCacheObj(exchange, symbol, companyId, requestBy, language) {
            var key;
            var storeCacheObj = {
                dataObj: {},
                metaObj: {}
            };
            if (requestBy === priceConstants['default'].DataTypes.CompanyProfileDataStore.requestBy.companyId) {
                key = this.getKey([companyId, language]);
                storeCacheObj.dataObj = companyProfile['default'].create({
                    companyId: companyId,
                    lan: language
                });
            } else {
                key = this.getKey([exchange, symbol, language]);
                storeCacheObj.dataObj = companyProfile['default'].create({
                    exg: exchange,
                    sym: symbol,
                    lan: language
                });
            }

            var companyDataTypes = priceConstants['default'].DataTypes.CompanyProfileDataStore;
            this.initMetaData(storeCacheObj, companyDataTypes);
            this.dataStore[key] = storeCacheObj;
            return storeCacheObj;
        },

        createCompanyManagement: function createCompanyManagement() {
            return companyManagement['default'].create();
        },

        createCompanyOwners: function createCompanyOwners() {
            return companyOwners['default'].create();
        },

        createCompanySubsidiaries: function createCompanySubsidiaries() {
            return companySubsidiaries['default'].create();
        },

        createCompanyProfileData: function createCompanyProfileData() {
            return companyProfile['default'].create();
        },

        createCompanyKeyOfficers: function createCompanyKeyOfficers() {
            return companyKeyOfficers['default'].create();
        },

        createCompanyCreditRatings: function createCompanyCreditRatings() {
            return companyCreditRatings['default'].create();
        },
        createCompanyAnalystContacts: function createCompanyAnalystContacts() {
            return companyAnalystContacts['default'].create();
        },
        createCompanyShareholderOwners: function createCompanyShareholderOwners() {
            return companyShareholderOwners['default'].create();
        },
        createCompanyIndividuals: function createCompanyIndividuals() {
            return companyIndividuals['default'].create();
        },
        createCompanyFunds: function createCompanyFunds() {
            return companyFunds['default'].create();
        },
        createCompanyOwnershipLimits: function createCompanyOwnershipLimits() {
            return companyOwnershipLimits['default'].create();
        },

        getStoredObjectByType: function getStoredObjectByType(exchange, symbol, companyId, language, successFn, errorFn, dataType) {

            var requestBy, key;

            if (utils['default'].validators.isAvailable(exchange) && utils['default'].validators.isAvailable(symbol)) {
                requestBy = priceConstants['default'].DataTypes.CompanyProfileDataStore.requestBy.symbol;
                key = this.getKey([exchange, symbol, language]);
            } else if (utils['default'].validators.isAvailable(companyId)) {
                requestBy = priceConstants['default'].DataTypes.CompanyProfileDataStore.requestBy.companyId;
                key = this.getKey([companyId, language]);
            }

            var cachedObj = this.dataStore[key];

            var postSuccess = function postSuccess() {
                cachedObj.metaObj[dataType].isAvailable = true;
                cachedObj.metaObj[dataType].isRequested = false;
                var successFns = cachedObj.metaObj[dataType].successFns;

                Ember['default'].$.each(successFns, function (index, sFn) {
                    if (Ember['default'].$.isFunction(sFn)) {
                        sFn(cachedObj);
                    }
                });
                cachedObj.metaObj[dataType].successFns = [];
            };

            var error = function error() {
                cachedObj.metaObj[dataType].isAvailable = false;
                cachedObj.metaObj[dataType].isRequested = false;
                var errorFns = cachedObj.metaObj[dataType].errorFns;

                Ember['default'].$.each(errorFns, function (index, eFn) {
                    if (Ember['default'].$.isFunction(eFn)) {
                        eFn();
                    }
                });
                cachedObj.metaObj[dataType].errorFns = [];
            };

            if (!cachedObj) {
                cachedObj = this.createStoreCacheObj(exchange, symbol, companyId, requestBy, language);
            }

            if (dataType === undefined) {
                // no data type defined. Assume no data initialization is needed.
                return cachedObj.dataObj;
            }

            if (cachedObj.metaObj[dataType].isAvailable) {
                if (Ember['default'].$.isFunction(successFn)) {
                    successFn();
                }
            } else {
                // data not available state
                this.addCallbackFunctions(cachedObj, dataType, successFn, errorFn);

                if (!cachedObj.metaObj[dataType].isRequested) {
                    // data not available and already NO earlier request is processing
                    cachedObj.metaObj[dataType].isRequested = true;
                    switch (dataType) {
                        case priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyBasicInfo:
                            if (requestBy === priceConstants['default'].DataTypes.CompanyProfileDataStore.requestBy.companyId) {
                                priceService['default'].sendCompanyProfileByIdRequest(companyId, language, postSuccess, error);
                            } else {
                                priceService['default'].sendCompanyProfileRequest(exchange, symbol, language, postSuccess, error);
                            }
                            break;

                        case priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyManagementInfo:
                            if (requestBy === priceConstants['default'].DataTypes.CompanyProfileDataStore.requestBy.companyId) {
                                priceService['default'].sendCompanyMgmntByIdRequest(companyId, language, postSuccess, error);
                            } else {
                                priceService['default'].sendCompanyMgmntRequest(exchange, symbol, language, postSuccess, error);
                            }
                            break;

                        // currently only call by company ID
                        case priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyMajorShareholder:
                            priceService['default'].sendMajorSharhoderRequest(companyId, language, postSuccess, error);
                            break;

                        case priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyCreditRatings:
                            priceService['default'].sendCompanyCreditRatingsRequest(exchange, symbol, language, postSuccess, error);
                            break;

                        case priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyAnalystContacts:
                            priceService['default'].sendCompanyAnalystContactsRequest(exchange, symbol, language, postSuccess, error);
                            break;

                        case priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyOwnershipLimits:
                            priceService['default'].sendCompanyOwnershipLimitsRequest(exchange, symbol, language, postSuccess, error);
                            break;

                        case priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyShareholderSegmentation:
                            priceService['default'].sendCompanyShareholderSegmentationRequest(exchange, symbol, cachedObj.dataObj.compID, language, postSuccess, error);
                            break;
                        default:
                            utils['default'].logger.logError('Unsupported company-profile-data-store Data Type: ' + dataType);
                            break;
                    }
                }
            }

            return cachedObj.dataObj;
        }

    }).create();

});