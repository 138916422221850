define('ir-app/models/price/price-business-entities/capital-action', ['exports', 'ember', 'ir-app/utils/utils', 'ir-app/config/user-settings', 'ir-app/models/shared/language/language-data-store'], function (exports, Ember, utils, userSettings, languageDataStore) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({

        exg: '', // Exchange or news provider
        sym: '', // Symbol
        lang: '', // Language
        anDate: '', // Announcing Date
        typeName: '', // Corporate Action Type Name
        typeId: '', // corporate Action Type ID
        actionId: '', // action Id
        efDate: '', // Effective Date
        status: '',
        coDate: '', // completed Date
        adjFactor: '',
        factor: '', // split factor
        rtsEdDt: '', // Rights End Date
        rcdDate: '', // Record Date
        numOfShr: '', // number Of Shares
        numOfShrNw: '', // number Of Shares New
        oldParVal: '', // old Par value
        parValNw: '', // Par value New
        acStatus: '', // Action Status Value
        dividend_amount: '',
        currency: '',
        issue_price: '',
        number_treasury_stocks: '',
        period_end_date: '',
        cd_related_period: '',
        cd_related_period_name: '',
        split_factor: '',
        approval_status: '',
        abnormal_cd: '',
        cd_year: '',
        cd_period: '',
        old_paid: '',
        new_paid: '',
        cash_paid: '',
        price_limits_removal: '',

        anndDt: (function () {
            var dateTime = this.get('anDate');
            return utils['default'].formatters.formatToDateTime(dateTime);
        }).property('anDate'), // Display Announcing date Time

        eftdDt: (function () {
            var dateTime = this.get('efDate');
            return utils['default'].formatters.formatToDateTime(dateTime);
        }).property('efDate'), // Display Effective date Time

        comdDt: (function () {
            var dateTime = this.get('coDate');
            return utils['default'].formatters.formatToDateTime(dateTime);
        }).property('coDate'), // Display Completion date Time

        desStatus: (function () {
            var currentLangObj = languageDataStore['default'].getLanguageObj().lang.labels;
            if (this.get('status') === '0') {
                return currentLangObj.proposed;
            } else {
                return currentLangObj.final;
            }
        }).property('status'),

        shareChange: (function () {
            var share = this.get('numOfShr');
            var shareNew = this.get('numOfShrNw');
            var change = shareNew - share;
            return change;
        }).property('numOfShr', 'numOfShrNw'),

        shareChgPct: (function () {
            var share = this.get('numOfShr');
            var shareNew = this.get('numOfShrNw');
            if (shareNew && shareNew !== 0 || !isNaN(shareNew)) {
                var decrease = (shareNew - share) / share;
                return utils['default'].formatters.multiplyNumber(decrease, 100, 2);
            } else {
                return 0;
            }
        }).property('numOfShr', 'numOfShrNw'),

        hasShrChg: (function () {
            var share = this.get('numOfShr');
            var shareNew = this.get('numOfShrNw');
            if ((share || shareNew) && (!isNaN(share) || !isNaN(shareNew))) {
                return share !== shareNew;
            }
            return false;
        }).property('numOfShr', 'numOfShrNw'),

        parChange: (function () {
            var par = this.get('oldParVal');
            var parNew = this.get('parValNw');
            var change = parNew - par;
            return change;
        }).property('oldParVal', 'parValNw'),

        disFactor: (function () {
            var fact = utils['default'].formatters.formatNumber(this.get('adjFactor'), 4);
            var typeId = this.get('typeId');
            // factor should not display for the action type 7: (Cash Dividend)
            if (fact !== userSettings['default'].displayFormat.noValue && typeId !== '7') {
                var tmpFactor = '1 : ' + Number(fact);
                return tmpFactor;
            } else {
                return userSettings['default'].displayFormat.noValue;
            }
        }).property('adjFactor', 'typeId'),

        parChgPct: (function () {
            var par = this.get('oldParVal');
            var parNew = this.get('parValNw');
            if (parNew && parNew !== 0 || !isNaN(parNew)) {
                var decrease = (parNew - par) / par;
                return utils['default'].formatters.multiplyNumber(decrease, 100, 2);
            } else {
                return 0;
            }
        }).property('oldParVal', 'parValNw'),

        hasParChg: (function () {
            var par = this.get('oldParVal');
            var parNew = this.get('parValNw');
            if ((par || parNew) && (!isNaN(parNew) || !isNaN(par))) {
                return par !== parNew;
            }
            return false;
        }).property('oldParVal', 'parValNw'),

        rgtEndDt: (function () {
            var dateTime = this.get('rtsEdDt');
            return utils['default'].formatters.formatToDateTime(dateTime);
        }).property('rtsEdDt'), // Rights End date Time

        rcdDt: (function () {
            var dateTime = this.get('rcdDate');
            return utils['default'].formatters.formatToDateTime(dateTime);
        }).property('rcdDate'), // Record date Time

        setData: function setData(capitalAction) {
            var that = this;
            Ember['default'].$.each(capitalAction, function (key, value) {
                that.set(key, value);
            });
        }

    });

});