define('ir-app/controllers/ir-mobile/financial-ratio-widgets/financial-tabs-portals', ['exports', 'ir-app/controllers/base-controller'], function (exports, BaseController) {

    'use strict';

    exports['default'] = BaseController['default'].extend({

        Tabs: {
            IS: 1,
            BS: 2,
            CF: 3,
            RATIOS: 4
        },
        onLoadWidget: function onLoadWidget() {
            this.initializeComponent(1);
        },
        initializeComponent: function initializeComponent(tabId) {
            var controller = this.container.lookupFactory('controller:ir-widgets/financial-widgets/advance-financials').create();
            controller.set('sym', this.get('sym'));
            controller.set('exg', this.get('exg'));

            if (tabId === this.get('Tabs').IS) {
                controller.set('type', 'IS');
            } else if (tabId === this.get('Tabs').BS) {
                controller.set('type', 'BS');
            } else if (tabId === this.get('Tabs').CF) {
                controller.set('type', 'CF');
            } else if (tabId === this.get('Tabs').RATIOS) {
                controller.set('type', 'RATIOS');
            }
            var route = this.container.lookup('route:application');

            controller.initializeWidget({ wn: 'ir-mobile/financial-ratio-widgets/financial-tabs-portals' });

            route.render('ir-widgets/financial-widgets/advance-financials', {
                into: 'ir-mobile/financial-ratio-widgets/financial-tabs-portals',
                outlet: 'fnlWidgetOutlet',
                controller: controller
            });
        },

        actions: {
            onTabSelected: function onTabSelected(tabId) {
                this.initializeComponent(tabId);
            } }
    });

});