define('ir-app/templates/ir-mobile/peer-widgets/peer-table', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 2,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","padding-large-top padding-large-bottom border-top");
            var el4 = dom.createTextNode("\n                            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","margin-large-bottom dir-ltr snap-font-size-half");
            var el5 = dom.createTextNode("\n                                ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"id","oneFiftyTwo");
            dom.setAttribute(el5,"class","text-align-left left-lang");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                                ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"id","hfiftyTwo");
            dom.setAttribute(el5,"class","text-align-right right-lang");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("<div class=\"horizontal-sep-line margin-medium-top margin-medium-bottom\"></div>");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement, blockArguments) {
            var dom = env.dom;
            var hooks = env.hooks, set = hooks.set, get = hooks.get, concat = hooks.concat, attribute = hooks.attribute, content = hooks.content, subexpr = hooks.subexpr, inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(element0, [3]);
            var element2 = dom.childAt(element0, [5]);
            var element3 = dom.childAt(element0, [7]);
            var element4 = dom.childAt(element0, [9]);
            var element5 = dom.childAt(element0, [11]);
            var element6 = dom.childAt(element0, [13, 1]);
            var element7 = dom.childAt(element6, [3]);
            var attrMorph0 = dom.createAttrMorph(element0, 'style');
            var morph0 = dom.createMorphAt(dom.childAt(element0, [1]),0,0);
            var morph1 = dom.createMorphAt(element1,0,0);
            var attrMorph1 = dom.createAttrMorph(element1, 'class');
            var morph2 = dom.createMorphAt(element2,0,0);
            var attrMorph2 = dom.createAttrMorph(element2, 'class');
            var morph3 = dom.createMorphAt(element3,0,0);
            var attrMorph3 = dom.createAttrMorph(element3, 'class');
            var morph4 = dom.createMorphAt(element4,0,0);
            var attrMorph4 = dom.createAttrMorph(element4, 'class');
            var morph5 = dom.createMorphAt(element5,0,0);
            var attrMorph5 = dom.createAttrMorph(element5, 'class');
            var morph6 = dom.createMorphAt(element6,1,1);
            var morph7 = dom.createMorphAt(dom.childAt(element7, [1]),0,0);
            var morph8 = dom.createMorphAt(dom.childAt(element7, [3]),0,0);
            set(env, context, "stock", blockArguments[0]);
            set(env, context, "index", blockArguments[1]);
            attribute(env, attrMorph0, element0, "style", concat(env, ["--animation-order: ", get(env, context, "index")]));
            content(env, morph0, context, "stock.sDes");
            attribute(env, attrMorph1, element1, "class", concat(env, [subexpr(env, context, "getPositiveNegativeStyle", [get(env, context, "stock.pctChgW")], {})]));
            inline(env, morph1, context, "formatNumber", [get(env, context, "stock.pctChgW"), get(env, context, "stock.deci")], {});
            attribute(env, attrMorph2, element2, "class", concat(env, [subexpr(env, context, "getPositiveNegativeStyle", [get(env, context, "stock.pctChgM")], {})]));
            inline(env, morph2, context, "formatNumber", [get(env, context, "stock.pctChgM"), get(env, context, "stock.deci")], {});
            attribute(env, attrMorph3, element3, "class", concat(env, [subexpr(env, context, "getPositiveNegativeStyle", [get(env, context, "stock.pctChg3M")], {})]));
            inline(env, morph3, context, "formatNumber", [get(env, context, "stock.pctChg3M"), get(env, context, "stock.deci")], {});
            attribute(env, attrMorph4, element4, "class", concat(env, [subexpr(env, context, "getPositiveNegativeStyle", [get(env, context, "stock.pctYtd")], {})]));
            inline(env, morph4, context, "formatNumber", [get(env, context, "stock.pctYtd"), get(env, context, "stock.deci")], {});
            attribute(env, attrMorph5, element5, "class", concat(env, [subexpr(env, context, "getPositiveNegativeStyle", [get(env, context, "stock.pctChgY")], {})]));
            inline(env, morph5, context, "formatNumber", [get(env, context, "stock.pctChgY"), get(env, context, "stock.deci")], {});
            inline(env, morph6, context, "portal-range-display-slider", [], {"high": get(env, context, "stock.h52"), "low": get(env, context, "stock.l52"), "current": get(env, context, "stock.ltp"), "preClosed": get(env, context, "stock.prvCls")});
            inline(env, morph7, context, "formatNumber", [get(env, context, "stock.l52"), get(env, context, "stock.deci")], {});
            inline(env, morph8, context, "formatNumber", [get(env, context, "stock.h52"), get(env, context, "stock.deci")], {});
            return fragment;
          }
        };
      }());
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("tbody");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, block = hooks.block;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var morph0 = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
          block(env, morph0, context, "each", [get(env, context, "stockList")], {}, child0, null);
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table-responsive margin-large-top");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("table");
        dom.setAttribute(el2,"class","table numeric-table margin-medium-top col-xs-12 table-mobile-scroll peer-analysis");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("thead");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("tr");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("th");
        var el6 = dom.createElement("p");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("th");
        var el6 = dom.createElement("p");
        var el7 = dom.createTextNode("1W %");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("th");
        var el6 = dom.createElement("p");
        var el7 = dom.createTextNode("1M %");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("th");
        var el6 = dom.createElement("p");
        var el7 = dom.createTextNode("3M %");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("th");
        var el6 = dom.createElement("p");
        var el7 = dom.createTextNode("YTD %");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("th");
        var el6 = dom.createElement("p");
        var el7 = dom.createTextNode("1Y %");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("th");
        var el6 = dom.createElement("p");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, content = hooks.content, get = hooks.get, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element8 = dom.childAt(fragment, [0, 1]);
        var morph0 = dom.createMorphAt(dom.childAt(element8, [1, 1, 13, 0]),0,0);
        var morph1 = dom.createMorphAt(element8,3,3);
        content(env, morph0, context, "app.lang.labels.fiftyTwoWkRange");
        block(env, morph1, context, "if", [get(env, context, "dataAvailable")], {}, child0, null);
        return fragment;
      }
    };
  }()));

});