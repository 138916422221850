define('ir-app/controllers/controller-factory', ['exports'], function (exports) {

    'use strict';

    exports['default'] = (function () {
        // Stores prototypes for the requested controllers
        var controllerConstructors = {};

        /**
         * Returns an instance of the controller for the given widget template
         * If controller prototype is not requested earlier, creates one by router.controllerFor and returns
         * If controller prototype is already available, returns a new instance of the requested controller
         * Reference: http://stackoverflow.com/questions/27971548/access-parent-constructor-in-ember-js-object
         * Reference: http://stackoverflow.com/questions/15705411/multiple-instances-of-the-same-controller-simultaneously-in-ember
         * @param router Ember route
         * @param widgetName Name of the widget template
         * @returns {*} Instance of the widget template
         */
        var createController = function createController(router, widgetName) {
            var controller;

            if (!controllerConstructors[widgetName]) {
                controller = router.controllerFor(widgetName); // Instantiate a controller instance for the first time
                controllerConstructors[widgetName] = controller.constructor; // Stores the prototype to serve later requests
            } else {
                    controller = controllerConstructors[widgetName].create(); // Creates a new instance from the stored prototype
                }

            return controller;
        };

        return {
            createController: createController
        };
    })();

});