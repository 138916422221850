define('ir-app/models/chart/chart-constants', ['exports'], function (exports) {

  'use strict';

  exports['default'] = {
    ChartRefreshTimeInterval: 30000, // n * 1000 - n number of seconds
    ChartWaitInterval: 1000,

    ChartCategory: {
      Intraday: {
        ID: 0,
        LanguageTag: 'chartIntraday',
        Icon: 'chart-graph-tb-intraday',
        DefaultChartViewPeriod: 'OneDay',
        RowTickFormat: 'minute',
        DefaultDataRequestDuration: 5 // five days
      },
      History: {
        ID: 1,
        LanguageTag: 'chartHistory',
        Icon: 'chart-graph-tb-history',
        DefaultChartViewPeriod: 'OneYear',
        RowTickFormat: 'day',
        DefaultDataRequestDuration: 10 // ten years
      }
    },
    // Capacity of data request
    ChartDataLevel: {
      IntradayCurrentDay: 1,
      IntradayFiveDay: 2,
      IntradayOneMonth: 3,
      IntradayCustom: 4,
      HistoryTwoYear: 5,
      HistoryTenYear: 6,
      HistoryCustom: 7
    },

    ChartDataRequestMode: {
      Intraday5DayHistory: 1,
      IntradayActiveStock: 2,
      HistoryData: 3
    },

    ChartDefaultDataPeriod: {
      Year: 365,
      Month: 30,
      TwoDay: 2,
      FiveDay: 5,
      OneDay: 1,
      MilliSecondsPerDay: 86400000 // = 1000 * 60 * 60 * 24
    },

    ChartDataType: {
      Basic: 3,
      CorporateAction: 5,
      Advanced: 7
    },

    ChartStyle: {
      Area: {
        ID: 1,
        LanguageTag: 'chartArea',
        ChartIQChartType: 'mountain'
      },
      Candle: {
        ID: 3,
        LanguageTag: 'chartCandle',
        ChartIQChartType: 'candle'
      },
      OHLC: {
        ID: 4,
        LanguageTag: 'chartOHLC',
        ChartIQChartType: 'bar'
      },
      Line: {
        ID: 2,
        LanguageTag: 'chartLine',
        ChartIQChartType: 'line'
      },
      CandleWithTrend: {
        ID: 5,
        LanguageTag: 'chartCandleWithTrend',
        ChartIQChartType: 'hollow_candle'
      }
    },

    ChartViewInterval: {
      EveryMinutes: {
        LanguageTag: 'chartEveryMinute',
        ID: 1,
        Value: 1,
        PerSeconds: 60,
        IsHistory: false,
        unitName: 'minute',
        unit: [1],
        IsDefault: true
      },
      EveryFiveMinutes: {
        LanguageTag: 'chartEvery5Minutes',
        ID: 2,
        Value: 5,
        PerSeconds: 5 * 60,
        IsHistory: false,
        unitName: 'minute',
        unit: [5],
        IsDefault: false
      },
      EveryTenMinutes: {
        LanguageTag: 'chartEvery10Minutes',
        ID: 3,
        Value: 10,
        PerSeconds: 10 * 60,
        IsHistory: false,
        unitName: 'minute',
        unit: [10],
        IsDefault: false
      },
      EveryFiftyMinutes: {
        LanguageTag: 'chartEvery15Minutes',
        ID: 4,
        Value: 15,
        PerSeconds: 15 * 60,
        IsHistory: false,
        unitName: 'minute',
        unit: [15],
        IsDefault: false
      },
      EveryThirtyMinutes: {
        LanguageTag: 'chartEvery30Minutes',
        ID: 5,
        Value: 30,
        PerSeconds: 30 * 60,
        IsHistory: false,
        unitName: 'minute',
        unit: [30],
        IsDefault: false
      },
      EverySixtyMinutes: {
        LanguageTag: 'chartEvery60Minutes',
        ID: 6,
        Value: 60,
        PerSeconds: 60 * 60,
        IsHistory: false,
        unitName: 'hour',
        unit: [1],
        IsDefault: false
      },
      Daily: {
        LanguageTag: 'chartDaily',
        ID: 7,
        Value: 1,
        PerSeconds: 24 * 3600, // 1 * 24 * 3600
        IsHistory: true,
        unitName: 'day',
        unit: [1],
        IsDefault: true
      },
      Weekly: {
        LanguageTag: 'chartWeekly',
        ID: 8,
        Value: 7,
        PerSeconds: 7 * 24 * 3600,
        IsHistory: true,
        unitName: 'week',
        unit: [1],
        IsDefault: false
      },
      Monthly: {
        LanguageTag: 'chartMonthly',
        ID: 9,
        Value: 30,
        PerSeconds: 30 * 24 * 3600,
        IsHistory: true,
        unitName: 'month',
        unit: [1],
        IsDefault: false
      },
      Quarterly: {
        LanguageTag: 'chartQuarterly',
        ID: 4,
        Value: 90,
        PerSeconds: 365 * 6 * 3600,
        IsHistory: true,
        unitName: 'month',
        unit: [4, 8, 12],
        IsDefault: false
      },
      Yearly: {
        LanguageTag: 'chartYearly',
        ID: 10,
        Value: 365,
        PerSeconds: 365 * 24 * 3600,
        IsHistory: true,
        unitName: 'year',
        unit: [1],
        IsDefault: false

      }
    },

    ChartViewPeriod: {
      OneDay: {
        DisplayName: '1D',
        ID: 0,
        IsHistory: false,
        ChartDataLevel: 1,
        DefaultInterval: 'EveryMinutes' // ChartInterval.Intraday.EveryMinutes
      },
      TwoDay: {
        DisplayName: '2D',
        ID: 1,
        IsHistory: false,
        ChartDataLevel: 2,
        DefaultInterval: 'EveryMinutes' // ChartInterval.Intraday.EveryMinutes
      },
      FiveDay: {
        DisplayName: '5D',
        ID: 2,
        IsHistory: false,
        ChartDataLevel: 2,
        DefaultInterval: 'EveryMinutes' // ChartInterval.Intraday.EveryMinutes
      },
      OneMonth: {
        DisplayName: '1M',
        ID: 3,
        IsHistory: true,
        ChartDataLevel: 5,
        DefaultInterval: 'Daily' // ChartInterval.History.Daily
      },
      ThreeMonth: {
        DisplayName: '3M',
        ID: 4,
        IsHistory: true,
        ChartDataLevel: 5,
        DefaultInterval: 'Daily' // ChartInterval.History.Daily
      },
      SixMonth: {
        DisplayName: '6M',
        ID: 5,
        IsHistory: true,
        ChartDataLevel: 5,
        DefaultInterval: 'Daily' // ChartInterval.History.Daily
      },
      YTD: {
        DisplayName: 'YTD',
        ID: 6,
        IsHistory: true,
        ChartDataLevel: 5,
        DefaultInterval: 'Daily' // ChartInterval.History.Daily
      },
      OneYear: {
        DisplayName: '1Y',
        ID: 7,
        IsHistory: true,
        ChartDataLevel: 5,
        DefaultInterval: 'Daily' // ChartInterval.History.Daily
      },
      TwoYear: {
        DisplayName: '2Y',
        ID: 8,
        IsHistory: true,
        ChartDataLevel: 5,
        DefaultInterval: 'Daily' // ChartInterval.History.Daily
      },
      ThreeYear: {
        DisplayName: '3Y',
        ID: 9,
        IsHistory: true,
        ChartDataLevel: 5,
        DefaultInterval: 'Daily' // ChartInterval.History.Daily
      },
      FiveYear: {
        DisplayName: '5Y',
        ID: 10,
        IsHistory: true,
        ChartDataLevel: 5,
        DefaultInterval: 'Daily' // ChartInterval.History.Daily
      },
      TenYear: {
        DisplayName: '10Y',
        ID: 11,
        IsHistory: true,
        ChartDataLevel: 5,
        DefaultInterval: 'Daily' // ChartInterval.History.Daily
      },
      All: {
        DisplayName: 'MAX',
        ID: 12,
        IsHistory: true,
        ChartDataLevel: 5,
        DefaultInterval: 'Daily' // ChartInterval.History.Daily
      },
      Custom: {
        DisplayName: '*',
        ID: 13,
        IsHistory: true,
        ChartDataLevel: 5,
        DefaultInterval: 'EveryMinutes' // ChartInterval.Intraday.EveryMinutes
      }
    },

    ChartViewPeriodAR: {
      OneDay: {
        DisplayName: 'يوم',
        ID: 0,
        IsHistory: false,
        ChartDataLevel: 1,
        DefaultInterval: 'EveryMinutes' // ChartInterval.Intraday.EveryMinutes
      },
      TwoDay: {
        DisplayName: '2D',
        ID: 1,
        IsHistory: false,
        ChartDataLevel: 2,
        DefaultInterval: 'EveryMinutes' // ChartInterval.Intraday.EveryMinutes
      },
      FiveDay: {
        DisplayName: '5 أيام',
        ID: 2,
        IsHistory: false,
        ChartDataLevel: 2,
        DefaultInterval: 'EveryMinutes' // ChartInterval.Intraday.EveryMinutes
      },
      OneMonth: {
        DisplayName: 'شهر',
        ID: 3,
        IsHistory: true,
        ChartDataLevel: 5,
        DefaultInterval: 'Daily' // ChartInterval.History.Daily
      },
      ThreeMonth: {
        DisplayName: '3M',
        ID: 4,
        IsHistory: true,
        ChartDataLevel: 5,
        DefaultInterval: 'Daily' // ChartInterval.History.Daily
      },
      SixMonth: {
        DisplayName: '6M',
        ID: 5,
        IsHistory: true,
        ChartDataLevel: 5,
        DefaultInterval: 'Daily' // ChartInterval.History.Daily
      },
      YTD: {
        DisplayName: 'YTD',
        ID: 6,
        IsHistory: true,
        ChartDataLevel: 5,
        DefaultInterval: 'Daily' // ChartInterval.History.Daily
      },
      OneYear: {
        DisplayName: 'عام',
        ID: 7,
        IsHistory: true,
        ChartDataLevel: 5,
        DefaultInterval: 'Daily' // ChartInterval.History.Daily
      },
      TwoYear: {
        DisplayName: 'عامين',
        ID: 8,
        IsHistory: true,
        ChartDataLevel: 5,
        DefaultInterval: 'Daily' // ChartInterval.History.Daily
      },
      ThreeYear: {
        DisplayName: '3Y',
        ID: 9,
        IsHistory: true,
        ChartDataLevel: 5,
        DefaultInterval: 'Daily' // ChartInterval.History.Daily
      },
      FiveYear: {
        DisplayName: '5 أعوام',
        ID: 10,
        IsHistory: true,
        ChartDataLevel: 5,
        DefaultInterval: 'Daily' // ChartInterval.History.Daily
      },
      TenYear: {
        DisplayName: '10Y',
        ID: 11,
        IsHistory: true,
        ChartDataLevel: 5,
        DefaultInterval: 'Daily' // ChartInterval.History.Daily
      },
      All: {
        DisplayName: 'الأعلي',
        ID: 12,
        IsHistory: true,
        ChartDataLevel: 5,
        DefaultInterval: 'Daily' // ChartInterval.History.Daily
      },
      Custom: {
        DisplayName: '*',
        ID: 13,
        IsHistory: true,
        ChartDataLevel: 5,
        DefaultInterval: 'EveryMinutes' // ChartInterval.Intraday.EveryMinutes
      }
    },

    // TODO: [Amila] Move below definition to a place where layout related configs are placed
    DetaiQuoteChartPeriodTab: {
      OneDay: true,
      TwoDay: true,
      FiveDay: false,
      OneMonth: true,
      ThreeMonth: true,
      SixMonth: true,
      YTD: false,
      OneYear: false,
      TwoYear: false,
      ThreeYear: false,
      FiveYear: false,
      TenYear: false,
      All: false,
      Custom: false
    },

    // TODO: [Amila] Move below definition to a place where layout related configs are placed
    ProChartPeriodTab: {
      OneDay: true,
      TwoDay: true,
      FiveDay: false,
      OneMonth: true,
      ThreeMonth: true,
      SixMonth: true,
      YTD: true,
      OneYear: true,
      TwoYear: true,
      ThreeYear: false,
      FiveYear: false,
      TenYear: false,
      All: true,
      Custom: false
    },

    IRChartPeriodTab: {
      OneDay: true,
      TwoDay: false,
      FiveDay: true,
      OneMonth: true,
      ThreeMonth: false,
      SixMonth: false,
      YTD: false,
      OneYear: true,
      TwoYear: true,
      ThreeYear: false,
      FiveYear: true,
      TenYear: false,
      All: true,
      Custom: false
    },

    ProChartViewInterval: {
      EveryMinutes: true,
      EveryFiveMinutes: true,
      EveryTenMinutes: true,
      EveryFiftyMinutes: true,
      EveryThirtyMinutes: true,
      EverySixtyMinutes: true,
      Daily: true,
      Weekly: true,
      Monthly: true,
      Quarterly: true,
      Yearly: true
    },

    ProChartGridStyle: {
      Both: {
        ID: 0,
        LanguageTag: 'chartGridBoth',
        Icon: 'chart-graph-tb-grid-both'
      },
      None: {
        ID: 1,
        LanguageTag: 'chartGridNone',
        Icon: 'chart-graph-tb-grid-none'
      },
      Horizontal: {
        ID: 2,
        LanguageTag: 'chartGridHoriz',
        Icon: 'chart-graph-tb-grid-horiz'
      },
      Vertical: {
        ID: 3,
        LanguageTag: 'chartGridVerti',
        Icon: 'chart-graph-tb-grid-verti'
      }
    },

    ChartGAActions: {
      indAdded: 'indiacator-added',
      compare: 'compare'
    },

    IRChartStyle: {

      Line: {
        ID: 2,
        LanguageTag: 'chartLine',
        ChartIQChartType: 'line'
      },
      Area: {
        ID: 1,
        LanguageTag: 'chartArea',
        ChartIQChartType: 'mountain'
      },
      Candle: {
        ID: 3,
        LanguageTag: 'chartCandle',
        ChartIQChartType: 'candle'
      },
      OHLC: {
        ID: 4,
        LanguageTag: 'chartOHLC',
        ChartIQChartType: 'bar'
      }
    },

    IRChartTypes: {
      Area: 'area',
      CandleStick: 'candlestick',
      OHLC: 'ohlc',
      Line: 'line'
    },

    IRIndicators: {
      SimpleMovingAverage: {
        ID: 1,
        LanguageTag: 'simpleMovingAvg', //'Simple moving average (14)',
        IsAdded: false,
        Params: {
          id: 'primary',
          name: 'SMA (14)',
          type: 'sma',
          styles: {
            strokeWidth: 2,
            stroke: 'red',
            dashstyle: 'solid'
          },
          params: {
            period: 14
          },
          showInLegend: false
        }
      },
      ExponentialMovingAverage: {
        ID: 2,
        LanguageTag: 'expMovAvg', //'Exponential Moving Average (14)',
        IsAdded: false,
        Params: {
          id: 'primary',
          type: 'ema',
          name: 'EMA (14)',
          styles: {
            strokeWidth: 2,
            stroke: 'green',
            dashstyle: 'solid'
          },
          params: {
            period: 14
          },
          showInLegend: false
        }
      },
      RSI: {
        ID: 3,
        LanguageTag: 'RSI',
        IsAdded: false,
        Params: {
          id: 'primary',
          type: 'rsi',
          name: 'RSI',
          styles: {
            strokeWidth: 2,
            stroke: 'black',
            dashstyle: 'solid'
          },
          showInLegend: false
        }
      }
    },
    PortalIndicators: {
      SimpleMoving10Average: {
        ID: 1,
        LanguageTag: 'SimpleMovingAverage10',
        IsAdded: false,
        Params: {
          id: 'primary',
          name: 'SMA (10)',
          type: 'sma',
          styles: {
            strokeWidth: 2,
            stroke: '#ff0000',
            dashstyle: 'solid'
          },
          params: {
            period: 10
          },
          showInLegend: true
        }
      },
      SimpleMoving25Average: {
        ID: 2,
        LanguageTag: 'SimpleMovingAverage25',
        IsAdded: false,
        Params: {
          id: 'primary',
          name: 'SMA (25)',
          type: 'sma',
          styles: {
            strokeWidth: 2,
            stroke: '#ff6600',
            dashstyle: 'solid'
          },
          params: {
            period: 25
          },
          showInLegend: true
        }
      },
      SimpleMoving50Average: {
        ID: 3,
        LanguageTag: 'SimpleMovingAverage50',
        IsAdded: false,
        Params: {
          id: 'primary',
          name: 'SMA (50)',
          type: 'sma',
          styles: {
            strokeWidth: 2,
            stroke: '#660000',
            dashstyle: 'solid'
          },
          params: {
            period: 50
          },
          showInLegend: true
        }
      },
      Exponential10MovingAverage: {
        ID: 4,
        LanguageTag: 'ExponentialMovingAverage10',
        IsAdded: false,
        Params: {
          id: 'primary',
          name: 'EMA (10)',
          type: 'ema',
          styles: {
            strokeWidth: 2,
            stroke: '#00cc99',
            dashstyle: 'solid'
          },
          params: {
            period: 10
          },
          showInLegend: true
        }
      },
      Exponential25MovingAverage: {
        ID: 5,
        LanguageTag: 'ExponentialMovingAverage25',
        IsAdded: false,
        Params: {
          id: 'primary',
          name: 'EMA (25)',
          type: 'ema',
          styles: {
            strokeWidth: 2,
            stroke: '#669900',
            dashstyle: 'solid'
          },
          params: {
            period: 25
          },
          showInLegend: true
        }
      },
      Exponential50MovingAverage: {
        ID: 6,
        LanguageTag: 'ExponentialMovingAverage50',
        IsAdded: false,
        Params: {
          id: 'primary',
          name: 'EMA (50)',
          type: 'ema',
          styles: {
            strokeWidth: 2,
            stroke: '#33cc33',
            dashstyle: 'solid'
          },
          params: {
            period: 50
          },
          showInLegend: true
        }
      },
      RSI: {
        ID: 7,
        LanguageTag: 'RSI',
        IsAdded: false,
        Params: {
          id: 'primary',
          name: 'RSI',
          type: 'rsi',
          styles: {
            strokeWidth: 2,
            stroke: 'black',
            dashstyle: 'solid'
          },
          yAxis: {
            labels: {
              align: 'center',
              enabled: true,
              x: -15
            },
            opposite: true
          },
          showInLegend: true
        }
      },
      ATR: {
        ID: 8,
        LanguageTag: 'ATR',
        IsAdded: false,
        Params: {
          id: 'primary',
          name: 'ATR',
          type: 'atr',
          params: {
            period: 14
          },
          yAxis: {
            labels: {
              align: 'center',
              enabled: true,
              x: -15
            },
            opposite: true
          },
          styles: {
            strokeWidth: 2,
            stroke: 'orange',
            dashstyle: 'solid'
          },
          showInLegend: true
        }
      }
    }
  };

});