define('ir-app/controllers/ir-widgets/exchange-summary/market-ratios', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service'], function (exports, Ember, BaseController, priceService, sharedService) {

    'use strict';

    exports['default'] = BaseController['default'].extend({

        indexDataList: Ember['default'].A(),
        selectedTab: undefined,
        lang: undefined,

        onPrepareData: function onPrepareData() {
            var that = this;
            var indexList = that.get('keys');
            var tmpIdxDataList = that.get('indexDataList');
            that.set('clientStkPage', that.get('clientStkPage'));
            that.set('lang', sharedService['default'].userSettings.currentLanguage.toLowerCase());

            indexList.forEach(function (item) {
                var idx = priceService['default'].stockDS.getStock(item.exg, item.idxCode);
                priceService['default'].addIndexRequest(item.exg, item.idxCode);

                var prop = ['idxDisp', sharedService['default'].userSettings.currentLanguage].join('');
                idx.idxLabel = item[prop];

                prop = ['mktDisp', sharedService['default'].userSettings.currentLanguage].join('');
                idx.marketName = item[prop];

                idx.curCode = item.curCode;
                tmpIdxDataList.push(idx);
            });
        },

        onClearData: function onClearData() {
            var tmpIdxDataList = this.get('indexDataList');

            tmpIdxDataList.forEach(function (item) {
                priceService['default'].removeSymbolRequest(item.exg, item.idxCode);
            });
            this.set('currencyList', []);
        }

    });

});