define('ir-app/controllers/ir-mobile/top-stocks-widgets/top-stocks-list', ['exports', 'ember', 'ir-app/models/price/price-service', 'ir-app/controllers/base-array-controller', 'ir-app/models/shared/shared-service', 'ir-app/models/price/price-constants'], function (exports, Ember, priceService, BaseArrayController, sharedService, PriceConstants) {

    'use strict';

    exports['default'] = BaseArrayController['default'].extend({
        topStocksRowCount: 10,
        top_stock_type_TopView: 1,
        top_stock_type_BottomView: 4,
        lang: undefined,

        // Base overrides
        onLoadWidget: function onLoadWidget() {
            var displayKeyList = [];
            var keyVal;
            if (this.get('dropDownKeys') !== undefined && this.get('dropDownKeys').length > 0) {
                this.get('dropDownKeys').forEach(function (item) {
                    keyVal = {};
                    keyVal.exg = item.exg;
                    var prop = ['displayName', sharedService['default'].userSettings.currentLanguage].join('');
                    keyVal.displayName = item[prop];
                    displayKeyList.push(keyVal);
                });

                this.setValueToBaseExchangeContainer('keyList', displayKeyList);
            }
        },

        onCheckDataAvailability: function onCheckDataAvailability() {
            return this.get('filteredContentTopView').length > 0 || this.get('filteredContentBottomView').length > 0;
        },

        onPrepareData: function onPrepareData() {
            this.set('lang', sharedService['default'].userSettings.currentLanguage.toLowerCase());
            sharedService['default'].userSettings.currentExchange = this.get('exg');
            var exgObj = priceService['default'].exchangeDS.getExchange(this.get('exg'));
            this.set('exchange', exgObj);

            priceService['default'].downloadExchangeProperty(this.get('exg'), sharedService['default'].userSettings.currentLanguage);

            this.set('tsContentTopView', priceService['default'].topStockDS.getTopStocksCollectionByType(this.get('exg'), this.get('top_stock_type_TopView')));
            this.set('tsContentBottomView', priceService['default'].topStockDS.getTopStocksCollectionByType(this.get('exg'), this.get('top_stock_type_BottomView')));

            this.setRequestTimeout(4, 'tsContentTopView.length');

            //this._onTabChangeTopView(this.get('top_stock_type_TopView'));
            //this._onTabChangeTopView(this.get('top_stock_type_BottomView'));
        },

        loadDataTopView: (function () {
            Ember['default'].run.cancel(this.get('timerTopView'));

            var exgObj = this.get('exchange');
            var subMkts = exgObj.getMainSubMkt();

            if (exgObj.isSubMktAvailable && subMkts) {
                this.loadContentTopView(exgObj.exg, subMkts.subMktCode);
            } else {
                this.loadContentTopView(exgObj.exg, -1);
            }
        }).observes('exchange.isSubMktAvailable'),

        loadContentTopView: function loadContentTopView(exchange, subMarket) {
            var that = this;
            // Update top stock data store periodically
            if (this.get('top_stock_type_TopView') !== undefined) {
                priceService['default'].sendTopStocksRequest(exchange, this.get('top_stock_type_TopView'), subMarket);
            }
            var timer = Ember['default'].run.later(function () {
                that.loadContentTopView(exchange, subMarket);
            }, PriceConstants['default'].TimeIntervals.TopStocksUpdateInterval);

            this.set('timerTopView', timer);
        },

        loadDataBottomView: (function () {
            Ember['default'].run.cancel(this.get('timerBottomView'));

            var exgObj = this.get('exchange');
            var subMkts = exgObj.getMainSubMkt();

            if (exgObj.isSubMktAvailable && subMkts) {
                this.loadContentBottomView(exgObj.exg, subMkts.subMktCode);
            } else {
                this.loadContentBottomView(exgObj.exg, -1);
            }
        }).observes('exchange.isSubMktAvailable'),

        loadContentBottomView: function loadContentBottomView(exchange, subMarket) {
            var that = this;
            // Update top stock data store periodically
            if (this.get('top_stock_type_BottomView') !== undefined) {
                priceService['default'].sendTopStocksRequest(exchange, this.get('top_stock_type_BottomView'), subMarket);
            }
            var timer = Ember['default'].run.later(function () {
                that.loadContentBottomView(exchange, subMarket);
            }, PriceConstants['default'].TimeIntervals.TopStocksUpdateInterval);

            this.set('timerBottomView', timer);
        },

        _onTabChangeTopView: function _onTabChangeTopView(tabId) {
            this.set('top_stock_type_TopView', tabId);
        },

        _onTabChangeBottomView: function _onTabChangeBottomView(tabId) {
            this.set('top_stock_type_BottomView', tabId);
        },

        onClearData: function onClearData() {
            this.set('tsContentTopView', []);
            this.set('tsContentBottomView', []);
        },

        onUnloadWidget: function onUnloadWidget() {
            Ember['default'].run.cancel(this.get('timerTopView'));
            Ember['default'].run.cancel(this.get('timerBottomView'));
        },

        filteredContentTopView: (function () {
            return this.getFilteredContent(this.get('tsContentTopView'));
        }).property('tsContentTopView.@each'),

        filteredContentBottomView: (function () {
            return this.getFilteredContent(this.get('tsContentBottomView'));
        }).property('tsContentBottomView.@each'),

        getFilteredContent: function getFilteredContent(content) {
            var filteredArray = [];
            var rowCount = this.topStocksRowCount;

            Ember['default'].$.each(content, function (index, item) {
                if (index === rowCount) {
                    return false;
                }
                filteredArray.push(item);
            });

            return filteredArray;
        },

        topViewTabSelected: (function () {
            this.set('tsContentTopView', priceService['default'].topStockDS.getTopStocksCollectionByType(this.get('exg'), this.get('top_stock_type_TopView')));
            this.loadDataTopView();
        }).observes('top_stock_type_TopView'),

        bottomViewTabSelected: (function () {
            this.set('tsContentBottomView', priceService['default'].topStockDS.getTopStocksCollectionByType(this.get('exg'), this.get('top_stock_type_BottomView')));

            if (this.get('top_stock_type_BottomView') === PriceConstants['default'].TopStocksTypes.MostActiveByValue) {
                this.set('isTurnOver', true);
            } else {
                this.set('isTurnOver', false);
            }

            this.loadDataBottomView();
        }).observes('top_stock_type_BottomView'),

        actions: {
            onTabSelectedTopView: function onTabSelectedTopView(tabId) {
                this._onTabChangeTopView(tabId);
            },
            onTabSelectedBottomView: function onTabSelectedBottomView(tabId) {
                this._onTabChangeBottomView(tabId);
            }
        }
    });

});