define('ir-app/models/price/price-business-entities/non-capital-action', ['exports', 'ember', 'ir-app/utils/utils'], function (exports, Ember, utils) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({

        exg: '', // Exchange or news provider
        sym: '', // Symbol
        lang: '', // Language
        newsId: '',
        anDate: '', // Announcing Date
        typeName: '', // Corporate Action Type Name
        typeId: '', // corporate Action Type ID
        efDate: '', // Effective Date
        desc: '',

        anndDt: (function () {
            var dateTime = this.get('anDate');
            return utils['default'].formatters.formatToDateTime(dateTime);
        }).property('anDate'), // Display Announcing date Time

        eftdDt: (function () {
            var dateTime = this.get('efDate');
            return utils['default'].formatters.formatToDateTime(dateTime);
        }).property('efDate'), // Display Effective date Time

        setData: function setData(nonCapitalAction) {
            var that = this;

            Ember['default'].$.each(nonCapitalAction, function (key, value) {
                that.set(key, value);
            });
        }

    });

});