define('ir-app/controllers/ir-mobile/file-widgets/file-list-new', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/config/price-widget-config', 'ir-app/models/shared/shared-service', 'ir-app/models/price/price-constants', 'ir-app/config/app-config', 'ir-app/utils/utils'], function (exports, Ember, BaseController, priceService, priceWidgetConfig, sharedService, priceConstants, appConfig, utils) {

    'use strict';

    exports['default'] = BaseController['default'].extend({
        files: Ember['default'].A(),
        basicInfo: {},
        selectedScat: null,
        scats: priceWidgetConfig['default'].scats,
        groupFiles: false,
        fileByYear: Ember['default'].A(),
        fileSelected: false,
        viewDate: true,
        isMobile: false,
        dropDownEnabled: false,
        showRawTitle: true,
        totalRelease: 0,
        defaultSelectYear: 2021,
        historyYears: 5, // default history is for 5 years. set through config to change to any other value

        // Base overrides
        onLoadWidget: function onLoadWidget() {
            this.setErrorMessage();

            if (sharedService['default'].userSettings.currentAppMode === appConfig['default'].applicationMode.ir_mobile.identifier) {
                this.set('isMobile', true);
            }
        },

        onPrepareData: function onPrepareData() {
            var that = this;
            var lanStore = this.get('app').lang.labels;
            this.set('dropDownEnabled', this.get('scats').length > 1);
            this.get('scats').forEach(function (item) {
                item.des = lanStore[item.sDes];
            }, this.get('scats'));

            var basicInfo = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, function () {
                that.onDataSuccess();
            }, function () {
                that.onDataError();
            }, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyBasicInfo);

            this.set('selectedScat', this.get('scats')[0]);
            this.set('basicInfo', basicInfo);
        },

        onSubCategoryChange: (function () {
            this.set('files', Ember['default'].A());
            var selectedScat = this.get('selectedScat');
            this.set('groupFiles', selectedScat.groupBy);
            if (selectedScat.viewDate) {
                this.set('viewDate', true);
            } else {
                this.set('viewDate', false);
            }
            this.populateFiles();
        }).observes('selectedScat'),

        populateFiles: function populateFiles() {
            var that = this;
            var basicInfo = this.get('basicInfo');
            this.hideDataErrorMessage();
            this.startLoadingProgress();
            var fromYear = this.get('defaultSelectYear');
            var toYear = fromYear + 1;

            var historyYears = [fromYear, toYear];
            var filesArray = priceService['default'].fileDS.getFilesCollection(that.get('exg'), that.get('sym'), basicInfo.compID, basicInfo.tickerSerial, "9,10,37,38,39,40,45,57", historyYears, sharedService['default'].userSettings.currentLanguage.toUpperCase(), function () {
                that.onDataSuccess();
            }, function () {
                that.onDataError();
            });

            if (filesArray) {
                that.set('files', filesArray);

                if (filesArray.length > 0) {
                    that.onDataSuccess(filesArray);
                }
            }
        },

        reLoadFileData: (function () {
            this.populateFiles();
        }).observes('basicInfo.tickerSerial'),

        caclFilesByYear: (function () {
            var result = Ember['default'].A();
            var that = this;
            var docCount = 0;
            var content = this.get('files');
            content.forEach(function (file) {

                if (that.get('defaultSelectYear') == file.year) {
                    var yr = file.get('scat');
                    if (yr === '10' || yr === '9') {
                        yr = '9,10';
                    }
                    var found = result.findBy('scat', yr);
                    if (!found) {
                        result.pushObject(Ember['default'].Object.create({ scat: yr, files: Ember['default'].A() }));
                    }
                    result.findBy('scat', yr).get('files').pushObject(file);
                    docCount++;
                }
            });

            this.set('totalRelease', docCount);
            this.set('filesByYear', result);
        }).observes('files.@each'),

        onCheckDataAvailability: function onCheckDataAvailability() {
            var filesArray = this.get('files');
            return filesArray.length > 0;
        },

        onClearData: function onClearData() {
            this.set('files', Ember['default'].A());
            this.hideDataErrorMessage();
        },

        onLanguageChanged: function onLanguageChanged() {
            this.onPrepareData();
            this.setErrorMessage();
        },

        searchInData: function searchInData() {

            var result = Ember['default'].A();
            var dtStart = Date.parse(this.get('fromDate'));
            var dtend = Date.parse(this.get('toDate'));
            if (isNaN(dtend)) {
                dtend = Date.parse(new Date());
            }
            var content = this.get('files');

            if (this.get('keyword') != undefined) {
                if (isNaN(dtStart)) {
                    this.keyWordSearchNoDate(content);
                } else {
                    this.keyWordSearch(dtStart, dtend, content);
                }
            } else {
                this.keyWithoutWordSearch(dtStart, dtend, content);
            }
        },
        keyWordSearchNoDate: function keyWordSearchNoDate(Content) {
            var result = Ember['default'].A();
            var that = this;
            var docCount = 0;

            Content.forEach(function (file) {

                if (that.get('keyword') != undefined && file.dTitle.toLowerCase().indexOf(that.get('keyword').toLowerCase()) > -1) {
                    var found = result.findBy('scat', file.scat);
                    if (!found) {
                        result.pushObject(Ember['default'].Object.create({ scat: file.scat, files: Ember['default'].A() }));
                    }
                    result.findBy('scat', file.scat).get('files').pushObject(file);
                    docCount++;
                }
            });
            this.set('totalRelease', docCount);
            this.set('filesByYear', result);
        },
        keyWordSearch: function keyWordSearch(startDate, EndDate, Content) {
            var result = Ember['default'].A();
            var that = this;
            var docCount = 0;

            Content.forEach(function (file) {
                var fileDate = Date.parse(that.utils.formatters.convertStringToDate(file.rdt));

                if (fileDate >= startDate && fileDate <= EndDate) {
                    if (file.scat === '10' || file.scat === '9') {
                        file.scat = '9,10';
                    }

                    if (that.get('keyword') != undefined && file.dTitle.toLowerCase().indexOf(that.get('keyword').toLowerCase()) > -1) {
                        var found = result.findBy('scat', file.scat);
                        if (!found) {
                            result.pushObject(Ember['default'].Object.create({ scat: file.scat, files: Ember['default'].A() }));
                        }
                        result.findBy('scat', file.scat).get('files').pushObject(file);
                        docCount++;
                    }
                }
            });
            this.set('totalRelease', docCount);
            this.set('filesByYear', result);
        },
        keyWithoutWordSearch: function keyWithoutWordSearch(startDate, EndDate, Content) {
            var result = Ember['default'].A();
            var that = this;
            var docCount = 0;

            Content.forEach(function (file) {
                if (file.scat === '10' || file.scat === '9') {
                    file.scat = '9,10';
                }
                var fileDate = Date.parse(that.utils.formatters.convertStringToDate(file.rdt));
                if (fileDate >= startDate && fileDate <= EndDate) {
                    var found = result.findBy('scat', file.scat);
                    if (!found) {
                        result.pushObject(Ember['default'].Object.create({ scat: file.scat, files: Ember['default'].A() }));
                    }
                    result.findBy('scat', file.scat).get('files').pushObject(file);
                    docCount++;
                }
            });
            this.set('totalRelease', docCount);
            this.set('filesByYear', result);
        },

        actions: {
            onItemClicked: function onItemClicked(file) {
                this.set('fileObj', file);
                this.set('fileSelected', true);
            },
            onCloseSlide: function onCloseSlide() {
                this.set('fileSelected', false);
            },
            changeSelectedScat: function changeSelectedScat(value) {
                this.set('selectedScat', value);
            },
            changeYear: function changeYear(year) {

                var btns = document.querySelectorAll(".btn-disclosure");
                var _iteratorNormalCompletion = true;
                var _didIteratorError = false;
                var _iteratorError = undefined;

                try {
                    for (var _iterator = btns[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                        var btn = _step.value;

                        if (year == btn.innerHTML) {
                            btn.classList.add('active-btn');
                        } else {
                            btn.classList.remove('active-btn');
                        }
                    }
                } catch (err) {
                    _didIteratorError = true;
                    _iteratorError = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion && _iterator['return']) {
                            _iterator['return']();
                        }
                    } finally {
                        if (_didIteratorError) {
                            throw _iteratorError;
                        }
                    }
                }

                this.set('defaultSelectYear', year);
                this.onClearData();
                this.populateFiles();
            },

            search: function search() {

                if (Ember['default'].$('.search-btn').html() == 'Search') {
                    this.searchInData();
                    Ember['default'].$('.search-btn').html('Cancel');
                } else {
                    Ember['default'].$('.search-btn').html('Search');
                    this.set('fromDate', '');
                    this.set('toDate', '');
                    this.set('keyword', '');
                    this.onClearData();
                    this.populateFiles();
                }
            }
        }

    });

    Ember['default'].Handlebars.helper('returnCat', function (type) {
        switch (type) {
            case "37":
                return "Press Releases";
            case "9,10":
                return "Financial Statement";
            case "38":
                return "Board Meeting Disclosure";
            case "39":
                return "Annual Meeting Disclosure";
            case "45":
                return "Annual Reports";
            case "40":
                return "Others";
            case "57":
                return "Basel III";
        }
    });

});