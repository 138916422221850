define('ir-app/router', ['exports', 'ember', 'ir-app/config/environment', 'ir-app/config/app-config'], function (exports, Ember, config, appConfig) {

    'use strict';

    var Router = Ember['default'].Router.extend({
        location: config['default'].locationType
    });

    exports['default'] = Router.map(function () {
        var _getParams = function _getParams(arrParams) {
            var paramStr = '';
            Ember['default'].$.each(arrParams, function (index, parameter) {
                paramStr += '/:' + parameter;
            });

            return paramStr;
        };

        // Mobile routes
        this.route('/', { path: _getParams(appConfig['default'].applicationMode.ir_mobile.params) });

        // IR routes
        this.route('/', { path: _getParams(appConfig['default'].applicationMode.ir_page.params) });
    });

});