define('ir-app/config/module-initializer-config', ['exports', 'ir-app/controllers/price/initializers/price-ui-module-initializer', 'ir-app/models/shared/shared-initializers/shared-data-module-initializer'], function (exports, priceUiModuleInitializer, sharedDataModuleInitializer) {

    'use strict';

    // import priceDataModuleInitializer from '../models/price/price-initializers/price-data-module-initializer';
    exports['default'] = {
        modules: [sharedDataModuleInitializer['default'],
        // priceDataModuleInitializer,
        priceUiModuleInitializer['default']]
    };

});