define('ir-app/models/price/price-business-entities/company-owners', ['exports', 'ember', 'ir-app/utils/utils'], function (exports, Ember, utils) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({

        id: '', // Owner Id
        ownerName: '', // Owners Name
        sherPrs: '', // Share parentage
        ownerDesig: '', // Owner Designation
        ownerType: '', // Ownership Type Company/Individual
        ownerComType: '', // Owner Company Type
        prsNa: '', //Percentage not applicable
        compId: '', //Owner Company Id
        currentPct: '', // Owner current Pct
        prevPct: '', // Owner previous Pct
        curntDate: '', // current ownership Date
        prevDate: '', // Previous ownership Date

        dspChange: (function () {
            var prevPct = utils['default'].formatters.multiplyNumber(this.get('prevPct'), 100, 3);
            var curntPct = utils['default'].formatters.multiplyNumber(this.get('currentPct'), 100, 3);
            return Number(curntPct - prevPct) !== 0 ? curntPct - prevPct : '';
        }).property('currentPct', 'prevPct'),

        setData: function setData(companyOwnersData) {
            var that = this;

            Ember['default'].$.each(companyOwnersData, function (key, value) {
                that.set(key, value);
            });
        }
    });

});