define('ir-app/config/user-settings', ['exports'], function (exports) {

    'use strict';

    exports['default'] = {
        customisation: {
            defaultTheme: 'theme2',
            defaultLanguage: 'EN',

            // For disable theme selection in mobile settings page: Need to define empty array (supportedThemes: []) for specific client.
            supportedThemes: [
            //{code: 'theme1', desc: 'Dark', category: 'Dark'},
            { code: 'theme2', desc: 'Light', category: 'Light' }],
            // Supported exchange list for load in mobile view menu dropdown. {displayLabel: '', exg: '', sym: ''}
            exchangeList: []
        },

        displayFormat: {
            dateFormat: 'YYYY-MM-DD',
            timeFormat: 'HH:mm:ss',
            dateTimeFormat: 'YYYY-MM-DD HH:mm:ss',
            noValue: '--',
            decimalPlaces: 2,
            dateInputFormat: 'YYYY-MM-DD'
        },

        // Change below if the colors needs to be changed in the general implementation.
        chartTheme: {
            lightThemeName: 'theme2',
            lightThemeObj: {
                colors: {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    gradientUpperColor: 'rgba(215, 233, 225, 0.7)',
                    areaChartBgColor: 'rgba(215, 233, 225, 0.7)',
                    gradientLowerColor: 'rgba(215, 233, 225, 0.7)',
                    lineColor: 'rgba(58, 133, 159, 0.7)',
                    volumeColor: '#44a96a',
                    fontColor: '#606060',
                    gridLineColor: '#ececec',
                    // candle chart
                    downColor: 'red',
                    upColor: 'green',
                    candleLineColor: 'black',
                    candleUpLineColor: 'black'
                }
            },
            darkThemeName: 'theme1',
            darkThemeObj: {
                colors: {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    gradientUpperColor: 'rgba(42, 80, 119, 0.7)',
                    areaChartBgColor: 'rgba(42, 80, 119, 0.7)',
                    gradientLowerColor: 'rgba(9, 14, 21, 0.7)',
                    lineColor: 'rgba(62, 112, 227, 0.7)',
                    volumeColor: '#1f9af7',
                    fontColor: '#d4d4d4',
                    gridLineColor: '#1E232F',
                    // candle chart
                    downColor: 'red',
                    upColor: 'green',
                    candleLineColor: 'black',
                    candleUpLineColor: 'black'
                }
            }
        }
    };

});