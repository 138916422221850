define('ir-app/models/price/price-business-entities/corporate-action', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({

        capitalAction: null,
        nonCapitalAction: null,
        corporateMetaData: null,
        listSize: null,

        init: function init() {
            this._super();
            this.set('capitalAction', Ember['default'].A());
            this.set('nonCapitalAction', Ember['default'].A());
            this.set('corporateMetaData', Ember['default'].A());
            this.set('listSize', 0);
        },

        setData: function setData(corporateAction) {
            var that = this;

            Ember['default'].$.each(corporateAction, function (key, value) {
                that.set(key, value);
            });
        }

    });

});