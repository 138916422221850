define('ir-app/models/chart/chart-data-stores/graph-data-manager', ['exports', 'ember', 'ir-app/models/price/price-service', 'ir-app/models/chart/chart-constants', 'ir-app/utils/utils'], function (exports, Ember, PriceService, ChartConstants, utils) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        // Time Settings
        chartCategory: null,
        chartDataLevel: -1,
        chartViewPeriod: null,

        // Symbol Data
        chartSymbolArray: [],

        // Call backs
        onData: undefined,
        onDataChunk: undefined,
        onLastCandleData: undefined,
        onErrorFn: undefined,

        // Flag
        isDifferentPeriod: true, // query first time data

        init: function init() {
            this._super();
            this.set('chartSymbolArray', []);
        },

        addChartSymbol: function addChartSymbol(exchange, symbol, isBaseSymbol) {
            var indexToAdd = 0;
            var symbolArray = this.get('chartSymbolArray');
            var chartSymbolObj = {
                exg: exchange,
                sym: symbol,
                chartPointArray: [],
                isBaseSymbol: isBaseSymbol
            };

            if (!isBaseSymbol) {
                indexToAdd = symbolArray.length;
            }

            symbolArray[indexToAdd] = chartSymbolObj;
            return chartSymbolObj;
        },

        removeChartSymbol: function removeChartSymbol(exg, sym) {
            var symbolArray = this.get('chartSymbolArray');

            if (exg && sym) {
                var indexToRemove = -1;

                for (var a = 0; a < symbolArray.length; a++) {
                    var chartSymbolObj = symbolArray[a];

                    if (chartSymbolObj.exg === exg && chartSymbolObj.sym === sym) {
                        indexToRemove = a;
                        break;
                    }
                }

                if (indexToRemove > -1) {
                    symbolArray.splice(indexToRemove, 1);
                }
            } else {
                symbolArray.length = 0;
            }
        },

        getDataArray: function getDataArray(exg, sym) {
            var symbObj = this.getCharSymbol(exg, sym);

            if (symbObj) {
                return symbObj.chartPointArray;
            }

            return [];
        },

        getCharSymbol: function getCharSymbol(exg, sym) {
            var symbolArray = this.get('chartSymbolArray');

            if (exg && sym) {
                for (var a = 0; a < symbolArray.length; a++) {
                    var chartSymbolObj = symbolArray[a];

                    if (chartSymbolObj.exg === exg && chartSymbolObj.sym === sym) {
                        return chartSymbolObj;
                    }
                }
            } else if (symbolArray.length > 0) {
                return symbolArray[0];
            } else {
                return null;
            }
        },

        removeChartSymbols: function removeChartSymbols() {
            this.removeChartSymbol();
        },

        addChartDataSubscription: function addChartDataSubscription(symbolObj) {
            var symbolArray = this.get('chartSymbolArray');

            if (symbolArray.length === 0) {
                return;
            }

            if (symbolObj) {
                this.addSubscription(symbolObj);
            } else {
                for (var a = 0; a < symbolArray.length; a++) {
                    this.addSubscription(symbolArray[a]);
                }
            }
        },

        addChartDateRange: function addChartDateRange(from, to) {

            this.set('fromDate', from);
            this.set('toDate', to);
        },

        addSubscription: function addSubscription(chartSymbolObj) {
            if (this.get('chartCategory').ID === ChartConstants['default'].ChartCategory.Intraday.ID) {
                var ohlcSeries = PriceService['default'].ohlcDS.getOHLCSeries(chartSymbolObj.exg, chartSymbolObj.sym, this.get('chartCategory'));
                ohlcSeries.registerForRealtimeData(this);
                PriceService['default'].addIntradayChartRequest(chartSymbolObj.exg, chartSymbolObj.sym);
                ohlcSeries.unRegisterForLastCandleUpdates();
            } else if (this.get('chartCategory').ID === ChartConstants['default'].ChartCategory.History.ID) {
                var historySeries = PriceService['default'].ohlcDS.getOHLCSeries(chartSymbolObj.exg, chartSymbolObj.sym, this.get('chartCategory'));
                historySeries.unregisterForRealtimeData();
                historySeries.registerForLastCandleUpdates(this);
                // PriceService.removeIntradayChartRequest(chartSymbolObj.exg, chartSymbolObj.sym);
            }

            this.downloadGraphData(chartSymbolObj);
        },

        removeChartDataSubscription: function removeChartDataSubscription(symbolObj) {
            var symbolArray = this.get('chartSymbolArray');

            if (symbolArray.length === 0) {
                return;
            }

            if (symbolObj) {
                this.removeSubscription(symbolObj);
            } else {
                for (var a = 0; a < symbolArray.length; a++) {
                    this.removeSubscription(symbolArray[a]);
                }
            }
        },

        removeSubscription: function removeSubscription(chartSymbolObj) {
            if (this.get('chartCategory').ID === ChartConstants['default'].ChartCategory.Intraday.ID) {
                // PriceService.removeSymbolRequest(chartSymbolObj.exg, chartSymbolObj.sym);
                PriceService['default'].removeIntradayChartRequest(chartSymbolObj.exg, chartSymbolObj.sym);
            }

            // remove all stores when un-subscription is invoked
            chartSymbolObj.chartPointArray.length = 0;
            PriceService['default'].ohlcDS.removeOHLCSeries(chartSymbolObj.exg, chartSymbolObj.sym, this.get('chartCategory'));

            // TODO: [Amila] add a un-subscription mechanism for each observer
        },

        refineGraphData: function refineGraphData(params) {
            var symbolArray = this.get('chartSymbolArray');
            var chartSymbolObj = null;

            this.set('chartCategory', params.chartCategory);
            this.set('chartDataLevel', params.chartDataLevel);
            this.set('chartViewPeriod', params.chartViewPeriod);

            for (var a = 0; a < symbolArray.length; a++) {
                chartSymbolObj = symbolArray[a];

                if (params.chartCategory.ID === ChartConstants['default'].ChartCategory.Intraday.ID) {
                    var ohlcSeries = PriceService['default'].ohlcDS.getOHLCSeries(chartSymbolObj.exg, chartSymbolObj.sym, this.get('chartCategory'));
                    ohlcSeries.registerForRealtimeData(this);
                    ohlcSeries.unRegisterForLastCandleUpdates();
                    PriceService['default'].addIntradayChartRequest(chartSymbolObj.exg, chartSymbolObj.sym);
                } else if (params.chartCategory.ID === ChartConstants['default'].ChartCategory.History.ID) {
                    var historySeries = PriceService['default'].ohlcDS.getOHLCSeries(chartSymbolObj.exg, chartSymbolObj.sym, this.get('chartCategory'));
                    historySeries.unregisterForRealtimeData();
                    historySeries.registerForLastCandleUpdates(this);
                    PriceService['default'].removeIntradayChartRequest(chartSymbolObj.exg, chartSymbolObj.sym);
                }

                this.downloadGraphData(chartSymbolObj);
                // call twice
                // this.queryData();
            }
        },

        downloadGraphData: function downloadGraphData(chartSymbolObj) {
            var that = this;
            var ohlcSeries = PriceService['default'].ohlcDS.getOHLCSeries(chartSymbolObj.exg, chartSymbolObj.sym, that.chartCategory);

            if (ohlcSeries.chartDataLevel < that.chartDataLevel) {
                ohlcSeries.set('chartDataLevel', that.chartDataLevel);
                that._requestChartData(chartSymbolObj);
            } else if (ohlcSeries && ohlcSeries.ohlcDataPoints.length > 0) {
                that.onDataDownloadedFromMix(that, chartSymbolObj);
            } else {
                that._requestChartData(chartSymbolObj);
            }
        },

        _requestChartData: function _requestChartData(chartSymbolObj) {
            var that = this;
            if (that.chartCategory.ID === ChartConstants['default'].ChartCategory.Intraday.ID) {
                PriceService['default'].downloadIntradayOHLCData({
                    exchange: chartSymbolObj.exg,
                    symbol: chartSymbolObj.sym,
                    chartDataLevel: that.chartDataLevel,
                    begin: undefined,

                    reqSuccessFn: function reqSuccessFn() {
                        that.onDataDownloadedFromMix(that, chartSymbolObj);
                    },

                    reqFailureFn: function reqFailureFn() {
                        if (that.onErrorFn && Ember['default'].$.isFunction(that.onErrorFn)) {
                            that.onErrorFn();
                        }

                        utils['default'].logger.logDebug('Data Unavailable Intraday');
                    }
                });
            } else {
                PriceService['default'].downloadHistoryOHLCData({
                    exchange: chartSymbolObj.exg,
                    symbol: chartSymbolObj.sym,
                    begin: undefined,

                    reqSuccessFn: function reqSuccessFn() {
                        that.onDataDownloadedFromMix(that, chartSymbolObj);
                    },

                    reqFailureFn: function reqFailureFn() {
                        if (that.onErrorFn && Ember['default'].$.isFunction(that.onErrorFn)) {
                            that.onErrorFn();
                        }

                        utils['default'].logger.logDebug('Data Unavailable For History');
                    }
                });
            }
        },

        onDataDownloadedFromMix: function onDataDownloadedFromMix(gdm, chartSymbolObj) {
            // First re-build the DataArray
            gdm.queryData(chartSymbolObj);

            if (gdm.onDataChunk && Ember['default'].$.isFunction(gdm.onDataChunk)) {
                gdm.onDataChunk(chartSymbolObj);
            }
        },

        onDataFromRealTime: function onDataFromRealTime(ohlcPoint, exg, sym) {
            var that = this;

            if (that.onData && Ember['default'].$.isFunction(that.onData)) {
                that.onData(ohlcPoint, exg, sym);
            }
        },

        onDataForLastCandle: function onDataForLastCandle(lastCandle, exg, sym) {
            var that = this;
            if (!isNaN(parseInt(lastCandle.close)) && that.onLastCandleData && Ember['default'].$.isFunction(that.onLastCandleData)) {
                // TODO [AKILA] consider a better way than using isNaN
                that.onLastCandleData(lastCandle, exg, sym);
            }
        },

        queryData: function queryData(chartSymbolObj) {
            try {
                if (chartSymbolObj) {
                    var that = this;
                    var from, to;
                    var ohlcSeries = PriceService['default'].ohlcDS.getOHLCSeries(chartSymbolObj.exg, chartSymbolObj.sym, that.chartCategory);
                    var newestPoint = ohlcSeries.ohlcDataPoints[ohlcSeries.ohlcDataPoints.length - 1];
                    // Todo [Ravindu] this loads category max data. change it to load maximum history data.
                    if (ChartConstants['default'].ChartViewPeriod.All.ID === that.chartViewPeriod.ID) {
                        chartSymbolObj.chartPointArray = ohlcSeries.ohlcDataPoints.slice(); // It is important to get a copy as chart IQ use the same array as its master data array

                        var firstPoint = ohlcSeries.ohlcDataPoints[0];

                        if (!this.get('fromDate') || !this.get('toDate')) {

                            to = new Date(newestPoint.DT.getTime());
                            from = firstPoint;

                            this.set('toDate', to);
                            this.set('fromDate', from);
                            ohlcSeries.registerForLastCandleUpdates(this);
                        } else {

                            to = this.get('toDate');
                            from = this.get('fromDate');
                            ohlcSeries.unRegisterForLastCandleUpdates();
                        }
                        chartSymbolObj.chartPointArray = ohlcSeries.queryPointsForRange(from.getTime(), to.getTime());
                    } else if (ohlcSeries.ohlcDataPoints.length > 0) {

                        var date = new Date(newestPoint.DT.getTime());
                        date.setHours(0);
                        date.setMinutes(0);
                        date.setSeconds(0);

                        switch (that.chartViewPeriod.ID) {
                            case ChartConstants['default'].ChartViewPeriod.OneDay.ID:
                                // date.getTime() : relevant code line is set in below
                                break;

                            case ChartConstants['default'].ChartViewPeriod.TwoDay.ID:
                                date.setDate(date.getDate() - ChartConstants['default'].ChartDefaultDataPeriod.TwoDay);
                                break;

                            case ChartConstants['default'].ChartViewPeriod.FiveDay.ID:
                                date.setDate(date.getDate() - ChartConstants['default'].ChartDefaultDataPeriod.FiveDay);
                                break;

                            case ChartConstants['default'].ChartViewPeriod.OneMonth.ID:
                                date.setMonth(date.getMonth() - 1);
                                break;

                            case ChartConstants['default'].ChartViewPeriod.ThreeMonth.ID:
                                date.setMonth(date.getMonth() - 3);
                                break;

                            case ChartConstants['default'].ChartViewPeriod.SixMonth.ID:
                                date.setMonth(date.getMonth() - 6);
                                break;

                            case ChartConstants['default'].ChartViewPeriod.OneYear.ID:
                                date.setYear(date.getFullYear() - 1);
                                break;

                            case ChartConstants['default'].ChartViewPeriod.TwoYear.ID:
                                date.setYear(date.getFullYear() - 2);
                                break;

                            case ChartConstants['default'].ChartViewPeriod.FiveYear.ID:
                                date.setYear(date.getFullYear() - 5);
                                break;

                            case ChartConstants['default'].ChartViewPeriod.TenYear.ID:
                                date.setYear(date.getFullYear() - 10);
                                break;

                            case ChartConstants['default'].ChartViewPeriod.YTD.ID:
                                date.setMonth(0);
                                date.setDate(1);
                                break;

                            default:
                                utils['default'].logger.logWarning('Unknown chart view period...!');
                        }
                        if (!this.get('fromDate') || !this.get('toDate')) {

                            to = new Date(newestPoint.DT.getTime());
                            from = date;

                            this.set('toDate', to);
                            this.set('fromDate', from);
                            ohlcSeries.registerForLastCandleUpdates(this);
                        } else {

                            to = this.get('toDate');
                            from = this.get('fromDate');
                            ohlcSeries.unRegisterForLastCandleUpdates();
                        }
                        chartSymbolObj.chartPointArray = ohlcSeries.queryPointsForRange(from.getTime(), to.getTime());
                    }
                }
            } catch (e) {
                utils['default'].logger.logError('Error in querying ohlc data : ' + e);
            }
        }
    });

});