define('ir-app/components/loading-indicator', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        isLoading: true,
        attributeBindings: ['style:style'],

        style: (function () {
            if (this.get('isLoading')) {
                return new Ember['default'].Handlebars.SafeString('width: 100%; height: 100%');
            } else {
                return new Ember['default'].Handlebars.SafeString('width: 100%; height: 100%; display: none');
            }
        }).property('isLoading')
    });

});