define('ir-app/components/stk-specific-components/days-range', ['exports', 'ember', 'ir-app/utils/utils'], function (exports, Ember, utils) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        layoutName: 'components/stk-specific-components/days-range',

        position: (function () {
            var stock = this.get('stock');
            var value = 0;

            if (stock) {
                var lowValue = stock.low;
                var highValue = stock.high;
                var currentValue = stock.ltp;
                var diff = highValue - lowValue;

                if (diff !== 0) {
                    value = parseInt((currentValue - lowValue) / diff * 100, 10);
                    value = value > 0 && value < 100 ? value : 0;
                }

                return value;
            }
        }).property('stock.ltp', 'stock.low', 'stock.high'),

        dotColor: (function () {
            var position = this.get('position');

            if (position < 50) {
                return this.get('fiftyTwoWkLowColor') ? this.get('fiftyTwoWkLowColor') : '#ff6b6b';
            } else if (position === 50 || position > 50) {
                return this.get('fiftyTwoWkHighColor') ? this.get('fiftyTwoWkHighColor') : '#2ecc71';
            }
        }).property('position'),

        highValue: (function () {
            var stock = this.get('stock');
            return utils['default'].formatters.formatNumber(stock.high, stock.deci);
        }).property('stock.high'),

        lowValue: (function () {
            var stock = this.get('stock');
            return utils['default'].formatters.formatNumber(stock.low, stock.deci);
        }).property('stock.low'),

        styles: (function () {
            var cellStyle = 'border-right: 4px solid ' + this.get('dotColor') + ';height:5px;';
            cellStyle = cellStyle + 'width:' + this.get('position') + '%;';
            return cellStyle;
        }).property('position', 'dotColor')
    });

});