define('ir-app/models/shared/shared-data-stores/theme-data-store', ['exports', 'ember', 'ir-app/models/shared/shared-service', 'ir-app/app-events', 'ir-app/utils/utils', 'ir-app/config/user-settings'], function (exports, Ember, sharedService, appEvents, utils, userSettings) {

    'use strict';

    exports['default'] = (function () {
        var isChangeThemeSet = false;
        var themeStore = {};
        var isThemeSet = false;

        var changeThemeObj = Ember['default'].Object.extend({
            theme: {}
        }).create();

        var selectedThemeObj = Ember['default'].Object.extend({
            themeName: {}
        }).create();

        var getChangeThemeObj = function getChangeThemeObj() {
            if (!isChangeThemeSet) {
                changeThemeObj.set('theme', _getChangeToTheme(sharedService['default'].userSettings.currentTheme));
                isChangeThemeSet = true;
            }

            return changeThemeObj;
        };

        var changeTheme = function changeTheme(themeCode) {
            setThemeClass(themeCode);

            changeThemeObj.set('theme', _getChangeToTheme(sharedService['default'].userSettings.currentTheme));

            // Notify subscribed widgets about the theme change
            appEvents['default'].onThemeChanged(themeCode);
        };

        var _getChangeToTheme = function _getChangeToTheme(selectedTheme) {
            var changedTheme = {};

            try {
                if (window.Android) {

                    // if Android Host do nothing

                } else {
                        var retrievedObject = localStorage.getItem('host_version');

                        if (JSON.parse(retrievedObject).os === 'ios') {

                            window.location = 'settings-theme' + '/' + selectedTheme;
                        }
                    }
            } catch (err) {

                // if error
            }

            Ember['default'].$.each(userSettings['default'].customisation.supportedThemes, function (key, val) {
                if (selectedTheme !== val.code) {
                    changedTheme = val;
                    return changedTheme;
                }
            });

            return changedTheme;
        };

        var setThemeClass = function setThemeClass(currentTheme) {
            Ember['default'].$('html').removeClass(sharedService['default'].userSettings.currentTheme);
            Ember['default'].$('body').removeClass(sharedService['default'].userSettings.currentTheme);

            Ember['default'].$('html').addClass(currentTheme);
            Ember['default'].$('body').addClass(currentTheme);

            // Save current theme in user's local machine when theme changes
            sharedService['default'].userSettings.set('currentTheme', currentTheme);
            sharedService['default'].userSettings.save();
        };

        var setOrientationClass = function setOrientationClass(currentLanguage) {
            if (currentLanguage === 'AR') {
                Ember['default'].$('html').addClass('ar');
                Ember['default'].$('body').addClass('ar');
            } else {
                Ember['default'].$('html').removeClass('ar');
                Ember['default'].$('body').removeClass('ar');
            }
        };

        var showBackground = function showBackground(enable) {
            if (enable) {
                Ember['default'].$('body').addClass('container-bg');
            } else {
                Ember['default'].$('body').removeClass('container-bg');
            }
        };

        var setThemeObj = function setThemeObj(themeCode, themeObj) {
            if (utils['default'].validators.isAvailable(themeCode) && themeObj) {
                themeStore[themeCode] = themeObj;
            } else {
                utils['default'].logger.logError('Theme data not available for : ' + themeCode);
            }
        };

        var getThemeObj = function getThemeObj() {
            if (!isThemeSet) {
                // when theme object not found we need to handle it. For that get the 1st element of the support theme.
                if (themeStore[sharedService['default'].userSettings.currentTheme] !== undefined) {
                    selectedThemeObj.set('themeName', themeStore[sharedService['default'].userSettings.currentTheme]);
                } else {
                    selectedThemeObj.set('themeName', themeStore[userSettings['default'].customisation.supportedThemes[0].code]);
                }
                isThemeSet = true;
            }
            return selectedThemeObj;
        };

        var changeThemeColorObject = function changeThemeColorObject(themeCode) {
            if (utils['default'].validators.isAvailable(themeCode)) {
                var themeObj = themeStore[themeCode];
                if (themeObj) {
                    selectedThemeObj.set('themeName', themeObj);
                } else {
                    utils['default'].logger.logError('Theme data not available for : ' + themeCode);
                }
            } else {
                utils['default'].logger.logError('Theme code not available');
            }
        };

        return {
            getChangeThemeObj: getChangeThemeObj,
            changeTheme: changeTheme,
            setThemeClass: setThemeClass,
            setOrientationClass: setOrientationClass,
            showBackground: showBackground,
            setThemeObj: setThemeObj,
            getThemeObj: getThemeObj,
            changeThemeColorObject: changeThemeColorObject
        };
    })();

});