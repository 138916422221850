define('ir-app/controllers/shared/basic-table-controller', ['exports', 'ember', 'ember-table/models/column-definition', 'ir-app/components/mixins/sortable-column-mixin', 'ir-app/config/price-widget-config', 'ir-app/controllers/base-array-controller', 'ir-app/models/shared/shared-service'], function (exports, Ember, columnDefinition, sortableColumnMixin, priceWidgetConfig, BaseArrayController, sharedService) {

    'use strict';

    exports['default'] = BaseArrayController['default'].extend({
        // Column Parameters
        columnViews: {},
        // Content related Parameters
        model: Ember['default'].A(),
        masterContent: Ember['default'].A(),

        columns: (function () {
            var that = this;
            var colData = [];
            var columnArray = this.get('columnDeclarations');

            Ember['default'].$.each(columnArray, function (index, elem) {
                colData[colData.length] = that.createColumn(elem, that.get('app'));
            });

            if (sharedService['default'].userSettings.currentLanguage === 'AR') {
                // Only arabic language needs column RTL
                colData = colData.reverse();
            }

            return Ember['default'].A(colData);
        }).property('columnDeclarations'),

        createColumn: function createColumn(element, app) {
            // Column Declarations parameters : id, width, headerName, sortKeyword, style, isColumnSortDisabled, fontColor, isBold, dataType, backgroundColour
            var sortMixin, sortKeyword, columnView;

            // Get Views assigned by parent controller
            var viewMapping = this.get('columnViews');

            // Get Views assigned by child controller
            var viewExternalMapping = this.get('cellViewsForColumns');

            if (element.type) {
                columnView = viewMapping[element.type] ? viewMapping[element.type] : viewExternalMapping[element.type];
            } else {
                columnView = 'Ember.Cell';
            }

            var column = {
                contentPath: element.id,
                dataType: element.dataType,
                headerCellName: app.lang.labels[element.headerName] ? app.lang.labels[element.headerName] : element.headerName,
                headerCellSecondName: app.lang.labels[element.headerCellSecondName] ? app.lang.labels[element.headerCellSecondName] : element.headerCellSecondName,
                name: element.name,
                positiveNegativeChange: element.positiveNegativeChange,
                cellStyle: element.cellStyle,
                firstValueStyle: element.firstValueStyle,
                secondValueStyle: element.secondValueStyle,
                backgroundStyle: element.backgroundStyle,
                isBlink: element.isBlink,
                blinkUpStyle: element.blinkUpStyle,
                blinkDownStyle: element.blinkDownStyle,
                noOfDecimalPlaces: element.noOfDecimalPlaces,
                noOfSecValueDecimalPlaces: element.noOfSecValueDecimalPlaces,
                iconClass: element.iconClass,
                secondId: element.secondId,
                thirdId: element.thirdId,
                buttonFunction: element.buttonFunction,
                buttonMenu: element.buttonMenu,
                headerStyles: element.headerStyle,
                tableCellViewClass: columnView,
                headerCellViewClass: element.headerCellView ? element.headerCellView : 'Ember.HeaderCell',
                canAutoResize: true,
                columnMode: 'fluid',
                isndicatorAvailable: element.isndicatorAvailable,
                getCellContent: function getCellContent(row) {
                    var values = {};
                    values.firstValue = typeof row.get === 'function' ? row.get(element.id) : row[element.id];
                    values.firstValueDesi = element.dataType === 'float' ? typeof row.get === 'function' ? row.get('deci') : row['deci'] : undefined;
                    values.secondValue = element.secondId ? row.get(element.secondId) : undefined;
                    values.thirdValue = element.thirdId ? row.get(element.thirdId) : undefined;

                    return values;
                }
            };

            if (element.width) {
                column = Ember['default'].$.extend(column, { savedWidth: element.width });
            }

            if (!element.isColumnSortDisabled) {
                // Default behavior is sorting enabled. If not, isColumnSortDisabled should be true
                sortMixin = sortableColumnMixin['default'];
                sortKeyword = element.sortKeyword ? element.sortKeyword : element.id;
                column = Ember['default'].$.extend(column, { sortKey: sortKeyword });
            }

            return columnDefinition['default'].create(sortMixin, column);
        },

        columnDefinitionsByMap: function columnDefinitionsByMap(columnList, columnIdArray) {
            var columnDefArray = [];
            var columns = columnList ? columnList : priceWidgetConfig['default'].watchList.irPortalColumnMapping;
            var columnIds = columnIdArray ? columnIdArray : priceWidgetConfig['default'].watchList.irPortalDefaultColumnIds;

            Ember['default'].$.each(columnIds, function (index, columnId) {
                if (columns[columnId]) {
                    columnDefArray[columnDefArray.length] = columns[columnId];
                }
            });

            return columnDefArray;
        }
    });

});