define('ir-app/utils/formatters', ['exports', 'ember', 'ir-app/config/user-settings', 'ir-app/utils/utils'], function (exports, Ember, userSettings, utils) {

  'use strict';

  /* global moment */
  exports['default'] = (function () {
    var NumberScale = {
      Thousand: 'K',
      Million: 'M',
      Billion: 'B',
      Trillion: 'T'
    };
    var NumberScale1 = {
      Thousand: 'Thousand',
      Million: 'Million',
      Billion: 'Billion',
      Trillion: 'Trillion'
    };
    /* *
     * Convert time stamp in milliseconds to UTC
     * @param milliSeconds Timestamp in milliseconds
     * @returns {number} Time stamp in milliseconds converted to UTC
     */
    var convertToUTCTimestamp = function convertToUTCTimestamp(milliSeconds) {
      var date = new Date(milliSeconds);

      return Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds(), date.getUTCMilliseconds());
    };

    /* *
     * Convert time stamp in milliseconds to UTC Date (GMT 0)
     * @param milliSeconds Timestamp in milliseconds
     * @param offset Offset, Format: x.y (Ex: 2, 2.0, 5.5)
     * @returns {Date} Javascript Date Object converted to UTC
     */
    var convertToUTCDate = function convertToUTCDate(milliSeconds, offset) {
      var date = new Date(milliSeconds);
      var gmtDate = new Date(date.valueOf() + date.getTimezoneOffset() * 60000);

      if (offset) {
        return adjustToTimezone(gmtDate, offset);
      } else {
        return gmtDate;
      }
    };

    /* *
     * Convert unicode string to native string
     * @param unicodeString Unicode string
     * @returns {*} Converted native string
     */
    var convertUnicodeToNativeString = function convertUnicodeToNativeString(unicodeString) {
      if (unicodeString) {
        return unicodeString.replace(/\\u[\dABCDEFabcdef][\dABCDEFabcdef][\dABCDEFabcdef][\dABCDEFabcdef]/g, function (match) {
          return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
        });
      }

      return unicodeString;
    };

    /* *
     * Convert date and / or time to Javascript Date object
     * @param dateTimeString Date and / or time string, Format: yyyyMMdd / yyyyMMdd HHmmss / HHmmss
     * @returns {*} Converted Javascript Date object if valid date and / or time string, undefined otherwise
     */
    var convertStringToDate = function convertStringToDate(dateTimeString) {
      var date;

      if (utils['default'].validators.isAvailable(dateTimeString)) {
        var dateTimeParts = dateTimeString.trim().split(utils['default'].Constants.StringConst.Space);

        if (dateTimeParts.length > 0) {
          var isWithTime;
          var year, month, day, hour, minutes, seconds;
          var dateOrTimePart = dateTimeParts[0];

          if (dateOrTimePart.length === 8) {
            isWithTime = false;

            year = dateOrTimePart.substring(0, 4);
            month = parseInt(dateOrTimePart.substring(4, 6), 10) - 1; // Javascript month is zero (0) based
            day = dateOrTimePart.substring(6, 8);
          } else if (dateOrTimePart.length === 6) {
            var dateNow = new Date();
            isWithTime = true;

            hour = dateOrTimePart.substring(0, 2);
            minutes = dateOrTimePart.substring(2, 4);
            seconds = dateOrTimePart.substring(4, 6);

            year = dateNow.getFullYear();
            month = dateNow.getMonth();
            day = dateNow.getDay();
          } else if (dateOrTimePart.length === 14) {
            isWithTime = true;
            year = dateOrTimePart.substring(0, 4);
            month = parseInt(dateOrTimePart.substring(4, 6), 10) - 1; // Javascript month is zero (0) based
            day = dateOrTimePart.substring(6, 8);

            hour = dateOrTimePart.substring(8, 10);
            minutes = dateOrTimePart.substring(10, 12);
            seconds = dateOrTimePart.substring(12, 14);
          }

          if (dateTimeParts.length > 1) {
            var timePart = dateTimeParts[1];
            date = new Date(year, month, day, timePart.substring(0, 2), timePart.substring(2, 4), timePart.substring(4, 6));
          } else {
            if (isWithTime) {
              date = new Date(year, month, day, hour, minutes, seconds);
            } else {
              date = new Date(year, month, day);
            }
          }
        }
      }

      return date;
    };

    /* *
     * Format date to display format
     * Display format will be taken from application configuration file
     * @param date object
     */
    var convertToDisplayTimeFormat = function convertToDisplayTimeFormat(datetime, format) {
      var dateTimeFormat = format ? format : userSettings['default'].displayFormat.timeFormat;

      return datetime ? moment(datetime).format(dateTimeFormat) : userSettings['default'].displayFormat.noValue;
    };

    /* *
     * Format date to display format
     * Display format will be taken from application configuration file
     * @param date Date String, Format: yyyyMMdd
     * @param offset Offset, Format: x.y (Ex: 2, 2.0, 5.5)
     */
    var formatToDate = function formatToDate(date, offset) {
      if (date === '19700101') {
        return userSettings['default'].displayFormat.noValue;
      }

      var adjustedDate = getAdjustedDateTime(date, offset);

      return adjustedDate ? moment(adjustedDate).format(userSettings['default'].displayFormat.dateFormat) : userSettings['default'].displayFormat.noValue;
    };

    /* *
     * Get year
     * Display format will be taken from application configuration file
     * @param date Date String, Format: yyyyMMdd
     */
    var getYearComponent = function getYearComponent(date) {
      var momentDate = moment(date, userSettings['default'].displayFormat.dateInputFormat);
      return momentDate.isValid() ? momentDate.year() : userSettings['default'].displayFormat.noValue;
    };

    /* *
     * Get month
     * Display format will be taken from application configuration file
     * @param date Date String, Format: yyyyMMdd
     * @param labels app.lang.labels
     * @param isShorten {true,false}, If true return short format. ie: Jan
     */
    var getMonthComponent = function getMonthComponent(date, labels, isShorten) {
      var momentDate = moment(date, userSettings['default'].displayFormat.dateInputFormat);
      var monthInteger = momentDate.isValid() ? momentDate.format('MM') : userSettings['default'].displayFormat.noValue;
      return getMonthStrFromInt(labels, monthInteger, isShorten);
    };

    /* *
     * Get date
     * Display format will be taken from application configuration file
     * @param date Date String, Format: yyyyMMdd
     */
    var getDateComponent = function getDateComponent(date) {
      var momentDate = moment(date, userSettings['default'].displayFormat.dateInputFormat);
      return momentDate.isValid() ? momentDate.format('DD') : userSettings['default'].displayFormat.noValue;
    };

    var getFormattedTimeComponent = function getFormattedTimeComponent(dateTime) {
      var momentTime = moment(dateTime, userSettings['default'].displayFormat.dateInputFormat);
      return momentTime.isValid() ? momentTime.format(userSettings['default'].displayFormat.timeFormat) : userSettings['default'].displayFormat.noValue;
    };

    /* *
     * Get time
     * Display format will be taken from application configuration file
     * @param dateTime Date String, Format: yyyyMMdd HHmmss
     */
    var getTimeComponent = function getTimeComponent(dateTime) {
      var momentTime = moment(dateTime, userSettings['default'].displayFormat.dateTimeFormat);
      return momentTime.isValid() ? momentTime.format(userSettings['default'].displayFormat.timeFormat) : userSettings['default'].displayFormat.noValue;
    };

    /* *
     * Format time to display format
     * Display format will be taken from application configuration file
     * @param time Time String, Format: HHmmss
     * @param offset Offset, Format: x.y (Ex: 2, 2.0, 5.5)
     */
    var formatToTime = function formatToTime(time, offset) {
      var adjustedDate = getAdjustedDateTime(time, offset);

      return adjustedDate ? moment(adjustedDate).format(userSettings['default'].displayFormat.timeFormat) : userSettings['default'].displayFormat.noValue;
    };

    /* *
     * Format date time to display format
     * Display format will be taken from application configuration file
     * @param dateTime Date time String, Format: yyyyMMdd HHmmss
     * @param offset Offset, Format: x.y (Ex: 2, 2.0, 5.5)
     */
    var formatToDateTime = function formatToDateTime(dateTime, offset) {
      var adjustedDate = getAdjustedDateTime(dateTime, offset);

      return adjustedDate ? moment(adjustedDate).format(userSettings['default'].displayFormat.dateTimeFormat) : userSettings['default'].displayFormat.noValue;
    };

    /* *
     * Format date time to Default display format
     * Display format will be taken from application configuration file
     * @param dateTime Date time String, Format: yyyyMMdd HHmmss
     * @param offset Offset, Format: x.y (Ex: 2, 2.0, 5.5)
     */
    var formatToDefaultDateTime = function formatToDefaultDateTime(dateTime, offset) {
      var adjustedDate = getAdjustedDateTime(dateTime, offset);

      return _convertDateToString(adjustedDate);
    };

    /* *
     * Format javascript date to display format
     * Display format will be taken from application configuration file
     * @param dateTime javascript date object
     */
    var formatDateToDisplayDate = function formatDateToDisplayDate(date) {
      return date ? [_convertToTwoDigits([date.getFullYear(), date.getMonth() + 1, date.getDate()]).join('-'), _convertToTwoDigits([date.getHours(), date.getMinutes(), date.getSeconds()]).join(':')].join(' ') : userSettings['default'].displayFormat.noValue;
    };

    /* *
     * Format javascript date to display date format
     * Display format will be taken from application configuration file
     * @param dateTime javascript date object
     */
    var formatDateToDisplayOnlyDate = function formatDateToDisplayOnlyDate(date) {
      return date ? [_convertToTwoDigits([date.getFullYear(), date.getMonth() + 1, date.getDate()]).join('-')].join(' ') : userSettings['default'].displayFormat.noValue;
    };

    /**
     * Format number to given decimal places and separate each 3 digits by commas
     * @param value Number to format
     * @param decimalPlaces Number of decimal places
     * @returns {*} Number formatted to given decimal places and commas added
     */
    var formatNumber = function formatNumber(value, decimalPlaces) {
      if (!isNaN(value) && Ember['default'].$.trim(value) !== '') {
        if (decimalPlaces >= 0) {
          return _formatNumberToDisplay(value, decimalPlaces);
        } else {
          return value;
        }
      } else {
        return userSettings['default'].displayFormat.noValue;
      }
    };

    /**
     * Format number to given decimal places and separate each 3 digits by commas and add percentage symbol (%)
     * @param value Number to format
     * @param decimalPlaces Number of decimal places
     * @returns {*} Number formatted to given decimal places and commas and percentage symbol (%) added
     */
    var formatNumberPercentage = function formatNumberPercentage(value, decimalPlaces) {
      if (!isNaN(value) && Ember['default'].$.trim(value) !== '') {
        return formatNumber(value, decimalPlaces) + '%';
      } else {
        return userSettings['default'].displayFormat.noValue;
      }
    };

    /**
     * Divide numbers to factors of thousands. Ex: million, billion etc.
     * @param value Number to format
     * @param decimalPlaces Number of decimal places
     * @returns {string} Number divided and suffix added
     */
    var divideNumber = function divideNumber(value, decimalPlaces) {
      var val = value;
      var isNegative = false;
      var noOfDecimals = decimalPlaces !== undefined ? decimalPlaces : userSettings['default'].displayFormat.decimalPlaces;

      if (!isNaN(val) && Ember['default'].$.trim(val) !== '') {

        if (val < 0) {
          isNegative = true;
          val = Math.abs(val);
        }
        var suffix = '';

        if (val >= Math.pow(10, 12)) {
          // Trillion
          val = val / Math.pow(10, 12);
          suffix = NumberScale.Trillion;
        } else if (val >= Math.pow(10, 9)) {
          // Billion
          val = val / Math.pow(10, 9);
          suffix = NumberScale.Billion;
        } else if (val >= Math.pow(10, 6)) {
          // Million
          val = val / Math.pow(10, 6);
          suffix = NumberScale.Million;
        } else if (val >= Math.pow(10, 3)) {
          // Thousand
          val = val / Math.pow(10, 3);
          suffix = NumberScale.Thousand;
        }

        if (!isNegative) {
          return val.toFixed(noOfDecimals) + ' ' + (suffix ? suffix : '');
        } else {
          return '-' + val.toFixed(noOfDecimals) + ' ' + (suffix ? suffix : '');
        }
      } else {
        return userSettings['default'].displayFormat.noValue;
      }
    };

    /* *
     * Divide numbers to factors of thousands. Ex: million
     * @param value Number to format
     * @param decimalPlaces Number of decimal places
     * @returns {string} Number divided and suffix added
     */
    var inMillions = function inMillions(value, decimalPlaces, hideUnit) {
      var val = value;
      var isNegative = false;
      var noOfDecimals = decimalPlaces !== undefined ? decimalPlaces : userSettings['default'].displayFormat.decimalPlaces;

      if (!isNaN(val) && Ember['default'].$.trim(val) !== '') {

        if (val < 0) {
          isNegative = true;
          val = Math.abs(val);
        }
        var suffix = '';

        if (val >= Math.pow(10, 6)) {
          // Million
          val = val / Math.pow(10, 6);
          if (!hideUnit) {
            suffix = NumberScale.Million;
          }
        } else if (val >= Math.pow(10, 3)) {
          // Thousand
          val = val / Math.pow(10, 3);
          if (!hideUnit) {
            suffix = NumberScale.Thousand;
          }
        }

        if (!isNegative) {
          return formatNumber(val.toFixed(noOfDecimals), noOfDecimals) + ' ' + (suffix ? suffix : '');
        } else {
          return '-' + formatNumber(val.toFixed(noOfDecimals), noOfDecimals) + ' ' + (suffix ? suffix : '');
        }
      } else {
        return userSettings['default'].displayFormat.noValue;
      }
    };
    var continMillions = function continMillions(value, decimalPlaces, hideUnit) {
      var val = value;
      var isNegative = false;
      var noOfDecimals = decimalPlaces !== undefined ? decimalPlaces : userSettings['default'].displayFormat.decimalPlaces;

      if (!isNaN(val) && Ember['default'].$.trim(val) !== '') {

        if (val < 0) {
          isNegative = true;
          val = Math.abs(val);
        }
        var suffix = '';

        if (val) {
          // Million
          val = val / Math.pow(10, 6);
          if (!hideUnit) {
            suffix = NumberScale1.Million;
          }
        }

        if (!isNegative) {
          return formatNumber(val.toFixed(noOfDecimals), noOfDecimals) + ' ' + (suffix ? suffix : '');
        } else {
          return '-' + formatNumber(val.toFixed(noOfDecimals), noOfDecimals) + ' ' + (suffix ? suffix : '');
        }
      } else {
        return userSettings['default'].displayFormat.noValue;
      }
    };
    //  var volinMillions = function (value, decimalPlaces, hideUnit) {
    //      var val = value;
    //      var isNegative = false;
    //      var noOfDecimals = decimalPlaces !== undefined ? decimalPlaces : userSettings.displayFormat.decimalPlaces;
    //
    //      if (!isNaN(val) && Ember.$.trim(val) !== '') {
    //
    //        if (val < 0) {
    //          isNegative = true;
    //          val = Math.abs(val);
    //        }
    //        var suffix = '';
    //
    //        if (val) {      // Million
    //          val = val / Math.pow(10, 6);
    //          if (!hideUnit) {
    //            suffix = NumberScale.Million;
    //          }
    //        }
    //
    //        if (!isNegative) {
    //          return formatNumber((val).toFixed(noOfDecimals), noOfDecimals) + ' ' + (suffix ? suffix : '');
    //        }
    //        else {
    //          return '-' + formatNumber((val).toFixed(noOfDecimals), noOfDecimals) + ' ' + (suffix ? suffix : '');
    //        }
    //
    //      } else {
    //        return userSettings.displayFormat.noValue;
    //      }
    //    };

    /* *
     * Multiply number by the given factor
     * @param value Number to format
     * @param factor Multiplication factor
     * @param decimalPlaces Number of decimal places
     * @returns {string} Number multiplied and format to given decimal places
     */
    var multiplyNumber = function multiplyNumber(value, factor, decimalPlaces) {
      var noOfDecimals = decimalPlaces !== undefined ? decimalPlaces : userSettings['default'].displayFormat.decimalPlaces;
      var multiFactor = factor !== undefined ? factor : 1;
      var val = value !== undefined ? value : 0.0;

      return (val * multiFactor).toFixed(noOfDecimals);
    };

    /* *
     * Multiply number by the given factor and add percentage sign
     * @param value Number to format
     * @param factor Multiplication factor
     * @param decimalPlaces Number of decimal places
     * @returns {string} Number multiplied and format to given decimal places
     */
    var multiplyNumberPercentage = function multiplyNumberPercentage(value, factor, decimalPlaces) {
      return multiplyNumber(value, factor, decimalPlaces) + '%';
    };

    /* *
     * Adjust date / time object to timezone
     * @param date Javascript Date object
     * @param offset Offset, Format: x.y (Ex: 2, 2.0, 5.5)
     * @returns {*} Timezone adjusted Date object
     */
    var adjustToTimezone = function adjustToTimezone(date, offset) {
      if (date && offset) {
        date.setHours(date.getHours() + offset);
      }

      return date;
    };

    var getAdjustedDateTime = function getAdjustedDateTime(dateTime, offset) {
      var timeOffset = offset ? offset : 0;
      var dateObj = convertStringToDate(dateTime);

      return adjustToTimezone(dateObj, timeOffset);
    };

    var getSystemDate = function getSystemDate() {
      return moment().format(userSettings['default'].displayFormat.dateFormat);
    };

    var _formatNumberToDisplay = function _formatNumberToDisplay(value, decimalPlaces) {
      var noOfDecimals = decimalPlaces;
      var roundedNum = _roundNumber(value, noOfDecimals);
      var wholeNum = roundedNum.split('.')[0].toString();
      var wholeNumWithoutMinus;

      if (wholeNum.charAt(0) === '-') {
        wholeNumWithoutMinus = wholeNum.substring(1, wholeNum.length);
      } else {
        wholeNumWithoutMinus = wholeNum;
      }

      var formWholeNum = '';

      for (var i = wholeNumWithoutMinus.length; i > 0; i -= 3) {
        formWholeNum = ',' + wholeNumWithoutMinus.substring(i - 3, i) + formWholeNum;
      }

      var formNum = formWholeNum.substring(1, formWholeNum.length);

      if (roundedNum.toString().split('.').length > 1) {
        formNum = formNum + '.' + roundedNum.toString().split('.')[1];
      } else {
        if (noOfDecimals > 0) {
          formNum += '.';

          while (noOfDecimals > 0) {
            formNum += '0';
            noOfDecimals--;
          }
        }
      }

      if (wholeNum.charAt(0) === '-') {
        formNum = '-' + formNum;
      }

      if (formNum === 'NaN' || formNum.indexOf('NaN') >= 0) {
        formNum = userSettings['default'].displayFormat.noValue;
      }

      return formNum;
    };

    var _roundNumber = function _roundNumber(value, decimalPlaces) {
      var num = parseFloat(Ember['default'].$.trim(value));
      var result = _toFixed(Math.round(num * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces));

      var numParts = result.split('.');
      if (numParts.length > 1) {
        var floatNum = numParts[1];

        if (floatNum.length < decimalPlaces) {
          for (var i = 0; i < decimalPlaces - floatNum.length; i++) {
            result += '0';
          }
        }
      }

      return result;
    };

    var _toFixed = function _toFixed(x) {
      var e;
      var value = x;

      if (Math.abs(value) < 1.0) {
        e = parseInt(value.toString().split('e-')[1], 10);

        if (e) {
          value *= Math.pow(10, e - 1);
          value = '0.' + new Array(e).join('0') + value.toString().substring(2);
        }
      } else {
        e = parseInt(value.toString().split('+')[1], 10);

        if (e > 20) {
          e -= 20;
          value /= Math.pow(10, e);
          value += new Array(e + 1).join('0');
        }
      }

      return value.toString();
    };

    // TODO: [Bashitha] This is a temporary fix to format single digit numbers to 2 digit numbers
    var _convertToTwoDigits = function _convertToTwoDigits(valArray) {
      return valArray.map(function (val) {
        return val < 10 ? '0' + val : val;
      });
    };

    //
    // Chart specific conversions
    //

    var generateChartBeginDateString = function generateChartBeginDateString(chartCategory) {
      if (utils['default'].chartConstants.ChartCategory.Intraday.ID === chartCategory.ID) {
        return generateIntradayBeginDateString(utils['default'].chartConstants.ChartCategory.Intraday.DefaultDataRequestDuration);
      } else {
        return generateHistoryBeginDateString(utils['default'].chartConstants.ChartCategory.History.DefaultDataRequestDuration, 0); // 0 months
      }
    };

    var generateChartEndDateString = function generateChartEndDateString(chartCategory) {
      if (utils['default'].chartConstants.ChartCategory.Intraday.ID === chartCategory.ID) {
        return generateIntradayEndDateString();
      } else {
        return generateHistoryEndDateString();
      }
    };

    var generateIntradayBeginDateString = function generateIntradayBeginDateString(days) {
      var date = new Date();

      if (days === utils['default'].chartConstants.ChartDefaultDataPeriod.Month) {
        date.setMonth(date.getMonth() - 1);
      } else {
        date.setDate(date.getDate() - days);
      }

      return _convertDateToString(date);
    };

    var generateIntradayEndDateString = function generateIntradayEndDateString() {
      var date = new Date();

      return _convertDateToString(date);
    };

    var generateHistoryBeginDateString = function generateHistoryBeginDateString(years, months) {
      var date = new Date();

      date.setYear(date.getFullYear() - years);
      date.setMonth(date.getMonth() - months);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      return _convertDateToString(date);
    };

    var generateHistoryEndDateString = function generateHistoryEndDateString() {
      var date = new Date();
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);

      return _convertDateToString(date);
    };

    /* *
     * Format date to mix date format
     * @param date JavaScript Date Obj
     * @return date string, Format yyyyMMddHHmmss
     */
    var _convertDateToString = function _convertDateToString(date) {
      if (!date) {
        return undefined;
      }

      return date.getFullYear() + _fillDateString(date.getMonth() + 1) + _fillDateString(date.getDate()) + _fillDateString(date.getHours()) + _fillDateString(date.getMinutes()) + _fillDateString(date.getSeconds());
    };

    /* *
     * Format date to yyyymmdd format
     * @param date JavaScript Date Obj
     * @return date string, Format yyyyMMdd
     */
    var convertDateToyyyymmdd = function convertDateToyyyymmdd(date) {
      if (!date) {
        return undefined;
      }

      return date.getFullYear() + _fillDateString(date.getMonth() + 1) + _fillDateString(date.getDate());
    };

    var _fillDateString = function _fillDateString(value) {
      return (value < 10 ? '0' : '') + value;
    };

    var timeDifference = function timeDifference(current, previous) {
      var msPerMinute = 60 * 1000;
      var msPerHour = msPerMinute * 60;
      var msPerDay = msPerHour * 24;
      var msPerMonth = msPerDay * 30;
      var msPerYear = msPerDay * 365;

      var elapsed = current - previous;

      if (elapsed < msPerMinute) {
        return Math.round(elapsed / 1000) + ' seconds ago';
      } else if (elapsed < msPerHour) {
        return Math.round(elapsed / msPerMinute) + ' minutes ago';
      } else if (elapsed < msPerDay) {
        return Math.round(elapsed / msPerHour) + ' hours ago';
      } else if (elapsed < msPerMonth) {
        return Math.round(elapsed / msPerDay) + ' days ago';
      } else if (elapsed < msPerYear) {
        return Math.round(elapsed / msPerMonth) + ' months ago';
      } else {
        return Math.round(elapsed / msPerYear) + ' years ago';
      }
    };

    /**
     * get month String for the given integer. ie: for 1 return January
     * @param app application
     * @param isShorten   {true,false}  if true return short format. ie: Jan
     * @returns {string} month as a String
     */
    var getMonthStrFromInt = function getMonthStrFromInt(labels, monthInteger, isShorten) {
      var monthInt = Number(monthInteger);
      switch (monthInt) {
        case 0:
          return isShorten ? labels.monthShortDec : labels.monthDec;
        case 1:
          return isShorten ? labels.monthShortJan : labels.monthJan;
        case 2:
          return isShorten ? labels.monthShortFeb : labels.monthFeb;
        case 3:
          return isShorten ? labels.monthShortMar : labels.monthMar;
        case 4:
          return isShorten ? labels.monthShortApril : labels.monthApril;
        case 5:
          return isShorten ? labels.monthShortMay : labels.monthMay;
        case 6:
          return isShorten ? labels.monthShortJun : labels.monthJun;
        case 7:
          return isShorten ? labels.monthShortJly : labels.monthJly;
        case 8:
          return isShorten ? labels.monthShortAgst : labels.monthAgst;
        case 9:
          return isShorten ? labels.monthShortSep : labels.monthSep;
        case 10:
          return isShorten ? labels.monthShortOct : labels.monthOct;
        case 11:
          return isShorten ? labels.monthShortNov : labels.monthNov;
        case 12:
          return isShorten ? labels.monthShortDec : labels.monthDec;
        default:
          return '';
      }
    };

    /**
     * apply placeholders for the given string
     * @placeHoldingStr place holding String
     * @args inputs for the placeholders by the respective index
     * @return
     *
     */
    var applyPlaceHolders = function applyPlaceHolders(placeHoldingStr, args) {
      var holderStartPatrn = '{{';
      var holderEndPatrn = '}}';
      var processedStr = '';
      var unProcessedStr = placeHoldingStr;
      var splitIndex = -1;
      var urlParam;

      while (unProcessedStr.indexOf(holderStartPatrn) >= 0) {
        splitIndex = unProcessedStr.indexOf(holderStartPatrn);
        processedStr += unProcessedStr.slice(0, splitIndex);
        unProcessedStr = unProcessedStr.slice(splitIndex + holderStartPatrn.length);
        splitIndex = unProcessedStr.indexOf(holderEndPatrn);
        urlParam = parseInt(unProcessedStr.slice(0, splitIndex), 10);
        unProcessedStr = unProcessedStr.slice(splitIndex + holderEndPatrn.length);
        processedStr += args[urlParam];
      }

      return processedStr + unProcessedStr;
    };

    /* *
    * Append currency code to given value
    * @param value Number (should not be a format Number)
    * @param noOfDecimals decimal palces
    * @param currency currency Code
    * @returns currency appended formatNumber
    */
    var appendCurrencyToValue = function appendCurrencyToValue(value, currency, decimalPlaces) {
      var val = value !== undefined ? value : 0.0;
      var noOfDecimals = decimalPlaces !== undefined ? decimalPlaces : userSettings['default'].displayFormat.decimalPlaces;
      var tmpSize = val > 0 ? utils['default'].formatters.formatNumber(val, noOfDecimals) : userSettings['default'].displayFormat.noValue;
      if (tmpSize !== userSettings['default'].displayFormat.noValue) {
        return [tmpSize, currency].join(utils['default'].Constants.StringConst.Space);
      } else {
        return tmpSize;
      }
    };

    /**
     * format string repacing invalid characters
     *  @params string
     * @returns {string} formatted string
     */
    var formatString = function formatString(value) {
      return value.replace(/[^A-Za-z_0-9(),]/g, '-');
    };

    var displayFormatDateTime = function displayFormatDateTime(date, labels, isShorten, isDateOnly) {
      var day = utils['default'].formatters.getDateComponent(date);
      var month = utils['default'].formatters.getMonthComponent(date, labels, isShorten);
      var year = utils['default'].formatters.getYearComponent(date);
      var time = utils['default'].formatters.getFormattedTimeComponent(date);
      var formattedDate;
      if (isDateOnly) {
        formattedDate = [day, month, year].join('-');
      } else {
        formattedDate = [[day, month, year].join('-'), time].join(' ');
      }
      return formattedDate;
    };

    return {
      convertToUTCTimestamp: convertToUTCTimestamp,
      convertStringToDate: convertStringToDate,
      convertToDisplayTimeFormat: convertToDisplayTimeFormat,
      getAdjustedDateTime: getAdjustedDateTime,
      convertUnicodeToNativeString: convertUnicodeToNativeString,
      adjustToTimezone: adjustToTimezone,
      formatToDate: formatToDate,
      formatToTime: formatToTime,
      formatToDateTime: formatToDateTime,
      formatNumber: formatNumber,
      formatNumberPercentage: formatNumberPercentage,
      divideNumber: divideNumber,
      inMillions: inMillions,
      continMillions: continMillions,
      multiplyNumber: multiplyNumber,
      multiplyNumberPercentage: multiplyNumberPercentage,
      convertToUTCDate: convertToUTCDate,
      formatDateToDisplayDate: formatDateToDisplayDate,
      formatDateToDisplayOnlyDate: formatDateToDisplayOnlyDate,
      generateChartBeginDateString: generateChartBeginDateString,
      generateChartEndDateString: generateChartEndDateString,
      generateIntradayBeginDateString: generateIntradayBeginDateString,
      generateIntradayEndDateString: generateIntradayEndDateString,
      generateHistoryBeginDateString: generateHistoryBeginDateString,
      generateHistoryEndDateString: generateHistoryEndDateString,
      getYearComponent: getYearComponent,
      getMonthComponent: getMonthComponent,
      getDateComponent: getDateComponent,
      getTimeComponent: getTimeComponent,
      getSystemDate: getSystemDate,
      timeDifference: timeDifference,
      convertDateToyyyymmdd: convertDateToyyyymmdd,
      getMonthStrFromInt: getMonthStrFromInt,
      applyPlaceHolders: applyPlaceHolders,
      appendCurrencyToValue: appendCurrencyToValue,
      formatString: formatString,
      getFormattedTimeComponent: getFormattedTimeComponent,
      displayFormatDateTime: displayFormatDateTime,
      formatToDefaultDateTime: formatToDefaultDateTime
    };
  })();

});