define('ir-app/controllers/ir-mobile/quote-widgets/minified-quote', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/components/stk-specific-components/cash-map-slider', 'ir-app/components/stk-specific-components/fifty-two-wk-hl-slider', 'ir-app/models/shared/shared-service'], function (exports, Ember, BaseController, priceService, CashMapSlider, FiftyTwoWkHlSlider, sharedService) {

    'use strict';

    /**
     * Created by User on 17/2/2016.
     */

    exports['default'] = BaseController['default'].extend({
        stock: {},

        // Base overrides
        onPrepareData: function onPrepareData() {
            priceService['default'].addSymbolRequest(this.get('exg'), this.get('sym'));
            this.set('stock', priceService['default'].stockDS.getStock(this.get('exg'), this.get('sym')));

            priceService['default'].sendSymbolValidationRequest(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, undefined, undefined);
        },

        onClearData: function onClearData() {
            priceService['default'].removeIndexRequest(this.get('exg'), this.get('sym'));
            this.set('stock', []);
        }
    });

    Ember['default'].Handlebars.helper('cash-map-slider', CashMapSlider['default']);
    Ember['default'].Handlebars.helper('fifty-two-wk-hl-slider', FiftyTwoWkHlSlider['default']);
    Ember['default'].Handlebars.helper('getPositiveNegativeStyle', function (value) {
        if (value >= 0) {
            return 'padding-medium snap-period-wrapper-green';
        } else {
            return 'padding-medium snap-period-wrapper-red';
        }
    });

});