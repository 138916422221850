define('ir-app/components/connection-status', ['exports', 'ember', 'ir-app/models/price/price-service'], function (exports, Ember, priceService) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        priceService: priceService['default'],
        connectionStatusStyle: 'connection-status-green',

        onConnectionStatusStyleChanged: (function () {
            if (this.get('priceService').connectionStatus) {
                this.set('connectionStatusStyle', 'connection-status-green');
            } else {
                this.set('connectionStatusStyle', 'connection-status-red');
            }
        }).observes('priceService.connectionStatus')
    });

});