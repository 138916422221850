define('ir-app/controllers/ir-mobile/chart-widgets/dividend-overview-chart', ['exports', 'ember', 'ir-app/controllers/base-array-controller', 'ir-app/models/chart/chart-constants', 'ir-app/models/chart/chart-data-stores/graph-data-manager', 'ir-app/components/stk-specific-components/high-chart/dynamic-high-chart', 'ir-app/models/price/price-service', 'ir-app/utils/utils', 'ir-app/models/shared/shared-service', 'ir-app/models/price/price-constants', 'ir-app/config/user-settings'], function (exports, Ember, BaseArrayController, ChartConstants, graphDM, DynamicHighChart, priceService, utils, sharedService, priceConstants, userSettings) {

    'use strict';

    /**
     * Created by banushas on 3/15/2016.
     */

    exports['default'] = BaseArrayController['default'].extend({

        chartDataArray: [],
        chartCloseDataArray: [],
        chartVolumeDataArray: [],
        minValue: -1,
        maxValue: -1,
        stock: {},
        chartViewPeriod: null,
        chartContainerHeight: Ember['default'].$('chartContainer').height(),
        chartContainerWidth: Ember['default'].$('chartContainer').width(),
        minimumChartHeight: 155,
        isPortalTopBarChart: false,
        isToolTipChart: false,
        isPortalChart: false,
        dataLoadingComplete: true,
        chartCategory: null,
        initialChartCategory: null, // This parameter use in side dynamic-high-chart
        chartDataLevel: null,
        baseSymbolKey: null,

        chartCompareSymbolList: {}, // contain compare symbol list and data arrays
        chartCompareSymbolKeyList: [], // compare symbol ket list
        chartCompareSymbolKeyAndDesMap: {}, // map for containing symbol key and short description
        compareSymbolNeedsToUpdate: false,
        dataSeries: [],
        chartYAxisToRight: true, // Added for Y axis side customization. for Alarabia Y axis in left hand side

        onLoadWidget: function onLoadWidget() {
            var that = this;
            that.set('symbol', that.get('sym'));
            that.set('chartDataArray', Ember['default'].A());
            that.set('chartCloseDataArray', Ember['default'].A());
            that.set('chartCompareSymbolList', {});
            that.set('chartVolumeDataArray', Ember['default'].A());
            that.set('chartCompareSymbolKeyList', Ember['default'].A());
            that.set('minValue', -1);
            that.set('maxValue', -1);
            that.set('baseSymbolKey', utils['default'].keyGenerator.getKey(that.get('exg'), that.get('sym')));

            if (userSettings['default'].chartDefaults != null && userSettings['default'].chartDefaults.chartYAxisToRight !== undefined) {
                this.set('chartYAxisToRight', userSettings['default'].chartDefaults.chartYAxisToRight);
            }

            if (!this.get('isToolTipChart')) {
                that.set('chartCategory', ChartConstants['default'].ChartCategory.History);
                that.set('initialChartCategory', ChartConstants['default'].ChartCategory.History);
                that.set('chartDataLevel', ChartConstants['default'].ChartViewPeriod.OneYear.ChartDataLevel);
                that.set('chartViewPeriod', ChartConstants['default'].ChartViewPeriod.OneYear);
                that.set('chartInterval', ChartConstants['default'].ChartViewInterval.Daily);
            }
        },

        onPrepareData: function onPrepareData() {
            this.loadChart();
        },

        loadChart: function loadChart() {
            // var that = this;
            var stock = priceService['default'].stockDS.getStock(this.get('exg'), this.get('sym'));
            this.set('stock', stock);
            // // request symbol meta
            // priceService.sendSymbolValidationRequest(this.get('exg'), this.get('sym'), sharedService.userSettings.currentLanguage);
            // if (!this.isPortalTopBarChart) {
            //     this.set('dataLoadingComplete', false);
            // }

            // var successFn = function () {
            //     that.generateChart();
            // };

            // var errorFn = function () {
            // };
            // // Load exchange object for get the TimeZone
            // priceService.downloadExchangeProperty(this.get('exg'), sharedService.userSettings.currentLanguage, successFn, errorFn);
            this.generateChart();
        },

        onClearData: function onClearData() {
            var gdm = this.get('graphDataManager');
            gdm.removeChartDataSubscription();
            gdm.removeChartSymbols();
        },

        // Base overrides
        generateChart: function generateChart() {
            var that = this;
            var gDM = graphDM['default'].create({
                chartCategory: that.get('chartCategory'),
                chartDataLevel: that.get('chartDataLevel'),
                chartViewPeriod: that.get('chartViewPeriod'),

                onData: function onData(ohlcPoint, exg, sym) {
                    that.amendChart(ohlcPoint, exg, sym);
                },

                onDataChunk: function onDataChunk(chartSymbolObj) {
                    that.updateChart(chartSymbolObj);
                },

                onLastCandleData: function onLastCandleData(lastCandle, exg, sym) {
                    that.amendLastCandle(lastCandle, exg, sym);
                },

                onErrorFn: function onErrorFn() {
                    // when request fail need to top the indicator
                    if (!that.isPortalTopBarChart) {
                        that.set('dataLoadingComplete', true);
                    }
                    // todo [Banusha] need to handle onErrorFn
                }
            });

            gDM.addChartSymbol(that.get('exg'), that.get('sym'), true);
            that.set('graphDataManager', gDM);
            that.get('graphDataManager').addChartDataSubscription();
        },

        amendLastCandle: function amendLastCandle(lastCandle, exg, sym) {
            var that = this;
            var dd, tmpTime, tempOhlc, today;
            dd = new Date(lastCandle.DT);
            today = new Date();
            var symbolKey = utils['default'].keyGenerator.getKey(exg, sym);
            tmpTime = Date.UTC(dd.getUTCFullYear(), dd.getUTCMonth(), dd.getUTCDate() - 1, 18, 30, 0); // TODO [Akila] find a better way to set the time instead of reducing the date

            if (that.get('baseSymbolKey') === symbolKey) {
                // base symbol
                tempOhlc = that.get('chartDataArray').pop();

                if (parseInt(utils['default'].formatters.formatDateToDisplayOnlyDate(today).split('-')[2]) > parseInt(utils['default'].formatters.formatDateToDisplayOnlyDate(new Date(tempOhlc.x)).split('-')[2])) {
                    that.get('chartDataArray').push(tempOhlc);
                    if (tempOhlc.x < lastCandle.DT) {
                        that.get('chartDataArray').pushObject({
                            x: tmpTime,
                            y: lastCandle.close,
                            open: lastCandle.open,
                            high: lastCandle.high,
                            low: lastCandle.low,
                            close: lastCandle.close
                        });
                        that.get('chartCloseDataArray').pushObject({
                            x: tmpTime,
                            y: lastCandle.close
                        });
                        that.get('chartVolumeDataArray').pushObject([tmpTime, lastCandle.Volume]);
                        // that.calculateMiMaxValues(lastCandle);
                    }
                } else {
                        if (tempOhlc.x < lastCandle.DT) {
                            that.get('chartDataArray').pushObject({
                                x: tmpTime,
                                y: lastCandle.close,
                                open: lastCandle.open,
                                high: lastCandle.high,
                                low: lastCandle.low,
                                close: lastCandle.close
                            });
                            that.get('chartCloseDataArray').pushObject({
                                x: tmpTime,
                                y: lastCandle.close
                            });
                            that.get('chartVolumeDataArray').pushObject([tmpTime, lastCandle.Volume]);
                            //that.calculateMiMaxValues(lastCandle);
                        }
                    }
            } else {
                    // compare symbols
                    that.set('compareSymbolNeedsToUpdate', false);
                    tempOhlc = that._getCompareList(symbolKey).pop();

                    if (tempOhlc === undefined) {
                        return;
                    }

                    if (parseInt(utils['default'].formatters.formatDateToDisplayOnlyDate(today).split('-')[2]) > parseInt(utils['default'].formatters.formatDateToDisplayOnlyDate(new Date(tempOhlc.x)).split('-')[2])) {
                        that._getCompareList(symbolKey).push(tempOhlc);
                        if (tempOhlc.x < lastCandle.DT) {
                            that._getCompareList(symbolKey).pushObject({
                                x: tmpTime,
                                y: lastCandle.close
                            });
                            // that.calculateMiMaxValues(lastCandle);
                        }
                    } else {
                            if (tempOhlc.x < lastCandle.DT) {
                                that._getCompareList(symbolKey).pushObject({
                                    x: tmpTime,
                                    y: lastCandle.close
                                });
                                //that.calculateMiMaxValues(lastCandle);
                            }
                        }
                    that.set('compareSymbolNeedsToUpdate', true);
                }
            if (!that.isPortalTopBarChart) {
                that.set('dataLoadingComplete', true);
            }
        },

        updateChart: function updateChart(chartSymbolObj) {
            var that = this;
            var exchange = this.get('exchange');

            if (chartSymbolObj.isBaseSymbol) {
                that.resetMinMax();
                that.resetDataArrays();
                that.set('chartPointArray', chartSymbolObj.chartPointArray);
                var dd, tmpTime;
                chartSymbolObj.chartPointArray.forEach(function (item) {
                    dd = new Date(item.DT);
                    tmpTime = Date.UTC(dd.getUTCFullYear(), dd.getUTCMonth(), dd.getUTCDate(), dd.getUTCHours(), dd.getUTCMinutes(), dd.getUTCSeconds(), dd.getUTCMilliseconds());
                    that.get('chartDataArray').pushObject({
                        x: tmpTime,
                        y: item.Close,
                        open: item.Open,
                        high: item.High,
                        low: item.Low,
                        close: item.Close
                    });
                    that.get('chartCloseDataArray').pushObject({ // [Todo] Banusha added because of area chart issue.Later we need to remove
                        x: tmpTime,
                        y: item.Close
                    });
                    that.get('chartVolumeDataArray').pushObject([tmpTime, item.Volume]);
                    // that.calculateMiMaxValues(item);
                });
                if (!that.isPortalTopBarChart) {
                    that.set('dataLoadingComplete', true);
                }
            } else {
                var dd1, tmpTime1;
                var key = utils['default'].keyGenerator.getKey(chartSymbolObj.exg, chartSymbolObj.sym);
                that._getCompareList(key).clear();
                that.set('compareSymbolNeedsToUpdate', false);
                chartSymbolObj.chartPointArray.forEach(function (item) {
                    dd1 = new Date(item.DT);
                    tmpTime1 = Date.UTC(dd1.getUTCFullYear(), dd1.getUTCMonth(), dd1.getUTCDate(), dd1.getUTCHours(), dd1.getUTCMinutes(), dd1.getUTCSeconds(), dd1.getUTCMilliseconds());
                    that._getCompareList(key).pushObject({
                        x: tmpTime1,
                        y: item.Close
                    });
                });
                that.set('compareSymbolNeedsToUpdate', true);
            }

            if (this.get('chartViewPeriod') === ChartConstants['default'].ChartViewPeriod.OneDay && exchange.stat === priceConstants['default'].MarketStatus.PreOpen) {
                that.set('chartDataArray', Ember['default'].A());
                that.set('chartCloseDataArray', Ember['default'].A());
                that.set('chartVolumeDataArray', Ember['default'].A());

                var compareList = this.get('chartCompareSymbolKeyList');
                for (var i = 0; i < compareList.length; i++) {
                    var symKey = compareList[i];
                    this._getCompareList(symKey).clear();
                }
            }
        },

        amendChart: function amendChart(ohlcPoint, exg, sym) {
            var that = this;
            var dd, tmpTime;
            dd = new Date(ohlcPoint.DT);
            var symbolKey = utils['default'].keyGenerator.getKey(exg, sym);
            tmpTime = Date.UTC(dd.getUTCFullYear(), dd.getUTCMonth(), dd.getUTCDate(), dd.getUTCHours(), dd.getUTCMinutes(), dd.getUTCSeconds(), dd.getUTCMilliseconds());
            if (that.get('baseSymbolKey') === symbolKey) {
                // base symbol
                that.get('chartDataArray').pushObject({
                    x: tmpTime,
                    y: ohlcPoint.Close,
                    open: ohlcPoint.Open,
                    high: ohlcPoint.High,
                    low: ohlcPoint.Low,
                    close: ohlcPoint.Close
                });
                that.get('chartCloseDataArray').pushObject({
                    x: tmpTime,
                    y: ohlcPoint.Close
                });
                that.get('chartVolumeDataArray').pushObject([tmpTime, ohlcPoint.Volume]);
                // that.calculateMiMaxValues(ohlcPoint);
            } else {
                    // compare symbols
                    that.set('compareSymbolNeedsToUpdate', false);
                    that._getCompareList(symbolKey).pushObject({
                        x: tmpTime,
                        y: ohlcPoint.Close,
                        open: ohlcPoint.Open,
                        high: ohlcPoint.High,
                        low: ohlcPoint.Low,
                        close: ohlcPoint.Close
                    });
                    that.set('compareSymbolNeedsToUpdate', true);
                }
            if (!that.isPortalTopBarChart) {
                that.set('dataLoadingComplete', true);
            }
        },

        calculateMiMaxValues: function calculateMiMaxValues(ohlcPoint) {
            var that = this;
            if (that.get('minValue') < 0 || ohlcPoint.Close < that.get('minValue')) {
                that.set('minValue');
            }
            if (that.get('maxValue') < 0 || ohlcPoint.Close > that.get('maxValue')) {
                that.set('maxValue', 1);
            }
        },

        resetMinMax: function resetMinMax() {
            var that = this;
            that.set('minValue', -1);
            that.set('maxValue', -1);
        },

        resetDataArrays: function resetDataArrays() {
            var that = this;
            that.set('chartDataArray', Ember['default'].A());
            that.set('chartCloseDataArray', Ember['default'].A());
            that.set('chartVolumeDataArray', Ember['default'].A());
        },

        _getCompareList: function _getCompareList(key) {
            var compareList = this.get('chartCompareSymbolList');
            if (!compareList[key]) {
                compareList[key] = Ember['default'].A([]);
            }
            return compareList[key];
        },

        // onResize: function () {
        //    this.handleResize();
        // },

        handleResize: function handleResize() {
            var that = this;
            if (!that.isPortalTopBarChart) {
                try {
                    if (that.get('isPortalChart')) {
                        // resizing for portal chart
                        if (document.getElementById('col-xs-12 col-sm-8') !== null && document.getElementById('col-xs-12 col-sm-4') !== null) {
                            var sidebarContainerHeight = document.getElementById('col-xs-12 col-sm-4').getBoundingClientRect().height;
                            that.set('chartContainerHeight', sidebarContainerHeight - (10 + document.getElementById('fourBox').getBoundingClientRect().height + document.getElementById('chartToolbar').getBoundingClientRect().height));
                            that.set('chartContainerWidth', document.getElementById('col-xs-12 col-sm-8').offsetWidth - 30);
                        }
                    } else {
                        if (document.getElementById('chartContainer') !== null) {
                            var chartHeight;
                            var topHeight = document.getElementById('chartContainer').getBoundingClientRect().top;
                            var fullHeight = Ember['default'].$(window).height();
                            if (this.get('fixedChartHeight')) {
                                chartHeight = this.get('fixedChartHeight') > that.minimumChartHeight ? this.get('fixedChartHeight') : that.minimumChartHeight;
                            } else {
                                var expectedChartHeight = fullHeight - topHeight - 3; // 3 is a bottom margin
                                chartHeight = expectedChartHeight > that.minimumChartHeight ? expectedChartHeight : that.minimumChartHeight;
                            }
                            that.set('chartContainerHeight', chartHeight);
                            that.set('chartContainerWidth', document.getElementById('chartContainer').offsetWidth);
                        }
                    }
                } catch (e) {
                    utils['default'].logger.logError('[basic chart widget] handleResize() ' + e);
                }
            }
        },

        handleInitialResize: function handleInitialResize() {
            var that = this;
            setTimeout(function () {
                // give 1s delay to let other components to load.
                that.handleResize();
            }, 1000);
        },

        bindResizeEvent: (function () {
            try {
                var that = this;
                Ember['default'].$(window).on('resize', Ember['default'].run.bind(this, this.handleResize));
                Ember['default'].$(window).ready(function () {
                    Ember['default'].$('#transbox').bind('DOMSubtreeModified', function () {
                        // Some times it is bit delay to load the top description panel(#transbox) of the home page
                        that.handleResize();
                    });
                });
                Ember['default'].$(window).ready(Ember['default'].run.bind(this, this.handleInitialResize));
            } catch (e) {
                utils['default'].logger.logError('[basic chart widget] bindResizeEvent() ' + e);
            }
        }).on('init'),

        chartMode: (function () {
            Highcharts.setOptions({ // jshint ignore:line
                lang: {
                    thousandsSep: ','
                }
            });
            return 'StockChart';
        }).property(),

        _basicChartOption: function _basicChartOption() {
            var that = this;
            return {
                chart: {
                    height: that.get('chartHeight') ? that.get('chartHeight') : 150,
                    backgroundColor: that.get('thm').themeName.colors.backgroundColor,
                    pinchType: '',
                    zoomType: '',
                    margin: [that.get('isToolTipChart') ? undefined : 0, 0, that.get('isToolTipChart') ? undefined : 0, 0]
                },
                xAxis: {
                    type: 'datetime',
                    gridLineWidth: that.get('isToolTipChart') ? '1' : '0',
                    labels: {
                        enabled: that.get('isToolTipChart') ? true : false
                    },
                    title: {
                        text: null
                    }
                },
                yAxis: [{
                    gridLineWidth: that.get('isToolTipChart') ? '1' : '0',
                    labels: {
                        enabled: that.get('isToolTipChart') ? true : false
                    },
                    title: {
                        text: null
                    }
                }],
                rangeSelector: {
                    enabled: false
                },
                navigator: {
                    enabled: false
                },
                scrollbar: {
                    enabled: false
                },
                tooltip: {
                    enabled: true,
                    useHTML: true
                },
                exporting: {
                    enabled: false
                }

            };
        },

        _advancedChartOption: function _advancedChartOption() {
            var that = this;
            return {
                chart: {
                    height: 250,
                    backgroundColor: that.get('thm').themeName.colors.backgroundColor,
                    pinchType: '',
                    zoomType: '',
                    marginLeft: 0,
                    marginRight: 0
                },
                xAxis: {
                    type: 'datetime',
                    gridLineWidth: '0'
                },
                yAxis: [{
                    labels: that._mainYAxisLabels(),
                    opposite: that.axisYIsOpposite(),
                    title: {
                        text: null
                    },
                    gridLineWidth: '0'
                }, {
                    labels: {
                        enabled: false
                    },
                    opposite: that.axisYIsOpposite(),
                    top: '55%',
                    height: '45%',
                    gridLineWidth: '0'
                }],
                rangeSelector: {
                    enabled: false
                },
                navigator: {
                    enabled: false
                },
                scrollbar: {
                    enabled: false
                },
                exporting: {
                    enabled: false
                }

            };
        },

        _basicChartData: function _basicChartData() {
            var that = this;
            return [{
                type: 'area',
                data: this.get("dataSeries"),
                name: that.get('stock').sDes,
                lineColor: that.get('thm').themeName.colors.lineColor,
                lineWidth: 1,
                fillColor: that.chartFillColor(),
                states: {
                    hover: {
                        enabled: false
                    }
                },
                dataGrouping: {
                    enabled: false
                },
                dataLabels: {
                    enabled: false
                },
                tooltip: {
                    valueDecimals: that.get('stock').deci,
                    useHTML: true
                }
            }];
        },

        _advancedChartData: function _advancedChartData() {
            var that = this;
            return [{
                type: 'area',
                data: that.get('chartCloseDataArray'),
                name: that.get('stock').sDes,
                lineColor: that.get('thm').themeName.colors.lineColor,
                lineWidth: 2,
                fillColor: that.chartFillColor(),
                states: {
                    hover: {
                        enabled: false
                    }
                },
                dataGrouping: {
                    enabled: false
                },
                dataLabels: {
                    enabled: false
                },
                tooltip: {
                    valueDecimals: that.get('stock').deci,
                    useHTML: true
                }
            }, {
                type: 'column',
                name: this.get('app').lang.labels.volume,
                data: this.get('chartVolumeDataArray'),
                yAxis: 1,
                color: that.get('thm').themeName.colors.volumeColor,
                dataGrouping: {
                    enabled: false
                }
            }];
        },

        axisYIsOpposite: function axisYIsOpposite() {
            return this.get('chartYAxisToRight');
        },

        _mainYAxisLabels: function _mainYAxisLabels() {
            var that = this;
            if (this.get('chartYAxisToRight')) {
                return {
                    align: 'center',
                    x: -20,
                    y: -2,
                    formatter: function formatter() {
                        return utils['default'].formatters.formatNumber(this.value, that.get('stock').deci);
                    },
                    style: {
                        color: that.get('thm').themeName.colors.fontColor
                    }
                };
            } else {
                return {
                    align: 'center',
                    x: 30,
                    y: -2,
                    formatter: function formatter() {
                        return utils['default'].formatters.formatNumber(this.value, that.get('stock').deci);
                    },
                    style: {
                        color: that.get('thm').themeName.colors.fontColor
                    }
                };
            }
        },

        chartFillColor: function chartFillColor() {
            var that = this;
            if (utils['default'].validators.isMSIEVersion()) {
                return that.get('thm').themeName.colors.areaChartBgColor;
            } else {
                return {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [[0, that.get('gradientUpperColor') ? that.get('gradientUpperColor') : that.get('thm').themeName.colors.gradientUpperColor], [1, that.get('gradientLowerColor') ? that.get('gradientLowerColor') : that.get('thm').themeName.colors.gradientLowerColor]]
                };
            }
        },

        chartOptions: (function () {
            var that = this;
            if (that.isPortalTopBarChart) {
                return that._basicChartOption();
            } else {
                return that._advancedChartOption();
            }
        }).property(),

        chartData: (function () {
            var that = this;
            return that._basicChartData();
            // if (that.isPortalTopBarChart) {
            //     return that._basicChartData();
            // } else {
            //     return that._advancedChartData();
            // }
        }).property('dataSeries')
    });

    Ember['default'].Handlebars.helper('dynamic-high-chart', DynamicHighChart['default']);

});