define('ir-app/models/price/price-business-entities/company-credit-ratings', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        provider: '', // Rating Agency
        longTerm: '', // Long Term Ratings
        shortTerm: '', // Short Term Ratings
        outlook: '', //outlook
        date: '', //Rate Affirmed
        url: '', //Report Url
        setData: function setData(creditRatings) {
            var that = this;

            Ember['default'].$.each(creditRatings, function (key, value) {
                that.set(key, value);
            });
        }
    });

});