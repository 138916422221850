define('ir-app/models/price/price-business-entities/announcement', ['exports', 'ember', 'ir-app/models/price/price-constants', 'ir-app/utils/utils', 'ir-app/models/price/price-service'], function (exports, Ember, PriceConstant, utils, PriceService) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        id: '', // Announcement id - Unique identifier
        exg: '', // Exchange or news provider
        sym: '', // Symbol
        ln: '', // Language
        hed: '', // Header
        bod: '', // Body
        ref: '', // Reference Code
        type: PriceConstant['default'].ResponseType.Data.ResponseAnnouncement, // 11 - Announcement, 77 - News, ?? - Calendar Event
        dt: '', // Date Time
        tzo: '', // Time Zone

        dHed: (function () {
            return utils['default'].formatters.convertUnicodeToNativeString(this.get('hed'));
        }).property('hed'), // Display Header

        dBody: (function () {
            return utils['default'].formatters.convertUnicodeToNativeString(this.get('bod'));
        }).property('bod'), // Display Body

        dDt: (function () {
            var dateTime = this.get('dt');
            return utils['default'].formatters.formatToDateTime(dateTime, this.get('tzo'));
        }).property('dt'), // Display date Time

        dSym: (function () {
            var symCode = this.get('sym');
            var exgCode = this.get('exg');

            // Symbol code is returned for all the cases where sufficient parameters and sufficient data is not available.
            // This is done to handle the asynchronous behaviour of the usages.
            if (utils['default'].validators.isAvailable(symCode) && utils['default'].validators.isAvailable(exgCode)) {
                var symbObj = PriceService['default'].stockDS.getStock(exgCode, symCode);

                if (utils['default'].validators.isAvailable(symbObj.sDes)) {
                    return symbObj.sDes;
                } else {
                    return symCode;
                }
            } else {
                return symCode;
            }
        }).property('sym', 'exg'), // Display symbol

        isAnnouncement: (function () {
            return this.type === PriceConstant['default'].ResponseType.Data.ResponseAnnouncement;
        }).property('type'),

        isNews: (function () {
            return this.type === PriceConstant['default'].ResponseType.Data.ResponseNews;
        }).property('type'),

        isBodyUpdated: (function () {
            return utils['default'].validators.isAvailable(this.bod);
        }).property('bod'),

        isArabic: (function () {
            return this.get('ln') === 'AR';
        }).property('ln'),

        dateObj: (function () {
            var dateTime = this.get('dt');
            return utils['default'].formatters.convertStringToDate(dateTime);
        }).property('dt'),

        setData: function setData(announcementMessage) {
            var that = this;

            Ember['default'].$.each(announcementMessage, function (key, value) {
                that.set(key, value);
            });
        }
    });

});