define('ir-app/models/price/price-business-entities/insider-trade', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    /**
     * Created by lakmaln on 7/26/2016.
     */

    exports['default'] = Ember['default'].Object.extend({
        qty: '', // Quantity
        date: '', // Date
        type: '', // Trade type (1- Buy, 2- Sell)
        typeName: '', // Trade type text
        name: '', // Display name (Individual name or Company name)
        indName: '', // Individual name
        indId: '', // Individual id
        comName: '', // Company name
        vwap: '', // Trade date VWAP

        insiderTrades: null,

        init: function init() {
            this._super();
            this.set('insiderTrades', Ember['default'].A());
        },

        setData: function setData(insiderTradeData) {
            var that = this;

            Ember['default'].$.each(insiderTradeData, function (key, value) {
                that.set(key, value);
            });
        }
    });

});