define('ir-app/views/table/table-mixins/condition-mixin', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Mixin.create({
        isPositive: function isPositive(value) {
            return value && parseFloat(value) > 0;
        },

        isZero: function isZero(value) {
            return value !== undefined && parseFloat(value) === 0;
        }
    });

});