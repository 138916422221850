define('ir-app/controllers/shared/initializer/language-initializer', ['exports', 'ember', 'ir-app/models/shared/language/language-data-store', 'ir-app/models/shared/shared-service', 'ir-app/config/user-settings', 'ir-app/utils/utils'], function (exports, Ember, languageDataStore, sharedService, userSettings, utils) {

    'use strict';

    exports['default'] = (function () {
        var prepareLanguageView = function prepareLanguageView() {
            sharedService['default'].userSettings.set('currentLanguage', _getUserLanguage());
            _setDisplayTexts();
            Ember['default'].$('input:radio[name=lang]').val([sharedService['default'].userSettings.get('currentLanguage')]);
            _bindEvents();
        };

        var showLanguageView = function showLanguageView() {
            Ember['default'].$('div#divLang').show();
        };

        /* *
         * Change display texts on language change
         * @private
         */
        var _setDisplayTexts = function _setDisplayTexts() {
            var currentLangObj = languageDataStore['default'].getLanguageObj().lang;

            // Set button texts
            Ember['default'].$('button#btnLang').text(currentLangObj.labels.next);
            Ember['default'].$('button#btnTheme').text(currentLangObj.labels.next);

            // Set copyright
            Ember['default'].$('#spanCopyright').text(currentLangObj.labels.copyright);
        };

        /* *
         * Gets last saved user language or default language if not available
         * @returns {*} User language
         * @private
         */
        var _getUserLanguage = function _getUserLanguage() {
            var userLang = sharedService['default'].userSettings.get('currentLanguage');
            return utils['default'].validators.isAvailable(userLang) ? userLang : userSettings['default'].customisation.defaultLanguage;
        };

        var _bindEvents = function _bindEvents() {
            Ember['default'].$('input[type="radio"][name="lang"]').bind('change', function () {
                languageDataStore['default'].changeLanguage(this.value);
                _setDisplayTexts();
            });

            Ember['default'].$('button#btnLang').bind('click', function () {
                var lang = Ember['default'].$('input[type="radio"][name="lang"]:checked').val();

                sharedService['default'].userSettings.set('currentLanguage', lang);
                sharedService['default'].userSettings.save();

                Ember['default'].$('div#divLang').hide(); // Hide language selection page
                Ember['default'].$('div#divTheme').show(); // Show theme selection page
            });
        };

        return {
            prepareLanguageView: prepareLanguageView,
            showLanguageView: showLanguageView
        };
    })();

});