define('ir-app/utils/json-helper', ['exports'], function (exports) {

    'use strict';

    exports['default'] = (function () {
        var convertToJson = function convertToJson(obj) {
            return JSON.stringify(obj);
        };

        var convertFromJson = function convertFromJson(json) {
            return JSON.parse(json);
        };

        return {
            convertToJson: convertToJson,
            convertFromJson: convertFromJson
        };
    })();

});