define('ir-app/components/stk-specific-components/cash-map-slider', ['exports', 'ember', 'ir-app/utils/utils'], function (exports, Ember, utils) {

    'use strict';

    /**
     * Created by User on 2/10/2016.
     */
    exports['default'] = Ember['default'].Component.extend({
        layoutName: 'components/stk-specific-components/cash-map-slider',

        calculateCash: (function () {
            var stock = this.get('stock');
            var cashIn = stock.cit;
            var cashOut = stock.cot;

            var value = cashIn / (cashIn + cashOut);

            return isNaN(value) ? 0 : value * 100;
        }).property('stock.cit', 'stock.cot'),

        showValue: (function () {
            return utils['default'].formatters.formatNumber(this.get('calculateCash'), 2) + '%';
        }).property('calculateCash'),

        styles: (function () {
            var progress = parseInt(this.get('calculateCash'), 10);
            return 'width:' + progress + '%;'; // Used inline styles since width is dynamically and changed rapidly
        }).property('calculateCash')

    });

});