define('ir-app/components/stk-specific-components/cash-map', ['exports', 'ember', 'ir-app/utils/utils'], function (exports, Ember, utils) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        layoutName: 'components/stk-specific-components/cash-map',

        cashMapSettings: {
            styles: {
                green: 'green',
                darkGreen: 'green-dark',
                red: 'red',
                darkRed: 'red-dark',
                white: 'colour-2'
            }
        },

        calculateCash: (function () {
            var stock = this.get('stock');
            var cashIn = stock.cit;
            var cashOut = stock.cot;
            var value = cashIn / (cashIn + cashOut);

            return isNaN(value) ? 0 : value * 100;
        }).property('stock.cit', 'stock.cot'),

        showValue: (function () {
            return utils['default'].formatters.formatNumber(this.get('calculateCash'), 2) + '%';
        }).property('calculateCash'),

        styles: (function () {
            var progress = parseInt(this.get('calculateCash'), 10);
            return 'width:' + progress + '%;'; // Used inline styles since width is dynamically and changed rapidly
        }).property('calculateCash'),

        valueStyle: (function () {
            return this.get('calculateCash') < 50 ? this.cashMapSettings.styles.red : this.cashMapSettings.styles.green;
        }).property('calculateCash')
    });

});