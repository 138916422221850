define('ir-app/controllers/ir-mobile/event-calendar-widget/event-calendar', ['exports', 'ember', 'ir-app/models/price/price-service', 'ir-app/models/price/price-constants', 'ir-app/controllers/base-array-controller', 'ir-app/utils/utils', 'ir-app/models/shared/shared-service'], function (exports, Ember, priceService, priceConstants, BaseArrayController, utils, sharedService) {

    'use strict';

    /**
     * Created by User on 2/16/2016.
     */

    exports['default'] = BaseArrayController['default'].extend({
        sortProperties: ['dDt'],
        sortAscending: true,
        minifiedVersion: false,
        portalMinifiedVersion: false,
        upcomingEventNotAvailable: true,
        isForExchange: false,
        isLoadAnnInPopup: false,
        isShowAnnPopup: false,
        isLoadingAnnPopup: false,
        annObj: null,
        lan: '',
        toDay: '',
        selectedTab: 1,
        minifiedSliceNumber: 3,
        basicInfo: {},
        enableAddAllEvents: false,
        showFotterNote: false,

        Tabs: {
            UpcomingEvents: 1,
            PastEvents: 2
        },

        // Base overrides
        onLoadWidget: function onLoadWidget() {

            if (this.get('isForExchange')) {
                this.set('sym', 'A');
            }

            this.set('toDay', new Date());
            this.set('lan', sharedService['default'].userSettings.currentLanguage);
            this.setErrorMessage();
            this.startLoadingProgress();

            if (this.get('portalMinifiedVersion')) {
                this.set('sortAscending', false);
            }
        },

        onCheckDataAvailability: function onCheckDataAvailability() {
            return this.get('filteredContent').length > 0;
        },

        onPrepareData: function onPrepareData() {

            var that = this;
            var successFn = function successFn() {
                if (that.get('isForExchange')) {
                    // Set both success and failure functions to the same function as in case of a failure we at least show the symbol code instead of the symbol name
                    priceService['default'].downloadParamsSymbolMasterForExchange({
                        exchange: that.get('exg'),
                        language: sharedService['default'].userSettings.currentLanguage,
                        successFn: function successFn() {
                            that._sendRequest();
                        },
                        errorFn: function errorFn() {
                            that._sendRequest();
                        }
                    });
                } else {
                    that._sendRequest();
                }
            };

            var errorFn = function errorFn() {};

            priceService['default'].exchangeDS.getExchange(that.get('exg'));
            priceService['default'].downloadExchangeProperty(that.get('exg'), sharedService['default'].userSettings.currentLanguage, successFn, errorFn);

            that.set('model', priceService['default'].eventCalendarDS.getEventList(that.get('exg'), that.get('sym'), that.get('lan')));
            // Binding the scrollbar after the data is available and rendering the view
            Ember['default'].$('.nano').nanoScroller();
            this.sendCompanyInfoRequest();
        },

        sendCompanyInfoRequest: function sendCompanyInfoRequest() {
            var that = this;
            var basicInfo = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, function () {
                that.successFn();
            }, function () {
                console.log("Get Company Info Request Failed");
            }, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyBasicInfo);
            that.set('basicInfo', basicInfo);
        },

        successFn: function successFn() {
            // company basic info request success function
        },

        _sendRequest: function _sendRequest() {
            var that = this;
            priceService['default'].sendEventCalendarRequest({
                exchange: that.get('exg'),
                symbol: that.get('sym'),
                language: sharedService['default'].userSettings.currentLanguage,
                successFn: function successFn() {
                    that.onDataSuccess();
                },
                errorFn: function errorFn() {
                    that.onDataError();
                }
            });
        },

        onClearData: function onClearData() {
            this._reset();
        },

        onAfterRender: function onAfterRender() {
            // this._setActiveTab();
            Ember['default'].$('.nano').nanoScroller();
        },

        onLanguageChanged: function onLanguageChanged() {
            this._reset();
            this.onLoadWidget();
            this.onPrepareData();
        },

        /** _setActiveTab: function () {
            // Set active tab
            Ember.$('#' + this.get('selectedTab')).addClass('active');
        },*/

        _onTabChange: function _onTabChange(tabId) {
            this.set('selectedTab', tabId);
            if (this.get('selectedTab') === this.Tabs.UpcomingEvents) {
                this.set('sortAscending', true); // Upcoming events to be listed nearest at the top
            } else {
                    this.set('sortAscending', false);
                }
        },

        _getDateTime: function _getDateTime(dateString) {
            return utils['default'].formatters.convertStringToDate(dateString);
        },

        _reset: function _reset() {
            this.set('model', Ember['default'].A());
        },
        /**
         * override to handle the error message according selected tab
         */
        setErrorMessage: (function () {
            if (!this.get('portalMinifiedVersion')) {
                if (this.get('filteredContent').length > 0) {
                    this.hideDataErrorMessage();
                    this.set('showFotterNote', true);
                    if (this.get('selectedTab') === this.Tabs.UpcomingEvents) {
                        this.set('upcomingEventNotAvailable', false);
                    } else {
                        this.set('upcomingEventNotAvailable', true);
                    }
                } else if (!this.get('isLoading')) {
                    this.showDataErrorMessage();
                    if (this.get('selectedTab') === this.Tabs.UpcomingEvents) {
                        this.set('errorMessage', this.get('app').lang.messages.noUpcomingEvents);
                        this.set('upcomingEventNotAvailable', true);
                    } else {
                        this.set('errorMessage', this.get('app').lang.messages.noEvents);
                    }
                }
            }
        }).observes('filteredContent'),

        sortedContent: (function () {
            return this.get('arrangedContent');
        }).property('arrangedContent'),

        filteredContent: (function () {
            return this.get('sortedContent').filter((function (that) {
                //eslint-disable-line
                return function (theObject) {
                    if (that.get('selectedTab') === that.Tabs.UpcomingEvents) {
                        return that._getDateTime(theObject.eventStartDate) >= that.get('toDay');
                    } else {
                        return that._getDateTime(theObject.eventStartDate) < that.get('toDay');
                    }
                };
            })(this));
        }).property('selectedTab', 'sortedContent.@each'),

        filteredSliceContent: (function () {
            var that = this;
            if (this.get('filteredContent').length > that.minifiedSliceNumber) {
                return this.get('filteredContent').slice(0, that.minifiedSliceNumber);
            } else {
                return this.get('filteredContent');
            }
        }).property('filteredContent'),

        allEventSliceContent: (function () {
            var that = this;
            if (that.get('sortedContent').length > 0) {
                return that.get('sortedContent').slice(0, that.minifiedSliceNumber);
            }
        }).property('sortedContent', 'sortedContent.@each'),

        displayContent: (function () {
            if (this.get('minifiedVersion')) {
                return this.get('filteredSliceContent');
            } else {
                return this.get('filteredContent');
            }
        }).property('filteredContent'),

        isTabHidden: (function () {
            return this.get('minifiedVersion') || this.get('portalMinifiedVersion');
        }).property('minifiedVersion', 'portalMinifiedVersion'),

        upComingEventTabSelected: (function () {
            return this.get('selectedTab') === this.Tabs.UpcomingEvents;
        }).property('selectedTab'),

        sendAnnouncementBodyRequest: function sendAnnouncementBodyRequest(annId) {
            var that = this;
            that.set('isShowAnnPopup', true);
            var annObj = priceService['default'].announcementDS.getAnnouncement(annId);
            this.set('annObj', annObj);

            if (!annObj.get('isBodyUpdated')) {
                that.set('isLoadingAnnPopup', true);
                // that.annObj.set('bod', that.get('app').lang.messages.loading);

                priceService['default'].sendNewsAnnBodyRequest({
                    id: annObj.get('id'),
                    type: annObj.get('type'),
                    reqSuccessFn: function reqSuccessFn() {
                        that.set('isLoadingAnnPopup', false);
                    },
                    reqFailureFn: function reqFailureFn() {
                        that.annObj.set('isBodyUpdated', false);
                        that.set('isShowAnnPopup', false);
                    },
                    lan: sharedService['default'].userSettings.currentLanguage
                });
            }
        },

        closeAnnPopup: function closeAnnPopup() {
            var that = this;
            that.set('isShowAnnPopup', false);
            this.set('annObj', null);
        },

        actions: {
            onTabSelected: function onTabSelected(tabId) {
                this._onTabChange(tabId);
            },

            addToCalendar: function addToCalendar(title, date, id) {
                if (window.Android) {
                    Android.addToCalendar(this.get('basicInfo').compName + ' - ' + title, date); // jshint ignore:line
                } else {
                        var retrievedObject = localStorage.getItem('host_version');

                        if (JSON.parse(retrievedObject).os === 'ios') {

                            window.location = 'event-calender' + '/' + this.get('basicInfo').compName + ' - ' + title + '~' + date + '~' + id;
                        }
                    }
            },

            addAllToCalendar: function addAllToCalendar() {
                if (window.Android) {
                    this.get('displayContent').forEach(function (event, index) {
                        if (!this.get('displayContent')[index].isPending) {
                            this.set('enableAddAllEvents', true);
                            Android.addAllToCalendar(this.get('basicInfo').compName + ' - ' + event.eventTitle, event.eventStartDate); // jshint ignore:line
                        }
                    }, 'displayContent');
                    Android.showAllAddedToast(); // jshint ignore:line
                } else {
                        var eventList = this.get('filteredContent');
                        var eventString = '';
                        for (var i = 0; i < eventList.length; i++) {
                            if (!eventList[i].isPending) {
                                this.set('enableAddAllEvents', true);
                                eventString += this.get('basicInfo').compName + ' - ' + eventList[i].eventTitle + '~' + eventList[i].eventStartDate + '~' + eventList[i].eventId + '|';
                            }
                        }
                        try {

                            var retrievedObject = localStorage.getItem('host_version');

                            if (JSON.parse(retrievedObject).os === 'ios') {

                                window.location = 'event-calender' + '/' + eventString;
                            }
                        } catch (err) {

                            // if error
                        }
                    }
            },

            loadAnnPopup: function loadAnnPopup(annId) {
                this.sendAnnouncementBodyRequest(annId);
            },

            closeAnnPopup: function closeAnnPopup() {
                this.closeAnnPopup();
            }
        }
    });

});