define('ir-app/views/table/dual-cells/dual-change-cell', ['exports', 'ember', 'ir-app/views/table/dual-cells/dual-cell'], function (exports, Ember, DualCell) {

    'use strict';

    exports['default'] = DualCell['default'].extend({
        templateName: 'table/views/dual_change-cell',

        formattedSecondValue: Ember['default'].computed(function () {
            // Override formattedSecondValue to add different format
            return this.addPercentageFormat(this.get('cellContent') ? this.get('cellContent').secondValue : undefined, 2);
        }).property('cellContent'),

        styleFirstValue: Ember['default'].computed(function () {
            return this.getPositiveNegativeStyle(this.get('firstValue'));
        }).property('firstValue'),

        styleSecondValue: Ember['default'].computed(function () {
            return this.getPositiveNegativeStyle(this.get('secondValue'), 'green-dark', 'red-dark');
        }).property('secondValue')
    });

});