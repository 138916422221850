define('ir-app/controllers/ir-widgets/financial-dividend-widgets/dividend-history', ['exports', 'ember', 'ir-app/controllers/base-array-controller', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/models/chart/chart-constants', 'ir-app/utils/utils', 'ir-app/models/price/price-constants'], function (exports, Ember, BaseArrayController, priceService, sharedService, chartConstants, utils, priceConstants) {

    'use strict';

    exports['default'] = BaseArrayController['default'].extend({
        needs: ['application'],
        count: undefined,
        searchParams: undefined,
        capActObj: undefined,
        detailList: Ember['default'].A(),
        actionTypes: Ember['default'].A(),
        isDtlTbl: false,
        isCapChgTbl: false,
        isActionTypes: false,
        isCapActData: false,
        isCachedData: false, // if object need to be cached or not
        customView: false,
        current: 1,
        actTypeId: 7,
        pageSize: 20,
        chartData: [],
        optionsCounter: false,
        yearListForBarChart: undefined,

        onLoadWidget: function onLoadWidget() {
            this.setErrorMessage();
        },

        onPrepareData: function onPrepareData() {
            var that = this;
            that.set('pageSize', this.get('pageSize'));
            that.startLoadingProgress();
            that._loadCapitalActionPage();
            that._downloadHistory();
            priceService['default'].addSymbolRequest(this.get('exg'), this.get('sym'));
        },

        onCheckDataAvailability: function onCheckDataAvailability() {
            var corporateActObj = priceService['default'].capitalActionsDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage);
            var dividendListForBarChart = [];
            var dividendYieldListForBarChart = [];
            var ohlcSeries = priceService['default'].ohlcDS.getOHLCSeries(this.get('exg'), this.get('sym'), chartConstants['default'].ChartCategory.History);

            //filter Cash Dividents
            var divdntList = [];
            var yearSet = new Set();
            corporateActObj.capitalAction.forEach(function (item) {
                if (item.typeId == 7) {
                    divdntList.push(item);
                    yearSet.add(utils['default'].formatters.getYearComponent(item.coDate).toString());
                }
            });
            var yearList = Array.from(yearSet);
            yearList.reverse();
            //Calculations of Dividend and dividend Yield for bar chart
            var divHis = [];
            yearList.forEach(function (dateInChart) {
                dateInChart = parseInt(dateInChart);
                var curDividend = 0;
                var divYield = 0;
                var first_date = new Date(dateInChart, 0, 1);
                var last_date = new Date(dateInChart, 12, 0);
                var historyData = ohlcSeries.queryPointsForRange(first_date.getTime(), last_date.getTime());

                divdntList.forEach(function (dividend) {
                    if (dividend.acStatus === 'Executed') {

                        var dividendDate = utils['default'].formatters.convertStringToDate(dividend.coDate, 0);
                        if (dividendDate != '' && dividendDate != undefined) {
                            if (dateInChart === dividendDate.getFullYear()) {
                                divHis.push(dividend);
                                curDividend = curDividend + parseFloat(dividend.dividend_amount);
                            }
                        }
                    }
                });
                if (historyData.length > 0) {
                    divYield = curDividend / historyData[historyData.length - 1].Close * 100;
                    dividendListForBarChart.push(curDividend);
                    dividendYieldListForBarChart.push(parseFloat(divYield.toFixed(3)));
                }
            });

            this.set("divHis", divHis);

            this.set("yearListForBarChart", yearList);
            this.set("dividendYieldListForBarChart", dividendYieldListForBarChart);
            this.set("dividendListForBarChart", dividendListForBarChart);
        },
        _downloadHistory: function _downloadHistory() {
            var that = this;
            priceService['default'].downloadHistoryOHLCData({
                exchange: this.get('exg'),
                symbol: this.get('sym'),
                begin: undefined,

                reqSuccessFn: function reqSuccessFn() {},
                reqFailureFn: function reqFailureFn() {
                    if (that.onErrorFn && Ember['default'].$.isFunction(that.onErrorFn)) {
                        that.onErrorFn();
                    }
                    utils['default'].logger.logDebug('Data Unavailable For History');
                }
            });
        },

        _loadCapitalActionPage: function _loadCapitalActionPage() {
            var that = this;
            var searchParams = this.get('searchParams');
            if (!searchParams) {
                var searchPara = {
                    pageSize: this.get('pageSize'),
                    pageIdx: 0,
                    actType: this.get('actTypeId'),
                    custView: this.get('customView'),
                    idList: this.get('typeIds')

                };
                this.set('searchParams', searchPara);
                this.set('current', 1);
            }

            var corporateAction = priceService['default'].capitalActionsDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, priceConstants['default'].DataTypes.CorporateActionDataStore.CapitalActionInfo, that.get('searchParams'), function () {
                that.onDataSuccess();
            }, function () {
                that.onDataError();
            }, this.get('isCachedData')).capitalAction;
        },

        onLanguageChanged: function onLanguageChanged() {
            this.onPrepareData();
        },
        setErrorMessage: (function () {
            if (this.get('filteredContent').length > 0) {
                this.hideDataErrorMessage();
            } else if (!this.get('isLoading')) {
                this.showDataErrorMessage();
                this.set('errorMessage', this.get('app').lang.messages.dataNotAvailable);
            }
        }).observes('filteredContent'),

        onClearData: function onClearData() {
            priceService['default'].removeSymbolRequest(this.get('exg'), this.get('sym'));
        },

        filteredContent: (function () {
            var content = this.get('arrangedContent');
            if (content) {
                return content;
            }
        }).property('arrangedContent'),

        setData: (function () {
            var chartContent = [{
                name: 'Dividend',
                type: 'column',
                yAxis: 1,
                color: "#dae2e7",
                data: this.get('dividendListForBarChart'),
                tooltip: {
                    valueSuffix: 'SAR'
                }

            }, {
                name: 'Dividend Yield',
                type: 'spline',
                data: this.get('dividendYieldListForBarChart'),
                tooltip: {
                    valueSuffix: '%'
                }
            }];

            this.set('chartData', chartContent);
        }).observes('dividendListForBarChart', 'dividendYieldListForBarChart'),

        setOptions: (function () {
            if (this.get("yearListForBarChart").length > 0) {
                this.set("optionsCounter", true);
            }
        }).observes("yearListForBarChart"),

        chartOptions: (function () {
            return {
                chart: {
                    width: 1100,
                    backgroundColor: "#fff",
                    zoomType: 'xy'
                },
                title: {
                    text: ''
                },
                exporting: {
                    enabled: false
                },
                // subtitle: {
                //   text: 'Source: WorldClimate.com'
                // },
                xAxis: [{
                    categories: this.get("yearListForBarChart"),
                    crosshair: true
                }],
                yAxis: [{ // Primary yAxis
                    labels: {
                        format: '{value} SAR',
                        style: {
                            color: 'blue'
                        }
                    },
                    title: {
                        text: 'Dividend',
                        style: {
                            color: '#d3d3d3'
                        }
                    }
                }, { // Secondary yAxis
                    title: {
                        text: 'Dividend Yeild',
                        style: {
                            color: 'black'
                        }
                    },
                    labels: {
                        format: '{value} %',
                        style: {
                            color: 'black'
                        }
                    },
                    opposite: true
                }],
                tooltip: {
                    shared: true,
                    useHtml: true,
                    formatter: function formatter() {
                        return 'year : <b>' + this.points[0].x + '</b> <br />' + 'Dividend : <b>' + this.points[0].y + ' SAR </b> <br />' + 'Dividend Yield <sub> * </sub> : <b>' + this.points[1].y + ' % </b>';
                    }
                },
                legend: {
                    layout: 'vertical',
                    align: 'left',
                    x: 120,
                    verticalAlign: 'top',
                    y: 100,
                    floating: true,
                    backgroundColor: 'rgba(255,255,255,0.25)'
                }

            };
        }).property(),

        actions: {
            exportTableToExcel: function exportTableToExcel() {
                var tableToExport;

                tableToExport = document.getElementById('divHis');

                utils['default'].exportData.exportToExcel(tableToExport);
            },

            exportToPng: function exportToPng() {

                utils['default'].exportData.exportToPng(document.getElementById("divHis"));
            },
            exportToPDF: function exportToPDF(event) {
                utils['default'].exportData.exportToPdf(document.getElementById("divHis"));
            }
        },

        chartMode: (function () {
            return '';
        }).property()

    });

});