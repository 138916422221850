define('ir-app/models/price/price-data-stores/announcements-data-store', ['exports', 'ember', 'ir-app/models/price/price-business-entities/announcement', 'ir-app/models/price/price-constants', 'ir-app/utils/web-storage', 'ir-app/models/price/price-service', 'ir-app/utils/utils'], function (exports, Ember, announcement, PriceConstants, WebStorage, priceService, utils) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        store: {},
        storeArray: Ember['default'].A(),
        storeMapBySymbolExchange: {},
        isNewItemsAvailable: true,
        annCacheTimerID: undefined,
        contentSearchStore: Ember['default'].A(),
        searchStore: {},
        annCount: undefined,

        createAnnouncement: function createAnnouncement(annsId) {
            var that = this;
            var currentStore = that.get('store');

            if (!currentStore[annsId]) {
                var annObj = this.getAnnouncement(annsId);
                var arr = that.get('storeArray');

                arr.pushObject(annObj);
                that.isNewItemsAvailable = true;

                // Initiate the timer for caching. At least n items needs to be there for caching
                if (!that.get('annCacheTimerID')) {
                    var timerID = setTimeout(function () {
                        that.cacheAnnouncements(PriceConstants['default'].TimeIntervals.AnnouncementCachingInterval * 30);
                    }, PriceConstants['default'].TimeIntervals.AnnouncementCachingInterval);

                    that.set('annCacheTimerID', timerID);
                }
            }

            return currentStore[annsId];
        },

        addToOtherCollections: function addToOtherCollections(annObj) {
            var key = utils['default'].keyGenerator.getKey(annObj.exg, annObj.sym);
            var currentStore = this.get('storeMapBySymbolExchange');
            var annCollectionBySym = currentStore[key];

            if (annCollectionBySym) {
                // Add to announcement collection which is managed for symbol
                annCollectionBySym.pushObject(annObj);
            }
        },

        getAnnouncement: function getAnnouncement(annId) {
            var currentStore = this.get('store');
            var annObj = currentStore[annId];

            if (!annObj) {
                annObj = announcement['default'].create({
                    id: annId
                });

                currentStore[annId] = annObj;
            }

            return annObj;
        },

        cacheAnnouncements: function cacheAnnouncements(nextCachingInterval) {
            // This function will cache the top n items, which will be used for next time loading.
            // Caching will periodically check and update to have the latest ones in the cash
            var that = this;

            // Flag for validating if there are new items available
            if (that.isNewItemsAvailable) {
                var arr = that.get('storeArray');
                var arrForCache = arr.slice(Math.max(arr.get('length') - PriceConstants['default'].AnnouncementNewsCashSize, 0));

                WebStorage['default'].addObject(WebStorage['default'].getKey(utils['default'].Constants.CacheKeys.LatestAnnouncements), arrForCache, utils['default'].Constants.StorageType.Local);
                that.isNewItemsAvailable = false;
            }

            // Next execution
            var timerID = setTimeout(function () {
                that.cacheAnnouncements(PriceConstants['default'].TimeIntervals.AnnouncementCachingInterval * 30);
            }, nextCachingInterval);
            that.set('annCacheTimerID', timerID);
        },

        getAnnCollectionBySymbol: function getAnnCollectionBySymbol(exchange, symbol) {
            var key = utils['default'].keyGenerator.getKey(exchange, symbol);
            var currentStore = this.get('storeMapBySymbolExchange');
            var annCollectionBySym = currentStore[key];

            if (!annCollectionBySym) {
                annCollectionBySym = Ember['default'].A([]);
                currentStore[key] = annCollectionBySym;
            }

            return annCollectionBySym;
        },

        loadCachedStore: function loadCachedStore() {
            var arrCache = WebStorage['default'].getObject(WebStorage['default'].getKey(utils['default'].Constants.CacheKeys.LatestAnnouncements));

            if (arrCache) {
                // Loop through it and populate the store
                var len = arrCache.length;

                for (var i = 0; i < len; i++) {
                    var annObj = arrCache[i];

                    if (annObj && annObj.hed) {
                        var newAnnObj = this.createAnnouncement(annObj.id);
                        newAnnObj.setData(annObj);
                    }
                }
            }
        },

        removeAnnCollectionBySymbol: function removeAnnCollectionBySymbol(exchange, symbol) {
            var key = utils['default'].keyGenerator.getKey(exchange, symbol);
            var currentStore = this.get('storeMapBySymbolExchange');

            currentStore[key] = null;
        },

        getContentSearchResult: function getContentSearchResult() {
            return this.get('contentSearchStore');
        },

        getAnnouncementSearchCollection: function getAnnouncementSearchCollection(key) {
            var searchStore = this.get('searchStore');

            if (!searchStore[key]) {
                searchStore[key] = Ember['default'].A([]);
            }

            return searchStore[key];
        },

        removeAnnouncementSearchCollection: function removeAnnouncementSearchCollection(key) {
            var searchStore = this.get('searchStore');

            if (searchStore[key]) {
                searchStore[key] = null;
            }
        },

        filterContentSearchResults: function filterContentSearchResults(searchKey, language, isEnabledAnnSearch, isEnabledNewsSearch, notifyFn) {
            this.get('contentSearchStore').clear();

            if (isEnabledAnnSearch) {
                priceService['default'].sendAnnouncementSearchRequest({
                    searchKey: searchKey,
                    language: language,
                    pageSize: PriceConstants['default'].AnnouncementSearchPageSize,

                    reqSuccessFn: function reqSuccessFn() {},

                    reqFailureFn: function reqFailureFn() {
                        notifyFn();
                    }
                }, this.get('contentSearchStore'));
            }

            if (isEnabledNewsSearch) {
                priceService['default'].sendNewsSearchRequest({
                    searchKey: searchKey,
                    language: language,
                    pageSize: PriceConstants['default'].NewsSearchPageSize,

                    reqSuccessFn: function reqSuccessFn() {},

                    reqFailureFn: function reqFailureFn() {
                        notifyFn();
                    }
                });
            }
        },

        setAnnCount: function setAnnCount(annCount) {
            this.set('annCount', annCount);
        },

        getAnnCount: function getAnnCount() {
            return this.get('annCount');
        }
    }).create();

});