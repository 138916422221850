define('ir-app/models/price/price-data-stores/corporate-meta-data-store', ['exports', 'ember', 'ir-app/models/price/price-business-entities/corporate-action', 'ir-app/models/price/price-business-entities/corporate-meta', 'ir-app/models/price/price-service', 'ir-app/models/price/price-constants', 'ir-app/utils/utils', 'ir-app/models/price/price-data-stores/base-data-store'], function (exports, Ember, corporateAction, corporateMetaData, priceService, priceConstants, utils, BaseStore) {

    'use strict';

    exports['default'] = BaseStore['default'].extend({

        /**
         * Override base method
         * get stored object... if dataType is available initialize data for the DS object.
         * @param dataType capital or non-capital
         * @param language
         * @param successFn functions to run on success data loading
         * @param errorFn   functions to run on error
         * @returns {*}
         */
        getStoredObject: function getStoredObject(dataType, language, successFn, errorFn) {
            var key = this.getKey([dataType, language]);
            var cachedObj = this.dataStore[key];

            var postSuccess = function postSuccess() {
                cachedObj.metaObj[dataType].isAvailable = true;
                cachedObj.metaObj[dataType].isRequested = false;
                var successFns = cachedObj.metaObj[dataType].successFns;

                Ember['default'].$.each(successFns, function (index, sFn) {
                    if (Ember['default'].$.isFunction(sFn)) {
                        sFn();
                    }
                });
                cachedObj.metaObj[dataType].successFns = [];
            };

            var error = function error() {
                cachedObj.metaObj[dataType].isAvailable = false;
                cachedObj.metaObj[dataType].isRequested = false;
                var errorFns = cachedObj.metaObj[dataType].errorFns;

                Ember['default'].$.each(errorFns, function (index, eFn) {
                    if (Ember['default'].$.isFunction(eFn)) {
                        eFn();
                    }
                });
                cachedObj.metaObj[dataType].errorFns = [];
            };

            if (!cachedObj) {
                cachedObj = this.createStoreCacheObj(dataType, language);
            }

            if (dataType === undefined) {
                // no data type defined. Assume no data initialization is needed.
                return cachedObj.dataObj;
            }

            if (cachedObj.metaObj[dataType].isAvailable) {
                if (Ember['default'].$.isFunction(successFn)) {
                    successFn();
                }
            } else {
                // data not available state
                this.addCallbackFunctions(cachedObj, dataType, successFn, errorFn);

                if (!cachedObj.metaObj[dataType].isRequested) {
                    // data not available and already NO earlier request is processing
                    cachedObj.metaObj[dataType].isRequested = true;
                    switch (dataType) {
                        case priceConstants['default'].DataTypes.CorporateActionDataStore.EquityCapital:
                            // capital Action
                            priceService['default'].sendCapitalOrNonCapitalRequest(priceConstants['default'].DataTypes.CorporateActionDataStore.EquityCapital, language, postSuccess, error);
                            break;

                        case priceConstants['default'].DataTypes.CorporateActionDataStore.NonCapital:
                            // non capital Action
                            priceService['default'].sendCapitalOrNonCapitalRequest(priceConstants['default'].DataTypes.CorporateActionDataStore.NonCapital, language, postSuccess, error);
                            break;

                        default:
                            utils['default'].logger.logError('Unsupported corporate-meta.js-store Data Type: ' + dataType);
                            break;
                    }
                }
            }

            return cachedObj.dataObj;
        },

        /**
         * Override Method
         * @param dataType
         * @param language
         */
        createStoreCacheObj: function createStoreCacheObj(dataType, language) {
            var key = this.getKey([dataType, language]);
            var storeCacheObj = {
                dataObj: {},
                metaObj: {}
            };

            storeCacheObj.dataObj = corporateAction['default'].create({});

            var corporateDataTypes = priceConstants['default'].DataTypes.CorporateActionDataStore;
            this.initMetaData(storeCacheObj, corporateDataTypes);
            this.dataStore[key] = storeCacheObj;
            return storeCacheObj;
        },

        createCorporateMetaData: function createCorporateMetaData() {
            return corporateMetaData['default'].create();
        }

    }).create();

});