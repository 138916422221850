define('ir-app/models/shared/shared-protocols/web-socket/socket-response-handler', ['exports', 'ember', 'ir-app/models/price/price-constants', 'ir-app/utils/utils'], function (exports, Ember, PriceConstants, utils) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        callbacks: undefined,
        inputQueue: undefined,
        processTimer: undefined,
        onSocketReady: undefined,
        previousFrame: '',

        /* *
         * Executes immediately a response comes from the server
         * @param response Response message
         * @param onSocketReady Callback function to notify whether communication path established for data transfer
         */
        onMessage: function onMessage(response, onSocketReady) {
            this.inputQueue.enqueue(response);
            this.onSocketReady = onSocketReady; // TODO: [Bashitha] Find a way to pass only once at initialization
        },

        /* *
         * Processes response from the server
         */
        processResponse: function processResponse() {
            while (this.inputQueue.getLength() > 0) {
                var frameBuffer = this.inputQueue.dequeue();

                utils['default'].logger.logDebug2('Received frame : ' + frameBuffer);
                utils['default'].logger.logDebug2('Previous frame : ' + this.previousFrame);

                if (this.previousFrame.length > 0) {
                    frameBuffer = this.previousFrame + frameBuffer;
                    this.previousFrame = '';
                }

                utils['default'].logger.logDebug2('Appended frame : ' + frameBuffer);

                while (true) {
                    var index = frameBuffer.indexOf('{');

                    // Below is a rare case where the read buffer is terminated from a message length digits
                    // Eg: OnMessage frame : 10{xxxxxxxx}5
                    // 5 may not be the length of the next frame. If could be 5xx...
                    // To handle this we need to skip processing immediately and move to the next OnMessage event to read the
                    // rest of the buffer
                    if (index === -1) {
                        this.previousFrame = frameBuffer;
                        break;
                    }

                    var frameLength = parseInt(frameBuffer.substr(0, index), 10);

                    // To extract and process a single frame the single frame length must me greater than the total frame length.
                    // Here the index is added when checking the lengths because the frame length indicating digits are not
                    // considered for actual frame length.
                    // Eg 01: 5{123} - In this buffer frame length is 5 but the total buffer length is 6.
                    // So if this is applied to below logic the if condition would be
                    // if (6 < 5+1) where it is a possible case for processing. So the else part would be executed
                    //
                    // Eg 01: 5{123 - In this buffer frame length is 5 but the total buffer length is also 5.
                    // so the if logic would be if (5 < 5 + 1) where this logic becomes true as we cannot process this frame since
                    // the latter '}' is missing. Hence we need to wait till the next OnMessage event and append this part at the
                    // beginning of next read buffer.
                    if (frameBuffer.length < frameLength + index) {
                        this.previousFrame = frameBuffer;
                        break;
                    } else {
                        var singleFrame = frameBuffer.substr(index, frameLength);
                        frameBuffer = frameBuffer.slice(index + frameLength);

                        utils['default'].logger.logDebug('Frame : ' + singleFrame);

                        try {
                            var frameMessage = JSON.parse(singleFrame);

                            try {
                                this._processMessage(frameMessage, this.onSocketReady);
                            } catch (e) {
                                utils['default'].logger.logError('Response processing failed : ' + e);
                            }
                        } catch (e) {
                            utils['default'].logger.logError('Json parse failed : ' + e);
                        }

                        // If there are no remainder we are done with current read. So exit the loop and prepare for the next
                        // OnMessage event... :)
                        if (frameBuffer.length === 0) {
                            break;
                        }
                    }
                }
            }

            var that = this;

            setTimeout(function () {
                that.processResponse();
            }, PriceConstants['default'].TimeIntervals.WebSocketInQueueProcessingInterval);
        }
    });

});