define('ir-app/components/stk-specific-components/high-chart/basic-candle-chart', ['exports', 'ember', 'ir-app/components/stk-specific-components/high-chart/dynamic-high-chart', 'ir-app/utils/utils'], function (exports, Ember, DynamicHighChart, utils) {

    'use strict';

    /**
     * Created by banushas on 3/22/2016.
     */

    exports['default'] = Ember['default'].Component.extend({
        layoutName: 'components/stk-specific-components/high-chart/basic-candle-chart',
        chartOHLCDataArray: [],
        minValue: -1,
        maxValue: -1,
        stock: {},

        chartMode: (function () {
            return 'StockChart';
        }).property(),

        chartOptions: (function () {
            var that = this;
            return {
                chart: {
                    height: 180
                },
                xAxis: {
                    gridLineWidth: '0'
                },
                yAxis: [{
                    labels: {
                        enabled: false
                    },
                    title: {
                        text: null
                    },
                    gridLineWidth: '0'
                }],

                rangeSelector: {
                    enabled: false
                },
                navigator: {
                    enabled: false
                },
                scrollbar: {
                    enabled: false
                },
                exporting: {
                    enabled: false
                },
                tooltip: {
                    useHTML: true,
                    formatter: function formatter() {
                        var s = '';
                        s += '<b>' + utils['default'].formatters.formatDateToDisplayOnlyDate(new Date(this.x)) + '</b><br />';
                        this.points.forEach(function (point) {
                            s += '<span>Open</span>: ' + utils['default'].formatters.formatNumber(point.point.open, that.get('stock').deci) + '<br/><span>High</span>: ' + utils['default'].formatters.formatNumber(point.point.high, that.get('stock').deci) + '<br/><span>Low</span>: ' + utils['default'].formatters.formatNumber(point.point.low, that.get('stock').deci) + '<br/><span>Close</span>: ' + utils['default'].formatters.formatNumber(point.point.close, that.get('stock').deci) + '<br/>';
                        });
                        return s;
                    }
                }
            };
        }).property(),

        chartData: (function () {
            var that = this;
            return [{
                type: 'candlestick',
                key: ['x', 'open', 'high', 'low', 'close'],
                data: that.get('chartOHLCDataArray'),
                color: '#e54f50',
                upColor: '#2db168',
                lineColor: '#a82e2d',
                upLineColor: '#148648',
                shadow: true
            }];
        }).property('chartOHLCDataArray', 'chartOHLCDataArray.@each')
    });

    Ember['default'].Handlebars.helper('dynamic-high-chart', DynamicHighChart['default']);

});