define('ir-app/models/price/price-business-entities/company-individuals', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({

        id: '', // Owner Id
        ownerName: '', // Owners Name
        sherPrs: '', // Share parentage
        ownerDesig: '', // Owner Designation
        ownerType: '', // Ownership Type Company/Individual
        ownerComType: '', // Owner Company Type
        prsNa: '', //Percentage not applicable
        compId: '', //Owner Company Id
        currentPct: '', // Owner current Pct
        prevPct: '', // Owner previous Pct
        curntDate: '', // current ownership Date
        prevDate: '', // Previous ownership Date

        individualName: '',
        ownerPct: '',
        residency: '',

        setData: function setData(companyOwnersData) {
            var that = this;

            Ember['default'].$.each(companyOwnersData, function (key, value) {
                that.set(key, value);
            });
        }
    });

});