define('ir-app/controllers/ir-mobile/settings-widgets/general-settings', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/shared/language/language-data-store', 'ir-app/models/shared/shared-data-stores/theme-data-store', 'ir-app/config/app-config', 'ir-app/models/shared/shared-service', 'ir-app/config/user-settings'], function (exports, Ember, BaseController, LanguageDataStore, ThemeDataStore, appConfig, sharedServices, userSettings) {

    'use strict';

    exports['default'] = BaseController['default'].extend({
        arrLang: {},
        arrTheme: {},

        currentLang: null,
        currentTheme: null,

        hostVersion: null,

        currentLanguageIndex: 0,
        currentThemeIndex: 0,
        isThemeSupportEnable: false,

        // Base overrides
        onPrepareData: function onPrepareData() {
            var that = this;

            this.set('arrLang', appConfig['default'].customisation.supportedLanguages);
            this.set('arrTheme', userSettings['default'].customisation.supportedThemes);

            this.set('currentLang', sharedServices['default'].userSettings.currentLanguage);
            this.set('currentTheme', sharedServices['default'].userSettings.currentTheme);
            try {
                if (window.Android) {

                    // if Android Host do nothing
                    // this.set('hostVersion', JSON.parse(localStorage.getItem('host_version')));

                } else {
                        var retrievedObject = localStorage.getItem('host_version');

                        if (JSON.parse(retrievedObject).os === 'ios') {

                            // window.location = 'settings-theme' + '/' + val.code;
                            this.set('hostVersion', JSON.parse(retrievedObject).version);
                        }
                    }
            } catch (err) {

                this.set('hostVersion', 'WEB');
            }
            Ember['default'].$.each(that.get('arrLang'), function (i, val) {
                if (that.get('currentLang') === val.code) {
                    that.set('currentLanguageIndex', i);
                }
            });

            Ember['default'].$.each(that.get('arrTheme'), function (i, val) {
                if (that.get('currentTheme') === val.code) {
                    that.set('currentThemeIndex', i);
                }
            });

            if (userSettings['default'].customisation.supportedThemes.length > 1) {
                this.set('isThemeSupportEnable', true);
            }
        },

        currentLanguageIndexChanged: (function () {
            var that = this;

            Ember['default'].$.each(that.get('arrLang'), function (i, val) {
                if (that.get('currentLanguageIndex') === i) {
                    LanguageDataStore['default'].changeLanguage(val.code);
                }
            });
        }).observes('currentLanguageIndex'),

        currentThemeIndexChanged: (function () {
            var that = this;

            Ember['default'].$.each(that.get('arrTheme'), function (i, val) {
                if (that.get('currentThemeIndex') === i) {
                    ThemeDataStore['default'].changeTheme(val.code);
                    ThemeDataStore['default'].changeThemeColorObject(val.code);
                    try {
                        if (window.Android) {

                            // if Android Host do nothing

                        } else {
                                var retrievedObject = localStorage.getItem('host_version');

                                if (JSON.parse(retrievedObject).os === 'ios') {

                                    window.location = 'settings-theme' + '/' + val.code;
                                }
                            }
                    } catch (error) {

                        // if error
                    }
                }
            });
        }).observes('currentThemeIndex')

    });

});