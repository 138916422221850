define('ir-app/controllers/ir-widgets/document-widget/document-file', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service'], function (exports, Ember, BaseController, PriceService, SharedService) {

    'use strict';

    exports['default'] = BaseController['default'].extend({
        documentUrls: [],
        documentArray: '',
        Categories: [],
        document: [],

        onPrepareData: function onPrepareData() {
            this.set('documentUrls', []);
            this.sendDocRequest();
        },
        sendDocRequest: function sendDocRequest() {
            var that = this;
            var exg = this.get('exg');
            var sym = this.get('sym');
            var lan = SharedService['default'].userSettings.currentLanguage;
            PriceService['default'].documentDS.getStoredObject(exg, sym, lan, function (data) {
                that.viewDocuments(data);
            }, function () {
                console.log("Error in Document Response");
            });
        },

        viewDocuments: function viewDocuments(data) {
            var that = this;
            var count = 0;
            Ember['default'].$.each(data, function (index, val) {
                if (!that.get('Categories').contains(val.Category)) {
                    that.get('Categories').pushObject(val.Category);
                }
            });
            Ember['default'].$.each(that.get('Categories'), function (ind, value) {
                that.get('documentUrls').push({});
                var docCategory = value;
                var docArray = [];
                Ember['default'].$.each(data, function (index, val) {
                    var docObject = { type: '', url: '', title: '' };
                    if (val.Category === value) {
                        docObject.type = val.Type;
                        docObject.url = val.Url;
                        docObject.title = val.Title;
                        docArray.push(docObject);
                    }
                });
                Ember['default'].set(that.get('documentUrls')[count], 'Category', docCategory);
                Ember['default'].set(that.get('documentUrls')[count], 'Urls', docArray);
                count++;
            });
            that.set('documentArray', that.get('documentUrls'));
        },

        onLanguageChanged: function onLanguageChanged() {
            this.onPrepareData();
        }

    });

});