define('ir-app/models/price/price-business-entities/search-result-item', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        sym: '', // Symbol
        lDes: '', // Long Description
        sDes: '', // Short Description
        dSym: '', // Display Symbol
        exg: '', // Exchange
        inst: '', // Instrument Type
        ast: '', // Asset Type
        type: -1, // Search result type
        isDisLDes: false, // Display long description

        setData: function setData(message) {
            var that = this;

            Ember['default'].$.each(message, function (key, value) {
                that.set(key, value);
            });
        }
    });

});