define('ir-app/components/stk-specific-components/financial-chart-comparison', ['exports', 'ember', 'ir-app/utils/utils'], function (exports, Ember, utils) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({

        minValue: -1,
        maxValue: -1,
        minimumChartHeight: 170,

        handleResize: function handleResize() {
            var that = this;
            try {
                if (document.getElementById('chart1') !== null) {
                    var topHeight = document.getElementById('chart1').getBoundingClientRect().top;
                    var fullHeight = Ember['default'].$(window).height();
                    var expectedChartHeight = fullHeight - topHeight - 3; // 3 is a bottom margin
                    var chartHeight = (expectedChartHeight > that.minimumChartHeight ? expectedChartHeight : that.minimumChartHeight) / 2;
                    that.set('chartContainerHeight', chartHeight);
                    that.set('chartContainerWidth', document.getElementById('chart1').offsetWidth);
                }
            } catch (e) {
                utils['default'].logger.logError('[basic chart widget] handleResize() ' + e);
            }
        },

        handleInitialResize: function handleInitialResize() {
            var that = this;
            setTimeout(function () {
                // give 1s delay to let other components to load.
                that.handleResize();
            }, 1);
        },

        bindResizeEvent: (function () {
            var that = this;
            Ember['default'].$(window).on('resize', Ember['default'].run.bind(this, this.handleResize));
            Ember['default'].$(window).ready(function () {
                Ember['default'].$('#transbox').bind('DOMSubtreeModified', function () {
                    // Some times it is bit delay to load the top description panel(#transbox) of the home page
                    that.handleResize();
                });
            });
            Ember['default'].$(window).ready(Ember['default'].run.bind(this, this.handleInitialResize));
        }).on('init'),

        formatter: function formatter(val) {
            var result = val;
            if (Math.abs(val) > 1000000) {
                result = Math.floor(val / 1000000) + ' M';
            } else if (Math.abs(val) > 1000) {
                result = Math.floor(val / 1000) + ' K';
            }
            return result;
        },

        layoutName: 'components/stk-specific-components/financial-chart1',
        chart: {},
        elementId: '',
        dataSet: {},
        dataSet2: {},

        xDataSet: {},
        chartOptions: {

            chart: {
                type: 'column',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                marginTop: 30

            },

            title: {
                useHTML: true,
                align: 'left',
                verticalAlign: 'top',
                y: 10,
                style: {
                    fontWeight: 'bold',
                    fontSize: '15px'
                }
            },
            subtitle: {
                text: null
            },
            xAxis: {
                title: {
                    text: null
                },
                tickLength: 0,

                labels: {
                    enabled: false
                }
            },
            yAxis: {
                title: {
                    text: '(in Millions)'
                },
                labels: {
                    overflow: 'justify', enabled: true,
                    formatter: function formatter() {
                        return this.value / 1000000000 + 'k';
                    }
                }

            },
            tooltip: {
                enabled: true,
                formatter: function formatter() {
                    return 'Value:  <b>' + this.y / 1000000 + 'M' + '</b>';
                }
            },
            plotOptions: {

                bar: {

                    dataLabels: {
                        enabled: true, style: { fontSize: '10px', align: 'left' }

                    }

                }
            },
            legend: {
                layout: 'horizontal',
                align: 'right',
                verticalAlign: 'top',
                x: 0,
                y: 20,
                floating: true,
                borderWidth: 1,
                shadow: true
            },

            credits: {
                enabled: false
            },
            series: [{
                data: [], showInLegend: false
            }, {
                data: []
            }, {
                data: []
            }, {
                data: []
            }, {
                data: []
            }]

        },
        generateChart: (function () {

            Array.prototype.max = function () {
                return Math.max.apply(null, this);
            };

            Array.prototype.min = function () {
                return Math.min.apply(null, this);
            };

            var chart = this.get('chartOptions');
            var currentDataSet = this.get('dataSet');
            if (currentDataSet && !currentDataSet[1] && !currentDataSet[2] && !currentDataSet[3] && !currentDataSet[4]) {
                var destroyChart = Ember['default'].$('#' + this.get('elementId')).highcharts();
                if (destroyChart) {
                    destroyChart.destroy();
                } else {
                    return;
                }
            }

            chart.series[0]['data'] = currentDataSet.slice(1, 2).map(Number);
            chart.series[1]['data'] = currentDataSet.slice(2, 3).map(Number);
            chart.series[2]['data'] = currentDataSet.slice(3, 4).map(Number);
            chart.series[3]['data'] = currentDataSet.slice(4, 5).map(Number);
            chart.series[4]['data'] = currentDataSet.slice(5, 6).map(Number);

            if (currentDataSet.slice(2, 3)[0] === undefined) {
                chart.series[1].showInLegend = false;
            } else {
                chart.series[1].showInLegend = true;
            }
            if (currentDataSet.slice(3, 4)[0] === undefined) {
                chart.series[2].showInLegend = false;
            } else {
                chart.series[2].showInLegend = true;
            }
            if (currentDataSet.slice(4, 5)[0] === undefined) {
                chart.series[3].showInLegend = false;
            } else {
                chart.series[3].showInLegend = true;
            }
            if (currentDataSet.slice(5, 6)[0] === undefined) {
                chart.series[4].showInLegend = false;
            } else {
                chart.series[4].showInLegend = true;
            }
            // enable/disable legend
            chart.legend.enabled = currentDataSet.slice(6, 7)[0];

            chart.series[0]['name'] = this.get('xDataSet')[0];

            if (this.get('xDataSet')[1] === undefined) {
                chart.series[1]['name'] = '-';
            } else {
                chart.series[1]['name'] = this.get('xDataSet')[1];
            }
            if (this.get('xDataSet')[2] === undefined) {
                chart.series[2]['name'] = '-';
            } else {
                chart.series[2]['name'] = this.get('xDataSet')[2];
            }
            if (this.get('xDataSet')[3] === undefined) {
                chart.series[3]['name'] = '-';
            } else {
                chart.series[3]['name'] = this.get('xDataSet')[3];
            }
            if (this.get('xDataSet')[4] === undefined) {
                chart.series[4]['name'] = '-';
            } else {
                chart.series[4]['name'] = this.get('xDataSet')[4];
            }

            //  }
            var dataArray = chart.series[0]['data'];

            chart.chart.height = this.get('chartContainerHeight');
            chart.title['text'] = currentDataSet[0];

            var prevY = 0;
            chart.plotOptions.bar['dataLabels'].formatter = function () {
                var result;
                if (prevY === 0) {
                    result = '';
                    prevY = this.y;
                } else {
                    result = utils['default'].formatters.formatNumber((this.y - prevY) / Math.abs(prevY) * 100, 2) + ' %';

                    prevY = this.y;
                }
                return result;
            };

            var cat = this.get('xDataSet').slice(1);
            var appendedCat = [];
            for (var i = 0; i < 4; ++i) {

                var temp = cat[i] + '<br>' + utils['default'].formatters.inMillions(dataArray[i], 0);
                appendedCat.push(temp);
            }
            chart.xAxis['categories'] = appendedCat;

            Ember['default'].$('#' + this.get('elementId')).highcharts(chart);
        }).observes('dataSet', 'chartContainerHeight'),

        didInsertElement: function didInsertElement() {
            this._super();
            this.generateChart();
        }
    });

});