define('ir-app/controllers/ir-mobile/quote-widgets/price-ticker', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/models/price/price-constants', 'ir-app/controllers/controller-factory', 'ir-app/models/shared/shared-service'], function (exports, Ember, BaseController, priceService, PriceConstants, ControllerFactory, sharedService) {

    'use strict';

    /* global Queue */

    exports['default'] = BaseController['default'].extend({
        exchange: undefined, // User Default exchange reference
        fullMarket: undefined, // ticker setting : to keep full market tickers
        tickerSymDisplayField: 'sym',

        // Ticker related settings
        previousRunTime: undefined, // last run time
        tickerIndex: 0, // global variable to keep last used index of ticker list
        noOfItemsToAddToQ: 50, // number of symbols to add to Q in one loop
        numberOfIteration: 0, // number of iterations

        timeInterval: {
            OneSecondInMillis: 1500,
            OneMinuteInMillis: 60000
        },

        /* *
         * Warning : Change css style according to millisecondsToTransformOneItem
         * Default settings
         * @type {{elementQueueMaxSize: number, numberOfHtmlElementsInTicker: number, itemWidth: number, millisecondsToTransformOneItem: number, millisecondsToWait: number}}
         */
        settings: {
            elementQueueMaxSize: 50,
            itemWidth: 225,
            itemWidthStyle: 'width: 225px', // Added this to remove "Binding style" warning. Update this if itemWidth is changed
            millisecondsToWait: 2500,
            singleTickerWidth: 2700,
            singleTickerItemCount: 12
        },

        elementQueue: undefined,

        // Ticker div 1 related properties
        tickerOneItems: Ember['default'].A(),
        tickerOneArrayIndex: 0,
        tickerOneLoaded: false,

        // Ticker div 2 related properties
        tickerTwoItems: Ember['default'].A(),
        tickerTwoArrayIndex: 0,
        tickerTwoLoaded: false,

        visibleTicker: 2,
        activeTicker: 1,
        isFirstRound: true,
        addedElementCount: 0,

        isTickerRunning: true, // Play or pause ticker
        isAnimationStarted: false, // To stabilise the animation

        isFirstTimeLoading: true, // to identify the initial data loading moment

        onPrepareData: function onPrepareData() {
            this._clearTicker();
            var exchange = priceService['default'].exchangeDS.getExchange(this.get('exg'));
            priceService['default'].addExchangeRequest(this.get('exg'));
            this.set('exchange', exchange);
            this._loadSymbolMeta();
        },

        _loadSymbolMeta: function _loadSymbolMeta() {
            var that = this;
            priceService['default'].downloadParamsSymbolMasterForExchange({
                exchange: that.get('exchange').exg,
                language: sharedService['default'].userSettings.currentLanguage,
                successFn: function successFn() {
                    that._preparePriceTicker();
                },
                errorFn: function errorFn() {
                    that.onError();
                }
            });
        },

        _preparePriceTicker: function _preparePriceTicker() {

            // get and set full market symbols
            this._setFullMarketSymbols();
            this._initPriceTicker();
            this._initPriceTickerData();
        },

        /* *
         * Initializing ticker
         */
        _initPriceTicker: function _initPriceTicker() {
            this.elementQueue = new Queue(this.settings.elementQueueMaxSize);
            this._moveTicker();
        },

        _clearPriceTicker: function _clearPriceTicker() {
            priceService['default'].removeFullMarketSymbolRequest(this.get('exchange').exg);
            priceService['default'].removeExchangeRequest(this.get('exg'));
        },

        /* *
         * Setting full market tickers to local variable
         */
        _setFullMarketSymbols: function _setFullMarketSymbols() {
            priceService['default'].addFullMarketSymbolRequest(this.get('exchange').exg);
            var store = priceService['default'].stockDS.get('stockMapByExg');
            var exchange = this.get('exchange').exg;

            var fullMarket = store[exchange];
            this.set('fullMarket', fullMarket);
        },

        /* *
         * Initializing price ticker data
         * Run every 1 minute if market is not open
         * Run every 1 seconds if Market open but no data to show in ticker
         * Run every 30 seconds if required elements are added to Q
         */
        _initPriceTickerData: function _initPriceTickerData() {
            var marketTime = this.get('exchange').time;
            var marketStatus = this.get('exchange').stat;

            // Initial sleep time
            var sleepTime = this.timeInterval.OneSecondInMillis;
            var that = this;
            this._addElementsToQ();
            if (marketStatus !== PriceConstants['default'].MarketStatus.Open) {
                this.set('previousRunTime', undefined);
            } else {
                // set last run time after running above function
                this.set('previousRunTime', marketTime);
            }
            // run this every 30 seconds
            if (this._getQueueSize() > 0) {
                sleepTime = this.timeInterval.OneSecondInMillis * 30;
            }
            setTimeout(function () {
                that._initPriceTickerData();
            }, sleepTime);
        },

        /* *
         * @param initEffectiveElementsCount
         */
        _addElementsToQ: function _addElementsToQ(initEffectiveElementsCount) {
            var previousRunTime = this.get('previousRunTime');
            var maxNumberOfIterationPerInvoke = 5;
            var effectiveElementsCount = initEffectiveElementsCount || 0;
            var numberOfIterationPerInvoke = this.get('numberOfIteration');

            var fullMarket = this.get('fullMarket');
            var tickerIndex = this.tickerIndex;

            var noOfTickersInList = fullMarket.length;
            var marketStatus = this.get('exchange').stat;

            // Add 20 elements in each loop or until complete the full list
            while (noOfTickersInList !== tickerIndex && effectiveElementsCount < this.noOfItemsToAddToQ) {
                var stock = fullMarket[tickerIndex];

                if (stock && stock.inst === 0) {
                    // Last traded time of this ticker
                    var ltt = stock.ltt;
                    var isValidStock;

                    if (previousRunTime === undefined) {
                        // This is first time. No need to check last traded time condition
                        // Just add to Q if there are trades
                        isValidStock = stock.trades > 0;
                    } else {
                        isValidStock = stock.trades > 0 && previousRunTime < ltt;
                    }
                    if (isValidStock || this.isFirstTimeLoading) {
                        if (marketStatus !== PriceConstants['default'].MarketStatus.Open) {
                            this._addItemToQueue(stock);
                        } else {
                            this._addItemToQueue(stock);
                        }

                        effectiveElementsCount++;
                    }
                }

                tickerIndex++;
            }
            this.isFirstTimeLoading = false;
            numberOfIterationPerInvoke++;

            // full list is completed
            if (noOfTickersInList === tickerIndex) {
                // reset tickerIndex
                tickerIndex = 0;
            }
            this.set('tickerIndex', tickerIndex);

            // Iterate through the list from the beginning if there is no 20 elements to add
            // Iterate only twice even though there are no enough elements
            if (effectiveElementsCount < this.noOfItemsToAddToQ && numberOfIterationPerInvoke < maxNumberOfIterationPerInvoke) {
                this.set('numberOfIteration', numberOfIterationPerInvoke);
                this.set('tickerIndex', 0);
                this._addElementsToQ(effectiveElementsCount);
            }
        },

        onAfterRender: function onAfterRender() {
            this._setTickerUI();
        },

        _setTickerUI: function _setTickerUI() {
            Ember['default'].$('.tickerDiv').bind('webkitAnimationIteration', this._animationIterationFinished.bind(this));
            Ember['default'].$('.tickerDiv').bind('mozAnimationIteration', this._animationIterationFinished.bind(this));
            Ember['default'].$('.tickerDiv').bind('animationiteration', this._animationIterationFinished.bind(this));
        },

        /* *
         * Callback function for AnimationIteration CSS event to toggle visible ticker property
         */
        _animationIterationFinished: function _animationIterationFinished() {
            if (this.isFirstRound) {
                this.set('isFirstRound', false);
                Ember['default'].$('#tickerTwo').removeClass('first-load');
                Ember['default'].$('#tickerOne').removeClass('first-anim-delay');
                Ember['default'].$('#tickerTwo').removeClass('second-anim-delay');
            }
            Ember['default'].$('#tickerTwo').removeClass('first-load');
            Ember['default'].$('#tickerOne').removeClass('first-anim-delay');
            Ember['default'].$('#tickerTwo').removeClass('second-anim-delay');
            if (this.visibleTicker === 1) {
                this.visibleTicker = 2;
                this.activeTicker = 1;
                this.tickerOneArrayIndex = 0;
                this.tickerOneLoaded = false;
            } else {
                this.visibleTicker = 1;
                this.activeTicker = 2;
                this.tickerTwoArrayIndex = 0;
                this.tickerTwoLoaded = false;
            }
        },

        /* *
         * Add item to queue
         * Removing element before add if queue is already reached the max limit
         * @param itemObject
         */
        _addItemToQueue: function _addItemToQueue(itemObject) {
            var elementQueueLength = this.elementQueue.getLength();

            if (elementQueueLength >= this.settings.elementQueueMaxSize) {
                this.elementQueue.dequeue();
            }

            // adding element to queue
            this.elementQueue.enqueue(itemObject);
        },

        _addItemToQueueWhenMarketClosed: function _addItemToQueueWhenMarketClosed(itemObject) {
            this._moveTickerWhenMarketClosed(itemObject);
        },

        /* *
         * @returns size of queue
         */
        _getQueueSize: function _getQueueSize() {
            return this.elementQueue.getLength();
        },

        _getCssClass: function _getCssClass(value) {
            return value > 0 ? 'fa fa-arrow-up ticker-green' : 'fa fa-arrow-down ticker-red';
        },

        /* *
         * This function is to update ticker div which is not visible by updating respective element in th array
         * @param itemObject item object
         */
        _addItemToTicker: function _addItemToTicker(itemObject) {
            if (this.isFirstRound && !this.tickerTwoLoaded) {
                this._addItemToSingleTicker(itemObject, 2);
            } else if (this.isFirstRound || this.activeTicker !== this.visibleTicker) {
                if (this.activeTicker === 1) {
                    this._addItemToSingleTicker(itemObject, 1);
                } else {
                    this._addItemToSingleTicker(itemObject, 2);
                }
            }
        },

        _addItemToSingleTicker: function _addItemToSingleTicker(itemObject, ticker) {
            var item;
            var tickerArrayIndex = ticker === 1 ? this.tickerOneArrayIndex : this.tickerTwoArrayIndex;
            var tickerItems = ticker === 1 ? 'tickerOneItems' : 'tickerTwoItems';

            if (itemObject) {
                item = this.get(tickerItems).objectAt(tickerArrayIndex);
                this._fillItem(item, itemObject);
            }

            this.set('isAnimationStarted', true);
            this.set('isTickerRunning', true);

            tickerArrayIndex++;
            this.tickerOneArrayIndex = ticker === 1 ? tickerArrayIndex : this.tickerOneArrayIndex;
            this.tickerTwoArrayIndex = ticker === 2 ? tickerArrayIndex : this.tickerTwoArrayIndex;

            if (tickerArrayIndex === this.settings.singleTickerItemCount) {
                this.tickerOneArrayIndex = ticker === 1 ? 0 : this.tickerOneArrayIndex;
                this.tickerTwoArrayIndex = ticker === 2 ? 0 : this.tickerTwoArrayIndex;
                //this.activeTicker = ticker === 1 ? 2 : 1;
                this.tickerOneLoaded = ticker === 1 ? true : false;
                this.tickerTwoLoaded = ticker === 2 ? true : false;
            }

            this.addedElementCount++;
        },

        _fillItem: function _fillItem(item, itemObject) {
            var cssClass = this._getCssClass(itemObject.chg);
            var description = itemObject[this.tickerSymDisplayField];
            var change = itemObject.chg;
            var exchange = itemObject.exg;
            var symbol = itemObject.sym;
            var instrumentType = itemObject.inst;

            if (description && description.length > 6) {
                description = description.substr(0, 6) + '...';
            }

            Ember['default'].set(item, 'des', description);
            Ember['default'].set(item, 'iconClass', cssClass);
            Ember['default'].set(item, 'chg', change);
            Ember['default'].set(item, 'sym', symbol);
            Ember['default'].set(item, 'exg', exchange);
            Ember['default'].set(item, 'inst', instrumentType);
            Ember['default'].set(item, 'itemWidthStyle', instrumentType);
            Ember['default'].set(item, 'obj', itemObject);
        },

        /* *
         *  This function to add items to ticker periodically
         */
        _moveTicker: function _moveTicker() {
            var that = this;
            var tickerUpdateFrequency = this.settings.millisecondsToWait;
            if (this.isFirstRound && !this.tickerTwoLoaded) {
                // speed up data update frequency to fill up visible ticker fast
                tickerUpdateFrequency = 100;
            }
            // need to avoid active ticker repeatively data loading
            if (this.activeTicker === 1 && !this.tickerOneLoaded || this.activeTicker === 2 && !this.tickerTwoLoaded) {
                // get item object from elements queue
                var itemObject = this.elementQueue.dequeue();
                this._addItemToTicker(itemObject);
            }
            setTimeout(function () {
                that._moveTicker();
            }, tickerUpdateFrequency);
        },

        _moveTickerWhenMarketClosed: function _moveTickerWhenMarketClosed(itemObject) {
            // No need to take any action if object is null or empty
            if (itemObject) {
                this._addItemToTicker(itemObject);
            }
        },

        _clearTicker: function _clearTicker() {
            this.set('tickerOneItems', [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
            this.set('tickerTwoItems', [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
        },

        _popUpWidget: function _popUpWidget(tickerItem) {
            var symbolPopupView = ControllerFactory['default'].createController(this.container, 'view:symbol-popup-view');

            // 0 for detail quote
            symbolPopupView.show(0, tickerItem.sym, tickerItem.exg, tickerItem.inst);
        },

        onLanguageChanged: function onLanguageChanged() {
            this.set('isAnimationStarted', false);
            this._clearPriceTicker();
            this._clearTicker();
            this._preparePriceTicker();
        },

        onClearData: function onClearData() {
            this._clearPriceTicker();
        }
    });

    //Ember.Handlebars.helper('getWidth', function (dynamicString) {
    //    if(dynamicString){
    //        return 150 + dynamicString.length*10 ;
    //    }else{
    //        return 150;
    //    }
    //});

});