define('ir-app/controllers/ir-widgets/financial-widgets/client-financials', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/utils/utils', 'ir-app/models/shared/shared-service', 'ir-app/models/price/price-business-entities/fundamentals/fundamental-constants', 'ir-app/models/price/price-business-entities/fundamentals/fundaental-common-functions', 'ir-app/models/price/price-constants', 'ir-app/components/stk-specific-components/financial-chart', 'ir-app/components/stk-specific-components/financial-chart-snapshot'], function (exports, Ember, BaseController, priceService, utils, sharedService, FundamentalConstants, FundamentalCommonFunctions, priceConstants, FinChart, FinancialChart) {

  'use strict';

  /**
   * Created by akilam on 3/23/2017.
   */

  exports['default'] = BaseController['default'].extend({
    viewDropdownListView: [],
    viewDropdownListFromDate: [],
    viewDropdownListToDate: [],
    viewDropdownListPeriods: [],
    keyListDate: undefined,

    styleObject: undefined,
    dataObject: undefined,
    dataKeySet: undefined,

    displayDataObject: {},
    displayStyleObject: Ember['default'].A([]),

    breakDown: FundamentalConstants['default'].BreakDown.CollapsedView,
    isExpand: 2,
    isCalendar: 1,
    isRatio: undefined,

    currentlyDataDisplayingViewType: undefined,
    currentlyDataDisplayingFilterFromDate: undefined,
    currentlyDataDisplayingFilterToDate: undefined,
    currentlyDataDisplayingIsCalendar: 1,

    fromDate: undefined,
    toDate: undefined,
    today: undefined,

    statementType: 'IS',
    filterFromDate: undefined,
    filterToDate: undefined,
    defSelectFrom: undefined,
    defSelectTo: undefined,
    defSelectView: undefined,

    reRender: false,
    isExpandButtonAvailable: true,

    titleObj: undefined,
    numberOfTabs: undefined,

    resetFieldFrom: false,
    resetFieldTo: false,
    lang: '',
    finYearEndMonth: '',

    setTitleIS: false,
    setTitleBS: false,
    setTitleCF: false,
    setTitleR: false,

    customizedBarLabels: 'disable',
    customizedDecimalPlaces: 2,
    customizedPointWidth: 20,
    customizedChartHeight: 200,
    minusValArray: [],
    defaultChartTypes: ['IS', 'BS', 'CF', 'R'],
    isSnapshotView: false,

    dataDisplayFormat: {
      thousands: 1,
      millions: 2
    },

    onPrepareData: function onPrepareData() {

      var keyListView = this.get('keyListView');
      if (keyListView[keyListView.length - 1].view === 1) {
        // setting the currentViewType and viewType according to the keyListView array
        this.set('viewType', 'A');
        this.set('currentlyDataDisplayingViewType', 'A');
      } else if (keyListView[keyListView.length - 1].view === 2) {
        this.set('viewType', 'Q');
        this.set('currentlyDataDisplayingViewType', 'Q');
      } else if (keyListView[keyListView.length - 1].view === 3) {
        this.set('viewType', 'C');
        this.set('currentlyDataDisplayingViewType', 'C');
      } else if (keyListView[keyListView.length - 1].view === 4) {
        this.set('viewType', 'T');
        this.set('currentlyDataDisplayingViewType', 'T');
      }

      if (this.get('enableDefaultDetailedView')) {
        this.set('breakDown', FundamentalConstants['default'].BreakDown.ExpandView);
        this.set('isExpand', 1);
      }
      this.setErrorMessage();
      this.set('lang', sharedService['default'].userSettings.currentLanguage);
      if (this.get('includeSnapshot')) {
        this.set('statementType', 'Snapshot');
      }
      // this.set('statementType', 'IS');
      this.set('numberOfTabs', this._calculateTabs());

      var exchange = this.get('exg');
      var symbol = this.get('sym');
      var view = this.get('currentlyDataDisplayingViewType');
      this.startLoadingProgress();
      var that = this;

      var success = function success() {
        that.set('compObj', priceService['default'].companyProfileDS.getStoredObject(that.get('exg'), that.get('sym'), sharedService['default'].userSettings.currentLanguage, undefined, undefined, undefined));
        that.sendDataRequest(exchange, symbol, view);
      };
      priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, success, undefined, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyBasicInfo);

      if (this.get('activeIS') === 'active') {
        // landing page can be given from configurations.
        this._onTabChange(1);
        this.set('setTitleIS', true);
      } else if (this.get('activeBS') === 'active') {
        this._onTabChange(2);
        this.set('setTitleBS', true);
      } else if (this.get('activeCF') === 'active') {
        this._onTabChange(3);
        this.set('setTitleCF', true);
      } else if (this.get('activeRatios') === 'active') {
        this._onTabChange(4);
        this.set('setTitleR', true);
      }
    },

    onClearData: function onClearData() {
      priceService['default'].removeSymbolRequest(this.get('exg'), this.get('sym'));
    },

    _calculateTabs: function _calculateTabs() {
      var IS = this.get('includeIS') ? 1 : 0;
      var BS = this.get('includeBS') ? 1 : 0;
      var CF = this.get('includeCF') ? 1 : 0;
      var Ratios = this.get('includeRatios') ? 1 : 0;

      switch (IS + BS + CF + Ratios) {
        case 1:
          return 'one';
        case 2:
          return 'two';
        case 3:
          return 'three';
        case 4:
          return 'four';
        default:
          return;
      }
    },

    _defaultSelectionMaker: function _defaultSelectionMaker() {
      this._setDropdownValues();
      var that = this;
      var keys = this.get('keyListView'),
          array = [],
          temp,
          arrObj = [];
      Ember['default'].$.each(keys, function (index, key) {
        temp = that.get('app').lang.labels[key.des];
        arrObj.view = key.view;
        arrObj.des = temp;
        array.push(arrObj);
        arrObj = [];
      });

      var keyListDate = this.get('keyListDate');
      if (!keyListDate) {
        this.set('viewDropdownListFromDate', []);
        this.set('viewDropdownListToDate', []);
        this.set('defSelectFrom', undefined);
        this.set('defSelectTo', undefined);
        this.set('filterFromDate', undefined);
        this.set('filterToDate', undefined);

        this.set('resetFieldFrom', !this.get('resetFieldFrom'));
        this.set('resetFieldTo', !this.get('resetFieldTo'));
        return;
      }

      var keyListView = array;
      keyListView.reverse();
      that.set('viewDropdownListView', keyListView);
      that.set('viewDropdownListFromDate', keyListDate);
      that.set('viewDropdownListToDate', keyListDate);
      that.set('defSelectTo', keyListDate[0]);
      that.set('defSelectView', keyListView[1]);
      if (keyListDate.length < parseInt(this.get('numberOFQuartersDisplayed'))) {
        that.set('filterFromDate', keyListDate[0]);
        that.set('defSelectFrom', keyListDate[0]);
      } else {
        that.set('filterFromDate', keyListDate[that.get('numberOFQuartersDisplayed') - 1].view);
        that.set('defSelectFrom', keyListDate[that.get('numberOFQuartersDisplayed') - 1]);
      }

      that.set('filterToDate', keyListDate[0].view);
      that.set('currentlyDataDisplayingViewType', that.get('viewType'));
      that.set('currentlyDataDisplayingFilterFromDate', that.get('filterFromDate'));
      that.set('currentlyDataDisplayingFilterToDate', that.get('filterToDate'));
    },

    sendDataRequest: function sendDataRequest(exchange, symbol, view) {
      var that = this;
      var postSuccess = function postSuccess() {
        if (!that.get('reRender')) {
          that._defaultSelectionMaker();
        }
        that.dataDisplay(exchange, symbol, view);
        that.stopLoadingProgress();
        that.hideDataErrorMessage();
      };

      var error = function error() {
        that.showDataErrorMessage();
        that.stopLoadingProgress();
      };
      priceService['default'].sendClientFundamentalDataRequest(exchange, symbol, sharedService['default'].userSettings.currentLanguage, 'X', postSuccess, error, '5', this.get('isCalendar'));
    },

    dataDisplay: function dataDisplay(exchange, symbol, view) {
      var filterFrom, filterTo;
      filterFrom = this.get('currentlyDataDisplayingFilterFromDate');
      filterTo = this.get('currentlyDataDisplayingFilterToDate');
      var statType = this.get('statementType');

      //this is for BS, IS, CF and R
      this.set('styleObject', priceService['default'].clientFundamentalDS.getStyleObject(exchange, symbol, statType, view, this.get('isCalendar')));
      this.set('dataObject', priceService['default'].clientFundamentalDS.getDataObject(exchange, symbol, statType, view, this.get('isCalendar')));
      this.hideTab(exchange, symbol, view);

      if (this.get('dataObject')) {
        this.set('dataKeySet', Object.keys(this.get('dataObject')));
        this.filterDataValues(filterFrom, filterTo, this.get('dataObject'), this.get('displayDataObject'));
        this.filterStyleValue();
      }
      if (Object.keys(this.get('displayDataObject')).length <= 0) {
        this.showDataErrorMessage(); // set error message if data is not available
        switch (this.get('statementType')) {
          case 'IS':
            this.set('setTitleIS', false);break;
          case 'BS':
            this.set('setTitleBS', false);break;
          case 'CF':
            this.set('setTitleCF', false);break;
          case 'R':
            this.set('setTitleR', false);break;
        }
      }

      this.set('isSnapshotView', false);

      if (statType == 'Snapshot') {
        this.set('isSnapshotView', true);
        var that = this;
        var arrayLength = this.defaultChartTypes.length;
        for (var i = 0; i < arrayLength; i++) {

          // Do something
          this.set('styleObject', priceService['default'].clientFundamentalDS.getStyleObject(exchange, symbol, this.defaultChartTypes[i], view, this.get('isCalendar')));
          this.set('dataObject', priceService['default'].clientFundamentalDS.getDataObject(exchange, symbol, this.defaultChartTypes[i], view, this.get('isCalendar')));
          this.hideTab(exchange, symbol, view);

          if (this.get('dataObject')) {
            this.set('dataKeySet', Object.keys(this.get('dataObject')));
            this.filterDataValues(filterFrom, filterTo, this.get('dataObject'), this.get('displayDataObject'));
            this.filterStyleValue();
          }
          this.set('arrX', Ember['default'].A());
          this.set('arr1IS', Ember['default'].A());
          this.set('arr2IS', Ember['default'].A());
          this.set('arr3IS', Ember['default'].A());
          this.set('arr4IS', Ember['default'].A());
          this.set('arr1BS', Ember['default'].A());
          this.set('arr2BS', Ember['default'].A());
          this.set('arr1CF', Ember['default'].A());
          this.set('arr2CF', Ember['default'].A());
          this.set('dataSetArr', []);

          switch (this.defaultChartTypes[i]) {
            case 'IS':
              this.setIncomeStatementChartData();
              break;
            case 'BS':
              this.setBalanceSheetChartData();
              break;
            case 'R':
              this.setFinancialRatioChartData();
              break;
            case 'CF':
              this.setCashFlowChartData();
              break;
          }
          // this.setIncomeStatementChartData();
          // this.setBalanceSheetChartData();
          // this.setFinancialRatioChartData();
          // this.setCashFlowChartData();
        }
      }
      // if (statType === 'IS') {
      //     this.setIncomeStatementChartData();
      // }
      // else if (statType === 'BS') {
      //     this.setBalanceSheetChartData();
      // }
      // else if (statType === 'CF') {
      //     this.setCashFlowChartData();
      // }
      // else if (statType === 'FR') {
      //     this.setFinancialRatioChartData();
      // }
      //   }
    },

    hideTab: function hideTab(exchange, symbol, view) {
      var statType = ['IS', 'BS', 'CF', 'R'];
      var isDataAvailable = [];

      for (var i = 0; i < 4; i++) {
        isDataAvailable[i] = priceService['default'].clientFundamentalDS.getDataObject(exchange, symbol, statType[i], view, this.get('isCalendar')) !== undefined;
      }

      this.set('includeIS', isDataAvailable[0]);
      this.set('includeBS', isDataAvailable[1]);
      this.set('includeCF', isDataAvailable[2]);
      this.set('includeRatios', isDataAvailable[3]);
      this.set('includeSnapshot', this.get('includeSnapshot'));
    },

    sortKeySet: function sortKeySet(objArrayOne) {
      var keySetOne = objArrayOne.sort(function (a, b) {
        var yearOne = parseInt(a.split('~')[0]);
        var yearTwo = parseInt(b.split('~')[0]);
        var QOne = parseInt(a.split('~')[1]);
        var QTwo = parseInt(b.split('~')[1]);

        if (yearOne > yearTwo) {
          return -1;
        } else if (yearOne < yearTwo) {
          return 1;
        } else {
          if (QOne > QTwo) {
            return -1;
          } else {
            return 1;
          }
        }
      });
      this.set('dataKeySet', keySetOne);
    },

    isExpandCollapse: (function () {
      this.dataDisplay(this.get('exg'), this.get('sym'), this.get('currentlyDataDisplayingViewType'));
    }).observes('breakDown'),

    /**
     * To get filtered data list in between from date and the to date
     * and set in to the displayDataObject.
     * @param fromDate and toDate
     */

    filterDataValues: function filterDataValues(fromDate, toDate, dataObj, displayDataObj, type) {
      var key;
      var valueObject;
      var keySet = Object.keys(dataObj);

      if (!this.get('includeFromToFields')) {
        if (this.get('currentlyDataDisplayingViewType') === 'A') {
          toDate = keySet[keySet.length - 1];
          if (keySet.length < parseInt(this.get('numberOFYearsDisplayed'))) {
            fromDate = keySet[0];
          } else {
            fromDate = keySet[keySet.length - parseInt(this.get('numberOFYearsDisplayed'))];
          }
        } else {
          toDate = keySet[keySet.length - 1];
          if (keySet.length < parseInt(this.get('numberOFQuartersDisplayed'))) {
            fromDate = keySet[0];
          } else {
            fromDate = keySet[keySet.length - parseInt(this.get('numberOFQuartersDisplayed'))];
          }
        }
      }

      for (var j = 0; j < keySet.length; j++) {
        key = keySet[j];
        valueObject = dataObj[key];
        if (this._compareDate(fromDate, key, toDate)) {
          displayDataObj[key] = valueObject;
        }
      }
      this.set('dataKeySet', Object.keys(displayDataObj));
      this.sortKeySet(this.get('dataKeySet'));
      this.get('dataKeySet').reverse(); // to change the display format of the columns from left to right
      keySet = Object.keys(displayDataObj);
      if (keySet.length <= 0) {
        return;
      }

      switch (type) {

        case 'IS':
          this.set('dataKeySetIS', Object.keys(displayDataObj));
          break;

        case 'BS':
          this.set('dataKeySetBS', Object.keys(displayDataObj));
          break;

        case 'CF':
          this.set('dataKeySetCF', Object.keys(displayDataObj));
          break;

        case 'FR':
          this.set('dataKeySetFR', Object.keys(displayDataObj));
          break;
        //
        // default :
        //     this.set('dataKeySet', Object.keys(displayDataObj));
        //     break;

      }
    },

    /**
     * To get filtered style list. Iterate the filtered data list and get only the styles that having data for
     * all the fields and filter the styles according to the breakdown views.
     */

    filterStyleValue: function filterStyleValue() {
      var styleObject, i;
      var keySet = this.get('dataKeySet');
      this.set('displayStyleObject', Ember['default'].A([]));
      for (var j = 0; j < this.get('styleObject').length; j++) {
        styleObject = this.get('styleObject')[j];
        var dataObject;
        var key;
        if (this.get('factSheetViewEnable')) {
          for (i = 0; i < keySet.length; i++) {
            key = keySet[i];
            dataObject = this.get('displayDataObject')[key];
            if (dataObject[styleObject.filedID]) {
              styleObject.filedID2 = styleObject.filedID;
              if (this.get('breakDown') === FundamentalConstants['default'].BreakDown.CollapsedView) {
                if (styleObject.level === '1') {
                  if (styleObject.factSheetEnable) {
                    if (styleObject.filedID.endsWith("(2)")) {
                      styleObject.filedID2 = styleObject.filedID.replace("(2)", "");
                    } else if (styleObject.filedID.endsWith("(3)")) {
                      styleObject.filedID2 = styleObject.filedID.replace("(3)", "");
                    } else if (styleObject.filedID.endsWith("(4)")) {
                      styleObject.filedID2 = styleObject.filedID.replace("(4)", "");
                    }
                    this.get('displayStyleObject').pushObject(styleObject);
                  }
                }
              } else {
                if (styleObject.factSheetEnable) {
                  if (styleObject.filedID.endsWith("(2)")) {
                    styleObject.filedID2 = styleObject.filedID.replace("(2)", "");
                  } else if (styleObject.filedID.endsWith("(3)")) {
                    styleObject.filedID2 = styleObject.filedID.replace("(3)", "");
                  } else if (styleObject.filedID.endsWith("(4)")) {
                    styleObject.filedID2 = styleObject.filedID.replace("(4)", "");
                  }
                  this.get('displayStyleObject').pushObject(styleObject);
                }
              }
              break;
            }
          }
        } else {
          for (i = 0; i < keySet.length; i++) {
            key = keySet[i];
            dataObject = this.get('displayDataObject')[key];
            if (dataObject[styleObject.filedID]) {
              if (this.get('breakDown') === FundamentalConstants['default'].BreakDown.CollapsedView) {
                if (styleObject.level === '1') {
                  this.get('displayStyleObject').pushObject(styleObject);
                }
              } else {
                this.get('displayStyleObject').pushObject(styleObject);
              }
              break;
            }
          }
        }
      }
      this.setTitleObj();
    },

    setTitleObj: function setTitleObj() {
      // title object contains all the styles which are sorted and sub categorized according to the group ID and group order ID
      var displayStyleObject = this.get('displayStyleObject');
      var titleObj = Ember['default'].A([]),
          temp = 0;
      var i;
      for (i = 0; i < displayStyleObject.length; i++) {
        if (parseInt(displayStyleObject[i].groupID, 10) !== temp) {
          temp++;

          titleObj.push(this._processArray(displayStyleObject, temp));
        }
      }
      this.set('titleObj', titleObj);
    },

    _processArray: function _processArray(displayStyleObject, temp) {
      var temArray;
      temArray = Ember['default'].$.grep(displayStyleObject, function (style) {
        return parseInt(style.groupID, 10) === temp;
      });
      temArray.sort(function (a, b) {
        return parseInt(a.orderInGroup, 10) - parseInt(b.orderInGroup, 10);
      });
      return temArray;
    },

    /**
     * This is a support function for filter data objects between two periods.
     * @param fromDate, date and toDate
     * @return {boolean}
     */

    _compareDate: function _compareDate(fromDate, date, toDate) {
      if (fromDate === undefined || date === undefined || toDate === undefined) {
        return false;
      }
      var splitFrom = fromDate.split('~');
      var splitDate = date.split('~');
      var splitTo = toDate.split('~');

      if (this.get('currentlyDataDisplayingViewType') === FundamentalConstants['default'].ViewTypes.VIEW_TYPE_ANNUAL) {
        if (parseInt(splitDate[0], 10) >= parseInt(fromDate, 10) && splitDate[0].localeCompare(toDate) <= 0) {
          return true;
        }
      } else {
        if (splitDate[0].concat(this._compareQuarter(splitDate[1])).localeCompare(splitFrom[0].concat(this._compareQuarter(splitFrom[1]))) >= 0 && splitDate[0].concat(this._compareQuarter(splitDate[1])).localeCompare(splitTo[0].concat(this._compareQuarter(splitTo[1]))) <= 0) {
          return true;
        }
      }
      return false;
    },

    _compareQuarter: function _compareQuarter(quarter) {
      try {
        if ('5' === quarter[quarter.length - 1]) {
          return '4';
        }
        return quarter[quarter.length - 1];
      } catch (e) {
        return quarter;
      }
    },

    _onTabChange: function _onTabChange(tabID) {

      var that = this;
      this._clearDisplay();
      this.set('isExpandButtonAvailable', false);
      this.set('isRatio', false);
      this.set('showColumnTopMsg', false);

      if (tabID === 0) {
        this.set('statementType', 'Snapshot');
      } else if (tabID === 1) {
        this.set('statementType', 'IS');
      } else if (tabID === 2) {
        this.set('statementType', 'BS');
      } else if (tabID === 3) {
        this.set('statementType', 'CF');
      } else if (tabID === 4) {
        this.set('statementType', 'R');
        this.set('showColumnTopMsg', true);
        this.set('isRatio', true);
      }

      var postSuccess = function postSuccess() {
        that._setMemoryVariables();
        that.dataDisplay(that.get('exg'), that.get('sym'), that.get('currentlyDataDisplayingViewType'));
      };

      this.dropDownSetter(postSuccess); // on tab change data will be set to currently selected criteria of the drop downs
    },

    onExpandCollapse: (function () {
      var selection = this.get('isExpand');
      if (selection === 1) {
        this.set('breakDown', FundamentalConstants['default'].BreakDown.ExpandView);
      } else if (selection === 2) {
        this.set('breakDown', FundamentalConstants['default'].BreakDown.CollapsedView);
      }
    }).observes('isExpand'),

    _clearDisplay: function _clearDisplay() {
      this.hideDataErrorMessage();
      this.set('displayDataObject', Ember['default'].A([]));
      this.set('displayStyleObject', Ember['default'].A([]));
    },

    _setMemoryVariables: function _setMemoryVariables() {
      // this method set the memory variables which are used to filter the data set
      this.set('currentlyDataDisplayingIsCalendar', this.get('isCalendar'));
      this.set('currentlyDataDisplayingViewType', this.get('viewType'));
      this.set('currentlyDataDisplayingFilterFromDate', this.get('filterFromDate'));
      this.set('currentlyDataDisplayingFilterToDate', this.get('filterToDate'));
    },

    _onClickView: function _onClickView() {
      this._clearDisplay();
      this._setMemoryVariables();
      this.startLoadingProgress();
      this.set('reRender', true);
      this.sendDataRequest(this.get('exg'), this.get('sym'), this.get('currentlyDataDisplayingViewType'));
    },

    _validator: function _validator() {
      if (this.get('filterFromDate') === undefined || this.get('filterToDate') === undefined) {
        return;
      }
      var arrayFrom = this.get('filterFromDate').split('~');
      var arrayTo = this.get('filterToDate').split('~');

      if (parseInt(arrayFrom[0]) > parseInt(arrayTo[0])) {
        if (this.get('viewDropdownListFromDate').length < parseInt(this.get('numberOFQuartersDisplayed'))) {
          this.set('defSelectFrom', this.get('viewDropdownListFromDate')[0]);
        } else {
          this.set('defSelectFrom', this.get('viewDropdownListFromDate')[this.get('numberOFQuartersDisplayed') - 1]);
        }
        this.set('filterFromDate', this.get('defSelectFrom').view);
        this.set('defSelectTo', this.get('viewDropdownListFromDate')[0]);
        this.set('filterToDate', this.get('defSelectTo').view);
        this.set('resetFieldFrom', !this.get('resetFieldFrom'));
        this.set('resetFieldTo', !this.get('resetFieldTo'));
      } else if (parseInt(arrayFrom[0]) === parseInt(arrayTo[0]) && parseInt(arrayFrom[1]) > parseInt(arrayTo[1])) {
        if (this.get('viewDropdownListFromDate').length < parseInt(this.get('numberOFQuartersDisplayed'))) {
          this.set('defSelectFrom', this.get('viewDropdownListFromDate')[0]);
        } else {
          this.set('defSelectFrom', this.get('viewDropdownListFromDate')[this.get('numberOFQuartersDisplayed') - 1]);
        }
        this.set('filterFromDate', this.get('defSelectFrom').view);
        this.set('defSelectTo', this.get('viewDropdownListFromDate')[0]);
        this.set('filterToDate', this.get('defSelectTo').view);
        this.set('resetFieldFrom', !this.get('resetFieldFrom'));
        this.set('resetFieldTo', !this.get('resetFieldTo'));
      }
    },

    getDisplayLabel: function getDisplayLabel(dataObject, key, viewType) {
      if (dataObject[key]) {
        if (viewType === 'Q') {
          return 'Q' + key.split('~')[1] + ' ' + key.split('~')[0];
        } else if (viewType === 'A') {
          return key.split('~')[0];
        }
      }
      return '';
    },
    _setDropdownValues: function _setDropdownValues() {
      var view = this.get('viewType');
      var that = this;
      var keyListDate,
          i,
          j,
          numberOfQuarters = 4,
          objArrayOne,
          keySetOne;
      var min_year, max_year, min_quarter, max_quarter;

      /* instead of taking min_max object from the response, we are finding the min max using the available data set , as it is more reliable */
      objArrayOne = priceService['default'].clientFundamentalDS.getDataObject(that.get('exg'), that.get('sym'), 'IS', view, this.get('isCalendar'));
      if (!objArrayOne) {
        // TODO [Akila] find a better way to select the view type for the drop down
        objArrayOne = priceService['default'].clientFundamentalDS.getDataObject(that.get('exg'), that.get('sym'), 'CF', view, this.get('isCalendar'));
      }
      if (!objArrayOne) {
        objArrayOne = priceService['default'].clientFundamentalDS.getDataObject(that.get('exg'), that.get('sym'), 'BS', view, this.get('isCalendar'));
      }
      if (!objArrayOne) {
        objArrayOne = priceService['default'].clientFundamentalDS.getDataObject(that.get('exg'), that.get('sym'), 'R', view, this.get('isCalendar'));
      }
      if (!objArrayOne) {
        this.set('viewDropdownListFromDate', []);
        this.set('viewDropdownListToDate', []);
        this.set('defSelectFrom', undefined);
        this.set('defSelectTo', undefined);
        this.set('filterFromDate', undefined);
        this.set('filterToDate', undefined);
        this.set('resetFieldFrom', !this.get('resetFieldFrom'));
        this.set('resetFieldTo', !this.get('resetFieldTo'));
        return;
      } else {
        keySetOne = Object.keys(objArrayOne);
        keySetOne = keySetOne.sort(function (a, b) {
          var yearOne = parseInt(a.split('~')[0]);
          var yearTwo = parseInt(b.split('~')[0]);
          var QOne = parseInt(a.split('~')[1]);
          var QTwo = parseInt(b.split('~')[1]);

          if (yearOne > yearTwo) {
            return -1;
          } else if (yearOne < yearTwo) {
            return 1;
          } else {
            if (QOne > QTwo) {
              return -1;
            } else {
              return 1;
            }
          }
        });

        max_year = parseInt(keySetOne[0].split('~')[0]);
        max_quarter = FundamentalCommonFunctions['default'].actualQuarter(keySetOne[0].split('~')[1]);
        min_year = parseInt(keySetOne[keySetOne.length - 1].split('~')[0]);
        min_quarter = FundamentalCommonFunctions['default'].actualQuarter(keySetOne[keySetOne.length - 1].split('~')[1]);
      }

      if (view === 'A') {
        keyListDate = [];
        for (i = min_year; i <= max_year; i++) {
          keyListDate.push({ view: i + '~' + '5', des: i });
        }
        keyListDate.reverse(); // to invert the upper most value of the dropdown
        this.set('viewDropdownListFromDate', keyListDate);
        this.set('viewDropdownListToDate', keyListDate);
        if (keyListDate.length < parseInt(this.get('numberOFYearsDisplayed'))) {
          /* for some symbols data set can be less than the number
          of default entries to be displayed in the view. this if was used to handle such scenarios */
          this.set('defSelectFrom', keyListDate[keyListDate.length - 1]);
          this.set('filterFromDate', keyListDate[keyListDate.length - 1].view);
        } else {
          this.set('defSelectFrom', keyListDate[this.get('numberOFYearsDisplayed') - 1]);
          this.set('filterFromDate', keyListDate[this.get('numberOFYearsDisplayed') - 1].view);
        }
        this.set('defSelectTo', keyListDate[0]);
        this.set('filterToDate', keyListDate[0].view);
        this.set('resetFieldFrom', !this.get('resetFieldFrom'));
        this.set('resetFieldTo', !this.get('resetFieldTo'));
      } else if (view === 'Q') {
        keyListDate = [];
        for (i = min_year; i <= max_year; i++) {
          for (j = 1; j <= numberOfQuarters; j++) {
            if (i === min_year) {
              if (j < min_quarter) {
                j = min_quarter;
              }
            }
            if (i === max_year) {
              numberOfQuarters = max_quarter;
            }

            keyListDate.push({ view: i + '~' + j, des: 'Q' + j + ' ' + i });
          }
        }
        keyListDate.reverse();
        this.set('viewDropdownListFromDate', keyListDate);
        this.set('viewDropdownListToDate', keyListDate);
        if (keyListDate.length < parseInt(this.get('numberOFYearsDisplayed'))) {
          /* for some symbols data set can be less than the number
          of default entries to be displayed in the view. this if was used to handle such scenarios */
          this.set('defSelectFrom', keyListDate[keyListDate.length - 1]);
          this.set('filterFromDate', keyListDate[keyListDate.length - 1].view);
        } else {
          this.set('defSelectFrom', keyListDate[this.get('numberOFYearsDisplayed') - 1]);
          this.set('filterFromDate', keyListDate[this.get('numberOFYearsDisplayed') - 1].view);
        }

        this.set('defSelectTo', keyListDate[0]);
        this.set('filterToDate', keyListDate[0].view);

        this.set('resetFieldFrom', !this.get('resetFieldFrom'));
        this.set('resetFieldTo', !this.get('resetFieldTo'));
      }
      this.set('keyListDate', keyListDate);
    },

    finYearEnd: (function () {
      // this returns the financial ending month as a string
      this.set('finYearEndMonth', utils['default'].formatters.getMonthStrFromInt(this.get('app').lang.labels, this.get('compObj').finYearStart - 1));
    }).observes('compObj.finYearStart'),

    /*if data was loaded initially for cal=1 then there will be no data for cal=0 {calendar and fiscal}
     * thus want to load that data when the user change the period from calendar to fiscal and vice versa.
     * As response is cached, request will be locally resolved*/
    dropDownSetter: (function (postSuccess) {
      if (!this.get('exg') || !this.get('sym')) {
        return;
      }
      var that = this;

      if (that.get('currentlyDataDisplayingIsCalendar') !== that.get('isCalendar')) {
        var suc = function suc() {
          that._setDropdownValues();
          if (Ember['default'].$.isFunction(postSuccess)) {
            postSuccess();
          }
        };
        var err = function err() {
          that.showDataErrorMessage();
          that.stopLoadingProgress();
        };
        priceService['default'].sendClientFundamentalDataRequest(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, 'X', suc, err, '5', this.get('isCalendar'));
      } else {
        that._setDropdownValues();
        if (Ember['default'].$.isFunction(postSuccess)) {
          postSuccess();
        }
      }
    }).observes('isCalendar'),

    setFinancialRatioChartData: function setFinancialRatioChartData() {
      var mainArr = Ember['default'].A();
      if (this.get('dataObject')) {

        this.set('displayDataObjectFR', {});
        this.filterDataValues(this.get('currentlyDataDisplayingFilterFromDate'), this.get('currentlyDataDisplayingFilterToDate'), this.get('dataObject'), this.get('displayDataObjectFR'), 'FR');

        for (var i = 0; i < this.get('displayStyleObject').length; i++) {
          var array = Ember['default'].A();
          if (this.get('displayStyleObject')[i].chartOnFactSheet) {
            this.getChartDataSetFR(this.get('displayStyleObject')[i].filedID, array, this.get('displayDataObjectFR'), this.get('displayStyleObject')[i].filedID);
            mainArr.push(array);
          }
        }
        this.set('dataSetArrFR', mainArr);
        this.set('xDataSetFR', this.get('arrXFR'));
      }
    },
    setIncomeStatementChartData: function setIncomeStatementChartData() {
      var mainArr = Ember['default'].A();
      if (this.get('dataObject')) {

        this.set('displayDataObjectIS', {});
        this.filterDataValues(this.get('currentlyDataDisplayingFilterFromDate'), this.get('currentlyDataDisplayingFilterToDate'), this.get('dataObject'), this.get('displayDataObjectIS'), 'IS');

        for (var i = 0; i < this.get('displayStyleObject').length; i++) {
          var array = Ember['default'].A();
          if (this.get('displayStyleObject')[i].chartOnFactSheet) {
            this.getChartDataSetIS(this.get('displayStyleObject')[i].filedID, array, this.get('displayDataObjectIS'), this.get('displayStyleObject')[i].filedID);
            mainArr.push(array);
          }
        }

        this.set('dataSetArrIS', mainArr);
        this.set('xDataSetIS', this.get('arrXIS'));
      }
    },

    setBalanceSheetChartData: function setBalanceSheetChartData() {
      var mainArr = Ember['default'].A();
      if (this.get('dataObject')) {

        this.set('displayDataObjectBS', {});
        this.filterDataValues(this.get('currentlyDataDisplayingFilterFromDate'), this.get('currentlyDataDisplayingFilterToDate'), this.get('dataObject'), this.get('displayDataObjectBS'), 'BS');

        for (var i = 0; i < this.get('displayStyleObject').length; i++) {
          var array = Ember['default'].A();
          if (this.get('displayStyleObject')[i].chartOnFactSheet) {
            this.getChartDataSetBS(this.get('displayStyleObject')[i].filedID, array, this.get('displayDataObjectBS'), this.get('displayStyleObject')[i].filedID);
            mainArr.push(array);
          }
        }

        this.set('dataSetArrBS', mainArr);
        this.set('xDataSetBS', this.get('arrXBS'));
      }
    },
    setCashFlowChartData: function setCashFlowChartData() {
      var mainArr = Ember['default'].A();
      if (this.get('dataObject')) {

        this.set('displayDataObjectCF', {});
        this.filterDataValues(this.get('currentlyDataDisplayingFilterFromDate'), this.get('currentlyDataDisplayingFilterToDate'), this.get('dataObject'), this.get('displayDataObjectCF'), 'CF');

        for (var i = 0; i < this.get('displayStyleObject').length; i++) {
          var array = Ember['default'].A();
          if (this.get('displayStyleObject')[i].chartOnFactSheet) {
            this.getChartDataSetCF(this.get('displayStyleObject')[i].filedID, array, this.get('displayDataObjectCF'), this.get('displayStyleObject')[i].filedID);
            mainArr.push(array);
          }
        }

        this.set('dataSetArrCF', mainArr);
        this.set('xDataSetCF', this.get('arrXCF'));
      }
    },

    getChartDataSetFR: function getChartDataSetFR(key, array, dataObj, label) {
      this.set('arrXFR', Ember['default'].A());
      this.set(array, Ember['default'].A());
      array.pushObject(this.get('app').lang.labels[key] ? this.get('app').lang.labels[key] : label);
      this.get('arrXFR').pushObject('x');

      for (var i = 0; i < this.get('dataKeySetFR.length'); i++) {
        var element = dataObj[this.get('dataKeySetFR')[i]][key];
        array.pushObject(element);
        this.get('arrXFR').pushObject(this.getDisplayLabel(this.get('displayDataObjectFR'), this.get('dataKeySetFR')[i], this.get('currentlyDataDisplayingViewType'), this.get('currentlyDataDisplayingIsCalendar')));
      }
    },

    getChartDataSetIS: function getChartDataSetIS(key, array, dataObj, label) {
      this.set('arrXIS', Ember['default'].A());
      this.set(array, Ember['default'].A());
      array.pushObject(this.get('app').lang.labels[key] ? this.get('app').lang.labels[key] : label);
      this.get('arrXIS').pushObject('x');

      for (var i = 0; i < this.get('dataKeySetIS.length'); i++) {
        var element = dataObj[this.get('dataKeySetIS')[i]][key];
        if (this.get("minusValArray").includes(key)) {
          element = Math.abs(element);
        }
        array.pushObject(element);
        this.get('arrXIS').pushObject(this.getDisplayLabel(this.get('displayDataObjectIS'), this.get('dataKeySetIS')[i], this.get('currentlyDataDisplayingViewType'), this.get('currentlyDataDisplayingIsCalendar')));
      }
    },

    getChartDataSetBS: function getChartDataSetBS(key, array, dataObj, label) {
      this.set('arrXBS', Ember['default'].A());
      this.set(array, Ember['default'].A());
      array.pushObject(this.get('app').lang.labels[key] ? this.get('app').lang.labels[key] : label);
      this.get('arrXBS').pushObject('x');

      for (var i = 0; i < this.get('dataKeySetBS.length'); i++) {
        var element = dataObj[this.get('dataKeySetBS')[i]][key];
        if (this.get("minusValArray").includes(key)) {
          element = Math.abs(element);
        }
        array.pushObject(element);
        this.get('arrXBS').pushObject(this.getDisplayLabel(this.get('displayDataObjectBS'), this.get('dataKeySetBS')[i], this.get('currentlyDataDisplayingViewType'), this.get('currentlyDataDisplayingIsCalendar')));
      }
    },

    getChartDataSetCF: function getChartDataSetCF(key, array, dataObj, label) {
      this.set('arrXCF', Ember['default'].A());
      this.set(array, Ember['default'].A());
      array.pushObject(this.get('app').lang.labels[key] ? this.get('app').lang.labels[key] : label);
      this.get('arrXCF').pushObject('x');

      for (var i = 0; i < this.get('dataKeySetCF.length'); i++) {
        var element = dataObj[this.get('dataKeySetCF')[i]][key];
        if (this.get("minusValArray").includes(key)) {
          element = Math.abs(element);
        }
        array.pushObject(element);
        this.get('arrXCF').pushObject(this.getDisplayLabel(this.get('displayDataObjectCF'), this.get('dataKeySetCF')[i], this.get('currentlyDataDisplayingViewType'), this.get('currentlyDataDisplayingIsCalendar')));
      }
    },

    actions: {
      onTabSelected: function onTabSelected(tabId) {
        this._onTabChange(tabId);
      },

      onSelectingView: function onSelectingView(item) {
        switch (item.view) {
          case 1:
            this.set('viewType', 'A');
            this._setDropdownValues();break;
          case 2:
            this.set('viewType', 'Q');
            this._setDropdownValues();break;
          case 3:
            this.set('viewType', 'C');
            this._setDropdownValues();break;
          case 4:
            this.set('viewType', 'T');
            this._setDropdownValues();break;
          default:
            return;
        }

        if (this.get('activateViewDropdown')) {
          this._onClickView();
        }
      },

      onSelectingFromDate: function onSelectingFromDate(item) {
        this.set('defSelectFrom', item);
        this.set('filterFromDate', item.view);
      },

      onSelectingToDate: function onSelectingToDate(item) {
        this.set('defSelectTo', item);
        this.set('filterToDate', item.view);
      },

      onClickView: function onClickView() {
        this._validator();
        this._onClickView();
      }
    },

    onAfterRender: function onAfterRender() {
      Ember['default'].$(document).ready(function () {
        if (!String.prototype.endsWith) {
          String.prototype.endsWith = function (suffix) {
            return this.indexOf(suffix, this.length - suffix.length) !== -1;
          };
        }
        Ember['default'].$('#scroll_tabs2').scrollTabs({
          scroll_distance: 200,
          // Pixel width distance for each scroll click.
          scroll_duration: 300,
          // <a href="http://www.jqueryscript.net/animation/">Animation</a> time for scrolling in milliseconds.
          left_arrow_size: 26,
          // Pixel width for the scroll button on the left side.
          right_arrow_size: 26,
          // Pixel width for the scroll button on the right side.
          click_callback: function click_callback(e) {
            // jshint ignore:line
            //Callback function on click. Accpets the click event object as an argument. The default callback function will change the page to the href in the 'rel' attribute of the item's span tag.
            var val = Ember['default'].$(this).attr('rel');
            if (val) {
              window.location.href = val;
            }
          }
        });
      });
    }
  });

  Ember['default'].Handlebars.helper('displayData', function (dataObject, key, fieldID, statementType) {
    if (dataObject[key]) {
      var valueObject = dataObject[key];
      var temp;
      if (statementType === 'R' || valueObject[fieldID] === '') {
        temp = utils['default'].formatters.formatNumber(valueObject[fieldID], 2);
      } else {
        temp = utils['default'].formatters.formatNumber(valueObject[fieldID], 0);
      }
      if (temp.charAt(0) === '-' && temp.charAt(1) !== '-') {
        temp = '(' + temp.substring(1, temp.length) + ')';
      }
      return temp;
    }
    return '';
  });

  Ember['default'].Handlebars.helper('getHeader', function (dataObject, key, viewType) {
    if (dataObject[key]) {
      if (viewType === 'Q') {
        return 'Q' + key.split('~')[1] + ' ' + key.split('~')[0];
      } else if (viewType === 'A') {
        return key.split('~')[0];
      }
    }
    return '';
  });
  Ember['default'].Handlebars.helper('financial-chart', FinancialChart['default']);

});