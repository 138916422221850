define('ir-app/utils/validators', ['exports', 'ir-app/utils/constants'], function (exports, Constants) {

    'use strict';

    exports['default'] = (function () {
        /**
         * Checks whether string is undefined, null or empty
         * @param value String to validate
         * @returns {boolean} True if string is not undefined, not null and not empty, false otherwise
         */
        var isAvailable = function isAvailable(value) {
            var valid = false;

            if (value !== undefined && value !== null) {
                if (value.trim) {
                    valid = value.trim() !== Constants['default'].StringConst.Empty;
                } else {
                    valid = true;
                }
            }

            return valid;
        };

        /**
         * compare given date objects considering only the date
         * @param date1 date to be compared
         * @param date2 date to be compared
         * @return -1 if date2 is before date1, 0 if date1 and date2 are on same day, 1 if date2 is after date1
         */
        var compareByDate = function compareByDate(date1, date2) {
            date1.setHours(0);
            date1.setMinutes(0);
            date1.setSeconds(0);
            date1.setMilliseconds(0);

            date2.setHours(0);
            date2.setMinutes(0);
            date2.setSeconds(0);
            date2.setMilliseconds(0);

            if (date1.getTime() === date2.getTime()) {
                return 0;
            } else if (date1.getTime() > date2.getTime()) {
                return -1;
            } else {
                return 1;
            }
        };

        var isMSIEVersion = function isMSIEVersion() {
            var ua = window.navigator.userAgent;

            var msie = ua.indexOf('MSIE ');
            if (msie > 0) {
                // IE 10 or older => return version number
                return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
            }

            var trident = ua.indexOf('Trident/');
            if (trident > 0) {
                // IE 11 => return version number
                var rv = ua.indexOf('rv:');
                return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
            }

            var edge = ua.indexOf('Edge/');
            if (edge > 0) {
                // Edge (IE 12+) => return version number
                return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
            }

            // other browser
            return false;
        };

        return {
            isAvailable: isAvailable,
            compareByDate: compareByDate,
            isMSIEVersion: isMSIEVersion
        };
    })();

});