define('ir-app/initializers/application-initializer', ['exports', 'ember', 'ir-app/utils/utils', 'ir-app/config/module-initializer-config'], function (exports, Ember, utils, moduleInitializerConfig) {

    'use strict';

    exports['default'] = (function () {
        var name = 'application-initializer';

        var initialize = function initialize(container, application) {
            application.deferReadiness();

            // Calling preInitialize of all module initializers
            Ember['default'].$.each(moduleInitializerConfig['default'].modules, function (key, config) {
                try {
                    config.preInitialize(application);
                } catch (e) {
                    utils['default'].logger.logError('Error in pre-initialize : ' + e);
                }
            });

            // Calling postInitialize of all module initializers
            Ember['default'].$.each(moduleInitializerConfig['default'].modules, function (key, config) {
                try {
                    config.postInitialize(application);
                } catch (e) {
                    utils['default'].logger.logError('Error in post-initialize : ' + e);
                }
            });

            application.advanceReadiness();
        };

        return {
            name: name,
            initialize: initialize
        };
    })();

});