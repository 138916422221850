define('ir-app/models/price/price-business-entities/ipo-status-types', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({

        id: '', // Type ID
        desc: '', // IPO desc

        setData: function setData(ipoTypes) {
            var that = this;

            Ember['default'].$.each(ipoTypes, function (key, value) {
                that.set(key, value);
            });
        }

    });

});