define('ir-app/utils/key-generator', ['exports', 'ir-app/utils/utils'], function (exports, utils) {

    'use strict';

    exports['default'] = (function () {
        var getKey = function getKey(exchange, symbol) {
            return [exchange, utils['default'].Constants.StringConst.Tilde, symbol].join('');
        };

        return {
            getKey: getKey
        };
    })();

});