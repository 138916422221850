define('ir-app/models/price/price-business-entities/depth-record', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    /**
     * Created by Amila on 6/22/15.
     */
    exports['default'] = Ember['default'].Object.extend({
        type: '', // Depth side (bid = 0 or offer = 1)
        lvl: '', // Depth level
        prc: '', // Price
        qty: '', // Quantity
        splt: '', // Number of splits
        per: '', // Bar width percentage
        isHighestVol: false, // Is highest Volume

        setData: function setData(depthRecord) {
            var that = this;

            Ember['default'].$.each(depthRecord, function (key, value) {
                that.set(key, value);
            });
        }
    });

});