define('ir-app/controllers/ir-widgets/financial-widgets/financial-ratios-comparison', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/utils/utils', 'ir-app/config/app-config', 'ir-app/models/shared/shared-service', 'ir-app/models/price/price-business-entities/fundamentals/fundamental-constants', 'ir-app/models/price/price-business-entities/fundamentals/fundamental-data-object'], function (exports, Ember, BaseController, priceService, utils, AppConfig, sharedService, FundamentalConstants, dataObj) {

    'use strict';


    exports['default'] = BaseController['default'].extend({

        titleObj: undefined,
        baseSector: undefined,
        symbolDesc: undefined,
        styleObject: undefined,
        minMaxObject: undefined,
        getSymbolList: undefined,
        portalExchangeList: undefined,
        viewDropdownListPeriod: undefined,
        placeHolder_1: undefined, placeHolder_2: undefined, placeHolder_3: undefined, placeHolder_4: undefined,

        isCalendar: FundamentalConstants['default'].CalendarTypes.CALENDAR_YEAR,
        statementType: FundamentalConstants['default'].StatementTypes.FinancialRatios,
        viewType: FundamentalConstants['default'].ViewTypes.VIEW_TYPE_QUARTER,

        currentlyDataDisplayingIsCalendar: FundamentalConstants['default'].CalendarTypes.CALENDAR_YEAR,
        currentlyDataDisplayingViewType: FundamentalConstants['default'].ViewTypes.VIEW_TYPE_QUARTER,

        dataObjects: Ember['default'].A([]),
        displayDataObjects: Ember['default'].A([]),
        symbolsList: Ember['default'].A([]),
        displayStyleObject: Ember['default'].A([]),
        sectors: Ember['default'].A(),

        sectorSymbolsList: [],
        viewDropdownListView: [],

        noOfCompareSymbols: 5, // if changed, change the hbs accordingly. Could not use the same action to call by the symbol dropdowns.

        onLoadWidget: function onLoadWidget() {
            this.setErrorMessage();
            this.set('portalExchangeList', AppConfig['default'].exchangeList.alArabia);
            this.set('placeHolder_1', this.get('app').lang.labels.selectSymbol);
            this.set('placeHolder_2', this.get('app').lang.labels.selectSymbol);
            this.set('placeHolder_3', this.get('app').lang.labels.selectSymbol);
            this.set('placeHolder_4', this.get('app').lang.labels.selectSymbol);
        },

        onPrepareData: function onPrepareData() {
            var that = this;
            var exchange = this.get('exg');
            var symbol = this.get('sym');
            var view = this.get('viewType');

            // first set all the elements as undefined. you can't set array blank and pushObject as it may occur mapping issue in symbol and ratio data
            for (var i = 0; i < this.get('noOfCompareSymbols'); i++) {
                this.get('symbolsList').pushObject(undefined);
                this.get('displayDataObjects').pushObject(undefined);
            }
            this.get('symbolsList').insertAt(0, symbol);

            this.startLoadingProgress();

            this.sendDataRequest(exchange, symbol, view, 0, true); // get data for the base symbol

            priceService['default'].downloadParamsSymbolMasterForExchange({
                exchange: that.get('exg'),
                language: sharedService['default'].userSettings.currentLanguage,
                successFn: function successFn() {
                    that.onSymbolDataSuccess();
                },
                errorFn: function errorFn() {
                    that.onError();
                }
            });

            priceService['default'].downloadExchangeProperty(that.get('exg'), sharedService['default'].userSettings.currentLanguage);

            this.set('lang', sharedService['default'].userSettings.currentLanguage.toLowerCase());
        },

        sendDataRequest: function sendDataRequest(exchange, symbol, view, i, isInit) {
            var that = this;
            var postSuccess = function postSuccess() {
                if (isInit) {
                    that._defaultSelectionMaker();
                }
                that.dataDisplay(exchange, symbol, view, i, isInit);
                that.stopLoadingProgress();
            };

            var error = function error() {
                that.stopLoadingProgress();
            };

            priceService['default'].sendFundamentalDataRequest(exchange, symbol, sharedService['default'].userSettings.currentLanguage, view, postSuccess, error, '5', this.get('isCalendar'));
        },

        dataDisplay: function dataDisplay(exchange, symbol, view, i, isInit) {
            var arrayFR = priceService['default'].advanceFundamentalDS.getDataObject(exchange, symbol, FundamentalConstants['default'].StatementTypes.FinancialRatios, view, this.get('isCalendar'));
            var arrayMR = priceService['default'].advanceFundamentalDS.getDataObject(exchange, symbol, FundamentalConstants['default'].StatementTypes.MarketRatios, view, this.get('isCalendar'));

            if (arrayFR && arrayMR) {
                this._mergeDataObjects(arrayFR, arrayMR, i);
            } else {
                this.get('dataObjects')[i] = undefined;
            }

            this.filterDataValues(i);

            if (this.get('dataObjects')[i]) {
                if (isInit) {
                    arrayFR = priceService['default'].advanceFundamentalDS.getStyleObject(exchange, symbol, FundamentalConstants['default'].StatementTypes.FinancialRatios, view, this.get('isCalendar'));
                    arrayMR = priceService['default'].advanceFundamentalDS.getStyleObject(exchange, symbol, FundamentalConstants['default'].StatementTypes.MarketRatios, view, this.get('isCalendar'));
                    this.set('styleObject', this._mergeStyleObjects(arrayFR, arrayMR));

                    this.filterStyleValue(i);
                }
            } else {
                this.onError();
            }
        },

        filterDataValues: function filterDataValues(i) {
            var valueObject = this.get('dataObjects')[i];
            this.get('displayDataObjects').removeAt(i, 1);
            this.get('displayDataObjects').insertAt(i, valueObject);
        },

        _mergeDataObjects: function _mergeDataObjects(arrayFR, arrayMR, position) {
            // this function merge both the FR data object and MR data object in to one object.
            var object = dataObj['default'].create();
            var key = this.get('filterPeriod');

            if (arrayMR[key] && arrayFR[key]) {
                object.setData(arrayMR[key]);
                object.setData(arrayFR[key]);
            } else if (arrayMR[key] && !arrayFR[key]) {
                object = arrayMR[key];
            } else if (arrayFR[key] && !arrayMR[key]) {
                object = arrayFR[key];
            }

            this.get('dataObjects')[position] = object;
        },

        _mergeStyleObjects: function _mergeStyleObjects(arrayFR, arrayMR) {
            // this function merge both the FR data object and MR data object in to one object.
            var property;
            var isHas;
            for (var k = 0; k < arrayMR.length; k++) {
                property = arrayMR[k];
                for (var j = 0; j < arrayFR.length; j++) {
                    if (arrayFR[j].hasOwnProperty(property.filedID)) {
                        isHas = true;
                    }
                }
                if (!isHas) {
                    arrayFR.pushObject(property);
                }
            }

            return arrayFR;
        },

        _compareDate: function _compareDate(fromDate, date) {
            var splitFrom = fromDate.split('~');
            var splitDate = date.split('~');
            var splitTo = fromDate.split('~');

            if (this.get('viewType') === FundamentalConstants['default'].ViewTypes.VIEW_TYPE_ANNUAL) {
                if (parseInt(splitDate[0], 10) >= parseInt(fromDate, 10) && splitDate[0].localeCompare(fromDate) <= 0) {
                    return true;
                }
            } else {
                if (splitDate[0].concat(this._compareQuarter(splitDate[1])).localeCompare(splitFrom[0].concat(this._compareQuarter(splitFrom[1]))) >= 0 && splitDate[0].concat(this._compareQuarter(splitDate[1])).localeCompare(splitTo[0].concat(this._compareQuarter(splitTo[1]))) <= 0) {
                    return true;
                }
            }
            return false;
        },

        _compareQuarter: function _compareQuarter(quarter) {
            try {
                if ('5' === quarter[quarter.length - 1]) {
                    return '4';
                }
                return quarter[quarter.length - 1];
            } catch (e) {
                return quarter;
            }
        },

        filterStyleValue: function filterStyleValue(position) {
            var styleObject;
            var dataObject;
            var displayStyleObject = Ember['default'].A([]);
            var customFieldsForRatios = this.get('customFieldsForRatios');

            for (var j = 0; j < this.get('styleObject').length; j++) {
                styleObject = this.get('styleObject')[j];
                dataObject = this.get('displayDataObjects')[position];
                if (dataObject[styleObject.filedID]) {
                    if (!customFieldsForRatios) {
                        displayStyleObject.pushObject(styleObject);
                    } else {
                        if (customFieldsForRatios.includes(styleObject.filedID)) {
                            displayStyleObject.pushObject(styleObject);
                        }
                    }
                }
            }

            this.set('displayStyleObject', displayStyleObject);

            this.setTitleObj();
        },

        setTitleObj: function setTitleObj() {
            // title object contains all the styles which are sorted and sub categorized according to the group ID and group order ID
            var displayStyleObject = this.get('displayStyleObject');
            var titleObj = Ember['default'].A([]),
                temp = 0;
            var str;
            var i;
            for (i = 0; i < displayStyleObject.length; i++) {
                if (parseInt(displayStyleObject[i].groupID, 10) !== temp) {
                    temp++;

                    str = this._processArray(displayStyleObject, temp);

                    if (str.length !== 0) {
                        titleObj.push(str);
                    }
                }
            }
            this.set('titleObj', titleObj);
        },

        _processArray: function _processArray(displayStyleObject, temp) {
            var temArray;
            temArray = Ember['default'].$.grep(displayStyleObject, function (style) {
                return parseInt(style.groupID, 10) === temp;
            });
            temArray.sort(function (a, b) {
                return parseInt(a.orderInGroup, 10) - parseInt(b.orderInGroup, 10);
            });
            return temArray;
        },

        onUnloadWidget: function onUnloadWidget() {
            this.set('sector', null);
        },

        onLanguageChanged: function onLanguageChanged() {
            this.setSectors();
            this._loadRatioData();
        },

        onSymbolDataSuccess: function onSymbolDataSuccess() {
            var that = this;
            priceService['default'].downloadParamsExchangeMetaData({
                exchange: that.get('exg'),
                language: sharedService['default'].userSettings.currentLanguage,
                successFn: function successFn() {
                    that.onSuccess();
                },
                errorFn: function errorFn() {
                    that.onError();
                }
            });
        },

        onSuccess: function onSuccess() {
            this.hideDataErrorMessage();
            this.setSectors();
            this._loadRatioData();
        },

        onError: function onError() {
            this.showDataErrorMessage();
        },

        _loadRatioData: function _loadRatioData() {
            var that = this;
            var exchange = this.get('exg');

            this.set('baseSector', priceService['default'].stockDS.getStock(exchange, this.get('sym')).get('sec'));
            this.sectors.forEach(function (sector) {
                if (that.get('baseSector') === sector.get('sec')) {
                    that.set('getSymbolList', sector.get('sortedContent'));
                }
            });

            var sectorSymbolsList = [];
            // var symbolItem = {symbol: undefined, symbolDes: this.get('app').lang.labels['selectSymbol']};
            var symbolItem = { symbol: undefined, symbolDes: 'Select Symbol' };

            sectorSymbolsList.push(symbolItem);

            if (this.get('getSymbolList')) {
                this.get('getSymbolList').forEach(function (item) {
                    if (that.get('sym') === item.get('sym')) {
                        that.set('symbolDesc', item.get('sym') + ' ' + item.get('sDes'));
                    } else {
                        symbolItem = { symbol: item.get('sym'), symbolDes: item.get('sym') + ' ' + item.get('sDes') };
                        sectorSymbolsList.push(symbolItem);
                    }
                });
            } else {
                this.onError();
            }

            this.set('sectorSymbolsList', sectorSymbolsList);
        },

        setSectors: function setSectors() {
            if (this.get('sectors').length > 0) {
                // On language change, remove previously applied sector desc
                this.set('sectors', Ember['default'].A());
            }

            this.set('sectors', priceService['default'].sectorDS.getSectorCollectionByExchange(this.get('exg')));
        },

        _setSymbol: function _setSymbol(position, symbol) {
            this.get('symbolsList').removeAt(position, 1);
            this.get('symbolsList').insertAt(position, symbol);

            if (symbol.get('sym') !== undefined) {
                this.startLoadingProgress();
                this.sendDataRequest(symbol.get('exg'), symbol.get('sym'), this.get('viewType'), position, false);
            } else {
                this.get('displayDataObjects').removeAt(position, 1);
                this.get('displayDataObjects').insertAt(position, undefined);
            }
        },

        _defaultSelectionMaker: function _defaultSelectionMaker() {
            var that = this;
            var keyListDate,
                i,
                j,
                k = 4;
            that.set('minMaxObject', priceService['default'].advanceFundamentalDS.getMinMaxObject(that.get('exg'), that.get('sym'), that.get('statementType'), that.get('viewType'), this.get('isCalendar')));
            var minMaxObj = this.get('minMaxObject');

            keyListDate = [];
            this.set('viewType', 'Q');

            var min_year, max_year, min_quarter, max_quarter;
            if (parseInt(this.get('currentlyDataDisplayingIsCalendar')) === 1) {
                // min_year max_year min_quarter max_quarter must change depending on fiscal or calendar
                min_year = parseInt(minMaxObj['MIN_CALENDER_YEAR'], 10);
                max_year = parseInt(minMaxObj['MAX_CALENDER_YEAR'], 10);
                min_quarter = parseInt(minMaxObj['MIN_CAL_QUARTER_REQUESTED'], 10);
                max_quarter = parseInt(minMaxObj['MAX_CAL_QUARTER_REQUESTED'], 10);
            } else if (parseInt(this.get('currentlyDataDisplayingIsCalendar')) === 0) {
                // if period basis is fiscal
                min_year = parseInt(minMaxObj['MIN_DUR_YEAR'], 10);
                max_year = parseInt(minMaxObj['MAX_DUR_YEAR'], 10);
                min_quarter = parseInt(minMaxObj['MIN_QUARTER_REQUESTED'], 10);
                max_quarter = parseInt(minMaxObj['MAX_QUARTER_REQUESTED'], 10);
            }

            if (minMaxObj) {
                for (i = min_year; i <= max_year; i++) {
                    for (j = 1; j <= k; j++) {
                        if (i === min_year) {
                            if (j < min_quarter) {
                                j = min_quarter;
                            }
                        } else if (i === max_year) {
                            k = max_quarter;
                        }

                        if (j === 1) {
                            keyListDate.push({ view: i + '~' + j, des: 'Q' + j + ' ' + i });
                        } else {
                            keyListDate.push({ view: i + '~' + '1' + j, des: 'Q' + j + ' ' + i });
                        }
                    }
                }
            }

            var keys = this.get('keyListView'),
                array = [],
                temp;
            Ember['default'].$.each(keys, function (index, key) {
                temp = that.get('app').lang.labels[key.des];
                key.des = temp;
                array.push(key);
            });

            var keyListView = array;
            keyListDate.reverse(); // to invert the upper most value of the dropdown

            this.set('viewDropdownListView', keyListView);
            this.set('viewDropdownListPeriod', keyListDate);
            this.set('defSelectView', keyListView[1]);
            this.set('currentlyDataDisplayingIsCalendar', that.get('isCalendar'));
            this.set('currentlyDataDisplayingViewType', that.get('viewType'));

            if (keyListDate.length > 0) {
                this.set('defSelectPeriod', keyListDate[0]);
                this.set('filterPeriod', keyListDate[0].view);
            }
        },

        _setDropdown: (function () {
            var view = this.get('viewType');
            var keyListDate,
                i,
                j,
                noOfQuarters = 4;
            var minMaxObj = this.get('minMaxObject');
            keyListDate = [];

            var min_year, max_year, min_quarter, max_quarter;
            if (parseInt(this.get('isCalendar')) === 1) {
                // min_year max_year min_quarter max_quarter must change depending on fiscal or calendar
                min_year = parseInt(minMaxObj['MIN_CALENDER_YEAR'], 10);
                max_year = parseInt(minMaxObj['MAX_CALENDER_YEAR'], 10);
                min_quarter = parseInt(minMaxObj['MIN_CAL_QUARTER_REQUESTED'], 10);
                max_quarter = parseInt(minMaxObj['MAX_CAL_QUARTER_REQUESTED'], 10);
            } else if (parseInt(this.get('isCalendar')) === 0) {
                // if period basis is fiscal
                min_year = parseInt(minMaxObj['MIN_DUR_YEAR'], 10);
                max_year = parseInt(minMaxObj['MAX_DUR_YEAR'], 10);
                min_quarter = parseInt(minMaxObj['MIN_QUARTER_REQUESTED'], 10);
                max_quarter = parseInt(minMaxObj['MAX_QUARTER_REQUESTED'], 10);
            }

            if (view === 'A') {
                this.set('viewType', FundamentalConstants['default'].ViewTypes.VIEW_TYPE_ANNUAL);
                for (i = min_year; i <= max_year - 1; i++) {
                    // one subtracted from max calender year as we dont know when the BE will add the annual data to max year
                    keyListDate.push({ view: i + '~' + '5', des: i });
                }
            } else if (view === 'Q') {
                this.set('viewType', FundamentalConstants['default'].ViewTypes.VIEW_TYPE_QUARTER);
                for (i = min_year; i <= max_year; i++) {
                    for (j = 1; j <= noOfQuarters; j++) {
                        if (i === min_year) {
                            if (j < min_quarter) {
                                j = min_quarter;
                            }
                        } else if (i === max_year) {
                            noOfQuarters = max_quarter;
                        }

                        if (j === 1) {
                            keyListDate.push({ view: i + '~' + j, des: 'Q' + j + ' ' + i });
                        } else {
                            keyListDate.push({ view: i + '~' + '1' + j, des: 'Q' + j + ' ' + i });
                        }
                    }
                }
            } else if (view === 'C') {
                this.set('viewType', FundamentalConstants['default'].ViewTypes.VIEW_TYPE_TTM);
                for (i = min_year; i <= max_year; i++) {
                    for (j = 1; j <= noOfQuarters; j++) {
                        if (i === min_year) {
                            if (j < min_quarter) {
                                j = min_quarter;
                            }
                        } else if (i === max_year) {
                            noOfQuarters = max_quarter;
                        }
                        keyListDate.push({ view: i + '~' + '2' + j, des: 'Q' + j + ' ' + i });
                    }
                }
            } else if (view === 'T') {
                this.set('viewType', FundamentalConstants['default'].ViewTypes.VIEW_TYPE_CUMULATIVE);
                for (i = min_year; i <= max_year; i++) {
                    for (j = 1; j <= noOfQuarters; j++) {
                        if (i === min_year) {
                            if (j < min_quarter) {
                                j = min_quarter;
                            }
                        } else if (i === max_year) {
                            noOfQuarters = max_quarter;
                        }

                        if (i === 4) {
                            keyListDate.push({ view: i + '~' + '5', des: 'Q' + j + ' ' + i });
                        } else {
                            keyListDate.push({ view: i + '~' + j, des: 'Q' + j + ' ' + i });
                        }
                    }
                }
            }

            keyListDate.reverse(); // to invert the upper most value of the dropdown
            this.set('viewDropdownListPeriod', keyListDate);
            this.set('filterPeriod', keyListDate.objectAt(0).view);
        }).observes('isCalendar'),

        actions: {
            setSymbol_1: function setSymbol_1(symbol) {
                this._setSymbol(1, symbol);
                this.set('searchSymbol_1', symbol.get('sym') + ' ' + symbol.get('sDes'));
            },

            setSymbol_2: function setSymbol_2(symbol) {
                this._setSymbol(2, symbol);
                this.set('searchSymbol_2', symbol.get('sym') + ' ' + symbol.get('sDes'));
            },

            setSymbol_3: function setSymbol_3(symbol) {
                this._setSymbol(3, symbol);
                this.set('searchSymbol_3', symbol.get('sym') + ' ' + symbol.get('sDes'));
            },

            setSymbol_4: function setSymbol_4(symbol) {
                this._setSymbol(4, symbol);
                this.set('searchSymbol_4', symbol.get('sym') + ' ' + symbol.get('sDes'));
            },

            onSelectingView: function onSelectingView(item) {
                switch (item.view) {
                    case 1:
                        this.set('viewType', 'A');
                        this._setDropdown();break;
                    case 2:
                        this.set('viewType', 'Q');
                        this._setDropdown();break;
                    case 3:
                        this.set('viewType', 'C');
                        this._setDropdown();break;
                    case 4:
                        this.set('viewType', 'T');
                        this._setDropdown();break;
                    default:
                        return;
                }
            },

            onClickView: function onClickView() {
                this.set('currentlyDataDisplayingIsCalendar', this.get('isCalendar'));
                this.set('currentlyDataDisplayingViewType', this.get('viewType'));
                var symbols = this.get('symbolsList');

                this.startLoadingProgress();

                for (var i = 0; i < symbols.length; i++) {
                    if (symbols[i] !== undefined) {
                        this.startLoadingProgress();

                        if (i === 0) {
                            this.sendDataRequest(this.get('exg'), symbols[0], this.get('viewType'), i, false);
                        } else {
                            this.sendDataRequest(symbols[i].get('exg'), symbols[i].get('sym'), this.get('viewType'), i, false);
                        }
                    }
                }
            },

            onSelectingPeriod: function onSelectingPeriod(item) {
                this.set('filterPeriod', item.view);
            },

            showSearchPopup_1: function showSearchPopup_1() {
                var modal = this.get('symbolSearch_1');
                modal.send('showModalPopup');
            },

            closeSearchPopup_1: function closeSearchPopup_1() {
                var modal = this.get('symbolSearch_1');
                modal.send('closeModalPopup');
            },

            showSearchPopup_2: function showSearchPopup_2() {
                var modal = this.get('symbolSearch_2');
                modal.send('showModalPopup');
            },

            closeSearchPopup_2: function closeSearchPopup_2() {
                var modal = this.get('symbolSearch_2');
                modal.send('closeModalPopup');
            },

            showSearchPopup_3: function showSearchPopup_3() {
                var modal = this.get('symbolSearch_3');
                modal.send('showModalPopup');
            },

            closeSearchPopup_3: function closeSearchPopup_3() {
                var modal = this.get('symbolSearch_3');
                modal.send('closeModalPopup');
            },

            showSearchPopup_4: function showSearchPopup_4() {
                var modal = this.get('symbolSearch_4');
                modal.send('showModalPopup');
            },

            closeSearchPopup_4: function closeSearchPopup_4() {
                var modal = this.get('symbolSearch_4');
                modal.send('closeModalPopup');
            }
        }
    });

    Ember['default'].Handlebars.helper('getDisplayRatio', function (dataObject, fieldID) {
        // ratios need not to be converted to millions so a this helper is specially used in Ratios page
        var temp;
        if (dataObject) {
            temp = utils['default'].formatters.formatNumber(dataObject[fieldID], 2); // todo [Banusha] currently put decimal places to 2. Later need to add symbol decimal places

            if (temp.charAt(0) === '-' && temp.charAt(1) !== '-') {
                temp = '(' + temp.substring(1, temp.length) + ')';
            }
            return temp;
        } else {
            return '';
        }
    });

    Ember['default'].Handlebars.helper('getSubTitle', function (subTitleObj) {
        if (subTitleObj[0]) {
            return subTitleObj[0].groupHeadDesc;
        }
        return '';
    });

});