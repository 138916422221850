define('ir-app/controllers/ir-widgets/index-overview-widgets/index-summary', ['exports', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/models/price/price-constants'], function (exports, BaseController, priceService, sharedService, priceConstants) {

    'use strict';

    /**
     * Created by chirang on 6/3/2016.
     */
    exports['default'] = BaseController['default'].extend({

        stock: {},
        isMainIndexQuote: false,

        onPrepareData: function onPrepareData() {
            var that = this;

            var stock = priceService['default'].stockDS.getStock(this.get('exg'), this.get('sym'));

            var basicInfo = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, function () {
                that.onDataSuccess();
            }, function () {
                that.onDataError();
            }, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyBasicInfo);

            this.set('basicInfo', basicInfo);
            this.set('stock', stock);

            priceService['default'].sendSymbolValidationRequest(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, undefined, undefined, 7);
            priceService['default'].addIndexRequest(this.get('exg'), this.get('sym'));
        },

        onCheckDataAvailability: function onCheckDataAvailability() {
            var companyProfObj = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage);

            if (companyProfObj) {
                return this.utils.validators.isAvailable(companyProfObj.compID);
            }
            return false;
        },

        onClearData: function onClearData() {
            priceService['default'].removeIndexRequest(this.get('exg'), this.get('sym'));
            this.set('stock', '');
        }
    });

});