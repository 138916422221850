define('ir-app/controllers/ir-mobile/chart-widgets/chart-widget', ['exports', 'ember', 'ir-app/models/chart/chart-constants', 'ir-app/components/stk-specific-components/high-chart/dynamic-high-chart', 'ir-app/utils/utils', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/controllers/ir-mobile/chart-widgets/basic-chart-widget', 'ir-app/config/app-config', 'ir-app/config/user-settings', 'ir-app/models/price/price-constants'], function (exports, Ember, ChartConstants, DynamicHighChart, utils, priceService, sharedService, BasicChartWidget, AppConfig, userSettings, priceConstants) {

  'use strict';

  exports['default'] = BasicChartWidget['default'].extend({

    volumeViewEnabled: null,
    chartStyle: ChartConstants['default'].ChartStyle.Line,
    plotStyle: null,
    indicatorList: Ember['default'].A(),
    chartCompareSymbolKeyList: Ember['default'].A(),
    closeMode: false,
    isAR: false,
    baseURL: 'demo',
    markerID: '',
    formattedDate: '',
    caMarkersArr: '',
    enableCross: true,
    detachWidth: 1100,
    compareSymbolParams: [], // compare symbol parameters
    compareViewEnabled: false,
    compareSymbolEnabled: false, // Enable the compare symbol mode
    chartIntervalEnabled: false, // Enable the chart intervals
    placeHolder: undefined,
    chartStyleBeforeCompare: undefined, // Chart style use before the compare symbol
    portalExchangeList: undefined,
    lang: '',
    IRChartPeriodTab: ChartConstants['default'].IRChartPeriodTab,
    IRChartPeriodTabAR: ChartConstants['default'].IRChartPeriodTab,
    exportDisabled: false,
    exchange: null,
    factSheetViewEnable: false,
    symbolCount: 0,
    open: null,
    symArray: Ember['default'].A(),
    high: null,
    points: '',
    isPoints: true,
    current: 1,
    close: null,
    low: null,
    customView: false,
    compareSym: [],
    modalWidth: 0,
    peerIndicesSymbol: [],
    peer: [{
      exg: "TDWL",
      inst: "0",
      lDes: "Riyad Bank",
      sDes: "RIBL",
      sym: "1010"
    }],
    indices: [{
      exg: "TDWL",
      inst: "7",
      lDes: "Tadawul All Share Index (TASI)",
      sDes: "TASI",
      sym: "TASI"
    }, {
      exg: "TDWL",
      inst: "7",
      lDes: "Banks",
      sDes: "TBNI",
      sym: "TBNI"
    }],
    actTypeId: 7,
    pageSize: 20,
    ShowHide: false,
    markerArray: [],
    ShowHideANN: false,

    onAfterRender: function onAfterRender() {
      var that = this;
      that._super();
      //that.set('chartContainerWidth' , Ember.$('#section2').width() + 10)
      //console.log(Ember.$('#section2').width())
      Ember['default'].$('#volumeCheckBox :checkbox').click(function () {
        // this will contain a reference to the checkbox
        that._onClickVolumeShow();
      });

      setTimeout(function () {
        that.set('wait', true);
        that.set('isLoading', false);
      }, 2000);

      /* _setSelectedTab is called only if symbol (sym) is set and _onPrepareData is called.
       This check is added to avoid setting invalid tab in MainIndex Chart : Decypha Mobile */

      if (this.utils.validators.isAvailable(this.get('sym'))) {
        that._setSelectedTab();
      }
    },

    onPrepareData: function onPrepareData() {
      var that = this;
      if (this.utils.validators.isAvailable(this.get('companyId'))) {
        priceService['default'].companyProfileDS.getStoredObjectByCompanyId(this.get('companyId'), sharedService['default'].userSettings.currentLanguage, function () {
          var _basicInfo = priceService['default'].companyProfileDS.getStoredObjectByCompanyId(that.get('companyId'), sharedService['default'].userSettings.currentLanguage);
          that.set('sym', _basicInfo.sym);
          that.set('exg', _basicInfo.exg);
          that._onPrepareData();
        }, function () {}, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyBasicInfo);
      } else if (!this.utils.validators.isAvailable(this.get('sym'))) {
        //Only Exchange is Available. Main Index is Taken as the Symbol:Decypha Mobile
        var exchange = priceService['default'].exchangeDS.getExchange(this.get('exg'));
        this.set('exchange', exchange);
        priceService['default'].downloadExchangeProperty(this.get('exg'), sharedService['default'].userSettings.currentLanguage);
      } else {
        that._onPrepareData();
      }

      sharedService['default'].userSettings.currentLanguage == 'AR' ? this.set('isAR', true) : this.set('isAR', false);
      this.updateSymbolArray(this.get('sym'), 0);
    },
    sendAnnouncementRequest: function sendAnnouncementRequest() {
      var that = this;
      priceService['default'].announcementDS.getAnnCollectionBySymbol(this.get('exg'), this.get('symbol')).clear();

      var startDate = null;
      var endDate = this.get('toDate');
      if (this.get('fromDate') !== null) {
        startDate = new Date(this.get('fromDate'));
        startDate.setDate(startDate.getDate() - 1);
      } else if (this.get('noOfRecentDays') !== null) {
        // if fromDate is not specified, announcements will load for no of days that specified in 'noOfRecentDays', this is configurable
        endDate = new Date();
        startDate = new Date();
        startDate.setDate(startDate.getDate() - this.get('noOfRecentDays'));
      }

      priceService['default'].sendAnnouncementSearchRequest({
        exchange: this.get('exg'),
        symbol: this.get('symbol'),
        pageSize: 20,
        startDate: utils['default'].formatters.convertDateToyyyymmdd(startDate),
        endDate: utils['default'].formatters.convertDateToyyyymmdd(endDate),
        pageIndex: this.get('current') - 1,
        reqSuccessFn: function reqSuccessFn() {
          that.set('stopLoadingAnnouncement', true);
          that.onDataSuccess();
        },
        reqFailureFn: function reqFailureFn() {
          that.set('stopLoadingAnnouncement', true);
          that.onDataError();
        }
      }, priceService['default'].announcementDS.getAnnCollectionBySymbol(this.get('exg'), this.get('symbol')));
    },

    onCheckDataAvailability: function onCheckDataAvailability() {

      var corporateActObj = priceService['default'].capitalActionsDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage);
      this._addAnnMarkerToArray();
      this._addCAMarkerToArray();
      this._addCAMarkerToLineChart();
      this._addANNMarkerToLineChart();
    },

    waitForCloseData: function waitForCloseData() {},

    observeCPAC: (function () {
      var that = this;

      var closeArray = this.get('chartCloseDataArray');
      var func = setTimeout(function () {
        if (closeArray.length > 0) {
          that._addCAMarkerToArray(closeArray);
          that._addAnnMarkerToArray();
        } else {
          func();
        }
      }, 1000);
    }).observes('chartCloseDataArray'),

    _addCAMarkerToArray: function _addCAMarkerToArray() {
      var closeArray = this.get('chartCloseDataArray');
      var corporateActObj = priceService['default'].capitalActionsDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage);
      if (corporateActObj) {

        closeArray.forEach(function (point, i) {

          if (point.data && point.marker == undefined) {
            (function () {
              var pointDate = Date.parse(point.data.date);
              corporateActObj.capitalAction.forEach(function (action) {

                var date = utils['default'].formatters.convertStringToDate(action.anDate);

                var mili = Date.parse(date);

                if (pointDate == mili) {

                  // let markPoint = {x: point.x ,y: point.y , marker:{enabled:false, name: action.typeName, id: 'CA', zIndex: 2, height: 20, width: 20, symbol: "url(../demo/assets/img/CPAC.png)"}};
                  var markPoint = { x: point.x, y: point.y, dataLabels: {
                      enabled: false,
                      format: '<img style="width: 20px; height: 20px" src="../mouwasat/assets/img/CPAC.png"></img>',
                      verticalAlign: 'bottom',
                      y: 0,
                      id: 'CA',
                      name: action.typeName
                    } };
                  closeArray[i] = markPoint;
                }
              });
            })();
          }
        });
      }
      this.set('markerArray', closeArray);
    },
    _addCAMarkerToLineChart: function _addCAMarkerToLineChart() {
      var closeArray = this.get('chartDataArray');
      var corporateActObj = priceService['default'].capitalActionsDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage);
      if (corporateActObj) {

        closeArray.forEach(function (point, i) {

          if (point.marker == undefined) {

            corporateActObj.capitalAction.forEach(function (action) {
              var date = utils['default'].formatters.convertStringToDate(action.anDate);

              var mili = Date.parse(date);

              if (point.x == mili) {

                var markPoint = { open: point.open, high: point.high, low: point.low, close: point.close, x: point.x, y: point.y, dataLabels: {
                    enabled: false,
                    format: '<img style="width: 20px; height: 20px" src="../mouwasat/assets/img/CPAC.png"></img>',
                    verticalAlign: 'bottom',
                    y: 0,
                    id: 'CA',
                    name: action.typeName
                  } };
                closeArray[i] = markPoint;
              }
            });
          }
        });
      }

      this.set('chartDataArray', closeArray);
      this.set('markerArray', closeArray);

      return closeArray;
    },

    _addANNMarkerToLineChart: function _addANNMarkerToLineChart() {
      var closeArray = this.get('chartDataArray');
      var announcements = priceService['default'].announcementDS.getAnnCollectionBySymbol(this.get('exg'), this.get('symbol'));

      if (announcements) {

        closeArray.forEach(function (point, i) {

          if (point.marker == undefined) {

            announcements.forEach(function (ann) {
              var date = utils['default'].formatters.convertStringToDate(ann.dt.substring(0, 8));

              var mili = Date.parse(date);

              if (point.x == mili) {

                //let markPoint = {x: point.x ,y: point.y , marker:{enabled:false, id: 'AN', height: 20, width: 20, symbol: "url(..//assets/img/ann.png)"}};
                var markPoint = { open: point.open, high: point.high, low: point.low, close: point.close, x: point.x, y: point.y, dataLabels: {
                    enabled: false,
                    format: '<img style="width: 20px; height: 20px" src="../mouwasat/assets/img/ann.png"></img>',
                    verticalAlign: 'bottom',
                    y: 0,
                    id: 'AN',
                    name: ann.hed
                  } };
                closeArray[i] = markPoint;
              }
            });
          }
        });
      }

      this.set('chartDataArray', closeArray);
      this.set('markerArray', closeArray);

      return closeArray;
    },
    _addAnnMarkerToArray: function _addAnnMarkerToArray() {
      var closeArray = this.get('chartCloseDataArray');
      var announcements = priceService['default'].announcementDS.getAnnCollectionBySymbol(this.get('exg'), this.get('symbol'));
      if (announcements) {
        closeArray.forEach(function (point, i) {

          if (point.data && point.marker == undefined) {
            (function () {
              var pointDate = Date.parse(point.data.date);
              announcements.forEach(function (ann) {
                var date = utils['default'].formatters.convertStringToDate(ann.dt.substring(0, 8));

                var mili = Date.parse(date);

                if (pointDate == mili) {

                  //let markPoint = {x: point.x ,y: point.y , marker:{enabled:false, name: ann.hed, id: 'AN', height: 20, width: 20, symbol: "url(../demo/assets/img/ann.png)"}};
                  var markPoint = { x: point.x, y: point.y, dataLabels: {
                      enabled: false,
                      format: '<img style="width: 20px; height: 20px" src="../mouwasat/assets/img/ann.png"></img>',
                      verticalAlign: 'bottom',
                      y: 0,
                      id: 'AN',
                      name: ann.hed
                    } };
                  closeArray[i] = markPoint;
                }
              });
            })();
          }
        });
      }
      this.set('markerArray', closeArray);
    },

    _loadCapitalActionPage: function _loadCapitalActionPage() {
      var that = this;
      var searchParams = this.get('searchParams');
      if (!searchParams) {
        var searchPara = {
          pageSize: this.get('pageSize'),
          pageIdx: 0,
          actType: this.get('actTypeId'),
          custView: this.get('customView'),
          idList: this.get('typeIds')

        };
        this.set('searchParams', searchPara);
        this.set('current', 1);
      }

      var corporateAction = priceService['default'].capitalActionsDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, priceConstants['default'].DataTypes.CorporateActionDataStore.CapitalActionInfo, that.get('searchParams'), function () {
        that.set('corporateAction', corporateAction);
        that.onDataSuccess();
      }, function () {
        that.onDataError();
      }, this.get('isCachedData')).capitalAction;
    },

    _onPrepareData: function _onPrepareData() {
      priceService['default'].addSymbolRequest(this.get('exg'), this.get('sym'));
      var that = this;
      this.set('placeHolder', that.get('app').lang.labels.enterCompareSym);
      that.set('compareSymbolParams', Ember['default'].A());
      that.set('chartCompareSymbolKeyList', Ember['default'].A());
      that.onLoadWidget();
      that.set('plotStyle', ChartConstants['default'].IRChartTypes.Line);
      that.set('indicatorList', Ember['default'].A());
      that.set('closeMode', false);
      that.set('volumeViewEnabled', true);
      if (userSettings['default'].chartDefaults !== undefined) {
        that.set('chartCategory', userSettings['default'].chartDefaults.chartCategory);
        that.set('initialChartCategory', userSettings['default'].chartDefaults.initialChartCategory);
        that.set('chartDataLevel', userSettings['default'].chartDefaults.chartDataLevel);
        that.set('chartViewPeriod', userSettings['default'].chartDefaults.chartViewPeriod);
        that.set('chartInterval', userSettings['default'].chartDefaults.chartInterval);
        that.set('volumeViewEnabled', userSettings['default'].chartDefaults.volumeViewEnabled);
      }
      if (that.get('isPortalChart')) {
        that.set('portalExchangeList', AppConfig['default'].exchangeList.alArabia);
      }
      if (that.get('chartCategory').ID === 1) {

        that.set('isHistory', true);
      } else {

        that.set('isHistory', false);
      }
      that.registerForMarketStates();
      that.set('lang', sharedService['default'].userSettings.currentLanguage.toLowerCase());
      that.loadChart();
      this._loadCapitalActionPage();
      this.sendAnnouncementRequest();
    },

    onMktStatChangeToPreopen: function onMktStatChangeToPreopen() {
      console.log('%c chart widger - inside the onMktStatChangeToPreopen', 'color: green; font-weight: bold;');
      if (this.get('chartViewPeriod').ChartDataLevel === ChartConstants['default'].ChartViewPeriod.OneDay.ChartDataLevel) {
        this.set('chartDataArray', Ember['default'].A());
        this.set('chartCloseDataArray', Ember['default'].A());
        this.set('chartVolumeDataArray', Ember['default'].A());
        this.resetMinMax();
        console.log('%c chart widget - chart arrays cleared', 'color: green; font-weight: bold;');
        var compareList = this.get('chartCompareSymbolKeyList');
        for (var i = 0; compareList.length; i++) {
          var key = compareList[i];
          this._getCompareList(key).clear();
        }
      }
    },

    clearDataOnTabChange: function clearDataOnTabChange() {
      this.get('chartDataArray').clear();
      this.get('chartCloseDataArray').clear();
      this.get('chartVolumeDataArray').clear();

      var compareList = this.get('chartCompareSymbolKeyList');
      for (var i = 0; i < compareList.length; i++) {
        var key = compareList[i];
        this._getCompareList(key).clear();
      }
    },

    onClearData: function onClearData() {
      // this use for remove all indicators when change modules.
      var that = this;
      that.set('closeMode', true);

      // removing all the compare symbol and index subscriptions
      var compareList = this.get('chartCompareSymbolKeyList');
      for (var i = 0; i < compareList.length; i++) {
        var key = compareList[i].split('~');
        priceService['default'].removeIndexRequest(key[0], key[1]);
        priceService['default'].removeSymbolRequest(key[0], key[1]);
      }
      //remove main symbol subscription
      priceService['default'].removeSymbolRequest(this.get('exg'), this.get('sym'));
      that._super();
    },

    chartMode: (function () {
      return '';
    }).property(),

    chartOptions: (function () {
      var that = this;
      return {
        colors: ['#3e70e3', '#ff8000', '#d700f0', '#327afe', '#00b91a', '#00ffba', '#ff0e63'],
        chart: {
          backgroundColor: that.get('thm').themeName.colors.backgroundColor,
          marginLeft: 0,
          marginRight: 0,

          style: {
            fontFamily: 'FontAwesome'
          }
        },
        title: {
          text: ''
        },
        xAxis: {
          crosshair: true,
          type: 'datetime',
          gridLineWidth: '1',
          gridLineColor: that.get('thm').themeName.colors.gridLineColor
        },
        yAxis: [{
          labels: that._mainYAxisLabels(),
          opposite: that.axisYIsOpposite(),
          height: '80%',
          gridLineWidth: '1',
          gridLineColor: that.get('thm').themeName.colors.gridLineColor,
          plotLines: [{
            value: 0,
            color: '#C4C5C6',
            width: 2,
            dashStyle: 'Solid',
            zIndex: 1
          }]
        }, {
          labels: that._secondaryYAxisLabels(),
          opposite: that.axisYIsOpposite(),
          top: '85%',
          height: '15%',
          gridLineWidth: '0',
          title: {
            text: this.get('app').lang.labels.volume,
            align: 'high',
            offset: 0,
            rotation: 0,
            x: this.get('lang') === 'en' ? -100 : -82
          }
        }],
        legend: that.chartLegendParams(),
        rangeSelector: {
          enabled: false
        },

        exporting: {
          enabled: !that.get('exportDisabled'),
          filename: that.get('sym') + '-data',
          buttons: {
            contextButton: that.exportButtonParams()
          }
        },

        navigator: {
          enabled: false
        },
        scrollbar: {
          enabled: false
        },
        tooltip: {
          enabledIndicators: true,
          useHTML: true,
          formatter: function formatter(tooltip) {

            var tooltipText = '';

            this.points.forEach(function (point) {
              if (point.point.dataLabels && point.point.dataLabels.enabled) {

                tooltipText = point.point.dataLabels.name;
              }
              if (that.get('isPoints') == false) {
                that.updateSymbolArray(point.series.name, point.y, point.point.color);
              }
            });

            if (tooltipText == '') {
              return tooltip.defaultFormatter.call(this, tooltip);
            } else {
              return tooltipText;
            }
          },
          shared: true
        },
        plotOptions: {
          series: {
            cursor: 'pointer',
            point: {
              events: {
                mouseOver: function mouseOver() {

                  if (this.data) {
                    that.set('open', this.data.open), that.set('high', this.data.high), that.set('close', this.data.close), that.set('low', this.data.low);
                    that.set('formattedDate', that.getFormatted(this.data.date));
                  }
                }
              }

            },
            animation: {
              duration: 3000
            },
            dataLabels: {
              useHTML: true
            },
            fillColor: that.chartFillColor()
          }
        },
        categoryField: "date"
      };
    }).property(),
    getFormatted: function getFormatted(date) {
      var date = utils['default'].formatters.convertDateToyyyymmdd(date);

      var year = date.substring(0, 4);
      var month = date.substring(4, 6);
      var day = date.substring(6, 8);

      return day + '/' + month + '/' + year;
    },

    getIndicators: (function () {
      return this.indicatorList;
    }).property('indicatorList.@each'),

    chartData: (function () {
      var that = this;
      return [this._getPlotTypeAndStyle(this.get('plotStyle')), {
        type: 'column',
        name: this.get('app').lang.labels.volume,
        turboThreshold: 0,
        data: this.get('chartVolumeDataArray'),
        yAxis: 1,
        color: that.get('thm').themeName.colors.volumeColor,
        dataGrouping: that.dataGroupingEnableDisable(),
        showInLegend: false
      }];
    }).property('plotStyle', 'chartDataArray', 'chartDataArray.@each', 'chartCloseDataArray', 'chartCloseDataArray.@each'),

    periods: (function () {
      var arrPeriods = Ember['default'].A();
      var lang = sharedService['default'].userSettings.currentLanguage.toLowerCase();
      if (lang == 'ar') {
        Ember['default'].$.each(this.get('IRChartPeriodTabAR'), function (key, value) {
          if (value) {
            arrPeriods.pushObject(ChartConstants['default'].ChartViewPeriodAR[key]);
          }
        });
      } else {
        Ember['default'].$.each(this.get('IRChartPeriodTab'), function (key, value) {
          if (value) {
            arrPeriods.pushObject(ChartConstants['default'].ChartViewPeriod[key]);
          }
        });
      }
      return arrPeriods;
    }).property(),

    chartStyles: (function () {
      var arrStyles = Ember['default'].A();
      var that = this;
      var chartStyle = null;
      // Display name is changed when changing a language
      var selectedStyle = that.get('chartStyle');

      Ember['default'].set(selectedStyle, 'DisplayName', that.get('app').lang.labels[selectedStyle.LanguageTag]);

      Ember['default'].$.each(ChartConstants['default'].IRChartStyle, function (key) {
        chartStyle = ChartConstants['default'].IRChartStyle[key];
        Ember['default'].set(chartStyle, 'DisplayName', that.get('app').lang.labels[chartStyle.LanguageTag]);
        arrStyles.pushObject(chartStyle);
      });

      return arrStyles;
    }).property(),

    getChartInterval: (function () {
      var arrStyles = Ember['default'].A();
      var that = this;
      var chartInt = null;
      var selectedInt = that.get('chartInterval');
      Ember['default'].set(selectedInt, 'DisplayName', that.get('app').lang.labels[selectedInt.LanguageTag]);
      Ember['default'].$.each(ChartConstants['default'].ChartViewInterval, function (key) {
        chartInt = ChartConstants['default'].ChartViewInterval[key];
        if (that.get('chartCategory').ID === ChartConstants['default'].ChartCategory.History.ID) {
          // History
          if (chartInt.IsHistory) {
            Ember['default'].set(chartInt, 'DisplayName', that.get('app').lang.labels[chartInt.LanguageTag]);
            Ember['default'].set(chartInt, 'IsAdded', false);
            arrStyles.pushObject(chartInt);
          }
        } else {
          // Intraday
          if (!chartInt.IsHistory) {
            Ember['default'].set(chartInt, 'DisplayName', that.get('app').lang.labels[chartInt.LanguageTag]);
            Ember['default'].set(chartInt, 'IsAdded', false);
            arrStyles.pushObject(chartInt);
          }
        }
      });

      return arrStyles;
    }).property('chartCategory'),

    getDisplayIndicators: (function () {
      var arrStyles = Ember['default'].A();
      var chartInd = null;
      var that = this;

      if (this.get('isPortalChart')) {
        Ember['default'].$.each(ChartConstants['default'].PortalIndicators, function (key) {
          chartInd = ChartConstants['default'].PortalIndicators[key];
          //    Ember.set(chartInd, 'DisplayName', chartInd.LanguageTag);
          Ember['default'].set(chartInd, 'DisplayName', that.get('app').lang.labels[chartInd.LanguageTag]);

          Ember['default'].set(chartInd, 'IsAdded', false);
          if (chartInd.Params.yAxis !== undefined) {
            if (userSettings['default'].chartDefaults.indicatorYAxis !== undefined) {
              Ember['default'].set(chartInd, 'Params.yAxis', userSettings['default'].chartDefaults.indicatorYAxis);
            }
          }
          arrStyles.pushObject(chartInd);
        });
      } else {
        Ember['default'].$.each(ChartConstants['default'].IRIndicators, function (key) {
          chartInd = ChartConstants['default'].IRIndicators[key];
          //  Ember.set(chartInd, 'DisplayName', chartInd.LanguageTag);
          Ember['default'].set(chartInd, 'DisplayName', that.get('app').lang.labels[chartInd.LanguageTag]);

          Ember['default'].set(chartInd, 'IsAdded', false);
          arrStyles.pushObject(chartInd);
        });
      }

      return arrStyles;
    }).property(),

    _setSelectedTab: function _setSelectedTab() {
      var that = this;
      Ember['default'].$('ul.nav a[href=' + that.get('chartViewPeriod').ID + ']').parent().addClass('active');
    },

    _deselectTab: function _deselectTab() {
      var that = this;
      if (that.get('chartViewPeriod')) {
        Ember['default'].$('ul.nav a[href=' + that.get('chartViewPeriod').ID + ']').parent().removeClass('active');
      }
    },

    _onChangePeriod: function _onChangePeriod(chartPeriod) {
      var that = this;
      try {
        var isDifferentPeriod = that.get('chartViewPeriod').ID !== chartPeriod.ID;
        this.get('graphDataManager').addChartDateRange(undefined, undefined);
        if (this.get('fromDate') || this.get('toDate')) {

          isDifferentPeriod = true;
        }
        this.set('fromDate', undefined);
        this.set('toDate', undefined);

        if (isDifferentPeriod) {
          that.clearDataOnTabChange(); // some symbols can have only history data thus clearing the chart is needed
          var gdm = that.get('graphDataManager');
          var differentCategory = that.get('chartViewPeriod').IsHistory !== chartPeriod.IsHistory;
          that.set('chartViewPeriod', chartPeriod);

          if (differentCategory) {
            that.set('chartCategory', chartPeriod.IsHistory ? ChartConstants['default'].ChartCategory.History : ChartConstants['default'].ChartCategory.Intraday);
            that.set('chartInterval', ChartConstants['default'].ChartViewInterval[chartPeriod.DefaultInterval]);
          }
          that.set('dataLoadingComplete', false);
          if (that.get('chartCategory').ID === 1) {

            that.set('isHistory', true);
          } else {

            that.set('isHistory', false);
          }
          gdm.refineGraphData({
            chartCategory: that.get('chartCategory'),
            chartDataLevel: chartPeriod.ChartDataLevel,
            chartViewPeriod: chartPeriod
          });
        }
      } catch (e) {
        utils['default'].logger.logError('[Chart Widget] onChangePeriod() ' + e);
      }
    },

    _onClickVolumeShow: function _onClickVolumeShow() {
      this.set('volumeViewEnabled', !this.get('volumeViewEnabled'));
    },

    _setChartStyle: function _setChartStyle(option) {
      var that = this;
      that.set('chartStyle', option);
      that._setPlotStyle(option);
    },

    _setIndicator: function _setIndicator(option) {
      if (!option.IsAdded) {
        this.indicatorList.pushObject(option);
        Ember['default'].set(option, 'IsAdded', true);
      } else {
        var obj = this.indicatorList.findBy('ID', option.ID);
        if (obj !== undefined) {
          this.indicatorList.removeObject(obj);
          Ember['default'].set(option, 'IsAdded', false);
        }
      }
    },

    _setInterval: function _setInterval(option) {
      this.set('chartInterval', option);
    },

    _setPlotStyle: function _setPlotStyle(option) {
      var that = this;
      if (option.ID === ChartConstants['default'].IRChartStyle.Area.ID) {
        that.set('plotStyle', ChartConstants['default'].IRChartTypes.Area); // area
      } else if (option.ID === ChartConstants['default'].IRChartStyle.Candle.ID) {
          that.set('plotStyle', ChartConstants['default'].IRChartTypes.CandleStick); // candle
        } else if (option.ID === ChartConstants['default'].IRChartStyle.OHLC.ID) {
            that.set('plotStyle', ChartConstants['default'].IRChartTypes.OHLC); // ohlc
          } else if (option.ID === ChartConstants['default'].IRChartStyle.Line.ID) {
              that.set('plotStyle', ChartConstants['default'].IRChartTypes.Line); // Line
            } else {
                that.set('plotStyle', ChartConstants['default'].IRChartTypes.Area);
              }
    },

    _getPlotTypeAndStyle: function _getPlotTypeAndStyle(type) {
      var that = this;
      if (type === ChartConstants['default'].IRChartTypes.CandleStick) {
        return that._getCandlestickChartType();
      } else if (type === ChartConstants['default'].IRChartTypes.Area) {
        return that._getAreaChartType();
      } else if (type === ChartConstants['default'].IRChartTypes.Line) {
        return that._getLineChartType();
      } else if (type === ChartConstants['default'].IRChartTypes.OHLC) {
        return that._getOHLCChartType();
      } else {
        return that._getAreaChartType();
      }
    },

    _getCandlestickChartType: function _getCandlestickChartType() {
      var that = this;
      this._addANNMarkerToLineChart();
      this._addCAMarkerToLineChart();
      return {
        type: 'candlestick',
        data: this.get('chartDataArray'),
        indicatorData: this.get('chartDataArray'),
        turboThreshold: 0,
        id: 'primary',
        name: '<span style="color: ' + that.get('thm').themeName.colors.fontColor + '">' + that.get('stock').sDes + '</span>',
        color: that.get('thm').themeName.colors.downColor,
        upColor: that.get('thm').themeName.colors.upColor,
        lineColor: that.get('thm').themeName.colors.candleLineColor,
        upLineColor: that.get('thm').themeName.colors.candleUpLineColor,
        lineWidth: 1.5,
        states: {
          hover: {
            enabled: false
          }
        },
        dataGrouping: that.dataGroupingEnableDisable(),
        tooltip: {
          valueDecimals: that.get('valueDecimals') ? that.get('valueDecimals') : that.get('stock').deci,
          useHTML: true,
          pointFormat: that.get('app').lang.labels.open + ': <b>{point.open}</b><br/>' + that.get('app').lang.labels.high + ': <b>{point.high}</b></br>' + that.get('app').lang.labels.low + ': <b>{point.low}</b></br>' + that.get('app').lang.labels.close + ': <b>{point.close}</b></br>',
          shared: true
        },
        showInLegend: true
      };
    },

    _getAreaChartType: function _getAreaChartType() {
      var that = this;
      return {
        type: 'area',
        data: this.get('chartCloseDataArray'),
        indicatorData: this.get('chartDataArray'),
        id: 'primary',
        name: that.get('stock').sDes,
        lineColor: that.get('thm').themeName.colors.lineColor,
        lineWidth: 1.5,
        turboThreshold: 0,
        animation: { duration: 3000, delay: 2000 },
        //fillColor: that.chartFillColor(),
        //color: that.chartFillColor(),
        states: {
          hover: {
            enabled: false
          }
        },
        dataGrouping: that.dataGroupingEnableDisable(),
        tooltip: {
          valueDecimals: that.get('valueDecimals') ? that.get('valueDecimals') : that.get('stock').deci,
          useHTML: true,
          pointFormat: that.get('app').lang.labels.close + ': <b>{point.y}</b></br>',
          shared: true
        },
        showInLegend: that.get('factSheetViewEnable') === true ? false : true,
        events: {
          legendItemClick: function legendItemClick() {
            return false;
          }
        }
      };
    },

    _getLineChartType: function _getLineChartType() {
      var that = this;
      this._addAnnMarkerToArray();
      this._addCAMarkerToArray();
      return {
        type: 'line',
        data: this.get('chartCloseDataArray'),
        indicatorData: this.get('chartDataArray'),
        turboThreshold: 0,
        id: 'primary',
        name: that.get('stock').sDes,
        color: that.get('thm').themeName.colors.lineColor,
        lineWidth: 1,
        animation: { duration: 3000, delay: 2000 },
        states: {
          hover: {
            enabled: true
          }
        },
        dataGrouping: that.dataGroupingEnableDisable(),
        tooltip: {
          valueDecimals: that.get('valueDecimals') ? that.get('valueDecimals') : that.get('stock').deci,
          useHTML: true,
          pointFormat: that.get('app').lang.labels.close + ': <b>{point.y}</b></br>',
          shared: true
        },
        marker: {
          zIndex: 3
        },
        showInLegend: true
      };
    },

    _getOHLCChartType: function _getOHLCChartType() {
      var that = this;
      return {
        type: 'ohlc',
        data: this.get('chartDataArray'),
        indicatorData: this.get('chartDataArray'),
        turboThreshold: 0,
        id: 'primary',
        name: '<span style="color: ' + that.get('thm').themeName.colors.fontColor + '">' + that.get('stock').sDes + '</span>',
        color: that.get('thm').themeName.colors.lineColor,
        dataGrouping: that.dataGroupingEnableDisable(),
        tooltip: {
          valueDecimals: that.get('valueDecimals') ? that.get('valueDecimals') : that.get('stock').deci,
          useHTML: true,
          pointFormat: that.get('app').lang.labels.open + ': <b>{point.open}</b><br/>' + that.get('app').lang.labels.high + ': <b>{point.high}</b></br>' + that.get('app').lang.labels.low + ': <b>{point.low}</b></br>' + that.get('app').lang.labels.close + ': <b>{point.close}</b></br>',
          shared: true
        },
        showInLegend: true
      };
    },

    _addCompareSymbol: function _addCompareSymbol(exchange, item) {
      var that = this;
      that.get('graphDataManager').addChartSymbol(exchange, item.sym, false);
      that.get('graphDataManager').addChartDataSubscription();

      if (parseInt(item.inst) === priceConstants['default'].InstrumentTypes.Equity) {
        priceService['default'].addSymbolRequest(exchange, item.sym);
      } else if (parseInt(item.inst) === priceConstants['default'].InstrumentTypes.Indices) {
        priceService['default'].addIndexRequest(exchange, item.sym);
      }
    },

    findSymbol: function findSymbol(key) {
      var symArr = this.get('peerIndicesSymbol');
      var value = symArr.find(function (element) {
        return key.indexOf(element) > -1;
      });

      if (value) {
        return true;
      } else {
        return false;
      }
    },

    _getCompareSymbolContent: function _getCompareSymbolContent(key) {
      var seriesLegend = '';
      var that = this;
      if (this.findSymbol(key)) {
        seriesLegend = that.getShortDesFromTheMap(key);
      } else {
        seriesLegend = that.getShortDesFromTheMap(key) + ' ' + '<span></span>';
      }

      return {
        id: key,
        type: 'line',
        turboThreshold: 0,
        data: that._getCompareList(key),
        yAxis: 0,
        name: seriesLegend,
        lineWidth: 1,
        states: {
          hover: {
            enabled: true
          }
        },
        animation: { duration: 3000, delay: 2000 },
        dataGrouping: that.dataGroupingEnableDisable(),
        tooltip: {
          shared: true,
          valueDecimals: that.get('valueDecimals') ? that.get('valueDecimals') : that.get('stock').deci,
          useHTML: true
        },
        showInLegend: true,
        events: {
          legendItemClick: function legendItemClick() {
            // var peer = this.get('peer');
            // var indices = this.get('indices')
            // var obj = peer.findBy('sym', key);
            // var obj2 = indices.findBy('sym', key);
            //
            // if(obj || obj2){
            //
            // }
            if (that.peerIndicesSymbol.indexOf(key) > -1) {} else {
              that.get('chartCompareSymbolKeyList').removeObject(key);
              that.removeShortDesFromMap(key);
            }

            return false;
          }
        }
      };
    },

    _getSymbolFromTheKey: function _getSymbolFromTheKey(key) {
      var ar = key.split(utils['default'].Constants.StringConst.Tilde);
      if (ar.length === 2) {
        return ar[1];
      }
      return key;
    },

    // Create compare symbol contents.
    _getCompareAllSymbolContent: (function () {
      var that = this;
      var compareSymbolObject;
      var key;
      that.set('compareSymbolParams', Ember['default'].A());
      for (var j = 0; j < that.get('chartCompareSymbolKeyList').length; j++) {
        key = that.get('chartCompareSymbolKeyList')[j];
        compareSymbolObject = that._getCompareSymbolContent(key);
        that.get('compareSymbolParams').pushObject(compareSymbolObject);
      }
    }).observes('chartCompareSymbolKeyList', 'chartCompareSymbolKeyList.@each'),

    compareSymbolContent: (function () {
      return this.get('compareSymbolParams');
    }).property('compareSymbolParams', 'compareSymbolParams.@each'),

    // Check compare symbol Enable/Disable
    compareSymbolMode: (function () {
      if (this.get('chartCompareSymbolKeyList') !== undefined && this.get('chartCompareSymbolKeyList').length > 0) {
        return true;
      }
      return false;
    }).property('chartCompareSymbolKeyList', 'chartCompareSymbolKeyList.@each'),

    // when compare symbol mode enable we need to change chart style to Line
    changeChartStyleAndRemoveIndicators: (function () {
      try {
        if (this.get('compareSymbolMode')) {
          if (this.get('chartStyle') !== ChartConstants['default'].IRChartStyle.Area) {
            this.set('chartStyleBeforeCompare', this.get('chartStyle'));
          }
          this._setChartStyle(ChartConstants['default'].IRChartStyle.Line);
          this.set('indicatorList', Ember['default'].A());
          var chartInd;
          for (var j = 0; j < this.get('getDisplayIndicators').length; j++) {
            chartInd = this.get('getDisplayIndicators')[j];
            Ember['default'].set(chartInd, 'IsAdded', false);
          }
        } else {
          if (this.get('chartStyleBeforeCompare') !== undefined) {
            // When compare symbol mode off apply the previous chart style
            this._setChartStyle(this.get('chartStyleBeforeCompare'));
          } else {
            this._setChartStyle(ChartConstants['default'].IRChartStyle.Line);
          }
        }
      } catch (e) {
        utils['default'].logger.logError('[chart widget] changeChartStyleAndRemoveIndicators() ' + e);
      }
    }).observes('compareSymbolMode'),

    addShortDesToTheMap: function addShortDesToTheMap(key, shortDes) {
      var compareList = this.get('chartCompareSymbolKeyAndDesMap');
      if (!compareList[key]) {
        compareList[key] = shortDes;
      }
    },

    getShortDesFromTheMap: function getShortDesFromTheMap(key) {
      var compareList = this.get('chartCompareSymbolKeyAndDesMap');
      if (compareList[key]) {
        return compareList[key];
      } else {
        return this._getSymbolFromTheKey(key);
      }
    },

    removeShortDesFromMap: function removeShortDesFromMap(key) {
      var compareList = this.get('chartCompareSymbolKeyAndDesMap');
      if (compareList[key]) {
        delete compareList[key];
      }
    },

    dataGroupingEnableDisable: function dataGroupingEnableDisable() {
      var that = this;
      if (that.get('chartInterval').IsDefault) {
        return {
          enabled: false
        };
      } else {
        return {
          enabled: true,
          units: [[that.get('chartInterval').unitName, that.get('chartInterval').unit]]
        };
      }
    },

    chartLegendParams: function chartLegendParams() {
      if (this.get('lang') === 'ar') {
        return {
          enabled: true,
          layout: 'horizontal',
          align: 'left',
          verticalAlign: 'top',
          //useHTML: true,
          itemDistance: 50,
          x: 30
        };
      } else {
        return {
          enabled: true,
          layout: 'horizontal',
          align: 'right',
          verticalAlign: 'top',
          x: -30
        };
      }
    },

    exportButtonParams: function exportButtonParams() {
      if (this.get('lang') === 'ar') {
        return {
          menuItems: null,
          symbol: 'url(assets/img/xls_download.png)',
          align: 'left',
          onclick: function onclick() {
            this.downloadXLS();
          }
        };
      } else {
        return {
          menuItems: null,
          symbol: 'url(assets/img/xls_download.png)',
          align: 'right',
          onclick: function onclick() {
            this.downloadXLS();
          }
        };
      }
    },

    listenSymbolCount: (function () {
      var count = this.get('symbolCount');
      if (count > 0) {
        this.set('isPoints', false);
      } else {
        this.set('isPoints', true);
      }
    }).observes('symbolCount'),

    _mainYAxisLabels: function _mainYAxisLabels() {
      var that = this;
      if (this.get('chartYAxisToRight')) {
        return {
          align: 'center',
          x: -20,
          y: -2,
          formatter: function formatter() {
            if (that.get('chartCompareSymbolKeyList').length > 0) {
              return utils['default'].formatters.formatNumber(this.value, 2) + ' %'; // 2 decimal places for compare chart
            } else {
                return utils['default'].formatters.formatNumber(this.value, that.get('stock').deci);
              }
          },
          style: {
            color: that.get('thm').themeName.colors.fontColor
          }
        };
      } else {
        return {
          align: 'center',
          x: 30,
          y: -2,
          formatter: function formatter() {
            if (that.get('chartCompareSymbolKeyList').length > 0) {
              return utils['default'].formatters.formatNumber(this.value, 2) + ' %'; // 2 decimal places for compare chart
            } else {
                return utils['default'].formatters.formatNumber(this.value, that.get('stock').deci);
              }
          },
          style: {
            color: that.get('thm').themeName.colors.fontColor
          }
        };
      }
    },

    _secondaryYAxisLabels: function _secondaryYAxisLabels() {
      if (this.get('chartYAxisToRight')) {
        return {
          align: 'center',
          enabled: true,
          x: -15
        };
      } else {
        return {
          align: 'center',
          enabled: true,
          x: 20
        };
      }
    },

    changedInputs: (function () {
      if (!(this.get('fromDate') == null || this.get('toDate') == null || this.get('toDate').getTime() < this.get('fromDate').getTime())) {

        var fdate = this.get('fromDate');
        var tdate = this.get('toDate');
        this._deselectTab();
        if (this.get('graphDataManager')) {
          this.get('graphDataManager').addChartDateRange(fdate, tdate);
          this.get('graphDataManager').addChartDataSubscription();
        }
      }
    }).observes('fromDate', 'toDate', 'graphDataManager'),

    onMainIndexSet: (function () {
      //Only Exchange is Available. Main Index is Taken as the Symbol
      var exchange = this.get('exchange');
      if (!this.utils.validators.isAvailable(this.get('sym')) && exchange.mainIdx) {
        this.set('sym', exchange.mainIdx);
        this._onPrepareData();
        this._setSelectedTab();
      }
    }).observes('exchange.mainIdx'),

    dynamicSymbol: function dynamicSymbol(item) {
      var that = this;
      var exchange;
      if (item.exg) {
        exchange = item.exg;
      } else {
        exchange = this.get('exg');
      }
      var key = utils['default'].keyGenerator.getKey(exchange, item.sym);

      var successFn = function successFn() {
        if (that.get('sym') !== item.sym) {
          if (!that.get('chartCompareSymbolKeyList').contains(key)) {
            that.addShortDesToTheMap(key, item.sDes);
            that.get('chartCompareSymbolKeyList').pushObject(key);
          }
          that._addCompareSymbol(exchange, item);
          that.set('searchSymbol', '');
        } else {
          that.set('searchSymbol', '');
        }
      };

      var errorFn = function errorFn() {};

      priceService['default'].downloadExchangeProperty(exchange, sharedService['default'].userSettings.currentLanguage, successFn, errorFn);
    },

    updateSymbolArray: function updateSymbolArray(symbol, value, color) {
      var symArr = this.get('symArray');

      var sym = symArr.findBy('name', symbol);

      if (sym == undefined) {
        symArr.pushObject(Ember['default'].Object.create({ name: symbol, y: 0, color: '' }));
      }
      symArr.findBy('name', symbol).set('y', value);
      symArr.findBy('name', symbol).set('color', color);
    },

    removeSymbol: function removeSymbol(item) {
      var key = utils['default'].keyGenerator.getKey(item.exg, item.sym);
      this.get('chartCompareSymbolKeyList').removeObject(key);
      this.removeShortDesFromMap(key);
    },
    clearChkBox: function clearChkBox() {
      Ember['default'].$('#CA').prop('checked', false);
      Ember['default'].$('#AN').prop('checked', false);
      this.set('markerID', '');
      this.set('ShowHide', false);
      this.set('ShowHideANN', false);
    },

    addMarkertotheChart: function addMarkertotheChart(id, flag) {
      var that = this;
      if (!flag) {
        flag = Ember['default'].$('#' + id).prop('checked');
      }

      if (id == 'CA') {
        that.set('markerID', 'CA');
        that.set('ShowHide', flag);
      } else {
        that.set('markerID', 'AN');
        that.set('ShowHideANN', flag);
      }
    },
    actions: {
      setChartPeriod: function setChartPeriod(tabItem) {
        this._onChangePeriod(tabItem);
        this.clearChkBox();
      },
      onToggleVolume: function onToggleVolume() {
        this._onClickVolumeShow();
      },
      setChartStyle: function setChartStyle(option) {
        this._setChartStyle(option);
        this.clearChkBox();
      },
      setIndicator: function setIndicator(option) {
        this._setIndicator(option);
      },

      addSymbol: function addSymbol(symbol) {
        var that = this;
        this.clearChkBox();
        var flag = Ember['default'].$('#' + symbol.sym).prop('checked');
        var count = this.get('symbolCount');

        if (flag) {
          this.set('enableCross', false);

          this.dynamicSymbol(symbol);
          this.updateSymbolArray(symbol.sDes, 0);
          count++;
        } else {
          this.set('enableCross', true);
          this.removeSymbol(symbol);
          var obj = this.get('symArray').findBy('name', symbol.sDes);
          this.get('symArray').removeObject(obj);
          count--;
        }
        // setTimeout(function(){
        //   that.set('wait', true);
        //   that.set('isLoading', false);
        // },2000)

        this.set('symbolCount', count);
      },
      onSymbolSelected: function onSymbolSelected(item) {
        this.dynamicSymbol(item);
        this.clearChkBox();
      },
      showSearchPopup: function showSearchPopup() {
        var modal = this.get('symbolSearch');
        modal.send('showModalPopup');
      },
      closeSearchPopup: function closeSearchPopup() {
        var modal = this.get('symbolSearch');
        modal.send('closeModalPopup');
      },
      setChartInterval: function setChartInterval(option) {
        this._setInterval(option);
      },
      enableMarker: function enableMarker(chkBxId) {
        this.addMarkertotheChart(chkBxId);
        // var that = this;
        // let flag = Ember.$('#'+chkBxId).prop('checked');
        //
        // if(chkBxId == 'CA'){
        //   that.set('markerID', 'CA')
        //   that.set('ShowHide' , flag);
        // }else{
        //   that.set('markerID', 'AN');
        //   that.set('ShowHideANN' , flag);e
        // }
      },

      detach: function detach() {
        var host = window.location.hostname;
        var exg = this.get('exg').toLowerCase();
        var sym = this.get('sym').toLowerCase();
        var lang = sharedService['default'].userSettings.currentLanguage.toLowerCase();
        window.open('http://' + host + '/' + this.get('baseUrl') + '/ir/' + this.get('baseUrl') + '/' + lang + '/detachChart/' + exg + '/' + sym, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
      },
      downloadExcel: function downloadExcel() {
        var hasData = true;
        var dtArray = this.get('chartCloseDataArray');

        var dtArrayHeads = [];
        var dtArrayData = [];

        dtArray.forEach(function (point) {
          var tempArray = [];
          if (point.data) {
            if (hasData) {
              //dtArrayHeads = Object.keys(dtArray[0].data);
              if (sharedService['default'].userSettings.currentLanguage == "AR") {
                // dtArrayHeads = ["فتح" , "الإغلاق" , "أعلى" , "أقل" , "التاريخ"]
                dtArrayHeads = ["فتح", "الإغلاق", "أعلى", "أقل", "التاريخ"];
              } else {
                dtArrayHeads = ["Open", "Close", "High", "Low", "Date"];
              }
              dtArrayData.push(dtArrayHeads.join(','));
              hasData = false;
            }
            tempArray = [point.data.open, point.data.close, point.data.high, point.data.low, utils['default'].formatters.convertDateToyyyymmdd(point.data.date)];

            dtArrayData.push(tempArray.join(','));
          }
        });

        utils['default'].exportData.exportArrayToExcel(this.get('sym'), dtArrayData);
      }
    }
  });

  Ember['default'].Handlebars.helper('getPositiveNegativeStyle', function (value) {
    if (value >= 0) {
      return 'padding-medium snap-period-wrapper-green';
    } else if (value < 0) {
      return 'padding-medium snap-period-wrapper-red';
    }
  });

  Ember['default'].Handlebars.helper('dynamic-high-chart', DynamicHighChart['default']);
  Ember['default'].Handlebars.helper('volumeEnabled', function (value) {
    if (value === true) {
      return 'btn btn-default border-none activeVol';
    } else {
      return 'btn btn-default border-none normal';
    }
  });

});