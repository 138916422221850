define('ir-app/controllers/ir-widgets/announcement-widgets/news-list', ['exports', 'ember', 'ir-app/controllers/base-array-controller', 'ir-app/models/price/price-service', 'ir-app/models/price/price-constants', 'ir-app/models/price/price-protocols/mix-web/mix-request-generator'], function (exports, Ember, BaseArrayController, priceService, PriceConstant, RequestGenerator) {

    'use strict';

    /**
     * Created by lakmaln on 9/21/2016.
     */

    exports['default'] = BaseArrayController['default'].extend({

        symbol: '',
        pageSize: PriceConstant['default'].AnnouncementSearchPageSize,
        count: 0,
        current: 1,

        onPrepareData: function onPrepareData() {
            if (!this.get('isForExchange')) {
                this.set('symbol', this.get('sym'));
            }
            this.set('pageSize', this.get('pageSize'));

            this.set('model', priceService['default'].announcementDS.getAnnCollectionBySymbol(this.get('exg'), this.get('symbol')));
            this.setErrorMessage();
            this.sendNewsRequest();
        },

        onCheckDataAvailability: function onCheckDataAvailability() {
            this.set('count', Math.ceil(priceService['default'].announcementDS.getAnnCount() / this.get('pageSize')));
            var news = priceService['default'].announcementDS.getAnnCollectionBySymbol(this.get('exg'), this.get('symbol'));
            return news && news.length > 0;
        },

        sendNewsRequest: function sendNewsRequest() {
            var that = this;
            priceService['default'].announcementDS.getAnnCollectionBySymbol(this.get('exg'), this.get('symbol')).clear();
            this.startLoadingProgress();

            priceService['default'].sendNewsSearchRequest({
                exchange: this.get('exg'),
                symbol: this.get('symbol'),
                pageSize: this.get('pageSize'),
                pageIndex: this.get('current') - 1,
                reqSuccessFn: function reqSuccessFn() {
                    that.onDataSuccess();
                },
                reqFailureFn: function reqFailureFn() {
                    that.onDataError();
                }
            }, priceService['default'].announcementDS.getAnnCollectionBySymbol(this.get('exg'), this.get('symbol')));
        },

        actions: {
            pageChanged: function pageChanged(current) {
                this.set('current', current);
                this.sendNewsRequest();
            }
        }
    });

    Ember['default'].Handlebars.helper('getImgUrl', function (id, lang) {
        return RequestGenerator['default'].generateNewsResourceDownloadUrl(id, lang);
    });

});