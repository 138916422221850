define('ir-app/components/stk-specific-components/estimate-chart', ['exports', 'ember', 'ir-app/utils/utils'], function (exports, Ember, utils) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({

        minValue: -1,
        maxValue: -1,
        minimumChartHeight: 170,

        handleResize: function handleResize() {
            var that = this;
            try {
                if (document.getElementById('chart1') !== null) {
                    var topHeight = document.getElementById('chart1').getBoundingClientRect().top;
                    var fullHeight = Ember['default'].$(window).height();
                    var expectedChartHeight = fullHeight - topHeight - 3; // 3 is a bottom margin
                    var chartHeight = (expectedChartHeight > that.minimumChartHeight ? expectedChartHeight : that.minimumChartHeight) / 2;
                    that.set('chartContainerHeight', chartHeight);
                    that.set('chartContainerWidth', document.getElementById('chart1').offsetWidth);
                }
            } catch (e) {
                utils['default'].logger.logError('[basic chart widget] handleResize() ' + e);
            }
        },

        handleInitialResize: function handleInitialResize() {
            var that = this;
            setTimeout(function () {
                // give 1s delay to let other components to load.
                that.handleResize();
            }, 1);
        },

        bindResizeEvent: (function () {
            var that = this;
            Ember['default'].$(window).on('resize', Ember['default'].run.bind(this, this.handleResize));
            Ember['default'].$(window).ready(function () {
                Ember['default'].$('#transbox').bind('DOMSubtreeModified', function () {
                    // Some times it is bit delay to load the top description panel(#transbox) of the home page
                    that.handleResize();
                });
            });
            Ember['default'].$(window).ready(Ember['default'].run.bind(this, this.handleInitialResize));
        }).on('init'),

        chart: {},
        elementId: '',
        dataSet: {},
        dataSetMin: {},
        dataSetAvg: {},
        dataSetMax: {},
        xDataSet: {},
        chartOptions: {

            chart: {
                type: 'spline',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                marginTop: 30,
                height: 320
            },
            title: {
                text: ''
            },
            subtitle: {
                text: null
            },
            xAxis: {
                title: {
                    text: 'Year'
                },
                tickLength: 0,
                tickPosition: 'outside',
                labels: {
                    overflow: 'justify',
                    enabled: true
                }
            },
            yAxis: {
                labels: {
                    overflow: 'justify',
                    enabled: true
                },
                gridLineWidth: 1,
                minorGridLineWidth: 1,
                tickPosition: 'outside',
                tickLength: 5
            },
            tooltip: {
                enabled: true
            },
            plotOptions: {},
            exporting: {
                enabled: false
            },
            legend: {
                enabled: true,
                align: "right",
                layout: "vertical",
                verticalAlign: "middle"
            },

            credits: {
                enabled: false
            },

            series: [{
                name: "ACT",
                color: '#2F7ED8'
            }, {
                name: "AVG",
                dashStyle: 'dash',
                color: '#910000'
            }, {
                name: "MIN",
                dashStyle: 'dash',
                color: '#8BBC21'
            }, {
                name: "MAX",
                dashStyle: 'dash',
                color: '#0D233A'
            }]

        },
        generateChart: (function () {

            Array.prototype.max = function () {
                return Math.max.apply(null, this);
            };

            Array.prototype.min = function () {
                return Math.min.apply(null, this);
            };

            var chart = this.get('chartOptions');

            chart.series[0]['data'] = this.get('dataSetAct');
            // var dataArray=chart.series[0]['data'];

            chart.series[1]['data'] = this.get('dataSetAvg');
            chart.series[2]['data'] = this.get('dataSetMin');
            chart.series[3]['data'] = this.get('dataSetMax');

            //chart.chart.height=this.get('chartContainerHeight');

            chart.xAxis['categories'] = this.get('xDataSet');

            // chart.yAxis.min= dataArray.min();
            // chart.yAxis.max=dataArray.max();

            Ember['default'].$('#' + this.get('elementId')).highcharts(chart);
        }).observes('dataSetAct', 'chartContainerHeight'),

        didInsertElement: function didInsertElement() {
            this._super();
            this.generateChart();
        }
    });

});