define('ir-app/utils/asset-types', ['exports'], function (exports) {

    'use strict';

    exports['default'] = {
        // Equity types
        Equity: 0,
        EquityNew: 60,
        CommonStock: 61,
        PreferredStock: 62,
        Warrant: 63,
        Premium: 64,
        Trust: 65,
        Right: 66,
        WarrantRight: 67,
        Etf: 86,

        // Option types
        Future: 68,
        FutureSpread: 69,
        Option: 10,
        EquityOption: 71,
        IndexOption: 72,
        FutureOption: 73,
        IndexFuture: 92,
        FutureInterest: 93,
        CurrencyFuture: 105,
        CommodityFuture: 96,
        CommodityOption: 95,
        FutureFuture: 97,

        // Mutual fund types
        MutualFund: 2,
        FixedIncome: 74,
        SukukBonds: 75,
        ConvertibleBond: 76,
        MBS: 77,
        GovBond: 78,
        CorpBond: 79,
        USAgencyBond: 80,
        USTreasuryBill: 81,
        USTreasuryCoupon: 82,
        MoneyMarket: 83,
        CD: 84,
        TreasuryBill: 108,
        LendingRate: 109,
        BorrowningRate: 110,
        InterbankRate: 111,
        Debenture: 112,
        Sukuk: 121,

        // Forex types
        Forex: 14,
        ForexFra: 88,
        ForexDeposit: 89,
        ForexForward: 90,
        Statistics: 91,

        // Index types
        Indices: 7,

        // Stock Borrowing  types
        StockBorrowing: 106,

        isEquity: function isEquity(inst) {
            return inst === this.Equity;
        },

        isSukukBonds: function isSukukBonds(inst) {
            return inst === this.SukukBonds;
        },

        isEtf: function isEtf(inst) {
            return inst === this.Etf;
        },

        isIndices: function isIndices(inst) {
            return inst === this.Indices;
        }
    };

});