define('ir-app/controllers/ir-mobile/chart-widgets/analyst-view-chart-widget', ['exports', 'ember', 'ir-app/models/chart/chart-constants', 'ir-app/utils/utils', 'ir-app/controllers/ir-mobile/chart-widgets/basic-chart-widget'], function (exports, Ember, ChartConstants, utils, BasicChartWidget) {

    'use strict';

    /**
     * Created by banushas on 3/15/2016.
     */

    exports['default'] = BasicChartWidget['default'].extend({

        chartProviderDataArray: [],

        onPrepareData: function onPrepareData() {
            this.loadChart();
            var chartArray = Ember['default'].A();
            var content = this.get('chartContent');
            for (var i = 0; i < content.length; i++) {
                var str = content[i].date;
                var y = str.substr(0, 4),
                    m = str.substr(4, 2) - 1,
                    d = str.substr(6, 2);
                var date = new Date(y, m, d);
                chartArray.pushObject([date.getTime(), content[i].targetPrice, content[i].provider, date.toISOString().substring(0, 10), content[i].refId]);
            }
            var period = this.get('chartPeriod');
            this._onChangePeriod(this.getChartPeriod(period), chartArray);
        },

        bindResizeEvent: (function () {// to stop resizing
        }).on('init'),

        getChartPeriod: function getChartPeriod(period) {

            switch (period) {

                case 2:
                    return ChartConstants['default'].ChartViewPeriod.ThreeMonth;
                case 3:
                    return ChartConstants['default'].ChartViewPeriod.SixMonth;
                case 4:
                    return ChartConstants['default'].ChartViewPeriod.OneYear;
                case 5:
                    return ChartConstants['default'].ChartViewPeriod.TwoYear;
                default:
                    return ChartConstants['default'].ChartViewPeriod.ThreeMonth;
            }
        },

        _onChangePeriod: function _onChangePeriod(chartPeriod, dataArray) {
            var that = this;
            try {
                that.get('graphDataManager').addChartDateRange(undefined, undefined);
                var isDifferentPeriod = that.get('chartViewPeriod').ID !== chartPeriod.ID;
                if (isDifferentPeriod) {
                    if (that.get('chartCategory') === 1) {
                        that.clearDataOnTabChange();
                    }
                    var gdm = that.get('graphDataManager');
                    var differentCategory = that.get('chartViewPeriod').IsHistory !== chartPeriod.IsHistory;
                    that.set('chartViewPeriod', chartPeriod);

                    if (differentCategory) {
                        that.set('chartCategory', chartPeriod.IsHistory ? ChartConstants['default'].ChartCategory.History : ChartConstants['default'].ChartCategory.Intraday);
                        that.set('chartInterval', ChartConstants['default'].ChartViewInterval[chartPeriod.DefaultInterval]);
                    }
                    that.set('dataLoadingComplete', false);
                    gdm.refineGraphData({
                        chartCategory: that.get('chartCategory'),
                        chartDataLevel: chartPeriod.ChartDataLevel,
                        chartViewPeriod: chartPeriod
                    });
                }
                that.set('chartProviderDataArray', dataArray);
            } catch (e) {
                utils['default'].logger.logError('[Chart Widget] onChangePeriod() ' + e);
            }
        },

        actions: {
            setChartPeriod: function setChartPeriod(tabItem) {
                this._onChangePeriod(tabItem);
            }
        },

        _advancedChartData: function _advancedChartData() {
            var that = this;
            return [{
                type: 'area',
                id: 'dataseries',
                data: this.get('chartCloseDataArray'),
                name: that.get('stock').sDes,
                lineColor: that.get('thm').themeName.colors.lineColor,
                lineWidth: 2,
                fillColor: that.chartFillColor(),
                states: {
                    hover: {
                        enabled: false
                    }
                },
                dataGrouping: {
                    enabled: false
                },
                dataLabels: {
                    enabled: false
                },
                tooltip: {
                    valueDecimals: that.get('stock').deci,
                    useHTML: true
                }
            }, {
                type: 'flags',
                data: this.get('chartProviderDataArray'),
                keys: ['x', 'y', 'provider', 'date', 'title'],
                color: that.get('thm').themeName.colors.volumeColor,
                onSeries: 'dataseries',
                shadow: true,
                shape: 'squarepin',
                tooltip: {
                    headerFormat: '{}',
                    pointFormat: that.get('app').lang.labels.value + ': <b>{point.y}</b><br/>' + that.get('app').lang.labels.provider + ': <b>{point.provider}</b><br/>' + that.get('app').lang.labels.date + ': <b>{point.date}</b><br/>'
                }
            }];
        }

    });

});