define('ir-app/utils/utils', ['exports', 'ir-app/utils/constants', 'ir-app/utils/logger', 'ir-app/models/chart/chart-constants', 'ir-app/utils/json-helper', 'ir-app/utils/image-helper', 'ir-app/utils/full-screen-helper', 'ir-app/utils/crypto', 'ir-app/utils/formatters', 'ir-app/utils/validators', 'ir-app/utils/asset-types', 'ir-app/utils/message-service', 'ir-app/utils/key-generator', 'ir-app/utils/web-storage', 'ir-app/utils/analytics-service', 'ir-app/utils/browser', 'ir-app/utils/config-helper', 'ir-app/models/shared/shared-service', 'ir-app/utils/export-data'], function (exports, Constants, logger, chartConstants, jsonHelper, imageHelper, fullScreenHelper, crypto, formatters, validators, AssetTypes, messageService, keyGenerator, webStorage, analyticsService, browser, configHelper, sharedService, exportData) {

    'use strict';

    exports['default'] = (function () {
        var logout = function logout() {
            var url = window.location.href;

            if (url.indexOf('?') > 0) {
                // SSO
                sharedService['default'].userSettings.ssoToken = '';
                sharedService['default'].userSettings.save();

                var closeWindow = function closeWindow() {
                    window.open('', '_self').close();
                };

                window.setTimeout(function () {
                    closeWindow();
                }, 1000);
            } else {
                // Retail
                webStorage['default'].addString(webStorage['default'].getKey(Constants['default'].CacheKeys.LoggedIn), '0', Constants['default'].StorageType.Session);
                window.location.href = url;
            }
        };

        return {
            logout: logout,
            exportData: exportData['default'],
            jsonHelper: jsonHelper['default'],
            imageHelper: imageHelper['default'],
            fullScreenHelper: fullScreenHelper['default'],
            crypto: crypto['default'],
            webStorage: webStorage['default'],
            logger: logger['default'],
            formatters: formatters['default'],
            validators: validators['default'],
            AssetTypes: AssetTypes['default'],
            messageService: messageService['default'],
            Constants: Constants['default'],
            keyGenerator: keyGenerator['default'],
            chartConstants: chartConstants['default'],
            analyticsService: analyticsService['default'],
            browser: browser['default'],
            configHelper: configHelper['default']
        };
    })();

});