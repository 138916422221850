define('ir-app/models/price/price-protocols/mix-web/mix-request-handler', ['exports', 'ember', 'ir-app/models/shared/communication-adapters/web-http-connection', 'ir-app/models/price/price-protocols/mix-web/mix-response-handler', 'ir-app/models/price/price-protocols/mix-web/mix-request-generator', 'ir-app/models/chart/chart-constants', 'ir-app/models/shared/shared-cache-managers/mix-cache-manager', 'ir-app/utils/utils'], function (exports, Ember, WebConnection, ResponseHandler, RequestGenerator, ChartConstants, MixCacheManager, Utils) {

    'use strict';

    exports['default'] = (function () {

        var loadSystemDefinitions = function loadSystemDefinitions(language, cachePolicy, successFn, errorFn) {

            var cacheKey = MixCacheManager['default'].getSystemDefCacheKey(cachePolicy, language);
            var isCachedData = MixCacheManager['default'].isCachedData(cachePolicy, cacheKey);

            if (!isCachedData) {
                WebConnection['default'].sendAjaxRequest({
                    url: RequestGenerator['default'].generateSystemDefinitionsDataUrl(language),

                    onSuccess: function onSuccess(dataObj) {
                        MixCacheManager['default'].persistMixResponse(cachePolicy, cacheKey, dataObj);
                        ResponseHandler['default'].processSystemDefinitionResponse(dataObj, language, successFn, errorFn);
                        if (Ember['default'].$.isFunction(successFn)) {
                            successFn();
                        }
                    },

                    onError: function onError() {
                        if (Ember['default'].$.isFunction(errorFn)) {
                            errorFn();
                        }
                    }
                });
            } else {

                var sysMeta = MixCacheManager['default'].getCachedMixResponse(cacheKey);
                ResponseHandler['default'].processSystemDefinitionResponse(sysMeta, language, successFn, errorFn);
                if (Ember['default'].$.isFunction(successFn)) {
                    successFn();
                }
            }
        };
        //
        // Exchange specific requests
        //
        var loadExchangeMasterInfoForExchange = function loadExchangeMasterInfoForExchange(exchange, language, successFn, errorFn, isSecondaryLan) {
            WebConnection['default'].sendAjaxRequest({
                url: RequestGenerator['default'].generateExchangeMasterDataUrl(exchange, language),

                onSuccess: function onSuccess(dataObj) {
                    ResponseHandler['default'].processExchangeMasterInfoResponse(exchange, dataObj, language, successFn, errorFn, isSecondaryLan);
                },

                onError: function onError() {
                    if (Ember['default'].$.isFunction(errorFn)) {
                        errorFn();
                    }
                }
            });
        };

        var loadExchangeData = function loadExchangeData(exg, language, cachePolicy, successFn, errorFn) {
            var cacheKey = MixCacheManager['default'].getExchangeMetaCacheKey(cachePolicy, exg, language);
            var isCachedData = MixCacheManager['default'].isCachedData(cachePolicy, cacheKey, exg);

            if (!isCachedData) {
                WebConnection['default'].sendAjaxRequest({
                    url: RequestGenerator['default'].generateExchangeDataUrl(exg),
                    onSuccess: function onSuccess(dataObj) {
                        MixCacheManager['default'].persistMixResponse(cachePolicy, cacheKey, dataObj);
                        ResponseHandler['default'].processExchangeResponse(dataObj);

                        if (Ember['default'].$.isFunction(successFn)) {
                            successFn();
                        }
                    },

                    onError: function onError() {
                        if (Ember['default'].$.isFunction(errorFn)) {
                            errorFn();
                        }
                    }
                });
            } else {
                var exgMeta = MixCacheManager['default'].getCachedMixResponse(cacheKey);
                ResponseHandler['default'].processExchangeResponse(exgMeta, successFn, errorFn);
            }
        };

        //
        // Symbol specific requests
        //
        var loadSymbolData = function loadSymbolData(exchange, language, cachePolicy, successFn, errorFn) {
            var cacheKey = MixCacheManager['default'].getSymbolMetaCacheKey(cachePolicy, exchange, language);
            var isCachedData = MixCacheManager['default'].isCachedData(cachePolicy, cacheKey, exchange);

            if (!isCachedData) {
                WebConnection['default'].sendAjaxRequest({
                    url: RequestGenerator['default'].generateSymbolMetaDataUrl(exchange, language),

                    onSuccess: function onSuccess(dataObj) {
                        MixCacheManager['default'].persistMixResponse(cachePolicy, cacheKey, dataObj);
                        ResponseHandler['default'].processSymbolResponse(dataObj);
                        if (Ember['default'].$.isFunction(successFn)) {
                            successFn();
                        }
                    },

                    onError: function onError() {
                        if (Ember['default'].$.isFunction(errorFn)) {
                            errorFn();
                        }
                    }
                });
            } else {
                var exgMeta = MixCacheManager['default'].getCachedMixResponse(cacheKey);
                ResponseHandler['default'].processSymbolResponse(exgMeta);

                if (Ember['default'].$.isFunction(successFn)) {
                    successFn();
                }
            }
        };

        var loadExchangeMetaData = function loadExchangeMetaData(exchange, language, cachePolicy, successFn, errorFn) {
            var cacheKey = MixCacheManager['default'].getSymbolMetaCacheKey(cachePolicy, exchange, language);
            var isCachedData = MixCacheManager['default'].isCachedData(cachePolicy, cacheKey, exchange);
            if (!isCachedData) {
                WebConnection['default'].sendAjaxRequest({
                    url: RequestGenerator['default'].generateExchangeMetaDataUrl(exchange, language),
                    onSuccess: function onSuccess(dataObj) {
                        MixCacheManager['default'].persistMixResponse(cachePolicy, cacheKey, dataObj);
                        ResponseHandler['default'].processExchangeMetaResponse(exchange, dataObj);
                        if (Ember['default'].$.isFunction(successFn)) {
                            successFn();
                        }
                    },
                    onError: function onError() {
                        if (Ember['default'].$.isFunction(errorFn)) {
                            errorFn();
                        }
                    }
                });
            } else {
                var exgMeta = MixCacheManager['default'].getCachedMixResponse(cacheKey);
                ResponseHandler['default'].processExchangeMetaResponse(exchange, exgMeta);

                if (Ember['default'].$.isFunction(successFn)) {
                    successFn();
                }
            }
        };

        var loadSymbolValidationData = function loadSymbolValidationData(exchange, symbol, language, reqSuccessFn, reqFailureFn, cachePolicy, insType) {
            var cacheKey = MixCacheManager['default'].getSymbolInfoCacheKey(cachePolicy, exchange, symbol, language);
            var isCachedData = MixCacheManager['default'].isCachedData(cachePolicy, cacheKey, exchange, symbol);

            if (!isCachedData) {
                WebConnection['default'].sendAjaxRequest({
                    url: RequestGenerator['default'].generateSymbolValidationUrl(exchange, symbol, insType !== undefined ? insType : 0), // Instrument type is not valid.// But to keep the backward compatibility we pass a dummy value.
                    onSuccess: function onSuccess(dataObj) {
                        MixCacheManager['default'].persistMixResponse(cachePolicy, cacheKey, dataObj);
                        ResponseHandler['default'].processSymbolValidationResponse(dataObj);

                        if (Ember['default'].$.isFunction(reqSuccessFn)) {
                            reqSuccessFn();
                        }
                    },

                    onError: function onError() {
                        // TODO: Handle error on symbol validation data loading
                        if (Ember['default'].$.isFunction(reqFailureFn)) {
                            reqFailureFn();
                        }
                    }
                });
            } else {
                var chacheObj = MixCacheManager['default'].getCachedMixResponse(cacheKey);
                var success = ResponseHandler['default'].processSymbolValidationResponse(chacheObj);

                if (success && Ember['default'].$.isFunction(reqSuccessFn)) {
                    reqSuccessFn();
                } else if (!success && Ember['default'].$.isFunction(reqFailureFn)) {
                    reqFailureFn();
                }
            }
        };

        var loadSymbolValidationBulkData = function loadSymbolValidationBulkData(symbolObjArray) {
            WebConnection['default'].sendAjaxRequest({
                url: RequestGenerator['default'].generateSymbolValidationBulkUrl(symbolObjArray),
                onSuccess: ResponseHandler['default'].processSymbolValidationResponse,

                onError: function onError() {
                    // TODO: Handle error on symbols validation data loading
                }
            });
        };

        var loadSymbolSearchData = function loadSymbolSearchData(searchKey, language, pageSize, notifyFn, exchangeList, tempID) {
            WebConnection['default'].sendAjaxRequest({
                url: RequestGenerator['default'].generateSymbolSearchUrl(searchKey, language, pageSize, exchangeList, tempID),

                onSuccess: function onSuccess(dataObj) {
                    ResponseHandler['default'].processSymbolSearchResponse(dataObj, searchKey, notifyFn);
                },

                onError: function onError() {
                    // TODO: Handle error on symbol search data loading
                }
            });
        };

        var loadSmartSearchData = function loadSmartSearchData(searchKey, language, pageSize, notifyFn, assertTypes, exchanges, searchType) {
            WebConnection['default'].sendAjaxRequest({
                url: RequestGenerator['default'].generateSmartSearchUrl(searchKey, language, assertTypes, exchanges, searchType),

                onSuccess: function onSuccess(dataObj) {
                    ResponseHandler['default'].processSmartSearchResponse(dataObj, searchKey, notifyFn);
                },

                onError: function onError() {
                    // TODO: Handle error on smart search data loading
                }
            });
        };

        //
        // Chart specific requests
        //
        var loadIntradayData = function loadIntradayData(exchange, symbol, numberOfDays, reqSuccessFn, reqFailureFn) {
            WebConnection['default'].sendAjaxRequest({
                url: RequestGenerator['default'].generateIntradayDataUrl(exchange, symbol, numberOfDays),
                onSuccess: function onSuccess(dataObj) {
                    ResponseHandler['default'].processChartResponse(dataObj, ChartConstants['default'].ChartCategory.Intraday, reqSuccessFn, reqFailureFn);
                },

                onError: function onError() {
                    // TODO: Handle error on chart data loading
                    reqFailureFn();
                }
            });
        };

        var loadChartData = function loadChartData(exchange, symbol, chartCategory, begin, reqSuccessFn, reqFailureFn, cachePolicy, isLookupChart) {
            var cacheKey = MixCacheManager['default'].getCompanyOHLCCacheKey(cachePolicy, exchange, symbol);
            var isCachedData = MixCacheManager['default'].isCachedData(cachePolicy, cacheKey, exchange, symbol);

            if (!isCachedData) {
                WebConnection['default'].sendAjaxRequest({
                    url: RequestGenerator['default'].generateChartDataUrl(exchange, symbol, chartCategory, begin, isLookupChart),
                    onSuccess: function onSuccess(dataObj) {
                        MixCacheManager['default'].persistMixResponse(cachePolicy, cacheKey, dataObj);
                        ResponseHandler['default'].processChartResponse(dataObj, chartCategory, reqSuccessFn, reqFailureFn);
                    },

                    onError: function onError() {
                        // TODO: Handle error on chart data loading
                    }
                });
            } else {
                    var ohlcChartMixResponse = MixCacheManager['default'].getCachedMixResponse(cacheKey);
                    ResponseHandler['default'].processChartResponse(ohlcChartMixResponse, chartCategory, reqSuccessFn, reqFailureFn);
                }
        };

        //
        // News & announcement specific requests
        //
        var loadAnnouncementBody = function loadAnnouncementBody(annID, reqSuccessFn, reqFailureFn, language) {
            WebConnection['default'].sendAjaxRequest({
                url: RequestGenerator['default'].generateAnnouncementBodyUrl(annID, language),

                onSuccess: function onSuccess(dataObj) {
                    ResponseHandler['default'].processAnnouncementBodyResponse(dataObj, reqSuccessFn, reqFailureFn);
                },

                onError: function onError() {
                    if (Ember['default'].$.isFunction(reqFailureFn)) {
                        reqFailureFn();
                    }
                }
            });
        };

        var loadAnnouncementSearchData = function loadAnnouncementSearchData(params, annCollection) {
            WebConnection['default'].sendAjaxRequest({
                url: RequestGenerator['default'].generateAnnouncementSearchUrl(params),

                onSuccess: function onSuccess(dataObj) {
                    ResponseHandler['default'].processAnnouncementSearchResponse(dataObj, annCollection, params);
                },
                onError: function onError() {
                    if (params.reqFailureFn && Ember['default'].$.isFunction(params.reqFailureFn)) {
                        params.reqFailureFn();
                    }
                }
            });
        };

        var loadNewsBody = function loadNewsBody(newsID, reqSuccessFn, reqFailureFn, language) {
            WebConnection['default'].sendAjaxRequest({
                url: RequestGenerator['default'].generateNewsBodyUrl(newsID, language),

                onSuccess: function onSuccess(dataObj) {
                    ResponseHandler['default'].processNewsBodyResponse(dataObj, reqSuccessFn, reqFailureFn);
                },

                onError: function onError() {
                    if (Ember['default'].$.isFunction(reqFailureFn)) {
                        reqFailureFn();
                    }
                }
            });
        };

        var loadNewsDetail = function loadNewsDetail(params) {
            WebConnection['default'].sendAjaxRequest({
                url: RequestGenerator['default'].generateNewsBodyUrl(params.newsId, params.lan),

                onSuccess: function onSuccess(dataObj) {
                    ResponseHandler['default'].processNewsDetailResponse(dataObj, params.reqSuccessFn, params.reqFailureFn);
                },
                onError: function onError(e) {
                    if (Ember['default'].$.isFunction(params.reqFailureFn)) {
                        params.reqFailureFn();
                    }
                    Utils['default'].logger.logError('Error in send ajax request : ' + e);
                }
            });
        };

        var loadNewsSearchData = function loadNewsSearchData(params, newsCollection) {
            WebConnection['default'].sendAjaxRequest({
                url: RequestGenerator['default'].generateNewsSearchUrl(params),

                onSuccess: function onSuccess(dataObj) {
                    ResponseHandler['default'].processNewsSearchResponse(dataObj, newsCollection, params);
                },
                onError: function onError() {
                    // TODO: Handle error on data loading
                }
            });
        };

        //
        // Company Profile specific requests
        //
        var loadCompanyProfileData = function loadCompanyProfileData(exchange, symbol, language, postSuccess, Error, cachePolicy) {
            var cacheKey = MixCacheManager['default'].getCompanyProfileCacheKey(cachePolicy, exchange, symbol, language);
            var isCachedData = MixCacheManager['default'].isCachedData(cachePolicy, cacheKey, exchange, symbol);

            if (!isCachedData) {
                WebConnection['default'].sendAjaxRequest({
                    url: RequestGenerator['default'].generateCompanyProfileUrl(exchange, symbol),
                    onSuccess: function onSuccess(dataObj) {

                        MixCacheManager['default'].persistMixResponse(cachePolicy, cacheKey, dataObj);
                        ResponseHandler['default'].processCompanyProfileResponse(dataObj, exchange, symbol, language);

                        if (Ember['default'].$.isFunction(postSuccess)) {
                            postSuccess();
                        }
                    },
                    onError: function onError() {
                        // TODO: Handle error on company profile data loading
                        if (Ember['default'].$.isFunction(Error)) {
                            Error();
                        }
                    }
                });
            } else {
                var comProMixResponse = MixCacheManager['default'].getCachedMixResponse(cacheKey);
                var success = ResponseHandler['default'].processCompanyProfileResponse(comProMixResponse, exchange, symbol, language);

                if (success && Ember['default'].$.isFunction(postSuccess)) {
                    postSuccess();
                } else if (!success && Ember['default'].$.isFunction(Error)) {
                    Error();
                }
            }
        };

        var loadCompanyMgmntData = function loadCompanyMgmntData(exchange, symbol, language, postSuccess, Error, cachePolicy) {
            var cacheKey = MixCacheManager['default'].getCompanyMgmntCacheKey(cachePolicy, exchange, symbol, language);
            var isCachedData = MixCacheManager['default'].isCachedData(cachePolicy, cacheKey, exchange, symbol);

            if (!isCachedData) {
                WebConnection['default'].sendAjaxRequest({
                    url: RequestGenerator['default'].generateCompanyMgmntUrl(exchange, symbol),
                    onSuccess: function onSuccess(dataObj) {

                        MixCacheManager['default'].persistMixResponse(cachePolicy, cacheKey, dataObj);
                        ResponseHandler['default'].processCompanyMgmntResponse(dataObj, exchange, symbol, language);

                        if (Ember['default'].$.isFunction(postSuccess)) {
                            postSuccess();
                        }
                    },
                    onError: function onError() {
                        if (Ember['default'].$.isFunction(Error)) {
                            Error();
                        }
                    }
                });
            } else {
                var comProMixResponse = MixCacheManager['default'].getCachedMixResponse(cacheKey);
                var success = ResponseHandler['default'].processCompanyMgmntResponse(comProMixResponse, exchange, symbol, language);

                if (success && Ember['default'].$.isFunction(postSuccess)) {
                    postSuccess();
                } else if (!success && Ember['default'].$.isFunction(Error)) {
                    Error();
                }
            }
        };

        var loadCompanyMgmntDataByCompanyId = function loadCompanyMgmntDataByCompanyId(companyId, language, postSuccess, Error, cachePolicy) {
            var cacheKey = MixCacheManager['default'].getCompanyMgmntCacheKeyByCompanyId(cachePolicy, companyId, language);
            var isCachedData = MixCacheManager['default'].isCachedData(cachePolicy, cacheKey, undefined, undefined);

            if (!isCachedData) {
                WebConnection['default'].sendAjaxRequest({
                    url: RequestGenerator['default'].generateCompanyMgmntUrlByCompanyId(companyId),
                    onSuccess: function onSuccess(dataObj) {

                        MixCacheManager['default'].persistMixResponse(cachePolicy, cacheKey, dataObj);
                        ResponseHandler['default'].processCompanyMgmntResponse(dataObj, undefined, undefined, language, companyId);

                        if (Ember['default'].$.isFunction(postSuccess)) {
                            postSuccess();
                        }
                    },
                    onError: function onError() {
                        if (Ember['default'].$.isFunction(Error)) {
                            Error();
                        }
                    }
                });
            } else {
                var comProMixResponse = MixCacheManager['default'].getCachedMixResponse(cacheKey);
                var success = ResponseHandler['default'].processCompanyMgmntResponse(comProMixResponse, undefined, undefined, language, companyId);

                if (success && Ember['default'].$.isFunction(postSuccess)) {
                    postSuccess();
                } else if (!success && Ember['default'].$.isFunction(Error)) {
                    Error();
                }
            }
        };

        //
        // Company Profile data request by Id
        //
        var loadCompanyProfileDataByCompanyId = function loadCompanyProfileDataByCompanyId(companyId, language, postSuccess, Error, cachePolicy) {
            var cacheKey = MixCacheManager['default'].getCompanyProfileCacheKeyByCompanyId(cachePolicy, companyId, language);
            var isCachedData = MixCacheManager['default'].isCachedData(cachePolicy, cacheKey, undefined, undefined);

            if (!isCachedData) {
                WebConnection['default'].sendAjaxRequest({
                    url: RequestGenerator['default'].generateCompanyProfileUrlByCompanyId(companyId),
                    onSuccess: function onSuccess(dataObj) {

                        MixCacheManager['default'].persistMixResponse(cachePolicy, cacheKey, dataObj);
                        ResponseHandler['default'].processCompanyProfileResponse(dataObj, undefined, undefined, language, companyId);

                        if (Ember['default'].$.isFunction(postSuccess)) {
                            postSuccess();
                        }
                    },
                    onError: function onError() {
                        if (Ember['default'].$.isFunction(Error)) {
                            Error();
                        }
                    }
                });
            } else {
                var comProMixResponse = MixCacheManager['default'].getCachedMixResponse(cacheKey);
                var success = ResponseHandler['default'].processCompanyProfileResponse(comProMixResponse, undefined, undefined, language, companyId);

                if (success && Ember['default'].$.isFunction(postSuccess)) {
                    postSuccess();
                } else if (!success && Ember['default'].$.isFunction(Error)) {
                    Error();
                }
            }
        };

        var loadMajorSharhoderData = function loadMajorSharhoderData(companyId, language, postSuccess, Error, cachePolicy) {
            var cacheKey = MixCacheManager['default'].getMajorShareholderCacheKey(cachePolicy, companyId, language);
            var isCachedData = MixCacheManager['default'].isCachedData(cachePolicy, cacheKey, companyId, language);

            if (!isCachedData) {
                WebConnection['default'].sendAjaxRequest({
                    url: RequestGenerator['default'].generateMajorSharhoderUrl(companyId),
                    onSuccess: function onSuccess(dataObj) {

                        MixCacheManager['default'].persistMixResponse(cachePolicy, cacheKey, dataObj);
                        ResponseHandler['default'].processMajorSharhoderResponse(dataObj, companyId, language);

                        if (Ember['default'].$.isFunction(postSuccess)) {
                            postSuccess();
                        }
                    },
                    onError: function onError() {
                        if (Ember['default'].$.isFunction(Error)) {
                            Error();
                        }
                    }
                });
            } else {
                var comProMixResponse = MixCacheManager['default'].getCachedMixResponse(cacheKey);
                var success = ResponseHandler['default'].processMajorSharhoderResponse(comProMixResponse, companyId, language);

                if (success && Ember['default'].$.isFunction(postSuccess)) {
                    postSuccess();
                } else if (!success && Ember['default'].$.isFunction(Error)) {
                    Error();
                }
            }
        };

        var loadCompanyCreditRatingsData = function loadCompanyCreditRatingsData(exchange, symbol, language, postSuccess, Error, cachePolicy) {
            var cacheKey = MixCacheManager['default'].getCreditRatingsCacheKey(cachePolicy, exchange, symbol, language);
            var isCachedData = MixCacheManager['default'].isCachedData(cachePolicy, cacheKey, exchange, symbol, language);

            if (!isCachedData) {
                WebConnection['default'].sendAjaxRequest({
                    url: RequestGenerator['default'].generateCompanyCreditRatingsUrl(exchange, symbol, language),
                    onSuccess: function onSuccess(dataObj) {

                        MixCacheManager['default'].persistMixResponse(cachePolicy, cacheKey, dataObj);
                        ResponseHandler['default'].processCompanyCreditRatingsResponse(dataObj, exchange, symbol, language);

                        if (Ember['default'].$.isFunction(postSuccess)) {
                            postSuccess();
                        }
                    },
                    onError: function onError() {
                        if (Ember['default'].$.isFunction(Error)) {
                            Error();
                        }
                    }
                });
            } else {
                var comProMixResponse = MixCacheManager['default'].getCachedMixResponse(cacheKey);
                var success = ResponseHandler['default'].processCompanyCreditRatingsResponse(comProMixResponse, exchange, symbol, language);

                if (success && Ember['default'].$.isFunction(postSuccess)) {
                    postSuccess();
                } else if (!success && Ember['default'].$.isFunction(Error)) {
                    Error();
                }
            }
        };

        var loadCompanyAnalystContactsData = function loadCompanyAnalystContactsData(exchange, symbol, language, postSuccess, Error, cachePolicy) {
            var cacheKey = MixCacheManager['default'].getAnalystContactsCacheKey(cachePolicy, exchange, symbol, language);
            var isCachedData = MixCacheManager['default'].isCachedData(cachePolicy, cacheKey, exchange, symbol, language);

            if (!isCachedData) {
                WebConnection['default'].sendAjaxRequest({
                    url: RequestGenerator['default'].generateCompanyAnalystContactsUrl(exchange, symbol, language),
                    onSuccess: function onSuccess(dataObj) {

                        MixCacheManager['default'].persistMixResponse(cachePolicy, cacheKey, dataObj);
                        ResponseHandler['default'].processCompanyAnalystContactsResponse(dataObj, exchange, symbol, language);

                        if (Ember['default'].$.isFunction(postSuccess)) {
                            postSuccess();
                        }
                    },
                    onError: function onError() {
                        if (Ember['default'].$.isFunction(Error)) {
                            Error();
                        }
                    }
                });
            } else {
                var comProMixResponse = MixCacheManager['default'].getCachedMixResponse(cacheKey);
                var success = ResponseHandler['default'].processCompanyAnalystContactsResponse(comProMixResponse, exchange, symbol, language);

                if (success && Ember['default'].$.isFunction(postSuccess)) {
                    postSuccess();
                } else if (!success && Ember['default'].$.isFunction(Error)) {
                    Error();
                }
            }
        };

        var loadCompanyShareholderSegmentationData = function loadCompanyShareholderSegmentationData(exchange, symbol, companyId, language, postSuccess, Error, cachePolicy) {
            //var cacheKey = MixCacheManager.getShareholderSegmentationCacheKey(cachePolicy, exchange, symbol, companyId, language);
            var cacheKey = MixCacheManager['default'].getShareholderSegmentationCacheKey(cachePolicy, exchange, symbol, language);

            var isCachedData = MixCacheManager['default'].isCachedData(cachePolicy, cacheKey, exchange, symbol, companyId, language);

            if (!isCachedData) {
                WebConnection['default'].sendAjaxRequest({
                    url: RequestGenerator['default'].generateCompanyShareholderSegmentationUrl(exchange, symbol, companyId, language),
                    onSuccess: function onSuccess(dataObj) {

                        MixCacheManager['default'].persistMixResponse(cachePolicy, cacheKey, dataObj);
                        ResponseHandler['default'].processCompanyShareholderSegmentationResponse(dataObj, exchange, symbol, language, companyId);

                        if (Ember['default'].$.isFunction(postSuccess)) {
                            postSuccess();
                        }
                    },
                    onError: function onError() {
                        if (Ember['default'].$.isFunction(Error)) {
                            Error();
                        }
                    }
                });
            } else {
                var comProMixResponse = MixCacheManager['default'].getCachedMixResponse(cacheKey);
                var success = ResponseHandler['default'].processCompanyShareholderSegmentationResponse(comProMixResponse, exchange, symbol, language, companyId);

                if (success && Ember['default'].$.isFunction(postSuccess)) {
                    postSuccess();
                } else if (!success && Ember['default'].$.isFunction(Error)) {
                    Error();
                }
            }
        };
        //
        // Fundamental Data specific requests
        //
        var loadFundamentalData = function loadFundamentalData(exchange, symbol, language, mode, postSuccess, Error, cachePolicy, level, isCal) {
            var cacheKey = MixCacheManager['default'].getFundamentalDataCacheKey(cachePolicy, exchange, symbol, mode, language, level, isCal);
            var isCachedData = MixCacheManager['default'].isCachedData(cachePolicy, cacheKey, exchange, symbol, language);

            if (!isCachedData) {
                WebConnection['default'].sendAjaxRequest({
                    url: RequestGenerator['default'].generateFundamentalDataUrl(exchange, symbol, mode, language, level, isCal),
                    onSuccess: function onSuccess(dataObj) {

                        MixCacheManager['default'].persistMixResponse(cachePolicy, cacheKey, dataObj);
                        ResponseHandler['default'].processFundamentalDataResponse(dataObj, exchange, symbol, language, mode);
                        ResponseHandler['default'].processFundamentalDataResponseForPortal(dataObj, exchange, symbol, language, mode, isCal);

                        if (Ember['default'].$.isFunction(postSuccess)) {
                            postSuccess();
                        }
                    },
                    onError: function onError() {
                        // TODO: Handle error on fundamental data loading
                        if (Ember['default'].$.isFunction(Error)) {
                            Error();
                        }
                    }
                });
            } else {
                var fundamentalMixResponse = MixCacheManager['default'].getCachedMixResponse(cacheKey);
                ResponseHandler['default'].processFundamentalDataResponse(fundamentalMixResponse, exchange, symbol, language, mode);
                ResponseHandler['default'].processFundamentalDataResponseForPortal(fundamentalMixResponse, exchange, symbol, language, mode, isCal);
                if (Ember['default'].$.isFunction(postSuccess)) {
                    postSuccess();
                }
            }
        };

        //
        // Fundamental Estimate Data specific requests
        //
        var loadEstimateData = function loadEstimateData(exchange, symbol, language, postSuccess, Error, cachePolicy) {
            var cacheKey = MixCacheManager['default'].getEstimateDataCacheKey(cachePolicy, exchange, symbol, language);
            var isCachedData = MixCacheManager['default'].isCachedData(cachePolicy, cacheKey, exchange, symbol, language);

            if (!isCachedData) {
                WebConnection['default'].sendAjaxRequest({
                    url: RequestGenerator['default'].generateFundamentalEstimateDataUrl(exchange, symbol, language),
                    onSuccess: function onSuccess(dataObj) {

                        MixCacheManager['default'].persistMixResponse(cachePolicy, cacheKey, dataObj);
                        ResponseHandler['default'].processFundamentalEstimateResponse(dataObj, exchange, symbol, language);

                        if (Ember['default'].$.isFunction(postSuccess)) {
                            postSuccess();
                        }
                    },
                    onError: function onError() {
                        if (Ember['default'].$.isFunction(Error)) {
                            Error();
                        }
                    }
                });
            } else {
                var fundamentalMixResponse = MixCacheManager['default'].getCachedMixResponse(cacheKey);
                ResponseHandler['default'].processFundamentalEstimateResponse(fundamentalMixResponse, exchange, symbol, language);
                if (Ember['default'].$.isFunction(postSuccess)) {
                    postSuccess();
                }
            }
        };

        var loadDividendData = function loadDividendData(exchange, symbol, startDate, endDate, postSuccess, Error) {
            WebConnection['default'].sendAjaxRequest({
                url: RequestGenerator['default'].generateDividendUrl(exchange, symbol, startDate, endDate),
                onSuccess: function onSuccess(dataObj) {
                    var totDividend = ResponseHandler['default'].processDividendResponse(dataObj);
                    if (Ember['default'].$.isFunction(postSuccess)) {
                        postSuccess(totDividend);
                    }
                },
                onError: function onError() {
                    if (Ember['default'].$.isFunction(Error)) {
                        Error();
                    }
                }
            });
        };

        var loadInsiderTradesData = function loadInsiderTradesData(exchange, symbol, language, postSuccess, Error, cachePolicy) {
            var cacheKey = MixCacheManager['default'].getInsiderTradesCacheKey(cachePolicy, exchange, symbol, language);
            var isCachedData = MixCacheManager['default'].isCachedData(cachePolicy, cacheKey, exchange, symbol);

            if (!isCachedData) {
                WebConnection['default'].sendAjaxRequest({
                    url: RequestGenerator['default'].generateInsiderTradesUrl(exchange, symbol),

                    onSuccess: function onSuccess(dataObj) {
                        MixCacheManager['default'].persistMixResponse(cachePolicy, cacheKey, dataObj);
                        ResponseHandler['default'].processInsiderTradesResponse(dataObj, exchange, symbol, language);
                        if (Ember['default'].$.isFunction(postSuccess)) {
                            postSuccess();
                        }
                    },
                    onError: function onError() {
                        if (Ember['default'].$.isFunction(Error)) {
                            Error();
                        }
                    }
                });
            } else {

                var insiderTradesMixResponse = MixCacheManager['default'].getCachedMixResponse(cacheKey);
                var success = ResponseHandler['default'].processInsiderTradesResponse(insiderTradesMixResponse, exchange, symbol, language);

                if (success && Ember['default'].$.isFunction(postSuccess)) {
                    postSuccess();
                } else if (!success && Ember['default'].$.isFunction(Error)) {
                    Error();
                }
            }
        };

        var sendTimeAndSalesBackLogRequest = function sendTimeAndSalesBackLogRequest(exchange, symbol, succFn, endSequence, startSequence) {
            WebConnection['default'].sendAjaxRequest({
                url: RequestGenerator['default'].generateSymbolTimeAndSalesBacklogUrl(exchange, symbol, endSequence, startSequence),
                onSuccess: function onSuccess(dataObj) {
                    ResponseHandler['default'].processSymbolTimeAndSalesBacklogResponse(dataObj);

                    if (Ember['default'].$.isFunction(succFn)) {
                        succFn();
                    }
                },

                onError: function onError() {
                    // TODO: Handle error on symbol validation data loading
                }
            });
        };

        var loadFileSearchData = function loadFileSearchData(params, cachePolicy) {
            var cacheKey = MixCacheManager['default'].getFileCacheKey(cachePolicy, params.exg, params.sym, params.cid, params.scat, params.lang);
            var isCachedData = MixCacheManager['default'].isCachedData(cachePolicy, cacheKey, params.exg, params.sym);

            if (!isCachedData) {
                WebConnection['default'].sendAjaxRequest({
                    url: RequestGenerator['default'].generateAllLoadDocumentSearchDataUrl(params.cid, params.tsr, params.scat, params.history, params.lang),

                    onSuccess: function onSuccess(dataObj) {
                        MixCacheManager['default'].persistMixResponse(cachePolicy, cacheKey, dataObj);
                        ResponseHandler['default'].processDocumentDataSearchResponse(dataObj, params.exg, params.sym, params.scat, params.success, params.failure);
                    },
                    onError: function onError() {
                        // TODO: Handle error on data loading
                    }
                });
            } else {
                    var fileMixResponse = MixCacheManager['default'].getCachedMixResponse(cacheKey);
                    ResponseHandler['default'].processDocumentDataSearchResponse(fileMixResponse, params.exg, params.sym, params.scat, params.success, params.failure);
                }
        };

        var loadEventCalendarData = function loadEventCalendarData(params) {
            WebConnection['default'].sendAjaxRequest({
                url: RequestGenerator['default'].generateEventCalenderUrl(params),
                onSuccess: function onSuccess(dataObj) {
                    ResponseHandler['default'].processEventCalendarResponse(dataObj, params.exchange, params.symbol, params.language);
                    if (Ember['default'].$.isFunction(params.successFn)) {
                        params.successFn();
                    }
                },
                onError: function onError() {
                    // TODO: Handle error on event calendar data loading
                    if (Ember['default'].$.isFunction(params.errorFn)) {
                        params.errorFn();
                    }
                }
            });
        };

        var loadCurrencyData = function loadCurrencyData(pairCode, postSuccess, Error, cachePolicy) {
            var cacheKey = MixCacheManager['default'].getCurrencyRateCacheKey(cachePolicy, pairCode);
            var isCachedData = MixCacheManager['default'].isCachedData(cachePolicy, cacheKey);

            if (!isCachedData) {
                WebConnection['default'].sendAjaxRequest({
                    url: RequestGenerator['default'].generateCurrencyDataUrl(pairCode),
                    onSuccess: function onSuccess(dataObj) {
                        postSuccess();
                        MixCacheManager['default'].persistMixResponse(cachePolicy, cacheKey, dataObj);
                        ResponseHandler['default'].processCurrencyDataResponse(dataObj, pairCode);
                    },
                    onError: function onError() {
                        if (Ember['default'].$.isFunction(Error)) {
                            Error();
                        }
                    }
                });
            } else {
                var currencyRateMixResponse = MixCacheManager['default'].getCachedMixResponse(cacheKey);
                ResponseHandler['default'].processCurrencyDataResponse(currencyRateMixResponse, pairCode);
                if (Ember['default'].$.isFunction(postSuccess)) {
                    postSuccess();
                }
            }
        };

        var downloadBulkExchangeProperty = function downloadBulkExchangeProperty(exchangeArray, language, postSuccess, Error, cachePolicy) {
            var cacheKey = MixCacheManager['default'].getExchangeArrayCacheKey(cachePolicy, exchangeArray, language);
            var isCachedData = MixCacheManager['default'].isCachedData(cachePolicy, cacheKey);

            if (!isCachedData) {
                WebConnection['default'].sendAjaxRequest({
                    url: RequestGenerator['default'].generateExchangeDataBulkUrl(exchangeArray, language),
                    onSuccess: function onSuccess(dataObj) {
                        MixCacheManager['default'].persistMixResponse(cachePolicy, cacheKey, dataObj);
                        ResponseHandler['default'].processExchangeResponse(dataObj, exchangeArray, language);
                    },
                    onError: function onError() {
                        if (Ember['default'].$.isFunction(Error)) {
                            Error();
                        }
                    }
                });
            } else {
                var exchangeArrayMixResponse = MixCacheManager['default'].getCachedMixResponse(cacheKey);
                ResponseHandler['default'].processExchangeResponse(exchangeArrayMixResponse, exchangeArray, language);
                if (Ember['default'].$.isFunction(postSuccess)) {
                    postSuccess();
                }
            }
        };

        var loadCapitalActionData = function loadCapitalActionData(exchange, symbol, language, dataType, params, postSuccess, Error) {

            WebConnection['default'].sendAjaxRequest({
                url: RequestGenerator['default'].generateCapitalActionDataUrl(exchange, symbol, language, dataType, params),
                onSuccess: function onSuccess(dataObj) {
                    ResponseHandler['default'].processCapitalActionResponse(dataObj, exchange, symbol, language, dataType, params);

                    if (Ember['default'].$.isFunction(postSuccess)) {
                        postSuccess();
                    }
                },
                onError: function onError() {
                    if (Ember['default'].$.isFunction(Error)) {
                        Error();
                    }
                }
            });
        };

        var loadNonCapitalActionData = function loadNonCapitalActionData(exchange, symbol, language, dataType, postSuccess, Error, cachePolicy, companyId) {
            var cacheKey = MixCacheManager['default'].getCorporateActionCacheKey(cachePolicy, exchange, symbol, language, dataType);
            var isCachedData = MixCacheManager['default'].isCachedData(cachePolicy, cacheKey);

            if (!isCachedData) {
                // TODO isCachedData
                WebConnection['default'].sendAjaxRequest({
                    url: RequestGenerator['default'].generateNonCapitalActionDataUrl(exchange, symbol, language, dataType, companyId),

                    onSuccess: function onSuccess(dataObj) {
                        MixCacheManager['default'].persistMixResponse(cachePolicy, cacheKey, dataObj);
                        ResponseHandler['default'].processNonCapitalActionResponse(dataObj, exchange, symbol, language);

                        if (Ember['default'].$.isFunction(postSuccess)) {
                            postSuccess();
                        }
                    },
                    onError: function onError() {
                        if (Ember['default'].$.isFunction(Error)) {
                            Error();
                        }
                    }
                });
            } else {
                var fileMixResponse = MixCacheManager['default'].getCachedMixResponse(cacheKey);
                var success = ResponseHandler['default'].processNonCapitalActionResponse(fileMixResponse, exchange, symbol, language);

                if (success && Ember['default'].$.isFunction(postSuccess)) {
                    postSuccess();
                } else if (!success && Ember['default'].$.isFunction(Error)) {
                    Error();
                }
            }
        };

        var loadCapitalOrNonCapitalData = function loadCapitalOrNonCapitalData(type, language, cachePolicy, postSuccess, Error) {
            var cacheKey = MixCacheManager['default'].getCapitalOrNonCapitalCacheKey(cachePolicy, type, language);
            var isCachedData = MixCacheManager['default'].isCachedData(cachePolicy, cacheKey);

            if (!isCachedData) {
                WebConnection['default'].sendAjaxRequest({
                    url: RequestGenerator['default'].generateCapOrNonCapDataUrl(type, language),

                    onSuccess: function onSuccess(dataObj) {
                        MixCacheManager['default'].persistMixResponse(cachePolicy, cacheKey, dataObj);
                        ResponseHandler['default'].processCapOrNonCapResponse(dataObj, type, language);

                        if (Ember['default'].$.isFunction(postSuccess)) {
                            postSuccess();
                        }
                    },
                    onError: function onError() {
                        if (Ember['default'].$.isFunction(Error)) {
                            Error();
                        }
                    }
                });
            } else {
                var fileMixResponse = MixCacheManager['default'].getCachedMixResponse(cacheKey);
                var success = ResponseHandler['default'].processCapOrNonCapResponse(fileMixResponse, type, language);

                if (success && Ember['default'].$.isFunction(postSuccess)) {
                    postSuccess();
                } else if (!success && Ember['default'].$.isFunction(Error)) {
                    Error();
                }
            }
        };

        var loadCapActionDetailData = function loadCapActionDetailData(exchage, symbol, language, dataType, actionId, postSuccess, Error, cachePolicy) {
            var cacheKey = MixCacheManager['default'].getCapitalActionDetailCacheKey(cachePolicy, exchage, symbol, actionId, language);
            var isCachedData = MixCacheManager['default'].isCachedData(cachePolicy, cacheKey);

            if (!isCachedData) {
                WebConnection['default'].sendAjaxRequest({
                    url: RequestGenerator['default'].generateCapActDetailUrl(actionId, language),

                    onSuccess: function onSuccess(dataObj) {
                        MixCacheManager['default'].persistMixResponse(cachePolicy, cacheKey, dataObj);
                        ResponseHandler['default'].processCapActDetailResponse(dataObj, exchage, symbol, language, dataType, actionId);

                        if (Ember['default'].$.isFunction(postSuccess)) {
                            postSuccess();
                        }
                    },
                    onError: function onError() {
                        if (Ember['default'].$.isFunction(Error)) {
                            Error();
                        }
                    }
                });
            } else {
                var fileMixResponse = MixCacheManager['default'].getCachedMixResponse(cacheKey);
                var success = ResponseHandler['default'].processCapActDetailResponse(fileMixResponse, exchage, symbol, language, dataType, actionId);

                if (success && Ember['default'].$.isFunction(postSuccess)) {
                    postSuccess();
                } else if (!success && Ember['default'].$.isFunction(Error)) {
                    Error();
                }
            }
        };

        var loadForeignOwnershipLimitData = function loadForeignOwnershipLimitData(exchange, symbol, language, postSuccess, Error, cachePolicy) {
            var cacheKey = MixCacheManager['default'].getForeignOwnershipCacheKey(cachePolicy, exchange, symbol, language);
            var isCachedData = MixCacheManager['default'].isCachedData(cachePolicy, cacheKey);

            if (!isCachedData) {
                WebConnection['default'].sendAjaxRequest({
                    url: RequestGenerator['default'].generateForeignOwnershipUrl(exchange, symbol),

                    onSuccess: function onSuccess(dataObj) {
                        MixCacheManager['default'].persistMixResponse(cachePolicy, cacheKey, dataObj);
                        ResponseHandler['default'].processForeignOwnershipResponse(dataObj, exchange, symbol, language);

                        if (Ember['default'].$.isFunction(postSuccess)) {
                            postSuccess();
                        }
                    },
                    onError: function onError() {
                        if (Ember['default'].$.isFunction(Error)) {
                            Error();
                        }
                    }
                });
            } else {
                var fileMixResponse = MixCacheManager['default'].getCachedMixResponse(cacheKey);
                var success = ResponseHandler['default'].processForeignOwnershipResponse(fileMixResponse, exchange, symbol, language);

                if (success && Ember['default'].$.isFunction(postSuccess)) {
                    postSuccess();
                } else if (!success && Ember['default'].$.isFunction(Error)) {
                    Error();
                }
            }
        };

        var loadIPOData = function loadIPOData(requestType, language, dataType, params, postSuccess, Error) {
            WebConnection['default'].sendAjaxRequest({
                url: RequestGenerator['default'].generateIpoDataUrl(requestType, language, params, dataType),

                onSuccess: function onSuccess(dataObj) {
                    ResponseHandler['default'].processIpoDataResponse(dataObj, requestType, language, dataType, params);

                    if (Ember['default'].$.isFunction(postSuccess)) {
                        postSuccess();
                    }
                },
                onError: function onError() {
                    if (Ember['default'].$.isFunction(Error)) {
                        Error();
                    }
                }
            });
        };

        var loadIpoIndustryOrCompanyListData = function loadIpoIndustryOrCompanyListData(requestType, language, dataType, postSuccess, Error, cachePolicy) {
            var cacheKey = MixCacheManager['default'].getIpoIndustryCompanyCacheKey(cachePolicy, requestType, language, dataType);
            var isCachedData = MixCacheManager['default'].isCachedData(cachePolicy, cacheKey);

            if (!isCachedData) {
                WebConnection['default'].sendAjaxRequest({
                    url: RequestGenerator['default'].generateIPOCompanyUrl(language, dataType),

                    onSuccess: function onSuccess(dataObj) {
                        MixCacheManager['default'].persistMixResponse(cachePolicy, cacheKey, dataObj);
                        ResponseHandler['default'].processIpoIndustryOrCompanyResponse(dataObj, requestType, language, dataType);

                        if (Ember['default'].$.isFunction(postSuccess)) {
                            postSuccess();
                        }
                    },
                    onError: function onError() {
                        if (Ember['default'].$.isFunction(Error)) {
                            Error();
                        }
                    }
                });
            } else {
                var fileMixResponse = MixCacheManager['default'].getCachedMixResponse(cacheKey);
                var success = ResponseHandler['default'].processIpoIndustryOrCompanyResponse(fileMixResponse, requestType, language, dataType);

                if (success && Ember['default'].$.isFunction(postSuccess)) {
                    postSuccess();
                } else if (!success && Ember['default'].$.isFunction(Error)) {
                    Error();
                }
            }
        };

        var loadFairValuesData = function loadFairValuesData(exchange, symbol, language, dataType, postSuccess, Error, cachePolicy) {
            var cacheKey = MixCacheManager['default'].getFairValuesCacheKey(cachePolicy, exchange, symbol, language);
            var isCachedData = MixCacheManager['default'].isCachedData(cachePolicy, cacheKey);

            if (!isCachedData) {
                WebConnection['default'].sendAjaxRequest({
                    url: RequestGenerator['default'].generateFairValuesUrl(exchange, symbol),

                    onSuccess: function onSuccess(dataObj) {
                        MixCacheManager['default'].persistMixResponse(cachePolicy, cacheKey, dataObj);
                        ResponseHandler['default'].processFairValuesResponse(dataObj, exchange, symbol, language, dataType);

                        if (Ember['default'].$.isFunction(postSuccess)) {
                            postSuccess();
                        }
                    },
                    onError: function onError() {
                        if (Ember['default'].$.isFunction(Error)) {
                            Error();
                        }
                    }
                });
            } else {
                var fairValuesResponse = MixCacheManager['default'].getCachedMixResponse(cacheKey);
                var success = ResponseHandler['default'].processFairValuesResponse(fairValuesResponse, exchange, symbol, language, dataType);

                if (success && Ember['default'].$.isFunction(postSuccess)) {
                    postSuccess();
                } else if (!success && Ember['default'].$.isFunction(Error)) {
                    Error();
                }
            }
        };

        //Handle segmentation request
        var loadSegmentationData = function loadSegmentationData(exchange, symbol, language, dataType, postSuccess, Error, cachePolicy) {
            var cacheKey = MixCacheManager['default'].getSegmentationCacheKey(cachePolicy, exchange, symbol, language);
            var isCachedData = MixCacheManager['default'].isCachedData(cachePolicy, cacheKey, exchange, symbol);

            if (!isCachedData) {
                WebConnection['default'].sendAjaxRequest({
                    url: RequestGenerator['default'].generateSegmentationUrl(exchange, symbol, language),
                    onSuccess: function onSuccess(dataObj) {
                        MixCacheManager['default'].persistMixResponse(cachePolicy, cacheKey, dataObj);
                        ResponseHandler['default'].processSegmentationResponse(dataObj, exchange, symbol, language, dataType);

                        if (Ember['default'].$.isFunction(postSuccess)) {
                            postSuccess();
                        }
                    },
                    onError: function onError() {
                        if (Ember['default'].$.isFunction(Error)) {
                            Error();
                        }
                    }
                });
            } else {
                var segmentationResponse = MixCacheManager['default'].getCachedMixResponse(cacheKey);
                var success = ResponseHandler['default'].processSegmentationResponse(segmentationResponse, exchange, symbol, language, dataType); //enter seg response to data

                if (success && Ember['default'].$.isFunction(postSuccess)) {
                    postSuccess();
                } else if (!success && Ember['default'].$.isFunction(Error)) {
                    Error();
                }
            }
        };

        var loadRatioComparisonData = function loadRatioComparisonData(exchage, symbol, instType, dataType, postSuccess, Error, cachePolicy) {
            var cacheKey = MixCacheManager['default'].getRatioComparisonDataCacheKey(cachePolicy, exchage, symbol, instType, dataType);
            var isCachedData = MixCacheManager['default'].isCachedData(cachePolicy, cacheKey);

            if (!isCachedData) {
                WebConnection['default'].sendAjaxRequest({
                    url: RequestGenerator['default'].generateRatioComparisonUrl(exchage, symbol, instType, dataType),

                    onSuccess: function onSuccess(dataObj) {
                        MixCacheManager['default'].persistMixResponse(cachePolicy, cacheKey, dataObj);
                        ResponseHandler['default'].processRatioComparisonResponse(dataObj, exchage, symbol, instType, dataType);

                        if (Ember['default'].$.isFunction(postSuccess)) {
                            postSuccess();
                        }
                    },
                    onError: function onError() {
                        if (Ember['default'].$.isFunction(Error)) {
                            Error();
                        }
                    }
                });
            } else {
                var fileMixResponse = MixCacheManager['default'].getCachedMixResponse(cacheKey);
                var success = ResponseHandler['default'].processRatioComparisonResponse(fileMixResponse, exchage, symbol, instType, dataType);

                if (success && Ember['default'].$.isFunction(postSuccess)) {
                    postSuccess();
                } else if (!success && Ember['default'].$.isFunction(Error)) {
                    Error();
                }
            }
        };

        var loadContactPersonData = function loadContactPersonData(individualId, companyId, language, postSuccess, Error, cachePolicy) {
            var cacheKey = MixCacheManager['default'].getContactPersonCacheKey(cachePolicy, individualId, companyId, language);
            var isCachedData = MixCacheManager['default'].isCachedData(cachePolicy, cacheKey, individualId, companyId, language);

            if (!isCachedData) {
                WebConnection['default'].sendAjaxRequest({
                    url: RequestGenerator['default'].generateContactPersonUrl(individualId, companyId),
                    onSuccess: function onSuccess(dataObj) {

                        MixCacheManager['default'].persistMixResponse(cachePolicy, cacheKey, dataObj);
                        ResponseHandler['default'].processContactPersonResponse(dataObj, individualId, companyId, language);

                        if (Ember['default'].$.isFunction(postSuccess)) {
                            postSuccess();
                        }
                    },
                    onError: function onError() {
                        if (Ember['default'].$.isFunction(Error)) {
                            Error();
                        }
                    }
                });
            } else {
                var comProMixResponse = MixCacheManager['default'].getCachedMixResponse(cacheKey);
                var success = ResponseHandler['default'].processContactPersonResponse(comProMixResponse, individualId, companyId, language);

                if (success && Ember['default'].$.isFunction(postSuccess)) {
                    postSuccess();
                } else if (!success && Ember['default'].$.isFunction(Error)) {
                    Error();
                }
            }
        };

        var sendPostDataEmail = function sendPostDataEmail(emailObj, postSuccess, Error) {

            //var sendStr= '{DATA:clientId|'+emailObj.clientId+',name|'+emailObj.name+',email|'+emailObj.email+',content|'+emailObj.message+'}';
            WebConnection['default'].sendAjaxRequest({
                //accept: 'application/json',
                //contentType: 'application/json',
                type: 'GET',
                url: RequestGenerator['default'].generateSendEmailUrl(emailObj),
                //data: sendStr,
                onSuccess: function onSuccess() {
                    if (Ember['default'].$.isFunction(postSuccess)) {
                        postSuccess();
                    }
                },
                onError: function onError() {
                    if (Ember['default'].$.isFunction(Error)) {
                        Error();
                    }
                }
            });
        };

        var loadVideoFiles = function loadVideoFiles(exchange, symbol, language, postSuccess, error) {

            WebConnection['default'].sendAjaxRequest({
                url: RequestGenerator['default'].generateVideoFileUrl(exchange, symbol),
                type: 'GET',
                onSuccess: function onSuccess(dataObj) {
                    ResponseHandler['default'].processVideoUrlResponse(dataObj, exchange, symbol, language);
                    postSuccess();
                },

                onError: function onError() {
                    if (Ember['default'].$.isFunction(error)) {
                        error();
                    }
                }
            });
        };

        var loadServerVersion = function loadServerVersion(postSuccess, error) {
            WebConnection['default'].sendAjaxRequest({
                url: RequestGenerator['default'].generateServerVersionUrl(),
                onSuccess: function onSuccess(dataObj) {
                    ResponseHandler['default'].processServerVersionResponse(dataObj);
                    postSuccess();
                },

                onError: function onError() {
                    if (Ember['default'].$.isFunction(error)) {
                        error();
                    }
                }
            });
        };

        var loadDocuments = function loadDocuments(exchange, symbol, language, postSuccess, error) {

            WebConnection['default'].sendAjaxRequest({
                url: RequestGenerator['default'].generateDocumentUrl(exchange, symbol),
                type: 'GET',
                onSuccess: function onSuccess(dataObj) {
                    ResponseHandler['default'].processDocumentResponse(dataObj, exchange, symbol, language);
                    postSuccess();
                },

                onError: function onError() {
                    if (Ember['default'].$.isFunction(error)) {
                        error();
                    }
                }
            });
        };

        var sendAlertData = function sendAlertData(exchange, symbol, action, criteria, fName, lName, email, postSuccess, error) {
            var sid = new Date().getTime();
            var uid = new Date().getTime();
            WebConnection['default'].sendAjaxRequest({
                url: RequestGenerator['default'].generateAlertUrl(exchange, symbol, action, criteria, fName, lName, email, sid, uid),
                type: 'GET',
                onSuccess: function onSuccess(dataObj) {
                    postSuccess(dataObj);
                },

                onError: function onError() {
                    if (Ember['default'].$.isFunction(error)) {
                        error();
                    }
                }
            });
        };
        var sendCalEventSubscription = function sendCalEventSubscription(exchange, symbol, criteria, fName, lName, email, company, profession, postSuccess, error) {
            var sid = new Date().getTime();
            var uid = new Date().getTime();
            WebConnection['default'].sendAjaxRequest({
                url: RequestGenerator['default'].generateCalEventSubUrl(exchange, symbol, criteria, fName, lName, email, company, profession, sid, uid),
                type: 'GET',
                onSuccess: function onSuccess(dataObj) {
                    postSuccess(dataObj);
                },

                onError: function onError() {
                    if (Ember['default'].$.isFunction(error)) {
                        error();
                    }
                }
            });
        };
        var getAlertUserData = function getAlertUserData(id, action, postSuccess, error) {
            var sid = new Date().getTime();
            var uid = new Date().getTime();
            WebConnection['default'].sendAjaxRequest({
                url: RequestGenerator['default'].generateAlertUserDataUrl(id, action, sid, uid),
                type: 'GET',
                onSuccess: function onSuccess(dataObj) {
                    ResponseHandler['default'].processAlertUserData(id, dataObj);
                    postSuccess();
                },

                onError: function onError() {
                    if (Ember['default'].$.isFunction(error)) {
                        error();
                    }
                }
            });
        };
        return {
            loadExchangeMasterInfoForExchange: loadExchangeMasterInfoForExchange,
            loadExchangeData: loadExchangeData,
            loadSymbolData: loadSymbolData,
            loadSymbolValidationData: loadSymbolValidationData,
            loadSymbolValidationBulkData: loadSymbolValidationBulkData,
            loadSymbolSearchData: loadSymbolSearchData,
            loadSmartSearchData: loadSmartSearchData,
            loadIntradayData: loadIntradayData,
            loadChartData: loadChartData,
            loadAnnouncementBody: loadAnnouncementBody,
            loadNewsBody: loadNewsBody,
            loadNewsDetail: loadNewsDetail,
            loadAnnouncementSearchData: loadAnnouncementSearchData,
            loadNewsSearchData: loadNewsSearchData,
            loadCompanyProfileData: loadCompanyProfileData,
            loadCompanyMgmntData: loadCompanyMgmntData,
            loadCompanyMgmntDataByCompanyId: loadCompanyMgmntDataByCompanyId,
            sendTimeAndSalesBackLogRequest: sendTimeAndSalesBackLogRequest,
            loadFundamentalData: loadFundamentalData,
            loadFileSearchData: loadFileSearchData,
            loadEventCalendarData: loadEventCalendarData,
            loadCurrencyData: loadCurrencyData,
            loadDividendData: loadDividendData,
            loadInsiderTradesData: loadInsiderTradesData,
            downloadBulkExchangeProperty: downloadBulkExchangeProperty,
            loadExchangeMetaData: loadExchangeMetaData,
            loadCapitalActionData: loadCapitalActionData,
            loadNonCapitalActionData: loadNonCapitalActionData,
            loadCapitalOrNonCapitalData: loadCapitalOrNonCapitalData,
            loadCapActionDetailData: loadCapActionDetailData,
            loadForeignOwnershipLimitData: loadForeignOwnershipLimitData,
            loadIPOData: loadIPOData,
            loadIpoIndustryOrCompanyListData: loadIpoIndustryOrCompanyListData,
            loadFairValuesData: loadFairValuesData,
            loadCompanyProfileDataByCompanyId: loadCompanyProfileDataByCompanyId,
            loadMajorSharhoderData: loadMajorSharhoderData,
            loadSegmentationData: loadSegmentationData,
            loadRatioComparisonData: loadRatioComparisonData,
            loadContactPersonData: loadContactPersonData,
            sendPostDataEmail: sendPostDataEmail,
            loadEstimateData: loadEstimateData,
            loadCompanyCreditRatingsData: loadCompanyCreditRatingsData,
            loadCompanyAnalystContactsData: loadCompanyAnalystContactsData,
            loadCompanyShareholderSegmentationData: loadCompanyShareholderSegmentationData,
            loadSystemDefinitions: loadSystemDefinitions,
            loadVideoFiles: loadVideoFiles,
            loadDocuments: loadDocuments,
            sendAlertData: sendAlertData,
            sendCalEventSubscription: sendCalEventSubscription,
            loadServerVersion: loadServerVersion,
            getAlertUserData: getAlertUserData
        };
    })();

});