define('ir-app/views/table/dual-cells/header-cell', ['exports', 'ember-table/views/header-cell'], function (exports, HeaderCell) {

        'use strict';

        exports['default'] = HeaderCell['default'].extend({
                templateName: 'table/views/header-cell',

                firstValue: (function () {
                        return this.get('content.headerCellName');
                }).property('content.headerCellName'),

                secondValue: (function () {
                        return this.get('content.headerCellSecondName');
                }).property('content.headerCellSecondName'),

                styles: (function () {
                        return this.get('column.headerStyles') ? this.get('column.headerStyles') : '';
                }).property('cellContent'),

                conditionalStyles: (function () {
                        return this.get('content').isSortSupported && this.get('content').isSorted ? 'sort-back-color' : ''; //  Access controller parameters from view
                }).property('cellContent', 'content.isSorted')
        });

});