define('ir-app/templates/ir-widgets/tab-layouts/financial-dividend-layout', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","container");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"id","tabsPane");
        dom.setAttribute(el2,"class","scroll");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"role","tablist");
        dom.setAttribute(el3,"class","dividend-widget");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4,"class","nav nav-tabs mb-3 tabs-list");
        dom.setAttribute(el4,"id","pills-tab");
        dom.setAttribute(el4,"style","display:block;");
        dom.setAttribute(el4,"role","tablist");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"class","nav-item nav-items active");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","nav-link");
        dom.setAttribute(el6,"id","pills-home-tab");
        dom.setAttribute(el6,"data-toggle","pill");
        dom.setAttribute(el6,"href","#pills-overview");
        dom.setAttribute(el6,"role","tab");
        dom.setAttribute(el6,"aria-controls","pills-home");
        dom.setAttribute(el6,"aria-selected","true");
        var el7 = dom.createTextNode("Overview");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"class","nav-item nav-items");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","nav-link");
        dom.setAttribute(el6,"id","pills-profile-tab");
        dom.setAttribute(el6,"data-toggle","pill");
        dom.setAttribute(el6,"href","#pills-totalReturn");
        dom.setAttribute(el6,"role","tab");
        dom.setAttribute(el6,"aria-controls","pills-profile");
        dom.setAttribute(el6,"aria-selected","false");
        var el7 = dom.createTextNode("Total Return");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"class","nav-item nav-items");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","nav-link");
        dom.setAttribute(el6,"id","pills-contact-tab");
        dom.setAttribute(el6,"data-toggle","pill");
        dom.setAttribute(el6,"href","#pills-dividendHistory");
        dom.setAttribute(el6,"role","tab");
        dom.setAttribute(el6,"aria-controls","pills-contact");
        dom.setAttribute(el6,"aria-selected","false");
        var el7 = dom.createTextNode("Dividend History");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"class","nav-item nav-items");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","nav-link");
        dom.setAttribute(el6,"id","pills-contact-tab");
        dom.setAttribute(el6,"data-toggle","pill");
        dom.setAttribute(el6,"href","#pills-dividendCalculator");
        dom.setAttribute(el6,"role","tab");
        dom.setAttribute(el6,"aria-controls","pills-contact");
        dom.setAttribute(el6,"aria-selected","false");
        var el7 = dom.createTextNode("Return Calculator");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("      \n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","tab-content");
        dom.setAttribute(el3,"id","myTabContent");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","tab-pane fade active in");
        dom.setAttribute(el4,"id","pills-overview");
        dom.setAttribute(el4,"role","tabpanel");
        dom.setAttribute(el4,"aria-labelledby","home-tab");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","tab-pane fade");
        dom.setAttribute(el4,"id","pills-totalReturn");
        dom.setAttribute(el4,"role","tabpanel");
        dom.setAttribute(el4,"aria-labelledby","profile-tab");
        var el5 = dom.createTextNode("\n            \n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            \n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","tab-pane fade");
        dom.setAttribute(el4,"id","pills-dividendHistory");
        dom.setAttribute(el4,"role","tabpanel");
        dom.setAttribute(el4,"aria-labelledby","contact-tab");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","tab-pane fade");
        dom.setAttribute(el4,"id","pills-dividendCalculator");
        dom.setAttribute(el4,"role","tabpanel");
        dom.setAttribute(el4,"aria-labelledby","contact-tab");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, inline = hooks.inline;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [5, 1]);
        var morph0 = dom.createMorphAt(dom.childAt(element0, [1]),1,1);
        var morph1 = dom.createMorphAt(dom.childAt(element1, [1]),1,1);
        var morph2 = dom.createMorphAt(dom.childAt(element1, [3]),1,1);
        var morph3 = dom.createMorphAt(dom.childAt(element1, [5]),1,1);
        var morph4 = dom.createMorphAt(dom.childAt(element1, [7]),1,1);
        inline(env, morph0, context, "outlet", ["w4"], {});
        inline(env, morph1, context, "outlet", ["w1"], {});
        inline(env, morph2, context, "outlet", ["w2"], {});
        inline(env, morph3, context, "outlet", ["w3"], {});
        inline(env, morph4, context, "outlet", ["w5"], {});
        return fragment;
      }
    };
  }()));

});