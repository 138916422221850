define('ir-app/models/price/price-constants', ['exports'], function (exports) {

    'use strict';

    exports['default'] = {
        ProductType: 40,
        UnixTimestampByMinutes: 60,
        UnixTimestampByMilliSeconds: 1000,
        AnnouncementNewsCashSize: 10,
        SymbolSearchPageSize: 15,
        AnnouncementSearchPageSize: 10,
        NewsSearchPageSize: 10,

        MarketStatus: {
            Initialising: 0,
            PreOpen: 1,
            Open: 2,
            Close: 3,
            PreClose: 4,
            OpenSuspended: 5,
            PreCloseSuspended: 6,
            CloseSuspended: 7,
            PreOpenSuspended: 8,
            MovingToPreOpen: 9,
            MovingToOpen: 10,
            MovingToPreClose: 11,
            MovingToClose: 12,
            PreCloseWithTrades: 18,
            CloseWithTrades: 19,
            TradingAtLast: 20,
            IntermediateClose: 22,
            PreCloseAdjustment: 36,
            Unknown: -1
        },

        // TODO: [Amila] Response and request types needs to be merged as per the protocol suggestion (item 08 of the google doc)
        RequestType: {
            Authentication: 150,

            Data: {
                RequestEquity: 0,
                RequestIndex: 7,
                RequestOHLC: 17,
                RequestAnnouncement: 27,
                RequestMarketDepthByOrder: 29,
                RequestMarketDepthByPrice: 30,
                RequestExchange: 32,
                RequestTopStocks: 64,
                RequestNews: 77,
                RequestTimeAndSales: 1
            }
        },

        ResponseType: {
            Authentication: 150,
            Pulse: 0,
            MessageType: '1',

            Data: {
                ResponseEquity: 3,
                ResponseIndex: 4,
                ResponseExchange: 5,
                ResponseOHLC: 6,
                ResponseMarketDepthByOrder: 7,
                ResponseMarketDepthByPrice: 9,
                ResponseAnnouncement: 11,
                ResponseTopStocks: 64,
                ResponseNews: 77,
                ResponseTimeAndSales: 2
            }
        },

        MixRequest: {
            ExchangeDetails: {
                RT: 50,
                MT: 'EXG,SUBM,IDX'
            },
            SymbolValidation: {

                RT: 52
            },
            SymbolSearch: {
                RT: 53,
                IFLD: 'DES,ISINC,DEP,CFID,TC,DS',
                ST: 'S,SDES,DES',
                XFLD: 'SEC'
            },
            SmartSearch: {
                RT: 53,
                IFLD: 'DES,ISINC,DEP,CFID,TC,DS,CTD,COM,CC,LIS',
                ST: 'S,SDES,DES,ISINC,COM',
                XFLD: 'SEC',
                PGS: 30,
                UE: 'ADSM,ASE,BSE,CSE,DFM,CASE,KSE,MSM,QE,TDWL,DIFX',
                SYMT: '1,5,6,7,8,9,10,18'

            },
            SymbolMetaDetails: {
                RT: 301
            },
            FullSymbolDescription: {
                RT: 303
            },
            ExchangeMetaDetails: {
                RT: 302
            },
            ExchangeFullMeta: {
                RT: 306
            },
            Chart: {
                RT: 37
            },
            AnnouncementBody: {
                RT: 26
            },
            NewsSearch: {
                RT: 27
            },
            NewsBody: {
                RT: 28
            },
            NewsResource: {
                RT: 62,
                MIMG: '1'
            },
            AnnouncementSearch: {
                RT: 25
            },
            CompanyProfile: {
                RT: 32,
                CIT: 'CP,STK,CPCLS,AUD,FFHIST'
            },
            Segmentation: {
                RT: 5305
            },
            CompanyMgmnt: {
                RT: 32,
                CIT: 'INMGT,OWN_IND,OWN_CMP,SUBS,COMGTREP,COMGT,KEY_OF'
            },
            CompanyOwnerLmts: {
                RT: 30,
                SCDT: 'OWLMD'
            },
            MajorShareholder: {
                RT: 30,
                SCDT: 'INOWH,CMOWH'
            },
            TimeAndSalesBacklog: {
                RT: 12
            },
            FundamentalData: {
                RT: 131,
                CFT: 'IS,CF,BS,FR,MR'
            },
            ClientFundamentalData: {
                RT: 5304
            },
            FundamentalEstData: {
                RT: 131,
                CFT: 'IS,CF,BS'
            },
            FileMetaSearch: {
                RT: 97,
                CAT: '5,8',
                SCAT: '10,37,38,39,45,57',
                TSL: 'TICKER_SERIAL_LIST~0',
                CIL: 'COMPANY_ID_LIST~0',
                HIS: 'REPORT_DATE~4',
                FC: '1',
                IFC: '1',
                PGS: '100',
                SF: 'REPORT_DATE',
                SO: 'DESC'
            },
            EventCalendar: {
                RT: 30,
                SCDT: 'CALEVT',
                SO: 'DESC',
                FC: '1',
                IFC: '1',
                PGS: '100'
            },
            Dividend: {
                RT: 30,
                SCDT: 'CPAC',
                PGI: '0',
                FC: 1
            },
            InsiderTrades: {
                RT: 30,
                SCDT: 'INTR',
                PGI: '0',
                FC: 1
            },
            Currency: {
                RT: 16,
                M: '1',
                H: '0'
            },
            CorporateAction: {
                RT: 30,
                H: '1',
                M: '0'
            },
            CapitalAction: {
                RT: 30,
                H: '1',
                M: '0',
                SCDT: 'CPAC,ECATY'
            },
            OwnershipLimit: {
                RT: 30,
                SCDT: 'OWLM_SV_TOT,OWLM_SV_QFI',
                SF: 'OWNERSHIP_VALUE_DATE',
                L: 'EN'
            },
            IpoData: {
                RT: 30,
                SCDT: 'IPO,IPOSTAT',
                PI: 'IPOSTAT'
            },
            RatioData: {
                RT: 601
            },
            IndProfile: {
                RT: 32,
                CIT: 'INMGT,INDV,KEY_OF',
                ITK: 'INDIVIDUAL_ID'
            },
            EmailData: {
                RT: 5101
            },
            FairValues: {
                RT: 30,
                SCDT: 'FRVL'
            },
            DocumentDownload: {
                RT: 87
            },
            CreditRatings: {
                RT: 5307,
                ID: 'CR'
            },
            AnalystContacts: {
                RT: 5309,
                ID: 'AC'
            },
            ShareholderSegmentation: {
                RT: 30,
                SCDT: 'FNINAL,OWN_CMP,OWN_IND'

            },
            ServerVersion: {
                RT: 6000
            }
        },

        MarketDepthSide: {
            Bid: 0,
            Offer: 1
        },

        MixRequestParameters: {
            AllExchange: 1,
            AllSymbol: 1,
            EnableUnicode: 1,
            EnableMetaTag: 1,
            EnableHeaderTag: 1,
            None: 0
        },

        UrlTypes: {
            PRICE: 'price',
            Content: 'content',
            Chart: 'chart',
            // Specific type overrides for IR App
            // TODO: [Chiran] Review below once the deployment is done at LDC
            Fundamental: 'fundamental/',
            FileContent: 'filecontent/',
            Announcement: 'announcement',
            CalenderEvents: 'calender_events',
            IntegratedBackend: 'ibe/',
            Search: 'search'
        },

        TimeAndSales: {
            BacklogRequestDelay: 2500,
            MinItemCount: 5
        },

        AssetTypes: {
            0: 'equity',
            7: 'indices',
            75: 'sukukBonds',
            66: 'right',
            86: 'etf',
            11: 'currency',
            68: 'future'
        },

        TopStocksTypes: {
            TopGainersByChange: 0,
            TopGainersByPercentageChange: 1,
            TopLosersByChange: 2,
            TopLosersByPercentageChange: 3,
            MostActiveByVolume: 4,
            MostActiveByTrades: 5,
            MostActiveByValue: 6
        },

        InstrumentTypes: {
            Equity: 0,
            Indices: 7
        },

        MetaVersionKeys: {
            WatchList: 'WL',
            ExchangeDefinitions: 'SRC'
        },

        // All below intervals are in milli seconds
        TimeIntervals: {
            AnnouncementCachingInterval: 60000, // Time interval - 60 * 1000 (1 min)
            AnnouncementCacheDelay: 15000, // 15 Seconds
            WebSocketOutQueueProcessingInterval: 100,
            WebSocketInQueueProcessingInterval: 100,
            TopStocksUpdateInterval: 60000,
            SearchAutoCompleteInterval: 400,
            SmartSearchAutoCompleteInterval: 90,
            AjaxRequestTimeout: 15000,
            RemoveSubscriptionBufferTime: 30000,
            RemoveSubscriptionProcessInterval: 5000
        },

        // Pulse related constants
        Pulse: {
            PulseInterval: 30000, // Time interval - n * 1000 (n seconds)
            MissedPulseCount: 20, // After this number of missed pulses, application will trigger a disconnection
            ReconnectionTimeInterval: 5000 // Attempt reconnection in this time interval
        },

        SocketConnectionType: {
            QuoteServer: 'qs'
        },

        PriceComponent: {
            DaysRange: 1,
            FiftyTwoWeekHighLow: 2,
            CashMap: 3
        },

        MarketDepthType: {
            DepthByPrice: 1,
            DepthByOrder: 2
        },

        WidgetList: [{ code: 'price.sector-overview', des: 'Sector Overview' }, { code: 'price.top-stocks', des: 'Top Stocks' }, { code: 'price.commodities-overview', des: 'GMS' }, { code: 'price.watch-list', des: 'Watch List' }, { code: 'price.quote-summery', des: 'Quote Summary' }, { code: 'chart.regular-chart', des: 'Quote Chart' }, { code: 'price.quote-intraday-performance', des: 'Stock Performance' }, { code: 'price.quote-time-and-sales', des: 'Time and Sales' }, { code: 'price.quote-market-depth', des: 'Depth by Price', args: { mode: 1 } }, { code: 'price.quote-market-depth', des: 'Depth by Order', args: { mode: 2 } }, { code: 'price.company-profile.company-basic-info', des: 'Company Profile' }, { code: 'price.company-profile.company-management-info', des: 'Company Management' }, { code: 'price.company-profile.company-owners-info', des: 'Company Owners' }, { code: 'price.company-profile.company-subsidiaries-info', des: 'Company Subsidiaries' }, { code: 'chart.pro-chart', des: 'Pro Chart' }],

        WidgetDefaults: {
            CompanyOwnersPecentageLimit: 5,
            MajorShareholderMinPct: 5,
            ForeignOwnershipExg: 'TDWL'
        },

        // Data type of Data Stores
        DataTypes: {
            CompanyProfileDataStore: {
                CompanyBasicInfo: 'CompanyBasicInfo',
                CompanyManagementInfo: 'CompanyManagementInfo',
                CompanyMajorShareholder: 'MajorShareholders',
                CompanyCreditRatings: 'CompanyCreditRatings',
                CompanyAnalystContacts: 'CompanyAnalystCoverage',
                CompanyShareholderSegmentation: 'CompanyShareholderSegmentation',
                CompanyOwnershipLimits: 'CompanyOwnershipLimits',
                requestBy: { // this is used to identify request is come with companyId or sym/exg
                    companyId: 'companyId',
                    symbol: 'symbol',
                    both: 'both'
                }
            },
            FundamentalDataStore: {
                QuarterlyInfo: 'Q',
                YearlyInfo: 'A',
                Interim: 'C',
                Ttm: 'T'
            },
            InsiderTradesDataStore: {
                InsiderTrades: 'InsiderTrades'
            },
            CorporateActionDataStore: {
                CapitalActionInfo: 'CPAC',
                EquityCapital: 'ECATY',
                NonCapitalActionInfo: 'NCCPAC',
                NonCapital: 'NCATY',
                SymbolBasedCall: 'SYMBOL',
                CompanyBasedCall: 'COMAPNY'
            },
            ForeignOwnershipDataStore: {
                FornLmt: 'FORIN_LIMIT',
                MaxLimit: 'OWLM_STK',
                ActualLimit: 'OWLM_VAL'
            },
            IpoDataList: {
                IPOData: 'IPOData',
                AllData: 'All',
                IPOCompList: 'IPOCMP',
                IPOIndustryList: 'GICS',
                IpoById: 'IpoId'
            },
            IndividualProfile: {
                CompanyContactPerson: 'CompanyContactPerson'
            },

            EmailService: {
                EmailServiceData: 'EmailServiceData'
            },
            FairValuesDataStore: {
                FairValues: 'FairValues'
            },
            RatioComparisonDataStore: {
                Quarter: 'Q',
                Year: 'Y'
            },
            SegmentationDataStore: {
                Segmentation: 'Segmentation'
            }
        },

        FinancialStatementType: {
            BalanceSheet: 'BS',
            IncomeStatement: 'IS',
            CashFlow: 'CF',
            FinancialRatios: 'FR',
            MarketRatios: 'MR',
            Ratios: 'RATIOS'
        },

        PeriodBasis: {
            Calendar: 1,
            Fiscal: 0
        },

        SubsidiaryType: {
            Subsidiary: '1',
            Associated: '2',
            Investments: '3'
        },

        TemplateID: {
            Industrial: '1',
            Banks: '2',
            Insurance: '3'
        },

        NewsAnnouncementsContentType: {
            Announcements: 1,
            News: 2
        },

        OwnershipType: {
            Company: '1',
            Individual: '2'
        },
        SmartSearch: {
            equityCategories: [{ type: "eq", categoryType: "quote", categoryVal: "1", "disp": "EQUITY", "data": [61, 62, 64, 65, 66, 67, 86, 0, 60], "symType": "1" }],
            nonEquityCategories: [{ type: "unlisted", categoryType: "other", categoryVal: "1", "disp": "Unlisted Company", symType: "-1", compType: 'Ordinary Company' }, { type: "unlisted", categoryType: "other", categoryVal: "7", "disp": "Family", symType: "-7", compType: 'Family' }, { type: "unlisted", categoryType: "other", categoryVal: "18", "disp": "Exchange", symType: "-18", compType: 'Exchange' }],
            exgToDispExg: { ADSM: "ADX",
                ASE: "ASE",
                BSE: "BSE",
                CSE: "CSE",
                DFM: "DFM",
                CASE: "EGX",
                KSE: "KSE",
                MSM: "MSM",
                DSM: "QE",
                TDWL: "TDWL"
            },

            symbolSearchOrder: ["1", "-1", "-7", "-18"]

        },

        CompanyListingStatus: {
            SingleListed: '1',
            MultiListed: '2',
            Unlisted: '3'
        }
    };

});