define('ir-app/models/price/price-business-entities/company-management', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        name: '', // Name
        desig: '', // Designation
        date: '', // start Date
        sortOrder: '', // Sort Order
        resignDate: '', // resignation date
        desigId: '', // designation id
        isIndivMgr: '', // individual manager or not
        isIndivRep: '', // individual representer or not
        mngCompName: '', // managed company name
        mngCompId: '', // managed company id

        setData: function setData(companyManagmentData) {
            var that = this;

            Ember['default'].$.each(companyManagmentData, function (key, value) {
                that.set(key, value);
            });
        }
    });

});