define('ir-app/models/price/price-business-entities/fair-value', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    /**
     * Created by lakmaln on 9/27/2016.
     */

    exports['default'] = Ember['default'].Object.extend({

        refId: 0, // Reference Id
        comId: '', // Company Id
        provider: '', // Provider
        date: '', // Date
        changeInd: '', // Recommendation change indicator
        ratingScore: '', // Rating score
        prevRatingScore: '', // Previous rating score
        targetPrice: '', // Target price
        adjTargetPrice: '', // Adjusted target price
        upsidePot: '', // Current upside potential
        report: '', // Report
        prvReportDate: '', // Previous report date
        prvRec: '', // Previous recommendation
        prvTargetPrice: '', // Previous target price
        prvAdjTargetPrice: '', // Previous adjusted target price
        prvReport: '', // Previous report
        index: 0, // Indicator for previous value update

        fairValuesList: null,

        init: function init() {
            this._super();
            this.set('fairValuesList', Ember['default'].A());
        },

        setData: function setData(fairValueData) {
            var that = this;

            Ember['default'].$.each(fairValueData, function (key, value) {
                that.set(key, value);
            });
        }
    });

});