define('ir-app/models/shared/language/language-secondary', ['exports'], function (exports) {

  'use strict';

  exports['default'] = {
    lang: 'AR',
    obj: {
      labels: {
        mngtNBrdMbrs: 'الإدارة وأعضاء مجلس الإدارة',
        daysRange: '%أيام المدى',
        weeksRange52: '%المدى 52 أسبوع',
        copyright: 'حقوق النسخ 2002 – 2016 دايركت إف إن المحدودة، جميح الحقوق محفوظة',
        companyInfor: 'معلومات الشركة',
        lastUpdated: 'أخر تحديث',
        news: 'الأخبار',
        title: 'العنوان',
        newsAnnouncement: 'الأخبار والإعلانات',
        market: 'السوق',
        quote: 'عرض',
        topLoosersByPerChg: 'أكثر انخفاضات بالتغير%',
        topLoosersByChg: 'أكثر انخفاضات بالتغير',
        topGainersByPerChg: 'أكثر ارتفاعات بالتغير%',
        topGainersByChg: 'أكثر ارتفاعات بالتغير',
        mostActByVol: 'الأكثر نشاطا بالكمية',
        mostActByTrades: 'الأكثر نشاطا بالصفقات',
        mostActByTurnover: 'الأكثر نشاطاً بقيمة التداول',
        companyProf: 'ملف الشركة',
        trend: 'الاتجاه',
        dark: 'لون غامق',
        light: 'لون فاتح',
        ok: 'موافق',
        yes: 'نعم',
        no: 'لا',
        save: 'حفظ',
        retry: 'أعد محاولة',
        search: 'بحث',
        provider: 'المزود',
        startDate: 'تاريخ البداية',
        endDate: 'تاريخ الإنتهاء',
        to: 'إلى',
        from: 'من',
        preferredTheme: 'الرجاء اختيار الطابع المفضل',
        preferredLanguage: 'الرجاء اختيار اللغة',
        pctChgWeek: 'التغير الأسبوعي %',
        pctChgMonth: 'التغير الشهري %',
        pctChg3Month: 'التغير خلال 3 شهور %',
        pctChgYear: '% سنة',
        avgVol30Days: 'متوسط كمية التداول فى 30 يوم',
        avgTovr30Days: 'متوسط قيمة التداول فى 30 يوم',
        price15minDelay: 'بيانات الأسعار مؤجلة 15 دقيقة',
        home: 'الرئيسية',
        eventCalendar: 'مُفكرة الأحداث',
        upComingEvents: 'الأحداث المرتقبة',
        pastEvents: 'الأحداث السابقة',
        financials: 'بيانات مالية',
        historylookup: 'تاريخ البحث',
        selectDate: 'حدد التاريخ',
        noTradeDataMessage: 'لا توجد بيانات للتاريخ الذى قمت بتحديدة . يوم التداول السابق',
        selectedDate: 'التاريخ المحدد',
        financialandratios: 'البيانات المالية & النسب المالية',
        documents: 'الملفات',
        is: 'قائمة الدخل',
        bs: 'قائمة الميزانية',
        cf: 'قائمة التدفق المالى',
        ratios: 'نسب',
        period: 'المدة',
        statementType: 'نوع القائمة',
        yearly: 'سنوي',
        quarterly: 'ربع سنوي',
        netIncome: 'صافي الدخل',
        totalRevenue: 'إجمالي الإيرادات',
        totalUWRevenue: 'إجمالى إيرادات الأكتتاب',
        operatingIncome: 'الدخل التشغيلى',
        totalAssets: 'إجمالي الأصول',
        totalEquity: 'إجمالى حقوق المساهمين',
        operatingActivities: 'الأنشطة التشغيلية',
        investingActivities: 'الأنشطة الأستثمارية',
        investorCal: 'حاسبة الأستثمار',
        mediaRoom: 'غرفة الأعلام',
        facebook: 'الفيسبوك',
        twitter: 'تويتر',
        youTube: 'يوتيوب',
        linkedIN: 'لينكد إن',
        stkPrice: 'السعر',
        results: 'النتائج',
        calc: 'حساب',
        reInvstDivid: 'إعادة استثمار الأرباح',
        totDivid: 'إجمالي توزيعات الأرباح',
        totReturn: 'إجمالي العائد',
        rtnPercentage: 'نسبة العائد',
        annualReturn: 'العائد السنوي',
        numSharesPurchased: 'عدد الأسهم المشتراة',
        sharesPurchased: 'الأسهم المشتراة',
        or: 'أو',
        amountInvest: 'المبلغ المستثمر',
        investDate: 'تاريخ الاستثمار',
        addAllEventToCalender: 'إضافة كافة الأحداث إلى التقويم',
        settings: 'إعدادات',
        invalidInputs: 'مدخل غير صحيح',
        marketStatus: 'حالة السوق',
        fiftyTwoWkH: 'أعلى سنوي',
        fiftyTwoWkL: 'أقل سنوي',
        w52highLow: 'اخر 52 أسبوع أعلي - أدنى',
        web: 'الموقع الإلكترونى',
        couponFreq: 'تكرار الكوبون',
        lastQty: 'كمية آخر',
        lastTradedDate: 'تاريخ آخر تداول',
        lastTradedTime: 'وقت آخر تنفيذ',
        indGrp: 'القطاع الصناعي',
        subInd: 'التخصص',
        isin: 'الكود الدولي',
        estbOn: 'تاريخ الإنشاء',
        freeFltShr: 'الأسهم الحرة',
        authCap: 'رأس المال المصرح به',
        trdName: 'اسم المتداول',
        parVal: 'القيمة الأسمية',
        auditor: 'المراقب',
        open: 'فتح',
        peRatio: 'مضاعف ربحية السهم',
        high: 'أعلى',
        quantity: 'الكمية',
        listedShr: 'الأسهم المدرجة',
        mktCap: 'رسملة السوق',
        openInterest: 'عـدد العقـود المفتوحة',
        outstandingAmount: 'قيمة الإصدار الإجمالية',
        sherPrs: 'النسبة المئوية',
        announcement: 'الإعلانات',
        unchanged: 'دون تغير',
        side: 'العملية',
        settlementDate: 'تاريخ التسوية',
        bondType: 'نوع السند',
        dayCountMethod: 'طريقة الحساب اليومي',
        currency: 'العملات',
        currencies: 'عملات',
        topGainers: 'أكثر ارتفاعات',
        topLosers: 'أكثر انخفاضات',
        totalBidQty: 'إجمالي كمية الطلب',
        totalOfferQty: 'إجمالي كمية العرض',
        volume: 'حجم التداول',
        all: 'الكل',
        addr: 'العنوان',
        previousCouponDate: 'تاريخ الكوبون السابق',
        phn: 'رقم الهاتف',
        fax: 'فاكس',
        owners: 'الملاك',
        subsidiaries: 'الشركات التابعة',
        lotSize: 'حجم العقد',
        name: 'الاسم',
        offer: 'العرض',
        bid: 'الطلب',
        bestOffer: 'أفضل عرض',
        bestBid: 'أفضل طلب',
        bidOfferRatio: 'نسبه الطلب إلي العرض',
        offerQty: 'كمية العرض',
        bidQty: 'كمية الطلب',
        description: 'الاسم',
        trades: 'الصفقات',
        preClosed: 'اغلاق سابق',
        symbol: 'الرمز',
        cashMap: 'مخطط السيولة %',
        chart: 'الرسم البياني',
        filter: 'البحث في الشركات',
        close: 'الإغلاق',
        vWAP: 'متوسط السعر المرجح',
        expiryDate: 'تاريخ إنتهاء الصلاحية',
        strikePrice: 'سعر التنفيذ',
        couponRate: 'سعر الكوبون',
        exchange: 'السوق',
        time: 'الوقت',
        splits: 'عدد',
        turnover: 'قيمة التداول',
        ups: 'إرتفاعات',
        down: 'انخفاض',
        email: 'بريد إلكتروني',
        price: 'السعر',
        eps: 'ربحية السهم',
        low: 'أقل',
        perChange: 'التغير%',
        change: 'التغير',
        yield1: 'العائد',
        selectInd: 'مؤشرات',
        beta: 'بيتا',
        dividendYield: 'الربح الموزع',
        todayRange: 'مدى اليوم',
        fiftyTwoWkRange: 'الأدنى والأعلى خلال 52 أسبوع',
        allPricesIn: 'جميع الأسعار',
        trailingTwelveMonths: 'محتسب بناء على آخر 12 شهر',
        commodities: 'السلع',
        listDate: 'تاريخ الأدراج',
        finYrStart: 'بداية السنة المالية',
        finYrEnd: 'انتهاء السنة المالية فى',
        contacts: 'اتصالات',
        lastPrice: 'آخر سعر',
        companyStatistics: 'إحصائيات السهم',
        outShr: 'الأسهم القائمة',
        pdCap: 'رأس المال المدفوع',
        bookValuePerShare: 'القيمة الدفترية',
        pbRatio: 'مضاعف القيمة الدفترية',
        dividends: 'التوزيعات',
        totalValueofCashDividend: '(إجمالي قيمة التوزيعات النقدية (بالمليون',
        payoutRatio: 'التوزيعات/صافي الارباح',
        dividendPerShare: 'الأرباح الموزعة لكل سهم',
        bonusShares: 'أسهم المنحة',
        announcedLast12Months: 'المعلنة خلال 12 شهرا السابقة*',
        naIndicatesMessage: 'N/A تعني إنه لم يتم إعلان قيم في الإثني عشر شهرا الاخيرة',
        events: 'مفكرة الشركة',
        dcfsFlagYellowText: 'الشركات ذات الخسائر المتراكمة بنسبة 100% فأكثر من رأس مالها',
        dcfsFlagOrangeText: 'الشركات ذات الخسائر المتراكمة بنسبة 75% إلى أقل من 100% من رأس مالها',
        dcfsFlagWhiteText: 'الشركات ذات الخسائر المتراكمة بنسبة 50% إلى أقل من 75% من رأس مالها',
        middleEast: 'الشرق الأوسط',
        ytd: 'التغير منذ بداية العام %',
        noOfTrades: 'عدد الصفقات',
        mktCapMillion: '(القيمة السوقية (م',
        marketMovers: 'الأسهم الأكثر تحركا',
        stkOvw: 'نظرة عامة على السهم',
        monthJan: 'يناير',
        monthShortJan: 'يناير',
        monthFeb: 'فبراير',
        monthShortFeb: 'فبراير',
        monthMar: 'مارس',
        monthShortMar: 'مارس',
        monthApril: 'إبريل',
        monthShortApril: 'إبريل',
        monthMay: 'مايو',
        monthShortMay: 'مايو',
        monthJun: 'يونيو',
        monthShortJun: 'يونيو',
        monthJly: 'يوليو',
        monthShortJly: 'يوليو',
        monthAgst: 'أغسطس',
        monthShortAgst: 'أغسطس',
        monthSep: 'سبتمبر',
        monthShortSep: 'سبتمبر',
        monthOct: 'أكتوبر',
        monthShortOct: 'أكتوبر',
        monthNov: 'نوفمبر',
        monthShortNov: 'نوفمبر',
        monthDec: 'ديسمبر',
        monthShortDec: 'ديسمبر',
        chartStyleTitle: 'نمط الرسم البياني',
        chartShowVol: 'إظهار الحجم',
        chartIntraday: 'يومي',
        chartHistory: 'تاريخي',
        chartEveryMinute: 'كل دقيقة',
        chartEvery5Minutes: 'كل 5 دقائق',
        chartEvery10Minutes: 'كل 10 دقائق',
        chartEvery15Minutes: 'كل 15 دقيقة',
        chartEvery30Minutes: 'كل 30 دقيقة',
        chartEvery60Minutes: 'كل 60 دقيقة',
        chartDaily: 'يومي',
        chartWeekly: 'أسبوعي',
        chartMonthly: 'شهري',
        chartQuarterly: 'ربع سنوي',
        chartYearly: 'سنوي',
        chartArea: 'مظلل',
        chartCandle: 'شموع',
        chartOHLC: 'فتح-أعلى-أدنى-إغلاق',
        chartLine: 'خط',
        chartCandleWithTrend: 'شموع مع اتجاه',
        international: 'العالمية',
        paidUpCapital: 'رأس المال المدفوع',
        reprstFor: 'كممثلا عن',
        reprstTo: 'كممثلا لـ',
        currencyConverter: 'محول العملات',
        currencyAmount: 'المبلغ',
        stocksTradedListed: 'الأسهم المتداولة',
        allAreSpot: '*كل الأسعار أسعار فوريه',
        majorShareholders: 'الملكية',
        mktIndices: 'مؤشرات السوق',
        marketPulse: 'نبض الأسواق',
        arabMarkets: 'الأسواق العربية',
        globalMarkets: 'الأسواق العالمية',
        btnClear: 'إلغاء',
        category: 'الشركة',
        location: 'الموقع',
        relatedAnn: 'الإعلان ذو الصلة',
        fromDate: 'من تاريخ',
        toDate: 'إلى تاريخ',
        marketRatios: 'أساسيات السوق',
        relatedStory: 'الخبر ذو الصلة',
        indexOverview: 'نظرة عامة',
        allStocks: 'كل الأسهم',
        indexDetails: 'تفاصيل المؤشر',
        indexStats: 'احصائيات المؤشر',
        stkExchange: 'سوق الأوراق المالية',
        indexName: 'اسم المؤشر',
        indexConst: 'الشركات المدرجه في المؤشر',
        indexSummary: 'ملخص المؤشرات',
        stksAccumLoss: 'الشركات ذات الخسائر المتراكمة',
        sector: 'القطاع',
        enterCompareSym: 'سهم المقارنة',
        mktInitialising: 'تجهيز',
        mktPreOpen: 'قبل الإفتتاح',
        mktOpen: 'مفتوح',
        mktClose: 'مغلق',
        mktPreClose: 'قبل الإغلاق',
        mktOpenSuspended: 'تعليق  الفتح',
        mktPreCloseSuspended: 'تم تعليق قبل الإغلاق',
        mktCloseSuspended: 'تعليق الإغلاق',
        mktPreOpenSuspended: 'تم تعليق قبل الافتتاح',
        mktMovingToPreOpen: 'الانتقال إلى قبل الافتتاح',
        mktMovingToOpen: 'الانتقال إلى الافتتاح',
        mktMovingToPreClose: 'الانتقال إلى قبل الإغلاق',
        mktMovingToClose: 'الانتقال إلى الإغلاق',
        mktPreCloseWithTrades: 'قبل الإغلاق',
        mktCloseWithTrades: 'مغلق',
        mktTradingAtLast: 'تداول على اخر',
        mktIntermediateClose: 'الإتصال مغلق',
        mktPreCloseAdjustment: 'ما قبل الإغلاق',
        status: 'الحالة',
        effectiveDate: 'تاريخ التفعيل',
        capitalAction: 'اجراءات رأس المال',
        nonCapitalAction: 'إجراءات لا تؤثر برأس المال',
        announcingDate: 'تاريخ الإعلان',
        completionDate: 'تاريخ استكمال الإجراء',
        action: 'نوع العملية',
        factor: 'المعامل',
        final: 'نهائي',
        proposed: 'مقترح',
        balanceSheet: 'الميزانية العمومية',
        cashFlow: 'التدفقات النقدية',
        incomeStat: 'قائمة الدخل',
        showTradesfor: 'عرض التعاملات لـ',
        transactions: 'عملية',
        count: 'العدد',
        estimatedValue: 'القيمة المتوقعة',
        date: 'التاريخ',
        insiderName: 'اسم المتعامل الداخلي',
        vwapPrice: 'متوسط السعر المرجح',
        estdTrnsValue: 'القيمة التقديرية للعملية',
        net: 'صافي',
        buy: 'شراء',
        sell: 'بيع',
        ratioFootNote: 'جميع النسب المالية محتسبة بناء على القوائم المالية المدققة وليس النتائج الأولية',
        recordDate: 'تاريخ السجل',
        details: 'تفاصيل',
        dividendAmount: 'مبلغ التوزيع',
        issuePrice: 'سعر الإصدار',
        splitFactor: 'عامل التجزئة',
        rightsEndDate: 'تاريخ الاستحقاق',
        sharesBefore: 'عدد الأسهم قبل الزيادة',
        sharesAfter: 'عدد الأسهم بعد الزيادة',
        sharesChange: 'التغير في عدد الأسهم',
        parValBfr: 'القيمة الإسمية قبل الزيادة',
        parValAtr: 'القيمة الإسمية بعد الزيادة',
        parValChange: 'التغير في القيمة الإسمية',
        capChanges: 'التغير في رأس المال',
        periodEndDate: 'تاريخ نهاية الفترة',
        approvalStatus: 'الموافقات',
        abnormalCd: 'توزيع نقدي غير اعتيادي',
        cdYear: 'التوزيع النقدي عن سنة',
        cdPeriod: 'التوزيع النقدي عن فترة',
        oldPaid: 'المدفوع قبل',
        newPaid: 'المدفوع الجديد',
        cashPaid: 'المدفوع نقدا',
        priceLimitRemoval: 'تحريك الحدود السعرية',
        numbTrsryStks: 'عدد أسهم الخزينة',
        cdRelatedPeriod: 'الفترة المالية للتوزيعات النقدية',
        cdRelatedPeriodName: 'اسم الفترة المالية للتوزيعات النقدية',
        actionDetails: 'تفاصيل الإجراء',
        financialStatements: 'القوائم المالية',
        pressReleases: 'بيان صحفي',
        bMeetingDisclosures: 'نتائج اجتماع مجلس الادارة',
        aMeetingDisclosures: 'نتائج اجتماع الجمعية العمومية',
        glossyReports: 'التقرير السنوي',
        baselIII: 'افصاح بازل 3',
        company: 'الشركة',
        actual: 'فعلي',
        summary: 'الملخص',
        view: 'عرض',
        calendar: 'التقويم',
        fiscal: 'السنة المالية',
        breakdown: 'قائمة البنود',
        periodBasis: 'الفترة الزمنية',
        detailedView: 'عرض بالتفصيل',
        interim: 'للفترة المالية',
        ttm: 'لأخر 12 شهراn',
        ratiosAndGrowth: 'المؤشرات المالية ومعدلات النمو',
        foreignOwnership: 'الحدود المسموحة لتملك الأجانب',
        qfiClientOwnership: 'الحد المسموح به لتلملك الأجانب',
        totalForeignOwnership: 'اجمالي ملكية الأجانب',
        maxLimit: 'الحد الأقصى المسموح به',
        actualLimit: 'محدد  فعلي',
        selectMarket: 'اختر السوق',
        stockScreener: 'باحث السهم',
        divYieldPer: 'العائد على التوزيع %',
        type: 'النوع',
        industry: 'الصناعة',
        corporateActions: 'إجراءات الشركات',
        valuationRatio: 'مؤشر التقييم',
        ipo: 'قائمة الإكتتابات',
        download: 'تحميل',
        currentDay: 'اليوم الحالي',
        changeLongDes: 'التغير',
        selectSymbol: 'يرجى اختيار السهم',
        searchResults: 'نتائج البحث',
        poweredBy: 'مقدمه من',
        previousDay: 'اليوم السابق',
        prospectus: 'نشرة إصدار',
        sizeMillions: 'حجم (مليون)',
        offered: 'النسبة المعروضة',
        capitalIncrease: 'زيادة رأس المال',
        screeningCriteria: 'معايير البحث',
        compare: 'مقارنة',
        inMillions: 'بالمليون',
        authCapital: 'رأس المال المصرح به (بالمليون)',
        latestQuarter: 'أحدث النتائج ربع السنوية المتاحة',
        disclosure: 'الافصاح',
        aggregatedMktSummary: 'ملخص السوق المجمع',
        CFFromInvestingActivities: 'التدفقات النقدية من الأنشطة الاستثمارية',
        CFFromOperatingActivities: 'التدفقات النقدية من الأنشطة التشغيلية',
        noOfStocks: 'عدد الأسهم',
        totAuthorizedCapital: 'رأس المال المصرح به (بالمليون)',
        corporateActionType: 'نوع الإجراء',
        aggregatedSummary: 'ملخص السوق المجمع',
        currentOwnership: 'الملكية الحالية',
        previousOwnership: 'الملكية في اليوم السابق',
        ratioComparison: 'مؤشرات مالية مقارنة',
        noResultsFound: 'لا توجد نتائج',
        itemsSelected: 'العناصر التى تم اختيارها',
        nothingSelected: 'لم يتم اختيار اى عنصر',
        selectAll: 'اختيار الكل',
        deselectAll: 'إلغاء اختيار الكل',
        currentQuarter: 'الربع السنوى الحالى',
        threeMonths: 'ثلاثة اشهر',
        sixMonths: 'ستة اشهر',
        oneYear: 'عام',
        twoYears: 'عامين',
        underPerform: '(القيمه العادله للسهم اقل من القيمه السوقيه ( بيع',
        hold: 'الإحتفاظ',
        outPerform: '(القيمه العادله للسهم اعلى من القيمه السوقيه(شراء',
        currentQuarterConsensus: 'التوصيات المجمعه فى فتره ربع سنويه',
        threeMonthsConsensus: 'التوصيات المجمعه فى ثلاثة اشهر',
        sixMonthsConsensus: 'التوصيات المجمعه  فى ستة اشهر',
        oneYearConsensus: 'التوصيات المجمعه فى عام',
        twoYearConsensus: 'التوصيات المجمعه فى عامين',
        meanConsensus: 'متوسط التوصيات المجمعه',
        recommendations: 'التوصيات',
        avgTargetPriceAdjusted: 'متوسط القيمه المستهدفه ( المعدله)',
        upsidePotential: 'نسبة الارتفاع المتوقعه',
        duration: 'المدة',
        providers: 'المزودين',
        showValues: 'القيم المشار اليها',
        adjForCorporateActions: 'القيمه معدله طبقا لاجراءات الشركه',
        asReported: 'القيمه مطابقه للتقرير',
        chgIndicator: 'مؤشرتغير التوصيه',
        rec: 'التوصيات',
        targetP: 'السعر المستهدف المعدل',
        upsideP: 'نسبة الارتفاع المتوقعه',
        rep: 'التقرير',
        prevRepDate: 'تاريخ التقرير السابق',
        prevRec: 'التوصيات السابقه',
        prevTargetP: 'السعر المستهدف السابق',
        prevRep: 'التقرير السابق',
        recChangeIndicator: 'مؤشرتغير التوصيه',
        recommendation: 'التوصيات',
        adjTargetPrice: 'السعر المستهدف المعدل',
        rawTargetPrice: 'السعر المستهدف',
        curUpsidePotential: 'نسبة الارتفاع المتوقعه الحاليه',
        report: 'التقرير',
        prevReportDate: 'تاريخ التقرير السابق',
        prevRecommendation: 'التوصيات السابقه',
        prevTargetPrice: 'السعر المستهدف السابق',
        prevReport: 'التقرير السابق',
        indicateValuesAdjusted: 'القيم المشار اليها معدله',
        rtnOnEquity: 'العائد على الملكية %',
        rtnOnAssets: 'العائد على الأصول %,',
        value: 'القيمة',
        investments: 'الاستثمارات',
        associates: 'الشركات الشقيقة',
        shareHolders: 'هيكل الملكية',
        corporate: 'شركات',
        nonCorporate: 'مؤسسات حكومية ومدنية',
        individuals: 'أفراد',
        management: 'الإدارة',
        keyOff: 'المدراء التنفيذيون',
        marketSummary: 'ملخص السوق',
        message: 'رسالة',
        avgTransVal: 'متوسط قيمة الصفقة',
        avgTransVal30Days: 'متوسط قيمة الصفقة خلال 30 يوم',
        companyContact: 'مسئول اتصال علاقات المستثمرين',
        IrContactPerson: 'بيانات اتصال الشركة',
        dividendHistory: 'التوزيعات النقدية التاريخية',
        sendMessage: 'إرسال رسالة نصية',
        examplePhone: 'مثال (الإمارات): 0097143259996',
        sending: 'إرسال....',
        send: 'إرسال',
        mandatoryField: 'لا يمكن ترك الخانات المطلوبة فارغة',
        dayChange: 'التغير اليومي',
        dayPerChange: 'التغير اليومي %',
        last: 'آخر سعر',
        performance: 'الأداء لسنة واحدة',
        subscribe: 'إشتراك',
        stopLoss: 'وقف الخسارة',
        stopLossAlert: 'تنبيه فى حالة إنخفاض ​​سعر السهم أقل من هذه القيمة',
        unsubscribe: 'إلغاء الاشتراك',
        unSubscribeEmail: 'إلغاء الاشتراك من إشعارات البريد الإلكتروني',
        highVolume: 'أعلى حجم تداول',
        highVolumeAlert: 'تنبيه فى حالة ارتفاع حجم التداول عن المتوسط خلال 30 يوم',
        volumeTarget: 'حجم التداول المستهدف',
        volumeTargetAlert: 'تنبيه فى حالة ارتفاع حجم التداول اليومي عن ذلك الحجم',
        negativePctChg: 'نسبة التغير بالسالب',
        negativePctChgAlert: 'تنبيه فى حالة إنخفاض نسبة تغير اليوم عن تلك القيمة',
        positivePctChg: 'نسبة التغير بالموجب',
        positivePctChgAlert: 'تنبيه فى حالة ارتفاع نسبة تغير اليوم عن تلك القيمة',
        priceTarget: 'السعر المستهدف',
        priceTargetAlert: 'تنبيه إذا وصل سعر السهم الى تلك القيمة.',
        dailyTradeSummary: 'ملخص التداول اليومى',
        dailyTradeSummaryAlert: 'إرسال ملخص التداول اليومى في نهاية تداول اليوم',
        emailSighUp: 'سجل لتلقي الاشعارات بالبريد الإلكتروني',
        emailAddress: 'أدخل عنوان بريدك الالكتروني هنا',
        changeSubscription: 'الرجاء إعادة إختيار وإدخال عنوان بريدك الإلكتروني إذا كنت ترغب في إجراء أية تغييرات في الاشتراك',
        unsubscriptionMessage: 'عند إدخال بريدك الإلكترونى هنا لن تتمكن من استلام إشعارات بريدية من قائمة الاشتراكات المذكورة أعلاه , وإذا قمت بإلغاء الاشتراك فى الإشعارات البريدية يمكنك التسجيل فى اى وقت عن طريق اتباع عملية التسجيل مرة اخرى',
        signUpMessage: 'لكى تستقبل إشعارات عن طريق البريد الإلكتروني , إدخل عنوان بريدك الإلكتروني مع إختيار إشتراك واحد على الأقل, وبعد إدخال بياناتك سوف تستقبل بريد ويجب عليك الضغط على الرابط الموجود لتفعيل إشتراكك, ويمكنك التسجيل لأى إشتراكات أخرى فى أى وقت',
        creditRatings: 'التصنيفات الائتمانية',
        ratingAgency: 'وكالة التصنيف',
        longTermRatings: 'التقييم طويل الأجل',
        shortTermRatings: 'التقييم قصير الأجل',
        outlook: 'نظرة مستقبلية',
        ratingsAffirmed: 'تصنيفات مؤكدة',
        analystRecommendation: 'توصية المحلل',
        analystCoverage: 'تغطية المحلل',
        firm: 'الشركة',
        analyst: 'المحلل',
        contactInfo: ' معلومات الاتصال',
        phone: 'هاتف',
        highestVol: 'الكمية الأعلى',
        lowestVol: 'أدنى حجم',
        highestPrice: 'أعلى سعر',
        lowestPrice: 'أدنى سعر',
        highestDayPctChg: 'أعلى تغير يومي %',
        lowestDayPctChg: 'أدنى تغير يومي %',
        chgPeriod: 'التغير والتغير % للفترة',
        sharePriceOneYear: 'سعر السهم – سنة واحدة',
        oneYr: 'سنة واحدة',
        lastTradedPrice: ' آخر سعر تداول',
        fiftyTwoWksRange: 'مدى 52 اسبوع ',
        stockInformation: 'معلومات السهم ',
        others: 'آخرين',
        mutualFunds: 'صناديق الاستثمار',
        ownersByCountry: 'الملاك حسب الدولة',
        ownersByType: 'الملاك حسب النوع',
        version: 'النسخة',
        hostVersion: 'نسخة المضيف',
        copyRight: '2017 دايركت إف إن . جميع الحقوق محفوظة',
        announcemnt: 'تاريخ الإعلان',
        completion: 'تاريخ استكمال الإجراء',
        rightsEnd: 'تاريخ الاستحقاق',
        disclosures: 'الافصاح',
        BoardOfDirectors: 'أعضاء مجلس الإدارة',
        ratings: 'تقييم',
        general: 'بيانات الاتصال',
        irDepartment: 'علاقات المستثمرين',
        presentations: 'علاقات المستثمرين',
        analystView: 'أراء المحللين',
        shareHolderAffairs: 'شؤون المساهمين',
        annualReports: 'التقارير السنوية',
        factSheet: 'بيان حقائق ',
        ownersAndSubsidiaries: 'Owners & Subsidiaries',
        sharePrice: 'سعر السهم',
        chg: 'التغير',
        pctChg: 'نسبة التغير',
        historicalPrices: 'الأسعار التاريخية لمدة عام',
        IrContactEmail: 'الإتصال بعلاقات المستثمرين',
        contactUs: 'اتصل بنا',
        fills: 'فلس ',
        inThousands: 'بالآلاف',
        back: 'رجوع',
        simpleMovingAvg: 'المتوسط المتحرك البسيط (14)',
        expMovAvg: 'المتوسط المتحرك الأسي (14)',
        RSI: 'مؤشر القوة النسبية',
        auditors: 'مدققي الحسابات',
        noOfSharesOutstanding: 'عدد الاسهم القائمة',
        generalCompanyEvents: 'أحداث عامة للشركة',
        shareholderMeeting: 'إجتماع جمعية عامة عادية',
        actions: 'الإجراءات',
        extraordinaryShareholderMeeting: 'إجتماع جمعية عامة غير عادية',
        economyEvents: 'أحداث إقتصادية',
        boardOfDirectorsMeeting: 'إجتماع مجلس إدارة',
        vacations: 'إجازات',
        speech: 'خطاب',
        reports: 'تقارير',
        purchasingManagersIndex: 'مؤشر مديري المشتروات',
        ordinaryAndExtraordinaryGeneralMeeting: 'إجتماع الجمعية العامة العادية وغير',
        quantitativeEasing: 'التسهيل الكمي',
        unemployment: 'البطالة',
        oil: 'بترول',
        interestRate: 'معدل الفائدة',
        inflation: 'التضخم',
        grossDomesticProduct: 'الناتج المحلي الإجمالي',
        tradingOfInstrumentsEvents: 'أحداث تداول الأدوات المالية',
        trade: 'تجارة',
        firstName: 'الاسم الاول',
        lastName: 'الكنية',
        submit: 'تقديم',
        reset: 'إعادة تعيين',
        totalIncome: 'إجمالي الدخل',
        netFinance: 'صافي التمويل',
        totDeposits: 'إجمالي الودائع',
        valueInMillion: '{{1}} القيم بالمليون',
        SimpleMovingAverage10: '(10)المتوسط ​​المتحرك البسيط',
        SimpleMovingAverage25: '(25)المتوسط ​​المتحرك البسيط',
        SimpleMovingAverage50: '(50)المتوسط ​​المتحرك البسيط',
        ExponentialMovingAverage10: '(10)المتوسط ​​المتحرك الأسي',
        ExponentialMovingAverage25: '(25)المتوسط ​​المتحرك الأسي',
        ExponentialMovingAverage50: '(50)المتوسط ​​المتحرك الأسي',
        ATR: 'متوسط المدى الحقيقي',
        shareGraph: 'الرسم البياني للسهم',
        shareData: 'بيانات السهم',
        performanceStk: 'الأداء',
        interval: 'الفترة الزمنية:',
        indicators: 'المؤشرات',
        chartType: 'نوع الرسم البياني',
        dateRange: 'نطاق البيانات',
        print: 'طباعة',
        detach: 'فصل',
        downloadExcel: 'تحميل اكسل',
        chartItems: 'عناصر الرسم البياني',
        indices: 'المؤشرات',
        peers: 'المماثل',
        providedBy: 'مقدم من',
        tickerCode: 'رمز السهم',
        numOfShares: 'عدد الأسهم',
        addVol: 'يضيفالحجم',
        announ: 'إعلان',
        corAct: 'إجراءات الشركات',
        oneDay: 'يوم',
        fiveDay: 'أيام 5',
        oneMonth: 'شهر',
        twoYear: 'عامين',
        fiveYear: '5 أعوام',
        maxYear: 'الأعلي',
        companyName: 'أسم الشركة',
        dataStartingFrom: 'البيانات بداية من',
        overview: 'نظرة عامة',
        latestDiv: 'أحدث توزيعات الأرباح',
        divPerShr: 'لكل سهم',
        exDate: 'تاريخ الإستبعاد من الأرباح',
        payDate: 'تاريخ الدفع',
        rtnCal: 'حاسبة العائد',
        twleMonDiv: 'توزيعات أرباح 12 شهر',
        totRtnTwleMon: 'إجمالي العائد لمدة 12 شهر',
        totRtn3Y: 'إجمالي العائد 3 سنوات',
        annDivYld: 'عائد توزيعات الأرباح السنوية',
        annDiv: 'توزيعات الأرباح السنوية',
        footNoteFirst: 'محسوبة على أساس توزيعات الأرباح المتراكمة' + 'المدفوعة (بعد الإدراج) خلال آخر' + '12 شهرًا وآخر سعر تداول',
        footerNoteSecond: 'محسوب على افتراض إعادة استثمار' + 'توزيعات الأرباح ',
        footerNoteThird: 'محسوبة على أساس سعر الإغلاق لآخر' + 'تاريخ تداول. للسنة الحالية - بنا' + 'ءً على سعر الإغلاق لآخر تاريخ تداول',

        perRtn: 'العائد الدوري',
        rtnReinvDiv: 'العائد على الأرباح المعاد استثمارها',
        annTotRtn: 'العائد الدوري',
        years: 'سنوات',
        footerNoteFourth: 'بناء على سعر الاغلاق فى نهاية اليوم  ',
        year: 'عام',
        annRtn: 'العائد السنوي',
        rtnDiv: 'عائد توزيعات الأرباح',
        divAdj: 'توزيعات أرباح (معدلة)',
        cumRtn: 'العائد التراكمي',
        cumRtn1Y: 'العائد التراكمي خلال سنه',
        cumRtn3Y: 'العائد التراكمي خلال ثلاث سنوات',
        cumRtn5Y: 'العائد التراكمي خلال خمسة سنوات',
        apply: 'تطبيق',
        cancel: 'إلغاء',
        custom: 'مخصص',
        values: 'القيم',
        Y1: 'السنة الاولى',
        Y3: 'السنة الثالثة',
        Y5: 'السنة الخامسة',
        Monday: 'الاثنين',
        Tuesday: 'الثلاثاء',
        Wednesday: 'الأربعاء',
        Thursday: 'الخميس',
        Friday: 'جمعة',
        Sunday: ' الأحد',
        Saturday: 'السبت',
        docPrw: 'معاينة الملف',
        ConStatemenCashFlows: 'بيان التدفق النقدي الموحد',
        ConStatementIncome: 'بيان الدخل الموحد',
        ConStatementFinPos: 'بيان المركز المالي الموحد',
        ask: ' سعر الطلب',
        million: 'مليون',
        shareSeries: 'سلسلة الأسهم',
        dailyChange: '+/- التغيير اليومى ',
        dailyChangeptg: '% التغيير اليومى',
        prevClose: 'سعر الإغلاق السابق',
        Week52Ptg: '52wk %',
        totRelease: 'إجمالي الإصدارات',
        enterKeyword: 'أدخل الكلمة المفتاحية',
        compName: "الجزيرة"
      },
      messages: {
        validSuccessfully: 'تم إرسال طلب التنبيه بنجاح. يرجى التحقق من بريدك الإلكتروني للتحقق',
        emailNotBelong: 'هذا البريد الإلكتروني $ {email} غير مرتبط بأي اشتراك',
        moreAdd: 'الرجاء إضافة معيار تنبيه واحد أو أكثر',
        sentSubEmail: 'يتم إرسال تفاصيل اشتراكك إلى بريدك الإلكتروني',
        pleaseValidEmail: 'يرجى تقديم عنوان بريد إلكتروني صالح .. !!',
        alertAlreadyDeleted: 'تم حذف هذا التنبيه بالفعل.',
        numberNotNegative: 'يجب ألا يكون الرقم سالبًا',
        dataNotAvailable: 'البيانات غير متاحة',
        loading: 'جاري التحميل...',
        alertCriteria: 'الرجاء إضافة معيار تنبيه واحد أو أكثر',
        noUpcomingEvents: 'لا توجد أحداث قادمة',
        noEvents: 'لا توجد أحداث',
        selectFromToDates: 'رجاء اختيار المدة من و إلى',
        financialsColumnMsg: 'انتهاء السنة المالية فى {{1}}. والقيم ب {{2}} مليون عدا ما يتعلق ببيانات السهم', // todo [akila] there are 2 place holders add it to arabic one, english one added
        financialsColumnMsgThousand: 'الف.{{2}}.القيم {{1}} السنة المالية تنتهي في',
        financialsColumnMsgFYEnds: 'السنة المالية تنتهي في {{1}}',
        mailSentSuccess: 'تم ارسال البريد الالكتروني بنجاح',
        mailSentFail: 'فشل في ارسال البريد الالكتروني',
        invalidName: 'الإسم غير صحيح',
        invalidEmail: 'البريد الإلكتروني غير صحيح',
        invalidFillAllFields: 'لو سمحت أملأ كل الحقول!',
        invalidSelectReminderDate: 'الرجاء تحديد تاريخ تذكير!',
        subSuccessfully: 'تم إرسال طلب الاشتراك بنجاح. يرجى التحقق من بريدك الإلكتروني للتحقق',
        ErrorAlertRequest: 'خطأ في طلب التنبيه. حاول مرة اخرى!',
        invalidPhone: 'رقم الهاتف غير صحيح',
        invalidMessage: 'محتوى الرسالة غير صحيح',
        setValue: 'الرجاء تحديد القيمة هنا',
        selectOneAlertType: 'الرجاء اختيار نوع تنبيه واحد على الأقل',
        noTranslation: 'لا يوجد ترجمة , برجاء الضغط لمشاهدة الإعلان باللغة الإنجليزية',
        eventCalenderMsg: 'جميع التواريخ المستقبلية عرضة للتغي',
        noEventsForThisMonth: 'لا توجد أحداث لهذا الشهر',
        remindThatDay: 'تذكير في نفس اليوم',
        remindOneDayBefore: 'تذكير قبل 1 يوم',
        remindOneWeekBefore: 'تذكير قبل 1 أسبوع',
        remindOneMonthBefore: 'تذكير قبل 1 شهر',
        downloadIntraday: 'تحميل جميع الصفقات خلال اليوم'
      }
    }
  };

});