define('ir-app/controllers/ir-widgets/financial-widgets/ratio-comparison', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/config/app-config', 'ir-app/models/price/price-constants', 'ir-app/utils/utils', 'ir-app/config/user-settings'], function (exports, Ember, BaseController, priceService, sharedService, AppConfig, priceConstants, utils, userSettings) {

    'use strict';

    exports['default'] = BaseController['default'].extend({

        viewType: undefined,
        viewTypeList: [],
        portalExchangeList: undefined,
        symbolsList: Ember['default'].A([]),
        ratioObj: undefined,
        exchange: undefined,
        symbol: undefined,
        symInstStore: {},
        columnSymbolStore: {},

        onLoadWidget: function onLoadWidget() {
            this.setErrorMessage();
            this.set('portalExchangeList', AppConfig['default'].exchangeList.alArabia);
            this.set('placeHolder_1', this.get('app').lang.labels.enterCompareSym);
        },

        onPrepareData: function onPrepareData() {
            var that = this;
            that.set('viewTypeList', []);
            var viewTypes = that.get('viewTypes'); // viewTypes in layout config list
            var array = that.get('viewTypeList');
            var viewType = '';
            var intType = '0';
            var stock = priceService['default'].stockDS.getStock(that.get('exg'), that.get('sym'));
            this.set('stock', stock);

            Ember['default'].$.each(viewTypes, function (index, key) {
                var idx = {
                    id: '',
                    desc: ''
                };
                // view type set on initial loading or language change
                if (index === 0 && !viewType) {
                    viewType = that._getViewType(key.id);
                }
                var prop = ['label', sharedService['default'].userSettings.currentLanguage.toUpperCase()].join('');
                Ember['default'].set(idx, 'desc', key[prop]);
                Ember['default'].set(idx, 'id', key.id);

                array.push(idx);
            });

            that.set('viewType', viewType);
            var instStore = that.get('symInstStore');
            instStore[that._joinDataType(that.get('exg'), that.get('sym'))] = intType;

            priceService['default'].sendSymbolValidationRequest(that.get('exg'), that.get('sym'), sharedService['default'].userSettings.currentLanguage);
            var colSymStore = that.get('columnSymbolStore');
            colSymStore[0] = that._joinDataType(that.get('exg'), that.get('sym'), intType);

            that._requestCompareSymbolData(0, that.get('exg'), that.get('sym'), intType);
        },

        _requestCompareSymbolData: function _requestCompareSymbolData(column, exchange, symbol, inst) {
            var that = this;
            that.set('exchange', exchange);
            that.set('symbol', symbol);
            that.startLoadingProgress();

            var ratioObj = priceService['default'].ratioComparisonDS.getStoredObject(exchange, symbol, inst, that.get('viewType'), function () {
                that.onDataSuccess();
            }, function () {
                that.onDataError();
            });
            that.set('ratioObj' + column, ratioObj);
        },

        onCheckDataAvailability: function onCheckDataAvailability() {
            var symInstStore = this.get('symInstStore');
            var instType = '0';
            for (var key in symInstStore) {
                if (symInstStore.hasOwnProperty(key)) {
                    var exgSymArr = this._splitDataType(key);
                    if (exgSymArr[0] === this.get('exchange') && exgSymArr[1] === this.get('symbol')) {
                        instType = symInstStore[key];
                    }
                }
            }

            var ratioObj = priceService['default'].ratioComparisonDS.getStoredObject(this.get('exchange'), this.get('symbol'), instType, this.get('viewType'));

            if (ratioObj) {
                this.utils.validators.isAvailable(ratioObj);
                return true;
            } else {

                return false;
            }
        },

        _joinDataType: function _joinDataType() {
            var args = Array.prototype.slice.call(arguments);
            return args.join('_');
        },

        _splitDataType: function _splitDataType(str) {
            return str.split('_');
        },

        _setSymbol: function _setSymbol(position, symbol) {
            if (symbol.get('sym') !== undefined) {
                // add new symbol to current symbol view data store
                var colSymStore = this.get('columnSymbolStore');
                colSymStore[position] = this._joinDataType(symbol.get('exg'), symbol.get('sym'), symbol.get('inst'));

                if (this.get('symInstStore') && !this.get('symInstStore')[this._joinDataType(symbol.get('exg'), symbol.get('sym'))]) {
                    // add new data to store
                    var instStore = this.get('symInstStore');
                    instStore[this._joinDataType(symbol.get('exg'), symbol.get('sym'))] = symbol.get('inst');

                    this._requestCompareSymbolData(position, symbol.get('exg'), symbol.get('sym'), symbol.get('inst'));
                } else {
                    // No need to call _requestCompareSymbolData due to symbol already in localStorage cached, So just get it from data from data store
                    var ratioObj = priceService['default'].ratioComparisonDS.getStoredObject(symbol.get('exg'), symbol.get('sym'), this._joinDataType(this.get('viewType'), symbol.get('inst')));
                    this.set('ratioObj' + position, ratioObj);
                }
            }
        },

        _validateSymbol: function _validateSymbol(position, symbol) {
            // check symbol is already viewed in another column
            var colSymStore = this.get('columnSymbolStore');
            for (var key in colSymStore) {
                if (colSymStore.hasOwnProperty(key)) {
                    if (this._joinDataType(symbol.get('exg'), symbol.get('sym'), symbol.get('inst')) === colSymStore[key]) {
                        // data already viewed
                        this.set(this._joinDataType('searchSymbol', position), '');
                        return false;
                    }
                }
            }
            // new symbol : can continue the process
            return true;
        },

        _getViewType: function _getViewType(typeId) {
            switch (typeId) {
                case 1:
                    return priceConstants['default'].DataTypes.RatioComparisonDataStore.Quarter;
                case 2:
                    return priceConstants['default'].DataTypes.RatioComparisonDataStore.Year;
                default:
                    utils['default'].logger.logError('ratio-comparison-controller unsupported  view Type Id: ' + typeId);
                    break;
            }
        },

        actions: {
            setSymbol_1: function setSymbol_1(symbol) {
                if (this._validateSymbol(1, symbol)) {
                    this._setSymbol(1, symbol);
                    this.set('searchSymbol_1', '');
                    this.set('label_1', symbol.get('sym') + ' ' + symbol.get('sDes'));
                }
            },

            setSymbol_2: function setSymbol_2(symbol) {
                if (this._validateSymbol(2, symbol)) {
                    this._setSymbol(2, symbol);
                    this.set('searchSymbol_2', '');
                    this.set('label_2', symbol.get('sym') + ' ' + symbol.get('sDes'));
                }
            },

            setSymbol_3: function setSymbol_3(symbol) {
                if (this._validateSymbol(3, symbol)) {
                    this._setSymbol(3, symbol);
                    this.set('searchSymbol_3', '');
                    this.set('label_3', symbol.get('sym') + ' ' + symbol.get('sDes'));
                }
            },

            setSymbol_4: function setSymbol_4(symbol) {
                if (this._validateSymbol(4, symbol)) {
                    this._setSymbol(4, symbol);
                    this.set('searchSymbol_4', '');
                    this.set('label_4', symbol.get('sym') + ' ' + symbol.get('sDes'));
                }
            },

            showSearchPopup_1: function showSearchPopup_1() {
                var modal = this.get('symbolSearch_1');
                modal.send('showModalPopup');
            },

            closeSearchPopup_1: function closeSearchPopup_1() {
                var modal = this.get('symbolSearch_1');
                modal.send('closeModalPopup');
            },

            showSearchPopup_2: function showSearchPopup_2() {
                var modal = this.get('symbolSearch_2');
                modal.send('showModalPopup');
            },

            closeSearchPopup_2: function closeSearchPopup_2() {
                var modal = this.get('symbolSearch_2');
                modal.send('closeModalPopup');
            },

            showSearchPopup_3: function showSearchPopup_3() {
                var modal = this.get('symbolSearch_3');
                modal.send('showModalPopup');
            },

            closeSearchPopup_3: function closeSearchPopup_3() {
                var modal = this.get('symbolSearch_3');
                modal.send('closeModalPopup');
            },

            showSearchPopup_4: function showSearchPopup_4() {
                var modal = this.get('symbolSearch_4');
                modal.send('showModalPopup');
            },

            closeSearchPopup_4: function closeSearchPopup_4() {
                var modal = this.get('symbolSearch_4');
                modal.send('closeModalPopup');
            },

            onSelectingView: function onSelectingView(option) {
                var that = this;
                that.set('viewType', that._getViewType(option.id));
                var colSymStore = that.get('columnSymbolStore');
                // request ratio-comparison data according to new type
                for (var key in colSymStore) {
                    if (colSymStore.hasOwnProperty(key)) {
                        var exgSymArr = that._splitDataType(colSymStore[key]);
                        that._requestCompareSymbolData(key, exgSymArr[0], exgSymArr[1], exgSymArr[2]);
                    }
                }
            }
        }

    });

    Ember['default'].Handlebars.helper('getDisplayRatio', function (value, decimal) {
        var temp;
        if (value) {
            temp = utils['default'].formatters.formatNumber(value, decimal);

            if (temp.charAt(0) === '-' && temp.charAt(1) !== '-') {
                temp = '(' + temp.substring(1, temp.length) + ')';
            }
        } else {
            temp = userSettings['default'].displayFormat.noValue;
        }
        return temp;
    });

});