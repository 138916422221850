define('ir-app/models/shared/shared-business-entities/user-state', ['exports', 'ember', 'ir-app/models/shared/shared-business-entities/persistent-object'], function (exports, Ember, PersistentObject) {

    'use strict';

    exports['default'] = PersistentObject['default'].extend({
        cacheKey: 'userState',

        lastMenu: undefined,
        lastTab: undefined,
        lastHomeTab: undefined,
        lastArgs: undefined,
        defaultWS: undefined,
        customWS: undefined,
        customWsRowCount: 1,
        customWsColCount: 1,
        popupCount: 0,

        setData: function setData(userState) {
            if (userState) {
                var that = this;

                Ember['default'].$.each(userState, function (key, value) {
                    that.set(key, value);
                });
            }
        }
    }).create();

});