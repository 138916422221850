define('ir-app/controllers/ir-widgets/tab-layouts/stock-overview-compact-layout', ['exports', 'ir-app/controllers/base-controller'], function (exports, BaseController) {

    'use strict';

    /**
     * Created by lakmaln on 6/30/2016.
     */
    // TODO:[Lakmal] Move Stock-overview-layout headings to widget level and remove this compact layout.
    exports['default'] = BaseController['default'].extend({

        init: function init() {
            this.setValueToBaseSymbolContainer('showBaseTitle', false);
        }

    });

});