define('ir-app/app', ['exports', 'ember', 'ember/resolver', 'ember/load-initializers', 'ir-app/config/environment'], function (exports, Ember, Resolver, loadInitializers, config) {

    'use strict';

    Ember['default'].MODEL_FACTORY_INJECTIONS = true;

    var App = Ember['default'].Application.extend({
        modulePrefix: config['default'].modulePrefix,
        podModulePrefix: config['default'].podModulePrefix,
        Resolver: Resolver['default'],
        LOG_VIEW_LOOKUPS: true,
        LOG_ACTIVE_GENERATION: true,
        rootElement: '#ember-app-root'
    });

    loadInitializers['default'](App, config['default'].modulePrefix);

    exports['default'] = App;

});