define('ir-app/components/stk-specific-components/financial-chart', ['exports', 'ember', 'ir-app/utils/utils', 'ir-app/models/shared/language/language-data-store'], function (exports, Ember, utils, LanguageDataStore) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({

    minValue: -1,
    maxValue: -1,
    minimumChartHeight: 250,
    // isRawValues: false,

    handleResize: function handleResize() {
      var that = this;
      try {
        if (document.getElementById('chart_fc_1') !== null) {
          var topHeight = document.getElementById('chart_fc_1').getBoundingClientRect().top;
          var fullHeight = Ember['default'].$(window).height();
          var expectedChartHeight = fullHeight - topHeight - 3; // 3 is a bottom margin
          var chartHeight = (expectedChartHeight > that.minimumChartHeight ? expectedChartHeight : that.minimumChartHeight) / 2;
          that.set('chartContainerHeight', chartHeight);
          that.set('chartContainerWidth', document.getElementById('chart_fc_1').offsetWidth);
        }
      } catch (e) {
        utils['default'].logger.logError('[basic chart widget] handleResize() ' + e);
      }
    },

    handleInitialResize: function handleInitialResize() {
      var that = this;
      setTimeout(function () {
        // give 1s delay to let other components to load.
        that.handleResize();
      }, 1);
    },

    bindResizeEvent: (function () {
      var that = this;
      Ember['default'].$(window).on('resize', Ember['default'].run.bind(this, this.handleResize));
      Ember['default'].$(window).ready(function () {
        Ember['default'].$('#transbox').bind('DOMSubtreeModified', function () {
          // Some times it is bit delay to load the top description panel(#transbox) of the home page
          that.handleResize();
        });
      });
      Ember['default'].$(window).ready(Ember['default'].run.bind(this, this.handleInitialResize));
    }).on('init'),

    formatter: function formatter(val) {
      var result = val;
      if (this.get('isRawValues')) {
        return val;
      }
      if (Math.abs(val) > 1000000) {
        result = Math.floor(val / 1000000) + ' M';
      } else if (Math.abs(val) > 1000) {
        result = Math.floor(val / 1000) + ' K';
      }
      return result;
    },

    layoutName: 'components/stk-specific-components/financial-chart',
    chart: {},
    elementId: '',
    dataSet: {},
    xDataSet: {},
    chartOptions: (function () {
      var that = this;
      return {
        chart: {
          type: 'bar',
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          //  borderWidth: 1,borderRadius: 20,
          marginTop: 30
        },

        // height: 200,

        title: {
          useHTML: true,
          align: 'left',
          // x: -80,
          verticalAlign: 'top',
          y: 10,
          style: {
            fontWeight: 'bold',
            fontSize: '15px'
          }
        },
        subtitle: {
          text: null
        },
        xAxis: {
          title: {
            text: null
          },
          tickLength: 0,

          labels: {
            formatter: function formatter() {

              return this.value;
            }
          }
        },
        yAxis: {
          title: {},
          labels: {
            overflow: 'justify', enabled: false
          },
          gridLineWidth: 0,
          minorGridLineWidth: 0,
          tickLength: 0

        },
        exporting: {
          enabled: false
        },
        tooltip: {
          enabled: true,
          formatter: function formatter() {
            if (this.series.chart.yAxis[0].getExtremes().dataMin < 1000) {
              return '<b>' + this.y.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '</b>';
            } else {
              if (this.series.chart.yAxis[0].getExtremes().dataMin > 1000 && this.series.chart.yAxis[0].getExtremes().dataMin < 100000) {
                return '<b>' + this.y.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '</b>';
              } else {
                return '<b>' + (this.y / 1000).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '</b>';
              }
            }
          }
        },
        plotOptions: {

          bar: {

            dataLabels: {
              enabled: true, style: { fontSize: '10px', align: 'left' }

            }
          }
        },
        legend: {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'top'

        },

        credits: {
          enabled: false
        },

        series: [{
          showInLegend: false, pointWidth: 20, stacking: 'normal'

        }]

      };
    }).property(),

    generateChart: (function () {

      Array.prototype.max = function () {
        return Math.max.apply(null, this);
      };

      Array.prototype.min = function () {
        return Math.min.apply(null, this);
      };

      var chart = this.get('chartOptions');
      var currentDataSet = this.get('dataSet');
      if (currentDataSet && !currentDataSet[1] && !currentDataSet[2] && !currentDataSet[3] && !currentDataSet[4]) {
        var destroyChart = Ember['default'].$('#' + this.get('elementId')).highcharts();
        if (destroyChart) {
          destroyChart.destroy();
        }
      }

      if (currentDataSet.length > 0) {
        chart.series[0]['data'] = currentDataSet.slice(1).map(Number);
      } else {
        return;
      }
      var dataArray = chart.series[0]['data'];
      if (this.get('customizedChartHeight')) {
        chart.chart.height = this.get('customizedChartHeight');
      } else {
        chart.chart.height = this.get('chartContainerHeight');
      }

      chart.yAxis.min = 0;
      chart.yAxis.max = dataArray.max();

      chart.title['text'] = currentDataSet[0];
      chart.yAxis.title['text'] = '';
      var prevY = 0;
      var labels = this.get('customizedBarLabels');
      var showBarLabels = false;
      if (labels === 'enable') {
        showBarLabels = true;
      } else if (labels === 'disable') {
        showBarLabels = false;
      }
      if (labels) {
        chart.plotOptions.bar.dataLabels.enabled = showBarLabels;
      }
      chart.plotOptions.bar['dataLabels'].formatter = function () {
        var result;

        if (prevY === 0) {
          result = '';
          prevY = this.y;
        } else {
          result = utils['default'].formatters.formatNumber((this.y - prevY) / Math.abs(prevY) * 100, 2) + ' %';

          prevY = this.y;
        }
        return result;
      };

      var cat = this.get('xDataSet').slice(1);
      var appendedCat = [];
      var decimalPalces = 0;
      if (this.get('customizedDecimalPlaces')) {
        decimalPalces = this.get('customizedDecimalPlaces');
      }
      for (var i = 0; i < dataArray.length; ++i) {

        // var temp = cat[i] + '<br>' + utils.formatters.inMillions(dataArray[i], 2, this.get('hideUnits'));
        var temp = cat[i] + '<br>' + utils['default'].formatters.formatNumber(dataArray[i], 0);

        if (dataArray.min() > 100000) {
          temp = cat[i] + '<br>' + utils['default'].formatters.formatNumber(dataArray[i] / 1000000, 0);

          chart.xAxis.title.text = LanguageDataStore['default'].getLanguageObj().lang.labels['inMillions'];
        } else if (dataArray.min() > 1000) {

          if (!this.get('alreadyInMillions')) {
            chart.xAxis.title.text = LanguageDataStore['default'].getLanguageObj().lang.labels['inThousands'];
          } else {
            chart.xAxis.title.text = LanguageDataStore['default'].getLanguageObj().lang.labels['inMillions'];
          }
        } else {
          temp = cat[i] + '<br>' + utils['default'].formatters.formatNumber(dataArray[i], 2);
        }
        appendedCat.push(temp);
      }
      chart.xAxis['categories'] = appendedCat;

      if (this.get('customizedPointWidth')) {
        chart.series[0].pointWidth = this.get('customizedPointWidth');
      }

      if (this.get('elementId') === 'chart_fc_1') {
        chart.plotOptions.bar.color = '#f29130';
      } else {
        chart.plotOptions.bar.color = this.get('chartBarColor');
      }
      Ember['default'].$('#' + this.get('elementId')).highcharts(chart);
    }).observes('dataSet', 'chartContainerHeight'),

    didInsertElement: function didInsertElement() {
      this._super();
      this.generateChart();
    }
  });

});