define('ir-app/models/decypha/decypha-master-data', ['exports'], function (exports) {

    'use strict';

    /**
     * Created by channas on 11/15/2016.
     */
    exports['default'] = {

        companyScreener: {
            menaCountryList: [{
                "title": "Algeria",
                "level": 2,
                "select": false,
                "type": "E",
                "key": "DZ",
                "tooltip": "Algeria"
            }, {
                "title": "Bahrain",
                "level": 2,
                "select": false,
                "type": "E",
                "key": "BH",
                "tooltip": "Bahrain"
            }, {
                "title": "Djibouti",
                "level": 2,
                "select": false,
                "type": "E",
                "key": "DJ",
                "tooltip": "Djibouti"
            }, {
                "title": "Egypt",
                "level": 2,
                "select": false,
                "type": "E",
                "key": "EG",
                "tooltip": "Egypt"
            }, {
                "title": "Iran",
                "level": 2,
                "select": false,
                "type": "E",
                "key": "IR",
                "tooltip": "Iran"
            }, {
                "title": "Iraq",
                "level": 2,
                "select": false,
                "type": "E",
                "key": "IQ",
                "tooltip": "Iraq"
            }, {
                "title": "Jordan",
                "level": 2,
                "select": false,
                "type": "E",
                "key": "JO",
                "tooltip": "Jordan"
            }, {
                "title": "Kuwait",
                "level": 2,
                "select": false,
                "type": "E",
                "key": "KW",
                "tooltip": "Kuwait"
            }, {
                "title": "Lebanon",
                "level": 2,
                "select": false,
                "type": "E",
                "key": "LB",
                "tooltip": "Lebanon"
            }, {
                "title": "Libya",
                "level": 2,
                "select": false,
                "type": "E",
                "key": "LY",
                "tooltip": "Libya"
            }, {
                "title": "Mauritania",
                "level": 2,
                "select": false,
                "type": "E",
                "key": "MR",
                "tooltip": "Mauritania"
            }, {
                "title": "Morocco",
                "level": 2,
                "select": false,
                "type": "E",
                "key": "MA",
                "tooltip": "Morocco"
            }, {
                "title": "Oman",
                "level": 2,
                "select": false,
                "type": "E",
                "key": "OM",
                "tooltip": "Oman"
            }, {
                "title": "Palestine",
                "level": 2,
                "select": false,
                "type": "E",
                "key": "PS",
                "tooltip": "Palestine"
            }, {
                "title": "Qatar",
                "level": 2,
                "select": false,
                "type": "E",
                "key": "QA",
                "tooltip": "Qatar"
            }, {
                "title": "Saudi Arabia",
                "level": 2,
                "select": false,
                "type": "E",
                "key": "SA",
                "tooltip": "Saudi Arabia"
            }, {
                "title": "Sudan",
                "level": 2,
                "select": false,
                "type": "E",
                "key": "SD",
                "tooltip": "Sudan"
            }, {
                "title": "Syria",
                "level": 2,
                "select": false,
                "type": "E",
                "key": "SY",
                "tooltip": "Syria"
            }, {
                "title": "Tunisia",
                "level": 2,
                "select": false,
                "type": "E",
                "key": "TN",
                "tooltip": "Tunisia"
            }, {
                "title": "United Arab Emirates",
                "level": 2,
                "select": false,
                "type": "E",
                "key": "AE",
                "tooltip": "United Arab Emirates"
            }, {
                "title": "Yemen",
                "level": 2,
                "select": false,
                "type": "E",
                "key": "YE",
                "tooltip": "Yemen"
            }],
            gicsTree: [{
                "title": "Consumer Discretionary",
                "level": 1,
                "select": false,
                "children": [{
                    "title": "Automobiles & Components",
                    "level": 2,
                    "select": false,
                    "children": [{
                        "title": "Auto Components",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Auto Parts & Equipment",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "25101010",
                            "tooltip": "Auto Parts & Equipment"
                        }, {
                            "title": "Tires & Rubber",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "25101020",
                            "tooltip": "Tires & Rubber"
                        }],
                        "key": "251010",
                        "tooltip": "Auto Components"
                    }, {
                        "title": "Automobiles",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Automobile Manufacturers",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "25102010",
                            "tooltip": "Automobile Manufacturers"
                        }, {
                            "title": "Motorcycle Manufacturers",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "25102020",
                            "tooltip": "Motorcycle Manufacturers"
                        }],
                        "key": "251020",
                        "tooltip": "Automobiles"
                    }],
                    "key": "2510",
                    "tooltip": "Automobiles & Components"
                }, {
                    "title": "Consumer Durables & Apparel",
                    "level": 2,
                    "select": false,
                    "children": [{
                        "title": "Household Durables",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Consumer Electronics",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "25201010",
                            "tooltip": "Consumer Electronics"
                        }, {
                            "title": "Home Furnishings",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "25201020",
                            "tooltip": "Home Furnishings"
                        }, {
                            "title": "Homebuilding",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "25201030",
                            "tooltip": "Homebuilding"
                        }, {
                            "title": "Household Appliances",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "25201040",
                            "tooltip": "Household Appliances"
                        }, {
                            "title": "Housewares & Specialties",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "25201050",
                            "tooltip": "Housewares & Specialties"
                        }],
                        "key": "252010",
                        "tooltip": "Household Durables"
                    }, {
                        "title": "Leisure Products",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Leisure Products",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "25202010",
                            "tooltip": "Leisure Products"
                        }],
                        "key": "252020",
                        "tooltip": "Leisure Products"
                    }, {
                        "title": "Textiles, Apparel & Luxury Goods",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Apparel, Accessories & Luxury Goods",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "25203010",
                            "tooltip": "Apparel, Accessories & Luxury Goods"
                        }, {
                            "title": "Footwear",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "25203020",
                            "tooltip": "Footwear"
                        }, {
                            "title": "Textiles",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "25203030",
                            "tooltip": "Textiles"
                        }],
                        "key": "252030",
                        "tooltip": "Textiles, Apparel & Luxury Goods"
                    }],
                    "key": "2520",
                    "tooltip": "Consumer Durables & Apparel"
                }, {
                    "title": "Consumer Services",
                    "level": 2,
                    "select": false,
                    "children": [{
                        "title": "Diversified Consumer Services",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Education Services",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "25302010",
                            "tooltip": "Education Services"
                        }, {
                            "title": "Specialized Consumer Services",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "25302020",
                            "tooltip": "Specialized Consumer Services"
                        }],
                        "key": "253020",
                        "tooltip": "Diversified Consumer Services"
                    }, {
                        "title": "Hotels, Restaurants & Leisure",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Casinos & Gaming",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "25301010",
                            "tooltip": "Casinos & Gaming"
                        }, {
                            "title": "Hotels, Resorts & Cruise Lines",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "25301020",
                            "tooltip": "Hotels, Resorts & Cruise Lines"
                        }, {
                            "title": "Leisure Facilities",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "25301030",
                            "tooltip": "Leisure Facilities"
                        }, {
                            "title": "Restaurants",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "25301040",
                            "tooltip": "Restaurants"
                        }],
                        "key": "253010",
                        "tooltip": "Hotels, Restaurants & Leisure"
                    }],
                    "key": "2530",
                    "tooltip": "Consumer Services"
                }, {
                    "title": "Media",
                    "level": 2,
                    "select": false,
                    "children": [{
                        "title": "Media",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Advertising",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "25401010",
                            "tooltip": "Advertising"
                        }, {
                            "title": "Broadcasting",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "25401020",
                            "tooltip": "Broadcasting"
                        }, {
                            "title": "Cable & Satellite",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "25401025",
                            "tooltip": "Cable & Satellite"
                        }, {
                            "title": "Movies & Entertainment",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "25401030",
                            "tooltip": "Movies & Entertainment"
                        }, {
                            "title": "Publishing",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "25401040",
                            "tooltip": "Publishing"
                        }],
                        "key": "254010",
                        "tooltip": "Media"
                    }],
                    "key": "2540",
                    "tooltip": "Media"
                }, {
                    "title": "Retailing",
                    "level": 2,
                    "select": false,
                    "children": [{
                        "title": "Distributors",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Distributors",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "25501010",
                            "tooltip": "Distributors"
                        }],
                        "key": "255010",
                        "tooltip": "Distributors"
                    }, {
                        "title": "Internet & Direct Marketing Retail",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Internet & Direct Marketing Retail",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "25502020",
                            "tooltip": "Internet & Direct Marketing Retail"
                        }],
                        "key": "255020",
                        "tooltip": "Internet & Direct Marketing Retail"
                    }, {
                        "title": "Multiline Retail",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Department Stores",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "25503010",
                            "tooltip": "Department Stores"
                        }, {
                            "title": "General Merchandise Stores",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "25503020",
                            "tooltip": "General Merchandise Stores"
                        }],
                        "key": "255030",
                        "tooltip": "Multiline Retail"
                    }, {
                        "title": "Specialty Retail",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Apparel Retail",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "25504010",
                            "tooltip": "Apparel Retail"
                        }, {
                            "title": "Automotive Retail",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "25504050",
                            "tooltip": "Automotive Retail"
                        }, {
                            "title": "Computer & Electronics Retail",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "25504020",
                            "tooltip": "Computer & Electronics Retail"
                        }, {
                            "title": "Home Improvement Retail",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "25504030",
                            "tooltip": "Home Improvement Retail"
                        }, {
                            "title": "Homefurnishing Retail",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "25504060",
                            "tooltip": "Homefurnishing Retail"
                        }, {
                            "title": "Specialty Stores",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "25504040",
                            "tooltip": "Specialty Stores"
                        }],
                        "key": "255040",
                        "tooltip": "Specialty Retail"
                    }],
                    "key": "2550",
                    "tooltip": "Retailing"
                }],
                "key": "25",
                "tooltip": "Consumer Discretionary"
            }, {
                "title": "Consumer Staples",
                "level": 1,
                "select": false,
                "children": [{
                    "title": "Food & Staples Retailing",
                    "level": 2,
                    "select": false,
                    "children": [{
                        "title": "Food & Staples Retailing",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Drug Retail",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "30101010",
                            "tooltip": "Drug Retail"
                        }, {
                            "title": "Food Distributors",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "30101020",
                            "tooltip": "Food Distributors"
                        }, {
                            "title": "Food Retail",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "30101030",
                            "tooltip": "Food Retail"
                        }, {
                            "title": "Hypermarkets & Super Centers",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "30101040",
                            "tooltip": "Hypermarkets & Super Centers"
                        }],
                        "key": "301010",
                        "tooltip": "Food & Staples Retailing"
                    }],
                    "key": "3010",
                    "tooltip": "Food & Staples Retailing"
                }, {
                    "title": "Food, Beverage & Tobacco",
                    "level": 2,
                    "select": false,
                    "children": [{
                        "title": "Beverages",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Brewers",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "30201010",
                            "tooltip": "Brewers"
                        }, {
                            "title": "Distillers & Vintners",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "30201020",
                            "tooltip": "Distillers & Vintners"
                        }, {
                            "title": "Soft Drinks",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "30201030",
                            "tooltip": "Soft Drinks"
                        }],
                        "key": "302010",
                        "tooltip": "Beverages"
                    }, {
                        "title": "Food Products",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Agricultural Products",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "30202010",
                            "tooltip": "Agricultural Products"
                        }, {
                            "title": "Packaged Foods & Meats",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "30202030",
                            "tooltip": "Packaged Foods & Meats"
                        }],
                        "key": "302020",
                        "tooltip": "Food Products"
                    }, {
                        "title": "Tobacco",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Tobacco",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "30203010",
                            "tooltip": "Tobacco"
                        }],
                        "key": "302030",
                        "tooltip": "Tobacco"
                    }],
                    "key": "3020",
                    "tooltip": "Food, Beverage & Tobacco"
                }, {
                    "title": "Household & Personal Products",
                    "level": 2,
                    "select": false,
                    "children": [{
                        "title": "Household Products",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Household Products",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "30301010",
                            "tooltip": "Household Products"
                        }],
                        "key": "303010",
                        "tooltip": "Household Products"
                    }, {
                        "title": "Personal Products",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Personal Products",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "30302010",
                            "tooltip": "Personal Products"
                        }],
                        "key": "303020",
                        "tooltip": "Personal Products"
                    }],
                    "key": "3030",
                    "tooltip": "Household & Personal Products"
                }],
                "key": "30",
                "tooltip": "Consumer Staples"
            }, {
                "title": "Energy",
                "level": 1,
                "select": false,
                "children": [{
                    "title": "Energy",
                    "level": 2,
                    "select": false,
                    "children": [{
                        "title": "Energy Equipment & Services",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Oil & Gas Drilling",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "10101010",
                            "tooltip": "Oil & Gas Drilling"
                        }, {
                            "title": "Oil & Gas Equipment & Services",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "10101020",
                            "tooltip": "Oil & Gas Equipment & Services"
                        }],
                        "key": "101010",
                        "tooltip": "Energy Equipment & Services"
                    }, {
                        "title": "Oil, Gas & Consumable Fuels",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Coal & Consumable Fuels",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "10102050",
                            "tooltip": "Coal & Consumable Fuels"
                        }, {
                            "title": "Integrated Oil & Gas",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "10102010",
                            "tooltip": "Integrated Oil & Gas"
                        }, {
                            "title": "Oil & Gas Exploration & Production",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "10102020",
                            "tooltip": "Oil & Gas Exploration & Production"
                        }, {
                            "title": "Oil & Gas Refining & Marketing",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "10102030",
                            "tooltip": "Oil & Gas Refining & Marketing"
                        }, {
                            "title": "Oil & Gas Storage & Transportation",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "10102040",
                            "tooltip": "Oil & Gas Storage & Transportation"
                        }],
                        "key": "101020",
                        "tooltip": "Oil, Gas & Consumable Fuels"
                    }],
                    "key": "1010",
                    "tooltip": "Energy"
                }],
                "key": "10",
                "tooltip": "Energy"
            }, {
                "title": "Financials",
                "level": 1,
                "select": false,
                "children": [{
                    "title": "Banks",
                    "level": 2,
                    "select": false,
                    "children": [{
                        "title": "Banks",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Diversified Banks",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "40101010",
                            "tooltip": "Diversified Banks"
                        }, {
                            "title": "Regional Banks",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "40101015",
                            "tooltip": "Regional Banks"
                        }],
                        "key": "401010",
                        "tooltip": "Banks"
                    }, {
                        "title": "Thrifts & Mortgage Finance",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Thrifts & Mortgage Finance",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "40102010",
                            "tooltip": "Thrifts & Mortgage Finance"
                        }],
                        "key": "401020",
                        "tooltip": "Thrifts & Mortgage Finance"
                    }],
                    "key": "4010",
                    "tooltip": "Banks"
                }, {
                    "title": "Diversified Financials",
                    "level": 2,
                    "select": false,
                    "children": [{
                        "title": "Capital Markets",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Asset Management & Custody Banks",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "40203010",
                            "tooltip": "Asset Management & Custody Banks"
                        }, {
                            "title": "Diversified Capital Markets",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "40203030",
                            "tooltip": "Diversified Capital Markets"
                        }, {
                            "title": "Financial Exchanges & Data",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "40203040",
                            "tooltip": "Financial Exchanges & Data"
                        }, {
                            "title": "Investment Banking & Brokerage",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "40203020",
                            "tooltip": "Investment Banking & Brokerage"
                        }],
                        "key": "402030",
                        "tooltip": "Capital Markets"
                    }, {
                        "title": "Consumer Finance",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Consumer Finance",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "40202010",
                            "tooltip": "Consumer Finance"
                        }],
                        "key": "402020",
                        "tooltip": "Consumer Finance"
                    }, {
                        "title": "Diversified Financial Services",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": " Other Diversified Financial Services",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "40201020",
                            "tooltip": " Other Diversified Financial Services"
                        }, {
                            "title": "Multi-Sector Holdings",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "40201030",
                            "tooltip": "Multi-Sector Holdings"
                        }, {
                            "title": "Specialized Finance",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "40201040",
                            "tooltip": "Specialized Finance"
                        }],
                        "key": "402010",
                        "tooltip": "Diversified Financial Services"
                    }, {
                        "title": "Mortgage Real Estate Investment Trusts (REITs)",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Mortgage REITs",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "40204010",
                            "tooltip": "Mortgage REITs"
                        }],
                        "key": "402040",
                        "tooltip": "Mortgage Real Estate Investment Trusts (REITs)"
                    }],
                    "key": "4020",
                    "tooltip": "Diversified Financials"
                }, {
                    "title": "Insurance",
                    "level": 2,
                    "select": false,
                    "children": [{
                        "title": "Insurance",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Insurance Brokers",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "40301010",
                            "tooltip": "Insurance Brokers"
                        }, {
                            "title": "Life & Health Insurance",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "40301020",
                            "tooltip": "Life & Health Insurance"
                        }, {
                            "title": "Multi-line Insurance",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "40301030",
                            "tooltip": "Multi-line Insurance"
                        }, {
                            "title": "Property & Casualty Insurance",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "40301040",
                            "tooltip": "Property & Casualty Insurance"
                        }, {
                            "title": "Reinsurance",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "40301050",
                            "tooltip": "Reinsurance"
                        }],
                        "key": "403010",
                        "tooltip": "Insurance"
                    }],
                    "key": "4030",
                    "tooltip": "Insurance"
                }],
                "key": "40",
                "tooltip": "Financials"
            }, {
                "title": "Health Care",
                "level": 1,
                "select": false,
                "children": [{
                    "title": "Health Care Equipment & Services",
                    "level": 2,
                    "select": false,
                    "children": [{
                        "title": "Health Care Equipment & Supplies",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Health Care Equipment",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "35101010",
                            "tooltip": "Health Care Equipment"
                        }, {
                            "title": "Health Care Supplies",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "35101020",
                            "tooltip": "Health Care Supplies"
                        }],
                        "key": "351010",
                        "tooltip": "Health Care Equipment & Supplies"
                    }, {
                        "title": "Health Care Providers & Services",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Health Care Services",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "35102015",
                            "tooltip": "Health Care Services"
                        }, {
                            "title": "Health Care Distributors",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "35102010",
                            "tooltip": "Health Care Distributors"
                        }, {
                            "title": "Health Care Facilities",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "35102020",
                            "tooltip": "Health Care Facilities"
                        }, {
                            "title": "Managed Health Care",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "35102030",
                            "tooltip": "Managed Health Care"
                        }],
                        "key": "351020",
                        "tooltip": "Health Care Providers & Services"
                    }, {
                        "title": "Health Care Technology",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Health Care Technology",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "35103010",
                            "tooltip": "Health Care Technology"
                        }],
                        "key": "351030",
                        "tooltip": "Health Care Technology"
                    }],
                    "key": "3510",
                    "tooltip": "Health Care Equipment & Services"
                }, {
                    "title": "Pharmaceuticals, Biotechnology & Life Sciences",
                    "level": 2,
                    "select": false,
                    "children": [{
                        "title": "Biotechnology",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Biotechnology",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "35201010",
                            "tooltip": "Biotechnology"
                        }],
                        "key": "352010",
                        "tooltip": "Biotechnology"
                    }, {
                        "title": "Life Sciences Tools & Services",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Life Sciences Tools & Services",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "35203010",
                            "tooltip": "Life Sciences Tools & Services"
                        }],
                        "key": "352030",
                        "tooltip": "Life Sciences Tools & Services"
                    }, {
                        "title": "Pharmaceuticals",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Pharmaceuticals",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "35202010",
                            "tooltip": "Pharmaceuticals"
                        }],
                        "key": "352020",
                        "tooltip": "Pharmaceuticals"
                    }],
                    "key": "3520",
                    "tooltip": "Pharmaceuticals, Biotechnology & Life Sciences"
                }],
                "key": "35",
                "tooltip": "Health Care"
            }, {
                "title": "Industrials",
                "level": 1,
                "select": false,
                "children": [{
                    "title": "Capital Goods",
                    "level": 2,
                    "select": false,
                    "children": [{
                        "title": "Aerospace & Defense",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Aerospace & Defense",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "20101010",
                            "tooltip": "Aerospace & Defense"
                        }],
                        "key": "201010",
                        "tooltip": "Aerospace & Defense"
                    }, {
                        "title": "Building Products",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Building Products",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "20102010",
                            "tooltip": "Building Products"
                        }],
                        "key": "201020",
                        "tooltip": "Building Products"
                    }, {
                        "title": "Construction & Engineering",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Construction & Engineering",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "20103010",
                            "tooltip": "Construction & Engineering"
                        }],
                        "key": "201030",
                        "tooltip": "Construction & Engineering"
                    }, {
                        "title": "Electrical Equipment",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Electrical Components & Equipment",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "20104010",
                            "tooltip": "Electrical Components & Equipment"
                        }, {
                            "title": "Heavy Electrical Equipment",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "20104020",
                            "tooltip": "Heavy Electrical Equipment"
                        }],
                        "key": "201040",
                        "tooltip": "Electrical Equipment"
                    }, {
                        "title": "Industrial Conglomerates",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Industrial Conglomerates",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "20105010",
                            "tooltip": "Industrial Conglomerates"
                        }],
                        "key": "201050",
                        "tooltip": "Industrial Conglomerates"
                    }, {
                        "title": "Machinery",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Agricultural & Farm Machinery",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "20106015",
                            "tooltip": "Agricultural & Farm Machinery"
                        }, {
                            "title": "Construction Machinery & Heavy Trucks",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "20106010",
                            "tooltip": "Construction Machinery & Heavy Trucks"
                        }, {
                            "title": "Industrial Machinery",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "20106020",
                            "tooltip": "Industrial Machinery"
                        }],
                        "key": "201060",
                        "tooltip": "Machinery"
                    }, {
                        "title": "Trading Companies & Distributors",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Trading Companies & Distributors",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "20107010",
                            "tooltip": "Trading Companies & Distributors"
                        }],
                        "key": "201070",
                        "tooltip": "Trading Companies & Distributors"
                    }],
                    "key": "2010",
                    "tooltip": "Capital Goods"
                }, {
                    "title": "Commercial & Professional Services",
                    "level": 2,
                    "select": false,
                    "children": [{
                        "title": "Commercial Services & Supplies",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Commercial Printing",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "20201010",
                            "tooltip": "Commercial Printing"
                        }, {
                            "title": "Diversified Support Services",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "20201070",
                            "tooltip": "Diversified Support Services"
                        }, {
                            "title": "Environmental & Facilities Services",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "20201050",
                            "tooltip": "Environmental & Facilities Services"
                        }, {
                            "title": "Office Services & Supplies",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "20201060",
                            "tooltip": "Office Services & Supplies"
                        }, {
                            "title": "Security & Alarm Services",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "20201080",
                            "tooltip": "Security & Alarm Services"
                        }],
                        "key": "202010",
                        "tooltip": "Commercial Services & Supplies"
                    }, {
                        "title": "Professional Services",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Human Resource & Employment Services",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "20202010",
                            "tooltip": "Human Resource & Employment Services"
                        }, {
                            "title": "Research & Consulting Services",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "20202020",
                            "tooltip": "Research & Consulting Services"
                        }],
                        "key": "202020",
                        "tooltip": "Professional Services"
                    }],
                    "key": "2020",
                    "tooltip": "Commercial & Professional Services"
                }, {
                    "title": "Transportation",
                    "level": 2,
                    "select": false,
                    "children": [{
                        "title": "Air Freight & Logistics",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Air Freight & Logistics",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "20301010",
                            "tooltip": "Air Freight & Logistics"
                        }],
                        "key": "203010",
                        "tooltip": "Air Freight & Logistics"
                    }, {
                        "title": "Airlines",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Airlines",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "20302010",
                            "tooltip": "Airlines"
                        }],
                        "key": "203020",
                        "tooltip": "Airlines"
                    }, {
                        "title": "Marine",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Marine",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "20303010",
                            "tooltip": "Marine"
                        }],
                        "key": "203030",
                        "tooltip": "Marine"
                    }, {
                        "title": "Road & Rail",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Railroads",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "20304010",
                            "tooltip": "Railroads"
                        }, {
                            "title": "Trucking",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "20304020",
                            "tooltip": "Trucking"
                        }],
                        "key": "203040",
                        "tooltip": "Road & Rail"
                    }, {
                        "title": "Transportation Infrastructure",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Airport Services",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "20305010",
                            "tooltip": "Airport Services"
                        }, {
                            "title": "Highways & Railtracks",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "20305020",
                            "tooltip": "Highways & Railtracks"
                        }, {
                            "title": "Marine Ports & Services",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "20305030",
                            "tooltip": "Marine Ports & Services"
                        }],
                        "key": "203050",
                        "tooltip": "Transportation Infrastructure"
                    }],
                    "key": "2030",
                    "tooltip": "Transportation"
                }],
                "key": "20",
                "tooltip": "Industrials"
            }, {
                "title": "Information Technology",
                "level": 1,
                "select": false,
                "children": [{
                    "title": "Semiconductors & Semiconductor Equipment",
                    "level": 2,
                    "select": false,
                    "children": [{
                        "title": "Semiconductors & Semiconductor Equipment",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Semiconductor Equipment ",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "45301010",
                            "tooltip": "Semiconductor Equipment "
                        }, {
                            "title": "Semiconductors",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "45301020",
                            "tooltip": "Semiconductors"
                        }],
                        "key": "453010",
                        "tooltip": "Semiconductors & Semiconductor Equipment"
                    }],
                    "key": "4530",
                    "tooltip": "Semiconductors & Semiconductor Equipment"
                }, {
                    "title": "Software & Services",
                    "level": 2,
                    "select": false,
                    "children": [{
                        "title": "IT Services",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Data Processing & Outsourced Services",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "45102020",
                            "tooltip": "Data Processing & Outsourced Services"
                        }, {
                            "title": "IT Consulting & Other Services",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "45102010",
                            "tooltip": "IT Consulting & Other Services"
                        }],
                        "key": "451020",
                        "tooltip": "IT Services"
                    }, {
                        "title": "Internet Software & Services",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Internet Software & Services",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "45101010",
                            "tooltip": "Internet Software & Services"
                        }],
                        "key": "451010",
                        "tooltip": "Internet Software & Services"
                    }, {
                        "title": "Software",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Application Software",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "45103010",
                            "tooltip": "Application Software"
                        }, {
                            "title": "Home Entertainment Software",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "45103030",
                            "tooltip": "Home Entertainment Software"
                        }, {
                            "title": "Systems Software",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "45103020",
                            "tooltip": "Systems Software"
                        }],
                        "key": "451030",
                        "tooltip": "Software"
                    }],
                    "key": "4510",
                    "tooltip": "Software & Services"
                }, {
                    "title": "Technology Hardware & Equipment",
                    "level": 2,
                    "select": false,
                    "children": [{
                        "title": "Communications Equipment",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Communications Equipment",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "45201020",
                            "tooltip": "Communications Equipment"
                        }],
                        "key": "452010",
                        "tooltip": "Communications Equipment"
                    }, {
                        "title": "Electronic Equipment, Instruments & Components",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Electronic Components",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "45203015",
                            "tooltip": "Electronic Components"
                        }, {
                            "title": "Electronic Equipment & Instruments ",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "45203010",
                            "tooltip": "Electronic Equipment & Instruments "
                        }, {
                            "title": "Electronic Manufacturing Services",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "45203020",
                            "tooltip": "Electronic Manufacturing Services"
                        }, {
                            "title": "Technology Distributors",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "45203030",
                            "tooltip": "Technology Distributors"
                        }],
                        "key": "452030",
                        "tooltip": "Electronic Equipment, Instruments & Components"
                    }, {
                        "title": "Technology Hardware, Storage & Peripherals",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Technology Hardware, Storage & Peripherals",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "45202030",
                            "tooltip": "Technology Hardware, Storage & Peripherals"
                        }],
                        "key": "452020",
                        "tooltip": "Technology Hardware, Storage & Peripherals"
                    }],
                    "key": "4520",
                    "tooltip": "Technology Hardware & Equipment"
                }],
                "key": "45",
                "tooltip": "Information Technology"
            }, {
                "title": "Materials",
                "level": 1,
                "select": false,
                "children": [{
                    "title": "Materials",
                    "level": 2,
                    "select": false,
                    "children": [{
                        "title": "Chemicals",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Commodity Chemicals",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "15101010",
                            "tooltip": "Commodity Chemicals"
                        }, {
                            "title": "Diversified Chemicals",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "15101020",
                            "tooltip": "Diversified Chemicals"
                        }, {
                            "title": "Fertilizers & Agricultural Chemicals",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "15101030",
                            "tooltip": "Fertilizers & Agricultural Chemicals"
                        }, {
                            "title": "Industrial Gases",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "15101040",
                            "tooltip": "Industrial Gases"
                        }, {
                            "title": "Specialty Chemicals",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "15101050",
                            "tooltip": "Specialty Chemicals"
                        }],
                        "key": "151010",
                        "tooltip": "Chemicals"
                    }, {
                        "title": "Construction Materials",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Construction Materials",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "15102010",
                            "tooltip": "Construction Materials"
                        }],
                        "key": "151020",
                        "tooltip": "Construction Materials"
                    }, {
                        "title": "Containers & Packaging",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Metal & Glass Containers",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "15103010",
                            "tooltip": "Metal & Glass Containers"
                        }, {
                            "title": "Paper Packaging",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "15103020",
                            "tooltip": "Paper Packaging"
                        }],
                        "key": "151030",
                        "tooltip": "Containers & Packaging"
                    }, {
                        "title": "Metals & Mining",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Aluminum",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "15104010",
                            "tooltip": "Aluminum"
                        }, {
                            "title": "Copper",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "15104025",
                            "tooltip": "Copper"
                        }, {
                            "title": "Diversified Metals & Mining",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "15104020",
                            "tooltip": "Diversified Metals & Mining"
                        }, {
                            "title": "Gold",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "15104030",
                            "tooltip": "Gold"
                        }, {
                            "title": "Precious Metals & Minerals",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "15104040",
                            "tooltip": "Precious Metals & Minerals"
                        }, {
                            "title": "Silver",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "15104045",
                            "tooltip": "Silver"
                        }, {
                            "title": "Steel",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "15104050",
                            "tooltip": "Steel"
                        }],
                        "key": "151040",
                        "tooltip": "Metals & Mining"
                    }, {
                        "title": "Paper & Forest Products",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Forest Products",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "15105010",
                            "tooltip": "Forest Products"
                        }, {
                            "title": "Paper Products",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "15105020",
                            "tooltip": "Paper Products"
                        }],
                        "key": "151050",
                        "tooltip": "Paper & Forest Products"
                    }],
                    "key": "1510",
                    "tooltip": "Materials"
                }],
                "key": "15",
                "tooltip": "Materials"
            }, {
                "title": "Real Estate",
                "level": 1,
                "select": false,
                "children": [{
                    "title": "Real Estate",
                    "level": 2,
                    "select": false,
                    "children": [{
                        "title": "Equity Real Estate Investment Trusts (REITs)",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Diversified REITs",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "60101010",
                            "tooltip": "Diversified REITs"
                        }, {
                            "title": "Health Care REITs ",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "60101050",
                            "tooltip": "Health Care REITs "
                        }, {
                            "title": "Hotel & Resort REITs ",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "60101030",
                            "tooltip": "Hotel & Resort REITs "
                        }, {
                            "title": "Industrial REITs",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "60101020",
                            "tooltip": "Industrial REITs"
                        }, {
                            "title": "Office REITs ",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "60101040",
                            "tooltip": "Office REITs "
                        }, {
                            "title": "Residential REITs",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "60101060",
                            "tooltip": "Residential REITs"
                        }, {
                            "title": "Retail REITs",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "60101070",
                            "tooltip": "Retail REITs"
                        }, {
                            "title": "Specialized REITs ",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "60101080",
                            "tooltip": "Specialized REITs "
                        }],
                        "key": "601010",
                        "tooltip": "Equity Real Estate Investment Trusts (REITs)"
                    }, {
                        "title": "Real Estate Management & Development",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Diversified Real Estate Activities ",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "60102010",
                            "tooltip": "Diversified Real Estate Activities "
                        }, {
                            "title": "Real Estate Development ",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "60102030",
                            "tooltip": "Real Estate Development "
                        }, {
                            "title": "Real Estate Operating Companies",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "60102020",
                            "tooltip": "Real Estate Operating Companies"
                        }, {
                            "title": "Real Estate Services ",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "60102040",
                            "tooltip": "Real Estate Services "
                        }],
                        "key": "601020",
                        "tooltip": "Real Estate Management & Development"
                    }],
                    "key": "6010",
                    "tooltip": "Real Estate"
                }],
                "key": "60",
                "tooltip": "Real Estate"
            }, {
                "title": "Telecommunication Services",
                "level": 1,
                "select": false,
                "children": [{
                    "title": "Telecommunication Services",
                    "level": 2,
                    "select": false,
                    "children": [{
                        "title": "Diversified Telecommunication Services",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Alternative Carriers",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "50101010",
                            "tooltip": "Alternative Carriers"
                        }, {
                            "title": "Integrated Telecommunication Services",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "50101020",
                            "tooltip": "Integrated Telecommunication Services"
                        }],
                        "key": "501010",
                        "tooltip": "Diversified Telecommunication Services"
                    }, {
                        "title": "Wireless Telecommunication Services",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Wireless Telecommunication Services",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "50102010",
                            "tooltip": "Wireless Telecommunication Services"
                        }],
                        "key": "501020",
                        "tooltip": "Wireless Telecommunication Services"
                    }],
                    "key": "5010",
                    "tooltip": "Telecommunication Services"
                }],
                "key": "50",
                "tooltip": "Telecommunication Services"
            }, {
                "title": "Utilities",
                "level": 1,
                "select": false,
                "children": [{
                    "title": "Utilities",
                    "level": 2,
                    "select": false,
                    "children": [{
                        "title": "Electric Utilities",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Electric Utilities",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "55101010",
                            "tooltip": "Electric Utilities"
                        }],
                        "key": "551010",
                        "tooltip": "Electric Utilities"
                    }, {
                        "title": "Gas Utilities",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Gas Utilities",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "55102010",
                            "tooltip": "Gas Utilities"
                        }],
                        "key": "551020",
                        "tooltip": "Gas Utilities"
                    }, {
                        "title": "Independent Power and Renewable Electricity Producers",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Independent Power Producers & Energy Traders",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "55105010",
                            "tooltip": "Independent Power Producers & Energy Traders"
                        }, {
                            "title": "Renewable Electricity",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "55105020",
                            "tooltip": "Renewable Electricity"
                        }],
                        "key": "551050",
                        "tooltip": "Independent Power and Renewable Electricity Producers"
                    }, {
                        "title": "Multi-Utilities",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Multi-Utilities",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "55103010",
                            "tooltip": "Multi-Utilities"
                        }],
                        "key": "551030",
                        "tooltip": "Multi-Utilities"
                    }, {
                        "title": "Water Utilities",
                        "level": 3,
                        "select": false,
                        "children": [{
                            "title": "Water Utilities",
                            "level": 4,
                            "select": false,
                            "type": "E",
                            "key": "55104010",
                            "tooltip": "Water Utilities"
                        }],
                        "key": "551040",
                        "tooltip": "Water Utilities"
                    }],
                    "key": "5510",
                    "tooltip": "Utilities"
                }],
                "key": "55",
                "tooltip": "Utilities"
            }]
        }
    };

});