define('ir-app/models/shared/language/language-customisations', ['exports'], function (exports) {

    'use strict';

    exports['default'] = {
        gfh: {
            EN: {
                obj: {
                    labels: {
                        eventCalendar: 'Calendar of Events',
                        documents: 'Disclosures',
                        exgDubai: 'Dubai',
                        exgKuwait: 'Kuwait',
                        exgBahrain: 'Bahrain'
                    }
                }
            },
            AR: {
                obj: {
                    labels: {
                        eventCalendar: 'الفعاليات',
                        documents: 'الإفصاحات',
                        description: 'التفاصيل',
                        exgDubai: 'دبي',
                        exgKuwait: 'الكويت',
                        exgBahrain: 'البحرين'
                    }
                }
            }
        }
    };

});