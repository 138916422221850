define('ir-app/templates/ir-widgets/fair-value-widgets/analyst-view', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("th");
          dom.setAttribute(el1,"id","lblTargetP");
          dom.setAttribute(el1,"class","text-align-right");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2,"class","fa fa-question-circle padding-small-left cursor-pointer");
          dom.setAttribute(el2,"aria-hidden","true");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, content = hooks.content, get = hooks.get, attribute = hooks.attribute;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element22 = dom.childAt(fragment, [1]);
          var element23 = dom.childAt(element22, [1]);
          var morph0 = dom.createMorphAt(element22,0,0);
          var attrMorph0 = dom.createAttrMorph(element23, 'title');
          content(env, morph0, context, "app.lang.labels.targetP");
          attribute(env, attrMorph0, element23, "title", get(env, context, "app.lang.labels.adjTargetPrice"));
          return fragment;
        }
      };
    }());
    var child1 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("th");
          dom.setAttribute(el1,"id","lblTargetP");
          dom.setAttribute(el1,"class","text-align-right");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2,"class","fa fa-question-circle padding-small-left cursor-pointer");
          dom.setAttribute(el2,"aria-hidden","true");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, content = hooks.content, get = hooks.get, attribute = hooks.attribute;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element20 = dom.childAt(fragment, [1]);
          var element21 = dom.childAt(element20, [1]);
          var morph0 = dom.createMorphAt(element20,0,0);
          var attrMorph0 = dom.createAttrMorph(element21, 'title');
          content(env, morph0, context, "app.lang.labels.targetP");
          attribute(env, attrMorph0, element21, "title", get(env, context, "app.lang.labels.rawTargetPrice"));
          return fragment;
        }
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(fragment,1,1,contextualElement);
            var morph1 = dom.createMorphAt(fragment,2,2,contextualElement);
            inline(env, morph0, context, "getAdjustedIndicator", [get(env, context, "item.adjTargetPrice"), get(env, context, "item.targetPrice")], {});
            inline(env, morph1, context, "formatNumber", [get(env, context, "item.adjTargetPrice")], {});
            return fragment;
          }
        };
      }());
      var child1 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(fragment,1,1,contextualElement);
            inline(env, morph0, context, "formatNumber", [get(env, context, "item.targetPrice")], {});
            return fragment;
          }
        };
      }());
      var child2 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, subexpr = hooks.subexpr, concat = hooks.concat, attribute = hooks.attribute, inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element1 = dom.childAt(fragment, [1]);
            var morph0 = dom.createMorphAt(element1,0,0);
            var attrMorph0 = dom.createAttrMorph(element1, 'class');
            attribute(env, attrMorph0, element1, "class", concat(env, [subexpr(env, context, "getUpDownStyle", [subexpr(env, context, "getUpsidePotential", [get(env, context, "stock.adjustedLtp"), get(env, context, "item.adjTargetPrice"), false], {}), "green up", "red down", ""], {})]));
            inline(env, morph0, context, "getUpsidePotential", [get(env, context, "stock.adjustedLtp"), get(env, context, "item.adjTargetPrice"), true], {});
            return fragment;
          }
        };
      }());
      var child3 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, subexpr = hooks.subexpr, concat = hooks.concat, attribute = hooks.attribute, inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element0 = dom.childAt(fragment, [1]);
            var morph0 = dom.createMorphAt(element0,0,0);
            var attrMorph0 = dom.createAttrMorph(element0, 'class');
            attribute(env, attrMorph0, element0, "class", concat(env, [subexpr(env, context, "getUpDownStyle", [subexpr(env, context, "getUpsidePotential", [get(env, context, "stock.adjustedLtp"), get(env, context, "item.targetPrice"), false], {}), "green up", "red down", ""], {})]));
            inline(env, morph0, context, "getUpsidePotential", [get(env, context, "stock.adjustedLtp"), get(env, context, "item.targetPrice"), true], {});
            return fragment;
          }
        };
      }());
      var child4 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(fragment,1,1,contextualElement);
            var morph1 = dom.createMorphAt(fragment,2,2,contextualElement);
            inline(env, morph0, context, "getAdjustedIndicator", [get(env, context, "item.prvAdjTargetPrice"), get(env, context, "item.prvTargetPrice")], {});
            inline(env, morph1, context, "formatNumber", [get(env, context, "item.prvAdjTargetPrice")], {});
            return fragment;
          }
        };
      }());
      var child5 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(fragment,1,1,contextualElement);
            inline(env, morph0, context, "formatNumber", [get(env, context, "item.prvTargetPrice")], {});
            return fragment;
          }
        };
      }());
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("tr");
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"class","text-align-right");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"class","text-align-left");
          var el3 = dom.createTextNode("\n                            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          dom.setAttribute(el3,"class","text-align-left");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"class","text-align-right");
          var el3 = dom.createTextNode("\n                            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"class","snap-change-icon");
          var el3 = dom.createTextNode("\n                            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          dom.setAttribute(el3,"class","text-align-center");
          var el4 = dom.createTextNode("\n                                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"class","text-align-left");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"class","text-align-right");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"class","text-align-right");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"class","text-align-center");
          var el3 = dom.createTextNode("\n                            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          dom.setAttribute(el3,"class","text-align-center");
          var el4 = dom.createTextNode("\n                                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("a");
          dom.setAttribute(el4,"target","_blank");
          var el5 = dom.createElement("span");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"class","text-align-right");
          var el3 = dom.createTextNode("\n                            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"class","text-align-left");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"class","text-align-right");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"class","text-align-center");
          var el3 = dom.createTextNode("\n                            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          dom.setAttribute(el3,"class","text-align-center");
          var el4 = dom.createTextNode("\n                                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("a");
          dom.setAttribute(el4,"target","_blank");
          var el5 = dom.createElement("span");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, concat = hooks.concat, attribute = hooks.attribute, content = hooks.content, inline = hooks.inline, subexpr = hooks.subexpr, block = hooks.block;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(element2, [1]);
          var element4 = dom.childAt(element2, [3]);
          var element5 = dom.childAt(element2, [5]);
          var element6 = dom.childAt(element2, [7]);
          var element7 = dom.childAt(element6, [1, 1]);
          var element8 = dom.childAt(element2, [9]);
          var element9 = dom.childAt(element2, [11]);
          var element10 = dom.childAt(element2, [13]);
          var element11 = dom.childAt(element2, [15]);
          var element12 = dom.childAt(element11, [1, 1]);
          var element13 = dom.childAt(element12, [0]);
          var element14 = dom.childAt(element2, [17]);
          var element15 = dom.childAt(element2, [19]);
          var element16 = dom.childAt(element2, [21]);
          var element17 = dom.childAt(element2, [23]);
          var element18 = dom.childAt(element17, [1, 1]);
          var element19 = dom.childAt(element18, [0]);
          var morph0 = dom.createMorphAt(element3,0,0);
          var attrMorph0 = dom.createAttrMorph(element3, 'id');
          var attrMorph1 = dom.createAttrMorph(element4, 'id');
          var morph1 = dom.createMorphAt(dom.childAt(element4, [1]),0,0);
          var attrMorph2 = dom.createAttrMorph(element5, 'id');
          var morph2 = dom.createMorphAt(dom.childAt(element5, [1]),0,0);
          var attrMorph3 = dom.createAttrMorph(element6, 'id');
          var attrMorph4 = dom.createAttrMorph(element7, 'class');
          var morph3 = dom.createMorphAt(element8,0,0);
          var attrMorph5 = dom.createAttrMorph(element8, 'id');
          var morph4 = dom.createMorphAt(element9,1,1);
          var attrMorph6 = dom.createAttrMorph(element9, 'id');
          var morph5 = dom.createMorphAt(element10,1,1);
          var attrMorph7 = dom.createAttrMorph(element10, 'id');
          var attrMorph8 = dom.createAttrMorph(element11, 'id');
          var attrMorph9 = dom.createAttrMorph(element12, 'href');
          var attrMorph10 = dom.createAttrMorph(element13, 'class');
          var attrMorph11 = dom.createAttrMorph(element13, 'id');
          var attrMorph12 = dom.createAttrMorph(element14, 'id');
          var morph6 = dom.createMorphAt(dom.childAt(element14, [1]),0,0);
          var morph7 = dom.createMorphAt(element15,0,0);
          var attrMorph13 = dom.createAttrMorph(element15, 'id');
          var morph8 = dom.createMorphAt(element16,1,1);
          var attrMorph14 = dom.createAttrMorph(element16, 'id');
          var attrMorph15 = dom.createAttrMorph(element17, 'id');
          var attrMorph16 = dom.createAttrMorph(element18, 'href');
          var attrMorph17 = dom.createAttrMorph(element19, 'class');
          var attrMorph18 = dom.createAttrMorph(element19, 'id');
          attribute(env, attrMorph0, element3, "id", concat(env, ["valueRefId_", get(env, context, "item.comId")]));
          content(env, morph0, context, "item.refId");
          attribute(env, attrMorph1, element4, "id", concat(env, ["valueProvider_", get(env, context, "item.comId")]));
          content(env, morph1, context, "item.provider");
          attribute(env, attrMorph2, element5, "id", concat(env, ["valueDate_", get(env, context, "item.comId")]));
          inline(env, morph2, context, "displayFormatDate", [get(env, context, "item.date"), get(env, context, "app.lang.labels"), true, "day", "month", "year"], {});
          attribute(env, attrMorph3, element6, "id", concat(env, ["valueChgInd_", get(env, context, "item.comId")]));
          attribute(env, attrMorph4, element7, "class", concat(env, ["float-none no-margin snap-change text-nowrap ", subexpr(env, context, "getRecChgIndicatorStyle", [get(env, context, "item.ratingScore"), get(env, context, "item.prevRatingScore")], {})]));
          attribute(env, attrMorph5, element8, "id", concat(env, ["valueRec_", get(env, context, "item.comId")]));
          inline(env, morph3, context, "getRecommendation", [get(env, context, "item.ratingScore")], {});
          attribute(env, attrMorph6, element9, "id", concat(env, ["valueTargetP_", get(env, context, "item.comId")]));
          block(env, morph4, context, "if", [get(env, context, "isAdjusted")], {}, child0, child1);
          attribute(env, attrMorph7, element10, "id", concat(env, ["valueUpsideP_", get(env, context, "item.comId")]));
          block(env, morph5, context, "if", [get(env, context, "isAdjusted")], {}, child2, child3);
          attribute(env, attrMorph8, element11, "id", concat(env, ["valueRep_", get(env, context, "item.comId")]));
          attribute(env, attrMorph9, element12, "href", concat(env, [subexpr(env, context, "getDocURL", [get(env, context, "docDownloadURL"), get(env, context, "item.report")], {})]));
          attribute(env, attrMorph10, element13, "class", concat(env, [subexpr(env, context, "getDocIcon", [get(env, context, "item.report")], {})]));
          attribute(env, attrMorph11, element13, "id", concat(env, ["fairvalue_report_", get(env, context, "item.comId")]));
          attribute(env, attrMorph12, element14, "id", concat(env, ["valuePrevRepDate_", get(env, context, "item.comId")]));
          inline(env, morph6, context, "displayFormatDate", [get(env, context, "item.prvReportDate"), get(env, context, "app.lang.labels"), true, "day", "month", "year"], {});
          attribute(env, attrMorph13, element15, "id", concat(env, ["valuePrevRec_", get(env, context, "item.comId")]));
          inline(env, morph7, context, "getRecommendation", [get(env, context, "item.prevRatingScore")], {});
          attribute(env, attrMorph14, element16, "id", concat(env, ["valuePrevTargetP_", get(env, context, "item.comId")]));
          block(env, morph8, context, "if", [get(env, context, "isAdjusted")], {}, child4, child5);
          attribute(env, attrMorph15, element17, "id", concat(env, ["valuePrevRep_", get(env, context, "item.comId")]));
          attribute(env, attrMorph16, element18, "href", concat(env, [subexpr(env, context, "getDocURL", [get(env, context, "docDownloadURL"), get(env, context, "item.prvReport")], {})]));
          attribute(env, attrMorph17, element19, "class", concat(env, [subexpr(env, context, "getDocIcon", [get(env, context, "item.prvReport")], {})]));
          attribute(env, attrMorph18, element19, "id", concat(env, ["fairvalue_prev_report_", get(env, context, "item.comId")]));
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","row margin-medium-bottom analyst-view");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col-xs-12 col-sm-6 col-md-7");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","margin-medium-bottom");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","row");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","container-fluid main-content-wrapper full-height");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col-xs-12 full-height");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","row");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col-xs-12 padding-medium-top padding-large-bottom highlight-row margin-medium-bottom");
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","row");
        var el10 = dom.createTextNode("\n                                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"id","lblConsensusMessage");
        dom.setAttribute(el10,"class","col-xs-12 col-md-6 margin-small-top bold margin-small-bottom");
        var el11 = dom.createTextNode("\n                                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                                    ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","col-xs-12 col-md-6 margin-medium-top");
        var el11 = dom.createTextNode("\n                                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","row");
        var el12 = dom.createTextNode("\n                                            ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("div");
        dom.setAttribute(el12,"id","lblConsensusSell");
        dom.setAttribute(el12,"class","col-xs-2 bold");
        var el13 = dom.createTextNode("\n                                                ");
        dom.appendChild(el12, el13);
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                                            ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                                            ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("div");
        dom.setAttribute(el12,"class","col-xs-8");
        var el13 = dom.createTextNode("\n                                                ");
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("div");
        dom.setAttribute(el13,"id","sliderConsensus");
        dom.setAttribute(el13,"class","row consensus-bar");
        var el14 = dom.createTextNode("\n                                                    ");
        dom.appendChild(el13, el14);
        var el14 = dom.createElement("div");
        dom.setAttribute(el14,"class","clear up-down-slider-row-1");
        var el15 = dom.createTextNode("\n                                                        ");
        dom.appendChild(el14, el15);
        var el15 = dom.createElement("div");
        dom.setAttribute(el15,"class","up-down-slider-row-2 marker-slider");
        dom.appendChild(el14, el15);
        var el15 = dom.createTextNode("\n                                                        ");
        dom.appendChild(el14, el15);
        var el15 = dom.createElement("div");
        dom.setAttribute(el15,"class","left slider-box up-down-slider-box-1");
        dom.appendChild(el14, el15);
        var el15 = dom.createTextNode("\n                                                        ");
        dom.appendChild(el14, el15);
        var el15 = dom.createElement("div");
        dom.setAttribute(el15,"class","left slider-box up-down-slider-box-2");
        dom.appendChild(el14, el15);
        var el15 = dom.createTextNode("\n                                                        ");
        dom.appendChild(el14, el15);
        var el15 = dom.createElement("div");
        dom.setAttribute(el15,"class","left slider-box up-down-slider-box-3");
        dom.appendChild(el14, el15);
        var el15 = dom.createTextNode("\n                                                        ");
        dom.appendChild(el14, el15);
        var el15 = dom.createElement("div");
        dom.setAttribute(el15,"class","left slider-box up-down-slider-box-4");
        dom.appendChild(el14, el15);
        var el15 = dom.createTextNode("\n                                                        ");
        dom.appendChild(el14, el15);
        var el15 = dom.createElement("div");
        dom.setAttribute(el15,"class","left slider-box up-down-slider-box-5");
        dom.appendChild(el14, el15);
        var el15 = dom.createTextNode("\n                                                        ");
        dom.appendChild(el14, el15);
        var el15 = dom.createElement("div");
        dom.setAttribute(el15,"class","left slider-box up-down-slider-box-6");
        dom.appendChild(el14, el15);
        var el15 = dom.createTextNode("\n                                                        ");
        dom.appendChild(el14, el15);
        var el15 = dom.createElement("div");
        dom.setAttribute(el15,"class","left slider-box up-down-slider-box-7");
        dom.appendChild(el14, el15);
        var el15 = dom.createTextNode("\n                                                        ");
        dom.appendChild(el14, el15);
        var el15 = dom.createElement("div");
        dom.setAttribute(el15,"class","left slider-box up-down-slider-box-8");
        dom.appendChild(el14, el15);
        var el15 = dom.createTextNode("\n                                                        ");
        dom.appendChild(el14, el15);
        var el15 = dom.createElement("div");
        dom.setAttribute(el15,"class","left slider-box up-down-slider-box-9");
        dom.appendChild(el14, el15);
        var el15 = dom.createTextNode("\n                                                        ");
        dom.appendChild(el14, el15);
        var el15 = dom.createElement("div");
        dom.setAttribute(el15,"class","left slider-box up-down-slider-box-10");
        dom.appendChild(el14, el15);
        var el15 = dom.createTextNode("\n                                                    ");
        dom.appendChild(el14, el15);
        dom.appendChild(el13, el14);
        var el14 = dom.createTextNode("\n                                                ");
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                                            ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                                            ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("div");
        dom.setAttribute(el12,"id","lblConsensusBuy");
        dom.setAttribute(el12,"class","col-xs-2 bold");
        var el13 = dom.createTextNode("\n                                                ");
        dom.appendChild(el12, el13);
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                                            ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                                        ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                                    ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","alt-row");
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","col-xs-12 padding-medium-top padding-medium-bottom border-top");
        var el10 = dom.createTextNode("\n                                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"id","lblMeanConsensus");
        dom.setAttribute(el10,"class","text-align-left left bold");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode(" :");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"id","meanConsensus");
        dom.setAttribute(el10,"class","text-align-right right");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","col-xs-12 padding-medium-top padding-medium-bottom border-top no-background");
        var el10 = dom.createTextNode("\n                                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"id","lblRecommendationsSummary");
        dom.setAttribute(el10,"class","text-align-left left bold");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode(" :");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"id","recommendationsSummary");
        dom.setAttribute(el10,"class","text-align-right right");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","col-xs-12 padding-medium-top padding-medium-bottom border-top");
        var el10 = dom.createTextNode("\n                                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"id","lblAvgTargetPriceSummary");
        dom.setAttribute(el10,"class","text-align-left left bold");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode(" :");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"id","avgTargetPriceSummary");
        dom.setAttribute(el10,"class","dir-ltr text-align-right right");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","col-xs-12 padding-medium-top padding-medium-bottom border-top no-background");
        var el10 = dom.createTextNode("\n                                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"id","lblUpsidePotentialSummary");
        dom.setAttribute(el10,"class","text-align-left left bold");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode(" :");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"id","upsidePotentialSummary");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","clearfix");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col-xs-12 col-sm-6 col-md-5");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","margin-medium-bottom");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","row");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","container-fluid main-content-wrapper full-height");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col-xs-12 full-height");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","row");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","alt-row");
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","col-xs-12 padding-medium-top padding-medium-bottom border-top");
        var el10 = dom.createTextNode("\n                                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"id","lblSelectDuration");
        dom.setAttribute(el10,"class","duration text-align-left left margin-small-bottom bold");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode(" :");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"id","selectDuration");
        dom.setAttribute(el10,"class","duration-select text-align-right right");
        var el11 = dom.createTextNode("\n                                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                                    ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","border-top border-box col-xs-12 padding-medium-top padding-medium-bottom");
        var el9 = dom.createTextNode("\n                                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"id","lblSelectLastPrice");
        dom.setAttribute(el9,"class","providers text-align-left left bold margin-small-bottom");
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode(" :");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"id","selectLastPrice");
        dom.setAttribute(el9,"class","provider-select text-align-right right");
        var el10 = dom.createTextNode("\n                                        ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                    ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col-xs-12 padding-medium-top padding-large-bottom border-top border-box");
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","row");
        var el10 = dom.createTextNode("\n                                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","col-xs-12 col-xsm-8 col-sm-8 col-md-8");
        var el11 = dom.createTextNode("\n                                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"id","lblShowValues");
        dom.setAttribute(el11,"class","text-align-left bold");
        var el12 = dom.createComment("");
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode(" :");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n\n                                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","margin-medium-bottom margin-medium-top");
        var el12 = dom.createTextNode("\n                                            ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("input");
        dom.setAttribute(el12,"id","radioAdjustedCA");
        dom.setAttribute(el12,"type","radio");
        dom.setAttribute(el12,"value","0");
        dom.setAttribute(el12,"name","showValues");
        dom.setAttribute(el12,"checked","");
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                                            ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"id","lblAdjustedCA");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                                        ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","margin-small-top");
        var el12 = dom.createTextNode("\n                                            ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("input");
        dom.setAttribute(el12,"id","radioAsReported");
        dom.setAttribute(el12,"type","radio");
        dom.setAttribute(el12,"value","1");
        dom.setAttribute(el12,"name","showValues");
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                                            ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"id","lblAsReported");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                                        ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                                    ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","col-xs-12 col-xsm-4 col-sm-4 col-md-4");
        var el11 = dom.createTextNode("\n                                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("button");
        dom.setAttribute(el11,"id","btnView");
        dom.setAttribute(el11,"class","primary-btn margin-large-top border-none padding-medium padding-large-left padding-large-right animation-wrap btn-width right");
        var el12 = dom.createTextNode("\n                                            ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("i");
        dom.setAttribute(el12,"class","fa fa-search scale");
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                                            ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","padding-small-left font-x-large");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                                        ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                                    ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","clearfix");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","chartView");
        dom.setAttribute(el1,"class","col-xs-12 margin-large-bottom");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","row analyst-view-tbl");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col-xs-12");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table-responsive");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("table");
        dom.setAttribute(el4,"id","tableFairValues");
        dom.setAttribute(el4,"class","touch show-scroll table table-striped table-bordered numeric-table large-tbl lg-tbl-mobile full-width no-white-space");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("thead");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("tr");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7,"id","lblRefId");
        dom.setAttribute(el7,"class","text-align-right");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7,"id","lblProvider");
        dom.setAttribute(el7,"class","text-align-left");
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("p");
        dom.setAttribute(el8,"class","text-align-center");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7,"id","lblDate");
        dom.setAttribute(el7,"class","text-align-right");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7,"id","lblChgInd");
        dom.setAttribute(el7,"class","text-align-center");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("i");
        dom.setAttribute(el8,"class","fa fa-question-circle padding-small-left cursor-pointer");
        dom.setAttribute(el8,"aria-hidden","true");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7,"id","lblRec");
        dom.setAttribute(el7,"class","text-align-left");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("i");
        dom.setAttribute(el8,"class","fa fa-question-circle padding-small-left cursor-pointer");
        dom.setAttribute(el8,"aria-hidden","true");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7,"id","lblUpsideP");
        dom.setAttribute(el7,"class","text-align-right");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("i");
        dom.setAttribute(el8,"class","fa fa-question-circle padding-small-left cursor-pointer");
        dom.setAttribute(el8,"aria-hidden","true");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7,"id","lblRep");
        dom.setAttribute(el7,"class","text-align-center");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("i");
        dom.setAttribute(el8,"class","fa fa-question-circle padding-small-left cursor-pointer");
        dom.setAttribute(el8,"aria-hidden","true");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7,"id","lblPrevRepDate");
        dom.setAttribute(el7,"class","text-align-right");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("i");
        dom.setAttribute(el8,"class","fa fa-question-circle padding-small-left cursor-pointer");
        dom.setAttribute(el8,"aria-hidden","true");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7,"id","lblPrevRec");
        dom.setAttribute(el7,"class","text-align-left");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("i");
        dom.setAttribute(el8,"class","fa fa-question-circle padding-small-left cursor-pointer");
        dom.setAttribute(el8,"aria-hidden","true");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7,"id","lblPrevTargetP");
        dom.setAttribute(el7,"class","text-align-right");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("i");
        dom.setAttribute(el8,"class","fa fa-question-circle padding-small-left cursor-pointer");
        dom.setAttribute(el8,"aria-hidden","true");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7,"id","lblPrevRep");
        dom.setAttribute(el7,"class","text-align-center");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("i");
        dom.setAttribute(el8,"class","fa fa-question-circle padding-small-left cursor-pointer");
        dom.setAttribute(el8,"aria-hidden","true");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("tbody");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("* ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, content = hooks.content, get = hooks.get, subexpr = hooks.subexpr, concat = hooks.concat, attribute = hooks.attribute, inline = hooks.inline, element = hooks.element, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element24 = dom.childAt(fragment, [0]);
        var element25 = dom.childAt(element24, [1, 1, 1, 1, 1, 1]);
        var element26 = dom.childAt(element25, [1, 1]);
        var element27 = dom.childAt(element26, [3, 1]);
        var element28 = dom.childAt(element27, [3, 1, 1, 1]);
        var element29 = dom.childAt(element25, [3]);
        var element30 = dom.childAt(element29, [1]);
        var element31 = dom.childAt(element29, [3]);
        var element32 = dom.childAt(element29, [5]);
        var element33 = dom.childAt(element29, [7]);
        var element34 = dom.childAt(element33, [3]);
        var element35 = dom.childAt(element24, [3, 1, 1, 1, 1, 1]);
        var element36 = dom.childAt(element35, [1, 1]);
        var element37 = dom.childAt(element35, [3]);
        var element38 = dom.childAt(element35, [5, 1]);
        var element39 = dom.childAt(element38, [1]);
        if (this.cachedFragment) { dom.repairClonedNode(dom.childAt(element39, [3, 1]),[],true); }
        var element40 = dom.childAt(element38, [3, 1]);
        var element41 = dom.childAt(fragment, [4, 1]);
        var element42 = dom.childAt(element41, [1, 1]);
        var element43 = dom.childAt(element42, [1, 1]);
        var element44 = dom.childAt(element43, [7]);
        var element45 = dom.childAt(element44, [1]);
        var element46 = dom.childAt(element43, [9]);
        var element47 = dom.childAt(element46, [1]);
        var element48 = dom.childAt(element43, [13]);
        var element49 = dom.childAt(element48, [1]);
        var element50 = dom.childAt(element43, [15]);
        var element51 = dom.childAt(element50, [1]);
        var element52 = dom.childAt(element43, [17]);
        var element53 = dom.childAt(element52, [1]);
        var element54 = dom.childAt(element43, [19]);
        var element55 = dom.childAt(element54, [1]);
        var element56 = dom.childAt(element43, [21]);
        var element57 = dom.childAt(element56, [1]);
        var element58 = dom.childAt(element43, [23]);
        var element59 = dom.childAt(element58, [1]);
        var morph0 = dom.createMorphAt(dom.childAt(element26, [1]),1,1);
        var morph1 = dom.createMorphAt(dom.childAt(element27, [1]),1,1);
        var attrMorph0 = dom.createAttrMorph(element28, 'style');
        var morph2 = dom.createMorphAt(dom.childAt(element27, [5]),1,1);
        var morph3 = dom.createMorphAt(dom.childAt(element30, [1]),0,0);
        var morph4 = dom.createMorphAt(dom.childAt(element30, [3]),0,0);
        var morph5 = dom.createMorphAt(dom.childAt(element31, [1]),0,0);
        var morph6 = dom.createMorphAt(dom.childAt(element31, [3]),0,0);
        var morph7 = dom.createMorphAt(dom.childAt(element32, [1]),0,0);
        var morph8 = dom.createMorphAt(dom.childAt(element32, [3]),0,0);
        var morph9 = dom.createMorphAt(dom.childAt(element33, [1]),0,0);
        var morph10 = dom.createMorphAt(element34,0,0);
        var attrMorph1 = dom.createAttrMorph(element34, 'class');
        var morph11 = dom.createMorphAt(dom.childAt(element36, [1]),0,0);
        var morph12 = dom.createMorphAt(dom.childAt(element36, [3]),1,1);
        var morph13 = dom.createMorphAt(dom.childAt(element37, [1]),0,0);
        var morph14 = dom.createMorphAt(dom.childAt(element37, [3]),1,1);
        var morph15 = dom.createMorphAt(dom.childAt(element39, [1]),0,0);
        var morph16 = dom.createMorphAt(dom.childAt(element39, [3, 3]),0,0);
        var morph17 = dom.createMorphAt(dom.childAt(element39, [5, 3]),0,0);
        var morph18 = dom.createMorphAt(dom.childAt(element40, [3]),0,0);
        var morph19 = dom.createMorphAt(dom.childAt(fragment, [2]),1,1);
        var morph20 = dom.createMorphAt(dom.childAt(element43, [3, 1]),0,0);
        var morph21 = dom.createMorphAt(dom.childAt(element43, [5]),0,0);
        var morph22 = dom.createMorphAt(element44,0,0);
        var attrMorph2 = dom.createAttrMorph(element45, 'title');
        var morph23 = dom.createMorphAt(element46,0,0);
        var attrMorph3 = dom.createAttrMorph(element47, 'title');
        var morph24 = dom.createMorphAt(element43,11,11);
        var morph25 = dom.createMorphAt(element48,0,0);
        var attrMorph4 = dom.createAttrMorph(element49, 'title');
        var morph26 = dom.createMorphAt(element50,0,0);
        var attrMorph5 = dom.createAttrMorph(element51, 'title');
        var morph27 = dom.createMorphAt(element52,0,0);
        var attrMorph6 = dom.createAttrMorph(element53, 'title');
        var morph28 = dom.createMorphAt(element54,0,0);
        var attrMorph7 = dom.createAttrMorph(element55, 'title');
        var morph29 = dom.createMorphAt(element56,0,0);
        var attrMorph8 = dom.createAttrMorph(element57, 'title');
        var morph30 = dom.createMorphAt(element58,0,0);
        var attrMorph9 = dom.createAttrMorph(element59, 'title');
        var morph31 = dom.createMorphAt(dom.childAt(element42, [3]),1,1);
        var morph32 = dom.createMorphAt(element41,3,3);
        var morph33 = dom.createMorphAt(dom.childAt(element41, [5]),1,1);
        content(env, morph0, context, "consensusMessage");
        content(env, morph1, context, "app.lang.labels.sell");
        attribute(env, attrMorph0, element28, "style", concat(env, ["width:", subexpr(env, context, "getMeanConsensusValue", [get(env, context, "filteredContent"), true], {}), "%"]));
        content(env, morph2, context, "app.lang.labels.buy");
        content(env, morph3, context, "app.lang.labels.meanConsensus");
        inline(env, morph4, context, "getRecommendation", [subexpr(env, context, "getMeanConsensusValue", [get(env, context, "filteredContent"), false], {})], {});
        content(env, morph5, context, "app.lang.labels.recommendations");
        inline(env, morph6, context, "getRecommendationsSummary", [get(env, context, "filteredContent")], {});
        content(env, morph7, context, "app.lang.labels.avgTargetPriceAdjusted");
        inline(env, morph8, context, "getAvgTargetPriceSummary", [get(env, context, "filteredContent"), get(env, context, "isAdjusted")], {});
        content(env, morph9, context, "app.lang.labels.upsidePotential");
        attribute(env, attrMorph1, element34, "class", concat(env, ["dir-ltr text-align-right right ", subexpr(env, context, "getUpDownStyle", [subexpr(env, context, "getUpsidePotentialSummary", [get(env, context, "filteredContent"), get(env, context, "stock.adjustedLtp"), get(env, context, "isAdjusted"), false], {}), "green up", "red down", ""], {})]));
        inline(env, morph10, context, "getUpsidePotentialSummary", [get(env, context, "filteredContent"), get(env, context, "stock.adjustedLtp"), get(env, context, "isAdjusted"), true], {});
        content(env, morph11, context, "app.lang.labels.duration");
        inline(env, morph12, context, "view", ["select"], {"content": get(env, context, "periodsList"), "value": get(env, context, "period"), "optionValuePath": "content.id", "optionLabelPath": "content.value", "class": "select-style-1"});
        content(env, morph13, context, "app.lang.labels.providers");
        inline(env, morph14, context, "multiselect-dropdown", [], {"options": get(env, context, "providersList"), "optionLabelPath": "provider", "optionValuePath": "id", "selectAction": "changeProviders", "nothingSelectedMessage": get(env, context, "app.lang.labels.nothingSelected"), "multipleSelectedMessage": get(env, context, "app.lang.labels.itemsSelected"), "selectAllLabel": get(env, context, "app.lang.labels.selectAll"), "selectNoneLabel": get(env, context, "app.lang.labels.deselectAll")});
        content(env, morph15, context, "app.lang.labels.showValues");
        content(env, morph16, context, "app.lang.labels.adjForCorporateActions");
        content(env, morph17, context, "app.lang.labels.asReported");
        element(env, element40, context, "action", ["applyFilters"], {});
        content(env, morph18, context, "app.lang.labels.view");
        inline(env, morph19, context, "outlet", ["chartView"], {});
        content(env, morph20, context, "app.lang.labels.provider");
        content(env, morph21, context, "app.lang.labels.date");
        content(env, morph22, context, "app.lang.labels.chgIndicator");
        attribute(env, attrMorph2, element45, "title", get(env, context, "app.lang.labels.recChangeIndicator"));
        content(env, morph23, context, "app.lang.labels.rec");
        attribute(env, attrMorph3, element47, "title", get(env, context, "app.lang.labels.recommendation"));
        block(env, morph24, context, "if", [get(env, context, "isAdjusted")], {}, child0, child1);
        content(env, morph25, context, "app.lang.labels.upsideP");
        attribute(env, attrMorph4, element49, "title", get(env, context, "app.lang.labels.curUpsidePotential"));
        content(env, morph26, context, "app.lang.labels.rep");
        attribute(env, attrMorph5, element51, "title", get(env, context, "app.lang.labels.report"));
        content(env, morph27, context, "app.lang.labels.prevRepDate");
        attribute(env, attrMorph6, element53, "title", get(env, context, "app.lang.labels.prevReportDate"));
        content(env, morph28, context, "app.lang.labels.prevRec");
        attribute(env, attrMorph7, element55, "title", get(env, context, "app.lang.labels.prevRecommendation"));
        content(env, morph29, context, "app.lang.labels.prevTargetP");
        attribute(env, attrMorph8, element57, "title", get(env, context, "app.lang.labels.prevTargetPrice"));
        content(env, morph30, context, "app.lang.labels.prevRep");
        attribute(env, attrMorph9, element59, "title", get(env, context, "app.lang.labels.prevReport"));
        block(env, morph31, context, "each", [get(env, context, "filteredContent")], {"keyword": "item"}, child2, null);
        inline(env, morph32, context, "single-message-viewer", [], {"message": get(env, context, "errorMessage"), "showMessage": get(env, context, "showError")});
        content(env, morph33, context, "app.lang.labels.indicateValuesAdjusted");
        return fragment;
      }
    };
  }()));

});