define('ir-app/models/price/price-data-stores/time-and-sales-data-store', ['exports', 'ember', 'ir-app/models/price/price-business-entities/trade', 'ir-app/utils/utils'], function (exports, Ember, trade, utils) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        tradesMapBySymbolExchange: {},
        lastTradeMapByKey: {},

        getNewTrade: function getNewTrade(exchange, symbol) {
            var key = utils['default'].keyGenerator.getKey(exchange, symbol);
            var currentStore = this.get('tradesMapBySymbolExchange');
            var tradeObj = trade['default'].create({ sym: symbol, exg: exchange });
            var arrTrades = currentStore[key];

            if (!arrTrades) {
                arrTrades = Ember['default'].A();
                currentStore[key] = arrTrades;
            }

            arrTrades.pushObject(tradeObj);
            return tradeObj;
        },

        getLastTrade: function getLastTrade(exchange, symbol) {
            var key = utils['default'].keyGenerator.getKey(exchange, symbol);
            var lastTradeStore = this.get('lastTradeMapByKey');
            return lastTradeStore[key];
        },

        setLastTrade: function setLastTrade(exchange, symbol, tradeObj) {
            var key = utils['default'].keyGenerator.getKey(exchange, symbol);
            var lastTradeStore = this.get('lastTradeMapByKey');
            var lastTrade = lastTradeStore[key];

            if (!lastTrade) {
                lastTrade = tradeObj;
                lastTradeStore[key] = lastTrade;
            }

            if (lastTrade.seq <= tradeObj.seq) {
                lastTradeStore[key] = tradeObj;
            }
        },

        getTradeCollection: function getTradeCollection(exchange, symbol) {
            var key = utils['default'].keyGenerator.getKey(exchange, symbol);
            var currentStore = this.get('tradesMapBySymbolExchange');
            var tradeCollection = currentStore[key];

            if (!tradeCollection) {
                tradeCollection = Ember['default'].A([]);
                currentStore[key] = tradeCollection;
            }

            return tradeCollection;
        },

        joinBacklogAndRealtimeTrades: function joinBacklogAndRealtimeTrades(exchange, symbol, backlogLastTrade) {
            var key = utils['default'].keyGenerator.getKey(exchange, symbol);
            var currentStore = this.get('tradesMapBySymbolExchange');
            var tradeCollection = currentStore[key];

            if (tradeCollection) {
                if (backlogLastTrade.seq <= tradeCollection[0].seq) {
                    tradeCollection[0].setTradeTick(backlogLastTrade.trp);
                }
            }
        },

        removeTradeCollection: function removeTradeCollection(exchange, symbol) {
            var key = utils['default'].keyGenerator.getKey(exchange, symbol);
            var currentStore = this.get('tradesMapBySymbolExchange');

            if (currentStore) {
                currentStore[key] = undefined;
            }
        }
    }).create();

});