define('ir-app/controllers/ir-widgets/monitoring-widgets/monitoring-widget', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-constants', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service'], function (exports, Ember, BaseController, priceConstants, priceService, sharedService) {

    'use strict';

    exports['default'] = BaseController['default'].extend({
        exchange: 'TDWL',
        symbol: '1010',
        keys: [{ exg: "TDWL", sym: "4240" }, { exg: "TDWL", sym: "4230" }, { exg: "CASE", sym: "ALEX" }, { exg: "TDWL", sym: "1120" }, { exg: "CASE", sym: "OLFI" }, { exg: "DFM", sym: "MASQ" }, { exg: "CASE", sym: "DOMT" }, { exg: "TDWL", sym: "8290" }, { exg: "TDWL", sym: "6002" }, { exg: "TDWL", sym: "7020" }, { exg: "CASE", sym: "PORT" }, { exg: "CASE", sym: "BTFH" }, { exg: "TDWL", sym: "4110" }, { exg: "CASE", sym: "HELI" }, { exg: "DFM", sym: "DIB" }],
        indexKeys: [{ exg: "TDWL", sym: "TASI" }, { exg: "DFM", sym: "DFMGI" }, { exg: "CASE", sym: "EGX30" }, { exg: "DSM", sym: "GNRI" }, { exg: "BSE", sym: "BSEX" }],
        annId: "",
        companyId: [{ sym: '21' }, { sym: '53' }, { sym: '490' }],
        idxb: '',
        idxbVal: '',
        idxs: '',
        idxsVal: '',
        title: "",
        searchKey: 1010,
        currPair: "SARUSD",
        instrumentType: '0',
        dataType: 'Q',
        isCal: "1",
        rt131Level: '5',
        rt131mode: 'X',
        refreshTime: 10000,
        errRefreshTime: 3000,
        compProfStatus: 0,
        compManagementStatus: 0,
        annStatus: 0,
        getExgStatus: 0,
        getSymValStatus: 0,
        intradayOHLCDataStatus: 0,
        paramsSymbolMasterForExchangeStatus: 0,
        fundamentalDataRequestStatus: 0,
        majorSharhoderRequestStatus: 0,
        ratioComparisonRequestStatus: 0,
        currencyRequestStatus: 0,
        newsAnnBodyRequestStatus: 0,
        paramsExchangeMetaDataStatus: 0,
        realTimeDataIdx: 0,
        realTimeIndexDataIdx: 0,
        realTimeIndexMonitor: true,
        indexMonitors: false,
        requests: [],
        recAnnId: false,
        rt25Request: {
            type: '25',
            name: 'Announcement Requst',
            status: 0,
            symbol: '',
            data: '',
            index: 0,
            check: '',
            error: false
        },
        rt32Request: {
            type: '32',
            name: 'CompanyProfile Service',
            status: 0,
            symbol: '',
            data: '',
            index: 0,
            check: '',
            error: false
        },
        rt32MRequest: {
            type: '32M',
            name: 'Company Managment',
            status: 0,
            symbol: '',
            data: '',
            index: 0,
            check: '',
            error: false
        },
        rt50Request: {
            type: '50',
            name: 'Exchange request',
            status: 0,
            symbol: '',
            data: '',
            index: 0,
            check: '',
            error: false
        },
        rt52Request: {
            type: '52',
            name: 'Symbol Validation',
            status: 0,
            symbol: '',
            data: '',
            index: 0,
            check: '',
            error: false
        },
        rt37Request: {
            type: '37',
            name: 'Intraday OHLC Data',
            status: 0,
            symbol: '',
            data: '',
            index: 0,
            check: '',
            error: false
        },
        rt303Request: {
            type: '303',
            name: 'Symbol Request',
            status: 0,
            symbol: '',
            data: '',
            index: 0,
            check: '',
            error: false
        },
        rt131Request: {
            type: '131',
            name: 'Fundamental Data',
            status: 0,
            symbol: '',
            data: '',
            index: 0,
            check: '',
            error: false
        },
        rt30Request: {
            type: '30',
            name: 'Major Sharhoder',
            status: 0,
            symbol: '',
            data: '',
            index: 0,
            check: '',
            error: false
        },
        rt601Request: {
            type: '601',
            name: 'Ratio Comparison',
            status: 0,
            symbol: '',
            data: '',
            index: 0,
            check: '',
            error: false
        },
        rt16Request: {
            type: '16',
            name: 'Currency request',
            status: 0,
            symbol: '',
            data: '',
            index: 0,
            check: '',
            error: false
        },
        rt26Request: {
            type: '26',
            name: 'NewsAnnBody request',
            status: 0,
            symbol: '',
            data: '',
            index: 0,
            check: '',
            error: false
        },
        rt302Request: {
            type: '302',
            name: 'Exchange Meta Data',
            status: 0,
            symbol: '',
            data: '',
            index: 0,
            check: '',
            error: false
        },
        exgArray: [{}],
        exchngList: [],
        stockObj: '',
        indexObj: '',
        realTimeReq: false,
        realTimeDataSym: '',
        realTimeIndxReq: false,
        realTimeIndxDataSym: '',
        DFMstockObj: '',
        DFMexchangeStatus: '',
        pageSize: priceConstants['default'].AnnouncementSearchPageSize,

        onPrepareData: function onPrepareData() {
            this.requests.pushObject(this.get('rt25Request'));
            this.requests.pushObject(this.get('rt32Request'));
            this.requests.pushObject(this.get('rt32MRequest'));
            this.requests.pushObject(this.get('rt50Request'));
            this.requests.pushObject(this.get('rt52Request'));
            this.requests.pushObject(this.get('rt37Request'));
            this.requests.pushObject(this.get('rt303Request'));
            this.requests.pushObject(this.get('rt131Request'));
            this.requests.pushObject(this.get('rt30Request'));
            this.requests.pushObject(this.get('rt601Request'));
            this.requests.pushObject(this.get('rt16Request'));
            this.requests.pushObject(this.get('rt26Request'));
            this.requests.pushObject(this.get('rt302Request'));

            var keylist = this.get('keys');
            var exgList = [];
            var i = 0;
            var that = this;
            Ember['default'].$.each(keylist, function (idx, key) {
                var exchng = key.exg;
                if (Ember['default'].$.inArray(exchng, exgList) === -1) {
                    exgList[i] = exchng;
                    i++;
                    var exchangeObj = priceService['default'].exchangeDS.getExchange(exchng);
                    priceService['default'].addExchangeRequest(exchng);
                    Ember['default'].set(that.get('exgArray'), exchng, exchangeObj);
                }
                that.set('exgList', exgList);
            });
            this.realTimeDataMonitor();

            if (this.get('realTimeIndexMonitor')) {
                this.realTimeIndexDataMonitor();
            }
            this.commoditBrentOilyRequest();
            this.commoditSilveryRequest();

            if (this.get('indexMonitors')) {
                this.dfmMonitor();
                this.tdwlMonitor();
                this.qatarMonitor();
                this.egyptMonitor();
                this.bseMonitor();
                this.kseMonitor();
            }

            var requests = this.get('requests');
            //send RT requests for rt types from arguments

            if (!this.get('scrapingOnly')) {
                Ember['default'].$.each(requests, function (key, request) {

                    switch (request.type) {
                        case '32':
                            that.companyProfileServiceRequest();
                            break;
                        case '32M':
                            that.companyManagmentRequest();
                            break;
                        case '50':
                            that.exchangeRequest();
                            break;
                        case '25':
                            that.announcementServiceRequest();
                            break;
                        case '52':
                            that.symbolValidationRequest();
                            break;
                        case '37':
                            that.intradayOHLCData();
                            break;
                        case '303':
                            that.paramsSymbolMasterForExchange();
                            break;
                        case '131':
                            that.fundamentalDataRequest();
                            break;
                        case '30':
                            that.majorSharhoderRequest();
                            break;
                        case '601':
                            that.ratioComparisonRequest();
                            break;
                        case '16':
                            that.currencyRequest();
                            break;
                        case '26':
                            that.newsAnnBodyRequest();
                            break;
                        case '302':
                            that.paramsExchangeMetaData();
                            break;
                        default:
                    }
                });
            }
        },

        onAfterRender: function onAfterRender() {
            var that = this;
            if (this.get('indexMonitors')) {
                var iframe = document.getElementById('iframe');
                iframe.onload = function () {
                    that.dfmgiMonitor();
                };
                var iframe2 = document.getElementById('iframe2');
                iframe2.onload = function () {
                    that.tasiMonitor();
                };
                var iframe3 = document.getElementById('iframe3');
                iframe3.onload = function () {
                    that.qeMonitor();
                };
                var iframe4 = document.getElementById('iframe4');
                iframe4.onload = function () {
                    that.egxMonitor();
                };
                var iframe5 = document.getElementById('iframe5');
                iframe5.onload = function () {
                    that.bhbMonitor();
                };
                // var iframe6 = document.getElementById('iframe6');
                // iframe6.onload = function () {
                //     that.kuwaitMonitor();
                // };
            }
        },

        announcementServiceRequest: function announcementServiceRequest() {
            window.localStorage.clear();
            var symList = this.get('keys');
            var key = symList[this.get('rt25Request.index')];
            var that = this;
            that.set('rt25Request.symbol', key);
            priceService['default'].sendAnnouncementSearchRequest({
                exchange: key.exg,
                symbol: key.sym,
                pageSize: that.get('pageSize'),
                startDate: undefined,
                endDate: undefined,
                reqSuccessFn: function reqSuccessFn() {
                    that.set('rt25Request.index', that.get('rt25Request.index') + 1);
                    if (that.get('rt25Request.index') === that.get('keys').length) {
                        that.set('rt25Request.index', 0);
                    }
                    var symbolAnnouncement = priceService['default'].announcementDS.getAnnCollectionBySymbol(key.exg, key.sym);
                    that.set('rt25Request.check', "Data ");

                    if (symbolAnnouncement.length) {
                        if (!that.get('recAnnId')) {
                            that.set('annId', symbolAnnouncement[0].id);
                            that.set('recAnnId', true);
                        }
                        that.set('rt25Request.status', 1);
                        that.set('rt25Request.error', false);
                    } else {
                        that.set('rt25Request.status', -1);
                        that.set('rt25Request.error', true);
                    }
                    setTimeout(function () {
                        that.announcementServiceRequest();
                    }, that.get('refreshTime'));
                },
                reqFailureFn: function reqFailureFn() {
                    if (that.get('exgArray')[key.exg].stat === 1 ? false : true) {
                        that.set('rt25Request.status', 0);
                        setTimeout(function () {
                            that.announcementServiceRequest();
                        }, that.get('errRefreshTime'));
                    }
                }
            }, priceService['default'].announcementDS.getAnnCollectionBySymbol(key.exg, key.sym));
        },

        companyProfileServiceRequest: function companyProfileServiceRequest() {
            window.localStorage.clear();
            var symList = this.get('keys');
            var key = symList[this.get('rt32Request.index')];
            var that = this;
            that.set('rt32Request.symbol', key);
            priceService['default'].sendCompanyProfileRequest(key.exg, key.sym, sharedService['default'].userSettings.currentLanguage, function () {
                that.set('rt32Request.index', that.get('rt32Request.index') + 1);
                if (that.get('rt32Request.index') === that.get('keys').length) {
                    that.set('rt32Request.index', 0);
                }
                var companyProfObj = priceService['default'].companyProfileDS.getStoredObject(key.exg, key.sym, sharedService['default'].userSettings.currentLanguage);

                var errField = '';
                var chkArray = ["compName"];
                Ember['default'].$.each(chkArray, function (idx, field) {
                    if (companyProfObj[field]) {
                        that.set('rt32Request.status', 1);
                        that.set('rt32Request.error', false);
                    } else {
                        errField += field + " ";
                        that.set('rt32Request.check', errField);
                        that.set('rt32Request.status', -1);
                        that.set('rt32Request.error', true);
                    }
                });
                setTimeout(function () {
                    that.companyProfileServiceRequest();
                }, that.get('refreshTime'));
            }, function () {
                if (that.get('exgArray')[key.exg].stat === 1 ? false : true) {
                    that.set('rt32Request.status', 0);
                    setTimeout(function () {
                        that.companyProfileServiceRequest();
                    }, that.get('errRefreshTime'));
                }
            }, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyBasicInfo);
        },

        companyManagmentRequest: function companyManagmentRequest() {
            window.localStorage.clear();
            var symList = this.get('keys');
            var key = symList[this.get('rt32MRequest.index')];
            var that = this;
            that.set('rt32MRequest.symbol', key);
            priceService['default'].sendCompanyMgmntRequest(key.exg, key.sym, sharedService['default'].userSettings.currentLanguage, function () {
                that.set('rt32MRequest.index', that.get('rt32MRequest.index') + 1);
                if (that.get('rt32MRequest.index') === that.get('keys').length) {
                    that.set('rt32MRequest.index', 0);
                }
                var chkArray = ["compManagement", "compOwners", "compKeyOfficers"];
                var managementInfo = priceService['default'].companyProfileDS.getStoredObject(key.exg, key.sym, sharedService['default'].userSettings.currentLanguage, function () {}, function () {}, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyManagementInfo);

                var errField = '';

                Ember['default'].$.each(chkArray, function (idx, field) {
                    if (managementInfo[field].length) {
                        that.set('rt32MRequest.status', 1);
                        that.set('rt32MRequest.error', false);
                    } else {
                        errField += field + " ";
                        that.set('rt32MRequest.check', errField);
                        that.set('rt32MRequest.status', -1);
                        that.set('rt32MRequest.error', true);
                    }
                });

                setTimeout(function () {
                    that.companyManagmentRequest();
                }, that.get('refreshTime'));
            }, function () {
                if (that.get('exgArray')[key.exg].stat === 1 ? false : true) {
                    that.set('rt32MRequest.status', 0);
                    setTimeout(function () {
                        that.companyManagmentRequest();
                    }, that.get('errRefreshTime'));
                }
            });
        },

        exchangeRequest: function exchangeRequest() {
            window.localStorage.clear();
            var that = this;
            var symList = this.get('keys');
            var key = symList[this.get('rt50Request.index')];
            that.set('rt50Request.symbol', key);
            priceService['default'].downloadExchangeProperty(key.exg, sharedService['default'].userSettings.currentLanguage, function () {
                that.set('rt50Request.index', that.get('rt50Request.index') + 1);
                if (that.get('rt50Request.index') === that.get('keys').length) {
                    that.set('rt50Request.index', 0);
                }
                var exchange = priceService['default'].exchangeDS.getExchange(key.exg);

                var chkArray = ["des"];
                var errField = '';
                Ember['default'].$.each(chkArray, function (idx, field) {
                    if (exchange[field]) {
                        that.set('rt50Request.status', 1);
                    } else {
                        that.set('rt50Request.status', -1);
                        errField += field + " ";
                    }
                });
                that.set('rt50Request.check', errField);
                setTimeout(function () {
                    that.exchangeRequest();
                }, that.get('refreshTime'));
            }, function () {
                if (that.get('exgArray')[key.exg].stat === 1 ? false : true) {
                    that.set('rt50Request.status', 0);
                    setTimeout(function () {
                        that.exchangeRequest();
                    }, 15000);
                }
            });
        },

        symbolValidationRequest: function symbolValidationRequest() {
            window.localStorage.clear();
            var that = this;
            var symList = this.get('keys');
            var key = symList[this.get('rt52Request.index')];
            that.set('rt52Request.symbol', key);
            priceService['default'].sendSymbolValidationRequest(key.exg, key.sym, sharedService['default'].userSettings.currentLanguage, function () {
                that.set('rt52Request.index', that.get('rt52Request.index') + 1);
                if (that.get('rt52Request.index') === that.get('keys').length) {
                    that.set('rt52Request.index', 0);
                }
                var stock = priceService['default'].stockDS.getStock(key.exg, key.sym);
                var chkArray = ["exg"];
                var errField = '';
                Ember['default'].$.each(chkArray, function (idx, field) {
                    if (stock[field]) {
                        that.set('rt52Request.status', 1);
                        that.set('rt52Request.error', false);
                    } else {
                        errField += field + " ";
                        that.set('rt52Request.check', errField);
                        that.set('rt52Request.status', -1);
                        that.set('rt52Request.error', true);
                    }
                });
                setTimeout(function () {
                    that.symbolValidationRequest();
                }, that.get('refreshTime'));
            }, function () {
                if (that.get('exgArray')[key.exg].stat === 1 ? false : true) {
                    that.set('rt52Request.status', 0);
                    setTimeout(function () {
                        that.symbolValidationRequest();
                    }, that.get('errRefreshTime'));
                }
            });
        },

        intradayOHLCData: function intradayOHLCData() {
            window.localStorage.clear();
            var that = this;
            var symList = this.get('keys');
            var key = symList[this.get('rt37Request.index')];
            that.set('rt37Request.symbol', key);
            priceService['default'].downloadIntradayOHLCData({
                exchange: key.exg,
                symbol: key.sym,
                chartDataLevel: 1,
                begin: undefined,

                reqSuccessFn: function reqSuccessFn() {
                    that.set('rt37Request.index', that.get('rt37Request.index') + 1);
                    if (that.get('rt37Request.index') === that.get('keys').length) {
                        that.set('rt37Request.index', 0);
                    }
                    var ohlcSeries = priceService['default'].ohlcDS.getOHLCSeries(key.exg, key.sym, 1);
                    var chkArray = ["exg"];
                    var errField = '';
                    Ember['default'].$.each(chkArray, function (idx, field) {
                        if (ohlcSeries[field]) {
                            that.set('rt37Request.status', 1);
                            that.set('rt37Request.error', false);
                        } else {
                            errField += field + " ";
                            that.set('rt37Request.check', errField);
                            that.set('rt37Request.status', -1);
                            that.set('rt37Request.error', true);
                        }
                    });
                    setTimeout(function () {
                        that.intradayOHLCData();
                    }, that.get('refreshTime'));
                },

                reqFailureFn: function reqFailureFn() {
                    if (that.get('exgArray')[key.exg].stat === 1 ? false : true) {
                        that.set('rt37Request.status', 0);
                        setTimeout(function () {
                            that.intradayOHLCData();
                        }, that.get('errRefreshTime'));
                    }
                }
            });
        },

        paramsSymbolMasterForExchange: function paramsSymbolMasterForExchange() {
            window.localStorage.clear();
            var that = this;
            var symList = this.get('keys');
            var key = symList[this.get('rt303Request.index')];
            that.set('rt303Request.symbol', key);
            priceService['default'].downloadParamsSymbolMasterForExchange({
                exchange: key.exg,
                language: sharedService['default'].userSettings.currentLanguage,
                successFn: function successFn() {

                    that.set('rt303Request.index', that.get('rt303Request.index') + 1);
                    if (that.get('rt303Request.index') === that.get('keys').length) {
                        that.set('rt303Request.index', 0);
                    }
                    var indxColl = priceService['default'].stockDS.getIndexCollectionForExchange(key.exg);

                    if (indxColl.length) {
                        that.set('rt303Request.status', 1);
                        that.set('rt303Request.error', false);
                    } else {
                        that.set('rt303Request.check', "Data ");
                        that.set('rt303Request.status', -1);
                        that.set('rt303Request.error', true);
                    }

                    setTimeout(function () {
                        that.paramsSymbolMasterForExchange();
                    }, that.get('refreshTime'));
                },
                errorFn: function errorFn() {
                    if (that.get('exgArray')[key.exg].stat === 1 ? false : true) {
                        that.set('rt303Request.status', 0);
                        setTimeout(function () {
                            that.paramsSymbolMasterForExchange();
                        }, that.get('errRefreshTime'));
                    }
                }
            });
        },

        fundamentalDataRequest: function fundamentalDataRequest() {
            window.localStorage.clear();
            var that = this;
            var symList = this.get('keys');
            var key = symList[this.get('rt131Request.index')];
            that.set('rt131Request.symbol', key);
            priceService['default'].sendFundamentalDataRequest(key.exg, key.sym, sharedService['default'].userSettings.currentLanguage, that.get('rt131mode'), function () {
                that.set('rt131Request.status', 1);
                that.set('rt131Request.index', that.get('rt131Request.index') + 1);
                if (that.get('rt131Request.index') === that.get('keys').length) {
                    that.set('rt131Request.index', 0);
                }
                //        var fundamentalData = priceService.advanceFundamentalDS.getDataObject(key.exg, key.sym, 'IS', 'A', 0);
                setTimeout(function () {
                    that.fundamentalDataRequest();
                }, that.get('refreshTime'));
            }, function () {
                if (that.get('exgArray')[key.exg].stat === 1 ? false : true) {
                    that.set('rt131Request.status', 0);
                    setTimeout(function () {
                        that.fundamentalDataRequest();
                    }, that.get('errRefreshTime'));
                }
            }, that.get('rt131Level'), that.get('isCal'));
        },

        majorSharhoderRequest: function majorSharhoderRequest() {
            window.localStorage.clear();
            var that = this;
            var idList = this.get('companyId');
            var key = idList[this.get('rt30Request.index')];
            that.set('rt30Request.symbol', key);
            priceService['default'].sendMajorSharhoderRequest(key.sym, sharedService['default'].userSettings.currentLanguage, function () {
                that.set('rt30Request.status', 1);
                that.set('rt30Request.index', that.get('rt30Request.index') + 1);
                if (that.get('rt30Request.index') === that.get('companyId').length) {
                    that.set('rt30Request.index', 0);
                }
                setTimeout(function () {
                    that.majorSharhoderRequest();
                }, that.get('refreshTime'));
            }, function () {
                that.set('rt30Request.status', 0);
                setTimeout(function () {
                    that.majorSharhoderRequest();
                }, that.get('errRefreshTime'));
            });
        },

        ratioComparisonRequest: function ratioComparisonRequest() {
            window.localStorage.clear();
            var that = this;
            var symList = this.get('keys');
            var key = symList[this.get('rt601Request.index')];
            that.set('rt601Request.symbol', key);
            priceService['default'].sendRatioComparisonRequest(key.exg, key.sym, that.get('instrumentType'), that.get('dataType'), function () {
                that.set('rt601Request.index', that.get('rt601Request.index') + 1);
                if (that.get('rt601Request.index') === that.get('keys').length) {
                    that.set('rt601Request.index', 0);
                }
                var ratioObj = priceService['default'].ratioComparisonDS.getStoredObject(key.exg, key.sym, that.get('instrumentType'), that.get('dataType'), function () {}, function () {});
                var chkArray = ["sym"];
                var errField = '';
                Ember['default'].$.each(chkArray, function (idx, field) {
                    if (ratioObj[field]) {
                        that.set('rt601Request.status', 1);
                        that.set('rt601Request.error', false);
                    } else {
                        errField += field + " ";
                        that.set('rt601Request.check', errField);
                        that.set('rt601Request.status', -1);
                        that.set('rt601Request.error', true);
                    }
                });
                setTimeout(function () {
                    that.ratioComparisonRequest();
                }, that.get('refreshTime'));
            }, function () {
                if (that.get('exgArray')[key.exg].stat === 1 ? false : true) {
                    that.set('rt601Request.status', 0);
                    setTimeout(function () {
                        that.ratioComparisonRequest();
                    }, that.get('errRefreshTime'));
                }
            });
        },

        currencyRequest: function currencyRequest() {
            window.localStorage.clear();
            var that = this;
            priceService['default'].sendCurrencyRequest(that.get('currPair'), function () {
                var applicableCur = priceService['default'].currencyDS.getCurrency(that.get('currPair'));
                if (applicableCur.pairCode) {
                    that.set('rt16Request.status', 1);
                } else {
                    that.set('rt16Request.status', -1);
                }
                setTimeout(function () {
                    that.currencyRequest();
                }, that.get('refreshTime'));
            }, function () {
                that.set('rt16Request.status', 0);
                setTimeout(function () {
                    that.currencyRequest();
                }, that.get('errRefreshTime'));
            });
        },
        newsAnnBodyRequest: function newsAnnBodyRequest() {
            window.localStorage.clear();
            var that = this;
            priceService['default'].sendNewsAnnBodyRequest({
                id: that.get('annId'),
                type: 11,
                reqSuccessFn: function reqSuccessFn() {
                    that.set('rt26Request.status', 1);
                    setTimeout(function () {
                        that.newsAnnBodyRequest();
                    }, that.get('refreshTime'));
                },
                reqFailureFn: function reqFailureFn() {
                    that.set('rt26Request.status', 0);
                    setTimeout(function () {
                        that.newsAnnBodyRequest();
                    }, that.get('errRefreshTime'));
                },
                lan: sharedService['default'].userSettings.currentLanguage
            });
        },
        paramsExchangeMetaData: function paramsExchangeMetaData() {
            window.localStorage.clear();
            var that = this;
            var symList = this.get('keys');
            var key = symList[this.get('rt302Request.index')];
            that.set('rt302Request.symbol', key);
            priceService['default'].downloadParamsExchangeMetaData({
                exchange: key.exg,
                language: sharedService['default'].userSettings.currentLanguage,
                successFn: function successFn() {
                    that.set('rt302Request.index', that.get('rt302Request.index') + 1);
                    if (that.get('rt302Request.index') === that.get('keys').length) {
                        that.set('rt302Request.index', 0);
                    }
                    var exgParams = priceService['default'].exchangeDS.getExchange(key.exg);

                    var chkArray = ["des"];
                    var errField = '';
                    Ember['default'].$.each(chkArray, function (idx, field) {
                        if (exgParams[field]) {
                            that.set('rt302Request.status', 1);
                            that.set('rt302Request.error', false);
                        } else {
                            errField += field + " ";
                            that.set('rt302Request.check', errField);
                            that.set('rt302Request.status', -1);
                            that.set('rt302Request.error', true);
                        }
                    });
                    setTimeout(function () {
                        that.paramsExchangeMetaData();
                    }, that.get('refreshTime'));
                },
                errorFn: function errorFn() {
                    if (that.get('exgArray')[key.exg].stat === 1 ? false : true) {
                        that.set('rt302Request.status', 0);
                        setTimeout(function () {
                            that.paramsExchangeMetaData();
                        }, that.get('errRefreshTime'));
                    }
                }
            });
        },

        realTimeDataMonitor: function realTimeDataMonitor() {
            var symList = this.get('keys');
            var key = symList[this.get('realTimeDataIdx')];
            var stockFromStore = priceService['default'].stockDS.getStock(key.exg, key.sym);
            this.set('realTimeDataSym', key);
            priceService['default'].addSymbolRequest(key.exg, key.sym);
            this.set('stockObj', stockFromStore);
            var that = this;

            setTimeout(function () {
                if (that.get('stockObj').prvCls > 0 && that.get('stockObj').per) {
                    that.set('realTimeReq', true);
                    that.set('realTimeDataIdx', that.get('realTimeDataIdx') + 1);
                    if (that.get('realTimeDataIdx') === that.get('keys').length) {
                        that.set('realTimeDataIdx', 0);
                    }
                } else {
                    that.set('realTimeReq', false);
                }
                that.clearStock(key.exg, key.sym);
                that.realTimeDataMonitor();
            }, 10000);
        },

        realTimeIndexDataMonitor: function realTimeIndexDataMonitor() {
            var symList = this.get('indexKeys');
            var key = symList[this.get('realTimeIndexDataIdx')];
            var stockFromStore = priceService['default'].stockDS.getStock(key.exg, key.sym);
            this.set('realTimeIndxDataSym', key);
            priceService['default'].addIndexRequest(key.exg, key.sym);
            this.set('indexObj', stockFromStore);
            var that = this;

            setTimeout(function () {
                if (that.get('indexObj').prvCls && that.get('indexObj').per && that.get('indexObj').dYield) {
                    that.set('realTimeIndxReq', true);
                    that.set('realTimeIndexDataIdx', that.get('realTimeIndexDataIdx') + 1);
                    if (that.get('realTimeIndexDataIdx') === that.get('indexKeys').length) {
                        that.set('realTimeIndexDataIdx', 0);
                    }
                } else {
                    that.set('realTimeIndxReq', false);
                }
                that.clearIndex(key.exg, key.sym);
                that.realTimeIndexDataMonitor();
            }, 40000);
        },

        // check Brent oil price
        commoditBrentOilyRequest: (function () {
            var that = this;
            var exg = "GLOBAL";
            var indx = "EBROUSDBR.SP";
            that.set('idxbVal', priceService['default'].stockDS.getStock(exg, indx));
            priceService['default'].addSymbolRequest(exg, indx);
            if (this.get('idxbVal').bbp < 40) {
                this.set('idxb', -1);
            } else {
                this.set('idxb', 1);
            }
        }).observes('idxbVal', 'idxbVal.bbp'),

        // check Silver price
        commoditSilveryRequest: (function () {
            var that = this;
            var exg = "GLOBAL";
            var indx = "SXAGUSDOZ.SP";
            that.set('idxsVal', priceService['default'].stockDS.getStock(exg, indx));
            priceService['default'].addSymbolRequest(exg, indx);
            if (this.get('idxsVal').bbp < 10) {
                this.set('idxs', -1);
            } else {
                this.set('idxs', 1);
            }
        }).observes('idxsVal.bbp'),

        clearStock: function clearStock(exg, sym) {
            window.localStorage.clear();
            priceService['default'].stockDS.clearStock(exg, sym);
            priceService['default'].removeSymbolRequest(exg, sym);
        },

        clearIndex: function clearIndex(exg, sym) {
            window.localStorage.clear();
            priceService['default'].stockDS.clearStock(exg, sym);
            priceService['default'].removeIndexRequest(exg, sym);
        },

        onClearData: function onClearData() {
            var exgList = this.get('exchngList');
            Ember['default'].$.each(exgList, function (idx, key) {
                priceService['default'].removeExchangeRequest(key);
            });
        },

        dfmMonitor: (function () {

            var that = this;
            var exg = "DFM";
            var indx = "DFMGI";
            that.set('DFMexchangeStatus', priceService['default'].exchangeDS.getExchange(exg));
            priceService['default'].addExchangeRequest(exg);
            that.set('DFMstockObj', priceService['default'].stockDS.getStock(exg, indx));
            priceService['default'].addIndexRequest(exg, indx);
        }).observes('DFMstockObj', 'DFMstockObj.ltp', 'DFMexchangeStatus'),

        dfmgiMonitor: function dfmgiMonitor() {
            var that = this;
            setTimeout(function () {
                var dfmValue = that.get('DFMstockObj').ltp;
                var dfmVal = parseFloat(dfmValue);
                var dfmStat = that.get('DFMexchangeStatus').stat;
                var fromIndex = document.getElementById("iframe").contentDocument.getElementsByClassName('index')[0].innerText;
                fromIndex = fromIndex.replace(/\,/g, '');
                var valFromIndex = parseFloat(fromIndex);
                document.getElementById("dfmMon2").innerText = "Website";
                if (dfmStat === 2) {
                    if (valFromIndex === dfmVal || valFromIndex >= dfmVal - 5 && valFromIndex <= dfmVal + 5) {
                        document.getElementById("dfmMonValue1").innerText = dfmVal;
                        document.getElementById("dfmMonValue2").innerText = valFromIndex;

                        Ember['default'].$('#dfmMonitor').removeClass().addClass('mat-card green');
                    } else {
                        document.getElementById("dfmMonValue1").innerText = dfmVal;
                        document.getElementById("dfmMonValue2").innerText = valFromIndex;

                        Ember['default'].$('#dfmMonitor').removeClass().addClass('mat-card yellow');
                    }
                } else {
                    if (valFromIndex === dfmVal) {
                        document.getElementById("dfmMonValue1").innerText = dfmVal;
                        document.getElementById("dfmMonValue2").innerText = valFromIndex;

                        Ember['default'].$('#dfmMonitor').removeClass().addClass('mat-card green');
                    } else {
                        document.getElementById("dfmMonValue1").innerText = dfmVal;
                        document.getElementById("dfmMonValue2").innerText = valFromIndex;

                        Ember['default'].$('#dfmMonitor').removeClass().addClass('mat-card red');
                    }
                }

                setTimeout(function () {
                    var iframe = document.getElementById('iframe');
                    iframe.contentWindow.location.reload();
                    iframe.onload = function () {
                        that.dfmgiMonitor();
                    };
                }, 40000);
            }, 15000);
        },

        tdwlMonitor: (function () {

            var that = this;
            var exg = "TDWL";
            var indx = "TASI";
            that.set('TDWLexchangeStatus', priceService['default'].exchangeDS.getExchange(exg));
            priceService['default'].addExchangeRequest(exg);
            that.set('TDWLstockObj', priceService['default'].stockDS.getStock(exg, indx));
            priceService['default'].addIndexRequest(exg, indx);
        }).observes('TDWLstockObj', 'TDWLstockObj.ltp', 'TDWLexchangeStatus'),

        tasiMonitor: function tasiMonitor() {
            var that = this;

            var tdwlValue = that.get('TDWLstockObj').ltp;
            var tdwlVal = parseFloat(tdwlValue);
            var tdwlStat = that.get('TDWLexchangeStatus').stat;
            var fromIndex = document.getElementById("iframe2").contentDocument.getElementById('ns_Z7_NHLCH082K0TNF0AQVOCBMEKU45_tasiIndexPrice').innerText;
            fromIndex = fromIndex.replace(/\,/g, '');
            var valFromIndex = parseFloat(fromIndex);
            document.getElementById("tasiMon2").innerText = "Website";
            if (tdwlStat === 2) {
                if (valFromIndex === tdwlVal || valFromIndex >= tdwlVal - 5 && valFromIndex <= tdwlVal + 5) {
                    document.getElementById("tasiMonValue1").innerText = tdwlVal;
                    document.getElementById("tasiMonValue2").innerText = valFromIndex;

                    Ember['default'].$('#tasiMonitor').removeClass().addClass('mat-card green');
                } else {
                    document.getElementById("tasiMonValue1").innerText = tdwlVal;
                    document.getElementById("tasiMonValue2").innerText = valFromIndex;

                    Ember['default'].$('#tasiMonitor').removeClass().addClass('mat-card yellow');
                }
            } else {
                if (valFromIndex === tdwlVal) {
                    document.getElementById("tasiMonValue1").innerText = tdwlVal;
                    document.getElementById("tasiMonValue2").innerText = valFromIndex;

                    Ember['default'].$('#tasiMonitor').removeClass().addClass('mat-card green');
                } else {
                    document.getElementById("tasiMonValue1").innerText = tdwlVal;
                    document.getElementById("tasiMonValue2").innerText = valFromIndex;

                    Ember['default'].$('#tasiMonitor').removeClass().addClass('mat-card red');
                }
            }
            setTimeout(function () {
                var iframe2 = document.getElementById('iframe2');
                iframe2.contentWindow.location.reload();
                iframe2.onload = function () {
                    that.tasiMonitor();
                };
            }, 40000);
        },

        qatarMonitor: (function () {
            var that = this;
            var exg = "DSM";
            var indx = "GNRI";
            that.set('qatarExchangeStatus', priceService['default'].exchangeDS.getExchange(exg));
            priceService['default'].addExchangeRequest(exg);
            that.set('qatarStockObj', priceService['default'].stockDS.getStock(exg, indx));
            priceService['default'].addIndexRequest(exg, indx);
        }).observes('qatarStockObj', 'qatarStockObj.ltp', 'qatarExchangeStatus'),

        qeMonitor: function qeMonitor() {
            var that = this;
            var qeValue = that.get('qatarStockObj').ltp;
            var qeVal = parseFloat(qeValue);
            var qeStat = that.get('qatarExchangeStatus').stat;
            var fromIndex = document.getElementById("iframe3").contentDocument.getElementById('qeNLSLastPrice').innerText;
            fromIndex = fromIndex.replace(/\,/g, '');
            var valFromIndex = parseFloat(fromIndex);
            document.getElementById("qeMon2").innerText = "Website";
            if (qeStat === 2) {
                if (valFromIndex === qeVal || valFromIndex >= qeVal - 5 && valFromIndex <= qeVal + 5) {
                    document.getElementById("qeMonValue1").innerText = qeVal;
                    document.getElementById("qeMonValue2").innerText = valFromIndex;

                    Ember['default'].$('#qeMonitor').removeClass().addClass('mat-card green');
                } else {
                    document.getElementById("qeMonValue1").innerText = qeVal;
                    document.getElementById("qeMonValue2").innerText = valFromIndex;

                    Ember['default'].$('#qeMonitor').removeClass().addClass('mat-card yellow');
                }
            } else {
                if (valFromIndex === qeVal) {
                    document.getElementById("qeMonValue1").innerText = qeVal;
                    document.getElementById("qeMonValue2").innerText = valFromIndex;

                    Ember['default'].$('#qeMonitor').removeClass().addClass('mat-card green');
                } else {
                    document.getElementById("qeMonValue1").innerText = qeVal;
                    document.getElementById("qeMonValue2").innerText = valFromIndex;

                    Ember['default'].$('#qeMonitor').removeClass().addClass('mat-card red');
                }
            }
            setTimeout(function () {
                var iframe3 = document.getElementById('iframe3');
                iframe3.contentWindow.location.reload();
                iframe3.onload = function () {
                    that.qeMonitor();
                };
            }, 40000);
        },

        egyptMonitor: (function () {
            var that = this;
            var exg = "CASE";
            var indx = "EGX30";
            that.set('egyptExchangeStatus', priceService['default'].exchangeDS.getExchange(exg));
            priceService['default'].addExchangeRequest(exg);
            that.set('egyptStockObj', priceService['default'].stockDS.getStock(exg, indx));
            priceService['default'].addIndexRequest(exg, indx);
        }).observes('egyptStockObj', 'egyptStockObj.ltp', 'egyptExchangeStatus'),

        egxMonitor: function egxMonitor() {
            var that = this;
            var egxValue = that.get('egyptStockObj').ltp;
            var egxVal = parseFloat(egxValue);
            var egxStat = that.get('egyptExchangeStatus').stat;
            var fromIndex = document.getElementById("iframe4").contentDocument.getElementById('ctl00_C_M_lblvalueIndex').innerText;
            fromIndex = fromIndex.replace(/\,/g, '');
            var valFromIndex = parseFloat(fromIndex);
            document.getElementById("egxMon2").innerText = "Website";
            if (egxStat === 2) {
                if (valFromIndex === egxVal || valFromIndex >= egxVal - 5 && valFromIndex <= egxVal + 5) {
                    document.getElementById("egxMonValue1").innerText = egxVal;
                    document.getElementById("egxMonValue2").innerText = valFromIndex;

                    Ember['default'].$('#egxMonitor').removeClass().addClass('mat-card green');
                } else {
                    document.getElementById("egxMonValue1").innerText = egxVal;
                    document.getElementById("egxMonValue2").innerText = valFromIndex;

                    Ember['default'].$('#egxMonitor').removeClass().addClass('mat-card yellow');
                }
            } else {
                if (valFromIndex === egxVal) {
                    document.getElementById("egxMonValue1").innerText = egxVal;
                    document.getElementById("egxMonValue2").innerText = valFromIndex;

                    Ember['default'].$('#egxMonitor').removeClass().addClass('mat-card green');
                } else {
                    document.getElementById("egxMonValue1").innerText = egxVal;
                    document.getElementById("egxMonValue2").innerText = valFromIndex;

                    Ember['default'].$('#egxMonitor').removeClass().addClass('mat-card red');
                }
            }
            setTimeout(function () {
                var iframe4 = document.getElementById('iframe4');
                iframe4.contentWindow.location.reload();
                iframe4.onload = function () {
                    that.egxMonitor();
                };
            }, 40000);
        },

        bseMonitor: (function () {
            var that = this;
            var exg = "BSE";
            var indx = "BSEX";
            that.set('bseExchangeStatus', priceService['default'].exchangeDS.getExchange(exg));
            priceService['default'].addExchangeRequest(exg);
            that.set('bseStockObj', priceService['default'].stockDS.getStock(exg, indx));
            priceService['default'].addIndexRequest(exg, indx);
        }).observes('bseStockObj', 'bseStockObj.ltp', 'bseExchangeStatus'),

        bhbMonitor: function bhbMonitor() {
            var that = this;
            var bhbValue = that.get('bseStockObj').ltp;
            var bhbVal = parseFloat(bhbValue);
            var bhbStat = that.get('bseExchangeStatus').stat;
            var fromIndex = document.getElementById("iframe5").contentDocument.getElementById('spnCurrIndexValue').innerText;
            fromIndex = fromIndex.replace(/\,/g, '');
            var valFromIndex = parseFloat(fromIndex);
            document.getElementById("bhbMon2").innerText = "Website";
            if (bhbStat === 2) {
                if (valFromIndex === bhbVal || valFromIndex >= bhbVal - 5 && valFromIndex <= bhbVal + 5) {
                    document.getElementById("bhbMonValue1").innerText = bhbVal;
                    document.getElementById("bhbMonValue2").innerText = valFromIndex;

                    Ember['default'].$('#bhbMonitor').removeClass().addClass('mat-card green');
                } else {
                    document.getElementById("bhbMonValue1").innerText = bhbVal;
                    document.getElementById("bhbMonValue2").innerText = valFromIndex;

                    Ember['default'].$('#bhbMonitor').removeClass().addClass('mat-card yellow');
                }
            } else {
                if (valFromIndex === bhbVal || valFromIndex >= bhbVal - 0.1 && valFromIndex <= bhbVal + 0.1) {
                    document.getElementById("bhbMonValue1").innerText = bhbVal;
                    document.getElementById("bhbMonValue2").innerText = valFromIndex;

                    Ember['default'].$('#bhbMonitor').removeClass().addClass('mat-card green');
                } else {
                    document.getElementById("bhbMonValue1").innerText = bhbVal;
                    document.getElementById("bhbMonValue2").innerText = valFromIndex;

                    Ember['default'].$('#bhbMonitor').removeClass().addClass('mat-card red');
                }
            }
            setTimeout(function () {
                var iframe5 = document.getElementById('iframe5');
                iframe5.contentWindow.location.reload();
                iframe5.onload = function () {
                    that.bhbMonitor();
                };
            }, 40000);
        },

        kseMonitor: (function () {
            var that = this;
            var exg = "KSE";
            var indx = "11";
            that.set('kseExchangeStatus', priceService['default'].exchangeDS.getExchange(exg));
            priceService['default'].addExchangeRequest(exg);
            that.set('kseStockObj', priceService['default'].stockDS.getStock(exg, indx));
            priceService['default'].addIndexRequest(exg, indx);
        }).observes('kseStockObj', 'kseStockObj.ltp', 'kseExchangeStatus'),

        kuwaitMonitor: function kuwaitMonitor() {
            var that = this;
            var kseValue = that.get('kseStockObj').ltp;
            var kseVal = parseFloat(kseValue);
            var kseStat = that.get('kseExchangeStatus').stat;
            var fromIndex = document.getElementById("iframe6").contentDocument.getElementById('ContentMatter_PriceIndexLBL').innerText;
            fromIndex = fromIndex.replace(/\,/g, '');
            var valFromIndex = parseFloat(fromIndex);
            document.getElementById("kseMon2").innerText = "Website";
            if (kseStat === 2) {
                if (valFromIndex === kseVal || valFromIndex >= kseVal - 5 && valFromIndex <= kseVal + 5) {
                    document.getElementById("kseMonValue1").innerText = kseVal;
                    document.getElementById("kseMonValue2").innerText = valFromIndex;

                    Ember['default'].$('#kseMonitor').removeClass().addClass('mat-card green');
                } else {
                    document.getElementById("kseMonValue1").innerText = kseVal;
                    document.getElementById("kseMonValue2").innerText = valFromIndex;

                    Ember['default'].$('#kseMonitor').removeClass().addClass('mat-card yellow');
                }
            } else {
                if (valFromIndex === kseVal || valFromIndex >= kseVal - 0.1 && valFromIndex <= kseVal + 0.1) {
                    document.getElementById("kseMonValue1").innerText = kseVal;
                    document.getElementById("kseMonValue2").innerText = valFromIndex;

                    Ember['default'].$('#kseMonitor').removeClass().addClass('mat-card green');
                } else {
                    document.getElementById("kseMonValue1").innerText = kseVal;
                    document.getElementById("kseMonValue2").innerText = valFromIndex;

                    Ember['default'].$('#kseMonitor').removeClass().addClass('mat-card red');
                }
            }
            setTimeout(function () {
                var iframe6 = document.getElementById('iframe6');
                iframe6.contentWindow.location.reload();
                iframe6.onload = function () {
                    that.kuwaitMonitor();
                };
            }, 40000);
        }

    });

});