define('ir-app/utils/image-helper', ['exports', 'ir-app/utils/utils'], function (exports, utils) {

    'use strict';

    exports['default'] = (function () {
        var getImage = function getImage(d) {
            var html = '';

            try {
                var o = [],
                    count = 0,
                    str;

                for (var i = 0; i < d.length; i = i + 2) {
                    o[count] = parseInt(d.substring(i, i + 2), 16);
                    count++;
                }

                str = String.fromCharCode.apply(null, o);
                html = '<img src="data:image/png;base64,' + btoa(str).replace(/.{76}(?=.)/g, '$&\n') + '">';
            } catch (x) {
                utils['default'].logger.logError('Error in image converting : ' + x);
            }

            return html;
        };

        return {
            getImage: getImage
        };
    })();

});