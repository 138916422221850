define('ir-app/models/price/price-business-entities/stock', ['exports', 'ember', 'ir-app/utils/utils', 'ir-app/models/price/price-service'], function (exports, Ember, utils, priceService) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        sym: '', // Symbol
        symStat: '0', // Symbol suspended status
        lDes: '', // Long Description
        sDes: '', // Short Description
        disSym: '', // Display Symbol
        dSym: '', // Display Symbol
        exg: '', // Exchange
        inst: 0, // Instrument Type
        ast: '', // Asset Type
        cid: '', // Company Id
        sec: '', // Sector Code
        subMkt: '', // Sub Market Code
        open: 0, // Open
        high: 0, // High
        low: 0, // Low
        cls: '', // Close
        chg: 0, // Change
        pctChg: 0, // Percentage Change
        prvCls: 0, // Previous Close
        tovr: 0, // TurnOver
        vol: 0, // Volume
        trades: 0, // No Of Trades
        cur: '', // Currency
        ltp: 0, // Last Traded Price - need to add a related logic
        ltq: '', // Last Traded Quantity
        bap: 0, // Best Ask Price
        baq: 0, // Best Ask Qty
        bbp: 0, // Best Bid Price
        bbq: 0, // Best Bid Qty
        tbq: '', // Total Bid Quantity
        taq: '', // Total Ask Quantity
        h52: 0, // 52 Weeks High
        l52: 0, // 52 Weeks Low
        per: '', // P / E Ratio
        pbr: '', // P / B Ratio
        eps: '', // Earnings Per Share
        yld: '', // Yield
        lot: 0, // Lot Size
        uSym: '', // Underline Symbol (for options)
        stkP: '', // Strike Price
        expDt: '', // Expire Date
        deci: 2, // Decimal Places
        dcf: '', // Decimal Correction Factor
        refValue: '', // Ref Value - This is there in RIA, Do we need this.
        vwap: '', // VWAP
        rng: '', // Range
        pctRng: '', // Percentage Range
        cshMp: '', // Cash Map
        mktCap: '0', // Market Cap
        mktCapChg: '', // Market Cap Change
        pctYtd: '', // Percentage Year To Date
        cit: 0, // Cash In Turn Over
        cot: 0, // Cash Out Turnover
        civ: 0, // Cash In Volume
        cov: 0, // Cash Out Volume
        min: 0, // Min Price
        max: 0, // Max Price
        cor: 0, // Coupon Rate
        cof: 0, // Coupon frequency
        pcd: 0, // Previous Coupon Date
        fVal: 0, // Face Value,
        bor: 0, // Bid/Offer Ratio
        oInt: 0, // Open Interest
        oIntC: 0, // Open Interest Change%
        div: 0, // Dividend
        edd: '', // Ex-Dividend Date
        sp: 0, // Settlement Price
        matD: '', // Maturity Date
        boT: 0, // Bond Type
        outA: 0, // Outstanding Amount
        setD: 0, // Settlement Date
        dcm: 0, // Day Count Method
        isMainIdx: false, // IsMainIndex (Index type)
        lstShares: 0, // Listed Shares
        lAnn: null, // Top Announcement reference
        ann: '', // Top Announcement header
        dcfs: '', // Distressed  company flag
        cvwap: '', // Closing VWAP
        intsV: '', // Intrinsic value
        twap: '', // TWAP
        beta: 0, // Beta Value
        prevD: '', // Previous Trade Date
        prevT: '', // Previous Trade Time
        tzo: 0, // Timezone set from an exchange obj

        tsr: '', // Ticker Serial
        pctChgW: 0, // 1 Week Percentage change - Use for Mobile IR
        pctChgM: 0, // 1 Month Percentage change - Use for Mobile IR
        pctChg3M: 0, // 3 Months Percentage change - Use for Mobile IR
        pctChgY: 0, // 1 Year Percentage change - Use for Mobile IR
        chgY: 0, // 1 Year change - Use for Mobile IR
        avgVol30D: 0, // 30Day Average Volume - Use for Mobile IR
        avgTovr30D: 0, // 30Day Average Turnover - Use for Mobile IR
        avgValue3M: 0, // 3Months Average Value - Use for Portals
        avgVolume3M: 0, // 3Months Average Volume - Use for Portals
        dYield: 0, // Dividend Yield Use for Portals,
        prTTM: '', // PayoutRatioTTM
        tac: '0', // Total Authorized Capital
        ltt: '', //Last Trade Time
        lutt: '', // Last updated trade time , it comes in yyyymmddhhmmss
        dateInMilSec: 0, // Last Date in milliseconds (use for chart last candle).
        avgTxVal30D: 0, // 30Day Average Transaction Value - Used for Decypha Mobile
        dyrpct: 0,

        suspended: (function () {
            var symStat = this.get('symStat');

            return symStat === '3' || symStat === '2';
        }).property('symStat'), // suspension status boolean

        is52WeekHigh: (function () {
            return this.get('high') >= this.get('h52') && this.get('high') > 0 && this.get('h52') > 0;
        }).property('high', 'h52'), // Reached 52 week high value

        is52WeekLow: (function () {
            return this.get('low') <= this.get('l52') && this.get('low') > 0 && this.get('l52') > 0;
        }).property('low', 'l52'), //  Reached 52 week low value

        isTodayHigh: (function () {
            return this.get('ltp') >= this.get('max') && this.get('ltp') > 0 && this.get('max') > 0;
        }).property('ltp', 'max'), //  Reached today's low value

        isTodayLow: (function () {
            return this.get('ltp') <= this.get('min') && this.get('ltp') > 0 && this.get('min') > 0;
        }).property('ltp', 'min'), //  Reached today's low value

        key: (function () {
            return this.get('sym') + utils['default'].Constants.StringConst.Tilde + this.get('exg');
        }).property('sym', 'exg'), // Unique key to identify stock uniquely.

        dExg: (function () {
            return this.get('exg');
        }).property('exg'), // Display Exchange - This need to collect from the correct repository.

        dPrevT: (function () {
            var time = this.get('prevT');
            var offSet = this.get('tzo');
            var displayTime = utils['default'].formatters.formatToTime(time, offSet); // This 3 should be replaced with relevant exchange time zone offset

            return displayTime;
        }).property('prevT'), // Display Time */

        bar: (function () {
            var tbq, taq, val;

            tbq = this.get('tbq');
            taq = this.get('taq');

            if (tbq === 0 && taq === 0) {
                val = '';
            } else if (tbq === 0) {
                val = 0;
            } else if (taq === 0) {
                val = 0;
            } else {
                val = tbq / taq;
            }

            return val;
        }).property('tbq', 'taq'), // Bid Ask Ratio

        sprdPer: (function () {
            var bbp = this.get('bbp');
            var bap = this.get('bap');
            var ltp = this.get('ltp');
            var spreadPer;

            if (bbp > 0 && bap > 0 && ltp > 0) {
                var diff = bap - bbp;

                if (diff > 0) {
                    spreadPer = (bap - bbp) / ltp * 100;
                }
            }

            return spreadPer;
        }).property('bbp', 'bap'), // Spread

        trend: (function () {
            // Trend
            var change = this.get('chg');
            return change > 0 ? 1 : change < 0 ? -1 : 0;
        }).property('chg'),

        init: function init() {
            this._super();
        },

        mktCapMillion: (function () {
            // Market Cap
            var mktCap = this.get('mktCap');
            var exg = this.get('exg');

            // Below is a temp solution applied for Al-Arabia until the issue with KSE market cap are fixed at DM level.
            // Currently it is flown after multiplying by 1000. So we divide it here
            if (exg === 'KSE') {
                return mktCap / (1000000 * 1000);
            }

            return mktCap / 1000000;
        }).property('mktCap', 'exg'),

        idxMktCapMillion: (function () {
            // Market Cap
            var mktCap = this.get('mktCap');
            return mktCap / 1000000;
        }).property('mktCap', 'exg'),

        idxAuthCapMillion: (function () {
            // Market Cap
            var mktCap = this.get('tac');
            return mktCap / 1000000;
        }).property('mktCap', 'exg'),

        setData: function setData(symbolMessage) {
            var that = this;

            Ember['default'].$.each(symbolMessage, function (key, value) {
                that.set(key, value);
            });
        },

        adjustedLtp: (function () {
            var ltp = this.get('ltp');
            var prvCls = this.get('prvCls');

            if (ltp === 0 && prvCls > 0) {
                return prvCls;
            }

            return ltp;
        }).property('ltp', 'prvCls'),

        filteredAdjustedLtp: (function () {
            return this.adjustCurrencyUnit(this.get('exg'), this.get('adjustedLtp'));
        }).property('adjustedLtp'),

        filteredOpen: (function () {
            return this.adjustCurrencyUnit(this.get('exg'), this.get('open'));
        }).property('open'),

        filteredPrvCls: (function () {
            return this.adjustCurrencyUnit(this.get('exg'), this.get('prvCls'));
        }).property('prvCls'),

        filteredChg: (function () {
            return this.adjustCurrencyUnit(this.get('exg'), this.get('chg'));
        }).property('chg'),

        filteredChgY: (function () {
            return this.adjustCurrencyUnit(this.get('exg'), this.get('chgY'));
        }).property('chgY'),

        filteredDeci: (function () {
            var exg = this.get('exg');
            var deci = this.get('deci');
            if (exg === 'KSE') {
                return deci < 0 ? 3 : deci + 3;
            }
            return deci;
        }).property('deci'),

        filteredHigh: (function () {
            return this.adjustCurrencyUnit(this.get('exg'), this.get('high'));
        }).property('high'),

        filteredLow: (function () {
            return this.adjustCurrencyUnit(this.get('exg'), this.get('low'));
        }).property('low'),

        filteredL52: (function () {
            return this.adjustCurrencyUnit(this.get('exg'), this.get('l52'));
        }).property('l52'),

        filteredH52: (function () {
            return this.adjustCurrencyUnit(this.get('exg'), this.get('h52'));
        }).property('h52'),

        filteredVwap: (function () {
            return this.adjustCurrencyUnit(this.get('exg'), this.get('vwap'));
        }).property('vwap'),

        adjustCurrencyUnit: function adjustCurrencyUnit(exchange, price) {
            return exchange === 'KSE' ? price / 1000 : price;
        },

        dDYield: (function () {
            var tmpDiv = this.get('dYield');
            var exg = this.get('exg');

            // div. yield value cannot display as 0 : It should be '--' when the value is 0
            if (tmpDiv === '0') {
                tmpDiv = '';
                return tmpDiv;
            }

            // Below is a temp solution applied for Al-Arabia until the issue with KSE div. yields are fixed at DM level.
            // Currently it is flown after multiplying by 1000
            if (exg === 'KSE') {
                return tmpDiv / 1000;
            }

            return tmpDiv;
        }).property('dYield', 'exg'),

        adjustedEps: (function () {
            var tmpEps = this.get('eps');
            var exg = this.get('exg');

            return exg === 'KSE' ? tmpEps * 1000 : tmpEps;
        }).property('eps', 'exg'),

        dateInMilliSeconds: (function () {
            var date;
            if (this.get('lutt')) {
                date = utils['default'].formatters.convertStringToDate(this.get('lutt'));
                this.set('dateInMilSec', date.getTime());
            } else {
                date = utils['default'].formatters.convertStringToDate(priceService['default'].exchangeDS.getExchange(this.get('exg')).date);
                this.set('dateInMilSec', date.getTime());
            }
        }).observes('lutt')

    });

});