define('ir-app/controllers/ir-mobile/quote-widgets/side-bar-quote', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/components/stk-specific-components/portal-range-display-slider', 'ir-app/models/shared/shared-service', 'ir-app/models/price/price-business-entities/panel-field', 'ir-app/models/price/price-constants', 'ir-app/utils/utils'], function (exports, Ember, BaseController, priceService, PortalRangeSlider, sharedService, panelField, priceConstants, utils) {

    'use strict';

    exports['default'] = BaseController['default'].extend({
        // TODO: [ASANKA] remove basicinfo from this controller once DividendYield issue is fixed from the BE.
        stock: {},
        basicInfo: {},
        panelFields: Ember['default'].A(),
        showTrailing12MonthMsg: true,
        showTodayRange: true,
        show52WkRange: true,
        newStkOvw: false,

        // Default set of fields. This can be overriden via the config
        displayFields: [{ lanKey: 'open', dataField: 'filteredOpen', formatter: 'C' }, { lanKey: 'preClosed', dataField: 'filteredPrvCls', formatter: 'C' }, { lanKey: 'volume', dataField: 'vol', formatter: 'L' }, { lanKey: 'turnover', dataField: 'tovr', formatter: 'L' }, { lanKey: 'noOfTrades', dataField: 'trades', formatter: 'L' }, { lanKey: 'avgVol30Days', dataField: 'avgVol30D', formatter: 'I' }, { lanKey: 'avgTovr30Days', dataField: 'avgTovr30D', formatter: 'I' }, { lanKey: 'beta', dataField: 'beta', formatter: 'R' }, { lanKey: 'mktCapMillion', dataField: 'mktCapMillion', formatter: 'L' }, { lanKey: 'dividendYield', dataField: 'dyrpct', formatter: 'P', suffix: ' *' }, //oldvalue was  'dDYield'
        { lanKey: 'eps', dataField: 'adjustedEps', formatter: 'R', suffix: ' *' }, //oldvalue was  'eps'
        { lanKey: 'peRatio', dataField: 'per', formatter: 'R', suffix: ' *' }],

        onPrepareData: function onPrepareData() {
            var that = this;
            var basicInfo = this.get('basicInfo');
            if (this.utils.validators.isAvailable(this.get('companyId'))) {
                basicInfo = priceService['default'].companyProfileDS.getStoredObjectByCompanyId(this.get('companyId'), sharedService['default'].userSettings.currentLanguage, function () {
                    var _basicInfo = priceService['default'].companyProfileDS.getStoredObjectByCompanyId(that.get('companyId'), sharedService['default'].userSettings.currentLanguage);
                    that.set('sym', _basicInfo.sym);
                    that.set('exg', _basicInfo.exg);
                    that._onPrepareData();
                }, function () {}, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyBasicInfo);
            } else {
                basicInfo = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, function () {}, function () {}, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyBasicInfo);
                this._onPrepareData();
            }
            this.set('basicInfo', basicInfo);
        },

        _onPrepareData: function _onPrepareData() {
            this.set('displayFields', this.get('displayFields'));
            this.set('showTrailing12MonthMsg', this.get('showTrailing12MonthMsg'));
            this.set('panelFields', Ember['default'].A());

            var stock = priceService['default'].stockDS.getStock(this.get('exg'), this.get('sym'));
            this.set('stock', stock);

            this.renderPanelFields();
            priceService['default'].sendSymbolValidationRequest(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, undefined, undefined);
            priceService['default'].addSymbolRequest(this.get('exg'), this.get('sym'));
        },

        onClearData: function onClearData() {
            priceService['default'].removeSymbolRequest(this.get('exg'), this.get('sym'));
        },

        renderPanelFields: function renderPanelFields() {
            try {
                var stockObj = this.get('stock');
                var fields = this.get('displayFields');
                var panelFieldsRef = this.get('panelFields');

                var lanStore = this.get('app').lang.labels;
                var that = this;

                if (fields && panelFieldsRef) {
                    Ember['default'].$.each(fields, function (key, value) {
                        var fieldObj = panelField['default'].create({
                            lanKey: lanStore[value.lanKey] ? lanStore[value.lanKey] : value.lanKey,
                            suffix: value['suffix'] ? value['suffix'] : '',
                            detailQouteTitle: lanStore[value.detailQouteTitle] ? lanStore[value.detailQouteTitle] : value.detailQouteTitle,
                            style: value.style || ''
                        });

                        fieldObj.valueObj = stockObj;
                        fieldObj.value = stockObj.get(value.dataField);
                        fieldObj.field = value.dataField;

                        if (value.decimalPlaces) {
                            fieldObj.numOfDeci = value.decimalPlaces;
                        } else {
                            fieldObj.numOfDeci = stockObj.get('filteredDeci');
                        }

                        switch (value.formatter) {
                            case that.utils.Constants.DataFormatter.Currency:
                                fieldObj.isCurrency = true;
                                break;
                            case that.utils.Constants.DataFormatter.Long:
                                fieldObj.isLong = true;
                                break;
                            case that.utils.Constants.DataFormatter.Integer:
                                fieldObj.isInteger = true;
                                break;
                            case that.utils.Constants.DataFormatter.String:
                                fieldObj.isString = true;
                                break;
                            case that.utils.Constants.DataFormatter.Date:
                                fieldObj.isDate = true;
                                break;
                            case that.utils.Constants.DataFormatter.Percentage:
                                fieldObj.isPercentage = true;
                                break;
                            case that.utils.Constants.DataFormatter.DivideNumber:
                                fieldObj.isDivideNumber = true;
                                break;
                            case that.utils.Constants.DataFormatter.Ratio:
                                fieldObj.isRatio = true;
                                break;
                        }

                        Ember['default'].addObserver(stockObj, value.dataField, fieldObj, fieldObj.notifyChange);

                        panelFieldsRef.pushObject(fieldObj);
                    });
                }
            } catch (x) {
                this.utils.logger.logError('Error in rendering detail quote panel fields : ' + x);
            }
        },

        // ---------- TODO: [ASANKA] remove below after Dividend Yield is fixed -----------------------------

        infoChg: (function () {
            if (utils['default'].validators.isAvailable(this.get('basicInfo').compName) && !utils['default'].validators.isAvailable(this.get('basicInfo').cashDivTTM) && this.get('stock') !== undefined) {
                Ember['default'].set(this.get('stock'), 'dDYield', '');
            }
        }).observes('basicInfo.cashDivTTM'),

        priceChg: (function () {
            if (utils['default'].validators.isAvailable(this.get('basicInfo').compName) && !utils['default'].validators.isAvailable(this.get('basicInfo').cashDivTTM)) {
                Ember['default'].set(this.get('stock'), 'dDYield', '');
            }
        }).observes('stock.dDYield')

        // --------------------------------------------------------------------------------------------------

    });

    Ember['default'].Handlebars.helper('portal-range-display-slider', PortalRangeSlider['default']);
    Ember['default'].Handlebars.helper('getPositiveNegativeStyle', function (value) {
        if (value >= 0) {
            return 'padding-medium snap-period-wrapper-green';
        } else {
            return 'padding-medium snap-period-wrapper-red';
        }
    });

});