define('ir-app/templates/ir-mobile/quote-widgets/top-bar-quote', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"id","symbolLabel");
            dom.setAttribute(el1,"class","bold left snap-font-color-2");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" : ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"id","symbol");
            dom.setAttribute(el1,"class","snap-font-color-1");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, content = hooks.content;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            var morph1 = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
            content(env, morph0, context, "app.lang.labels.symbol");
            content(env, morph1, context, "stock.sDes");
            return fragment;
          }
        };
      }());
      var child1 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"id","symbolLabel");
            dom.setAttribute(el1,"class","bold left snap-font-color-2");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" : ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"id","symbol");
            dom.setAttribute(el1,"class","snap-font-color-1");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, content = hooks.content;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            var morph1 = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
            content(env, morph0, context, "app.lang.labels.symbol");
            content(env, morph1, context, "stock.sym");
            return fragment;
          }
        };
      }());
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","top-bar-href");
          dom.setAttribute(el1,"target","_parent");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","mkt-snap-wrapper full-width margin-large-bottom margin-large-top");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","row");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col-xs-12 col-sm-3 col-lg-3");
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","col-xs-12 col-sm-12");
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("h1");
          dom.setAttribute(el6,"id","compName");
          dom.setAttribute(el6,"class","snap-company-name text-align-left margin-small-bottom left");
          var el7 = dom.createElement("span");
          dom.setAttribute(el7,"class","name-span");
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                            ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("span");
          dom.setAttribute(el7,"id","topBarAcumFlag");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n\n                    ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","col-xs-12 col-sm-12");
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","snap-note text-align-left margin-small-bottom margin-medium-top");
          var el7 = dom.createTextNode("\n\n                            ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          dom.setAttribute(el7,"id","lastUpdatedLabel");
          dom.setAttribute(el7,"class","display-block left");
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode(" :");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                            ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          dom.setAttribute(el7,"id","lastUpdatedDate");
          dom.setAttribute(el7,"class","display-block left");
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode(" ");
          dom.appendChild(el7, el8);
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col-xs-12 col-sm-6 col-lg-6");
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","row");
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","col-xs-12 full-width display-table");
          var el7 = dom.createTextNode("\n                            ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          dom.setAttribute(el7,"class","col-xs-12 col-sm-4 col-lg-4 no-padding display-table");
          var el8 = dom.createTextNode("\n                                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("div");
          dom.setAttribute(el8,"class","col-xs-4 display-table-cell float-none vertical-bottom fig-row text-align-left no-padding");
          var el9 = dom.createTextNode("\n                                    ");
          dom.appendChild(el8, el9);
          var el9 = dom.createElement("span");
          dom.setAttribute(el9,"class","snap-current-price-currency-label");
          var el10 = dom.createTextNode("Share Price");
          dom.appendChild(el9, el10);
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode("\n                                    ");
          dom.appendChild(el8, el9);
          var el9 = dom.createElement("span");
          dom.setAttribute(el9,"id","lastTradePrice");
          dom.setAttribute(el9,"class","snap-current-price");
          var el10 = dom.createComment("");
          dom.appendChild(el9, el10);
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode("\n                                    ");
          dom.appendChild(el8, el9);
          var el9 = dom.createElement("span");
          dom.setAttribute(el9,"id","currency");
          dom.setAttribute(el9,"class","snap-current-price-currency");
          var el10 = dom.createComment("");
          dom.appendChild(el9, el10);
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode("\n                                ");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                              ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                            ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          dom.setAttribute(el7,"class","col-xs-12 col-sm-8 col-lg-8 display-table");
          var el8 = dom.createTextNode("\n                                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("div");
          dom.setAttribute(el8,"class","col-xs-4 display-table-cell float-none vertical-bottom fig-row dir-ltr text-align-left no-padding");
          var el9 = dom.createTextNode("\n                                    ");
          dom.appendChild(el8, el9);
          var el9 = dom.createElement("span");
          dom.setAttribute(el9,"id","change");
          var el10 = dom.createComment("");
          dom.appendChild(el9, el10);
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode("\n                                ");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("div");
          dom.setAttribute(el8,"class","col-xs-4 display-table-cell float-none vertical-bottom fig-row dir-ltr text-align-left no-padding");
          var el9 = dom.createTextNode("\n                                    ");
          dom.appendChild(el8, el9);
          var el9 = dom.createElement("span");
          dom.setAttribute(el9,"id","pctChange");
          var el10 = dom.createComment("");
          dom.appendChild(el9, el10);
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode("\n                                ");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                            ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col-xs-12 col-sm-3 col-lg-3 mini-chart");
          dom.setAttribute(el4,"style","margin-top:20px;");
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"id","chartView");
          dom.setAttribute(el5,"class","col-xs-12 chart-widget-mini");
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","col-xs-12 margin-medium-top margin-small-bottom");
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","row");
          var el7 = dom.createTextNode("\n                            ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          dom.setAttribute(el7,"id","oneYearLabel");
          dom.setAttribute(el7,"class","col-xs-3 text-align-left");
          var el8 = dom.createTextNode("1Y");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                            ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          dom.setAttribute(el7,"id","chartChgY");
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                            ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          dom.setAttribute(el7,"id","chartPctChgY");
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","horizontal-sep-line margin-medium-top margin-medium-bottom");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","row row-fig");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col-xs-12");
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","col-xs-12 col-sm-2 v-sep margin-large-bottom");
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","text-align-left margin-small-top left");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("                        ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","col-xs-12 col-sm-4 col-cust-3 v-sep margin-large-bottom");
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","text-align-left margin-small-top left");
          var el7 = dom.createTextNode("\n                            ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("span");
          dom.setAttribute(el7,"id","isinLabel");
          dom.setAttribute(el7,"class","bold left snap-font-color-2");
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" : ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("span");
          dom.setAttribute(el7,"id","isin");
          dom.setAttribute(el7,"class","snap-font-color-1");
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","col-xs-12 col-sm-3 col-cust-4 v-sep margin-large-bottom");
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","text-align-left margin-small-top left");
          var el7 = dom.createTextNode("\n                            ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("span");
          dom.setAttribute(el7,"id","exchangeLabel");
          dom.setAttribute(el7,"class","bold left snap-font-color-2");
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" : ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("span");
          dom.setAttribute(el7,"id","exchange");
          dom.setAttribute(el7,"class","snap-font-color-1");
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","col-xs-12 col-sm-3 v-sep margin-large-bottom");
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","text-align-left margin-small-top left");
          var el7 = dom.createTextNode("\n                            ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("span");
          dom.setAttribute(el7,"id","sectorLabel");
          dom.setAttribute(el7,"class","bold left snap-font-color-2");
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" : ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("span");
          dom.setAttribute(el7,"id","sector");
          dom.setAttribute(el7,"class","snap-font-color-1");
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, subexpr = hooks.subexpr, concat = hooks.concat, attribute = hooks.attribute, content = hooks.content, inline = hooks.inline, block = hooks.block;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element19 = dom.childAt(fragment, [1]);
          var element20 = dom.childAt(element19, [1]);
          var element21 = dom.childAt(element20, [1]);
          var element22 = dom.childAt(element21, [1]);
          var element23 = dom.childAt(element22, [1, 1]);
          var element24 = dom.childAt(element23, [2]);
          var element25 = dom.childAt(element22, [3, 1]);
          var element26 = dom.childAt(element25, [3]);
          var element27 = dom.childAt(element21, [3, 1, 1]);
          var element28 = dom.childAt(element27, [1, 1]);
          var element29 = dom.childAt(element27, [3]);
          var element30 = dom.childAt(element29, [1, 1]);
          var element31 = dom.childAt(element29, [3, 1]);
          var element32 = dom.childAt(element21, [5]);
          var element33 = dom.childAt(element32, [3, 1]);
          var element34 = dom.childAt(element33, [3]);
          var element35 = dom.childAt(element33, [5]);
          var element36 = dom.childAt(element20, [5, 1]);
          var element37 = dom.childAt(element36, [3, 1]);
          var element38 = dom.childAt(element36, [5, 1]);
          var element39 = dom.childAt(element36, [7, 1]);
          var attrMorph0 = dom.createAttrMorph(element19, 'href');
          var morph0 = dom.createMorphAt(dom.childAt(element23, [0]),0,0);
          var attrMorph1 = dom.createAttrMorph(element24, 'title');
          var attrMorph2 = dom.createAttrMorph(element24, 'class');
          var morph1 = dom.createMorphAt(dom.childAt(element25, [1]),0,0);
          var morph2 = dom.createMorphAt(element26,0,0);
          var morph3 = dom.createMorphAt(element26,2,2);
          var morph4 = dom.createMorphAt(dom.childAt(element28, [3]),0,0);
          var morph5 = dom.createMorphAt(dom.childAt(element28, [5]),0,0);
          var morph6 = dom.createMorphAt(element30,0,0);
          var attrMorph3 = dom.createAttrMorph(element30, 'class');
          var morph7 = dom.createMorphAt(element31,0,0);
          var attrMorph4 = dom.createAttrMorph(element31, 'class');
          var morph8 = dom.createMorphAt(dom.childAt(element32, [1]),1,1);
          var morph9 = dom.createMorphAt(element34,0,0);
          var attrMorph5 = dom.createAttrMorph(element34, 'class');
          var morph10 = dom.createMorphAt(element35,0,0);
          var attrMorph6 = dom.createAttrMorph(element35, 'class');
          var morph11 = dom.createMorphAt(dom.childAt(element36, [1, 1]),1,1);
          var morph12 = dom.createMorphAt(dom.childAt(element37, [1]),0,0);
          var morph13 = dom.createMorphAt(dom.childAt(element37, [3]),0,0);
          var morph14 = dom.createMorphAt(dom.childAt(element38, [1]),0,0);
          var morph15 = dom.createMorphAt(dom.childAt(element38, [3]),0,0);
          var morph16 = dom.createMorphAt(dom.childAt(element39, [1]),0,0);
          var morph17 = dom.createMorphAt(dom.childAt(element39, [3]),0,0);
          attribute(env, attrMorph0, element19, "href", concat(env, [subexpr(env, context, "applyPlaceHolders", [get(env, context, "clientStkPage"), get(env, context, "stock.exg"), get(env, context, "stock.sym"), get(env, context, "lang")], {})]));
          content(env, morph0, context, "basicInfo.compName");
          attribute(env, attrMorph1, element24, "title", concat(env, [subexpr(env, context, "getDCFSFlagToolTipText", [get(env, context, "stock.dcfs"), get(env, context, "app.lang.labels")], {})]));
          attribute(env, attrMorph2, element24, "class", concat(env, [subexpr(env, context, "getFlagStyle", [get(env, context, "stock.dcfs")], {})]));
          content(env, morph1, context, "app.lang.labels.lastUpdated");
          inline(env, morph2, context, "formatToDate", [get(env, context, "stock.prevD")], {});
          inline(env, morph3, context, "formatToTime", [get(env, context, "stock.prevT"), get(env, context, "exchange.tzo")], {});
          inline(env, morph4, context, "formatNumber", [get(env, context, "stock.filteredAdjustedLtp"), get(env, context, "stock.filteredDeci")], {});
          content(env, morph5, context, "stock.cur");
          attribute(env, attrMorph3, element30, "class", concat(env, ["float-none no-margin snap-change text-nowrap ", subexpr(env, context, "getUpDownStyle", [get(env, context, "stock.chg"), "green up", "red down", ""], {})]));
          inline(env, morph6, context, "formatNumber", [get(env, context, "stock.filteredChg"), get(env, context, "stock.filteredDeci")], {});
          attribute(env, attrMorph4, element31, "class", concat(env, ["snap-percentange ", subexpr(env, context, "getUpDownStyle", [get(env, context, "stock.pctChg"), "green", "red", ""], {})]));
          inline(env, morph7, context, "formatNumberPercentage", [get(env, context, "stock.pctChg")], {});
          inline(env, morph8, context, "outlet", ["chartView"], {});
          attribute(env, attrMorph5, element34, "class", concat(env, ["col-xs-4 text-align-right dir-ltr ", subexpr(env, context, "getUpDownStyle", [get(env, context, "stock.pctChgY"), "green up", "red down", ""], {})]));
          inline(env, morph9, context, "formatNumber", [get(env, context, "stock.filteredChgY"), get(env, context, "stock.filteredDeci")], {});
          attribute(env, attrMorph6, element35, "class", concat(env, ["dir-ltr col-xs-5 text-align-right ", subexpr(env, context, "getUpDownStyle", [get(env, context, "stock.pctChgY"), "green", "red", ""], {})]));
          inline(env, morph10, context, "formatNumberPercentage", [get(env, context, "stock.pctChgY")], {});
          block(env, morph11, context, "if", [get(env, context, "showDisplaySymbol")], {}, child0, child1);
          content(env, morph12, context, "app.lang.labels.isin");
          content(env, morph13, context, "basicInfo.isin");
          content(env, morph14, context, "app.lang.labels.exchange");
          content(env, morph15, context, "exchange.exchangeDes");
          content(env, morph16, context, "app.lang.labels.sector");
          content(env, morph17, context, "basicInfo.sectorDes");
          return fragment;
        }
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col-xs-12 col-sm-3 col-lg-3 mini-chart");
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","col-xs-12 snap-current-price-currency-label no-padding");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"id","chartView");
            dom.setAttribute(el2,"class","col-xs-12 chart-widget-mini no-padding");
            var el3 = dom.createTextNode("\n                                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","col-xs-12 margin-medium-top");
            var el3 = dom.createTextNode("\n                                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","row");
            var el4 = dom.createTextNode("\n                                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","col-xs-3 text-align-left");
            var el5 = dom.createTextNode("1Y");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, content = hooks.content, inline = hooks.inline, get = hooks.get, subexpr = hooks.subexpr, concat = hooks.concat, attribute = hooks.attribute;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element4 = dom.childAt(fragment, [1]);
            var element5 = dom.childAt(element4, [5, 1]);
            var element6 = dom.childAt(element5, [3]);
            var element7 = dom.childAt(element5, [5]);
            var morph0 = dom.createMorphAt(dom.childAt(element4, [1]),0,0);
            var morph1 = dom.createMorphAt(dom.childAt(element4, [3]),1,1);
            var morph2 = dom.createMorphAt(element6,0,0);
            var attrMorph0 = dom.createAttrMorph(element6, 'class');
            var morph3 = dom.createMorphAt(element7,0,0);
            var attrMorph1 = dom.createAttrMorph(element7, 'class');
            content(env, morph0, context, "app.lang.labels.historicalPrices");
            inline(env, morph1, context, "outlet", ["chartView"], {});
            attribute(env, attrMorph0, element6, "class", concat(env, ["col-xs-4 text-align-right dir-ltr ", subexpr(env, context, "getUpDownStyle", [get(env, context, "stock.pctChgY"), "green up", "red down", ""], {})]));
            inline(env, morph2, context, "formatNumber", [get(env, context, "stock.filteredChgY"), get(env, context, "stock.filteredDeci")], {});
            attribute(env, attrMorph1, element7, "class", concat(env, ["dir-ltr col-xs-5 text-align-right ", subexpr(env, context, "getUpDownStyle", [get(env, context, "stock.pctChgY"), "green", "red", ""], {})]));
            inline(env, morph3, context, "formatNumberPercentage", [get(env, context, "stock.pctChgY")], {});
            return fragment;
          }
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            isHTMLBars: true,
            revision: "Ember@1.11.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"id","symbolLabel");
              dom.setAttribute(el1,"class","bold left snap-font-color-2");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" : ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"id","symbol");
              dom.setAttribute(el1,"class","snap-font-color-1");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks, content = hooks.content;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var morph0 = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
              var morph1 = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
              content(env, morph0, context, "app.lang.labels.symbol");
              content(env, morph1, context, "stock.sDes");
              return fragment;
            }
          };
        }());
        var child1 = (function() {
          return {
            isHTMLBars: true,
            revision: "Ember@1.11.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"id","symbolLabel");
              dom.setAttribute(el1,"class","bold left snap-font-color-2");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" : ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"id","symbol");
              dom.setAttribute(el1,"class","snap-font-color-1");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks, content = hooks.content;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var morph0 = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
              var morph1 = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
              content(env, morph0, context, "app.lang.labels.symbol");
              content(env, morph1, context, "stock.sym");
              return fragment;
            }
          };
        }());
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","horizontal-sep-line margin-medium-top margin-medium-bottom");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","row row-fig");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","col-xs-12 stkOvr-padd space-large");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col-xs-12 col-sm-2 v-sep margin-large-bottom noPadding");
            var el4 = dom.createTextNode("\n                      ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","text-align-left margin-small-top left");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("                      ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col-xs-12 col-sm-3 col-cust-4 v-sep margin-large-bottom noPadding");
            var el4 = dom.createTextNode("\n                      ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","text-align-left margin-small-top left");
            var el5 = dom.createTextNode("\n                          ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"id","isinLabel");
            dom.setAttribute(el5,"class","bold left snap-font-color-2");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode(" : ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"id","isin");
            dom.setAttribute(el5,"class","snap-font-color-1");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                      ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col-xs-12 col-sm-3 col-cust-4 v-sep margin-large-bottom noPadding");
            var el4 = dom.createTextNode("\n                      ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","text-align-left margin-small-top left");
            var el5 = dom.createTextNode("\n                          ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"id","exchangeLabel");
            dom.setAttribute(el5,"class","bold left snap-font-color-2");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode(" : ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"id","exchange");
            dom.setAttribute(el5,"class","snap-font-color-1");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                      ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col-xs-12 col-sm-3 v-sep margin-large-bottom");
            var el4 = dom.createTextNode("\n                      ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","text-align-left margin-small-top left");
            var el5 = dom.createTextNode("\n                          ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"id","sectorLabel");
            dom.setAttribute(el5,"class","bold left snap-font-color-2");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode(" : ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"id","sector");
            dom.setAttribute(el5,"class","snap-font-color-1");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                      ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, block = hooks.block, content = hooks.content;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element0 = dom.childAt(fragment, [3, 1]);
            var element1 = dom.childAt(element0, [3, 1]);
            var element2 = dom.childAt(element0, [5, 1]);
            var element3 = dom.childAt(element0, [7, 1]);
            var morph0 = dom.createMorphAt(dom.childAt(element0, [1, 1]),1,1);
            var morph1 = dom.createMorphAt(dom.childAt(element1, [1]),0,0);
            var morph2 = dom.createMorphAt(dom.childAt(element1, [3]),0,0);
            var morph3 = dom.createMorphAt(dom.childAt(element2, [1]),0,0);
            var morph4 = dom.createMorphAt(dom.childAt(element2, [3]),0,0);
            var morph5 = dom.createMorphAt(dom.childAt(element3, [1]),0,0);
            var morph6 = dom.createMorphAt(dom.childAt(element3, [3]),0,0);
            block(env, morph0, context, "if", [get(env, context, "showDisplaySymbol")], {}, child0, child1);
            content(env, morph1, context, "app.lang.labels.isin");
            content(env, morph2, context, "basicInfo.isin");
            content(env, morph3, context, "app.lang.labels.exchange");
            content(env, morph4, context, "exchange.exchangeDes");
            content(env, morph5, context, "app.lang.labels.sector");
            content(env, morph6, context, "basicInfo.sectorDes");
            return fragment;
          }
        };
      }());
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","full-width margin-large-bottom mobile-view");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","row space-large print-display");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col-xs-12 col-lg-3  col-sm-3 margin-large-bottom no-padding");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col-xs-12 col-sm-12");
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("h1");
          dom.setAttribute(el5,"id","compName");
          dom.setAttribute(el5,"class","snap-company-name text-align-left margin-small-bottom titleFontSize no-padding");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col-xs-12 col-sm-12");
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","snap-note text-align-left margin-small-bottom margin-medium-top");
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"id","lastUpdatedLabel");
          dom.setAttribute(el6,"class","display-block left");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" :");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"id","lastUpdatedDate");
          dom.setAttribute(el6,"class","display-block left");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" ");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col-xs-12 col-lg-6  col-sm-6");
          var el4 = dom.createTextNode("\n ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","row stkOvw-title print-display");
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","col-xs-12 col-sm-4 col-lg-4");
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"style","display: flex; flex-direction: column;");
          var el7 = dom.createTextNode("\n                            ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("span");
          dom.setAttribute(el7,"class","snap-current-price-currency-label left");
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                            ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("span");
          dom.setAttribute(el7,"id","lastTradePrice");
          dom.setAttribute(el7,"class","print-margin snap-current-price snap-current-price-change stkOrv-bold left");
          dom.setAttribute(el7,"style","margin-top: 10px; font-size: 24px");
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("span");
          dom.setAttribute(el8,"id","currency");
          dom.setAttribute(el8,"class","snap-current-price-currency stkOrv-bold");
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                            ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n\n                        ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n\n                    ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                     ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","col-xs-12 col-sm-4 col-lg-4");
          dom.setAttribute(el5,"style","display: flex; flex-direction: column");
          var el6 = dom.createTextNode("\n                                            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"class","snap-current-price-currency-label left");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                                             ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"id","change");
          dom.setAttribute(el6,"style","margin-top:4px ;font-size: 24px");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n\n                                        ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","col-xs-12 col-sm-4 col-lg-4");
          dom.setAttribute(el5,"style","display: flex; flex-direction: column");
          var el6 = dom.createTextNode("\n                                            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"class","snap-current-price-currency-label left");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                                            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"id","pctChange");
          dom.setAttribute(el6,"style","margin-top: 10px; font-size: 24px");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n\n                                        ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                                    ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, content = hooks.content, get = hooks.get, inline = hooks.inline, subexpr = hooks.subexpr, concat = hooks.concat, attribute = hooks.attribute, block = hooks.block;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element8 = dom.childAt(fragment, [1, 1]);
          var element9 = dom.childAt(element8, [1]);
          var element10 = dom.childAt(element9, [3, 1]);
          var element11 = dom.childAt(element10, [3]);
          var element12 = dom.childAt(element8, [3, 1]);
          var element13 = dom.childAt(element12, [1, 1]);
          var element14 = dom.childAt(element13, [3]);
          var element15 = dom.childAt(element12, [3]);
          var element16 = dom.childAt(element15, [3]);
          var element17 = dom.childAt(element12, [5]);
          var element18 = dom.childAt(element17, [3]);
          var morph0 = dom.createMorphAt(dom.childAt(element9, [1, 1]),0,0);
          var morph1 = dom.createMorphAt(dom.childAt(element10, [1]),0,0);
          var morph2 = dom.createMorphAt(element11,0,0);
          var morph3 = dom.createMorphAt(element11,2,2);
          var morph4 = dom.createMorphAt(dom.childAt(element13, [1]),0,0);
          var morph5 = dom.createMorphAt(element14,0,0);
          var morph6 = dom.createMorphAt(dom.childAt(element14, [2]),0,0);
          var morph7 = dom.createMorphAt(dom.childAt(element15, [1]),0,0);
          var morph8 = dom.createMorphAt(element16,0,0);
          var attrMorph0 = dom.createAttrMorph(element16, 'class');
          var morph9 = dom.createMorphAt(dom.childAt(element17, [1]),0,0);
          var morph10 = dom.createMorphAt(element18,0,0);
          var attrMorph1 = dom.createAttrMorph(element18, 'class');
          var morph11 = dom.createMorphAt(element8,5,5);
          var morph12 = dom.createMorphAt(fragment,3,3,contextualElement);
          dom.insertBoundary(fragment, null);
          content(env, morph0, context, "basicInfo.compName");
          content(env, morph1, context, "app.lang.labels.lastUpdated");
          inline(env, morph2, context, "formatToDate", [get(env, context, "stock.prevD")], {});
          inline(env, morph3, context, "formatToTime", [get(env, context, "stock.prevT"), get(env, context, "exchange.tzo")], {});
          content(env, morph4, context, "app.lang.labels.sharePrice");
          inline(env, morph5, context, "formatNumber", [get(env, context, "stock.filteredAdjustedLtp"), get(env, context, "stock.filteredDeci")], {});
          content(env, morph6, context, "stock.cur");
          content(env, morph7, context, "app.lang.labels.chg");
          attribute(env, attrMorph0, element16, "class", concat(env, ["stkOrv-bold float-none snap-current-price snap-change text-nowrap left ", subexpr(env, context, "getUpDownStyle", [get(env, context, "stock.filteredChg"), "green up", "red down", ""], {})]));
          inline(env, morph8, context, "formatNumber", [get(env, context, "stock.filteredChg"), get(env, context, "stock.filteredDeci")], {});
          content(env, morph9, context, "app.lang.labels.pctChg");
          attribute(env, attrMorph1, element18, "class", concat(env, ["left stkOrv-bold snap-current-price-change snap-percentange ", subexpr(env, context, "getUpDownStyle", [get(env, context, "stock.pctChg"), "green", "red", ""], {})]));
          inline(env, morph10, context, "formatNumberPercentage", [get(env, context, "stock.pctChg")], {});
          block(env, morph11, context, "unless", [get(env, context, "isChartDisabled")], {}, child0, null);
          block(env, morph12, context, "if", [get(env, context, "newStkOvw")], {}, child1, null);
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, null);
        dom.insertBoundary(fragment, 0);
        block(env, morph0, context, "if", [get(env, context, "isWidgetClickable")], {}, child0, child1);
        return fragment;
      }
    };
  }()));

});