define('ir-app/models/price/price-business-entities/fundamentals/estimate-forecast-object', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        providerName: undefined,
        providedDate: undefined,
        value: undefined,

        latestDate: undefined,
        prevDate: undefined,
        latestEstimates: undefined,
        prevEstimates: undefined,
        keysOfPrev: undefined,
        keysOfLatest: undefined,

        init: function init() {
            this.keysOfPrev = Ember['default'].A([]);
            this.keysOfLatest = Ember['default'].A([]);
            this.latestEstimates = {};
            this.prevEstimates = {};
        },

        setLatestEst: function setLatestEst(year, data) {
            this.get('latestEstimates')[year] = data;
            this.get('keysOfLatest').push(year);
        },

        setPrevEst: function setPrevEst(year, data) {
            this.get('prevEstimates')[year] = data;
            this.get('keysOfPrev').push(year);
        }
    });

});