define('ir-app/components/stk-specific-components/financial-chart-snapshot', ['exports', 'ember', 'ir-app/utils/utils', 'ir-app/models/shared/language/language-data-store'], function (exports, Ember, utils, LanguageDataStore) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({

    minValue: -1,
    maxValue: -1,
    minimumChartHeight: 170,
    hideCF: true,
    dataDisplayFormat: 0,

    handleResize: function handleResize() {
      var that = this;
      try {
        if (document.getElementById('chart1') !== null) {
          var topHeight = document.getElementById('chart1').getBoundingClientRect().top;
          var fullHeight = Ember['default'].$(window).height();
          var expectedChartHeight = fullHeight - topHeight - 3; // 3 is a bottom margin
          var chartHeight = (expectedChartHeight > that.minimumChartHeight ? expectedChartHeight : that.minimumChartHeight) / 2;
          that.set('chartContainerHeight', chartHeight);
          that.set('chartContainerWidth', document.getElementById('chart1').offsetWidth);
        }
      } catch (e) {
        utils['default'].logger.logError('[basic chart widget] handleResize() ' + e);
      }
    },

    handleInitialResize: function handleInitialResize() {
      var that = this;
      setTimeout(function () {
        // give 1s delay to let other components to load.
        that.handleResize();
      }, 1);
    },

    bindResizeEvent: (function () {
      var that = this;
      Ember['default'].$(window).on('resize', Ember['default'].run.bind(this, this.handleResize));
      Ember['default'].$(window).ready(function () {
        Ember['default'].$('#transbox').bind('DOMSubtreeModified', function () {
          // Some times it is bit delay to load the top description panel(#transbox) of the home page
          that.handleResize();
        });
      });
      Ember['default'].$(window).ready(Ember['default'].run.bind(this, this.handleInitialResize));
    }).on('init'),

    formater: function formater(val) {
      var result = val;
      if (Math.abs(val) > 1000000) {
        result = Math.floor(val / 1000000) + ' M';
      } else if (Math.abs(val) > 1000) {
        result = Math.floor(val / 1000) + ' K';
      }
      return result;
    },

    layoutName: 'components/stk-specific-components/financial-chart1',
    chart: {},
    elementId: '',
    dataSet: {},
    dataSet2: {},
    nonMillionField: false,

    xDataSet: {},

    chartOptions: (function () {
      var that = this;
      that.set('dataDisplayFormat', this.get('dataDisplayFormat'));

      return { chart: {
          type: 'column',
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          marginTop: 30

        },

        title: {
          useHTML: true,
          align: 'center',
          verticalAlign: 'top',
          y: 10,
          style: {
            fontWeight: 'bold',
            fontSize: '15px'
          }
        },
        subtitle: {
          text: null
        },
        xAxis: {
          title: {
            text: null
          },
          tickLength: 0,

          labels: {
            enabled: true
          }
        },
        yAxis: {
          title: {
            text: '(in Millions)'
          },
          labels: {
            overflow: 'justify', enabled: true,
            formatter: function formatter() {
              //var val = this.value / 1000000;
              var result;

              if (that.get('dataDisplayFormat') === 2) {
                result = this.value / 1000000;
              } else if (that.get('dataDisplayFormat') === 1) {
                result = this.value;
                if (this.chart.yAxis[0].getExtremes().dataMin < 1000) {
                  result = this.value.toFixed(2);
                }
              } else {

                if (this.chart.yAxis[0].getExtremes().dataMin > 10000000) {
                  result = this.value / 1000000;
                } else if (this.chart.yAxis[0].getExtremes().dataMin > 100000) {
                  result = this.value / 1000;
                } else {
                  result = this.value;
                }
              }

              // var num = Math.abs(val);

              // return num > 999 ? (val/1000).toFixed(1) + 'k' : val;
              return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
          }
          // gridLineWidth: 0,
          // minorGridLineWidth: 0,
          // tickLength: 0
        },
        tooltip: {
          enabled: true,
          formatter: function formatter() {
            // return 'Value:  <b>'+ this.y/1000000+'M' +'</b>';
            var result;
            if (that.get('dataDisplayFormat') == 2) {
              result = this.y / 1000;
              return '<b>' + result.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '</b>';
            } else if (that.get('dataDisplayFormat') == 1) {
              result = this.y;
              if (this.series.chart.yAxis[0].getExtremes().dataMin < 1000) {
                return '<b>' + result.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '</b>';
              } else {
                return '<b>' + result.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '</b>';
              }
            } else {
              if (this.series.chart.yAxis[0].getExtremes().dataMin > 10000000) {
                result = this.y / 1000000;
                return '<b>' + result.toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'M' + '</b>';
              } else if (this.series.chart.yAxis[0].getExtremes().dataMin > 100000) {
                result = this.y / 1000;
                return '<b>' + result.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '</b>';
              } else {
                result = this.y;
                return '<b>' + result.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '</b>';
              }
            }
          }
        },
        plotOptions: {

          bar: {

            dataLabels: {
              enabled: true, style: { fontSize: '10px', align: 'left' }

            }

          }
        },
        legend: {
          layout: 'horizontal',
          align: 'right',
          verticalAlign: 'top',
          x: 0,
          y: 20,
          floating: true,
          borderWidth: 1,
          shadow: true
        },

        credits: {
          enabled: false
        },

        exporting: {
          enabled: false
        },
        series: [
        //{
        //   showInLegend: true, pointWidth: 60, stacking: 'normal', groupPadding: 0.1
        //},
        { showInLegend: false }]

      };
    }).property(),

    generateChart: (function () {

      Array.prototype.max = function () {
        return Math.max.apply(null, this);
      };

      Array.prototype.min = function () {
        return Math.min.apply(null, this);
      };

      var chart = this.get('chartOptions');
      var currentDataSet = this.get('dataSet');
      if (currentDataSet && !currentDataSet[1] && !currentDataSet[2] && !currentDataSet[3] && !currentDataSet[4]) {
        var destroyChart = Ember['default'].$('#' + this.get('elementId')).highcharts();
        if (destroyChart) {
          destroyChart.destroy();
        }
      }

      var languageObj = LanguageDataStore['default'].getLanguageObj();
      // if (!this.get('nonMillionField')) {
      //     chart.yAxis.title.text = languageObj.lang.labels['inMillions'];
      // } else {
      //     chart.yAxis.title.text = '';
      // }

      if (currentDataSet.length > 0) {
        chart.series[0]['data'] = currentDataSet.slice(1).map(Number);
      } else {
        return;
      }

      if (this.get('dataDisplayFormat') == 2) {
        chart.yAxis.title.text = languageObj.lang.labels['inMillions'];
      } else if (this.get('dataDisplayFormat') == 1) {

        if (chart.series[0]['data'].min() < 1000) {
          chart.yAxis.title.text = '';
        } else {
          chart.yAxis.title.text = languageObj.lang.labels['inThousands'];
        }
      } else {
        if (chart.series[0]['data'].min() > 10000000) {
          chart.yAxis.title.text = languageObj.lang.labels['inMillions'];
        } else if (chart.series[0]['data'].min() > 100000) {
          chart.yAxis.title.text = languageObj.lang.labels['inThousands'];
        } else {
          chart.yAxis.title.text = '';
        }
      }
      //  }
      // var dataArray=chart.series[0]['data'];

      chart.chart.height = 250; //this.get('chartContainerHeight');

      //chart.yAxis.min= dataArray.min();
      //chart.yAxis.max=dataArray.max();

      chart.title['text'] = currentDataSet[0];
      // chart.yAxis.title['text'] = '';

      var prevY = 0;
      chart.plotOptions.bar['dataLabels'].formatter = function () {
        var result;
        if (prevY === 0) {
          result = '';
          prevY = this.y;
        } else {
          result = utils['default'].formatters.formatNumber((this.y - prevY) / Math.abs(prevY) * 100, 2) + ' %';

          prevY = this.y;
        }
        return result;
      };

      var cat;
      if (this.get('xDataSet')) {
        cat = this.get('xDataSet').slice(1);
      }

      var appendedCat = [];
      for (var i = 0; i < this.get('xDataSet.length') - 1; ++i) {

        var temp = cat[i];
        appendedCat.push(temp);
      }
      chart.xAxis['categories'] = appendedCat;

      if (this.get('elementId') === 'chart1') {

        chart.plotOptions.bar.color = '#f29130';
      } else {

        chart.plotOptions.bar.color = '#ba96c4';
      }
      Ember['default'].$('#' + this.get('elementId')).highcharts(chart);
    }).observes('dataSet'),

    formatNum: function formatNum() {
      var val;
      if (this.get('nonMillion')) {
        val = this.value;
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        val = this.value / 1000000;
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },

    didInsertElement: function didInsertElement() {
      this._super();
      this.generateChart();
    }
  });

});