define('ir-app/components/stk-specific-components/financial-statement-portals', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        app: {}, // language settings
        layoutName: 'components/stk-specific-components/financial-statement-portals',
        contents: {}, // financial available quarters -for loading purpose
        toSelection: {},
        selectedPeriodType: {},
        dataSet1: {},
        dataSet2: {},
        xDataSet: {},
        styleObject: {},
        ratioList: []

    });

});