define('ir-app/controllers/ir-widgets/ipo-widgets/ipo-detail-view', ['exports', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/models/price/price-constants', 'ir-app/models/shared/shared-service'], function (exports, BaseController, priceService, priceConstants, sharedService) {

    'use strict';

    exports['default'] = BaseController['default'].extend({

        ipoObj: undefined,
        basicInfo: undefined,
        ipoDataList: undefined,
        symbol: undefined,
        exchange: undefined,
        stock: undefined,
        isCachedData: false,
        isIpoSuccess: false,
        isIpoObj: false,
        isCompanyProfileData: false,
        ipoCompanyId: undefined,

        // Base overrides
        onPrepareData: function onPrepareData() {
            var that = this;
            var searchPara = {
                pageSize: 1,
                pageIdx: 0,
                ipoId: that.get('sym')
            };

            var ipoDataList = priceService['default'].ipoDataListDS.getStoredObject(that.get('sym'), // set IPO ID
            sharedService['default'].userSettings.currentLanguage, priceConstants['default'].DataTypes.IpoDataList.IpoById, function () {
                that.set('isIpoSucess', true);
                that._setExchangeAndSymbol();
                if (that.get('exchange') && that.get('symbol')) {
                    that._loadNewsList();
                }
                that.onDataSuccess();
            }, function () {
                that.set('isIpoSucess', false);
                that.onDataError();
            }, this.get('isCachedData'), searchPara).ipoDataList;

            this.set('ipoDataList', ipoDataList);
        },

        _setExchangeAndSymbol: function _setExchangeAndSymbol() {
            var ipoData = priceService['default'].ipoDataListDS.getStoredObject(this.get('sym'), sharedService['default'].userSettings.currentLanguage, priceConstants['default'].DataTypes.IpoDataList.IpoById);

            if (ipoData && ipoData.ipoDataList.length > 0 && ipoData.fullListSize > 0) {
                this.set('ipoObj', ipoData.ipoDataList[0]);
                this.set('exchange', ipoData.ipoDataList[0].exg);
                this.set('symbol', ipoData.ipoDataList[0].sym);
            }
        },

        onCheckDataAvailability: function onCheckDataAvailability() {
            if (this.get('isProfileData')) {
                var companyObj = priceService['default'].companyProfileDS.getStoredObjectByCompanyId(this.get('ipoCompanyId'), sharedService['default'].userSettings.currentLanguage);
                this.set('basicInfo', companyObj);
                this.set('isCompanyProfileData', true);
            } else {
                this.set('isCompanyProfileData', false);
            }
            if (this.get('ipoObj')) {
                if (this.get('exchange') && this.get('symbol')) {
                    var stock = priceService['default'].stockDS.getStock(this.get('exchange'), this.get('symbol'));
                    this.set('stock', stock);
                    priceService['default'].addSymbolRequest(this.get('exchange'), this.get('symbol'));
                }
                this.set('isIpoObj', true);
                return true;
            }
            return false;
        },

        image: (function () {
            if (this.utils.validators.isAvailable(this.get('basicInfo.logo'))) {
                return this.utils.imageHelper.getImage(this.get('basicInfo.logo'));
            }
        }).property('basicInfo.logo'),

        formattedAddress: (function () {
            var str = this.get('basicInfo.addr');

            if (this.utils.validators.isAvailable(str)) {
                return str.split(', ');
            }
        }).property('basicInfo.addr'),

        _loadNewsList: function _loadNewsList() {
            var detailViewController = 'ir-widgets/announcement-widgets/announcements-compact-list';
            var controllerString = 'controller:' + detailViewController;
            var widgetController = this.container.lookupFactory(controllerString).create();
            widgetController.set('isPortalTopBarChart', true);
            widgetController.set('sym', this.get('symbol'));
            widgetController.set('exg', this.get('exchange'));
            widgetController.set('minifiedSliceNumber', 5);
            widgetController.set('loadInPopup', true);
            widgetController.initializeWidget({ wn: controllerString.split('/').pop() });
            var route = this.container.lookup('route:application');
            route.render(detailViewController, {
                into: 'ir-widgets/ipo-widgets/ipo-detail-view',
                outlet: 'newsView',
                controller: widgetController
            });

            widgetController.onUnloadWidget();
        },

        requestCompanyData: (function () {
            var that = this;
            var ipoId = that.get('ipoObj');
            this.set('ipoCompanyId', ipoId.compId);
            if (this.get('isIpoObj') && ipoId) {
                priceService['default'].sendCompanyProfileByIdRequest(ipoId.compId, sharedService['default'].userSettings.currentLanguage, function () {
                    that.set('isProfileData', true);
                    that.onDataSuccess();
                }, function () {
                    that.set('isProfileData', true);
                    that.onDataError();
                });
            }
        }).observes('isIpoObj'),

        onClearData: function onClearData() {
            this.set('ipoObj', null);
            if (this.get('exchange') && this.get('symbol')) {
                priceService['default'].removeSymbolRequest(this.get('exchange'), this.get('symbol'));
            }
        }

    });

});