define('ir-app/controllers/ir-widgets/stock-overview-widgets/price-change-widget', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/models/price/price-business-entities/panel-field', 'ir-app/models/shared/shared-service', 'ir-app/models/price/price-constants'], function (exports, Ember, BaseController, priceService, panelField, sharedService, priceConstants) {

    'use strict';

    exports['default'] = BaseController['default'].extend({

        stock: {},
        panelFields: Ember['default'].A(),
        // Default set of fields. This can be overriden via the config
        displayFields: [{ lanKey: 'pctChgWeek', dataField: 'pctChgW' }, { lanKey: 'pctChgMonth', dataField: 'pctChgM' }, { lanKey: 'pctChg3Month', dataField: 'pctChg3M' }, { lanKey: 'ytd', dataField: 'pctYtd' }],

        onPrepareData: function onPrepareData() {
            var that = this;
            if (this.utils.validators.isAvailable(this.get('companyId'))) {
                priceService['default'].companyProfileDS.getStoredObjectByCompanyId(this.get('companyId'), sharedService['default'].userSettings.currentLanguage, function () {
                    var _basicInfo = priceService['default'].companyProfileDS.getStoredObjectByCompanyId(that.get('companyId'), sharedService['default'].userSettings.currentLanguage);
                    that.set('sym', _basicInfo.sym);
                    that.set('exg', _basicInfo.exg);
                    that._onPrepareData();
                }, function () {}, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyBasicInfo);
            } else {
                that._onPrepareData();
            }
        },

        _onPrepareData: function _onPrepareData() {
            this.set('displayFields', this.get('displayFields'));
            this.set('panelFields', Ember['default'].A());

            var stock = priceService['default'].stockDS.getStock(this.get('exg'), this.get('sym'));
            this.set('stock', stock);
            this.renderPanelFields();
            priceService['default'].addSymbolRequest(this.get('exg'), this.get('sym'));
        },

        onClearData: function onClearData() {
            priceService['default'].removeSymbolRequest(this.get('exg'), this.get('sym'));
        },

        renderPanelFields: function renderPanelFields() {
            try {
                var stockObj = this.get('stock');
                var fields = this.get('displayFields');
                var panelFieldsRef = this.get('panelFields');

                var lanStore = this.get('app').lang.labels;

                if (fields && panelFieldsRef) {

                    Ember['default'].$.each(fields, function (key, value) {

                        var fieldObj = panelField['default'].create({
                            lanKey: lanStore[value.lanKey] ? lanStore[value.lanKey] : value.lanKey,
                            valueObj: stockObj,
                            value: stockObj.get(value.dataField),
                            field: value.dataField
                        });

                        if (value.decimalPlaces) {
                            fieldObj.numOfDeci = value.decimalPlaces;
                        } else {
                            fieldObj.numOfDeci = stockObj.get('deci');
                        }

                        Ember['default'].addObserver(stockObj, value.dataField, fieldObj, fieldObj.notifyChange);
                        panelFieldsRef.pushObject(fieldObj);
                    });
                }
            } catch (x) {
                this.utils.logger.logError('Error in rendering price change widget panel fields : ' + x);
            }
        }
    });

});