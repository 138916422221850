define('ir-app/controllers/ir-mobile/media-room-widgets/media-room', ['exports', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/utils/utils', 'ir-app/models/price/price-constants'], function (exports, BaseController, priceService, sharedService, utils, priceConstants) {

    'use strict';

    /**
     * Created by banushas on 3/25/2016.
     */
    exports['default'] = BaseController['default'].extend({
        Tabs: {
            YOUTUBE: 1,
            FACEBOOK: 2,
            TWITTER: 3,
            LINKEDIN: 4
        },
        activeYoutube: '',
        activeFacebook: '',
        activeTwitter: '',

        onLoadWidget: function onLoadWidget() {
            this._loadCompanyProfile();

            if (!this.get('noYoutube')) {
                this.set('activeYoutube', 'active');
                this.initializeComponent(this.Tabs.YOUTUBE);
            } else if (this.get('noYoutube') && !this.get('noFacebook')) {
                this.set('activeFacebook', 'active');
                this.initializeComponent(this.Tabs.FACEBOOK);
            } else if (this.get('noYoutube') && this.get('noFacebook')) {
                this.set('onlyTwitter', true);
                this.set('activeTwitter', 'active');
                this.initializeComponent(this.Tabs.TWITTER);
            }
        },
        initializeComponent: function initializeComponent(tabId) {
            var detailViewController;
            var mediaURL = '';
            if (tabId === this.get('Tabs').FACEBOOK) {
                detailViewController = 'ir-mobile/media-room-widgets/facebook';
                if (utils['default'].validators.isAvailable(this.get('facebook_url'))) {
                    mediaURL = this.get('facebook_url');
                } else {
                    mediaURL = this.get('basicInfo').facebook;
                }
            } else if (tabId === this.get('Tabs').TWITTER) {
                detailViewController = 'ir-mobile/media-room-widgets/twitter';
                mediaURL = this.get('basicInfo').twitter;
            } else if (tabId === this.get('Tabs').LINKEDIN) {
                detailViewController = 'ir-mobile/media-room-widgets/linkedin';
                mediaURL = this.get('basicInfo').linkedIn;
            } else if (tabId === this.get('Tabs').YOUTUBE) {
                detailViewController = 'ir-mobile/media-room-widgets/youtube';
                if (utils['default'].validators.isAvailable(this.get('youtube_url'))) {
                    mediaURL = this.get('youtube_url');
                } else {
                    mediaURL = this.get('basicInfo').youtube;
                }
            }

            var controllerString = 'controller:' + detailViewController;
            var widgetController = this.container.lookupFactory(controllerString).create();
            widgetController.set('mediaURL', mediaURL);
            widgetController.set('twitter_widget_id', this.get('twitter_widget_id'));
            widgetController.initializeWidget({ wn: controllerString.split('/').pop() });

            var route = this.container.lookup('route:application');
            route.render(detailViewController, {
                into: 'ir-mobile/media-room-widgets/media-room',
                outlet: 'mediaRoomOutlet',
                controller: widgetController
            });

            widgetController.onUnloadWidget();
        },

        _loadCompanyProfile: function _loadCompanyProfile() {
            var basicInfo = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, function () {}, function () {}, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyBasicInfo);

            this.set('basicInfo', basicInfo);
        },

        actions: {
            onTabSelected: function onTabSelected(tabId) {
                this.initializeComponent(tabId);
            }
        }
    });

});