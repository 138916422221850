define('ir-app/components/stk-specific-components/high-chart/dynamic-high-chart', ['exports', 'ember', 'ir-app/utils/utils', 'ember-highcharts/components/high-charts'], function (exports, Ember, utils, EmberHighChartsComponent) {

    'use strict';

    /**
     * Created by banushas on 3/11/2016.
     */

    exports['default'] = EmberHighChartsComponent['default'].extend({

        layoutName: 'components/stk-specific-components/high-chart/dynamic-high-chart',
        minValue: -1,
        maxValue: -1,
        volumeViewEnabled: true,
        basicChartType: undefined,
        isPaddingNeed: true,
        chartHeight: 400,
        markerId: '',
        chartWidth: 400,
        indicatorList: Ember['default'].A(),
        closeMode: false, // change the chart forcusing
        dataLoadingComplete: true,
        compareViewEnabled: true,
        compareSymbolContent: Ember['default'].A(),
        chartCompareSymbolKeyList: Ember['default'].A(),
        compareSymbolNeedsToUpdate: false,
        chartInterval: undefined,
        showSeries: undefined,
        chartCategory: undefined,
        prevChartCategory: undefined,
        xaxisCat: undefined,
        isFinancialChart: false,
        showHide: false,
        announcementsMarkers: false,
        dataArray: 'reload',
        xAxisCatTemp: undefined, // Initially assign from basic-chart-widget initialChartCategory parameter

        // When the data content is change need o redraw the chart.
        contentDidChange: (function () {
            var that = this;
            var chart = that.get('chart');
            if (that.get("xaxisCat") != undefined) {
                that.set("xAxisCatTemp", that.get("xaxisCat"));
            }

            try {
                // Find a better way to refresh the chart series please.
                if (that.isFinancialChart) {
                    while (chart.series.length > that.get('content').length) {
                        chart.series[that.get('content').length].remove();
                        console.log('chart series', chart.series.length);
                    }
                }

                for (var i = 0; i < that.get('content').length; i++) {
                    if (chart.series[i] !== undefined) {
                        chart.series[i].update({
                            name: that.get('content')[i].name,
                            data: that.get('content')[i].data,
                            type: that.get('content')[i].type,
                            color: that.get('content')[i].color,
                            upColor: that.get('content')[i].upColor,
                            lineColor: that.get('content')[i].lineColor,
                            dataGrouping: that.get('content')[i].dataGrouping,
                            indicatorData: that.get('content')[i].indicatorData,
                            tooltip: that.get('content')[i].tooltip,
                            animation: { duration: 3000, delay: 2000 }
                        }, false);
                    } else {
                        chart.addSeries({
                            name: that.get('content')[i].name,
                            data: that.get('content')[i].data,
                            type: that.get('content')[i].type,
                            color: that.get('content')[i].color,
                            upColor: that.get('content')[i].upColor,
                            lineColor: that.get('content')[i].lineColor,
                            dataGrouping: that.get('content')[i].dataGrouping,
                            indicatorData: that.get('content')[i].indicatorData,
                            tooltip: that.get('content')[i].tooltip,
                            animation: { duration: 3000, delay: 2000 }
                        });
                    }
                }
                that.showHideVolume();
                chart.redraw();
                that.set('basicChartType', this.get('content')[0].type);
                var numOfYAxis = chart.yAxis.length - 1; // Number of Y Axis with out main y axis
                if (numOfYAxis === 1 && that.volumeViewEnabled) {
                    utils['default'].logger.logInfo('There are no indicators only volume is display');
                } else {
                    that.reSizeYAxisWithIndicators(chart); // to avoid change sizes of Y axis when chart redrawing.
                }
                chart.xAxis[0].update({}, true);
            } catch (e) {
                utils['default'].logger.logError('[Dynamic High Chart] contentDidChange() ' + e);
            }
        }).observes('content.@each.isLoaded', 'chart'),

        reloadChart: function reloadChart() {
            var that = this;
            var chart = this.get('chart');
            for (var i = 0; i < that.get('content').length; i++) {
                if (chart.series[i] !== undefined) {
                    chart.series[i].update({
                        name: that.get('content')[i].name,
                        data: that.get('content')[i].data,
                        type: that.get('content')[i].type,
                        color: that.get('content')[i].color,
                        upColor: that.get('content')[i].upColor,
                        lineColor: that.get('content')[i].lineColor,
                        dataGrouping: that.get('content')[i].dataGrouping,
                        indicatorData: that.get('content')[i].indicatorData,
                        tooltip: that.get('content')[i].tooltip
                    }, false);
                } else {
                    chart.addSeries({
                        name: that.get('content')[i].name,
                        data: that.get('content')[i].data,
                        type: that.get('content')[i].type,
                        color: that.get('content')[i].color,
                        upColor: that.get('content')[i].upColor,
                        lineColor: that.get('content')[i].lineColor,
                        dataGrouping: that.get('content')[i].dataGrouping,
                        indicatorData: that.get('content')[i].indicatorData,
                        tooltip: that.get('content')[i].tooltip
                    });
                }
            }
            that.showHideVolume();
            chart.redraw();
        },

        compareSymbolContentDidChange: (function () {
            var that = this;
            var chart = that.get('chart');
            try {
                var compareSymbolSeriesLength = chart.series.length - that.get('content').length;
                var compareSymbolContentLength = that.get('compareSymbolContent').length;
                if (compareSymbolContentLength == 0) {
                    that.contentDidChange();
                }
                if (compareSymbolSeriesLength > compareSymbolContentLength) {
                    // compare symbol is removing
                    // compare symbol remove
                    var symbolID;
                    for (var j = that.get('content').length; j < chart.series.length; j++) {
                        if (chart.series[j] !== undefined) {
                            symbolID = chart.series[j].options.id;
                            if (symbolID !== undefined) {
                                if (!this.get('chartCompareSymbolKeyList').contains(symbolID)) {
                                    chart.series[j].options.showInLegend = false;
                                    chart.series[j].legendItem = null;
                                    chart.legend.destroyItem(chart.series[j]);
                                    chart.legend.render();
                                    chart.series[j].remove();
                                    break;
                                }
                            }
                        }
                    }
                } else {
                    // compare symbol added
                    var lastIndex = that.get('compareSymbolContent').length - 1; // get the last item in the compareSymbolContent
                    if (lastIndex >= 0) {
                        chart.addSeries({
                            id: this.get('compareSymbolContent')[lastIndex].id,
                            name: this.get('compareSymbolContent')[lastIndex].name,
                            turboThreshold: this.get('compareSymbolContent')[lastIndex].turboThreshold,
                            data: this.get('compareSymbolContent')[lastIndex].data,
                            type: this.get('compareSymbolContent')[lastIndex].type,
                            color: this.get('compareSymbolContent')[lastIndex].color,
                            yAxis: this.get('compareSymbolContent')[lastIndex].yAxis,
                            showInLegend: this.get('compareSymbolContent')[lastIndex].showInLegend,
                            events: this.get('compareSymbolContent')[lastIndex].events,
                            tooltip: this.get('compareSymbolContent')[lastIndex].tooltip,
                            animation: { duration: 3000, delay: 2000 }
                        }, true);
                    }
                    that.enableDisableCompareSymbolMode();
                }
                chart.redraw();
            } catch (e) {
                utils['default'].logger.logError('[Dynamic High Chart] compareSymbolContentDidChange() ' + e);
            }
        }).observes('compareSymbolContent.@each'),

        // update the compare symbols
        compareSymbolContentUpdate: (function () {
            var that = this;
            var chart = that.get('chart');
            var currentSeriesIndex = that.get('content').length;
            if (that.get('compareSymbolNeedsToUpdate')) {
                for (var j = 0; j < that.get('compareSymbolContent').length; j++) {
                    if (chart.series[currentSeriesIndex + j] !== undefined) {
                        chart.series[currentSeriesIndex + j].update({
                            id: that.get('compareSymbolContent')[j].id,
                            name: that.get('compareSymbolContent')[j].name,
                            data: that.get('compareSymbolContent')[j].data,
                            type: that.get('compareSymbolContent')[j].type,
                            dataGrouping: that.get('content')[0].dataGrouping,
                            lineWidth: that.get('compareSymbolContent')[j].lineWidth,
                            states: that.get('compareSymbolContent')[j].states,
                            tooltip: that.get('compareSymbolContent')[j].tooltip,
                            animation: { duration: 3000, delay: 2000 }
                        }, false);
                    }
                }
                chart.yAxis[0].update({}, true);
                chart.redraw();
                that.set('compareSymbolNeedsToUpdate', false);
            }
        }).observes('compareSymbolNeedsToUpdate'),

        // enable/disable compare symbol mode for scaling purpose
        enableDisableCompareSymbolMode: (function () {
            var that = this;
            var chart = that.get('chart');
            if (this.get('compareViewEnabled')) {
                for (var j = 0; j < chart.series.length; j++) {
                    if (chart.series[j] !== undefined) {
                        if (j === 1) {
                            continue;
                        }
                        chart.series[j].update({
                            compare: 'percent'
                        }, true);
                    }
                }
            } else {
                for (var i = 0; i < chart.series.length; i++) {
                    if (chart.series[i] !== undefined) {
                        chart.series[i].update({
                            compare: ''
                        }, true);
                    }
                }
            }
        }).observes('compareViewEnabled'),

        // This method use for scaling the chart when we switching between home page chart and main chart
        setScale: (function () {
            try {
                var that = this;
                var chart = that.get('chart');
                if (!that.get('compareViewEnabled')) {
                    chart.yAxis[0].setExtremes(that.minValue - that.getBottomPadding() > 0 ? that.minValue - that.getBottomPadding() : 0, that.maxValue + that.getTopPadding());
                    chart.yAxis[0].update({
                        min: that.minValue - that.getBottomPadding() > 0 ? that.minValue - that.getBottomPadding() : 0,
                        max: that.maxValue + that.getTopPadding()
                    }, true);
                } else {
                    chart.yAxis[0].setExtremes(undefined, undefined);
                    chart.yAxis[0].update({
                        min: undefined,
                        max: undefined
                    }, true);
                }
            } catch (e) {
                utils['default'].logger.logError('[Dynamic High Chart] setScale() ' + e);
            }
        }).observes('chart', 'minValue', 'maxValue', 'compareViewEnabled'),

        // This method use for show/hide volume
        showHideVolume: (function () {
            try {
                var that = this;
                var chart = that.get('chart');
                if (that.volumeViewEnabled) {
                    if (chart.series[1] !== undefined) {
                        if (!chart.series[1].visible) {
                            if (chart.yAxis[1] !== undefined) {
                                chart.yAxis[1].update({
                                    visible: true
                                });
                            }
                            that.reSizeYAxisWithIndicators(chart);
                            if (this.get('content')[1] !== undefined) {
                                chart.series[1].show();
                            }
                        }
                    }
                } else {
                    if (chart.series[1] !== undefined) {
                        chart.series[1].hide();
                        if (chart.yAxis[1] !== undefined) {
                            chart.yAxis[1].update({
                                visible: false
                            });
                        }
                        that.reSizeYAxisWithIndicators(chart);
                    }
                }
                //
            } catch (e) {
                utils['default'].logger.logError('[Dynamic High Chart] showHideVolume() ' + e);
            }
        }).observes('volumeViewEnabled'),

        chartIntervalChange: (function () {
            var that = this;
            var chart = that.get('chart');
            var chartInterval = that.get('chartInterval');
            var prevChartCategory = that.get('prevChartCategory');
            var chartCategory = that.get('chartCategory');
            if (chartCategory.DefaultChartViewPeriod === prevChartCategory.DefaultChartViewPeriod) {
                // we only need to update data grouping from here, when only changing the interval. When period change datagrouping update
                // inside contentDidChange() with data.
                for (var j = 0; j < chart.series.length; j++) {
                    chart.series[j].update({
                        dataGrouping: {
                            enabled: true,
                            forced: true,
                            units: [[chartInterval.unitName, chartInterval.unit]]
                        },
                        animation: { duration: 3000, delay: 2000 }
                    }, false);
                }
                chart.redraw();
            }
            that.set('prevChartCategory', that.get('chartCategory'));
        }).observes('chartInterval'),

        getTopPadding: function getTopPadding() {
            var that = this;
            // todo [Banusha] need to implement this proper way
            return (that.maxValue - that.minValue) / 4;
        },
        getBottomPadding: function getBottomPadding() {
            var that = this;
            // todo [Banusha] need to implement this proper way
            return (that.maxValue - that.minValue) / 8;
        },

        reScaleTheChart: (function () {
            try {
                var that = this;
                var chart = that.get('chart');
                chart.setSize(that.chartWidth, that.chartHeight);
                chart.redraw();
                chart.reflow();
                var numOfYAxis = chart.yAxis.length - 1; // Number of Y Axis with out main y axis
                if (numOfYAxis === 1 && that.volumeViewEnabled) {
                    utils['default'].logger.logInfo('reScaleTheChart() - There are no indicators only volume is display');
                } else {
                    that.reSizeYAxisWithIndicators(chart);
                }
            } catch (e) {
                utils['default'].logger.logError('[Dynamic High Chart] reScaleTheChart() ' + e);
            }
        }).observes('chartHeight', 'chartWidth'),

        drawIndicators: (function () {
            try {
                var that = this;
                var chart = that.get('chart');
                if (chart.series[0].indicators !== undefined) {
                    var ind;
                    while (chart.series[0].indicators.length > 0) {
                        ind = chart.series[0].indicators[0];
                        ind.destroy();
                    }
                }
                var indi;
                for (var i = 0; i < this.get('indicatorList').length; i++) {
                    indi = this.get('indicatorList')[i];
                    chart.addIndicator(indi.Params, that.get('content')[0]);
                }
                that.reSizeYAxisWithIndicators(chart);
            } catch (e) {
                utils['default'].logger.logError('[Dynamic High Chart] drawIndicators() ' + e);
            }
        }).observes('indicatorList.@each'),

        clearIndicators: (function () {
            try {
                var that = this;
                var chart = that.get('chart');
                if (chart.series[0].indicators !== undefined) {
                    var ind;
                    while (chart.series[0].indicators.length > 0) {
                        ind = chart.series[0].indicators[0];
                        ind.destroy();
                    }
                }
            } catch (e) {
                utils['default'].logger.logError('[Dynamic High Chart] clearIndicators() ' + e);
            }
        }).observes('closeMode'),

        reSizeYAxisWithIndicators: function reSizeYAxisWithIndicators(chart) {
            try {
                var that = this;
                var numOfYAxis = chart.yAxis.length - 1; // Number of Y Axis with out main y axis
                var additionalYAxisHeightPrecentage = that.volumeChartHeightPctg === undefined ? 25 : that.volumeChartHeightPctg;
                var additionalYAxisHeightGap = 5;
                var totalAdditionalYAxisHeight = 0;
                for (var i = 1; i <= numOfYAxis; i++) {
                    if (i === 1 && !that.volumeViewEnabled) {
                        // when volume bar is hide.
                        continue;
                    } else {
                        totalAdditionalYAxisHeight += additionalYAxisHeightPrecentage + additionalYAxisHeightGap;
                    }
                }

                var mainYAxisHeight = 100 - totalAdditionalYAxisHeight;
                chart.yAxis[0].update({
                    height: mainYAxisHeight + '%'
                });
                var incrementYAxisValue = mainYAxisHeight + additionalYAxisHeightGap;
                for (var j = 1; j <= numOfYAxis; j++) {
                    if (j === 1 && !that.volumeViewEnabled) {
                        // when volume bar is hide.
                        continue;
                    } else {
                        chart.yAxis[j].update({
                            top: incrementYAxisValue + '%',
                            height: additionalYAxisHeightPrecentage + '%'
                        });
                        incrementYAxisValue += additionalYAxisHeightPrecentage + additionalYAxisHeightGap;
                    }
                }
            } catch (e) {
                utils['default'].logger.logError('[Dynamic High Chart] reSizeYAxisWithIndicators() ' + e);
            }
        },

        chartDataLoadingIndicator: (function () {
            var chart = this.get('chart');
            if (!this.get('dataLoadingComplete')) {
                chart.showLoading();
            } else {
                chart.hideLoading();
            }
        }).observes('dataLoadingComplete'),

        changeXaxisCat: (function () {

            var chart = this.get('chart');
            chart.xAxis[0].setCategories(this.get("xAxisCatTemp"));
        }).observes('xAxisCatTemp'),

        showHideSeries: (function () {
            var _this = this;

            var chart = this.get('chart');
            chart.series.forEach(function (series) {
                if (_this.get('showSeries')) {
                    series.show();
                } else {
                    series.hide();
                }
            });
        }).observes('showSeries'),

        CAMarkers: (function () {
            var chart = this.get('chart');
            var showHide = this.get('showHide');
            var markID = this.get('markerId');

            chart.series.forEach(function (series) {
                series.data.forEach(function (point, i) {
                    // if(point.marker && point.marker.id == markID){
                    //     point.marker.radius = 6;
                    //
                    //     point.marker.enabled = showHide;
                    // }

                    if (point.dataLabels && point.dataLabels.id == markID) {
                        point.dataLabels.enabled = showHide;
                    } else if (point.dataLabels && markID == '') {
                        point.dataLabels.enabled = showHide;
                    }
                });
            });
            //  if(this.volumeViewEnabled){
            this.reloadChart();
            // }
            chart.redraw();
        }).observes('showHide'),

        AnnouncementsMarkers: (function () {
            var chart = this.get('chart');
            var showHide = this.get('announcementsMarkers');
            var markID = this.get('markerId');
            chart.series.forEach(function (series) {

                series.data.forEach(function (point, i) {

                    // if(point.marker && point.marker.id == markID){
                    //     point.marker.radius = 6;
                    //     point.marker.enabled = showHide;
                    // }
                    if (point.dataLabels && point.dataLabels.id == markID) {
                        point.dataLabels.enabled = showHide;
                    } else if (point.dataLabels && markID == '') {
                        point.dataLabels.enabled = showHide;
                    }
                });
            });
            // if(this.volumeViewEnabled){
            this.reloadChart();
            // }
            chart.redraw();
        }).observes('announcementsMarkers'),

        observeDataArray: (function () {
            this.contentDidChange();
        }).observes('dataArray')

    });

});