define('ir-app/models/price/price-business-entities/fundamentals/fundamental-constants', ['exports'], function (exports) {

    'use strict';

    /**
     * Created by banushas on 8/15/2016.
     */
    exports['default'] = {

        ViewTypes: {
            VIEW_TYPE_ANNUAL: 'A',
            VIEW_TYPE_QUARTER: 'Q',
            VIEW_TYPE_CUMULATIVE: 'C',
            VIEW_TYPE_TTM: 'T'
        },

        BreakDown: {
            CollapsedView: '1',
            ExpandView: '2'
        },

        NonMillionFields: ['IS_RotBasEPS', 'IS_RptDilEPS', 'IS_ReptDilEPS', 'IS_RprtdDilEPS', 'MS_DilutedEPS', 'DILUTEDEPS'],

        StatementTypes: {
            FinancialRatios: 'FR',
            MarketRatios: 'MR'
        },

        CalendarTypes: {
            CALENDAR_YEAR: 1,
            FISCAL_YEAR: 0
        }
    };

});