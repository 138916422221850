define('ir-app/controllers/ir-widgets/insider-transaction-widgets/insider-transactions', ['exports', 'ember', 'ir-app/controllers/base-array-controller', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/models/price/price-constants', 'ir-app/utils/utils', 'ir-app/config/user-settings', 'ir-app/models/shared/language/language-data-store'], function (exports, Ember, BaseArrayController, priceService, sharedService, priceConstants, utils, userSettings, LanguageDataStore) {

    'use strict';

    /**
     * Created by lakmaln on 7/6/2016.
     */

    exports['default'] = BaseArrayController['default'].extend({

        tradesForList: Ember['default'].A(),
        showTradesFor: '',
        buyCount: 0,
        sellCount: 0,
        buyVolume: 0,
        sellVolume: 0,
        buyEstValue: 0,
        sellEstValue: 0,

        onLoadWidget: function onLoadWidget() {
            this.setErrorMessage();
        },

        onPrepareData: function onPrepareData() {
            var that = this;
            var insiderTrades = priceService['default'].insiderTradesDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, function () {
                that.onDataSuccess();
            }, function () {
                that.onDataError();
            }, priceConstants['default'].DataTypes.InsiderTradesDataStore.InsiderTrades).insiderTrades;

            this.set('model', insiderTrades);
        },

        onCheckDataAvailability: function onCheckDataAvailability() {
            var insiderTradesObj = priceService['default'].insiderTradesDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage);

            var isAvailable = insiderTradesObj && insiderTradesObj.insiderTrades && insiderTradesObj.insiderTrades.length > 0;
            if (isAvailable) {
                this.set('tradesForList', priceService['default'].insiderTradesDS.getTradesForList());
                this.set('showTradesFor', this.get('tradesForList')[0].trdFor);
            }
            return isAvailable;
        },

        onLanguageChanged: function onLanguageChanged() {
            this.onPrepareData();
        },

        onUnloadWidget: function onUnloadWidget() {
            this.set('model', Ember['default'].A());
        },

        filteredContent: (function () {
            var that = this;
            that.set('buyCount', 0);
            that.set('sellCount', 0);
            that.set('buyVolume', 0);
            that.set('sellVolume', 0);
            that.set('buyEstValue', 0);
            that.set('sellEstValue', 0);
            var content = that.get('arrangedContent');

            if (content) {
                return content.filter(function (item) {

                    if (item.name === that.get('showTradesFor') || that.get('showTradesFor') === that.get('app').lang.labels.all) {

                        if (item.type === '1') {
                            var totalBuyCount = that.get('buyCount') + 1;
                            that.set('buyCount', totalBuyCount);
                            var totalBuyVolume = that.get('buyVolume') + item.qty;
                            that.set('buyVolume', totalBuyVolume);
                            var totalBuyEstValue = that.get('buyEstValue') + item.qty * item.vwap;
                            that.set('buyEstValue', totalBuyEstValue);
                        } else {
                            var totalSellCount = that.get('sellCount') + 1;
                            that.set('sellCount', totalSellCount);
                            var totalSellVolume = that.get('sellVolume') + item.qty;
                            that.set('sellVolume', totalSellVolume);
                            var totalSellEstValue = that.get('sellEstValue') + item.qty * item.vwap;
                            that.set('sellEstValue', totalSellEstValue);
                        }
                        return true;
                    }
                });
            }
        }).property('arrangedContent', 'showTradesFor'),

        setErrorMessage: (function () {
            if (this.get('filteredContent').length > 0) {
                this.hideDataErrorMessage();
            } else {
                this.set('errorMessage', this.get('app').lang.messages.dataNotAvailable);
                this.showDataErrorMessage();
            }
        }).observes('filteredContent'),

        actions: {
            changeShowTradesFor: function changeShowTradesFor(option) {
                this.set('showTradesFor', option.trdFor);
                this.set(this.set('current', 1));
            },
            isValid: function isValid(value) {
                if (value !== '') {
                    return true;
                } else {
                    return false;
                }
            }
        }
    });

    Ember['default'].Handlebars.helper('getNetValue', function (buyValue, sellValue, decimalPlaces) {
        var noOfDecimals = !isNaN(decimalPlaces) ? decimalPlaces : userSettings['default'].displayFormat.decimalPlaces;

        var dif = 0;
        if (buyValue > sellValue) {
            dif = utils['default'].formatters.formatNumber(buyValue - sellValue, noOfDecimals);
            return LanguageDataStore['default'].getLanguageObj().lang.labels.buy + ' : ' + dif;
        } else if (sellValue > buyValue) {
            dif = utils['default'].formatters.formatNumber(sellValue - buyValue, noOfDecimals);
            return LanguageDataStore['default'].getLanguageObj().lang.labels.sell + ' : ' + dif;
        } else {
            return dif;
        }
    });

});