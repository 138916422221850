define('ir-app/controllers/ir-widgets/watchlist-widget/portal-watch-lists', ['exports', 'ember', 'ir-app/controllers/base-array-controller', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service'], function (exports, Ember, BaseArrayController, priceService, sharedService) {

    'use strict';

    exports['default'] = BaseArrayController['default'].extend({

        content: Ember['default'].A(),
        sortProperties: ['sDes'],
        sortAscending: true,
        sectors: Ember['default'].A(),
        // Filter parameters
        textFilter: null,
        lang: undefined,
        clientStkPage: undefined,
        isAccumTab: false,
        exgObj: undefined,
        // reference for scroll event listening scope binded function
        scrollController: undefined,

        onLoadWidget: function onLoadWidget() {

            this.makeTblHeadsSticky();
        },

        onPrepareData: function onPrepareData() {
            var that = this;
            var exchange = this.get('exg');
            this.set('exgObj', priceService['default'].exchangeDS.getExchange(exchange));

            if (exchange === 'TDWL') {
                this.set('isAccumTab', true);
            } else {
                this.set('isAccumTab', false);
            }

            priceService['default'].downloadParamsSymbolMasterForExchange({
                exchange: that.get('exg'),
                language: sharedService['default'].userSettings.currentLanguage,
                successFn: function successFn() {
                    that.onSymbolDataSuccess();
                },
                errorFn: function errorFn() {
                    that.onError();
                }
            });

            priceService['default'].downloadExchangeProperty(that.get('exg'), sharedService['default'].userSettings.currentLanguage);

            priceService['default'].addFullMarketSymbolRequest(exchange);

            that.set('lang', sharedService['default'].userSettings.currentLanguage.toLowerCase());
            that.set('clientStkPage', that.get('clientStkPage'));
        },

        onClearData: function onClearData() {
            var exchange = this.get('exg');
            priceService['default'].removeFullMarketSymbolRequest(exchange);
        },

        onUnloadWidget: function onUnloadWidget() {
            var that = this;
            if (window.removeEventListener) {
                window.removeEventListener('message', that.get('scrollController'), false);
            } else {
                window.detachEvent('message', that.get('scrollController'));
            }
            Ember['default'].$(window).off('scroll');
            this.set('textFilter', null);
            this.set('sector', null);
            this.set('masterContent', Ember['default'].A());
        },

        onLanguageChanged: function onLanguageChanged() {
            this.setSectors();
            this.loadWLContent();
        },

        onSymbolDataSuccess: function onSymbolDataSuccess() {
            var that = this;
            priceService['default'].downloadParamsExchangeMetaData({
                exchange: that.get('exg'),
                language: sharedService['default'].userSettings.currentLanguage,
                successFn: function successFn() {
                    that.onSuccess();
                },
                errorFn: function errorFn() {
                    that.onError();
                }
            });
        },

        onSuccess: function onSuccess() {
            this.setSectors();
            this.loadWLContent();
            // todo [Banusha] loadWLContent() method call for observe the DCFS data change (masterContent.@each.dcfs).We need to check another alternative way.Ember support @each only works one level deep.
        },

        onError: function onError() {},

        loadWLContent: function loadWLContent() {
            var exchange = this.get('exg');
            var store = priceService['default'].stockDS.get('stockMapByExg');

            if (!store[exchange]) {
                store[exchange] = Ember['default'].A();
                priceService['default'].stockDS.set('stockMapByExg', store);
            }
            this.set('masterContent', store[exchange]);
        },

        setSectors: function setSectors() {
            if (this.get('sectors').length > 0) {
                // On language change, remove previously applied sector desc
                this.set('sectors', Ember['default'].A());
            }

            this.set('sectors', priceService['default'].sectorDS.getSectorCollectionByExchange(this.get('exg')));
        },

        getSectors: (function () {
            return this.get('sectors');
        }).property('sectors', 'sectors.@each'),

        sortedContent: (function () {
            return this.get('arrangedContent');
        }).property('arrangedContent'),

        txtFilterAdded: (function () {
            return this.utils.validators.isAvailable(this.get('textFilter'));
        }).property('textFilter'),

        checkFilterMatch: function checkFilterMatch(stock, textFilter, sectorFilter, accumulatedLossFilter) {
            var field;
            var isMatchedTextFilter = !textFilter; // If a argument is false, that means that filter is not applied
            var isMatchedSectorFilter = !sectorFilter;
            var isAccumulatedLossFilter = !accumulatedLossFilter;

            if (!isMatchedTextFilter) {
                for (field in stock) {
                    if (stock.hasOwnProperty(field) && (field === 'sym' || field === 'sDes') && stock[field] && this.filterWord(stock[field].toString(), textFilter)) {
                        isMatchedTextFilter = true;
                    }
                }
            }
            if (!isMatchedSectorFilter) {
                isMatchedSectorFilter = stock.get('sec') === sectorFilter;
            }

            if (!isAccumulatedLossFilter) {
                isAccumulatedLossFilter = stock.get('dcfs') === '1' || stock.get('dcfs') === '2' || stock.get('dcfs') === '3';
            }

            return isMatchedTextFilter && isMatchedSectorFilter && isAccumulatedLossFilter;
        },

        filterWord: function filterWord(stringFiled, filterText) {
            filterText = filterText.replace(/\s+$/, "");
            return stringFiled.toString().slice(0, filterText.length).toLowerCase() === filterText.toLowerCase();
        },

        filterStocks: (function () {
            var that = this;
            var textFilter = this.utils.validators.isAvailable(this.get('textFilter')) ? this.get('textFilter') : false; // If any filter is false, that means that filter is not applied
            var sectorFilter = this.utils.validators.isAvailable(this.get('sector')) ? this.get('sector') : false;
            var accumulatedLossFilter = this.get('isAccumulatedLossFilter');

            this.sectors.forEach(function (sector) {
                var filteredStocks = sector.get('sortedContent').filter((function (that) {
                    //eslint-disable-line
                    return function (stock) {

                        if (textFilter || sectorFilter || accumulatedLossFilter) {
                            return that.checkFilterMatch(stock, textFilter, sectorFilter, accumulatedLossFilter);
                        } else {
                            return true;
                        }
                    };
                })(that));

                sector.set('getSymbolList', filteredStocks);
            });
            // Need to capture filter removing event to avoid 'set' without filters
        }).observes('textFilter', 'sector', 'isAccumulatedLossFilter', 'masterContent.@each.dcfs', 'sectors.@each'),

        actions: {
            onTabSelectionChanged: function onTabSelectionChanged(option) {
                if (option === 'loss') {
                    this.set('isAccumulatedLossFilter', true);
                } else {
                    this.set('isAccumulatedLossFilter', false);
                }
            }
        },

        onAfterRender: function onAfterRender() {
            var originalHead = Ember['default'].$('#tbl-allstocks > thead');
            var stickyHead = originalHead.clone();
            originalHead.addClass('original');
            stickyHead.addClass('clone display-none');
            originalHead.after(stickyHead);
        },

        makeTblHeadsSticky: function makeTblHeadsSticky() {

            var that = this;
            that.set('scrollController', that.controlScroll.bind(that));
            if (that.get('parentDomains')) {
                // parent site controls the table headers stickiness

                if (window.addEventListener) {
                    window.addEventListener('message', that.get('scrollController'), false);
                } else if (window.attachEvent) {
                    // IE
                    window.attachEvent('onmessage', that.get('scrollController'));
                }
            } else {
                // application itself controls the table headers stickiness

                Ember['default'].$(window).off('scroll');
                Ember['default'].$(window).bind('scroll', function () {
                    var currentHeight = Ember['default'].$(window).scrollTop();
                    var heightToHead = Ember['default'].$('.original').offset().top;
                    var heightToBotm = Ember['default'].$('#tbl-allstocks').offset().top + Ember['default'].$('#tbl-allstocks').outerHeight(true);
                    that.fixHeader(currentHeight, heightToHead, heightToBotm);
                });
            }
        },

        controlScroll: function controlScroll(event) {
            var that = this;
            var parentDomains = that.get('parentDomains');
            if (parentDomains.indexOf(event.origin) < 0) {
                // validate message sender
                return;
            }
            var parentScrollMessage = event.data.split('=')[1]; // format   parentScroll=scrollVal,iframTop
            var currentHeight = parseInt(parentScrollMessage.split(',')[0], 10);
            var heightToHead = parseInt(parentScrollMessage.split(',')[1], 10) + Ember['default'].$('.original').offset().top;
            var heightToBotm = parseInt(parentScrollMessage.split(',')[1], 10) + Ember['default'].$('#tbl-allstocks').offset().top + Ember['default'].$('#tbl-allstocks').outerHeight(true);
            that.fixHeader(currentHeight, heightToHead, heightToBotm);
        },

        fixHeader: function fixHeader(currentHeight, heightToHead, heightToBotm) {

            if (heightToBotm > currentHeight && currentHeight > heightToHead) {
                Ember['default'].$('.clone').removeClass('display-none');
                Ember['default'].$('.clone').addClass('absolute display-block');
                Ember['default'].$('.clone').css('top', currentHeight - heightToHead);
            } else {
                Ember['default'].$('.clone').css('top', '0px');
                Ember['default'].$('.clone').removeClass('absolute display-block');
                Ember['default'].$('.clone').addClass('display-none');
            }
        }

    });

});