define('ir-app/controllers/ir-widgets/announcement-widgets/announcement-body', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service'], function (exports, Ember, BaseController, priceService, sharedService) {

    'use strict';

    exports['default'] = BaseController['default'].extend({

        annObj: null,

        onPrepareData: function onPrepareData() {
            var that = this;
            that.startLoadingProgress();
            var annId = this.get('exg'); // get 'exg' parameter on the Url as announcement Id
            var annObj = priceService['default'].announcementDS.getAnnouncement(annId);
            this.set('annObj', annObj);

            if (!annObj.get('isBodyUpdated')) {
                that.annObj.set('bod', that.get('app').lang.messages.loading);
                priceService['default'].sendNewsAnnBodyRequest({
                    id: annObj.get('id'),
                    type: annObj.get('type'),
                    reqSuccessFn: function reqSuccessFn() {
                        that.stopLoadingProgress();
                        that.set('isDataAvailable', true);

                        Ember['default'].run.later(function () {
                            Ember['default'].$(document).ready(function () {
                                Ember['default'].$('#annBody a').attr('target', '_blank');
                            });
                        }, 300);
                    },
                    reqFailureFn: function reqFailureFn() {
                        that.stopLoadingProgress();
                        that.annObj.set('isBodyUpdated', false);
                    },
                    lan: sharedService['default'].userSettings.currentLanguage
                });
            }
        },

        onLanguageChanged: function onLanguageChanged() {
            this.onPrepareData();
        }
    });

});