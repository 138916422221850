define('ir-app/models/decypha/decypha-protocols/decypha-response-handler', ['exports', 'ember', 'ir-app/utils/utils'], function (exports, Ember, Utils) {

    'use strict';

    /**
     * Created by udaras on 10/13/2016.
     */
    exports['default'] = (function () {

        var processNewsSearchResponse = function processNewsSearchResponse(dataObj, params) {

            try {
                if (dataObj && Ember['default'].$.isFunction(params.reqSuccessFn)) {
                    params.reqSuccessFn(dataObj);
                }
            } catch (e) {
                if (Ember['default'].$.isFunction(params.reqFailureFn)) {
                    params.reqFailureFn();
                }

                Utils['default'].logger.logError('Error in processing news search response : ' + e);
            }
        };

        var processCompanyScreenerResponse = function processCompanyScreenerResponse(dataObj, params) {

            try {
                if (dataObj && Ember['default'].$.isFunction(params.reqSuccessFn)) {
                    params.reqSuccessFn(dataObj);
                }
            } catch (e) {
                if (Ember['default'].$.isFunction(params.reqFailureFn)) {
                    params.reqFailureFn();
                }

                Utils['default'].logger.logError('Error in processing company screener response : ' + e);
            }
        };

        return {
            processNewsSearchResponse: processNewsSearchResponse,
            processCompanyScreenerResponse: processCompanyScreenerResponse
        };
    })();

});