define('ir-app/templates/ir-mobile/tab-layouts/compact-financial-layout', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n    $(\"#com-financial-chart-btn\").click(function() {\n        $(\"#com-financial-chart-btn\").addClass(\"com-btn-act\");\n        $(\"#com-financial-list-btn\").removeClass(\"com-btn-act\");\n        $(\"#com-financial-chart-view\").css('height', 'auto');\n        $(\"#com-financial-list-view\").css('height', '0');\n    });\n    $(\"#com-financial-list-btn\").click(function() {\n        $(\"#com-financial-list-btn\").addClass(\"com-btn-act\");\n        $(\"#com-financial-chart-btn\").removeClass(\"com-btn-act\");\n        $(\"#com-financial-list-view\").css('height', 'auto');\n        $(\"#com-financial-chart-view\").css('height', '0');\n    });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","full-height");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","row");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col-xs-12 p-relative compact-financials");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"type","button");
        dom.setAttribute(el4,"class","fa com-btn-act com-financial-list-btn");
        dom.setAttribute(el4,"id","com-financial-list-btn");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"type","button");
        dom.setAttribute(el4,"class","fa com-financial-chart-btn");
        dom.setAttribute(el4,"id","com-financial-chart-btn");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"id","com-financial-list-view");
        dom.setAttribute(el4,"class","com-financial-list-view");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"id","com-financial-chart-view");
        dom.setAttribute(el4,"class","com-financial-chart-view");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, inline = hooks.inline;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element0 = dom.childAt(fragment, [2, 1, 1]);
        var morph0 = dom.createMorphAt(dom.childAt(element0, [5]),0,0);
        var morph1 = dom.createMorphAt(dom.childAt(element0, [7]),0,0);
        inline(env, morph0, context, "outlet", ["w1"], {});
        inline(env, morph1, context, "outlet", ["w2"], {});
        return fragment;
      }
    };
  }()));

});