define('ir-app/models/price/price-business-entities/ipo-list', ['exports', 'ember', 'ir-app/utils/utils'], function (exports, Ember, utils) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({

        // Basic Information
        id: '', // IPO Id
        exg: '', // Exchange
        sym: '', // Symbol
        annDate: '', // Announce Date
        company: '', // Company
        compId: '', // Company Id
        country: '', // Country
        status: '', // Status Desc
        statId: '', // Status Id
        type: '', // type desc
        typeId: '', // type Id
        currency: '', // Currency
        size: '', // size
        volumeCov: '', // volume Coverage
        isePce: '', // Issue Price
        startDt: '', // Start Date
        endDt: '', // End Date
        offered: '', // Coverage Pct
        dwnUrl: '', // Download Url
        indId: '', // Industry ID
        indDesc: '', // Industry Name
        tradeDate: '', // Trading Date
        adjPrcTrade: '', // Adjusted Price of Trading Date

        dspSize: (function () {
            var volume = this.get('size');
            var currency = this.get('currency');
            var ipoInMillion = volume && Number(volume) > 0 ? volume / 1000000 : undefined; // data set to millions
            return utils['default'].formatters.appendCurrencyToValue(ipoInMillion, currency, 2);
        }).property('size', 'currency'),

        setData: function setData(ipoDataList) {
            var that = this;

            Ember['default'].$.each(ipoDataList, function (key, value) {
                that.set(key, value);
            });
        }

    });

});