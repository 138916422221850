define('ir-app/components/modal-popup', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        isEnabled: false,

        expose: (function () {
            var parentController = this.get('targetObject');
            var exposedName = this.get('id');
            parentController.set(exposedName, this);
        }).on('didInsertElement'),

        actions: {
            closeModalPopup: function closeModalPopup() {
                if (this.get('isEnabled')) {
                    this.set('isEnabled', false);
                }
            },

            showModalPopup: function showModalPopup() {
                if (!this.get('isEnabled')) {
                    this.set('isEnabled', true);
                }
            }
        }
    });

});