define('ir-app/models/shared/communication-adapters/web-http-connection', ['exports', 'ember', 'ir-app/models/price/price-constants', 'ir-app/utils/utils'], function (exports, Ember, PriceConstants, utils) {

    'use strict';

    exports['default'] = (function () {
        var sendAjaxRequest = function sendAjaxRequest(param) {
            try {
                var req = {
                    type: param.type ? param.type : 'GET',
                    dataType: param.dataType ? param.dataType : 'json',
                    contentType: param.contentType ? param.contentType : 'application/json; charset=utf-8',
                    url: param.url,

                    success: function success(data, textStatus, xhr) {
                        if (Ember['default'].$.isFunction(param.onSuccess)) {
                            param.onSuccess(data, textStatus, xhr);
                        }
                    },

                    error: function error(e) {
                        if (Ember['default'].$.isFunction(param.onError)) {
                            param.onError(e);
                        }
                    },

                    timeout: param.timeout ? param.timeout : PriceConstants['default'].TimeIntervals.AjaxRequestTimeout
                };

                if (param.async) {
                    req.async = param.async;
                }

                if (param.cache) {
                    req.cache = param.cache;
                }

                if (param.data) {
                    req.data = param.data;
                }

                Ember['default'].$.ajax(req);
            } catch (ex) {
                utils['default'].logger.logError('Error in send ajax request : ' + param.url);

                if (Ember['default'].$.isFunction(param.onError)) {
                    param.onError({});
                }
            }
        };

        return {
            sendAjaxRequest: sendAjaxRequest
        };
    })();

});