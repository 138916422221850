define('ir-app/models/price/price-data-stores/advanced-fundermental-data-store', ['exports', 'ember', 'ir-app/utils/utils'], function (exports, Ember, utils) {

    'use strict';

    /**
     * Created by banushas on 8/11/2016.
     */
    exports['default'] = Ember['default'].Object.extend({
        store: {},

        addDataHolderToStore: function addDataHolderToStore(exchange, symbol, dataHolder, mode, isCal) {
            var key = utils['default'].keyGenerator.getKey(exchange, symbol);
            var secondaryKey;
            var store = this.get('store');
            var financialStore = store[key];
            if (financialStore) {
                secondaryKey = utils['default'].keyGenerator.getKey(mode, isCal);
                financialStore[secondaryKey] = dataHolder;
            } else {
                financialStore = {};
                secondaryKey = utils['default'].keyGenerator.getKey(mode, isCal);
                financialStore[secondaryKey] = dataHolder;
                store[key] = financialStore;
            }
        },

        getStyleObject: function getStyleObject(exchange, symbol, type, mode, isCal) {
            var key = utils['default'].keyGenerator.getKey(exchange, symbol);
            var secondaryKey;
            var store = this.get('store');
            if (store[key]) {
                var modeHolder = store[key];
                secondaryKey = utils['default'].keyGenerator.getKey(mode, isCal);
                if (modeHolder[secondaryKey]) {
                    var dataHolder = modeHolder[secondaryKey];
                    var styleTable = dataHolder.get('styleTable');
                    if (styleTable[type]) {
                        return styleTable[type];
                    }
                }
            }
            return undefined;
        },

        getDataObject: function getDataObject(exchange, symbol, type, mode, isCal) {
            var key = utils['default'].keyGenerator.getKey(exchange, symbol);
            var secondaryKey;
            var store = this.get('store');
            if (store[key]) {
                var modeHolder = store[key];
                secondaryKey = utils['default'].keyGenerator.getKey(mode, isCal);
                if (modeHolder[secondaryKey]) {
                    var dataHolder = modeHolder[secondaryKey];
                    var dataTable = dataHolder.get('dataTable');
                    if (dataTable[type]) {
                        return dataTable[type];
                    }
                }
            }
            return undefined;
        },

        getMinMaxObject: function getMinMaxObject(exchange, symbol, type, mode, isCal) {
            var key = utils['default'].keyGenerator.getKey(exchange, symbol);
            var secondaryKey;
            var store = this.get('store');
            if (store[key]) {
                var modeHolder = store[key];
                secondaryKey = utils['default'].keyGenerator.getKey(mode, isCal);
                if (modeHolder[secondaryKey]) {
                    var dataHolder = modeHolder[secondaryKey];
                    var dataTable = dataHolder.get('minMaxTable');
                    if (dataTable[type]) {
                        return dataTable[type];
                    }
                }
            }
            return undefined;
        }

    }).create();

});