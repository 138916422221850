define('ir-app/controllers/ir-widgets/corporate-actions/non-capital-action-list', ['exports', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/controllers/base-array-controller', 'ir-app/models/price/price-constants'], function (exports, priceService, sharedService, BaseArrayController, priceConstants) {

    'use strict';

    exports['default'] = BaseArrayController['default'].extend({

        count: undefined,
        current: undefined,
        isFiltered: false,
        isCustomPage: false,
        pageSize: 20,
        corporateMetaDes: [],
        isDropDownSelected: false,
        selectedId: undefined,
        onPrepare: false,
        actionType: undefined,
        basicInfo: {},
        finalizedList: {},

        onLoadWidget: function onLoadWidget() {
            this.setErrorMessage();
        },

        onPrepareData: function onPrepareData() {
            var that = this;
            that.startLoadingProgress();
            var corporateMetaData = priceService['default'].corporateMetaDS.getStoredObject(priceConstants['default'].DataTypes.CorporateActionDataStore.NonCapital, sharedService['default'].userSettings.currentLanguage, function () {
                that.onDataSuccess();
            }, function () {
                that.onDataError();
            }).corporateMetaData;

            this.set('corporateMetaDes', corporateMetaData);

            var basicInfo = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, function () {
                that.onDataSuccess();
            }, function () {
                that.onDataError();
            }, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyBasicInfo);

            this.set('basicInfo', basicInfo);

            var nonCorporateAction = priceService['default'].nonCapitalDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, priceConstants['default'].DataTypes.CorporateActionDataStore.SymbolBasedCall, function () {
                that.onDataSuccess();
            }, function () {
                that.onDataError();
            }).nonCapitalAction;

            this.set('finalizedList', nonCorporateAction);
            this.set('onPrepare', true);
        },

        onCheckDataAvailability: function onCheckDataAvailability() {

            var dataAvailable = false;
            var nonCorporateAction = priceService['default'].nonCapitalDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage).nonCapitalAction;
            if (nonCorporateAction && nonCorporateAction.length > 0) {
                this.loadModelListData();
                dataAvailable = true;
            }

            var corporateMetaDes = this.get('corporateMetaDes');

            return corporateMetaDes && corporateMetaDes.length > 0 && dataAvailable;
        },

        onLanguageChanged: function onLanguageChanged() {
            this.onPrepareData();
        },

        filteredContent: (function () {
            // TODO Review the code [Rajith] need to check variable usage
            var content = this.get('arrangedContent');
            var selectId = this.get('selectedId');
            if (this.get('isCustomPage')) {
                if (this.get('onPrepare')) {
                    return content;
                } else {
                    if (selectId) {
                        return content.filter(function (item) {
                            return item.typeId === selectId;
                        });
                    } else {
                        return content;
                    }
                }
            } else {
                var contentLst = this.get('finalizedList');
                if (this.get('isDropDownSelected')) {
                    if (contentLst) {
                        if (Number(selectId) === 0) {
                            this.set('current', 1);
                            this.set('count', Math.ceil(contentLst.length / this.get('pageSize')));
                            return contentLst.slice(0, this.get('pageSize'));
                        }
                        var tmpLst = contentLst.filter(function (item) {
                            return item.typeId === selectId;
                        });
                        this.set('current', 1);
                        this.set('count', Math.ceil(tmpLst.length / this.get('pageSize')));
                        return tmpLst.slice(0, this.get('pageSize'));
                    }
                } else {
                    this.set('current', 1);
                    this.set('count', Math.ceil(content.length / this.get('pageSize')));
                    return content.slice(0, this.get('pageSize'));
                }
            }
        }).property('arrangedContent', 'selectedId'),

        _generateCustomPage: function _generateCustomPage(pageNo) {
            var pgeSize = this.get('pageSize');
            var startInx = Number((pageNo - 1) * pgeSize);
            var endIdx = Number(pageNo * pgeSize);
            this.set('current', pageNo);
            var cpLst = this.get('finalizedList');
            this.set('content', cpLst.slice(startInx, endIdx));
        },

        setErrorMessage: (function () {
            if (this.get('filteredContent').length > 0) {
                this.hideDataErrorMessage();
            } else if (!this.get('isLoading')) {
                this.showDataErrorMessage();
                this.set('errorMessage', this.get('app').lang.messages.dataNotAvailable);
            }
        }).observes('filteredContent'),

        loadCompanyIdListData: (function () {
            var that = this;
            var companyId = this.get('basicInfo').compID;
            if (companyId) {
                var companyIdList = priceService['default'].nonCapitalDS.getStoredObject(that.get('exg'), that.get('sym'), sharedService['default'].userSettings.currentLanguage, priceConstants['default'].DataTypes.CorporateActionDataStore.CompanyBasedCall, function () {
                    that.onDataSuccess();
                }, function () {
                    that.onDataError();
                }, companyId).nonCapitalAction;

                this.set('finalizedList', companyIdList);
            }
        }).observes('basicInfo.compID'),

        loadModelListData: (function () {
            var tempArray = this.get('finalizedList');
            if (tempArray && tempArray.length > 0) {
                this.set('count', Math.ceil(tempArray.length / this.get('pageSize')));
                this.set('model', tempArray);
            }
        }).observes('finalizedList'),

        actions: {

            corpActDropdownFrom: function corpActDropdownFrom(option) {
                this.set('isDropDownSelected', true);
                this.set('isCustomPage', false);
                this.set('selectedId', option.id);
                this.set('onPrepare', false);
            },
            pageChanged: function pageChanged(current) {
                if (Number(current) === 0) {
                    this.set('isCustomPage', false);
                } else {
                    this.set('isCustomPage', true);
                }
                this.set('current', current);
                this.set('isDropDownSelected', false);
                this._generateCustomPage(current);
                this.set('onPrepare', false);
            }
        }

    });

});