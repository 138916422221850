define('ir-app/utils/crypto', ['exports', 'ir-app/utils/utils'], function (exports, utils) {

    'use strict';

    /* global CryptoJS */
    exports['default'] = (function () {
        var encryptText = function encryptText(plainText) {
            var keyUtf8 = CryptoJS.enc.Utf8.parse(utils['default'].Constants.Encryption.Key);
            var ivUtf8 = CryptoJS.enc.Utf8.parse(utils['default'].Constants.Encryption.Iv);
            var encryptedUtf8 = CryptoJS.TripleDES.encrypt(plainText, keyUtf8, { iv: ivUtf8 });

            return encryptedUtf8.toString();
        };

        var decryptText = function decryptText(cipherText) {
            var keyUtf8 = CryptoJS.enc.Utf8.parse(utils['default'].Constants.Encryption.Key);
            var ivUtf8 = CryptoJS.enc.Utf8.parse(utils['default'].Constants.Encryption.Iv);
            var decryptedUtf8 = CryptoJS.TripleDES.decrypt(cipherText, keyUtf8, { iv: ivUtf8 });

            return decryptedUtf8.toString(CryptoJS.enc.Utf8);
        };

        return {
            encryptText: encryptText,
            decryptText: decryptText
        };
    })();

});