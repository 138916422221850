define('ir-app/models/shared/shared-cache-managers/mix-cache-manager', ['exports', 'ember', 'ir-app/utils/utils'], function (exports, Ember, utils) {

    'use strict';

    /**
     * Created by asankaa on 5/6/2016.
     */
    exports['default'] = (function () {

        /**
         * evaluate cachePolicy against the cached objects and decide whether to directly
         * access data from MIX or not
         * @param cachePolicy
         * @param cacheKey
         * @param [exchange]  optional field. If exchange and symbol available then consider client wise overrides in cachePolicy
         * @param [symbol]    optional field. If exchange and symbol available then consider client wise overrides in cachePolicy
         * @param [language]    optional field. If language then consider client wise overrides in cachePolicy
         * @returns {boolean}   whether to access data from the cached or from the MIX directly.
         */
        var isCachedData = function isCachedData(cachePolicy, cacheKey, exchange, symbol, language) {

            var cachedData = utils['default'].webStorage.getObject(cacheKey);
            var expireTime = getCacheExpireTimeForPolicy(cachePolicy, exchange, symbol, language);
            if (!cachedData || expireTime === 0) {
                return false;
            } else if (expireTime === -1) {
                return true;
            }

            var persistTime = cachedData.persistTime;
            var today = new Date().getTime();
            if (persistTime) {
                var elapseTime = today - persistTime;
                return elapseTime < expireTime;
            } else {
                return false;
            }
        };

        var getCachedMixResponse = function getCachedMixResponse(cacheKey) {
            var cachedObj = getCachedObj(cacheKey);
            return cachedObj ? cachedObj.data : null;
        };

        /**
         * Persist mix response to browser cache according to the given cachePolicy
         * @param cachePolicy
         * @param cacheKey
         * @param mixResponse
         */
        var persistMixResponse = function persistMixResponse(cachePolicy, cacheKey, mixResponse) {
            if (mixResponse.DAT === undefined) {
                // this if condition was implemented to prevent empty response caching
                return;
            }

            var cacheObj = {
                persistTime: new Date().getTime(),
                data: mixResponse
            };
            utils['default'].webStorage.addObject(cacheKey, cacheObj, utils['default'].Constants.StorageType.Local);
        };

        /**
         *
         * @param cachePolicy
         * @param [exchange] optional
         * @param [symbol]  optional
         * @param [language]  optional
         * @returns {number} expire time for given policy.
         */
        var getCacheExpireTimeForPolicy = function getCacheExpireTimeForPolicy(cachePolicy, exchange, symbol, language) {

            var expTime = cachePolicy.expTime;
            if (exchange && symbol) {
                // client identifier params available.
                var client;
                if (language) {
                    client = [exchange.toLowerCase(), symbol.toLowerCase(), language.toLowerCase()].join(utils['default'].Constants.StringConst.Underscore);
                } else {
                    client = [exchange.toLowerCase(), symbol.toLowerCase()].join(utils['default'].Constants.StringConst.Underscore);
                }

                if (cachePolicy.overrideExpTime) {

                    var overridesArray = cachePolicy.overrideExpTime;
                    Ember['default'].$.each(overridesArray, function (key, val) {
                        var clientArray = val.clients;
                        if (clientArray.indexOf(client) >= 0) {
                            expTime = val.expTime;
                            return false;
                        }
                    });
                }
            }
            return expTime;
        };

        var getCachedObj = function getCachedObj(cacheKey) {
            return utils['default'].webStorage.getObject(cacheKey);
        };

        // Cache Key  Generation Methods
        var getCompanyProfileCacheKey = function getCompanyProfileCacheKey(cachePolicy, exchange, symbol, language) {
            return [cachePolicy.cacheKeyPrefix, exchange.toLowerCase(), symbol.toLowerCase(), language.toLowerCase()].join(utils['default'].Constants.StringConst.Underscore);
        };

        var getSegmentationCacheKey = function getSegmentationCacheKey(cachePolicy, exchange, symbol, language) {
            return [cachePolicy.cacheKeyPrefix, exchange.toLowerCase(), symbol.toLowerCase(), language.toLowerCase()].join(utils['default'].Constants.StringConst.Underscore);
        };

        var getCompanyProfileCacheKeyByCompanyId = function getCompanyProfileCacheKeyByCompanyId(cachePolicy, companyId, language) {
            return [cachePolicy.cacheKeyPrefix, companyId.toLowerCase(), language.toLowerCase()].join(utils['default'].Constants.StringConst.Underscore);
        };

        var getCompanyMgmntCacheKey = function getCompanyMgmntCacheKey(cachePolicy, exchange, symbol, language) {
            return [cachePolicy.cacheKeyPrefix, exchange.toLowerCase(), symbol.toLowerCase(), language.toLowerCase()].join(utils['default'].Constants.StringConst.Underscore);
        };

        var getCompanyMgmntCacheKeyByCompanyId = function getCompanyMgmntCacheKeyByCompanyId(cachePolicy, companyId, language) {
            return [cachePolicy.cacheKeyPrefix, companyId.toLowerCase(), language.toLowerCase()].join(utils['default'].Constants.StringConst.Underscore);
        };

        var getCompanyOHLCCacheKey = function getCompanyOHLCCacheKey(cachePolicy, exchange, symbol) {
            return [cachePolicy.cacheKeyPrefix, exchange.toLowerCase(), symbol.toLowerCase()].join(utils['default'].Constants.StringConst.Underscore);
        };

        var getFundamentalDataCacheKey = function getFundamentalDataCacheKey(cachePolicy, exchange, symbol, mode, language, level, isCal) {
            return [cachePolicy.cacheKeyPrefix, exchange.toLowerCase(), symbol.toLowerCase(), mode.toLowerCase(), language.toLowerCase(), level.toLowerCase(), isCal === 1 ? 1 : 0].join(utils['default'].Constants.StringConst.Underscore);
        };

        var getClientFundamentalDataCacheKey = function getClientFundamentalDataCacheKey(cachePolicy, exchange, symbol, mode, language) {
            return [cachePolicy.cacheKeyPrefix, exchange.toLowerCase(), symbol.toLowerCase(), mode.toLowerCase(), language.toLowerCase()].join(utils['default'].Constants.StringConst.Underscore);
        };

        var getEstimateDataCacheKey = function getEstimateDataCacheKey(cachePolicy, exchange, symbol, language) {
            return [cachePolicy.cacheKeyPrefix, exchange.toLowerCase(), symbol.toLowerCase(), language.toLowerCase()].join(utils['default'].Constants.StringConst.Underscore);
        };

        var getFileCacheKey = function getFileCacheKey(cachePolicy, exchange, symbol, companyID, subCategory, language) {
            return [cachePolicy.cacheKeyPrefix, exchange.toLowerCase(), symbol.toLowerCase(), companyID.toLowerCase(), subCategory, language.toLowerCase()].join(utils['default'].Constants.StringConst.Underscore);
        };

        var getExchangeMetaCacheKey = function getExchangeMetaCacheKey(cachePolicy, exchange, language) {
            return [cachePolicy.cacheKeyPrefix, exchange.toLowerCase(), language.toLowerCase()].join(utils['default'].Constants.StringConst.Underscore);
        };

        var getSymbolInfoCacheKey = function getSymbolInfoCacheKey(cachePolicy, exchange, symbol, language) {
            return [cachePolicy.cacheKeyPrefix, exchange.toLowerCase(), symbol.toLowerCase(), language.toLowerCase()].join(utils['default'].Constants.StringConst.Underscore);
        };

        var getCurrencyRateCacheKey = function getCurrencyRateCacheKey(cachePolicy, pairCode) {
            return [cachePolicy.cacheKeyPrefix, pairCode.toLowerCase()].join(utils['default'].Constants.StringConst.Underscore);
        };

        var getSymbolMetaCacheKey = function getSymbolMetaCacheKey(cachePolicy, exchange, language) {
            return [cachePolicy.cacheKeyPrefix, exchange.toLowerCase(), language.toLowerCase()].join(utils['default'].Constants.StringConst.Underscore);
        };

        var getSystemDefCacheKey = function getSystemDefCacheKey(cachePolicy, language) {
            return [cachePolicy.cacheKeyPrefix, language.toLowerCase()].join(utils['default'].Constants.StringConst.Underscore);
        };

        var getExchangeArrayCacheKey = function getExchangeArrayCacheKey(cachePolicy, exchangeArray, language) {
            var exchangeStr = exchangeArray.join(utils['default'].Constants.StringConst.Tilde);
            return [cachePolicy.cacheKeyPrefix, exchangeStr.toLowerCase(), language.toLowerCase()].join(utils['default'].Constants.StringConst.Underscore);
        };

        var getInsiderTradesCacheKey = function getInsiderTradesCacheKey(cachePolicy, exchange, symbol, language) {
            return [cachePolicy.cacheKeyPrefix, exchange.toLowerCase(), symbol.toLowerCase(), language.toLowerCase()].join(utils['default'].Constants.StringConst.Underscore);
        };

        var getCorporateActionCacheKey = function getCorporateActionCacheKey(cachePolicy, exchange, symbol, language, type) {
            return [cachePolicy.cacheKeyPrefix, exchange.toLowerCase(), symbol.toLowerCase(), language.toLowerCase(), type.toLowerCase()].join(utils['default'].Constants.StringConst.Underscore);
        };

        var getCapitalOrNonCapitalCacheKey = function getCapitalOrNonCapitalCacheKey(cachePolicy, scdt, language) {
            return [cachePolicy.cacheKeyPrefix, scdt.toLowerCase(), language.toLowerCase()].join(utils['default'].Constants.StringConst.Underscore);
        };

        var getCapitalActionDetailCacheKey = function getCapitalActionDetailCacheKey(cachePolicy, exchage, symbol, actId, language) {
            return [cachePolicy.cacheKeyPrefix, exchage.toLowerCase(), symbol.toLowerCase(), actId, language.toLowerCase()].join(utils['default'].Constants.StringConst.Underscore);
        };

        var getForeignOwnershipCacheKey = function getForeignOwnershipCacheKey(cachePolicy, exchage, symbol, language) {
            return [cachePolicy.cacheKeyPrefix, exchage.toLowerCase(), symbol.toLowerCase(), language.toLowerCase()].join(utils['default'].Constants.StringConst.Underscore);
        };

        var getIpoIndustryCompanyCacheKey = function getIpoIndustryCompanyCacheKey(cachePolicy, requestType, language, dataType) {
            return [cachePolicy.cacheKeyPrefix, requestType.toLowerCase(), language.toLowerCase(), dataType.toLowerCase()].join(utils['default'].Constants.StringConst.Underscore);
        };

        var getFairValuesCacheKey = function getFairValuesCacheKey(cachePolicy, exchage, symbol, language) {
            return [cachePolicy.cacheKeyPrefix, exchage.toLowerCase(), symbol.toLowerCase(), language.toLowerCase()].join(utils['default'].Constants.StringConst.Underscore);
        };

        var getMajorShareholderCacheKey = function getMajorShareholderCacheKey(cachePolicy, companiId, language) {
            return [cachePolicy.cacheKeyPrefix, companiId.toLowerCase(), language.toLowerCase()].join(utils['default'].Constants.StringConst.Underscore);
        };

        var getRatioComparisonDataCacheKey = function getRatioComparisonDataCacheKey(cachePolicy, exchage, symbol, instType, dataType) {
            return [cachePolicy.cacheKeyPrefix, exchage.toLowerCase(), symbol.toLowerCase(), instType.toLowerCase(), dataType.toLowerCase()].join(utils['default'].Constants.StringConst.Underscore);
        };

        var getContactPersonCacheKey = function getContactPersonCacheKey(cachePolicy, individualId, companyId, language) {
            return [cachePolicy.cacheKeyPrefix, individualId.toLowerCase(), companyId.toLowerCase(), language.toLowerCase()].join(utils['default'].Constants.StringConst.Underscore);
        };

        var getCreditRatingsCacheKey = function getCreditRatingsCacheKey(cachePolicy, exchage, symbol, language) {
            return [cachePolicy.cacheKeyPrefix, exchage.toLowerCase(), symbol.toLowerCase(), language.toLowerCase()].join(utils['default'].Constants.StringConst.Underscore);
        };

        var getAnalystContactsCacheKey = function getAnalystContactsCacheKey(cachePolicy, exchage, symbol, language) {
            return [cachePolicy.cacheKeyPrefix, exchage.toLowerCase(), symbol.toLowerCase(), language.toLowerCase()].join(utils['default'].Constants.StringConst.Underscore);
        };

        var getShareholderSegmentationCacheKey = function getShareholderSegmentationCacheKey(cachePolicy, exchage, symbol, language) {
            return [cachePolicy.cacheKeyPrefix, exchage.toLowerCase(), symbol.toLowerCase(), language.toLowerCase()].join(utils['default'].Constants.StringConst.Underscore);
        };

        var getOwnershipLimitsCacheKey = function getOwnershipLimitsCacheKey(cachePolicy, exchage, symbol, language) {
            return [cachePolicy.cacheKeyPrefix, exchage.toLowerCase(), symbol.toLowerCase(), language.toLowerCase()].join(utils['default'].Constants.StringConst.Underscore);
        };

        // end of cache Key Generation Methods
        return {
            isCachedData: isCachedData,
            getCachedMixResponse: getCachedMixResponse,
            persistMixResponse: persistMixResponse,
            getCachedObj: getCachedObj,
            getCompanyProfileCacheKey: getCompanyProfileCacheKey,
            getSegmentationCacheKey: getSegmentationCacheKey,
            getCompanyProfileCacheKeyByCompanyId: getCompanyProfileCacheKeyByCompanyId,
            getCompanyMgmntCacheKey: getCompanyMgmntCacheKey,
            getCompanyMgmntCacheKeyByCompanyId: getCompanyMgmntCacheKeyByCompanyId,
            getCompanyOHLCCacheKey: getCompanyOHLCCacheKey,
            getFundamentalDataCacheKey: getFundamentalDataCacheKey,
            getClientFundamentalDataCacheKey: getClientFundamentalDataCacheKey,
            getFileCacheKey: getFileCacheKey,
            getExchangeMetaCacheKey: getExchangeMetaCacheKey,
            getSymbolInfoCacheKey: getSymbolInfoCacheKey,
            getCurrencyRateCacheKey: getCurrencyRateCacheKey,
            getExchangeArrayCacheKey: getExchangeArrayCacheKey,
            getSymbolMetaCacheKey: getSymbolMetaCacheKey,
            getInsiderTradesCacheKey: getInsiderTradesCacheKey,
            getCorporateActionCacheKey: getCorporateActionCacheKey,
            getCapitalOrNonCapitalCacheKey: getCapitalOrNonCapitalCacheKey,
            getCapitalActionDetailCacheKey: getCapitalActionDetailCacheKey,
            getForeignOwnershipCacheKey: getForeignOwnershipCacheKey,
            getIpoIndustryCompanyCacheKey: getIpoIndustryCompanyCacheKey,
            getFairValuesCacheKey: getFairValuesCacheKey,
            getMajorShareholderCacheKey: getMajorShareholderCacheKey,
            getRatioComparisonDataCacheKey: getRatioComparisonDataCacheKey,
            getContactPersonCacheKey: getContactPersonCacheKey,
            getEstimateDataCacheKey: getEstimateDataCacheKey,
            getCreditRatingsCacheKey: getCreditRatingsCacheKey,
            getAnalystContactsCacheKey: getAnalystContactsCacheKey,
            getShareholderSegmentationCacheKey: getShareholderSegmentationCacheKey,
            getOwnershipLimitsCacheKey: getOwnershipLimitsCacheKey,
            getSystemDefCacheKey: getSystemDefCacheKey

        };
    })();

});