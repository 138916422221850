define('ir-app/components/global-search', ['exports', 'ember', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/models/price/price-constants', 'ir-app/config/app-config', 'ir-app/app-events', 'ir-app/utils/utils', 'ir-app/controllers/controller-factory', 'ir-app/models/shared/language/language-data-store'], function (exports, Ember, priceService, sharedService, PriceConstants, AppConfig, AppEvents, utils, ControllerFactory, LanguageDataStore) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend(Ember['default'].SortableMixin, {
        app: LanguageDataStore['default'].getLanguageObj(),
        layoutName: 'components/global-search',

        // Parameters related to the symbol search
        content: priceService['default'].searchDS.getSymbolSearchResults(),
        sortProperties: ['rank'],
        sortAscending: true,

        // Parameters related to the announcement search
        currentButton: 0,

        // Exchange list for symbol search
        exchangeList: undefined,
        tempID: undefined,

        sortedSymbolContent: (function () {
            return this.get('arrangedContent');
        }).property('arrangedContent'),

        showSearchResultContainer: (function () {
            return this.get('content').length > 0 && this.get('searchKey');
        }).property('content.@each'),

        getSearchResult: function getSearchResult() {
            this.getSymbolSearchResult();
        },

        getTypingLanguage: function getTypingLanguage(strInput) {
            var arabicUnicodeSet = /[\u0600-\u06FF]/;
            var englishLowerCaseUnicodeSet = /[\u0041-\u005A]/;
            var englishUpperCaseUnicodeSet = /[\u0061-\u007A]/;
            var numericUnicodeSet = /[\u0030-\u0039]/;
            if (arabicUnicodeSet.test(strInput)) {
                return 'AR';
            } else if (englishLowerCaseUnicodeSet.test(strInput) || englishUpperCaseUnicodeSet.test(strInput)) {
                return 'EN';
            } else if (numericUnicodeSet.test(strInput)) {
                return sharedService['default'].userSettings.currentLanguage;
            }
        },

        getSymbolSearchResult: function getSymbolSearchResult() {
            var searchKey = this.get('searchKey');
            var tempID = this.get('tempID');
            var typingLanguage = this.getTypingLanguage(searchKey);
            if (this.get('content')) {
                this.get('content').clear();
            }

            if (searchKey && searchKey.length >= AppConfig['default'].searchConfig.minCharLenForSymbol) {
                priceService['default'].searchDS.filterSymbolSearchResults(searchKey, typingLanguage, this.exchangeList, tempID);
            }
        },

        _onItemSelected: function _onItemSelected(item) {
            var stopNotify = this.get('stopGlobalNotification');

            if (!stopNotify) {
                AppEvents['default'].onSymbolChanged(item.sym, item.exg, item.inst);
            }

            utils['default'].analyticsService.trackEvent(this.get('analyticsKey'), utils['default'].Constants.GAActions.search, ['query:' + this.get('searchKey') + ',sym:', item.sym, '~', item.exg].join(''));
            priceService['default'].searchDS.addRecentSearchedItem(item);
            this.sendAction('clickAction', item);
            this.sendAction('closePopup');
        },

        _onWidgetSelected: function _onWidgetSelected(item, widgetId) {
            var symbolPopupView = ControllerFactory['default'].createController(this.container, 'view:symbol-popup-view');

            utils['default'].analyticsService.trackEvent(this.get('analyticsKey'), utils['default'].Constants.GAActions.rowIconClick, ['popup:', widgetId, ',', 'sym:', item.sym, '~', item.exg].join(''));
            symbolPopupView.show(widgetId, item.sym, item.exg, item.inst);
            this.sendAction('closePopup');
        },

        searchKeyDidChange: (function () {
            // Every time a key is pressed, this event fires, and that event will start the filter in given time interval
            Ember['default'].run.debounce(this, this.getSearchResult, PriceConstants['default'].TimeIntervals.SearchAutoCompleteInterval);
        }).observes('searchKey'),

        actions: {
            searchClick: function searchClick() {
                this.getSearchResult();
            },

            onItemSelected: function onItemSelected(item) {
                this._onItemSelected(item);
            },

            onWidgetSelected: function onWidgetSelected(item, widgetId) {
                this._onWidgetSelected(item, widgetId);
            }
        }
    });

});