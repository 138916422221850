define('ir-app/components/bootstrap-dropdown-select', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        layoutName: 'components/bootstrap-dropdown-select',
        isDropDownRest: false,

        applyDescription: (function () {
            var that = this;
            var optionArray = that.get('options');

            if (optionArray && optionArray.length > 0) {
                optionArray.forEach(function (element) {
                    if (element) {
                        Ember['default'].set(element, 'selectedDesc', element[that.get('labelKey')]);
                    }
                });

                if (that.get('IsDefaultAddedToDropdown')) {
                    optionArray.insertAt(0, that.get('defaultSelect'));
                }

                that.set('options', optionArray);
            }
        }).on('init'),

        selectedOption: (function () {
            var arrOptions = this.get('options');
            var value = this.get('value');
            var defaultValue = this.get('defaultSelect');
            var isReset = this.get('isDropDownRest');

            if (isReset) {
                this.set('value', undefined);
                if (!Ember['default'].isEmpty(defaultValue)) {
                    return defaultValue;
                } else if (!Ember['default'].isEmpty(arrOptions)) {
                    return arrOptions[0];
                }
            } else {
                if (!Ember['default'].isEmpty(value)) {
                    return arrOptions.findProperty(this.get('valueKey'), value);
                } else if (!Ember['default'].isEmpty(defaultValue)) {
                    return defaultValue;
                } else if (!Ember['default'].isEmpty(arrOptions)) {
                    return arrOptions[0];
                }
            }
        }).property('options', 'value', 'isDropDownRest'),

        reloadDropDown: (function () {
            this.applyDescription();
        }).observes('options'),

        actions: {
            select: function select(option) {
                this.set('isDropDownRest', false);
                this.set('value', option[this.get('valueKey')]);
                option.customArgs = this.get('customArgs');

                this.sendAction('selectAction', option);
            }
        }
    });

});