define('ir-app/models/price/price-initializers/meta-data-loader', ['exports', 'ember', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/models/price/price-protocols/mix-web/mix-response-handler', 'ir-app/utils/utils'], function (exports, Ember, priceService, sharedService, mixResponseHandler, utils) {

    'use strict';

    exports['default'] = (function () {
        var loadMetaData = function loadMetaData(application) {
            application.deferReadiness();

            var successFn = function successFn() {
                application.advanceReadiness();

                /* var secondaryLan = languageDataStore.getChangeToLanguage(priceService.userDS.currentLanguage);
                // Initiate the mega request for secondary language
                if (secondaryLan) {
                    priceService.downloadExchangeMasterInfo(
                        appConfig.customisation.defaultExchange,
                        secondaryLan.code,
                        undefined,
                        undefined,
                        true
                    );
                }*/
            };

            var errorFn = function errorFn() {
                // TODO: [Bashitha] Handle request failures
            };

            localStorage.setItem('host_version', '{\"os\":\"ios\",\"version\":\"1.0.1 (Build 29)\",\"deviceToken\":\"\"}');
            priceService['default'].announcementDS.loadCachedStore();
            loadExchangeDefinitions(successFn, errorFn, sharedService['default'].userSettings.currentLanguage);
        };

        var loadExchangeDefinitions = function loadExchangeDefinitions(successFn, errorFn, language) {
            var downloadMeta = true;

            // Load Exchange definition
            var dataObj = utils['default'].webStorage.getObject(utils['default'].webStorage.getKey(utils['default'].Constants.CacheKeys.ExchangeDefinition, language));
            var headerObj = utils['default'].webStorage.getObject(utils['default'].webStorage.getKey(utils['default'].Constants.CacheKeys.ExchangeDefinitionHeader));

            if (dataObj && headerObj) {
                downloadMeta = false;
                var exchangeObj = mixResponseHandler['default'].processExchangeDefinition(headerObj, dataObj);

                dataObj = utils['default'].webStorage.getObject(utils['default'].webStorage.getKey(utils['default'].Constants.CacheKeys.ExchangeNewsProvider, language));
                headerObj = utils['default'].webStorage.getObject(utils['default'].webStorage.getKey(utils['default'].Constants.CacheKeys.ExchangeNewsProviderHeader));

                if (exchangeObj && dataObj && headerObj) {
                    mixResponseHandler['default'].processExchangeNewsProviders(exchangeObj, headerObj, dataObj);
                }

                dataObj = utils['default'].webStorage.getObject(utils['default'].webStorage.getKey(utils['default'].Constants.CacheKeys.ExchangeSectorDefinition, language));
                headerObj = utils['default'].webStorage.getObject(utils['default'].webStorage.getKey(utils['default'].Constants.CacheKeys.ExchangeSectorDefinitionHeader));

                if (exchangeObj && dataObj && headerObj) {
                    mixResponseHandler['default'].processSectorDefinition(exchangeObj.get('exg'), headerObj, dataObj);
                }
            } else {
                downloadMeta = true;
            }

            // Load Watchlist definition
            dataObj = utils['default'].webStorage.getObject(utils['default'].webStorage.getKey(utils['default'].Constants.CacheKeys.ExchangeSymbolList, language));
            headerObj = utils['default'].webStorage.getObject(utils['default'].webStorage.getKey(utils['default'].Constants.CacheKeys.ExchangeSymbolListHeader));

            if (dataObj && headerObj) {
                downloadMeta = false;
                mixResponseHandler['default'].processExchangeWatchlist(headerObj, dataObj);
            } else {
                downloadMeta = true;
            }

            // Load Exchange Index definition
            dataObj = utils['default'].webStorage.getObject(utils['default'].webStorage.getKey(utils['default'].Constants.CacheKeys.ExchangeIndicesDefinition, language));
            headerObj = utils['default'].webStorage.getObject(utils['default'].webStorage.getKey(utils['default'].Constants.CacheKeys.ExchangeIndicesDefinitionHeader));

            if (dataObj && headerObj) {
                downloadMeta = false;
                mixResponseHandler['default'].processExchangeIndices(headerObj, dataObj);
            } else {
                downloadMeta = true;
            }

            if (!downloadMeta) {
                if (Ember['default'].$.isFunction(successFn)) {
                    successFn();
                }
            }

            // Initiate the mega request. If the versions are changed, new meta will be downloaded. Else app will continue working with the existing cached data
            priceService['default'].downloadExchangeMasterInfo(sharedService['default'].userSettings.currentExchange, language, downloadMeta ? successFn : undefined, downloadMeta ? errorFn : undefined);
        };

        return {
            loadMetaData: loadMetaData,
            loadExchangeDefinitions: loadExchangeDefinitions
        };
    })();

});