define('ir-app/components/stk-specific-components/portal-range-display-slider', ['exports', 'ember', 'ir-app/utils/utils'], function (exports, Ember, utils) {

    'use strict';

    /**
     * Created by banushas on 5/12/2016.
     */

    exports['default'] = Ember['default'].Component.extend({
        layoutName: 'components/stk-specific-components/portal-range-display-slider',

        position: (function () {
            var current = this.get('current');
            var high = this.get('high');
            var low = this.get('low');
            var preClosed = this.get('preClosed');
            var value = 0;

            if (current === 0) {
                // when last trade price is equal to zero assign preClosed
                current = preClosed;
            }

            var diff = high - low;
            if (diff !== 0) {
                value = parseInt((current - low) / diff * 100, 10);
                value = value > 0 && value <= 100 ? value : 0;
            }
            return value;
        }).property('current', 'high', 'low'),

        styles: (function () {
            var progress = parseInt(this.get('position'), 10);
            return 'width:' + progress + '%;'; // Used inline styles since width is dynamically and changed rapidly
        }).property('position'),

        highValue: (function () {
            return utils['default'].formatters.formatNumber(this.get('high'), 2);
        }).property('high'),

        lowValue: (function () {
            return utils['default'].formatters.formatNumber(this.get('low'), 2);
        }).property('low'),

        currentValue: (function () {
            return utils['default'].formatters.formatNumber(this.get('current'), 2);
        }).property('current')
    });

});