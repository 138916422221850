define('ir-app/models/shared/language/language-tags', ['exports'], function (exports) {

    'use strict';

    exports['default'] = {
        lang: 'EN',
        obj: {
            labels: {
                mngtNBrdMbrs: '[14567]',
                daysRange: '[15141]',
                weeksRange52: '[15142]',
                copyright: '[14653]',
                companyInfor: '[14654]',
                lastUpdated: '[10166]',
                news: '[3039]',
                title: '[382]',
                newsAnnouncement: '[6841]',
                market: '[385]',
                quote: '[2263]',
                topLoosersByPerChg: '[2987]',
                topLoosersByChg: '[5824]',
                topGainersByPerChg: '[2985]',
                topGainersByChg: '[9714]',
                mostActByVol: '[2704]',
                mostActByTrades: '[5815]',
                mostActByTurnover: '[10500]',
                companyProf: '[927]',
                trend: '[4568]',
                dark: '[8300]',
                light: '[8299]',
                ok: '[3129]',
                yes: '[5321]',
                no: '[3071]',
                save: '[3811]',
                cancel: '[3745]',
                retry: '[6370]',
                search: '[5308]',
                provider: '[3628]',
                startDate: '[2074]',
                endDate: '[2047]',
                to: '[557]',
                from: '[1635]',
                preferredTheme: '[14870]',
                preferredLanguage: '[14883]',
                pctChgWeek: '[12801]',
                pctChgMonth: '[8]',
                pctChg3Month: '[19]',
                pctChgYear: '[12803]',
                avgVol30Days: '[5902]',
                avgTovr30Days: '[14976]',
                price15minDelay: '[14979]',
                home: '[1990]',
                eventCalendar: '[14495]',
                upComingEvents: '[15002]',
                pastEvents: '[15003]',
                financials: '[6778]',
                historylookup: '[15019]',
                selectDate: '[15020]',
                noTradeDataMessage: '[15021]',
                selectedDate: '[15022]',
                financialandratios: '[15023]',
                documents: '[15024]',
                is: '[15025]',
                bs: '[15026]',
                cf: '[15027]',
                ratios: '[6886]',
                period: '[3406]',
                statementType: '[8475]',
                yearly: '[8199]',
                quarterly: '[6885]',
                netIncome: '[1553]',
                totalRevenue: '[12810]',
                totalUWRevenue: '[15140]',
                operatingIncome: '[15028]',
                totalAssets: '[10080]',
                totalEquity: '[1451]',
                operatingActivities: '[15029]',
                investingActivities: '[15030]',
                investorCal: '[15031]',
                mediaRoom: '[15032]',
                facebook: '[12447]',
                twitter: '[8648]',
                youTube: '[15033]',
                linkedIN: '[15034]',
                stkPrice: '[4408]',
                results: '[519]',
                calc: '[590]',
                reInvstDivid: '[15045]',
                totDivid: '[15046]',
                totReturn: '[15047]',
                rtnPercentage: '[15048]',
                annualReturn: '[15049]',
                numSharesPurchased: '[15050]',
                sharesPurchased: '[15051]',
                or: '[7047]',
                amountInvest: '[15052]',
                investDate: '[15053]',
                addAllEventToCalender: '[15054]',
                settings: '[3832]',
                invalidInputs: '[8103]',
                marketStatus: '[2564]',
                fiftyTwoWkH: '[7693]',
                fiftyTwoWkL: '[9164]',
                w52highLow: '[14977]',
                web: '[11175]',
                couponFreq: '[12385]',
                lastQty: '[3709]',
                lastTradedDate: '[2484]',
                lastTradedTime: '[14547]',
                indGrp: '[14560]',
                subInd: '[14561]',
                isin: '[4809]',
                estbOn: '[11174]',
                freeFltShr: '[14563]',
                authCap: '[14564]',
                trdName: '[14565]',
                parVal: '[12188]',
                auditor: '[8289]',
                open: '[1782]',
                peRatio: '[1880]',
                high: '[1943]',
                quantity: '[2365]',
                listedShr: '[2453]',
                mktCap: '[2515]',
                openInterest: '[3159]',
                outstandingAmount: '[3365]',
                sherPrs: '[3397]',
                announcement: '[3841]',
                unchanged: '[4013]',
                side: '[4223]',
                settlementDate: '[4687]',
                bondType: '[4697]',
                dayCountMethod: '[4700]',
                currency: '[1664]',
                currencies: '[3873]',
                topGainers: '[5001]',
                topLosers: '[5008]',
                totalBidQty: '[5027]',
                totalOfferQty: '[14324]',
                volume: '[5230]',
                all: '[5336]',
                addr: '[5925]',
                previousCouponDate: '[4710]',
                phn: '[8294]',
                fax: '[8295]',
                owners: '[8296]',
                subsidiaries: '[8297]',
                lotSize: '[76]',
                name: '[168]',
                offer: '[193]',
                bid: '[2543]',
                bestOffer: '[256]',
                bestBid: '[259]',
                bidOfferRatio: '[281]',
                offerQty: '[329]',
                bidQty: '[331]',
                description: '[357]',
                trades: '[388]',
                preClosed: '[6881]',
                symbol: '[622]',
                cashMap: '[2028]',
                chart: '[774]',
                filter: '[837]',
                close: '[845]',
                vWAP: '[880]',
                expiryDate: '[988]',
                strikePrice: '[989]',
                couponRate: '[1026]',
                exchange: '[1179]',
                time: '[1340]',
                splits: '[1346]',
                turnover: '[1353]',
                ups: '[3782]',
                down: '[1387]',
                email: '[1420]',
                price: '[1506]',
                eps: '[1549]',
                low: '[1552]',
                perChange: '[5006]',
                change: '[1751]',
                yield1: '[1752]',
                selectInd: '[2080]',
                beta: '[1871]',
                dividendYield: '[1873]',
                todayRange: '[10257]',
                fiftyTwoWkRange: '[15172]',
                allPricesIn: '[15173]',
                trailingTwelveMonths: '[15174]',
                commodities: '[1732]',
                listDate: '[15182]',
                finYrStart: '[8288]',
                contacts: '[11600]',
                lastPrice: '[2409]',
                companyStatistics: '[15183]',
                outShr: '[14562]',
                pdCap: '[14973]',
                bookValuePerShare: '[15184]',
                pbRatio: '[12807]',
                dividends: '[10122]',
                totalValueofCashDividend: '[15186]',
                payoutRatio: '[15187]',
                dividendPerShare: '[15188]',
                bonusShares: '[15189]',
                announcedLast12Months: '[15190]',
                naIndicatesMessage: '[15191]',
                events: '[15192]',
                dcfsFlagYellowText: '[15193]',
                dcfsFlagOrangeText: '[15194]',
                dcfsFlagWhiteText: '[15195]',
                middleEast: '[8733]',
                ytd: '[12802]',
                noOfTrades: '[3095]',
                mktCapMillion: '[14978]',
                marketMovers: '[15225]',
                stkOvw: '[15227]',
                monthJan: '[2690]',
                monthShortJan: '[4157]',
                monthFeb: '[2691]',
                monthShortFeb: '[4158]',
                monthMar: '[2692]',
                monthShortMar: '[4159]',
                monthApril: '[2693]',
                monthShortApril: '[2693]',
                monthMay: '[2694]',
                monthShortMay: '[2694]',
                monthJun: '[2695]',
                monthShortJun: '[4162]',
                monthJly: '[2696]',
                monthShortJly: '[2696]',
                monthAgst: '[2697]',
                monthShortAgst: '[4164]',
                monthSep: '[2698]',
                monthShortSep: '[4165]',
                monthOct: '[2699]',
                monthShortOct: '[4166]',
                monthNov: '[2700]',
                monthShortNov: '[4167]',
                monthDec: '[4168]',
                monthShortDec: '[2701]',
                chartStyleTitle: '[5484]',
                chartShowVol: '[4218]',
                chartIntraday: '[2286]',
                chartHistory: '[748]',
                chartEveryMinute: '[1468]',
                chartEvery5Minutes: '[23]',
                chartEvery10Minutes: '[1]',
                chartEvery15Minutes: '[4]',
                chartEvery30Minutes: '[16]',
                chartEvery60Minutes: '[26]',
                chartDaily: '[1227]',
                chartWeekly: '[5278]',
                chartMonthly: '[3408]',
                chartQuarterly: '[6885]',
                chartYearly: '[8199]',
                chartArea: '[6725]',
                chartCandle: '[605]',
                chartOHLC: '[3127]',
                chartLine: '[2435]',
                chartCandleWithTrend: '[13006]',
                international: '[10967]',
                paidUpCapital: '[13352]',
                reprstFor: '[15208]',
                reprstTo: '[15209]',
                currencyConverter: '[15210]',
                currencyAmount: '[15211]',
                stocksTradedListed: '[15262]',
                allAreSpot: '[15213]',
                majorShareholders: '[15214]',
                mktIndices: '[2547]',
                marketPulse: '[15215]',
                arabMarkets: '[10956]',
                globalMarkets: '[15216]',
                btnClear: '[15217]',
                category: '[405]',
                location: '[3355]',
                relatedAnn: '[15218]',
                fromDate: '[1636]',
                toDate: '[5047]',
                marketRatios: '[15219]',
                relatedStory: '[15220]',
                indexOverview: '[6854]',
                allStocks: '[15221]',
                indexDetails: '[15222]',
                indexStats: '[15223]',
                stkExchange: '[15224]',
                indexName: '[7934]',
                indexConst: '[14625]',
                indexSummary: '[2031]',
                stksAccumLoss: '[15226]',
                sector: '[3777]',
                enterCompareSym: '[11497]',
                mktInitialising: '[8107]',
                mktPreOpen: '[2676]',
                mktOpen: '[4360]',
                mktClose: '[1615]',
                mktPreClose: '[2675]',
                mktOpenSuspended: '[7893]',
                mktPreCloseSuspended: '[8244]',
                mktCloseSuspended: '[7523]',
                mktPreOpenSuspended: '[8101]',
                mktMovingToPreOpen: '[8189]',
                mktMovingToOpen: '[8132]',
                mktMovingToPreClose: '[7587]',
                mktMovingToClose: '[7986]',
                mktPreCloseWithTrades: '[5678]',
                mktCloseWithTrades: '[1615]',
                mktTradingAtLast: '[10173]',
                mktIntermediateClose: '[10289]',
                mktPreCloseAdjustment: '[14354]',
                status: '[120]',
                effectiveDate: '[702]',
                capitalAction: '[15250]',
                nonCapitalAction: '[15251]',
                announcingDate: '[15252]',
                completionDate: '[15253]',
                action: '[37]',
                factor: '[7464]',
                final: '[15254]',
                proposed: '[15255]',
                balanceSheet: '[6730]',
                cashFlow: '[632]',
                incomeStat: '[6790]',
                showTradesfor: '[15260]',
                transactions: '[15014]',
                count: '[11186]',
                estimatedValue: '[15256]',
                date: '[1447]',
                insiderName: '[8875]',
                vwapPrice: '[15257]',
                estdTrnsValue: '[15258]',
                net: '[15259]',
                buy: '[577]',
                sell: '[526]',
                ratioFootNote: ['15267'],
                recordDate: ['2072'],
                details: ['727'],
                dividendAmount: ['9746'],
                issuePrice: ['15316'],
                splitFactor: ['15268'],
                rightsEndDate: ['15269'],
                sharesBefore: ['15270'],
                sharesAfter: ['15271'],
                sharesChange: ['15272'],
                parValBfr: ['15273'],
                parValAtr: ['15274'],
                parValChange: ['15275'],
                capChanges: ['15276'],
                periodEndDate: ['15277'],
                approvalStatus: ['15278'],
                abnormalCd: ['15279'],
                cdYear: ['15280'],
                cdPeriod: ['15281'],
                oldPaid: ['15282'],
                newPaid: ['15283'],
                cashPaid: ['15284'],
                priceLimitRemoval: ['15285'],
                numbTrsryStks: ['9745'],
                cdRelatedPeriod: ['15298'],
                cdRelatedPeriodName: ['15299'],
                actionDetails: ['15300'],
                financialStatements: ['15301'],
                pressReleases: ['15302'],
                bMeetingDisclosures: ['15303'],
                aMeetingDisclosures: ['15304'],
                glossyReports: ['15305'],
                baselIII: ['15306'],
                company: ['9248'],
                actual: ['10580'],
                summary: ['548'],
                view: ['5225'],
                calendar: ['9227'],
                fiscal: ['15313'],
                breakdown: ['15312'],
                periodBasis: ['15311'],
                detailedView: ['15310'],
                interim: ['15314'],
                ttm: ['15315'],
                ratiosAndGrowth: ['15323'],
                foreignOwnership: ['15541'],
                qfiClientOwnership: ['15325'],
                totalForeignOwnership: ['15326'],
                maxLimit: ['15327'],
                actualLimit: ['15329'],
                selectMarket: ['4091'],
                stockScreener: ['15332'],
                divYieldPer: ['15331'],
                type: ['3233'],
                industry: ['7629'],
                corporateActions: ['14913'],
                valuationRatio: ['15535'], // todo [] add the tag
                ipo: ['2371'],
                download: ['1380'],
                currentDay: ['1162'],
                changeLongDes: ['9520'],
                selectSymbol: ['5290'],
                searchResults: ['6898'],
                poweredBy: ['12798'],
                previousDay: ['15408'],
                prospectus: ['15409'],
                sizeMillions: ['15410'],
                offered: ['15411'],
                capitalIncrease: ['15412'],
                screeningCriteria: ['15413'],
                compare: ['6972'],
                inMillions: ['2356'],
                authCapital: ['15538'],
                latestQuarter: ['15534'],
                disclosure: ['15536'],
                aggregatedMktSummary: ['15537'],
                CFFromInvestingActivities: ['15539'],
                CFFromOperatingActivities: ['15540'],
                noOfStocks: ['11632'],
                totAuthorizedCapital: ['15542'],
                corporateActionType: ['8683'],
                aggregatedSummary: ['15537'],
                currentOwnership: ['15544'],
                previousOwnership: ['15545'],
                ratioComparison: ['9923'],
                noResultsFound: ['15546'],
                itemsSelected: ['13942'],
                nothingSelected: ['15572'],
                selectAll: ['251'],
                deselectAll: ['13026'],
                currentQuarter: ['15573'],
                threeMonths: ['5429'],
                sixMonths: ['13505'],
                oneYear: ['11008'],
                twoYears: ['8279'],
                underPerform: ['15574'],
                hold: ['1911'],
                outPerform: ['15575'],
                currentQuarterConsensus: ['15576'],
                threeMonthsConsensus: ['15577'],
                sixMonthsConsensus: ['15578'],
                oneYearConsensus: ['15579'],
                twoYearConsensus: ['15580'],
                meanConsensus: ['15581'],
                recommendations: ['9828'],
                avgTargetPriceAdjusted: ['15582'],
                upsidePotential: ['15583'],
                duration: ['1400'],
                providers: ['10135'],
                showValues: ['15584'],
                adjForCorporateActions: ['15585'],
                asReported: ['15586'],
                chgIndicator: ['15587'],
                rec: ['15588'],
                targetP: ['15589'],
                upsideP: ['15590'],
                rep: ['15591'],
                prevRepDate: ['15592'],
                prevRec: ['15593'],
                prevTargetP: ['15594'],
                prevRep: ['15595'],
                recChangeIndicator: ['15596'],
                recommendation: ['15597'],
                adjTargetPrice: ['15598'],
                rawTargetPrice: ['15599'],
                curUpsidePotential: ['15600'],
                report: ['9805'],
                prevReportDate: ['15601'],
                prevRecommendation: ['15602'],
                prevTargetPrice: ['15603'],
                prevReport: ['15604'],
                indicateValuesAdjusted: ['15605'],
                rtnOnEquity: ['8564'],
                rtnOnAssets: ['8565'],
                value: ['9505'],
                investments: ['15511'],
                associates: ['15690'],
                shareHolders: ['15691'],
                corporate: ['11565'],
                nonCorporate: ['15692'],
                individuals: ['15543'],
                management: ['11173'],
                keyOff: ['15689'],
                marketSummary: ['2565'],
                message: ['5378'],
                avgTransVal: ['15693'],
                avgTransVal30Days: ['15694'],
                companyContact: ['15695'],
                IrContactPerson: ['15696'],
                dividendHistory: ['15752'],
                send: ['4114'],
                sending: ['3300'],
                mandatoryField: ['8912'],
                dayChange: ['15704'],
                dayPerChange: ['15705'],
                last: ['346'],
                performance: ['11035'],
                subscribe: ['4495'],
                stopLoss: ['309'],
                stopLossAlert: ['15724'],
                unsubscribe: ['15725'],
                unSubscribeEmail: ['15726'],
                highVolume: ['15727'],
                highVolumeAlert: ['15728'],
                volumeTarget: ['15729'],
                volumeTargetAlert: ['15730'],
                negativePctChg: ['15731'],
                negativePctChgAlert: ['15732'],
                positivePctChg: ['15733'],
                positivePctChgAlert: ['15734'],
                priceTarget: ['15735'],
                priceTargetAlert: ['15736'],
                dailyTradeSummary: ['15737'],
                dailyTradeSummaryAlert: ['15738'],
                emailSighUp: ['15739'],
                emailAddress: ['15740'],
                changeSubscription: ['15741'],
                unsubscriptionMessage: ['15742'],
                signUpMessage: ['15743'],
                creditRatings: ['15718'],
                ratingAgency: ['15719'],
                longTermRatings: ['15720'],
                shortTermRatings: ['15721'],
                outlook: ['15722'],
                ratingsAffirmed: ['15723'],
                analystRecommendation: ['15753'],
                analystCoverage: ['15754'],
                firm: ['15755'],
                analyst: ['15756'],
                contactInfo: ['12776'],
                phone: ['6861'],
                highestVol: ['1944'],
                lowestVol: ['15781'],
                highestPrice: ['15779'],
                lowestPrice: ['15780'],
                highestDayPctChg: ['15783'],
                lowestDayPctChg: ['15782'],
                chgPeriod: ['15784'],
                sharePriceOneYear: ['15804'],
                oneYr: ['11'],
                lastTradedPrice: ['9519'],
                fiftyTwoWksRange: ['14648'],
                stockInformation: ['15805'],
                others: ['3339'],
                mutualFunds: ['3922'],
                ownersByCountry: [],
                ownersByType: [],
                version: ['737'],
                hostVersion: ['16126'],
                copyRight: ['16127'],
                finYrEnd: ['16129']

            },
            messages: {
                dataNotAvailable: '[8550]',
                loading: '[2456]',
                noUpcomingEvents: '[15157]',
                noEvents: '[15158]',
                selectFromToDates: '[15261]',
                financialsColumnMsg: '[15319]',
                mailSentSuccess: '[15746]',
                mailSentFail: '[15747]',
                invalidName: '[15748]',
                invalidEmail: '[15749]',
                invalidPhone: '[15750]',
                invalidMessage: '[15751]',
                setValue: '[15744]',
                selectOneAlertType: '[15745]'
            }
        }
    };

});