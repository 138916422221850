define('ir-app/components/table/portal-table-component', ['exports', 'ember', 'ember-table/components/ember-table', 'ember-table/controllers/row-array', 'ir-app/views/table/row-definition', 'ir-app/config/price-widget-config'], function (exports, Ember, EmberTableComponent, RowArrayController, RowDefinition, priceWidgetConfig) {

    'use strict';

    exports['default'] = EmberTableComponent['default'].extend({

        layoutName: 'components/ember-table',
        buttonAction: null,
        buttonMenuAction: null,

        // Extended from lib to give our row view
        tableRowView: 'Ember.TableRow',
        tableRowViewClass: Ember['default'].computed.alias('tableRowView'),

        // Event handling params
        clickEventHandler: null,

        bodyContent: Ember['default'].computed(function () {
            // Extended from lib to give our row definition
            return RowArrayController['default'].create({
                target: this,
                parentController: this,
                container: this.get('container'),
                itemController: RowDefinition['default'],
                content: this.get('content')
            });
        }).property('content'),

        refreshTableComponent: (function () {
            var that = this;
            Ember['default'].run.later(function () {
                Ember['default'].run(that, that.elementSizeDidChange);
            }, 1000);
        }).observes('content'),

        getTableComponent: function getTableComponent() {
            var widget = Ember['default'].$('#' + this.get('id'));

            if (widget && widget.length > 0) {
                widget = widget[0];
            }

            return widget;
        },

        /* Starting Event handling Functions */
        clickEventFunction: function clickEventFunction(e) {
            // This only blocks default right click menu
            var event = e;

            if (event.ctrlKey) {
                return true;
            }

            if (!event) {
                event = window.event;
            }

            if (event.preventDefault) {
                event.preventDefault();
            } else {
                event.returnValue = false;
            }

            event.stopPropagation();
            return false;
        },

        doubleClick: function doubleClick(e) {
            var event = e;

            if (!event) {
                event = window.event;
            }

            var selectedRow = this.getRowForEvent(event);

            if (!selectedRow) {
                return true;
            } else {
                this.sendAction('doubleClickAction', selectedRow, event);
            }
        },

        load: (function () {
            this.clickEventHandler = this.onDocumentClick.bind(this);
            var widget = this.getTableComponent();

            if (widget.addEventListener) {
                // For all major browsers, except IE 8 and earlier
                widget.addEventListener('mousedown', this.clickEventHandler);
                document.addEventListener('contextmenu', this.clickEventFunction);
            } else if (widget.attachEvent) {
                // For IE 8 and earlier versions
                widget.attachEvent('onclick', this.clickEventHandler);
                widget.attachEvent('onmousedown', this.clickEventHandler);
                document.attachEvent('oncontextmenu', this.clickEventFunction);
            }
        }).on('didInsertElement'),

        unload: (function () {
            // Implement rest of the event removal
            var widget = this.getTableComponent();

            if (widget.removeEventListener) {
                widget.removeEventListener('mousedown', this.clickEventHandler);
                document.removeEventListener('contextmenu', this.clickEventFunction);
            } else if (widget.detachEvent) {
                // For IE 8 and earlier versions
                widget.detachEvent('onclick', this.clickEventHandler);
                widget.detachEvent('onmousedown', this.clickEventHandler);
                document.detachEvent('oncontextmenu', this.clickEventFunction);
            }
        }).on('willDestroyElement'),

        onDocumentClick: function onDocumentClick(e) {
            var event = e;

            if (!event) {
                event = window.event;
            }

            var selectedCell = this.getColumnId(event);
            // set first two column to clickable
            if (selectedCell) {
                if (selectedCell !== priceWidgetConfig['default'].watchList.irPortalColumnMapping.sym.id && selectedCell !== priceWidgetConfig['default'].watchList.irPortalColumnMapping.sDes.id) {
                    event.preventDefault();
                    return false;
                }
            }

            var selectedRow = this.getRowForEvent(event);

            if (!selectedRow) {
                return true;
            } else {
                var that = this;
                that.sendAction('rowClickAction', selectedRow, event); // This should be executed before event.stopPropagation();

                // This patch is needed in order to make the selection appear in the table
                Ember['default'].run.later(function () {
                    that.click(event);
                }, 100);
            }
        },

        getRowForEvent: function getRowForEvent(event) {
            var $rowView = Ember['default'].$(event.target).parents('.ember-table-table-row');
            var view = Ember['default'].View.views[$rowView.attr('id')];
            if (view) {
                return view.get('row');
            }
            return null;
        },

        getColumnId: function getColumnId(event) {
            var $cellView = Ember['default'].$(event.target).parents('.ember-table-cell');
            var view = Ember['default'].View.views[$cellView.attr('id')];
            if (view) {
                return view.get('columnId');
            }
            return null;
        },

        /* Ending Event handling Functions */

        afterRenderEvent: function afterRenderEvent() {},

        footerContent: Ember['default'].computed(function () {
            var rows = this.get('footerArray');

            if (!rows) {
                return Ember['default'].A();
            }

            return rows;
        }).property('footerArray'),

        actions: {
            sortByColumn: function sortByColumn(column) {
                this.sendAction('sortAction', column);
            }
        }
    });

});