define('ir-app/controllers/ir-mobile/company-widgets/company-major-shareholders', ['exports', 'ember', 'ir-app/controllers/base-array-controller', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/models/price/price-constants'], function (exports, Ember, BaseArrayController, priceService, sharedService, priceConstants) {

    'use strict';

    exports['default'] = BaseArrayController['default'].extend({

        basicInfo: {},
        ownerData: {},
        companyId: undefined,

        onLoadWidget: function onLoadWidget() {
            this.setErrorMessage();
        },

        // Base overrides
        onPrepareData: function onPrepareData() {
            var that = this;
            that.startLoadingProgress();
            var basicInfo = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, function () {
                that._loadShareholderData();
            }, function () {
                that.onDataError();
            }, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyBasicInfo);

            this.set('basicInfo', basicInfo);
        },

        onCheckDataAvailability: function onCheckDataAvailability() {
            var companyProfObj = priceService['default'].companyProfileDS.getStoredObjectByCompanyId(this.get('companyId'), sharedService['default'].userSettings.currentLanguage);

            if (companyProfObj && companyProfObj.compOwners && companyProfObj.compOwners.length > 0) {
                this.set('model', companyProfObj.compOwners);
                return true;
            } else {
                this.set('model', []);
                return false;
            }
        },

        _loadShareholderData: function _loadShareholderData() {
            var that = this;
            // get stored company profile object
            var companyProfObj = priceService['default'].companyProfileDS.getStoredObject(that.get('exg'), that.get('sym'), sharedService['default'].userSettings.currentLanguage);
            that.set('companyId', companyProfObj.compID);
            // request major shareholder data using company Id
            var ownerData = priceService['default'].companyProfileDS.getStoredObjectByCompanyId(that.get('companyId'), sharedService['default'].userSettings.currentLanguage, function () {
                that.onDataSuccess();
            }, function () {
                that.onDataError();
            }, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyMajorShareholder).compOwners;

            that.set('ownerData', ownerData);
        },

        filteredContent: (function () {
            var content = this.get('arrangedContent');
            // Ownership percentage limit
            var pctLimit = this.get('pctLimit') !== undefined ? this.get('pctLimit') : priceConstants['default'].WidgetDefaults.MajorShareholderMinPct;
            var filteredContent = content.filter(function (item) {

                return Number(item.currentPct) * 100 > pctLimit;
            });
            return filteredContent;
        }).property('arrangedContent'),

        // filter by current percentage desc order
        sortedContent: (function () {
            return Ember['default'].ArrayProxy.extend(Ember['default'].SortableMixin).create({
                sortProperties: ['currentPct'],
                sortAscending: false,
                content: this.get('filteredContent')
            });
        }).property('filteredContent'),

        onClearData: function onClearData() {
            this.set('model', Ember['default'].A());
        },

        onLanguageChanged: function onLanguageChanged() {
            this.onPrepareData();
        }
    });

});