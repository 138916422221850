define('ir-app/models/shared/shared-initializers/language-loader', ['exports', 'ir-app/models/shared/language/language-primary', 'ir-app/models/shared/language/language-secondary', 'ir-app/models/shared/language/language-data-store', 'ir-app/models/shared/language/language-customisations', 'ir-app/utils/utils', 'ir-app/models/shared/shared-service'], function (exports, languagePrimary, languageSecondary, languageDataStore, languageCustomisation, utils, sharedService) {

    'use strict';

    exports['default'] = (function () {
        var loadLanguageData = function loadLanguageData() {
            try {
                // load any customisation if available
                var customisation = languageCustomisation['default'][sharedService['default'].userSettings.currentCustomisation];

                // If available, then merge data
                if (customisation) {
                    if (customisation[languagePrimary['default'].lang]) {
                        utils['default'].configHelper.mergeConfigSettings(languagePrimary['default'].obj, customisation[languagePrimary['default'].lang].obj);
                    }

                    if (customisation[languageSecondary['default'].lang]) {
                        utils['default'].configHelper.mergeConfigSettings(languageSecondary['default'].obj, customisation[languageSecondary['default'].lang].obj);
                    }
                }

                languageDataStore['default'].setLanguageObj(languagePrimary['default'].lang, languagePrimary['default'].obj);
                languageDataStore['default'].setLanguageObj(languageSecondary['default'].lang, languageSecondary['default'].obj);
            } catch (e) {
                utils['default'].logger.logError('Error in loading language data : ' + e);
            }
        };

        return {
            loadLanguageData: loadLanguageData
        };
    })();

});