define('ir-app/components/input-field-price', ['exports', 'ember', 'ir-app/utils/utils'], function (exports, Ember, utils) {

    'use strict';

    exports['default'] = Ember['default'].TextField.extend({
        textVal: (function (key, textVal) {
            var value;
            var textValue = textVal;

            if (textValue) {
                this.set('value', utils['default'].formatters.formatNumber(textValue, 0));
            } else {
                value = this.get('value');

                if (value) {
                    textValue = value.replace(/,/g, '');
                } else {
                    textValue = null;
                }
            }

            return textValue;
        }).property('value')
    });

});