define('ir-app/utils/constants', ['exports'], function (exports) {

    'use strict';

    exports['default'] = {
        DefaultAssetType: 1,

        Yes: '1',
        No: '0',

        StringConst: {
            Equal: '=',
            Ampersand: '&',
            Question: '?',
            Pipe: '|',
            Comma: ',',
            Empty: '',
            Tilde: '~',
            Dot: '.',
            Space: ' ',
            Underscore: '_',
            Colon: ':',
            Slash: '/',
            Star: '*',
            OpenBrace: '{',
            CloseBrace: '}',
            OpenBracket: '[',
            CloseBracket: ']',
            Quote: '"'

        },

        StorageType: {
            Local: 'local', // Local storage
            Session: 'session' // Session storage
        },

        CacheType: {
            Announcement: 'anns',
            User: 'user',
            System: 'sys',
            Exchange: 'exchg'
        },

        CacheKeys: {
            ExchangeDefinition: 'exgDefinition',
            ExchangeDefinitionHeader: 'exgDefinitionHeader',

            ExchangeIndicesDefinition: 'exgIndices',
            ExchangeIndicesDefinitionHeader: 'exgIndicesHeader',

            ExchangeSymbolList: 'exgSymbList',
            ExchangeSymbolListHeader: 'exgSymbListHeader',

            ExchangeNewsProvider: 'exgNewsProv',
            ExchangeNewsProviderHeader: 'exgNewsProvHeader',

            ExchangeSectorDefinition: 'exgSector',
            ExchangeSectorDefinitionHeader: 'exgSectorHeader',

            HistoryData: 'historyData',
            HistoryDataLastUpdate: 'historyDataLastUpdate',

            FundamentalDataQ: 'fundamentalDataQ',
            FundamentalDataLastUpdateQ: 'fundamentalDataLastUpdateQ',

            FundamentalDataY: 'fundamentalDataY',
            FundamentalDataLastUpdateY: 'fundamentalDataLastUpdateY',
            FundamentalDataSelectedPeriodType: 'previousSelectedPeriodType',

            CompanyProfileData: 'CompanyProfileData',
            CompanyProfileDataLastUpdate: 'CompanyProfileDataLastUpdate',

            CurrentlySelectedExchange: 'currExg:',
            CurrentlySelectedIndex: 'currIdx',
            LatestAnnouncements: 'latestAnn',

            LoggedIn: 'loggedIn',

            UserState: 'userState',
            UserSettings: 'userSettings',

            FileMetaData: 'fileMetaData',
            FileMetaDataLastUpdate: 'fileMetaDataLastUpdate',

            ServerVersion: 'serverVersion'
        },

        DataFormatter: {
            Long: 'L',
            Integer: 'I',
            Currency: 'C',
            String: 'S',
            Date: 'D',
            Percentage: 'P',
            DivideNumber: 'DN',
            ToMonthString: 'TMS',
            Float: 'F',
            Ratio: 'R',
            Array: 'A'
        },

        Encryption: {
            Key: '21969ca8a64af278f240c014', // First 24 characters of MD5 of 'universalapp'
            Iv: '15607a23' // First 8 characters of MD5 of 'vivaldi'
        },

        MessageTypes: {
            Error: 1,
            Warning: 2,
            Info: 3,
            Question: 4
        },

        MessageBoxButtons: {
            Ok: 'ok',
            Cancel: 'cancel',
            Yes: 'yes',
            No: 'no',
            Retry: 'retry'
        },

        GAActions: {
            show: 'show',
            viewChanged: 'view-changed',
            maximize: 'maximize',
            restore: 'restore',
            rowClick: 'row-click',
            search: 'search',
            columnsChanged: 'columns-changed',
            rowDoubleClick: 'row-double-click',
            rowRightClick: 'row-right-click',
            rowMenuButton: 'row-menu-button',
            settingsChanged: 'settings-changed',
            popup: 'popup',
            keyboardShortcut: 'keyboard-shortcut',
            rowIconClick: 'row-icon-click',
            click: 'click',
            filter: 'filter',
            select: 'select',
            logout: 'logout'
        },

        // sort order for management against designation_id
        MgmntOrder: {
            1: '1',
            16767: '2',
            16768: '3',
            2: '4',
            4: '5',
            17666: '6',
            3: '7',
            5: '8'
        },

        // allow backspace, tab, delete, enter, arrows, numbers and keypad numbers ONLY
        NumericKeys: [8, 9, 13, 46, 110, 190, 37, 38, 39, 40, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105]
    };

});