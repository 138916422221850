define('ir-app/controllers/ir-widgets/factsheet-widget/company-details', ['exports', 'ir-app/controllers/base-array-controller', 'ir-app/models/price/price-service', 'ir-app/models/price/price-constants', 'ir-app/models/shared/shared-service'], function (exports, BaseArrayController, PriceService, PriceConstants, sharedService) {

    'use strict';

    /**
     * Created by akilam on 1/31/2017.
     */
    //import Ember from 'ember';
    //import Utils from '../../../utils/utils';
    exports['default'] = BaseArrayController['default'].extend({
        stock: undefined,
        basicInfo: {},
        needs: ['application'],
        zeroFreeFltShr: undefined,
        lanEn: true,

        onPrepareData: function onPrepareData() {
            var that = this;
            var basicInfo = that.get('basicInfo');
            basicInfo = PriceService['default'].companyProfileDS.getStoredObject(that.get('exg'), that.get('sym'), sharedService['default'].userSettings.currentLanguage, function () {}, function () {}, PriceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyBasicInfo);

            if (sharedService['default'].userSettings.currentLanguage === 'EN') {
                that.set('lanEn', true);
            } else {
                that.set('lanEn', false);
            }

            that.set('basicInfo', basicInfo);
            that.set('stock', PriceService['default'].stockDS.getStock(that.get('exg'), that.get('sym')));
            PriceService['default'].addSymbolRequest(that.get('exg'), that.get('sym'));
        },

        onClearData: function onClearData() {
            PriceService['default'].removeSymbolRequest(this.get('exg'), this.get('sym'));
        },

        freeFltShr: (function () {
            if (isNaN(this.basicInfo.freeFltShr) || this.basicInfo.freeFltShr === "" || this.basicInfo.freeFltShr === 0) {
                this.zeroFreeFltShr = true;
            } else {
                this.zeroFreeFltShr = false;
            }
        }).observes('basicInfo.freeFltShr'),

        image: (function () {
            if (this.utils.validators.isAvailable(this.get('basicInfo.logo'))) {
                return this.utils.imageHelper.getImage(this.get('basicInfo.logo'));
            }
        }).property('basicInfo.logo')
    });

});