define('ir-app/models/shared/shared-business-entities/user-settings', ['exports', 'ember', 'ir-app/models/shared/shared-business-entities/persistent-object'], function (exports, Ember, PersistentObject) {

    'use strict';

    exports['default'] = PersistentObject['default'].extend({
        cacheKey: 'userSettings',

        // Current session related params
        currentCustomisation: '',
        currentLanguage: '',
        currentTheme: '',
        CurrentAppMode: '',
        currentExchange: '',
        currentSymbol: '',
        currentCompanyId: '',
        currentIndex: '',
        currentSubMarket: -1,
        currentLoginStatus: 1,
        username: '',
        password: '',
        ssoToken: '',
        previousLoggedIn: '0',
        rememberMe: '0',
        serverVersion: -1,
        orientListenerAdded: false,

        setCacheKey: function setCacheKey(appMode, customisation) {
            this.set('cacheKey', 'userSettings_v1' + '_' + appMode + '_' + customisation);
        },

        setData: function setData(userSettings) {
            if (userSettings) {
                var that = this;

                Ember['default'].$.each(userSettings, function (key, value) {
                    that.set(key, value);
                });
            }
        }
    }).create();

});