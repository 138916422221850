define('ir-app/models/price/price-business-entities/fundamentals/estimate-field-object', ['exports', 'ember', 'ir-app/models/price/price-business-entities/fundamentals/estimate-year-object'], function (exports, Ember, yearObj) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        fieldID: undefined,
        yearlyFieldForecasts: undefined,

        init: function init() {
            this.yearlyFieldForecasts = {};
        },

        setFieldForecastsTable: function setFieldForecastsTable(key, DUR_YEAR, PROVIDER_NAME, PROVIDED_DATE, value) {
            if (key === undefined || value === undefined) {
                return;
            }
            var yearlyFieldForecasts = this.get('yearlyFieldForecasts');
            var dataObject = yearlyFieldForecasts[DUR_YEAR];

            var object;

            if (!dataObject) {
                object = yearObj['default'].create();
                object.estimateYear = DUR_YEAR;
                object.setProviderList(DUR_YEAR, PROVIDER_NAME, PROVIDED_DATE, value);

                yearlyFieldForecasts[DUR_YEAR] = object;
            } else {
                object = yearlyFieldForecasts[DUR_YEAR];
                object.setProviderList(DUR_YEAR, PROVIDER_NAME, PROVIDED_DATE, value);
            }
            //return object;
        }
    });

});