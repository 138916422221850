define('ir-app/views/table/per-change-cell', ['exports', 'ember', 'ir-app/views/table/dual-cells/dual-change-cell'], function (exports, Ember, DualChangeCell) {

    'use strict';

    exports['default'] = DualChangeCell['default'].extend({
        templateName: 'table/views/change-cell',

        formattedFirstValue: Ember['default'].computed(function () {
            // Override formattedSecondValue to add different format
            return this.addPercentageFormat(this.get('cellContent') ? this.get('cellContent').firstValue : undefined, 2);
        }).property('cellContent')
    });

});