define('ir-app/controllers/ir-widgets/exchange-summary/market-indices', ['exports', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service'], function (exports, BaseController, priceService, sharedService) {

    'use strict';

    exports['default'] = BaseController['default'].extend({
        exchange: {},
        idxDetails: [],

        onPrepareData: function onPrepareData() {
            var that = this;
            var exchange = this.get('exg');
            that.startLoadingProgress();
            priceService['default'].downloadExchangeProperty(exchange, that.get('lan'));

            this.set('exchange', priceService['default'].exchangeDS.getExchange(exchange));
            priceService['default'].addExchangeRequest(exchange);
            priceService['default'].downloadParamsSymbolMasterForExchange({
                exchange: exchange,
                language: sharedService['default'].userSettings.currentLanguage,

                successFn: function successFn() {
                    that.onSuccess();
                    that.onDataSuccess();
                },

                errorFn: function errorFn() {
                    that.onError();
                    that.onDataError();
                }
            });
        },

        onClearData: function onClearData() {
            priceService['default'].removeFullMarketIndexRequest(this.get('exg'));
            priceService['default'].removeExchangeRequest(this.get('exg'));
            this.set('exchange', null);
        },
        onSuccess: function onSuccess() {
            this.set('idxDetails', priceService['default'].stockDS.getIndexCollectionForExchange(this.get('exg')));
            priceService['default'].addFullMarketIndexRequest(this.get('exg'));
        },
        onCheckDataAvailability: function onCheckDataAvailability() {
            var idxDetails = priceService['default'].downloadParamsSymbolMasterForExchange({
                exchange: this.get('exg'),
                language: sharedService['default'].userSettings.currentLanguage });
            if (idxDetails) {
                return true;
            }
            return false;
        },

        onError: function onError() {}
    });

});