define('ir-app/controllers/ir-mobile/quote-widgets/top-bar-quote-new', ['exports', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/models/price/price-constants'], function (exports, BaseController, priceService, sharedService, priceConstants) {

    'use strict';

    /**
     * Created by banushas on 5/11/2016.
     */

    exports['default'] = BaseController['default'].extend({
        stock: {},
        basicInfo: {},
        exchange: {},
        newStkOvw: false,
        lang: undefined,
        needs: ['application'],

        onPrepareData: function onPrepareData() {
            var that = this;
            var basicInfo = this.get('basicInfo');
            if (this.utils.validators.isAvailable(this.get('companyId'))) {
                basicInfo = priceService['default'].companyProfileDS.getStoredObjectByCompanyId(this.get('companyId'), sharedService['default'].userSettings.currentLanguage, function () {
                    var _basicInfo = priceService['default'].companyProfileDS.getStoredObjectByCompanyId(that.get('companyId'), sharedService['default'].userSettings.currentLanguage);
                    that.set('sym', _basicInfo.sym);
                    that.set('exg', _basicInfo.exg);
                    that._onPrepareData();
                }, function () {}, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyBasicInfo);
            } else {
                basicInfo = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, function () {}, function () {}, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyBasicInfo);
                this._onPrepareData();
            }
            this.set('basicInfo', basicInfo);
        },

        onAfterRender: function onAfterRender() {
            this._renderChartView();
        },

        onClearData: function onClearData() {
            priceService['default'].removeSymbolRequest(this.get('exg'), this.get('sym'));
        },

        onLanguageChanged: function onLanguageChanged() {
            this.set('basicInfo', null);
            this._onPrepareData();
            priceService['default'].downloadExchangeProperty(this.get('exg'), sharedService['default'].userSettings.currentLanguage);
        },

        _onPrepareData: function _onPrepareData() {
            var stock = priceService['default'].stockDS.getStock(this.get('exg'), this.get('sym'));
            this.set('stock', stock);

            var exchange = priceService['default'].exchangeDS.getExchange(this.get('exg'));
            this.set('exchange', exchange);

            this.set('lang', sharedService['default'].userSettings.currentLanguage.toLowerCase());
            priceService['default'].addSymbolRequest(this.get('exg'), this.get('sym'));
            priceService['default'].downloadExchangeProperty(this.get('exg'), sharedService['default'].userSettings.currentLanguage);
        },

        baseTitle: (function () {
            this.setValueToBaseSymbolContainer('baseTitle', this.get('basicInfo').compName);
        }).observes('basicInfo.compName'),

        _renderChartView: function _renderChartView() {
            var detailViewController = 'ir-mobile/chart-widgets/basic-chart-widget';
            var controllerString = 'controller:' + detailViewController;
            var widgetController = this.container.lookupFactory(controllerString).create();
            widgetController.set('isPortalTopBarChart', true);
            widgetController.set('chartHeight', this.get('chartHeight'));

            widgetController.set('sym', this.get('sym'));
            widgetController.set('exg', this.get('exg'));
            widgetController.initializeWidget({ wn: controllerString.split('/').pop() });

            var route = this.container.lookup('route:' + this.get('controllers.application.currentRouteName'));
            route.render(detailViewController, {
                into: 'ir-mobile/quote-widgets/top-bar-quote-new',
                outlet: 'chartView',
                controller: widgetController
            });

            widgetController.onUnloadWidget();
        }

    });

});