define('ir-app/controllers/ir-mobile/media-room-widgets/facebook', ['exports', 'ir-app/controllers/base-controller', 'ir-app/utils/utils'], function (exports, BaseController, utils) {

    'use strict';

    /**
     * Created by banushas on 3/25/2016.
     */

    exports['default'] = BaseController['default'].extend({

        facebookNotAvailable: false,
        facebookLoadComplete: false,
        facebookURL: '',

        onLoadWidget: function onLoadWidget() {
            var that = this;
            that.set('facebookLoadComplete', false);
            try {
                if (that.get('mediaURL') !== '') {
                    that.set('facebookURL', that.get('mediaURL'));
                    that.set('facebookLoadComplete', true);
                } else {
                    that.set('facebookNotAvailable', true);
                }
            } catch (e) {
                that.set('facebookNotAvailable', true);
                utils['default'].logger.logError('[Facebook] onLoadWidget() ' + e);
            }
            this.setErrorMessage();
        }

    });

});