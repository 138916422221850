define('ir-app/controllers/ir-widgets/financial-widgets/stock-trades', ['exports', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/models/price/price-constants', 'ir-app/utils/utils'], function (exports, BaseController, priceService, sharedService, priceConstants, utils) {

    'use strict';

    /**
     * Created by banushas on 5/11/2016.
     */

    exports['default'] = BaseController['default'].extend({
        stock: {},
        basicInfo: {},
        trades: {},
        exchange: {},
        showTrades: Ember.A(),
        lang: undefined,
        dataAvailable: false,
        needs: ['application'],

        onPrepareData: function onPrepareData() {
            var that = this;
            var basicInfo = this.get('basicInfo');
            if (this.utils.validators.isAvailable(this.get('companyId'))) {
                basicInfo = priceService['default'].companyProfileDS.getStoredObjectByCompanyId(this.get('companyId'), sharedService['default'].userSettings.currentLanguage, function () {
                    var _basicInfo = priceService['default'].companyProfileDS.getStoredObjectByCompanyId(that.get('companyId'), sharedService['default'].userSettings.currentLanguage);
                    that.set('sym', _basicInfo.sym);
                    that.set('exg', _basicInfo.exg);
                    that._onPrepareData();
                }, function () {}, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyBasicInfo);
            } else {
                basicInfo = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, function () {}, function () {}, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyBasicInfo);
                this._onPrepareData();
            }
            this.set('basicInfo', basicInfo);
            this.loadTradeData();
        },

        loadTradeData: function loadTradeData() {
            var _this = this;

            var that = this;
            this.set('trades', priceService['default'].sendTimeAndSalesBacklogRequest(that.get('exg'), that.get('sym'), function () {

                var trade = priceService['default'].timeAndSalesDS.getTradeCollection(that.get('exg'), that.get('sym'));

                that.set('trades', trade.reverse());
                that.set('dataAvailable', true);
            }));
            setInterval(function () {
                _this.loadTradeData();
            }, 60 * 5 * 1000);
        },

        onClearData: function onClearData() {
            priceService['default'].removeSymbolRequest(this.get('exg'), this.get('sym'));
        },

        onLanguageChanged: function onLanguageChanged() {
            this.set('basicInfo', null);
            this._onPrepareData();
            priceService['default'].downloadExchangeProperty(this.get('exg'), sharedService['default'].userSettings.currentLanguage);
        },

        _onPrepareData: function _onPrepareData() {
            var stock = priceService['default'].stockDS.getStock(this.get('exg'), this.get('sym'));
            this.set('stock', stock);

            var exchange = priceService['default'].exchangeDS.getExchange(this.get('exg'));
            this.set('exchange', exchange);

            this.set('lang', sharedService['default'].userSettings.currentLanguage.toLowerCase());
            priceService['default'].addSymbolRequest(this.get('exg'), this.get('sym'));
            priceService['default'].downloadExchangeProperty(this.get('exg'), sharedService['default'].userSettings.currentLanguage);
        },

        convertTimeString: function convertTimeString(time) {
            var hour = time.substring(0, 2);
            var mint = time.substring(2, 4);
            var sec = time.substring(4, 6);

            return hour + ':' + mint + ':' + sec;
        },

        obTrades: (function () {

            var allTrades = this.get('trades');

            var shwTrd = this.get('showTrades');
            var that = this;
            shwTrd = Ember.A();

            if (allTrades != undefined) {
                allTrades.forEach(function (trade, index) {
                    if (index < 10) {
                        var tempTrade = trade;
                        tempTrade.tts = that.convertTimeString(tempTrade.tts);
                        shwTrd.pushObject(tempTrade);
                    }
                });
                that.set('showTrades', shwTrd);
            }
        }).observes('trades'),

        baseTitle: (function () {
            this.setValueToBaseSymbolContainer('baseTitle', this.get('basicInfo').compName);
        }).observes('basicInfo.compName'),

        actions: {
            exportTableToExcel: function exportTableToExcel() {
                var that = this;
                var dtArray = this.get('trades');

                var dtArrayHeads = ['Time', 'Last', 'Volume'];
                var dtArrayData = [];
                dtArrayData.push(dtArrayHeads.join(','));

                dtArray.forEach(function (trade) {
                    var tempArray = [];
                    var time = '';
                    if (trade.tts.indexOf(":") > -1) {
                        time = trade.tts;
                    } else {
                        time = that.convertTimeString(trade.tts);
                    }
                    tempArray = [time, trade.trp, trade.trq];
                    dtArrayData.push(tempArray.join(','));
                });

                utils['default'].exportData.exportArrayToExcel(that.get('sym'), dtArrayData);
            }
        }
    });

});