define('ir-app/templates/ir-widgets/financial-widgets/estimates', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("th");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            inline(env, morph0, context, "getDisplayHeader", [get(env, context, "displayDataObjectAct"), get(env, context, "key"), 2], {});
            return fragment;
          }
        };
      }());
      var child1 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("th");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            inline(env, morph0, context, "getDisplayHeader", [get(env, context, "displayDataObject"), get(env, context, "key"), 1], {});
            return fragment;
          }
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              isHTMLBars: true,
              revision: "Ember@1.11.1",
              blockParams: 0,
              cachedFragment: null,
              hasRendered: false,
              build: function build(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                     ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("td");
                var el2 = dom.createTextNode("\n                                         ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2,"class","bold_l_2 single-line-text");
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                     ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              render: function render(context, env, contextualElement) {
                var dom = env.dom;
                var hooks = env.hooks, get = hooks.get, concat = hooks.concat, attribute = hooks.attribute, inline = hooks.inline;
                dom.detectNamespace(contextualElement);
                var fragment;
                if (env.useFragmentCache && dom.canClone) {
                  if (this.cachedFragment === null) {
                    fragment = this.build(dom);
                    if (this.hasRendered) {
                      this.cachedFragment = fragment;
                    } else {
                      this.hasRendered = true;
                    }
                  }
                  if (this.cachedFragment) {
                    fragment = dom.cloneNode(this.cachedFragment, true);
                  }
                } else {
                  fragment = this.build(dom);
                }
                var element12 = dom.childAt(fragment, [1, 1]);
                var morph0 = dom.createMorphAt(element12,1,1);
                var attrMorph0 = dom.createAttrMorph(element12, 'id');
                attribute(env, attrMorph0, element12, "id", concat(env, ["financial_value_", get(env, context, "style.filedID"), "_", get(env, context, "key")]));
                inline(env, morph0, context, "getDisplayData", [get(env, context, "displayDataObjectAct"), get(env, context, "key"), get(env, context, "style.filedID"), false], {});
                return fragment;
              }
            };
          }());
          var child1 = (function() {
            return {
              isHTMLBars: true,
              revision: "Ember@1.11.1",
              blockParams: 0,
              cachedFragment: null,
              hasRendered: false,
              build: function build(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                     ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("td");
                var el2 = dom.createTextNode("\n                                         ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2,"class","bold_l_2 single-line-text");
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                     ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              render: function render(context, env, contextualElement) {
                var dom = env.dom;
                var hooks = env.hooks, get = hooks.get, concat = hooks.concat, attribute = hooks.attribute, inline = hooks.inline;
                dom.detectNamespace(contextualElement);
                var fragment;
                if (env.useFragmentCache && dom.canClone) {
                  if (this.cachedFragment === null) {
                    fragment = this.build(dom);
                    if (this.hasRendered) {
                      this.cachedFragment = fragment;
                    } else {
                      this.hasRendered = true;
                    }
                  }
                  if (this.cachedFragment) {
                    fragment = dom.cloneNode(this.cachedFragment, true);
                  }
                } else {
                  fragment = this.build(dom);
                }
                var element11 = dom.childAt(fragment, [1, 1]);
                var morph0 = dom.createMorphAt(element11,1,1);
                var attrMorph0 = dom.createAttrMorph(element11, 'id');
                attribute(env, attrMorph0, element11, "id", concat(env, ["financial_value_", get(env, context, "style.filedID"), "_", get(env, context, "key")]));
                inline(env, morph0, context, "getDisplayData", [get(env, context, "displayDataObject"), get(env, context, "key"), get(env, context, "style.filedID"), false], {});
                return fragment;
              }
            };
          }());
          return {
            isHTMLBars: true,
            revision: "Ember@1.11.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              var el2 = dom.createTextNode("\n                                 ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createTextNode("\n                                     ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","cursor-pointer link indent_l_1 single-line-text");
              var el4 = dom.createTextNode(" ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                 ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("                            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks, get = hooks.get, concat = hooks.concat, attribute = hooks.attribute, element = hooks.element, content = hooks.content, block = hooks.block;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var element13 = dom.childAt(fragment, [1]);
              var element14 = dom.childAt(element13, [1, 1]);
              var morph0 = dom.createMorphAt(element14,1,1);
              var attrMorph0 = dom.createAttrMorph(element14, 'id');
              var morph1 = dom.createMorphAt(element13,3,3);
              var morph2 = dom.createMorphAt(element13,4,4);
              attribute(env, attrMorph0, element14, "id", concat(env, ["financial_label_", get(env, context, "style.filedID")]));
              element(env, element14, context, "action", ["loadPopup", get(env, context, "style")], {});
              content(env, morph0, context, "style.des");
              block(env, morph1, context, "each", [get(env, context, "dataKeySetAct")], {"keyword": "key"}, child0, null);
              block(env, morph2, context, "each", [get(env, context, "dataKeySet")], {"keyword": "key"}, child1, null);
              return fragment;
            }
          };
        }());
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("th");
            dom.setAttribute(el2,"class","estimate-table-hr");
            dom.setAttribute(el2,"colspan","8");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, inline = hooks.inline, block = hooks.block;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(dom.childAt(fragment, [1, 1]),0,0);
            var morph1 = dom.createMorphAt(fragment,3,3,contextualElement);
            dom.insertBoundary(fragment, null);
            inline(env, morph0, context, "getSubCatName", [get(env, context, "app.lang.labels"), get(env, context, "displayStyleObject")], {});
            block(env, morph1, context, "each", [get(env, context, "displayStyleObject")], {"keyword": "style"}, child0, null);
            return fragment;
          }
        };
      }());
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("table");
          dom.setAttribute(el1,"border","1");
          dom.setAttribute(el1,"class","table table-striped table-bordered numeric-table large-tbl large-tbl-mobile");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("tbody");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("tr");
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("th");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("tbody");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, block = hooks.block;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element15 = dom.childAt(fragment, [1]);
          var element16 = dom.childAt(element15, [1, 1]);
          var morph0 = dom.createMorphAt(element16,3,3);
          var morph1 = dom.createMorphAt(element16,4,4);
          var morph2 = dom.createMorphAt(dom.childAt(element15, [3]),1,1);
          block(env, morph0, context, "each", [get(env, context, "dataKeySetAct")], {"keyword": "key"}, child0, null);
          block(env, morph1, context, "each", [get(env, context, "dataKeySet")], {"keyword": "key"}, child1, null);
          block(env, morph2, context, "each", [get(env, context, "displayChunkObject")], {"keyword": "displayStyleObject"}, child2, null);
          return fragment;
        }
      };
    }());
    var child1 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                No Data Available\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          return fragment;
        }
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("th");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            inline(env, morph0, context, "getDisplayHeader", [get(env, context, "displayDataObjectAct"), get(env, context, "key"), 2], {});
            return fragment;
          }
        };
      }());
      var child1 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            inline(env, morph0, context, "getDisplayData", [get(env, context, "displayDataObjectAct"), get(env, context, "key"), get(env, context, "popupField"), false], {});
            return fragment;
          }
        };
      }());
      var child2 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            dom.setAttribute(el1,"class","text-align-center");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, content = hooks.content;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            content(env, morph0, context, "key");
            return fragment;
          }
        };
      }());
      var child3 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            dom.setAttribute(el1,"class","text-align-center");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, content = hooks.content;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            content(env, morph0, context, "key");
            return fragment;
          }
        };
      }());
      var child4 = (function() {
        var child0 = (function() {
          return {
            isHTMLBars: true,
            revision: "Ember@1.11.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("td");
              dom.setAttribute(el1,"class","text-align-right");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var morph0 = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
              inline(env, morph0, context, "getDisplayData", [get(env, context, "obj.prevEstimates"), get(env, context, "key"), get(env, context, "popupField"), true], {});
              return fragment;
            }
          };
        }());
        var child1 = (function() {
          return {
            isHTMLBars: true,
            revision: "Ember@1.11.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("td");
              dom.setAttribute(el1,"class","text-align-right");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var morph0 = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
              inline(env, morph0, context, "getDisplayData", [get(env, context, "obj.latestEstimates"), get(env, context, "key"), get(env, context, "popupField"), true], {});
              return fragment;
            }
          };
        }());
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            var el2 = dom.createTextNode("\n                                                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2,"class","text-align-left");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("<td>--</td>");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("<td>--</td>");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("                                                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, content = hooks.content, get = hooks.get, block = hooks.block;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element0 = dom.childAt(fragment, [1]);
            var morph0 = dom.createMorphAt(dom.childAt(element0, [1]),0,0);
            var morph1 = dom.createMorphAt(element0,7,7);
            var morph2 = dom.createMorphAt(element0,8,8);
            content(env, morph0, context, "obj.providerID");
            block(env, morph1, context, "each", [get(env, context, "headingYearSetter.keysOfPrev")], {"keyword": "key"}, child0, null);
            block(env, morph2, context, "each", [get(env, context, "headingYearSetter.keysOfLatest")], {"keyword": "key"}, child1, null);
            return fragment;
          }
        };
      }());
      var child5 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            dom.setAttribute(el1,"class","text-align-right");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            inline(env, morph0, context, "getAvgData", [get(env, context, "popupDataMap"), get(env, context, "key"), get(env, context, "popupField"), 0], {});
            return fragment;
          }
        };
      }());
      var child6 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            dom.setAttribute(el1,"class","text-align-right");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            inline(env, morph0, context, "getAvgData", [get(env, context, "popupDataMap"), get(env, context, "key"), get(env, context, "popupField"), 1], {});
            return fragment;
          }
        };
      }());
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col-xs-12 col-md-12 col-lg-10");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","widget-popup is-visible");
          dom.setAttribute(el2,"role","alert");
          var el3 = dom.createTextNode("\n\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","widget-popup-container estimate-popup_box estimates-popup-div container-wrapper show-scroll");
          var el4 = dom.createTextNode("\n\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","padding-large");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("<div class=\"col-xs-4 col-md-4 col-lg-4 margin-medium-bottom\">");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","col-xs-12 col-md-12 col-lg-12 no-padding margin-large-bottom");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("h2");
          dom.setAttribute(el6,"class","widget-popup-title  text-align-center");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n\n\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("<div class=\"\">");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","col-xs-12 col-md-4 col-lg-4 no-padding actual-table display-inline margin-large-bottom");
          var el6 = dom.createTextNode("\n                                    ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("<div class=\"col-xs-12 col-md-3 no-padding\">");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                                    ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("table");
          dom.setAttribute(el6,"border","1");
          dom.setAttribute(el6,"class","widget-popup-table table table-striped table-bordered estimate-popup");
          var el7 = dom.createTextNode("\n                                        ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("tbody");
          var el8 = dom.createTextNode("\n                                            ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("tr");
          var el9 = dom.createTextNode("\n");
          dom.appendChild(el8, el9);
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode("                                            ");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                                        ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                                        ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("tbody");
          var el8 = dom.createTextNode("\n                                            ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("tr");
          dom.setAttribute(el8,"class","text-align-right");
          var el9 = dom.createTextNode("\n");
          dom.appendChild(el8, el9);
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode("                                            ");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                                        ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                                    ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                                    ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("</div>");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("</div>");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","col-xs-12 col-md-8 col-lg-8 no-padding display-inline margin-large-bottom");
          var el6 = dom.createTextNode("\n                                    ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","estimate-pop-up-chart-height");
          var el7 = dom.createTextNode("\n                                        ");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                                    ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("</div>");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","col-xs-12 col-md-12 col-lg-12 no-padding margin-medium-bottom margin-large-bottom");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","display-inline full-width");
          var el7 = dom.createTextNode("\n                                    ");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("<div class=\"col-xs-12 col-md-12 no-padding\">");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                                        ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          dom.setAttribute(el7,"class","table-responsive");
          var el8 = dom.createTextNode("\n                                            ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("table");
          dom.setAttribute(el8,"class","table table-bordered estimate-popup table-condensed");
          var el9 = dom.createTextNode("\n                                                ");
          dom.appendChild(el8, el9);
          var el9 = dom.createElement("thead");
          var el10 = dom.createTextNode("\n                                                    ");
          dom.appendChild(el9, el10);
          var el10 = dom.createElement("tr");
          var el11 = dom.createTextNode("\n                                                        ");
          dom.appendChild(el10, el11);
          var el11 = dom.createElement("th");
          dom.setAttribute(el11,"colspan","1");
          var el12 = dom.createTextNode("Research Details");
          dom.appendChild(el11, el12);
          dom.appendChild(el10, el11);
          var el11 = dom.createTextNode("\n                                                        ");
          dom.appendChild(el10, el11);
          var el11 = dom.createElement("th");
          var el12 = dom.createTextNode("Previous Estimates");
          dom.appendChild(el11, el12);
          dom.appendChild(el10, el11);
          var el11 = dom.createTextNode("\n                                                        ");
          dom.appendChild(el10, el11);
          var el11 = dom.createElement("th");
          var el12 = dom.createTextNode("Latest Estimates");
          dom.appendChild(el11, el12);
          dom.appendChild(el10, el11);
          var el11 = dom.createTextNode("\n                                                    ");
          dom.appendChild(el10, el11);
          dom.appendChild(el9, el10);
          var el10 = dom.createTextNode("\n                                                ");
          dom.appendChild(el9, el10);
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode("\n                                                ");
          dom.appendChild(el8, el9);
          var el9 = dom.createElement("tbody");
          var el10 = dom.createTextNode("\n                                                    ");
          dom.appendChild(el9, el10);
          var el10 = dom.createElement("tr");
          var el11 = dom.createTextNode("\n                                                        ");
          dom.appendChild(el10, el11);
          var el11 = dom.createElement("td");
          dom.setAttribute(el11,"class","text-align-left");
          var el12 = dom.createTextNode("Provider");
          dom.appendChild(el11, el12);
          dom.appendChild(el10, el11);
          var el11 = dom.createTextNode("\n                                                        ");
          dom.appendChild(el10, el11);
          var el11 = dom.createComment("<td>Latest Date</td>");
          dom.appendChild(el10, el11);
          var el11 = dom.createTextNode("\n                                                        ");
          dom.appendChild(el10, el11);
          var el11 = dom.createComment("<td>Prev. Date</td>");
          dom.appendChild(el10, el11);
          var el11 = dom.createTextNode("\n");
          dom.appendChild(el10, el11);
          var el11 = dom.createComment("");
          dom.appendChild(el10, el11);
          var el11 = dom.createComment("");
          dom.appendChild(el10, el11);
          var el11 = dom.createTextNode("\n                                                    ");
          dom.appendChild(el10, el11);
          dom.appendChild(el9, el10);
          var el10 = dom.createTextNode("\n");
          dom.appendChild(el9, el10);
          var el10 = dom.createComment("");
          dom.appendChild(el9, el10);
          var el10 = dom.createTextNode("                                                ");
          dom.appendChild(el9, el10);
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode("\n                                                ");
          dom.appendChild(el8, el9);
          var el9 = dom.createElement("tfoot");
          var el10 = dom.createTextNode("\n                                                    ");
          dom.appendChild(el9, el10);
          var el10 = dom.createElement("tr");
          dom.setAttribute(el10,"class","average");
          var el11 = dom.createTextNode("\n                                                        ");
          dom.appendChild(el10, el11);
          var el11 = dom.createElement("td");
          dom.setAttribute(el11,"class","bold text-align-right");
          dom.setAttribute(el11,"colspan","1");
          var el12 = dom.createTextNode("Average Values");
          dom.appendChild(el11, el12);
          dom.appendChild(el10, el11);
          var el11 = dom.createTextNode("\n");
          dom.appendChild(el10, el11);
          var el11 = dom.createComment("");
          dom.appendChild(el10, el11);
          var el11 = dom.createComment("");
          dom.appendChild(el10, el11);
          var el11 = dom.createTextNode("                                                    ");
          dom.appendChild(el10, el11);
          dom.appendChild(el9, el10);
          var el10 = dom.createTextNode("\n                                                ");
          dom.appendChild(el9, el10);
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode("\n                                            ");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                                        ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                                    ");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("</div>");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                                ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("a");
          dom.setAttribute(el5,"class","widget-popup-close img-replace");
          var el6 = dom.createTextNode("Close");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, content = hooks.content, get = hooks.get, block = hooks.block, inline = hooks.inline, concat = hooks.concat, attribute = hooks.attribute, element = hooks.element;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element1 = dom.childAt(fragment, [1, 1, 1, 1]);
          var element2 = dom.childAt(element1, [7, 3]);
          var element3 = dom.childAt(element1, [15, 1, 3, 1]);
          var element4 = dom.childAt(element3, [1, 1]);
          var element5 = dom.childAt(element4, [3]);
          var element6 = dom.childAt(element4, [5]);
          var element7 = dom.childAt(element3, [3]);
          var element8 = dom.childAt(element7, [1]);
          var element9 = dom.childAt(element3, [5, 1]);
          var element10 = dom.childAt(element1, [17]);
          var morph0 = dom.createMorphAt(dom.childAt(element1, [3, 1]),0,0);
          var morph1 = dom.createMorphAt(dom.childAt(element2, [1, 1]),1,1);
          var morph2 = dom.createMorphAt(dom.childAt(element2, [3, 1]),1,1);
          var morph3 = dom.createMorphAt(dom.childAt(element1, [11, 1]),1,1);
          var attrMorph0 = dom.createAttrMorph(element5, 'colspan');
          var attrMorph1 = dom.createAttrMorph(element6, 'colspan');
          var morph4 = dom.createMorphAt(element8,7,7);
          var morph5 = dom.createMorphAt(element8,8,8);
          var morph6 = dom.createMorphAt(element7,3,3);
          var morph7 = dom.createMorphAt(element9,3,3);
          var morph8 = dom.createMorphAt(element9,4,4);
          content(env, morph0, context, "popupTitle");
          block(env, morph1, context, "each", [get(env, context, "popupActArray")], {"keyword": "key"}, child0, null);
          block(env, morph2, context, "each", [get(env, context, "popupActArray")], {"keyword": "key"}, child1, null);
          inline(env, morph3, context, "estimate-chart", [], {"xDataSet": get(env, context, "xDataSet"), "dataSetAct": get(env, context, "dataSetAct"), "dataSetMin": get(env, context, "dataSetMin"), "dataSetAvg": get(env, context, "dataSetAvg"), "dataSetMax": get(env, context, "dataSetMax"), "elementId": "chart1"});
          attribute(env, attrMorph0, element5, "colspan", concat(env, [get(env, context, "prevEstDivisionSetter")]));
          attribute(env, attrMorph1, element6, "colspan", concat(env, [get(env, context, "latestEstDivisionSetter")]));
          block(env, morph4, context, "each", [get(env, context, "headingYearSetter.keysOfPrev")], {"keyword": "key"}, child2, null);
          block(env, morph5, context, "each", [get(env, context, "headingYearSetter.keysOfLatest")], {"keyword": "key"}, child3, null);
          block(env, morph6, context, "each", [get(env, context, "popupDataArr")], {"keyword": "obj"}, child4, null);
          block(env, morph7, context, "each", [get(env, context, "headingYearSetter.keysOfPrev")], {"keyword": "key"}, child5, null);
          block(env, morph8, context, "each", [get(env, context, "headingYearSetter.keysOfLatest")], {"keyword": "key"}, child6, null);
          element(env, element10, context, "action", ["unloadPopup"], {});
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","row");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col-xs-12");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","full-width x-scroll-auto show-scroll");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element17 = dom.childAt(fragment, [0, 1, 1]);
        var morph0 = dom.createMorphAt(element17,1,1);
        var morph1 = dom.createMorphAt(element17,3,3);
        var morph2 = dom.createMorphAt(fragment,2,2,contextualElement);
        dom.insertBoundary(fragment, null);
        block(env, morph0, context, "unless", [get(env, context, "estimationsNotAvailable")], {}, child0, null);
        block(env, morph1, context, "if", [get(env, context, "estimationsNotAvailable")], {}, child1, null);
        block(env, morph2, context, "if", [get(env, context, "isShowPopup")], {}, child2, null);
        return fragment;
      }
    };
  }()));

});