define('ir-app/models/price/price-business-entities/stock-fundamental', ['exports', 'ember', 'ir-app/models/price/price-business-entities/balance-sheet', 'ir-app/models/price/price-business-entities/income-statement', 'ir-app/models/price/price-business-entities/cash-flow', 'ir-app/models/price/price-business-entities/ratios', 'ir-app/models/price/price-business-entities/fundamental-data'], function (exports, Ember, balanceSheet, incomeStatement, cashFlow, ratios, fundamentalItem) {

    'use strict';

    /**
     * Created by asankaa on 5/11/2016.
     */
    exports['default'] = Ember['default'].Object.extend({

        fdItemQ: null,
        fdItemY: null,

        getDataItemQ: function getDataItemQ() {
            return this.get('fdItemQ');
        },

        setDataItemQ: function setDataItemQ(fDataQ) {
            this.set('fdItemQ', fDataQ);
        },

        getDataItemY: function getDataItemY() {
            return this.get('fdItemY');
        },

        setDataItemY: function setDataItemY(fDataY) {
            this.set('fdItemY', fDataY);
        },

        createBalanceSheet: function createBalanceSheet() {
            return balanceSheet['default'].create();
        },

        createIncomeStatement: function createIncomeStatement() {
            return incomeStatement['default'].create();
        },

        createCashFlow: function createCashFlow() {
            return cashFlow['default'].create();
        },

        createRatios: function createRatios() {
            return ratios['default'].create();
        },

        createfundamentalItem: function createfundamentalItem() {
            return fundamentalItem['default'].create();
        }

    });

});