define('ir-app/controllers/ir-mobile/chart-widgets/summary-chart-widget', ['exports', 'ember', 'ir-app/controllers/ir-mobile/chart-widgets/basic-chart-widget', 'ir-app/models/price/price-service', 'ir-app/models/chart/chart-constants', 'ir-app/models/shared/shared-service', 'ir-app/components/stk-specific-components/high-chart/dynamic-high-chart'], function (exports, Ember, BasicChartWidget, priceService, ChartConstants, sharedService, DynamicHighChart) {

    'use strict';

    exports['default'] = BasicChartWidget['default'].extend({

        indexData: undefined,
        volumeViewEnabled: null,
        exchange: undefined,
        lang: undefined,
        symbol: undefined,

        // Base overrides
        onLoadWidget: function onLoadWidget() {
            this._super();
            this.set('isPortalChart', true);
            this.set('volumeViewEnabled', false);
            this.set('chartCategory', ChartConstants['default'].ChartCategory.Intraday);
            this.set('chartDataLevel', ChartConstants['default'].ChartViewPeriod.OneDay.ChartDataLevel);
            this.set('chartViewPeriod', ChartConstants['default'].ChartViewPeriod.OneDay);

            var displayKeyList = [];
            var keyVal;
            if (this.get('dropDownKeys') !== undefined && this.get('dropDownKeys').length > 0) {
                this.get('dropDownKeys').forEach(function (item) {
                    keyVal = {};
                    keyVal.exg = item.exg;
                    var prop = ['displayName', sharedService['default'].userSettings.currentLanguage].join('');
                    keyVal.displayName = item[prop];
                    displayKeyList.push(keyVal);
                });

                this.setValueToBaseExchangeContainer('keyList', displayKeyList);
                this.set('keyList', this.get('dropDownKeys'));
            }
        },

        onPrepareData: function onPrepareData() {
            var that = this;
            if (that.get('keyList')) {
                that.set('sym', that.get('keyList').find(function (o) {
                    return o.exg === that.get('exg');
                }).index);
            }var renameSymbols = that.get('renameSymbols');
            var tmpIdx = priceService['default'].stockDS.getStock(that.get('exg'), that.get('sym'));

            // set display symbol instead of url symbol - as per the given requirement
            if (renameSymbols) {
                if (renameSymbols[that.get('sym')]) {
                    tmpIdx.displaySym = renameSymbols[that.get('sym')];
                }
            } else {
                tmpIdx.displaySym = that.get('sym');
            }
            priceService['default'].addIndexRequest(that.get('exg'), that.get('sym'));
            that.set('indexData', tmpIdx);

            that.set('exchange', that.get('exg'));
            that.set('lang', sharedService['default'].userSettings.currentLanguage.toLowerCase());
            that.set('clientStkPage', that.get('clientStkPage'));
            that.set('symbol', that.get('sym'));

            var successFn = function successFn() {
                that.loadChart();
            };

            var errorFn = function errorFn() {};

            priceService['default'].downloadExchangeProperty(that.get('exg'), sharedService['default'].userSettings.currentLanguage, successFn, errorFn);
        },

        onMktStatChangeToPreopen: function onMktStatChangeToPreopen() {
            if (this.get('chartViewPeriod').chartDataLevel === ChartConstants['default'].ChartViewPeriod.OneDay.chartDataLevel) {
                this.set('chartDataArray', Ember['default'].A());
                this.set('chartCloseDataArray', Ember['default'].A());
                this.set('chartVolumeDataArray', Ember['default'].A());
                this.resetMinMax();
            }
        },

        onAfterRender: function onAfterRender() {
            var that = this;
            that._super();
        },

        onClearData: function onClearData() {
            priceService['default'].removeIndexRequest(this.get('exg'), this.get('sym'));
            this._super();
        }

    });

    Ember['default'].Handlebars.helper('dynamic-high-chart', DynamicHighChart['default']);

});