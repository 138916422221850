define('ir-app/controllers/ir-widgets/financial-dividend-widgets/total-return', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/models/chart/chart-constants', 'ir-app/models/shared/shared-service', 'ir-app/models/price/price-constants', 'ir-app/utils/utils', 'ir-app/components/stk-specific-components/high-chart/dynamic-high-chart'], function (exports, Ember, BaseController, priceService, chartConstants, sharedService, priceConstants, utils, DynamicHighChart) {

    'use strict';

    exports['default'] = BaseController['default'].extend({

        count: undefined,
        searchParams: undefined,
        capActObj: undefined,
        detailList: Ember['default'].A(),
        actionTypes: Ember['default'].A(),
        chartWidth: 500,
        isDtlTbl: false,
        isCapChgTbl: false,
        isActionTypes: false,
        isCapActData: false,
        isCachedData: false, // if object need to be cached or not
        customView: false,
        current: 1,
        actTypeId: 7,
        pageSize: 20,
        isCalendar: 1,
        chartData: [],
        annualChartData: [],
        annualData: [],
        tableData: [],
        optionsCounter: false,
        optionsThreeCounter: false,
        optionsFiveCounter: false,
        optionsCustomCounter: false,
        oneYearCumulativeDates: undefined,
        threeYearCumulativeDates: undefined,
        fiveYearCumulativeDates: undefined,
        customYearCumulativeDates: undefined,
        oneYearCumulativeCounter: true,
        threeYearCumulativeCounter: false,
        fiveYearCumulativeCounter: false,
        customYearCumulativeCounter: false,
        globalOHLCSeries: undefined,
        globalDividendListForTotalReturn: undefined,
        globalMarkerPoints: Ember['default'].A(),
        stock: undefined,
        flag: true,
        to_date: undefined,
        from_date: undefined,

        chartOptions: (function () {
            var that = this;
            return {
                chart: {
                    width: 1100,
                    backgroundColor: "#fff",
                    pinchType: '',
                    zoomType: ''

                },
                title: {
                    text: ' '
                },
                plotOptions: {
                    column: {
                        dataLabels: {
                            enabled: true,
                            formatter: function formatter() {

                                return this.y + ' %';
                            }
                        }
                    }
                },
                dataGrouping: {
                    enabled: true
                },
                tickLength: 0,
                xAxis: {
                    type: 'datetime',
                    categories: ["1 year", "3 year", "5 year"],
                    // gridLineWidth: that.get('isToolTipChart') ? '1' : '0',
                    // labels: {
                    //     enabled: that.get('isToolTipChart') ? true : false
                    // },
                    title: {
                        text: null
                    }
                },
                yAxis: {
                    labels: {
                        enabled: false
                    },
                    stackLabels: {
                        style: {
                            color: 'black'
                        },
                        enabled: true,
                        verticalAlign: 'top'
                    },
                    gridLineColor: '#ffffff',
                    lineColor: '#ffffff',
                    title: {
                        text: null
                    }
                },
                rangeSelector: {
                    enabled: false
                },
                navigator: {
                    enabled: false
                },
                scrollbar: {
                    enabled: false
                },
                tooltip: {
                    enabled: true,
                    shared: true,
                    useHTML: true,
                    valueSuffix: ' %'
                },
                exporting: {
                    enabled: false
                } };
        }).property(),
        annualChartOptions: (function () {
            var yearsList = this.generateArrayOfYears(5).reverse();
            return {
                chart: {
                    width: 1100,
                    backgroundColor: "#fff",
                    pinchType: '',
                    zoomType: ''

                },
                title: {
                    text: ' '
                },
                plotOptions: {
                    column: {
                        dataLabels: {
                            enabled: true
                        }
                    }
                },
                dataGrouping: {
                    enabled: true
                },
                tickLength: 0,
                xAxis: {
                    type: 'datetime',
                    categories: yearsList,
                    // gridLineWidth: that.get('isToolTipChart') ? '1' : '0',
                    // labels: {
                    //     enabled: that.get('isToolTipChart') ? true : false
                    // },
                    title: {
                        text: null
                    }
                },
                yAxis: {
                    title: {
                        text: '%'
                    },
                    // labels: {
                    //     enabled: true
                    // },
                    stackLabels: {
                        style: {
                            color: 'black'
                        },
                        enabled: true,
                        formatter: function formatter() {
                            return this.y + " %";
                        },
                        verticalAlign: 'top'
                    },
                    gridLineColor: '#d3d3d3',
                    lineColor: '#d3d3d3'
                },
                rangeSelector: {
                    enabled: false
                },
                navigator: {
                    enabled: false
                },
                scrollbar: {
                    enabled: false
                },
                tooltip: {
                    enabled: true,
                    useHTML: true
                },
                exporting: {
                    enabled: false
                } };
        }).property(),

        setData: (function () {
            var that = this;
            var chart = [{
                type: 'column',
                data: this.get("series")[0].data,
                name: that.stock.lDes,
                color: '#F5F5F5'
            }, {
                type: 'column',
                data: this.get("series")[1].data,
                name: "Return on reinvested dividends",
                color: '#E0E0E0'
            }, {
                type: 'column',
                data: this.get("series")[2].data,
                name: "Total return",
                color: '#dae2e7'
            }];
            this.set("chartData", chart);
        }).observes('series'),

        setAnnualData: (function () {

            var data = [];
            var years = [];

            var annualData = this.get('annualData');

            annualData.forEach(function (v) {
                data.push(v[v.length - 1]);
                years.push(v[0]);
            });

            var chartData = [{
                type: 'column',
                data: data,
                name: "Total Return",
                color: '#dae2e7'
            }];
            this.set("chartYears", years);
            this.set("annualChartData", chartData);
        }).observes('annualData'),

        onLoadWidget: function onLoadWidget() {

            this.setErrorMessage();
        },

        onAfterRender: function onAfterRender() {
            var that = this;
            Ember['default'].$('#custom').daterangepicker({
                opens: 'right',
                applyButtonClasses: "range-picker rangepicker-apply",
                buttonClasses: "range-picker",
                cancelButtonClasses: "range-picker range-picker"
            }, function (start, end, label) {
                that.from_date = start._d;
                that.to_date = end._d;

                that.set("oneYearCumulativeCounter", false);
                that.set("threeYearCumulativeCounter", false);
                that.set("fiveYearCumulativeCounter", false);
                that.set("customYearCumulativeCounter", false);

                that.change_data_on_date_picker(start._d, end._d);
            });
        },

        change_data_on_date_picker: function change_data_on_date_picker(from_date, to_date) {
            // var dividend = 0;
            // var customDividendED = undefined;
            var from_d, to_d;
            from_d = from_date;
            to_d = to_date;

            // this.globalDividendListForTotalReturn.forEach((item)=> {
            //     if(item.acStatus==='Executed'){
            //         var newDate=utils.formatters.convertStringToDate(item.coDate, 0);
            //         if( from_d.getTime() < newDate.getTime() ){
            //             dividend =dividend+parseFloat(item.dividend_amount);
            //             if(customDividendED===undefined){
            //                 customDividendED=utils.formatters.convertStringToDate(item.efDate, 0);
            //             }
            //         }
            //     }
            // });

            var customData = this.globalOHLCSeries.queryPointsForRange(from_d.getTime(), to_d.getTime());
            var that = this;

            var cumulativeSeriesForCustomYear = this.generateTotalReturnSeries(customData, this.globalDividendListForTotalReturn, that);

            this.set("customYearCumulativeDates", cumulativeSeriesForCustomYear[0]);
            this.set("customYearCumulativeTotalReturn", cumulativeSeriesForCustomYear[1]);
            this.set("customYearCumulativePriceReturn", cumulativeSeriesForCustomYear[2]);

            setTimeout(function () {
                that.set("customYearCumulativeCounter", true);
            }, 1000);
        },

        onPrepareData: function onPrepareData() {

            var that = this;
            this.set("range", "empty");
            that.startLoadingProgress();
            that._loadCapitalActionPage();
            this.stock = priceService['default'].stockDS.getStock(this.get('exg'), this.get('sym'));

            this.set("topBarData", this.stock);
            //that.onCheckDataAvailability();
        },

        onCheckDataAvailability: function onCheckDataAvailability() {
            var that = this;
            var today = new Date();
            today.setHours(0);
            today.setMinutes(0);
            today.setSeconds(0);

            //12 month
            var date = new Date();
            date.setFullYear(date.getFullYear() - 1);

            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            //3 Years date
            var date3Year = new Date();
            date3Year.setFullYear(date3Year.getFullYear() - 3);

            date3Year.setHours(0);
            date3Year.setMinutes(0);
            date3Year.setSeconds(0);
            //5 years
            var date5Year = new Date();
            date5Year.setFullYear(date5Year.getFullYear() - 5);

            date5Year.setHours(0);
            date5Year.setMinutes(0);
            date5Year.setSeconds(0);

            var ohlcSeries = priceService['default'].ohlcDS.getOHLCSeries(this.get('exg'), this.get('sym'), chartConstants['default'].ChartCategory.History);
            this.globalOHLCSeries = ohlcSeries;
            var corporateActObj = priceService['default'].capitalActionsDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage);
            //filter Cash Dividents
            var divdntList = [];
            corporateActObj.capitalAction.forEach(function (item) {
                if (item.typeId == 7) {
                    divdntList.push(item);
                }
            });
            divdntList.reverse();

            //periodic return
            var anulaDivid = 0;
            var threeYearDivident = 0;
            var fiveYearDivident = 0;

            var markerPoints = [];

            var dividendListForTotalReturn = {};

            divdntList.forEach(function (item) {
                markerPoints.push(ohlcSeries.queryNearestPoint(utils['default'].formatters.convertStringToDate(item.efDate, 0)));

                if (item.acStatus === 'Executed') {
                    dividendListForTotalReturn[utils['default'].formatters.convertStringToDate(item.efDate).getTime()] = parseFloat(item.dividend_amount);
                    // console.log(utils.formatters.convertStringToDate(item.efDate).getTime()),parseFloat(item.dividend_amount);
                    // dividendListForTotalReturn[item.efDate] = item.dividend_amount;
                    var newDate = utils['default'].formatters.convertStringToDate(item.coDate, 0);
                    if (newDate != '' && newDate != undefined) {
                        if (date.getTime() < newDate.getTime()) {
                            anulaDivid = anulaDivid + parseFloat(item.dividend_amount);
                        }

                        if (date3Year.getTime() < newDate.getTime()) {
                            threeYearDivident = threeYearDivident + parseFloat(item.dividend_amount);
                        }

                        if (date5Year.getTime() < newDate.getTime()) {
                            fiveYearDivident = fiveYearDivident + parseFloat(item.dividend_amount);
                        }
                    }
                }
            });
            this.globalDividendListForTotalReturn = dividendListForTotalReturn;

            this.globalMarkerPoints = markerPoints;
            var periodicSeries = Ember['default'].A();
            var anulaData = ohlcSeries.queryPointsForRange(date.getTime(), today.getTime());
            var anulaStartValue = anulaData[0].Close;
            var anualPrice = parseFloat(((anulaData[anulaData.length - 1].Close - anulaStartValue) / anulaStartValue * 100).toFixed(2));
            var anualReinvested = parseFloat((anulaDivid / anulaStartValue * 100).toFixed(2));
            var anulaTotalReturn = parseFloat(((anulaDivid + anulaData[anulaData.length - 1].Close - anulaStartValue) / anulaStartValue * 100).toFixed(2));

            var threeYearData = ohlcSeries.queryPointsForRange(date3Year.getTime(), today.getTime());
            var threeYearStartValue = threeYearData[0].Close;
            var threeYearPrice = parseFloat(((threeYearData[threeYearData.length - 1].Close - threeYearStartValue) / threeYearStartValue * 100).toFixed(2));
            var threeYearReinvested = parseFloat((threeYearDivident / threeYearStartValue * 100).toFixed(2));
            var threeYearTotalReturn = parseFloat(((threeYearDivident + threeYearData[threeYearData.length - 1].Close - threeYearStartValue) / threeYearStartValue * 100).toFixed(2));

            var fiveYearData = ohlcSeries.queryPointsForRange(date5Year.getTime(), today.getTime());
            var fiveYearStartValue = fiveYearData[0].Close;
            var fiveYearPrice = parseFloat(((fiveYearData[fiveYearData.length - 1].Close - fiveYearStartValue) / fiveYearStartValue * 100).toFixed(2));
            var fiveYearReinvested = parseFloat((fiveYearDivident / fiveYearStartValue * 100).toFixed(2));
            var fiveYearTotalReturn = parseFloat(((fiveYearDivident + fiveYearData[fiveYearData.length - 1].Close - fiveYearStartValue) / fiveYearStartValue * 100).toFixed(2));

            var obj1 = { year: '1year', data: [anualPrice, threeYearPrice, fiveYearPrice] };
            periodicSeries.push(obj1);
            var obj3 = { year: '3year', data: [anualReinvested, threeYearReinvested, fiveYearReinvested] };
            periodicSeries.push(obj3);
            var obj5 = { year: '5year', data: [anulaTotalReturn, threeYearTotalReturn, fiveYearTotalReturn] };
            periodicSeries.push(obj5);

            this.set("series", periodicSeries);
            var perData = [];
            var pobj1 = { year: '1 year', stc: ((1 + anulaTotalReturn) * (1 / 1) - 1).toFixed(3) };
            perData.push(pobj1);

            var baseForThreeYear = threeYearTotalReturn / 100 + 1;
            var pobj3 = { year: '3 year', stc: ((Math.pow(baseForThreeYear, 1 / 3) - 1) * 100).toFixed(3) };
            perData.push(pobj3);
            var baseForFiveYear = fiveYearTotalReturn / 100 + 1;
            var pobj5 = { year: '5 year', stc: ((Math.pow(baseForFiveYear, 1 / 5) - 1) * 100).toFixed(3) };
            perData.push(pobj5);
            this.set("perDataTable", perData);

            //CumulativeReturn

            var cumulativeSeriesForOneYear = this.generateTotalReturnSeries(anulaData, dividendListForTotalReturn, that);
            this.set("oneYearCumulativeDates", cumulativeSeriesForOneYear[0]);
            this.set("oneYearCumulativeTotalReturn", cumulativeSeriesForOneYear[1]);
            this.set("oneYearCumulativePriceReturn", cumulativeSeriesForOneYear[2]);

            var cumulativeSeriesForThreeYear = this.generateTotalReturnSeries(threeYearData, dividendListForTotalReturn, that);
            this.set("threeYearCumulativeDates", cumulativeSeriesForThreeYear[0]);
            this.set("threeYearCumulativeTotalReturn", cumulativeSeriesForThreeYear[1]);
            this.set("threeYearCumulativePriceReturn", cumulativeSeriesForThreeYear[2]);

            var cumulativeSeriesForFiveYear = this.generateTotalReturnSeries(fiveYearData, dividendListForTotalReturn, that);
            this.set("fiveYearCumulativeDates", cumulativeSeriesForFiveYear[0]);
            this.set("fiveYearCumulativeTotalReturn", cumulativeSeriesForFiveYear[1]);
            this.set("fiveYearCumulativePriceReturn", cumulativeSeriesForFiveYear[2]);

            //Annual Return
            var yearList = this.generateArrayOfYears(5);
            yearList.reverse();
            var yearAnnualReturnDivident = Ember['default'].A();
            var yaer1AnnualReturnDivident = 0;
            var year2AnnualReturnDivident = 0;
            var yaer3AnnualReturnDivident = 0;
            var yaer4AnnualReturnDivident = 0;
            var yaer5AnnualReturnDivident = 0;
            divdntList.forEach(function (item) {
                if (item.acStatus === 'Executed') {
                    var year = utils['default'].formatters.getYearComponent(item.coDate);
                    if (yearList[0] == year) {
                        yaer1AnnualReturnDivident = yaer1AnnualReturnDivident + parseFloat(item.dividend_amount);
                    }
                    if (yearList[1] == year) {
                        year2AnnualReturnDivident = year2AnnualReturnDivident + parseFloat(item.dividend_amount);
                    }
                    if (yearList[2] == year) {
                        yaer3AnnualReturnDivident = yaer3AnnualReturnDivident + parseFloat(item.dividend_amount);
                    }
                    if (yearList[3] == year) {
                        yaer4AnnualReturnDivident = yaer4AnnualReturnDivident + parseFloat(item.dividend_amount);
                    }
                    if (yearList[4] == year) {
                        yaer5AnnualReturnDivident = yaer5AnnualReturnDivident + parseFloat(item.dividend_amount);
                    }
                }
            });
            yearAnnualReturnDivident.push(yaer1AnnualReturnDivident);
            yearAnnualReturnDivident.push(year2AnnualReturnDivident);
            yearAnnualReturnDivident.push(yaer3AnnualReturnDivident);
            yearAnnualReturnDivident.push(yaer4AnnualReturnDivident);
            yearAnnualReturnDivident.push(yaer5AnnualReturnDivident);

            var annualReturnSeries = Ember['default'].A();

            Ember['default'].$.each(yearList, function (k, item) {

                var dStart = new Date(item, 0, 1);
                var dEnd = new Date(item, 11, 31);
                var anuualOhlcSeries = ohlcSeries.queryPointsForRange(dStart.getTime(), dEnd.getTime());
                if (anuualOhlcSeries) {
                    var stc = parseFloat(((anuualOhlcSeries[anuualOhlcSeries.length - 1].Close - anuualOhlcSeries[0].Close) / anuualOhlcSeries[0].Close * 100).toFixed(3));
                    var returnFromDivident = parseFloat((yearAnnualReturnDivident[k] / anuualOhlcSeries[0].Close * 100).toFixed(3));
                    var totalReturn = parseFloat((stc + returnFromDivident).toFixed(3));
                    annualReturnSeries.push([item, stc, returnFromDivident, totalReturn]);
                }
            });

            var data = [];
            annualReturnSeries.forEach(function (v) {
                var obj = { year: v[0], stc: v[1], 'return': v[2], totRtn: v[3] };
                data.push(obj);
            });
            this.set("annualData", annualReturnSeries);
            this.set("tableData", data);
        },
        _loadCapitalActionPage: function _loadCapitalActionPage() {
            var that = this;
            var searchParams = this.get('searchParams');
            if (!searchParams) {
                var searchPara = {
                    pageSize: this.get('pageSize'),
                    pageIdx: 0,
                    actType: this.get('actTypeId'),
                    custView: this.get('customView'),
                    idList: this.get('typeIds')

                };
                this.set('searchParams', searchPara);
                this.set('current', 1);
            }

            var corporateAction = priceService['default'].capitalActionsDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, priceConstants['default'].DataTypes.CorporateActionDataStore.CapitalActionInfo, that.get('searchParams'), function () {
                that.onDataSuccess();
            }, function () {
                that.onDataError();
            }, this.get('isCachedData')).capitalAction;
        },
        setErrorMessage: function setErrorMessage() {},

        generateArrayOfYears: function generateArrayOfYears(minYear) {
            var max = new Date().getFullYear();
            var min = max - (minYear - 1);
            var years = [];

            for (var i = max; i >= min; i--) {
                years.push(i);
            }
            return years;
        },
        generateTotalReturnSeries: function generateTotalReturnSeries(data, dividendList, that) {
            var j = 0;
            var dividend = 0;
            // var tempData=[];
            var returnSeries = Ember['default'].A();
            var returnSeriesDates = Ember['default'].A();
            var priceReturnSeries = Ember['default'].A();
            Ember['default'].$.each(data, function (k, item) {

                var excluedDividendDate = new Date(parseInt(Object.keys(dividendList)[j]));

                if (excluedDividendDate.getFullYear() < item.DT.getFullYear()) {
                    j += 1;
                }
                // var obj = [item.DT, (anulaDivid+item.Close-anulaStartValue)/anulaStartValue];
                returnSeriesDates.push(utils['default'].formatters.formatDateToDisplayOnlyDate(item.DT));
                if (Object.keys(dividendList).length > 0 && parseInt(Object.keys(dividendList)[j]) <= item.DT.getTime() && parseInt(Object.keys(dividendList)[j]) >= data[0].DT.getTime() && data[data.length - 1].DT.getTime() >= parseInt(Object.keys(dividendList)[j]) && !isNaN(parseInt(Object.keys(dividendList)[j]))) {

                    dividend += dividendList[Object.keys(dividendList)[j]];
                    j += 1;
                }
                // var dataa = {
                //     "Date" : utils.formatters.formatDateToDisplayOnlyDate(item.DT),
                //     "Dividend" : dividend,
                //     "Condition": parseInt(Object.keys(dividendList)[0]) + ">=" + item.DT.getTime()
                //    + " and " + parseInt(Object.keys(dividendList)[0]) + ">=" + data[0].DT.getTime()
                //    + " and " + data[data.length-1].DT.getTime() + ">=" + parseInt(Object.keys(dividendList)[0]) ,
                //     "Close Price" : item.Close,
                //     "Start Value" : data[0].Close,
                //     "Total Return" : parseFloat((((dividend+item.Close-data[0].Close)/data[0].Close)*100).toFixed(3)),
                //     "Price Return" : parseFloat((((item.Close-data[0].Close)/data[0].Close)*100).toFixed(3))
                // }
                // tempData.push(dataa);
                if (that.globalMarkerPoints.includes(item)) {
                    returnSeries.push({ y: parseFloat(((dividend + item.Close - data[0].Close) / data[0].Close * 100).toFixed(3)), marker: { enabled: true, symbol: 'url(assets/images/table/div.png)', width: 30, height: 30 } });
                } else {
                    returnSeries.push(parseFloat(((dividend + item.Close - data[0].Close) / data[0].Close * 100).toFixed(3)));
                }
                priceReturnSeries.push(parseFloat(((item.Close - data[0].Close) / data[0].Close * 100).toFixed(3)));
            });
            // console.log(tempData);
            var totalReturn = (dividend + data[data.length - 1].Close - data[0].close) / data[0].close;
            return [returnSeriesDates, returnSeries, priceReturnSeries, totalReturn];
        },
        setCumulativeData: (function () {
            var chartContent = [{
                name: this.stock.lDes,
                data: this.get('oneYearCumulativePriceReturn'),
                tooltip: {
                    valueSuffix: '%'
                }

            }, {
                name: 'Total Return',
                data: this.get('oneYearCumulativeTotalReturn'),
                tooltip: {
                    valueSuffix: '%'
                }
            }];

            this.set('cumulativeChartData', chartContent);
        }).observes('oneYearCumulativeTotalReturn', 'oneYearCumulativePriceReturn'),

        setCumulativeOptions: (function () {
            if (this.get("oneYearCumulativeDates").length > 0) {
                this.set("optionsCounter", true);
            }
        }).observes("oneYearCumulativeDates"),

        chartCumulativeOptions: (function () {
            return {
                chart: {
                    width: 1100,
                    backgroundColor: "#fff",
                    zoomType: 'xy'
                },
                title: {
                    text: null
                },
                // subtitle: {
                //   text: 'Source: WorldClimate.com'
                // },
                xAxis: [{
                    categories: this.get("oneYearCumulativeDates"),
                    crosshair: true
                }],
                yAxis: { // Primary yAxis
                    labels: {
                        format: '{value} %',
                        style: {
                            color: 'blue'
                        }
                    }
                },
                tooltip: {
                    shared: true
                },
                legend: {
                    layout: 'vertical',
                    align: 'left',
                    x: 120,
                    verticalAlign: 'top',
                    y: 100,
                    floating: true,
                    backgroundColor: 'rgba(255,255,255,0.25)'
                },
                exporting: {
                    enabled: false
                }, plotOptions: {
                    series: {
                        marker: {
                            enabled: false
                        }
                    }
                }

            };
        }).property(),

        setThreeCumulativeData: (function () {
            var chartContent = [{
                name: this.stock.lDes,
                data: this.get('threeYearCumulativePriceReturn'),
                tooltip: {
                    valueSuffix: '%'
                }

            }, {
                name: 'Total Return',
                data: this.get('threeYearCumulativeTotalReturn'),
                tooltip: {
                    valueSuffix: '%'
                }
            }];

            this.set('cumulativeThreeChartData', chartContent);
        }).observes('threeYearCumulativeTotalReturn', 'threeYearCumulativePriceReturn'),

        setThreeCumulativeOptions: (function () {
            if (this.get("threeYearCumulativeDates").length > 0) {
                this.set("optionsThreeCounter", true);
            }
        }).observes("threeYearCumulativeDates"),

        chartThreeCumulativeOptions: (function () {
            return {
                chart: {
                    width: 1100,
                    backgroundColor: "#fff",
                    zoomType: 'xy'
                },
                title: {
                    text: 'Cumulative Return'
                },
                // subtitle: {
                //   text: 'Source: WorldClimate.com'
                // },
                xAxis: [{
                    categories: this.get("threeYearCumulativeDates"),
                    crosshair: true
                }],
                yAxis: { // Primary yAxis
                    labels: {
                        format: '{value} %',
                        style: {
                            color: 'blue'
                        }
                    }
                },
                tooltip: {
                    shared: true
                },
                legend: {
                    layout: 'vertical',
                    align: 'left',
                    x: 120,
                    verticalAlign: 'top',
                    y: 100,
                    floating: true,
                    backgroundColor: 'rgba(255,255,255,0.25)'
                },
                exporting: {
                    enabled: false
                }, plotOptions: {
                    series: {
                        marker: {
                            enabled: false
                        }
                    }
                }

            };
        }).property(),

        setFiveCumulativeData: (function () {
            var chartContent = [{
                name: this.stock.lDes,
                data: this.get('fiveYearCumulativePriceReturn'),
                tooltip: {
                    valueSuffix: '%'
                }

            }, {
                name: 'Total Return',
                data: this.get('fiveYearCumulativeTotalReturn'),
                tooltip: {
                    valueSuffix: '%'
                }
            }];

            this.set('cumulativeFiveChartData', chartContent);
        }).observes('fiveYearCumulativeTotalReturn', 'fiveYearCumulativePriceReturn'),

        setFiveCumulativeOptions: (function () {
            if (this.get("fiveYearCumulativeDates").length > 0) {
                this.set("optionsFiveCounter", true);
            }
        }).observes("fiveYearCumulativeDates"),

        chartFiveCumulativeOptions: (function () {
            return {
                chart: {
                    width: 1100,
                    backgroundColor: "#fff",
                    zoomType: 'xy'
                },
                title: {
                    text: ''
                },
                // subtitle: {
                //   text: 'Source: WorldClimate.com'
                // },
                xAxis: [{
                    categories: this.get("fiveYearCumulativeDates"),
                    crosshair: true
                }],
                yAxis: { // Primary yAxis
                    labels: {
                        format: '{value} %',
                        style: {
                            color: 'blue'
                        }
                    }
                },
                tooltip: {
                    shared: true
                },
                legend: {
                    layout: 'vertical',
                    align: 'left',
                    x: 120,
                    verticalAlign: 'top',
                    y: 100,
                    floating: true,
                    backgroundColor: 'rgba(255,255,255,0.25)'
                },
                exporting: {
                    enabled: false
                }, plotOptions: {
                    series: {
                        marker: {
                            enabled: false
                        }
                    }
                }

            };
        }).property(),

        setCustomCumulativeData: (function () {
            var chartContent = [{
                name: this.stock.lDes,
                data: this.get('customYearCumulativePriceReturn'),
                tooltip: {
                    valueSuffix: '%'
                }

            }, {
                name: 'Total Return',
                data: this.get('customYearCumulativeTotalReturn'),
                tooltip: {
                    valueSuffix: '%'
                }
            }];

            this.set('cumulativeCustomChartData', chartContent);
        }).observes('customYearCumulativeTotalReturn', 'customYearCumulativePriceReturn'),

        chartCustomCumulativeOptions: (function () {
            return {
                chart: {
                    width: 1100,
                    backgroundColor: "#fff",
                    zoomType: 'xy'
                },
                title: {
                    text: ''
                },
                // subtitle: {
                //   text: 'Source: WorldClimate.com'
                // },
                xAxis: [{
                    categories: this.get("customYearCumulativeDates"),
                    crosshair: true
                }],
                yAxis: { // Primary yAxis
                    labels: {
                        format: '{value} %',
                        style: {
                            color: 'blue'
                        }
                    }
                },
                tooltip: {
                    shared: true
                },
                legend: {
                    layout: 'vertical',
                    align: 'left',
                    x: 120,
                    verticalAlign: 'top',
                    y: 100,
                    floating: true,
                    backgroundColor: 'rgba(255,255,255,0.25)'
                },
                exporting: {
                    enabled: false
                }, plotOptions: {
                    series: {
                        marker: {
                            enabled: false
                        }
                    }
                }

            };
        }).property(),

        setCustomCumulativeOptions: (function () {
            if (this.get("customYearCumulativeDates").length > 0) {
                this.set("optionsCustomCounter", true);
            }
        }).observes("customYearCumulativeDates"),

        actions: {
            oneYear: function oneYear() {
                this.set("oneYearCumulativeCounter", true);
                this.set("threeYearCumulativeCounter", false);
                this.set("fiveYearCumulativeCounter", false);
                this.set("customYearCumulativeCounter", false);
            },
            threeYears: function threeYears() {
                this.set("oneYearCumulativeCounter", false);
                this.set("threeYearCumulativeCounter", true);
                this.set("fiveYearCumulativeCounter", false);
                this.set("customYearCumulativeCounter", false);
            },
            fiveYears: function fiveYears() {
                this.set("oneYearCumulativeCounter", false);
                this.set("threeYearCumulativeCounter", false);
                this.set("fiveYearCumulativeCounter", true);
                this.set("customYearCumulativeCounter", false);
            },

            exportTableToExcel: function exportTableToExcel(event) {
                var tableToExport;
                if (event == 1) {
                    tableToExport = document.getElementById('periodicTable');
                } else if (event == 3) {
                    tableToExport = document.getElementById('annual');
                }
                utils['default'].exportData.exportToExcel(tableToExport);
            },

            exportToPng: function exportToPng(event) {
                if (event == 1) {

                    utils['default'].exportData.exportToPng(document.getElementById("peroidicReturn"));
                } else if (event == 2) {
                    utils['default'].exportData.exportToPng(document.getElementById("commulative"));
                } else if (event == 3) {
                    utils['default'].exportData.exportToPng(document.getElementById("annual"));
                }
            },
            exportToPDF: function exportToPDF(event) {
                if (event == 1) {

                    utils['default'].exportData.exportToPdf(document.getElementById("peroidicReturn"));
                } else if (event == 2) {
                    utils['default'].exportData.exportToPdf(document.getElementById("commulative"));
                } else if (event == 3) {
                    utils['default'].exportData.exportToPdf(document.getElementById("annual"));
                }
            }
        },

        cumulativeChartMode: (function () {
            return '';
        }).property()

    });
    Ember['default'].Handlebars.helper('dynamic-high-chart', DynamicHighChart['default']);

});