define('ir-app/controllers/ir-widgets/shareholder-analysis/shareholder-segmentation', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/shared/shared-service', 'ir-app/models/price/price-constants', 'ir-app/models/price/price-service', 'ir-app/utils/utils'], function (exports, Ember, BaseController, sharedService, PriceConstant, priceService, Utils) {

    'use strict';

    exports['default'] = BaseController['default'].extend({

        segDataList: Ember['default'].A(),

        onPrepareData: function onPrepareData() {

            var that = this;
            this.set('arrSymbolPiValues', Ember['default'].A());
            this.set('arrSymbolsPi', Ember['default'].A());
            this.set('arrTypePiValues', Ember['default'].A());
            this.set('arrTypesPi', Ember['default'].A());

            var onSysDefSucess = function onSysDefSucess() {
                that.set('sysDef', priceService['default'].systemDS.getStoredObject(sharedService['default'].userSettings.currentLanguage));
            };
            priceService['default'].downloadSystemDefinitions(sharedService['default'].userSettings.currentLanguage, onSysDefSucess);

            var onSegmentaDataSucess = function onSegmentaDataSucess(obj) {
                that.calculatePct(obj.dataObj);
                that.drawPiChartByCountry();
                that.drawPiChartByType();
            };

            var success = function success() {
                that.set('compObj', priceService['default'].companyProfileDS.getStoredObject(that.get('exg'), that.get('sym'), sharedService['default'].userSettings.currentLanguage, onSegmentaDataSucess, undefined, PriceConstant['default'].DataTypes.CompanyProfileDataStore.CompanyShareholderSegmentation));
            };

            priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, success, undefined, PriceConstant['default'].DataTypes.CompanyProfileDataStore.CompanyBasicInfo);
        },

        getCountryDescription: function getCountryDescription(code) {

            var that = this;

            var result = that.get('sysDef').countryDefinitions.filter(function (obj) {
                return obj.countryCode === code;
            });
            if (result.length > 0) {
                return result[0].countryDes;
            } else {
                return code;
            }
        },

        drawPiChartByCountry: function drawPiChartByCountry() {

            var that = this;

            var sorted = [];
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = that.get('pctMapByCountry')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var x = _step.value;

                    sorted.push(x);
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator['return']) {
                        _iterator['return']();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }

            sorted.sort(function (x, y) {
                return y[1] - x[1];
            });

            that.get('arrSymbolPiValues').push(that.get('app').lang.labels.ownersByCountry);
            that.get('arrSymbolsPi').push('x');

            var _iteratorNormalCompletion2 = true;
            var _didIteratorError2 = false;
            var _iteratorError2 = undefined;

            try {
                for (var _iterator2 = sorted[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                    var y = _step2.value;

                    that.get('arrSymbolPiValues').push(Utils['default'].formatters.formatNumber(y[1], 3));
                    that.get('arrSymbolsPi').push(this.getCountryDescription(y[0]));
                }
            } catch (err) {
                _didIteratorError2 = true;
                _iteratorError2 = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion2 && _iterator2['return']) {
                        _iterator2['return']();
                    }
                } finally {
                    if (_didIteratorError2) {
                        throw _iteratorError2;
                    }
                }
            }

            that.set('xDataSet', that.get('arrSymbolsPi'));
            that.set('dataSet', that.get('arrSymbolPiValues'));
        },

        drawPiChartByType: function drawPiChartByType() {

            var that = this;

            var sorted = [];
            var _iteratorNormalCompletion3 = true;
            var _didIteratorError3 = false;
            var _iteratorError3 = undefined;

            try {
                for (var _iterator3 = that.get('pctMapByType')[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                    var x = _step3.value;

                    sorted.push(x);
                }
            } catch (err) {
                _didIteratorError3 = true;
                _iteratorError3 = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion3 && _iterator3['return']) {
                        _iterator3['return']();
                    }
                } finally {
                    if (_didIteratorError3) {
                        throw _iteratorError3;
                    }
                }
            }

            sorted.sort(function (x, y) {
                return y[1] - x[1];
            });

            that.get('arrTypePiValues').push(that.get('app').lang.labels.ownersByType);
            that.get('arrTypesPi').push('x');

            var _iteratorNormalCompletion4 = true;
            var _didIteratorError4 = false;
            var _iteratorError4 = undefined;

            try {
                for (var _iterator4 = sorted[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
                    var y = _step4.value;

                    that.get('arrTypePiValues').push(Utils['default'].formatters.formatNumber(y[1], 3));
                    that.get('arrTypesPi').push(y[0]);
                }
            } catch (err) {
                _didIteratorError4 = true;
                _iteratorError4 = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion4 && _iterator4['return']) {
                        _iterator4['return']();
                    }
                } finally {
                    if (_didIteratorError4) {
                        throw _iteratorError4;
                    }
                }
            }

            that.set('xDataSet2', that.get('arrTypesPi'));
            that.set('dataSet2', that.get('arrTypePiValues'));
        },

        getFundPct: function getFundPct(fundInvestRatio, currencyConvRate, tnavOnDate, marketCapStock) {

            var ownershipPercent = -1;

            if (!isNaN(parseFloat(fundInvestRatio)) && !isNaN(parseFloat(tnavOnDate)) && !isNaN(parseFloat(currencyConvRate)) && !isNaN(parseFloat(marketCapStock)) && parseFloat(marketCapStock) !== 0) {
                ownershipPercent = parseFloat(fundInvestRatio) * parseFloat(tnavOnDate) * parseFloat(currencyConvRate) * 100 / parseFloat(marketCapStock);
            }
            return ownershipPercent;
        },

        calculatePct: function calculatePct(obj) {

            var that = this;

            var funds = obj.compFunds;
            var individuals = obj.compIndividuals;
            var owners = obj.compShareHolderOwners;
            var pctMapByCountry = new Map();
            var pctMapByType = new Map();

            var fundPct = 0;
            var indPct = 0;
            var ownerPctCorp = 0;
            var ownerPctNonCorp = 0;

            for (var i = 0; i < funds.length; i++) {
                var tempPct = 0;
                var ownershipPercent = this.getFundPct(funds[i].fundInvestRatio, funds[i].currencyConvRate, funds[i].tnavOnDate, funds[i].marketCapStock);

                if (ownershipPercent !== -1) {
                    fundPct += ownershipPercent;
                }
                if (pctMapByCountry.size > 0) {
                    if (pctMapByCountry.has(funds[i].fundCountry)) {
                        if (ownershipPercent !== -1) {
                            tempPct = parseFloat(pctMapByCountry.get(funds[i].fundCountry)) + ownershipPercent;
                            pctMapByCountry.set(funds[i].fundCountry, tempPct);
                        }
                    } else {
                        if (ownershipPercent !== -1) {
                            pctMapByCountry.set(funds[i].fundCountry, ownershipPercent);
                        }
                    }
                } else {
                    if (ownershipPercent !== -1) {
                        pctMapByCountry.set(funds[i].fundCountry, ownershipPercent);
                    }
                }
            }
            pctMapByType.set(that.get('app').lang.labels.mutualFunds, fundPct);

            for (var j = 0; j < individuals.length; j++) {
                var tempOwnerPct = 0;
                if (individuals[j].ownerPct !== '') {

                    indPct += parseFloat(individuals[j].ownerPct);
                }
                if (pctMapByCountry.size > 0) {
                    if (pctMapByCountry.has(individuals[j].residency)) {
                        if (individuals[j].ownerPct !== '') {

                            tempOwnerPct = parseFloat(pctMapByCountry.get(individuals[j].residency)) + parseFloat(individuals[j].ownerPct * 100);
                            pctMapByCountry.set(individuals[j].residency, tempOwnerPct);
                        }
                    } else {
                        if (individuals[j].ownerPct !== '') {
                            pctMapByCountry.set(individuals[j].residency, individuals[j].ownerPct * 100);
                        }
                    }
                } else {
                    if (individuals[j].ownerPct !== '') {
                        pctMapByCountry.set(individuals[j].residency, individuals[j].ownerPct * 100);
                    }
                }
            }
            pctMapByType.set(that.get('app').lang.labels.individuals, indPct * 100);

            for (var k = 0; k < owners.length; k++) {
                var tempOwnerCompPct = 0;
                if (owners[k].OwnerCompPct !== '') {

                    if (owners[k].OwnerCompType === '1') {
                        ownerPctCorp += parseFloat(owners[k].OwnerCompPct);
                    } else {
                        ownerPctNonCorp += parseFloat(owners[k].OwnerCompPct);
                    }
                }
                if (pctMapByCountry.size > 0) {
                    if (pctMapByCountry.has(owners[k].countryCode)) {
                        if (owners[k].OwnerCompPct !== '') {

                            tempOwnerCompPct = parseFloat(pctMapByCountry.get(owners[k].countryCode)) + parseFloat(owners[k].OwnerCompPct * 100);
                            pctMapByCountry.set(owners[k].countryCode, tempOwnerCompPct);
                        }
                    } else {
                        if (owners[k].OwnerCompPct !== '') {
                            pctMapByCountry.set(owners[k].countryCode, owners[k].OwnerCompPct * 100);
                        }
                    }
                } else {
                    if (owners[k].OwnerCompPct !== '') {
                        pctMapByCountry.set(owners[k].countryCode, owners[k].OwnerCompPct * 100);
                    }
                }
            }
            pctMapByType.set(that.get('app').lang.labels.corporate, ownerPctCorp * 100);
            pctMapByType.set(that.get('app').lang.labels.nonCorporate, ownerPctNonCorp * 100);

            var allCountries = 0;
            var _iteratorNormalCompletion5 = true;
            var _didIteratorError5 = false;
            var _iteratorError5 = undefined;

            try {
                for (var _iterator5 = pctMapByCountry.values()[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
                    var value = _step5.value;

                    allCountries += parseFloat(value);
                }
            } catch (err) {
                _didIteratorError5 = true;
                _iteratorError5 = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion5 && _iterator5['return']) {
                        _iterator5['return']();
                    }
                } finally {
                    if (_didIteratorError5) {
                        throw _iteratorError5;
                    }
                }
            }

            pctMapByCountry.set(that.get('app').lang.labels.others, 100 - allCountries);

            var allTypes = 0;
            var _iteratorNormalCompletion6 = true;
            var _didIteratorError6 = false;
            var _iteratorError6 = undefined;

            try {
                for (var _iterator6 = pctMapByType.values()[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
                    var valueType = _step6.value;

                    allTypes += parseFloat(valueType);
                }
            } catch (err) {
                _didIteratorError6 = true;
                _iteratorError6 = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion6 && _iterator6['return']) {
                        _iterator6['return']();
                    }
                } finally {
                    if (_didIteratorError6) {
                        throw _iteratorError6;
                    }
                }
            }

            pctMapByType.set(that.get('app').lang.labels.others, 100 - allTypes);

            this.set('pctMapByCountry', pctMapByCountry);
            this.set('pctMapByType', pctMapByType);
        }
    });

});