define('ir-app/models/price/price-business-entities/fundamentals/estimate-provider-object', ['exports', 'ember', 'ir-app/models/price/price-business-entities/fundamentals/estimate-forecast-object'], function (exports, Ember, forecastObj) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        providerID: undefined,
        providerForecasts: undefined,

        init: function init() {
            this.providerForecasts = {};
        },

        setYearlyEst: function setYearlyEst(PROVIDER_NAME, PROVIDED_DATE, value) {
            if (PROVIDER_NAME === undefined || value === undefined) {
                return;
            }
            var providerForecasts = this.get('providerForecasts');

            var object = forecastObj['default'].create();
            object.providerName = PROVIDER_NAME;
            object.providedDate = PROVIDED_DATE;
            object.value = value;

            providerForecasts[PROVIDED_DATE] = object;
        }
    });

});