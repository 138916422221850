define('ir-app/config/app-config', ['exports'], function (exports) {

    'use strict';

    exports['default'] = {
        customisation: {
            clientPrefix: 'default',
            authenticationMode: 6,
            supportedLanguages: [{ code: 'EN', desc: 'English' }, { code: 'AR', desc: 'العربية' }]
        },

        localStorage: {
            maxLocalStorageSize: 3 // in MBs
        },

        loggerConfig: {
            serverLogLevel: 0, // Server log level is info
            consoleLogLevel: 1, // Console log level is info
            logBufferSize: 50, // This number of latest logs will be maintained. Will be used only in sendLogToServer mode
            logUpdateTimeout: 300000, // Logs will be uploaded to server every 5min
            maxLogBufferSize: 10000 // Log buffer will be cleared when it reach maxLogBufferSize
        },

        searchConfig: {
            minCharLenForSymbol: 2, // Minimum input characters for performing symbol search
            minCharLenForContent: 3 // Minimum input characters for performing news & announcement search
        },

        googleAnalyticConfig: {
            id: 'UA-70494667-1'
        },

        googleAPIConfig: {
            key: 'AIzaSyBvSrwJhexd0h_8u52CqlHZVocWHcKSy-c',
            youtubeApiUrl: 'https://www.googleapis.com/youtube/v3/'
        },

        // TODO: [Amila] Process /mobile/tdwl/1010 router vs /ir/en/currencies router issue. Check with rajith
        applicationMode: {
            ir_page: {
                identifier: 'ir',
                params: ['mode', 'customisation', 'lan', 'page', 'exg', 'sym']
            },
            ir_mobile: {
                identifier: 'mobile',
                params: ['mode', 'customisation', 'exg', 'sym']
            }
        },

        // all the times are in milliseconds. overrideExpTime field is optional.
        cachePolicy: {
            companyProfile: {
                cacheKeyPrefix: 'companyProfileCachePolicy_v2',
                expTime: 3600000 // = 1000 * 60 * 60 * 24
                /** overrideExpTime: [
                    {
                        expTime: 3600000,
                        clients: [
                            'tdwl_4230',
                            'tdwl_1010'
                        ]
                    },
                    {
                        expTime: 500,
                        clients: [
                            'tdwl_1020'
                        ]
                    }
                ]*/
            },
            companyMgmnt: {
                cacheKeyPrefix: 'companyManagementCachePolicy_v2',
                expTime: 3600000 // = 1000 * 60 * 60
            },
            ownershipLimits: {
                cacheKeyPrefix: 'ownershipLimitsCachePolicy_v2',
                expTime: 3600000 // = 1000 * 60 * 60
            },
            segmentation: {
                cacheKeyPrefix: 'segmentationdata_v2',
                expTime: 0 // not keeping cache.
            },
            insiderTrades: {
                cacheKeyPrefix: 'insiderTradesCachePolicy_v2',
                expTime: 3600000 // = 1000 * 60 * 60
            },
            exchangeInfo: {
                cacheKeyPrefix: 'exchangeInfoCachePolicy_v2',
                expTime: 3600000 // = 1000 * 60 * 60
            },
            systemDefinitions: {
                cacheKeyPrefix: 'systemDefinitionsCachePolicy_v2',
                expTime: 3600000 // = 1000 * 60 * 60
            },
            exchangeArrayInfo: {
                cacheKeyPrefix: 'exchangeArrayInfoCachePolicy_v2',
                expTime: 3600000 // = 1000 * 60 * 60
            },
            symbolInfo: {
                cacheKeyPrefix: 'symbolInfoCachePolicy_v2',
                expTime: 3600000 // = 1000 * 60 * 60
            },
            ohlcHistory: {
                cacheKeyPrefix: 'ohlcHistoryCachePolicy_v2',
                expTime: 3600000 // = 1000 * 60 * 60
            },
            fundamentalDataQ: {
                cacheKeyPrefix: 'fundamentalDataQCachePolicy_v2',
                expTime: 3600000 // = 1000 * 60 * 60
            },
            clientFundamentalDataQ: {
                cacheKeyPrefix: 'clientFundamentalDataCachePolicy_v2',
                expTime: 0 // client updated financial data must be reflected in front end with zero latency.
            },
            fundamentalDataY: {
                cacheKeyPrefix: 'fundamentalDataYCachePolicy_v2',
                expTime: 3600000 // = 1000 * 60 * 60
            },
            financialStatement: {
                cacheKeyPrefix: 'financialStatementCachePolicy_v2',
                expTime: 3600000 // = 1000 * 60 * 60
            },
            pressRelease: {
                cacheKeyPrefix: 'pressReleaseCachePolicy_v2',
                expTime: 3600000 // = 1000 * 60 * 60
            },
            boardMeetingDisclosure: {
                cacheKeyPrefix: 'boardMeetingDisclosureCachePolicy_v2',
                expTime: 3600000 // = 1000 * 60 * 60
            },
            assemblyMeetingDisclosure: {
                cacheKeyPrefix: 'assemblyMeetingDisclosureCachePolicy_v2',
                expTime: 3600000 // = 1000 * 60 * 60
            },
            otherDisclousers: {
                cacheKeyPrefix: 'otherDisclosureCachePolicy_v2',
                expTime: 3600000 // = 1000 * 60 * 60
            },
            glossyReports: {
                cacheKeyPrefix: 'glossyReportsCachePolicy_v2',
                expTime: 3600000 // = 1000 * 60 * 60
            },
            baselIII: {
                cacheKeyPrefix: 'baselIIICachePolicy_v2',
                expTime: 3600000 // = 1000 * 60 * 60
            },
            currencyRate: {
                cacheKeyPrefix: 'currencyRateCachePolicy_v2',
                expTime: 3600000 // = 1000 * 60 * 60
            },
            symbolMetaInfo: {
                cacheKeyPrefix: 'symbolMetaCachePolicy_v2',
                expTime: 3600000 // = 1000 * 60 * 60
            },
            exchangeMetaInfo: {
                cacheKeyPrefix: 'exchangeMetaCachePolicy_v2',
                expTime: 3600000 // = 1000 * 60 * 60
            },
            corporateMetaData: {
                cacheKeyPrefix: 'corporateMetaDataCachePolicy_v2',
                expTime: 3600000 // = 1000 * 60 * 60
            },
            nonCapitalAction: {
                cacheKeyPrefix: 'nonCapActionCachePolicy_v2',
                expTime: 3600000 // = 1000 * 60 * 60
            },
            sendCapitalOrNonCapitalRequest: {
                cacheKeyPrefix: 'CapitalOrNonCapitalCachePolicy_v2',
                expTime: 3600000 // = 1000 * 60 * 60
            },
            capitalActionDetails: {
                cacheKeyPrefix: 'capitalActionDetailsCachePolicy_v2',
                expTime: 3600000 // = 1000 * 60 * 60
            },
            foreignOwnershipLimit: {
                cacheKeyPrefix: 'foreignOwnershipLimitCachePolicy_v2',
                expTime: 300000 // = 1000 * 60 * 5
            },
            ipoIndOrCompList: {
                cacheKeyPrefix: 'ipoIndOrCompListCachePolicy_v2',
                expTime: 3600000 // = 1000 * 60 * 60
            },
            fairValues: {
                cacheKeyPrefix: 'fairValuesCachePolicy_v2',
                expTime: 3600000 // = 1000 * 60 * 60
            },
            majorShareholder: {
                cacheKeyPrefix: 'majorShareholderCachePolicy_v2',
                expTime: 3600000 // = 1000 * 60 * 60
            },
            contactPerson: {
                cacheKeyPrefix: 'contactPersonCachePolicy_v2',
                expTime: 3600000 // = 1000 * 60 * 60
            },
            creditRatings: {
                cacheKeyPrefix: 'creditRatingsCachePolicy_v2',
                expTime: 3600000 // = 1000 * 60 * 60
            },
            analystContacts: {
                cacheKeyPrefix: 'analystContactsCachePolicy_v2',
                expTime: 3600000 // = 1000 * 60 * 60
            },
            shareholderSegmentation: {
                cacheKeyPrefix: 'shareholderSegmentationCachePolicy_v2',
                expTime: 3600000 // = 1000 * 60 * 60
            }
        },

        exchangeList: {
            alArabia: 'TDWL,DFM,ADSM,KSE,DSM,MSM,BSE,CASE'
        }
    };

});