define('ir-app/controllers/ir-widgets/alert-widgets/alert-widget', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/utils/utils'], function (exports, Ember, BaseController, priceService, utils) {

    'use strict';

    /**
     * Created by isurup on 1/7/2019.
     */
    exports['default'] = BaseController['default'].extend({
        isDailyClosingPrice: false,
        isPriceTarget: false,
        isStopLoss: false,
        isVariation: false,
        onPrepareData: function onPrepareData() {
            var alertId = '';
            var url_string = window.location.href;
            var url = new URL(url_string);
            alertId = url.searchParams.get("ID");
            if (alertId !== '' && alertId !== null) {
                this.getAlertDetails(alertId);
            }
        },

        onClearData: function onClearData() {},

        onLanguageChanged: function onLanguageChanged() {},

        getAlertDetails: function getAlertDetails(alertId) {
            var that = this;
            var action = 'G';

            var onSuccess = function onSuccess(data) {
                var status = data[0].status;
                if (status === 'ACTIVE') {
                    var criteria = data[0].criteria.split(',');
                    that.setValuesToForm(data[0].fName, data[0].lName, data[0].email);
                    for (var i = 0; i < criteria.length; i++) {
                        var fieldValues = criteria[i].split('~');
                        if (fieldValues[0] === 'CLS' && fieldValues[1] === '13') {
                            that.set('isDailyClosingPrice', true);
                        } else if (fieldValues[0] === 'PRC' && fieldValues[1] === '13') {
                            that.set('isPriceTarget', true);
                            that.set('priceTarget', fieldValues[2]);
                        } else if (fieldValues[0] === 'PRC' && fieldValues[1] === '23') {
                            that.set('isStopLoss', true);
                            that.set('stopLoss', fieldValues[2]);
                        } else if (fieldValues[0] === 'PCT' && fieldValues[1] === '13') {
                            that.set('isVariation', true);
                            that.set('variation', fieldValues[2]);
                        }
                    }
                } else if (status === 'DELETED') {
                    that.set('message', 'This Alert has already been deleted.');
                }
            };

            var onError = function onError(error) {
                console.log(error);
            };

            priceService['default'].alertUserDS.getStoredObject(alertId, action, onSuccess, onError);
        },
        setValuesToForm: function setValuesToForm(fname, lname, email) {
            this.set('lName', lname);
            this.set('fName', fname);
            this.set('email', email);
        },

        actions: {

            updateVariation: function updateVariation(val) {
                val = parseInt(val);
                if (val >= 0) {
                    this.set('quantity', val);
                } else {
                    window.alert('number must not be negative');
                    this.notifyPropertyChange('quantity');
                }
            },

            formSubmit: function formSubmit() {
                var that = this;
                var exchange = this.get('exg');
                var symbol = this.get('sym');
                var lastName = this.get('lName');
                var firstName = this.get('fName');
                var email = this.get('email');
                var priceTargetCriteria = undefined;
                var stopLossCriteria = undefined;
                var variationCriteria = undefined;
                var dailyClosingPriceCriteria = undefined;
                var criteria = undefined;
                var priceTarget = undefined,
                    stopLoss = undefined,
                    variation = undefined;
                if (!lastName || !firstName || !email) {
                    this.set('message', 'please fill all fields!');
                } else {
                    if (this._validateEmail(email)) {
                        this.set('message', '');
                        if (this.isDailyClosingPrice) {
                            dailyClosingPriceCriteria = ['CLS', 13, 0, 'D'].join(utils['default'].Constants.StringConst.Tilde);
                            criteria = dailyClosingPriceCriteria;
                        }
                        if (this.isPriceTarget) {
                            priceTarget = this.get('priceTarget');
                            priceTargetCriteria = ['PRC', 13, priceTarget, 'D'].join(utils['default'].Constants.StringConst.Tilde);
                            if (criteria) {
                                criteria = [criteria, priceTargetCriteria].join(utils['default'].Constants.StringConst.Comma);
                            } else {
                                criteria = priceTargetCriteria;
                            }
                        }
                        if (this.isStopLoss) {
                            stopLoss = this.get('stopLoss');
                            stopLossCriteria = ['PRC', 23, stopLoss, 'D'].join(utils['default'].Constants.StringConst.Tilde);
                            if (criteria) {
                                criteria = [criteria, stopLossCriteria].join(utils['default'].Constants.StringConst.Comma);
                            } else {
                                criteria = stopLossCriteria;
                            }
                        }
                        if (this.isVariation) {
                            variation = this.get('variation');
                            variationCriteria = ['PCT', 13, variation, 'D'].join(utils['default'].Constants.StringConst.Tilde);
                            if (criteria) {
                                criteria = [criteria, variationCriteria].join(utils['default'].Constants.StringConst.Comma);
                            } else {
                                criteria = variationCriteria;
                            }
                        }
                        var action = 'S';
                        var onSuccess = function onSuccess(result) {
                            that.set('message', 'Alert request sent successfully. Please check your email for verification');
                            console.log(result);
                        };

                        var onError = function onError(error) {
                            that.set('message', 'Error in Alert Request. Please try again.!');
                            console.log(error);
                        };
                        if (!priceTarget && !stopLoss && !variation && !this.isDailyClosingPrice) {
                            that.set('message', 'Please add one or more alert criteria');
                        } else {
                            that.set('message', '');
                            priceService['default'].sendAlertManagerData(exchange, symbol, action, criteria, firstName, lastName, email, onSuccess, onError);
                        }
                    }
                }
            },

            clearForm: function clearForm() {
                this.set('isStopLoss', false);
                this.set('priceTarget', '');
                this.set('isDailyClosingPrice', false);
                this.set('isPriceTarget', false);
                this.set('priceTarget', '');
                this.set('isVariation', false);
                this.set('variation', '');
                this.set('ErrorFromEmail', '');
                this.set('message', '');
                Ember['default'].$('#alertForm')[0].reset();
            }

        },
        _validateEmail: function _validateEmail(mailAdr) {
            var validMail = mailAdr ? mailAdr.trim() : '';
            var eMail = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            if (eMail.test(validMail)) {
                this.set('ErrorFromEmail', '');
                return true;
            } else {
                this.set('ErrorFromEmail', this.get('app').lang.messages.invalidEmail);
                return false;
            }
        }
    });

});