define('ir-app/controllers/ir-mobile/home-widgets/bottom-container', ['exports', 'ir-app/controllers/base-controller', 'ir-app/models/shared/shared-service'], function (exports, BaseController, sharedService) {

    'use strict';

    exports['default'] = BaseController['default'].extend({
        currentActiveView: 0,
        focusGain: false,
        btn1Selected: 'active',
        btn2Selected: '',
        btn3Selected: '',
        rotationFunction: null,
        isLoadAnnInPopup: true,
        widgetArray: ['ir-mobile/chart-widgets/basic-chart-widget', 'ir-mobile/news-announcement-widget/news-announcement', 'ir-mobile/event-calendar-widget/event-calendar'],
        widgetNavList: [],

        onPrepareData: function onPrepareData() {
            if (!sharedService['default'].userState.lastHomeTab) {
                sharedService['default'].userState.lastHomeTab = 0;
            }

            this.set('currentActiveView', sharedService['default'].userState.lastHomeTab);
            this._setActiveTab(this.currentActiveView);
            this._renderSubView(this.currentActiveView);
            if (this.get('widgetNavList')) {
                this.widgetNavList = this.get('widgetNavList');
                this.widgetNavList.length;
                for (var i = 0; i < this.widgetNavList.length; i++) {
                    this.widgetNavList[i].title = this.get('app').lang.labels[this.widgetNavList[i].name];
                }
            }
        },

        _setActiveTab: function _setActiveTab(type) {
            switch (type) {
                case 0:
                    this.set('btn1Selected', 'active');
                    this.set('btn2Selected', '');
                    this.set('btn3Selected', '');
                    break;

                case 1:
                    this.set('btn1Selected', '');
                    this.set('btn2Selected', 'active');
                    this.set('btn3Selected', '');
                    break;

                case 2:
                    this.set('btn1Selected', '');
                    this.set('btn2Selected', '');
                    this.set('btn3Selected', 'active');
                    break;

                default:
                    this.set('btn1Selected', 'active');
                    this.set('btn2Selected', '');
                    this.set('btn3Selected', '');
                    break;
            }
        },

        _renderSubView: function _renderSubView(type) {
            var detailViewController;
            var that = this;
            switch (type) {
                case 0:
                    detailViewController = that.get('widgetArray')[0];
                    break;

                case 1:
                    detailViewController = that.get('widgetArray')[1];
                    break;

                case 2:
                    detailViewController = that.get('widgetArray')[2];
                    break;

                default:
                    detailViewController = that.get('widgetArray')[0];
                    break;
            }

            var controllerString = 'controller:' + detailViewController;
            var widgetController = this.container.lookupFactory(controllerString).create();
            widgetController.set('minifiedVersion', true);
            widgetController.set('isLoadAnnInPopup', this.get('isLoadAnnInPopup'));

            // widgetController.set('sortAscending', false);
            // widgetController.set('selectedTab', 2);

            // Setting symbol and exchange to be passed to the newly created controller
            widgetController.set('sym', this.get('sym'));
            widgetController.set('exg', this.get('exg'));

            // widgetController.onLoadWidget();
            // widgetController.onPrepareData();
            // widgetController.onAfterRender();
            widgetController.initializeWidget({ wn: controllerString.split('/').pop() });

            var route = this.container.lookup('route:application');
            route.render(detailViewController, {
                into: 'ir-mobile/home-widgets/bottom-container',
                outlet: 'detailView',
                controller: widgetController
            });

            widgetController.onUnloadWidget();

            this.set('currentActiveView', type);

            if (this.get('currentActiveView') === 0) {
                // only register for chart component
                this.registerForOrientationChange();
            } else {
                this.removeFormOrientationChange();
            }
        },

        renderMenuWidget: function renderMenuWidget(menuId) {

            var route = this.container.lookup('route:application');
            var menuContent = route.get('appLayout').layout.mainContent[menuId - 1];
            route.controllerFor('title-bar').onChangeMenu(menuContent);

            route.set('menuContent', menuContent);

            route.widgetContainer = route.controllerFor(menuContent.layoutTemplate.wn);

            route.render(menuContent.layoutTemplate.wn, {
                into: 'application',
                outlet: 'mainContainerOutlet'
            });

            if (route.widgetContainer && route.widgetContainer.closeContainer) {
                route.widgetContainer.closeContainer();
            }

            // set container level configuration args
            var widgetArgs = route.get('appLayout').args;
            if (widgetArgs) {
                var containerArgs = widgetArgs[menuContent.id] ? widgetArgs[menuContent.id].args : undefined;

                if (containerArgs) {
                    route.widgetContainer.setArgs(containerArgs);
                }
            }

            route.widgetContainer.menuView(route, menuContent, route.get('appLayout').args, route.get('storedArgs'), true);
        },

        onLoadWidget: function onLoadWidget() {
            var that = this;
            that.set('focusGain', true);
            that.set('rotationFunction', function () {
                that.onDeviceOrientationChange(window.orientation);
            });
        },

        onUnloadWidget: function onUnloadWidget() {
            var that = this;
            that.set('focusGain', false);
        },

        onDeviceLandscapeEnable: function onDeviceLandscapeEnable() {
            this.hideAllDivTags();
            // this.handleResize();
        },

        onDevicePortraitEnable: function onDevicePortraitEnable() {
            this.showAllDivTags();
            // this.handleResize();
        },

        hideAllDivTags: function hideAllDivTags() {
            if (document.getElementById('transbox') !== null) {
                document.getElementById('transbox').style.display = 'none';
            }
            if (document.getElementById('margin-small-top') !== null) {
                document.getElementById('margin-small-top').style.display = 'none';
            }
            if (document.getElementById('h-sep-line margin-small-bottom') !== null) {
                document.getElementById('h-sep-line margin-small-bottom').style.display = 'none';
            }
            if (document.getElementById('row margin-small-bottom') !== null) {
                document.getElementById('row margin-small-bottom').style.display = 'none';
            }
        },

        showAllDivTags: function showAllDivTags() {
            if (document.getElementById('transbox') !== null) {
                document.getElementById('transbox').style.display = 'block';
            }
            if (document.getElementById('margin-small-top') !== null) {
                document.getElementById('margin-small-top').style.display = 'block';
            }
            if (document.getElementById('h-sep-line margin-small-bottom') !== null) {
                document.getElementById('h-sep-line margin-small-bottom').style.display = 'block';
            }
            if (document.getElementById('row margin-small-bottom') !== null) {
                document.getElementById('row margin-small-bottom').style.display = 'block';
            }
        },

        registerForOrientationChange: function registerForOrientationChange() {
            var that = this;
            window.addEventListener('orientationchange', that.get('rotationFunction'));
        },

        removeFormOrientationChange: function removeFormOrientationChange() {
            var that = this;
            window.removeEventListener('orientationchange', that.get('rotationFunction'));
        },

        onDeviceOrientationChange: function onDeviceOrientationChange(orientation) {
            // This is not the best implementation for that..Need to recheck again
            if (this.get('focusGain') === true) {
                if (orientation === 90 || orientation === -90) {
                    this.onDeviceLandscapeEnable();
                } else {
                    this.onDevicePortraitEnable();
                }
            }
        },

        actions: {
            changeDetailView: function changeDetailView(type) {
                if (this.currentActiveView !== type) {
                    sharedService['default'].userState.lastHomeTab = type;
                    this._setActiveTab(type);
                    this._renderSubView(type);
                }
            },
            navigateWidget: function navigateWidget(id) {
                this.renderMenuWidget(id);
            }
        }
    });

});