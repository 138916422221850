define('ir-app/models/price/price-business-entities/event-calendar-record', ['exports', 'ember', 'ir-app/utils/utils', 'ir-app/models/price/price-service'], function (exports, Ember, utils, PriceService) {

    'use strict';

    /**
     * Created by User on 2/16/2016.
     */

    exports['default'] = Ember['default'].Object.extend({
        exg: '',
        sym: '',
        comID: '',
        lang: '',
        ctryCode: '',
        eventTitleID: '',
        eventStartDate: '',
        eventEndDate: '',
        tradingDuringEvent: '',
        eventTitle: '',
        eventId: '',
        categoryId: '',
        localtime: '',
        category: '',
        location: '',
        annID: '',
        tzo: '',

        dDt: (function () {
            var dateTime = this.get('eventStartDate');
            return utils['default'].formatters.formatToDateTime(dateTime, this.get('tzo'));
        }).property('eventStartDate'),

        dSym: (function () {
            var symCode = this.get('sym');
            var exgCode = this.get('exg');

            // Symbol code is returned for all the cases where sufficient parameters and sufficient data is not available.
            // This is done to handle the asynchronous behaviour of the usages.
            if (utils['default'].validators.isAvailable(symCode) && utils['default'].validators.isAvailable(exgCode)) {
                var symbObj = PriceService['default'].stockDS.getStock(exgCode, symCode);

                if (utils['default'].validators.isAvailable(symbObj.sDes)) {
                    return symbObj.sDes;
                } else {
                    return symCode;
                }
            } else {
                return symCode;
            }
        }).property('sym', 'exg'), // Display symbol

        setData: function setData(eventCalendarData) {
            var that = this;

            Ember['default'].$.each(eventCalendarData, function (key, value) {
                that.set(key, value);
            });
        }
    });

});