define('ir-app/models/price/price-data-stores/top-stocks-data-store', ['exports', 'ember', 'ir-app/models/price/price-business-entities/topstock'], function (exports, Ember, topStock) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        topStocksMapByExgs: {},

        createTopStocks: function createTopStocks(exchange, type, topStocks) {
            var topStocksMapByExg = this.get('topStocksMapByExgs');
            var topStocksForExg = topStocksMapByExg[exchange] ? topStocksMapByExg[exchange] : {};
            var topStockArr = topStocksForExg[type] ? topStocksForExg[type] : Ember['default'].A([]);

            if (topStocks && topStocks.length > 0) {
                Ember['default'].$.each(topStocks, function (index, valueObj) {
                    var tempObj = topStockArr.objectAt(index);

                    if (tempObj === undefined) {
                        tempObj = topStock['default'].create();
                        topStockArr.pushObject(tempObj);
                    }

                    tempObj.setData(valueObj);
                });

                if (topStockArr.length > topStocks.length) {
                    topStockArr.removeAt(topStocks.length, topStockArr.length - topStocks.length);
                }
            } else {
                topStockArr.clear();
            }

            topStocksForExg[type] = topStockArr;
            topStocksMapByExg[exchange] = topStocksForExg;
        },

        getTopStocksCollectionByType: function getTopStocksCollectionByType(exchange, type) {
            var topStocksMapByExg = this.get('topStocksMapByExgs');

            if (!topStocksMapByExg[exchange] || !topStocksMapByExg[exchange][type]) {
                this.createTopStocks(exchange, type, []);
            }

            return topStocksMapByExg[exchange][type];
        }
    }).create();

});