define('ir-app/utils/web-storage', ['exports', 'ir-app/utils/utils', 'ir-app/config/app-config', 'ir-app/models/shared/profile/profile-data-store'], function (exports, utils, appConfig, profileDS) {

    'use strict';

    exports['default'] = (function () {
        var _isStorageAvailable = function _isStorageAvailable() {
            // return (typeof (Storage) !== 'undefined'); // Old way - Doesn't work in Chrome but works in Firefox.
            var temp = 'test';
            try {
                localStorage.setItem(temp, temp);
                localStorage.removeItem(temp);
                return true;
            } catch (e) {
                return false;
            }
        };

        var _getFromStorageAsString = function _getFromStorageAsString(key, storageType) {
            var value;

            if (_isStorageAvailable() && utils['default'].validators.isAvailable(key)) {
                if (storageType === utils['default'].Constants.StorageType.Local) {
                    value = localStorage.getItem(key);
                } else {
                    value = sessionStorage.getItem(key);
                }
            }

            return value;
        };

        var _getFromStorageAsObj = function _getFromStorageAsObj(key, storageType) {
            var obj;
            var str = _getFromStorageAsString(key, storageType);

            if (utils['default'].validators.isAvailable(str)) {
                obj = utils['default'].jsonHelper.convertFromJson(str);
            }

            return obj;
        };

        // TODO: [Janitha] Temporary until authentication refactoring.
        var _getFromStorageAsObjDecrypted = function _getFromStorageAsObjDecrypted(key, storageType) {
            var obj;
            var str = _getFromStorageAsString(key, storageType);

            if (utils['default'].validators.isAvailable(str)) {
                var decrypted = utils['default'].crypto.decryptText(str);
                obj = utils['default'].jsonHelper.convertFromJson(decrypted);
            }

            return obj;
        };

        var _containsInStorage = function _containsInStorage(key, storageType) {
            var contains = false;

            if (_isStorageAvailable() && utils['default'].validators.isAvailable(key)) {
                var storageObj;

                if (storageType === utils['default'].Constants.StorageType.Local) {
                    storageObj = localStorage.getItem(key);
                } else {
                    storageObj = sessionStorage.getItem(key);
                }

                if (storageObj) {
                    contains = true;
                }
            }

            return contains;
        };

        var _addToStorageFromString = function _addToStorageFromString(key, value, storageType) {
            var status = false;

            if (_isStorageAvailable() && utils['default'].validators.isAvailable(key) && utils['default'].validators.isAvailable(value)) {
                if (storageType === utils['default'].Constants.StorageType.Local) {
                    localStorage.setItem(key, value);
                } else {
                    sessionStorage.setItem(key, value);
                }

                status = true;
            }

            return status;
        };

        var _addToStorageFromObj = function _addToStorageFromObj(key, valueObj, storageType) {
            var status = false;

            if (utils['default'].validators.isAvailable(key) && valueObj) {
                var valueString = utils['default'].jsonHelper.convertToJson(valueObj);
                status = _addToStorageFromString(key, valueString, storageType);
            }

            return status;
        };

        // TODO: [Janitha] Temporary until authentication refactoring.
        var _addToStorageFromObjEncrypted = function _addToStorageFromObjEncrypted(key, valueObj, storageType) {
            var status = false;

            if (utils['default'].validators.isAvailable(key) && valueObj) {
                var valueString = utils['default'].jsonHelper.convertToJson(valueObj);
                var encrypted = utils['default'].crypto.encryptText(valueString);
                status = _addToStorageFromString(key, encrypted, storageType);
            }

            return status;
        };

        var _removeFromStorageByKey = function _removeFromStorageByKey(key, storageType) {
            var status = false;

            if (_isStorageAvailable() && utils['default'].validators.isAvailable(key)) {
                if (storageType === utils['default'].Constants.StorageType.Local) {
                    localStorage.removeItem(key);
                } else {
                    sessionStorage.removeItem(key);
                }

                status = true;
            }

            return status;
        };

        var getKey = function getKey(key, language) {
            var cacheKey = utils['default'].validators.isAvailable(key) ? key : utils['default'].Constants.StringConst.Empty;
            var keyArray = [appConfig['default'].customisation.clientPrefix, cacheKey];

            if (utils['default'].validators.isAvailable(language)) {
                keyArray[keyArray.length] = language;
            }

            return keyArray.join(utils['default'].Constants.StringConst.Underscore);
        };

        var getExchangeKey = function getExchangeKey(key, exchange, language) {
            var cacheExchange = utils['default'].validators.isAvailable(exchange) ? exchange : utils['default'].Constants.StringConst.Empty;
            var cacheKey = utils['default'].validators.isAvailable(key) ? key : utils['default'].Constants.StringConst.Empty;
            var keyArray = [appConfig['default'].customisation.clientPrefix, cacheExchange, cacheKey];

            if (utils['default'].validators.isAvailable(language)) {
                keyArray[keyArray.length] = language;
            }

            return keyArray.join(utils['default'].Constants.StringConst.Underscore);
        };

        var addString = function addString(key, value, storageType, saveImmediately) {
            var storageTypeValue = storageType;

            if (!storageTypeValue) {
                profileDS['default'].setComponent(key, value, saveImmediately);
                storageTypeValue = storageType || utils['default'].Constants.StorageType.Local;
            }

            return _addToStorageFromString(key, value, storageTypeValue);
        };

        var addObject = function addObject(key, value, storageType, saveImmediately) {
            var storageTypeValue = storageType;

            if (!storageTypeValue) {
                profileDS['default'].setComponent(key, value, saveImmediately);
                storageTypeValue = storageType || utils['default'].Constants.StorageType.Local;
            }

            return _addToStorageFromObj(key, value, storageTypeValue);
        };

        var addFromObjEncrypted = function addFromObjEncrypted(key, value, storageType, saveImmediately) {
            var storageTypeValue = storageType;

            if (!storageTypeValue) {
                profileDS['default'].setComponent(key, value, saveImmediately);
                storageTypeValue = storageType || utils['default'].Constants.StorageType.Local;
            }

            return _addToStorageFromObjEncrypted(key, value, storageTypeValue);
        };

        var getString = function getString(key, storageType) {
            var storageTypeValue = storageType || utils['default'].Constants.StorageType.Local;
            return _getFromStorageAsString(key, storageTypeValue);
        };

        var getObject = function getObject(key, storageType) {
            var storageTypeValue = storageType || utils['default'].Constants.StorageType.Local;
            return _getFromStorageAsObj(key, storageTypeValue);
        };

        // TODO: [Janitha] Temporary until authentication refactoring.
        var getAsObjDecrypted = function getAsObjDecrypted(key, storageType) {
            var storageTypeValue = storageType || utils['default'].Constants.StorageType.Local;
            return _getFromStorageAsObjDecrypted(key, storageTypeValue);
        };

        var contains = function contains(key, storageType) {
            var storageTypeValue = storageType || utils['default'].Constants.StorageType.Local;
            return _containsInStorage(key, storageTypeValue);
        };

        var remove = function remove(key, storageType) {
            var storageTypeValue = storageType || utils['default'].Constants.StorageType.Local;
            return _removeFromStorageByKey(key, storageTypeValue);
        };

        var clearCache = function clearCache() {
            // Calculate the size and if it exceeds 3 MB, clear the cache
            var size = 0;
            for (var i in localStorage) {
                size += localStorage[i].length * 2 / 1024 / 1024;
            }

            if (size > appConfig['default'].localStorage.maxLocalStorageSize) {
                localStorage.clear();
            }
        };

        var flush = function flush() {
            localStorage.clear();
        };

        var getCookie = function getCookie(cname) {
            var name = cname + "=";
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) === ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) === 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        };

        var createCookie = function createCookie(name, value, days) {
            var expires = "";
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
                expires = "; expires=" + date.toGMTString();
            } else {
                expires = "";
            }
            document.cookie = encodeURI(name) + "=" + encodeURI(value) + expires + "; path=/";
        };

        return {
            addString: addString,
            addObject: addObject,
            addFromObjEncrypted: addFromObjEncrypted, // TODO: [Janitha] Temporary until authentication refactoring.
            getString: getString,
            getObject: getObject,
            getAsObjDecrypted: getAsObjDecrypted, // TODO: [Janitha] Temporary until authentication refactoring.
            contains: contains,
            remove: remove,
            getKey: getKey,
            getExchangeKey: getExchangeKey,
            clearCache: clearCache,
            getCookie: getCookie,
            createCookie: createCookie,
            flush: flush
        };
    })();

});