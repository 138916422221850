define('ir-app/utils/full-screen-helper', ['exports', 'ir-app/utils/utils'], function (exports, utils) {

    'use strict';

    exports['default'] = (function () {
        var requestFullScreen = function requestFullScreen(element) {
            // Supports most browsers and their versions.
            var requestMethod = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullscreen;

            if (requestMethod) {
                // Native full screen.
                requestMethod.call(element);
            } else {
                // Older IE.
                try {
                    // Ignore from JSHint and ESLint, this is only available in IE and this is how it should be invoked
                    // noinspection Eslint
                    var wScript = new ActiveXObject('WScript.Shell'); // jshint ignore:line

                    wScript.SendKeys('{F11}');
                } catch (e) {
                    utils['default'].logger.logError('Full screen mode not supported by the browser');

                    showMessage();
                }
            }
        };

        var cancelFullScreen = function cancelFullScreen(element) {
            var requestMethod = element.cancelFullScreen || element.webkitCancelFullScreen || element.mozCancelFullScreen || element.exitFullscreen || element.webkitExitFullscreen || element.msExitFullscreen;

            if (requestMethod) {
                // cancel full screen.
                requestMethod.call(element);
            } else {
                // Older IE.
                try {
                    // Ignore from JSHint and ESLint, this is only available in IE and this is how it should be invoked
                    // noinspection Eslint
                    var wScript = new ActiveXObject('WScript.Shell'); // jshint ignore:line

                    wScript.SendKeys('{F11}');
                } catch (e) {
                    utils['default'].logger.logError('Full screen mode not supported by the browser');

                    showMessage();
                }
            }
        };

        var showMessage = function showMessage() {
            // TODO: [Sahan] Find a way to pass the message in current language
            utils['default'].messageService.showMessage('Full screen mode not supported by the browser. Please press F11.', utils['default'].Constants.MessageTypes.Warning, false, 'Warning', [{
                type: utils['default'].Constants.MessageBoxButtons.Ok
            }]);
        };

        return {
            requestFullScreen: requestFullScreen,
            cancelFullScreen: cancelFullScreen
        };
    })();

});