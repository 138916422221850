define('ir-app/templates/ir-mobile/financial-ratio-widgets/financial-tabs', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"role","tabpanel");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("ul");
        dom.setAttribute(el2,"id","tablist");
        dom.setAttribute(el2,"class","nav nav-tabs fin-tabs");
        dom.setAttribute(el2,"role","tablist");
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3,"role","presentation");
        dom.setAttribute(el3,"class","active four-tabs");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"href","#location1");
        dom.setAttribute(el4,"aria-controls","location1");
        dom.setAttribute(el4,"role","tab");
        dom.setAttribute(el4,"data-toggle","tab");
        dom.setAttribute(el4,"class","font-medium");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3,"role","presentation");
        dom.setAttribute(el3,"class","four-tabs");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"href","#location1");
        dom.setAttribute(el4,"aria-controls","location1");
        dom.setAttribute(el4,"role","tab");
        dom.setAttribute(el4,"data-toggle","tab");
        dom.setAttribute(el4,"class","font-medium");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3,"role","presentation");
        dom.setAttribute(el3,"class","four-tabs");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"href","#location1");
        dom.setAttribute(el4,"aria-controls","location1");
        dom.setAttribute(el4,"role","tab");
        dom.setAttribute(el4,"data-toggle","tab");
        dom.setAttribute(el4,"class","font-medium");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3,"role","presentation");
        dom.setAttribute(el3,"class","four-tabs");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"href","#location1");
        dom.setAttribute(el4,"aria-controls","location1");
        dom.setAttribute(el4,"role","tab");
        dom.setAttribute(el4,"data-toggle","tab");
        dom.setAttribute(el4,"class","font-medium");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","full-height");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, element = hooks.element, content = hooks.content, inline = hooks.inline;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element0 = dom.childAt(fragment, [0, 1]);
        var element1 = dom.childAt(element0, [1, 1]);
        var element2 = dom.childAt(element0, [3, 1]);
        var element3 = dom.childAt(element0, [5, 1]);
        var element4 = dom.childAt(element0, [7, 1]);
        var morph0 = dom.createMorphAt(element1,0,0);
        var morph1 = dom.createMorphAt(element2,0,0);
        var morph2 = dom.createMorphAt(element3,0,0);
        var morph3 = dom.createMorphAt(element4,0,0);
        var morph4 = dom.createMorphAt(dom.childAt(fragment, [2]),1,1);
        element(env, element1, context, "action", ["onTabSelected", 1], {});
        content(env, morph0, context, "app.lang.labels.is");
        element(env, element2, context, "action", ["onTabSelected", 2], {});
        content(env, morph1, context, "app.lang.labels.bs");
        element(env, element3, context, "action", ["onTabSelected", 3], {});
        content(env, morph2, context, "app.lang.labels.cf");
        element(env, element4, context, "action", ["onTabSelected", 4], {});
        content(env, morph3, context, "app.lang.labels.ratios");
        inline(env, morph4, context, "outlet", ["fnlWidgetOutlet"], {});
        return fragment;
      }
    };
  }()));

});