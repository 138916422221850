define('ir-app/models/price/price-business-entities/trade', ['exports', 'ember', 'ir-app/utils/utils'], function (exports, Ember, utils) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        sym: '', // Symbol
        dSym: '', // Display Symbol
        exg: '', // Exchange
        inst: '', // Instrument Type
        trp: 0, // Traded Price
        trq: 0, // Traded Quantity
        tts: '', // Trade Time String
        mds: '', // Market Date String
        tick: 0, // Tick
        splits: 0, // Splits
        nChg: '', // Net Change
        pctChg: '', // Percentage Change
        seq: '', // Sequence
        vwap: '', // VWAP
        trdType: 0, // Trade Type

        dDt: (function () {
            var time = this.get('tts');
            return utils['default'].formatters.formatToTime(time, 3); // This 3 should be replaced with relevant exchange time zone offset
        }).property('tts'), // Display date Time

        // TODO [Rasike] : No need to review this method until calculation logic get finalized.
        setTradeTick: function setTradeTick(prevTrp) {
            var currentTrp = this.get('trp');

            if (currentTrp === 0) {
                // If default value for trp changed this need to be changed accordingly.
                currentTrp = prevTrp;
            }

            if (prevTrp < currentTrp) {
                this.set('tick', 1);
            } else if (prevTrp === currentTrp) {
                this.set('tick', 0);
            } else {
                this.set('tick', -1);
            }
        },

        setData: function setData(symbolMessage) {
            var that = this;

            Ember['default'].$.each(symbolMessage, function (key, value) {
                that.set(key, value);
            });
        }
    });

});