define('ir-app/config/layout-config', ['exports'], function (exports) {

    'use strict';

    exports['default'] = {
        'default': {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'error page',
                    widgetTitle: 'error',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: true,
                    tab: [{
                        id: 1,
                        title: 'Error',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-mobile.common-widgets.error-page' }]
                    }]
                }]
            }
        },
        ir_mouwasat_stockticker_tdwl_4002: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'stock-ticker',
                    widgetTitle: 'stock-ticker',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'ticker',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-mobile.quote-widgets.stock-price-ticker' }]
                    }]
                }]
            },
            args: {
                args: {
                    showFooterNote: true
                },
                1: {
                    tab: {
                        1: {
                            w: {
                                1: {
                                    stockList: [{ exg: 'TDWL', sym: '4002' }
                                    //{exg: 'TDWL', sym: 'TASI', isIndex: true}
                                    ]
                                }
                            }
                        }
                    }
                }
            }
        },
        ir_mouwasat_stockoverview_tdwl_4002: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'stock-overview',
                    widgetTitle: 'stock-overview',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'stkOvw',
                        def: true,
                        css: 'active',
                        outlet: 'ir-widgets.tab-layouts.stock-overview-compact-layout',
                        w: [{ id: 1, wn: 'ir-mobile.quote-widgets.top-bar-quote' }, { id: 2, wn: 'ir-mobile.quote-widgets.side-bar-quote' }, { id: 3, wn: 'ir-mobile.chart-widgets.chart-widget' }, { id: 4, wn: 'ir-widgets.financial-widgets.share-data' }, { id: 5, wn: 'ir-mobile.peer-widgets.peer-table' }, { id: 6, wn: 'ir-widgets.financial-widgets.stock-trades' }]
                    }]
                }]
            },
            args: {
                args: {
                    showFooterNote: true
                },
                1: {
                    tab: {
                        1: {
                            w: {
                                1: {
                                    showDisplaySymbol: true
                                },
                                2: {
                                    newStkOvw: true
                                },
                                3: {
                                    isPortalChart: true,
                                    compareSymbolEnabled: true,
                                    chartIntervalEnabled: true,
                                    //volumeChartHeightPctg: 50,
                                    baseUrl: 'mouwasat',
                                    peerIndicesSymbol: ["TDWL~TASI", "TDWL~TISI", "TDWL~8210", "TDWL~8010", "TDWL~8230", "TDWL~8160", "TDWL~8030", "TDWL~8250", "TDWL~8200", "TDWL~8190", "TDWL~8060"],
                                    indices: [{
                                        exg: "TDWL",
                                        inst: "7",
                                        lDes: "Tadawul All Share Index (TASI)",
                                        sDes: "TASI",
                                        arDes: "مؤشر السوق الرئيسية (تاسي)",
                                        sym: "TASI"
                                    }, {
                                        exg: "TDWL",
                                        inst: "7",
                                        lDes: "Insurance",
                                        sDes: "Insurance",
                                        arDes: "التأمين",
                                        sym: "TISI"
                                    }],
                                    peer: [{
                                        exg: "TDWL",
                                        inst: "0",
                                        lDes: "BUPA ARABIA",
                                        sDes: "BUPA ARABIA",
                                        arDes: "شركة بوبا العربية للتأمين التعاوني",
                                        sym: "8210"
                                    }, {
                                        exg: "TDWL",
                                        inst: "0",
                                        lDes: "TAWUNIYA",
                                        sDes: "TAWUNIYA",
                                        arDes: "الشركة التعاونية للتأمين",
                                        sym: "8010"
                                    }, {
                                        exg: "TDWL",
                                        inst: "0",
                                        lDes: "ALRAJHI TAKAFUL",
                                        sDes: "ALRAJHI TAKAFUL",
                                        arDes: "شركة الراجحي للتأمين التعاوني",
                                        sym: "8230"
                                    }],
                                    dir: 'mouwasat'
                                    //exportDisabled: true
                                }
                            }
                        }
                    }
                }
            }
        },
        ir_mouwasat_detachChart_tdwl_4002: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'detach-chart',
                    widgetTitle: 'detach-chart',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'ticker',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-mobile.chart-widgets.detach-chart-widget' }]
                    }]
                }]
            },
            args: {
                1: {
                    tab: {
                        1: {
                            w: {
                                1: {
                                    isPortalChart: true,
                                    compareSymbolEnabled: true,
                                    chartIntervalEnabled: true,
                                    volumeChartHeightPctg: 50
                                }
                            }
                        }
                    }
                }
            }
        },
        ir_mouwasat_financial_tdwl_4002: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'announcement-body',
                    widgetTitle: 'announcement-body',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'announcementBody',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-widgets.financial-widgets.new-advanced-financial' }]
                    }]
                }]
            },
            args: {
                1: {
                    tab: {
                        1: {
                            w: {
                                1: {
                                    customFieldsForIS: undefined, // array of custom fields should be included here
                                    customFieldsForBS: undefined,
                                    customFieldsForCF: undefined,
                                    customFieldsForRatios: undefined,

                                    includeSnapshot: true, // this parameter can add or remove a tab
                                    includeIS: true,
                                    includeBS: true,
                                    includeCF: true,
                                    includeRatios: false,

                                    activeSnapshot: 'active', // this parameter can have string values as ->  'active' or '' , only one active tab must be specified
                                    activeIS: '',
                                    activeBS: '',
                                    activeCF: '',
                                    activeRatios: '',

                                    includeFromToFields: true,
                                    includePeriodBasis: true,
                                    includeViewBtn: true,
                                    includeBreakdown: true,
                                    includeValuationRatioSegment: true, // valuation ratio block can be add or removed from snapshot
                                    isDisclosureAvailable: true, // disclosure visibility can be changed
                                    includeCompareBtn: true, // visibility of compare btn can be controlled

                                    comparePgeURL: 'http://www.alarabiya.net/aswaq/financial/{{1}}/{{2}}', // this must be given by the client
                                    docDownloadURL: window.location.origin + '/mouwasat/ibe?sid?=sid&RT=87&DOC=', // PDFs are downloaded from this URL
                                    colorsArrayForChart: ['#211F5F', '#E97C8B', '#403cb8', '#e97ca6', '#e97f7c', '#3c76b8', '#e9887c'],
                                    numberOFQuartersDisplayed: 4, // number of default displaying quarters and years can be specified
                                    numberOFYearsDisplayed: 7,

                                    activateViewDropdown: false, // changing the view period will change the content also if this is true
                                    keyListView: [{ view: 3, des: 'interim' }, { view: 4, des: 'ttm' }, { view: 2, des: 'quarterly' }, { view: 1, des: 'yearly' }], // view types can be customized, A-1 Q-2 C-3 T-4
                                    enableDefaultDetailedView: false // initial breakdown can be selected, True - Expand view False - Collapsed view
                                    //yearQuarterDisplayFormat: 'Q Y'
                                }
                            }
                        }
                    }
                }
            }
        },
        ir_mouwasat_factsheet_tdwl_4002: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'factSheet',
                    widgetTitle: 'factSheet',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'factSheet',
                        def: true,
                        css: 'active',
                        outlet: 'ir-widgets.tab-layouts.fact-sheet-layout',
                        w: [{ id: 1, wn: 'ir-widgets.factsheet-widget.company-details' }, { id: 2, wn: 'ir-mobile.chart-widgets.old-chart-widget' }, { id: 9, wn: 'ir-mobile.company-widgets.company-owners-info' },
                        // //{id: 4, wn: 'ir-mobile.company-widgets.company-management-info'},
                        //                                {id: 4, wn: 'ir-mobile.company-widgets.company-credit-ratings'},
                        { id: 5, wn: 'ir-widgets.financial-widgets.advance-financials' }, { id: 6, wn: 'ir-widgets.financial-widgets.advance-financials' }, { id: 7, wn: 'ir-widgets.financial-widgets.advance-financials' },
                        // // {id: 8, wn: 'ir-widgets.financial-widgets.client-financials'},
                        { id: 3, wn: 'ir-widgets.factsheet-widget.snapshot-chart-widget' }]
                    }]
                }]
            },

            //{id: 10, wn: 'ir-mobile.company-widgets.company-info'},
            //{id: 8, wn: 'ir-widgets.financial-widgets.financial-segmentation'}
            args: {
                args: {
                    showFooterNote: true
                },
                1: {
                    tab: {
                        1: {
                            w: {
                                2: {
                                    isPortalChart: true,
                                    includeControls: true,
                                    includeFourBoxes: true,
                                    exportDisabled: true,
                                    factSheetViewEnable: true,
                                    volumeViewEnabled: false
                                },
                                5: {
                                    activeIS: 'active', // this parameter can have string values as ->  'active' or '' , only one active tab must be specified
                                    activeBS: '',
                                    activeCF: '',
                                    activeRatios: '',
                                    includeFromToFields: true,
                                    includeViewBtn: true,
                                    setTitleIS: true,
                                    showMsg: true,
                                    factSheetViewEnable: true, // will have to enable when using in fact sheet view. This will hide the controllers and
                                    showColumnTopMsg: false,

                                    numberOFQuartersDisplayed: 2, // number of default displaying quarters and years can be specified
                                    numberOFYearsDisplayed: 2,

                                    activateViewDropdown: false, // changing the view period will change the content also if this is true
                                    keyListView: [{ view: 2, des: 'quarterly' }, { view: 1, des: 'yearly' }], // view types can be customized, A-1 Q-2 C-3 T-4
                                    enableDefaultDetailedView: false // initial breakdown can be selected, True - Expand view False - Collapsed view
                                    //yearQuarterDisplayFormat: 'Q Y'
                                },
                                6: {
                                    activeIS: '', // this parameter can have string values as ->  'active' or '' , only one active tab must be specified
                                    activeBS: 'active',
                                    activeCF: '',
                                    activeRatios: '',
                                    setTitleBS: true,
                                    showMsg: false,
                                    includeFromToFields: true,
                                    includeViewBtn: true,

                                    factSheetViewEnable: true, // will have to enable when using in fact sheet view. This will hide the controllers and
                                    showColumnTopMsg: true,

                                    numberOFQuartersDisplayed: 2, // number of default displaying quarters and years can be specified
                                    numberOFYearsDisplayed: 2,

                                    activateViewDropdown: false, // changing the view period will change the content also if this is true
                                    keyListView: [{ view: 2, des: 'quarterly' }, { view: 1, des: 'yearly' }], // view types can be customized, A-1 Q-2 C-3 T-4
                                    enableDefaultDetailedView: false // initial breakdown can be selected, True - Expand view False - Collapsed view
                                    //yearQuarterDisplayFormat: 'Q Y'
                                },
                                7: {
                                    activeIS: '', // this parameter can have string values as ->  'active' or '' , only one active tab must be specified
                                    activeBS: '',
                                    activeCF: '',
                                    showMsg: false,
                                    activeRatios: 'active',
                                    setTitleR: true,
                                    customFieldsForRatios: ["FR_EPS", "FR_BVPS", "FR_DPS", "FR_PER", "FR_PBR", "FR_DivYld", "FR_ROAE", "FR_ROAA"],
                                    includeFromToFields: true,
                                    includeViewBtn: true,

                                    factSheetViewEnable: true, // will have to enable when using in fact sheet view. This will hide the controllers and
                                    showColumnTopMsg: true,

                                    numberOFQuartersDisplayed: 2, // number of default displaying quarters and years can be specified
                                    numberOFYearsDisplayed: 2,

                                    activateViewDropdown: false, // changing the view period will change the content also if this is true
                                    keyListView: [{ view: 2, des: 'quarterly' }, { view: 1, des: 'yearly' }], // view types can be customized, A-1 Q-2 C-3 T-4
                                    enableDefaultDetailedView: false // initial breakdown can be selected, True - Expand view False - Collapsed view
                                    //yearQuarterDisplayFormat: 'Q Y'
                                },

                                3: {
                                    numberOFQuartersDisplayed: 2, // number of default displaying quarters and years can be specified
                                    numberOFYearsDisplayed: 2,
                                    isValuesInRawData: true,

                                    keyListView: [{ view: 2, des: 'quarterly' }, { view: 1, des: 'yearly' }] },
                                // view types can be customized, A-1 Q-2 C-3 T-4
                                10: {
                                    keys: {
                                        showBasicInfo: false,
                                        showContacts: true
                                    }
                                }
                            }
                        }
                    }
                } }
        },

        ir_mouwasat_disclosures_tdwl_4002: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'documents',
                    widgetTitle: 'documents',
                    icon: 'icon-files',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'documents',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-mobile.file-widgets.file-list' }]
                    }]
                }]
            }
        },
        ir_mouwasat_companyprofile_tdwl_4002: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'companyProf',
                    widgetTitle: 'companyProf',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'companyProf',
                        def: true,
                        css: 'active',
                        outlet: 'ir-widgets.tab-layouts.company-profile-layout',
                        w: [{ id: 1, wn: 'ir-mobile.quote-widgets.top-bar-quote-new' }, { id: 2, wn: 'ir-mobile.company-widgets.company-info' }, { id: 3, wn: 'ir-mobile.company-widgets.company-management-info' }, { id: 5, wn: 'ir-mobile.company-widgets.company-owners-info' }, { id: 4, wn: 'ir-mobile.company-widgets.company-subsidiaries-info' }, { id: 6, wn: 'ir-mobile.company-widgets.company-key-officers-info' }]
                    }]
                }]
            },
            args: {
                1: {
                    tab: {
                        1: {
                            w: {
                                1: {
                                    showDisplaySymbol: true
                                },
                                2: {
                                    keys: {
                                        showBasicInfo: true,
                                        showContacts: true
                                    }
                                },
                                4: {
                                    displayFields: [{ lanKey: 'outShr', dataField: 'outStdShr', formatter: 'L', type: 'CP' }, { lanKey: 'freeFltShr', dataField: 'freeFltShr', formatter: 'P', type: 'CP' }, { lanKey: 'parVal', dataField: 'parVal', formatter: 'C', type: 'CP' }, { lanKey: 'pdCap', dataField: 'paidCap', formatter: 'C', type: 'CP' }, { lanKey: 'mktCap', dataField: 'mktCap', formatter: 'C', type: 'STK' }, { lanKey: 'authCap', dataField: 'authCap', formatter: 'C', type: 'CP' }]
                                }
                            }
                        }
                    }
                }
            }
        },
        ir_mouwasat_newsannouncements_tdwl_4002: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'newsAnnouncement',
                    widgetTitle: 'newsAnnouncement',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'announcement',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-widgets.announcement-widgets.announcements-full-list' }]
                    }, {
                        id: 2,
                        title: 'news',
                        def: false,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-widgets.announcement-widgets.announcements-full-list' }]
                    }]
                }]
            },
            args: {
                1: {
                    tab: {
                        1: {
                            w: {
                                1: {
                                    contentType: 1,
                                    pageSize: 20,
                                    showCustomTitle: false,
                                    loadInPopup: true
                                }
                            }
                        },
                        2: {
                            w: {
                                1: {
                                    contentType: 2,
                                    pageSize: 20,
                                    showCustomTitle: false,
                                    loadInPopup: true
                                }
                            }
                        }
                    }
                }
            }
        },
        ir_mouwasat_financialCalendar_tdwl_4002: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'financial-calendar',
                    widgetTitle: 'financial-calendar',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'financialCalendar',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-widgets.calendar-events.financial-calendar' }]
                    }]
                }]
            },
            args: {
                1: {
                    tab: {
                        1: {
                            w: {
                                1: {
                                    isForExchange: false,
                                    pageSize: 10,
                                    loadAnnInPopup: true,
                                    tableView: false
                                }
                            }
                        }
                    }
                }
            }
        },
        ir_mouwasat_miniQuote_tdwl_4002: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'smartQuote',
                    widgetTitle: 'smartQuote',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'smartQuote',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout-miniquote',
                        w: [{ id: 1, wn: 'ir-mobile.quote-widgets.smart-quote-mouwasat' }]
                    }]
                }]
            }
        }
    };

});