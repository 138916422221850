define('ir-app/controllers/ir-mobile/common-widgets/stock-header', ['exports', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-constants'], function (exports, priceService, sharedService, BaseController, priceConstants) {

    'use strict';

    exports['default'] = BaseController['default'].extend({
        basicInfo: {},
        stock: {},
        exchange: {},

        // Base overrides
        onLoadWidget: function onLoadWidget() {
            var stock = priceService['default'].stockDS.getStock(this.get('exg'), this.get('sym'));
            this.set('stock', stock);

            var exchange = priceService['default'].exchangeDS.getExchange(this.get('exg'));
            this.set('exchange', exchange);
        },

        onPrepareData: function onPrepareData() {
            var that = this;

            var basicInfo = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, function () {
                that.onDataSuccess();
            }, function () {
                that.onDataError();
            }, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyBasicInfo);

            this.set('basicInfo', basicInfo);
            priceService['default'].addSymbolRequest(this.get('exg'), this.get('sym'));

            // request symbol meta
            priceService['default'].sendSymbolValidationRequest(that.get('exg'), that.get('sym'), sharedService['default'].userSettings.currentLanguage, undefined, undefined);

            // request exchange meta
            priceService['default'].downloadExchangeProperty(this.get('exg'), sharedService['default'].userSettings.currentLanguage);

            this.utils.analyticsService.trackEvent(this.get('gaKey'), this.utils.Constants.GAActions.show, ['sym:', this.get('sym'), '~', this.get('exg')].join(''));
        },

        onClearData: function onClearData() {
            this.set('basicInfo', null);
            this.set('stock', null);
            priceService['default'].removeSymbolRequest(this.get('exg'), this.get('sym'));
        },

        onCheckDataAvailability: function onCheckDataAvailability() {
            var companyProfObj = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage);

            // TODO: [ASANKA] stock data availability should check
            if (companyProfObj) {
                return this.utils.validators.isAvailable(companyProfObj.compID);
            }

            return false;
        },

        image: (function () {
            if (this.utils.validators.isAvailable(this.get('basicInfo.logo'))) {
                return this.utils.imageHelper.getImage(this.get('basicInfo.logo'));
            }
        }).property('basicInfo.logo'),

        onLanguageChanged: function onLanguageChanged() {
            this.onPrepareData();
        }
    });

});