define('ir-app/components/table/table-component', ['exports', 'ember', 'ember-table/components/ember-table', 'ember-table/controllers/row-array', 'ir-app/views/table/row-definition'], function (exports, Ember, EmberTableComponent, RowArrayController, RowDefinition) {

    'use strict';

    exports['default'] = EmberTableComponent['default'].extend({
        layoutName: 'components/ember-table',

        afterRenderEvent: function afterRenderEvent() {},
        buttonAction: null,
        buttonMenuAction: null,

        // Extended from lib to give our row view
        tableRowView: 'Ember.TableRow',
        tableRowViewClass: Ember['default'].computed.alias('tableRowView'),

        // Event handling params
        clickEventHandler: null,

        bodyContent: Ember['default'].computed(function () {
            // Extended from lib to give our row definition
            return RowArrayController['default'].create({
                target: this,
                parentController: this,
                container: this.get('container'),
                itemController: RowDefinition['default'],
                content: this.get('content')
            });
        }).property('content'),

        refreshTableComponent: (function () {
            var that = this;
            Ember['default'].run(that, that.elementSizeDidChange);
        }).observes('isRefreshed', 'content'),

        getTableComponent: function getTableComponent() {
            var widget = Ember['default'].$('#' + this.get('id'));

            if (widget && widget.length > 0) {
                widget = widget[0];
            }

            return widget;
        },

        /* Starting Event handling Functions */
        clickEventFunction: function clickEventFunction(e) {
            // This only blocks default right click menu
            var event = e;

            if (event.ctrlKey) {
                return true;
            }

            if (!event) {
                event = window.event;
            }

            if (event.preventDefault) {
                event.preventDefault();
            } else {
                event.returnValue = false;
            }

            event.stopPropagation();
            return false;
        },

        doubleClick: function doubleClick(e) {
            var event = e;

            if (!event) {
                event = window.event;
            }

            var selectedRow = this.getRowForEvent(event);

            if (!selectedRow) {
                return true;
            } else {
                this.sendAction('doubleClickAction', selectedRow, event);
            }
        },

        load: (function () {
            this.clickEventHandler = this.onDocumentClick.bind(this);
            var widget = this.getTableComponent();

            if (widget.addEventListener) {
                // For all major browsers, except IE 8 and earlier
                widget.addEventListener('mousedown', this.clickEventHandler);
                document.addEventListener('contextmenu', this.clickEventFunction);
            } else if (widget.attachEvent) {
                // For IE 8 and earlier versions
                widget.attachEvent('onclick', this.clickEventHandler);
                widget.attachEvent('onmousedown', this.clickEventHandler);
                document.attachEvent('oncontextmenu', this.clickEventFunction);
            }
        }).on('didInsertElement'),

        unload: (function () {
            // Implement rest of the event removal
            var widget = this.getTableComponent();

            if (widget.removeEventListener) {
                widget.removeEventListener('mousedown', this.clickEventHandler);
                document.removeEventListener('contextmenu', this.clickEventFunction);
            } else if (widget.detachEvent) {
                // For IE 8 and earlier versions
                widget.detachEvent('onclick', this.clickEventHandler);
                widget.detachEvent('onmousedown', this.clickEventHandler);
                document.detachEvent('oncontextmenu', this.clickEventFunction);
            }
        }).on('willDestroyElement'),

        onDocumentClick: function onDocumentClick(e) {
            var event = e;

            if (!event) {
                event = window.event;
            }

            var selectedRow = this.getRowForEvent(event);

            if (!selectedRow) {
                return true;
            } else {
                var that = this;
                that.sendAction('rowClickAction', selectedRow, event); // This should be executed before event.stopPropagation();

                // This patch is needed in order to make the selection appear in the table
                Ember['default'].run.later(function () {
                    that.click(event);
                }, 100);
            }
        },
        /* Ending Event handling Functions */

        footerContent: Ember['default'].computed(function () {
            var rows = this.get('footerArray');

            if (!rows) {
                return Ember['default'].A();
            }

            return rows;
        }).property('footerArray'),

        actions: {
            sortByColumn: function sortByColumn(column) {
                this.sendAction('sortAction', column);
            },

            buttonAction: function buttonAction(column, row) {
                this.set('buttonAction', column.get('buttonFunction'));
                this.sendAction('buttonAction', column, row);
            },

            buttonMenuAction: function buttonMenuAction(option, row, column) {
                this.set('buttonMenuAction', column.get('buttonFunction'));
                this.sendAction('buttonMenuAction', option, row, column);
            }
        }
    });

});