define('ir-app/controllers/ir-widgets/calendar-events/calendar-events-list', ['exports', 'ember', 'ir-app/models/price/price-service', 'ir-app/controllers/base-array-controller', 'ir-app/utils/utils', 'ir-app/models/shared/shared-service'], function (exports, Ember, priceService, BaseArrayController, utils, sharedService) {

  'use strict';

  exports['default'] = BaseArrayController['default'].extend({
    model: Ember['default'].A(),
    fromDate: null,
    toDate: null,
    lan: undefined,
    exchange: undefined,
    expandEventId: undefined,
    count: 0,
    pageSize: undefined,
    current: 1,
    isLoadAnnInPopup: false,
    isShowAnnPopup: false,
    isLoadingAnnPopup: false,
    annObj: null,
    toDay: '',
    showUpcoming: false,

    Tabs: {
      UpcomingEvents: 1,
      PastEvents: 2
    },

    // Base overrides
    onLoadWidget: function onLoadWidget() {
      if (this.get('showUpcoming')) {
        this.set('selectedTab', this.Tabs.UpcomingEvents);
      } else {
        this.set('selectedTab', this.Tabs.PastEvents);
      }

      this.set('toDay', new Date());
      if (this.get('isForExchange')) {
        this.set('sym', 'A');
      }

      var expandEventId = this.get('generatedArgs') ? this.get('generatedArgs')[0] : undefined;
      this.set('expandEventId', expandEventId);

      this.set('lan', sharedService['default'].userSettings.currentLanguage.toLowerCase());
      this.set('exchange', this.get('exg'));
      this.set('pageSize', this.get('pageSize'));
      this.set('isLoadAnnInPopup', this.get('loadAnnInPopup'));
      this.setErrorMessage();
      this._setDefaultDates();

      if (this.get('showCustomTitle')) {
        this.setValueToBaseSymbolContainer('showBaseTitle', true);
        this.setValueToBaseSymbolContainer('baseTitle', this.get('app').lang.labels.events);
      }
      this.set('sortAscending', true);
    },

    onPrepareData: function onPrepareData() {

      this.set('today', new Date());
      this._setBoundaryDays();
      this.set('model', priceService['default'].eventCalendarDS.getEventList(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage));
      // Binding the scrollbar after the data is available and rendering the view
      this._setCount();
      Ember['default'].$('.nano').nanoScroller();

      var that = this;

      // Set both success and failure functions to the same function as in case of a failure we at least show the symbol code instead of the symbol name
      priceService['default'].downloadParamsSymbolMasterForExchange({
        exchange: that.get('exg'),
        language: sharedService['default'].userSettings.currentLanguage,
        successFn: function successFn() {
          that._sendRequest();
        },
        errorFn: function errorFn() {
          that._sendRequest();
        }
      });
    },

    onCheckDataAvailability: function onCheckDataAvailability() {
      this._setCount();
      return this.get('filteredContent').length > 0;
    },

    _sendRequest: function _sendRequest() {
      var that = this;
      var fromDate = that.get('fromDate');
      var toDate = that.get('toDate');

      priceService['default'].eventCalendarDS.clearData(that.get('exg'), that.get('sym'), sharedService['default'].userSettings.currentLanguage);
      that.startLoadingProgress();

      priceService['default'].sendEventCalendarRequest({
        exchange: that.get('exg'),
        symbol: that.get('sym'),
        language: sharedService['default'].userSettings.currentLanguage,
        pageSize: that.get('pageSize'),
        startDate: fromDate,
        endDate: toDate,
        pageIndex: that.get('current') - 1,
        sortField: 12459,
        successFn: function successFn() {
          that.onDataSuccess();
        },
        errorFn: function errorFn() {
          that.onDataError();
        }
      });
    },

    _setCount: function _setCount() {
      if (this.get('selectedTab') === this.Tabs.UpcomingEvents) {
        this.set('count', 0);
      } else {
        this.set('count', Math.ceil(priceService['default'].eventCalendarDS.getEventCount() / this.get('pageSize')));
      }
    },

    _setBoundaryDays: function _setBoundaryDays() {
      var year = this.get('today').getFullYear();
      this.set('backDate', new Date());
      this.get('backDate').setFullYear(year - 5);
      this.set('forwardDate', new Date());
      this.get('forwardDate').setFullYear(year + 5);
    },

    _setDefaultDates: function _setDefaultDates() {
      var backdate = new Date();
      var year = backdate.getFullYear();
      backdate.setFullYear(year - 5);
      if (!this.get('isUnlimitedHistory')) {
        this.set('fromDate', utils['default'].formatters.convertDateToyyyymmdd(backdate));
      }
      backdate.setFullYear(year + 5);
      this.set('toDate', utils['default'].formatters.convertDateToyyyymmdd(backdate));
    },

    _getDateTime: function _getDateTime(dateString) {
      return utils['default'].formatters.convertStringToDate(dateString);
    },

    onClearData: function onClearData() {
      this._reset();
    },

    onAfterRender: function onAfterRender() {
      // this._setActiveTab();
      Ember['default'].$('.nano').nanoScroller();
    },

    onLanguageChanged: function onLanguageChanged() {
      this._reset();
      this.onLoadWidget();
      this.onPrepareData();
    },

    _reset: function _reset() {
      this.set('fromDate', null);
      this.set('toDate', null);
      this.set('model', Ember['default'].A());
      this.set('current', 1);
    },

    setNoEvents: function setNoEvents() {
      this.set('errorMessage', this.get('app').lang.messages.dataNotAvailable);
    },

    setErrorMessage: (function () {
      if (this.get('filteredContent').length > 0) {
        this.hideDataErrorMessage();
      } else if (!this.get('isLoading')) {
        this.showDataErrorMessage();
        this.set('errorMessage', this.get('app').lang.messages.noEvents);
      }
    }).observes('filteredContent'),

    filteredContent: (function () {
      return this.get('model').filter((function (that) {
        //eslint-disable-line
        return function (theObject) {
          if (that.get('selectedTab') === that.Tabs.UpcomingEvents) {
            return that._getDateTime(theObject.eventStartDate) >= that.get('toDay');
          } else {
            return that._getDateTime(theObject.eventStartDate) < that.get('toDay');
          }
        };
      })(this));
    }).property('selectedTab', 'model.@each'),

    sendAnnouncementBodyRequest: function sendAnnouncementBodyRequest(annId) {
      var that = this;
      that.set('isShowAnnPopup', true);
      var annObj = priceService['default'].announcementDS.getAnnouncement(annId);
      this.set('annObj', annObj);

      if (!annObj.get('isBodyUpdated')) {
        that.set('isLoadingAnnPopup', true);
        // that.annObj.set('bod', that.get('app').lang.messages.loading);

        priceService['default'].sendNewsAnnBodyRequest({
          id: annObj.get('id'),
          type: annObj.get('type'),
          reqSuccessFn: function reqSuccessFn() {
            that.set('isLoadingAnnPopup', false);
          },
          reqFailureFn: function reqFailureFn() {
            that.annObj.set('isBodyUpdated', false);
            that.set('isShowAnnPopup', false);
          },
          lan: sharedService['default'].userSettings.currentLanguage
        });
      }
    },

    closeAnnPopup: function closeAnnPopup() {
      var that = this;
      that.set('isShowAnnPopup', false);
      this.set('annObj', null);
    },
    _onTabChange: function _onTabChange(tabId) {
      this.set('selectedTab', tabId);
      this._setCount();
    },
    upComingEventTabSelected: (function () {
      return this.get('selectedTab') === this.Tabs.UpcomingEvents;
    }).property('selectedTab'),
    actions: {

      onTabSelected: function onTabSelected(tabId) {
        this._onTabChange(tabId);
      },

      searchByDateSelection: function searchByDateSelection() {
        var fromDate = this.get('fromDate');
        var toDate = this.get('toDate');

        if (typeof fromDate === 'object' && typeof toDate === 'object') {
          this.set('fromDate', utils['default'].formatters.convertDateToyyyymmdd(fromDate));
          this.set('toDate', utils['default'].formatters.convertDateToyyyymmdd(toDate));
          this.setNoEvents();
          // this.setErrorMessage();
          this.set('current', 1);
          this.startLoadingProgress();
          this._sendRequest();
        } else {
          this.set('errorMessage', this.get('app').lang.messages.selectFromToDates);
          this.set('showError', true);
        }

        this.set('fromDate', fromDate);
        this.set('toDate', toDate);
      },

      resetDateSelection: function resetDateSelection() {
        this.set('expandEventId', undefined);
        this.onLanguageChanged();
      },

      pageChanged: function pageChanged(current) {
        this.startLoadingProgress();
        this.set('current', current);
        this._sendRequest();
      },

      loadAnnPopup: function loadAnnPopup(annId) {
        this.sendAnnouncementBodyRequest(annId);
      },

      closeAnnPopup: function closeAnnPopup() {
        this.closeAnnPopup();
      }
    }
  });

});