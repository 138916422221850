define('ir-app/controllers/ir-widgets/corporate-actions/capital-actions-list', ['exports', 'ember', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/controllers/base-array-controller', 'ir-app/models/price/price-constants', 'ir-app/models/shared/language/language-data-store', 'ir-app/utils/utils'], function (exports, Ember, priceService, sharedService, BaseArrayController, priceConstants, languageDataStore, utils) {

    'use strict';

    exports['default'] = BaseArrayController['default'].extend({

        count: undefined,
        searchParams: undefined,
        capActObj: undefined,
        detailList: Ember['default'].A(),
        actionTypes: Ember['default'].A(),
        isDtlTbl: false,
        isCapChgTbl: false,
        isActionTypes: false,
        isCapActData: false,
        isCachedData: true, // if object need to be cached or not
        customView: false,
        current: 1,
        actTypeId: 0,
        pageSize: 20,

        onLoadWidget: function onLoadWidget() {
            this.setErrorMessage();
        },

        onPrepareData: function onPrepareData() {
            var that = this;
            that.startLoadingProgress();
            that.set('pageSize', this.get('pageSize'));
            that._loadCapitalActionPage();
        },

        onCheckDataAvailability: function onCheckDataAvailability() {
            var corporateActObj = priceService['default'].capitalActionsDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage);

            if (corporateActObj && corporateActObj.capitalAction && corporateActObj.capitalAction.length > 0) {
                if (corporateActObj.corporateMetaData.length > 0) {
                    this.set('actionTypes', corporateActObj.corporateMetaData);
                }
                this.set('count', Math.ceil(corporateActObj.listSize / this.get('pageSize')));
            } else if (corporateActObj && corporateActObj.capitalAction && corporateActObj.corporateMetaData.length > 0) {
                this.set('actionTypes', corporateActObj.corporateMetaData);
            }
            return corporateActObj && corporateActObj.capitalAction && corporateActObj.capitalAction.length > 0;
        },

        onLanguageChanged: function onLanguageChanged() {
            this.onPrepareData();
        },

        filteredContent: (function () {
            var content = this.get('arrangedContent');
            if (content) {
                return content;
            }
        }).property('arrangedContent'),

        _loadCapitalActionPage: function _loadCapitalActionPage() {
            var that = this;
            var searchParams = this.get('searchParams');
            if (!searchParams) {
                var searchPara = {
                    pageSize: this.get('pageSize'),
                    pageIdx: 0,
                    actType: this.get('actTypeId'),
                    custView: this.get('customView'),
                    idList: this.get('typeIds')

                };
                this.set('searchParams', searchPara);
                this.set('current', 1);
            }

            var corporateAction = priceService['default'].capitalActionsDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, priceConstants['default'].DataTypes.CorporateActionDataStore.CapitalActionInfo, that.get('searchParams'), function () {
                that.onDataSuccess();
            }, function () {
                that.onDataError();
            }, this.get('isCachedData')).capitalAction;

            this.set('model', corporateAction);
        },

        setErrorMessage: (function () {
            if (this.get('filteredContent').length > 0) {
                this.hideDataErrorMessage();
            } else if (!this.get('isLoading')) {
                this.showDataErrorMessage();
                this.set('errorMessage', this.get('app').lang.messages.dataNotAvailable);
            }
        }).observes('filteredContent'),

        sendCapitalActinDetalRequest: function sendCapitalActinDetalRequest(actionId) {
            var that = this;

            var successFn = function successFn() {
                var actDetails = priceService['default'].capitalActionsDS.getCapitalActionByActionId(that.get('exg'), that.get('sym'), sharedService['default'].userSettings.currentLanguage, priceConstants['default'].DataTypes.CorporateActionDataStore.CapitalActionInfo, actionId);
                that.set('capActObj', actDetails);
                that._viewCapitalActDetails(actDetails);
            };

            var errorFn = function errorFn() {
                that.set('isShowPopup', false);
            };

            priceService['default'].capitalActionsDS.getActionDetail(that.get('exg'), that.get('sym'), sharedService['default'].userSettings.currentLanguage, priceConstants['default'].DataTypes.CorporateActionDataStore.CapitalActionInfo, actionId, successFn, errorFn);
        },

        _viewCapitalActDetails: function _viewCapitalActDetails(corporateActObj) {
            var that = this;

            if (corporateActObj && (corporateActObj.hasShrChg || corporateActObj.hasParChg())) {
                that.set('isCapChgTbl', true);
            } else {
                that.set('isCapChgTbl', false);
            }

            if (corporateActObj.typeId) {
                var detailLst = that.get('detailList');
                var capObj;

                var currentLangObj = languageDataStore['default'].getLanguageObj().lang.labels;
                switch (Number(corporateActObj.typeId)) {
                    case 7:
                        // Cash Dividend

                        capObj = {
                            label: currentLangObj.dividends,
                            value: corporateActObj.dividend_amount
                        };
                        detailLst.push(capObj);
                        capObj = {
                            label: currentLangObj.currency,
                            value: corporateActObj.currency
                        };
                        detailLst.push(capObj);

                        capObj = {
                            label: currentLangObj.periodEndDate,
                            value: corporateActObj.period_end_date
                        };
                        detailLst.push(capObj);
                        capObj = {
                            label: currentLangObj.cdRelatedPeriod,
                            value: corporateActObj.cd_related_period
                        };
                        detailLst.push(capObj);
                        capObj = {
                            label: currentLangObj.splitFactor,
                            value: corporateActObj.split_factor
                        };
                        detailLst.push(capObj);
                        capObj = {
                            label: currentLangObj.cdRelatedPeriodName,
                            value: corporateActObj.cd_related_period_name
                        };
                        detailLst.push(capObj);

                        capObj = {
                            label: currentLangObj.approvalStatus,
                            value: corporateActObj.approval_status
                        };
                        detailLst.push(capObj);
                        capObj = {
                            label: currentLangObj.abnormalCd,
                            value: corporateActObj.abnormal_cd
                        };
                        detailLst.push(capObj);
                        capObj = {
                            label: currentLangObj.cdYear,
                            value: corporateActObj.cd_year
                        };
                        detailLst.push(capObj);
                        capObj = {
                            label: currentLangObj.cdPeriod,
                            value: corporateActObj.cd_period
                        };
                        detailLst.push(capObj);
                        capObj = {
                            label: currentLangObj.priceLimitRemoval,
                            value: corporateActObj.price_limits_removal
                        };
                        detailLst.push(capObj);
                        break;

                    case 8:
                        // Right Issue
                        capObj = {
                            label: currentLangObj.approvalStatus,
                            value: corporateActObj.approval_status
                        };
                        detailLst.push(capObj);
                        capObj = {
                            label: currentLangObj.issuePrice,
                            value: corporateActObj.issue_price
                        };
                        detailLst.push(capObj);

                        capObj = {
                            label: currentLangObj.factor,
                            value: corporateActObj.split_factor
                        };
                        detailLst.push(capObj);
                        capObj = {
                            label: currentLangObj.priceLimitRemoval,
                            value: corporateActObj.price_limits_removal
                        };
                        detailLst.push(capObj);
                        break;

                    case 10:
                        // Convert bonds to shares

                        capObj = {
                            label: currentLangObj.approvalStatus,
                            value: corporateActObj.approval_status
                        };
                        detailLst.push(capObj);

                        capObj = {
                            label: currentLangObj.splitFactor,
                            value: corporateActObj.split_factor
                        };
                        detailLst.push(capObj);
                        capObj = {
                            label: currentLangObj.priceLimitRemoval,
                            value: corporateActObj.price_limits_removal
                        };
                        detailLst.push(capObj);
                        break;

                    case 11:
                        // Switch shares
                        capObj = {
                            label: currentLangObj.approvalStatus,
                            value: corporateActObj.approval_status
                        };
                        detailLst.push(capObj);
                        capObj = {
                            label: currentLangObj.factor,
                            value: corporateActObj.split_factor
                        };
                        detailLst.push(capObj);
                        capObj = {
                            label: currentLangObj.priceLimitRemoval,
                            value: corporateActObj.price_limits_removal
                        };
                        detailLst.push(capObj);
                        break;

                    case 12:
                        // Non-Public Offering
                        capObj = {
                            label: currentLangObj.splitFactor,
                            value: corporateActObj.split_factor
                        };
                        detailLst.push(capObj);
                        capObj = {
                            label: currentLangObj.approvalStatus,
                            value: corporateActObj.approval_status
                        };
                        detailLst.push(capObj);
                        capObj = {
                            label: currentLangObj.priceLimitRemoval,
                            value: corporateActObj.price_limits_removal
                        };
                        detailLst.push(capObj);
                        break;

                    case 13:
                        // Employee Stock Options
                        capObj = {
                            label: currentLangObj.approvalStatus,
                            value: corporateActObj.approval_status
                        };
                        detailLst.push(capObj);
                        capObj = {
                            label: currentLangObj.splitFactor,
                            value: corporateActObj.split_factor
                        };
                        detailLst.push(capObj);
                        capObj = {
                            label: currentLangObj.priceLimitRemoval,
                            value: corporateActObj.price_limits_removal
                        };
                        detailLst.push(capObj);
                        break;

                    case 14:
                        // Par Value Increase
                        capObj = {
                            label: currentLangObj.approvalStatus,
                            value: corporateActObj.approval_status
                        };
                        detailLst.push(capObj);
                        capObj = {
                            label: currentLangObj.splitFactor,
                            value: corporateActObj.split_factor
                        };
                        detailLst.push(capObj);
                        capObj = {
                            label: currentLangObj.priceLimitRemoval,
                            value: corporateActObj.price_limits_removal
                        };
                        detailLst.push(capObj);
                        break;

                    case 15:
                        // Reducing Shares
                        capObj = {
                            label: currentLangObj.approvalStatus,
                            value: corporateActObj.approval_status
                        };
                        detailLst.push(capObj);
                        capObj = {
                            label: currentLangObj.factor,
                            value: corporateActObj.split_factor
                        };
                        detailLst.push(capObj);

                        break;

                    case 16:
                        // Par Value Reduction
                        capObj = {
                            label: currentLangObj.approvalStatus,
                            value: corporateActObj.approval_status
                        };
                        detailLst.push(capObj);
                        capObj = {
                            label: currentLangObj.splitFactor,
                            value: corporateActObj.split_factor
                        };
                        detailLst.push(capObj);
                        capObj = {
                            label: currentLangObj.priceLimitRemoval,
                            value: corporateActObj.price_limits_removal
                        };
                        detailLst.push(capObj);
                        break;

                    case 17:
                        // Treasury Stock Cancellation
                        capObj = {
                            label: currentLangObj.approvalStatus,
                            value: corporateActObj.approval_status
                        };
                        detailLst.push(capObj);
                        capObj = {
                            label: currentLangObj.factor,
                            value: corporateActObj.split_factor
                        };
                        detailLst.push(capObj);
                        capObj = {
                            label: currentLangObj.priceLimitRemoval,
                            value: corporateActObj.price_limits_removal
                        };
                        detailLst.push(capObj);
                        break;

                    case 18:
                        // Distribute Treasury Stocks as Bonus Shares
                        capObj = {
                            label: currentLangObj.numbTrsryStks,
                            value: corporateActObj.number_treasury_stocks
                        };
                        detailLst.push(capObj);
                        capObj = {
                            label: currentLangObj.approvalStatus,
                            value: corporateActObj.approval_status
                        };
                        detailLst.push(capObj);
                        capObj = {
                            label: currentLangObj.splitFactor,
                            value: corporateActObj.split_factor
                        };
                        detailLst.push(capObj);
                        capObj = {
                            label: currentLangObj.priceLimitRemoval,
                            value: corporateActObj.price_limits_removal
                        };
                        detailLst.push(capObj);
                        break;

                    case 36:
                        // Partial Call
                        capObj = {
                            label: currentLangObj.approvalStatus,
                            value: corporateActObj.approval_status
                        };
                        detailLst.push(capObj);
                        capObj = {
                            label: currentLangObj.factor,
                            value: corporateActObj.split_factor
                        };
                        detailLst.push(capObj);
                        capObj = {
                            label: currentLangObj.oldPaid,
                            value: corporateActObj.old_paid
                        };
                        detailLst.push(capObj);
                        capObj = {
                            label: currentLangObj.newPaid,
                            value: corporateActObj.new_paid
                        };
                        detailLst.push(capObj);
                        capObj = {
                            label: currentLangObj.cashPaid,
                            value: corporateActObj.cash_paid
                        };
                        detailLst.push(capObj);
                        capObj = {
                            label: currentLangObj.priceLimitRemoval,
                            value: corporateActObj.price_limits_removal
                        };
                        detailLst.push(capObj);
                        break;

                    default:
                        utils['default'].logger.logError('Unknown action Type in processing capital-action-list-js Type : ' + corporateActObj.typeId);
                        break;
                }
                that.set('isDtlTbl', detailLst.length > 0);
                that.set('isShowPopup', true);
            }
        },

        closePopup: function closePopup() {
            var that = this;
            that.set('isShowPopup', false);
            that.set('detailList', []);
        },
        actions: {
            corpActDropdownFrom: function corpActDropdownFrom(option) {
                var that = this;
                this.set('actTypeId', option.id);
                this.set('searchParams', null);
                that.onPrepareData();
            },
            pageChanged: function pageChanged(current) {
                var that = this;
                var pageSize = this.get('pageSize');
                var searchPara = {
                    pageSize: pageSize,
                    pageIdx: current - 1,
                    actType: this.get('actTypeId'),
                    custView: this.get('customView'),
                    idList: this.get('typeIds')
                };
                this.set('current', current);
                this.set('searchParams', searchPara);
                that.onPrepareData();
            },
            loadPopup: function loadPopup(actionId) {
                var that = this;
                this.set('detailList', []);
                that.sendCapitalActinDetalRequest(actionId);
            },
            closePopup: function closePopup() {
                this.closePopup();
            }
        }

    });

});