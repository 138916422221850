define('ir-app/models/price/price-business-entities/company-profile', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        // Basic Information
        logo: '', // Logo
        sym: '', // Symbol
        exg: '', // exchange
        compName: '', // Company Name
        des: '', // Description
        indGrp: '', // Industry Group
        subInd: '', // Sub Industry
        isin: '', // ISIN Code
        estbOn: '', // Established On
        stkLstOn: '', // Listed On
        outShr: '', // Outstanding Share
        authCap: '', // Authorized Capital
        noOfStocks: '', // Number of Stocks(only for Indices)
        trdName: '', // Trade Name
        currency: '', // Currency
        country: '', // Country
        bbgid: '', // BBGI
        sector: '', // Sector
        sectorDes: '', // Sector Description
        auditor: '', // Auditor
        compID: '', // Comppany ID
        freeFltShr: '', // Free float shares
        outStdShr: '', // Outstanding shares
        parVal: '', // Par Value
        linkedIn: '', // linkedIn URL
        facebook: '', // Facebook URL
        twitter: '', // twitter URL
        youtube: '', // Youtube URL
        templateId: '', // Template ID
        finYearStart: '', // Financial Year Start Month
        tickerSerial: '', // Ticker serial
        bvps: '', // book value per share
        ebitda: '', // EBITDA ratio
        cashDivTTM: '', // total cash dividend for TTM
        didPerShr: '', // dividend per share for TTM
        bonusShrs: '', // total bonus shares for TTM
        payoutRatio: '', // payout Ratio TTM
        contactPersonId: '', // contact person Id
        listingStatus: '', //CompanyListingStatus
        displaySymbol: '',

        paidCap: (function () {
            var parVal, outShr, val;

            outShr = this.get('outStdShr');
            parVal = this.get('parVal');
            if (this.get('currency') === 'KWD') {
                val = outShr * parVal / 1000; // KSE parVal comes in fills
            } else {
                    val = outShr * parVal;
                }

            return isNaN(val) ? 0 : val;
        }).property('outStdShr', 'parVal'), // Paid Capital

        divMillion: (function () {
            // total cash dividend for TTM in Million
            var divMil = this.get('cashDivTTM');
            return divMil / 1000000;
        }).property('cashDivTTM'),

        finYearEnd: (function () {
            return this.get('finYearStart') - 1;
        }).property('finYearStart'),

        // Contact Information
        addr: '', // Address
        phn: '', // Tel
        fax: '', // Fax
        email: '', // Email
        web: '', // Website

        compManagement: null,
        compOwners: null,
        compSubsidiaries: null,
        compKeyOfficers: null,
        creditRatings: null,
        analystContacts: null,
        compFunds: null,
        compIndividuals: null,
        compShareHolderOwners: null,
        ownershipLimits: null,

        init: function init() {
            this._super();
            this.set('compManagement', Ember['default'].A());
            this.set('compOwners', Ember['default'].A());
            this.set('compSubsidiaries', Ember['default'].A());
            this.set('compKeyOfficers', Ember['default'].A());
            this.set('creditRatings', Ember['default'].A());
            this.set('analystContacts', Ember['default'].A());
            this.set('compFunds', Ember['default'].A());
            this.set('compIndividuals', Ember['default'].A());
            this.set('compShareHolderOwners', Ember['default'].A());
            this.set('ownershipLimits', Ember['default'].A());
            this.set('auditor', Ember['default'].A());
        },

        setData: function setData(companyProfileData) {
            var that = this;

            Ember['default'].$.each(companyProfileData, function (key, value) {
                that.set(key, value);
            });
        }
    });

});