define('ir-app/controllers/ir-widgets/watchlist-widget/stock-screener', ['exports', 'ember', 'ir-app/controllers/base-array-controller', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/models/shared/language/language-data-store', 'ir-app/utils/utils'], function (exports, Ember, BaseArrayController, priceService, sharedService, LanguageDataStore, Utils) {

    'use strict';

    /**
     * Created by lakmaln on 8/30/2016.
     */

    exports['default'] = BaseArrayController['default'].extend({

        exchangeList: [{ exg: 'TDWL' }, { exg: 'DFM' }, { exg: 'KSE' }, { exg: 'BSE' }],
        currencyList: [{ cur: 'USD' }, { cur: 'SAR' }, { cur: 'AED' }],
        industryList: [],

        filterExchange: null,
        filterCompany: null,
        filterIndustry: null,
        filterCurrency: null,
        filterMktCapMin: null,
        filterMktCapMax: null,
        filterPriceMin: null,
        filterPriceMax: null,
        filterDYldMin: null,
        filterDYldMax: null,
        filterPEMin: null,
        filterPEMax: null,
        filterPBMin: null,
        filterPBMax: null,

        exchange: null,
        company: null,
        industry: null,
        currency: null,
        mktCapMin: null,
        mktCapMax: null,
        priceMin: null,
        priceMax: null,
        dYldMin: null,
        dYldMax: null,
        peMin: null,
        peMax: null,
        pbMin: null,
        pbMax: null,

        onPrepareData: function onPrepareData() {
            // this.startLoadingProgress();
            this.set('exchange', this.get('exg'));
            this.set('filterExchange', this.get('exg'));
            var currencyList = this.get('currencyList');
            var allItem = [{ cur: LanguageDataStore['default'].getLanguageObj().lang.labels.all }];
            this.set('currencyList', allItem.concat(currencyList));
            this.set('filterCurrency', LanguageDataStore['default'].getLanguageObj().lang.labels.all);

            this.sendServerRequests();
        },

        sendServerRequests: function sendServerRequests() {
            var that = this;
            priceService['default'].downloadParamsSymbolMasterForExchange({
                exchange: that.get('exchange'),
                language: sharedService['default'].userSettings.currentLanguage,
                successFn: function successFn() {
                    that.onSymbolDataSuccess();
                },
                errorFn: function errorFn() {
                    that.onError();
                }
            });

            priceService['default'].addFullMarketSymbolRequest(that.get('exchange'));
        },

        //onUnloadWidget: function () {
        //    this.set('filterCompany', null);
        //    this.set('filterIndustry', null);
        //    this.set('masterContent', Ember.A());
        //},

        onClearData: function onClearData() {
            priceService['default'].removeFullMarketSymbolRequest(this.get('exchange'));
        },

        loadWLContent: function loadWLContent() {
            var exchange = this.get('exchange');
            var store = priceService['default'].stockDS.get('stockMapByExg');

            if (!store[exchange]) {
                store[exchange] = Ember['default'].A();
                priceService['default'].stockDS.set('stockMapByExg', store);
            }

            this.set('model', store[exchange]);
        },

        onSymbolDataSuccess: function onSymbolDataSuccess() {
            var that = this;
            priceService['default'].downloadParamsExchangeMetaData({
                exchange: that.get('exchange'),
                language: sharedService['default'].userSettings.currentLanguage,
                successFn: function successFn() {
                    that.onSuccess();
                },
                errorFn: function errorFn() {
                    that.onError();
                }
            });
        },

        onSuccess: function onSuccess() {
            this.loadWLContent();
            this.loadIndustryList();
        },

        onError: function onError() {},

        filteredContent: (function () {
            var that = this;
            var companyFilter = that.get('company');
            var industryFilter = that.get('industry');
            var currencyFilter = that.get('currency');
            var mktCapMinFilter = that.get('mktCapMin');
            var mktCapMaxFilter = that.get('mktCapMax');
            var priceMinFilter = that.get('priceMin');
            var priceMaxFilter = that.get('priceMax');
            var dYldMinFilter = that.get('dYldMin');
            var dYldMaxFilter = that.get('dYldMax');
            var peMinFilter = that.get('peMin');
            var peMaxFilter = that.get('peMax');
            var pbMinFilter = that.get('pbMin');
            var pbMaxFilter = that.get('pbMax');

            var filteredStocks = that.get('arrangedContent').filter((function (that) {
                //eslint-disable-line
                return function (stock) {

                    if (companyFilter || industryFilter || currencyFilter || mktCapMinFilter || mktCapMaxFilter || priceMinFilter || priceMaxFilter || dYldMinFilter || dYldMaxFilter || peMinFilter || peMaxFilter || pbMinFilter || pbMaxFilter) {
                        return that.checkFilterMatch(stock, companyFilter, industryFilter, currencyFilter, mktCapMinFilter, mktCapMaxFilter, priceMinFilter, priceMaxFilter, dYldMinFilter, dYldMaxFilter, peMinFilter, peMaxFilter, pbMinFilter, pbMaxFilter);
                    } else {
                        return true;
                    }
                };
            })(that));

            return filteredStocks;
        }).property('arrangedContent', 'exchange', 'company', 'industry', 'currency', 'mktCapMin', 'mktCapMax', 'priceMin', 'priceMax', 'dYldMin', 'dYldMax', 'peMin', 'peMax', 'pbMin', 'pbMax'),

        checkFilterMatch: function checkFilterMatch(stock, companyFilter, industryFilter, currencyFilter, mktCapMinFilter, mktCapMaxFilter, priceMinFilter, priceMaxFilter, dYldMinFilter, dYldMaxFilter, peMinFilter, peMaxFilter, pbMinFilter, pbMaxFilter) {

            var isMatchedCompanyFilter = !companyFilter;
            var isMatchedIndustryFilter = !industryFilter;
            var isMatchedCurrencyFilter = !currencyFilter;
            var isMatchedMktCapMinFilter = !mktCapMinFilter;
            var isMatchedMktCapMaxFilter = !mktCapMaxFilter;
            var isMatchedPriceMinFilter = !priceMinFilter;
            var isMatchedPriceMaxFilter = !priceMaxFilter;
            var isMatchedDYldMinFilter = !dYldMinFilter;
            var isMatchedDYldMaxFilter = !dYldMaxFilter;
            var isMatchedPEMinFilter = !peMinFilter;
            var isMatchedPEMaxFilter = !peMaxFilter;
            var isMatchedPBMinFilter = !pbMinFilter;
            var isMatchedPBMaxFilter = !pbMaxFilter;

            if (!isMatchedCompanyFilter) {
                if (this.filterWord(stock['lDes'].toString(), companyFilter)) {
                    isMatchedCompanyFilter = true;
                }
            }
            if (!isMatchedIndustryFilter) {
                if (industryFilter === 'ALL') {
                    isMatchedIndustryFilter = true;
                } else {
                    isMatchedIndustryFilter = stock.get('sec') === industryFilter;
                }
            }
            if (!isMatchedCurrencyFilter) {
                if (currencyFilter === LanguageDataStore['default'].getLanguageObj().lang.labels.all) {
                    isMatchedCurrencyFilter = true;
                } else {
                    isMatchedCurrencyFilter = stock.get('cur') === currencyFilter;
                }
            }

            if (!isMatchedMktCapMinFilter) {
                isMatchedMktCapMinFilter = stock.get('mktCap') >= mktCapMinFilter;
            }
            if (!isMatchedMktCapMaxFilter) {
                isMatchedMktCapMaxFilter = stock.get('mktCap') <= mktCapMaxFilter;
            }

            if (!isMatchedPriceMinFilter) {
                isMatchedPriceMinFilter = stock.get('ltp') >= priceMinFilter;
            }
            if (!isMatchedPriceMaxFilter) {
                isMatchedPriceMaxFilter = stock.get('ltp') <= priceMaxFilter;
            }

            if (!isMatchedDYldMinFilter) {
                isMatchedDYldMinFilter = stock.get('dDYield') >= dYldMinFilter;
            }
            if (!isMatchedDYldMaxFilter) {
                isMatchedDYldMaxFilter = stock.get('dDYield') <= dYldMaxFilter;
            }

            if (!isMatchedPEMinFilter) {
                isMatchedPEMinFilter = stock.get('per') >= peMinFilter;
            }
            if (!isMatchedPEMaxFilter) {
                isMatchedPEMaxFilter = stock.get('per') <= peMaxFilter;
            }

            if (!isMatchedPBMinFilter) {
                isMatchedPBMinFilter = stock.get('pbr') >= pbMinFilter;
            }
            if (!isMatchedPBMaxFilter) {
                isMatchedPBMaxFilter = stock.get('pbr') <= pbMaxFilter;
            }

            return isMatchedCompanyFilter && isMatchedIndustryFilter && isMatchedCurrencyFilter && isMatchedMktCapMinFilter && isMatchedMktCapMaxFilter && isMatchedPriceMinFilter && isMatchedPriceMaxFilter && isMatchedDYldMinFilter && isMatchedDYldMaxFilter && isMatchedPEMinFilter && isMatchedPEMaxFilter && isMatchedPBMinFilter && isMatchedPBMaxFilter;
        },

        filterWord: function filterWord(filed, filterText) {
            var array = filed.split(' ');
            var stringFiled;
            for (var j = 0; j < array.length; j++) {
                stringFiled = array[j];
                if (stringFiled.toString().slice(0, filterText.length).toLowerCase() === filterText.toLowerCase()) {
                    return true;
                }
            }
            return false;
        },

        loadIndustryList: function loadIndustryList() {
            var indList = priceService['default'].sectorDS.getSectorCollectionByExchange(this.get('exchange'));
            var allItem = [{ des: LanguageDataStore['default'].getLanguageObj().lang.labels.all, sec: 'ALL' }];
            var fullList = allItem.concat(indList);
            this.set('industryList', fullList);
            this.set('filterIndustry', 'ALL');
        },

        actions: {
            applyFilters: function applyFilters() {
                var that = this;

                if (that.get('exchange') !== that.get('filterExchange')) {
                    // Exchange changed.

                    this.set('model', Ember['default'].A());
                    this.set('exchange', this.get('filterExchange'));

                    this.set('filterCompany', null);
                    that.set('filterIndustry', 'ALL');
                    that.set('filterCurrency', LanguageDataStore['default'].getLanguageObj().lang.labels.all);
                    // TODO: Reset other filters with exchange change if required.

                    that.sendServerRequests();
                }

                that.set('company', that.get('filterCompany'));
                that.set('industry', that.get('filterIndustry'));
                that.set('currency', that.get('filterCurrency'));
                that.set('mktCapMin', that.get('filterMktCapMin'));
                that.set('mktCapMax', that.get('filterMktCapMax'));
                that.set('priceMin', that.get('filterPriceMin'));
                that.set('priceMax', that.get('filterPriceMax'));
                that.set('dYldMin', that.get('filterDYldMin'));
                that.set('dYldMax', that.get('filterDYldMax'));
                that.set('peMin', that.get('filterPEMin'));
                that.set('peMax', that.get('filterPEMax'));
                that.set('pbMin', that.get('filterPBMin'));
                that.set('pbMax', that.get('filterPBMax'));
            },

            resetFilters: function resetFilters() {
                var that = this;

                that.set('filterCompany', null);
                that.set('filterIndustry', 'ALL');
                that.set('filterCurrency', LanguageDataStore['default'].getLanguageObj().lang.labels.all);
                that.set('filterMktCapMin', null);
                that.set('filterMktCapMax', null);
                that.set('filterPriceMin', null);
                that.set('filterPriceMax', null);
                that.set('filterDYldMin', null);
                that.set('filterDYldMax', null);
                that.set('filterPEMin', null);
                that.set('filterPEMax', null);
                that.set('filterPBMin', null);
                that.set('filterPBMax', null);

                that.set('company', null);
                that.set('industry', 'ALL');
                that.set('currency', LanguageDataStore['default'].getLanguageObj().lang.labels.all);
                that.set('mktCapMin', null);
                that.set('mktCapMax', null);
                that.set('priceMin', null);
                that.set('priceMax', null);
                that.set('dYldMin', null);
                that.set('dYldMax', null);
                that.set('peMin', null);
                that.set('peMax', null);
                that.set('pbMin', null);
                that.set('pbMax', null);
            }
        }
    });

    Ember['default'].Handlebars.helper('getIndustry', function (exchange, secCode) {
        if (!Utils['default'].validators.isAvailable(exchange) || !Utils['default'].validators.isAvailable(secCode)) {
            return '';
        }
        var sectorObj = priceService['default'].sectorDS.getSector(exchange, secCode);
        return sectorObj.des;
    });

});