define('ir-app/components/message-box', ['exports', 'ember', 'ir-app/templates/components/message-box', 'ir-app/models/shared/language/language-data-store'], function (exports, Ember, layout, LanguageDataStore) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        enabled: false,
        messageBoxClass: '',

        actions: {
            showMessageBox: function showMessageBox(messageType, message, title, buttons, listItems) {
                var languageObj = LanguageDataStore['default'].getLanguageObj();
                var that = this;

                this.set('buttons', []);
                this.set('messageBoxClass', 'message-box-icon-class-' + messageType);
                this.set('message', message);
                this.set('title', title);
                this.set('listItems', listItems);

                Ember['default'].$.each(buttons, function (index, indexObject) {
                    var button = {
                        displayText: languageObj.lang.labels[indexObject.type],
                        btnAction: indexObject.btnAction
                    };

                    that.get('buttons').push(button);
                });

                this.set('enabled', true);
            },

            closeMessageBox: function closeMessageBox() {
                this.set('enabled', false);
            },

            onButtonClick: function onButtonClick(index) {
                this.set('enabled', false);

                var buttons = this.get('buttons');

                if (Ember['default'].$.isFunction(buttons[index].btnAction)) {
                    buttons[index].btnAction();
                }
            }
        },

        layout: layout['default']
    });

});