define('ir-app/models/decypha/decypha-service', ['exports', 'ember', 'ir-app/models/decypha/decypha-protocols/decypha-request-handler'], function (exports, Ember, DecyphaRequestHandler) {

    'use strict';

    /**
     * Created by udaras on 10/13/2016.
     */
    exports['default'] = Ember['default'].Object.extend({

        sendNewsRequest: function sendNewsRequest(params) {
            DecyphaRequestHandler['default'].loadNewsSearchData(params);
        },
        sendCompanyScreenerRequest: function sendCompanyScreenerRequest(params) {
            DecyphaRequestHandler['default'].loadCompanyScreenerData(params);
        }
    }).create();

});