define('ir-app/controllers/ir-widgets/financial-dividend-widgets/dividend-topbar', ['exports', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/models/chart/chart-constants', 'ir-app/utils/utils'], function (exports, BaseController, priceService, chartConstants, utils) {

    'use strict';

    exports['default'] = BaseController['default'].extend({
        onPrepareData: function onPrepareData() {

            var stock = priceService['default'].stockDS.getStock(this.get('exg'), this.get('sym'));

            this.set("topBarData", stock);

            this._downloadHistory();
            var ohlcSeries = priceService['default'].ohlcDS.getOHLCSeries(this.get('exg'), this.get('sym'), chartConstants['default'].ChartCategory.History);
            var dStart = new Date(new Date().getFullYear() - 5, 0, 1);

            var annualData = ohlcSeries.queryPointsForRange(dStart.getTime(), new Date().getTime());

            this.set("dataStart", utils['default'].formatters.formatDateToDisplayOnlyDate(annualData[0].DT));
        },
        _downloadHistory: function _downloadHistory() {
            var that = this;
            priceService['default'].downloadHistoryOHLCData({
                exchange: this.get('exg'),
                symbol: this.get('sym'),
                begin: undefined,

                reqSuccessFn: function reqSuccessFn() {},
                reqFailureFn: function reqFailureFn() {
                    if (that.onErrorFn && Ember.$.isFunction(that.onErrorFn)) {
                        that.onErrorFn();
                    }
                    utils['default'].logger.logDebug('Data Unavailable For History');
                }
            });
        }
    });

});