define('ir-app/controllers/ir-widgets/financial-widgets/compact-client-financials', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/utils/utils', 'ir-app/models/shared/shared-service', 'ir-app/models/price/price-business-entities/fundamentals/fundamental-constants', 'ir-app/models/price/price-business-entities/fundamentals/fundaental-common-functions', 'ir-app/models/price/price-constants', 'ir-app/models/price/price-business-entities/fundamentals/fundamental-data-object', 'ir-app/components/stk-specific-components/financial-chart'], function (exports, Ember, BaseController, priceService, utils, sharedService, FundamentalConstants, FundamentalCommonFunctions, priceConstants, fundamental_data_object, FinChart) {

  'use strict';

  exports['default'] = BaseController['default'].extend({
    viewDropdownListView: [],
    viewDropdownListFromDate: [],
    viewDropdownListToDate: [],
    viewDropdownListPeriods: [],
    keyListDate: undefined,
    minusValArray: [],

    styleObject: undefined,
    dataObject: undefined,
    dataKeySet: undefined,

    displayDataObject: {},
    displayStyleObject: Ember['default'].A([]),

    breakDown: FundamentalConstants['default'].BreakDown.CollapsedView,
    isExpand: 2,
    isCalendar: 1,

    currentlyDataDisplayingFilterFromDate: undefined,
    currentlyDataDisplayingFilterToDate: undefined,
    currentlyDataDisplayingIsCalendar: 1,

    fromDate: undefined,
    toDate: undefined,
    today: undefined,

    statementType: 'IS',
    filterFromDate: undefined,
    filterToDate: undefined,
    defSelectFrom: undefined,
    defSelectTo: undefined,
    defSelectView: undefined,
    finYearEndMonth: '',

    displayDataObjectIS: {},
    displayDataObjectBS: {},
    displayDataObjectCF: {},
    displayDataObjectFR: {},

    reRender: false,
    isRatios: false,
    isSnapshot: undefined,
    isExpandButtonAvailable: true,
    dataSet1: Ember['default'].A(),
    titleObj: undefined,
    numberOfTabs: undefined,
    DateRange: 3,
    pdfValidator: false,
    lang: '',
    currentView: '',
    widgetKey: '',
    chartView: false,
    customizedBarLabels: 'disable',
    customizedDecimalPlaces: 2,
    customizedPointWidth: 20,
    customizedChartHeight: 200,

    dataObjectAdditional: undefined, // to be used for DOC_ID filling for quarterly view type

    onPrepareData: function onPrepareData() {

      if (!utils['default'].validators.isAvailable(this.get('isCalendar'))) {
        // isCalender is empty when zero is set form the the layout config. So re-assign it
        this.set('isCalendar', 0);
      }
      var keyListView = this.get('keyListView');
      if (keyListView[keyListView.length - 1].view === 1) {
        // setting the currentViewType and viewType according to the keyListView array
        this.set('viewType', 'A');
        this.set('currentlyDataDisplayingViewType', 'A');
      } else if (keyListView[keyListView.length - 1].view === 2) {
        this.set('viewType', 'Q');
        this.set('currentlyDataDisplayingViewType', 'Q');
      } else if (keyListView[keyListView.length - 1].view === 3) {
        this.set('viewType', 'C');
        this.set('currentlyDataDisplayingViewType', 'C');
      } else if (keyListView[keyListView.length - 1].view === 4) {
        this.set('viewType', 'T');
        this.set('currentlyDataDisplayingViewType', 'T');
      }

      if (this.get('enableDefaultDetailedView')) {
        this.set('breakDown', FundamentalConstants['default'].BreakDown.ExpandView);
        this.set('isExpand', 1);
      }
      this.set('lang', sharedService['default'].userSettings.currentLanguage);
      this.set('numberOfTabs', this._calculateTabs());

      if (this.get('activeRatios') === 'active') {
        // used to initially hide summary and expand radio btns in  ratio tab
        this.set('isExpandButtonAvailable', false);
      }

      var exchange = this.get('exg');
      var symbol = this.get('sym');
      var view = this.get('currentlyDataDisplayingViewType');
      this.startLoadingProgress();
      var that = this;
      var success = function success() {
        that.set('compObj', priceService['default'].companyProfileDS.getStoredObject(that.get('exg'), that.get('sym'), sharedService['default'].userSettings.currentLanguage, undefined, undefined, undefined));
        that.sendDataRequest(exchange, symbol, view);
      };
      priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, success, undefined, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyBasicInfo);

      if (this.get('activeIS') === 'active') {
        this._onTabChange(1);
      } else if (this.get('activeBS') === 'active') {
        this._onTabChange(2);
      } else if (this.get('activeCF') === 'active') {
        this._onTabChange(3);
      } else if (this.get('activeRatios') === 'active') {
        this._onTabChange(4);
      }
      this.set('widgetKey', this.get('wkey'));
    },

    finYearEnd: (function () {
      // this returns the financial ending month as a string
      this.set('finYearEndMonth', utils['default'].formatters.getMonthStrFromInt(this.get('app').lang.labels, this.get('compObj').finYearStart - 1));
    }).observes('compObj.finYearStart'),

    onClearData: function onClearData() {
      priceService['default'].removeSymbolRequest(this.get('exg'), this.get('sym'));
    },

    _calculateTabs: function _calculateTabs() {
      var IS = this.get('includeIS') ? 1 : 0;
      var BS = this.get('includeBS') ? 1 : 0;
      var CF = this.get('includeCF') ? 1 : 0;
      var Ratios = this.get('includeRatios') ? 1 : 0;

      switch (IS + BS + CF + Ratios) {
        case 1:
          return 'one';
        case 2:
          return 'two';
        case 3:
          return 'three';
        case 4:
          return 'four';
        default:
          return;
      }
    },

    _defaultSelectionMaker: function _defaultSelectionMaker() {
      this._setDropdownValues();
      var that = this;
      var keys = this.get('keyListView'),
          array = [],
          temp,
          arrObj = [];
      Ember['default'].$.each(keys, function (index, key) {
        temp = that.get('app').lang.labels[key.des];
        arrObj.view = key.view;
        arrObj.des = temp;
        array.push(arrObj);
        arrObj = [];
      });

      var keyListDate = this.get('keyListDate');
      if (!keyListDate) {
        this.set('viewDropdownListFromDate', []);
        this.set('viewDropdownListToDate', []);
        this.set('defSelectFrom', undefined);
        this.set('defSelectTo', undefined);
        this.set('filterFromDate', undefined);
        this.set('filterToDate', undefined);

        return;
      }

      var keyListView = array;
      keyListView.reverse();
      that.set('viewDropdownListView', keyListView);
      that.set('viewDropdownListFromDate', keyListDate);
      that.set('viewDropdownListToDate', keyListDate);
      that.set('defSelectFrom', keyListDate[that.get('numberOFQuartersDisplayed') - 1]);
      that.set('defSelectTo', keyListDate[0]);
      that.set('defSelectView', keyListView[1]);
      that.set('filterFromDate', keyListDate[that.get('numberOFQuartersDisplayed') - 1].view);
      that.set('filterToDate', keyListDate[0].view);
      that.set('currentlyDataDisplayingViewType', that.get('viewType'));
      that.set('currentlyDataDisplayingFilterFromDate', that.get('filterFromDate'));
      that.set('currentlyDataDisplayingFilterToDate', that.get('filterToDate'));
    },

    sendDataRequest: function sendDataRequest(exchange, symbol, view) {
      var that = this;
      var postSuccess = function postSuccess() {
        if (!that.get('reRender')) {
          that._defaultSelectionMaker();
        }
        that.dataDisplay(exchange, symbol, view);
        that.stopLoadingProgress();
        that.hideDataErrorMessage();
      };

      var error = function error() {
        that.showDataErrorMessage();
        that.stopLoadingProgress();
      };

      priceService['default'].sendClientFundamentalDataRequest(exchange, symbol, sharedService['default'].userSettings.currentLanguage, 'X', postSuccess, error, '5', this.get('isCalendar'));
    },

    /*Quarterly documents are available only for 1st quarter thus interim documents are shown in Quarterly view also*/
    _fillDocId: function _fillDocId() {
      var dataObjectAdditional = this.get('dataObjectAdditional');
      var dataObject = this.get('dataObject'),
          dataObj,
          key,
          year,
          quarter;
      var keySet = Object.keys(dataObject);
      var keySetAdditional = Object.keys(dataObjectAdditional);
      for (var i = 0; i < keySet.length; i++) {
        dataObj = dataObject[keySet[i]];
        key = keySet[i];
        year = key.split('~')[0];
        quarter = key.split('~')[1];
        for (var k = 0; k < keySetAdditional.length; k++) {
          if (year === keySetAdditional[k].split('~')[0] && (quarter === '1' && keySetAdditional[k].split('~')[1] === '1' || quarter === '12' && keySetAdditional[k].split('~')[1] === '2' || quarter === '13' && keySetAdditional[k].split('~')[1] === '3' || quarter === '14' && keySetAdditional[k].split('~')[1] === '5')) {

            if (dataObj.DOC_ID) {
              continue;
            } else {
              dataObj.DOC_ID = dataObjectAdditional[keySetAdditional[k]].DOC_ID;
            }
          }
        }
      }
    },

    dataDisplay: function dataDisplay(exchange, symbol, view) {
      var that = this;
      var filterFrom, filterTo, concatMRFR;
      filterFrom = this.get('currentlyDataDisplayingFilterFromDate');
      filterTo = this.get('currentlyDataDisplayingFilterToDate');
      var statType = this.get('statementType');

      if (this.get('statementType') === 'FR') {
        // MR and FR both are displayed on the Ratios and Growth page
        var arrayFR = priceService['default'].clientFundamentalDS.getStyleObject(exchange, symbol, 'R', view, this.get('isCalendar'));
        var arrayMR = priceService['default'].clientFundamentalDS.getStyleObject(exchange, symbol, 'MR', view, this.get('isCalendar'));

        if (arrayFR === undefined && arrayMR === undefined) {
          return;
        } else if (arrayFR !== undefined && arrayMR === undefined) {
          concatMRFR = arrayFR;
        } else if (arrayFR === undefined && arrayMR !== undefined) {
          concatMRFR = arrayMR;
        } else {
          concatMRFR = arrayFR.concat(arrayMR);
        }

        concatMRFR.sort(function (a, b) {
          return parseInt(a.groupID, 10) - parseInt(b.groupID, 10);
        });
        this.set('styleObject', concatMRFR);
        arrayFR = priceService['default'].clientFundamentalDS.getDataObject(exchange, symbol, 'R', view, this.get('isCalendar'));
        arrayMR = priceService['default'].clientFundamentalDS.getDataObject(exchange, symbol, 'MR', view, this.get('isCalendar'));
        this._mergeDataObjects(arrayFR, arrayMR);

        if (this.get('currentlyDataDisplayingViewType') === 'Q') {
          // interim DOC_ID is included to quarterly view
          arrayFR = priceService['default'].clientFundamentalDS.getDataObject(exchange, symbol, 'R', 'A', this.get('isCalendar'));
          arrayMR = priceService['default'].clientFundamentalDS.getDataObject(exchange, symbol, 'MR', 'A', this.get('isCalendar'));
          this._mergeDataObjects(arrayFR, arrayMR, true);
          this._fillDocId();
        }
      } else {
        this.set('styleObject', priceService['default'].clientFundamentalDS.getStyleObject(exchange, symbol, statType, view, this.get('isCalendar')));
        this.set('dataObject', priceService['default'].clientFundamentalDS.getDataObject(exchange, symbol, statType, view, this.get('isCalendar')));
        if (this.get('currentlyDataDisplayingViewType') === 'Q' && this.get('dataObject')) {
          // interim DOC_ID is included to quarterly view
          this.set('dataObjectAdditional', priceService['default'].clientFundamentalDS.getDataObject(exchange, symbol, statType, 'A', this.get('isCalendar')));
          this._fillDocId();
        }
      }

      if (this.get('dataObject')) {
        this.set('dataKeySet', Object.keys(this.get('dataObject')));
        this.filterDataValues(filterFrom, filterTo, this.get('dataObject'), this.get('displayDataObject'));
        this.filterStyleValue();
      }

      if (Object.keys(this.get('displayDataObject')).length <= 0) {
        this.showDataErrorMessage(); // set error message if data is not available
      }

      if (this.get('chartView')) {
        this.set('arrX', Ember['default'].A());
        this.set('arr1IS', Ember['default'].A());
        this.set('arr2IS', Ember['default'].A());
        this.set('arr3IS', Ember['default'].A());
        this.set('arr4IS', Ember['default'].A());
        this.set('arr1BS', Ember['default'].A());
        this.set('arr2BS', Ember['default'].A());
        this.set('arr1CF', Ember['default'].A());
        this.set('arr2CF', Ember['default'].A());
        this.set('dataSetArr', []);

        if (statType === 'IS') {
          that.setIncomeStatementChartData();
        } else if (statType === 'BS') {
          that.setBalanceSheetChartData();
        } else if (statType === 'CF') {
          that.setCashFlowChartData();
        } else if (statType === 'FR') {
          that.setFinancialRatioChartData();
        }
      }
    },
    setFinancialRatioChartData: function setFinancialRatioChartData() {
      var mainArr = Ember['default'].A();
      if (this.get('dataObject')) {

        this.set('displayDataObjectFR', {});
        this.filterDataValues(this.get('currentlyDataDisplayingFilterFromDate'), this.get('currentlyDataDisplayingFilterToDate'), this.get('dataObject'), this.get('displayDataObjectFR'), 'FR');

        for (var i = 0; i < this.get('displayStyleObject').length; i++) {
          var array = Ember['default'].A();
          if (this.get('displayStyleObject')[i].chartOnFactSheet) {
            this.getChartDataSetFR(this.get('displayStyleObject')[i].filedID, array, this.get('displayDataObjectFR'), this.get('displayStyleObject')[i].filedID);
            mainArr.push(array);
          }
        }
        this.set('dataSetArr', mainArr);
        this.set('xDataSet', this.get('arrXFR'));
      }
    },
    setIncomeStatementChartData: function setIncomeStatementChartData() {
      var mainArr = Ember['default'].A();
      if (this.get('dataObject')) {

        this.set('displayDataObjectIS', {});
        this.filterDataValues(this.get('currentlyDataDisplayingFilterFromDate'), this.get('currentlyDataDisplayingFilterToDate'), this.get('dataObject'), this.get('displayDataObjectIS'), 'IS');

        for (var i = 0; i < this.get('displayStyleObject').length; i++) {
          var array = Ember['default'].A();
          if (this.get('displayStyleObject')[i].chartOnFactSheet) {
            this.getChartDataSetIS(this.get('displayStyleObject')[i].filedID, array, this.get('displayDataObjectIS'), this.get('displayStyleObject')[i].filedID);
            mainArr.push(array);
          }
        }

        this.set('dataSetArr', mainArr);
        this.set('xDataSet', this.get('arrXIS'));
      }
    },

    setBalanceSheetChartData: function setBalanceSheetChartData() {
      var mainArr = Ember['default'].A();
      if (this.get('dataObject')) {

        this.set('displayDataObjectBS', {});
        this.filterDataValues(this.get('currentlyDataDisplayingFilterFromDate'), this.get('currentlyDataDisplayingFilterToDate'), this.get('dataObject'), this.get('displayDataObjectBS'), 'BS');

        for (var i = 0; i < this.get('displayStyleObject').length; i++) {
          var array = Ember['default'].A();
          if (this.get('displayStyleObject')[i].chartOnFactSheet) {
            this.getChartDataSetBS(this.get('displayStyleObject')[i].filedID, array, this.get('displayDataObjectBS'), this.get('displayStyleObject')[i].filedID);
            mainArr.push(array);
          }
        }

        this.set('dataSetArr', mainArr);
        this.set('xDataSet', this.get('arrXBS'));
      }
    },
    setCashFlowChartData: function setCashFlowChartData() {
      var mainArr = Ember['default'].A();
      if (this.get('dataObject')) {

        this.set('displayDataObjectCF', {});
        this.filterDataValues(this.get('currentlyDataDisplayingFilterFromDate'), this.get('currentlyDataDisplayingFilterToDate'), this.get('dataObject'), this.get('displayDataObjectCF'), 'CF');

        for (var i = 0; i < this.get('displayStyleObject').length; i++) {
          var array = Ember['default'].A();
          if (this.get('displayStyleObject')[i].chartOnFactSheet) {
            this.getChartDataSetCF(this.get('displayStyleObject')[i].filedID, array, this.get('displayDataObjectCF'), this.get('displayStyleObject')[i].filedID);
            mainArr.push(array);
          }
        }

        this.set('dataSetArr', mainArr);
        this.set('xDataSet', this.get('arrXCF'));
      }
    },

    getChartDataSetFR: function getChartDataSetFR(key, array, dataObj, label) {
      this.set('arrXFR', Ember['default'].A());
      this.set(array, Ember['default'].A());
      array.pushObject(this.get('app').lang.labels[key] ? this.get('app').lang.labels[key] : label);
      this.get('arrXFR').pushObject('x');

      for (var i = 0; i < this.get('dataKeySetFR.length'); i++) {
        var element = dataObj[this.get('dataKeySetFR')[i]][key];
        array.pushObject(element);
        this.get('arrXFR').pushObject(this.getDisplayLabel(this.get('displayDataObjectFR'), this.get('dataKeySetFR')[i], this.get('currentlyDataDisplayingViewType'), this.get('currentlyDataDisplayingIsCalendar')));
      }
    },

    getChartDataSetIS: function getChartDataSetIS(key, array, dataObj, label) {
      this.set('arrXIS', Ember['default'].A());
      this.set(array, Ember['default'].A());
      array.pushObject(this.get('app').lang.labels[key] ? this.get('app').lang.labels[key] : label);
      this.get('arrXIS').pushObject('x');

      for (var i = 0; i < this.get('dataKeySetIS.length'); i++) {
        var element = dataObj[this.get('dataKeySetIS')[i]][key];
        if (this.get("minusValArray").includes(key)) {
          element = Math.abs(element);
        }
        array.pushObject(element);
        this.get('arrXIS').pushObject(this.getDisplayLabel(this.get('displayDataObjectIS'), this.get('dataKeySetIS')[i], this.get('currentlyDataDisplayingViewType'), this.get('currentlyDataDisplayingIsCalendar')));
      }
    },

    getChartDataSetBS: function getChartDataSetBS(key, array, dataObj, label) {
      this.set('arrXBS', Ember['default'].A());
      this.set(array, Ember['default'].A());
      array.pushObject(this.get('app').lang.labels[key] ? this.get('app').lang.labels[key] : label);
      this.get('arrXBS').pushObject('x');

      for (var i = 0; i < this.get('dataKeySetBS.length'); i++) {
        var element = dataObj[this.get('dataKeySetBS')[i]][key];
        if (this.get("minusValArray").includes(key)) {
          element = Math.abs(element);
        }
        array.pushObject(element);
        this.get('arrXBS').pushObject(this.getDisplayLabel(this.get('displayDataObjectBS'), this.get('dataKeySetBS')[i], this.get('currentlyDataDisplayingViewType'), this.get('currentlyDataDisplayingIsCalendar')));
      }
    },

    getChartDataSetCF: function getChartDataSetCF(key, array, dataObj, label) {
      this.set('arrXCF', Ember['default'].A());
      this.set(array, Ember['default'].A());
      array.pushObject(this.get('app').lang.labels[key] ? this.get('app').lang.labels[key] : label);
      this.get('arrXCF').pushObject('x');

      for (var i = 0; i < this.get('dataKeySetCF.length'); i++) {
        var element = dataObj[this.get('dataKeySetCF')[i]][key];
        if (this.get("minusValArray").includes(key)) {
          element = Math.abs(element);
        }
        array.pushObject(element);
        this.get('arrXCF').pushObject(this.getDisplayLabel(this.get('displayDataObjectCF'), this.get('dataKeySetCF')[i], this.get('currentlyDataDisplayingViewType'), this.get('currentlyDataDisplayingIsCalendar')));
      }
    },

    _mergeDataObjects: function _mergeDataObjects(arrayFR, arrayMR, setToAdditionalObj) {
      // this function merge both the FR data object and MR data object in to one object.
      var keySetFR, keySetMR, i;
      var displayDataSet = [];
      var object;

      if (arrayFR === undefined || arrayMR === undefined) {
        if (arrayFR === undefined) {
          displayDataSet = arrayMR;
        } else if (arrayMR === undefined) {
          displayDataSet = arrayFR;
        } else {
          displayDataSet = undefined;
        }
      } else {
        keySetFR = Object.keys(arrayFR);
        keySetMR = Object.keys(arrayMR);

        if (keySetFR.length >= keySetMR.length) {
          for (i = 0; i < keySetFR.length; i++) {
            object = fundamental_data_object['default'].create();
            object.setData(arrayFR[keySetFR[i]]);
            if (keySetMR.indexOf(keySetFR[i]) >= 0 && i < keySetMR.length) {
              object.setData(arrayMR[keySetFR[i]]);
            }
            displayDataSet[keySetFR[i]] = object;
          }
        } else if (keySetMR.length > keySetFR.length) {
          for (i = 0; i < keySetMR.length; i++) {
            object = fundamental_data_object['default'].create();
            object.setData(arrayMR[keySetMR[i]]);
            if (keySetFR.indexOf(keySetMR[i]) >= 0 && i < keySetFR.length) {
              object.setData(arrayFR[keySetMR[i]]);
            }
            displayDataSet[keySetMR[i]] = object;
          }
        }
      }

      if (setToAdditionalObj) {
        // this will be set only to fill the interim DOC_IDs to Quarterly DOC_IDs
        this.set('dataObjectAdditional', displayDataSet);
      } else {
        this.set('dataObject', displayDataSet);
      }
    },

    isExpandCollapse: (function () {
      this.dataDisplay(this.get('exg'), this.get('sym'), this.get('currentlyDataDisplayingViewType'));
    }).observes('breakDown'),

    /**
     * To get filtered data list in between from date and the to date
     * and set in to the displayDataObject.
     * @param fromDate and toDate
     */

    filterDataValues: function filterDataValues(fromDate, toDate, dataObj, displayDataObj, type) {
      var key;
      var valueObject;
      var keySet = Object.keys(dataObj);
      //keySet.reverse(); // to change the display format of the columns from left to right

      if (!this.get('includeFromToFields')) {
        if (this.get('currentlyDataDisplayingViewType') === 'A') {
          toDate = keySet[keySet.length - 1];
          fromDate = keySet[keySet.length - parseInt(this.get('numberOFYearsDisplayed'))];
          if (fromDate === undefined) {
            fromDate = keySet[0];
          }
        } else {
          toDate = keySet[keySet.length - 1];
          fromDate = keySet[keySet.length - parseInt(this.get('numberOFQuartersDisplayed'))];
          if (fromDate === undefined) {
            fromDate = keySet[0];
          }
        }
      }

      for (var j = 0; j < keySet.length; j++) {
        key = keySet[j];
        valueObject = dataObj[key];
        if (this._compareDate(fromDate, key, toDate)) {
          displayDataObj[key] = valueObject;
        }
      }
      // this.set('dataKeySet', Object.keys(displayDataObj));

      switch (type) {

        case 'IS':
          this.set('dataKeySetIS', Object.keys(displayDataObj));
          break;

        case 'BS':
          this.set('dataKeySetBS', Object.keys(displayDataObj));
          break;

        case 'CF':
          this.set('dataKeySetCF', Object.keys(displayDataObj));
          break;

        case 'FR':
          this.set('dataKeySetFR', Object.keys(displayDataObj));
          break;

        default:
          this.set('dataKeySet', Object.keys(displayDataObj));
          break;

      }

      keySet = Object.keys(displayDataObj);
      if (keySet.length <= 0) {
        return;
      }

      var keyListDate = this.get('viewDropdownListFromDate');

      for (var i = 0; i < keyListDate.length; i++) {
        // this was done to update the To year if the latest available year is lesser than the max year
        var latestAvailable = keySet[0];
        if (latestAvailable === keyListDate[i].view) {
          this.set('defSelectTo', keyListDate[i]);
          this.set('filterToDate', keyListDate[i].view);
        }
      }
    },

    /**
     * To get filtered style list. Iterate the filtered data list and get only the styles that having data for
     * all the fields and filter the styles according to the breakdown views.
     */

    filterStyleValue: function filterStyleValue() {
      var styleObject,
          i,
          customFieldsForIS = this.get('customFieldsForIS'),
          customFieldsForBS = this.get('customFieldsForBS'),
          customFieldsForCF = this.get('customFieldsForCF'),
          customFieldsForRatios = this.get('customFieldsForRatios');
      var keySet = this.get('dataKeySet');
      this.set('displayStyleObject', Ember['default'].A([]));
      for (var j = 0; j < this.get('styleObject').length; j++) {
        styleObject = this.get('styleObject')[j];
        var dataObject;
        var key;
        if (customFieldsForIS !== undefined && this.get('statementType') === 'IS' || customFieldsForBS !== undefined && this.get('statementType') === 'BS' || customFieldsForCF !== undefined && this.get('statementType') === 'CF' || customFieldsForRatios !== undefined && this.get('statementType') === 'FR') {
          for (i = 0; i < keySet.length; i++) {
            key = keySet[i];
            dataObject = this.get('displayDataObject')[key];
            if (dataObject[styleObject.filedID] && (customFieldsForIS && customFieldsForIS.indexOf(styleObject.filedID) >= 0 || customFieldsForBS && customFieldsForBS.indexOf(styleObject.filedID) >= 0 || customFieldsForCF && customFieldsForCF.indexOf(styleObject.filedID) >= 0 || customFieldsForRatios && customFieldsForRatios.indexOf(styleObject.filedID) >= 0)) {
              if (this.get('breakDown') === FundamentalConstants['default'].BreakDown.CollapsedView) {
                if (this.get('showLevel2Charts') || styleObject.level === '1') {
                  this.get('displayStyleObject').pushObject(styleObject);
                }
              } else {
                this.get('displayStyleObject').pushObject(styleObject);
              }
              break;
            }
          }
        } else {
          for (i = 0; i < keySet.length; i++) {
            key = keySet[i];
            dataObject = this.get('displayDataObject')[key];
            if (dataObject[styleObject.filedID]) {
              if (this.get('breakDown') === FundamentalConstants['default'].BreakDown.CollapsedView) {
                if (this.get('showLevel2Charts') || styleObject.level === '1') {
                  this.get('displayStyleObject').pushObject(styleObject);
                }
              } else {
                this.get('displayStyleObject').pushObject(styleObject);
              }
              break;
            }
          }
        }
      }
      this.setTitleObj();
    },

    setTitleObj: function setTitleObj() {
      // title object contains all the styles which are sorted and sub categorized according to the group ID and group order ID
      var displayStyleObject = this.get('displayStyleObject');
      var titleObj = Ember['default'].A([]),
          temp = 0;
      var i;
      for (i = 0; i < displayStyleObject.length; i++) {
        if (parseInt(displayStyleObject[i].groupID, 10) !== temp) {
          temp++;

          titleObj.push(this._processArray(displayStyleObject, temp));
        }
      }
      this.set('titleObj', titleObj);
    },

    _processArray: function _processArray(displayStyleObject, temp) {
      var temArray;
      temArray = Ember['default'].$.grep(displayStyleObject, function (style) {
        return parseInt(style.groupID, 10) === temp;
      });
      temArray.sort(function (a, b) {
        return parseInt(a.orderInGroup, 10) - parseInt(b.orderInGroup, 10);
      });
      return temArray;
    },

    /**
     * This is a support function for filter data objects between two periods.
     * @param fromDate, date and toDate
     * @return {boolean}
     */

    _compareDate: function _compareDate(fromDate, date, toDate) {
      if (fromDate === undefined || date === undefined || toDate === undefined) {
        return false;
      }
      var splitFrom = fromDate.split('~');
      var splitDate = date.split('~');
      var splitTo = toDate.split('~');

      if (this.get('currentlyDataDisplayingViewType') === FundamentalConstants['default'].ViewTypes.VIEW_TYPE_ANNUAL) {
        if (parseInt(splitDate[0], 10) >= parseInt(fromDate, 10) && splitDate[0].localeCompare(toDate) <= 0) {
          return true;
        }
      } else {
        if (splitDate[0].concat(this._compareQuarter(splitDate[1])).localeCompare(splitFrom[0].concat(this._compareQuarter(splitFrom[1]))) >= 0 && splitDate[0].concat(this._compareQuarter(splitDate[1])).localeCompare(splitTo[0].concat(this._compareQuarter(splitTo[1]))) <= 0) {
          return true;
        }
      }
      return false;
    },

    _compareQuarter: function _compareQuarter(quarter) {
      try {
        if ('5' === quarter[quarter.length - 1]) {
          return '4';
        }
        return quarter[quarter.length - 1];
      } catch (e) {
        return quarter;
      }
    },

    _onTabChange: function _onTabChange(tabID) {
      var that = this;
      this._clearDisplay();
      this.set('isRatios', false);
      this.set('isExpandButtonAvailable', false);
      this.set('showColumnTopMsg', false);

      if (tabID === 1) {
        this.set('statementType', 'IS');
      } else if (tabID === 2) {
        this.set('statementType', 'BS');
      } else if (tabID === 3) {
        this.set('statementType', 'CF');
      } else if (tabID === 4) {
        this.set('statementType', 'FR');
        this.set('showColumnTopMsg', true);
        this.set('isRatios', true);
      }

      var postSuccess = function postSuccess() {
        that._setMemoryVariables();
        that.dataDisplay(that.get('exg'), that.get('sym'), that.get('currentlyDataDisplayingViewType'));
      };

      this.dropDownSetter(postSuccess); // on tab change data will be set to currently selected criteria of the drop downs
    },

    onExpandCollapse: (function () {
      var selection = this.get('isExpand');
      if (selection === 1) {
        this.set('breakDown', FundamentalConstants['default'].BreakDown.ExpandView);
      } else if (selection === 2) {
        this.set('breakDown', FundamentalConstants['default'].BreakDown.CollapsedView);
      }
    }).observes('isExpand'),

    getDisplayLabel: function getDisplayLabel(dataObject, key, viewType) {
      if (dataObject[key]) {
        if (viewType === 'Q') {
          return 'Q' + key.split('~')[1] + ' ' + key.split('~')[0];
        } else if (viewType === 'A') {
          return key.split('~')[0];
        }
      }
      return '';
    },

    _clearDisplay: function _clearDisplay() {
      this.hideDataErrorMessage();
      this.set('displayDataObject', Ember['default'].A([]));
      this.set('displayStyleObject', Ember['default'].A([]));
    },

    _setMemoryVariables: function _setMemoryVariables() {
      // this method set the memory variables which are used to filter the data set
      this.set('currentlyDataDisplayingIsCalendar', this.get('isCalendar'));
      this.set('currentlyDataDisplayingViewType', this.get('viewType'));
      this.set('currentlyDataDisplayingFilterFromDate', this.get('filterFromDate'));
      this.set('currentlyDataDisplayingFilterToDate', this.get('filterToDate'));
    },

    _onClickView: function _onClickView() {
      this._clearDisplay();
      this._setMemoryVariables();
      this.startLoadingProgress();
      this.set('reRender', true);
      this.sendDataRequest(this.get('exg'), this.get('sym'), this.get('currentlyDataDisplayingViewType'));
    },

    _validator: function _validator() {
      if (this.get('filterFromDate') === undefined || this.get('filterToDate') === undefined) {
        return;
      }
      var arrayFrom = this.get('filterFromDate').split('~');
      var arrayTo = this.get('filterToDate').split('~');

      if (parseInt(arrayFrom[0]) > parseInt(arrayTo[0])) {
        this.set('defSelectFrom', this.get('viewDropdownListFromDate')[this.get('numberOFQuartersDisplayed') - 1]);
        this.set('filterFromDate', this.get('defSelectFrom').view);
        this.set('defSelectTo', this.get('viewDropdownListFromDate')[0]);
        this.set('filterToDate', this.get('defSelectTo').view);
      } else if (parseInt(arrayFrom[0]) === parseInt(arrayTo[0]) && parseInt(arrayFrom[1]) > parseInt(arrayTo[1])) {
        this.set('defSelectFrom', this.get('viewDropdownListFromDate')[this.get('numberOFQuartersDisplayed') - 1]);
        this.set('filterFromDate', this.get('defSelectFrom').view);
        this.set('defSelectTo', this.get('viewDropdownListFromDate')[0]);
        this.set('filterToDate', this.get('defSelectTo').view);
      }
    },

    _setDropdownValues: function _setDropdownValues() {
      var view = this.get('viewType');
      var that = this;
      var keyListDate,
          i,
          j,
          numberOfQuarters = 4,
          objArrayOne,
          objArrayTwo,
          keySetOne,
          keySetTwo;
      var stat = that.get('statementType');
      var min_year, max_year, min_quarter, max_quarter;

      /* instead of taking min_max object from the response, we are finding the min max using the available data set , as it is more reliable */
      if (stat === 'BS' || stat === 'IS' || stat === 'CF') {
        objArrayOne = priceService['default'].clientFundamentalDS.getDataObject(that.get('exg'), that.get('sym'), 'IS', view, this.get('isCalendar'));

        if (!objArrayOne) {
          // TODO [Akila] find a better way to select the view type for the drop down
          objArrayOne = priceService['default'].clientFundamentalDS.getDataObject(that.get('exg'), that.get('sym'), 'CF', view, this.get('isCalendar'));
        }

        if (!objArrayOne) {
          objArrayOne = priceService['default'].clientFundamentalDS.getDataObject(that.get('exg'), that.get('sym'), 'BS', view, this.get('isCalendar'));
        }

        if (!objArrayOne) {
          this.set('viewDropdownListFromDate', []);
          this.set('viewDropdownListToDate', []);
          this.set('defSelectFrom', undefined);
          this.set('defSelectTo', undefined);
          this.set('filterFromDate', undefined);
          this.set('filterToDate', undefined);
          return;
        } else {
          keySetOne = Object.keys(objArrayOne);
          max_year = parseInt(keySetOne[0].split('~')[0]);
          max_quarter = FundamentalCommonFunctions['default'].actualQuarter(keySetOne[0].split('~')[1]);
          min_year = parseInt(keySetOne[keySetOne.length - 1].split('~')[0]);
          min_quarter = FundamentalCommonFunctions['default'].actualQuarter(keySetOne[keySetOne.length - 1].split('~')[1]);
        }
      } else if (stat === 'FR' || stat === 'MR') {
        objArrayOne = priceService['default'].clientFundamentalDS.getDataObject(that.get('exg'), that.get('sym'), 'R', view, this.get('isCalendar'));
        objArrayTwo = priceService['default'].clientFundamentalDS.getDataObject(that.get('exg'), that.get('sym'), 'R', view, this.get('isCalendar'));
        if (!objArrayOne && !objArrayTwo) {
          this.set('viewDropdownListFromDate', []);
          this.set('viewDropdownListToDate', []);
          this.set('defSelectFrom', undefined);
          this.set('defSelectTo', undefined);
          this.set('filterFromDate', undefined);
          this.set('filterToDate', undefined);
          return;
        } else {
          if (objArrayOne && !objArrayTwo) {
            keySetOne = Object.keys(objArrayOne);
            max_year = parseInt(keySetOne[0].split('~')[0]);
            max_quarter = FundamentalCommonFunctions['default'].actualQuarter(keySetOne[0].split('~')[1]);
            min_year = parseInt(keySetOne[keySetOne.length - 1].split('~')[0]);
            min_quarter = FundamentalCommonFunctions['default'].actualQuarter(keySetOne[keySetOne.length - 1].split('~')[1]);
          } else if (!objArrayOne && objArrayTwo) {
            keySetTwo = Object.keys(objArrayTwo);
            max_year = parseInt(keySetTwo[0].split('~')[0]);
            max_quarter = FundamentalCommonFunctions['default'].actualQuarter(keySetTwo[0].split('~')[1]);
            min_year = parseInt(keySetTwo[keySetTwo.length - 1].split('~')[0]);
            min_quarter = FundamentalCommonFunctions['default'].actualQuarter(keySetTwo[keySetTwo.length - 1].split('~')[1]);
          } else if (objArrayOne && objArrayTwo) {
            keySetOne = Object.keys(objArrayOne);
            keySetTwo = Object.keys(objArrayTwo);
            if (keySetOne[0].split('~')[0] < keySetTwo[0].split('~')[0]) {
              keySetOne = keySetTwo;
            }
            if (keySetOne[0].split('~')[0] === keySetTwo[0].split('~')[0]) {
              if (FundamentalCommonFunctions['default'].actualQuarter(keySetOne[0].split('~')[1]) < FundamentalCommonFunctions['default'].actualQuarter(keySetTwo[0].split('~')[1])) {
                keySetOne = keySetTwo;
              }
            }

            max_year = parseInt(keySetOne[0].split('~')[0]);
            max_quarter = FundamentalCommonFunctions['default'].actualQuarter(keySetOne[0].split('~')[1]);
            min_year = parseInt(keySetOne[keySetOne.length - 1].split('~')[0]);
            min_quarter = FundamentalCommonFunctions['default'].actualQuarter(keySetOne[keySetOne.length - 1].split('~')[1]);
          }
        }
      }

      if (view === 'A') {
        keyListDate = [];
        for (i = min_year; i <= max_year; i++) {
          keyListDate.push({ view: i + '~' + '5', des: i });
        }
        keyListDate.reverse(); // to invert the upper most value of the dropdown
        this.set('viewDropdownListFromDate', keyListDate);
        this.set('viewDropdownListToDate', keyListDate);
        if (keyListDate.length < parseInt(this.get('numberOFYearsDisplayed'))) {
          /* for some symbols data set can be less than the number
          of default entries to be displayed in the view. this if was used to handle such scenarios */
          this.set('defSelectFrom', keyListDate[keyListDate.length - 1]);
          this.set('filterFromDate', keyListDate[keyListDate.length - 1].view);
        } else {
          this.set('defSelectFrom', keyListDate[this.get('numberOFYearsDisplayed') - 1]);
          this.set('filterFromDate', keyListDate[this.get('numberOFYearsDisplayed') - 1].view);
        }

        this.set('defSelectTo', keyListDate[0]);
        this.set('filterToDate', keyListDate[0].view);
      } else if (view === 'Q') {
        keyListDate = [];
        for (i = min_year; i <= max_year; i++) {
          for (j = 1; j <= numberOfQuarters; j++) {
            if (i === min_year) {
              if (j < min_quarter) {
                j = min_quarter;
              }
            } else if (i === max_year) {
              numberOfQuarters = max_quarter;
            }

            if (j === 1) {
              keyListDate.push({ view: i + '~' + j, des: 'Q' + j + ' ' + i });
            } else {
              keyListDate.push({ view: i + '~' + '1' + j, des: 'Q' + j + ' ' + i });
            }
          }
        }
        keyListDate.reverse();
        this.set('viewDropdownListFromDate', keyListDate);
        this.set('viewDropdownListToDate', keyListDate);
        if (keyListDate.length < parseInt(this.get('numberOFYearsDisplayed'))) {
          /* for some symbols data set can be less than the number
          of default entries to be displayed in the view. this if was used to handle such scenarios */
          this.set('defSelectFrom', keyListDate[keyListDate.length - 1]);
          this.set('filterFromDate', keyListDate[keyListDate.length - 1].view);
        } else {
          this.set('defSelectFrom', keyListDate[this.get('numberOFYearsDisplayed') - 1]);
          this.set('filterFromDate', keyListDate[this.get('numberOFYearsDisplayed') - 1].view);
        }

        this.set('defSelectTo', keyListDate[0]);
        this.set('filterToDate', keyListDate[0].view);
      } else if (view === 'C') {
        keyListDate = [];
        for (i = min_year; i <= max_year; i++) {
          for (j = 1; j <= numberOfQuarters; j++) {
            if (i === min_year) {
              if (j < min_quarter) {
                j = min_quarter;
              }
            } else if (i === max_year) {
              numberOfQuarters = max_quarter;
            }
            keyListDate.push({ view: i + '~' + '2' + j, des: 'Q' + j + ' ' + i });
          }
        }
        keyListDate.reverse();
        this.set('viewDropdownListFromDate', keyListDate);
        this.set('viewDropdownListToDate', keyListDate);
        if (keyListDate.length < parseInt(this.get('numberOFYearsDisplayed'))) {
          /* for some symbols data set can be less than the number
          of default entries to be displayed in the view. this if was used to handle such scenarios */
          this.set('defSelectFrom', keyListDate[keyListDate.length - 1]);
          this.set('filterFromDate', keyListDate[keyListDate.length - 1].view);
        } else {
          this.set('defSelectFrom', keyListDate[this.get('numberOFYearsDisplayed') - 1]);
          this.set('filterFromDate', keyListDate[this.get('numberOFYearsDisplayed') - 1].view);
        }

        this.set('defSelectTo', keyListDate[0]);
        this.set('filterToDate', keyListDate[0].view);
      } else if (view === 'T') {
        keyListDate = [];
        for (i = min_year; i <= max_year; i++) {
          for (j = 1; j <= numberOfQuarters; j++) {
            if (i === min_year) {
              if (j < min_quarter) {
                j = min_quarter;
              }
            } else if (i === max_year) {
              numberOfQuarters = max_quarter;
            }

            if (i === 4) {
              keyListDate.push({ view: i + '~' + '5', des: 'Q' + j + ' ' + i });
            } else {
              keyListDate.push({ view: i + '~' + j, des: 'Q' + j + ' ' + i });
            }
          }
        }
        keyListDate.reverse();
        this.set('viewDropdownListFromDate', keyListDate);
        this.set('viewDropdownListToDate', keyListDate);
        if (keyListDate.length < parseInt(this.get('numberOFYearsDisplayed'))) {
          /* for some symbols data set can be less than the number
          of default entries to be displayed in the view. this if was used to handle such scenarios */
          this.set('defSelectFrom', keyListDate[keyListDate.length - 1]);
          this.set('filterFromDate', keyListDate[keyListDate.length - 1].view);
        } else {
          this.set('defSelectFrom', keyListDate[this.get('numberOFYearsDisplayed') - 1]);
          this.set('filterFromDate', keyListDate[this.get('numberOFYearsDisplayed') - 1].view);
        }

        this.set('defSelectTo', keyListDate[0]);
        this.set('filterToDate', keyListDate[0].view);
      }

      this.set('keyListDate', keyListDate);
    },

    /*if data was loaded initially for cal=1 then there will be no data for cal=0 {calendar and fiscal}
     * thus want to load that data when the user change the period from calendar to fiscal and vice versa.
     * As response is cached, request will be locally resolved*/
    dropDownSetter: (function (postSuccess) {
      if (!this.get('exg') || !this.get('sym')) {
        return;
      }
      var that = this;

      if (that.get('currentlyDataDisplayingIsCalendar') !== that.get('isCalendar')) {
        var suc = function suc() {
          that._setDropdownValues();
          if (Ember['default'].$.isFunction(postSuccess)) {
            postSuccess();
          }
        };
        var err = function err() {
          that.showDataErrorMessage();
          that.stopLoadingProgress();
        };
        priceService['default'].sendFundamentalDataRequest(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, 'X', suc, err, '5', this.get('isCalendar'));
      } else {
        that._setDropdownValues();
        if (Ember['default'].$.isFunction(postSuccess)) {
          postSuccess();
        }
      }
    }).observes('isCalendar'),
    //set to date when user select from date
    setToDates: function setToDates() {
      var that = this;
      if (that.get('filterFromDate') === undefined || that.get('filterToDate') === undefined) {
        return;
      }
      var index = this.get('viewDropdownListFromDate').findIndex(function (x) {
        return x.view === that.get('defSelectFrom').view;
      });
      if (index - this.get('DateRange') < 0) {
        that.set('defSelectTo', that.get('viewDropdownListFromDate')[0]);
        that.set('defSelectFrom', that.get('viewDropdownListFromDate')[that.get('DateRange')]);
      } else {
        that.set('defSelectTo', that.get('viewDropdownListFromDate')[index - that.get('DateRange')]);
      }
      that.set('filterToDate', that.get('defSelectTo').view);
      that.set('filterFromDate', that.get('defSelectFrom').view);
    },
    //set from date when user select to date
    setFromDates: function setFromDates() {
      var that = this;
      var lastIndex = that.get('viewDropdownListFromDate').length - 1;
      if (that.get('filterFromDate') === undefined || that.get('filterToDate') === undefined) {
        return;
      }
      var index = this.get('viewDropdownListFromDate').findIndex(function (x) {
        return x.view === that.get('defSelectTo').view;
      });
      if (index + that.get('DateRange') === that.get('viewDropdownListFromDate').length || index + that.get('DateRange') > that.get('viewDropdownListFromDate').length) {
        that.set('defSelectFrom', that.get('viewDropdownListFromDate')[lastIndex]);
        that.set('defSelectTo', that.get('viewDropdownListFromDate')[lastIndex - that.get('DateRange')]);
      } else {
        this.set('defSelectFrom', this.get('viewDropdownListFromDate')[index + that.get('DateRange')]);
      }
      this.set('filterFromDate', this.get('defSelectFrom').view);
      this.set('filterToDate', this.get('defSelectTo').view);
    },

    actions: {
      // call by tab change
      onTabSelected: function onTabSelected(tabId) {
        this._onTabChange(tabId);
      },
      //select the view type
      onSelectingView: function onSelectingView(item) {
        switch (item.view) {
          case 1:
            this.set('viewType', 'A');
            this._setDropdownValues();
            break;
          case 2:
            this.set('viewType', 'Q');
            this._setDropdownValues();
            break;
          case 3:
            this.set('viewType', 'C');
            this._setDropdownValues();
            break;
          case 4:
            this.set('viewType', 'T');
            this._setDropdownValues();
            break;
          default:
            return;
        }

        if (this.get('activateViewDropdown')) {
          this._onClickView();
        }
      },

      onSelectingFromDate: function onSelectingFromDate(item) {
        this.set('defSelectFrom', item);
        this.set('filterFromDate', item.view);
        this.setToDates();
        this._onClickView();
      },

      onSelectingToDate: function onSelectingToDate(item) {
        this.set('defSelectTo', item);
        this.set('filterToDate', item.view);
        this.setFromDates();
        this._onClickView();
      },

      onClickView: function onClickView() {
        this._validator();
        this._onClickView();
      }
    },

    onAfterRender: function onAfterRender() {
      var that = this;
      Ember['default'].$(document).ready(function () {
        Ember['default'].$('#' + 'scroll_tabs_' + that.get('widgetKey')).scrollTabs({
          scroll_distance: 200,
          scroll_duration: 300,
          left_arrow_size: 26,
          right_arrow_size: 26,
          click_callback: function click_callback(e) {
            // jshint ignore:line
            //Callback function on click. Accpets the click event object as an argument. The default callback function will change the page to the href in the 'rel' attribute of the item's span tag.
            var val = Ember['default'].$(this).attr('rel');
            if (val) {
              window.location.href = val;
            }
          }
        });
      });
    }
  });

  Ember['default'].Handlebars.helper('displayData', function (dataObject, key, fieldID, statementType) {
    if (dataObject[key]) {
      var valueObject = dataObject[key];
      var temp;
      if (statementType === 'R' || valueObject[fieldID] === '') {
        temp = utils['default'].formatters.formatNumber(valueObject[fieldID], 2);
      } else {
        temp = utils['default'].formatters.formatNumber(valueObject[fieldID], 0);
      }
      if (temp.charAt(0) === '-' && temp.charAt(1) !== '-') {
        temp = '(' + temp.substring(1, temp.length) + ')';
      }
      return temp;
    }
    return '';
  });

  Ember['default'].Handlebars.helper('getHeader', function (dataObject, key, viewType) {
    if (dataObject[key]) {
      if (viewType === 'Q') {
        return 'Q' + key.split('~')[1] + ' ' + key.split('~')[0];
      } else if (viewType === 'A') {
        return key.split('~')[0];
      }
    }
    return '';
  });

  Ember['default'].Handlebars.helper('financial-chart', FinChart['default']);

  Ember['default'].Handlebars.helper('getDisplayData', function (dataObject, key, fieldID) {
    if (dataObject[key]) {
      var valueObject = dataObject[key];
      var temp;
      if (FundamentalConstants['default'].NonMillionFields.indexOf(fieldID) >= 0 || valueObject[fieldID] === '') {
        temp = utils['default'].formatters.formatNumber(valueObject[fieldID], 2);
      } else {
        temp = utils['default'].formatters.formatNumber(valueObject[fieldID] / 1000000, 2);
      }

      if (temp.charAt(0) === '-' && temp.charAt(1) !== '-') {
        temp = '(' + temp.substring(1, temp.length) + ')';
      }
      return temp;
    }
    return '';
  });

  Ember['default'].Handlebars.helper('getDisplayRatio', function (dataObject, key, fieldID) {
    // ratios need not to be converted to millions so a this helper is specially used in Ratios page
    if (dataObject[key]) {
      var valueObject = dataObject[key];
      var temp;
      temp = utils['default'].formatters.formatNumber(valueObject[fieldID], 2);

      if (temp.charAt(0) === '-' && temp.charAt(1) !== '-') {
        temp = '(' + temp.substring(1, temp.length) + ')';
      }
      return temp;
    }
    return '';
  });

  Ember['default'].Handlebars.helper('getSubTitle', function (subTitleObj) {
    if (subTitleObj[0]) {
      return subTitleObj[0].groupHeadDesc;
    }
    return '';
  });

  Ember['default'].Handlebars.helper('getDisplayHeader', function (dataObject, key, viewType, isCalendar, yearQuarterDisplayFormat) {
    if (dataObject[key]) {
      var valueObject = dataObject[key];
      if (isCalendar === 1) {
        if (yearQuarterDisplayFormat) {
          return yearQuarterDisplayFormat.replace('Y', valueObject['CALENDER_YEAR']).replace('Q', viewType === FundamentalConstants['default'].ViewTypes.VIEW_TYPE_ANNUAL ? '' : FundamentalCommonFunctions['default'].displayQuater(valueObject['CALENDER_PERIOD'] ? valueObject['CALENDER_PERIOD'] : valueObject['CAL_QUARTER_REQUESTED']));
        } else {
          return valueObject['CALENDER_YEAR'] + (viewType === FundamentalConstants['default'].ViewTypes.VIEW_TYPE_ANNUAL ? '' : ' ' + FundamentalCommonFunctions['default'].displayQuater(valueObject['CALENDER_PERIOD'] ? valueObject['CALENDER_PERIOD'] : valueObject['CAL_QUARTER_REQUESTED']));
        }
      } else if (isCalendar === 0) {

        if (yearQuarterDisplayFormat) {
          return yearQuarterDisplayFormat.replace('Y', valueObject['DUR_YEAR']).replace('Q', viewType === FundamentalConstants['default'].ViewTypes.VIEW_TYPE_ANNUAL ? '' : FundamentalCommonFunctions['default'].displayQuater(valueObject['PERIOD'] ? valueObject['PERIOD'] : valueObject['QUARTER_REQUESTED']));
        } else {
          return valueObject['DUR_YEAR'] + (viewType === FundamentalConstants['default'].ViewTypes.VIEW_TYPE_ANNUAL ? '' : ' ' + FundamentalCommonFunctions['default'].displayQuater(valueObject['PERIOD'] ? valueObject['PERIOD'] : valueObject['QUARTER_REQUESTED']));
        }
      }
    }
    return '';
  });

});