define('ir-app/controllers/ir-widgets/financial-widgets/estimates', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/utils/utils', 'ir-app/models/shared/shared-service', 'ir-app/models/price/price-business-entities/fundamentals/fundamental-constants', 'ir-app/models/price/price-business-entities/fundamentals/fundamental-data-object', 'ir-app/models/price/price-business-entities/fundamentals/estimate-forecast-object', 'ir-app/components/stk-specific-components/estimate-chart', 'ir-app/models/shared/language/language-data-store'], function (exports, Ember, BaseController, priceService, utils, sharedService, FundamentalConstants, dataObj, forecastObj, estimateChart, LanguageDataStore) {

  'use strict';

  exports['default'] = BaseController['default'].extend({

    styleObjectIS: undefined,
    dataObjectIS: undefined,
    dataKeySetIS: undefined,
    displayDataObjectIS: {},
    displayStyleObjectIS: Ember['default'].A([]),

    styleObjectBS: undefined,
    dataObjectBS: undefined,
    dataKeySetBS: undefined,
    displayDataObjectBS: {},
    displayStyleObjectBS: Ember['default'].A([]),

    styleObjectCF: undefined,
    dataObjectCF: undefined,
    dataKeySetCF: undefined,
    displayDataObjectCF: {},
    displayStyleObjectCF: Ember['default'].A([]),

    dataObjectISAct: undefined,
    dataObjectBSAct: undefined,
    dataObjectCFAct: undefined,
    displayDataObjectAct: undefined,
    dataKeySetAct: undefined,

    displayDataObject: undefined,
    displayChunkObject: undefined,
    dataKeySet: undefined,
    today: undefined,
    availableMaxActual: undefined,

    titleObj: undefined,
    lang: '',
    estimationsNotAvailable: false,

    popupTitle: undefined,
    popupActArray: undefined,
    popupField: undefined,
    popupDataArr: Ember['default'].A([]),
    popupDataMap: undefined,
    headingYearSetter: undefined,

    onPrepareData: function onPrepareData() {

      this.setErrorMessage();
      this.set('lang', sharedService['default'].userSettings.currentLanguage);
      var exchange = this.get('exg');
      var symbol = this.get('sym');
      var view = this.get('currentlyDataDisplayingViewType');
      this.startLoadingProgress();
      this.sendActualDataRequest(exchange, symbol, view);
    },

    sendEstDataRequest: function sendEstDataRequest(exchange, symbol, view) {
      var that = this;
      var postSuccess = function postSuccess() {
        that.dataDisplay(exchange, symbol, view);
      };

      var error = function error() {
        that.showDataErrorMessage();
      };

      priceService['default'].sendFundamentalDataEstimateRequest(exchange, symbol, sharedService['default'].userSettings.currentLanguage, postSuccess, error);
    },

    sendActualDataRequest: function sendActualDataRequest(exchange, symbol, view) {
      var that = this;
      var postSuccess = function postSuccess() {
        that.actualDataDisplay(exchange, symbol, view);
        that.sendEstDataRequest(exchange, symbol, view);
      };

      var error = function error() {
        that.showDataErrorMessage();
      };
      priceService['default'].sendFundamentalDataRequest(exchange, symbol, sharedService['default'].userSettings.currentLanguage, 'X', postSuccess, error, '5', 0);
    },

    actualDataDisplay: function actualDataDisplay() {
      // this for BS, IS and CF
      this.set('dataObjectISAct', priceService['default'].advanceFundamentalDS.getDataObject(this.get('exg'), this.get('sym'), 'IS', 'A', 0));

      this.set('dataObjectBSAct', priceService['default'].advanceFundamentalDS.getDataObject(this.get('exg'), this.get('sym'), 'BS', 'A', 0));

      this.set('dataObjectCFAct', priceService['default'].advanceFundamentalDS.getDataObject(this.get('exg'), this.get('sym'), 'CF', 'A', 0));

      var dataSet = this._mergeDataObjects(this._mergeDataObjects(this.get('dataObjectBSAct'), this.get('dataObjectCFAct')), this.get('dataObjectISAct'));
      var keys = Object.keys(dataSet);
      this.set('displayDataObjectAct', {});
      for (var i = 0; i < 3; i++) {
        this.get('displayDataObjectAct')[keys[i]] = dataSet[keys[i]];
      }
      this.set('dataKeySetAct', Object.keys(this.get('displayDataObjectAct')).reverse());
      this.set('availableMaxActual', this.get('dataKeySetAct')[2].split('~')[0]);
    },

    dataDisplay: function dataDisplay() {

      // this for BS, IS and CF
      this.set('styleObjectIS', priceService['default'].fundamentalEstimateDS.getStyleObject(this.get('exg'), this.get('sym'), 'IS', sharedService['default'].userSettings.currentLanguage));
      this.set('dataObjectIS', priceService['default'].fundamentalEstimateDS.getDataObject(this.get('exg'), this.get('sym'), 'IS', sharedService['default'].userSettings.currentLanguage));

      this.set('styleObjectBS', priceService['default'].fundamentalEstimateDS.getStyleObject(this.get('exg'), this.get('sym'), 'BS', sharedService['default'].userSettings.currentLanguage));
      this.set('dataObjectBS', priceService['default'].fundamentalEstimateDS.getDataObject(this.get('exg'), this.get('sym'), 'BS', sharedService['default'].userSettings.currentLanguage));

      this.set('styleObjectCF', priceService['default'].fundamentalEstimateDS.getStyleObject(this.get('exg'), this.get('sym'), 'CF', sharedService['default'].userSettings.currentLanguage));
      this.set('dataObjectCF', priceService['default'].fundamentalEstimateDS.getDataObject(this.get('exg'), this.get('sym'), 'CF', sharedService['default'].userSettings.currentLanguage));

      if (this.get('dataObjectIS')) {
        this.processDataObject(this.get('dataObjectIS'), 'IS');
      }
      if (this.get('dataObjectBS')) {
        this.processDataObject(this.get('dataObjectBS'), 'BS');
      }
      if (this.get('dataObjectCF')) {
        this.processDataObject(this.get('dataObjectCF'), 'CF');
      }

      if (!this.get('dataObjectIS') && !this.get('dataObjectBS') && !this.get('dataObjectCF')) {
        this.set('estimationsNotAvailable', true);
        return;
      }

      var dataSet = this._mergeDataObjects(this._mergeDataObjects(this.get('displayDataObjectIS'), this.get('displayDataObjectBS')), this.get('displayDataObjectCF'));
      var keys = Object.keys(dataSet);
      this.set('displayDataObject', {});
      for (var i = keys.indexOf(this.get('availableMaxActual')) + 1; i < keys.length; i++) {
        this.get('displayDataObject')[keys[i]] = dataSet[keys[i]];
      }

      this.set('dataKeySet', Object.keys(this.get('displayDataObject')));

      if (this.get('dataObjectIS')) {
        this.filterStyleValue(this.get('styleObjectIS'), this.get('dataKeySetIS'), this.get('displayDataObjectIS'), 'IS');
      }
      if (this.get('dataObjectBS')) {
        this.filterStyleValue(this.get('styleObjectBS'), this.get('dataKeySetBS'), this.get('displayDataObjectBS'), 'BS');
      }
      if (this.get('dataObjectCF')) {
        this.filterStyleValue(this.get('styleObjectCF'), this.get('dataKeySetCF'), this.get('displayDataObjectCF'), 'CF');
      }

      this.setSubtitleObj(this.get('displayStyleObjectIS'), this.get('displayStyleObjectBS'), this.get('displayStyleObjectCF'));
    },

    setSubtitleObj: function setSubtitleObj(styleObjectIS, styleObjectBS, styleObjectCF) {
      var displayChunkObject = Ember['default'].A([]);

      if (styleObjectIS.length > 0) {
        displayChunkObject.push(styleObjectIS);
      }

      if (styleObjectBS.length > 0) {
        displayChunkObject.push(styleObjectBS);
      }

      if (styleObjectCF.length > 0) {
        displayChunkObject.push(styleObjectCF);
      }

      this.set('displayChunkObject', displayChunkObject);
    },

    _mergeDataObjects: function _mergeDataObjects(arrayOne, arrayTwo) {
      // this function merge both the FR data object and MR data object in to one object.
      var keySetOne, keySetTwo, i;
      var displayDataSet = [];
      var object;

      if (arrayOne === undefined || arrayTwo === undefined) {
        if (arrayOne === undefined) {
          displayDataSet = arrayTwo;
        } else if (arrayTwo === undefined) {
          displayDataSet = arrayOne;
        } else {
          displayDataSet = undefined;
        }
      } else {
        keySetOne = Object.keys(arrayOne);
        keySetTwo = Object.keys(arrayTwo);

        if (keySetOne.length >= keySetTwo.length) {
          for (i = 0; i < keySetOne.length; i++) {
            object = dataObj['default'].create();
            object.setData(arrayOne[keySetOne[i]]);
            if (keySetTwo.indexOf(keySetOne[i]) >= 0) {
              object.setData(arrayTwo[keySetOne[i]]);
            }
            displayDataSet[keySetOne[i]] = object;
          }
        } else if (keySetTwo.length > keySetOne.length) {
          for (i = 0; i < keySetTwo.length; i++) {
            object = dataObj['default'].create();
            object.setData(arrayTwo[keySetTwo[i]]);
            if (keySetOne.indexOf(keySetTwo[i]) >= 0 && i < keySetOne.length) {
              object.setData(arrayOne[keySetTwo[i]]);
            }
            displayDataSet[keySetTwo[i]] = object;
          }
        }
      }

      return displayDataSet;
    },

    processDataObject: function processDataObject(dataObject, flag) {
      var formattedDataObj = {};
      var yearlyFieldForecasts = {},
          providerList = {},
          providerForecasts = {},
          value,
          i,
          j,
          field,
          tempArr = {},
          year,
          providerID,
          dates,
          forecastDate,
          forecastVal;

      Ember['default'].$.each(dataObject, function (key, fieldObj) {
        // key is a fieldID
        field = fieldObj.fieldID;
        yearlyFieldForecasts = fieldObj.yearlyFieldForecasts;

        Ember['default'].$.each(yearlyFieldForecasts, function (key, yearObj) {
          // key is a year
          year = key;
          providerList = yearObj.providerList;
          value = 0;
          i = 0;
          j = 0;
          Ember['default'].$.each(providerList, function (key, providerObj) {
            // key is a providerID
            providerID = providerObj.providerID;
            providerForecasts = providerObj.providerForecasts;
            dates = Object.keys(providerForecasts);

            Ember['default'].$.each(providerForecasts, function (key, forecast) {
              // key is a date string
              j++;
              if (forecast.value === '') {
                return;
              }
              forecastDate = forecast.providedDate;
              forecastVal = parseInt(forecast.value, 10);
            });

            if (j === dates.length && parseInt(forecastDate) > 0) {
              i++;
              value += forecastVal;
              forecastDate = 0;
              forecastVal = 0;
            }
            j = 0;
          });
          value = value / i;
          formattedDataObj = tempArr[year];

          if (!formattedDataObj) {
            formattedDataObj = {};
            formattedDataObj[field] = value;
            tempArr[year] = formattedDataObj;
          } else {
            formattedDataObj[field] = value;
          }
        });
      });

      switch (flag) {
        case 'IS':
          this.set('displayDataObjectIS', tempArr);
          this.set('dataKeySetIS', Object.keys(tempArr));
          break;
        case 'BS':
          this.set('displayDataObjectBS', tempArr);
          this.set('dataKeySetBS', Object.keys(tempArr));
          break;
        case 'CF':
          this.set('displayDataObjectCF', tempArr);
          this.set('dataKeySetCF', Object.keys(tempArr));
          break;
      }
    },

    /**
     * To get filtered style list. Iterate the filtered data list and get only the styles that having data for
     * all the fields and filter the styles according to the breakdown views.
     */

    filterStyleValue: function filterStyleValue(styleObject, keySet, displayDataObject, flag) {
      var style, i;
      switch (flag) {
        case 'IS':
          this.set('displayStyleObjectIS', Ember['default'].A([]));
          break;
        case 'BS':
          this.set('displayStyleObjectBS', Ember['default'].A([]));
          break;
        case 'CF':
          this.set('displayStyleObjectCF', Ember['default'].A([]));
          break;
      }

      for (var j = 0; j < styleObject.length; j++) {
        style = styleObject[j];
        var dataObject;
        var key;
        var availableMaxActual = this.get('availableMaxActual');
        for (i = keySet.indexOf(availableMaxActual) + 1; i < keySet.length; i++) {
          key = keySet[i];
          dataObject = displayDataObject[key];
          if (!isNaN(dataObject[style.filedID])) {

            switch (flag) {
              case 'IS':
                if (this.get('ISFieldList')) {
                  if (this.get('ISFieldList').fields.indexOf(style.filedID) > -1) {
                    var label = this.get('ISFieldList').labels[this.get('ISFieldList').fields.indexOf(style.filedID)];

                    if (label) {
                      style.des = LanguageDataStore['default'].getLanguageObj().lang.labels[label];
                    }
                    this.get('displayStyleObjectIS').pushObject(style);
                  }
                } else {
                  this.get('displayStyleObjectIS').pushObject(style);
                }
                break;

              case 'BS':
                if (this.get('BSFieldList')) {
                  if (this.get('BSFieldList').fields.indexOf(style.filedID) > -1) {
                    var label = this.get('BSFieldList').labels[this.get('BSFieldList').fields.indexOf(style.filedID)];

                    if (label) {
                      style.des = LanguageDataStore['default'].getLanguageObj().lang.labels[label];
                    }
                    this.get('displayStyleObjectBS').pushObject(style);
                  }
                } else {
                  this.get('displayStyleObjectBS').pushObject(style);
                }
                break;
              case 'CF':

                if (this.get('CFFieldList')) {
                  if (this.get('CFFieldList').fields.indexOf(style.filedID) > -1) {
                    var label = this.get('CFFieldList').labels[this.get('CFFieldList').fields.indexOf(style.filedID)];

                    if (label) {
                      style.des = label;
                    }
                    this.get('displayStyleObjectCF').pushObject(style);
                  }
                } else {
                  this.get('displayStyleObjectCF').pushObject(style);
                }
                break;
            }

            break;
          }
        }
      }
    },

    setAvgValues: function setAvgValues() {
      var dataObject = this.get('popupDataMap');
      var headingYearSetter = this.get('headingYearSetter');

      var avgVal,
          keys,
          value = 0,
          k = 0,
          j;
      if (dataObject) {
        keys = Object.keys(dataObject);
        for (var i = 0; i < headingYearSetter.keysOfLatest.length; i++) {
          value = 0;
          k = 0;
          for (j = 0; j < keys.length; j++) {

            if (isNaN(parseInt(dataObject[keys[j]].latestEstimates[headingYearSetter.keysOfLatest[i]]))) {
              continue;
            }
            if (parseInt(dataObject[keys[j]].latestEstimates[headingYearSetter.keysOfLatest[i]]) !== 0) {
              k++;
            }
            value += parseInt(dataObject[keys[j]].latestEstimates[headingYearSetter.keysOfLatest[i]]);
          }
          avgVal = value / k;

          this.get('dataSetAvg').push([parseInt(headingYearSetter.keysOfLatest[i]), parseInt(avgVal)]);
        }
      }
    },

    setMinMaxValues: function setMinMaxValues() {
      var dataObject = this.get('popupDataMap');
      var headingYearSetter = this.get('headingYearSetter');

      var val, maxVal, minVal, tempVal, keys, j;
      if (dataObject) {
        keys = Object.keys(dataObject);
        for (var i = 0; i < headingYearSetter.keysOfLatest.length; i++) {
          maxVal = -1000000000000000; // TODO [Akila] use a better way rather than having 2 limiting values for max and min
          minVal = 1000000000000000;
          tempVal = 0;
          for (j = 0; j < keys.length; j++) {
            val = parseInt(dataObject[keys[j]].latestEstimates[headingYearSetter.keysOfLatest[i]]);
            if (isNaN(val) || val === 0) {
              continue;
            }
            if (maxVal < val) {
              maxVal = val;
            }
            if (minVal > val) {
              minVal = val;
            }
          }

          this.get('dataSetMax').push([parseInt(headingYearSetter.keysOfLatest[i]), parseInt(maxVal)]);
          this.get('dataSetMin').push([parseInt(headingYearSetter.keysOfLatest[i]), parseInt(minVal)]);
        }
      }
    },

    setChart: function setChart() {
      var i;
      this.set('xDataSet', Ember['default'].A([]));
      this.set('dataSetAct', Ember['default'].A([]));
      this.set('dataSetAvg', Ember['default'].A([]));
      this.set('dataSetMin', Ember['default'].A([]));
      this.set('dataSetMax', Ember['default'].A([]));

      var field = this.get('popupField');
      var displayDataObjectAct = this.get('displayDataObjectAct');
      var dataKeySetAct = this.get('dataKeySetAct');

      for (i = 0; i < dataKeySetAct.length; i++) {
        this.get('xDataSet').push(dataKeySetAct[i].split('~')[0]);
        this.get('dataSetAct').push([parseInt(dataKeySetAct[i].split('~')[0]), parseInt(displayDataObjectAct[dataKeySetAct[i]][field])]);
        if (i === dataKeySetAct.length - 1) {
          var firstElement = [parseInt(dataKeySetAct[i].split('~')[0]), parseInt(displayDataObjectAct[dataKeySetAct[i]][field])];
          this.get('dataSetAvg').push(firstElement);
          this.get('dataSetMax').push(firstElement);
          this.get('dataSetMin').push(firstElement);
        }
      }

      this.setAvgValues();
      this.setMinMaxValues();

      var headingYearSetter = this.get('headingYearSetter');
      var keys = headingYearSetter.keysOfLatest;
      for (i = 0; i < keys.length; i++) {
        this.get('xDataSet').push(keys[i]);
      }
    },

    closePopup: function closePopup() {
      this.set('isShowPopup', false);
    },

    openPopup: function openPopup(styleObj) {
      var that = this;
      this.set('isShowPopup', true);
      this.set('popupTitle', styleObj.des);
      this.set('popupField', styleObj.filedID);
      this.set('popupActArray', {});
      this.set('popupDataArr', Ember['default'].A([]));
      var keys = Object.keys(this.get('displayDataObjectAct'));
      this.set('popupActArray', keys.reverse());

      var dataObject,
          prevVal,
          latestVal,
          yearSet = Ember['default'].A([]);
      var yearlyFieldForecasts = {},
          providerList = {},
          providerForecasts = {},
          value,
          i,
          j,
          field,
          tempArr = Ember['default'].A([]),
          year,
          providerID,
          dates,
          prevDate,
          latestDate;

      switch (styleObj.type) {
        case 'IS':
          dataObject = this.get('dataObjectIS');break;
        case 'BS':
          dataObject = this.get('dataObjectBS');break;
        case 'CF':
          dataObject = this.get('dataObjectCF');break;
      }

      var object;

      Ember['default'].$.each(dataObject, function (key, fieldObj) {
        // key is a fieldID
        field = fieldObj.fieldID;
        yearlyFieldForecasts = fieldObj.yearlyFieldForecasts;

        if (styleObj.filedID === field) {
          Ember['default'].$.each(yearlyFieldForecasts, function (key, yearObj) {
            // key is a year
            year = key;
            providerList = yearObj.providerList;
            value = 0;
            i = 0;
            j = 0;
            if (parseInt(that.get('availableMaxActual')) < parseInt(key)) {
              yearSet.push(year);

              Ember['default'].$.each(providerList, function (key, providerObj) {
                // key is a providerID
                providerID = providerObj.providerID;
                providerForecasts = providerObj.providerForecasts;
                dates = Object.keys(providerForecasts);
                latestDate = 0;
                latestVal = 0;

                Ember['default'].$.each(providerForecasts, function (key, forecast) {
                  // key is a date string
                  if (forecast.value === '') {
                    return;
                  }
                  prevDate = latestDate;
                  latestDate = forecast.providedDate;
                  prevVal = latestVal;
                  latestVal = forecast.value;
                });

                if (latestDate !== 0 && latestVal !== 0) {
                  if (tempArr[providerID]) {
                    object = tempArr[providerID];
                  } else {
                    object = forecastObj['default'].create();
                    object.providerID = providerID;
                    tempArr[providerID] = object;
                  }

                  object.latestDate = latestDate;
                  object.prevDate = prevDate;
                  object.setPrevEst(year, prevVal);
                  object.setLatestEst(year, latestVal);
                }
              });
            }
          });
        }
      });

      if (tempArr) {
        this.set('popupDataMap', tempArr);
        var tempArrKeys = Object.keys(tempArr),
            headingYearSetter;
        headingYearSetter = tempArr[tempArrKeys[0]];
        for (var k = 0; k < tempArrKeys.length; k++) {
          that.get('popupDataArr').push(tempArr[tempArrKeys[k]]);
          if (Object.keys(headingYearSetter.keysOfLatest).length <= tempArr[tempArrKeys[k]].keysOfLatest.length) {
            headingYearSetter = tempArr[tempArrKeys[k]];
          }
        }
        this.set('headingYearSetter', headingYearSetter);
        this.set('prevEstDivisionSetter', Object.keys(headingYearSetter.keysOfPrev).length);
        this.set('latestEstDivisionSetter', Object.keys(headingYearSetter.keysOfLatest).length);
      }

      this.setChart();
    },

    actions: {
      loadPopup: function loadPopup(styleObj) {
        this.openPopup(styleObj);
      },
      unloadPopup: function unloadPopup() {
        this.closePopup();
      }
    },

    onAfterRender: function onAfterRender() {
      Ember['default'].$(document).ready(function () {
        Ember['default'].$('#scroll_tabs2').scrollTabs({
          scroll_distance: 200,
          // Pixel width distance for each scroll click.
          scroll_duration: 300,
          // <a href="http://www.jqueryscript.net/animation/">Animation</a> time for scrolling in milliseconds.
          left_arrow_size: 26,
          // Pixel width for the scroll button on the left side.
          right_arrow_size: 26,
          // Pixel width for the scroll button on the right side.
          click_callback: function click_callback(e) {
            // jshint ignore:line
            //Callback function on click. Accpets the click event object as an argument. The default callback function will change the page to the href in the 'rel' attribute of the item's span tag.
            var val = Ember['default'].$(this).attr('rel');
            if (val) {
              window.location.href = val;
            }
          }
        });
      });
    }
  });

  Ember['default'].Handlebars.helper('getDisplayData', function (dataObject, key, fieldID, isPopup) {
    var temp = '--';
    if (isPopup) {
      if (dataObject[key]) {
        var value = dataObject[key];
        if (FundamentalConstants['default'].NonMillionFields.indexOf(fieldID) >= 0 || value === '' || isNaN(value)) {
          temp = utils['default'].formatters.formatNumber(value, 2);
        } else {
          temp = utils['default'].formatters.formatNumber(value / 1000000, 2);
        }

        if (temp.charAt(0) === '-' && temp.charAt(1) !== '-') {
          temp = '(' + temp.substring(1, temp.length) + ')';
        }
        return temp;
      }
    } else {
      if (dataObject[key]) {
        var valueObject = dataObject[key];
        if (FundamentalConstants['default'].NonMillionFields.indexOf(fieldID) >= 0 || valueObject[fieldID] === '' || isNaN(valueObject[fieldID])) {
          temp = utils['default'].formatters.formatNumber(valueObject[fieldID], 2);
        } else {
          temp = utils['default'].formatters.formatNumber(valueObject[fieldID] / 1000000, 2);
        }

        if (temp.charAt(0) === '-' && temp.charAt(1) !== '-') {
          temp = '(' + temp.substring(1, temp.length) + ')';
        }
        return temp;
      }
    }

    return '--';
  });

  Ember['default'].Handlebars.helper('getDisplayHeader', function (dataObject, key, flag) {
    if (dataObject[key]) {
      var valueObject = dataObject[key];
      if (flag === 1) {
        return 'Est. ' + valueObject['DUR_YEAR'];
      } else if (flag === 2) {
        return 'Actual ' + valueObject['DUR_YEAR'];
      }
    }
    return '';
  });

  Ember['default'].Handlebars.helper('getAvgData', function (dataObject, key, fieldID, isLatest) {
    var temp = '--',
        keys,
        value = 0,
        k = 0;
    if (dataObject) {
      if (isLatest) {
        keys = Object.keys(dataObject);
        for (var i = 0; i < keys.length; i++) {
          if (isNaN(parseInt(dataObject[keys[i]].latestEstimates[key]))) {
            continue;
          }
          if (parseInt(dataObject[keys[i]].latestEstimates[key]) !== 0) {
            k++;
          }
          value += parseInt(dataObject[keys[i]].latestEstimates[key]);
        }
        value = value / k;
        if (FundamentalConstants['default'].NonMillionFields.indexOf(fieldID) >= 0 || value === '' || isNaN(value)) {
          temp = utils['default'].formatters.formatNumber(value, 2);
        } else {
          temp = utils['default'].formatters.formatNumber(value / 1000000, 2);
        }
        if (temp.charAt(0) === '-' && temp.charAt(1) !== '-') {
          temp = '(' + temp.substring(1, temp.length) + ')';
        }
        return temp;
      } else {
        keys = Object.keys(dataObject);
        for (var j = 0; j < keys.length; j++) {
          if (isNaN(parseInt(dataObject[keys[j]].prevEstimates[key]))) {
            continue;
          }
          if (parseInt(dataObject[keys[j]].prevEstimates[key]) !== 0) {
            k++;
          }
          value += parseInt(dataObject[keys[j]].prevEstimates[key]);
        }
        value = value / k;
        if (FundamentalConstants['default'].NonMillionFields.indexOf(fieldID) >= 0 || value === '' || isNaN(value)) {
          temp = utils['default'].formatters.formatNumber(value, 2);
        } else {
          temp = utils['default'].formatters.formatNumber(value / 1000000, 2);
        }
        if (temp.charAt(0) === '-' && temp.charAt(1) !== '-') {
          temp = '(' + temp.substring(1, temp.length) + ')';
        }
        return temp;
      }
    }
    return '--';
  });

  Ember['default'].Handlebars.helper('getSubCatName', function (langArr, displayStyleObject) {
    if (displayStyleObject) {
      switch (displayStyleObject[0].type) {
        case 'IS':
          return langArr['incomeStat'];
        case 'BS':
          return langArr['balanceSheet'];
        case 'CF':
          return langArr['cashFlow'];
      }
    }
    return '';
  });

  Ember['default'].Handlebars.helper('estimate-chart', estimateChart['default']);

  Ember['default'].Handlebars.helper('getSubTitle', function (subTitleObj) {
    if (subTitleObj[0]) {
      return subTitleObj[0].groupHeadDesc;
    }
    return '';
  });

});