define('ir-app/controllers/ir-widgets/news-widgets/news-tiled-view', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/decypha/decypha-service', 'ir-app/models/decypha/decypha-service-constants', 'ir-app/models/price/price-protocols/mix-web/mix-request-generator', 'ir-app/utils/utils'], function (exports, Ember, BaseController, DecyphaService, DecyphaConstant, RequestGenerator, Utils) {

    'use strict';

    /**
     * Created by udaras on 10/12/2016.
     */
    exports['default'] = BaseController['default'].extend({
        newsList: undefined,
        pageSize: DecyphaConstant['default'].NewsSearchPageSize,
        count: 0,
        current: 0,
        newsType: undefined, // will set from configs
        newsDetailRouter: '',
        scrollContainerRef: undefined,

        onPrepareData: function onPrepareData() {
            this.startLoadingProgress();
            this.setErrorMessage();
            this.sendNewsRequest(true);
        },
        onCheckDataAvailability: function onCheckDataAvailability() {
            return this.get('count') && this.get('count') > 0;
        },
        sendNewsRequest: function sendNewsRequest(isInitial) {
            var that = this;
            DecyphaService['default'].sendNewsRequest({
                pageSize: this.get('pageSize'),
                pageIndex: this.get('current'),
                newsType: this.get('newsType'),
                reqSuccessFn: function reqSuccessFn(newsData) {
                    if (newsData && newsData.DATA && newsData.COUNT && newsData.COUNT > 0) {

                        if (isInitial) {
                            that.set('firstNews', newsData.DATA[0]);
                            that.set('secondNewsCol1', newsData.DATA[1] ? newsData.DATA[1] : undefined);
                            that.set('secondNewsCol2', newsData.DATA[2] ? newsData.DATA[2] : undefined);
                            that.set('newsList', newsData.DATA.length > 3 ? newsData.DATA.slice(3) : []);
                            that.set('count', newsData.COUNT);
                        } else {
                            that.get('newsList').pushObjects(newsData.DATA);
                        }
                    } else {
                        Utils['default'].logger.logError('No News Available');
                    }
                    that.onDataSuccess();
                },
                reqFailureFn: function reqFailureFn() {
                    that.onDataError();
                }
            });
        },
        actions: {
            pageChanged: function pageChanged(current) {
                this.set('current', current);
                this.sendNewsRequest(false);
            }
        },
        onLanguageChanged: function onLanguageChanged() {
            this.onPrepareData();
        }
    });

    Ember['default'].Handlebars.helper('getImgUrl', function (id, lang, imageSize) {
        return RequestGenerator['default'].generateNewsResourceDownloadUrl(id, lang, imageSize);
    });

});