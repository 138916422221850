define('ir-app/models/price/price-protocols/mix-web/ibe-mix-response-handler', ['exports', 'ember', 'ir-app/models/price/price-service', 'ir-app/models/price/price-constants', 'ir-app/utils/utils', 'ir-app/models/price/price-business-entities/fundamentals/fundamental-data-holder'], function (exports, Ember, priceService, PriceConstants, utils, fundamentalDataHolder) {

    'use strict';

    /**
     * Created by akilam on 3/23/2017.
     */

    exports['default'] = (function () {

        var processClientFundamentalDataResponseForPortal = function processClientFundamentalDataResponseForPortal(dataObj, exchange, symbol, language, mode, isCal) {
            try {
                var viewTypes = ['Q', 'A'];

                for (var j = 0; j < viewTypes.length; j++) {
                    mode = viewTypes[j];
                    _clientFundamentalDataResponseHandler(dataObj, exchange, symbol, language, mode, isCal);
                }
            } catch (x) {
                utils['default'].logger.logError('Error in processing fundamental data : ' + x);
            }
        };

        var _clientFundamentalDataResponseHandler = function _clientFundamentalDataResponseHandler(dataObj, exchange, symbol, language, mode, isCal) {
            if (dataObj.DAT && dataObj.HED) {
                var dtArray, headingArr, jObj, attrList;

                var dataHolderObj,
                    i,
                    data = {},
                    preProcess = {},
                    storeKey;
                dataHolderObj = fundamentalDataHolder['default'].create({
                    styleTable: {},
                    dataTable: {},
                    minMaxTable: {},
                    symbol: undefined,
                    exchange: undefined
                });

                if (dataObj.DAT.IS) {
                    headingArr = dataObj.HED.IS.split(utils['default'].Constants.StringConst.Pipe);
                    attrList = Ember['default'].A();

                    Ember['default'].$.each(dataObj.DAT.IS, function (key, val) {
                        dtArray = val.split(utils['default'].Constants.StringConst.Pipe);
                        preProcess = {};
                        data = {};
                        for (i = 0; i < headingArr.length; i++) {
                            preProcess[headingArr[i]] = dtArray[i];
                        }

                        jObj = JSON.parse(preProcess.ATTRIBUTES);
                        for (i = 0; i < jObj.length; i++) {
                            if (attrList.length === undefined || attrList.indexOf(jObj[i].attribute) < 0) {
                                dataHolderObj.setStyleTable(PriceConstants['default'].FinancialStatementType.IncomeStatement, jObj[i].attribute, jObj[i].level, jObj[i].fact_sheet, jObj[i].chart);
                                attrList.push(jObj[i].attribute);
                            }
                            data[jObj[i].attribute] = jObj[i].value;
                        }

                        storeKey = [preProcess['YEAR'], preProcess['QUARTER']].join(utils['default'].Constants.StringConst.Tilde);
                        var CALENDER_PERIOD = parseInt(preProcess['QUARTER']);

                        if (isCal === PriceConstants['default'].PeriodBasis.Calendar) {
                            if (mode === 'Q' && (CALENDER_PERIOD === 1 || CALENDER_PERIOD === 2 || CALENDER_PERIOD === 3 || CALENDER_PERIOD === 4)) {
                                dataHolderObj.setDataTable(PriceConstants['default'].FinancialStatementType.IncomeStatement, storeKey, data);
                            } else if (mode === 'A' && CALENDER_PERIOD === 5) {
                                dataHolderObj.setDataTable(PriceConstants['default'].FinancialStatementType.IncomeStatement, storeKey, data);
                            }
                        }
                    });
                }

                if (dataObj.DAT.BS) {
                    headingArr = dataObj.HED.BS.split(utils['default'].Constants.StringConst.Pipe);
                    attrList = Ember['default'].A();

                    Ember['default'].$.each(dataObj.DAT.BS, function (key, val) {
                        dtArray = val.split(utils['default'].Constants.StringConst.Pipe);
                        preProcess = {};
                        data = {};
                        for (i = 0; i < headingArr.length; i++) {
                            preProcess[headingArr[i]] = dtArray[i];
                        }

                        jObj = JSON.parse(preProcess.ATTRIBUTES);
                        for (i = 0; i < jObj.length; i++) {
                            if (attrList.length === undefined || attrList.indexOf(jObj[i].attribute) < 0) {
                                dataHolderObj.setStyleTable(PriceConstants['default'].FinancialStatementType.BalanceSheet, jObj[i].attribute, jObj[i].level, jObj[i].fact_sheet, jObj[i].chart);
                                attrList.push(jObj[i].attribute);
                            }
                            data[jObj[i].attribute] = jObj[i].value;
                        }

                        storeKey = [preProcess['YEAR'], preProcess['QUARTER']].join(utils['default'].Constants.StringConst.Tilde);
                        var CALENDER_PERIOD = parseInt(preProcess['QUARTER']);

                        if (isCal === PriceConstants['default'].PeriodBasis.Calendar) {
                            if (mode === 'Q' && (CALENDER_PERIOD === 1 || CALENDER_PERIOD === 2 || CALENDER_PERIOD === 3 || CALENDER_PERIOD === 4)) {
                                dataHolderObj.setDataTable(PriceConstants['default'].FinancialStatementType.BalanceSheet, storeKey, data);
                            } else if (mode === 'A' && CALENDER_PERIOD === 5) {
                                dataHolderObj.setDataTable(PriceConstants['default'].FinancialStatementType.BalanceSheet, storeKey, data);
                            }
                        }
                    });
                }

                if (dataObj.DAT.CF) {
                    headingArr = dataObj.HED.CF.split(utils['default'].Constants.StringConst.Pipe);
                    attrList = Ember['default'].A();

                    Ember['default'].$.each(dataObj.DAT.CF, function (key, val) {
                        dtArray = val.split(utils['default'].Constants.StringConst.Pipe);
                        preProcess = {};
                        data = {};
                        for (i = 0; i < headingArr.length; i++) {
                            preProcess[headingArr[i]] = dtArray[i];
                        }

                        jObj = JSON.parse(preProcess.ATTRIBUTES);
                        for (i = 0; i < jObj.length; i++) {
                            if (attrList.length === undefined || attrList.indexOf(jObj[i].attribute) < 0) {
                                dataHolderObj.setStyleTable(PriceConstants['default'].FinancialStatementType.CashFlow, jObj[i].attribute, jObj[i].level, jObj[i].fact_sheet, jObj[i].chart);
                                attrList.push(jObj[i].attribute);
                            }
                            data[jObj[i].attribute] = jObj[i].value;
                        }

                        storeKey = [preProcess['YEAR'], preProcess['QUARTER']].join(utils['default'].Constants.StringConst.Tilde);
                        var CALENDER_PERIOD = parseInt(preProcess['QUARTER']);

                        if (isCal === PriceConstants['default'].PeriodBasis.Calendar) {
                            if (mode === 'Q' && (CALENDER_PERIOD === 1 || CALENDER_PERIOD === 2 || CALENDER_PERIOD === 3 || CALENDER_PERIOD === 4)) {
                                dataHolderObj.setDataTable(PriceConstants['default'].FinancialStatementType.CashFlow, storeKey, data);
                            } else if (mode === 'A' && CALENDER_PERIOD === 5) {
                                dataHolderObj.setDataTable(PriceConstants['default'].FinancialStatementType.CashFlow, storeKey, data);
                            }
                        }
                    });
                }

                if (dataObj.DAT.R) {
                    headingArr = dataObj.HED.R.split(utils['default'].Constants.StringConst.Pipe);
                    attrList = Ember['default'].A();

                    Ember['default'].$.each(dataObj.DAT.R, function (key, val) {
                        dtArray = val.split(utils['default'].Constants.StringConst.Pipe);
                        preProcess = {};
                        data = {};
                        for (i = 0; i < headingArr.length; i++) {
                            preProcess[headingArr[i]] = dtArray[i];
                        }

                        jObj = JSON.parse(preProcess.ATTRIBUTES);
                        for (i = 0; i < jObj.length; i++) {
                            if (attrList.length === undefined || attrList.indexOf(jObj[i].attribute) < 0) {
                                dataHolderObj.setStyleTable('R', jObj[i].attribute, jObj[i].level, jObj[i].fact_sheet, jObj[i].chart); // TODO [Akila] use an enum instead of 'R'
                                attrList.push(jObj[i].attribute);
                            }
                            data[jObj[i].attribute] = jObj[i].value;
                        }

                        storeKey = [preProcess['YEAR'], preProcess['QUARTER']].join(utils['default'].Constants.StringConst.Tilde);
                        var CALENDER_PERIOD = parseInt(preProcess['QUARTER']);

                        if (isCal === PriceConstants['default'].PeriodBasis.Calendar) {
                            if (mode === 'Q' && (CALENDER_PERIOD === 1 || CALENDER_PERIOD === 2 || CALENDER_PERIOD === 3 || CALENDER_PERIOD === 4)) {
                                dataHolderObj.setDataTable('R', storeKey, data); // TODO [Akila] use an enum instead of 'R'
                            } else if (mode === 'A' && CALENDER_PERIOD === 5) {
                                    dataHolderObj.setDataTable('R', storeKey, data); // TODO [Akila] use an enum instead of 'R'
                                }
                        }
                    });
                }

                priceService['default'].clientFundamentalDS.addDataHolderToStore(exchange, symbol, dataHolderObj, mode, isCal);
            }
        };

        var processCompanyOwnershipLimitsResponse = function processCompanyOwnershipLimitsResponse(dataObj, exchange, symbol, language) {
            var success = true;
            try {
                if (dataObj.DAT && dataObj.HED) {
                    var dtArray, cpObj, limitList;
                    cpObj = priceService['default'].companyProfileDS.getStoredObject(exchange, symbol, language);
                    if (dataObj.DAT.CDS && dataObj.DAT.CDS.OWLMD) {
                        limitList = _getHeaderIndexList(dataObj.HED.CDS.OWLMD, ['SERIES_ID', 'SERIES_NAME', 'OWNERSHIP_ELIGIBILITY', 'DIRECTION', 'OWNERSHIP_LIMIT', 'OWNERSHIP_VALUE_DATE', 'OWNERSHIP_VALUE', 'WEEKLY_PCT', 'YEARLY_PCT', 'MONTHLY_PCT', 'YTD_PCT', 'THREE_MONTH_PCT']);
                        var colObj;

                        Ember['default'].$.each(dataObj.DAT.CDS.OWLMD, function (key, val) {
                            dtArray = val.split(utils['default'].Constants.StringConst.Pipe);
                            colObj = priceService['default'].companyProfileDS.createCompanyOwnershipLimits();

                            colObj.setData({
                                seriesId: dtArray[limitList.SERIES_ID],
                                seriesName: dtArray[limitList.SERIES_NAME],
                                eligibility: dtArray[limitList.OWNERSHIP_ELIGIBILITY],
                                direction: dtArray[limitList.DIRECTION],
                                limit: dtArray[limitList.OWNERSHIP_LIMIT],
                                valueDate: dtArray[limitList.OWNERSHIP_VALUE_DATE],
                                value: dtArray[limitList.OWNERSHIP_VALUE],
                                weeklyPctChg: dtArray[limitList.WEEKLY_PCT],
                                monthlyPctChg: dtArray[limitList.MONTHLY_PCT],
                                threeMonthsPctChg: dtArray[limitList.THREE_MONTH_PCT],
                                ytdPctChg: dtArray[limitList.YEARLY_PCT],
                                yearPctChg: dtArray[limitList.YTD_PCT]
                            });
                            cpObj.ownershipLimits.pushObject(colObj);
                        });
                    }
                }
            } catch (x) {
                success = false;
                utils['default'].logger.logError('Error in Company Ownership Data : ' + x);
            }
            return success;
        };

        //
        // Helper functions
        //
        var _getHeaderIndexList = function _getHeaderIndexList(headerObj, headerFields) {
            return headerObj.split(utils['default'].Constants.StringConst.Pipe).indicesOf(headerFields);
        };

        return {
            processClientFundamentalDataResponseForPortal: processClientFundamentalDataResponseForPortal,
            processCompanyOwnershipLimitsResponse: processCompanyOwnershipLimitsResponse
        };
    })();

});