define('ir-app/models/price/price-data-stores/base-data-store', ['exports', 'ember', 'ir-app/utils/utils'], function (exports, Ember, utils) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        dataStore: {},
        init: function init() {
            this._super();
            this.set('dataStore', Ember['default'].A());
        },
        /**
         * Override this to return the stored data entity of the store
         */
        getStoredObject: function getStoredObject() {},

        /**
         * Override this to create data store cache object
         */
        createStoreCacheObj: function createStoreCacheObj() {},

        /**
         * Provide the Data Store cache key of given parameter array
         */
        getKey: function getKey(keyParams) {
            return keyParams.join(utils['default'].Constants.StringConst.Tilde);
        },

        /**
         * populate meta data for Data Store specific data types
         * @param storeCacheObj
         * @param DataTypes
         */
        initMetaData: function initMetaData(storeCacheObj, dataTypes) {
            for (var dataType in dataTypes) {
                if (dataTypes.hasOwnProperty(dataType)) {
                    storeCacheObj.metaObj[dataTypes[dataType]] = {
                        isAvailable: false,
                        isRequested: false,
                        successFns: [],
                        errorFns: []
                    };
                }
            }
        },

        /**
         * add success and error call back functions to the cache object for the given data type
         * @param cachedObj
         * @param dataType
         * @param successFn
         * @param errorFn
         */
        addCallbackFunctions: function addCallbackFunctions(cachedObj, dataType, successFn, errorFn) {
            if (successFn) {
                cachedObj.metaObj[dataType].successFns[cachedObj.metaObj[dataType].successFns.length] = successFn;
            }
            if (errorFn) {
                cachedObj.metaObj[dataType].errorFns[cachedObj.metaObj[dataType].errorFns.length] = errorFn;
            }
        }

    });

});