define('ir-app/models/price/price-data-stores/capital-action-data-store', ['exports', 'ember', 'ir-app/models/price/price-business-entities/corporate-action', 'ir-app/models/price/price-business-entities/capital-action', 'ir-app/models/price/price-business-entities/corporate-meta', 'ir-app/models/price/price-service', 'ir-app/models/price/price-constants', 'ir-app/utils/utils', 'ir-app/models/price/price-data-stores/base-data-store'], function (exports, Ember, corporateAction, capitalAction, actionTypes, priceService, priceConstants, utils, BaseStore) {

    'use strict';

    exports['default'] = BaseStore['default'].extend({

        /**
         * Override base method
         * get stored object... if dataType is available initialize data for the DS object.
         * @param exchange
         * @param symbol
         * @param language
         * @param dataType capital
         * @param params
         * @param successFn functions to run on success data loading
         * @param errorFn   functions to run on error
         * @param isCachedData (optional) check if the object need to be cached
         * @returns {*}
         */
        getStoredObject: function getStoredObject(exchange, symbol, language, dataType, params, successFn, errorFn, isCachedData) {
            var key = this.getKey([exchange, symbol, language]);
            var cachedObj = this.dataStore[key];

            var postSuccess = function postSuccess() {
                cachedObj.metaObj[dataType].isAvailable = true;
                cachedObj.metaObj[dataType].isRequested = false;
                var successFns = cachedObj.metaObj[dataType].successFns;

                Ember['default'].$.each(successFns, function (index, sFn) {
                    if (Ember['default'].$.isFunction(sFn)) {
                        sFn();
                    }
                });
                cachedObj.metaObj[dataType].successFns = [];
            };

            var error = function error() {
                cachedObj.metaObj[dataType].isAvailable = false;
                cachedObj.metaObj[dataType].isRequested = false;
                var errorFns = cachedObj.metaObj[dataType].errorFns;

                Ember['default'].$.each(errorFns, function (index, eFn) {
                    if (Ember['default'].$.isFunction(eFn)) {
                        eFn();
                    }
                });
                cachedObj.metaObj[dataType].errorFns = [];
            };

            if (!cachedObj || isCachedData) {
                cachedObj = this.createStoreCacheObj(exchange, symbol, language);
            }

            if (dataType === undefined) {
                // no data type defined. Assume no data initialization is needed.
                return cachedObj.dataObj;
            }

            if (cachedObj.metaObj[dataType].isAvailable) {
                if (Ember['default'].$.isFunction(successFn)) {
                    successFn();
                }
            } else {
                // data not available state
                this.addCallbackFunctions(cachedObj, dataType, successFn, errorFn);

                if (!cachedObj.metaObj[dataType].isRequested) {
                    // data not available and already NO earlier request is processing
                    cachedObj.metaObj[dataType].isRequested = true;
                    switch (dataType) {
                        case priceConstants['default'].DataTypes.CorporateActionDataStore.CapitalActionInfo:
                            // capital Action
                            priceService['default'].sendCapitalActionsRequest(exchange, symbol, language, dataType, params, postSuccess, error);
                            break;

                        default:
                            utils['default'].logger.logError('Unsupported company-profile-data-store Data Type: ' + dataType);
                            break;
                    }
                }
            }

            return cachedObj.dataObj;
        },

        /**
         * Override Method
         * @param exchange
         * @param symbol
         * @param language
         */
        createStoreCacheObj: function createStoreCacheObj(exchange, symbol, language) {
            var key = this.getKey([exchange, symbol, language]);
            var storeCacheObj = {
                dataObj: {},
                metaObj: {}
            };

            storeCacheObj.dataObj = corporateAction['default'].create({});

            var corporateDataTypes = priceConstants['default'].DataTypes.CorporateActionDataStore;
            this.initMetaData(storeCacheObj, corporateDataTypes);
            this.dataStore[key] = storeCacheObj;
            return storeCacheObj;
        },

        createCapitalAction: function createCapitalAction() {
            return capitalAction['default'].create();
        },

        createActionTypes: function createActionTypes() {
            return actionTypes['default'].create();
        },

        getCapitalActionByActionId: function getCapitalActionByActionId(exchange, symbol, language, dataType, actionId) {
            var key = this.getKey([exchange, symbol, language]);
            var cachedObj = this.dataStore[key].dataObj.capitalAction;
            if (cachedObj && cachedObj.length > 0) {
                var capAction = cachedObj.filter(function (item) {
                    return item.actionId === actionId;
                });
                return capAction[0];
            } else {
                return null;
            }
        },

        getActionDetail: function getActionDetail(exchange, symbol, language, dataType, actionId, SuccessFn, errorFn) {
            priceService['default'].sendCapitaActionDetailRequest(exchange, symbol, language, dataType, actionId, SuccessFn, errorFn);
        }

    }).create();

});