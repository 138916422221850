define('ir-app/controllers/ir-widgets/index-overview-widgets/sectors-selection-dropdown', ['exports', 'ir-app/controllers/base-array-controller', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/utils/utils', 'ir-app/app-events'], function (exports, BaseArrayController, priceService, sharedService, utils, AppEvents) {

    'use strict';

    /**
     * Created by lakmaln on 6/22/2016.
     */

    exports['default'] = BaseArrayController['default'].extend({
        selectedKey: '',
        selectedIndexTitle: '',
        sortProperties: ['sDes'],
        sortAscending: true,

        onLoadWidget: function onLoadWidget() {
            var that = this;
            priceService['default'].downloadParamsSymbolMasterForExchange({
                exchange: that.get('exg'),
                language: sharedService['default'].userSettings.currentLanguage,

                successFn: function successFn() {
                    that.onSuccess();
                },

                errorFn: function errorFn() {
                    that.onError();
                }
            });
        },

        loadSectorsList: function loadSectorsList() {
            var exchange = this.get('exg');
            this.set('model', priceService['default'].stockDS.getIndexCollectionForExchange(exchange));

            if (this.get('generatedArgs')) {
                var selectedSector = this.get('generatedArgs')[0];
                this.set('selectedKey', selectedSector);
            } else {
                // According to Para ,Always should select the main index when tab changes happen. So change the logic according to that request..
                this.selectMainIndex();
            }
        },

        selectMainIndex: function selectMainIndex() {
            var idxList = this.get('arrangedContent');
            if (idxList.length > 0) {
                for (var j = 0; j < idxList.length; j++) {
                    if (idxList[j].isMainIdx) {
                        this.set('selectedKey', idxList[j].sym);
                        break;
                    }
                }

                if (!this.get('selectedKey')) {
                    this.set('selectedKey', idxList[0].sym);
                }
            }
        },

        setSelectedIndexTitle: (function () {
            var selectedIndexCode = this.get('selectedKey');

            // If not update the local storage and proceed
            utils['default'].webStorage.addString(this.getCacheKey(), selectedIndexCode);
            var indexObj = priceService['default'].stockDS.getStock(this.get('exg'), selectedIndexCode);
            this.set('selectedIndexTitle', indexObj.sDes);

            // Notify the widgets
            AppEvents['default'].onSymbolChanged(indexObj.sym, indexObj.exg);
        }).observes('selectedKey'),

        getCacheKey: function getCacheKey() {
            return utils['default'].webStorage.getKey(utils['default'].Constants.CacheKeys.CurrentlySelectedIndex, this.get('exg'));
        },

        onSuccess: function onSuccess() {
            this.loadSectorsList();
        },

        onError: function onError() {}
    });

});