define('ir-app/views/table/cell', ['exports', 'ember-table/views/table-cell', 'ir-app/views/table/table-mixins/blink-mixin', 'ir-app/views/table/table-mixins/condition-mixin', 'ir-app/views/table/table-mixins/formatter-mixin', 'ir-app/views/table/table-mixins/style-mixin'], function (exports, TableCell, blinkMixin, conditionMixin, formatterMixin, styleMixin) {

    'use strict';

    exports['default'] = TableCell['default'].extend(blinkMixin['default'], conditionMixin['default'], formatterMixin['default'], styleMixin['default'], {
        templateName: 'table/views/cell-cell',

        columnId: (function () {
            return this.get('column.contentPath');
        }).property()
    });

});