define('ir-app/controllers/ir-widgets/announcement-widgets/announcements-ticker', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/models/price/price-constants', 'ir-app/models/shared/shared-service'], function (exports, Ember, BaseController, priceService, PriceConstant, sharedService) {

    'use strict';

    /* global Queue */
    exports['default'] = BaseController['default'].extend({

        exchange: '',
        lang: '',
        contentType: PriceConstant['default'].NewsAnnouncementsContentType.Announcements,
        pageSize: PriceConstant['default'].AnnouncementSearchPageSize,
        count: 0,
        isFirstRound: true,
        displayedAnnouncements: 5,
        elementQueue: undefined,
        elementQueueMaxSize: 50,
        refreshTime: 500,
        activeTicker: 1,
        visibleTicker: 2,
        tickerElementOne: undefined,
        tickerOneItems: Ember['default'].A(),
        tickerOneArrayIndex: 0,
        tickerElementTwo: undefined,
        tickerTwoItems: Ember['default'].A(),
        tickerTwoArrayIndex: 0,
        tickerIndex: 0,
        singleTickerItemCount: 0,
        tickerOneDataLoaded: false,
        tickerTwoDataLoaded: false,
        arrayState: false,
        sampleArr: Ember['default'].A(),
        symbols: '',

        onPrepareData: function onPrepareData() {
            var symbols = this.get('symbols');
            if (!symbols) {
                this.set('symbols', [{ 'exg': this.get('exg'), 'sym': this.get('sym') }]);
            }
            // this.set('singleTickerItemCount', Math.round(this.get('displayedAnnouncements') * symbols.length/2));
            this.set('singleTickerItemCount', Math.round(this.get('displayedAnnouncements') * symbols.length));
            this.set('exchange', this.get('exg'));
            this.set('lang', sharedService['default'].userSettings.currentLanguage.toLowerCase());
            this.set('pageSize', this.get('pageSize'));
            this.set('contentType', this.get('contentType'));
            this.elementQueue = new Queue(this.elementQueueMaxSize);
            //check for symbol list from arguments

            this.sendSampleRequest();
        },

        sendSampleRequest: function sendSampleRequest() {
            var symbols = this.get('symbols');
            var that = this;
            var sampleIndex = 0;
            Ember['default'].$.each(symbols, function (key, symbol) {
                priceService['default'].sendAnnouncementSearchRequest({
                    exchange: symbol.exg,
                    symbol: symbol.sym,
                    pageSize: that.get('pageSize'),
                    startDate: undefined,
                    endDate: undefined,
                    reqSuccessFn: function reqSuccessFn() {
                        var symbolAnnouncement = priceService['default'].announcementDS.getAnnCollectionBySymbol(symbol.exg, symbol.sym);
                        var slicedAnnouncement = symbolAnnouncement.slice(0, that.displayedAnnouncements);
                        Ember['default'].$.each(slicedAnnouncement, function (key, announcement) {
                            that.sampleArr.push(announcement);
                        });
                        sampleIndex++;
                        if (sampleIndex === symbols.length) {
                            that._clearTicker();
                            that.sendAnnouncementRequest();
                        }
                    },
                    reqFailureFn: function reqFailureFn() {
                        that.onDataError();
                    }
                }, priceService['default'].announcementDS.getAnnCollectionBySymbol(symbol.exg, symbol.sym));
            });
        },
        onAfterRender: function onAfterRender() {
            this._setTickerUI();
        },

        setErrorMessage: function setErrorMessage() {
            this.set('errorMessage', this.get('app').lang.messages.dataNotAvailable);
        },

        onLanguageChanged: function onLanguageChanged() {
            this.onPrepareData();
        },

        /*send request for announcement
         slice announcement array
         add announcements to queue*/

        sendAnnouncementRequest: function sendAnnouncementRequest() {
            window.localStorage.clear();
            var symbols = this.get('symbols');
            var that = this;
            Ember['default'].$.each(symbols, function (key, symbol) {
                priceService['default'].announcementDS.removeAnnCollectionBySymbol(symbol.exg, symbol.sym);
                if (that.get('contentType') === PriceConstant['default'].NewsAnnouncementsContentType.Announcements) {
                    priceService['default'].sendAnnouncementSearchRequest({
                        exchange: symbol.exg,
                        symbol: symbol.sym,
                        pageSize: that.get('pageSize'),
                        startDate: undefined,
                        endDate: undefined,
                        reqSuccessFn: function reqSuccessFn() {
                            var symbolAnnouncement = priceService['default'].announcementDS.getAnnCollectionBySymbol(symbol.exg, symbol.sym);
                            var slicedAnnouncement = symbolAnnouncement.slice(0, that.displayedAnnouncements);

                            Ember['default'].$.each(slicedAnnouncement, function (key, annoncement) {
                                var elementQueueLength = that.elementQueue.getLength();
                                if (elementQueueLength >= that.elementQueueMaxSize) {
                                    that.elementQueue.dequeue();
                                }
                                that.elementQueue.enqueue(annoncement);
                            });
                            that._prepareTicker();
                        },
                        reqFailureFn: function reqFailureFn() {
                            that.onDataError();
                        }
                    }, priceService['default'].announcementDS.getAnnCollectionBySymbol(symbol.exg, symbol.sym));
                } else {
                    priceService['default'].sendNewsSearchRequest({
                        exchange: symbol.exg,
                        symbol: symbol.sym,
                        pageSize: that.get('pageSize'),
                        reqSuccessFn: function reqSuccessFn() {
                            var symbolAnnouncement = priceService['default'].announcementDS.getAnnCollectionBySymbol(symbol.exg, symbol.sym);
                            var slicedAnnouncement = symbolAnnouncement.slice(0, that.displayedAnnouncements);

                            Ember['default'].$.each(slicedAnnouncement, function (key, annoncement) {
                                var elementQueueLength = that.elementQueue.getLength();
                                if (elementQueueLength >= that.elementQueueMaxSize) {
                                    that.elementQueue.dequeue();
                                }
                                that.elementQueue.enqueue(annoncement);
                            });
                            that._prepareTicker();
                        },
                        reqFailureFn: function reqFailureFn() {
                            that.onDataError();
                        }
                    }, priceService['default'].announcementDS.getAnnCollectionBySymbol(symbol.exg, symbol.sym));
                }
            });
        },

        _prepareTicker: function _prepareTicker() {
            this._addItemToTicker();
        },

        _setTickerUI: function _setTickerUI() {
            Ember['default'].$('.annticker').bind('webkitAnimationIteration', this._animationIterationFinished.bind(this));
            Ember['default'].$('.annticker').bind('mozAnimationIteration', this._animationIterationFinished.bind(this));
            Ember['default'].$('.annticker').bind('animationiteration', this._animationIterationFinished.bind(this));
        },

        //check the ticker states and pass the suitable ticker number for add announcement
        _addItemToTicker: function _addItemToTicker() {
            var that = this;
            if (this.isFirstRound || this.activeTicker !== this.visibleTicker) {
                if (this.activeTicker === 1) {
                    this._addItemToSingleTicker(1);
                } else {
                    this._addItemToSingleTicker(1); // add all items to ticker one
                }
            } else {
                    this.activeTicker = this.activeTicker === 1 ? 2 : 1;
                }
            setTimeout(function () {
                that._addItemToTicker();
            }, this.refreshTime);
        },

        // add announcement to suitable ticker
        _addItemToSingleTicker: function _addItemToSingleTicker(ticker) {
            var tickerDataLoaded = ticker === 1 ? this.tickerOneDataLoaded : this.tickerTwoDataLoaded;
            if (!tickerDataLoaded) {
                var item;
                var tickerArrayIndex = ticker === 1 ? this.tickerOneArrayIndex : this.tickerTwoArrayIndex;
                var tickerItems = ticker === 1 ? 'tickerOneItems' : 'tickerTwoItems';
                var itemObject = this.elementQueue.dequeue();
                if (itemObject) {
                    item = this.get(tickerItems).objectAt(tickerArrayIndex);
                    Ember['default'].set(item, 'obj', itemObject);
                }
                tickerArrayIndex++;
                this.tickerOneArrayIndex = ticker === 1 ? tickerArrayIndex : this.tickerOneArrayIndex;
                this.tickerTwoArrayIndex = ticker === 2 ? tickerArrayIndex : this.tickerTwoArrayIndex;

                if (tickerArrayIndex === this.get('tickerOneItems').length) {
                    if (ticker === 1) {
                        this.tickerOneDataLoaded = true;
                    } else {
                        this.tickerTwoDataLoaded = true;
                    }
                    this.tickerOneArrayIndex = ticker === 1 ? 0 : this.tickerOneArrayIndex;
                    this.tickerTwoArrayIndex = ticker === 2 ? 0 : this.tickerTwoArrayIndex;
                    // this.activeTicker = ticker === 1 ? 2 : 1;
                }
            }
        },

        _clearTicker: function _clearTicker() {
            // var tickerSize = Math.round(this.get('sampleArr').length / 2); comment to add all item to one ticker
            var tickerSize = Math.round(this.get('sampleArr').length);
            var tickerOneIt = this.get('tickerOneItems');
            var tickerTwoIt = this.get('tickerTwoItems');
            for (var i = 0; i < tickerSize; i++) {
                tickerOneIt.push({});
                tickerTwoIt.push({});
            }
            this.set('tickerOneItems', tickerOneIt);
            this.set('tickerTwoItems', tickerTwoIt);
            this.set('arrayState', true);
        },

        _animationIterationFinished: function _animationIterationFinished() {
            if (this.isFirstRound) {
                this.isFirstRound = false;
                this.visibleTicker = 1;
            } else {
                if (this.visibleTicker === 1) {
                    this.visibleTicker = 2;
                    this.activeTicker = 1;
                    this.tickerOneArrayIndex = 0;
                    this.tickerOneDataLoaded = false;
                } else {
                    this.visibleTicker = 1;
                    this.activeTicker = 2;
                    this.tickerTwoArrayIndex = 0;
                    this.tickerTwoDataLoaded = false;
                }
            }
        }

    });

    Ember['default'].Handlebars.helper('isArabic', function (lanKey) {
        if (lanKey) {
            return lanKey.includes('ا', 'ء', 'أ', 'إ') ? 'arabic-lan-text' : 'english-lan-text';
        }
    });

});