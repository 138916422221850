define('ir-app/views/custom-workspace/custom-layout-view', ['exports', 'ember', 'ir-app/models/shared/shared-service', 'ir-app/controllers/custom-workspace/template-generator', 'ir-app/utils/utils'], function (exports, Ember, sharedService, templateGenerator, utils) {

    'use strict';

    exports['default'] = Ember['default'].View.extend({
        hGap: 5, // Horizontal gap
        cellHeight: 5, // Set unit cell height
        amountOfCols: 12, // Amount of columns provided by grid stack framework
        maximumWidgetCount: 20, // Maximum allowed widget count

        loadTemplate: (function () {
            var viewHeight = Ember['default'].$(window).height(); // Height of the view port
            var workspace = sharedService['default'].userState.customWS;
            var controller = this.get('controller');
            var htmlArray = ['<div class="grid-stack">'];

            if (workspace && workspace.length > 0) {
                // If condition executes all the time except initial layout selection
                htmlArray = this.generateWorkspaceHtml(htmlArray, controller, workspace);
            } else {
                var rowCount = sharedService['default'].userState.customWsRowCount;
                var colCount = sharedService['default'].userState.customWsColCount;

                // Height of a single container is calculated by considering the overall height and the number of rows in the layout
                // This function is derived based on the height calculation function available in the grid stack framework
                // To be precise, below function is the inverse of the grid stack height calculation function
                // Values returning from this function is tested for most of the cases in different resolutions
                // But there may be exceptions encountered for some resolutions
                // top panel + footer = 145px
                var height = ((viewHeight - 145 - this.hGap * (rowCount - 1)) / rowCount - this.cellHeight) / (this.cellHeight + this.hGap) + 1;
                height = Math.floor(height);

                // Width of a single container is simply calculated by dividing the available column count by user selected column count
                var width = this.amountOfCols / colCount;

                var widgetCount = rowCount * colCount;
                var coordinates = this.calculateCoordinates(rowCount, colCount, height, width);
                var extraCoordinates = this.calculateExtraCoordinates(widgetCount, rowCount, colCount, height);

                htmlArray = this.generateLayoutHtml(htmlArray, controller, coordinates, extraCoordinates, height, width, widgetCount);
            }

            htmlArray[htmlArray.length] = '</div>';

            this.template = Ember['default'].HTMLBars.compile(htmlArray.join(utils['default'].Constants.StringConst.Empty));
        }).on('init'),

        generateLayoutHtml: function generateLayoutHtml(htmlArray, controller, coordinates, extraCoordinates, height, width, widgetCount) {
            for (var i = 0; i < this.maximumWidgetCount; i++) {
                var index = i + 1;

                if (i < widgetCount) {
                    Ember['default'].set(controller.outletMap, 'o' + index, 'border: solid #cecece 1px'); // TODO: [Bashitha] To be moved to a CSS
                    Ember['default'].set(controller.ctrlMap, 'c' + index, '');

                    htmlArray[htmlArray.length] = templateGenerator['default'].generateWorkspaceWidgetContainer(index, height, width, coordinates[i], true);
                } else {
                    Ember['default'].set(controller.outletMap, 'o' + index, '');
                    Ember['default'].set(controller.ctrlMap, 'c' + index, 'display: none');

                    htmlArray[htmlArray.length] = templateGenerator['default'].generateWorkspaceWidgetContainer(index, 1, 12, extraCoordinates[i], false);
                }

                Ember['default'].set(controller.widgetMap, 'w' + index, false);
            }

            return htmlArray;
        },

        generateWorkspaceHtml: function generateWorkspaceHtml(htmlArray, controller, workspace) {
            Ember['default'].$.each(workspace, function (key, containerObj) {
                var index = containerObj.i;
                var activeContainer = containerObj.act === utils['default'].Constants.Yes;

                htmlArray[htmlArray.length] = templateGenerator['default'].generateWorkspaceWidgetContainer(index, containerObj.h, containerObj.w, {
                    x: containerObj.x,
                    y: containerObj.y
                }, activeContainer);

                if (activeContainer) {
                    Ember['default'].set(controller.outletMap, 'o' + index, 'border: solid #cecece 1px'); // TODO: [Bashitha] To be moved to a CSS
                    Ember['default'].set(controller.ctrlMap, 'c' + index, '');
                } else {
                    Ember['default'].set(controller.outletMap, 'o' + index, '');
                    Ember['default'].set(controller.ctrlMap, 'c' + index, 'display: none');
                }

                Ember['default'].set(controller.widgetMap, 'w' + index, false);
            });

            return htmlArray;
        },

        calculateCoordinates: function calculateCoordinates(rowCount, colCount, height, width) {
            var coordinates = [];
            var index;

            for (var i = 0; i < rowCount; i++) {
                for (var j = 0; j < colCount; j++) {
                    index = colCount * i + j;
                    coordinates[index] = coordinates[index] || {};
                    coordinates[index].x = width * j;
                }
            }

            for (var k = 0; k < colCount; k++) {
                for (var l = 0; l < rowCount; l++) {
                    index = colCount * l + k;
                    coordinates[index] = coordinates[index] || {};
                    coordinates[index].y = height * l;
                }
            }

            return coordinates;
        },

        calculateExtraCoordinates: function calculateExtraCoordinates(widgetCount, rowCount, colCount, height) {
            var coordinates = [];
            var index = widgetCount;
            var extraWidgetCount = this.maximumWidgetCount - widgetCount;
            var extraRows = Math.ceil(extraWidgetCount / this.amountOfCols);
            var yStart = height * rowCount;

            for (var i = 0; i < extraRows; i++) {
                for (var j = 0; j < this.amountOfCols; j++) {
                    coordinates[index] = coordinates[index] || {};
                    coordinates[index].y = i * this.amountOfCols + yStart + j;
                    coordinates[index].x = 0;

                    index++;

                    if (index >= this.maximumWidgetCount) {
                        return coordinates;
                    }
                }
            }

            return coordinates;
        }
    });

});