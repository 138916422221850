define('ir-app/models/price/price-data-stores/fair-values-data-store', ['exports', 'ember', 'ir-app/models/price/price-data-stores/base-data-store', 'ir-app/models/price/price-business-entities/fair-value', 'ir-app/models/price/price-constants', 'ir-app/models/price/price-service', 'ir-app/utils/utils'], function (exports, Ember, BaseStore, fairValue, priceConstants, priceService, utils) {

  'use strict';

  /**
   * Created by lakmaln on 9/26/2016.
   */

  exports['default'] = BaseStore['default'].extend({

    fairValuesStore: Ember['default'].A(),
    providersList: Ember['default'].A(),

    getStoredObject: function getStoredObject(exchange, symbol, language, dataType, successFn, errorFn) {
      var key = this.getKey([exchange, symbol, language]);
      var cachedObj = this.dataStore[key];

      var postSuccess = function postSuccess() {
        cachedObj.metaObj[dataType].isAvailable = true;
        cachedObj.metaObj[dataType].isRequested = false;
        var successFns = cachedObj.metaObj[dataType].successFns;

        Ember['default'].$.each(successFns, function (index, sFn) {
          if (Ember['default'].$.isFunction(sFn)) {
            sFn();
          }
        });
        cachedObj.metaObj[dataType].successFns = [];
      };

      var error = function error() {
        cachedObj.metaObj[dataType].isAvailable = false;
        cachedObj.metaObj[dataType].isRequested = false;
        var errorFns = cachedObj.metaObj[dataType].errorFns;

        Ember['default'].$.each(errorFns, function (index, eFn) {
          if (Ember['default'].$.isFunction(eFn)) {
            eFn();
          }
        });
        cachedObj.metaObj[dataType].errorFns = [];
      };

      if (!cachedObj) {
        cachedObj = this.createStoreCacheObj(exchange, symbol, language);
      }

      if (dataType === undefined) {
        // no data type defined. Assume no data initialization is needed.
        return cachedObj.dataObj;
      }

      if (cachedObj.metaObj[dataType].isAvailable) {
        if (Ember['default'].$.isFunction(successFn)) {
          successFn();
        }
      } else {
        // data not available state
        this.addCallbackFunctions(cachedObj, dataType, successFn, errorFn);

        if (!cachedObj.metaObj[dataType].isRequested) {
          // data not available and already NO earlier request is processing
          cachedObj.metaObj[dataType].isRequested = true;
          switch (dataType) {
            case priceConstants['default'].DataTypes.FairValuesDataStore.FairValues:
              priceService['default'].sendFairValuesRequest(exchange, symbol, language, dataType, postSuccess, error);
              break;

            default:
              utils['default'].logger.logError('Unsupported fair-values-data-store Data Type: ' + dataType);
              break;
          }
        }
      }
      return cachedObj.dataObj;
    },

    createStoreCacheObj: function createStoreCacheObj(exchange, symbol, language) {
      var key = this.getKey([exchange, symbol, language]);
      var storeCacheObj = {
        dataObj: {},
        metaObj: {}
      };

      storeCacheObj.dataObj = fairValue['default'].create({
        exg: exchange,
        sym: symbol,
        lan: language
      });

      var fairValueTypes = priceConstants['default'].DataTypes.FairValuesDataStore;
      this.initMetaData(storeCacheObj, fairValueTypes);
      this.dataStore[key] = storeCacheObj;
      return storeCacheObj;
    },

    createNewFairValue: function createNewFairValue() {
      return fairValue['default'].create();
    },

    clearData: function clearData() {
      this.set('fairValuesStore', Ember['default'].A());
      this.set('providersList', Ember['default'].A());
    },

    addToProvidersList: function addToProvidersList(id, provider) {
      var providersCollection = this.get('providersList');

      if (!this.isInProvidersList(id)) {
        if (provider.endsWith("- RP")) {
          //Companies with - RP should be shown as they are the dedicated company profiles for contributing research reports and estimates which are only used to display data in IR
          providersCollection.pushObject({ id: id, provider: provider });
        }
      }
    },

    isInProvidersList: function isInProvidersList(id) {
      for (var i = 0; i < this.get('providersList').length; i++) {
        if (id === this.get('providersList')[i].id) {
          return true;
        }
      }
      return false;
    },

    getProvidersList: function getProvidersList() {
      return this.get('providersList');
    }
  }).create();

});