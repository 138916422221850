define('ir-app/models/price/price-data-stores/sector-data-store', ['exports', 'ember', 'ir-app/models/price/price-business-entities/sector', 'ir-app/utils/utils'], function (exports, Ember, sector, utils) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        store: {},
        sectorMapByExg: {},

        getSector: function getSector(exchange, secCode) {
            var key = utils['default'].keyGenerator.getKey(exchange, secCode);
            var currentStore = this.get('store');
            var sectorObj = currentStore[key];

            if (!sectorObj) {
                sectorObj = sector['default'].create({
                    sec: secCode,
                    model: Ember['default'].A([])
                });

                this.addToOtherCollections(exchange, sectorObj);
                currentStore[key] = sectorObj;
            }

            return sectorObj;
        },

        addToOtherCollections: function addToOtherCollections(exchange, sectorObj) {
            var currentStockMapByExg = this.get('sectorMapByExg');

            if (currentStockMapByExg[exchange]) {
                currentStockMapByExg[exchange].pushObject(sectorObj);
            } else {
                currentStockMapByExg[exchange] = Ember['default'].A([sectorObj]);
            }
        },

        getSectorCollectionByExchange: function getSectorCollectionByExchange(exchange) {
            var sectorMapByExg = this.get('sectorMapByExg');

            if (!sectorMapByExg[exchange]) {
                sectorMapByExg[exchange] = Ember['default'].A([]);
            }

            // set Ascending sorting for sector List
            var sortedMap = {};
            if (sectorMapByExg[exchange].length > 0) {
                sortedMap = sectorMapByExg[exchange].sort(function (a, b) {
                    return a.get('sortKey') - b.get('sortKey');
                });
            }
            return sortedMap;
        }

    }).create();

});