define('ir-app/controllers/ir-widgets/upper-panel/upper-panel-view', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service'], function (exports, Ember, BaseController, priceService, sharedService) {

    'use strict';

    exports['default'] = BaseController['default'].extend({

        idxDetails: [],
        selectedTab: undefined,
        lang: undefined,

        onPrepareData: function onPrepareData() {
            this.set('tab0.class', 'active');
            this.set('selectedTab', 0);
            this.set('lang', sharedService['default'].userSettings.currentLanguage.toLowerCase());
            this._getTabSelectedData(0);
        },

        _getTabSelectedData: function _getTabSelectedData(tabID) {
            var that = this;
            var tabName = that._getTabName(tabID);
            var tabDataLst = that.get(tabName).keys;
            that.set('clientStkPage', that.get(tabName).clientStkPage);
            var idx = null;
            that.set('idxDetails', []);
            var array = that.get('idxDetails');

            Ember['default'].$.each(tabDataLst, function (index, key) {
                idx = priceService['default'].stockDS.getStock(key.exg, key.idxCode);
                if (key.isIndex) {
                    priceService['default'].addIndexRequest(key.exg, key.idxCode);
                } else {
                    priceService['default'].addSymbolRequest(key.exg, key.idxCode);
                }

                var prop = ['idxDisp', sharedService['default'].userSettings.currentLanguage].join('');
                Ember['default'].set(idx, 'index', key[prop]);

                if (key.isUrlMapped) {
                    Ember['default'].set(idx, 'isUrlMapped', true);
                } else {
                    Ember['default'].set(idx, 'isUrlMapped', false);
                }

                if (key.isCurrency) {
                    Ember['default'].set(idx, 'mktLabel', '');
                    Ember['default'].set(idx, 'isCurrency', true);
                    Ember['default'].set(idx, 'isIndex', false);
                    Ember['default'].set(idx, 'deci', key.deci);
                } else if (!key.isCurrency && key.isIndex) {
                    prop = ['mktDisp', sharedService['default'].userSettings.currentLanguage].join('');
                    Ember['default'].set(idx, 'mktLabel', key[prop]);
                    Ember['default'].set(idx, 'isIndex', true);
                    Ember['default'].set(idx, 'isCurrency', false);
                } else {
                    Ember['default'].set(idx, 'isIndex', false);
                    Ember['default'].set(idx, 'isCurrency', false);
                }

                array.push(idx);
            });
        },

        onClearData: function onClearData() {
            this._clearDataOnSelectedTab(this.get('selectedTab'));
        },

        _clearDataOnSelectedTab: function _clearDataOnSelectedTab(tabID) {
            var tabName = this._getTabName(tabID);
            var keyList = this.get(tabName).keys;
            Ember['default'].$.each(keyList, function (index, key) {
                if (key.isIndex) {
                    priceService['default'].removeIndexRequest(key.exg, key.idxCode);
                } else {
                    priceService['default'].removeSymbolRequest(key.exg, key.idxCode);
                }
            });
        },

        _getTabName: function _getTabName(tabID) {
            return ['tab', tabID].join('');
        },

        _getTabCSSName: function _getTabCSSName(tabID) {
            return ['tab', tabID, '.class'].join('');
        },

        actions: {
            tabClick: function tabClick(option) {
                var tabID = this.get('selectedTab');
                this.set(this._getTabCSSName(tabID), 'dct');
                this._clearDataOnSelectedTab(tabID);

                // Override the values with the newly selected tab
                this.set('selectedTab', option);
                this.set(this._getTabCSSName(option), 'active');
                this._getTabSelectedData(option);
            }
        }
    });

});