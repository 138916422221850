define('ir-app/controllers/ir-mobile/media-room-widgets/linkedin', ['exports', 'ir-app/controllers/base-controller'], function (exports, BaseController) {

  'use strict';

  /**
   * Created by banushas on 3/29/2016.
   */

  exports['default'] = BaseController['default'].extend({

    onLoadWidget: function onLoadWidget() {}

  });

});