define('ir-app/components/stk-specific-components/fifty-two-wk-hl-slider', ['exports', 'ember', 'ir-app/utils/utils'], function (exports, Ember, utils) {

    'use strict';

    /**
     * Created by User on 2/5/2016.
     */
    exports['default'] = Ember['default'].Component.extend({
        layoutName: 'components/stk-specific-components/fifty-two-wk-hl-slider',

        calculateFiftyTwoWeekRange: (function () {
            var stock = this.get('stock');
            var value = 0;

            if (stock) {
                var lowValue = stock.l52;
                var highValue = stock.h52;
                var ltp = stock.ltp;
                var prvCls = stock.prvCls;
                var currentValue = ltp === 0 && prvCls > 0 ? prvCls : ltp;
                var diff = highValue - lowValue;

                if (diff !== 0) {
                    value = (currentValue - lowValue) / diff * 100;
                    value = value > 0 && value <= 100 ? value : 0;
                }

                return value;
            }

            return value;
        }).property('stock.ltp', 'stock.prvCls', 'stock.l52', 'stock.h52'),

        showValue: (function () {
            return utils['default'].formatters.formatNumber(this.get('calculateFiftyTwoWeekRange'), 2) + '%';
        }).property('calculateFiftyTwoWeekRange'),

        styles: (function () {
            var progress = parseInt(this.get('calculateFiftyTwoWeekRange'), 10);
            return 'width:' + progress + '%;'; // Used inline styles since width is dynamically and changed rapidly
        }).property('calculateFiftyTwoWeekRange')

    });

});