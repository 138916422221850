define('ir-app/controllers/ir-mobile/company-widgets/company-key-officers-info', ['exports', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/controllers/base-array-controller', 'ir-app/models/price/price-constants'], function (exports, priceService, sharedService, BaseArrayController, priceConstants) {

    'use strict';

    exports['default'] = BaseArrayController['default'].extend({
        sortProperties: ['desig'],
        sortAscending: true,

        onLoadWidget: function onLoadWidget() {
            this.setErrorMessage();
        },

        onPrepareData: function onPrepareData() {
            var that = this;
            this.startLoadingProgress();

            var keyOfficerInfo;
            if (this.utils.validators.isAvailable(this.get('companyId'))) {
                keyOfficerInfo = priceService['default'].companyProfileDS.getStoredObjectByCompanyId(this.get('companyId'), sharedService['default'].userSettings.currentLanguage, function () {
                    that.onDataSuccess();
                }, function () {
                    that.onDataError();
                }, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyManagementInfo).compKeyOfficers;
            } else {
                keyOfficerInfo = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, function () {
                    that.onDataSuccess();
                }, function () {
                    that.onDataError();
                }, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyManagementInfo).compKeyOfficers;
            }

            this.set('model', keyOfficerInfo);
        },

        onCheckDataAvailability: function onCheckDataAvailability() {
            var companyProfObj;
            if (this.utils.validators.isAvailable(this.get('companyId'))) {
                companyProfObj = priceService['default'].companyProfileDS.getStoredObjectByCompanyId(this.get('companyId'), sharedService['default'].userSettings.currentLanguage);
            } else {
                companyProfObj = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage);
            }

            return companyProfObj && companyProfObj.compKeyOfficers && companyProfObj.compKeyOfficers.length > 0;
        },

        onLanguageChanged: function onLanguageChanged() {
            this.onPrepareData();
        },

        filteredContent: (function () {
            this.setErrorMessage();
            return this.getFilteredContent();
        }).property('arrangedContent'),

        // consider entries without resignation date as current members
        getFilteredContent: function getFilteredContent() {
            var that = this;
            var content = this.get('arrangedContent');
            if (content) {
                return content.filter(function (item) {
                    if (that.get('filterDesignations')) {
                        return !item.resignDate && that.get('filterDesignations').indexOf(item.desigId) > -1;
                    } else {
                        return !item.resignDate;
                    }
                });
            }
        },
        setErrorMessage: function setErrorMessage() {
            if (this.getFilteredContent() && this.getFilteredContent().length > 0) {
                this.hideDataErrorMessage();
            } else if (!this.get('isLoading')) {
                this.showDataErrorMessage();
                this.set('errorMessage', this.get('app').lang.messages.dataNotAvailable);
            }
        }
    });

});