define('ir-app/models/price/price-business-entities/alert-user', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({

        fNAme: '',
        lName: '',
        email: '',
        criteria: '',
        status: '',
        // alertUserArray: [],

        setData: function setData(alert) {
            var that = this;
            Ember['default'].$.each(alert, function (key, value) {
                that.set(key, value);
            });
        }
    });

});