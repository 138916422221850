define('ir-app/models/shared/shared-business-entities/persistent-object', ['exports', 'ember', 'ir-app/utils/utils'], function (exports, Ember, utils) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        save: function save() {
            utils['default'].webStorage.addObject(utils['default'].webStorage.getKey(this.get('cacheKey')), this, utils['default'].Constants.StorageType.Local);
        }
    });

});