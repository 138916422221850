define('ir-app/controllers/ir-mobile/media-room-widgets/twitter', ['exports', 'ir-app/controllers/base-controller'], function (exports, BaseController) {

    'use strict';

    /**
     * Created by banushas on 3/25/2016.
     */

    exports['default'] = BaseController['default'].extend({
        widgetId: '',

        onLoadWidget: function onLoadWidget() {

            this.set('widgetId', 'https://' + this.get('mediaURL'));
        },

        loadTweets: (function (d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0],
                p = /^http:/.test(d.location) ? 'http' : 'https';
            if (!d.getElementById(id)) {
                js = d.createElement(s);
                js.id = id;
                js.src = p + '://platform.twitter.com/widgets.js';
                fjs.parentNode.insertBefore(js, fjs);
            }
        })(document, 'script', 'twitter-wjs')

    });
    //widgetId: '727487099360833538',
    //getLabelName: function ()
    //{
    //    return 'Tweets by @almarai'
    //},

});