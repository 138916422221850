define('ir-app/controllers/base-widget-container', ['exports', 'ember', 'ir-app/components/global-search', 'ir-app/app-events', 'ir-app/controllers/controller-factory', 'ir-app/models/shared/shared-service', 'ir-app/models/shared/language/language-data-store', 'ir-app/utils/utils'], function (exports, Ember, GlobalSearch, AppEvents, ControllerFactory, sharedService, LanguageDataStore, utils) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend({
        app: LanguageDataStore['default'].getLanguageObj(),

        // Properties required to draw common panel at the top of the widget container
        exchange: {},
        exchangeList: [],
        tabList: [],
        tabCSS: '',

        // Controlling which controls to be shown based on the loaded container
        showExchange: false,
        showTabs: false,
        dropdownKeys: [],

        // Store ember route for later usages
        router: undefined,

        // Information about loaded widgets
        controllers: {},
        rightPanelControllers: {},
        widgetArgs: {},
        storedArgs: {},

        // Required to save widget specific settings in user's local machine on demand
        menuContent: {},
        tabContent: {},

        // This is used by global search component
        searchKey: '',
        showBaseTitle: false,

        initializeContainer: function initializeContainer(args, tabList, menuContent) {
            var that = this;
            that.set('widgetTitle', this.get('app').lang.labels[menuContent.widgetTitle]);

            var tabsStyle = menuContent.tabsStyle ? menuContent.tabsStyle : '';
            that.set('tabsStyle', tabsStyle);

            Ember['default'].$.each(tabList, function (key, tab) {
                try {
                    Ember['default'].set(tab, 'displayTitle', that.get('app').lang.labels[tab.title]);
                } catch (e) {
                    tab.displayTitle = that.get('app').lang.labels[tab.title];
                }
            });

            this.onLoadContainer(args, tabList, menuContent);
        },

        closeContainer: function closeContainer() {
            this.onUnloadContainer();
        },

        onLoadContainer: function onLoadContainer() {
            AppEvents['default'].subscribeLanguageChanged(this);
            AppEvents['default'].subscribeThemeChanged(this);
            AppEvents['default'].subscribeSymbolChanged(this);
            AppEvents['default'].subscribeVisibilityChanged(this);

            Ember['default'].$(document).ready(function () {
                Ember['default'].$('#scroll_tabs').scrollTabs({
                    scroll_distance: 200,
                    // Pixel width distance for each scroll click.
                    scroll_duration: 300,
                    // <a href="http://www.jqueryscript.net/animation/">Animation</a> time for scrolling in milliseconds.
                    left_arrow_size: 26,
                    // Pixel width for the scroll button on the left side.
                    right_arrow_size: 26,
                    // Pixel width for the scroll button on the right side.
                    click_callback: function click_callback(e) {
                        // jshint ignore:line
                        //Callback function on click. Accpets the click event object as an argument. The default callback function will change the page to the href in the 'rel' attribute of the item's span tag.
                        var val = Ember['default'].$(this).attr('rel');
                        if (val) {
                            window.location.href = val;
                        }
                    }
                });
            });
        },

        onUnloadContainer: function onUnloadContainer() {
            AppEvents['default'].unSubscribeLanguageChanged();
            AppEvents['default'].unSubscribeThemeChanged();
            AppEvents['default'].unSubscribeSymbolChanged();
            AppEvents['default'].unSubscribeVisibilityChanged();
        },

        onPrepareTab: function onPrepareTab(tabContent) {
            return tabContent;
        },

        setTabStype: (function () {
            var tabList = this.get('tabList');
            var count = tabList.length;

            switch (count) {
                case 1:
                    this.set('tabCSS', ''); // Tabs are not visible when tab count is 1
                    break;

                case 2:
                    this.set('tabCSS', 'two-tabs');
                    break;

                case 3:
                    this.set('tabCSS', 'three-tabs');
                    break;

                case 4:
                    this.set('tabCSS', 'four-tabs');
                    break;

                case 5:
                    this.set('tabCSS', 'five-tabs');
                    break;

                case 6:
                    this.set('tabCSS', 'six-tabs');
                    break;

                case 7:
                    this.set('tabCSS', 'seven-tabs');
                    break;

                case 8:
                    this.set('tabCSS', 'eight-tabs');
                    break;

                default:
                    utils['default'].logger.logError('Tab Count not supported : ' + count);
                    break;
            }
        }).observes('tabList'),

        menuView: function menuView(router, menuContent, widgetArgs, storedArgs, isReRender) {

            this.router = router;
            this.widgetArgs = widgetArgs || {};
            this.storedArgs = storedArgs || {};

            var _getDefaultTab = function _getDefaultTab() {
                var savedTabId;
                var defTab = {};
                var tabContent = menuContent.tab;
                var savedTabObj = sharedService['default'].userState.lastTab;

                if (savedTabObj && savedTabObj[menuContent.id]) {
                    savedTabId = savedTabObj[menuContent.id];
                } else {
                    savedTabId = -1;
                }

                if (tabContent && tabContent.length > 0) {
                    Ember['default'].$.each(tabContent, function (index, tab) {
                        if (savedTabId > -1) {
                            if (tab.id === savedTabId) {
                                defTab = tab;
                                return false;
                            }
                        } else if (tab.def) {
                            defTab = tab;
                            return false;
                        }
                    });
                }

                return defTab;
            };

            // Prepare tabs by merging with saved arguments
            var that = this;
            var preparedTabs = [];

            Ember['default'].$.each(menuContent.tab, function (key, tabContent) {
                preparedTabs[preparedTabs.length] = that.onPrepareTab(tabContent, menuContent, widgetArgs, storedArgs);
            });

            // Call initializeContainer() in loaded template
            this.initializeContainer(this.getContainerArgs(menuContent), preparedTabs, menuContent);
            this.tabView(router, _getDefaultTab(), menuContent, isReRender);
        },

        tabView: function tabView(router, tabContent, menuContent, isReRender, generatedArgs) {
            // Not allowed to load same tab more than once consecutively
            if (!isReRender && sharedService['default'].userState.lastMenu === menuContent.id && sharedService['default'].userState.lastTab && sharedService['default'].userState.lastTab[menuContent.id] === tabContent.id) {
                return;
            }

            var that = this;

            this.router = router;
            this.set('menuContent', menuContent);
            this.tabContent = this.onPrepareTab(tabContent, menuContent, this.widgetArgs, this.storedArgs);

            var savedTabObj = sharedService['default'].userState.lastTab;
            savedTabObj = savedTabObj || {};
            savedTabObj[menuContent.id] = this.tabContent.id;

            sharedService['default'].userState.lastMenu = menuContent.id;
            sharedService['default'].userState.lastTab = savedTabObj;
            sharedService['default'].userState.save();

            router.render(this.tabContent.outlet, {
                into: menuContent.layoutTemplate.wn,
                outlet: 'widgetContainerOutlet'
            });

            var tabOutletArgs = this.widgetArgs[menuContent.id] ? this.widgetArgs[menuContent.id].tab[tabContent.id] ? this.widgetArgs[menuContent.id].tab[tabContent.id].args : undefined : undefined;
            if (tabOutletArgs) {
                router.controllerFor(this.tabContent.outlet).setArgs(tabOutletArgs);
            }

            this.setActiveTab(menuContent.tab, tabContent);

            // Selected layout may change for each tab
            if (menuContent.custom) {
                router.controllerFor(this.tabContent.outlet).onLoadLayoutContainer(router, menuContent, tabContent, this);
            }

            // Remove existing widgets
            if (this.controllers) {
                Ember['default'].$.each(this.controllers, function (key, controller) {
                    that.closeWidget(key, controller);
                });
            }

            if (this.tabContent.w !== undefined) {
                for (var i = 0; i < this.tabContent.w.length; ++i) {
                    var widgetDef = this.tabContent.w[i];

                    if (widgetDef) {
                        var templateName = menuContent.custom && !tabContent.preset ? 'custom-workspace.custom-layout-view' : this.tabContent.outlet;
                        this.initializeWidget(widgetDef, templateName, 'w' + widgetDef.id, generatedArgs);
                    }
                }
            }

            this.utils.analyticsService.trackPage([menuContent.title, tabContent.outlet.split('.').pop()].join(':'));
        },

        renderRightPanelView: function renderRightPanelView(router, rightPanelContent) {
            var that = this;

            // Remove existing widgets in right panel
            if (this.rightPanelControllers) {
                Ember['default'].$.each(this.rightPanelControllers, function (key, controller) {
                    that.closeRightPanelWidget(key, controller);
                });
            }

            if (rightPanelContent) {
                router.render(rightPanelContent.layoutTemplate, {
                    into: 'layout.right-panel',
                    outlet: 'rightPanelOutlet'
                });

                var rightPanelController = router.controllerFor(rightPanelContent.layoutTemplate);

                this.rightPanelControllers[rightPanelContent.id] = rightPanelController;
                rightPanelController.initializeWidget({
                    id: rightPanelContent.id,
                    wn: rightPanelContent.layoutTemplate
                }, undefined, this);
            }
        },

        closeRightPanelWidget: function closeRightPanelWidget(widgetId, controller) {
            if (controller) {
                controller.closeWidget();
                this.rightPanelControllers[widgetId] = undefined;
            }
        },

        initializeWidget: function initializeWidget(widgetDef, templateName, outletName, args) {
            var widgetController = ControllerFactory['default'].createController(this.router, widgetDef.wn);

            this.router.render(widgetDef.wn, {
                into: templateName,
                outlet: outletName,
                controller: widgetController
            });

            this.controllers[widgetDef.id] = widgetController;

            // Call initializeWidget() in each loaded widget
            var widgetArgs = this.getWidgetArgs(widgetDef, this.tabContent, this.menuContent);

            if (args) {
                widgetArgs.widgetArgs = widgetArgs.widgetArgs || {};

                Ember['default'].$.each(args, function (prop, val) {
                    widgetArgs.widgetArgs[prop] = val;
                });
            }

            widgetController.initializeWidget(widgetDef, widgetArgs, this);

            return widgetController;
        },

        closeWidget: function closeWidget(widgetId, controller) {
            if (controller) {
                controller.closeWidget();
                this.controllers[widgetId] = undefined;
            }
        },

        getContainerArgs: function getContainerArgs(menuContent) {
            var containerArgs = {};

            var _filterContainerArgs = function _filterContainerArgs(args) {
                var filteredArgs = {};

                if (args && args[menuContent.id]) {
                    filteredArgs = args[menuContent.id];
                }

                return filteredArgs;
            };

            var customArgs = _filterContainerArgs(this.widgetArgs); // Arguments stored in argument section in layout config
            var customStoredArgs = _filterContainerArgs(this.storedArgs); // Arguments stored in user's local machine

            var mergedArgs = Ember['default'].$.extend({}, customArgs, customStoredArgs);

            // Priority given for arguments
            // 1. user's local machine
            // 2. argument section in layout config
            Ember['default'].$.each(mergedArgs, function (key) {
                containerArgs[key] = customStoredArgs[key] ? customStoredArgs[key] : customArgs[key] ? customArgs[key] : '';
            });

            return containerArgs;
        },

        getWidgetArgs: function getWidgetArgs(widgetDef, tabContent, menuContent) {
            var widgetArgs = {};
            var containerArgs = this.getContainerArgs(menuContent); // Arguments passed via widget container

            var _filterWidgetArgs = function _filterWidgetArgs(args) {
                var filteredArgs = {};

                if (args && args[menuContent.id] && args[menuContent.id].tab && args[menuContent.id].tab[tabContent.id] && args[menuContent.id].tab[tabContent.id].w && args[menuContent.id].tab[tabContent.id].w[widgetDef.id]) {
                    filteredArgs = args[menuContent.id].tab[tabContent.id].w[widgetDef.id];
                }

                return filteredArgs;
            };

            var customArgs = _filterWidgetArgs(this.widgetArgs); // Arguments stored in argument section in layout config
            var customStoredArgs = _filterWidgetArgs(this.storedArgs); // Arguments stored in user's local machine

            var mergedArgs = Ember['default'].$.extend({}, customArgs, containerArgs, customStoredArgs);

            // Priority given for arguments
            // 1. user's local machine
            // 2. argument section in layout config
            // 3. arguments passed via widget container
            Ember['default'].$.each(mergedArgs, function (key) {
                widgetArgs[key] = customStoredArgs[key] ? customStoredArgs[key] : customArgs[key] ? customArgs[key] : containerArgs[key] ? containerArgs[key] : '';
            });

            return {
                widgetArgs: widgetArgs,
                storedArgs: customStoredArgs
            };
        },

        saveSettings: function saveSettings() {
            // Specific widget should implement this method to provide specific functionality
            // Otherwise base function will be executed
        },

        saveTabArgs: function saveTabArgs(tabArgs) {
            var menuId = this.menuContent.id;
            var tabId = this.tabContent.id;

            var storedArgs = this.createStoredArgs(menuId, tabId, 'c');
            storedArgs[menuId].tab[tabId].c = Ember['default'].$.extend({}, storedArgs[menuId].tab[tabId].c, tabArgs);
            this.storedArgs = storedArgs;

            sharedService['default'].userState.defaultWS = this.storedArgs;
            sharedService['default'].userState.save();
        },

        saveWidgetArgs: function saveWidgetArgs(widgetId, widgetArgs) {
            var menuId = this.menuContent.id;
            var tabId = this.tabContent.id;

            var storedArgs = this.createStoredArgs(menuId, tabId, 'w');
            storedArgs[menuId].tab[tabId].w[widgetId] = widgetArgs;
            this.storedArgs = storedArgs;

            sharedService['default'].userState.defaultWS = this.storedArgs;
            sharedService['default'].userState.save();
        },

        saveTabWidget: function saveTabWidget(widgetDef) {
            var menuId = this.menuContent.id;
            var tabId = this.tabContent.id;

            var storedArgs = this.createStoredArgs(menuId, tabId, 'w');
            storedArgs[menuId].tab[tabId].w[widgetDef.id] = storedArgs[menuId].tab[tabId].w[widgetDef.id] || {};
            storedArgs[menuId].tab[tabId].w[widgetDef.id].wn = widgetDef.wn;
            this.storedArgs = storedArgs;

            sharedService['default'].userState.defaultWS = this.storedArgs;
            sharedService['default'].userState.save();
        },

        removeTabWidget: function removeTabWidget(widgetId) {
            var menuId = this.menuContent.id;
            var tabId = this.tabContent.id;

            var storedArgs = this.createStoredArgs(menuId, tabId, 'w');
            delete storedArgs[menuId].tab[tabId].w[widgetId];
            this.storedArgs = storedArgs;

            sharedService['default'].userState.defaultWS = this.storedArgs;
            sharedService['default'].userState.save();

            Ember['default'].$.each(this.tabList, function (tabKey, tabObj) {
                if (tabObj.id === tabId) {
                    Ember['default'].$.each(tabObj.w, function (wKey, widget) {
                        if (widget && widget.id === widgetId) {
                            tabObj.w[widgetId - 1] = undefined;
                        }
                    });
                }
            });
        },

        saveWorkspace: function saveWorkspace() {
            sharedService['default'].userState.customWS = Ember['default'].$.map(Ember['default'].$('.grid-stack > .grid-stack-item'), function (el) {
                var node = Ember['default'].$(el).data('_gridstack_node');
                return {
                    i: el.attributes['grid-index'].value,
                    act: el.attributes['active-c'].value,
                    x: node.x,
                    y: node.y,
                    w: node.width,
                    h: node.height,
                    mh: node.min_height
                };
            });

            sharedService['default'].userState.save();
        },

        createStoredArgs: function createStoredArgs(menuId, tabId, argType) {
            // Check existence of individual object's arguments and create if not exists, overwrite if exists
            this.storedArgs = this.storedArgs || {};
            this.storedArgs[menuId] = this.storedArgs[menuId] || {};
            this.storedArgs[menuId].tab = this.storedArgs[menuId].tab || {};
            this.storedArgs[menuId].tab[tabId] = this.storedArgs[menuId].tab[tabId] || {};
            this.storedArgs[menuId].tab[tabId][argType] = this.storedArgs[menuId].tab[tabId][argType] || {};

            return this.storedArgs;
        },

        onLanguageChanged: function onLanguageChanged(language) {
            var that = this;
            var tabList = that.get('tabList');

            Ember['default'].$.each(tabList, function (key, tab) {
                try {
                    Ember['default'].set(tab, 'displayTitle', that.get('app').lang.labels[tab.title]);
                } catch (e) {
                    tab.displayTitle = that.get('app').lang.labels[tab.title];
                }
            });

            var _changeControllerLanguage = function _changeControllerLanguage(controllers) {
                if (controllers) {
                    Ember['default'].$.each(controllers, function (key, controller) {
                        if (controller) {
                            try {
                                controller.onLanguageChanged(language);
                            } catch (e) {
                                utils['default'].logger.logError(e);
                            }
                        }
                    });
                }
            };

            _changeControllerLanguage(this.controllers);
            _changeControllerLanguage(this.rightPanelControllers);

            // this.router.controllerFor('layout.left-navigation').onLanguageChanged(language);
        },

        onThemeChanged: function onThemeChanged(theme) {
            var _changeControllerTheme = function _changeControllerTheme(controllers) {
                if (controllers) {
                    Ember['default'].$.each(controllers, function (key, controller) {
                        if (controller) {
                            try {
                                controller.onThemeChanged(theme);
                            } catch (e) {
                                utils['default'].logger.logError(e);
                            }
                        }
                    });
                }
            };

            _changeControllerTheme(this.controllers);
            _changeControllerTheme(this.rightPanelControllers);
        },

        setActiveTab: function setActiveTab(tabArray, currentTab) {
            Ember['default'].$.each(tabArray, function (key, tabObj) {
                Ember['default'].set(tabObj, 'css', '');

                if (tabObj.id === currentTab.id) {
                    Ember['default'].set(tabObj, 'css', 'active');
                }
            });
        },

        onVisibilityChanged: function onVisibilityChanged(isHidden) {
            var _changeVisibility = function _changeVisibility(controllers) {
                if (controllers) {
                    Ember['default'].$.each(controllers, function (key, controller) {
                        if (controller) {
                            try {
                                controller.onVisibilityChanged(isHidden);
                            } catch (e) {
                                utils['default'].logger.logError(e);
                            }
                        }
                    });
                }
            };

            _changeVisibility(this.controllers);
            _changeVisibility(this.rightPanelControllers);
        },

        actions: {
            showSearchPopup: function showSearchPopup() {
                var modal = this.get('popupSymbolSearch');
                modal.send('showModalPopup');
            },

            closeSearchPopup: function closeSearchPopup() {
                var modal = this.get('popupSymbolSearch');
                modal.send('closeModalPopup');
            }
        },

        setArgs: function setArgs(args) {
            var that = this;

            Ember['default'].$.each(args, function (prop, val) {
                that.set(prop, val);
            });
        }
    });

    Ember['default'].Handlebars.helper('global-search', GlobalSearch['default']);

});