define('ir-app/components/stk-specific-components/financial-chart1', ['exports', 'ember', 'ir-app/utils/utils'], function (exports, Ember, utils) {

    'use strict';

    /* global c3 */

    exports['default'] = Ember['default'].Component.extend({
        layoutName: 'components/stk-specific-components/financial-chart',
        chart: {},
        elementId: '',
        dataSet: {},
        xDataSet: {},

        generateChart: (function () {
            var that = this;
            try {
                this.chart = c3.generate({
                    bindto: '#' + this.elementId,
                    data: {
                        x: 'x',

                        columns: [that.xDataSet, that.dataSet],
                        type: 'bar',
                        labels: {

                            format: function format(x) {
                                return utils['default'].formatters.divideNumber(x, 2);
                            },

                            position: 'left'
                        },
                        empty: {
                            label: {
                                text: 'No Data'
                            }
                        },
                        selection: {
                            enabled: true
                        }

                    },
                    bar: {

                        width: {
                            ratio: 0.5
                        },

                        zerobased: false
                    },
                    axis: {
                        rotated: false,
                        y: {
                            show: false
                        },
                        x: {
                            type: 'category'

                        }
                    },
                    tooltip: {
                        show: true
                    }
                });
            } catch (e) {
                utils['default'].logger.logError('Error in drawing financial chart: ' + e);
            }
        }).observes('dataSet'),
        didInsertElement: function didInsertElement() {
            this._super();
            this.generateChart();
        }
    });

});