define('ir-app/views/table/dual-cells/dual-text-cell', ['exports', 'ir-app/views/table/dual-cells/dual-cell', 'ir-app/models/shared/language/language-data-store'], function (exports, DualCell, languageDataStore) {

    'use strict';

    exports['default'] = DualCell['default'].extend({
        templateName: 'table/views/dual_text-cell',

        // Tooltips
        dcfsToolTip: null,
        fiftyTwoWeekHighToolTip: "High Reached 52Week High",
        fiftyTwoWeekLowToolTip: "Low Reached 52Week Low",
        TodayHighToolTip: "Last Traded Reached High",
        TodayLowToolTip: "Last Traded Reached Low",
        annToolTip: null,

        isndicatorAvailable: (function () {
            return this.get('column.isndicatorAvailable');
        }).property('cellContent'),

        dcfsValue: (function () {
            return this.get('row') ? this.get('row.dcfs') : undefined;
        }).property('row.dcfs'),

        dcfsStyle: (function () {
            var dcfs = this.get('dcfsValue');
            var currentLangObj = languageDataStore['default'].getLanguageObj().lang.labels;
            var dcfsClass = '';

            // DCFS constants
            var dcfsLessThanFifty = '0';
            var dcfsLessThanSeventyFive = '1';
            var dcfsLessThanHundred = '2';
            var dcfsGreaterThanHundred = '3';

            switch (dcfs) {
                case dcfsLessThanFifty:
                    break;
                case dcfsLessThanSeventyFive:
                    dcfsClass = 'fa fa-flag yellow-flag right font-sub';
                    this.set('dcfsToolTip', currentLangObj.dcfsFlagWhiteText); // this.get('app').lang.labels['dcfsFlagWhiteText']
                    break;
                case dcfsLessThanHundred:
                    dcfsClass = 'fa fa-flag orange-flag right font-sub';
                    this.set('dcfsToolTip', currentLangObj.dcfsFlagOrangeText); // this.get('app').lang.labels['dcfsFlagOrangeText']
                    break;
                case dcfsGreaterThanHundred:
                    dcfsClass = 'fa fa-flag red-flag right font-sub';
                    this.set('dcfsToolTip', currentLangObj.dcfsFlagYellowText); // this.get('app').lang.labels['dcfsFlagYellowText']
                    break;
                default:
                    break;
            }

            return dcfsClass;
        }).property('dcfsValue')

    });

});