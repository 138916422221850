define('ir-app/controllers/ir-widgets/financial-widgets/advance-financials', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/utils/utils', 'ir-app/models/shared/shared-service', 'ir-app/models/price/price-business-entities/fundamentals/fundamental-constants', 'ir-app/models/price/price-business-entities/fundamentals/fundaental-common-functions', 'ir-app/components/stk-specific-components/financial-chart-snapshot', 'ir-app/models/price/price-constants', 'ir-app/models/price/price-business-entities/fundamentals/fundamental-data-object'], function (exports, Ember, BaseController, priceService, utils, sharedService, FundamentalConstants, FundamentalCommonFunctions, FinancialChart, priceConstants, fundamental_data_object) {

    'use strict';

    exports['default'] = BaseController['default'].extend({
        viewDropdownListView: [],
        viewDropdownListFromDate: [],
        viewDropdownListToDate: [],
        viewDropdownListPeriods: [],
        keyListDate: undefined,

        styleObject: undefined,
        dataObject: undefined,
        dataKeySet: undefined,

        displayDataObject: {},
        displayStyleObject: Ember['default'].A([]),

        breakDown: FundamentalConstants['default'].BreakDown.CollapsedView,
        isExpand: 2,
        isCalendar: 1,

        currentlyDataDisplayingFilterFromDate: undefined,
        currentlyDataDisplayingFilterToDate: undefined,
        currentlyDataDisplayingIsCalendar: 1,

        fromDate: undefined,
        toDate: undefined,
        today: undefined,

        statementType: 'IS',
        filterFromDate: undefined,
        filterToDate: undefined,
        defSelectFrom: undefined,
        defSelectTo: undefined,
        defSelectView: undefined,
        finYearEndMonth: '',

        displayDataObjectIS: {},
        displayDataObjectBS: {},
        displayDataObjectCF: {},

        stock: undefined,
        reRender: false,
        isRatios: false,
        isSnapshot: undefined,
        isExpandButtonAvailable: true,

        dataSet1: Ember['default'].A(),

        titleObj: undefined,
        numberOfTabs: undefined,

        pdfValidator: false,
        resetFieldFrom: false,
        resetFieldTo: false,
        lang: '',

        dataObjectAdditional: undefined, // to be used for DOC_ID filling for quarterly view type

        onPrepareData: function onPrepareData() {

            if (!utils['default'].validators.isAvailable(this.get('isCalendar'))) {
                // isCalender is empty when zero is set form the the layout config. So re-assign it
                this.set('isCalendar', 0);
            }
            var keyListView = this.get('keyListView');
            if (keyListView[keyListView.length - 1].view === 1) {
                // setting the currentViewType and viewType according to the keyListView array
                this.set('viewType', 'A');
                this.set('currentlyDataDisplayingViewType', 'A');
            } else if (keyListView[keyListView.length - 1].view === 2) {
                this.set('viewType', 'Q');
                this.set('currentlyDataDisplayingViewType', 'Q');
            } else if (keyListView[keyListView.length - 1].view === 3) {
                this.set('viewType', 'C');
                this.set('currentlyDataDisplayingViewType', 'C');
            } else if (keyListView[keyListView.length - 1].view === 4) {
                this.set('viewType', 'T');
                this.set('currentlyDataDisplayingViewType', 'T');
            }

            if (this.get('enableDefaultDetailedView')) {
                this.set('breakDown', FundamentalConstants['default'].BreakDown.ExpandView);
                this.set('isExpand', 1);
            }
            this.setErrorMessage();
            this.set('lang', sharedService['default'].userSettings.currentLanguage);
            this.set('isSnapshot', this.get('includeSnapshot'));
            this.set('statementType', this.get('isSnapshot') ? 'Snapshot' : 'IS');
            this.set('numberOfTabs', this._calculateTabs());

            if (this.get('activeSnapshot') === 'active' || this.get('activeRatios') === 'active') {
                // used to initially hide summary and expand radio btns in snapshot and ratio tabs
                this.set('isExpandButtonAvailable', false);
            }

            var exchange = this.get('exg');
            var symbol = this.get('sym');
            var view = this.get('currentlyDataDisplayingViewType');
            this.startLoadingProgress();
            var that = this;
            var success = function success() {
                that.set('compObj', priceService['default'].companyProfileDS.getStoredObject(that.get('exg'), that.get('sym'), sharedService['default'].userSettings.currentLanguage, undefined, undefined, undefined));
                that.sendDataRequest(exchange, symbol, view);
            };
            priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, success, undefined, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyBasicInfo);

            if (this.get('activeSnapshot') === 'active') {
                // landing page can be given from configurations. TODO [Akila] enabling widget must be handled
                this._onTabChange(0);
            } else if (this.get('activeIS') === 'active') {
                this._onTabChange(1);
            } else if (this.get('activeBS') === 'active') {
                this._onTabChange(2);
            } else if (this.get('activeCF') === 'active') {
                this._onTabChange(3);
            } else if (this.get('activeRatios') === 'active') {
                this._onTabChange(4);
            }
        },

        finYearEnd: (function () {
            // this returns the financial ending month as a string
            this.set('finYearEndMonth', utils['default'].formatters.getMonthStrFromInt(this.get('app').lang.labels, this.get('compObj').finYearStart - 1));
        }).observes('compObj.finYearStart'),

        onClearData: function onClearData() {
            priceService['default'].removeSymbolRequest(this.get('exg'), this.get('sym'));
        },

        _calculateTabs: function _calculateTabs() {
            var Snapshot = this.get('includeSnapshot') ? 1 : 0;
            var IS = this.get('includeIS') ? 1 : 0;
            var BS = this.get('includeBS') ? 1 : 0;
            var CF = this.get('includeCF') ? 1 : 0;
            var Ratios = this.get('includeRatios') ? 1 : 0;

            switch (Snapshot + IS + BS + CF + Ratios) {
                case 1:
                    return 'one';
                case 2:
                    return 'two';
                case 3:
                    return 'three';
                case 4:
                    return 'four';
                case 5:
                    return 'five';
                default:
                    return;
            }
        },

        _defaultSelectionMaker: function _defaultSelectionMaker() {
            this._setDropdownValues();
            var that = this;
            var keys = this.get('keyListView'),
                array = [],
                temp,
                arrObj = [];
            Ember['default'].$.each(keys, function (index, key) {
                temp = that.get('app').lang.labels[key.des];
                arrObj.view = key.view;
                arrObj.des = temp;
                array.push(arrObj);
                arrObj = [];
            });

            var keyListDate = this.get('keyListDate');
            if (!keyListDate) {
                this.set('viewDropdownListFromDate', []);
                this.set('viewDropdownListToDate', []);
                this.set('defSelectFrom', undefined);
                this.set('defSelectTo', undefined);
                this.set('filterFromDate', undefined);
                this.set('filterToDate', undefined);

                this.set('resetFieldFrom', !this.get('resetFieldFrom'));
                this.set('resetFieldTo', !this.get('resetFieldTo'));
                return;
            }

            var keyListView = array;
            keyListView.reverse();
            that.set('viewDropdownListView', keyListView);
            that.set('viewDropdownListFromDate', keyListDate);
            that.set('viewDropdownListToDate', keyListDate);

            that.set('defSelectFrom', keyListDate[that.get('numberOFQuartersDisplayed') - 1]);
            that.set('defSelectTo', keyListDate[0]);
            that.set('defSelectView', keyListView[1]);
            that.set('filterFromDate', keyListDate[that.get('numberOFQuartersDisplayed') - 1].view);
            that.set('filterToDate', keyListDate[0].view);
            that.set('currentlyDataDisplayingViewType', that.get('viewType'));
            that.set('currentlyDataDisplayingFilterFromDate', that.get('filterFromDate'));
            that.set('currentlyDataDisplayingFilterToDate', that.get('filterToDate'));
        },

        sendDataRequest: function sendDataRequest(exchange, symbol, view) {
            var that = this;
            var postSuccess = function postSuccess() {
                if (!that.get('reRender')) {
                    that._defaultSelectionMaker();
                }
                that.dataDisplay(exchange, symbol, view);
                that.stopLoadingProgress();
            };

            var error = function error() {
                that.showDataErrorMessage();
                that.stopLoadingProgress();
            };

            priceService['default'].sendFundamentalDataRequest(exchange, symbol, sharedService['default'].userSettings.currentLanguage, 'X', postSuccess, error, '5', this.get('isCalendar'));
            if (this.get('isSnapshot')) {
                priceService['default'].addSymbolRequest(exchange, symbol);
            }
        },

        /*Quarterly documents are available only for 1st quarter thus interim documents are shown in Quarterly view also*/
        _fillDocId: function _fillDocId() {
            var dataObjectAdditional = this.get('dataObjectAdditional');
            var dataObject = this.get('dataObject'),
                dataObj,
                key,
                year,
                quarter;
            var keySet = Object.keys(dataObject);
            var keySetAdditional = Object.keys(dataObjectAdditional);
            for (var i = 0; i < keySet.length; i++) {
                dataObj = dataObject[keySet[i]];
                key = keySet[i];
                year = key.split('~')[0];
                quarter = key.split('~')[1];
                for (var k = 0; k < keySetAdditional.length; k++) {
                    if (year === keySetAdditional[k].split('~')[0] && (quarter === '1' && keySetAdditional[k].split('~')[1] === '1' || quarter === '12' && keySetAdditional[k].split('~')[1] === '2' || quarter === '13' && keySetAdditional[k].split('~')[1] === '3' || quarter === '14' && keySetAdditional[k].split('~')[1] === '5')) {

                        if (dataObj.DOC_ID) {
                            continue;
                        } else {
                            dataObj.DOC_ID = dataObjectAdditional[keySetAdditional[k]].DOC_ID;
                        }
                    }
                }
            }
        },

        dataDisplay: function dataDisplay(exchange, symbol, view) {
            var filterFrom, filterTo, concatMRFR;
            filterFrom = this.get('currentlyDataDisplayingFilterFromDate');
            filterTo = this.get('currentlyDataDisplayingFilterToDate');
            var statType = this.get('statementType');

            if (this.get('statementType') === 'FR') {
                // MR and FR both are displayed on the Ratios and Growth page
                var arrayFR = priceService['default'].advanceFundamentalDS.getStyleObject(exchange, symbol, 'FR', view, this.get('isCalendar'));
                var arrayMR = priceService['default'].advanceFundamentalDS.getStyleObject(exchange, symbol, 'MR', view, this.get('isCalendar'));

                if (arrayFR === undefined && arrayMR === undefined) {
                    return;
                } else if (arrayFR !== undefined && arrayMR === undefined) {
                    concatMRFR = arrayFR;
                } else if (arrayFR === undefined && arrayMR !== undefined) {
                    concatMRFR = arrayMR;
                } else {
                    concatMRFR = arrayFR.concat(arrayMR);
                }

                concatMRFR.sort(function (a, b) {
                    return parseInt(a.groupID, 10) - parseInt(b.groupID, 10);
                });
                this.set('styleObject', concatMRFR);
                arrayFR = priceService['default'].advanceFundamentalDS.getDataObject(exchange, symbol, 'FR', view, this.get('isCalendar'));
                arrayMR = priceService['default'].advanceFundamentalDS.getDataObject(exchange, symbol, 'MR', view, this.get('isCalendar'));
                this._mergeDataObjects(arrayFR, arrayMR);

                if (this.get('currentlyDataDisplayingViewType') === 'Q') {
                    // interim DOC_ID is included to quarterly view
                    arrayFR = priceService['default'].advanceFundamentalDS.getDataObject(exchange, symbol, 'FR', 'C', this.get('isCalendar'));
                    arrayMR = priceService['default'].advanceFundamentalDS.getDataObject(exchange, symbol, 'MR', 'C', this.get('isCalendar'));
                    this._mergeDataObjects(arrayFR, arrayMR, true);
                    this._fillDocId();
                }
            } else if (this.get('statementType') === 'Snapshot') {

                this.set('dataObjectIS', priceService['default'].advanceFundamentalDS.getDataObject(exchange, symbol, 'IS', view, this.get('isCalendar')));
                this.set('dataObjectBS', priceService['default'].advanceFundamentalDS.getDataObject(exchange, symbol, 'BS', view, this.get('isCalendar')));
                this.set('dataObjectCF', priceService['default'].advanceFundamentalDS.getDataObject(exchange, symbol, 'CF', view, this.get('isCalendar')));
                this.set('stock', priceService['default'].stockDS.getStock(this.get('exg'), this.get('sym')));
            } else {
                // this else is for BS, IS and CF
                this.set('styleObject', priceService['default'].advanceFundamentalDS.getStyleObject(exchange, symbol, statType, view, this.get('isCalendar')));
                this.set('dataObject', priceService['default'].advanceFundamentalDS.getDataObject(exchange, symbol, statType, view, this.get('isCalendar')));
                if (this.get('currentlyDataDisplayingViewType') === 'Q' && this.get('dataObject')) {
                    // interim DOC_ID is included to quarterly view
                    this.set('dataObjectAdditional', priceService['default'].advanceFundamentalDS.getDataObject(exchange, symbol, statType, 'C', this.get('isCalendar')));
                    this._fillDocId();
                }
            }

            if (this.get('dataObject')) {
                this.set('dataKeySet', Object.keys(this.get('dataObject')));
                this.filterDataValues(filterFrom, filterTo, this.get('dataObject'), this.get('displayDataObject'));
                this.filterStyleValue();
            }

            if (Object.keys(this.get('displayDataObject')).length <= 0) {
                this.showDataErrorMessage(); // set error message if data is not available
            }

            if (this.get('isSnapshot')) {
                this.set('arrX', Ember['default'].A());
                this.set('arr1IS', Ember['default'].A());
                this.set('arr2IS', Ember['default'].A());
                this.set('arr3IS', Ember['default'].A());
                this.set('arr4IS', Ember['default'].A());
                this.set('arr1BS', Ember['default'].A());
                this.set('arr2BS', Ember['default'].A());
                this.set('arr1CF', Ember['default'].A());
                this.set('arr2CF', Ember['default'].A());

                this.setIncomeStatementSnapShotData();
                this.setBalanceSheetSnapShotData();
                this.setCashFlowSnapShotData();
            }
        },
        setIncomeStatementSnapShotData: function setIncomeStatementSnapShotData() {
            if (this.get('dataObjectIS')) {

                var templateID = this.get('compObj').templateId;
                if (this.get('isSnapshot')) {
                    this.set('displayDataObjectIS', {});
                    this.filterDataValues(this.get('currentlyDataDisplayingFilterFromDate'), this.get('currentlyDataDisplayingFilterToDate'), this.get('dataObjectIS'), this.get('displayDataObjectIS'), 'IS');

                    if (templateID === priceConstants['default'].TemplateID.Industrial) {
                        this.getChartDataSetIS('IS_NetInc', this.get('arr1IS'), this.get('displayDataObjectIS'));
                        this.getChartDataSetIS('IS_TotRvn', this.get('arr2IS'), this.get('displayDataObjectIS'));
                    } else if (templateID === priceConstants['default'].TemplateID.Banks) {

                        this.getChartDataSetIS('IS_NetInc', this.get('arr1IS'), this.get('displayDataObjectIS'));
                        this.getChartDataSetIS('IS_OprIncBnkrv', this.get('arr2IS'), this.get('displayDataObjectIS'));
                    } else if (templateID === priceConstants['default'].TemplateID.Insurance) {

                        this.getChartDataSetIS('IS_NetInc', this.get('arr1IS'), this.get('displayDataObjectIS'));
                        this.getChartDataSetIS('IS_TotUWGRv', this.get('arr2IS'), this.get('displayDataObjectIS'));
                    }

                    this.getChartDataSetIS('IS_RotBasEPS', this.get('arr3IS'), this.get('displayDataObjectIS'));

                    this.set('dataSet1', this.get('arr1IS'));
                    this.set('dataSet2', this.get('arr2IS'));
                    this.set('dataSet7', this.get('arr3IS'));
                    this.set('xDataSetIS', this.get('arrXIS'));
                }
            }
        },
        setBalanceSheetSnapShotData: function setBalanceSheetSnapShotData() {

            if (this.get('dataObjectBS')) {
                var templateID = this.get('compObj').templateId;
                if (this.get('isSnapshot')) {
                    this.set('displayDataObjectBS', {});
                    this.filterDataValues(this.get('currentlyDataDisplayingFilterFromDate'), this.get('currentlyDataDisplayingFilterToDate'), this.get('dataObjectBS'), this.get('displayDataObjectBS'), 'BS');

                    if (templateID === priceConstants['default'].TemplateID.Industrial) {
                        this.getChartDataSetBS('BS_TotAst', this.get('arr1BS'), this.get('displayDataObjectBS'));
                        this.getChartDataSetBS('BS_TOEAMINT', this.get('arr2BS'), this.get('displayDataObjectBS'));
                    } else if (templateID === priceConstants['default'].TemplateID.Banks) {

                        this.getChartDataSetBS('BS_TotAst', this.get('arr1BS'), this.get('displayDataObjectBS'));
                        this.getChartDataSetBS('BS_TOEAMINT', this.get('arr2BS'), this.get('displayDataObjectBS'));
                    } else if (templateID === priceConstants['default'].TemplateID.Insurance) {

                        this.getChartDataSetBS('BS_TotAst', this.get('arr1BS'), this.get('displayDataObjectBS'));
                        this.getChartDataSetBS('BS_TOEAMINT', this.get('arr2BS'), this.get('displayDataObjectBS'));
                    }

                    this.set('dataSet3', this.get('arr1BS'));
                    this.set('dataSet4', this.get('arr2BS'));
                    this.set('xDataSetBS', this.get('arrXBS'));
                }
            }
        },
        setCashFlowSnapShotData: function setCashFlowSnapShotData() {

            if (this.get('dataObjectCF')) {
                var templateID = this.get('compObj').templateId;

                if (this.get('isSnapshot')) {
                    this.set('displayDataObjectCF', {});
                    this.filterDataValues(this.get('currentlyDataDisplayingFilterFromDate'), this.get('currentlyDataDisplayingFilterToDate'), this.get('dataObjectCF'), this.get('displayDataObjectCF'), 'CF');

                    if (templateID === priceConstants['default'].TemplateID.Industrial) {
                        this.getChartDataSetCF('CF_CshOprAcv', this.get('arr1CF'), this.get('displayDataObjectCF'));
                        this.getChartDataSetCF('CF_CshInvAcv', this.get('arr2CF'), this.get('displayDataObjectCF'));
                    } else if (templateID === priceConstants['default'].TemplateID.Banks) {

                        this.getChartDataSetCF('CF_CshOprAcv', this.get('arr1CF'), this.get('displayDataObjectCF'));
                        this.getChartDataSetCF('CF_CshInvAcv', this.get('arr2CF'), this.get('displayDataObjectCF'));
                    } else if (templateID === priceConstants['default'].TemplateID.Insurance) {

                        this.getChartDataSetCF('CF_CshOprAcv', this.get('arr1CF'), this.get('displayDataObjectCF'));
                        this.getChartDataSetCF('CF_CshInvAcv', this.get('arr2CF'), this.get('displayDataObjectCF'));
                    }

                    this.set('dataSet5', this.get('arr1CF'));
                    this.set('dataSet6', this.get('arr2CF'));
                    this.set('xDataSetCF', this.get('arrXCF'));
                }
            }
        },

        getChartDataSetIS: function getChartDataSetIS(key, array, dataObj) {
            this.set('arrXIS', Ember['default'].A());
            this.set(array, Ember['default'].A());
            array.pushObject(this.getLanguageLabel(key));
            this.get('arrXIS').pushObject('x');

            for (var i = 0; i < this.get('dataKeySetIS.length'); i++) {
                var element = dataObj[this.get('dataKeySetIS')[i]][key];
                array.pushObject(element);
                this.get('arrXIS').pushObject(this.getDisplayLabel(this.get('displayDataObjectIS'), this.get('dataKeySetIS')[i], this.get('currentlyDataDisplayingViewType'), this.get('currentlyDataDisplayingIsCalendar')));
            }
        },

        getChartDataSetBS: function getChartDataSetBS(key, array, dataObj) {
            this.set('arrXBS', Ember['default'].A());
            this.set(array, Ember['default'].A());
            array.pushObject(this.getLanguageLabel(key));
            this.get('arrXBS').pushObject('x');

            for (var i = 0; i < this.get('dataKeySetBS.length'); i++) {
                var element = dataObj[this.get('dataKeySetBS')[i]][key];
                array.pushObject(element);
                this.get('arrXBS').pushObject(this.getDisplayLabel(this.get('displayDataObjectBS'), this.get('dataKeySetBS')[i], this.get('currentlyDataDisplayingViewType'), this.get('currentlyDataDisplayingIsCalendar')));
            }
        },

        getChartDataSetCF: function getChartDataSetCF(key, array, dataObj) {
            this.set('arrXCF', Ember['default'].A());
            this.set(array, Ember['default'].A());
            array.pushObject(this.getLanguageLabel(key));
            this.get('arrXCF').pushObject('x');

            for (var i = 0; i < this.get('dataKeySetCF.length'); i++) {
                var element = dataObj[this.get('dataKeySetCF')[i]][key];
                array.pushObject(element);
                this.get('arrXCF').pushObject(this.getDisplayLabel(this.get('displayDataObjectCF'), this.get('dataKeySetCF')[i], this.get('currentlyDataDisplayingViewType'), this.get('currentlyDataDisplayingIsCalendar')));
            }
        },

        getLanguageLabel: function getLanguageLabel(key) {

            switch (key) {
                case 'IS_NetInc':
                    return this.get('app').lang.labels['netIncome'];
                case 'IS_TotRvn':
                    return this.get('app').lang.labels['totalRevenue'];
                case 'IS_OprIncBnkrv':
                    return this.get('app').lang.labels['operatingIncome'];
                case 'IS_TotUWGRv':
                    return this.get('app').lang.labels['totalUWRevenue'];
                case 'BS_TotAst':
                    return this.get('app').lang.labels['totalAssets'];
                case 'BS_TOEAMINT':
                    return this.get('app').lang.labels['totalEquity'];
                case 'CF_CshOprAcv':
                    return this.get('app').lang.labels['operatingActivities'];
                case 'CF_CshInvAcv':
                    return this.get('app').lang.labels['investingActivities'];
                default:
                    return '';
            }
        },

        _mergeDataObjects: function _mergeDataObjects(arrayFR, arrayMR, setToAdditionalObj) {
            // this function merge both the FR data object and MR data object in to one object.
            var keySetFR, keySetMR, i;
            var displayDataSet = [];
            var object;

            if (arrayFR === undefined || arrayMR === undefined) {
                if (arrayFR === undefined) {
                    displayDataSet = arrayMR;
                } else if (arrayMR === undefined) {
                    displayDataSet = arrayFR;
                } else {
                    displayDataSet = undefined;
                }
            } else {
                keySetFR = Object.keys(arrayFR);
                keySetMR = Object.keys(arrayMR);

                if (keySetFR.length >= keySetMR.length) {
                    var extraMR = keySetMR.filter(function (a) {
                        return !keySetFR.includes(a);
                    }); // find periods in MR additional to FR
                    var extraItems = Object.values(extraMR);
                    for (var i = 0; i < extraItems.length; i++) {
                        if (extraItems[i].FR_PBR || extraItems[i].FR_PER || extraItems[i].PAR_VALUE) {
                            // Validate if one more field has data except  FR_DivYld ,FR_DPS

                            object = fundamental_data_object['default'].create();
                            object.setData(arrayMR[extraItems[i]]);
                            displayDataSet[extraItems[i]] = object;
                        }
                    }
                    for (i = 0; i < keySetFR.length; i++) {
                        object = fundamental_data_object['default'].create();
                        object.setData(arrayFR[keySetFR[i]]);
                        if (keySetMR.indexOf(keySetFR[i]) >= 0 && i < keySetMR.length) {
                            object.setData(arrayMR[keySetFR[i]]);
                        }
                        if (Object.keys(object).length > 0) {
                            displayDataSet[keySetFR[i]] = object;
                        }
                    }
                } else if (keySetMR.length > keySetFR.length) {
                    for (i = 0; i < keySetMR.length; i++) {
                        object = fundamental_data_object['default'].create();
                        var tempMR = arrayMR[keySetMR[i]];
                        if (tempMR.FR_PBR || tempMR.FR_PER || tempMR.PAR_VALUE) {
                            // Validate if one more field has data except  FR_DivYld ,FR_DPS
                            object.setData(arrayMR[keySetMR[i]]);
                        }
                        if (keySetFR.indexOf(keySetMR[i]) >= 0 && i < keySetFR.length) {
                            object.setData(arrayFR[keySetMR[i]]);
                        }
                        if (Object.keys(object).length > 0) {
                            displayDataSet[keySetMR[i]] = object;
                        }
                    }
                }
            }

            if (setToAdditionalObj) {
                // this will be set only to fill the interim DOC_IDs to Quarterly DOC_IDs
                this.set('dataObjectAdditional', displayDataSet);
            } else {
                this.set('dataObject', displayDataSet);
            }
        },

        isExpandCollapse: (function () {
            this.dataDisplay(this.get('exg'), this.get('sym'), this.get('currentlyDataDisplayingViewType'));
        }).observes('breakDown'),

        /**
         * To get filtered data list in between from date and the to date
         * and set in to the displayDataObject.
         * @param fromDate and toDate
         */

        filterDataValues: function filterDataValues(fromDate, toDate, dataObj, displayDataObj, type) {
            var key;
            var valueObject;
            var keySet = Object.keys(dataObj);
            keySet.reverse(); // to change the display format of the columns from left to right

            if (!this.get('includeFromToFields')) {
                if (this.get('currentlyDataDisplayingViewType') === 'A') {
                    toDate = keySet[keySet.length - 1];
                    fromDate = keySet[keySet.length - parseInt(this.get('numberOFYearsDisplayed'))];
                    if (fromDate === undefined) {
                        fromDate = keySet[0];
                    }
                } else {
                    toDate = keySet[keySet.length - 1];
                    fromDate = keySet[keySet.length - parseInt(this.get('numberOFQuartersDisplayed'))];
                    if (fromDate === undefined) {
                        fromDate = keySet[0];
                    }
                }
            }

            for (var j = 0; j < keySet.length; j++) {
                key = keySet[j];
                valueObject = dataObj[key];
                if (this._compareDate(fromDate, key, toDate)) {
                    displayDataObj[key] = valueObject;
                }
            }
            // this.set('dataKeySet', Object.keys(displayDataObj));

            switch (type) {

                case 'IS':
                    this.set('dataKeySetIS', Object.keys(displayDataObj));
                    break;

                case 'BS':
                    this.set('dataKeySetBS', Object.keys(displayDataObj));
                    break;

                case 'CF':
                    this.set('dataKeySetCF', Object.keys(displayDataObj));
                    break;

                default:
                    this.set('dataKeySet', Object.keys(displayDataObj));
                    break;

            }

            keySet = Object.keys(displayDataObj);
            if (keySet.length <= 0) {
                return;
            }

            var keyListDate = this.get('viewDropdownListFromDate');

            for (var i = 0; i < keyListDate.length; i++) {
                // this was done to update the To year if the latest available year is lesser than the max year
                var latestAvailable = keySet[keySet.length - 1];
                if (latestAvailable === keyListDate[i].view) {
                    this.set('defSelectTo', keyListDate[i]);
                    this.set('filterToDate', keyListDate[i].view);
                    this.set('resetFieldTo', !this.get('resetFieldTo'));
                    this.set('resetFieldFrom', !this.get('resetFieldFrom'));
                }
            }
        },

        /**
         * To get filtered style list. Iterate the filtered data list and get only the styles that having data for
         * all the fields and filter the styles according to the breakdown views.
         */

        filterStyleValue: function filterStyleValue() {
            var styleObject,
                i,
                customFieldsForIS = this.get('customFieldsForIS'),
                customFieldsForBS = this.get('customFieldsForBS'),
                customFieldsForCF = this.get('customFieldsForCF'),
                customFieldsForRatios = this.get('customFieldsForRatios');
            var keySet = this.get('dataKeySet');
            this.set('displayStyleObject', Ember['default'].A([]));
            for (var j = 0; j < this.get('styleObject').length; j++) {
                styleObject = this.get('styleObject')[j];
                var dataObject;
                var key;
                if (customFieldsForIS !== undefined && this.get('statementType') === 'IS' || customFieldsForBS !== undefined && this.get('statementType') === 'BS' || customFieldsForCF !== undefined && this.get('statementType') === 'CF' || customFieldsForRatios !== undefined && this.get('statementType') === 'FR') {
                    for (i = 0; i < keySet.length; i++) {
                        key = keySet[i];
                        dataObject = this.get('displayDataObject')[key];
                        if (dataObject[styleObject.filedID] && (customFieldsForIS && customFieldsForIS.indexOf(styleObject.filedID) >= 0 || customFieldsForBS && customFieldsForBS.indexOf(styleObject.filedID) >= 0 || customFieldsForCF && customFieldsForCF.indexOf(styleObject.filedID) >= 0 || customFieldsForRatios && customFieldsForRatios.indexOf(styleObject.filedID) >= 0)) {
                            if (this.get('breakDown') === FundamentalConstants['default'].BreakDown.CollapsedView) {
                                if (styleObject.level === '1') {
                                    this.get('displayStyleObject').pushObject(styleObject);
                                }
                            } else {
                                this.get('displayStyleObject').pushObject(styleObject);
                            }
                            break;
                        }
                    }
                } else {
                    for (i = 0; i < keySet.length; i++) {
                        key = keySet[i];
                        dataObject = this.get('displayDataObject')[key];
                        if (dataObject[styleObject.filedID]) {
                            if (this.get('breakDown') === FundamentalConstants['default'].BreakDown.CollapsedView) {
                                if (styleObject.level === '1') {
                                    this.get('displayStyleObject').pushObject(styleObject);
                                }
                            } else {
                                this.get('displayStyleObject').pushObject(styleObject);
                            }
                            break;
                        }
                    }
                }
            }
            this.setTitleObj();
        },

        setTitleObj: function setTitleObj() {
            // title object contains all the styles which are sorted and sub categorized according to the group ID and group order ID
            var displayStyleObject = this.get('displayStyleObject');
            var titleObj = Ember['default'].A([]),
                temp = 0;
            var i;
            for (i = 0; i < displayStyleObject.length; i++) {
                if (parseInt(displayStyleObject[i].groupID, 10) !== temp) {
                    temp++;

                    titleObj.push(this._processArray(displayStyleObject, temp));
                }
            }
            this.set('titleObj', titleObj);
        },

        _processArray: function _processArray(displayStyleObject, temp) {
            var temArray;
            temArray = Ember['default'].$.grep(displayStyleObject, function (style) {
                return parseInt(style.groupID, 10) === temp;
            });
            temArray.sort(function (a, b) {
                return parseInt(a.orderInGroup, 10) - parseInt(b.orderInGroup, 10);
            });
            return temArray;
        },

        /**
         * This is a support function for filter data objects between two periods.
         * @param fromDate, date and toDate
         * @return {boolean}
         */

        _compareDate: function _compareDate(fromDate, date, toDate) {
            if (fromDate === undefined || date === undefined || toDate === undefined) {
                return false;
            }
            var splitFrom = fromDate.split('~');
            var splitDate = date.split('~');
            var splitTo = toDate.split('~');

            if (this.get('currentlyDataDisplayingViewType') === FundamentalConstants['default'].ViewTypes.VIEW_TYPE_ANNUAL) {
                if (parseInt(splitDate[0], 10) >= parseInt(fromDate, 10) && splitDate[0].localeCompare(toDate) <= 0) {
                    return true;
                }
            } else {
                if (splitDate[0].concat(this._compareQuarter(splitDate[1])).localeCompare(splitFrom[0].concat(this._compareQuarter(splitFrom[1]))) >= 0 && splitDate[0].concat(this._compareQuarter(splitDate[1])).localeCompare(splitTo[0].concat(this._compareQuarter(splitTo[1]))) <= 0) {
                    return true;
                }
            }
            return false;
        },

        _compareQuarter: function _compareQuarter(quarter) {
            try {
                if ('5' === quarter[quarter.length - 1]) {
                    return '4';
                }
                return quarter[quarter.length - 1];
            } catch (e) {
                return quarter;
            }
        },

        _onTabChange: function _onTabChange(tabID) {
            var that = this;
            this._clearDisplay();
            this.set('isRatios', false);
            this.set('isSnapshot', false);
            this.set('isExpandButtonAvailable', false);

            if (tabID === 0) {
                this.set('statementType', 'Snapshot');
                this.set('isSnapshot', true);
            } else if (tabID === 1) {
                this.set('statementType', 'IS');
            } else if (tabID === 2) {
                this.set('statementType', 'BS');
            } else if (tabID === 3) {
                this.set('statementType', 'CF');
            } else if (tabID === 4) {
                this.set('statementType', 'FR');
                this.set('isRatios', true);
            }

            var postSuccess = function postSuccess() {
                that._setMemoryVariables();
                that.set('isExpandButtonAvailable', that.get('isRatios') || that.get('isSnapshot') ? false : true);
                that.dataDisplay(that.get('exg'), that.get('sym'), that.get('currentlyDataDisplayingViewType'));
            };

            this.dropDownSetter(postSuccess); // on tab change data will be set to currently selected criteria of the drop downs
        },

        onExpandCollapse: (function () {
            var selection = this.get('isExpand');
            if (selection === 1) {
                this.set('breakDown', FundamentalConstants['default'].BreakDown.ExpandView);
            } else if (selection === 2) {
                this.set('breakDown', FundamentalConstants['default'].BreakDown.CollapsedView);
            }
        }).observes('isExpand'),

        getDisplayLabel: function getDisplayLabel(dataObject, key, viewType, isCalendar) {
            if (dataObject[key]) {
                var valueObject = dataObject[key];
                if (isCalendar === 1) {
                    if (this.get('yearQuarterDisplayFormat')) {
                        return this.get('yearQuarterDisplayFormat').replace('Y', valueObject['CALENDER_YEAR']).replace('Q', viewType === FundamentalConstants['default'].ViewTypes.VIEW_TYPE_ANNUAL ? '' : FundamentalCommonFunctions['default'].displayQuater(valueObject['CAL_QUARTER_REQUESTED'] ? valueObject['CAL_QUARTER_REQUESTED'] : valueObject['CALENDER_PERIOD']));
                    } else {
                        return valueObject['CALENDER_YEAR'] + (viewType === FundamentalConstants['default'].ViewTypes.VIEW_TYPE_ANNUAL ? '' : ' ' + FundamentalCommonFunctions['default'].displayQuater(valueObject['CAL_QUARTER_REQUESTED'] ? valueObject['CAL_QUARTER_REQUESTED'] : valueObject['CALENDER_PERIOD']));
                    }
                } else if (isCalendar === 0) {
                    if (this.get('yearQuarterDisplayFormat')) {
                        return this.get('yearQuarterDisplayFormat').replace('Y', valueObject['DUR_YEAR']).replace('Q', viewType === FundamentalConstants['default'].ViewTypes.VIEW_TYPE_ANNUAL ? '' : FundamentalCommonFunctions['default'].displayQuater(valueObject['QUARTER_REQUESTED'] ? valueObject['QUARTER_REQUESTED'] : valueObject['PERIOD']));
                    } else {
                        return valueObject['DUR_YEAR'] + (viewType === FundamentalConstants['default'].ViewTypes.VIEW_TYPE_ANNUAL ? '' : ' ' + FundamentalCommonFunctions['default'].displayQuater(valueObject['QUARTER_REQUESTED'] ? valueObject['QUARTER_REQUESTED'] : valueObject['PERIOD']));
                    }
                }
            }
            return '';
        },

        _clearDisplay: function _clearDisplay() {
            this.hideDataErrorMessage();
            this.set('displayDataObject', Ember['default'].A([]));
            this.set('displayStyleObject', Ember['default'].A([]));
        },

        _setMemoryVariables: function _setMemoryVariables() {
            // this method set the memory variables which are used to filter the data set
            this.set('currentlyDataDisplayingIsCalendar', this.get('isCalendar'));
            this.set('currentlyDataDisplayingViewType', this.get('viewType'));
            this.set('currentlyDataDisplayingFilterFromDate', this.get('filterFromDate'));
            this.set('currentlyDataDisplayingFilterToDate', this.get('filterToDate'));
        },

        _onClickView: function _onClickView() {
            this._clearDisplay();
            this._setMemoryVariables();
            this.startLoadingProgress();
            this.set('reRender', true);
            this.sendDataRequest(this.get('exg'), this.get('sym'), this.get('currentlyDataDisplayingViewType'));
        },

        _validator: function _validator() {
            if (this.get('filterFromDate') === undefined || this.get('filterToDate') === undefined) {
                return;
            }
            var arrayFrom = this.get('filterFromDate').split('~');
            var arrayTo = this.get('filterToDate').split('~');

            if (parseInt(arrayFrom[0]) > parseInt(arrayTo[0])) {
                this.set('defSelectFrom', this.get('viewDropdownListFromDate')[this.get('numberOFQuartersDisplayed') - 1]);
                this.set('filterFromDate', this.get('defSelectFrom').view);
                this.set('defSelectTo', this.get('viewDropdownListFromDate')[0]);
                this.set('filterToDate', this.get('defSelectTo').view);
                this.set('resetFieldFrom', !this.get('resetFieldFrom'));
                this.set('resetFieldTo', !this.get('resetFieldTo'));
            } else if (parseInt(arrayFrom[0]) === parseInt(arrayTo[0]) && parseInt(arrayFrom[1]) > parseInt(arrayTo[1])) {
                this.set('defSelectFrom', this.get('viewDropdownListFromDate')[this.get('numberOFQuartersDisplayed') - 1]);
                this.set('filterFromDate', this.get('defSelectFrom').view);
                this.set('defSelectTo', this.get('viewDropdownListFromDate')[0]);
                this.set('filterToDate', this.get('defSelectTo').view);
                this.set('resetFieldFrom', !this.get('resetFieldFrom'));
                this.set('resetFieldTo', !this.get('resetFieldTo'));
            }
        },

        _setDropdownValues: function _setDropdownValues() {
            var view = this.get('viewType');
            var that = this;
            var keyListDate,
                i,
                j,
                numberOfQuarters = 4,
                objArrayOne,
                objArrayTwo,
                keySetOne,
                keySetTwo;
            var stat = that.get('statementType') === 'Snapshot' ? 'IS' : that.get('statementType');
            var min_year, max_year, min_quarter, max_quarter;

            /* instead of taking min_max object from the response, we are finding the min max using the available data set , as it is more reliable */
            if (stat === 'BS' || stat === 'IS' || stat === 'CF') {
                objArrayOne = priceService['default'].advanceFundamentalDS.getDataObject(that.get('exg'), that.get('sym'), 'IS', view, this.get('isCalendar'));

                if (!objArrayOne) {
                    // TODO [Akila] find a better way to select the view type for the drop down
                    objArrayOne = priceService['default'].advanceFundamentalDS.getDataObject(that.get('exg'), that.get('sym'), 'CF', view, this.get('isCalendar'));
                }

                if (!objArrayOne) {
                    objArrayOne = priceService['default'].advanceFundamentalDS.getDataObject(that.get('exg'), that.get('sym'), 'BS', view, this.get('isCalendar'));
                }

                if (!objArrayOne) {
                    this.set('viewDropdownListFromDate', []);
                    this.set('viewDropdownListToDate', []);
                    this.set('defSelectFrom', undefined);
                    this.set('defSelectTo', undefined);
                    this.set('filterFromDate', undefined);
                    this.set('filterToDate', undefined);

                    this.set('resetFieldFrom', !this.get('resetFieldFrom'));
                    this.set('resetFieldTo', !this.get('resetFieldTo'));
                    return;
                } else {
                    keySetOne = Object.keys(objArrayOne);
                    max_year = parseInt(keySetOne[0].split('~')[0]);
                    max_quarter = FundamentalCommonFunctions['default'].actualQuarter(keySetOne[0].split('~')[1]);
                    min_year = parseInt(keySetOne[keySetOne.length - 1].split('~')[0]);
                    min_quarter = FundamentalCommonFunctions['default'].actualQuarter(keySetOne[keySetOne.length - 1].split('~')[1]);
                }
            } else if (stat === 'FR' || stat === 'MR') {
                objArrayOne = priceService['default'].advanceFundamentalDS.getDataObject(that.get('exg'), that.get('sym'), 'FR', view, this.get('isCalendar'));
                objArrayTwo = priceService['default'].advanceFundamentalDS.getDataObject(that.get('exg'), that.get('sym'), 'MR', view, this.get('isCalendar'));
                if (!objArrayOne && !objArrayTwo) {
                    this.set('viewDropdownListFromDate', []);
                    this.set('viewDropdownListToDate', []);
                    this.set('defSelectFrom', undefined);
                    this.set('defSelectTo', undefined);
                    this.set('filterFromDate', undefined);
                    this.set('filterToDate', undefined);

                    this.set('resetFieldFrom', !this.get('resetFieldFrom'));
                    this.set('resetFieldTo', !this.get('resetFieldTo'));
                    return;
                } else {
                    if (objArrayOne && !objArrayTwo) {
                        keySetOne = Object.keys(objArrayOne);
                        max_year = parseInt(keySetOne[0].split('~')[0]);
                        max_quarter = FundamentalCommonFunctions['default'].actualQuarter(keySetOne[0].split('~')[1]);
                        min_year = parseInt(keySetOne[keySetOne.length - 1].split('~')[0]);
                        min_quarter = FundamentalCommonFunctions['default'].actualQuarter(keySetOne[keySetOne.length - 1].split('~')[1]);
                    } else if (!objArrayOne && objArrayTwo) {
                        keySetTwo = Object.keys(objArrayTwo);
                        max_year = parseInt(keySetTwo[0].split('~')[0]);
                        max_quarter = FundamentalCommonFunctions['default'].actualQuarter(keySetTwo[0].split('~')[1]);
                        min_year = parseInt(keySetTwo[keySetTwo.length - 1].split('~')[0]);
                        min_quarter = FundamentalCommonFunctions['default'].actualQuarter(keySetTwo[keySetTwo.length - 1].split('~')[1]);
                    } else if (objArrayOne && objArrayTwo) {
                        keySetOne = Object.keys(objArrayOne);
                        keySetTwo = Object.keys(objArrayTwo);
                        if (keySetOne[0].split('~')[0] < keySetTwo[0].split('~')[0]) {
                            keySetOne = keySetTwo;
                        }
                        if (keySetOne[0].split('~')[0] === keySetTwo[0].split('~')[0]) {
                            if (FundamentalCommonFunctions['default'].actualQuarter(keySetOne[0].split('~')[1]) < FundamentalCommonFunctions['default'].actualQuarter(keySetTwo[0].split('~')[1])) {
                                keySetOne = keySetTwo;
                            }
                        }

                        max_year = parseInt(keySetOne[0].split('~')[0]);
                        max_quarter = FundamentalCommonFunctions['default'].actualQuarter(keySetOne[0].split('~')[1]);
                        min_year = parseInt(keySetOne[keySetOne.length - 1].split('~')[0]);
                        min_quarter = FundamentalCommonFunctions['default'].actualQuarter(keySetOne[keySetOne.length - 1].split('~')[1]);
                    }
                }
            }

            if (view === 'A') {
                keyListDate = [];
                for (i = min_year; i <= max_year; i++) {
                    keyListDate.push({ view: i + '~' + '5', des: i });
                }
                keyListDate.reverse(); // to invert the upper most value of the dropdown
                this.set('viewDropdownListFromDate', keyListDate);
                this.set('viewDropdownListToDate', keyListDate);
                if (keyListDate.length < parseInt(this.get('numberOFYearsDisplayed'))) {
                    /* for some symbols data set can be less than the number
                    of default entries to be displayed in the view. this if was used to handle such scenarios */
                    this.set('defSelectFrom', keyListDate[keyListDate.length - 1]);
                    this.set('filterFromDate', keyListDate[keyListDate.length - 1].view);
                } else {
                    this.set('defSelectFrom', keyListDate[this.get('numberOFYearsDisplayed') - 1]);
                    this.set('filterFromDate', keyListDate[this.get('numberOFYearsDisplayed') - 1].view);
                }

                this.set('defSelectTo', keyListDate[0]);
                this.set('filterToDate', keyListDate[0].view);
            } else if (view === 'Q') {
                keyListDate = [];
                for (i = min_year; i <= max_year; i++) {
                    for (j = 1; j <= numberOfQuarters; j++) {
                        if (i === min_year) {
                            if (j < min_quarter) {
                                j = min_quarter;
                            }
                        } else if (i === max_year) {
                            numberOfQuarters = max_quarter;
                        }

                        if (j === 1) {
                            keyListDate.push({ view: i + '~' + j, des: 'Q' + j + ' ' + i });
                        } else {
                            keyListDate.push({ view: i + '~' + '1' + j, des: 'Q' + j + ' ' + i });
                        }
                    }
                }
                keyListDate.reverse();
                this.set('viewDropdownListFromDate', keyListDate);
                this.set('viewDropdownListToDate', keyListDate);
                if (keyListDate.length < parseInt(this.get('numberOFYearsDisplayed'))) {
                    /* for some symbols data set can be less than the number
                    of default entries to be displayed in the view. this if was used to handle such scenarios */
                    this.set('defSelectFrom', keyListDate[keyListDate.length - 1]);
                    this.set('filterFromDate', keyListDate[keyListDate.length - 1].view);
                } else {
                    this.set('defSelectFrom', keyListDate[this.get('numberOFYearsDisplayed') - 1]);
                    this.set('filterFromDate', keyListDate[this.get('numberOFYearsDisplayed') - 1].view);
                }

                this.set('defSelectTo', keyListDate[0]);
                this.set('filterToDate', keyListDate[0].view);

                this.set('resetFieldFrom', !this.get('resetFieldFrom'));
                this.set('resetFieldTo', !this.get('resetFieldTo'));
            } else if (view === 'C') {
                keyListDate = [];
                for (i = min_year; i <= max_year; i++) {
                    for (j = 1; j <= numberOfQuarters; j++) {
                        if (i === min_year) {
                            if (j < min_quarter) {
                                j = min_quarter;
                            }
                        } else if (i === max_year) {
                            numberOfQuarters = max_quarter;
                        }
                        keyListDate.push({ view: i + '~' + '2' + j, des: 'Q' + j + ' ' + i });
                    }
                }
                keyListDate.reverse();
                this.set('viewDropdownListFromDate', keyListDate);
                this.set('viewDropdownListToDate', keyListDate);
                if (keyListDate.length < parseInt(this.get('numberOFYearsDisplayed'))) {
                    /* for some symbols data set can be less than the number
                    of default entries to be displayed in the view. this if was used to handle such scenarios */
                    this.set('defSelectFrom', keyListDate[keyListDate.length - 1]);
                    this.set('filterFromDate', keyListDate[keyListDate.length - 1].view);
                } else {
                    this.set('defSelectFrom', keyListDate[this.get('numberOFYearsDisplayed') - 1]);
                    this.set('filterFromDate', keyListDate[this.get('numberOFYearsDisplayed') - 1].view);
                }

                this.set('defSelectTo', keyListDate[0]);
                this.set('filterToDate', keyListDate[0].view);

                this.set('resetFieldFrom', !this.get('resetFieldFrom'));
                this.set('resetFieldTo', !this.get('resetFieldTo'));
            } else if (view === 'T') {
                keyListDate = [];
                for (i = min_year; i <= max_year; i++) {
                    for (j = 1; j <= numberOfQuarters; j++) {
                        if (i === min_year) {
                            if (j < min_quarter) {
                                j = min_quarter;
                            }
                        } else if (i === max_year) {
                            numberOfQuarters = max_quarter;
                        }

                        if (i === 4) {
                            keyListDate.push({ view: i + '~' + '5', des: 'Q' + j + ' ' + i });
                        } else {
                            keyListDate.push({ view: i + '~' + j, des: 'Q' + j + ' ' + i });
                        }
                    }
                }
                keyListDate.reverse();
                this.set('viewDropdownListFromDate', keyListDate);
                this.set('viewDropdownListToDate', keyListDate);
                if (keyListDate.length < parseInt(this.get('numberOFYearsDisplayed'))) {
                    /* for some symbols data set can be less than the number
                    of default entries to be displayed in the view. this if was used to handle such scenarios */
                    this.set('defSelectFrom', keyListDate[keyListDate.length - 1]);
                    this.set('filterFromDate', keyListDate[keyListDate.length - 1].view);
                } else {
                    this.set('defSelectFrom', keyListDate[this.get('numberOFYearsDisplayed') - 1]);
                    this.set('filterFromDate', keyListDate[this.get('numberOFYearsDisplayed') - 1].view);
                }

                this.set('defSelectTo', keyListDate[0]);
                this.set('filterToDate', keyListDate[0].view);

                this.set('resetFieldFrom', !this.get('resetFieldFrom'));
                this.set('resetFieldTo', !this.get('resetFieldTo'));
            }

            this.set('keyListDate', keyListDate);
        },

        /*if data was loaded initially for cal=1 then there will be no data for cal=0 {calendar and fiscal}
        * thus want to load that data when the user change the period from calendar to fiscal and vice versa.
        * As response is cached, request will be locally resolved*/
        dropDownSetter: (function (postSuccess) {
            if (!this.get('exg') || !this.get('sym')) {
                return;
            }
            var that = this;

            if (that.get('currentlyDataDisplayingIsCalendar') !== that.get('isCalendar')) {
                var suc = function suc() {
                    that._setDropdownValues();
                    if (Ember['default'].$.isFunction(postSuccess)) {
                        postSuccess();
                    }
                };
                var err = function err() {
                    that.showDataErrorMessage();
                    that.stopLoadingProgress();
                };
                priceService['default'].sendFundamentalDataRequest(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, 'X', suc, err, '5', this.get('isCalendar'));
            } else {
                that._setDropdownValues();
                if (Ember['default'].$.isFunction(postSuccess)) {
                    postSuccess();
                }
            }
        }).observes('isCalendar'),

        actions: {
            onTabSelected: function onTabSelected(tabId) {
                this._onTabChange(tabId);
            },

            onSelectingView: function onSelectingView(item) {
                switch (item.view) {
                    case 1:
                        this.set('viewType', 'A');
                        this._setDropdownValues();break;
                    case 2:
                        this.set('viewType', 'Q');
                        this._setDropdownValues();break;
                    case 3:
                        this.set('viewType', 'C');
                        this._setDropdownValues();break;
                    case 4:
                        this.set('viewType', 'T');
                        this._setDropdownValues();break;
                    default:
                        return;
                }

                if (this.get('activateViewDropdown')) {
                    this._onClickView();
                }
            },

            onSelectingFromDate: function onSelectingFromDate(item) {
                this.set('defSelectFrom', item);
                this.set('filterFromDate', item.view);
            },

            onSelectingToDate: function onSelectingToDate(item) {
                this.set('defSelectTo', item);
                this.set('filterToDate', item.view);
            },

            onClickView: function onClickView() {
                this._validator();
                this._onClickView();
            }
        },

        onAfterRender: function onAfterRender() {
            Ember['default'].$(document).ready(function () {
                Ember['default'].$('#scroll_tabs2').scrollTabs({
                    scroll_distance: 200,
                    // Pixel width distance for each scroll click.
                    scroll_duration: 300,
                    // <a href="http://www.jqueryscript.net/animation/">Animation</a> time for scrolling in milliseconds.
                    left_arrow_size: 26,
                    // Pixel width for the scroll button on the left side.
                    right_arrow_size: 26,
                    // Pixel width for the scroll button on the right side.
                    click_callback: function click_callback(e) {
                        // jshint ignore:line
                        //Callback function on click. Accpets the click event object as an argument. The default callback function will change the page to the href in the 'rel' attribute of the item's span tag.
                        var val = Ember['default'].$(this).attr('rel');
                        if (val) {
                            window.location.href = val;
                        }
                    }
                });
            });
        }
    });

    Ember['default'].Handlebars.helper('getDisplayData', function (dataObject, key, fieldID) {
        if (dataObject[key]) {
            var valueObject = dataObject[key];
            var temp;
            if (FundamentalConstants['default'].NonMillionFields.indexOf(fieldID) >= 0 || valueObject[fieldID] === '') {
                temp = utils['default'].formatters.formatNumber(valueObject[fieldID], 2); // todo [Banusha] currently put decimal places to 2. Later need to add symbol decimal places
            } else {
                    temp = utils['default'].formatters.formatNumber(valueObject[fieldID] / 1000000, 2); // todo [Banusha] currently put decimal places to 2. Later need to add symbol decimal places
                }

            if (temp.charAt(0) === '-' && temp.charAt(1) !== '-') {
                temp = '(' + temp.substring(1, temp.length) + ')';
            }
            return temp;
        }
        return '';
    });

    Ember['default'].Handlebars.helper('financial-chart-snapshot', FinancialChart['default']);

    Ember['default'].Handlebars.helper('getDisplayHeader', function (dataObject, key, viewType, isCalendar, yearQuarterDisplayFormat) {
        if (dataObject[key]) {
            var valueObject = dataObject[key];
            if (isCalendar === 1) {
                if (yearQuarterDisplayFormat) {
                    return yearQuarterDisplayFormat.replace('Y', valueObject['CALENDER_YEAR']).replace('Q', viewType === FundamentalConstants['default'].ViewTypes.VIEW_TYPE_ANNUAL ? '' : FundamentalCommonFunctions['default'].displayQuater(valueObject['CALENDER_PERIOD'] ? valueObject['CALENDER_PERIOD'] : valueObject['CAL_QUARTER_REQUESTED']));
                } else {
                    return valueObject['CALENDER_YEAR'] + (viewType === FundamentalConstants['default'].ViewTypes.VIEW_TYPE_ANNUAL ? '' : ' ' + FundamentalCommonFunctions['default'].displayQuater(valueObject['CALENDER_PERIOD'] ? valueObject['CALENDER_PERIOD'] : valueObject['CAL_QUARTER_REQUESTED']));
                }
            } else if (isCalendar === 0) {

                if (yearQuarterDisplayFormat) {
                    return yearQuarterDisplayFormat.replace('Y', valueObject['DUR_YEAR']).replace('Q', viewType === FundamentalConstants['default'].ViewTypes.VIEW_TYPE_ANNUAL ? '' : FundamentalCommonFunctions['default'].displayQuater(valueObject['PERIOD'] ? valueObject['PERIOD'] : valueObject['QUARTER_REQUESTED']));
                } else {
                    return valueObject['DUR_YEAR'] + (viewType === FundamentalConstants['default'].ViewTypes.VIEW_TYPE_ANNUAL ? '' : ' ' + FundamentalCommonFunctions['default'].displayQuater(valueObject['PERIOD'] ? valueObject['PERIOD'] : valueObject['QUARTER_REQUESTED']));
                }
            }
        }
        return '';
    });

    Ember['default'].Handlebars.helper('getURL', function (dataObject, key, docDownloadURL, lang) {
        if (dataObject[key]) {
            var valueObject = dataObject[key];
            var docID = valueObject['DOC_ID'] ? valueObject['DOC_ID'] : '';
            var URL = docDownloadURL + docID + '&LANG=' + lang;
            return URL;
        }
        return '#';
    });

    Ember['default'].Handlebars.helper('getIcon', function (dataObject, key) {
        if (dataObject[key]) {
            var valueObject = dataObject[key];
            var docID = valueObject['DOC_ID'] ? valueObject['DOC_ID'] : '';
            if (docID === '') {
                return '';
            } else {
                return 'fa fa-file-pdf-o';
            }
        }
    });

    Ember['default'].Handlebars.helper('getDisplayRatio', function (dataObject, key, fieldID) {
        // ratios need not to be converted to millions so a this helper is specially used in Ratios page
        if (dataObject[key]) {
            var valueObject = dataObject[key];
            var temp;
            temp = utils['default'].formatters.formatNumber(valueObject[fieldID], 2); // todo [Banusha] currently put decimal places to 2. Later need to add symbol decimal places

            if (temp.charAt(0) === '-' && temp.charAt(1) !== '-') {
                temp = '(' + temp.substring(1, temp.length) + ')';
            }
            return temp;
        }
        return '';
    });

    Ember['default'].Handlebars.helper('getSubTitle', function (subTitleObj) {
        if (subTitleObj[0]) {
            return subTitleObj[0].groupHeadDesc;
        }
        return '';
    });

});