define('ir-app/controllers/ir-mobile/financial-ratio-widgets/default-financials', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/components/stk-specific-components/financial-chart', 'ir-app/components/stk-specific-components/financial-statement', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/utils/utils', 'ir-app/models/price/price-constants'], function (exports, Ember, BaseController, FinancialChart, FinancialStatement, priceService, sharedService, utils, priceConstants) {

    'use strict';

    exports['default'] = BaseController['default'].extend({

        quartersAll: Ember['default'].A(),
        quarters: Ember['default'].A(),
        annuals: Ember['default'].A(),
        annualsAll: Ember['default'].A(),
        contents: Ember['default'].A(),
        statementPeriodType: {},
        selectedPeriodType: {},
        previousSelectedPeriodType: {},
        toSelection: {},
        xDataSet: Ember['default'].A(),

        tempObj: Ember['default'].A(),
        tempObjQ: Ember['default'].A(),

        dataSet1: Ember['default'].A(),
        dataSet2: Ember['default'].A(),
        ratioList: Ember['default'].A(),
        stockFundamentalObj: null,
        isPageDataAvailable: false,
        isQuarterDataAvailable: false,
        isYearDataAvailable: false,

        onLoadWidget: function onLoadWidget() {
            this.startLoadingProgress();
            this.set('isQuarterDataAvailable', false);
            this.set('isYearDataAvailable', false);
            this.initializeComponent();
        },
        initializeComponent: function initializeComponent() {
            var that = this;
            var basicInfo = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, function () {}, function () {}, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyBasicInfo);

            this.set('basicInfo', basicInfo);

            this.statementPeriodType = [{ displayText: this.get('app').lang.labels['yearly'], value: priceConstants['default'].DataTypes.FundamentalDataStore.YearlyInfo }, { displayText: this.get('app').lang.labels['quarterly'], value: priceConstants['default'].DataTypes.FundamentalDataStore.QuarterlyInfo }];

            priceService['default'].fundamentalDS.getStoredObject(sharedService['default'].userSettings.currentExchange, sharedService['default'].userSettings.currentSymbol, sharedService['default'].userSettings.currentLanguage, function () {
                that.set('isQuarterDataAvailable', true);
            }, function () {
                that.onDataError();
            }, priceConstants['default'].DataTypes.FundamentalDataStore.QuarterlyInfo);

            priceService['default'].fundamentalDS.getStoredObject(sharedService['default'].userSettings.currentExchange, sharedService['default'].userSettings.currentSymbol, sharedService['default'].userSettings.currentLanguage, function () {
                that.set('isYearDataAvailable', true);
            }, function () {
                that.onDataError();
            }, priceConstants['default'].DataTypes.FundamentalDataStore.YearlyInfo);
        },

        prepareData: (function () {
            if (!this.get('isQuarterDataAvailable') || !this.get('isYearDataAvailable')) {
                return;
            }

            this.stopLoadingProgress();
            var statementType = this.get('type');
            var stockFundamentalObj = priceService['default'].fundamentalDS.getStoredObject(sharedService['default'].userSettings.currentExchange, sharedService['default'].userSettings.currentSymbol, sharedService['default'].userSettings.currentLanguage);
            this.set('stockFundamentalObj', stockFundamentalObj);
            this.set('isRatios', false);
            if (statementType === priceConstants['default'].FinancialStatementType.BalanceSheet) {
                this.set('annualsAll', Object.keys(stockFundamentalObj.getDataItemY().balanceSheetY));
                this.set('quartersAll', Object.keys(stockFundamentalObj.getDataItemQ().balanceSheetQ));
            } else if (statementType === priceConstants['default'].FinancialStatementType.IncomeStatement) {
                this.set('annualsAll', Object.keys(stockFundamentalObj.getDataItemY().incomeStatementY));
                this.set('quartersAll', Object.keys(stockFundamentalObj.getDataItemQ().incomeStatementQ));
            } else if (statementType === priceConstants['default'].FinancialStatementType.CashFlow) {
                this.set('annualsAll', Object.keys(stockFundamentalObj.getDataItemY().cashFlowY));
                this.set('quartersAll', Object.keys(stockFundamentalObj.getDataItemQ().cashFlowQ));
            } else if (statementType === priceConstants['default'].FinancialStatementType.Ratios) {
                this.set('isRatios', true);
                this.set('annualsAll', Object.keys(stockFundamentalObj.getDataItemY().ratiosMR_Y).filter(function (n) {
                    return Object.keys(stockFundamentalObj.getDataItemY().ratiosFR_Y).indexOf(n) !== -1;
                }));
                this.set('quartersAll', Object.keys(stockFundamentalObj.getDataItemQ().ratiosMR_Q).filter(function (n) {
                    return Object.keys(stockFundamentalObj.getDataItemQ().ratiosFR_Q).indexOf(n) !== -1;
                }));
            }
            this.annualsAll.reverse();

            for (var i = 0; i < this.annualsAll.length - 3; i++) {
                this.tempObj[i] = this.annualsAll[i] + ' - ' + this.annualsAll[i + 3];
            }

            var array = this.tempObj;

            for (var j = 0; j < this.quartersAll.length - 4; j++) {
                this.tempObjQ[j] = this.quartersAll[j + 3] + ' - ' + this.quartersAll[j];
            }

            var arrayQ = this.tempObjQ;

            this.set('quarters', arrayQ);
            this.set('annuals', array);

            if (utils['default'].webStorage.getObject(utils['default'].Constants.CacheKeys.FundamentalDataSelectedPeriodType) === undefined) {
                this.set('selectedPeriodType', this.get('statementPeriodType')[1]);
                utils['default'].webStorage.addObject(utils['default'].Constants.CacheKeys.FundamentalDataSelectedPeriodType, '1', utils['default'].Constants.StorageType.Local);
            } else {
                this.set('selectedPeriodType', this.get('statementPeriodType')[utils['default'].webStorage.getObject(utils['default'].Constants.CacheKeys.FundamentalDataSelectedPeriodType)]);
            }
        }).observes('isQuarterDataAvailable', 'isYearDataAvailable'),

        onCheckDataAvailability: function onCheckDataAvailability() {
            if (!this.get('isQuarterDataAvailable') || !this.get('isYearDataAvailable')) {
                return false;
            } else {
                return true;
            }
        },

        periodSelectionChanged: (function () {
            var stockFundamentalObj = this.get('stockFundamentalObj');
            var statementType = this.get('type');
            var select = this.get('toSelection');
            var index;
            var templateID = this.get('basicInfo').templateId;

            if (this.get('selectedPeriodType').value === priceConstants['default'].DataTypes.FundamentalDataStore.YearlyInfo) {
                index = this.annuals.indexOf(select);
            } else {
                index = this.quarters.indexOf(select);
            }

            if (statementType === priceConstants['default'].FinancialStatementType.BalanceSheet) {

                if (this.get('selectedPeriodType').value === priceConstants['default'].DataTypes.FundamentalDataStore.YearlyInfo) {
                    var dat1arr = [];
                    dat1arr.push(this.get('app').lang.labels['totalAssets']);
                    for (var k = 0; k < 4; k++) {
                        dat1arr.push(stockFundamentalObj.getDataItemY().getYearlyBS(this.annualsAll[index + k]).totalAssets);
                    }
                    this.set('dataSet1', dat1arr);
                    // this.set('dataSet1', [this.get('app').lang.labels['totalAssets'],
                    //    stockFundamentalObj.getDataItemY().getYearlyBS(this.annualsAll[index]).totalAssets,
                    //    stockFundamentalObj.getDataItemY().getYearlyBS(this.annualsAll[index + 1]).totalAssets,
                    //    stockFundamentalObj.getDataItemY().getYearlyBS(this.annualsAll[index + 2]).totalAssets,
                    //    stockFundamentalObj.getDataItemY().getYearlyBS(this.annualsAll[index + 3]).totalAssets]);
                    this.set('dataSet2', [this.get('app').lang.labels['totalEquity'], stockFundamentalObj.getDataItemY().getYearlyBS(this.annualsAll[index]).totalEquity, stockFundamentalObj.getDataItemY().getYearlyBS(this.annualsAll[index + 1]).totalEquity, stockFundamentalObj.getDataItemY().getYearlyBS(this.annualsAll[index + 2]).totalEquity, stockFundamentalObj.getDataItemY().getYearlyBS(this.annualsAll[index + 3]).totalEquity]);
                    this.set('xDataSet', ['x', this.annualsAll[index], this.annualsAll[index + 1], this.annualsAll[index + 2], this.annualsAll[index + 3]]);
                } else if (this.get('selectedPeriodType').value === priceConstants['default'].DataTypes.FundamentalDataStore.QuarterlyInfo) {

                    this.set('dataSet1', [this.get('app').lang.labels['totalAssets'], stockFundamentalObj.getDataItemQ().getQuarterlyBS(this.quartersAll[index + 3]).totalAssets, stockFundamentalObj.getDataItemQ().getQuarterlyBS(this.quartersAll[index + 2]).totalAssets, stockFundamentalObj.getDataItemQ().getQuarterlyBS(this.quartersAll[index + 1]).totalAssets, stockFundamentalObj.getDataItemQ().getQuarterlyBS(this.quartersAll[index + 0]).totalAssets]);
                    this.set('dataSet2', [this.get('app').lang.labels['totalEquity'], stockFundamentalObj.getDataItemQ().getQuarterlyBS(this.quartersAll[index + 3]).totalEquity, stockFundamentalObj.getDataItemQ().getQuarterlyBS(this.quartersAll[index + 2]).totalEquity, stockFundamentalObj.getDataItemQ().getQuarterlyBS(this.quartersAll[index + 1]).totalEquity, stockFundamentalObj.getDataItemQ().getQuarterlyBS(this.quartersAll[index + 0]).totalEquity]);
                    this.set('xDataSet', ['x', this.quartersAll[index + 3], this.quartersAll[index + 2], this.quartersAll[index + 1], this.quartersAll[index + 0]]);
                }
            } else if (statementType === priceConstants['default'].FinancialStatementType.IncomeStatement) {

                if (this.get('selectedPeriodType').value === priceConstants['default'].DataTypes.FundamentalDataStore.YearlyInfo) {
                    this.set('dataSet1', [this.get('app').lang.labels['netIncome'], stockFundamentalObj.getDataItemY().getYearlyIS(this.annualsAll[index]).netIncome, stockFundamentalObj.getDataItemY().getYearlyIS(this.annualsAll[index + 1]).netIncome, stockFundamentalObj.getDataItemY().getYearlyIS(this.annualsAll[index + 2]).netIncome, stockFundamentalObj.getDataItemY().getYearlyIS(this.annualsAll[index + 3]).netIncome]);

                    if (templateID === priceConstants['default'].TemplateID.Industrial) {
                        this.set('dataSet2', [this.get('app').lang.labels['totalRevenue'], stockFundamentalObj.getDataItemY().getYearlyIS(this.annualsAll[index]).totalRevenue, stockFundamentalObj.getDataItemY().getYearlyIS(this.annualsAll[index + 1]).totalRevenue, stockFundamentalObj.getDataItemY().getYearlyIS(this.annualsAll[index + 2]).totalRevenue, stockFundamentalObj.getDataItemY().getYearlyIS(this.annualsAll[index + 3]).totalRevenue]);
                    } else if (templateID === priceConstants['default'].TemplateID.Banks) {
                        this.set('dataSet2', [this.get('app').lang.labels['operatingIncome'], stockFundamentalObj.getDataItemY().getYearlyIS(this.annualsAll[index]).operatingIncome, stockFundamentalObj.getDataItemY().getYearlyIS(this.annualsAll[index + 1]).operatingIncome, stockFundamentalObj.getDataItemY().getYearlyIS(this.annualsAll[index + 2]).operatingIncome, stockFundamentalObj.getDataItemY().getYearlyIS(this.annualsAll[index + 3]).operatingIncome]);
                    } else if (templateID === priceConstants['default'].TemplateID.Insurance) {
                        this.set('dataSet2', [this.get('app').lang.labels['totalUWRevenue'], stockFundamentalObj.getDataItemY().getYearlyIS(this.annualsAll[index]).totalUWRevenue, stockFundamentalObj.getDataItemY().getYearlyIS(this.annualsAll[index + 1]).totalUWRevenue, stockFundamentalObj.getDataItemY().getYearlyIS(this.annualsAll[index + 2]).totalUWRevenue, stockFundamentalObj.getDataItemY().getYearlyIS(this.annualsAll[index + 3]).totalUWRevenue]);
                    }

                    this.set('xDataSet', ['x', this.annualsAll[index], this.annualsAll[index + 1], this.annualsAll[index + 2], this.annualsAll[index + 3]]);
                } else if (this.get('selectedPeriodType').value === priceConstants['default'].DataTypes.FundamentalDataStore.QuarterlyInfo) {

                    this.set('dataSet1', [this.get('app').lang.labels['netIncome'], stockFundamentalObj.getDataItemQ().getQuarterlyIS(this.quartersAll[index + 3]).netIncome, stockFundamentalObj.getDataItemQ().getQuarterlyIS(this.quartersAll[index + 2]).netIncome, stockFundamentalObj.getDataItemQ().getQuarterlyIS(this.quartersAll[index + 1]).netIncome, stockFundamentalObj.getDataItemQ().getQuarterlyIS(this.quartersAll[index + 0]).netIncome]);

                    if (templateID === priceConstants['default'].TemplateID.Industrial) {
                        this.set('dataSet2', [this.get('app').lang.labels['totalRevenue'], stockFundamentalObj.getDataItemQ().getQuarterlyIS(this.quartersAll[index + 3]).totalRevenue, stockFundamentalObj.getDataItemQ().getQuarterlyIS(this.quartersAll[index + 2]).totalRevenue, stockFundamentalObj.getDataItemQ().getQuarterlyIS(this.quartersAll[index + 1]).totalRevenue, stockFundamentalObj.getDataItemQ().getQuarterlyIS(this.quartersAll[index + 0]).totalRevenue]);
                    } else if (templateID === priceConstants['default'].TemplateID.Banks) {
                        this.set('dataSet2', [this.get('app').lang.labels['operatingIncome'], stockFundamentalObj.getDataItemQ().getQuarterlyIS(this.quartersAll[index + 3]).operatingIncome, stockFundamentalObj.getDataItemQ().getQuarterlyIS(this.quartersAll[index + 2]).operatingIncome, stockFundamentalObj.getDataItemQ().getQuarterlyIS(this.quartersAll[index + 1]).operatingIncome, stockFundamentalObj.getDataItemQ().getQuarterlyIS(this.quartersAll[index + 0]).operatingIncome]);
                    } else if (templateID === priceConstants['default'].TemplateID.Insurance) {
                        this.set('dataSet2', [this.get('app').lang.labels['totalUWRevenue'], stockFundamentalObj.getDataItemQ().getQuarterlyIS(this.quartersAll[index + 3]).totalUWRevenue, stockFundamentalObj.getDataItemQ().getQuarterlyIS(this.quartersAll[index + 2]).totalUWRevenue, stockFundamentalObj.getDataItemQ().getQuarterlyIS(this.quartersAll[index + 1]).totalUWRevenue, stockFundamentalObj.getDataItemQ().getQuarterlyIS(this.quartersAll[index + 0]).totalUWRevenue]);
                    }

                    this.set('xDataSet', ['x', this.quartersAll[index + 3], this.quartersAll[index + 2], this.quartersAll[index + 1], this.quartersAll[index + 0]]);
                }
            } else if (statementType === priceConstants['default'].FinancialStatementType.CashFlow) {

                if (this.get('selectedPeriodType').value === priceConstants['default'].DataTypes.FundamentalDataStore.YearlyInfo) {
                    this.set('dataSet1', [this.get('app').lang.labels['operatingActivities'], stockFundamentalObj.getDataItemY().getYearlyCF(this.annualsAll[index]).operatingActivities, stockFundamentalObj.getDataItemY().getYearlyCF(this.annualsAll[index + 1]).operatingActivities, stockFundamentalObj.getDataItemY().getYearlyCF(this.annualsAll[index + 2]).operatingActivities, stockFundamentalObj.getDataItemY().getYearlyCF(this.annualsAll[index + 3]).operatingActivities]);
                    this.set('dataSet2', [this.get('app').lang.labels['investingActivities'], stockFundamentalObj.getDataItemY().getYearlyCF(this.annualsAll[index]).investingActivities, stockFundamentalObj.getDataItemY().getYearlyCF(this.annualsAll[index + 1]).investingActivities, stockFundamentalObj.getDataItemY().getYearlyCF(this.annualsAll[index + 2]).investingActivities, stockFundamentalObj.getDataItemY().getYearlyCF(this.annualsAll[index + 3]).investingActivities]);
                    this.set('xDataSet', ['x', this.annualsAll[index], this.annualsAll[index + 1], this.annualsAll[index + 2], this.annualsAll[index + 3]]);
                } else if (this.get('selectedPeriodType').value === priceConstants['default'].DataTypes.FundamentalDataStore.QuarterlyInfo) {
                    this.set('dataSet1', [this.get('app').lang.labels['operatingActivities'], stockFundamentalObj.getDataItemQ().getQuarterlyCF(this.quartersAll[index + 3]).operatingActivities, stockFundamentalObj.getDataItemQ().getQuarterlyCF(this.quartersAll[index + 2]).operatingActivities, stockFundamentalObj.getDataItemQ().getQuarterlyCF(this.quartersAll[index + 1]).operatingActivities, stockFundamentalObj.getDataItemQ().getQuarterlyCF(this.quartersAll[index + 0]).operatingActivities]);
                    this.set('dataSet2', [this.get('app').lang.labels['investingActivities'], stockFundamentalObj.getDataItemQ().getQuarterlyCF(this.quartersAll[index + 3]).investingActivities, stockFundamentalObj.getDataItemQ().getQuarterlyCF(this.quartersAll[index + 2]).investingActivities, stockFundamentalObj.getDataItemQ().getQuarterlyCF(this.quartersAll[index + 1]).investingActivities, stockFundamentalObj.getDataItemQ().getQuarterlyCF(this.quartersAll[index + 0]).investingActivities]);
                    this.set('xDataSet', ['x', this.quartersAll[index + 3], this.quartersAll[index + 2], this.quartersAll[index + 1], this.quartersAll[index + 0]]);
                }
            } else if (statementType === priceConstants['default'].FinancialStatementType.Ratios) {

                templateID = this.get('basicInfo').templateId;

                if (this.get('selectedPeriodType').value === priceConstants['default'].DataTypes.FundamentalDataStore.YearlyInfo) {

                    if (templateID === priceConstants['default'].TemplateID.Industrial) {
                        // industrial
                        var arrTemplate1Y = [];
                        var temp1ArrFR = ['FR_EPS', 'FR_BVPS', 'FR_EV_EBITDA', 'FR_ROAE', 'FR_GrPrMarg', 'FR_NtPrMarg', 'FR_RevGrwt', 'FR_NPGRWT', 'FR_EPSGrwt', 'FR_CurRatio', 'FR_QkRatio', 'FR_TotDebtEq', 'FR_IntCov'];

                        var temp1ArrMR = ['FR_DPS', 'FR_PER', 'FR_PBR', 'FR_PSR', 'FR_DivYld'];

                        for (var m = 0; m < temp1ArrFR.length; m++) {
                            var temp1FRY = [this._getFRDataY(index + 0, temp1ArrFR[m]), this._getFRDataY(index + 1, temp1ArrFR[m]), this._getFRDataY(index + 2, temp1ArrFR[m]), this._getFRDataY(index + 3, temp1ArrFR[m])];
                            if (temp1FRY[0].displayText !== undefined) {
                                arrTemplate1Y.push(temp1FRY);
                            }
                        }

                        for (var n = 0; n < temp1ArrMR.length; n++) {
                            var temp1MRY = [this._getMRDataY(index + 0, temp1ArrMR[n]), this._getMRDataY(index + 1, temp1ArrMR[n]), this._getMRDataY(index + 2, temp1ArrMR[n]), this._getMRDataY(index + 3, temp1ArrMR[n])];
                            if (temp1MRY[0].displayText !== undefined) {
                                arrTemplate1Y.push(temp1MRY);
                            }
                        }
                        this.set('ratioList', arrTemplate1Y);
                    } else if (templateID === priceConstants['default'].TemplateID.Banks) {

                        // banks

                        var arrTemplate2Y = [];
                        var temp2ArrFRY = ['FR_EPS', 'FR_ROAE', 'FR_NPGRWT', 'FR_EPSGrwt', 'FR_NtLoanDep', 'FR_NtLoanTotAst', 'FR_DepTotAst'];

                        var temp2ArrMRY = ['FR_DPS', 'FR_PER', 'FR_PBR', 'FR_DivYld'];

                        for (var c = 0; c < temp2ArrFRY.length; c++) {
                            var temp2FRY = [this._getFRDataY(index + 0, temp2ArrFRY[c]), this._getFRDataY(index + 1, temp2ArrFRY[c]), this._getFRDataY(index + 2, temp2ArrFRY[c]), this._getFRDataY(index + 3, temp2ArrFRY[c])];
                            if (temp2FRY[0].displayText !== undefined) {
                                arrTemplate2Y.push(temp2FRY);
                            }
                        }

                        for (var d = 0; d < temp2ArrMRY.length; d++) {
                            var temp2MRY = [this._getMRDataY(index + 0, temp2ArrMRY[d]), this._getMRDataY(index + 1, temp2ArrMRY[d]), this._getMRDataY(index + 2, temp2ArrMRY[d]), this._getMRDataY(index + 3, temp2ArrMRY[d])];
                            if (temp2MRY[0].displayText !== undefined) {
                                arrTemplate2Y.push(temp2MRY);
                            }
                        }
                        this.set('ratioList', arrTemplate2Y);
                    } else if (templateID === priceConstants['default'].TemplateID.Insurance) {
                        // Insurance

                        var arrTemplate3Y = [];
                        var temp3ArrFRY = ['FR_EPS', 'FR_ROAE', 'FR_NPGRWT', 'FR_EPSGrwt'];

                        var temp3ArrMRY = ['FR_DPS', 'FR_PER', 'FR_PBR', 'FR_DivYld'];

                        for (var s = 0; s < temp3ArrFRY.length; s++) {
                            var temp3FRY = [this._getFRDataY(index + 0, temp3ArrFRY[s]), this._getFRDataY(index + 1, temp3ArrFRY[s]), this._getFRDataY(index + 2, temp3ArrFRY[s]), this._getFRDataY(index + 3, temp3ArrFRY[s])];
                            if (temp3FRY[0].displayText !== undefined) {
                                arrTemplate3Y.push(temp3FRY);
                            }
                        }

                        for (var t = 0; t < temp3ArrMRY.length; t++) {
                            var temp3MRY = [this._getMRDataY(index + 0, temp3ArrMRY[t]), this._getMRDataY(index + 1, temp3ArrMRY[t]), this._getMRDataY(index + 2, temp3ArrMRY[t]), this._getMRDataY(index + 3, temp3ArrMRY[t])];
                            if (temp3MRY[0].displayText !== undefined) {
                                arrTemplate3Y.push(temp3MRY);
                            }
                        }
                        this.set('ratioList', arrTemplate3Y);
                    }
                } else if (this.get('selectedPeriodType').value === priceConstants['default'].DataTypes.FundamentalDataStore.QuarterlyInfo) {

                    if (templateID === priceConstants['default'].TemplateID.Industrial) {
                        // industrial
                        var arrTemplate1Q = [];
                        var temp1ArrFRQ = ['FR_EPS', 'FR_BVPS', 'FR_EV_EBITDA', 'FR_ROAE', 'FR_GrPrMarg', 'FR_NtPrMarg', 'FR_RevGrwt', 'FR_NPGRWT', 'FR_EPSGrwt', 'FR_CurRatio', 'FR_QkRatio', 'FR_TotDebtEq', 'FR_IntCov'];

                        var temp1ArrMRQ = ['FR_DPS', 'FR_PER', 'FR_PBR', 'FR_PSR', 'FR_DivYld'];

                        for (var i = 0; i < temp1ArrFRQ.length; i++) {
                            var temp1FRQ = [this._getFRDataQ(index + 0, temp1ArrFRQ[i]), this._getFRDataQ(index + 1, temp1ArrFRQ[i]), this._getFRDataQ(index + 2, temp1ArrFRQ[i]), this._getFRDataQ(index + 3, temp1ArrFRQ[i])];
                            if (temp1FRQ[0].displayText !== undefined) {
                                arrTemplate1Q.push(temp1FRQ);
                            }
                        }

                        for (var j = 0; j < temp1ArrMRQ.length; j++) {
                            var temp1MRQ = [this._getMRDataQ(index + 0, temp1ArrMRQ[j]), this._getMRDataQ(index + 1, temp1ArrMRQ[j]), this._getMRDataQ(index + 2, temp1ArrMRQ[j]), this._getMRDataQ(index + 3, temp1ArrMRQ[j])];
                            if (temp1MRQ[0].displayText !== undefined) {
                                arrTemplate1Q.push(temp1MRQ);
                            }
                        }
                        this.set('ratioList', arrTemplate1Q);
                    } else if (templateID === priceConstants['default'].TemplateID.Banks) {
                        // banks
                        var arrTemplate2Q = [];
                        var temp2ArrFRQ = ['FR_EPS', 'FR_ROAE', 'FR_NPGRWT', 'FR_EPSGrwt', 'FR_NtLoanDep', 'FR_NtLoanTotAst', 'FR_DepTotAst'];

                        var temp2ArrMRQ = ['FR_DPS', 'FR_PER', 'FR_PBR', 'FR_DivYld'];

                        for (var x = 0; x < temp2ArrFRQ.length; x++) {
                            var temp2FRQ = [this._getFRDataQ(index + 0, temp2ArrFRQ[x]), this._getFRDataQ(index + 1, temp2ArrFRQ[x]), this._getFRDataQ(index + 2, temp2ArrFRQ[x]), this._getFRDataQ(index + 3, temp2ArrFRQ[x])];

                            if (temp2FRQ[0].displayText !== undefined) {
                                arrTemplate2Q.push(temp2FRQ);
                            }
                        }

                        for (var y = 0; y < temp2ArrMRQ.length; y++) {
                            var temp2MRQ = [this._getMRDataQ(index + 0, temp2ArrMRQ[y]), this._getMRDataQ(index + 1, temp2ArrMRQ[y]), this._getMRDataQ(index + 2, temp2ArrMRQ[y]), this._getMRDataQ(index + 3, temp2ArrMRQ[y])];

                            if (temp2MRQ[0].displayText !== undefined) {
                                arrTemplate2Q.push(temp2MRQ);
                            }
                        }
                        this.set('ratioList', arrTemplate2Q);
                    } else if (templateID === priceConstants['default'].TemplateID.Insurance) {
                        // Insurance
                        var arrTemplate3Q = [];
                        var temp3ArrFRQ = ['FR_EPS', 'FR_ROAE', 'FR_NPGRWT', 'FR_EPSGrwt'];

                        var temp3ArrMRQ = ['FR_DPS', 'FR_PER', 'FR_PBR', 'FR_DivYld'];

                        for (var p = 0; p < temp3ArrFRQ.length; p++) {
                            var temp3FRQ = [this._getFRDataQ(index + 0, temp3ArrFRQ[p]), this._getFRDataQ(index + 1, temp3ArrFRQ[p]), this._getFRDataQ(index + 2, temp3ArrFRQ[p]), this._getFRDataQ(index + 3, temp3ArrFRQ[p])];
                            if (temp3FRQ[0].displayText !== undefined) {
                                arrTemplate3Q.push(temp3FRQ);
                            }
                        }

                        for (var q = 0; q < temp3ArrMRQ.length; q++) {
                            var temp3MRQ = [this._getMRDataQ(index + 0, temp3ArrMRQ[q]), this._getMRDataQ(index + 1, temp3ArrMRQ[q]), this._getMRDataQ(index + 2, temp3ArrMRQ[q]), this._getMRDataQ(index + 3, temp3ArrMRQ[q])];
                            if (temp3MRQ[0].displayText !== undefined) {
                                arrTemplate3Q.push(temp3MRQ);
                            }
                        }
                        this.set('ratioList', arrTemplate3Q);
                    }
                }
            }
        }).observes('toSelection'),

        periodTypeSelectionChanged: (function () {
            this.setPeriodsOnTypeChange();
        }).observes('selectedPeriodType'),

        getRatioList: (function () {

            return this.get('ratioList');
        }).observes('ratioList.@each'),

        setPeriodsOnTypeChange: function setPeriodsOnTypeChange() {
            if (this.get('selectedPeriodType').value === 'A') {
                utils['default'].webStorage.addObject(utils['default'].Constants.CacheKeys.FundamentalDataSelectedPeriodType, '0', utils['default'].Constants.StorageType.Local);

                this.set('contents', this.get('annuals'));
                this.set('toSelection', this.get('contents')[0]);
            } else {
                utils['default'].webStorage.addObject(utils['default'].Constants.CacheKeys.FundamentalDataSelectedPeriodType, '1', utils['default'].Constants.StorageType.Local);

                this.set('contents', this.get('quarters'));
                this.set('toSelection', this.get('contents')[0]);
            }
        },

        _getFRDataQ: function _getFRDataQ(index, headerField) {

            var stockFundamentalObj = this.get('stockFundamentalObj');
            var periodQ = this.quartersAll[index];
            var indexQ = stockFundamentalObj.getDataItemQ().getQuarterlyFR(this.quartersAll[index]).headerArray[headerField];
            var valueQ = utils['default'].formatters.formatNumber(stockFundamentalObj.getDataItemQ().getQuarterlyFR(this.quartersAll[index]).dataArray[indexQ], 2);
            var strQ = stockFundamentalObj.getDataItemQ().getQuarterlyFR(this.quartersAll[index]).styleArray[indexQ];
            var labelQ;
            if (strQ !== undefined) {
                labelQ = strQ.split(utils['default'].Constants.StringConst.Tilde)[2].split(utils['default'].Constants.StringConst.Comma)[1];
            }

            return { displayText: labelQ, value: valueQ, period: periodQ };
        },

        _getMRDataY: function _getMRDataY(index, headerField) {

            var stockFundamentalObj = this.get('stockFundamentalObj');
            var periodY = this.annualsAll[index];
            var indexY = stockFundamentalObj.getDataItemY().getYearlyMR(this.annualsAll[index]).headerArray[headerField];
            var valueY = utils['default'].formatters.formatNumber(stockFundamentalObj.getDataItemY().getYearlyMR(this.annualsAll[index]).dataArray[indexY], 2);
            var strY = stockFundamentalObj.getDataItemY().getYearlyMR(this.annualsAll[index]).styleArray[indexY];
            var labelY;
            if (strY !== undefined) {
                labelY = strY.split(utils['default'].Constants.StringConst.Tilde)[2].split(utils['default'].Constants.StringConst.Comma)[1];
            }
            return { displayText: labelY, value: valueY, period: periodY };
        },

        _getMRDataQ: function _getMRDataQ(index, headerField) {

            var stockFundamentalObj = this.get('stockFundamentalObj');
            var periodQ = this.quartersAll[index];
            var indexQ = stockFundamentalObj.getDataItemQ().getQuarterlyMR(this.quartersAll[index]).headerArray[headerField];
            var valueQ = utils['default'].formatters.formatNumber(stockFundamentalObj.getDataItemQ().getQuarterlyMR(this.quartersAll[index]).dataArray[indexQ], 2);
            var strQ = stockFundamentalObj.getDataItemQ().getQuarterlyMR(this.quartersAll[index]).styleArray[indexQ];
            var labelQ;
            if (strQ !== undefined) {
                labelQ = strQ.split(utils['default'].Constants.StringConst.Tilde)[2].split(utils['default'].Constants.StringConst.Comma)[1];
            }

            return { displayText: labelQ, value: valueQ, period: periodQ };
        },

        _getFRDataY: function _getFRDataY(index, headerField) {

            var stockFundamentalObj = this.get('stockFundamentalObj');
            var periodY = this.annualsAll[index];
            var indexY = stockFundamentalObj.getDataItemY().getYearlyFR(this.annualsAll[index]).headerArray[headerField];
            var valueY = utils['default'].formatters.formatNumber(stockFundamentalObj.getDataItemY().getYearlyFR(this.annualsAll[index]).dataArray[indexY], 2);
            var strY = stockFundamentalObj.getDataItemY().getYearlyFR(this.annualsAll[index]).styleArray[indexY];
            var labelY;
            if (strY !== undefined) {
                labelY = strY.split(utils['default'].Constants.StringConst.Tilde)[2].split(utils['default'].Constants.StringConst.Comma)[1];
            }
            return { displayText: labelY, value: valueY, period: periodY };
        }
    });

    Ember['default'].Handlebars.helper('financial-chart', FinancialChart['default']);
    Ember['default'].Handlebars.helper('financial-statement', FinancialStatement['default']);

});