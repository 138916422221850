define('ir-app/controllers/ir-mobile/company-widgets/company-ownership-limits', ['exports', 'ember', 'ir-app/controllers/base-array-controller', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/models/price/price-constants'], function (exports, Ember, BaseArrayController, priceService, sharedService, PriceConstants) {

    'use strict';

    exports['default'] = BaseArrayController['default'].extend({

        ownershipLimits: '',
        arrSymbolPiValues: '',
        arrSymbolsPi: '',

        onLoadWidget: function onLoadWidget() {
            this.setErrorMessage();
        },

        onPrepareData: function onPrepareData() {
            var that = this;
            this.set('arrSymbolPiValues', Ember['default'].A());
            this.set('arrSymbolsPi', Ember['default'].A());
            that.startLoadingProgress();
            var owners;
            if (this.utils.validators.isAvailable(this.get('companyId'))) {
                owners = priceService['default'].companyProfileDS.getStoredObjectByCompanyId(this.get('companyId'), sharedService['default'].userSettings.currentLanguage, function () {
                    that.onDataSuccess();
                }, function () {
                    that.onDataError();
                }, PriceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyManagementInfo).compOwners;
            } else {
                owners = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, function () {
                    that.onDataSuccess();
                }, function () {
                    that.onDataError();
                }, PriceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyOwnershipLimits).ownershipLimits;
            }
        },

        onCheckDataAvailability: function onCheckDataAvailability() {
            var companyProfObj;
            if (this.utils.validators.isAvailable(this.get('companyId'))) {
                companyProfObj = priceService['default'].companyProfileDS.getStoredObjectByCompanyId(this.get('companyId'), sharedService['default'].userSettings.currentLanguage).ownershipLimits;
            } else {
                companyProfObj = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage).ownershipLimits;
            }
            this.set('ownershipLimits', companyProfObj);
            this.setData();
            return companyProfObj && companyProfObj.length > 0;
        },

        setData: function setData() {
            var that = this;
            that.get('arrSymbolPiValues').push(" ");
            that.get('arrSymbolsPi').push('x');
            var ownerships = that.get('ownershipLimits');
            var shares;
            Ember['default'].$.each(ownerships, function (key, val) {
                if (val.direction === 'Min') {
                    shares = 1 - val.value;
                } else {
                    shares = val.limit - val.value;
                }
                Ember['default'].set(val, 'shares', shares);
            });
            Ember['default'].$.each(ownerships, function (key, val) {
                that.get('arrSymbolPiValues').push(val.value);
                that.get('arrSymbolsPi').push(val.seriesName);
            });
            that.set('xDataSet', that.get('arrSymbolsPi'));
            that.set('dataSet', that.get('arrSymbolPiValues'));
        },

        onLanguageChanged: function onLanguageChanged() {
            this.onPrepareData();
        }
    });

});