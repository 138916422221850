define('ir-app/controllers/ir-widgets/currency-widgets/currency-list-view', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service'], function (exports, Ember, BaseController, priceService) {

    'use strict';

    exports['default'] = BaseController['default'].extend({

        currencyList: Ember['default'].A(),

        // Base overrides
        onPrepareData: function onPrepareData() {
            var keyList = this.get('keys');
            var exchange = this.get('exg');
            var tmpCurrencyList = this.get('currencyList');

            keyList.forEach(function (item) {
                var currency = priceService['default'].stockDS.getStock(exchange, item.sym);
                currency.deci = item.deci;
                tmpCurrencyList.push(currency);

                priceService['default'].addSymbolRequest(exchange, item.sym);
            });
        },

        onClearData: function onClearData() {
            var tmpCurrencyList = this.get('currencyList');

            tmpCurrencyList.forEach(function (item) {
                priceService['default'].removeSymbolRequest(item.exg, item.sym);
            });

            this.set('currencyList', []);
        }

    });

});