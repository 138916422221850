define('ir-app/models/price/price-data-stores/stock-data-store', ['exports', 'ember', 'ir-app/models/price/price-business-entities/stock', 'ir-app/utils/utils'], function (exports, Ember, stock, utils) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        store: {},
        stockMapByExg: {},
        indexMapByExg: {},
        indexTickerSymbols: {},

        getStock: function getStock(exchange, symbol) {
            var key = utils['default'].keyGenerator.getKey(exchange, symbol);
            var currentStore = this.get('store');
            var symbolObj = currentStore[key];

            if (!symbolObj) {
                symbolObj = stock['default'].create({ sym: symbol, exg: exchange, inst: 0 });
                // Start with 0 instrument type and later it is populated with correct value received from servers
                currentStore[key] = symbolObj;
            }

            return symbolObj;
        },

        setAnnouncement: function setAnnouncement(annObj) {
            var exchange = annObj.exg;
            var symbol = annObj.sym;

            if (utils['default'].validators.isAvailable(exchange) && utils['default'].validators.isAvailable(symbol)) {
                var stockObj = this.getStock(exchange, symbol);

                if (stockObj && (!stock['default'].lAnn || stockObj.lAnn.dateObj > annObj.dateObj)) {
                    stockObj.set('lAnn', annObj);
                    stockObj.set('ann', annObj.get('dHed'));
                }
            }
        },

        getStockCollection: function getStockCollection() {
            return this.get('store');
        },

        getIndexCollectionForExchange: function getIndexCollectionForExchange(exchange) {
            var currentIndexMapByExg = this.get('indexMapByExg');

            if (!currentIndexMapByExg[exchange]) {
                currentIndexMapByExg[exchange] = Ember['default'].A();
            }

            return currentIndexMapByExg[exchange];
        },

        addToSymbolCollection: function addToSymbolCollection(exchange, stockObj) {
            var currentStockMapByExg = this.get('stockMapByExg');

            if (currentStockMapByExg[exchange]) {
                if (!currentStockMapByExg[exchange].contains(stockObj)) {
                    currentStockMapByExg[exchange].pushObject(stockObj);
                }
            } else {
                currentStockMapByExg[exchange] = Ember['default'].A([stockObj]);
            }
        },

        addToIndexCollection: function addToIndexCollection(exchange, stockObj) {
            var currentIndexMapByExg = this.get('indexMapByExg');

            if (currentIndexMapByExg[exchange]) {
                if (!currentIndexMapByExg[exchange].contains(stockObj)) {
                    currentIndexMapByExg[exchange].pushObject(stockObj);
                }
            } else {
                currentIndexMapByExg[exchange] = Ember['default'].A([stockObj]);
            }
        },

        addToIndexTickerCollection: function addToIndexTickerCollection(tickerID, stockObj) {
            var currentIndexTickerSymbols = this.get('indexTickerSymbols');

            if (currentIndexTickerSymbols[tickerID]) {
                if (!currentIndexTickerSymbols[tickerID].contains(stockObj)) {
                    currentIndexTickerSymbols[tickerID].pushObject(stockObj);
                }
            } else {
                currentIndexTickerSymbols[tickerID] = Ember['default'].A([stockObj]);
            }
        },

        clearStock: function clearStock(exchange, symbol) {
            // this.set('store',{});
            delete this.get('store')[utils['default'].keyGenerator.getKey(exchange, symbol)];
        }

    }).create();

});