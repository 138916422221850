define('ir-app/controllers/ir-widgets/stock-overview-widgets/dividends', ['exports', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/models/price/price-constants'], function (exports, BaseController, priceService, sharedService, priceConstants) {

    'use strict';

    /**
     * Created by lakmaln on 5/10/2016.
     */

    exports['default'] = BaseController['default'].extend({

        stock: {},
        basicInfo: {},

        onPrepareData: function onPrepareData() {
            var stock = priceService['default'].stockDS.getStock(this.get('exg'), this.get('sym'));
            this.set('stock', stock);
            this._loadCompanyProfile();
            priceService['default'].addSymbolRequest(this.get('exg'), this.get('sym'));
        },

        onClearData: function onClearData() {
            priceService['default'].removeSymbolRequest(this.get('exg'), this.get('sym'));
        },

        onLanguageChanged: function onLanguageChanged() {
            this._loadCompanyProfile();
        },

        onCheckDataAvailability: function onCheckDataAvailability() {
            var companyProfObj = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage);

            if (companyProfObj) {
                return this.utils.validators.isAvailable(companyProfObj.compID);
            }
            return false;
        },

        _loadCompanyProfile: function _loadCompanyProfile() {
            var that = this;
            var basicInfo = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, function () {
                that.onDataSuccess();
            }, function () {
                that.onDataError();
            }, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyBasicInfo);

            this.set('basicInfo', basicInfo);
        }
    });

});