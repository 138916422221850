define('ir-app/models/price/price-business-entities/exchange', ['exports', 'ember', 'ir-app/utils/utils'], function (exports, Ember, utils) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        exg: '', // Exchange
        des: '', // Description
        dcf: '', // Decimal Correction Factor
        dep: '', // Decimal Places
        de: '', // Display exchange
        stat: 3, // Market Status
        isOpen: -1, // Flag used to identify the market is open
        cur: '', // Currency
        delTime: '', // Market Data Delayed Time (in minutes)
        country: '', // Country Code
        tzo: 0, // Time zone offset
        date: '', // Market Date
        time: '', // Market Time
        led: '', // Last Eod Date - Need to check the usage of this
        ups: '', // UPs
        dwns: '', // Downs
        nChg: '', // No Change
        symt: '', // Number of Symbols Traded
        vol: '', // Volume
        tovr: '', // TurnOver
        trades: '', // No Of Trades
        mktCap: '', // Market Capitalisation
        mboal: 0, // MboAdvancedLimit
        mbol: 0,
        mbpal: 0, // MbpAdvancedLimit
        mbpl: 0,
        mboae: false, // IsAdvancedMboEnabled
        mbpae: false, // IsAdvancedMbpEnabled
        virtual: false, // IsVirtual Exchange
        cio: '', // Cash In No of Orders
        civ: '', // Cash In Volume
        cit: '', // Cash In Turn Over
        coo: '', // Cash Out No of Orders
        cov: '', // Cash Out Volume
        cot: '', // Cash Out Turnover
        netCashPer: '', // Net Cash per
        cashInPer: '', // Cash in per
        mainIdx: '', // Main index
        newsProv: '', // News Provider
        openTime: '', // Market Open Time
        closeTime: '', // Market Close Time
        isSubMktAvailable: false, // is Sub Markets Available
        subMarkets: null, // Sub Markets Array
        indices: null, // Index array
        totalStks: null, // total stocks
        avgTransVal: '', //Average Transaction Value

        init: function init() {
            this._super();
            this.set('subMarkets', Ember['default'].A());
            this.set('indices', Ember['default'].A());
        },

        mktTime: (function () {
            return this.get('date') + '' + this.get('time');
        }).property('date', 'time'),

        exchangeDes: (function () {
            return utils['default'].formatters.convertUnicodeToNativeString(this.get('des'));
        }).property('des'),

        calculateCashPer: (function () {
            var cashInTurnOver = this.get('cit');
            var cashOutTurnOver = this.get('cot');

            this.set('cashInPer', cashInTurnOver * 100 / (cashInTurnOver + cashOutTurnOver));
        }).observes('cit', 'cot'),

        addToSubMarkets: function addToSubMarkets(subMkt) {
            var subMktArray = this.get('subMarkets');
            var isItemExist = false;
            subMktArray.forEach(function (item) {
                if (item.subMktCode === subMkt.subMktCode) {
                    isItemExist = true;
                    return;
                }
            });

            if (!isItemExist) {
                this.get('subMarkets').push(subMkt);
            }
        },

        getMainSubMkt: function getMainSubMkt() {
            var subMktArray = this.get('subMarkets');
            var selectedSubMkt = null;
            subMktArray.forEach(function (item) {
                if (item.isDefSubMkt) {
                    selectedSubMkt = item;
                    return; // Breaks the loop.
                }
            });

            return selectedSubMkt;
        },

        addIndex: function addIndex(indexCode) {
            var indexArray = this.get('indices');
            var isItemExist = false;
            indexArray.forEach(function (item) {
                if (item === indexCode) {
                    isItemExist = true;
                    return;
                }
            });

            if (!isItemExist) {
                this.get('indices').push(indexCode);
            }
        },

        setData: function setData(exchangeMessage) {
            var that = this;

            Ember['default'].$.each(exchangeMessage, function (key, value) {
                that.set(key, value);
            });
        },

        CalculateAvgTransVal: (function () {
            var turnover = this.get('tovr');
            var trades = this.get('trades');

            if (turnover && trades && trades !== 0) {
                this.set('avgTransVal', Math.round(turnover / trades, 5));
            }
        }).observes('tovr', 'trades')
    });

});