define('ir-app/models/price/price-business-entities/segmentation', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({

        Category: '',
        Year: '',
        Currency: '',
        FactSheet: '',
        Attributes: '',
        segmentationArr: Ember['default'].A(),

        setData: function setData(seg) {
            var that = this;
            Ember['default'].$.each(seg, function (key, value) {
                that.set(key, value);
            });
        }
    });

});