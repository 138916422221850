define('ir-app/models/price/price-data-stores/alert-user-data-store', ['exports', 'ember', 'ir-app/models/price/price-business-entities/alert-user', 'ir-app/models/price/price-service', 'ir-app/models/price/price-data-stores/base-data-store'], function (exports, Ember, alertUser, priceService, BaseStore) {

    'use strict';

    exports['default'] = BaseStore['default'].extend({

        getStoredObject: function getStoredObject(id, action, successFn, Error) {
            var key = this.getKey([id]);
            var cachedObj = this.dataStore[key];

            var postSuccess = function postSuccess() {
                cachedObj.metaObj.isAvailable = true;
                cachedObj.metaObj.isRequested = false;
                successFn(cachedObj.dataObj.alertUserArray);
            };

            var error = function error() {
                cachedObj.metaObj.isAvailable = false;
                cachedObj.metaObj.isRequested = false;
                Error();
            };

            if (!cachedObj) {
                cachedObj = this.createStoreCacheObj(id);
            }
            if (cachedObj.metaObj.isAvailable) {
                if (Ember['default'].$.isFunction(successFn)) {
                    successFn(cachedObj.dataObj.alertUserArray);
                }
            } else {
                if (!cachedObj.metaObj.isRequested) {
                    cachedObj.metaObj.isRequested = true;
                    priceService['default'].getAlertUserDetails(id, action, postSuccess, error);
                }
            }
            return cachedObj.dataObj;
        },
        createStoreCacheObj: function createStoreCacheObj(id) {
            var key = this.getKey([id]);
            var storeCacheObj = {
                dataObj: { alertUserArray: [] },
                metaObj: {}
            };
            this.dataStore[key] = storeCacheObj;
            return storeCacheObj;
        },

        createAlertUser: function createAlertUser() {
            return alertUser['default'].create();
        }

    }).create();

});