define('ir-app/controllers/ir-mobile/history-lookup-widget/advanced-history-lookup', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/models/chart/chart-constants', 'ir-app/components/stk-specific-components/candle-chart', 'ir-app/components/stk-specific-components/high-chart/basic-candle-chart', 'ir-app/models/shared/shared-service', 'ir-app/utils/utils'], function (exports, Ember, BaseController, priceService, chartConstants, CandleChart, BasicCandleChart, sharedService, utils) {

  'use strict';

  /*global unescape: true */
  /**
   * Created by User on 2/23/2016.
   */
  exports['default'] = BaseController['default'].extend({
    ohlcSeries: null,
    historyObject: null,
    chartPoints: [],
    fromDate: null,
    toDate: null,

    endDate: null, // Calendar End date
    dataAvailableForSelectedDate: false,
    numberOfCandlePoints: 5,
    stock: {},
    chartOHLCDataArray: Ember['default'].A(),
    minValue: -1,
    maxValue: -1,
    minValueDate: -1,
    maxValueDate: -1,
    minVolValue: -1,
    maxVolValue: -1,
    minVolValueDate: -1,
    maxVolValueDate: -1,
    minPerChgValue: -1,
    maxPerChgValue: -1,
    minPerChgValueDate: -1,
    maxPerChgValueDate: -1,
    isFirstTimeLoading: false,
    dataAvailable: false,
    notTradedOnStartDate: false,
    notTradedOnEndDate: false,
    validInputs: null,
    invalidInputs: null,
    isMobileView: false,

    onLoadWidget: function onLoadWidget() {
      var that = this;
      that.set('isFirstTimeLoading', true);
    },

    // Base overrides
    onPrepareData: function onPrepareData() {
      this.set('validInputs', false);
      this.set('invalidInputs', false);
      var that = this;
      priceService['default'].downloadHistoryOHLCData({
        exchange: this.get('exg'),
        symbol: this.get('sym'),
        begin: undefined,

        reqSuccessFn: function reqSuccessFn() {
          that.set('ohlcSeries', priceService['default'].ohlcDS.getOHLCSeries(that.get('exg'), that.get('sym'), chartConstants['default'].ChartCategory.History));
          that.set('oldestDate', that.get('ohlcSeries').ohlcDataPoints[0].DT);
        },
        reqFailureFn: function reqFailureFn() {
          if (that.onErrorFn && Ember['default'].$.isFunction(that.onErrorFn)) {
            that.onErrorFn();
          }
          // utils.logger.logDebug('Data Unavailable For History');
        },
        isLookupChart: true
      });

      //this.set('ohlcSeries', priceService.ohlcDS.getOHLCSeries(this.get('exg'), this.get('sym'), chartConstants.ChartCategory.History));

      // initialise the fromdate to current date and populate the last available data point
      var oneWeekAgo = new Date();
      oneWeekAgo.setYear(oneWeekAgo.getFullYear() - 1);
      // this.set('fromDate',oneWeekAgo);
      // this.set('toDate', new Date());
      // this.set('dataAvailable',true);

      var stock = priceService['default'].stockDS.getStock(this.get('exg'), this.get('sym'));
      this.set('stock', stock);

      // request symbol meta
      priceService['default'].sendSymbolValidationRequest(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, undefined, undefined);
    },

    calculateMiMaxValues: function calculateMiMaxValues(ohlcPoint) {
      var that = this;
      if (that.get('minValue') < 0 || ohlcPoint.Low < that.get('minValue')) {
        that.set('minValue', ohlcPoint.Low);
        that.set('minValueDate', ohlcPoint.DT);
      }
      if (that.get('maxValue') < 0 || ohlcPoint.High > that.get('maxValue')) {
        that.set('maxValue', ohlcPoint.High);
        that.set('maxValueDate', ohlcPoint.DT);
      }
      if (that.get('maxVolValue') < 0 || ohlcPoint.Volume > that.get('maxVolValue')) {
        that.set('maxVolValue', ohlcPoint.Volume);
        that.set('maxVolValueDate', ohlcPoint.DT);
      }
      if (that.get('minVolValue') < 0 || ohlcPoint.Volume < that.get('minVolValue')) {
        that.set('minVolValue', ohlcPoint.Volume);
        that.set('minVolValueDate', ohlcPoint.DT);
      }
      if (that.get('maxPerChgValue') < 0 || ohlcPoint.PerChange > that.get('maxPerChgValue')) {
        that.set('maxPerChgValue', ohlcPoint.PerChange);
        that.set('maxPerChgValueDate', ohlcPoint.DT);
      }
      if (ohlcPoint.PerChange < that.get('minPerChgValue')) {
        that.set('minPerChgValue', ohlcPoint.PerChange);
        that.set('minPerChgValueDate', ohlcPoint.DT);
      }
    },
    resetMinMax: function resetMinMax() {
      var that = this;
      that.set('minValue', -1);
      that.set('maxValue', -1);
      that.set('minVolValue', -1);
      that.set('maxVolValue', -1);
      that.set('minPerChgValue', -1);
      that.set('maxPerChgValue', -1);
    },

    _loadChart: function _loadChart() {

      var detailViewController = 'ir-mobile/chart-widgets/chart-widget';
      var controllerString = 'controller:' + detailViewController;
      var widgetController = this.container.lookupFactory(controllerString).create();
      widgetController.set('toDate', this.get('toDate'));
      widgetController.set('fromDate', this.get('fromDate'));
      widgetController.set('sym', this.get('sym'));
      widgetController.set('exg', this.get('exg'));
      widgetController.set('includeFourBoxes', true);
      widgetController.set('includeControls', true);
      widgetController.set('exportDisabled', true);
      if (this.get('hideFootNote')) {
        widgetController.set('hideFootNote', this.get('hideFootNote'));
      }
      if (this.get('fixedChartHeight')) {
        widgetController.set('fixedChartHeight', this.get('fixedChartHeight'));
      }
      widgetController.initializeWidget({ wn: controllerString.split('/').pop() });
      var route = this.container.lookup('route:application');
      route.render(detailViewController, {
        into: 'ir-mobile/history-lookup-widget/advanced-history-lookup',
        outlet: 'lookupChart',
        controller: widgetController
      });

      widgetController.onUnloadWidget();
    },

    calculateChangeValue: (function () {

      if (this.get('historyToObject') && this.get('historyObject')) {
        var periodChange = this.get('historyToObject').Close - this.get('historyObject').Close;

        var periodPerChange = periodChange / this.get('historyObject').Close * 100;

        this.set('periodChange', periodChange);
        this.set('periodPerChange', periodPerChange);
      }
    }).observes('historyToObject', 'historyObject'),

    validateInputs: function validateInputs() {
      // validate the inputs
      if (this.get('fromDate') == null || this.get('toDate') == null || this.get('toDate').getTime() < this.get('fromDate').getTime()) {
        this.set('invalidInputs', true);
        this.set('validInputs', false);
      } else {
        this.set('invalidInputs', false);
        this.set('validInputs', true);
      }
    },

    actions: {
      lookUp: function lookUp() {
        var that = this;
        that.validateInputs();
        that.set('dataAvailable', true);
        var fromDate = this.get('fromDate');
        fromDate.setHours(0);
        fromDate.setMinutes(0);
        fromDate.setSeconds(0);

        var chartPointFrom = this.get('ohlcSeries').queryNearestPoint(fromDate.getTime());
        that.set('historyObject', chartPointFrom);

        this.set('notTradedOnStartDate', utils['default'].validators.compareByDate(fromDate, chartPointFrom.DT) !== 0);

        var toDate = this.get('toDate');
        toDate.setHours(0);
        toDate.setMinutes(0);
        toDate.setSeconds(0);

        var chartPointTo = this.get('ohlcSeries').queryNearestPoint(toDate.getTime());
        that.set('historyToObject', chartPointTo);

        this.set('notTradedOnEndDate', utils['default'].validators.compareByDate(toDate, chartPointTo.DT) !== 0);

        that.set('chartPoints', this.get('ohlcSeries').queryPointsForRange(this.get('fromDate'), this.get('toDate')));

        that.get('chartOHLCDataArray').clear();
        that.resetMinMax();

        var dd, tmpTime;
        that.get('chartPoints').forEach(function (item) {
          dd = new Date(item.DT);
          tmpTime = Date.UTC(dd.getUTCFullYear(), dd.getUTCMonth(), dd.getUTCDate(), dd.getUTCHours(), dd.getUTCMinutes(), dd.getUTCSeconds(), dd.getUTCMilliseconds());
          that.get('chartOHLCDataArray').pushObject([tmpTime, item.Open, item.High, item.Low, item.Close, item.Change, item.PerChange]);
          that.calculateMiMaxValues(item);
        });
        this._loadChart();
      },

      tableToExcel: function tableToExcel(table, name, filename) {
        var uri = 'data:application/vnd.ms-excel;base64,',
            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
            base64 = function base64(s) {
          return window.btoa(unescape(encodeURIComponent(s)));
        },
            format = function format(s, c) {
          return s.replace(/{(\w+)}/g, function (m, p) {
            return c[p];
          });
        };

        if (!table.nodeType) {
          table = document.getElementById(table);
        }
        var ctx = { worksheet: name || 'Worksheet', table: table.innerHTML };

        document.getElementById("dlink").href = uri + base64(format(template, ctx));
        document.getElementById("dlink").download = filename;
        document.getElementById("dlink").click();
      }

    }

  });

  Ember['default'].Handlebars.helper('candle-chart', CandleChart['default']);
  Ember['default'].Handlebars.helper('basic-candle-chart', BasicCandleChart['default']);
  Ember['default'].Handlebars.helper('getDate', function (date) {

    if (date !== -1) {
      // return  (date.getUTCFullYear() + '/' + date.getMonth() + '/' + date.getUTCDate());

      var day = new Date(date);
      day.setDate(day.getDate() + 1);
      return day.toISOString().substring(0, 10);
    } else {

      return '';
    }
  });

});