define('ir-app/templates/ir-widgets/tab-layouts/stock-overview-compact-layout', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","tabsPane");
        dom.setAttribute(el1,"class","scroll");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"role","tablist");
        dom.setAttribute(el2,"class","stockoverview-widget margin-small-top print-interval");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("ul");
        dom.setAttribute(el3,"class","nav nav-tabs tabs-list no-padding");
        dom.setAttribute(el3,"id","pills-tab");
        dom.setAttribute(el3,"role","tablist");
        dom.setAttribute(el3,"style","display:block;");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"class"," nav-items active flex-item");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5,"class","nav-link topbrcer");
        dom.setAttribute(el5,"id","pills-home-tab");
        dom.setAttribute(el5,"data-toggle","pill");
        dom.setAttribute(el5,"href","#pills-shareGraph");
        dom.setAttribute(el5,"role","tab");
        dom.setAttribute(el5,"aria-controls","pills-home");
        dom.setAttribute(el5,"aria-selected","false");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"class","nav-item nav-items flex-item");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5,"class","nav-link");
        dom.setAttribute(el5,"id","pills-profile-tab");
        dom.setAttribute(el5,"data-toggle","pill");
        dom.setAttribute(el5,"href","#pills-shareData");
        dom.setAttribute(el5,"role","tab");
        dom.setAttribute(el5,"aria-controls","pills-profile");
        dom.setAttribute(el5,"aria-selected","false");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"class","nav-item nav-items flex-item");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5,"class","nav-link");
        dom.setAttribute(el5,"id","pills-contact-tab");
        dom.setAttribute(el5,"data-toggle","pill");
        dom.setAttribute(el5,"href","#pills-trade");
        dom.setAttribute(el5,"role","tab");
        dom.setAttribute(el5,"aria-controls","pills-contact");
        dom.setAttribute(el5,"aria-selected","false");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"class","nav-item nav-items flex-item");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5,"class","nav-link");
        dom.setAttribute(el5,"id","pills-contact-tab");
        dom.setAttribute(el5,"data-toggle","pill");
        dom.setAttribute(el5,"href","#pills-performance");
        dom.setAttribute(el5,"role","tab");
        dom.setAttribute(el5,"aria-controls","pills-contact");
        dom.setAttribute(el5,"aria-selected","false");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","tab-content");
        dom.setAttribute(el3,"id","myTabContent");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","tab-pane fade active in");
        dom.setAttribute(el4,"id","pills-shareGraph");
        dom.setAttribute(el4,"role","tabpanel");
        dom.setAttribute(el4,"aria-labelledby","home-tab");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","row full-height");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col-xs-12 full-height");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","row margin-large-bottom margin-large-top print-interval");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col-xs-12");
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","row margin-medium-bottom stkoverview");
        dom.setAttribute(el7,"id","row margin-medium-bottom");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col-xs-12 col-sm-12 print-interval");
        dom.setAttribute(el8,"id","col-xs-12 col-sm-12");
        dom.setAttribute(el8,"style","--animation-order: 2");
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        var el10 = dom.createTextNode("\n                                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col-xs-12 col-sm-12");
        dom.setAttribute(el8,"id","col-xs-12 col-sm-8");
        dom.setAttribute(el8,"style","--animation-order: 3");
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        var el10 = dom.createTextNode("\n                                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","tab-pane fade");
        dom.setAttribute(el4,"id","pills-shareData");
        dom.setAttribute(el4,"role","tabpanel");
        dom.setAttribute(el4,"aria-labelledby","profile-tab");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","tab-pane fade");
        dom.setAttribute(el4,"id","pills-trade");
        dom.setAttribute(el4,"role","tabpanel");
        dom.setAttribute(el4,"aria-labelledby","contact-tab");
        var el5 = dom.createTextNode("\n\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","tab-pane fade");
        dom.setAttribute(el4,"id","pills-performance");
        dom.setAttribute(el4,"role","tabpanel");
        dom.setAttribute(el4,"aria-labelledby","contact-tab");
        var el5 = dom.createTextNode("\n               ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n\n\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, content = hooks.content, inline = hooks.inline;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element0 = dom.childAt(fragment, [1]);
        var element1 = dom.childAt(element0, [1, 1]);
        var element2 = dom.childAt(element0, [3, 1]);
        var element3 = dom.childAt(element2, [1, 1, 1]);
        var element4 = dom.childAt(element3, [3]);
        var morph0 = dom.createMorphAt(dom.childAt(element1, [1, 1]),0,0);
        var morph1 = dom.createMorphAt(dom.childAt(element1, [3, 1]),0,0);
        var morph2 = dom.createMorphAt(dom.childAt(element1, [5, 1]),0,0);
        var morph3 = dom.createMorphAt(dom.childAt(element1, [7, 1]),0,0);
        var morph4 = dom.createMorphAt(dom.childAt(element3, [1, 1]),1,1);
        var morph5 = dom.createMorphAt(dom.childAt(element4, [1, 1]),1,1);
        var morph6 = dom.createMorphAt(dom.childAt(element4, [3, 1]),1,1);
        var morph7 = dom.createMorphAt(dom.childAt(element2, [3]),1,1);
        var morph8 = dom.createMorphAt(dom.childAt(element2, [5]),1,1);
        var morph9 = dom.createMorphAt(dom.childAt(element2, [7]),1,1);
        content(env, morph0, context, "app.lang.labels.shareGraph");
        content(env, morph1, context, "app.lang.labels.shareData");
        content(env, morph2, context, "app.lang.labels.trades");
        content(env, morph3, context, "app.lang.labels.performanceStk");
        inline(env, morph4, context, "outlet", ["w1"], {});
        inline(env, morph5, context, "outlet", ["w2"], {});
        inline(env, morph6, context, "outlet", ["w3"], {});
        inline(env, morph7, context, "outlet", ["w4"], {});
        inline(env, morph8, context, "outlet", ["w6"], {});
        inline(env, morph9, context, "outlet", ["w5"], {});
        return fragment;
      }
    };
  }()));

});