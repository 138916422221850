define('ir-app/models/price/price-business-entities/ipo-company-list', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({

        id: '', // Company ID
        name: '', // Company Name
        exg: '', // Exchange

        setData: function setData(companyList) {
            var that = this;

            Ember['default'].$.each(companyList, function (key, value) {
                that.set(key, value);
            });
        }

    });

});