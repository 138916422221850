define('ir-app/controllers/ir-widgets/company-screener-widget/company-screener-results-view', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/decypha/decypha-service', 'ir-app/models/decypha/decypha-service-constants', 'ir-app/models/decypha/decypha-master-data', 'ir-app/utils/utils'], function (exports, Ember, BaseController, DecyphaService, DecyphaConstant, DecyphaMasterData, Utils) {

    'use strict';

    /**
     * Created by chanakar on 2016-11-07.
     */
    exports['default'] = BaseController['default'].extend({
        companyResultsList: undefined,
        pageSize: DecyphaConstant['default'].CompanyScreenerPageSize,
        count: 0,
        current: 0,
        screenerDataType: undefined, // will set from configs
        countries: undefined,
        scrollContainerRef: undefined,
        isShowFilterPopup: false,
        companyName: undefined,
        countryList: DecyphaMasterData['default'].companyScreener.menaCountryList,
        gicsTree: DecyphaMasterData['default'].companyScreener.gicsTree,
        gicsTreeLevel2: [],
        gicsTreeLevel3: [],
        gicsTreeLevel4: [],
        isShowLevel2: false,
        isShowLevel3: false,
        isShowLevel4: false,
        resetLevel1Select: false,

        onPrepareData: function onPrepareData() {
            this.setErrorMessage();
            this.sendCompanyScreenerRequest(true);
        },
        onCheckDataAvailability: function onCheckDataAvailability() {
            return this.get('count') && this.get('count') > 0;
        },
        sendCompanyScreenerRequest: function sendCompanyScreenerRequest(isInitial, isEmail) {
            var that = this;
            if (isInitial) {
                this.startLoadingProgress();
            }
            DecyphaService['default'].sendCompanyScreenerRequest({
                pageSize: this.get('pageSize'),
                pageIndex: this.get('current'),
                countries: this.get('countries'),
                listingStatus: this.get('listingStatus'),
                industries: this.get('industries'),
                screenerDataType: isEmail ? DecyphaConstant['default'].DecyphaRequest.CompanyScreener.SDT.COMPANY_SCREENER_EXCEL : DecyphaConstant['default'].DecyphaRequest.CompanyScreener.SDT.COMPANY_SCREENER_DATA,
                filters: Utils['default'].validators.isAvailable(this.get('companyName')) ? DecyphaConstant['default'].DecyphaRequest.CompanyScreener.COMPANY_NAME + Utils['default'].Constants.StringConst.Colon + this.get('companyName') : undefined,
                reqSuccessFn: function reqSuccessFn(companies) {
                    if (!isEmail) {
                        if (companies && companies.DATA) {

                            if (isInitial) {
                                that.set('companyResultsList', companies.DATA);
                                that.set('count', companies.COUNT);
                            } else {
                                that.get('companyResultsList').pushObjects(companies.DATA);
                            }
                        } else {
                            Utils['default'].logger.logError('No Companies Available for give criteria');
                        }
                    } else {
                        that.set('emailStatus', '');
                        that.set('showEmailStatus', false);
                        if (companies && companies.STATUS && companies.STATUS === 'SUCCESS') {
                            that.set('emailStatus', 'Excel file sent to your email address');
                        } else {
                            that.set('emailStatus', 'Error sending email');
                        }
                        that.set('showEmailStatus', true);
                        Ember['default'].run.later(function () {
                            that.set('emailStatus', '');
                            that.set('showEmailStatus', false);
                        }, 3000);
                    }
                    that.onDataSuccess();
                },
                reqFailureFn: function reqFailureFn() {
                    if (isEmail) {
                        that.set('emailStatus', '');
                        that.set('showEmailStatus', false);
                        that.set('emailStatus', 'Error sending email');
                        that.set('showEmailStatus', true);
                        Ember['default'].run.later(function () {
                            that.set('emailStatus', '');
                            that.set('showEmailStatus', false);
                        }, 3000);
                    }
                    that.onDataError();
                }
            });
        },
        actions: {
            pageChanged: function pageChanged(current) {
                this.set('current', current);
                this.sendCompanyScreenerRequest(false);
            },
            closeFilterPopup: function closeFilterPopup() {
                this.set('isShowFilterPopup', false);
            },
            showResults: function showResults() {
                this.set('isShowFilterPopup', false);
                this.set('current', 0);
                this._setListingStatus();
                this.sendCompanyScreenerRequest(true);
            },
            changeCountries: function changeCountries(options) {
                this.set('countries', options);
            },
            emailExcel: function emailExcel() {
                this.set('emailStatus', 'Preparing email...');
                this.set('showEmailStatus', true);
                this.sendCompanyScreenerRequest(true, true);
            },
            chageGicsLevel1: function chageGicsLevel1(option) {
                this.set('isShowLevel2', true);
                this.set('isShowLevel3', false);
                this.set('isShowLevel4', false);
                this.set('gicsTreeLevel2', option.children);
                this.set('industries', this._createGicsString(1, option.key));
            },
            chageGicsLevel2: function chageGicsLevel2(option) {
                this.set('isShowLevel3', true);
                this.set('isShowLevel4', false);
                this.set('gicsTreeLevel3', option.children);
                this.set('industries', this._createGicsString(2, option.key));
            },
            chageGicsLevel3: function chageGicsLevel3(option) {
                this.set('isShowLevel4', true);
                this.set('gicsTreeLevel4', option.children);
                this.set('industries', this._createGicsString(3, option.key));
            },
            chageGicsLevel4: function chageGicsLevel4(option) {
                this.set('industries', this._createGicsString(4, option.key));
            },
            resetFilters: function resetFilters() {
                this.set('companyName', '');
                this.set('listed', false);
                this.set('multiListed', false);
                this.set('unlisted', false);
                this.set('industries', '');
                this.toggleProperty('resetLevel1Select');
                this.set('isShowLevel2', false);
                if (this.get('scrollContainerRef')) {
                    var container = Ember['default'].$('div[ref="' + this.get('scrollContainerRef') + '"]');
                    container.animate({ scrollTop: 0 }, "fast");
                }
            },
            showFilters: function showFilters() {
                if (this.get('scrollContainerRef')) {
                    var container = Ember['default'].$('div[ref="' + this.get('scrollContainerRef') + '"]');
                    container.animate({ scrollTop: 0 }, "fast");
                }
            }
        },
        _createGicsString: function _createGicsString(level, code) {
            var gicsString = "";
            if (level === 1) {
                gicsString = code + "|--|--|--";
            } else if (level === 2) {
                gicsString = "--|" + code + "|--|--";
            } else if (level === 3) {
                gicsString = "--|--|" + code + "|--";
            } else if (level === 4) {
                gicsString = "--|--|--|" + code;
            }
            return gicsString;
        },
        onLanguageChanged: function onLanguageChanged() {
            this.onPrepareData();
        },
        _setListingStatus: function _setListingStatus() {
            var listingStatus = [];
            if (this.get('listed') === true) {
                listingStatus.push('1');
            }

            if (this.get('multiListed') === true) {
                listingStatus.push('2');
            }

            if (this.get('unlisted') === true) {
                listingStatus.push('3');
            }

            this.set('listingStatus', listingStatus.join());
        }
    });

});