define('ir-app/mixins/base-widget-mixin', ['exports', 'ember', 'ir-app/models/shared/language/language-data-store', 'ir-app/models/shared/shared-data-stores/theme-data-store', 'ir-app/app-events', 'ir-app/utils/utils', 'ir-app/models/price/price-service', 'ir-app/models/price/price-constants'], function (exports, Ember, LanguageDataStore, ThemeDataStore, AppEvents, utils, priceService, priceConstants) {

    'use strict';

    exports['default'] = Ember['default'].Mixin.create({
        app: LanguageDataStore['default'].getLanguageObj(),
        thm: ThemeDataStore['default'].getThemeObj(),
        widgetContainer: undefined,
        storedArgs: {},

        // Full Screen parameters
        previousParent: null,
        previousWatchListStyleAttribute: null,
        previousFullScreenContainerStyleAttribute: null,
        isFullScreenMode: false,

        // Parameters for loading indicator and error message
        showError: false,
        errorMessage: '',
        isLoading: false,
        isObserving: false,
        observableProperty: '',
        isDataAvailable: false,

        // Link Parameters
        links: [{ code: 0, desc: '' }, { code: 1, desc: 'progress-up' }, { code: 2, desc: 'progress-down' }],
        defaultLink: { code: 1, desc: 'progress-up' },
        selectedLink: 1,

        // for Market states checking
        exchange: undefined,

        setArgs: function setArgs(args) {
            var that = this;

            Ember['default'].$.each(args, function (prop, val) {
                that.set(prop, val);
            });
        },

        saveWidget: function saveWidget(widgetArgs) {
            try {
                this.storedArgs = Ember['default'].$.extend({}, this.storedArgs, widgetArgs);

                if (this.widgetContainer) {
                    this.widgetContainer.saveWidgetArgs(this.get('wid'), this.storedArgs);
                }
            } catch (e) {
                utils['default'].logger.logError(e);
            }
        },

        initializeWidget: function initializeWidget(widgetDef, args, widgetContainer) {
            try {
                if (args) {
                    this.storedArgs = args.storedArgs;
                    this.widgetContainer = widgetContainer;

                    this.set('wid', widgetDef.id);
                    this.set('wkey', [widgetContainer.menuContent.id, widgetContainer.tabContent.id, widgetDef.id].join('-'));

                    this.setArgs(args.widgetArgs);
                }

                if (widgetContainer) {
                    this.set('gaKey', [widgetContainer.menuContent.title, widgetContainer.tabContent.outlet.split('.').pop(), widgetDef.wn.split('.').pop()].join(':'));
                } else {
                    this.set('gaKey', widgetDef.wn.split('.').pop());
                }

                this.onLoadWidget();
                this.onPrepareData();

                AppEvents['default'].subscribeSymbolChanged(this, this.get('selectedLink'));

                Ember['default'].run.schedule('afterRender', this, this.onAfterRender);
            } catch (e) {
                utils['default'].logger.logError(e);
            }
        },

        refreshWidget: function refreshWidget(args) {
            try {
                this.onClearData();
                this.setArgs(args);
                this.onPrepareData();
            } catch (e) {
                utils['default'].logger.logError(e);
            }
        },

        closeWidget: function closeWidget() {
            try {
                this.onClearData();
                this.onUnloadWidget();
            } catch (e) {
                utils['default'].logger.logError(e);
            }

            AppEvents['default'].unSubscribeSymbolChanged(this, this.get('selectedLink'));
        },

        registerForMarketStates: function registerForMarketStates(exg) {
            var exchange;
            if (exg) {
                priceService['default'].addExchangeRequest(exg);
                exchange = priceService['default'].exchangeDS.getExchange(exg);
            } else {
                priceService['default'].addExchangeRequest(this.get('exg'));
                exchange = priceService['default'].exchangeDS.getExchange(this.get('exg'));
            }
            this.set('exchange', exchange);
        },

        _isStatePreopen: (function () {
            var exg = this.get('exchange');
            if (exg && exg.stat === priceConstants['default'].MarketStatus.PreOpen) {
                this.onMktStatChangeToPreopen();
            }
        }).observes('exchange.stat'),

        onMktStatChangeToPreopen: function onMktStatChangeToPreopen() {
            // Specific widget should implement this method to do a task on change of market stat from close to pre open
        },

        onLoadWidget: function onLoadWidget() {
            // Specific widget should implement this method to provide specific functionality
            // Otherwise base function will be executed
        },

        onPrepareData: function onPrepareData() {
            // Specific widget should implement this method to provide specific functionality
            // Otherwise base function will be executed
        },

        onClearData: function onClearData() {
            // Specific widget should implement this method to provide specific functionality
            // Otherwise base function will be executed
        },

        onUnloadWidget: function onUnloadWidget() {
            // Specific widget should implement this method to provide specific functionality
            // Otherwise base function will be executed
        },

        onLanguageChanged: function onLanguageChanged() {
            // Specific widget should implement this method to provide specific functionality
            // Otherwise base function will be executed
        },

        onThemeChanged: function onThemeChanged() {
            // Specific widget should implement this method to provide specific functionality
            // Otherwise base function will be executed
        },

        onVisibilityChanged: function onVisibilityChanged() {
            // Specific widget should implement this method to provide specific functionality
            // Otherwise base function will be executed
            // This function triggered with boolean arg while document visibility changes as isHidden
        },

        onAfterRender: function onAfterRender() {
            // Specific widget should implement this method to provide specific functionality
            // Otherwise base function will be executed
        },

        onDataSuccess: function onDataSuccess() {
            this.stopLoadingProgress();
            this.set('isDataAvailable', this.onCheckDataAvailability());

            if (this.get('isDataAvailable')) {
                this.hideDataErrorMessage();
                this.cancelRequestTimeout(this.get('loadingTimeoutTimer'));

                if (this.get('isObserving')) {
                    this.removeObserver(this.get('observableProperty'), this.onDataSuccess);
                    this.set('isObserving', false);
                }
            } else {

                if (this.get('loadingTimeoutTimer')) {
                    this.hideDataErrorMessage();
                    this.startLoadingProgress();
                } else {
                    this.showDataErrorMessage();
                }
            }
        },

        onDataError: function onDataError() {
            this.stopLoadingProgress();
            this.set('isDataAvailable', this.onCheckDataAvailability());

            if (this.get('isDataAvailable')) {
                this.hideDataErrorMessage();
            } else {
                this.showDataErrorMessage();
            }
        },

        showDataErrorMessage: function showDataErrorMessage() {
            this.set('showError', true);
        },

        hideDataErrorMessage: function hideDataErrorMessage() {
            this.set('showError', false);
        },

        setErrorMessage: function setErrorMessage() {
            this.set('errorMessage', this.get('app').lang.messages.dataNotAvailable);
        },

        startLoadingProgress: function startLoadingProgress() {
            this.set('isDataAvailable', false);
            this.hideDataErrorMessage();
            this.set('isLoading', true);
        },

        stopLoadingProgress: function stopLoadingProgress() {
            this.set('isLoading', false);
        },

        setRequestTimeout: function setRequestTimeout(timeout, observableProperty) {
            // TODO: [Bashitha] Handle default timeout
            var that = this;

            this.set('observableProperty', observableProperty);
            this.cancelRequestTimeout(this.get('loadingTimeoutTimer'));

            this.set('loadingTimeoutTimer', Ember['default'].run.later(function () {
                that.onDataError();
            }, timeout * 1000));

            this.addObserver(observableProperty, this.onDataSuccess);
            this.set('isObserving', true);
            this.startLoadingProgress();
        },

        cancelRequestTimeout: function cancelRequestTimeout(timer) {
            if (timer) {
                Ember['default'].run.cancel(timer);
            }
        },

        toggleFullScreen: function toggleFullScreen(widgetContainerId, widgetId, fullViewHeight, regularViewHeight) {
            var fullScreenContainer = document.getElementById('fullScreenContainer');
            var widgetContainer = document.getElementById(widgetContainerId);
            var widget = document.getElementById(widgetId);
            var body = document.body;

            if (!this.get('previousParent')) {
                this.set('previousParent', widgetContainer.parentElement);
                this.set('previousWatchListStyleAttribute', widgetContainer.getAttribute('style'));
                this.set('previousFullScreenContainerStyleAttribute', fullScreenContainer.getAttribute('style'));
                fullScreenContainer.appendChild(widgetContainer);
                widgetContainer.setAttribute('style', 'position: absolute; left: 0; top: 0; bottom: 0; right: 0;');
                fullScreenContainer.setAttribute('style', 'z-index:300; position: absolute; top: 0; width: 100%; height: 100%');

                var html = document.documentElement;
                var height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

                widget.setAttribute('style', 'height:' + fullViewHeight ? fullViewHeight : height + 'px');
            } else {
                this.get('previousParent').appendChild(widgetContainer);
                widgetContainer.setAttribute('style', this.get('previousWatchListStyleAttribute'));
                fullScreenContainer.setAttribute('style', this.get('previousFullScreenContainerStyleAttribute'));
                this.set('previousParent', null);

                if (regularViewHeight) {
                    widget.setAttribute('style', 'height:' + regularViewHeight + 'px');
                }
            }
        },

        setWidgetLink: function setWidgetLink(option, popupContainer) {
            // TODO [AROSHA] Discuss a better way to change popup Keys
            if (this.get('selectedLink')) {
                AppEvents['default'].unSubscribeSymbolChanged(this, this.get('selectedLink'), popupContainer);
            }

            this.set('selectedLink', option.code);
            AppEvents['default'].subscribeSymbolChanged(this, this.get('selectedLink'), popupContainer);
            this.saveWidget({ selectedLink: option.code });
        },

        onWidgetKeysChange: function onWidgetKeysChange(args) {
            this.refreshWidget(args);
        }
    });

});