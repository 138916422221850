define('ir-app/models/price/price-data-stores/depth-data-store', ['exports', 'ir-app/models/price/price-business-entities/depth', 'ir-app/utils/utils'], function (exports, depth, utils) {

    'use strict';

    exports['default'] = (function () {
        var depthStore = {};

        var getDepthItem = function getDepthItem(exchange, symbol, depthType) {
            var key = _getKey(exchange, symbol, depthType);
            var depthObj = depthStore[key];

            if (!depthObj) {
                depthObj = depth['default'].create({
                    sym: symbol,
                    exg: exchange,
                    dt: depthType
                });
                depthStore[key] = depthObj;
            }

            return depthObj;
        };

        var _getKey = function _getKey(exchange, symbol, depthType) {
            return [exchange, utils['default'].Constants.StringConst.Tilde, symbol, utils['default'].Constants.StringConst.Tilde, depthType].join('');
        };

        return {
            getDepthItem: getDepthItem
        };
    })();

});