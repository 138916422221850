define('ir-app/models/price/price-business-entities/fundamentals/fundaental-common-functions', ['exports'], function (exports) {

    'use strict';

    /**
     * Created by banushas on 8/17/2016.
     */
    exports['default'] = (function () {
        /**
         * To get the display quater for various kind for quater declarations.
         * @param quater String to validate
         * @returns {string} display quater string
         */
        var displayQuater = function displayQuater(quater) {

            switch (quater) {
                case '1':
                case '11':
                case '21':
                    return 'Q1';
                case '2':
                case '12':
                case '22':
                    return 'Q2';
                case '3':
                case '13':
                case '23':
                    return 'Q3';
                case '4':
                case '5':
                case '14':
                case '24':
                    return 'Q4';
                default:
                    return '';

            }
        };

        var actualQuarter = function actualQuarter(quater) {

            switch (quater) {
                case '1':
                case '11':
                case '21':
                    return 1;
                case '2':
                case '12':
                case '22':
                    return 2;
                case '3':
                case '13':
                case '23':
                    return 3;
                case '4':
                case '5':
                case '14':
                case '24':
                    return 4;
                default:
                    return '';

            }
        };

        return {
            displayQuater: displayQuater,
            actualQuarter: actualQuarter
        };
    })();

});