define('ir-app/controllers/ir-widgets/video-widget/video-file', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service'], function (exports, Ember, BaseController, PriceService, SharedService) {

    'use strict';

    exports['default'] = BaseController['default'].extend({
        videoUrls: [],
        videoArray: '',
        titles: [],
        videos: [],
        videoSrc: '',

        onPrepareData: function onPrepareData() {
            this.sendVideoRequest();
        },
        sendVideoRequest: function sendVideoRequest() {
            var that = this;
            var exg = this.get('exg');
            var sym = this.get('sym');
            var lan = SharedService['default'].userSettings.currentLanguage;
            PriceService['default'].videoDS.getStoredObject(exg, sym, lan, function (data) {
                that.displayVideos(data);
            }, function () {
                //  alert("error");
            });
        },

        displayVideos: function displayVideos(data) {
            var that = this;
            var count = 0;
            that.set('videoSrc', data[0].Url);
            Ember['default'].$.each(data, function (index, val) {
                if (!that.get('titles').contains(val.Title)) {
                    that.get('titles').pushObject(val.Title);
                }
            });

            Ember['default'].$.each(that.get('titles'), function (ind, value) {
                that.get('videoUrls').push({});
                var videoTitle = value;
                var VidArray = [];
                Ember['default'].$.each(data, function (index, val) {
                    if (val.Title === value) {
                        VidArray.push(val.Url);
                    }
                });
                Ember['default'].set(that.get('videoUrls')[count], 'Title', videoTitle);
                Ember['default'].set(that.get('videoUrls')[count], 'Urls', VidArray);
                count++;
            });

            that.set('videoArray', that.get('videoUrls'));
            Ember['default'].$('#videoPage').on('click', 'section', function (e) {
                that.set('videoSrc', e.target.id + "?rel=0&autoplay=1");
            });
            console.log("Error in video Response");
        },
        onLanguageChanged: function onLanguageChanged() {
            this.onPrepareData();
        },

        actions: {
            onSectionClick: function onSectionClick() {
                Ember['default'].$(window).scrollTop(0);
            }
        }

    });

});