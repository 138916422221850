define('ir-app/models/price/price-business-entities/corporate-meta', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({

        id: '', // corporate Action Mix ID
        desc: '', // corporate Action Name
        type: '', // corpType

        setData: function setData(nonCapitalAction) {
            var that = this;

            Ember['default'].$.each(nonCapitalAction, function (key, value) {
                that.set(key, value);
            });
        }

    });

});