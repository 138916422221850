define('ir-app/views/table/table-mixins/formatter-mixin', ['exports', 'ember', 'ir-app/config/user-settings', 'ir-app/utils/utils'], function (exports, Ember, userSettings, utils) {

    'use strict';

    exports['default'] = Ember['default'].Mixin.create({
        addFormat: function addFormat(currentValue, deci, isSecondValue) {
            var dataType = this.get('column.dataType');
            var value = currentValue;

            if (value) {
                if (dataType === 'int' || dataType === 'float') {
                    // TODO [AROSHA] Use common formatter for int/float formatting
                    var decimalPlaces = dataType === 'int' ? 0 : isSecondValue ? this.get('column.noOfSecValueDecimalPlaces') : deci ? deci : this.get('column.noOfDecimalPlaces');
                    if (decimalPlaces < 0) {
                        decimalPlaces = 2; //set default as 2 decimal places
                    }
                    value = utils['default'].formatters.formatNumber(value, decimalPlaces || decimalPlaces === 0 ? decimalPlaces : userSettings['default'].displayFormat.decimalPlaces);
                } else if (dataType === 'percentage') {
                    value = utils['default'].formatters.formatNumber(value, 2);
                } else if (dataType === 'date') {
                    // Formatted first value as Date second value as Time
                    var formatted = value;

                    if (value && value.length >= 8) {
                        // Date
                        formatted = utils['default'].formatters.formatToDate(value, 0);
                    }

                    value = formatted;
                } else if (dataType === 'time') {
                    var time = value;

                    if (value && value.length >= 6) {
                        // Date  // TODO [AROSHA] remove checker to already formatted time - dual cell
                        // var offset = this.get('column.offset');
                        // alert(this.get('column.offset'));
                        // time = utils.formatters.formatToTime(value, offset ? offset : 0);
                        time = utils['default'].formatters.formatToTime(value, 0);
                    }

                    value = time;
                }
            } else if (value === 0) {
                // To put decimal places for Zero values
                if (dataType === 'int' || dataType === 'float') {
                    var decPlaces = dataType === 'int' ? 0 : isSecondValue ? this.get('column.noOfSecValueDecimalPlaces') : deci ? deci : this.get('column.noOfDecimalPlaces');
                    value = utils['default'].formatters.formatNumber(value, decPlaces || decPlaces === 0 ? decPlaces : userSettings['default'].displayFormat.decimalPlaces);
                } else if (dataType === 'percentage') {
                    value = utils['default'].formatters.formatNumber(value, 2);
                }
            }

            return value;
        },

        addPercentageFormat: function addPercentageFormat(value, decimalPlaces) {
            return utils['default'].formatters.formatNumberPercentage(value, decimalPlaces || decimalPlaces === 0 ? decimalPlaces : userSettings['default'].displayFormat.decimalPlaces);
        },

        formattedFirstValue: Ember['default'].computed(function () {
            return this.addFormat(this.get('cellContent') ? this.get('cellContent').firstValue : undefined, this.get('cellContent') ? this.get('cellContent').firstValueDesi : undefined);
        }).property('cellContent')

        // formattedSecondValue: Ember.computed(function () {
        //     return this.addFormat(this.get('cellContent') ? this.get('cellContent').secondValue : undefined, 2, true); // TODO: [Amila] Implement the same logic as the primary value is custom decimal formatting is needed for second value also
        // }).property('cellContent')
    });

});