define('ir-app/models/price/price-data-stores/fundamental-data-store', ['exports', 'ember', 'ir-app/models/price/price-data-stores/base-data-store', 'ir-app/models/price/price-service', 'ir-app/models/price/price-business-entities/stock-fundamental', 'ir-app/models/price/price-constants'], function (exports, Ember, BaseStore, priceService, fundamentalData, priceConstants) {

    'use strict';

    /**
     * Created by Chiran on 6/16/16
     */
    exports['default'] = BaseStore['default'].extend({

        getStoredObject: function getStoredObject(exchange, symbol, language, successFn, errorFn, dataType) {
            var key = this.getKey([exchange, symbol, language]);
            var cachedObj = this.dataStore[key];

            var postSuccess = function postSuccess() {
                cachedObj.metaObj[dataType].isAvailable = true;
                cachedObj.metaObj[dataType].isRequested = false;

                var successFns = cachedObj.metaObj[dataType].successFns;

                Ember['default'].$.each(successFns, function (index, sFn) {
                    if (Ember['default'].$.isFunction(sFn)) {
                        sFn();
                    }
                });
                cachedObj.metaObj[dataType].successFns = [];
            };

            var error = function error() {
                cachedObj.metaObj[dataType].isAvailable = false;
                cachedObj.metaObj[dataType].isRequested = false;

                var errorFns = cachedObj.metaObj[dataType].errorFn;

                Ember['default'].$.each(errorFns, function (index, eFn) {
                    if (Ember['default'].$.isFunction(eFn)) {
                        eFn();
                    }
                });
                cachedObj.metaObj[dataType].errorFns = [];
            };

            if (!cachedObj) {
                cachedObj = this.createStoreCacheObj(exchange, symbol, language);
            }

            if (dataType === undefined) {
                return cachedObj.dataObj;
            }

            if (cachedObj.metaObj[dataType].isAvailable) {
                if (Ember['default'].$.isFunction(successFn)) {
                    successFn();
                }
            } else {
                this.addCallbackFunctions(cachedObj, dataType, successFn, errorFn);

                if (!cachedObj.metaObj[dataType].isRequested) {
                    cachedObj.metaObj[dataType].isRequested = true;

                    priceService['default'].sendFundamentalDataRequest(exchange, symbol, language, dataType, postSuccess, error, '1');
                }
            }
            return cachedObj.dataObj;
        },

        createStoreCacheObj: function createStoreCacheObj(exchange, symbol, language) {
            var key = this.getKey([exchange, symbol, language]);
            var storeCacheObj = {
                dataObj: {},
                metaObj: {}
            };
            storeCacheObj.dataObj = fundamentalData['default'].create({
                exg: exchange,
                sym: symbol,
                lan: language
            });
            var fundamentalDataTypes = priceConstants['default'].DataTypes.FundamentalDataStore;
            this.initMetaData(storeCacheObj, fundamentalDataTypes);
            this.dataStore[key] = storeCacheObj;

            return storeCacheObj;
        }

    }).create();

});