define('ir-app/templates/ir-widgets/exchange-summary/exchange-summary', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","col-xs-12 margin-large-bottom");
        var el2 = dom.createTextNode("\n        ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","row border-row");
        var el3 = dom.createTextNode("\n            ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col-xs-12 padding-medium-top padding-medium-bottom border-top");
        var el4 = dom.createTextNode("\n                ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"id","marketStatusLabel");
        dom.setAttribute(el4,"class","text-align-left left");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" :");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n                ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"id","marketStatus");
        var el5 = dom.createTextNode("\n                    ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n            ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col-xs-12 padding-medium-top padding-medium-bottom border-top");
        var el4 = dom.createTextNode("\n                ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"id","volumeLabel");
        dom.setAttribute(el4,"class","text-align-left left");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" :");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n                ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"id","volume");
        dom.setAttribute(el4,"class","text-align-right right");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n            ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col-xs-12 padding-medium-top padding-medium-bottom border-top");
        var el4 = dom.createTextNode("\n                ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"id","turnoverLabel");
        dom.setAttribute(el4,"class","text-align-left left");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" :");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n                ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"id","turnover");
        dom.setAttribute(el4,"class","text-align-right right");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n            ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col-xs-12 padding-medium-top padding-medium-bottom border-top");
        var el4 = dom.createTextNode("\n                ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"id","noOfTradesLabel");
        dom.setAttribute(el4,"class","text-align-left left");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" :");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n                ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"id","noOfTrades");
        dom.setAttribute(el4,"class","text-align-right right");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n            ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col-xs-12 padding-medium-top padding-medium-bottom border-top");
        var el4 = dom.createTextNode("\n                ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"id","stocksTradedListedLabel");
        dom.setAttribute(el4,"class","text-align-left left");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" :");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n                ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"id","stocksTradedListed");
        dom.setAttribute(el4,"class","text-align-right right");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","col-xs-12");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"id","exchangeBar");
        dom.setAttribute(el2,"class","row exchange-bar");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, content = hooks.content, get = hooks.get, subexpr = hooks.subexpr, concat = hooks.concat, attribute = hooks.attribute, inline = hooks.inline;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element0 = dom.childAt(fragment, [0, 1]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element1, [3]);
        var element3 = dom.childAt(element0, [3]);
        var element4 = dom.childAt(element0, [5]);
        var element5 = dom.childAt(element0, [7]);
        var element6 = dom.childAt(element0, [9]);
        var morph0 = dom.createMorphAt(dom.childAt(element1, [1]),0,0);
        var morph1 = dom.createMorphAt(element2,1,1);
        var attrMorph0 = dom.createAttrMorph(element2, 'class');
        var morph2 = dom.createMorphAt(dom.childAt(element3, [1]),0,0);
        var morph3 = dom.createMorphAt(dom.childAt(element3, [3]),0,0);
        var morph4 = dom.createMorphAt(dom.childAt(element4, [1]),0,0);
        var morph5 = dom.createMorphAt(dom.childAt(element4, [3]),0,0);
        var morph6 = dom.createMorphAt(dom.childAt(element5, [1]),0,0);
        var morph7 = dom.createMorphAt(dom.childAt(element5, [3]),0,0);
        var morph8 = dom.createMorphAt(dom.childAt(element6, [1]),0,0);
        var morph9 = dom.createMorphAt(dom.childAt(element6, [3]),0,0);
        var morph10 = dom.createMorphAt(dom.childAt(fragment, [2, 1]),1,1);
        content(env, morph0, context, "app.lang.labels.marketStatus");
        attribute(env, attrMorph0, element2, "class", concat(env, ["text-align-right right ", subexpr(env, context, "getStyleforMktStatus", [get(env, context, "exchange.stat"), "", "green", "", ""], {})]));
        inline(env, morph1, context, "getMarketStatus", [get(env, context, "app.lang.labels"), get(env, context, "exchange.stat")], {});
        content(env, morph2, context, "app.lang.labels.volume");
        inline(env, morph3, context, "formatNumber", [get(env, context, "exchange.vol"), 0], {});
        content(env, morph4, context, "app.lang.labels.turnover");
        inline(env, morph5, context, "formatNumber", [get(env, context, "exchange.tovr"), 0], {});
        content(env, morph6, context, "app.lang.labels.noOfTrades");
        inline(env, morph7, context, "formatNumber", [get(env, context, "exchange.trades"), 0], {});
        content(env, morph8, context, "app.lang.labels.stocksTradedListed");
        content(env, morph9, context, "exchange.symt");
        inline(env, morph10, context, "portal-up-down-flat-display-bar", [], {"ups": get(env, context, "exchange.ups"), "downs": get(env, context, "exchange.dwns"), "flats": get(env, context, "exchange.nChg")});
        return fragment;
      }
    };
  }()));

});