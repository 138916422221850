define('ir-app/models/price/price-data-stores/exchange-data-store', ['exports', 'ir-app/models/price/price-business-entities/exchange'], function (exports, Exchange) {

    'use strict';

    exports['default'] = (function () {
        var store = {};

        var getExchange = function getExchange(exchange) {
            if (store[exchange]) {
                return store[exchange];
            } else {
                store[exchange] = Exchange['default'].create({ 'exg': exchange });
                return store[exchange];
            }
        };

        return {
            getExchange: getExchange
        };
    })();

});