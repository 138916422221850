define('ir-app/models/price/price-data-stores/currency-data-store', ['exports', 'ember', 'ir-app/utils/utils', 'ir-app/models/price/price-business-entities/currency', 'ir-app/models/price/price-service'], function (exports, Ember, utils, currency, priceService) {

    'use strict';

    /**
     * Created by asankah on 4/6/2016.
     */
    exports['default'] = (function () {

        var store = {};
        var flagsAndFns = {};

        var getCurrency = function getCurrency(prCode, successFn, errorFn) {

            var pairCode = prCode.toUpperCase();
            var key = _getKey(pairCode);
            var currencyFlagsAndFns = flagsAndFns[key];
            var currencyObj = store[key];

            // the function to call after a success data retrieved
            var postSuccess = function postSuccess(dataObj) {
                currencyFlagsAndFns.dataAvailable = true;
                var successFns = currencyFlagsAndFns.successFns;

                Ember['default'].$.each(successFns, function (index, sFn) {
                    if (Ember['default'].$.isFunction(sFn)) {
                        sFn(dataObj);
                    }
                });
            };
            // error function to be called in case of error occured while data retrieving
            var error = function error() {
                store[key] = undefined;
                var errorFns = currencyFlagsAndFns.errorFns;

                Ember['default'].$.each(errorFns, function (index, eFn) {
                    if (Ember['default'].$.isFunction(eFn)) {
                        eFn();
                    }
                });
                flagsAndFns[key] = undefined;
            };

            var addFnsToArray = function addFnsToArray() {
                currencyFlagsAndFns.successFns[currencyFlagsAndFns.successFns.length] = successFn;
                currencyFlagsAndFns.errorFns[currencyFlagsAndFns.successFns.length] = errorFn;
            };

            if (!currencyFlagsAndFns) {
                currencyObj = currency['default'].create({
                    pairCode: pairCode
                });

                currencyFlagsAndFns = {
                    dataAvailable: false,
                    successFns: [],
                    errorFns: []
                };

                store[key] = currencyObj;
                flagsAndFns[key] = currencyFlagsAndFns;
                addFnsToArray();
                priceService['default'].sendCurrencyRequest(pairCode, postSuccess, error);
            } else {
                if (currencyFlagsAndFns.dataAvailable) {
                    currencyObj = store[key];
                    if (Ember['default'].$.isFunction(successFn)) {
                        successFn();
                    }
                } else {
                    addFnsToArray();
                    currencyObj = store[key];
                }
            }
            return currencyObj;
        };

        var _getKey = function _getKey(pairCode) {
            // AED~USD
            return [pairCode.substring(0, 3), utils['default'].Constants.StringConst.Tilde, pairCode.substring(3)].join('');
        };

        return {
            getCurrency: getCurrency
        };
    })();

});