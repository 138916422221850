define('ir-app/views/table/table-mixins/blink-mixin', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Mixin.create({
        previousCellValue: -1,
        currentBoundValue: null,

        clearStyles: Ember['default'].computed(function () {
            var that = this;

            try {
                Ember['default'].run.later(function () {
                    if (!(that.get('isDestroyed') || that.get('isDestroying'))) {
                        that.set('currentBoundValue', -1);
                    }
                }, 1000);
            } catch (e) {
                return;
            }

            return null;
        }).property('cellContent'),

        valueWillChange: (function () {
            this.set('previousCellValue', this.get('cellContent') ? this.get('cellContent').firstValue : undefined);
        }).observesBefore('cellContent'),

        blinkStyle: Ember['default'].computed(function () {
            var preVal, currentVal, cellStyle;
            preVal = this.get('previousCellValue');
            currentVal = this.get('cellContent') ? this.get('cellContent').firstValue : undefined;

            if (typeof preVal === 'string') {
                // Since updating only Numeric values, parse to float makes comparison perfect
                preVal = parseFloat(preVal);
            }

            if (typeof currentVal === 'string') {
                currentVal = parseFloat(currentVal);
            }

            if (this.get('column.isBlink') && preVal !== 'undefined' && currentVal !== 'undefined' && currentVal !== -1 && preVal !== -1) {
                if (this.get('currentBoundValue') === -1) {
                    cellStyle = '';
                } else if (preVal > currentVal) {
                    cellStyle = this.get('column.blinkDownStyle') ? this.get('column.blinkDownStyle') : 'blink-style-down';
                } else if (preVal < currentVal) {
                    cellStyle = this.get('column.blinkUpStyle') ? this.get('column.blinkUpStyle') : 'blink-style-up';
                }
            }

            this.set('currentBoundValue', 0);
            return cellStyle;
        }).property('cellContent', 'currentBoundValue')
    });

});