define('ir-app/components/column-context-menu', ['exports', 'ember', 'ir-app/utils/utils'], function (exports, Ember, utils) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        layoutName: 'components/column-context-menu',
        filterText: null,
        shownColumns: Ember['default'].computed.oneWay('columns'),

        checkFilterMatch: function checkFilterMatch(columnName, filterText) {
            var isMatchedTextFilter = true;

            if (utils['default'].validators.isAvailable(filterText)) {
                var columnWords = columnName.split(utils['default'].Constants.StringConst.Space);
                isMatchedTextFilter = false;

                Ember['default'].$.each(columnWords, function (index, word) {
                    if (word.slice(0, filterText.length).toLowerCase() === filterText.toLowerCase()) {
                        isMatchedTextFilter = true;
                        return false;
                    }
                });
            }

            return isMatchedTextFilter;
        },

        filterStocks: (function () {
            var filterText = this.get('filterText');

            var filteredColumns = this.get('columns').filter((function (that) {
                //eslint-disable-line
                return function (column) {
                    return that.checkFilterMatch(column.displayName, filterText);
                };
            })(this));

            this.set('shownColumns', filteredColumns); // Need to capture filter removing event to avoid 'set' without filters
        }).observes('filterText'),

        click: function click(event) {
            var target = Ember['default'].$(event.target);

            if (!target.hasClass('btn')) {
                event.stopPropagation();
            }
        },

        actions: {
            save: function save() {
                this.sendAction('saveAction');
            },

            cancel: function cancel() {
                this.sendAction('cancelAction');
            }
        }
    });

});