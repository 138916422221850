define('ir-app/controllers/ir-widgets/fair-value-widgets/analyst-coverage', ['exports', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/controllers/base-array-controller', 'ir-app/models/price/price-constants'], function (exports, priceService, sharedService, BaseArrayController, priceConstants) {

    'use strict';

    exports['default'] = BaseArrayController['default'].extend({
        onLoadWidget: function onLoadWidget() {
            this.setErrorMessage();
        },

        onPrepareData: function onPrepareData() {
            var that = this;
            this.startLoadingProgress();

            var analystContacts = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, function () {
                that.onDataSuccess();
            }, function () {
                that.onDataError();
            }, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyAnalystContacts).analystContacts;

            this.set('model', analystContacts);
        },

        onCheckDataAvailability: function onCheckDataAvailability() {

            var companyProfObj = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage);
            return companyProfObj && companyProfObj.analystContacts && companyProfObj.analystContacts.length > 0;
        },

        onLanguageChanged: function onLanguageChanged() {
            this.onPrepareData();
        },

        setErrorMessage: function setErrorMessage() {

            if (this.get('arrangedContent') && this.get('arrangedContent').length > 0) {
                this.hideDataErrorMessage();
            } else if (!this.get('isLoading')) {
                this.showDataErrorMessage();
                this.set('errorMessage', this.get('app').lang.messages.dataNotAvailable);
            }
        }
    });

});