define('ir-app/components/multi-select-with-search', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    /**
     * Created by channas on 2016-11-16.
     */

    exports['default'] = Ember['default'].Component.extend({

        dataTree: null,
        divId: null,

        _initializeAutoComplete: (function () {
            Ember['default'].$('#' + this.get('divId')).setAutocomplete({
                data: this.get('dataTree'),
                multiSelect: true,
                selectingType: 3
            });
        }).on('didInsertElement')
    });

});