define('ir-app/controllers/symbol-container-controller', ['exports', 'ember', 'ir-app/controllers/base-widget-container', 'ir-app/models/shared/shared-service', 'ir-app/config/app-config'], function (exports, Ember, BaseWidgetContainer, sharedService, appConfig) {

    'use strict';

    exports['default'] = BaseWidgetContainer['default'].extend({

        showTitle: false,
        showExchange: false,

        onLoadContainer: function onLoadContainer(args, tabList, menuContent) {
            this._super();

            this.set('tabList', tabList);
            this.set('showTabs', menuContent.tab.length > 1);
        },

        getContainerArgs: function getContainerArgs(menuContent) {
            // var symbol, exchange, insType;
            var containerArgs = this._super(menuContent); // Call the getContainerArgs() function in base controller
            // var savedSettings = sharedService.userState.lastArgs;

            /** if (savedSettings && savedSettings[menuContent.id] && savedSettings[menuContent.id].sym && savedSettings[menuContent.id].exg) {
                symbol = savedSettings[menuContent.id].sym;
                exchange = savedSettings[menuContent.id].exg;
                insType = savedSettings[menuContent.id].inst;
            } else {
                var store = priceService.stockDS.get('stockMapByExg');
                  if (store) {
                    exchange = sharedService.userSettings.currentExchange;
                    var symbolList = store[exchange];
                      if (symbolList && symbolList.length > 0) {
                        var defaultSymbol = symbolList[0];
                          symbol = defaultSymbol.sym; // Get first symbol as the default symbol
                        insType = defaultSymbol.inst;
                    }
                }
            }
              containerArgs.sym = symbol;
            containerArgs.exg = exchange;
            containerArgs.inst = insType; */

            containerArgs.sym = sharedService['default'].userSettings.currentSymbol;
            containerArgs.exg = sharedService['default'].userSettings.currentExchange;
            containerArgs.lan = sharedService['default'].userSettings.currentLanguage.toLowerCase();
            containerArgs.inst = appConfig['default'].customisation.defaultInstType;
            containerArgs.companyId = sharedService['default'].userSettings.currentCompId;

            return containerArgs;
        },

        onSymbolChanged: function onSymbolChanged(symbol, exchange, insType) {
            this.saveSettings(symbol, exchange, insType);

            if (this.controllers) {
                Ember['default'].$.each(this.controllers, function (key, controller) {
                    if (controller) {
                        controller.onWidgetKeysChange({
                            sym: symbol,
                            exg: exchange,
                            inst: insType
                        });
                    }
                });
            }
        },

        saveSettings: function saveSettings(symbol, exchange, insType) {
            var menuId = this.get('menuContent').id;
            var savedSettings = sharedService['default'].userState.lastArgs;

            savedSettings = savedSettings || {};
            savedSettings[menuId] = savedSettings[menuId] || {};

            savedSettings[menuId].sym = symbol;
            savedSettings[menuId].exg = exchange;
            savedSettings[menuId].inst = insType;

            sharedService['default'].userState.lastArgs = savedSettings;
            sharedService['default'].userState.save();
        }
    });

});