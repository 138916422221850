define('ir-app/models/price/price-business-entities/ipo-industry-list', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({

        id: '', // Industry ID
        desc: '', // Industry Name

        setData: function setData(industryList) {
            var that = this;

            Ember['default'].$.each(industryList, function (key, value) {
                that.set(key, value);
            });
        }

    });

});