define('ir-app/controllers/authentication/free-login-authenticator', ['exports', 'ember', 'ir-app/models/price/price-service'], function (exports, Ember, priceService) {

    'use strict';

    /**
     * Created by amila on 2/11/16.
     */
    exports['default'] = Ember['default'].Controller.extend({
        resendSubscriptions: false,

        /* *
         * Authenticate user
         * @param username Username
         * @param password Password
         * @param allowInit Allow application to initialize before login
         * @private
         */
        authenticateUser: function authenticateUser(uname, pwd, allowInit, authSuc, authFl) {
            var that = this;

            priceService['default'].authenticateWithUsernameAndPassword({
                username: uname,
                password: pwd,
                resendSubscriptions: that.resendSubscriptions,
                authSuccess: authSuc,
                authFailed: authFl
            });
        }
    }).create();

});