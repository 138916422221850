define('ir-app/controllers/base-array-controller', ['exports', 'ember', 'ir-app/mixins/base-widget-mixin'], function (exports, Ember, BaseWidgetMixin) {

    'use strict';

    exports['default'] = Ember['default'].ArrayController.extend(BaseWidgetMixin['default'], {

        needs: ['symbol-container-controller', 'exchange-container-controller'],

        setValueToBaseSymbolContainer: function setValueToBaseSymbolContainer(key, value) {
            this.get('controllers.symbol-container-controller').set(key, value);
        },

        setValueToBaseExchangeContainer: function setValueToBaseExchangeContainer(key, value) {
            this.get('controllers.exchange-container-controller').set(key, value);
        }

    });

});