define('ir-app/controllers/ir-mobile/history-lookup-widget/history-lookup', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/models/chart/chart-constants', 'ir-app/components/stk-specific-components/candle-chart', 'ir-app/components/stk-specific-components/high-chart/basic-candle-chart', 'ir-app/models/shared/shared-service'], function (exports, Ember, BaseController, priceService, chartConstants, CandleChart, BasicCandleChart, sharedService) {

    'use strict';

    /**
     * Created by User on 2/23/2016.
     */
    exports['default'] = BaseController['default'].extend({
        ohlcSeries: null,
        historyObject: null,
        lastThreePoints: [],
        fromDate: null,
        endDate: null, // Calendar End date
        dataAvailableForSelectedDate: false,
        numberOfCandlePoints: 5,
        stock: {},
        chartOHLCDataArray: Ember['default'].A(),
        minValue: -1,
        maxValue: -1,
        isFirstTimeLoading: false,

        onLoadWidget: function onLoadWidget() {
            var that = this;
            that.set('isFirstTimeLoading', true);
        },
        // Base overrides
        onPrepareData: function onPrepareData() {
            this.set('ohlcSeries', priceService['default'].ohlcDS.getOHLCSeries(this.get('exg'), this.get('sym'), chartConstants['default'].ChartCategory.History));

            // initialise the fromdate to current date and populate the last available data point
            this.set('fromDate', new Date());
            this.set('endDate', new Date());
            this._onChangeDate();

            var stock = priceService['default'].stockDS.getStock(this.get('exg'), this.get('sym'));
            this.set('stock', stock);

            // request symbol meta
            priceService['default'].sendSymbolValidationRequest(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, undefined, undefined);
        },

        _onChangeDate: (function () {
            var that = this;
            var date = this.get('fromDate');
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);

            var chartPoint = this.get('ohlcSeries').queryNearestPoint(date.getTime());
            that.set('historyObject', chartPoint);
            that.set('lastThreePoints', this.get('ohlcSeries').queryNearestChartPoints(this.get('historyObject'), this.get('numberOfCandlePoints')));

            that.get('chartOHLCDataArray').clear();
            that.resetMinMax();

            var dd, tmpTime;
            that.get('lastThreePoints').forEach(function (item) {
                dd = new Date(item.DT);
                tmpTime = Date.UTC(dd.getUTCFullYear(), dd.getUTCMonth(), dd.getUTCDate(), dd.getUTCHours(), dd.getUTCMinutes(), dd.getUTCSeconds(), dd.getUTCMilliseconds());
                that.get('chartOHLCDataArray').pushObject([tmpTime, item.Open, item.High, item.Low, item.Close]);
                that.calculateMiMaxValues(item);
            });

            var chartPointDate = chartPoint.DT;
            chartPointDate.setHours(0);
            chartPointDate.setMinutes(0);
            chartPointDate.setSeconds(0);

            if (that.get('isFirstTimeLoading')) {
                that.set('isFirstTimeLoading', false);
                that.set('dataAvailableForSelectedDate', true);
                that.set('fromDate', new Date(chartPointDate.getTime()));
            } else {
                that.set('dataAvailableForSelectedDate', date.getTime() === chartPointDate.getTime());
            }
        }).observes('fromDate'),

        calculateMiMaxValues: function calculateMiMaxValues(ohlcPoint) {
            var that = this;
            if (that.get('minValue') < 0 || ohlcPoint.Low < that.get('minValue')) {
                that.set('minValue', ohlcPoint.Low);
            }
            if (that.get('maxValue') < 0 || ohlcPoint.High > that.get('maxValue')) {
                that.set('maxValue', ohlcPoint.High);
            }
        },
        resetMinMax: function resetMinMax() {
            var that = this;
            that.set('minValue', -1);
            that.set('maxValue', -1);
        }
    });

    Ember['default'].Handlebars.helper('candle-chart', CandleChart['default']);
    Ember['default'].Handlebars.helper('basic-candle-chart', BasicCandleChart['default']);

});