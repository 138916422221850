define('ir-app/models/price/price-business-entities/fundamental-data', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        // Basic Information
        year: '', // year
        quater: '', // quater
        balanceSheetY: null,
        incomeStatementY: null,
        cashFlowY: null,
        balanceSheetQ: null,
        incomeStatementQ: null,
        cashFlowQ: null,
        ratiosMR_Q: null,
        ratiosFR_Y: null,

        init: function init() {
            this._super();
            this.set('balanceSheetQ', Ember['default'].A());
            this.set('incomeStatementQ', Ember['default'].A());
            this.set('cashFlowQ', Ember['default'].A());
            this.set('ratiosMR_Q', Ember['default'].A());
            this.set('ratiosFR_Q', Ember['default'].A());
            this.set('balanceSheetY', Ember['default'].A());
            this.set('incomeStatementY', Ember['default'].A());
            this.set('cashFlowY', Ember['default'].A());
            this.set('ratiosMR_Y', Ember['default'].A());
            this.set('ratiosFR_Y', Ember['default'].A());
        },

        // Quarterly data

        getQuarterlyIS: function getQuarterlyIS(k) {

            return this.incomeStatementQ[k];
        },

        setQuarterlyIS: function setQuarterlyIS(map) {

            this.incomeStatementQ = map;
        },
        getQuarterlyBS: function getQuarterlyBS(k) {

            return this.balanceSheetQ[k];
        },

        setQuarterlyBS: function setQuarterlyBS(map) {

            this.balanceSheetQ = map;
        },
        getQuarterlyCF: function getQuarterlyCF(k) {

            return this.cashFlowQ[k];
        },

        setQuarterlyCF: function setQuarterlyCF(map) {

            this.cashFlowQ = map;
        },

        getQuarterlyMR: function getQuarterlyMR(k) {

            return this.ratiosMR_Q[k];
        },

        setQuarterlyMR: function setQuarterlyMR(map) {

            this.ratiosMR_Q = map;
        },
        getQuarterlyFR: function getQuarterlyFR(k) {

            return this.ratiosFR_Q[k];
        },

        setQuarterlyFR: function setQuarterlyFR(map) {

            this.ratiosFR_Q = map;
        },

        // Yearly data

        getYearlyIS: function getYearlyIS(k) {

            return this.incomeStatementY[k];
        },

        setYearlyIS: function setYearlyIS(map) {

            this.incomeStatementY = map;
        },
        getYearlyBS: function getYearlyBS(k) {

            return this.balanceSheetY[k];
        },

        setYearlyBS: function setYearlyBS(map) {

            this.balanceSheetY = map;
        },
        getYearlyCF: function getYearlyCF(k) {

            return this.cashFlowY[k];
        },

        setYearlyCF: function setYearlyCF(map) {

            this.cashFlowY = map;
        },

        getYearlyMR: function getYearlyMR(k) {

            return this.ratiosMR_Y[k];
        },

        setYearlyMR: function setYearlyMR(map) {

            this.ratiosMR_Y = map;
        },
        getYearlyFR: function getYearlyFR(k) {

            return this.ratiosFR_Y[k];
        },

        setYearlyFR: function setYearlyFR(map) {

            this.ratiosFR_Y = map;
        },
        setData: function setData(fundamentalData) {
            var that = this;

            Ember['default'].$.each(fundamentalData, function (key, value) {
                that.set(key, value);
            });
        }
    });

});