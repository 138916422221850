define('ir-app/models/price/price-data-stores/individual-profile-data-store', ['exports', 'ember', 'ir-app/models/price/price-business-entities/individual-profile', 'ir-app/models/price/price-service', 'ir-app/models/price/price-constants', 'ir-app/utils/utils', 'ir-app/models/price/price-data-stores/base-data-store'], function (exports, Ember, profileObj, priceService, priceConstants, utils, BaseStore) {

    'use strict';

    exports['default'] = BaseStore['default'].extend({

        /**
         * Override base method
         * get stored object... if dataType is available initialize data for the DS object.
         * @param individualId  contact Person Id
         * @param language
         * @param companyId
         * @param dataType optional
         * @param successFn functions to run on success data loading
         * @param errorFn   functions to run on error
         * @returns {*}
         */

        getStoredObject: function getStoredObject(individualId, companyId, language, successFn, errorFn, dataType) {

            var key = this.getKey([individualId, companyId, language]);
            var cachedObj = this.dataStore[key];

            // the function to call after a success data retrieved
            var postSuccess = function postSuccess() {
                cachedObj.metaObj[dataType].isAvailable = true;
                cachedObj.metaObj[dataType].isRequested = false;
                var successFns = cachedObj.metaObj[dataType].successFns;

                Ember['default'].$.each(successFns, function (index, sFn) {
                    if (Ember['default'].$.isFunction(sFn)) {
                        sFn();
                    }
                });
                cachedObj.metaObj[dataType].successFns = [];
            };

            var error = function error() {
                cachedObj.metaObj[dataType].isAvailable = false;
                cachedObj.metaObj[dataType].isRequested = false;
                var errorFns = cachedObj.metaObj[dataType].errorFns;

                Ember['default'].$.each(errorFns, function (index, eFn) {
                    if (Ember['default'].$.isFunction(eFn)) {
                        eFn();
                    }
                });
                cachedObj.metaObj[dataType].errorFns = [];
            };

            if (!cachedObj) {
                cachedObj = this.createStoreCacheObj(individualId, companyId, language);
            }

            if (dataType === undefined) {
                // no data type defined. Assume no data initialization is needed.
                return cachedObj.dataObj;
            }

            if (cachedObj.metaObj[dataType].isAvailable) {
                if (Ember['default'].$.isFunction(successFn)) {
                    successFn();
                }
            } else {
                // data not available state
                this.addCallbackFunctions(cachedObj, dataType, successFn, errorFn);

                if (!cachedObj.metaObj[dataType].isRequested) {
                    // data not available and already NO earlier request is processing
                    cachedObj.metaObj[dataType].isRequested = true;
                    switch (dataType) {
                        case priceConstants['default'].DataTypes.IndividualProfile.CompanyContactPerson:
                            priceService['default'].sendContactPersonRequest(individualId, companyId, language, postSuccess, error);
                            break;
                        default:
                            utils['default'].logger.logError('Unsupported individual-profile-store Data Type: ' + dataType);
                            break;
                    }
                }
            }

            return cachedObj.dataObj;
        },

        createStoreCacheObj: function createStoreCacheObj(individualId, companyId, language) {
            var key = this.getKey([individualId, companyId, language]);
            var storeCacheObj = {
                dataObj: {},
                metaObj: {}
            };

            storeCacheObj.dataObj = profileObj['default'].create({});

            var profileTypes = priceConstants['default'].DataTypes.IndividualProfile;
            this.initMetaData(storeCacheObj, profileTypes);
            this.dataStore[key] = storeCacheObj;
            return storeCacheObj;
        },

        createIndividualData: function createIndividualData() {
            return profileObj['default'].create();
        }

    }).create();

});