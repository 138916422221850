define('ir-app/models/price/price-data-stores/ratio-comparison-data-store', ['exports', 'ember', 'ir-app/models/price/price-business-entities/ratio-data', 'ir-app/models/price/price-service', 'ir-app/models/price/price-constants', 'ir-app/utils/utils', 'ir-app/models/price/price-data-stores/base-data-store'], function (exports, Ember, ratioCompObj, priceService, priceConstants, utils, BaseStore) {

    'use strict';

    exports['default'] = BaseStore['default'].extend({

        /**
         * Override base method
         * get stored object... if dataType is available initialize data for the DS object.
         * @param exchange
         * @param symbol
         * @param instType
         * @param successFn functions to run on success data loading
         * @param errorFn   functions to run on error
         * @param dataType   Optional
         * @returns {*}
         */
        getStoredObject: function getStoredObject(exchange, symbol, instType, dataType, successFn, errorFn) {
            var key = this.getKey([exchange, symbol, instType, dataType]);
            var cachedObj = this.dataStore[key];

            var postSuccess = function postSuccess() {
                cachedObj.metaObj[dataType].isAvailable = true;
                cachedObj.metaObj[dataType].isRequested = false;
                var successFns = cachedObj.metaObj[dataType].successFns;

                Ember['default'].$.each(successFns, function (index, sFn) {
                    if (Ember['default'].$.isFunction(sFn)) {
                        sFn();
                    }
                });
                cachedObj.metaObj[dataType].successFns = [];
            };

            var error = function error() {
                cachedObj.metaObj[dataType].isAvailable = false;
                cachedObj.metaObj[dataType].isRequested = false;
                var errorFns = cachedObj.metaObj[dataType].errorFns;

                Ember['default'].$.each(errorFns, function (index, eFn) {
                    if (Ember['default'].$.isFunction(eFn)) {
                        eFn();
                    }
                });
                cachedObj.metaObj[dataType].errorFns = [];
            };

            if (!cachedObj) {
                cachedObj = this.createStoreCacheObj(exchange, symbol, instType, dataType);
            }

            if (dataType === undefined) {
                // no data type defined. Assume no data initialization is needed.
                return cachedObj.dataObj;
            }

            if (cachedObj.metaObj[dataType].isAvailable) {
                if (Ember['default'].$.isFunction(successFn)) {
                    successFn();
                }
            } else {
                // data not available state
                this.addCallbackFunctions(cachedObj, dataType, successFn, errorFn);

                if (!cachedObj.metaObj[dataType].isRequested) {
                    // data not available and already NO earlier request is processing
                    cachedObj.metaObj[dataType].isRequested = true;

                    switch (dataType) {
                        case priceConstants['default'].DataTypes.RatioComparisonDataStore.Quarter:
                        case priceConstants['default'].DataTypes.RatioComparisonDataStore.Year:
                            priceService['default'].sendRatioComparisonRequest(exchange, symbol, instType, dataType, postSuccess, error);
                            break;

                        default:
                            utils['default'].logger.logError('Unsupported ratio-comparison-data-store Data Type: ' + dataType);
                            break;

                    }
                }
            }

            return cachedObj.dataObj;
        },

        /**
         * Override Method
         * @param exchange
         * @param symbol
         * @param instType
         * @param dataType
         */
        createStoreCacheObj: function createStoreCacheObj(exchange, symbol, instType, dataType) {
            var key = this.getKey([exchange, symbol, instType, dataType]);
            var storeCacheObj = {
                dataObj: {},
                metaObj: {}
            };

            storeCacheObj.dataObj = ratioCompObj['default'].create({
                exg: exchange,
                sym: symbol,
                inst: instType
            });

            var ownershipDataTypes = priceConstants['default'].DataTypes.RatioComparisonDataStore;
            this.initMetaData(storeCacheObj, ownershipDataTypes);
            this.dataStore[key] = storeCacheObj;
            return storeCacheObj;
        },

        createRatioComparison: function createRatioComparison() {
            return ratioCompObj['default'].create();
        }

    }).create();

});