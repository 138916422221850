define('ir-app/models/price/price-business-entities/video', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({

        Title: '',
        Category: '',
        Url: '',
        vidArr: Ember['default'].A(),

        setData: function setData(vid) {
            var that = this;
            Ember['default'].$.each(vid, function (key, value) {
                that.set(key, value);
            });
        }
    });

});