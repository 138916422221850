define('ir-app/models/price/price-data-stores/video-data-store', ['exports', 'ember', 'ir-app/models/price/price-business-entities/video', 'ir-app/models/price/price-service', 'ir-app/models/price/price-data-stores/base-data-store'], function (exports, Ember, videoObj, priceService, BaseStore) {

    'use strict';

    exports['default'] = BaseStore['default'].extend({

        getStoredObject: function getStoredObject(exchange, symbol, language, successFn, Error) {
            var key = this.getKey([exchange, symbol, language]);
            var cachedObj = this.dataStore[key];

            var postSuccess = function postSuccess() {
                cachedObj.metaObj.isAvailable = true;
                cachedObj.metaObj.isRequested = false;
                successFn(cachedObj.dataObj.vidArr);
            };

            var error = function error() {
                cachedObj.metaObj.isAvailable = false;
                cachedObj.metaObj.isRequested = false;
                Error();
            };

            if (!cachedObj) {
                cachedObj = this.createStoreCacheObj(exchange, symbol, language);
            }
            if (cachedObj.metaObj.isAvailable) {
                if (Ember['default'].$.isFunction(successFn)) {
                    successFn(cachedObj.dataObj.vidArr);
                }
            } else {
                if (!cachedObj.metaObj.isRequested) {
                    cachedObj.metaObj.isRequested = true;
                    priceService['default'].sendVideoFileRequest(exchange, symbol, language, postSuccess, error);
                }
            }
            return cachedObj.dataObj;
        },
        createStoreCacheObj: function createStoreCacheObj(exchange, symbol, language) {
            var key = this.getKey([exchange, symbol, language]);
            var storeCacheObj = {
                dataObj: {},
                metaObj: {}
            };
            storeCacheObj.dataObj = videoObj['default'].create({
                exg: exchange,
                sym: symbol
            });
            this.dataStore[key] = storeCacheObj;
            return storeCacheObj;
        },

        createVideoObj: function createVideoObj() {
            return videoObj['default'].create();
        }

    }).create();

});