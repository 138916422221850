define('ir-app/templates/ir-widgets/stock-overview-widgets/company-statistics', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            isHTMLBars: true,
            revision: "Ember@1.11.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("td");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks, get = hooks.get, concat = hooks.concat, attribute = hooks.attribute, inline = hooks.inline, content = hooks.content;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var element8 = dom.childAt(fragment, [1]);
              var morph0 = dom.createMorphAt(element8,0,0);
              var morph1 = dom.createMorphAt(element8,2,2);
              var attrMorph0 = dom.createAttrMorph(element8, 'id');
              attribute(env, attrMorph0, element8, "id", concat(env, [get(env, context, "field.field")]));
              inline(env, morph0, context, "inMillions", [get(env, context, "field.value"), 4], {});
              content(env, morph1, context, "field.valueObj.currency");
              return fragment;
            }
          };
        }());
        var child1 = (function() {
          return {
            isHTMLBars: true,
            revision: "Ember@1.11.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("td");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks, get = hooks.get, concat = hooks.concat, attribute = hooks.attribute, inline = hooks.inline, content = hooks.content;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var element7 = dom.childAt(fragment, [1]);
              var morph0 = dom.createMorphAt(element7,0,0);
              var morph1 = dom.createMorphAt(element7,2,2);
              var attrMorph0 = dom.createAttrMorph(element7, 'id');
              attribute(env, attrMorph0, element7, "id", concat(env, [get(env, context, "field.field")]));
              inline(env, morph0, context, "inMillions", [get(env, context, "field.value"), 4], {});
              content(env, morph1, context, "field.valueObj.cur");
              return fragment;
            }
          };
        }());
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, block = hooks.block;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, null);
            dom.insertBoundary(fragment, 0);
            block(env, morph0, context, "if", [get(env, context, "field.valueObj.currency")], {}, child0, child1);
            return fragment;
          }
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            isHTMLBars: true,
            revision: "Ember@1.11.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("td");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks, get = hooks.get, concat = hooks.concat, attribute = hooks.attribute, inline = hooks.inline;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var element6 = dom.childAt(fragment, [1]);
              var morph0 = dom.createMorphAt(element6,0,0);
              var attrMorph0 = dom.createAttrMorph(element6, 'id');
              attribute(env, attrMorph0, element6, "id", concat(env, [get(env, context, "field.field")]));
              inline(env, morph0, context, "formatNumber", [get(env, context, "field.value"), 0], {});
              return fragment;
            }
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              isHTMLBars: true,
              revision: "Ember@1.11.1",
              blockParams: 0,
              cachedFragment: null,
              hasRendered: false,
              build: function build(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("td");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              render: function render(context, env, contextualElement) {
                var dom = env.dom;
                var hooks = env.hooks, get = hooks.get, concat = hooks.concat, attribute = hooks.attribute, inline = hooks.inline;
                dom.detectNamespace(contextualElement);
                var fragment;
                if (env.useFragmentCache && dom.canClone) {
                  if (this.cachedFragment === null) {
                    fragment = this.build(dom);
                    if (this.hasRendered) {
                      this.cachedFragment = fragment;
                    } else {
                      this.hasRendered = true;
                    }
                  }
                  if (this.cachedFragment) {
                    fragment = dom.cloneNode(this.cachedFragment, true);
                  }
                } else {
                  fragment = this.build(dom);
                }
                var element5 = dom.childAt(fragment, [1]);
                var morph0 = dom.createMorphAt(element5,0,0);
                var attrMorph0 = dom.createAttrMorph(element5, 'id');
                attribute(env, attrMorph0, element5, "id", concat(env, [get(env, context, "field.field")]));
                inline(env, morph0, context, "formatToDate", [get(env, context, "field.value")], {});
                return fragment;
              }
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                isHTMLBars: true,
                revision: "Ember@1.11.1",
                blockParams: 0,
                cachedFragment: null,
                hasRendered: false,
                build: function build(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("td");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                render: function render(context, env, contextualElement) {
                  var dom = env.dom;
                  var hooks = env.hooks, get = hooks.get, concat = hooks.concat, attribute = hooks.attribute, inline = hooks.inline;
                  dom.detectNamespace(contextualElement);
                  var fragment;
                  if (env.useFragmentCache && dom.canClone) {
                    if (this.cachedFragment === null) {
                      fragment = this.build(dom);
                      if (this.hasRendered) {
                        this.cachedFragment = fragment;
                      } else {
                        this.hasRendered = true;
                      }
                    }
                    if (this.cachedFragment) {
                      fragment = dom.cloneNode(this.cachedFragment, true);
                    }
                  } else {
                    fragment = this.build(dom);
                  }
                  var element4 = dom.childAt(fragment, [1]);
                  var morph0 = dom.createMorphAt(element4,0,0);
                  var attrMorph0 = dom.createAttrMorph(element4, 'id');
                  attribute(env, attrMorph0, element4, "id", concat(env, [get(env, context, "field.field")]));
                  inline(env, morph0, context, "formatNumberPercentage", [get(env, context, "field.value")], {});
                  return fragment;
                }
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  isHTMLBars: true,
                  revision: "Ember@1.11.1",
                  blockParams: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  build: function build(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("td");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  render: function render(context, env, contextualElement) {
                    var dom = env.dom;
                    var hooks = env.hooks, get = hooks.get, concat = hooks.concat, attribute = hooks.attribute, inline = hooks.inline;
                    dom.detectNamespace(contextualElement);
                    var fragment;
                    if (env.useFragmentCache && dom.canClone) {
                      if (this.cachedFragment === null) {
                        fragment = this.build(dom);
                        if (this.hasRendered) {
                          this.cachedFragment = fragment;
                        } else {
                          this.hasRendered = true;
                        }
                      }
                      if (this.cachedFragment) {
                        fragment = dom.cloneNode(this.cachedFragment, true);
                      }
                    } else {
                      fragment = this.build(dom);
                    }
                    var element3 = dom.childAt(fragment, [1]);
                    var morph0 = dom.createMorphAt(element3,0,0);
                    var attrMorph0 = dom.createAttrMorph(element3, 'id');
                    attribute(env, attrMorph0, element3, "id", concat(env, [get(env, context, "field.field")]));
                    inline(env, morph0, context, "formatNumber", [get(env, context, "field.value"), 0], {});
                    return fragment;
                  }
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    isHTMLBars: true,
                    revision: "Ember@1.11.1",
                    blockParams: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    build: function build(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                                            ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("td");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    render: function render(context, env, contextualElement) {
                      var dom = env.dom;
                      var hooks = env.hooks, get = hooks.get, concat = hooks.concat, attribute = hooks.attribute, inline = hooks.inline;
                      dom.detectNamespace(contextualElement);
                      var fragment;
                      if (env.useFragmentCache && dom.canClone) {
                        if (this.cachedFragment === null) {
                          fragment = this.build(dom);
                          if (this.hasRendered) {
                            this.cachedFragment = fragment;
                          } else {
                            this.hasRendered = true;
                          }
                        }
                        if (this.cachedFragment) {
                          fragment = dom.cloneNode(this.cachedFragment, true);
                        }
                      } else {
                        fragment = this.build(dom);
                      }
                      var element2 = dom.childAt(fragment, [1]);
                      var morph0 = dom.createMorphAt(element2,0,0);
                      var attrMorph0 = dom.createAttrMorph(element2, 'id');
                      attribute(env, attrMorph0, element2, "id", concat(env, [get(env, context, "field.field")]));
                      inline(env, morph0, context, "divideNumber", [get(env, context, "field.value")], {});
                      return fragment;
                    }
                  };
                }());
                var child1 = (function() {
                  var child0 = (function() {
                    return {
                      isHTMLBars: true,
                      revision: "Ember@1.11.1",
                      blockParams: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      build: function build(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                                                ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("td");
                        var el2 = dom.createComment("");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      render: function render(context, env, contextualElement) {
                        var dom = env.dom;
                        var hooks = env.hooks, get = hooks.get, concat = hooks.concat, attribute = hooks.attribute, inline = hooks.inline;
                        dom.detectNamespace(contextualElement);
                        var fragment;
                        if (env.useFragmentCache && dom.canClone) {
                          if (this.cachedFragment === null) {
                            fragment = this.build(dom);
                            if (this.hasRendered) {
                              this.cachedFragment = fragment;
                            } else {
                              this.hasRendered = true;
                            }
                          }
                          if (this.cachedFragment) {
                            fragment = dom.cloneNode(this.cachedFragment, true);
                          }
                        } else {
                          fragment = this.build(dom);
                        }
                        var element1 = dom.childAt(fragment, [1]);
                        var morph0 = dom.createMorphAt(element1,0,0);
                        var attrMorph0 = dom.createAttrMorph(element1, 'id');
                        attribute(env, attrMorph0, element1, "id", concat(env, [get(env, context, "field.field")]));
                        inline(env, morph0, context, "formatNumber", [get(env, context, "field.value"), 2], {});
                        return fragment;
                      }
                    };
                  }());
                  var child1 = (function() {
                    return {
                      isHTMLBars: true,
                      revision: "Ember@1.11.1",
                      blockParams: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      build: function build(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                                                ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("td");
                        var el2 = dom.createComment("");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      render: function render(context, env, contextualElement) {
                        var dom = env.dom;
                        var hooks = env.hooks, get = hooks.get, concat = hooks.concat, attribute = hooks.attribute, content = hooks.content;
                        dom.detectNamespace(contextualElement);
                        var fragment;
                        if (env.useFragmentCache && dom.canClone) {
                          if (this.cachedFragment === null) {
                            fragment = this.build(dom);
                            if (this.hasRendered) {
                              this.cachedFragment = fragment;
                            } else {
                              this.hasRendered = true;
                            }
                          }
                          if (this.cachedFragment) {
                            fragment = dom.cloneNode(this.cachedFragment, true);
                          }
                        } else {
                          fragment = this.build(dom);
                        }
                        var element0 = dom.childAt(fragment, [1]);
                        var morph0 = dom.createMorphAt(element0,0,0);
                        var attrMorph0 = dom.createAttrMorph(element0, 'id');
                        attribute(env, attrMorph0, element0, "id", concat(env, [get(env, context, "field.field")]));
                        content(env, morph0, context, "field.value");
                        return fragment;
                      }
                    };
                  }());
                  return {
                    isHTMLBars: true,
                    revision: "Ember@1.11.1",
                    blockParams: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    build: function build(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    render: function render(context, env, contextualElement) {
                      var dom = env.dom;
                      var hooks = env.hooks, get = hooks.get, block = hooks.block;
                      dom.detectNamespace(contextualElement);
                      var fragment;
                      if (env.useFragmentCache && dom.canClone) {
                        if (this.cachedFragment === null) {
                          fragment = this.build(dom);
                          if (this.hasRendered) {
                            this.cachedFragment = fragment;
                          } else {
                            this.hasRendered = true;
                          }
                        }
                        if (this.cachedFragment) {
                          fragment = dom.cloneNode(this.cachedFragment, true);
                        }
                      } else {
                        fragment = this.build(dom);
                      }
                      var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, null);
                      dom.insertBoundary(fragment, 0);
                      block(env, morph0, context, "if", [get(env, context, "field.isFloat")], {}, child0, child1);
                      return fragment;
                    }
                  };
                }());
                return {
                  isHTMLBars: true,
                  revision: "Ember@1.11.1",
                  blockParams: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  build: function build(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  render: function render(context, env, contextualElement) {
                    var dom = env.dom;
                    var hooks = env.hooks, get = hooks.get, block = hooks.block;
                    dom.detectNamespace(contextualElement);
                    var fragment;
                    if (env.useFragmentCache && dom.canClone) {
                      if (this.cachedFragment === null) {
                        fragment = this.build(dom);
                        if (this.hasRendered) {
                          this.cachedFragment = fragment;
                        } else {
                          this.hasRendered = true;
                        }
                      }
                      if (this.cachedFragment) {
                        fragment = dom.cloneNode(this.cachedFragment, true);
                      }
                    } else {
                      fragment = this.build(dom);
                    }
                    var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, null);
                    dom.insertBoundary(fragment, 0);
                    block(env, morph0, context, "if", [get(env, context, "field.isDivideNumber")], {}, child0, child1);
                    return fragment;
                  }
                };
              }());
              return {
                isHTMLBars: true,
                revision: "Ember@1.11.1",
                blockParams: 0,
                cachedFragment: null,
                hasRendered: false,
                build: function build(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                render: function render(context, env, contextualElement) {
                  var dom = env.dom;
                  var hooks = env.hooks, get = hooks.get, block = hooks.block;
                  dom.detectNamespace(contextualElement);
                  var fragment;
                  if (env.useFragmentCache && dom.canClone) {
                    if (this.cachedFragment === null) {
                      fragment = this.build(dom);
                      if (this.hasRendered) {
                        this.cachedFragment = fragment;
                      } else {
                        this.hasRendered = true;
                      }
                    }
                    if (this.cachedFragment) {
                      fragment = dom.cloneNode(this.cachedFragment, true);
                    }
                  } else {
                    fragment = this.build(dom);
                  }
                  var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, null);
                  dom.insertBoundary(fragment, 0);
                  block(env, morph0, context, "if", [get(env, context, "field.isInteger")], {}, child0, child1);
                  return fragment;
                }
              };
            }());
            return {
              isHTMLBars: true,
              revision: "Ember@1.11.1",
              blockParams: 0,
              cachedFragment: null,
              hasRendered: false,
              build: function build(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              render: function render(context, env, contextualElement) {
                var dom = env.dom;
                var hooks = env.hooks, get = hooks.get, block = hooks.block;
                dom.detectNamespace(contextualElement);
                var fragment;
                if (env.useFragmentCache && dom.canClone) {
                  if (this.cachedFragment === null) {
                    fragment = this.build(dom);
                    if (this.hasRendered) {
                      this.cachedFragment = fragment;
                    } else {
                      this.hasRendered = true;
                    }
                  }
                  if (this.cachedFragment) {
                    fragment = dom.cloneNode(this.cachedFragment, true);
                  }
                } else {
                  fragment = this.build(dom);
                }
                var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, null);
                dom.insertBoundary(fragment, 0);
                block(env, morph0, context, "if", [get(env, context, "field.isPercentage")], {}, child0, child1);
                return fragment;
              }
            };
          }());
          return {
            isHTMLBars: true,
            revision: "Ember@1.11.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks, get = hooks.get, block = hooks.block;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, null);
              dom.insertBoundary(fragment, 0);
              block(env, morph0, context, "if", [get(env, context, "field.isDate")], {}, child0, child1);
              return fragment;
            }
          };
        }());
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, block = hooks.block;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, null);
            dom.insertBoundary(fragment, 0);
            block(env, morph0, context, "if", [get(env, context, "field.isLong")], {}, child0, child1);
            return fragment;
          }
        };
      }());
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("tr");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, concat = hooks.concat, attribute = hooks.attribute, content = hooks.content, block = hooks.block;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element9 = dom.childAt(fragment, [1]);
          var element10 = dom.childAt(element9, [1]);
          var morph0 = dom.createMorphAt(element10,0,0);
          var morph1 = dom.createMorphAt(element10,2,2);
          var attrMorph0 = dom.createAttrMorph(element10, 'id');
          var morph2 = dom.createMorphAt(element9,3,3);
          attribute(env, attrMorph0, element10, "id", concat(env, [get(env, context, "field.field"), "Label"]));
          content(env, morph0, context, "field.lanKey");
          content(env, morph1, context, "field.suffix");
          block(env, morph2, context, "if", [get(env, context, "field.isCurrency")], {}, child0, child1);
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","col-xs-12 col-md-12 col-lg-12 full-width widget-wrapper-style1 company-statistics margin-medium-bottom");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table-responsive");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("table");
        dom.setAttribute(el3,"class","table table-striped numeric-table");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("tbody");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var morph0 = dom.createMorphAt(dom.childAt(fragment, [0, 1, 1, 1]),1,1);
        block(env, morph0, context, "each", [get(env, context, "valueFields")], {"keyword": "field"}, child0, null);
        return fragment;
      }
    };
  }()));

});