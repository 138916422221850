define('ir-app/controllers/ir-widgets/index-overview-widgets/top-bar-index-quote', ['exports', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/utils/utils'], function (exports, BaseController, priceService, sharedService, utils) {

    'use strict';

    exports['default'] = BaseController['default'].extend({
        stock: {},
        exchange: {},
        lastUpdate: '',
        lang: undefined,
        needs: ['application'],

        onPrepareData: function onPrepareData() {
            this.set('lang', sharedService['default'].userSettings.currentLanguage.toLowerCase());

            var exchange = priceService['default'].exchangeDS.getExchange(this.get('exg'));
            this.set('exchange', exchange);

            priceService['default'].addExchangeRequest(this.get('exg'));
            priceService['default'].downloadExchangeProperty(this.get('exg'), sharedService['default'].userSettings.currentLanguage);

            if (this.utils.validators.isAvailable(this.get('sym'))) {
                this.setIndex(this.get('sym'));
            } else if (!this.utils.validators.isAvailable(this.get('sym')) && exchange.mainIdx) {
                // Only Exchange is Available .Show Main Index's data
                this.setIndex(exchange.mainIdx);
            }
        },

        onAfterRender: function onAfterRender() {
            this._renderChartView();
        },

        onClearData: function onClearData() {
            priceService['default'].removeIndexRequest(this.get('exg'), this.get('sym'));
            priceService['default'].removeExchangeRequest(this.get('exg'));
        },

        onLanguageChanged: function onLanguageChanged() {
            priceService['default'].downloadExchangeProperty(this.get('exg'), sharedService['default'].userSettings.currentLanguage);
        },

        baseTitle: (function () {
            this.setValueToBaseSymbolContainer('baseTitle', utils['default'].formatters.convertUnicodeToNativeString(this.get('exchange').des));
        }).observes('exchange.des'),

        _renderChartView: function _renderChartView() {
            var detailViewController = 'ir-mobile/chart-widgets/basic-chart-widget';
            var controllerString = 'controller:' + detailViewController;
            var widgetController = this.container.lookupFactory(controllerString).create();
            widgetController.set('isPortalTopBarChart', true);

            widgetController.set('sym', this.get('sym'));
            widgetController.set('exg', this.get('exg'));
            widgetController.initializeWidget({ wn: controllerString.split('/').pop() });

            var route = this.container.lookup('route:' + this.get('controllers.application.currentRouteName'));
            route.render(detailViewController, {
                into: 'ir-widgets/index-overview-widgets/top-bar-index-quote',
                outlet: 'chartView',
                controller: widgetController
            });

            widgetController.onUnloadWidget();
        },

        updateLastUpdate: (function () {
            var exgObj = this.get('exchange');
            var lastUpdate = '';

            if (exgObj.date !== exgObj.led && utils['default'].validators.isAvailable(exgObj.time)) {
                lastUpdate = utils['default'].formatters.formatToDate(exgObj.date, 0) + ' ' + utils['default'].formatters.formatToTime(exgObj.time, exgObj.tzo);
            } else {
                lastUpdate = utils['default'].formatters.formatToDate(exgObj.date, 0);
            }

            this.set('lastUpdate', lastUpdate);
        }).observes('exchange.time', 'exchange.date', 'exchange.led'),

        onMainIndexSet: (function () {
            var exchange = this.get('exchange');
            if (!this.utils.validators.isAvailable(this.get('sym')) && exchange.mainIdx) {
                // Only Exchange is Available .Show Main Index's data
                this.setIndex(exchange.mainIdx);
            }
        }).observes('exchange.mainIdx'),

        setIndex: function setIndex(index) {
            var stock = priceService['default'].stockDS.getStock(this.get('exg'), index);
            this.set('stock', stock);
            priceService['default'].addIndexRequest(this.get('exg'), index);
        }
    });

});