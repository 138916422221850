define('ir-app/views/widget-popup-view', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    /* global Draggabilly */
    exports['default'] = Ember['default'].View.extend({
        dimensions: {
            w: 430,
            h: 575
        },

        showWidget1: true,
        outletName: '',
        currentWidgetController: null,
        popUpWidgetTitle: '',

        load: (function () {
            this.outletName = 'out-' + Math.floor(Date.now() / 1000);

            this.template = Ember['default'].HTMLBars.compile('<div style="width: ' + this.get('dimensions').w + 'px; height: ' + this.get('dimensions').h + 'px; overflow-y:hidden; border: 2px solid #3d647a; padding-left: 0px; box-sizing: content-box;" class="popup-widget js-resizable">' + '<div id="title-handle" style="cursor: move;">{{widget-header widgetTitle=view.popUpWidgetTitle isWidgetCloseAvailable=true closeWidgetAction="closePopup" closeActionTarget=view hideWidgetLink=true}}</div>' + '<div class="popup-height margin-small-top">{{outlet OUTLET_NAME}}</div></div>'.replace('OUTLET_NAME', '"' + this.outletName + '"'));
            this.set('showWidget1', true);
        }).on('init'),

        show: function show(widgetController) {
            Ember['default'].PopupContainerView.addPopupView(this);
            this.set('popUpWidgetTitle', widgetController.get('title'));

            var popupElement = document.getElementById(Ember['default'].PopupContainerView.elementId);
            var popupElementId = this.elementId;
            var that = this;

            if (popupElement) {
                popupElement.setAttribute('style', 'height: 1px; max-height: 1px; position: absolute;');
            }

            Ember['default'].run.later(function () {
                var elem = document.getElementById(popupElementId);
                that.preparePopUp(widgetController);

                if (elem) {
                    elem.setAttribute('style', 'position: absolute;');

                    new Draggabilly(elem, { // eslint-disable-line
                        handle: '#title-handle',
                        x: 100,
                        y: 200
                    });

                    Ember['default'].$('.js-resizable').resizable();
                }
            }, 1000);
        },

        preparePopUp: function preparePopUp(widgetController) {
            var route = this.container.lookup('route:application');

            route.render(widgetController.get('routeString'), {
                into: 'application',
                outlet: this.outletName,
                controller: widgetController
            });

            this.set('currentWidgetController', widgetController);
        },

        click: function click() {
            var elem = document.getElementById(this.elementId);

            if (!Ember['default'].PopupZIndex) {
                Ember['default'].PopupZIndex = 2000;
            }

            Ember['default'].PopupZIndex = Ember['default'].PopupZIndex + 1;
            elem.style.zIndex = Ember['default'].PopupZIndex;
        },

        unloadCurrentController: function unloadCurrentController() {
            var currentController = this.get('currentWidgetController');

            if (currentController) {
                currentController.closeWidget();
            }
        },

        actions: {
            closePopup: function closePopup() {
                this.unloadCurrentController();
                Ember['default'].PopupContainerView.removeObject(this);
            }
        }
    });

});