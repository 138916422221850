define('ir-app/models/price/price-business-entities/topstock', ['exports', 'ember', 'ir-app/utils/utils'], function (exports, Ember, utils) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        sym: '', // Symbol
        chg: '', // Change
        pctChg: '', // Percentage change
        ltp: '', // Last Trade Price
        vol: '', // Volume
        trades: '', // No of trades
        tovr: '', // Turnover
        desc: '', // Description
        dcfs: '-1', // Distressed  company flag
        per: '', // P / E ratio
        deci: 2, // Decimal Places

        dDesc: (function () {
            return utils['default'].formatters.convertUnicodeToNativeString(this.get('desc'));
        }).property('desc'), // Display symbol description

        setData: function setData(topStockMsg) {
            var that = this;

            Ember['default'].$.each(topStockMsg, function (key, value) {
                that.set(key, value);
            });
        }
    });

});