define('ir-app/models/price/price-data-stores/system-data-store', ['exports', 'ir-app/models/price/price-business-entities/country-definitions', 'ir-app/models/price/price-data-stores/base-data-store', 'ir-app/models/price/price-business-entities/system-definitions'], function (exports, countries, BaseStore, systemDefinitions) {

    'use strict';

    exports['default'] = BaseStore['default'].extend({

        getStoredObject: function getStoredObject(language, successFn, errorFn, dataType) {
            var key = language;
            var cachedObj = this.dataStore[key];

            if (!cachedObj) {
                cachedObj = this.createStoreCacheObj(language);
            }
            if (dataType === undefined) {
                return cachedObj.dataObj;
            }
        },

        createStoreCacheObj: function createStoreCacheObj(language) {
            var key;
            var storeCacheObj = {
                dataObj: {},
                metaObj: {}
            };

            key = language;
            storeCacheObj.dataObj = systemDefinitions['default'].create({

                lan: language
            });

            // var companyDataTypes = priceConstants.DataTypes.CompanyProfileDataStore;
            this.initMetaData(storeCacheObj);
            this.dataStore[key] = storeCacheObj;
            return storeCacheObj;
        },

        createCountryDefinitions: function createCountryDefinitions() {
            return countries['default'].create();
        }

    }).create();

});