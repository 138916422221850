define('ir-app/models/shared/profile/profile-service', ['exports', 'ember', 'ir-app/models/shared/communication-adapters/web-http-connection', 'ir-app/utils/utils', 'ir-app/models/price/price-service', 'ir-app/models/shared/profile/profile-data-store', 'ir-app/utils/web-storage'], function (exports, Ember, WebConnection, utils, priceService, profileDS, WebStorage) {

    'use strict';

    exports['default'] = (function () {
        var RequestParams = {
            GetUserProfile: {
                RT: 175,
                VER: 1,
                BCD: 'ISI'
            },

            ModifyComponent: {
                RT: 575,
                VER: 1,
                PANEL: 'test panel'
            },

            AddComponent: {
                RT: 555,
                VER: 1,
                PANEL: 'test panel'
            },

            PDM: 56
        };

        var UrlType = 'profile';

        var getUserProfileFromServer = function getUserProfileFromServer() {
            var profileObj = profileDS['default'].getUserProfileObj();
            var url = _generateGetUserProfileUrl();
            var onSuccess = _processGetUserProfileResponse;
            _sendProfileServiceRequest(url, 'GET', undefined, undefined, onSuccess, profileObj);
        };

        var modifyComponentOnServer = function modifyComponentOnServer(componentStrObj, profileId) {
            var url = UrlType;
            var data = _generateModifyComponentUrl(componentStrObj, profileId);
            _sendProfileServiceRequest(url, 'POST', 'application/x-www-form-urlencoded; charset=UTF-8', data);
        };

        var addComponentOnServer = function addComponentOnServer(componentStrObj) {
            var url = UrlType;
            var data = _generateAddComponentUrl(componentStrObj);
            _sendProfileServiceRequest(url, 'POST', 'application/x-www-form-urlencoded; charset=UTF-8', data);
        };

        var _sendProfileServiceRequest = function _sendProfileServiceRequest(url, type, contentType, data, _onSuccess, profileObj) {
            WebConnection['default'].sendAjaxRequest({
                url: url,
                type: type,
                contentType: contentType,
                data: data,

                onSuccess: function onSuccess(dataObj) {
                    if (_onSuccess) {
                        _onSuccess(dataObj, profileObj);
                    }
                },

                onError: function onError() {
                    // TODO: [Janitha] Handle error on ProfileServiceRequest
                }
            });
        };

        var _generateGetUserProfileUrl = function _generateGetUserProfileUrl() {
            var queryParams = {
                RT: RequestParams.GetUserProfile.RT,
                VER: RequestParams.GetUserProfile.VER,
                BCD: RequestParams.GetUserProfile.BCD
            };

            return _generateQueryString(queryParams, UrlType);
        };

        var _generateModifyComponentUrl = function _generateModifyComponentUrl(componentStrObj, profileId) {
            var queryParams = {
                RT: RequestParams.ModifyComponent.RT,
                COMP: componentStrObj,
                PANEL: RequestParams.ModifyComponent.PANEL,
                VER: RequestParams.ModifyComponent.VER,
                PID: profileId
            };

            return _generateQueryString(queryParams);
        };

        var _generateAddComponentUrl = function _generateAddComponentUrl(componentStrObj) {
            var queryParams = {
                RT: RequestParams.AddComponent.RT,
                COMP: componentStrObj,
                PANEL: RequestParams.AddComponent.PANEL,
                VER: RequestParams.AddComponent.VER
            };

            return _generateQueryString(queryParams);
        };

        var _generateQueryString = function _generateQueryString(params, urlPrefix) {
            var url,
                queryParams = _getQueryParam(params),
                queryArray = [];

            for (var prop in queryParams) {
                if (queryParams.hasOwnProperty(prop)) {
                    queryArray[queryArray.length] = [prop, queryParams[prop]].join(utils['default'].Constants.StringConst.Equal);
                }
            }

            var queryString = queryArray.join(utils['default'].Constants.StringConst.Ampersand);

            if (urlPrefix) {
                url = [urlPrefix, queryString].join(utils['default'].Constants.StringConst.Question);
                return url;
            }

            return queryString;
        };

        var _getQueryParam = function _getQueryParam(params) {
            // Add general parameters
            var queryParams = {
                PDM: RequestParams.PDM,
                UID: priceService['default'].userDS.userId,
                SID: priceService['default'].userDS.sessionId
            };

            if (params) {
                for (var prop in params) {
                    if (params.hasOwnProperty(prop)) {
                        queryParams[prop] = params[prop];
                    }
                }
            }

            return queryParams;
        };

        var _processGetUserProfileResponse = function _processGetUserProfileResponse(dataObj, profileObj) {
            try {
                var data = dataObj.DAT;

                if (data) {
                    profileObj.setData({
                        Id: data.Id,
                        Version: data.Version,
                        ProductId: data.ProductId,
                        Name: data.Name,
                        Panels: data.Panels,
                        Components: data.Components,
                        BillingCode: data.BillingCode
                    });

                    Ember['default'].$.each(data.Components, function (id, component) {
                        if (component.id >= component.ProfileId * 1000 + 10) {
                            // 10 to skip default components. temporary until default components are removed.
                            WebStorage['default'].addString(component.Name, component.Contents, utils['default'].Constants.StorageType.Local);
                        }
                    });
                } else {
                    // TODO: [Janitha] Handle error response
                }
            } catch (e) {
                // TODO: [Janitha] Handle error on no response
                utils['default'].logger.logError('Error in response for get user profile request: ' + e);
            }
        };

        return {
            getUserProfileFromServer: getUserProfileFromServer,
            modifyComponentOnServer: modifyComponentOnServer,
            addComponentOnServer: addComponentOnServer
        };
    })();

});