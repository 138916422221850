define('ir-app/models/price/price-business-entities/company-key-officers', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        name: '', // Name
        desig: '', // Designation
        date: '', // start Date
        resignDate: '',
        desigId: '',

        setData: function setData(companyKeyOfficersData) {
            var that = this;

            Ember['default'].$.each(companyKeyOfficersData, function (key, value) {
                that.set(key, value);
            });
        }
    });

});