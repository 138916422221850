define('ir-app/controllers/ir-mobile/quote-widgets/stock-price-ticker', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service'], function (exports, Ember, BaseController, priceService, sharedService) {

    'use strict';

    exports['default'] = BaseController['default'].extend({

        tickerList: Ember['default'].A(),
        tickerOneItems: Ember['default'].A(),
        tickerTwoItems: Ember['default'].A(),
        isFirstRound: true,

        onPrepareData: function onPrepareData() {
            if (!this.get('stockList')) {
                this.set('stockList', [{ exg: this.get('exg'), sym: this.get('sym') }]);
            }
            this._preparePriceTicker();
        },

        _preparePriceTicker: function _preparePriceTicker() {
            // subscribe for the requested symbol list
            this._subscribeSymbols();
            // at the load up active ticker is ticker one
            this._addSymbolsToActiveTicker(1);
            this._addSymbolsToActiveTicker(2);
        },

        /* *
         * subscribe symbols and create symbol array
         */
        _subscribeSymbols: function _subscribeSymbols() {
            var that = this;
            Ember['default'].$.each(this.get('stockList'), function (key, val) {
                if (val.isIndex) {
                    priceService['default'].sendSymbolValidationRequest(val.exg, val.sym, sharedService['default'].userSettings.currentLanguage, undefined, undefined, 7);
                    priceService['default'].addIndexRequest(val.exg, val.sym);
                } else {
                    priceService['default'].sendSymbolValidationRequest(val.exg, val.sym, sharedService['default'].userSettings.currentLanguage, undefined, undefined, 0);
                    priceService['default'].addSymbolRequest(val.exg, val.sym);
                }
                var stock = priceService['default'].stockDS.getStock(val.exg, val.sym);
                var exchange = priceService['default'].exchangeDS.getExchange(val.exg);
                priceService['default'].downloadExchangeProperty(val.exg, sharedService['default'].userSettings.currentLanguage);
                var item = { stock: stock, exchange: exchange };
                that.get('tickerList').push(item);
            });
        },

        _addSymbolsToActiveTicker: function _addSymbolsToActiveTicker(activeTicker) {
            var ticker = activeTicker === 1 ? this.tickerOneItems : this.tickerTwoItems;
            Ember['default'].$.each(this.get('tickerList'), function (key, stock) {
                ticker.push(stock);
            });
        },

        onAfterRender: function onAfterRender() {
            Ember['default'].$('.ticker').bind('webkitAnimationIteration', this._animationIterationFinished.bind(this));
            Ember['default'].$('.ticker').bind('mozAnimationIteration', this._animationIterationFinished.bind(this));
            Ember['default'].$('.ticker').bind('animationiteration', this._animationIterationFinished.bind(this));
        },

        /* *
         * Callback function for AnimationIteration CSS event to toggle visible ticker property
         */
        _animationIterationFinished: function _animationIterationFinished() {},

        onClearData: function onClearData() {
            Ember['default'].$.each(this.get('stockList'), function (key, val) {
                if (val.isIndex) {
                    priceService['default'].removeIndexRequest(val.exg, val.sym);
                } else {
                    priceService['default'].removeSymbolRequest(val.exg, val.sym);
                }
            });
        }
    });

});