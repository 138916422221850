define('ir-app/app-events', ['exports', 'ember', 'ir-app/models/price/price-service'], function (exports, Ember, priceService) {

    'use strict';

    exports['default'] = (function () {
        var symbolSubscriptionWidgets = {};
        var containerController = '';
        var languageSubscription;
        var themeSubscription;
        var visibilityChangeSubscription;

        var onSymbolChanged = function onSymbolChanged(symbol, exchange, insType, link) {
            if (link) {
                var subscribersByLink = symbolSubscriptionWidgets ? symbolSubscriptionWidgets[link] : '';

                if (subscribersByLink) {
                    Ember['default'].$.each(subscribersByLink, function (id, subscriber) {
                        if (subscriber && Ember['default'].$.isFunction(subscriber.onWidgetKeysChange)) {
                            subscriber.onWidgetKeysChange({
                                sym: symbol,
                                exg: exchange
                            });
                        }

                        // TODO [arosha] Discuss how to subscribe popup containers properly
                        if (subscriber && subscriber.wkey && symbolSubscriptionWidgets[subscriber.wkey]) {
                            var popupContainer = symbolSubscriptionWidgets[subscriber.wkey];
                            var stockFromStore = priceService['default'].stockDS.getStock(exchange, symbol);

                            popupContainer.updatePopup(symbol, exchange, insType, stockFromStore.get('lDes'));
                        }
                    });
                }

                if (containerController) {
                    containerController.saveSettings(symbol, exchange, insType);
                }
            } else if (link !== 0 && containerController && Ember['default'].$.isFunction(containerController.onSymbolChanged)) {
                containerController.onSymbolChanged(symbol, exchange, insType);
            }
        };

        var onLanguageChanged = function onLanguageChanged(language) {
            if (languageSubscription && languageSubscription.onLanguageChanged && Ember['default'].$.isFunction(languageSubscription.onLanguageChanged)) {
                languageSubscription.onLanguageChanged(language);
            }
        };

        var onThemeChanged = function onThemeChanged(theme) {
            if (themeSubscription && themeSubscription.onThemeChanged && Ember['default'].$.isFunction(themeSubscription.onThemeChanged)) {
                themeSubscription.onThemeChanged(theme);
            }
        };

        var onVisibilityChanged = function onVisibilityChanged(isHidden) {
            if (visibilityChangeSubscription && visibilityChangeSubscription.onVisibilityChanged && Ember['default'].$.isFunction(visibilityChangeSubscription.onVisibilityChanged)) {
                visibilityChangeSubscription.onVisibilityChanged(isHidden);
            }
        };

        var subscribeSymbolChanged = function subscribeSymbolChanged(subscriber, link, popupContainer) {
            if (link) {
                var subscribersForLink = symbolSubscriptionWidgets[link];

                if (!subscribersForLink) {
                    subscribersForLink = symbolSubscriptionWidgets[link] = [];
                }

                subscribersForLink[subscribersForLink.length] = subscriber;

                if (popupContainer) {
                    symbolSubscriptionWidgets[subscriber.wkey] = popupContainer;
                }
            } else if (link !== 0 && subscriber && Ember['default'].$.isFunction(subscriber.onSymbolChanged)) {
                containerController = subscriber;
            }
        };

        var unSubscribeSymbolChanged = function unSubscribeSymbolChanged(subscriber, link) {
            if (link) {
                var subscribersForLink = symbolSubscriptionWidgets[link];

                if (subscribersForLink && subscriber) {
                    Ember['default'].$.each(subscribersForLink, function (index, widget) {
                        if (subscriber === widget) {
                            subscribersForLink[index] = null;
                        }
                    });
                }

                if (symbolSubscriptionWidgets[subscriber.wkey]) {
                    symbolSubscriptionWidgets[subscriber.wkey] = null;
                }
            } else if (link !== 0) {
                containerController = '';
            }
        };

        var subscribeLanguageChanged = function subscribeLanguageChanged(subscriber) {
            languageSubscription = subscriber;
        };

        var unSubscribeLanguageChanged = function unSubscribeLanguageChanged() {
            languageSubscription = undefined;
        };

        var subscribeThemeChanged = function subscribeThemeChanged(subscriber) {
            themeSubscription = subscriber;
        };

        var unSubscribeThemeChanged = function unSubscribeThemeChanged() {
            themeSubscription = undefined;
        };

        var subscribeVisibilityChanged = function subscribeVisibilityChanged(subscriber) {
            visibilityChangeSubscription = subscriber;
        };

        var unSubscribeVisibilityChanged = function unSubscribeVisibilityChanged() {
            visibilityChangeSubscription = undefined;
        };

        return {
            onSymbolChanged: onSymbolChanged,
            onLanguageChanged: onLanguageChanged,
            onThemeChanged: onThemeChanged,
            subscribeSymbolChanged: subscribeSymbolChanged,
            subscribeLanguageChanged: subscribeLanguageChanged,
            subscribeThemeChanged: subscribeThemeChanged,
            unSubscribeSymbolChanged: unSubscribeSymbolChanged,
            unSubscribeLanguageChanged: unSubscribeLanguageChanged,
            unSubscribeThemeChanged: unSubscribeThemeChanged,
            subscribeVisibilityChanged: subscribeVisibilityChanged,
            unSubscribeVisibilityChanged: unSubscribeVisibilityChanged,
            onVisibilityChanged: onVisibilityChanged
        };
    })();

});