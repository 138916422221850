define('ir-app/components/stk-specific-components/portal-up-down-flat-display-bar', ['exports', 'ember', 'ir-app/utils/utils'], function (exports, Ember, utils) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({

        layoutName: 'components/stk-specific-components/portal-up-down-flat-display-bar',

        upStyle: undefined,
        downStyle: undefined,
        noChgStyle: undefined,
        ups: undefined,
        downs: undefined,
        flats: undefined,
        total: 0,

        getStyle: function getStyle(val) {
            var total = this.get('total');
            var temp = 0;

            if (total > 0) {
                temp = val / total * 100;
                return 'width:' + utils['default'].formatters.formatNumber(temp, 0) + '%;';
            }

            return 'width:0%;';
        },

        calcTotal: (function () {
            var ups = this.get('ups');
            var downs = this.get('downs');
            var flats = this.get('flats');
            this.set('total', ups + downs + flats);
        }).observes('ups', 'downs', 'flats'),

        up: (function () {
            var ups = this.get('ups');
            this.set('upStyle', this.getStyle(ups));
        }).observes('ups', 'downs', 'flats'),

        down: (function () {
            var downs = this.get('downs');
            this.set('downStyle', this.getStyle(downs));
        }).observes('ups', 'downs', 'flats'),

        noChange: (function () {
            var noChg = this.get('flats');
            this.set('noChgStyle', this.getStyle(noChg));
        }).observes('ups', 'downs', 'flats')
    });

});