define('ir-app/models/price/price-data-stores/document-data-store', ['exports', 'ember', 'ir-app/models/price/price-business-entities/document', 'ir-app/models/price/price-service', 'ir-app/models/price/price-data-stores/base-data-store'], function (exports, Ember, documentObj, priceService, BaseStore) {

    'use strict';

    exports['default'] = BaseStore['default'].extend({

        getStoredObject: function getStoredObject(exchange, symbol, language, successFn, Error) {
            var key = this.getKey([exchange, symbol, language]);
            var cachedObj = this.dataStore[key];

            var postSuccess = function postSuccess() {
                cachedObj.metaObj.isAvailable = true;
                cachedObj.metaObj.isRequested = false;
                successFn(cachedObj.dataObj.docArr);
            };

            var error = function error() {
                cachedObj.metaObj.isAvailable = false;
                cachedObj.metaObj.isRequested = false;
                Error();
            };

            if (!cachedObj) {
                cachedObj = this.createStoreCacheObj(exchange, symbol, language);
            }
            if (cachedObj.metaObj.isAvailable) {
                if (Ember['default'].$.isFunction(successFn)) {
                    successFn(cachedObj.dataObj.docArr);
                }
            } else {
                if (!cachedObj.metaObj.isRequested) {
                    cachedObj.metaObj.isRequested = true;
                    priceService['default'].sendDocumentRequest(exchange, symbol, language, postSuccess, error);
                }
            }
            return cachedObj.dataObj;
        },
        createStoreCacheObj: function createStoreCacheObj(exchange, symbol, language) {
            var key = this.getKey([exchange, symbol, language]);
            var storeCacheObj = {
                dataObj: { docArr: [] },
                metaObj: {}
            };
            this.dataStore[key] = storeCacheObj;
            return storeCacheObj;
        },

        createDocObj: function createDocObj() {
            return documentObj['default'].create();
        }

    }).create();

});