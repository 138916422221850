define('ir-app/models/price/price-business-entities/ratio-data', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({

        inst: '', // Instrument Type
        pe: '', // Company ID
        pb: '', // (Price to Book Value)
        divPerSher: '', // Dividend Per Share
        eps: '', // EPS
        bookVal: '', // Book Value per Share
        rtnEqty: '', // Return on Equity %
        rtnOnAst: '', // Return on Assets %

        setData: function setData(ratioVal) {
            var that = this;

            Ember['default'].$.each(ratioVal, function (key, value) {
                that.set(key, value);
            });
        }
    });

});