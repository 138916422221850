define('ir-app/views/table/buy-sell-cell', ['exports', 'ember', 'ir-app/views/table/cell'], function (exports, Ember, Cell) {

    'use strict';

    exports['default'] = Cell['default'].extend({
        templateName: 'table/views/buy-sell-cell',

        cellValue: Ember['default'].computed(function () {
            var receivedValue = this.get('cellContent') ? this.get('cellContent').firstValue : undefined;
            var shownValue = '--';

            if (receivedValue === 'B') {
                shownValue = 'Buy'; // TODO [AROSHA] get this from language file
            } else if (receivedValue === 'S') {
                    shownValue = 'Sell';
                }

            return shownValue;
        }).property('cellContent'),

        getPositiveNegativeStyle: function getPositiveNegativeStyle() {
            var value = this.get('cellContent') ? this.get('cellContent').firstValue : undefined;
            return value === 'B' ? 'green' : 'red';
        }
    });

});