define('ir-app/controllers/authentication/price-sso-authenticator', ['exports', 'ember', 'ir-app/models/price/price-service'], function (exports, Ember, priceService) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend({
        /* *
         * Authenticate user
         * @param ssoToken SSO token
         * @private
         */
        authenticateUser: function authenticateUser(ssoToken, _authSuccess, authFail) {
            priceService['default'].authenticateWithSsoToken({
                ssoToken: ssoToken,
                authSuccess: function authSuccess() {
                    _authSuccess();

                    // TODO: [Bashitha] Call secondary authentication service here, after finalizing auth protocol
                },
                authFailed: function authFailed(reason) {
                    authFail(reason);
                }
            });
        }
    }).create();

});