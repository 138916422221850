define('ir-app/config/price-widget-config', ['exports'], function (exports) {

    'use strict';

    exports['default'] = {
        watchList: {

            irPortalColumnMapping: {
                sym: { id: 'sym', width: 60, headerName: 'symbol', headerCellView: 'Ember.ClassicHeaderCell', headerStyle: 'text-left-header', sortKeyword: 'sym', cellStyle: 'colour-normal text-left-header', type: 'dualText' }, // width:94px for worst case scenario
                sDes: { id: 'sDes', width: 120, headerCellView: 'Ember.ClassicHeaderCell', headerName: 'description', headerStyle: 'text-left-header', sortKeyword: 'sDes', cellStyle: 'text-left-header', type: 'dualText', isndicatorAvailable: false },
                adjustedLtp: { id: 'adjustedLtp', width: 85, headerCellView: 'Ember.ClassicHeaderCell', headerName: 'lastPrice', sortKeyword: 'adjustedLtp', dataType: 'float', firstValueStyle: 'colour-2', backgroundStyle: 'watchlist-cell-back-lastqty', blinkUpStyle: 'blink-classic-up', blinkDownStyle: 'blink-classic-down', isBlink: true, type: 'classicCell' },
                cls: { id: 'cls', width: 90, headerCellView: 'Ember.ClassicHeaderCell', headerName: 'close', type: 'classicCell', sortKeyword: 'cls', firstValueStyle: 'colour-normal', dataType: 'float' },
                vol: { id: 'vol', width: 80, headerCellView: 'Ember.ClassicHeaderCell', headerName: 'volume', type: 'classicCell', sortKeyword: 'vol', firstValueStyle: 'colour-normal', dataType: 'int', blinkUpStyle: 'blink-font-style-change', blinkDownStyle: 'blink-font-style-change', isBlink: true },
                tovr: { id: 'tovr', width: 90, headerCellView: 'Ember.ClassicHeaderCell', headerName: 'turnover', type: 'classicCell', sortKeyword: 'tovr', firstValueStyle: 'colour-normal', dataType: 'int', blinkUpStyle: 'blink-font-style-change', blinkDownStyle: 'blink-font-style-change', isBlink: true },
                chg: { id: 'chg', width: 50, headerCellView: 'Ember.ClassicHeaderCell', headerName: 'change', sortKeyword: 'chg', positiveNegativeChange: true, type: 'changeCell', dataType: 'float' },
                trades: { id: 'trades', width: 60, headerCellView: 'Ember.ClassicHeaderCell', headerName: 'trades', type: 'classicCell', sortKeyword: 'trades', firstValueStyle: 'colour-normal', dataType: 'int', blinkUpStyle: 'blink-font-style-change', blinkDownStyle: 'blink-font-style-change', isBlink: true },
                pctChg: { id: 'pctChg', width: 75, headerCellView: 'Ember.ClassicHeaderCell', headerName: 'perChange', sortKeyword: 'pctChg', positiveNegativeChange: true, type: 'changeCell', dataType: 'percentage' },
                high: { id: 'high', width: 60, headerCellView: 'Ember.ClassicHeaderCell', headerName: 'high', type: 'classicCell', sortKeyword: 'high', firstValueStyle: 'colour-normal', dataType: 'float' },
                low: { id: 'low', width: 60, headerCellView: 'Ember.ClassicHeaderCell', headerName: 'low', type: 'classicCell', sortKeyword: 'low', firstValueStyle: 'colour-normal', dataType: 'float' },
                prevD: { id: 'prevD', width: 110, headerCellView: 'Ember.ClassicHeaderCell', headerName: 'lastTradedDate', type: 'classicCell', sortKeyword: 'prevD', firstValueStyle: 'colour-normal', dataType: 'date' },
                dPrevT: { id: 'dPrevT', width: 110, headerCellView: 'Ember.ClassicHeaderCell', headerName: 'lastTradedTime', type: 'classicCell', sortKeyword: 'dPrevT', firstValueStyle: 'colour-normal' }
            },

            irPortalIndexColumnMapping: {
                sDes: { id: 'sDes', width: 120, headerCellView: 'Ember.ClassicHeaderCell', headerName: 'indexName', headerStyle: 'text-left-header', sortKeyword: 'sDes', cellStyle: 'text-left-header', type: 'dualText' },
                adjustedLtp: { id: 'adjustedLtp', width: 85, headerCellView: 'Ember.ClassicHeaderCell', headerName: 'lastPrice', sortKeyword: 'adjustedLtp', dataType: 'float', firstValueStyle: 'colour-2', backgroundStyle: 'watchlist-cell-back-lastqty', blinkUpStyle: 'blink-classic-up', blinkDownStyle: 'blink-classic-down', isBlink: true, type: 'classicCell' },
                vol: { id: 'vol', width: 80, headerCellView: 'Ember.ClassicHeaderCell', headerName: 'volume', type: 'classicCell', sortKeyword: 'vol', firstValueStyle: 'colour-normal', dataType: 'int', blinkUpStyle: 'blink-font-style-change', blinkDownStyle: 'blink-font-style-change', isBlink: true },
                tovr: { id: 'tovr', width: 90, headerCellView: 'Ember.ClassicHeaderCell', headerName: 'turnover', type: 'classicCell', sortKeyword: 'tovr', firstValueStyle: 'colour-normal', dataType: 'int', blinkUpStyle: 'blink-font-style-change', blinkDownStyle: 'blink-font-style-change', isBlink: true },
                chg: { id: 'chg', width: 50, headerCellView: 'Ember.ClassicHeaderCell', headerName: 'change', sortKeyword: 'chg', positiveNegativeChange: true, type: 'changeCell', dataType: 'float' },
                trades: { id: 'trades', width: 60, headerCellView: 'Ember.ClassicHeaderCell', headerName: 'trades', type: 'classicCell', sortKeyword: 'trades', firstValueStyle: 'colour-normal', dataType: 'int', blinkUpStyle: 'blink-font-style-change', blinkDownStyle: 'blink-font-style-change', isBlink: true },
                pctChg: { id: 'pctChg', width: 75, headerCellView: 'Ember.ClassicHeaderCell', headerName: 'perChange', sortKeyword: 'pctChg', positiveNegativeChange: true, type: 'changeCell', dataType: 'percentage' },
                pctChgY: { id: 'pctChgY', width: 75, headerCellView: 'Ember.ClassicHeaderCell', headerName: 'pctChgYear', sortKeyword: 'pctChgY', positiveNegativeChange: true, type: 'changeCell', dataType: 'percentage' }
            },

            irPortalDefaultColumnIds: ['sym', 'sDes', 'adjustedLtp', 'cls', 'chg', 'pctChg', 'vol', 'tovr', 'trades', 'high', 'low', 'dPrevT', 'prevD'],

            irPortalIndexColumnIds: ['sDes', 'adjustedLtp', 'chg', 'pctChg', 'tovr', 'vol', 'trades', 'pctChgY'],

            tableParams: {
                MinHeaderHeight: { standard: 36, classic: 25, portal: 35 },
                RowHeight: { standard: 42, classic: 24, portal: 35 },
                MaxTableWidth: 5700
            }
        },

        globalSearch: {
            maxResultsForGroup: 20,

            groups: {
                topHits: { rank: 1, groupName: 'Top hits', type: 'T' },
                // Equity
                1: { rank: 2, groupName: 'Equity', type: 1 },
                // Fixed Income
                6: { rank: 3, groupName: 'Fixed Income', type: 6 },
                // Future
                4: { rank: 4, groupName: 'Future', type: 4 },
                // Option
                3: { rank: 5, groupName: 'Option', type: 3 },
                // Mutual Fund
                5: { rank: 6, groupName: 'Mutual Fund', type: 5 },
                // Index
                8: { rank: 7, groupName: 'Index', type: 8 },
                other: { rank: 8, groupName: 'Other', type: 'D' }
            }
        },

        scats: [{ id: '9,10', sDes: 'financialStatements', groupBy: true, viewDate: false }, { id: '37', sDes: 'pressReleases', groupBy: false, viewDate: true }, { id: '38', sDes: 'bMeetingDisclosures', groupBy: true, viewDate: true }, { id: '39', sDes: 'aMeetingDisclosures', groupBy: false, viewDate: true }, { id: '45', sDes: 'annualReports', groupBy: true, viewDate: false }, { id: '57', sDes: 'baselIII', groupBy: false, viewDate: false }, { id: '40', sDes: 'others', groupBy: false, viewDate: true }]
    };

});