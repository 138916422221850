define('ir-app/controllers/ir-mobile/alerts-widgets/email-alerts', ['exports', 'ir-app/controllers/base-controller', 'ir-app/models/shared/shared-service', 'ir-app/models/price/price-service', 'ir-app/models/price/price-constants', 'ir-app/utils/utils'], function (exports, BaseController, sharedService, priceService, priceConstants, utils) {

    'use strict';

    exports['default'] = BaseController['default'].extend({

        basicInfo: {},
        showContacts: false,
        indProObj: undefined,
        isButtonEnabled: true,

        // Base overrides
        onLoadWidget: function onLoadWidget() {
            this.setErrorMessage();
        },

        onPrepareData: function onPrepareData() {
            var that = this;
            var basicInfo = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, function () {
                that.set('showBasicInfo', true);
            }, function () {
                that.set('showBasicInfo', false);
                that.onDataError();
            }, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyBasicInfo);

            this.set('basicInfo', basicInfo);
        },

        onCheckDataAvailability: function onCheckDataAvailability() {
            var compObj = this.get('basicInfo');
            var individualProfObj = priceService['default'].indProfileDS.getStoredObject(compObj.contactPersonId, compObj.compID, sharedService['default'].userSettings.currentLanguage);

            return this.utils.validators.isAvailable(individualProfObj.name);
        },

        setProfileErrorMessage: (function () {
            this.set('showError1', false);
            if (!this.get('showBasicInfo')) {
                this.set('showError1', true);
                this.set('errorMessage1', this.get('app').lang.messages.dataNotAvailable);
            }
        }).observes('showBasicInfo'),

        onLanguageChanged: function onLanguageChanged() {
            this.onPrepareData();
        },

        emailSendMessage: function emailSendMessage() {
            if (this.get('isEmailSend') && this.get('isButtonEnabled')) {
                this.set('senderName', '');
                this.set('senderEmail', '');
                this.set('senderPone', '');
                this.set('senderMessage', '');
                this.set('ErrorFormMessage', '');
                document.getElementById("sendButton").disabled = false;
                this.set('SuccessFormMessage', this.get('app').lang.messages.mailSentSuccess);
            } else if (!this.get('isEmailSend') && this.get('isButtonEnabled')) {
                this.set('SuccessFormMessage', '');
                this.set('ErrorFormMessage', this.get('app').lang.messages.mailSentFail);
            }
        },

        _generateUrl: function _generateUrl(placeHoldingStr) {
            return utils['default'].formatters.applyPlaceHolders(placeHoldingStr, arguments);
        },

        _validateString: function _validateString(str) {
            var message = str ? str.trim() : '';

            if (message && message.length > 0) {
                this.set('ErrorFormText', '');
                return true;
            } else {
                this.set('ErrorFormText', this.get('app').lang.messages.invalidMessage);
                return false;
            }
        },

        _validateEmail: function _validateEmail(mailAdr) {
            var validMail = mailAdr ? mailAdr.trim() : '';
            var eMail = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            if (eMail.test(validMail)) {
                //this.set('ErrorFormSubscribeMail', '');
                return true;
            } else {
                //this.set('ErrorFormSubscribeMail',  this.get('app').lang.messages.invalidEmail);
                return false;
            }
        },

        _validateNumber: function _validateNumber(number) {
            var no = number ? number.trim() : '';
            //var phoneno = /^\d{10}$/;
            var phoneno = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
            if (no.match(phoneno) && no.length >= 10) {
                this.set('ErrorFromPhone', '');
                return true;
            } else {
                this.set('ErrorFromPhone', this.get('app').lang.messages.invalidPhone);
                return false;
            }
        },

        actions: {

            subscribeEmail: function subscribeEmail() {

                this.set('ErrorFormSelection', '');
                this.set('ErrorFormPriceTarget', '');
                this.set('ErrorFromStopLoss', '');
                this.set('ErrorFromPositivePct', '');
                this.set('ErrorFromNegativePct', '');
                this.set('ErrorFormVolumeTarget', '');
                this.set('ErrorFormSubscribeMail', '');
                // this.set('ErrorFormUnsubscribeMail', '');

                if (this.get('isPriceTarget') || this.get('isReDailySummary') || this.get('isStopLoss') || this.get('isPositivePct') || this.get('isNegativePct') || this.get('isVolumeTarget') || this.get('isHighVolume')) {

                    if (this.get('isPriceTarget')) {
                        if (!this.get('priceTarget')) {
                            this.set('ErrorFormPriceTarget', this.get('app').lang.messages.setValue);
                        }
                    }
                    if (this.get('isStopLoss')) {
                        if (!this.get('priceTarget')) {
                            this.set('ErrorFromStopLoss', this.get('app').lang.messages.setValue);
                        }
                    }
                    if (this.get('isPositivePct')) {
                        if (!this.get('priceTarget')) {
                            this.set('ErrorFromPositivePct', this.get('app').lang.messages.setValue);
                        }
                    }if (this.get('isNegativePct')) {
                        if (!this.get('priceTarget')) {
                            this.set('ErrorFromNegativePct', this.get('app').lang.messages.setValue);
                        }
                    }if (this.get('isVolumeTarget')) {
                        if (!this.get('priceTarget')) {
                            this.set('ErrorFormVolumeTarget', this.get('app').lang.messages.setValue);
                        }
                    }
                    this.set('isSelected', true);
                } else {
                    this.set('isSelected', false);
                    this.set('ErrorFormSelection', this.get('app').lang.messages.selectOneAlertType);
                }

                if (this.get('isSelected') && this._validateEmail(this.get('subscriberEmail'))) {

                    // Send Request
                } else {
                        this.set('ErrorFormSubscribeMail', this.get('app').lang.messages.invalidEmail);
                    }
                //this.set('SuccessFormMessage', '');
                //if (this.get('isButtonEnabled') && isValidName && isValidEmail && isValidPhone && isValidText) {
                //    var that = this;
                //    this.set('isButtonEnabled', false);
                //    this.set('SuccessFormMessage', this.get('app').lang.labels.sending);
                //    document.getElementById("sendButton").disabled = true;
                //    priceService.sendEmailPostRequest({
                //        name: this.get('senderName').trim(),
                //        email: this.get('senderEmail').trim(),
                //        clientId: this.get('clientId'),
                //        phone: this.get('senderPone').trim(),
                //        message: this.get('senderMessage').trim(),
                //        reqSuccessFn: function () {
                //            that.set('isEmailSend', true);
                //            that.set('isButtonEnabled', true);
                //            that.emailSendMessage();
                //        },
                //        reqFailureFn: function () {
                //            that.set('isEmailSend', false);
                //            that.set('isButtonEnabled', true);
                //            that.emailSendMessage();
                //        }
                //    });
                //} else {
                //    //this.set('ErrorFormMessage', this.get('app').lang.labels.invalidInputs);
                //    this.set('isEmailSend', false);
                //    this.set('isButtonEnabled', true);
                //}
            },

            unSubscribeEmail: function unSubscribeEmail() {

                this.set('ErrorFormUnsubscribeMail', '');

                if (!this._validateEmail(this.get('unSubscriberEmail'))) {

                    this.set('ErrorFormUnsubscribeMail', this.get('app').lang.messages.invalidEmail);
                }
            }
        }

    });

});