define('ir-app/controllers/ir-widgets/watchlist-widget/advance-watch-list', ['exports', 'ember', 'ir-app/controllers/shared/basic-table-controller', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/config/price-widget-config', 'ir-app/utils/utils', 'ir-app/views/table/classic-header-cell', 'ir-app/views/table/dual-cells/header-cell', 'ir-app/views/table/cell', 'ir-app/views/table/button-cell', 'ir-app/views/table/button-menu-cell', 'ir-app/views/table/classic-cell', 'ir-app/views/table/classic-progress-cell', 'ir-app/views/table/change-cell', 'ir-app/views/table/per-change-cell', 'ir-app/views/table/up-down-cell', 'ir-app/views/table/dual-cells/dot-cell', 'ir-app/views/table/dual-cells/dual-arrow-cell', 'ir-app/views/table/dual-cells/dual-change-cell', 'ir-app/views/table/dual-cells/dual-text-cell', 'ir-app/views/table/dual-cells/dual-cell', 'ir-app/views/table/dual-cells/progress-cell', 'ir-app/views/table/table-row'], function (exports, Ember, BasicTableController, priceService, sharedService, priceWidgetConfig, utils, ClassicHeaderCell, HeaderCell, Cell, ButtonCell, ButtonMenuCell, ClassicCell, ClassicProgressCell, ChangeCell, PerChangeCell, UpDownCell, DotCell, DualArrowCell, DualChangeCell, DualTextCell, DualCell, ProgressCell, TableRow) {

    'use strict';

    exports['default'] = BasicTableController['default'].extend({
        // Filter parameters
        textFilter: null,
        // Table Parameters
        minHeaderHeight: priceWidgetConfig['default'].watchList.tableParams.MinHeaderHeight.standard,
        rowHeight: priceWidgetConfig['default'].watchList.tableParams.RowHeight.standard,
        indicatorConfig: {
            isTodaysHighEnabled: false,
            isTodaysLowEnabled: false,
            isFiftyTwoHEnabled: false,
            isFiftyTwoLEnabled: false,
            isSymSuspendedEnabled: true
        },
        numOfFixedColumns: 0,
        symList: Ember['default'].A(),

        defaultColumnDeclarations: Ember['default'].A(),
        // Column Parametrs
        columnDeclarations: Ember['default'].A(),

        marketWatchListColumnIds: null,
        indexWatchListColumnIds: {
            defaultIds: priceWidgetConfig['default'].watchList.irPortalIndexColumnIds,
            columnMapping: priceWidgetConfig['default'].watchList.irPortalIndexColumnMapping
        },
        isAccumulatedLossFilter: false,
        sortAscending: false,
        sector: null,
        isIndexWLMode: false,
        isSectorWLMode: false,
        exgObj: undefined,

        // Column Parameters
        columnViews: {},

        // Content related Parameters
        model: Ember['default'].A(),
        masterContent: Ember['default'].A(),
        // reference for scroll event listening scope binded function
        scrollController: undefined,

        onLoadWidget: function onLoadWidget() {

            this.makeTblHeadsSticky();
            this.setCellViewsScopeToGlobal();
            if (this.isSectorWLMode && this.get('sym').toUpperCase() !== 'A') {
                this.set('sector', this.get('sym'));
            }
            this.watchListColumnIds = { defaultIds: this.get('irPortalDefaultColumnIds'), columnMapping: this.get('irPortalColumnMapping') };
            this.defaultSortId = this.get('defaultSortId') ? this.get('defaultSortId') : 'tovr';

            if (this.isIndexWLMode) {
                this.set('marketWatchListColumnIds', this.get('indexWatchListColumnIds'));
            } else {
                this.set('marketWatchListColumnIds', this.get('watchListColumnIds'));
            }
            this.setTableParameters();
            this.setDefaultColumnDeclarations();
            this.setDefaultSort();
        },

        onPrepareData: function onPrepareData() {
            var that = this;
            var exchange = this.get('exg');
            this.set('exgObj', priceService['default'].exchangeDS.getExchange(exchange));
            this.startLoadingProgress();

            if (this.isSectorWLMode && this.get('sym').toUpperCase() !== 'A') {
                this.set('sector', this.get('sym'));
            }

            priceService['default'].downloadExchangeProperty(that.get('exg'), sharedService['default'].userSettings.currentLanguage);

            priceService['default'].downloadParamsSymbolMasterForExchange({
                exchange: that.get('exg'),
                language: sharedService['default'].userSettings.currentLanguage,

                successFn: function successFn() {
                    Ember['default'].run.later(function () {
                        that.onSuccess();
                    }, 500);
                },

                errorFn: function errorFn() {
                    that.onError();
                }
            });
        },

        onClearData: function onClearData() {
            var exchange = this.get('exg');
            if (this.isIndexWLMode) {
                priceService['default'].removeFullMarketIndexRequest(exchange);
            } else {
                var arrSymbols = this.get('symList');
                Ember['default'].$.each(arrSymbols, function (id, item) {
                    priceService['default'].addSymbolRequest(item.exg, item.sym);
                });
            }
        },

        onUnloadWidget: function onUnloadWidget() {
            var that = this;
            if (window.removeEventListener) {
                window.removeEventListener('message', that.get('scrollController'), false);
            } else {
                window.detachEvent('message', that.get('scrollController'));
            }
            Ember['default'].$(window).off('scroll');
            this.set('textFilter', null);
            this.set('sector', null);
            this.set('model', Ember['default'].A());
            this.set('masterContent', Ember['default'].A());
            this.set('symList', Ember['default'].A());
        },

        onLanguageChanged: function onLanguageChanged() {
            this.onLoadWidget();
        },

        onSuccess: function onSuccess() {
            this.loadContent();

            if (this.isIndexWLMode) {
                priceService['default'].addFullMarketIndexRequest(this.get('exg'));
            } else {
                var arrSymbols = this.get('symList');
                var exgObj = this.get('exgObj');
                Ember['default'].$.each(arrSymbols, function (id, item) {
                    item.set('tzo', exgObj.tzo);
                    priceService['default'].addSymbolRequest(item.exg, item.sym);
                });
            }
        },

        onError: function onError() {},

        setLanguageSettings: function setLanguageSettings(language) {
            this.setDefaultColumns();
            this.setLangLayoutSettings(language);
        },

        setLangLayoutSettings: function setLangLayoutSettings(language) {
            var numOfFixedColumns, scrollPixels;

            if (language === 'AR') {
                numOfFixedColumns = 0;
                scrollPixels = priceWidgetConfig['default'].watchList.tableParams.MaxTableWidth;
            } else {
                numOfFixedColumns = 2;
                scrollPixels = 0;
            }

            this.set('numOfFixedColumns', numOfFixedColumns);

            Ember['default'].run.later(function () {
                Ember['default'].$('.antiscroll-inner').scrollLeft(scrollPixels);
            }, 200);
        },

        loadContent: function loadContent() {
            if (this.isIndexWLMode) {
                this.loadIndexWLContent();
            } else if (this.isSectorWLMode) {
                this.loadWLSectorContent();
            } else {
                this.loadWLContent();
            }
        },

        loadWLContent: function loadWLContent() {
            var exchange = this.get('exg');
            var store = priceService['default'].stockDS.get('stockMapByExg');

            if (!store[exchange]) {
                store[exchange] = Ember['default'].A();
                priceService['default'].stockDS.set('stockMapByExg', store);
            }
            this.set('model', store[exchange]);
            this.set('masterContent', store[exchange]);
        },

        loadWLSectorContent: function loadWLSectorContent() {
            var exchange = this.get('exg');
            var sector = this.get('sector');
            var store = priceService['default'].stockDS.get('indexTickerSymbols');
            var id = exchange + '_' + sector;

            if (!store[id]) {
                store[id] = Ember['default'].A();
                priceService['default'].stockDS.set('indexTickerSymbols', store);
            }

            this.set('symList', store[id]);
            this.set('model', store[id]);
            this.set('masterContent', store[id]);
        },

        loadIndexWLContent: function loadIndexWLContent() {
            var exchange = this.get('exg');
            var store = priceService['default'].stockDS.get('indexMapByExg');

            if (!store[exchange]) {
                store[exchange] = Ember['default'].A();
                priceService['default'].stockDS.set('indexMapByExg', store);
            }

            this.set('model', store[exchange]);
            this.set('masterContent', store[exchange]);
        },

        setTableParameters: function setTableParameters() {
            this.setTableViewParameters();
            this.setLanguageSettings(sharedService['default'].userSettings.currentLanguage);
        },

        setTableViewParameters: function setTableViewParameters() {
            this.set('minHeaderHeight', priceWidgetConfig['default'].watchList.tableParams.MinHeaderHeight.portal);
            this.set('rowHeight', priceWidgetConfig['default'].watchList.tableParams.RowHeight.portal);
            this.setLangLayoutSettings(sharedService['default'].userSettings.currentLanguage, this.get('isFullScreenWL'));
        },

        setCellViewsScopeToGlobal: function setCellViewsScopeToGlobal() {
            Ember['default'].HeaderCell = HeaderCell['default'];
            Ember['default'].ClassicHeaderCell = ClassicHeaderCell['default'];
            Ember['default'].Cell = Cell['default'];
            Ember['default'].ClassicCell = ClassicCell['default'];
            Ember['default'].ClassicProgressCell = ClassicProgressCell['default'];
            Ember['default'].ChangeCell = ChangeCell['default'];
            Ember['default'].PerChangeCell = PerChangeCell['default'];
            Ember['default'].UpDownCell = UpDownCell['default'];
            Ember['default'].ButtonCell = ButtonCell['default'];
            Ember['default'].ButtonMenuCell = ButtonMenuCell['default'];
            Ember['default'].DotCell = DotCell['default'];
            Ember['default'].DualArrowCell = DualArrowCell['default'];
            Ember['default'].DualChangeCell = DualChangeCell['default'];
            Ember['default'].DualTextCell = DualTextCell['default'];
            Ember['default'].DualCell = DualCell['default'];
            Ember['default'].ProgressCell = ProgressCell['default'];
            Ember['default'].TableRow = TableRow['default'];
        },

        cellViewsForColumns: {
            button: 'Ember.ButtonCell',
            buttonMenu: 'Ember.ButtonMenuCell',
            classicProgressCell: 'Ember.ClassicProgressCell',
            classicCell: 'Ember.ClassicCell',
            changeCell: 'Ember.ChangeCell',
            perChangeCell: 'Ember.PerChangeCell',
            upDown: 'Ember.UpDownCell',
            dual: 'Ember.DualCell',
            dualText: 'Ember.DualTextCell',
            dualChange: 'Ember.DualChangeCell',
            progress: 'Ember.ProgressCell',
            dot: 'Ember.DotCell',
            dualArrow: 'Ember.DualArrowCell'
        },

        // checkFilterMatch: function checkFilterMatch(stock, textFilter, sectorFilter, accumulatedLossFilter) {
        //    var field;
        //    var isMatchedTextFilter = !textFilter;  // If a argument is false, that means that filter is not applied
        //    var isMatchedSectorFilter = !sectorFilter;
        //    var isAccumulatedLossFilter = !accumulatedLossFilter;
        //
        //    if (!isMatchedTextFilter) {
        //        for (field in stock) {
        //            if (stock.hasOwnProperty(field) && (field === 'sym' || field === 'sDes' || field === 'lDes') && stock[field] && stock[field].toString().slice(0, textFilter.length).toLowerCase() === textFilter.toLowerCase()) {
        //                isMatchedTextFilter = true;
        //            }
        //        }
        //    }
        //    if (!isMatchedSectorFilter) {
        //        isMatchedSectorFilter = stock.get('sec') === sectorFilter;
        //    }
        //
        //    if (!isAccumulatedLossFilter) {
        //        isAccumulatedLossFilter = (stock.get('dcfs') === '1' || stock.get('dcfs') === '2' || stock.get('dcfs') === '3');
        //    }
        //
        //    return isMatchedTextFilter && isMatchedSectorFilter && isAccumulatedLossFilter;
        // },
        //
        // filterStocks: (function () {
        //    var textFilter = this.utils.validators.isAvailable(this.get('textFilter')) ? this.get('textFilter') : false;  // If any filter is false, that means that filter is not applied
        //    var sectorFilter = this.utils.validators.isAvailable(this.get('sector')) ? this.get('sector') : false;
        //    var accumulatedLossFilter = this.get('isAccumulatedLossFilter');
        //
        //    var filteredStocks = this.get('masterContent').filter((function (that) {    //eslint-disable-line
        //        return function (stock) {
        //
        //            if ((textFilter || sectorFilter || accumulatedLossFilter)) {
        //                return that.checkFilterMatch(stock, textFilter, sectorFilter, accumulatedLossFilter);
        //            } else {
        //                return true;
        //            }
        //        };
        //    })(this));
        //
        //    this.set('content', filteredStocks);        // Need to capture filter removing event to avoid 'set' without filters
        // }).observes('textFilter', 'isAccumulatedLossFilter', 'masterContent.@each'),     // Removed sortProperties observing - masterContent.@each apply filters when market changed

        trackFiltersGA: (function () {
            var textFilter = this.utils.validators.isAvailable(this.get('textFilter')) ? this.get('textFilter') : false; // If any filter is false, that means that filter is not applied
            var sectorFilter = this.utils.validators.isAvailable(this.get('sector')) ? this.get('sector') : false;

            this.utils.analyticsService.trackEvent(this.get('gaKey'), this.utils.Constants.GAActions.filter, ['textFilter:', textFilter, '~', sectorFilter].join(''));
        }).observes('textFilter', 'sector'),

        /* Fill More Columns window with columns selected accordingly */
        setDefaultColumnDeclarations: function setDefaultColumnDeclarations() {
            var that = this;
            var watchListColumnIds = this.get('marketWatchListColumnIds');
            var columns = watchListColumnIds.columnMapping;
            var columnIds = watchListColumnIds.defaultIds;
            var columnArray = this.columnDefinitionsByMap(columns, columnIds);
            var currentColumnArray = this.get('columnDeclarations');
            var currentColumnMap = {};

            Ember['default'].$.each(currentColumnArray, function (index, column) {
                currentColumnMap[column.id] = column;
            });

            Ember['default'].$.each(columnArray, function (index, column) {
                if (currentColumnMap[column.id]) {
                    Ember['default'].set(column, 'isSelectedColumn', true);
                } else {
                    Ember['default'].set(column, 'isSelectedColumn', false);
                }

                var name = column.name ? that.get('app').lang.labels[column.name] : that.get('app').lang.labels[column.headerName];

                if (column.headerSecondName) {
                    name = name + ' / ' + that.get('app').lang.labels[column.headerSecondName];
                }

                Ember['default'].set(column, 'displayName', name);
            });

            this.set('defaultColumnDeclarations', columnArray);
        },

        setDefaultColumns: function setDefaultColumns() {
            var columns, columnIds;
            var watchListColumnIds = this.get('marketWatchListColumnIds');

            columnIds = watchListColumnIds.defaultIds;
            columns = watchListColumnIds.columnMapping;
            this.set('columnDeclarations', this.columnDefinitionsByMap(columns, columnIds));

            Ember['default'].run.later(function () {
                if (Ember['default'].$('.antiscroll-wrap').antiscroll().data('antiscroll')) {
                    Ember['default'].$('.antiscroll-wrap').antiscroll().data('antiscroll').rebuild();
                }
            }, 200);
        },

        setDefaultSort: function setDefaultSort() {
            if (this.get('isSectorWLMode')) {
                this.set('sortAscending', false);
            } else {
                this.set('defaultSortId', 'sDes');
                this.set('sortAscending', true);
            }
            var sortId = this.defaultSortId;
            var that = this;
            this.set('sortProperties', [sortId]);

            Ember['default'].run.later(function () {
                var tableColumns = that.get('columns');

                Ember['default'].$.each(tableColumns, function (key, column) {
                    if (column.get('contentPath') === sortId) {
                        Ember['default'].set(column, 'isSorted', true);
                        return false;
                    }
                });
            }, 200);
        },

        actions: {
            onTabSelectionChanged: function onTabSelectionChanged(option) {
                if (option === 'loss') {
                    this.set('isAccumulatedLossFilter', true);
                } else {
                    this.set('isAccumulatedLossFilter', false);
                }
            },

            clickRow: function clickRow(selectedRow) {

                if (this.get('isIndexWLMode')) {
                    this.send('changeTab', 3, selectedRow.get('sym'));
                } else {
                    var link = this.get('clientStkPage');
                    // var lan = sharedService.userSettings.currentLanguage.toLowerCase();
                    var exchange = selectedRow.get('exg');
                    var symbol = selectedRow.get('sym');
                    this._generateUrl(link, exchange, symbol);
                }
            },

            sort: function sort(column) {
                if (!column.get('isSortSupported')) {
                    return;
                }

                if (this.get('sortColumn') !== column) {
                    this.get('columns').setEach('isSorted', false);
                    column.set('isSorted', true);
                    this.set('sortColumn', column);
                    this.set('sortProperties', [column.get('sortKey')]);
                    this.set('isSortApplied', true);
                } else if (this.get('sortColumn') === column) {
                    // Handle disabling sorts
                    if (this.get('sortAscending') === true) {
                        this.set('sortColumn', undefined);
                        this.set('sortAscending', false);
                        column.set('isSorted', false);
                        this.set('isSortApplied', false);
                        this.set('sortProperties', []);
                    } else {
                        this.set('sortProperties', [column.get('sortKey')]);
                        this.toggleProperty('sortAscending');
                    }
                }

                this.utils.analyticsService.trackEvent(this.get('gaKey'), this.utils.Constants.GAActions.select, ['sortedColumn:', this.get('sortProperties').join('')].join(''));
            }
        },

        onAfterRender: function onAfterRender() {
            var originalHead = Ember['default'].$('.ember-table-header-container');
            var stickyHead = originalHead.clone();
            originalHead.addClass('original');
            stickyHead.addClass('clone display-none');
            originalHead.after(stickyHead);
        },

        makeTblHeadsSticky: function makeTblHeadsSticky() {
            var that = this;
            that.set('scrollController', that.controlScroll.bind(that));

            if (that.get('parentDomains')) {
                // parent site controls the table headers stickiness

                if (window.addEventListener) {
                    window.addEventListener('message', that.get('scrollController'), false);
                } else if (window.attachEvent) {
                    // IE
                    window.attachEvent('onmessage', that.get('scrollController'));
                }
            } else {
                // application itself controls the table headers stickiness

                Ember['default'].$(window).off('scroll');
                Ember['default'].$(window).bind('scroll', function () {
                    var currentHeight = Ember['default'].$(window).scrollTop();
                    var heightToHead = Ember['default'].$('.original').offset().top;
                    var heightToBotm = Ember['default'].$('#watchListContainer').offset().top + Ember['default'].$('#watchListContainer').outerHeight(true);
                    that.fixHeader(currentHeight, heightToHead, heightToBotm);
                });
            }
        },

        controlScroll: function controlScroll(event) {
            var that = this;
            var parentDomains = that.get('parentDomains');
            if (parentDomains.indexOf(event.origin) < 0) {
                // validate message sender
                return;
            }
            var parentScrollMessage = event.data.split('=')[1]; // format   parentScroll=scrollVal,iframTop
            var currentHeight = parseInt(parentScrollMessage.split(',')[0], 10);
            var heightToHead = parseInt(parentScrollMessage.split(',')[1], 10) + Ember['default'].$('.original').offset().top;
            var heightToBotm = parseInt(parentScrollMessage.split(',')[1], 10) + Ember['default'].$('#watchListContainer').offset().top + Ember['default'].$('#watchListContainer').outerHeight(true);
            that.fixHeader(currentHeight, heightToHead, heightToBotm);
        },

        fixHeader: function fixHeader(currentHeight, heightToHead, heightToBotm) {

            if (heightToBotm > currentHeight && currentHeight > heightToHead) {
                Ember['default'].$('.clone').removeClass('display-none');
                Ember['default'].$('.clone').addClass('absolute display-block');
                Ember['default'].$('.clone').css('top', currentHeight - heightToHead);
            } else {
                Ember['default'].$('.clone').css('top', '0px');
                Ember['default'].$('.clone').removeClass('absolute display-block');
                Ember['default'].$('.clone').addClass('display-none');
            }
        },

        _generateUrl: function _generateUrl(placeHoldingStr) {
            var link = utils['default'].formatters.applyPlaceHolders(placeHoldingStr, arguments);
            var win = window.open(link, '_parent');
            win.focus();
        }
    });

    Ember['default'].Handlebars.helper('watchlistHeight', function (content) {
        return content.length * priceWidgetConfig['default'].watchList.tableParams.RowHeight.portal + priceWidgetConfig['default'].watchList.tableParams.MinHeaderHeight.portal + 5 + 'px';
    });

});