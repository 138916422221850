define('ir-app/models/price/price-business-entities/ipo-data', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({

        ipoDataList: null,
        fullListSize: null,
        ipoStatusList: null,
        ipoCompanyList: null,
        ipoIndustryList: null,

        init: function init() {
            this._super();
            this.set('ipoDataList', Ember['default'].A());
            this.set('ipoStatusList', Ember['default'].A());
            this.set('ipoCompanyList', Ember['default'].A());
            this.set('ipoIndustryList', Ember['default'].A());
            this.set('fullListSize', 0);
        },

        setData: function setData(ipoDataList) {
            var that = this;

            Ember['default'].$.each(ipoDataList, function (key, value) {
                that.set(key, value);
            });
        }

    });

});