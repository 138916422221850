define('ir-app/components/widget-header', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        layoutName: 'components/widget-header',

        actions: {
            setLink: function setLink(option) {
                if (this.get('parentView') && this.get('parentView').outletName) {
                    // Only two types of Headers :- In Popup Views and Widgets
                    this.get('parentView').send('setLinkView', option);
                } else {
                    this.sendAction('setLink', option);
                }
            },

            titleTabAction: function titleTabAction(option) {
                this.sendAction('tabAction', option);
            }
        }
    });

});