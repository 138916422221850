define('ir-app/models/price/price-data-stores/search-data-store', ['exports', 'ember', 'ir-app/models/price/price-service', 'ir-app/models/price/price-business-entities/search-result-item', 'ir-app/config/price-widget-config', 'ir-app/models/price/price-constants', 'ir-app/utils/utils'], function (exports, Ember, priceService, searchResultItem, priceWidgetConfig, PriceConstants, utils) {

    'use strict';

    exports['default'] = (function () {
        var symbolSearchResults = Ember['default'].A();
        var recentSearchedItems = Ember['default'].A();
        // TODO: [Eranga] Get this value in proper manner
        var hasNonDefaultMarket = true;

        var _filterSymbolFromLocalStorage = function _filterSymbolFromLocalStorage(searchKey) {
            var stocks = priceService['default'].stockDS.getStockCollection();
            var resultItem;

            if (stocks) {
                var filteredContent = Ember['default'].$.map(stocks, function (value) {

                    if ((utils['default'].validators.isAvailable(value.sym) && value.sym.isExist(searchKey) || utils['default'].validators.isAvailable(value.lDes) && value.lDes.isExist(searchKey) || utils['default'].validators.isAvailable(value.sDes) && value.sDes.isExist(searchKey)) && utils['default'].validators.isAvailable(value.inst)) {
                        // TODO: [Eranga G] Instrument types filtering is added temorary in order to avoid duplicating TASI
                        // symbol in search result. This statement can be removed after stopping adding index to stock collection

                        resultItem = searchResultItem['default'].create();

                        resultItem.setData({
                            sym: value.sym,
                            exg: value.exg,
                            inst: value.inst,
                            lDes: value.lDes,
                            sDes: value.sDes,
                            ast: value.ast
                        });

                        return resultItem;
                    }
                });

                if (filteredContent && filteredContent.length > 0) {
                    _groupFilteredContent(filteredContent, searchKey);
                } else {
                    _setSearchResultUnavailability();
                }
            }
        };

        var _groupFilteredContent = function _groupFilteredContent(filteredContent, searchKey) {
            var hasType, type, hasStartingChar, groupSetting, assetType, resultArray;
            var config = priceWidgetConfig['default'].globalSearch.groups;
            var resultLimit = priceWidgetConfig['default'].globalSearch.maxResultsForGroup;

            Ember['default'].$.each(filteredContent, function (key, item) {
                assetType = item.get('ast');
                groupSetting = config[assetType] ? config[assetType] : config.other;
                hasStartingChar = false;

                if (item.sym.isExactMatch(searchKey) || item.lDes.isExactMatch(searchKey) || item.sDes.isExactMatch(searchKey)) {
                    groupSetting = config.topHits;
                } else if (item.sym.isStartedWith(searchKey) || item.lDes.isStartedWith(searchKey) || item.sDes.isStartedWith(searchKey)) {
                    hasStartingChar = true;
                }

                type = groupSetting.type;
                hasType = symbolSearchResults.findBy('type', type);

                if (!hasType) {
                    symbolSearchResults.pushObject(Ember['default'].Object.create({
                        type: type,
                        rank: groupSetting.rank,
                        name: groupSetting.groupName,
                        contents: Ember['default'].A()
                    }));
                }

                resultArray = symbolSearchResults.findBy('type', type).get('contents');

                if (hasStartingChar) {
                    resultArray.unshiftObject(item);
                } else {
                    resultArray.pushObject(item);
                }

                if (resultArray.length > resultLimit) {
                    resultArray.pop();
                }
            });
        };

        var _setSearchResultUnavailability = function _setSearchResultUnavailability() {
            symbolSearchResults.pushObject(Ember['default'].Object.create({
                isDataUnavailable: true
            }));
        };

        var getSymbolSearchResults = function getSymbolSearchResults() {
            return symbolSearchResults;
        };

        var filterSymbolSearchResults = function filterSymbolSearchResults(searchKey, language, exchangeList, tempID) {
            symbolSearchResults.clear();

            if (hasNonDefaultMarket) {
                // If non default market is available, search result will be taken from mix service
                priceService['default'].sendSymbolSearchRequest(searchKey, language, PriceConstants['default'].SymbolSearchPageSize, getFilteredContentFromServer, exchangeList, tempID);
            } else {
                _filterSymbolFromLocalStorage(searchKey);
            }
        };

        var getFilteredContentFromServer = function getFilteredContentFromServer(isSuccess, searchKey, content) {
            if (isSuccess) {
                _groupFilteredContent(content, searchKey);
            } else {
                _setSearchResultUnavailability();
            }
        };

        var addRecentSearchedItem = function addRecentSearchedItem(searchedItem) {
            recentSearchedItems.pushObject(searchedItem);
        };

        var getRecentSearchedItems = function getRecentSearchedItems() {
            return recentSearchedItems;
        };

        return {
            addRecentSearchedItem: addRecentSearchedItem,
            filterSymbolSearchResults: filterSymbolSearchResults,
            getSymbolSearchResults: getSymbolSearchResults,
            getRecentSearchedItems: getRecentSearchedItems
        };
    })();

});