define('ir-app/models/price/price-business-entities/fundamentals/estimate-year-object', ['exports', 'ember', 'ir-app/models/price/price-business-entities/fundamentals/estimate-provider-object'], function (exports, Ember, providerObj) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        estimateYear: undefined,
        providerList: undefined,

        init: function init() {
            this.providerList = {};
        },

        setProviderList: function setProviderList(DUR_YEAR, PROVIDER_NAME, PROVIDED_DATE, value) {
            if (DUR_YEAR === undefined || value === undefined) {
                return;
            }
            var providerList = this.get('providerList');
            var dataObject = providerList[PROVIDER_NAME];

            var object;

            if (!dataObject) {
                object = providerObj['default'].create();
                object.providerID = PROVIDER_NAME;
                object.setYearlyEst(PROVIDER_NAME, PROVIDED_DATE, value);

                providerList[PROVIDER_NAME] = object;
            } else {
                object = providerList[PROVIDER_NAME];
                object.setYearlyEst(PROVIDER_NAME, PROVIDED_DATE, value);
            }
            // return object;
        }
    });

});