define('ir-app/views/table/row-definition', ['exports', 'ember', 'ember-table/controllers/row'], function (exports, Ember, RowDefinition) {

    'use strict';

    exports['default'] = RowDefinition['default'].extend({
        isOddRow: Ember['default'].computed(function () {
            var rowId = this.get('parentController').rowIndex(this);
            return rowId && rowId % 2 !== 0;
        }).property('parentController.bodyContent.length'),

        isSymSuspended: Ember['default'].computed(function () {
            return this.get('parentController.indicatorConfig') ? this.get('parentController.indicatorConfig').isSymSuspendedEnabled && (this.get('content.symStat') === '2' || this.get('content.symStat') === '3') : false;
        }).property('content.symStat'),

        isTodaysHigh: Ember['default'].computed(function () {
            return this.get('parentController.indicatorConfig') ? this.get('parentController.indicatorConfig').isTodaysHighEnabled && this.get('content.isTodayHigh') : false;
        }).property('content.is52WeekHigh'),

        isTodaysLow: Ember['default'].computed(function () {
            return this.get('parentController.indicatorConfig') ? this.get('parentController.indicatorConfig').isTodaysLowEnabled && this.get('content.isTodayLow') : false;
        }).property('content.is52WeekLow'),

        is52WeekHigh: Ember['default'].computed(function () {
            return this.get('parentController.indicatorConfig') ? this.get('parentController.indicatorConfig').isFiftyTwoHEnabled && this.get('content.is52WeekHigh') : false;
        }).property('content.is52WeekHigh'),

        is52WeekLow: Ember['default'].computed(function () {
            return this.get('parentController.indicatorConfig') ? this.get('parentController.indicatorConfig').isFiftyTwoLEnabled && this.get('content.is52WeekLow') : false;
        }).property('content.is52WeekLow')
    });

});