define('ir-app/components/multiselect-dropdown', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    /**
     * Created by lakmaln on 10/17/2016.
     */

    exports['default'] = Ember['default'].Component.extend({
        layoutName: 'components/multiselect-dropdown',
        fullKeyList: [],
        selectedKeyList: [],

        selectMessage: '',
        nothingSelectedMessage: 'Nothing Selected',
        multipleSelectedMessage: 'Items Selected',
        selectAllLabel: 'Select All',
        selectNoneLabel: 'Deselect All',

        applyProperties: (function () {
            var that = this;
            var optionArray = that.get('options');

            if (optionArray && optionArray.length > 0) {
                that.set('fullKeyList', []);
                optionArray.forEach(function (element) {
                    if (element) {
                        Ember['default'].set(element, 'optionLabel', element[that.get('optionLabelPath')]);
                        Ember['default'].set(element, 'optionValue', element[that.get('optionValuePath')]);

                        that.get('fullKeyList').pushObject(element[that.get('optionValuePath')]);
                    }
                });

                that.set('selectedKeyList', that.get('fullKeyList').slice());
                that.set('options', optionArray);
            }
            that.sendAction('selectAction', that.get('selectedKeyList'), true);
        }).on('init').observes('options'),

        updateSelectionMessage: (function () {
            var that = this;

            if (that.get('selectedKeyList').length > 0) {
                if (that.get('selectedKeyList').length > 1) {
                    that.set('selectMessage', that.get('selectedKeyList').length + " " + that.get('multipleSelectedMessage'));
                } else {
                    that.set('selectMessage', that.get('options').findBy(that.get('optionValuePath'), that.get('selectedKeyList')[0]).provider);
                }
            } else {
                that.set('selectMessage', that.get('nothingSelectedMessage'));
            }
        }).observes('selectedKeyList'),

        hasClass: function hasClass(el, className) {
            if (el.classList) {
                return el.classList.contains(className);
            } else {
                return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
            }
        },

        addClass: function addClass(el, className) {
            var that = this;

            if (el.classList) {
                el.classList.add(className);
            } else if (!that.hasClass(el, className)) {
                el.className += " " + className;
            }
        },

        removeClass: function removeClass(el, className) {
            var that = this;

            if (el.classList) {
                el.classList.remove(className);
            } else if (that.hasClass(el, className)) {
                var reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
                el.className = el.className.replace(reg, ' ');
            }
        },

        allSelectionChange: function allSelectionChange(keyList, isEnable) {
            var that = this;

            if (keyList && keyList.length > 0) {
                keyList.forEach(function (key) {
                    var id = "optionIndicator_" + key;
                    var element = document.getElementById(id);

                    if (isEnable) {
                        that.removeClass(element, 'fa-square-o');
                        that.addClass(element, 'fa-check-square');
                    } else {
                        that.removeClass(element, 'fa-check-square');
                        that.addClass(element, 'fa-square-o');
                    }
                });
            }
        },

        actions: {
            select: function select(option) {
                var that = this;
                var id = "optionIndicator_" + option['optionValue'];
                var element = document.getElementById(id);

                if (that.hasClass(element, 'fa-square-o')) {
                    that.removeClass(element, 'fa-square-o');
                    that.addClass(element, 'fa-check-square');

                    that.get('selectedKeyList').pushObject(option['optionValue']);
                    that.updateSelectionMessage();
                } else {
                    that.removeClass(element, 'fa-check-square');
                    that.addClass(element, 'fa-square-o');

                    that.get('selectedKeyList').removeObject(option['optionValue']);
                    that.updateSelectionMessage();
                }
                that.sendAction('selectAction', that.get('selectedKeyList'), false);
            },

            selectAll: function selectAll() {
                var that = this;
                that.allSelectionChange(that.get('fullKeyList'), true);
                that.set('selectedKeyList', that.get('fullKeyList').slice());
                that.sendAction('selectAction', that.get('selectedKeyList'), false);
            },

            deselectAll: function deselectAll() {
                var that = this;
                that.allSelectionChange(that.get('fullKeyList'), false);
                that.set('selectedKeyList', []);
                that.sendAction('selectAction', that.get('selectedKeyList'), false);
            },

            showDropdown: function showDropdown() {
                var that = this;
                var element = document.getElementById("multiSelectComponent");

                if (that.hasClass(element, 'open')) {
                    that.removeClass(element, 'open');
                } else {
                    that.addClass(element, 'open');
                }
            },
            closeDropdown: function closeDropdown() {
                var that = this;
                var element = document.getElementById("multiSelectComponent");
                that.removeClass(element, 'open');
            }
        }

    });

});