define('ir-app/models/price/price-data-stores/ipo-data-store', ['exports', 'ember', 'ir-app/models/price/price-business-entities/ipo-company-list', 'ir-app/models/price/price-business-entities/ipo-industry-list', 'ir-app/models/price/price-business-entities/ipo-data', 'ir-app/models/price/price-business-entities/ipo-list', 'ir-app/models/price/price-business-entities/ipo-status-types', 'ir-app/models/price/price-service', 'ir-app/models/price/price-constants', 'ir-app/utils/utils', 'ir-app/models/price/price-data-stores/base-data-store'], function (exports, Ember, companyList, industryObj, ipoData, ipoObj, ipoStatus, priceService, priceConstants, utils, BaseStore) {

    'use strict';

    exports['default'] = BaseStore['default'].extend({

        /**
         * Override base method
         * get stored object... if dataType is available initialize data for the DS object.
         *
         * Tnis method using both IPO data list request and IPO data object request
         * @param requestType  this can be exchange name or All type  or IPO ID
         * @param language
         * @param dataType optional
         * @param params
         * @param successFn functions to run on success data loading
         * @param errorFn   functions to run on error
         * @param isCachedData [optional]
         * @returns {*}
         */

        getStoredObject: function getStoredObject(requestType, language, dataType, successFn, errorFn, isCachedData, params) {

            var key = this.getKey([requestType, language, dataType]);
            var cachedObj = this.dataStore[key];

            // the function to call after a success data retrieved
            var postSuccess = function postSuccess() {
                cachedObj.metaObj[dataType].isAvailable = true;
                cachedObj.metaObj[dataType].isRequested = false;
                var successFns = cachedObj.metaObj[dataType].successFns;

                Ember['default'].$.each(successFns, function (index, sFn) {
                    if (Ember['default'].$.isFunction(sFn)) {
                        sFn();
                    }
                });
                cachedObj.metaObj[dataType].successFns = [];
            };

            var error = function error() {
                cachedObj.metaObj[dataType].isAvailable = false;
                cachedObj.metaObj[dataType].isRequested = false;
                var errorFns = cachedObj.metaObj[dataType].errorFns;

                Ember['default'].$.each(errorFns, function (index, eFn) {
                    if (Ember['default'].$.isFunction(eFn)) {
                        eFn();
                    }
                });
                cachedObj.metaObj[dataType].errorFns = [];
            };

            if (!cachedObj || isCachedData) {
                cachedObj = this.createStoreCacheObj(requestType, language, dataType);
            }

            if (dataType === undefined) {
                // no data type defined. Assume no data initialization is needed.
                return cachedObj.dataObj;
            }

            if (cachedObj.metaObj[dataType].isAvailable) {
                if (Ember['default'].$.isFunction(successFn)) {
                    successFn();
                }
            } else {
                // data not available state
                this.addCallbackFunctions(cachedObj, dataType, successFn, errorFn);

                if (!cachedObj.metaObj[dataType].isRequested) {
                    // data not available and already NO earlier request is processing
                    cachedObj.metaObj[dataType].isRequested = true;
                    switch (dataType) {
                        case priceConstants['default'].DataTypes.IpoDataList.IpoById:
                        case priceConstants['default'].DataTypes.IpoDataList.IPOData:
                            priceService['default'].sendIPODataRequest(requestType, language, dataType, params, postSuccess, error);
                            break;
                        case priceConstants['default'].DataTypes.IpoDataList.IPOCompList:
                        case priceConstants['default'].DataTypes.IpoDataList.IPOIndustryList:
                            priceService['default'].sendIpoIndustryOrCompanyListRequest(requestType, language, dataType, postSuccess, error);
                            break;
                        default:
                            utils['default'].logger.logError('Unsupported ipo-data-store Data Type: ' + dataType);
                            break;
                    }
                }
            }

            return cachedObj.dataObj;
        },

        createStoreCacheObj: function createStoreCacheObj(requestType, language, dataType) {
            var key = this.getKey([requestType, language, dataType]);
            var storeCacheObj = {
                dataObj: {},
                metaObj: {}
            };

            storeCacheObj.dataObj = ipoData['default'].create({});

            var ipoDataTypes = priceConstants['default'].DataTypes.IpoDataList;
            this.initMetaData(storeCacheObj, ipoDataTypes);
            this.dataStore[key] = storeCacheObj;
            return storeCacheObj;
        },

        createIPOData: function createIPOData() {
            return ipoObj['default'].create();
        },
        createIPOStatus: function createIPOStatus() {
            return ipoStatus['default'].create();
        },
        createCompanyList: function createCompanyList() {
            return companyList['default'].create();
        },
        createIndustryList: function createIndustryList() {
            return industryObj['default'].create();
        }

    }).create();

});