define('ir-app/controllers/exchange-container-controller', ['exports', 'ember', 'ir-app/controllers/base-widget-container', 'ir-app/models/shared/shared-service'], function (exports, Ember, BaseWidgetContainer, sharedService) {

    'use strict';

    exports['default'] = BaseWidgetContainer['default'].extend({

        isDropdownClicked: false,
        showDropDown: false,
        keyList: null,

        onLoadContainer: function onLoadContainer(args, tabList, menuContent) {
            this._super();
            this.set('tabList', tabList);
            this.set('showTabs', menuContent.tab.length > 1);
            this.set('showTitle', menuContent.widgetTitle.length > 0);

            this.set('keyList', args.dropDownKeys);
            this.set('selectedKey', args.exg);
            if (this.get('keyList') !== undefined && this.get('keyList').length > 0) {
                this.set('showDropDown', true);
            }
        },

        getContainerArgs: function getContainerArgs(menuContent) {
            var containerArgs = this._super(menuContent); // Call the getContainerArgs() function in base controller
            var userExg = sharedService['default'].userSettings.currentExchange;

            if (!this.utils.validators.isAvailable(containerArgs.exg) && this.utils.validators.isAvailable(userExg)) {
                containerArgs.exg = userExg;
            }
            containerArgs.lan = sharedService['default'].userSettings.currentLanguage.toLowerCase();
            containerArgs.compId = sharedService['default'].userSettings.currentCompId;
            return containerArgs;
        },

        onExchangeChanged: (function () {

            var selectedExg = this.get('selectedKey');
            if (this.controllers) {
                Ember['default'].$.each(this.controllers, function (key, controller) {
                    if (controller) {
                        controller.onWidgetKeysChange({
                            exg: selectedExg
                        });
                    }
                });
            }
        }).observes('selectedKey')
    });

});