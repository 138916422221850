define('ir-app/models/price/price-business-entities/country-definitions', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({

        countryCode: '',
        countryDesc: '',

        setData: function setData(countryDefData) {
            var that = this;

            Ember['default'].$.each(countryDefData, function (key, value) {
                that.set(key, value);
            });
        }
    });

});