define('ir-app/models/price/price-initializers/price-data-module-initializer', ['exports', 'ir-app/models/shared/shared-initializers/base-module-initializer', 'ir-app/models/price/price-initializers/meta-data-loader', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/utils/utils', 'ir-app/config/price-settings', 'ir-app/config/price-widget-config', 'ir-app/config/extended-settings'], function (exports, BaseModuleInitializer, metaDataLoader, priceService, sharedService, utils, priceSettings, priceWidgetConfig, extendedSettings) {

    'use strict';

    exports['default'] = BaseModuleInitializer['default'].extend({
        authController: undefined,

        preInitialize: function preInitialize() {
            utils['default'].configHelper.mergeConfigSettings(priceSettings['default'], extendedSettings['default'].priceSettings);
            utils['default'].configHelper.mergeConfigSettings(priceWidgetConfig['default'], extendedSettings['default'].priceWidgetConfig);

            sharedService['default'].registerService('priceService', priceService['default']);

            this._loadMetaVersion();
        },

        postInitialize: function postInitialize(application) {
            metaDataLoader['default'].loadMetaData(application);
        },

        _loadMetaVersion: function _loadMetaVersion() {
            // Load data as fresh, if version not available in local storage
            var authVersionMeta = priceService['default'].userDS.metaVersion;

            if (!utils['default'].validators.isAvailable(authVersionMeta) || isNaN(authVersionMeta)) {
                authVersionMeta = 1;
            }

            priceService['default'].userDS.metaVersion = authVersionMeta;
        }
    }).create();

});