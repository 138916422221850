define('ir-app/controllers/shared/initializer/theme-initializer', ['exports', 'ember', 'ir-app/models/shared/shared-service', 'ir-app/config/user-settings', 'ir-app/utils/utils', 'ir-app/models/shared/shared-data-stores/theme-data-store'], function (exports, Ember, sharedService, userSettings, utils, themeDataStore) {

    'use strict';

    exports['default'] = (function () {
        var prepareThemeView = function prepareThemeView() {
            themeDataStore['default'].setOrientationClass(sharedService['default'].userSettings.get('currentLanguage'));
            themeDataStore['default'].setThemeClass(_getUserTheme());

            Ember['default'].$('input:radio[name=color]').val([sharedService['default'].userSettings.get('currentTheme')]);

            _bindEvents();
        };

        /* *
         * Gets last saved user theme or default theme if not available
         * @returns {*} User theme
         * @private
         */
        var _getUserTheme = function _getUserTheme() {
            var userTheme = sharedService['default'].userSettings.get('currentTheme');
            return utils['default'].validators.isAvailable(userTheme) ? userTheme : userSettings['default'].customisation.defaultTheme;
        };

        var _bindEvents = function _bindEvents() {
            Ember['default'].$('input[type="radio"][name="color"]').bind('change', function () {
                themeDataStore['default'].setThemeClass(this.value);
            });

            Ember['default'].$('button#btnTheme').bind('click', function () {
                var theme = Ember['default'].$('input[type="radio"][name="color"]:checked').val();

                sharedService['default'].userSettings.set('currentTheme', theme);
                sharedService['default'].userSettings.save();

                Ember['default'].$('div#divTheme').hide(); // Hide theme selection page
            });
        };

        return {
            prepareThemeView: prepareThemeView
        };
    })();

});