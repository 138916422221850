define('ir-app/templates/ir-widgets/index-overview-widgets/top-bar-index-quote', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col-xs-12 col-sm-3 col-lg-3 margin-medium-bottom mini-chart");
            var el2 = dom.createTextNode("\n\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"id","chartView");
            dom.setAttribute(el2,"class","col-xs-12 chart-widget-mini");
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("<svg class=\"chart-gradient\" width=\"0\" height=\"0\"> // To-do - Chart UI from Sass\n                            <defs>\n                                <linearGradient id=\"linear\" x2=\"0\" y2=\"100%\">\n                                    <stop offset=\"0%\" class=\"upper\"></stop>\n                                    <stop offset=\"100%\" class=\"lower\"></stop>\n                                </linearGradient>\n                            </defs>\n                        </svg>");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","col-xs-12 margin-medium-top");
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","display-table full-width");
            var el4 = dom.createTextNode("\n                            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"id","oneYear");
            dom.setAttribute(el4,"class","col-xs-3 display-table-cell vertical-bottom float-none text-align-left no-padding");
            var el5 = dom.createTextNode("\n                                1Y\n                            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"id","changeY");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"id","pctChangeY");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, inline = hooks.inline, get = hooks.get, subexpr = hooks.subexpr, concat = hooks.concat, attribute = hooks.attribute;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element11 = dom.childAt(fragment, [1]);
            var element12 = dom.childAt(element11, [3, 1]);
            var element13 = dom.childAt(element12, [3]);
            var element14 = dom.childAt(element12, [5]);
            var morph0 = dom.createMorphAt(dom.childAt(element11, [1]),3,3);
            var morph1 = dom.createMorphAt(element13,0,0);
            var attrMorph0 = dom.createAttrMorph(element13, 'class');
            var morph2 = dom.createMorphAt(element14,0,0);
            var attrMorph1 = dom.createAttrMorph(element14, 'class');
            inline(env, morph0, context, "outlet", ["chartView"], {});
            attribute(env, attrMorph0, element13, "class", concat(env, ["col-xs-4 display-table-cell vertical-bottom float-none text-align-right dir-ltr ", subexpr(env, context, "getUpDownStyle", [get(env, context, "stock.chgY"), "green up", "red down", ""], {})]));
            inline(env, morph1, context, "formatNumber", [get(env, context, "stock.chgY"), get(env, context, "stock.deci")], {});
            attribute(env, attrMorph1, element14, "class", concat(env, ["dir-ltr col-xs-5 display-table-cell vertical-bottom float-none text-align-right no-padding ", subexpr(env, context, "getUpDownStyle", [get(env, context, "stock.chgY"), "green", "red", ""], {})]));
            inline(env, morph2, context, "formatNumberPercentage", [get(env, context, "stock.pctChgY")], {});
            return fragment;
          }
        };
      }());
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","top-bar-href");
          dom.setAttribute(el1,"target","_parent");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","mkt-snap-wrapper full-width margin-large-bottom");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","row");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col-xs-12 col-sm-3 col-lg-3");
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","col-xs-12 col-sm-12");
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("h1");
          dom.setAttribute(el6,"id","lDes");
          dom.setAttribute(el6,"class","snap-company-name text-align-left margin-small-bottom");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","col-xs-12 col-sm-12");
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","snap-note text-align-left margin-small-bottom");
          var el7 = dom.createTextNode("\n                            ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          dom.setAttribute(el7,"id","lastUpdatedLabel");
          dom.setAttribute(el7,"class","display-block left");
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode(" :");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                            ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          dom.setAttribute(el7,"id","lastUpdate");
          dom.setAttribute(el7,"class","display-block left");
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col-xs-12 col-sm-6 col-lg-6 margin-medium-bottom");
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","row");
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","col-xs-12 full-width display-table");
          var el7 = dom.createTextNode("\n                            ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          dom.setAttribute(el7,"class","col-xs-12 col-sm-4 col-lg-4 no-padding display-table");
          var el8 = dom.createTextNode("\n                                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("div");
          dom.setAttribute(el8,"class","col-xs-4 display-table-cell float-none vertical-bottom fig-row text-align-center no-padding");
          var el9 = dom.createTextNode("\n                                    ");
          dom.appendChild(el8, el9);
          var el9 = dom.createElement("span");
          dom.setAttribute(el9,"id","adjustedLtp");
          dom.setAttribute(el9,"class","snap-current-price");
          var el10 = dom.createComment("");
          dom.appendChild(el9, el10);
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode("\n                                ");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                            ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                            ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          dom.setAttribute(el7,"class","col-xs-12 col-sm-8 col-lg-8 display-table");
          var el8 = dom.createTextNode("\n                                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("div");
          dom.setAttribute(el8,"class","col-xs-4 display-table-cell float-none vertical-bottom fig-row text-align-center no-padding");
          var el9 = dom.createTextNode("\n                                    ");
          dom.appendChild(el8, el9);
          var el9 = dom.createElement("span");
          dom.setAttribute(el9,"id","change");
          var el10 = dom.createComment("");
          dom.appendChild(el9, el10);
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode("\n                                ");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("div");
          dom.setAttribute(el8,"class","col-xs-4 display-table-cell float-none vertical-bottom fig-row dir-ltr text-align-center no-padding");
          var el9 = dom.createTextNode("\n                                    ");
          dom.appendChild(el8, el9);
          var el9 = dom.createElement("span");
          dom.setAttribute(el9,"id","pctChange");
          var el10 = dom.createComment("");
          dom.appendChild(el9, el10);
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode("\n                                ");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                            ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, subexpr = hooks.subexpr, concat = hooks.concat, attribute = hooks.attribute, content = hooks.content, inline = hooks.inline, block = hooks.block;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element15 = dom.childAt(fragment, [1]);
          var element16 = dom.childAt(element15, [1, 1]);
          var element17 = dom.childAt(element16, [1]);
          var element18 = dom.childAt(element17, [3, 1]);
          var element19 = dom.childAt(element16, [3, 1, 1]);
          var element20 = dom.childAt(element19, [3]);
          var element21 = dom.childAt(element20, [1, 1]);
          var element22 = dom.childAt(element20, [3, 1]);
          var attrMorph0 = dom.createAttrMorph(element15, 'href');
          var morph0 = dom.createMorphAt(dom.childAt(element17, [1, 1]),0,0);
          var morph1 = dom.createMorphAt(dom.childAt(element18, [1]),0,0);
          var morph2 = dom.createMorphAt(dom.childAt(element18, [3]),0,0);
          var morph3 = dom.createMorphAt(dom.childAt(element19, [1, 1, 1]),0,0);
          var morph4 = dom.createMorphAt(element21,0,0);
          var attrMorph1 = dom.createAttrMorph(element21, 'class');
          var morph5 = dom.createMorphAt(element22,0,0);
          var attrMorph2 = dom.createAttrMorph(element22, 'class');
          var morph6 = dom.createMorphAt(element16,5,5);
          attribute(env, attrMorph0, element15, "href", concat(env, [subexpr(env, context, "applyPlaceHolders", [get(env, context, "clientIndexPage"), get(env, context, "stock.exg"), get(env, context, "stock.sym"), get(env, context, "lang")], {})]));
          content(env, morph0, context, "stock.lDes");
          content(env, morph1, context, "app.lang.labels.lastUpdated");
          content(env, morph2, context, "lastUpdate");
          inline(env, morph3, context, "formatNumber", [get(env, context, "stock.adjustedLtp"), get(env, context, "stock.deci")], {});
          attribute(env, attrMorph1, element21, "class", concat(env, ["float-none no-margin snap-change text-nowrap ", subexpr(env, context, "getUpDownStyle", [get(env, context, "stock.chg"), "green up", "red down", ""], {})]));
          inline(env, morph4, context, "formatNumber", [get(env, context, "stock.chg"), get(env, context, "stock.deci")], {});
          attribute(env, attrMorph2, element22, "class", concat(env, ["snap-percentange ", subexpr(env, context, "getUpDownStyle", [get(env, context, "stock.pctChg"), "green", "red", ""], {})]));
          inline(env, morph5, context, "formatNumberPercentage", [get(env, context, "stock.pctChg")], {});
          block(env, morph6, context, "unless", [get(env, context, "isChartDisabled")], {}, child0, null);
          return fragment;
        }
      };
    }());
    var child1 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","mkt-snap-wrapper full-width margin-large-bottom");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","row");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col-xs-12 col-sm-3 col-lg-3");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col-xs-12 col-sm-12");
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("h1");
          dom.setAttribute(el5,"class","snap-company-name text-align-left margin-small-bottom");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col-xs-12 col-sm-12");
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","snap-note text-align-left margin-small-bottom");
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"id","lastUpdatedLabel");
          dom.setAttribute(el6,"class","display-block left");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" :");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"id","lastUpdate");
          dom.setAttribute(el6,"class","display-block left");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col-xs-12 col-sm-6 col-lg-6 margin-medium-bottom");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","row");
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","col-xs-12 full-width display-table");
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","col-xs-12 col-sm-4 col-lg-4 no-padding display-table");
          var el7 = dom.createTextNode("\n                            ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          dom.setAttribute(el7,"class","col-xs-4 display-table-cell float-none vertical-bottom fig-row text-align-center no-padding");
          var el8 = dom.createTextNode("\n                                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("span");
          dom.setAttribute(el8,"id","adjustedLtp");
          dom.setAttribute(el8,"class","snap-current-price");
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                            ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","col-xs-12 col-sm-8 col-lg-8 display-table");
          var el7 = dom.createTextNode("\n                            ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          dom.setAttribute(el7,"class","col-xs-4 display-table-cell float-none vertical-bottom fig-row text-align-center no-padding");
          var el8 = dom.createTextNode("\n                                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("span");
          dom.setAttribute(el8,"id","chgange");
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                            ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                            ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          dom.setAttribute(el7,"class","col-xs-4 display-table-cell float-none vertical-bottom fig-row dir-ltr text-align-center no-padding");
          var el8 = dom.createTextNode("\n                                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("span");
          dom.setAttribute(el8,"id","pctChange");
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                            ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col-xs-12 col-sm-3 col-lg-3 margin-medium-bottom mini-chart");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"id","chartView");
          dom.setAttribute(el4,"class","col-xs-12 chart-widget-mini");
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col-xs-12 margin-medium-top");
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","display-table full-width");
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"id","oneYear");
          dom.setAttribute(el6,"class","col-xs-3 display-table-cell vertical-bottom float-none text-align-left no-paddi\n                            ng\n                        ");
          var el7 = dom.createTextNode("1Y");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"id","changeY");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"id","pctChangeY");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, content = hooks.content, get = hooks.get, inline = hooks.inline, subexpr = hooks.subexpr, concat = hooks.concat, attribute = hooks.attribute;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element0 = dom.childAt(fragment, [1, 1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element1, [3, 1]);
          var element3 = dom.childAt(element0, [3, 1, 1]);
          var element4 = dom.childAt(element3, [3]);
          var element5 = dom.childAt(element4, [1, 1]);
          var element6 = dom.childAt(element4, [3, 1]);
          var element7 = dom.childAt(element0, [5]);
          var element8 = dom.childAt(element7, [3, 1]);
          var element9 = dom.childAt(element8, [3]);
          var element10 = dom.childAt(element8, [5]);
          var morph0 = dom.createMorphAt(dom.childAt(element1, [1, 1]),0,0);
          var morph1 = dom.createMorphAt(dom.childAt(element2, [1]),0,0);
          var morph2 = dom.createMorphAt(dom.childAt(element2, [3]),0,0);
          var morph3 = dom.createMorphAt(dom.childAt(element3, [1, 1, 1]),0,0);
          var morph4 = dom.createMorphAt(element5,0,0);
          var attrMorph0 = dom.createAttrMorph(element5, 'class');
          var morph5 = dom.createMorphAt(element6,0,0);
          var attrMorph1 = dom.createAttrMorph(element6, 'class');
          var morph6 = dom.createMorphAt(dom.childAt(element7, [1]),1,1);
          var morph7 = dom.createMorphAt(element9,0,0);
          var attrMorph2 = dom.createAttrMorph(element9, 'class');
          var morph8 = dom.createMorphAt(element10,0,0);
          var attrMorph3 = dom.createAttrMorph(element10, 'class');
          content(env, morph0, context, "stock.lDes");
          content(env, morph1, context, "app.lang.labels.lastUpdated");
          content(env, morph2, context, "lastUpdate");
          inline(env, morph3, context, "formatNumber", [get(env, context, "stock.adjustedLtp"), get(env, context, "stock.deci")], {});
          attribute(env, attrMorph0, element5, "class", concat(env, ["float-none no-margin snap-change text-nowrap ", subexpr(env, context, "getUpDownStyle", [get(env, context, "stock.chg"), "green up", "red down", ""], {})]));
          inline(env, morph4, context, "formatNumber", [get(env, context, "stock.chg"), get(env, context, "stock.deci")], {});
          attribute(env, attrMorph1, element6, "class", concat(env, ["snap-percentange ", subexpr(env, context, "getUpDownStyle", [get(env, context, "stock.pctChg"), "green", "red", ""], {})]));
          inline(env, morph5, context, "formatNumberPercentage", [get(env, context, "stock.pctChg")], {});
          inline(env, morph6, context, "outlet", ["chartView"], {});
          attribute(env, attrMorph2, element9, "class", concat(env, ["col-xs-4 display-table-cell vertical-bottom float-none text-align-right dir-ltr ", subexpr(env, context, "getUpDownStyle", [get(env, context, "stock.chgY"), "green up", "red down", ""], {})]));
          inline(env, morph7, context, "formatNumber", [get(env, context, "stock.chgY"), get(env, context, "stock.deci")], {});
          attribute(env, attrMorph3, element10, "class", concat(env, ["dir-ltr col-xs-5 display-table-cell vertical-bottom float-none text-align-right no-padding ", subexpr(env, context, "getUpDownStyle", [get(env, context, "stock.chgY"), "green", "red", ""], {})]));
          inline(env, morph8, context, "formatNumberPercentage", [get(env, context, "stock.pctChgY")], {});
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var morph0 = dom.createMorphAt(fragment,1,1,contextualElement);
        dom.insertBoundary(fragment, null);
        block(env, morph0, context, "if", [get(env, context, "isWidgetClickable")], {}, child0, child1);
        return fragment;
      }
    };
  }()));

});