define('ir-app/models/price/price-business-entities/currency', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    /**
     * Created by asankah on 4/6/2016.
     */
    exports['default'] = Ember['default'].Object.extend({
        pairCode: '', // Currency pair code ie. AEDUSD
        rate: '', // exchange rate

        setData: function setData(currencyData) {
            var that = this;

            Ember['default'].$.each(currencyData, function (key, value) {
                that.set(key, value);
            });
        }
    });

});