define('ir-app/models/price/price-business-entities/price-user', ['exports', 'ember', 'ir-app/models/shared/shared-business-entities/persistent-object'], function (exports, Ember, PersistentObject) {

    'use strict';

    exports['default'] = PersistentObject['default'].extend({
        cacheKey: 'priceUser',

        // Auth related params
        sessionId: 'IRPORTAL',
        authStatus: 0,
        userId: 'IRPORTAL',
        username: '',
        userExchg: '',
        newsProviders: '',
        expiryDate: '',
        windowTypes: '',
        name: '',
        expiredExchg: '',
        inactiveExchg: '',
        metaVersion: '',

        authResponseMapping: {
            'SID': 'sessionId',
            'AUTHSTAT': 'authStatus',
            'UID': 'userId',
            'UNM': 'username',
            'UE': 'userExchg',
            'NWSP': 'newsProviders',
            'EXPDATE': 'expiryDate',
            'WT': 'windowTypes',
            'NAME': 'name',
            'EXPEXG': 'expiredExchg',
            'INACEXG': 'inactiveExchg',
            'METAVER': 'metaVersion'
        },

        setData: function setData(userParams) {
            var that = this;

            Ember['default'].$.each(userParams, function (key, value) {
                var prop = that.authResponseMapping[key];

                if (prop) {
                    that.set(prop, value);
                }
            });
        }
    }).create();

});