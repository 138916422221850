define('ir-app/controllers/ir-mobile/company-widgets/company-info', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/models/price/price-constants', 'ir-app/models/price/price-business-entities/panel-field'], function (exports, Ember, BaseController, priceService, sharedService, priceConstants, panelField) {

    'use strict';

    exports['default'] = BaseController['default'].extend({

        basicInfo: {},
        showContacts: false,
        showBasicInfo: false,
        showLogoAndName: true,
        isListed: false,
        lanEn: true,
        // Default set of fields. This can be overriden via the config
        displayFields: [{ lanKey: 'estbOn', dataField: 'estbOn', formatter: 'D' }, { lanKey: 'listDate', dataField: 'stkLstOn', formatter: 'D' }, { lanKey: 'finYrEnd', dataField: 'finYearEnd', formatter: 'TMS' }, { lanKey: 'auditor', dataField: 'auditor', formatter: 'S' }],
        valueFields: Ember['default'].A(),

        onLoadWidget: function onLoadWidget() {
            this.setErrorMessage();
            this.set('displayFields', this.get('displayFields'));
            this.set('valueFields', Ember['default'].A());
        },

        onPrepareData: function onPrepareData() {
            var that = this;
            that.set('showContacts', this.get('keys').showContacts);
            that.set('showBasicInfo', this.get('keys').showBasicInfo);
            that.startLoadingProgress();
            var basicInfo;
            if (this.utils.validators.isAvailable(this.get('companyId'))) {
                basicInfo = priceService['default'].companyProfileDS.getStoredObjectByCompanyId(this.get('companyId'), sharedService['default'].userSettings.currentLanguage, function () {
                    var _basicInfo = priceService['default'].companyProfileDS.getStoredObjectByCompanyId(that.get('companyId'), sharedService['default'].userSettings.currentLanguage);
                    if (_basicInfo && _basicInfo.listingStatus && (_basicInfo.listingStatus === priceConstants['default'].CompanyListingStatus.SingleListed || _basicInfo.listingStatus === priceConstants['default'].CompanyListingStatus.MultiListed)) {
                        that.set('isListed', true);
                    } else {
                        that.set('isListed', false);
                    }
                    that.onDataSuccess();
                }, function () {
                    that.onDataError();
                }, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyBasicInfo);
            } else {
                this.set('isListed', true);
                basicInfo = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, function () {
                    that.onDataSuccess();
                }, function () {
                    that.onDataError();
                }, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyBasicInfo);
            }

            this.set('basicInfo', basicInfo);
            if (sharedService['default'].userSettings.currentLanguage === 'EN') {
                that.set('lanEn', true);
            } else {
                that.set('lanEn', false);
            }
            that.renderValueFields();
        },
        renderValueFields: function renderValueFields() {
            try {
                var companyObj = this.get('basicInfo');
                if (!this.get('isListed') && this.utils.validators.isAvailable(this.get('unlistedDisplayFields'))) {
                    this.set('displayFields', this.get('unlistedDisplayFields'));
                }
                var fields = this.get('displayFields');
                var valueFieldsRef = this.get('valueFields');

                var lanStore = this.get('app').lang.labels;
                var that = this;

                if (fields && valueFieldsRef) {
                    Ember['default'].$.each(fields, function (key, value) {
                        var fieldObj = panelField['default'].create({
                            lanKey: lanStore[value.lanKey] ? lanStore[value.lanKey] : value.lanKey
                        });

                        fieldObj.valueObj = companyObj;
                        fieldObj.value = companyObj.get(value.dataField);
                        fieldObj.field = value.dataField;
                        if (value.decimalPlaces) {
                            fieldObj.numOfDeci = value.decimalPlaces;
                        } else {
                            fieldObj.numOfDeci = companyObj.get('deci');
                        }

                        switch (value.formatter) {
                            case that.utils.Constants.DataFormatter.Currency:
                                fieldObj.isCurrency = true;
                                break;
                            case that.utils.Constants.DataFormatter.Long:
                                fieldObj.isLong = true;
                                break;
                            case that.utils.Constants.DataFormatter.Integer:
                                fieldObj.isInteger = true;
                                break;
                            case that.utils.Constants.DataFormatter.String:
                                fieldObj.isString = true;
                                break;
                            case that.utils.Constants.DataFormatter.Date:
                                fieldObj.isDate = true;
                                break;
                            case that.utils.Constants.DataFormatter.Percentage:
                                fieldObj.isPercentage = true;
                                break;
                            case that.utils.Constants.DataFormatter.DivideNumber:
                                fieldObj.isDivideNumber = true;
                                break;
                            case that.utils.Constants.DataFormatter.Array:
                                fieldObj.isArray = true;
                                break;
                            case that.utils.Constants.DataFormatter.ToMonthString:
                                fieldObj.isToMonthString = true;
                                break;
                        }

                        valueFieldsRef.pushObject(fieldObj);
                        Ember['default'].addObserver(companyObj, value.dataField, fieldObj, fieldObj.notifyChange);
                    });
                }
            } catch (x) {
                this.utils.logger.logError('Error in rendering company_info panel fields : ' + x);
            }
        },

        onCheckDataAvailability: function onCheckDataAvailability() {
            var companyProfObj;
            if (this.utils.validators.isAvailable(this.get('companyId'))) {
                companyProfObj = priceService['default'].companyProfileDS.getStoredObjectByCompanyId(this.get('companyId'), sharedService['default'].userSettings.currentLanguage);
            } else {
                companyProfObj = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage);
            }
            if (companyProfObj) {
                return this.utils.validators.isAvailable(companyProfObj.compID);
            }
            return false;
        },

        onLanguageChanged: function onLanguageChanged() {
            this.onPrepareData();
        },

        image: (function () {
            if (this.utils.validators.isAvailable(this.get('basicInfo.logo'))) {
                return this.utils.imageHelper.getImage(this.get('basicInfo.logo'));
            }
        }).property('basicInfo.logo'),

        formattedAddress: (function () {
            var str = this.get('basicInfo.addr');

            if (this.utils.validators.isAvailable(str)) {
                return str.split(', '); // TODO: [Thilina] need to use trim
            }
        }).property('basicInfo.addr'),

        isEmailAvailable: (function () {
            return this.utils.validators.isAvailable(this.get('basicInfo.email'));
        }).property('basicInfo.email'),

        isWebAvailable: (function () {
            return this.utils.validators.isAvailable(this.get('basicInfo.web'));
        }).property('basicInfo.web')

    });

});