define('ir-app/models/price/price-data-stores/file-data-store', ['exports', 'ember', 'ir-app/utils/utils', 'ir-app/models/price/price-service'], function (exports, Ember, utils, priceService) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        store: {},

        getFileMetaItemArray: function getFileMetaItemArray(exchange, symbol, scat) {
            var key = utils['default'].keyGenerator.getKey(exchange, symbol);
            var currentStore = this.get('store');
            var filesMapByKey = currentStore[key];
            var filesMapByScat = null;

            if (!filesMapByKey) {
                filesMapByScat = Ember['default'].A();
                filesMapByKey = {};
                filesMapByKey[scat] = filesMapByScat;
                currentStore[key] = filesMapByKey;
            } else {
                filesMapByScat = filesMapByKey[scat];
                if (!filesMapByScat) {
                    filesMapByScat = Ember['default'].A();
                    filesMapByKey[scat] = filesMapByScat;
                }
            }

            return filesMapByKey[scat];
        },

        getFilesCollection: function getFilesCollection(exg, sym, cid, tickerSerial, scat, historyYears, lang, reqSuccessFn, reqFailureFn) {
            if (!utils['default'].validators.isAvailable(exg) || !utils['default'].validators.isAvailable(sym) || !utils['default'].validators.isAvailable(cid) || !utils['default'].validators.isAvailable(tickerSerial)) {
                return;
            }

            var key = utils['default'].keyGenerator.getKey(exg, sym);
            var currentStore = this.get('store');
            var filesMapByKey = currentStore[key];
            var filesMapByScat = null;
            var isDataReqNeeded = false;

            if (!filesMapByKey) {
                filesMapByKey = {};
                filesMapByKey[scat] = Ember['default'].A();
                currentStore[key] = filesMapByKey;
                isDataReqNeeded = true;
            } else {
                filesMapByScat = filesMapByKey[scat];
                if (!filesMapByScat) {
                    filesMapByScat = Ember['default'].A();
                    filesMapByKey[scat] = filesMapByScat;
                    isDataReqNeeded = true;
                }

                if (filesMapByScat.length === 0) {
                    isDataReqNeeded = true;
                }
            }

            if (isDataReqNeeded) {
                priceService['default'].sendFileSearchRequest({
                    cid: cid,
                    tsr: tickerSerial,
                    exg: exg,
                    sym: sym,
                    scat: scat,
                    history: historyYears,
                    lang: lang,
                    success: reqSuccessFn,
                    failure: reqFailureFn
                });
            }

            return filesMapByKey[scat];
        }
    }).create();

});