define('ir-app/models/price/price-business-entities/company-subsidiaries', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        subsiName: '', // Name
        subsiSherPrs: '', // Share parentage
        type: '', // type
        prsNa: '', //Percentage not applicable
        compId: '', //Subsidiary Company Id

        setData: function setData(companySubsidiariesData) {
            var that = this;

            Ember['default'].$.each(companySubsidiariesData, function (key, value) {
                that.set(key, value);
            });
        }
    });

});