define('ir-app/models/shared/shared-initializers/base-module-initializer', ['exports', 'ember', 'ir-app/config/app-config'], function (exports, Ember, appConfig) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend({
        authModes: {
            priceRetail: 1,
            priceSso: 2,
            priceSsoTradeSso: 3,
            tradeRetailPriceSso: 4,
            tradeSsoPriceSso: 5,
            freeUser: 6
        },

        loginStatus: {
            notLoggedIn: 1,
            prevLoggedIn: 2,
            loggedIn: 3
        },

        authTypes: {
            retail: 1,
            sso: 2
        },

        preInitialize: function preInitialize() {
            // Specific initializer should implement this method to provide specific functionality
            // Otherwise base function will be executed
        },

        postInitialize: function postInitialize() {
            // Specific initializer should implement this method to provide specific functionality
            // Otherwise base function will be executed
        },

        setAuthType: function setAuthType() {
            var authType;

            switch (appConfig['default'].customisation.authenticationMode) {
                case this.authModes.priceRetail:
                case this.authModes.freeUser:
                    authType = this.authTypes.retail;
                    break;

                case this.authModes.priceSso:
                case this.authModes.priceSsoTradeSso:
                    authType = this.authTypes.sso;
                    break;

                default:
                    break;
            }

            appConfig['default'].customisation.authType = authType;
        }
    });

});