define('ir-app/config/extended-settings', ['exports', 'ir-app/models/chart/chart-constants'], function (exports, ChartConstants) {

    'use strict';

    exports['default'] = {
        appConfig: {
            customisation: {
                userName: 'AMILA.UNI',
                password: '123456',

                dcmsUser: '132330',
                dcmsSessionId: 'BD584E3E-DB9B-9ECF-E040-007F01005D49',
                // list of currency codes for the invest calculator
                curList: ['USD']
            }
        },

        userSettings: {
            clients: {
                mouwasat: {
                    customisation: {
                        defaultTheme: 'theme2',
                        showFooterNote: false
                    },

                    displayFormat: {
                        dateFormat: 'DD-MM-YYYY'
                    },

                    chartDefaults: {
                        chartCategory: ChartConstants['default'].ChartCategory.Intraday,
                        initialChartCategory: ChartConstants['default'].ChartCategory.Intraday,
                        chartDataLevel: ChartConstants['default'].ChartViewPeriod.OneDay.ChartDataLevel,
                        chartViewPeriod: ChartConstants['default'].ChartViewPeriod.OneDay,
                        chartInterval: ChartConstants['default'].ChartViewInterval.EveryMinutes,
                        chartYAxisToRight: false,
                        volumeViewEnabled: false,
                        indicatorYAxis: {
                            labels: {
                                enabled: true,
                                x: 20
                            },
                            opposite: false
                        }
                    },

                    chartTheme: {
                        lightThemeName: 'theme2',
                        lightThemeObj: {
                            colors: {
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                gradientUpperColor: 'rgba(33,31,95,1)',
                                areaChartBgColor: 'rgba(112,144,195,0.7)',
                                gradientLowerColor: 'rgba(199, 212, 232, 0.7)',
                                lineColor: '#211F5F',
                                volumeColor: '#236192',
                                fontColor: '#606060',
                                gridLineColor: '#ececec',
                                // candle chart
                                downColor: 'red',
                                upColor: 'green',
                                candleLineColor: 'black',
                                candleUpLineColor: 'black'
                            }
                        }
                    }
                }
            }
        }
    };

});