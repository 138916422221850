define('ir-app/controllers/ir-mobile/top-stocks-widgets/top-stocks', ['exports', 'ember', 'ir-app/models/price/price-service', 'ir-app/controllers/base-array-controller', 'ir-app/models/price/price-constants', 'ir-app/models/shared/shared-service', 'ir-app/models/chart/chart-constants'], function (exports, Ember, priceService, BaseArrayController, PriceConstants, sharedService, ChartConstants) {

    'use strict';

    exports['default'] = BaseArrayController['default'].extend({
        topStocksRowCount: 10,
        exchange: null,
        timer: undefined,
        isTurnOver: false,
        displaySubTitle: '',
        lang: undefined,

        onLoadWidget: function onLoadWidget() {
            var that = this;
            var displayKeyList = [];
            var keyVal;
            if (this.get('keyList') !== undefined && this.get('keyList').length > 0) {
                this.get('keyList').forEach(function (item) {
                    keyVal = {};
                    keyVal.exg = item.exg;
                    keyVal.displayName = that.get('app').lang.labels[item.displayName];
                    displayKeyList.push(keyVal);
                });

                this.setValueToBaseExchangeContainer('keyList', displayKeyList);
            }

            Ember['default'].run.later(function () {
                Ember['default'].$('.nano').nanoScroller();
            }, 3000);

            if (this.get('top_stock_type') === PriceConstants['default'].TopStocksTypes.MostActiveByValue) {
                this.set('isTurnOver', true);
            } else {
                this.set('isTurnOver', false);
            }

            if (this.get('subTitle')) {
                this.set('displaySubTitle', this.get('app').lang.labels[this.get('subTitle')]);
            }

            this.startLoadingProgress();
            this.setErrorMessage();
        },

        onPrepareData: function onPrepareData() {
            this.set('lang', sharedService['default'].userSettings.currentLanguage.toLowerCase());
            // Below is required as currentExchange is used for generating MIX requests.
            // TODO: [Chiran (reviewed by Amila)] Refactor such requests and allow the exhcange to be passed from the widget level
            sharedService['default'].userSettings.currentExchange = this.get('exg');
            priceService['default'].downloadExchangeProperty(this.get('exg'), sharedService['default'].userSettings.currentLanguage);

            var exgObj = priceService['default'].exchangeDS.getExchange(this.get('exg'));
            this.set('exchange', exgObj);

            this.setRequestTimeout(10, 'tgpContent.length');
            this.set('tgpContent', priceService['default'].topStockDS.getTopStocksCollectionByType(this.get('exg'), this.get('top_stock_type')));

            this.utils.analyticsService.trackEvent(this.get('gaKey'), this.utils.Constants.GAActions.show, ['exg:', this.get('exg')].join(''));
        },

        loadData: (function () {
            var exgObj = this.get('exchange');
            var subMkts = exgObj.getMainSubMkt();

            if (exgObj.isSubMktAvailable && subMkts) {
                this.loadContent(exgObj.exg, subMkts.subMktCode);
            } else {
                this.loadContent(exgObj.exg, -1);
            }
        }).observes('exchange.isSubMktAvailable'),

        onClearData: function onClearData() {
            this.set('tgpContent', []);
        },

        onUnloadWidget: function onUnloadWidget() {
            Ember['default'].run.cancel(this.get('timer'));
        },

        loadContent: function loadContent(exchange, subMarket) {
            var that = this;
            priceService['default'].sendTopStocksRequest(exchange, this.get('top_stock_type'), subMarket);

            // Update top stock data store periodically
            var timer = Ember['default'].run.later(function () {
                that.loadContent(exchange, subMarket);
            }, PriceConstants['default'].TimeIntervals.TopStocksUpdateInterval);

            this.set('timer', timer);
        },

        onCheckDataAvailability: function onCheckDataAvailability() {
            var mavStock = priceService['default'].topStockDS.getTopStocksCollectionByType(this.get('exg'), this.get('top_stock_type'));
            return mavStock.length > 0;
        },

        tgpFilteredContent: (function () {
            return this.getFilteredContent(this.get('tgpContent'));
        }).property('tgpContent.@each'),

        getFilteredContent: function getFilteredContent(content) {
            var filteredArray = [];
            var rowCount;
            if (this.get('rowCount')) {
                rowCount = this.get('rowCount');
            } else {
                rowCount = this.topStocksRowCount;
            }

            Ember['default'].$.each(content, function (index, item) {
                if (index === rowCount) {
                    return false;
                }
                filteredArray.push(item);
            });

            return filteredArray;
        },

        _renderChartView: function _renderChartView() {
            var detailViewController = 'ir-mobile/chart-widgets/basic-chart-widget';
            var controllerString = 'controller:' + detailViewController;
            var widgetController = this.container.lookupFactory(controllerString).create();

            widgetController.set('isPortalTopBarChart', true);
            widgetController.set('isToolTipChart', true);

            widgetController.set('chartHeight', 250);

            widgetController.set('sym', this.get('sym'));
            widgetController.set('exg', this.get('exg'));

            widgetController.set('chartCategory', ChartConstants['default'].ChartCategory.Intraday);
            widgetController.set('chartDataLevel', ChartConstants['default'].ChartDataLevel.IntradayCurrentDay);
            widgetController.set('chartViewPeriod', ChartConstants['default'].ChartViewPeriod.OneDay);
            widgetController.set('chartInterval', ChartConstants['default'].ChartViewInterval.EveryMinutes);

            widgetController.initializeWidget({ wn: controllerString.split('/').pop() });
            var route = this.container.lookup('route:application');
            route.render(detailViewController, {
                into: 'ir-mobile/top-stocks-widgets/top-stocks',
                outlet: 'chartView',
                controller: widgetController
            });

            widgetController.onUnloadWidget();
        },

        actions: {

            showTooltip: function showTooltip(symbol) {

                this.set('sym', symbol);
                this._renderChartView();

                var stock = priceService['default'].stockDS.getStock(this.get('exg'), this.get('sym'));
                this.set('stock', stock);

                priceService['default'].sendSymbolValidationRequest(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, undefined, undefined);
                priceService['default'].addSymbolRequest(this.get('exg'), this.get('sym'));

                var rect = Ember['default'].$('[name=' + symbol + ']').get(0).getBoundingClientRect();
                var d = document.getElementById("test");
                d.style.display = "block";

                d.style.position = "absolute";
                d.style.left = rect.right + 'px';
                d.style.top = rect.bottom + 'px';
            },

            hideTooltip: function hideTooltip() {
                document.getElementById("test").style.display = "none";
            }

        }
    });

    Ember['default'].Handlebars.helper('getPositiveNegativeStyle', function (value) {
        if (value >= 0) {
            return 'padding-medium snap-period-wrapper-green';
        } else if (value < 0) {
            return 'padding-medium snap-period-wrapper-red';
        }
    });

});