define('ir-app/controllers/ir-widgets/financial-widgets/financial-segmentation', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/shared/shared-service', 'ir-app/models/price/price-constants', 'ir-app/models/price/price-service'], function (exports, Ember, BaseController, sharedService, PriceConstant, priceService) {

  'use strict';

  exports['default'] = BaseController['default'].extend({

    segDataList: Ember['default'].A(),

    onPrepareData: function onPrepareData() {
      this.set('arrSymbolPiValues', Ember['default'].A());
      this.set('arrSymbolsPi', Ember['default'].A());
      var that = this;
      that.set('lang', sharedService['default'].userSettings.currentLanguage);
      priceService['default'].segmentationDS.getStoredObject(that.get('exg'), that.get('sym'), sharedService['default'].userSettings.currentLanguage, that.get('pageSize'), PriceConstant['default'].DataTypes.SegmentationDataStore.Segmentation, function () {

        var segData = priceService['default'].segmentationDS.getStoredObject(that.get('exg'), that.get('sym'), sharedService['default'].userSettings.currentLanguage);
        that.sortedData(segData.segmentationArr);
        var jsonObj = that.get('segDataList')[0];
        that.get('arrSymbolPiValues').push(jsonObj.Category + "" + jsonObj.Year);
        that.get('arrSymbolsPi').push('x');
        var attributes = jsonObj.Attributes;
        var piChartData = JSON.parse(attributes);

        Ember['default'].$.each(piChartData, function (key, val) {
          that.get('arrSymbolsPi').push(key);
          that.get('arrSymbolPiValues').push(val);
        });
        that.set('xDataSet', that.get('arrSymbolsPi'));
        that.set('dataSet', that.get('arrSymbolPiValues'));
      }, function () {
        that.set('segment', false);
      });
    },
    sortedData: function sortedData(json) {
      var jsnObj = json;
      var that = this;
      Ember['default'].$.each(jsnObj, function (key, segment) {
        if (segment.FactSheet === "1") {
          that.get('segDataList').push(segment);
        }
      });
    }
  });

});