define('ir-app/controllers/ir-widgets/exchange-summary/exchange-summary', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/components/stk-specific-components/portal-up-down-flat-display-bar'], function (exports, Ember, BaseController, priceService, sharedService, PortalSlider) {

    'use strict';

    exports['default'] = BaseController['default'].extend({
        exchange: {},
        index: {},
        lastUpdate: '',
        isIndexDataRequired: false,

        onPrepareData: function onPrepareData() {
            var exchange = priceService['default'].exchangeDS.getExchange(this.get('exg'));
            this.set('exchange', exchange);

            var that = this;
            priceService['default'].addExchangeRequest(this.get('exg'));
            priceService['default'].downloadExchangeProperty(this.get('exg'), sharedService['default'].userSettings.currentLanguage, function () {
                if (that.isIndexDataRequired) {
                    // Main Index's Data is Retrieved
                    if (that.get('exchange').mainIdx) {
                        var index = priceService['default'].stockDS.getStock(that.get('exg'), that.get('exchange').mainIdx);
                        that.set('index', index);
                        priceService['default'].addIndexRequest(that.get('exg'), that.get('exchange').mainIdx);
                    }
                }
            });
        },
        onClearData: function onClearData() {
            priceService['default'].removeExchangeRequest(this.get('exg'));
            if (this.isIndexDataRequired && this.get('exchange') && this.get('exchange').mainIdx) {
                priceService['default'].removeIndexRequest(this.get('exg'), this.get('exchange').mainIdx);
                this.set('index', null);
            }
            this.set('exchange', null);
        }
    });

    Ember['default'].Handlebars.helper('portal-up-down-flat-display-bar', PortalSlider['default']);

});