define('ir-app/models/price/price-business-entities/foreign-ownership', ['exports', 'ember', 'ir-app/utils/utils'], function (exports, Ember, utils) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({

        exg: '', // Exchange or news provider
        sym: '', // Symbol
        cmName: '', // Company Name
        lang: '', // Language
        lstDate: '', // Last Updated Date
        qfiMax: '', // QFI Max Limit
        qfiAct: '', // QFI Actual
        ttlMax: '', // Total Max Limit
        ttlAct: '', // Total Actual
        qfiCrntDay: '', // Qfi Last updated
        ttlCrntDay: '', // Total Last updated
        qfiCrntVal: '', // QFI Current Value
        qfiPreVal: '', // QFI Previous Value
        ttlCrntVal: '', // Total Current Value
        ttlPreVal: '', // Total Previous Value

        lstUpDate: (function () {
            var qfiDtObj = utils['default'].formatters.convertStringToDate(this.get('qfiCrntDay'));
            var ttlDtObj = utils['default'].formatters.convertStringToDate(this.get('ttlCrntDay'));

            // -1 if date2 is before date1, 0 if date1 and date2 are on same day, 1 if date2 is after date1
            if (qfiDtObj && ttlDtObj) {
                var compareInt = utils['default'].validators.compareByDate(qfiDtObj, ttlDtObj);

                if (compareInt >= 0) {
                    return utils['default'].formatters.formatToDateTime(this.get('ttlCrntDay'));
                } else {
                    return utils['default'].formatters.formatToDateTime(this.get('qfiCrntDay'));
                }
            }
        }).property('qfiCrntDay', 'ttlCrntDay'), // Last Updated Date

        qfiChange: (function () {
            var qfiPre = utils['default'].formatters.multiplyNumber(this.get('qfiPreVal'), 100, 2);
            var qfiCrnt = utils['default'].formatters.multiplyNumber(this.get('qfiCrntVal'), 100, 2);
            return Number(qfiCrnt - qfiPre) !== 0 ? qfiCrnt - qfiPre : '';
        }).property('qfiCrntVal', 'qfiPreVal'),

        ttlChange: (function () {
            var ttlPre = utils['default'].formatters.multiplyNumber(this.get('ttlPreVal'), 100, 2);
            var ttlCrnt = utils['default'].formatters.multiplyNumber(this.get('ttlCrntVal'), 100, 2);
            return Number(ttlCrnt - ttlPre) !== 0 ? ttlCrnt - ttlPre : '';
        }).property('ttlPreVal', 'ttlCrntVal'),

        setData: function setData(foreignOwner) {
            var that = this;
            Ember['default'].$.each(foreignOwner, function (key, value) {
                that.set(key, value);
            });
        }

    });

});