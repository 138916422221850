define('ir-app/utils/export-data', ['exports'], function (exports) {

    'use strict';


    exports['default'] = (function () {
        var sym = 'IR';
        var exportToExcel = function exportToExcel(tableToExport) {
            var csv = [];

            var headers = tableToExport.querySelectorAll('thead th');
            //console.log(headers)
            var row = [];
            for (var i = 0; i < headers.length; i++) {

                row.push(headers[i].innerText.replace(",", ""));
            }
            csv.push(row.join(","));

            var body = tableToExport.querySelectorAll('tbody tr');
            for (var j = 0; j < body.length; j++) {
                var col = body[j].querySelectorAll("td");
                var bodyRow = [];
                for (var k = 0; k < col.length; k++) {
                    bodyRow.push(col[k].innerText);
                }
                csv.push(bodyRow.join(","));
            }

            downloadCSV(csv.join("\n"), "text/csv", ".csv");

            return "Exporting";
        };

        var exportArrayToExcel = function exportArrayToExcel(_sym, body) {

            sym = _sym;
            downloadCSV(body.join("\n"), "text/csv;charset=utf-8", ".csv");
            // console.log(csv);
            return "Exporting";
        };

        var downloadCSV = function downloadCSV(data, type, extension) {
            console.log("downloading ...");
            var dataFile;
            var downloadLink;

            dataFile = new Blob(['﻿' + data], { type: type });
            downloadLink = document.createElement("a");
            var time = new Date().toUTCString();
            downloadLink.download = sym + "-" + time + extension;
            downloadLink.href = window.URL.createObjectURL(dataFile);
            downloadLink.style.display = "none";

            document.body.appendChild(downloadLink);

            downloadLink.click();
        };

        var exportToPng = function exportToPng(element) {
            var downloadLink;
            window.scrollTo(0, 0);
            html2canvas(element).then(function (canvas) {
                var imageData = canvas.toDataURL("image/png");
                // Now browser starts downloading it instead of just showing it
                var newData = imageData.replace("/^data:image\/png/", "data:application/octet-stream");
                downloadLink = document.createElement("a");
                var time = new Date().toUTCString();
                downloadLink.download = "IR-" + time + ".png";
                downloadLink.href = newData;
                downloadLink.style.display = "none";

                document.body.appendChild(downloadLink);

                downloadLink.click();
            });
        };

        var exportToPdf = function exportToPdf(element) {

            window.scrollTo(0, 0);
            html2canvas(element).then(function (canvas) {

                var imageData = canvas.toDataURL("image/png", 1.0);

                var pdfDoc = new jsPDF('p', 'mm');
                var height = pdfDoc.internal.pageSize.height / 2;
                var width = pdfDoc.internal.pageSize.width;

                pdfDoc.addImage(imageData, 'PNG', 0, 0, width, height);

                var time = new Date().toUTCString();
                var fileName = "IR-" + time + ".pdf";
                pdfDoc.save(fileName);
            });
        };

        return {
            exportToExcel: exportToExcel,
            exportToPng: exportToPng,
            exportToPdf: exportToPdf,
            exportArrayToExcel: exportArrayToExcel
        };
    })();

});