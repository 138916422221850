define('ir-app/controllers/ir-widgets/stock-overview-widgets/company-statistics', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/models/price/price-constants', 'ir-app/models/price/price-business-entities/panel-field'], function (exports, Ember, BaseController, priceService, sharedService, priceConstants, panelField) {

    'use strict';

    /**
     * Created by lakmaln on 5/10/2016.
     */
    exports['default'] = BaseController['default'].extend({
        stock: {},
        basicInfo: {},
        isListed: false,
        displayFields: [{ lanKey: 'outShr', dataField: 'outStdShr', formatter: 'L', type: 'CP' }, { lanKey: 'authCap', dataField: 'authCap', formatter: 'C', type: 'CP' }, { lanKey: 'paidUpCapital', dataField: 'paidCap', formatter: 'C', type: 'CP' }, { lanKey: 'freeFltShr', dataField: 'freeFltShr', formatter: 'P', type: 'CP' }, { lanKey: 'bookValuePerShare', dataField: 'bvps', formatter: 'F', suffix: ' *', type: 'CP' }, { lanKey: 'pbRatio', dataField: 'pbr', formatter: 'S', suffix: ' **', type: 'STK' }, { lanKey: 'EV/EBITDA', dataField: 'ebitda', formatter: 'F', suffix: ' *', type: 'CP' }],
        valueFields: Ember['default'].A(),

        onPrepareData: function onPrepareData() {
            var that = this;
            if (this.utils.validators.isAvailable(this.get('companyId'))) {
                priceService['default'].companyProfileDS.getStoredObjectByCompanyId(this.get('companyId'), sharedService['default'].userSettings.currentLanguage, function () {
                    var _basicInfo = priceService['default'].companyProfileDS.getStoredObjectByCompanyId(that.get('companyId'), sharedService['default'].userSettings.currentLanguage);
                    that.set('sym', _basicInfo.sym);
                    that.set('exg', _basicInfo.exg);
                    that._onPrepareData();
                }, function () {}, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyBasicInfo);
            } else {
                that._onPrepareData();
            }
        },

        _onPrepareData: function _onPrepareData() {
            priceService['default'].sendSymbolValidationRequest(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, undefined, undefined, 0);
            var stock = priceService['default'].stockDS.getStock(this.get('exg'), this.get('sym'));
            this.set('stock', stock);
            this.set('displayFields', this.get('displayFields'));
            this.set('valueFields', Ember['default'].A());

            this._loadCompanyProfile();
            priceService['default'].addSymbolRequest(this.get('exg'), this.get('sym'));
            this.renderValueFields();
        },

        onClearData: function onClearData() {
            priceService['default'].removeSymbolRequest(this.get('exg'), this.get('sym'));
        },

        onLanguageChanged: function onLanguageChanged() {
            this._loadCompanyProfile();
        },

        onCheckDataAvailability: function onCheckDataAvailability() {
            var companyProfObj;
            if (this.utils.validators.isAvailable(this.get('companyId'))) {
                companyProfObj = priceService['default'].companyProfileDS.getStoredObjectByCompanyId(this.get('companyId'), sharedService['default'].userSettings.currentLanguage);
            } else {
                companyProfObj = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage);
            }
            if (companyProfObj) {
                return this.utils.validators.isAvailable(companyProfObj.compID);
            }
            return false;
        },

        _loadCompanyProfile: function _loadCompanyProfile() {
            var that = this;
            var basicInfo;
            if (this.utils.validators.isAvailable(this.get('companyId'))) {
                basicInfo = priceService['default'].companyProfileDS.getStoredObjectByCompanyId(this.get('companyId'), sharedService['default'].userSettings.currentLanguage, function () {
                    var _basicInfo = priceService['default'].companyProfileDS.getStoredObjectByCompanyId(that.get('companyId'), sharedService['default'].userSettings.currentLanguage);
                    if (_basicInfo && _basicInfo.listingStatus && (_basicInfo.listingStatus === priceConstants['default'].CompanyListingStatus.SingleListed || _basicInfo.listingStatus === priceConstants['default'].CompanyListingStatus.MultiListed)) {
                        that.set('isListed', true);
                    } else {
                        that.set('isListed', false);
                    }
                    that.onDataSuccess();
                }, function () {
                    that.onDataError();
                }, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyBasicInfo);
            } else {
                that.set('isListed', true);
                basicInfo = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, function () {
                    that.onDataSuccess();
                }, function () {
                    that.onDataError();
                }, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyBasicInfo);
            }

            this.set('basicInfo', basicInfo);
        },

        renderValueFields: function renderValueFields() {
            try {

                var companyStatObj = this.get('basicInfo');
                var stockObj = this.get('stock');
                if (!this.get('isListed') && this.utils.validators.isAvailable(this.get('unlistedDisplayFields'))) {
                    this.set('displayFields', this.get('unlistedDisplayFields'));
                }
                var fields = this.get('displayFields');
                var valueFieldsRef = this.get('valueFields');

                var lanStore = this.get('app').lang.labels;
                var that = this;

                if (fields && valueFieldsRef) {
                    Ember['default'].$.each(fields, function (key, value) {
                        var fieldObj = panelField['default'].create({
                            lanKey: lanStore[value.lanKey] ? lanStore[value.lanKey] : value.lanKey,
                            suffix: value['suffix'] ? value['suffix'] : ''
                        });

                        fieldObj.field = value.dataField;
                        fieldObj.currency = companyStatObj.get('currency');

                        if (value.decimalPlaces) {
                            fieldObj.numOfDeci = value.decimalPlaces;
                        } else {
                            fieldObj.numOfDeci = companyStatObj.get('deci');
                        }

                        switch (value.formatter) {
                            case that.utils.Constants.DataFormatter.Currency:
                                fieldObj.isCurrency = true;
                                break;
                            case that.utils.Constants.DataFormatter.Long:
                                fieldObj.isLong = true;
                                break;
                            case that.utils.Constants.DataFormatter.Integer:
                                fieldObj.isInteger = true;
                                break;
                            case that.utils.Constants.DataFormatter.String:
                                fieldObj.isString = true;
                                break;
                            case that.utils.Constants.DataFormatter.Date:
                                fieldObj.isDate = true;
                                break;
                            case that.utils.Constants.DataFormatter.Percentage:
                                fieldObj.isPercentage = true;
                                break;
                            case that.utils.Constants.DataFormatter.DivideNumber:
                                fieldObj.isDivideNumber = true;
                                break;
                            case that.utils.Constants.DataFormatter.Float:
                                fieldObj.isFloat = true;
                                break;
                        }

                        if (value.type === 'CP') {
                            fieldObj.valueObj = companyStatObj;
                            fieldObj.value = companyStatObj.get(value.dataField);
                            Ember['default'].addObserver(companyStatObj, value.dataField, fieldObj, fieldObj.notifyChange);
                        } else if (value.type === 'STK') {
                            fieldObj.valueObj = stockObj;
                            fieldObj.value = stockObj.get(value.dataField);
                            Ember['default'].addObserver(stockObj, value.dataField, fieldObj, fieldObj.notifyChange);
                        }

                        valueFieldsRef.pushObject(fieldObj);
                    });
                }
            } catch (x) {
                this.utils.logger.logError('Error in rendering company-statistics fields : ' + x);
            }
        }
    });

});