define('ir-app/templates/ir-widgets/watchlist-widget/portal-watch-lists', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1,"role","presentation");
          dom.setAttribute(el1,"class","col-xs-12 col-sm-4 col-md-4 col-lg-4");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"id","tabLoss");
          dom.setAttribute(el2,"href","#");
          dom.setAttribute(el2,"aria-controls","watchlist-area");
          dom.setAttribute(el2,"role","tab");
          dom.setAttribute(el2,"data-toggle","tab");
          dom.setAttribute(el2,"class","font-medium");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, element = hooks.element, content = hooks.content;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element12 = dom.childAt(fragment, [1, 1]);
          var morph0 = dom.createMorphAt(element12,0,0);
          element(env, element12, context, "action", ["onTabSelectionChanged", "loss"], {});
          content(env, morph0, context, "app.lang.labels.stksAccumLoss");
          return fragment;
        }
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2,"colspan","13");
            dom.setAttribute(el2,"class","side-bar-title text-align-left");
            var el3 = dom.createTextNode("\n                                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, content = hooks.content;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(dom.childAt(fragment, [1, 1, 1]),0,0);
            content(env, morph0, context, "sec.des");
            return fragment;
          }
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            isHTMLBars: true,
            revision: "Ember@1.11.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              dom.setAttribute(el1,"class","tbl-row-line-strike");
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","col-one");
              var el3 = dom.createTextNode("\n                                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("a");
              dom.setAttribute(el3,"target","_parent");
              var el4 = dom.createTextNode("\n                                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                    ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","col-two");
              var el3 = dom.createTextNode("\n                                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("p");
              dom.setAttribute(el3,"class","text-align-left watchlist-flag");
              var el4 = dom.createTextNode("\n                                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("a");
              dom.setAttribute(el4,"target","_parent");
              var el5 = dom.createTextNode("\n                                            ");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                                            ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("span");
              dom.setAttribute(el5,"data-toggle","tooltip");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                                        ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                    ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","col-three");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","col-four");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","col-five format-arabic-num");
              var el3 = dom.createTextNode("\n                                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","col-six format-arabic-num");
              var el3 = dom.createTextNode("\n                                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("<i class= aria-hidden=\"true\"></i>");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","col-seven");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","col-eight");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","col-nine");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","col-ten");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","col-eleven");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","col-twelve");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","col-thirteen");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks, get = hooks.get, concat = hooks.concat, attribute = hooks.attribute, subexpr = hooks.subexpr, content = hooks.content, inline = hooks.inline;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var element6 = dom.childAt(fragment, [1]);
              var element7 = dom.childAt(element6, [1, 1]);
              var element8 = dom.childAt(element6, [3, 1, 1]);
              var element9 = dom.childAt(element8, [3]);
              var element10 = dom.childAt(element6, [9, 1]);
              var element11 = dom.childAt(element6, [11, 1]);
              var morph0 = dom.createMorphAt(element7,1,1);
              var attrMorph0 = dom.createAttrMorph(element7, 'title');
              var attrMorph1 = dom.createAttrMorph(element7, 'href');
              var morph1 = dom.createMorphAt(element8,1,1);
              var attrMorph2 = dom.createAttrMorph(element8, 'title');
              var attrMorph3 = dom.createAttrMorph(element8, 'href');
              var attrMorph4 = dom.createAttrMorph(element9, 'title');
              var attrMorph5 = dom.createAttrMorph(element9, 'class');
              var morph2 = dom.createMorphAt(dom.childAt(element6, [5]),0,0);
              var morph3 = dom.createMorphAt(dom.childAt(element6, [7]),0,0);
              var morph4 = dom.createMorphAt(element10,0,0);
              var attrMorph6 = dom.createAttrMorph(element10, 'class');
              var morph5 = dom.createMorphAt(element11,0,0);
              var attrMorph7 = dom.createAttrMorph(element11, 'class');
              var morph6 = dom.createMorphAt(dom.childAt(element6, [13]),0,0);
              var morph7 = dom.createMorphAt(dom.childAt(element6, [15]),0,0);
              var morph8 = dom.createMorphAt(dom.childAt(element6, [17]),0,0);
              var morph9 = dom.createMorphAt(dom.childAt(element6, [19]),0,0);
              var morph10 = dom.createMorphAt(dom.childAt(element6, [21]),0,0);
              var morph11 = dom.createMorphAt(dom.childAt(element6, [23]),0,0);
              var morph12 = dom.createMorphAt(dom.childAt(element6, [25]),0,0);
              attribute(env, attrMorph0, element7, "title", concat(env, [get(env, context, "symbol.sym")]));
              attribute(env, attrMorph1, element7, "href", concat(env, [subexpr(env, context, "applyPlaceHolders", [get(env, context, "clientStkPage"), get(env, context, "symbol.exg"), get(env, context, "symbol.sym"), get(env, context, "lang")], {})]));
              content(env, morph0, context, "symbol.sym");
              attribute(env, attrMorph2, element8, "title", concat(env, [get(env, context, "symbol.sDes")]));
              attribute(env, attrMorph3, element8, "href", concat(env, [subexpr(env, context, "applyPlaceHolders", [get(env, context, "clientStkPage"), get(env, context, "symbol.exg"), get(env, context, "symbol.sym"), get(env, context, "lang")], {})]));
              content(env, morph1, context, "symbol.sDes");
              attribute(env, attrMorph4, element9, "title", subexpr(env, context, "getDCFSFlagToolTipText", [get(env, context, "symbol.dcfs"), get(env, context, "app.lang.labels")], {}));
              attribute(env, attrMorph5, element9, "class", subexpr(env, context, "getFlagStyle", [get(env, context, "symbol.dcfs")], {}));
              inline(env, morph2, context, "formatNumber", [get(env, context, "symbol.adjustedLtp"), get(env, context, "symbol.deci")], {});
              inline(env, morph3, context, "formatNumber", [get(env, context, "symbol.cls"), get(env, context, "symbol.deci")], {});
              attribute(env, attrMorph6, element10, "class", subexpr(env, context, "getUpDownStyle", [get(env, context, "symbol.chg"), "green", "red"], {}));
              inline(env, morph4, context, "formatNumber", [get(env, context, "symbol.chg"), get(env, context, "symbol.deci")], {});
              attribute(env, attrMorph7, element11, "class", subexpr(env, context, "getUpDownStyle", [get(env, context, "symbol.pctChg"), "green", "red"], {}));
              inline(env, morph5, context, "formatNumber", [get(env, context, "symbol.pctChg")], {});
              inline(env, morph6, context, "formatNumber", [get(env, context, "symbol.vol"), 0], {});
              inline(env, morph7, context, "formatNumber", [get(env, context, "symbol.tovr"), 0], {});
              inline(env, morph8, context, "formatNumber", [get(env, context, "symbol.trades"), 0], {});
              inline(env, morph9, context, "formatNumber", [get(env, context, "symbol.high"), get(env, context, "symbol.deci")], {});
              inline(env, morph10, context, "formatNumber", [get(env, context, "symbol.low"), get(env, context, "symbol.deci")], {});
              inline(env, morph11, context, "formatToTime", [get(env, context, "symbol.prevT"), get(env, context, "exgObj.tzo")], {});
              inline(env, morph12, context, "formatToDate", [get(env, context, "symbol.prevD")], {});
              return fragment;
            }
          };
        }());
        var child1 = (function() {
          return {
            isHTMLBars: true,
            revision: "Ember@1.11.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","col-one");
              var el3 = dom.createComment("style=\"max-width: 130px; width: 50px\"");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("a");
              dom.setAttribute(el3,"target","_parent");
              var el4 = dom.createTextNode("\n                                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                    ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","col-two");
              var el3 = dom.createTextNode("\n                                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("p");
              dom.setAttribute(el3,"class","text-align-left watchlist-flag");
              var el4 = dom.createTextNode("\n                                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("a");
              dom.setAttribute(el4,"target","_parent");
              var el5 = dom.createTextNode("\n                                            ");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                                            ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("span");
              dom.setAttribute(el5,"data-toggle","tooltip");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                                        ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                    ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","col-three");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","col-four");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","col-five format-arabic-num");
              var el3 = dom.createTextNode("\n                                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","col-six format-arabic-num");
              var el3 = dom.createTextNode("\n                                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("<i class= aria-hidden=\"true\"></i>");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","col-seven");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","col-eight");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","col-nine");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","col-ten");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","col-eleven");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","col-twelve");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","col-thirteen");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks, get = hooks.get, concat = hooks.concat, attribute = hooks.attribute, subexpr = hooks.subexpr, content = hooks.content, inline = hooks.inline;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var element0 = dom.childAt(fragment, [1]);
              var element1 = dom.childAt(element0, [1, 2]);
              var element2 = dom.childAt(element0, [3, 1, 1]);
              var element3 = dom.childAt(element2, [3]);
              var element4 = dom.childAt(element0, [9, 1]);
              var element5 = dom.childAt(element0, [11, 1]);
              var morph0 = dom.createMorphAt(element1,1,1);
              var attrMorph0 = dom.createAttrMorph(element1, 'title');
              var attrMorph1 = dom.createAttrMorph(element1, 'href');
              var morph1 = dom.createMorphAt(element2,1,1);
              var attrMorph2 = dom.createAttrMorph(element2, 'title');
              var attrMorph3 = dom.createAttrMorph(element2, 'href');
              var attrMorph4 = dom.createAttrMorph(element3, 'title');
              var attrMorph5 = dom.createAttrMorph(element3, 'class');
              var morph2 = dom.createMorphAt(dom.childAt(element0, [5]),0,0);
              var morph3 = dom.createMorphAt(dom.childAt(element0, [7]),0,0);
              var morph4 = dom.createMorphAt(element4,0,0);
              var attrMorph6 = dom.createAttrMorph(element4, 'class');
              var morph5 = dom.createMorphAt(element5,0,0);
              var attrMorph7 = dom.createAttrMorph(element5, 'class');
              var morph6 = dom.createMorphAt(dom.childAt(element0, [13]),0,0);
              var morph7 = dom.createMorphAt(dom.childAt(element0, [15]),0,0);
              var morph8 = dom.createMorphAt(dom.childAt(element0, [17]),0,0);
              var morph9 = dom.createMorphAt(dom.childAt(element0, [19]),0,0);
              var morph10 = dom.createMorphAt(dom.childAt(element0, [21]),0,0);
              var morph11 = dom.createMorphAt(dom.childAt(element0, [23]),0,0);
              var morph12 = dom.createMorphAt(dom.childAt(element0, [25]),0,0);
              attribute(env, attrMorph0, element1, "title", concat(env, [get(env, context, "symbol.sym")]));
              attribute(env, attrMorph1, element1, "href", concat(env, [subexpr(env, context, "applyPlaceHolders", [get(env, context, "clientStkPage"), get(env, context, "symbol.exg"), get(env, context, "symbol.sym"), get(env, context, "lang")], {})]));
              content(env, morph0, context, "symbol.sym");
              attribute(env, attrMorph2, element2, "title", concat(env, [get(env, context, "symbol.sDes")]));
              attribute(env, attrMorph3, element2, "href", concat(env, [subexpr(env, context, "applyPlaceHolders", [get(env, context, "clientStkPage"), get(env, context, "symbol.exg"), get(env, context, "symbol.sym"), get(env, context, "lang")], {})]));
              content(env, morph1, context, "symbol.sDes");
              attribute(env, attrMorph4, element3, "title", subexpr(env, context, "getDCFSFlagToolTipText", [get(env, context, "symbol.dcfs"), get(env, context, "app.lang.labels")], {}));
              attribute(env, attrMorph5, element3, "class", subexpr(env, context, "getFlagStyle", [get(env, context, "symbol.dcfs")], {}));
              inline(env, morph2, context, "formatNumber", [get(env, context, "symbol.adjustedLtp"), get(env, context, "symbol.deci")], {});
              inline(env, morph3, context, "formatNumber", [get(env, context, "symbol.cls"), get(env, context, "symbol.deci")], {});
              attribute(env, attrMorph6, element4, "class", subexpr(env, context, "getUpDownStyle", [get(env, context, "symbol.chg"), "green", "red"], {}));
              inline(env, morph4, context, "formatNumber", [get(env, context, "symbol.chg"), get(env, context, "symbol.deci")], {});
              attribute(env, attrMorph7, element5, "class", subexpr(env, context, "getUpDownStyle", [get(env, context, "symbol.pctChg"), "green", "red"], {}));
              inline(env, morph5, context, "formatNumber", [get(env, context, "symbol.pctChg")], {});
              inline(env, morph6, context, "formatNumber", [get(env, context, "symbol.vol"), 0], {});
              inline(env, morph7, context, "formatNumber", [get(env, context, "symbol.tovr"), 0], {});
              inline(env, morph8, context, "formatNumber", [get(env, context, "symbol.trades"), 0], {});
              inline(env, morph9, context, "formatNumber", [get(env, context, "symbol.high"), get(env, context, "symbol.deci")], {});
              inline(env, morph10, context, "formatNumber", [get(env, context, "symbol.low"), get(env, context, "symbol.deci")], {});
              inline(env, morph11, context, "formatToTime", [get(env, context, "symbol.prevT"), get(env, context, "exgObj.tzo")], {});
              inline(env, morph12, context, "formatToDate", [get(env, context, "symbol.prevD")], {});
              return fragment;
            }
          };
        }());
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, block = hooks.block;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, null);
            dom.insertBoundary(fragment, 0);
            block(env, morph0, context, "if", [get(env, context, "symbol.suspended")], {}, child0, child1);
            return fragment;
          }
        };
      }());
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, block = hooks.block;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
          var morph1 = dom.createMorphAt(fragment,2,2,contextualElement);
          dom.insertBoundary(fragment, null);
          dom.insertBoundary(fragment, 0);
          block(env, morph0, context, "if", [get(env, context, "sec.dataAvailable")], {}, child0, null);
          block(env, morph1, context, "each", [get(env, context, "sec.getSymbolList")], {"keyword": "symbol"}, child1, null);
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","row");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col-xs-12 col-md-12 col-lg-12");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("ul");
        dom.setAttribute(el3,"id","tabs");
        dom.setAttribute(el3,"class","nav nav-tabs padding-bottom-mobile-search main-tabular padding-small-left margin-large-bottom");
        dom.setAttribute(el3,"data-tabs","tabs");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"role","presentation");
        dom.setAttribute(el4,"class","active col-xs-12 col-sm-4 col-md-4 col-lg-4");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5,"id","tabAll");
        dom.setAttribute(el5,"href","#");
        dom.setAttribute(el5,"aria-controls","watchlist-area");
        dom.setAttribute(el5,"role","tab");
        dom.setAttribute(el5,"data-toggle","tab");
        dom.setAttribute(el5,"class","font-medium");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"class","col-xs-12 col-sm-4 col-md-4 col-lg-4");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"id","filterSearch");
        dom.setAttribute(el5,"class","filter-search fa fa-search full-width");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("<span class=\"input-group-btn\">\n                        <button class=\"btn btn-default\" type=\"button\">\n                            <i class=\"glyphicon glyphicon-search\" aria-hidden=\"true\"></i>\n                        </button>\n                    </span>");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","row portal-watch-list");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col-xs-12");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table-responsive");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","tbl-large-wrapper");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("table");
        dom.setAttribute(el5,"id","tbl-allstocks");
        dom.setAttribute(el5,"class","table table-striped table-condensed table-bordered numeric-table large-tbl allstocks-tbl p-relative ");
        dom.setAttribute(el5,"width","100%");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("thead");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("th");
        dom.setAttribute(el8,"id","symbolLabel");
        dom.setAttribute(el8,"class","col-one");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("th");
        dom.setAttribute(el8,"id","descLabel");
        dom.setAttribute(el8,"class","col-two");
        var el9 = dom.createElement("p");
        dom.setAttribute(el9,"class","text-align-left");
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("th");
        dom.setAttribute(el8,"id","lastPriceLabel");
        dom.setAttribute(el8,"class","col-three");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("th");
        dom.setAttribute(el8,"id","closeLabel");
        dom.setAttribute(el8,"class","col-four");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("th");
        dom.setAttribute(el8,"id","changeLabel");
        dom.setAttribute(el8,"class","col-five");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("th");
        dom.setAttribute(el8,"id","preChangeLabel");
        dom.setAttribute(el8,"class","col-six");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("th");
        dom.setAttribute(el8,"id","volumeLabel");
        dom.setAttribute(el8,"class","col-seven");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("th");
        dom.setAttribute(el8,"id","turnOverLabel");
        dom.setAttribute(el8,"class","col-eight");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("th");
        dom.setAttribute(el8,"id","tradesLabel");
        dom.setAttribute(el8,"class","col-nine");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("th");
        dom.setAttribute(el8,"id","highLabel");
        dom.setAttribute(el8,"class","col-ten");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("th");
        dom.setAttribute(el8,"id","lowLabel");
        dom.setAttribute(el8,"class","col-eleven");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("th");
        dom.setAttribute(el8,"id","ltTimeLabel");
        dom.setAttribute(el8,"class","col-twelve");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("th");
        dom.setAttribute(el8,"id","ltDateLabel");
        dom.setAttribute(el8,"class","col-thirteen");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("tbody");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, element = hooks.element, content = hooks.content, get = hooks.get, block = hooks.block, inline = hooks.inline;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element13 = dom.childAt(fragment, [0, 1, 1]);
        var element14 = dom.childAt(element13, [1, 1]);
        var element15 = dom.childAt(fragment, [2, 1, 1, 1, 1]);
        var element16 = dom.childAt(element15, [1, 1]);
        var morph0 = dom.createMorphAt(element14,0,0);
        var morph1 = dom.createMorphAt(element13,3,3);
        var morph2 = dom.createMorphAt(dom.childAt(element13, [5, 1]),1,1);
        var morph3 = dom.createMorphAt(dom.childAt(element16, [1]),0,0);
        var morph4 = dom.createMorphAt(dom.childAt(element16, [3, 0]),0,0);
        var morph5 = dom.createMorphAt(dom.childAt(element16, [5]),0,0);
        var morph6 = dom.createMorphAt(dom.childAt(element16, [7]),0,0);
        var morph7 = dom.createMorphAt(dom.childAt(element16, [9]),0,0);
        var morph8 = dom.createMorphAt(dom.childAt(element16, [11]),0,0);
        var morph9 = dom.createMorphAt(dom.childAt(element16, [13]),0,0);
        var morph10 = dom.createMorphAt(dom.childAt(element16, [15]),0,0);
        var morph11 = dom.createMorphAt(dom.childAt(element16, [17]),0,0);
        var morph12 = dom.createMorphAt(dom.childAt(element16, [19]),0,0);
        var morph13 = dom.createMorphAt(dom.childAt(element16, [21]),0,0);
        var morph14 = dom.createMorphAt(dom.childAt(element16, [23]),0,0);
        var morph15 = dom.createMorphAt(dom.childAt(element16, [25]),0,0);
        var morph16 = dom.createMorphAt(dom.childAt(element15, [3]),1,1);
        element(env, element14, context, "action", ["onTabSelectionChanged", "all"], {});
        content(env, morph0, context, "app.lang.labels.all");
        block(env, morph1, context, "if", [get(env, context, "isAccumTab")], {}, child0, null);
        inline(env, morph2, context, "input", [], {"value": get(env, context, "textFilter"), "type": "text", "class": "search-query form-control font-family-arial", "placeholder": get(env, context, "app.lang.labels.filter")});
        content(env, morph3, context, "app.lang.labels.symbol");
        content(env, morph4, context, "app.lang.labels.description");
        content(env, morph5, context, "app.lang.labels.lastPrice");
        content(env, morph6, context, "app.lang.labels.close");
        content(env, morph7, context, "app.lang.labels.change");
        content(env, morph8, context, "app.lang.labels.perChange");
        content(env, morph9, context, "app.lang.labels.volume");
        content(env, morph10, context, "app.lang.labels.turnover");
        content(env, morph11, context, "app.lang.labels.trades");
        content(env, morph12, context, "app.lang.labels.high");
        content(env, morph13, context, "app.lang.labels.low");
        content(env, morph14, context, "app.lang.labels.lastTradedTime");
        content(env, morph15, context, "app.lang.labels.lastTradedDate");
        block(env, morph16, context, "each", [get(env, context, "getSectors")], {"keyword": "sec"}, child1, null);
        return fragment;
      }
    };
  }()));

});