define('ir-app/models/price/price-data-stores/insider-trades-data-store', ['exports', 'ember', 'ir-app/models/price/price-data-stores/base-data-store', 'ir-app/models/price/price-business-entities/insider-trade', 'ir-app/models/price/price-service', 'ir-app/models/price/price-constants', 'ir-app/utils/utils', 'ir-app/models/shared/language/language-data-store'], function (exports, Ember, BaseStore, insiderTrade, priceService, priceConstants, utils, LanguageDataStore) {

    'use strict';

    /**
     * Created by lakmaln on 7/26/2016.
     */

    exports['default'] = BaseStore['default'].extend({

        insiderTradesStore: Ember['default'].A(),
        tradesForList: Ember['default'].A(),

        getStoredObject: function getStoredObject(exchange, symbol, language, successFn, errorFn, dataType) {
            var key = this.getKey([exchange, symbol, language]);
            var cachedObj = this.dataStore[key];

            var postSuccess = function postSuccess() {
                cachedObj.metaObj[dataType].isAvailable = true;
                cachedObj.metaObj[dataType].isRequested = false;
                var successFns = cachedObj.metaObj[dataType].successFns;

                Ember['default'].$.each(successFns, function (index, sFn) {
                    if (Ember['default'].$.isFunction(sFn)) {
                        sFn();
                    }
                });
                cachedObj.metaObj[dataType].successFns = [];
            };

            var error = function error() {
                cachedObj.metaObj[dataType].isAvailable = false;
                cachedObj.metaObj[dataType].isRequested = false;
                var errorFns = cachedObj.metaObj[dataType].errorFns;

                Ember['default'].$.each(errorFns, function (index, eFn) {
                    if (Ember['default'].$.isFunction(eFn)) {
                        eFn();
                    }
                });
                cachedObj.metaObj[dataType].errorFns = [];
            };

            if (!cachedObj) {
                cachedObj = this.createStoreCacheObj(exchange, symbol, language);
            }

            if (dataType === undefined) {
                // no data type defined. Assume no data initialization is needed.
                return cachedObj.dataObj;
            }

            if (cachedObj.metaObj[dataType].isAvailable) {
                if (Ember['default'].$.isFunction(successFn)) {
                    successFn();
                }
            } else {
                // data not available state
                this.addCallbackFunctions(cachedObj, dataType, successFn, errorFn);

                if (!cachedObj.metaObj[dataType].isRequested) {
                    // data not available and already NO earlier request is processing
                    cachedObj.metaObj[dataType].isRequested = true;
                    switch (dataType) {
                        case priceConstants['default'].DataTypes.InsiderTradesDataStore.InsiderTrades:
                            priceService['default'].sendInsiderTradesDataRequest(exchange, symbol, language, postSuccess, error);
                            break;

                        default:
                            utils['default'].logger.logError('Unsupported insider-trades-data-store Data Type: ' + dataType);
                            break;
                    }
                }
            }
            return cachedObj.dataObj;
        },

        createStoreCacheObj: function createStoreCacheObj(exchange, symbol, language) {
            var key = this.getKey([exchange, symbol, language]);
            var storeCacheObj = {
                dataObj: {},
                metaObj: {}
            };

            storeCacheObj.dataObj = insiderTrade['default'].create({
                exg: exchange,
                sym: symbol,
                lan: language
            });

            var insiderTradeTypes = priceConstants['default'].DataTypes.InsiderTradesDataStore;
            this.initMetaData(storeCacheObj, insiderTradeTypes);
            this.dataStore[key] = storeCacheObj;
            return storeCacheObj;
        },

        getNewInsiderTrade: function getNewInsiderTrade() {
            var insiderTradeObj = insiderTrade['default'].create();
            this.addToCollection(insiderTradeObj);
            return insiderTradeObj;
        },

        addToCollection: function addToCollection(insiderTradeObj) {
            var insiderTradesCollection = this.get('insiderTradesStore');
            insiderTradesCollection.pushObject(insiderTradeObj);
        },

        clearData: function clearData() {
            this.set('insiderTradesStore', Ember['default'].A());
            this.set('tradesForList', Ember['default'].A());
            this.addToTradeForList(LanguageDataStore['default'].getLanguageObj().lang.labels.all);
        },

        addToTradeForList: function addToTradeForList(tradeFor) {
            var tradeForCollection = this.get('tradesForList');

            if (!this.isInTradeForList(tradeFor)) {
                tradeForCollection.pushObject({ trdFor: tradeFor });
            }
        },

        isInTradeForList: function isInTradeForList(comparer) {
            for (var i = 0; i < this.get('tradesForList').length; i++) {
                if (comparer === this.get('tradesForList')[i].trdFor) {
                    return true;
                }
            }
            return false;
        },

        getTradesForList: function getTradesForList() {
            return this.get('tradesForList');
        }

    }).create();

});