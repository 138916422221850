define('ir-app/views/table/button-cell', ['exports', 'ember-table/views/table-cell'], function (exports, TableCell) {

    'use strict';

    exports['default'] = TableCell['default'].extend({
        templateName: 'table/views/button-cell',

        iconClass: (function () {
            return this.get('column.iconClass');
        }).property(),

        rowValues: (function () {
            return this.getProperties('row') ? this.getProperties('row').row : undefined;
        }).property('cellContent')
    });

});