define('ir-app/controllers/ir-widgets/announcement-widgets/announcements-compact-list', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/models/price/price-constants', 'ir-app/models/shared/shared-service'], function (exports, Ember, BaseController, priceService, PriceConstant, sharedService) {

    'use strict';

    exports['default'] = BaseController['default'].extend({

        model: Ember['default'].A(),
        minifiedSliceNumber: 5,
        clientAnnouncementPage: '',
        exchange: '',
        lang: '',
        symbol: '',
        isForExchange: false,
        showSymbol: false,
        isLoadInPopup: false,
        isShowPopup: false,
        isLoadingPopup: false,
        annObj: null,

        onLoadWidget: function onLoadWidget() {
            this.setErrorMessage();
        },

        onPrepareData: function onPrepareData() {
            this.set('exchange', this.get('exg'));
            this.set('lang', sharedService['default'].userSettings.currentLanguage.toLowerCase());
            this.set('clientAnnouncementPage', this.get('clientAnnouncementPage'));

            this.set('isForExchange', this.get('isForExchange'));
            if (!this.get('isForExchange')) {
                this.set('symbol', this.get('sym'));
            }

            this.startLoadingProgress();
            this.set('model', priceService['default'].announcementDS.getAnnCollectionBySymbol(this.get('exchange'), this.get('symbol')));

            var that = this;

            var successFn = function successFn() {
                var loadAnn = function loadAnn() {
                    priceService['default'].announcementDS.getAnnCollectionBySymbol(that.get('exg'), that.get('symbol')).clear();
                    priceService['default'].sendAnnouncementSearchRequest({
                        exchange: that.get('exchange'),
                        symbol: that.get('symbol'),
                        pageSize: PriceConstant['default'].AnnouncementSearchPageSize,
                        reqSuccessFn: function reqSuccessFn() {
                            that.set('stopLoadingAnnouncement', true);
                            that.onDataSuccess();
                        },
                        reqFailureFn: function reqFailureFn() {
                            that.set('stopLoadingAnnouncement', true);
                            that.onDataError();
                        }
                    }, priceService['default'].announcementDS.getAnnCollectionBySymbol(that.get('exchange'), that.get('symbol')));
                };

                // Set both success and failure functions to the same function as in case of a failure we at least show the symbol code instead of the symbol name
                priceService['default'].downloadParamsSymbolMasterForExchange({
                    exchange: that.get('exg'),
                    language: sharedService['default'].userSettings.currentLanguage,
                    successFn: loadAnn,
                    errorFn: loadAnn
                });
            };

            var errorFn = function errorFn() {};

            priceService['default'].exchangeDS.getExchange(that.get('exg'));
            priceService['default'].downloadExchangeProperty(that.get('exg'), sharedService['default'].userSettings.currentLanguage, successFn, errorFn);
        },

        sortedContent: (function () {
            return Ember['default'].ArrayProxy.extend(Ember['default'].SortableMixin).create({
                sortProperties: ['dt'],
                sortAscending: false,
                content: this.get('model')
            });
        }).property('model'),

        filteredSliceContent: (function () {
            var that = this;
            return this.get('sortedContent').slice(0, that.minifiedSliceNumber);
        }).property('sortedContent.@each'),

        setErrorMessage: function setErrorMessage() {
            this.set('errorMessage', this.get('app').lang.messages.dataNotAvailable);
        },

        onCheckDataAvailability: function onCheckDataAvailability() {
            var announcements = priceService['default'].announcementDS.getAnnCollectionBySymbol(this.get('exg'), this.get('symbol'));
            return announcements && announcements.length > 0;
        },

        _setErrorMessage: (function () {
            if (this.get('filteredSliceContent').length > 0) {
                this.set('showError', false);
            } else {
                this.set('showError', true);
            }
        }).observes('filteredSliceContent'),

        _reset: function _reset() {
            this.set('model', Ember['default'].A());
        },

        sendNewsAnnouncementBodyRequest: function sendNewsAnnouncementBodyRequest(annId) {
            var that = this;
            that.set('isShowPopup', true);
            var annObj = priceService['default'].announcementDS.getAnnouncement(annId);
            this.set('annObj', annObj);

            if (!annObj.get('isBodyUpdated')) {
                that.set('isLoadingPopup', true);
                // that.annObj.set('bod', that.get('app').lang.messages.loading);

                priceService['default'].sendNewsAnnBodyRequest({
                    id: annObj.get('id'),
                    type: annObj.get('type'),
                    reqSuccessFn: function reqSuccessFn() {
                        that.set('isLoadingPopup', false);
                    },
                    reqFailureFn: function reqFailureFn() {
                        that.annObj.set('isBodyUpdated', false);
                        that.set('isShowPopup', false);
                    },
                    lan: sharedService['default'].userSettings.currentLanguage
                });
            }
        },

        closePopup: function closePopup() {
            var that = this;
            that.set('isShowPopup', false);
            this.set('annObj', null);
        },

        actions: {
            loadPopup: function loadPopup(annId) {
                this.sendNewsAnnouncementBodyRequest(annId);
            },

            closePopup: function closePopup() {
                this.closePopup();
            }
        }
    });

});