define('ir-app/views/table/table-mixins/style-mixin', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Mixin.create({
        positiveNegativeStyle: Ember['default'].computed(function () {
            return this.getPositiveNegativeStyle();
        }).property('cellContent'),

        getPositiveNegativeStyle: function getPositiveNegativeStyle(currentValue, positiveStyle, negativeStyle, zeroStyle) {
            var value = currentValue ? currentValue : this.get('cellContent') ? this.get('cellContent').firstValue : undefined;
            return this.isPositive(value) ? positiveStyle ? positiveStyle : 'green' : this.isZero(value) ? zeroStyle ? zeroStyle : 'colour-normal' : negativeStyle ? negativeStyle : 'red';
        },

        styles: Ember['default'].computed(function () {
            this.get('clearStyles');
            var cellStyle = '';

            if (this.get('blinkStyle') && this.get('column.isBlink')) {
                cellStyle = this.get('blinkStyle');
            } else if (this.get('column.cellStyle')) {
                cellStyle = this.get('column.cellStyle');
            } else if (this.get('column.positiveNegativeChange')) {
                cellStyle = this.get('positiveNegativeStyle');
            }

            return cellStyle;
        }).property('cellContent', 'blinkStyle'),

        styleFirstValue: Ember['default'].computed(function () {
            return this.get('column.firstValueStyle') && !this.get('blinkStyle') ? this.get('column.firstValueStyle') : '';
        }).property('cellContent', 'blinkStyle'),

        styleSecondValue: Ember['default'].computed(function () {
            return this.get('column.secondValueStyle') ? this.get('column.secondValueStyle') : '';
        }).property('cellContent'), // Second value is not required to blink so blinkStyle is not observed

        styleBackground: Ember['default'].computed(function () {
            return this.get('column.backgroundStyle') ? this.get('column.backgroundStyle') : '';
        }).property()
    });

});