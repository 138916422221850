define('ir-app/controllers/ir-widgets/ipo-widgets/ipo-list-view', ['exports', 'ember', 'ir-app/controllers/base-array-controller', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/models/price/price-constants', 'ir-app/utils/utils'], function (exports, Ember, BaseArrayController, priceService, sharedService, priceConstants, utils) {

    'use strict';

    exports['default'] = BaseArrayController['default'].extend({

        ipoStatusList: Ember['default'].A(),
        count: undefined, // page count
        current: 1, // current page
        params: null, // set search parameters
        listSize: undefined,
        searchParams: undefined,
        fromDate: null,
        toDate: null,
        exchange: undefined,
        defaultSelect: true,
        ipoTypeId: 0,
        isCachedData: true, // is object need to be cached or not
        portalExchangeList: undefined,
        placeHolder: undefined,
        lang: undefined,
        dropDownList: [],
        companyId: undefined,
        companyList: [],
        industryList: [],
        isCompanyListData: false,
        isIpoDataList: false,
        ipoDataList: [],
        isIndustryListData: false,
        ipoIndstryId: 0,
        industryDropDownList: [],
        ipoSubId: '',
        sortColumn: undefined,
        selectedClass: undefined,
        isAscSort: undefined,
        isColumnEnabled: true,
        selectExchangeFromUrl: false,

        onLoadWidget: function onLoadWidget() {
            this.setErrorMessage();
        },

        onPrepareData: function onPrepareData() {
            var isSortParam = this.get('isSortColumnAsc');
            if (isSortParam === undefined || isSortParam === '') {
                this.set('isSortColumnAsc', false);
            }
            this.set('isAscSort', this.get('isSortColumnAsc'));
            this._loadIpoData();
        },

        _loadIpoData: function _loadIpoData() {
            var that = this;
            that.set('placeHolder', that.get('app').lang.labels.company);
            var layoutExgList = that.get('exgList');
            that.set('dropDownList', []);
            var array = that.get('dropDownList');

            Ember['default'].$.each(layoutExgList, function (index, key) {
                var idx = {
                    exg: '',
                    desc: ''
                };
                var prop = ['label', sharedService['default'].userSettings.currentLanguage.toUpperCase()].join('');
                Ember['default'].set(idx, 'desc', key[prop]);
                Ember['default'].set(idx, 'exg', key.exg);

                array.push(idx);
            });

            if (that.get('selectExchangeFromUrl')) {

                var def = {};
                var defIdx = 0;
                Ember['default'].$.each(array, function (index, key) {
                    if (key.exg === that.get('exg')) {
                        def = key;
                        defIdx = index;
                    }
                });

                if (that.get('defaultSelect')) {
                    that.set('defaultExgKey', array[defIdx]);
                    that.set('exchange', array[defIdx].exg);
                }
            } else {
                if (that.get('defaultSelect')) {
                    that.set('defaultExgKey', array[0]);
                    that.set('exchange', priceConstants['default'].DataTypes.IpoDataList.AllData);
                }
            }
            if (that.get('isCustomize')) {
                if (!that.get('ipoSubType') || that.get('ipoSubType') !== 2) {
                    that.set('ipoSubType', 1);
                    that.set('ipoSubId', that.get('mixIpoSubIds')[0]);
                } else {
                    that.set('ipoSubType', 2);
                    that.set('ipoSubId', that.get('mixIpoSubIds')[1]);
                }
            }

            that.startLoadingProgress();
            var searchParams = that.get('searchParams');
            if (!searchParams) {
                var searchPara = {
                    pageSize: that.get('pageSize'),
                    pageIdx: 0,
                    ipoType: that.get('ipoTypeId'),
                    companyId: that.get('companyId'),
                    startDate: '',
                    endDate: '',
                    ipoCategory: that.get('ipoSubId'),
                    ipoIndstryId: that.get('ipoIndstryId'),
                    isCustomize: that.get('isCustomize'),
                    statusIdList: this.get('ipoStatusIds'),
                    sortMixColumn: this.get('sortMixColumnId'),
                    isAscSort: this.get('isSortColumnAsc')
                };
                this.set('searchParams', searchPara);
                this.set('current', 1);
                this.set('isAscSort', this.get('isSortColumnAsc'));
                this.set(this._getTabCSSName(this.get('sortColumn')), '');
                this.set(this._getTabCSSName(this.get('sortMixColumnId')), this.get('sortTypeClass')); // added class for newly selected column
                this.set('sortColumn', this.get('sortMixColumnId'));
            }

            if (!this.get('isCompanyListData')) {
                var companyList = priceService['default'].ipoDataListDS.getStoredObject(priceConstants['default'].DataTypes.IpoDataList.AllData, sharedService['default'].userSettings.currentLanguage, priceConstants['default'].DataTypes.IpoDataList.IPOCompList, function () {
                    that.set('isCompanyListData', true);
                }, function () {
                    that.set('isCompanyListData', false);
                    that.onDataError();
                }).ipoCompanyList;

                this.set('companyList', companyList);
            }

            if (that.get('isCustomize') && !that.get('isIndustryListData') || that.get('isCustomize') && that.get('industryList').length === 0) {
                that.set('industryList', []);
                var industryList = priceService['default'].ipoDataListDS.getStoredObject(priceConstants['default'].DataTypes.IpoDataList.AllData, sharedService['default'].userSettings.currentLanguage, priceConstants['default'].DataTypes.IpoDataList.IPOIndustryList, function () {
                    that.set('isIndustryListData', true);
                }, function () {
                    that.set('isIndustryListData', false);
                    that.onDataError();
                }).ipoIndustryList;

                that.set('industryList', industryList);
            }

            var ipoList = priceService['default'].ipoDataListDS.getStoredObject(that.get('exchange'), sharedService['default'].userSettings.currentLanguage, priceConstants['default'].DataTypes.IpoDataList.IPOData, function () {
                that.set('isIpoDataList', true);
            }, function () {
                that.set('isIpoDataList', false);
                that.onDataError();
            }, that.get('isCachedData'), that.get('searchParams')).ipoDataList;
            that.set('ipoDataList', ipoList);
        },

        onCheckDataAvailability: function onCheckDataAvailability() {
            var ipoData = priceService['default'].ipoDataListDS.getStoredObject(this.get('exchange'), sharedService['default'].userSettings.currentLanguage, priceConstants['default'].DataTypes.IpoDataList.IPOData);

            if (ipoData && ipoData.ipoDataList.length > 0 && ipoData.fullListSize > 0) {
                if (ipoData.ipoStatusList.length > 1) {
                    this.set('ipoStatusList', ipoData.ipoStatusList);
                }
                this.set('count', Math.ceil(ipoData.fullListSize / this.get('pageSize')));
                this.set('listSize', ipoData.fullListSize);
                this.set('industryDropDownList', this.get('industryList'));
                this.set('isColumnEnabled', true);
                return true;
            }
            return false;
        },

        _ipoDataView: (function () {
            if (this.get('isCustomize')) {
                if (this.get('isIpoDataList') && this.get('isCompanyListData') && this.get('isIndustryListData')) {
                    this.set('model', this.get('ipoDataList'));
                    this.onDataSuccess();
                }
            } else {
                if (this.get('isIpoDataList') && this.get('isCompanyListData')) {
                    this.set('model', this.get('ipoDataList'));
                    this.onDataSuccess();
                }
            }
        }).observes('isIpoDataList', 'isCompanyListData', 'isIndustryListData'),

        filteredContent: (function () {
            var content = this.get('arrangedContent');

            if (content) {
                return content;
            }
        }).property('arrangedContent'),

        searchResult: (function () {
            var searchTerm = this.get('searchTerm');
            var regExp = ''; // TODO [Rajith] added arabic RegExp But Need to use proper Regex for arabic
            if (sharedService['default'].userSettings.currentLanguage === 'EN') {
                regExp = new RegExp("\\b" + searchTerm, 'i');
            } else {
                regExp = new RegExp(searchTerm, 'i');
            }
            var companyList = this.get('companyList');
            this.set('showSearchResultContainer', true);
            var filteredList = companyList.filter(function (item) {
                return regExp.test(item.name);
            });

            if (filteredList.length > 0) {
                this.set('isDataUnavailable', false);
            } else {
                this.set('isDataUnavailable', true);
            }
            this.set('resltLst', filteredList);
        }).observes('searchTerm'),

        classAscOrDesc: (function () {
            var isAscClass = this.get('isAscSort');
            if (isAscClass) {
                this.set('sortTypeClass', 'up');
            } else {
                this.set('sortTypeClass', 'down');
            }
        }).observes('isAscSort'),

        _clearDateSelection: function _clearDateSelection() {
            this.set('defaultSelect', true);
            this.set('isIpoDataList', false);
            this.set('exchange', null);
            this.set('searchParams', null);
            this.set('fromDate', null);
            this.set('toDate', null);
            this.set('showError', false);
            this.set('ipoTypeId', 0);
            this.set('dropDownList', []);
            this.set('ipoStatusList', []);
            this.set('resetField', true);
            this.set('companyId', null);
            this.set('ipoIndstryId', 0);
            this.set('industryDropDownList', []);
            this.set('searchTerm', null);
            this.set('ipoSubId', null);
            this.set('showErrorScrener', false);
            this.set('isAscSort', this.get('isSortColumnAsc'));

            this._loadIpoData();
        },
        setErrorMessage: (function () {
            if (this.get('filteredContent').length > 0) {
                this.hideDataErrorMessage();
            } else if (!this.get('isLoading')) {
                this.showDataErrorMessage();
                this.set('errorMessage', this.get('app').lang.messages.dataNotAvailable);
            }
        }).observes('filteredContent'),

        onLanguageChanged: function onLanguageChanged() {
            this.set('defaultSelect', true);
            this.set('searchParams', null);
            this._loadIpoData();
        },

        onClearData: function onClearData() {
            this.set('model', []);
        },

        _viewDetailPage: function _viewDetailPage(ipoId) {
            var link = this.get('ipoDetailPage');
            var lan = sharedService['default'].userSettings.currentLanguage.toLowerCase();
            var exchange = this.get('exchange');
            this._generateUrl(link, exchange, ipoId, lan);
        },

        _generateUrl: function _generateUrl(placeHoldingStr) {
            var link = utils['default'].formatters.applyPlaceHolders(placeHoldingStr, arguments);
            var win = window.open(link, '_parent');
            win.focus();
        },

        _getTabCSSName: function _getTabCSSName(columnID) {
            return [columnID, '_class'].join('');
        },
        actions: {
            pageChanged: function pageChanged(current) {
                var pageSize = this.get('pageSize');
                this.set('isIpoDataList', false);
                var tempPara = this.get('searchParams');
                var searchPara = {
                    pageSize: pageSize,
                    pageIdx: current - 1,
                    ipoType: this.get('ipoTypeId'),
                    companyId: this.get('companyId'),
                    ipoCategory: this.get('ipoSubId'),
                    ipoIndstryId: this.get('ipoIndstryId'),
                    isCustomize: this.get('isCustomize'),
                    statusIdList: this.get('ipoStatusIds'),
                    sortMixColumn: this.get('sortColumn'),
                    isAscSort: this.get('isSortColumnAsc')
                };
                if (tempPara.startDate && tempPara.endDate) {
                    searchPara.startDate = tempPara.startDate;
                    searchPara.endDate = tempPara.endDate;
                }
                this.set('current', current);
                this.set('searchParams', searchPara);
                this._loadIpoData();
            },

            searchByDateSelection: function searchByDateSelection() {
                if (this.get('isCustomize')) {
                    if (!this.get('ipoSubType') && this.get('ipoSubType') === 1) {
                        this.set('ipoSubId', this.get('mixIpoSubIds')[0]); // IPO Subscription
                    } else {
                            this.set('ipoSubId', this.get('mixIpoSubIds')[1]); // Capital Increase Subscription
                        }
                }
                if (!this.get('searchTerm')) {
                    this.set('companyId', undefined);
                }
                this.set('isIpoDataList', false);
                this.set('resetField', false);
                var startDate = null;
                if (this.get('fromDate') !== null) {
                    startDate = new Date(this.get('fromDate'));
                    startDate.setDate(startDate.getDate());
                }
                if (this.get('fromDate') !== null && this.get('toDate') !== null) {
                    this.setErrorMessage();
                    this.set('current', 1);
                    this.startLoadingProgress();
                    var searchPara = {
                        pageSize: this.get('pageSize'),
                        pageIdx: 0,
                        ipoType: this.get('ipoTypeId'),
                        companyId: this.get('companyId'),
                        ipoIndstryId: this.get('ipoIndstryId'),
                        isCustomize: this.get('isCustomize'),
                        ipoCategory: this.get('ipoSubId'),
                        startDate: utils['default'].formatters.convertDateToyyyymmdd(startDate),
                        endDate: utils['default'].formatters.convertDateToyyyymmdd(this.get('toDate')),
                        statusIdList: this.get('ipoStatusIds'),
                        sortMixColumn: this.get('sortColumn'),
                        isAscSort: this.get('isAscSort')
                    };
                    this.set('searchParams', searchPara);
                    this.set('showErrorScrener', false);
                    this._loadIpoData();
                } else {
                    if (this.get('fromDate') !== null || this.get('toDate') !== null) {
                        this.set('errorMessageScrener', this.get('app').lang.messages.selectFromToDates);
                        this.set('showErrorScrener', true);
                    } else {
                        this.set('showErrorScrener', false);
                        this.setErrorMessage();
                        this.set('current', 1);
                        this.startLoadingProgress();
                        var searchParam = {
                            pageSize: this.get('pageSize'),
                            pageIdx: 0,
                            ipoType: this.get('ipoTypeId'),
                            companyId: this.get('companyId'),
                            ipoCategory: this.get('ipoSubId'),
                            ipoIndstryId: this.get('ipoIndstryId'),
                            isCustomize: this.get('isCustomize'),
                            statusIdList: this.get('ipoStatusIds'),
                            sortMixColumn: this.get('sortColumn'),
                            isAscSort: this.get('isAscSort')
                        };
                        this.set('searchParams', searchParam);
                        this._loadIpoData();
                    }
                }
            },
            resetDateSelection: function resetDateSelection() {
                this._clearDateSelection();
            },
            exchangeSelect: function exchangeSelect(option) {
                this.set('exchange', option.exg);
                this.set('defaultSelect', false);
                this.set('searchParams', null);
            },
            ipoTypeSelect: function ipoTypeSelect(option) {
                this.set('ipoTypeId', option.id);
                this.set('defaultSelect', false);
                this.set('searchParams', null);
            },
            ipoIndustrySelect: function ipoIndustrySelect(option) {
                this.set('ipoIndstryId', option.id);
                this.set('defaultSelect', false);
                this.set('searchParams', null);
            },
            onItemSelected: function onItemSelected(item) {
                this.set('companyId', item.id);
                this.set('searchTerm', item.name);
                this.set('showSearchResultContainer', false);
            },
            outsideClick: function outsideClick() {
                this.set('showSearchResultContainer', false);
            },
            fileDownload: function fileDownload(dwnUrl) {
                var link = [this.get('dwnLoadUrl'), dwnUrl, '&LANG=' + sharedService['default'].userSettings.currentLanguage].join(utils['default'].Constants.StringConst.Empty);
                var win = window.open(link, '_blank');
                win.focus();
            },
            detailView: function detailView(ipoId) {
                this._viewDetailPage(ipoId);
            },
            tableSort: function tableSort(columnId) {
                // get MIX column ID and pass to the request
                if (!this.get('isColumnEnabled')) {
                    return;
                }
                this.set('isColumnEnabled', false);
                var columnMixId = columnId;
                var prevSelected = this.get('sortColumn');
                this.set('isAscSort', !this.get('isAscSort')); // true: asc data else desc data
                this.set(this._getTabCSSName(prevSelected), ''); // remove class from previously selected column
                this.set(this._getTabCSSName(columnId), this.get('sortTypeClass')); // added class for newly selected column
                this.set('selectedClass', this._getTabCSSName(columnId));
                this.set('sortColumn', columnMixId);
                this.set('isIpoDataList', false);
                var tempPara = this.get('searchParams');
                var searchPara = {
                    pageSize: this.get('pageSize'),
                    pageIdx: 0,
                    ipoType: this.get('ipoTypeId'),
                    companyId: this.get('companyId'),
                    ipoCategory: this.get('ipoSubId'),
                    ipoIndstryId: this.get('ipoIndstryId'),
                    isCustomize: this.get('isCustomize'),
                    statusIdList: this.get('ipoStatusIds'),
                    sortMixColumn: columnMixId,
                    isAscSort: this.get('isAscSort')
                };
                if (tempPara.startDate && tempPara.endDate) {
                    searchPara.startDate = tempPara.startDate;
                    searchPara.endDate = tempPara.endDate;
                }
                this.set('current', 1);
                this.set('searchParams', searchPara);
                this._loadIpoData();
            }
        }
    });

});