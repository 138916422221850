define('ir-app/models/price/price-protocols/web-socket/price-socket-request-handler', ['exports', 'ir-app/models/price/price-subscription-manager', 'ir-app/models/price/price-constants', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/utils/utils'], function (exports, priceSubscriptionManager, priceConstants, priceService, sharedService, utils) {

    'use strict';

    exports['default'] = (function () {
        // Authentication related requests
        var generateRetailAuthRequest = function generateRetailAuthRequest(authParams) {
            var reqElements = _generateAuthRequest();

            reqElements[reqElements.length] = '"UNM":"';
            reqElements[reqElements.length] = authParams.username;
            reqElements[reqElements.length] = '","PWD":"';
            reqElements[reqElements.length] = authParams.password;
            reqElements[reqElements.length] = '"}\n';

            var req = reqElements.join('');
            req = req.length + req;

            utils['default'].logger.logInfo('Retail Auth Request : ' + req);

            return req;
        };

        var generateSsoAuthRequest = function generateSsoAuthRequest(authParams) {
            var reqElements = _generateAuthRequest();

            reqElements[reqElements.length] = '"SSOTOK":"';
            reqElements[reqElements.length] = authParams.ssoToken;
            reqElements[reqElements.length] = '","SSOTYPE":"';
            reqElements[reqElements.length] = 1;
            reqElements[reqElements.length] = '"}\n';

            var req = reqElements.join('');
            req = req.length + req;

            utils['default'].logger.logInfo('SSO Auth Request : ' + req);

            return req;
        };

        var generateReconnectionAuthRequest = function generateReconnectionAuthRequest() {
            var req;

            // TODO: [Amila] Below user id is hardcoded until the server side fix the username issue.
            priceService['default'].userDS.set('username', 'AMILA.UNI');
            if (utils['default'].validators.isAvailable(priceService['default'].userDS.username)) {
                var reqElements = ['{"AUTHVER":"10","UNM":"', priceService['default'].userDS.username, '","SID":"', priceService['default'].userDS.sessionId, '","LAN":"', sharedService['default'].userSettings.currentLanguage, '","METAVER":"', priceService['default'].userDS.metaVersion, '"}\n'];

                req = reqElements.join('');
                req = req.length + req;
            }

            return req;
        };

        // 40 type requests
        var generateAddExchangeRequest = function generateAddExchangeRequest(exchange, messageType, language) {
            var subStatus = priceSubscriptionManager['default'].addSubscription(messageType, exchange, language);

            if (subStatus.retVal === 1) {
                var reqElements = ['{"40":"', messageType, '","E":"', exchange];

                if (language !== undefined) {
                    reqElements.push('","L":"', language);
                }

                reqElements.push('"}\n');

                var req = reqElements.join('');
                req = req.length + req;

                subStatus.reqArray[subStatus.reqArray.length] = req;
            }

            return subStatus.reqArray;
        };

        var generateRemoveExchangeRequest = function generateRemoveExchangeRequest(exchange, messageType, language) {
            var subStatus = priceSubscriptionManager['default'].removeSubscription(messageType, exchange, language);

            if (subStatus.retVal === 0) {
                var reqElements = ['{"41":"', messageType, '","E":"', exchange];

                if (language !== undefined) {
                    reqElements.push('","L":"', language);
                }

                reqElements.push('"}\n');
                var req = reqElements.join('');
                req = req.length + req;

                subStatus.reqArray[subStatus.reqArray.length] = req;
            }

            return subStatus.reqArray;
        };

        // 80 type requests
        var generateAddSymbolRequest = function generateAddSymbolRequest(exchange, symbol, messageType) {
            var subStatus = priceSubscriptionManager['default'].addSubscription(messageType, exchange, symbol);

            if (subStatus.retVal === 1) {
                var reqElements = ['{"80":"', messageType, '","E":"', exchange, '","S":"', symbol, '"}\n'];
                var req = reqElements.join('');
                req = req.length + req;

                subStatus.reqArray[subStatus.reqArray.length] = req;
            }

            return subStatus.reqArray;
        };

        var generateRemoveSymbolRequest = function generateRemoveSymbolRequest(exchange, symbol, messageType) {
            var subStatus = priceSubscriptionManager['default'].removeSubscription(messageType, exchange, symbol);

            if (subStatus.retVal === 0) {
                var reqElements = ['{"81":"', messageType, '","E":"', exchange, '","S":"', symbol, '"}\n'];
                var req = reqElements.join('');
                req = req.length + req;

                subStatus.reqArray[subStatus.reqArray.length] = req;
            }

            return subStatus.reqArray;
        };

        // 160 type requests
        var generateAddSymbolBulkRequest = function generateAddSymbolBulkRequest(exchange, symbolList, messageType) {
            var subStatus = priceSubscriptionManager['default'].addSubscription(messageType, exchange, symbolList);

            if (subStatus.retVal === 1) {
                var reqElements = ['{"160":"', messageType, '","E":"', exchange, '","S":"', symbolList, '"}\n'];
                var req = reqElements.join('');
                req = req.length + req;

                subStatus.reqArray[subStatus.reqArray.length] = req;
            }

            return subStatus.reqArray;
        };

        var generateRemoveSymbolBulkRequest = function generateRemoveSymbolBulkRequest(exchange, symbolList, messageType) {
            var subStatus = priceSubscriptionManager['default'].removeSubscription(messageType, exchange, symbolList);

            if (subStatus.retVal === 0) {
                var reqElements = ['{"161":"', messageType, '","E":"', exchange, '","S":"', symbolList, '"}\n'];
                var req = reqElements.join('');
                req = req.length + req;

                subStatus.reqArray[subStatus.reqArray.length] = req;
            }

            return subStatus.reqArray;
        };

        // TopStock
        var generateTopStockRequest = function generateTopStockRequest(exchange, topStockType, subMarketCode, language) {
            var lan = language ? language : sharedService['default'].userSettings.currentLanguage;

            var reqElements = ['{"40":"64","E":"', exchange, '","TT":"', topStockType, '","MKT":"', subMarketCode, '","L":"', lan, '"}\n'];
            var req = reqElements.join('');
            req = req.length + req;

            return [req];
        };

        var _generateAuthRequest = function _generateAuthRequest() {
            return ['{"AUTHVER":"10",', '"LOGINIP":"', '192.168.0.1', '","CLVER":"', '1.0.0', '","PDM":"', priceConstants['default'].ProductType, '","LAN":"', sharedService['default'].userSettings.currentLanguage, '","METAVER":"', priceService['default'].userDS.metaVersion, '",'];
        };

        return {
            generateRetailAuthRequest: generateRetailAuthRequest,
            generateSsoAuthRequest: generateSsoAuthRequest,
            generateReconnectionAuthRequest: generateReconnectionAuthRequest,
            generateAddExchangeRequest: generateAddExchangeRequest,
            generateRemoveExchangeRequest: generateRemoveExchangeRequest,
            generateAddSymbolRequest: generateAddSymbolRequest,
            generateRemoveSymbolRequest: generateRemoveSymbolRequest,
            generateAddSymbolBulkRequest: generateAddSymbolBulkRequest,
            generateRemoveSymbolBulkRequest: generateRemoveSymbolBulkRequest,
            generateTopStockRequest: generateTopStockRequest
        };
    })();

});