define('ir-app/controllers/ir-mobile/quote-widgets/smart-quote', ['exports', 'ember', 'ir-app/controllers/ir-mobile/chart-widgets/basic-chart-widget', 'ir-app/models/price/price-service', 'ir-app/models/chart/chart-constants', 'ir-app/models/shared/shared-service', 'ir-app/models/price/price-constants', 'ir-app/components/stk-specific-components/high-chart/dynamic-high-chart', 'ir-app/utils/utils'], function (exports, Ember, BasicChartWidget, priceService, ChartConstants, sharedService, priceConstants, DynamicHighChart, utils) {

    'use strict';

    exports['default'] = BasicChartWidget['default'].extend({

        volumeViewEnabled: null,
        exchange: undefined,
        lang: undefined,
        symbol: undefined,

        // Base overrides
        onLoadWidget: function onLoadWidget() {
            if (this.get('isChartEnable')) {
                this._super();
                if (!this.get('isHistoryChart')) {
                    this.set('isPortalChart', true);
                    this.set('volumeViewEnabled', false);
                    this.set('chartCategory', ChartConstants['default'].ChartCategory.Intraday);
                    this.set('chartDataLevel', ChartConstants['default'].ChartViewPeriod.OneDay.ChartDataLevel);
                    this.set('chartViewPeriod', ChartConstants['default'].ChartViewPeriod.OneDay);
                } else {
                    this.set('isPortalChart', true);
                    this.set('volumeViewEnabled', false);
                    this.set('chartCategory', ChartConstants['default'].ChartCategory.History);
                    this.set('chartDataLevel', ChartConstants['default'].ChartViewPeriod.OneYear.ChartDataLevel);
                    this.set('chartViewPeriod', ChartConstants['default'].ChartViewPeriod.OneYear);
                }
            }
        },

        chartFillColor: function chartFillColor() {
            var that = this;
            if (utils['default'].validators.isMSIEVersion()) {
                return that.get('thm').themeName.colors.areaChartBgColor;
            } else {
                return {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [[0, that.get('gradientUpperColor') ? that.get('gradientUpperColor') : that.get('thm').themeName.colors.gradientUpperColor], [1, that.get('gradientLowerColor') ? that.get('gradientLowerColor') : that.get('thm').themeName.colors.gradientLowerColor]]
                };
            }
        },

        onPrepareData: function onPrepareData() {
            var that = this;
            that.set('isPortalTopBarChart', true);
            var stock = priceService['default'].stockDS.getStock(this.get('exg'), this.get('sym'));
            this.set('stock', stock);
            priceService['default'].addSymbolRequest(this.get('exg'), this.get('sym'));
            priceService['default'].sendSymbolValidationRequest(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, undefined, undefined);
            that.set('exchange', that.get('exg'));
            that.set('lang', sharedService['default'].userSettings.currentLanguage.toLowerCase());
            that.set('symbol', that.get('sym'));

            var successFn = function successFn() {
                if (that.get('isChartEnable')) {
                    that.loadChart();
                }
            };

            var errorFn = function errorFn() {};

            var basicInfo = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, function () {}, function () {}, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyBasicInfo);

            this.set('basicInfo', basicInfo);

            priceService['default'].downloadExchangeProperty(that.get('exg'), sharedService['default'].userSettings.currentLanguage, successFn, errorFn);
        },

        onMktStatChangeToPreopen: function onMktStatChangeToPreopen() {
            if (this.get('isChartEnable')) {
                if (this.get('chartViewPeriod').chartDataLevel === ChartConstants['default'].ChartViewPeriod.OneDay.chartDataLevel) {
                    this.set('chartDataArray', Ember['default'].A());
                    this.set('chartCloseDataArray', Ember['default'].A());
                    this.set('chartVolumeDataArray', Ember['default'].A());
                    this.resetMinMax();
                }
            }
        },

        onAfterRender: function onAfterRender() {
            if (this.get('isChartEnable')) {
                var that = this;
                that._super();
            }
        },

        onClearData: function onClearData() {
            priceService['default'].removeIndexRequest(this.get('exg'), this.get('sym'));
            if (this.get('isChartEnable')) {
                this._super();
            }
        },

        _basicChartOption: function _basicChartOption() {
            var that = this;
            return {
                chart: {
                    height: that.get('chartHeight') ? that.get('chartHeight') : 150,
                    backgroundColor: that.get('thm').themeName.colors.backgroundColor,
                    pinchType: '',
                    zoomType: '',
                    margin: [0, 0, 0, 0]
                },
                xAxis: {
                    type: 'datetime',
                    gridLineWidth: '0',
                    labels: {
                        enabled: false
                    },
                    title: {
                        text: null
                    }
                },
                yAxis: [{
                    gridLineWidth: '0',
                    labels: {
                        enabled: false
                    },
                    title: {
                        text: null
                    }
                }],
                rangeSelector: {
                    enabled: false
                },
                navigator: {
                    enabled: false
                },
                scrollbar: {
                    enabled: false
                },
                tooltip: {
                    enabled: true,
                    useHTML: true
                },
                exporting: {
                    enabled: false
                }

            };
        }
    });

    Ember['default'].Handlebars.helper('dynamic-high-chart', DynamicHighChart['default']);

});