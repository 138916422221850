define('ir-app/controllers/ir-widgets/currency-widgets/currency-calculater', ['exports', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/utils/utils'], function (exports, BaseController, priceService, utils) {

    'use strict';

    exports['default'] = BaseController['default'].extend({

        dropDownList: {},
        currencyAmount: 1,
        fromCurrency: null,
        toCurrency: null,
        currencyRate: null,
        deciPoint: null,
        currencyVal: null,
        isDataAvailable: false,
        dropDownToCurDef: null,

        // Base overrides
        onPrepareData: function onPrepareData() {
            var currencyList = this.get('keys');
            this.set('fromCurrency', currencyList[0].cur);
            this.set('currencyAmount', this.get('defAmount'));
            this.set('dropDownToCurDef', currencyList[1]);
            this.set('toCurrency', currencyList[1].cur);
            this.set('dropDownList', currencyList);
            this.set('deciPoint', this.get('deciPoint'));
            this.set('isDataAvailable', true);
        },

        calculateCurrencyRate: (function () {
            var fromCur = this.get('fromCurrency');
            var toCur = this.get('toCurrency');
            var curAmount = this.get('currencyAmount');

            if (fromCur && toCur) {
                if (fromCur === toCur) {
                    this.set('currencyRate', curAmount);
                } else {
                    this.getApplicableExchangeRate(fromCur, toCur);
                }
            }
        }).observes('fromCurrency', 'toCurrency'),

        calculateAmount: (function () {
            var amount = this.get('currencyAmount');
            var curVal = this.get('currencyVal');
            var fromCur = this.get('fromCurrency');
            var toCur = this.get('toCurrency');
            var calRate;

            if (fromCur !== toCur && curVal && curVal.rate) {
                this.set('isDataAvailable', true);
                this.stopLoadingProgress();
                calRate = curVal.rate * amount;
            } else {
                calRate = amount;
            }
            this.set('currencyAmount', amount);
            this.set('currencyRate', calRate);
        }).observes('currencyAmount', 'currencyVal.rate'),

        getApplicableExchangeRate: function getApplicableExchangeRate(defaultCur, selectedCur) {
            var that = this;
            var onSuccess = function onSuccess(result) {
                that.onDataSuccess();
                if (result) {
                    var curVal = that.get('currencyVal');
                    var amount = that.get('currencyAmount');
                    var calRate = curVal.rate * amount;
                    that.set('currencyAmount', amount);
                    that.set('currencyRate', calRate);
                }
            };

            var curPair = [defaultCur, selectedCur].join('');
            var tempCurency = priceService['default'].currencyDS.getCurrency(curPair, onSuccess);
            that.startLoadingProgress();
            that.set('currencyVal', tempCurency);
        },

        onCheckDataAvailability: function onCheckDataAvailability() {
            var calculateVal = this.get('currencyVal');
            return calculateVal;
        },

        onClearData: function onClearData() {
            this.set('dropDownList', {});
        },

        actions: {
            currencyDropdownFrom: function currencyDropdownFrom(option) {
                this.set('fromCurrency', option.cur);
            },

            currencyDropdownTo: function currencyDropdownTo(option) {
                this.set('toCurrency', option.cur);
            },

            processKeyDown: function processKeyDown(value, event) {
                var key = event.which ? event.which : event.keyCode;
                var allowedKeys = utils['default'].Constants.NumericKeys;
                if (allowedKeys.indexOf(key) >= 0) {
                    return true;
                }
                event.preventDefault();
                return false;
            }
        }
    });

});