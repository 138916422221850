define('ir-app/models/price/price-business-entities/system-definitions', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        // Basic Information

        countryDefinitions: null,

        init: function init() {
            this._super();
            this.set('countryDefinitions', Ember['default'].A());
        },

        setData: function setData(systemDefinitionsData) {
            var that = this;

            Ember['default'].$.each(systemDefinitionsData, function (key, value) {
                that.set(key, value);
            });
        }
    });

});