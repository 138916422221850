define('ir-app/components/stk-specific-components/financial-statement-comparison', ['exports', 'ember', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service'], function (exports, Ember, priceService, sharedService) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        app: {}, // language settings
        layoutName: 'components/stk-specific-components/financial-statement-comparison',
        contents: {}, // financial available quarters -for loading purpose
        toSelection: {},
        selectedPeriodType: {},
        selectedSymbol: {},
        dataSet1: {},
        dataSet2: {},
        dataSet3: {},
        dataSet4: {},
        dataSet5: {},
        dataSet6: {},
        dataSet7: {},
        dataSet8: {},
        dataSet9: {},

        compareExg1: {},
        compareExg2: {},
        compareExg3: {},

        compareSym1: {},
        compareSym2: {},
        compareSym3: {},
        compareSym1Des: {},
        compareSym2Des: {},
        compareSym3Des: {},

        dataCompareObjectIS1: {},
        dataCompareObjectIS2: {},
        dataCompareObjectIS3: {},

        dataCompareObjectBS1: {},
        dataCompareObjectBS2: {},
        dataCompareObjectBS3: {},

        dataCompareObjectCF1: {},
        dataCompareObjectCF2: {},
        dataCompareObjectCF3: {},

        xDataSet: {},
        ratioList: [],
        tempID: undefined,

        actions: {

            onSymbolSelected: function onSymbolSelected(item) {
                var that = this;
                var exchange;
                if (item.exg) {
                    exchange = item.exg;
                } else {
                    exchange = this.get('exg');
                }
                var key = exchange + '~' + item.sym + '~' + item.lDes;

                var successFn = function successFn() {
                    that.set('selectedSymbol', key);
                    that.set('searchSymbol', item.lDes);
                };

                var errorFn = function errorFn() {};

                priceService['default'].downloadExchangeProperty(exchange, sharedService['default'].userSettings.currentLanguage, successFn, errorFn);
            },
            showSearchPopup: function showSearchPopup() {
                var modal = this.get('symbolSearch');
                modal.send('showModalPopup');
            },
            closeSearchPopup: function closeSearchPopup() {
                var modal = this.get('symbolSearch');
                modal.send('closeModalPopup');
            },

            onSymbol2Selected: function onSymbol2Selected(item) {
                var that = this;
                var exchange;
                if (item.exg) {
                    exchange = item.exg;
                } else {
                    exchange = this.get('exg');
                }
                var key = exchange + '~' + item.sym + '~' + item.lDes;

                var successFn = function successFn() {
                    that.set('selectedSymbol2', key);
                    that.set('searchSymbol2', item.lDes);
                };

                var errorFn = function errorFn() {};

                priceService['default'].downloadExchangeProperty(exchange, sharedService['default'].userSettings.currentLanguage, successFn, errorFn);
            },
            showSearchPopup2: function showSearchPopup2() {
                var modal = this.get('symbolSearch2');
                modal.send('showModalPopup');
            },
            closeSearchPopup2: function closeSearchPopup2() {
                var modal = this.get('symbolSearch2');
                modal.send('closeModalPopup');
            },

            onSymbol3Selected: function onSymbol3Selected(item) {
                var that = this;
                var exchange;
                if (item.exg) {
                    exchange = item.exg;
                } else {
                    exchange = this.get('exg');
                }
                var key = exchange + '~' + item.sym + '~' + item.lDes;

                var successFn = function successFn() {
                    that.set('selectedSymbol3', key);
                    that.set('searchSymbol3', item.lDes);
                };

                var errorFn = function errorFn() {};

                priceService['default'].downloadExchangeProperty(exchange, sharedService['default'].userSettings.currentLanguage, successFn, errorFn);
            },
            showSearchPopup3: function showSearchPopup3() {
                var modal = this.get('symbolSearch3');
                modal.send('showModalPopup');
            },
            closeSearchPopup3: function closeSearchPopup3() {
                var modal = this.get('symbolSearch3');
                modal.send('closeModalPopup');
            },
            clear1: function clear1() {
                this.set('searchSymbol', '');
                this.set('dataCompareObjectIS1', undefined);
                this.set('dataCompareObjectBS1', undefined);
                this.set('dataCompareObjectCF1', undefined);
                this.set('compareExg1', undefined);
                this.set('compareSym1', undefined);
            },
            clear2: function clear2() {
                this.set('searchSymbol2', '');
                this.set('dataCompareObjectIS2', undefined);
                this.set('dataCompareObjectBS2', undefined);
                this.set('dataCompareObjectCF2', undefined);
                this.set('compareExg2', undefined);
                this.set('compareSym2', undefined);
            },
            clear3: function clear3() {
                this.set('searchSymbol3', '');
                this.set('dataCompareObjectIS3', undefined);
                this.set('dataCompareObjectBS3', undefined);
                this.set('dataCompareObjectCF3', undefined);
                this.set('compareExg3', undefined);
                this.set('compareSym3', undefined);
            }
        }

    });

});