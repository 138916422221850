define('ir-app/controllers/ir-widgets/fair-value-widgets/analyst-view', ['exports', 'ember', 'ir-app/utils/utils', 'ir-app/controllers/base-array-controller', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/models/price/price-constants', 'ir-app/models/shared/language/language-data-store', 'ir-app/components/stk-specific-components/portal-range-display-slider', 'ir-app/models/price/price-protocols/mix-web/mix-request-generator'], function (exports, Ember, Utils, BaseArrayController, PriceService, SharedService, PriceConstants, LanguageDataStore, PortalRangeSlider, RequestGenerator) {

    'use strict';

    /**
     * Created by lakmaln on 9/26/2016.
     */

    /* global moment */
    exports['default'] = BaseArrayController['default'].extend({

        stock: {},
        providersList: Ember['default'].A(),
        periodsList: Ember['default'].A(),
        period: 3,
        providers: [],
        filterDate: '',
        filterProviders: [],
        isAdjusted: true,
        consensusMax: 5,
        consensusMin: 1,
        consensusMessage: '',
        needs: ['application'],

        onLoadWidget: function onLoadWidget() {
            this.setErrorMessage();
        },

        onPrepareData: function onPrepareData() {
            var that = this;
            that.set('stock', PriceService['default'].stockDS.getStock(that.get('exg'), that.get('sym')));
            PriceService['default'].addSymbolRequest(that.get('exg'), that.get('sym'));

            that.set('periodsList', [{ id: 2, value: that.get('app').lang.labels.threeMonths }, { id: 3, value: that.get('app').lang.labels.sixMonths }, { id: 4, value: that.get('app').lang.labels.oneYear }, { id: 5, value: that.get('app').lang.labels.twoYears }]);
            //that.set('periodsList', [{id: 1, value: that.get('app').lang.labels.currentQuarter},
            //    {id: 2, value: that.get('app').lang.labels.threeMonths},
            //    {id: 3, value: that.get('app').lang.labels.sixMonths},
            //    {id: 4, value: that.get('app').lang.labels.oneYear},
            //    {id: 5, value: that.get('app').lang.labels.twoYears}
            //]);
            that.setFilterDate(that.get('period'));

            var fairValues = PriceService['default'].fairValuesDS.getStoredObject(that.get('exg'), that.get('sym'), SharedService['default'].userSettings.currentLanguage, PriceConstants['default'].DataTypes.FairValuesDataStore.FairValues, function () {
                that.onDataSuccess();
            }, function () {
                that.onDataError();
            }).fairValuesList;

            this.set('model', fairValues);
        },

        onClearData: function onClearData() {
            PriceService['default'].removeSymbolRequest(this.get('exg'), this.get('sym'));
        },

        onAfterRender: function onAfterRender() {
            this._renderChartView();
        },

        filteredContent: (function () {
            var that = this;
            var dateFilter = that.get('filterDate');
            var providersFilter = that.get('filterProviders');
            var refId = 1;

            var filteredFairValues = that.get('arrangedContent').filter((function (that) {
                // eslint-disable-line
                return function (fairValue) {

                    if (dateFilter) {
                        if (that.checkFilterMatch(fairValue, dateFilter, providersFilter)) {
                            Ember['default'].set(fairValue, "refId", refId);
                            refId++;
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return true;
                    }
                };
            })(that));

            return filteredFairValues;
        }).property('arrangedContent', 'filterDate', 'filterProviders'),

        checkFilterMatch: function checkFilterMatch(fairValue, dateFilter, providersFilter) {
            var isMatchedDateFilter = !dateFilter;
            var isMatchedProvidersFilter = !providersFilter;

            if (!isMatchedDateFilter) {
                var fvDate = moment(fairValue['date'], "YYYYMMDD");
                fvDate.format();

                if (fvDate > dateFilter) {
                    isMatchedDateFilter = true;
                }
            }

            if (!isMatchedProvidersFilter) {
                providersFilter.forEach(function (provider) {
                    if (provider && provider === fairValue['comId']) {
                        isMatchedProvidersFilter = true;
                        //this.break; //TODO: Check
                    }
                });
            }

            return isMatchedDateFilter && isMatchedProvidersFilter;
        },

        onCheckDataAvailability: function onCheckDataAvailability() {
            var fairValues = PriceService['default'].fairValuesDS.getStoredObject(this.get('exg'), this.get('sym'), SharedService['default'].userSettings.currentLanguage).fairValuesList;

            var isAvailable = fairValues && fairValues.length > 0;
            if (isAvailable) {
                var blackList = this.get('providerBlackList');
                var allProvidersList = PriceService['default'].fairValuesDS.getProvidersList();
                var validProviders = [];
                for (var i = 0; i < allProvidersList.length; i++) {
                    var found = false;
                    if (blackList && blackList.length === 0) {
                        found = true;
                    }

                    if (blackList) {
                        for (var j = 0; j < blackList.length; j++) {
                            if (blackList[j] === allProvidersList[i].id) {
                                found = false;
                                break;
                            } else {
                                found = true;
                            }
                        }
                    } else {
                        found = true;
                    }

                    if (found) {
                        validProviders.push(allProvidersList[i]);
                    }
                }
                this.set('providersList', validProviders);
            }
            return isAvailable;
        },

        _renderChartView: (function () {
            var detailViewController = 'ir-mobile/chart-widgets/analyst-view-chart-widget';
            var controllerString = 'controller:' + detailViewController;
            var widgetController = this.container.lookupFactory(controllerString).create();
            widgetController.set('chartContent', this.get('filteredContent'));
            widgetController.set('chartPeriod', this.get('period'));
            widgetController.set('sym', this.get('sym'));
            widgetController.set('exg', this.get('exg'));
            widgetController.initializeWidget({ wn: controllerString.split('/').pop() });

            var route = this.container.lookup('route:' + this.get('controllers.application.currentRouteName'));

            route.render(detailViewController, {
                into: 'ir-widgets/fair-value-widgets/analyst-view',
                outlet: 'chartView',
                controller: widgetController
            });

            widgetController.onUnloadWidget();
        }).observes('filteredContent'),

        setFilterDate: function setFilterDate(periodId) {
            var currentDate = new Date();
            var langStore = this.get('app').lang.labels;

            switch (periodId) {
                case 1:
                    this.set('filterDate', this.getQuarterDays(currentDate));
                    this.set('consensusMessage', langStore.currentQuarterConsensus);
                    break;
                case 2:
                    if (currentDate.getMonth() >= 3) {
                        this.set('filterDate', new Date(currentDate.getFullYear(), currentDate.getMonth() - 3, currentDate.getDate()));
                    } else {
                        this.set('filterDate', new Date(currentDate.getFullYear() - 1, currentDate.getMonth() + 9, currentDate.getDate()));
                    }
                    this.set('consensusMessage', langStore.threeMonthsConsensus);
                    break;
                case 3:
                    if (currentDate.getMonth() >= 6) {
                        this.set('filterDate', new Date(currentDate.getFullYear(), currentDate.getMonth() - 6, currentDate.getDate()));
                    } else {
                        this.set('filterDate', new Date(currentDate.getFullYear() - 1, currentDate.getMonth() + 6, currentDate.getDate()));
                    }
                    this.set('consensusMessage', langStore.sixMonthsConsensus);
                    break;
                case 4:
                    this.set('filterDate', new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), currentDate.getDate()));
                    this.set('consensusMessage', langStore.oneYearConsensus);
                    break;
                case 5:
                    this.set('filterDate', new Date(currentDate.getFullYear() - 2, currentDate.getMonth(), currentDate.getDate()));
                    this.set('consensusMessage', langStore.twoYearConsensus);
                    break;
            }
        },

        getQuarterDays: function getQuarterDays(d) {
            return new Date(d.getFullYear(), (this.getQuarter(d) - 1) * 3, 1);
        },

        getQuarter: function getQuarter(d) {
            var m = Math.floor(d.getMonth() / 3) + 1;
            return m > 4 ? m - 5 : m;
        },

        actions: {
            applyFilters: function applyFilters() {
                var radios = document.getElementsByName('showValues');

                for (var i = 0; i < radios.length; i++) {
                    if (radios[i].checked) {
                        if (radios[i].value === '0') {
                            this.set('isAdjusted', true);
                        } else {
                            this.set('isAdjusted', false);
                        }
                        break;
                    }
                }

                this.setFilterDate(this.get('period'));
                this.set('filterProviders', this.get('providers'));
                this._renderChartView();
            },
            changeProviders: function changeProviders(options, isLoading) {
                this.set('providers', options);

                if (isLoading) {
                    this.set('filterProviders', this.get('providers'));
                }
            }
        }
    });

    Ember['default'].Handlebars.helper('portal-range-display-slider', PortalRangeSlider['default']);

    Ember['default'].Handlebars.helper('getRecChgIndicatorStyle', function (ratingScore, prevRatingScore) {
        var value = 'no-value-icon font-small';

        if (ratingScore && prevRatingScore) {
            if (prevRatingScore < ratingScore) {
                value = 'green up';
            } else if (prevRatingScore > ratingScore) {
                value = 'red down';
            } else if (prevRatingScore === ratingScore) {
                value = 'icon-no-change no-change';
            }
        }
        return value;
    });

    Ember['default'].Handlebars.helper('getRecommendation', function (score) {
        score = Math.round(score);
        var scoreVal = parseInt(score, 10);
        var langStore = LanguageDataStore['default'].getLanguageObj().lang.labels;

        switch (scoreVal) {
            case 1:
                return langStore.sell;
            case 2:
                return langStore.underPerform;
            case 3:
                return langStore.hold;
            case 4:
                return langStore.outPerform;
            case 5:
                return langStore.buy;
            default:
                return '--';
        }
    });

    Ember['default'].Handlebars.helper('getUpsidePotential', function (ltp, targetPrice, isPercentage) {
        var divideFactor = 1;

        if (isNaN(ltp) || isNaN(targetPrice) || ltp === 0 || targetPrice === 0) {
            return '-';
        }
        // var value = ((parseFloat(targetPrice) / (parseFloat(ltp) * (divideFactor ? divideFactor : 1))) - 1) * 100;
        var value = (parseFloat(targetPrice) / (parseFloat(ltp) * divideFactor) - 1) * 100;

        if (isPercentage) {
            return Utils['default'].formatters.formatNumberPercentage(value, 1);
        } else {
            return Utils['default'].formatters.formatNumber(value, 1);
        }
    });

    Ember['default'].Handlebars.helper('getAvgTargetPriceSummary', function (content, isAdjusted) {
        var totalPrice = 0;
        var recordCount = 0;
        var avgPrice = 0;

        Ember['default'].$.each(content, function (key, value) {
            //if (isAdjusted) {
            totalPrice = totalPrice + value.adjTargetPrice;
            //} else {
            //    totalPrice = totalPrice + value.targetPrice;
            //}
            recordCount++;
        });

        if (recordCount !== 0) {
            avgPrice = totalPrice / recordCount;
            if (!isNaN(avgPrice)) {
                if (isAdjusted) {
                    return "*" + Utils['default'].formatters.formatNumber(avgPrice, 2);
                } else {
                    return Utils['default'].formatters.formatNumber(avgPrice, 2);
                }
            }
        }
        return '--';
    });

    Ember['default'].Handlebars.helper('getUpsidePotentialSummary', function (content, ltp, isAdjusted, isPercentage) {
        var totalPotential = 0;
        var recordCount = 0;
        var avgPotential = 0;
        var divideFactor = 1;

        if (isNaN(ltp) || content === null || ltp === 0 || content.length === 0) {
            return '--';
        }

        Ember['default'].$.each(content, function (key, value) {
            //if (isAdjusted) {
            totalPotential = totalPotential + (parseFloat(value.adjTargetPrice) / (parseFloat(ltp) * divideFactor) - 1) * 100;
            //} else {
            //    totalPotential = totalPotential + ((parseFloat(value.targetPrice) / (parseFloat(ltp) * divideFactor)) - 1) * 100;
            //}
            recordCount++;
        });

        if (recordCount !== 0) {
            avgPotential = totalPotential / recordCount;
            if (!isNaN(avgPotential)) {
                if (isPercentage) {
                    return Utils['default'].formatters.formatNumberPercentage(avgPotential, 1);
                } else {
                    return Utils['default'].formatters.formatNumber(avgPotential, 1);
                }
            }
        }
        return '--';
    });

    Ember['default'].Handlebars.helper('getRecommendationsSummary', function (content) {
        var recordCount = 0;

        Ember['default'].$.each(content, function () {
            recordCount++;
        });
        return recordCount;
    });

    Ember['default'].Handlebars.helper('getMeanConsensusValue', function (content, isPercentage) {
        var sumConsensus = 0;
        var recordCount = 0;
        var meanConsensusVal = 0;

        Ember['default'].$.each(content, function (key, value) {
            sumConsensus = sumConsensus + parseInt(value.ratingScore, 0);
            recordCount++;
        });

        if (recordCount !== 0) {
            meanConsensusVal = sumConsensus / recordCount;
        }

        if (!isNaN(meanConsensusVal)) {

            if (isPercentage) {
                return (meanConsensusVal - 1) * 100 / 4;
            } else {
                return meanConsensusVal;
            }
        } else {
            return 0;
        }
    });

    Ember['default'].Handlebars.helper('getDocURL', function (docDownloadURL, docId) {
        if (docId) {
            return RequestGenerator['default'].generateDocumentDownloadUrl(docId);
        }
        return '#';
    });

    Ember['default'].Handlebars.helper('getDocIcon', function (docId) {
        if (docId) {
            return 'fa fa-file-pdf-o';
        } else {
            return 'no-value-icon font-small';
        }
    });

    Ember['default'].Handlebars.helper('getAdjustedIndicator', function (adjustedValue, reportedValue) {
        if (adjustedValue !== reportedValue) {
            return "*";
        }
    });

});