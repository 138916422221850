define('ir-app/models/shared/language/language-data-store', ['exports', 'ember', 'ir-app/config/app-config', 'ir-app/models/shared/shared-service', 'ir-app/app-events', 'ir-app/utils/utils', 'ir-app/models/shared/shared-data-stores/theme-data-store'], function (exports, Ember, appConfig, sharedService, appEvents, utils, themeDataStore) {

    'use strict';

    exports['default'] = (function () {
        var langStore = {};
        var isLangSet = false;
        var isChangeLangSet = false;

        var selectedLangObj = Ember['default'].Object.extend({
            lang: {}
        }).create();

        var changeLangObj = Ember['default'].Object.extend({
            lang: {}
        }).create();

        var getLanguageObj = function getLanguageObj() {
            if (!isLangSet) {
                selectedLangObj.set('lang', langStore[sharedService['default'].userSettings.currentLanguage]);
                isLangSet = true;
            }

            return selectedLangObj;
        };

        var getChangeLanguageObj = function getChangeLanguageObj() {
            if (!isChangeLangSet) {
                changeLangObj.set('lang', getChangeToLanguage(sharedService['default'].userSettings.currentLanguage));
                isChangeLangSet = true;
            }

            return changeLangObj;
        };

        var setLanguageObj = function setLanguageObj(langCode, langObj) {
            if (utils['default'].validators.isAvailable(langCode) && langObj) {
                langStore[langCode] = langObj;
            } else {
                utils['default'].logger.logError('Language data not available for : ' + langCode);
            }
        };

        var changeLanguage = function changeLanguage(langCode) {
            if (utils['default'].validators.isAvailable(langCode)) {
                var langObj = langStore[langCode];

                if (langObj) {
                    selectedLangObj.set('lang', langObj);
                    changeLangObj.set('lang', getChangeToLanguage(langCode));

                    // Save current language in user's local machine when language changes
                    sharedService['default'].userSettings.set('currentLanguage', langCode);
                    sharedService['default'].userSettings.save();

                    themeDataStore['default'].setOrientationClass(langCode);

                    // Notify subscribed widgets about the language change
                    appEvents['default'].onLanguageChanged(langCode);
                } else {
                    utils['default'].logger.logError('Language data not available for : ' + langCode);
                }
            } else {
                utils['default'].logger.logError('Language code not available');
            }
        };

        var getChangeToLanguage = function getChangeToLanguage(selectedLanguage) {
            var changeLang = {};

            Ember['default'].$.each(appConfig['default'].customisation.supportedLanguages, function (key, val) {
                if (selectedLanguage !== val.code) {
                    changeLang = val;
                    return changeLang;
                }
            });

            return changeLang;
        };

        return {
            getChangeToLanguage: getChangeToLanguage,
            getLanguageObj: getLanguageObj,
            getChangeLanguageObj: getChangeLanguageObj,
            setLanguageObj: setLanguageObj,
            changeLanguage: changeLanguage
        };
    })();

});