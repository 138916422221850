define('ir-app/controllers/ir-widgets/alert-widgets/alert-widget-remove', ['exports', 'ir-app/controllers/base-controller'], function (exports, BaseController) {

	'use strict';

	/**
	 * Created by isurup on 1/7/2019.
	 */

	exports['default'] = BaseController['default'].extend({});

});