define('ir-app/controllers/ir-mobile/calculator-widgets/investment-calculator', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/models/chart/chart-constants', 'ir-app/utils/utils', 'ir-app/config/app-config', 'ir-app/models/shared/shared-service'], function (exports, Ember, BaseController, priceService, chartConstants, utils, appConfig, sharedService) {

    'use strict';

    /**
     * Created by asankah on 3/7/2016.
     */

    exports['default'] = BaseController['default'].extend({

        ohlcSeries: null,
        startDateStats: null,
        startPrice: null,
        endDateStats: null,
        endPrice: null,
        fromDate: null,
        toDate: null,
        today: null, // this is to control the --> calender selectable range
        amount: null,
        shares: null,
        totReturn: null,
        rtnPercentage: null,
        annualReturn: null,
        totDividends: 0,
        calType: null,
        isReInvst: null,
        isCalculated: null,
        validInputs: null,
        invalidInputs: null,
        notTradedOnStartDate: false,
        notTradedOnEndDate: false,
        isAmountEnabled: null,
        isSharesEnabled: null,
        YEAR_IN_MILLS: 86400000,
        DAYS_PER_YEAR: 365,
        currencyList: null,
        defaultCur: null,
        selectedCur: null,
        rateDefSelec: 1,
        dataAvailable: false,
        oldestDate: undefined,
        showInSlider: true,
        stock: undefined,
        dropdownCurList: [],
        dropdownList: [],
        showCur: '',

        // Base overrides
        onPrepareData: function onPrepareData() {
            var that = this;
            this.set('validInputs', false);
            this.set('invalidInputs', false);
            this.set('today', new Date());
            this.set('calType', 1);
            this.set('isReInvst', false);
            this.set('isCalculated', false);
            this.set('showInSlider', this.get('showInSlider'));
            this.set('stock', priceService['default'].stockDS.getStock(that.get('exg'), that.get('sym')));

            var reqSuccessFn = function reqSuccessFn() {
                that.set('defaultCur', priceService['default'].stockDS.getStock(that.get('exg'), that.get('sym')).cur);
                var selectedObj = { val: that.get('defaultCur'), selectedDesc: that.get('defaultCur') };
                that.set('selectedCur', selectedObj);
                // TODO: [ASANKA] get this from widget args
                that.set('currencyList', appConfig['default'].customisation.curList);
                if (that.get('currencyList').indexOf(that.get('defaultCur')) < 0) {
                    that.get('currencyList').push(that.get('defaultCur'));
                }
                var index = that.get('currencyList').indexOf(that.get('defaultCur'));
                // pre-fetch currency rates data
                Ember['default'].$.each(that.get('currencyList'), function (key, value) {
                    if (that.get('defaultCur') !== value) {
                        priceService['default'].currencyDS.getCurrency([that.get('defaultCur'), value].join(''));
                    }
                    var dropDownCur = { view: value };
                    that.get('dropdownList').pushObject(dropDownCur);
                });
                that.set('dropdownCurList', that.get('dropdownList'));
                that.set('showCur', that.get('dropdownCurList')[index]);
            };

            var reqFailureFn = function reqFailureFn() {
                utils['default'].logger.logDebug('Error in symbol validation response.');
            };

            priceService['default'].sendSymbolValidationRequest(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, reqSuccessFn, reqFailureFn);

            priceService['default'].downloadHistoryOHLCData({
                exchange: this.get('exg'),
                symbol: this.get('sym'),
                begin: undefined,

                reqSuccessFn: function reqSuccessFn() {
                    that.set('ohlcSeries', priceService['default'].ohlcDS.getOHLCSeries(that.get('exg'), that.get('sym'), chartConstants['default'].ChartCategory.History));
                    that.set('oldestDate', that.get('ohlcSeries').ohlcDataPoints[0].DT);
                },
                reqFailureFn: function reqFailureFn() {
                    if (that.onErrorFn && Ember['default'].$.isFunction(that.onErrorFn)) {
                        that.onErrorFn();
                    }
                    utils['default'].logger.logDebug('Data Unavailable For History');
                }
            });
        },

        onCheckDataAvailability: function onCheckDataAvailability() {
            return this.get('dataAvailable');
        },

        calculate: (function () {
            this.set('dataAvailable', true);
            var endDateStats = this.get('endDateStats');
            var totDividends = this.get('totDividends');
            var startDateStats = this.get('startDateStats');
            var rateDefSelec = this.get('rateDefSelec');

            // calculate total return
            var totalReturn = (this.getFilteredPriceValue(endDateStats.Close) + totDividends - this.getFilteredPriceValue(startDateStats.Close)) * this.get('shares') * rateDefSelec;
            this.set('totReturn', totalReturn.toFixed(3));

            // calculate percentage return and annualized return if shares available
            if (this.get('shares') > 0) {
                var rtnPercentage = (this.getFilteredPriceValue(endDateStats.Close) + totDividends - this.getFilteredPriceValue(startDateStats.Close)) / this.getFilteredPriceValue(startDateStats.Close) * 100;
                this.set('rtnPercentage', rtnPercentage.toFixed(2));
                var numberOfYears = (endDateStats.DT - startDateStats.DT) / this.get('YEAR_IN_MILLS') / this.get('DAYS_PER_YEAR');
                var annualRtn = (Math.pow((this.getFilteredPriceValue(endDateStats.Close) + totDividends) / this.getFilteredPriceValue(startDateStats.Close), 1 / numberOfYears) - 1) * 100;
                this.set('annualReturn', annualRtn.toFixed(2));
            }

            this.set('isCalculated', true);
        }).observes('totDividends'),

        resetResults: function resetResults() {
            // reset results
            this.set('notTradedOnStartDate', false);
            this.set('notTradedOnEndDate', false);
            this.set('isCalculated', false);
            this.set('totReturn', 0);
            this.set('rtnPercentage', 0);
            this.set('annualReturn', 0);
        },

        validateInputs: function validateInputs() {
            // validate the inputs
            if (this.get('fromDate') == null || this.get('toDate') == null || this.get('toDate').getTime() < this.get('fromDate').getTime() || this.get('calType') === 1 && (!utils['default'].validators.isAvailable(this.get('amount')) || isNaN(this.get('amount'))) || this.get('calType') === 2 && (!utils['default'].validators.isAvailable(this.get('shares')) || isNaN(this.get('shares')))) {
                this.set('invalidInputs', true);
                this.set('validInputs', false);
            } else {
                this.set('invalidInputs', false);
                this.set('validInputs', true);
            }
        },

        actions: {
            calculateOnClick: function calculateOnClick() {
                this.resetResults();
                this.validateInputs();

                var rateDefSelec = this.getApplicableExchangeRate(this.get('defaultCur'), this.get('selectedCur').val);
                this.set('rateDefSelec', rateDefSelec);

                var date = this.get('fromDate');
                var startDateStats = this.get('ohlcSeries').queryNearestPoint(date.getTime());
                this.set('startDateStats', startDateStats);
                this.set('startPrice', this.getFilteredPriceValue(startDateStats.Close) * rateDefSelec);
                var statDate = startDateStats.DT;
                this.set('notTradedOnStartDate', utils['default'].validators.compareByDate(date, statDate) !== 0);
                date = this.get('toDate');
                var endDateStats = this.get('ohlcSeries').queryNearestPoint(date.getTime());
                this.set('endDateStats', endDateStats);
                this.set('endPrice', this.getFilteredPriceValue(endDateStats.Close) * rateDefSelec);
                statDate = endDateStats.DT;
                this.set('notTradedOnEndDate', utils['default'].validators.compareByDate(date, statDate) !== 0);

                if (utils['default'].validators.compareByDate(startDateStats.DT, endDateStats.DT) === 0) {
                    this.set('invalidInputs', true);
                    this.set('validInputs', false);
                    return;
                }

                if (this.get('calType') === 1) {
                    // amount is set in selected Currency). need to calculate shares
                    var shares = this.get('amount') / (rateDefSelec * this.getFilteredPriceValue(startDateStats.Close));
                    this.set('shares', Math.floor(shares));
                } else {
                    var amount = this.get('shares') * this.getFilteredPriceValue(startDateStats.Close) * rateDefSelec;
                    this.set('amount', amount.toFixed(2));
                }

                var totDividends = 0;
                this.set('totDividends', totDividends);
                this.calculate();

                if (this.get('isReInvst')) {
                    this.startLoadingProgress();
                    this.getDividend(this.get('fromDate'), this.get('toDate'));
                }
            },
            onChangeCurrency: function onChangeCurrency(value) {
                Ember['default'].set(this.get('selectedCur'), 'val', value.view);
            }
        },

        /**
         * convert currency of specific exchanges
         */
        getFilteredPriceValue: function getFilteredPriceValue(price) {
            if (this.get('exg') === 'KSE') {
                return price / 1000;
            }
            return price;
        },
        getFilteredDividendValue: (function (dividend) {
            if (this.get('exg') === 'KSE') {
                return dividend / 1000;
            }
            return dividend;
        }).observes('totDividends'),

        calTypeChanged: (function () {
            if (this.get('calType') === 1) {
                this.set('shares', null);
                this.set('isAmountEnabled', true);
                this.set('isSharesEnabled', false);
            } else {
                this.set('amount', null);
                this.set('isSharesEnabled', true);
                this.set('isAmountEnabled', false);
            }
        }).observes('calType'),

        getApplicableExchangeRate: function getApplicableExchangeRate(defaultCur, selectedCur) {
            if (defaultCur === selectedCur) {
                return 1;
            } else {
                var curPair = [defaultCur, selectedCur].join('');
                var applicableCur = priceService['default'].currencyDS.getCurrency(curPair);
                return applicableCur.rate;
            }
        },

        getDividend: function getDividend(fromDate, toDate) {
            var dtStart = this.utils.formatters.convertDateToyyyymmdd(fromDate);
            var dtEnd = this.utils.formatters.convertDateToyyyymmdd(toDate);
            var that = this;

            var onSuccess = function onSuccess(result) {
                that.onDataSuccess();
                that.set('dataAvailable', true);
                var totDividends = that.utils.formatters.formatNumber(result, 2);
                totDividends = that.getFilteredDividendValue(parseFloat(totDividends));

                that.set('totDividends', parseFloat(totDividends));
            };

            var onError = function onError() {
                that.set('dataAvailable', false);
                that.onDataError();
            };

            priceService['default'].sendDividendDataRequest(that.get('exg'), that.get('sym'), dtStart, dtEnd, onSuccess, onError);
        },

        changedInputs: (function () {
            if (!(this.get('fromDate') == null || this.get('toDate') == null || this.get('toDate').getTime() < this.get('fromDate').getTime() || this.get('calType') === 1 && (!utils['default'].validators.isAvailable(this.get('amount')) || isNaN(this.get('amount'))) || this.get('calType') === 2 && (!utils['default'].validators.isAvailable(this.get('shares')) || isNaN(this.get('shares'))))) {

                if (this.get('showInSlider')) {
                    this.set('validInputs', true);
                }
                this.set('invalidInputs', false);
            }
        }).observes('fromDate', 'toDate', 'amount', 'shares')
    });

    Ember['default'].Handlebars.helper('getPositiveNegativeStyle', function (value) {
        if (value >= 0) {
            return 'padding-medium snap-period-wrapper-green';
        } else {
            return 'padding-medium snap-period-wrapper-red';
        }
    });

});