define('ir-app/models/chart/chart-business-entities/ohlc', ['exports'], function (exports) {

    'use strict';

    exports['default'] = (function () {
        // CharIQ expects Pascal case references as its data format
        var getOHLCObj = function getOHLCObj(args) {
            var ohlcObj = null;
            if (args) {
                ohlcObj = {
                    'DT': args.dt, // Javascript Date
                    'Open': args.open,
                    'High': args.high,
                    'Low': args.low,
                    'Close': args.close,
                    'Volume': args.volume,
                    'Turnover': args.turnover,
                    'Change': args.change,
                    'PerChange': args.perChange

                };
            }
            return ohlcObj;
        };
        return {
            getOHLCObj: getOHLCObj
        };
    })();

});