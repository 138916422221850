define('ir-app/models/price/price-protocols/mix-web/ibe-mix-request-handler', ['exports', 'ember', 'ir-app/models/shared/communication-adapters/web-http-connection', 'ir-app/models/price/price-protocols/mix-web/ibe-mix-response-handler', 'ir-app/models/price/price-protocols/mix-web/mix-request-generator', 'ir-app/models/shared/shared-cache-managers/mix-cache-manager'], function (exports, Ember, WebConnection, IBEResponseHandler, RequestGenerator, MixCacheManager) {

    'use strict';

    /**
     * Created by akilam on 3/23/2017.
     */

    exports['default'] = (function () {

        //
        // Fundamental Data specific requests
        //
        var loadClientFundamentalData = function loadClientFundamentalData(exchange, symbol, language, mode, postSuccess, Error, cachePolicy, level, isCal) {
            var cacheKey = MixCacheManager['default'].getClientFundamentalDataCacheKey(cachePolicy, exchange, symbol, mode, language, level, isCal);
            var isCachedData = MixCacheManager['default'].isCachedData(cachePolicy, cacheKey, exchange, symbol, language);

            if (!isCachedData) {
                WebConnection['default'].sendAjaxRequest({
                    url: RequestGenerator['default'].generateClientFundamentalDataUrl(exchange, symbol, mode, language, level, isCal),
                    onSuccess: function onSuccess(dataObj) {

                        MixCacheManager['default'].persistMixResponse(cachePolicy, cacheKey, dataObj);
                        IBEResponseHandler['default'].processClientFundamentalDataResponseForPortal(dataObj, exchange, symbol, language, mode, isCal);

                        if (Ember['default'].$.isFunction(postSuccess)) {
                            postSuccess();
                        }
                    },
                    onError: function onError() {
                        // TODO: Handle error on fundamental data loading
                        if (Ember['default'].$.isFunction(Error)) {
                            Error();
                        }
                    }
                });
            } else {
                var fundamentalMixResponse = MixCacheManager['default'].getCachedMixResponse(cacheKey);
                IBEResponseHandler['default'].processClientFundamentalDataResponseForPortal(fundamentalMixResponse, exchange, symbol, language, mode, isCal);
                if (Ember['default'].$.isFunction(postSuccess)) {
                    postSuccess();
                }
            }
        };

        var loadCompanyOwnershipLimitsData = function loadCompanyOwnershipLimitsData(exchange, symbol, language, postSuccess, Error, cachePolicy) {
            var cacheKey = MixCacheManager['default'].getOwnershipLimitsCacheKey(cachePolicy, exchange, symbol, language);
            var isCachedData = MixCacheManager['default'].isCachedData(cachePolicy, cacheKey, exchange, symbol, language);

            if (!isCachedData) {
                WebConnection['default'].sendAjaxRequest({
                    url: RequestGenerator['default'].generateCompanyOwnershipLimitsUrl(exchange, symbol),
                    onSuccess: function onSuccess(dataObj) {

                        MixCacheManager['default'].persistMixResponse(cachePolicy, cacheKey, dataObj);
                        IBEResponseHandler['default'].processCompanyOwnershipLimitsResponse(dataObj, exchange, symbol, language);

                        if (Ember['default'].$.isFunction(postSuccess)) {
                            postSuccess();
                        }
                    },
                    onError: function onError() {
                        if (Ember['default'].$.isFunction(Error)) {
                            Error();
                        }
                    }
                });
            } else {
                var comOwnerLimitsResponse = MixCacheManager['default'].getCachedMixResponse(cacheKey);
                var success = IBEResponseHandler['default'].processCompanyOwnershipLimitsResponse(comOwnerLimitsResponse, exchange, symbol, language);

                if (success && Ember['default'].$.isFunction(postSuccess)) {
                    postSuccess();
                } else if (!success && Ember['default'].$.isFunction(Error)) {
                    Error();
                }
            }
        };

        return {
            loadClientFundamentalData: loadClientFundamentalData,
            loadCompanyOwnershipLimitsData: loadCompanyOwnershipLimitsData
        };
    })();

});