define('ir-app/controllers/application', ['exports', 'ember', 'ir-app/models/shared/shared-service', 'ir-app/models/shared/language/language-data-store'], function (exports, Ember, sharedService, LanguageDataStore) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend({

        showFooterNote: true,
        footerLink: "https://www.globalfinancialmedia.com/",

        onPrepareApp: function onPrepareApp() {
            this.set('lang', sharedService['default'].userSettings.currentLanguage.toLowerCase());
            this.set('languageObj', LanguageDataStore['default'].getLanguageObj());
        },

        setArgs: function setArgs(args) {
            var that = this;

            Ember['default'].$.each(args, function (prop, val) {
                that.set(prop, val);
            });
        }
    });

});