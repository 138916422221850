define('ir-app/controllers/layout-controller', ['exports', 'ember', 'ir-app/models/shared/shared-service'], function (exports, Ember, sharedService) {

    'use strict';

    exports['default'] = (function () {
        var loadLayout = function loadLayout(rowCount, colCount, customLayout, widgetContainer) {
            sharedService['default'].userState.customWsRowCount = rowCount;
            sharedService['default'].userState.customWsColCount = colCount;
            sharedService['default'].userState.save();

            _loadWorkspaceContent(customLayout, widgetContainer);
        };

        var loadWorkspace = function loadWorkspace(workspace, customLayout, widgetContainer) {
            sharedService['default'].userState.customWS = workspace;
            sharedService['default'].userState.save();

            _loadWorkspaceContent(customLayout, widgetContainer);
        };

        var _loadWorkspaceContent = function _loadWorkspaceContent(customLayout, widgetContainer) {
            var route = customLayout.container.lookup('route:application');

            route.render('custom-workspace.custom-layout-view', {
                into: 'custom-workspace.custom-layout',
                outlet: 'custom-outlet',
                controller: customLayout
            });

            Ember['default'].run.later(function () {
                var gridDiv = Ember['default'].$('.grid-stack');
                // noinspection Eslint
                var options = {
                    cell_height: 5, // Set unit cell height
                    vertical_margin: 5, // Vertical gap between rows
                    resizable: {
                        handles: 'se' // Resizing is available in these direction
                    }
                };

                if (!gridDiv.data('gridstack')) {
                    gridDiv.gridstack(options);

                    gridDiv.on('change', function () {
                        widgetContainer.saveWorkspace();
                    });
                }
            }, 100);
        };

        return {
            loadLayout: loadLayout,
            loadWorkspace: loadWorkspace
        };
    })();

});