define('ir-app/utils/message-service', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        titleBar: null,

        showMessage: function showMessage(message, messageType, isTitle, title, buttons, listItems) {
            if (isTitle) {
                var titleBar = this.get('titleBar');

                if (titleBar) {
                    titleBar.showMessage(message, messageType);
                }
            } else {
                var messageBox = Ember['default'].View.views['message-box'];

                messageBox.send('showMessageBox', messageType, message, title, buttons, listItems);
            }
        },

        hideMessage: function hideMessage() {
            var messageBox = Ember['default'].View.views['message-box'];

            messageBox.send('closeMessageBox');
        }
    }).create();

});