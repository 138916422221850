define('ir-app/controllers/ir-widgets/tab-layouts/fact-sheet-layout', ['exports', 'ir-app/controllers/base-controller', 'ember'], function (exports, BaseController, Ember) {

    'use strict';

    /**
     * Created by akilam on 1/31/2017.
     */
    exports['default'] = BaseController['default'].extend({

        init: function init() {
            this.setValueToBaseSymbolContainer('showBaseTitle', true);
        },

        actions: {
            print: function print() {
                Ember['default'].$('#pdfbutton').hide();
                var popupWin = window.open('', '_blank', 'width=1100,height=850,location=no,left=200px');
                var bodyClass = Ember['default'].$('body').attr('class');
                Ember['default'].$(document).find('script').remove();
                popupWin.document.open();
                popupWin.document.write('<html><head>');
                popupWin.document.write(Ember['default'].$('head').html());
                popupWin.document.write('</head>');
                popupWin.document.write('<body class=' + bodyClass + ' onload="window.print()">');
                popupWin.document.write(Ember['default'].$('body').html());
                popupWin.document.write('</body>');
                popupWin.document.write('</html>');
                popupWin.document.close();
                Ember['default'].$('#pdfbutton').show();
            }
        }
    });

});