define('ir-app/models/price/price-subscription-manager', ['exports', 'ir-app/utils/utils'], function (exports, utils) {

    'use strict';

    function Node(value) {
        this.value = value;
        this.paramArray = [];
        this.reqCount = 0;
        this.children = {};
        this.hasOwnChildren = false;
        this.parent = null;

        this.setParentNode = function (node) {
            this.parent = node;
        };

        this.addChild = function (node) {
            node.setParentNode(this);
            this.children[node.value] = node;

            // Fill the param array using the parent node values
            node.paramArray.push.apply(node.paramArray, this.paramArray);
            node.paramArray.push(node.value);
        };

        this.getChildren = function () {
            return this.children;
        };

        this.increaseRequestCount = function () {
            this.reqCount++;
        };

        this.decreaseRequestCount = function () {
            this.reqCount--;

            if (this.reqCount < 0) {
                utils['default'].logger.logDebug('ERROR : Invalid subscription handling for value : ' + this.value + ' req Count : ' + this.reqCount);
                this.reqCount = 0;
            }
        };
    }

    exports['default'] = (function () {
        var root = new Node('root');

        var addSubscription = function addSubscription(type, exchange, symbol) {
            var retVal;
            var reqArray = [];

            if (symbol !== undefined) {
                retVal = _addSubscription(root, [type, exchange, symbol], 0, true, reqArray);
            } else if (exchange !== undefined) {
                retVal = _addSubscription(root, [type, exchange], 0, true, reqArray);
            } else {
                // This cannot happen. So return error value
                retVal = { retVal: -1, reqArray: reqArray };
            }

            return retVal;
        };

        var removeSubscription = function removeSubscription(type, exchange, symbol) {
            var retVal;
            var reqArray = [];

            if (symbol !== undefined) {
                retVal = _removeSubscription(root, [type, exchange, symbol], 0, true, reqArray);
            } else if (exchange !== undefined) {
                retVal = _removeSubscription(root, [type, exchange], 0, true, reqArray);
            } else {
                // This cannot happen. So return default value
                retVal = { retVal: -1, reqArray: reqArray };
            }

            return retVal;
        };

        var getCurrentSubscriptions = function getCurrentSubscriptions() {
            var reqArray = [];

            _getChildSubscriptions(root, reqArray);

            return reqArray;
        };

        var _getChildSubscriptions = function _getChildSubscriptions(currentNode, reqArray) {
            var children = currentNode.getChildren();

            for (var property in children) {
                if (children.hasOwnProperty(property)) {
                    var iterationNode = children[property];

                    if (iterationNode.reqCount > 0) {
                        _sendRequest(iterationNode.paramArray, reqArray);
                    } else {
                        _getChildSubscriptions(iterationNode, reqArray);
                    }
                }
            }
        };

        var _sendRequest = function _sendRequest(arrValues, reqArray) {
            var len = arrValues.length;
            var req;

            switch (len) {
                case 3:
                    var type = parseInt(arrValues[0], 10);

                    if (type === 77 || type === 27) {
                        req = ['{"40":"', arrValues[0], '","E":"', arrValues[1], '","L":"', arrValues[2], '"}\n'].join('');
                    } else {
                        // 80 type general requests
                        req = ['{"80":"', arrValues[0], '","E":"', arrValues[1], '","S":"', arrValues[2], '"}\n'].join('');
                    }
                    break;

                case 2:
                    // 40 type general requests
                    req = ['{"40":"', arrValues[0], '","E":"', arrValues[1], '"}\n'].join('');
                    break;

                default:
                    utils['default'].logger.logWarning('Un-known subscription tree state');
                    break;
            }

            req = req.length + req;
            reqArray[reqArray.length] = req;
        };

        var _addSubscription = function _addSubscription(_x, _x2, _x3, _x4, _x5) {
            var _again = true;

            _function: while (_again) {
                var currentNode = _x,
                    arrValues = _x2,
                    indexToProcess = _x3,
                    sendAddRequests = _x4,
                    reqArray = _x5;
                _again = false;

                // Get the children list
                var children = currentNode.getChildren();

                if (arrValues.length === indexToProcess) {
                    // Time to exit :)
                    // Check if there are child nodes under current node
                    if (currentNode.hasOwnChildren) {
                        // Send un - subscriptions to all children as they are masked by the parent node's subscription
                        for (var property in children) {
                            // All these must be 80 type requests
                            if (children.hasOwnProperty(property)) {
                                var iterationNode = children[property];

                                if (iterationNode.reqCount > 0) {
                                    var req = ['{"81":"', arrValues[0], '","E":"', arrValues[1], '","S":"', iterationNode.value, '"}\n'].join('');
                                    req = req.length + req;

                                    reqArray[reqArray.length] = req;
                                }
                            }
                        }
                    }

                    currentNode.increaseRequestCount();

                    if (sendAddRequests) {
                        return { retVal: currentNode.reqCount, reqArray: reqArray };
                    } else {
                        return { retVal: -1, reqArray: reqArray };
                    }
                }

                var param = arrValues[indexToProcess];

                var newNode = null;
                var _sendAddRequests = true;

                if (!children[param]) {
                    newNode = new Node(param);
                    currentNode.addChild(newNode);

                    if (currentNode.reqCount > 0) {
                        // Add child but no need to send requests as there is already active subscription covering it
                        _sendAddRequests = false;
                    }

                    currentNode.hasOwnChildren = true;
                } else {
                    newNode = children[param];
                }

                _x = newNode;
                _x2 = arrValues;
                _x3 = indexToProcess + 1;
                _x4 = _sendAddRequests;
                _x5 = reqArray;
                _again = true;
                children = property = iterationNode = req = param = newNode = _sendAddRequests = undefined;
                continue _function;
            }
        };

        var _removeSubscription = function _removeSubscription(_x6, _x7, _x8, _x9, _x10) {
            var _again2 = true;

            _function2: while (_again2) {
                var currentNode = _x6,
                    arrValues = _x7,
                    indexToProcess = _x8,
                    sendRemoveRequests = _x9,
                    reqArray = _x10;
                _again2 = false;

                // Get the children list
                var children = currentNode.getChildren();

                if (arrValues.length === indexToProcess) {
                    // Time to exit :)

                    // Check if there are child nodes under current node
                    if (currentNode.hasOwnChildren) {
                        // Send un - subscriptions to all children as they are masked by the parent node's subscription
                        for (var property in children) {
                            // All these must be 80 type requests
                            if (children.hasOwnProperty(property)) {
                                var iterationNode = children[property];

                                if (iterationNode.reqCount > 0) {
                                    var req = ['{"80":"', arrValues[0], '","E":"', arrValues[1], '","S":"', iterationNode.value, '"}\n'].join('');
                                    req = req.length + req;

                                    reqArray[reqArray.length] = req;
                                }
                            }
                        }
                    }

                    currentNode.decreaseRequestCount();

                    if (sendRemoveRequests) {
                        return { retVal: currentNode.reqCount, reqArray: reqArray };
                    } else {
                        return { retVal: -1, reqArray: reqArray };
                    }
                }

                var param = arrValues[indexToProcess];

                var newNode = null;
                var _sendRemoveRequests = true;

                if (!children[param]) {
                    // This is an error. Un-subscription cannot happen without a subscription
                    // Return an error
                    return { retVal: -1, reqArray: reqArray };
                } else {
                    newNode = children[param];

                    if (currentNode.reqCount > 0) {
                        _sendRemoveRequests = false;
                    }
                }

                _x6 = newNode;
                _x7 = arrValues;
                _x8 = indexToProcess + 1;
                _x9 = _sendRemoveRequests;
                _x10 = reqArray;
                _again2 = true;
                children = property = iterationNode = req = param = newNode = _sendRemoveRequests = undefined;
                continue _function2;
            }
        };

        return {
            addSubscription: addSubscription,
            removeSubscription: removeSubscription,
            getCurrentSubscriptions: getCurrentSubscriptions
        };
    })();

});