define('ir-app/controllers/ir-widgets/index-overview-widgets/side-bar-index-quote', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/components/stk-specific-components/portal-range-display-slider'], function (exports, Ember, BaseController, priceService, sharedService, PortalRangeSlider) {

    'use strict';

    /**
     * Created by lakmaln on 6/3/2016.
     */

    exports['default'] = BaseController['default'].extend({

        stock: {},
        isMainIndexQuote: false,

        onPrepareData: function onPrepareData() {
            var stock = priceService['default'].stockDS.getStock(this.get('exg'), this.get('sym'));
            this.set('stock', stock);
            this.set('isMainIndexQuote', this.get('isMainIndexQuote'));

            priceService['default'].sendSymbolValidationRequest(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, undefined, undefined);
            priceService['default'].addIndexRequest(this.get('exg'), this.get('sym'));
        },

        onClearData: function onClearData() {
            priceService['default'].removeIndexRequest(this.get('exg'), this.get('sym'));
            this.set('stock', '');
        }
    });

    Ember['default'].Handlebars.helper('portal-range-display-slider', PortalRangeSlider['default']);
    Ember['default'].Handlebars.helper('getPositiveNegativeStyle', function (value) {
        if (value >= 0) {
            return 'padding-medium snap-period-wrapper-green';
        } else {
            return 'padding-medium snap-period-wrapper-red';
        }
    });

});