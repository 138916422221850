define('ir-app/models/shared/profile/profile-data-store', ['exports', 'ember', 'ir-app/models/shared/profile/profile', 'ir-app/models/shared/profile/component', 'ir-app/utils/utils', 'ir-app/models/shared/profile/profile-service'], function (exports, Ember, Profile, Component, utils, profileService) {

    'use strict';

    exports['default'] = (function () {
        var ComponentParams = {
            ComponentTypeId: 1,
            Status: 0,
            Type: 'tab',
            PermissionList: null,
            IdShift: 1000,
            NoOfDefaultComponents: 5
        };

        var profileObj = null;
        var componentMap = {};

        var getUserProfile = function getUserProfile() {
            if (!profileObj) {
                profileObj = Profile['default'].create({});
                profileService['default'].getUserProfileFromServer();
                _setItemOnServerPeriodically();
            }
        };

        var getComponent = function getComponent(key) {
            var componentObj = null;
            var componentStr = _getItemAsString(key);

            if (componentStr) {
                componentObj = utils['default'].convertFromJson(componentStr);
            }

            return componentObj;
        };

        var getUserProfileObj = function getUserProfileObj() {
            return profileObj;
        };

        var setComponent = function setComponent(key, value, saveImmediately) {
            if (utils['default'].validators.isAvailable(key) && utils['default'].validators.isAvailable(value)) {
                if (saveImmediately) {
                    if (key in componentMap) {
                        componentMap.key = undefined;
                    }
                    _setItemOnServer(key, value);
                } else {
                    componentMap[key] = value;
                }
            }
        };

        var _setItemOnServerPeriodically = function _setItemOnServerPeriodically() {
            var interval = 300000;

            Ember['default'].run.later(this, function () {
                _setItemQueueOnServer();
                _setItemOnServerPeriodically();
            }, interval);
        };

        var _setItemQueueOnServer = function _setItemQueueOnServer() {
            Ember['default'].$.each(componentMap, function (key, value) {
                if (value) {
                    _setItemOnServer(key, value);
                }
            });
            componentMap = {};
        };

        var _getItemAsString = function _getItemAsString(key) {
            var itemStr = null;
            var componentArray = profileObj.Components;

            if (profileObj && utils['default'].validators.isAvailable(key)) {
                Ember['default'].$.each(componentArray, function (id, component) {
                    if (component.Name === key) {
                        itemStr = component.Contents;
                        return false;
                    }
                });
            }

            return itemStr;
        };

        var _setItemOnServer = function _setItemOnServer(key, value) {
            var componentObj, componentStrObj, componentArray;
            var contentValue = typeof value === 'object' ? utils['default'].jsonHelper.convertToJson(value) : value;

            if (profileObj && utils['default'].validators.isAvailable(key) && utils['default'].validators.isAvailable(contentValue)) {
                componentArray = profileObj.Components;

                Ember['default'].$.each(componentArray, function (id, component) {
                    if (component.Name === key) {
                        componentObj = component;
                        return false;
                    }
                });

                if (componentObj) {
                    componentObj.Contents = contentValue;
                    componentStrObj = utils['default'].jsonHelper.convertToJson(componentObj);
                    profileService['default'].modifyComponentOnServer(componentStrObj, componentObj.ProfileId);
                } else {
                    _addComponent(key, contentValue);
                }
            }
        };

        var _addComponent = function _addComponent(key, contentValue) {
            var componentObj = _createComponent(key, contentValue);
            var componentStrObj = utils['default'].jsonHelper.convertToJson(componentObj);
            profileService['default'].addComponentOnServer(componentStrObj);
        };

        var _createComponent = function _createComponent(key, contentValue) {
            var componentArray = profileObj.Components;
            var compId = profileObj.Id * ComponentParams.IdShift + ComponentParams.NoOfDefaultComponents + componentArray.length;

            var newComponentObj = Component['default'].create({
                Id: compId,
                ComponentTypeId: ComponentParams.ComponentTypeId,
                Status: ComponentParams.Status,
                ProfileId: profileObj.Id,
                Version: ComponentParams.Version,
                Name: key,
                Type: ComponentParams.Type,
                Contents: contentValue,
                PermissionList: ComponentParams.PermissionList
            });

            profileObj.Components.pushObject(newComponentObj);
            return newComponentObj;
        };

        return {
            getUserProfile: getUserProfile,
            getComponent: getComponent,
            getUserProfileObj: getUserProfileObj,
            setComponent: setComponent
        };
    })();

});