define('ir-app/controllers/ir-mobile/file-widgets/file-list', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/config/price-widget-config', 'ir-app/models/shared/shared-service', 'ir-app/models/price/price-constants', 'ir-app/config/app-config'], function (exports, Ember, BaseController, priceService, priceWidgetConfig, sharedService, priceConstants, appConfig) {

    'use strict';

    exports['default'] = BaseController['default'].extend({
        files: Ember['default'].A(),
        basicInfo: {},
        selectedScat: null,
        scats: priceWidgetConfig['default'].scats,
        groupFiles: false,
        fileByYear: Ember['default'].A(),
        fileSelected: false,
        viewDate: false,
        isMobile: false,
        dropDownEnabled: true,
        historyYears: 5, // default history is for 5 years. set through config to change to any other value

        // Base overrides
        onLoadWidget: function onLoadWidget() {
            this.setErrorMessage();

            if (sharedService['default'].userSettings.currentAppMode === appConfig['default'].applicationMode.ir_mobile.identifier) {
                this.set('isMobile', true);
            }
        },

        onPrepareData: function onPrepareData() {
            var that = this;
            var lanStore = this.get('app').lang.labels;
            this.set('dropDownEnabled', this.get('scats').length > 1);
            this.get('scats').forEach(function (item) {
                item.des = lanStore[item.sDes];
            }, this.get('scats'));

            var basicInfo = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, function () {
                that.onDataSuccess();
            }, function () {
                that.onDataError();
            }, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyBasicInfo);

            this.set('selectedScat', this.get('scats')[0]);
            this.set('basicInfo', basicInfo);
        },

        onSubCategoryChange: (function () {
            this.set('files', Ember['default'].A());
            var selectedScat = this.get('selectedScat');
            this.set('groupFiles', selectedScat.groupBy);
            if (selectedScat.viewDate) {
                this.set('viewDate', true);
            } else {
                this.set('viewDate', false);
            }
            this.populateFiles();
        }).observes('selectedScat'),

        populateFiles: function populateFiles() {
            var that = this;
            var basicInfo = this.get('basicInfo');
            this.hideDataErrorMessage();
            this.startLoadingProgress();
            var fromYear = new Date().getFullYear() - that.get('historyYears');
            var filesArray = priceService['default'].fileDS.getFilesCollection(that.get('exg'), that.get('sym'), basicInfo.compID, basicInfo.tickerSerial, that.get('selectedScat').id, fromYear, sharedService['default'].userSettings.currentLanguage.toUpperCase(), function () {
                that.onDataSuccess();
            }, function () {
                that.onDataError();
            });

            if (filesArray) {
                that.set('files', filesArray);

                if (filesArray.length > 0) {
                    that.onDataSuccess(filesArray);
                }
            }
        },

        reLoadFileData: (function () {
            this.populateFiles();
        }).observes('basicInfo.tickerSerial'),

        caclFilesByYear: (function () {
            var result = Ember['default'].A();
            var content = this.get('files');
            content.forEach(function (file) {
                var yr = file.get('year');
                var found = result.findBy('year', yr);
                if (!found) {
                    result.pushObject(Ember['default'].Object.create({ year: yr, files: Ember['default'].A() }));
                }
                result.findBy('year', yr).get('files').pushObject(file);
            });

            this.set('filesByYear', result);
        }).observes('files.@each'),

        onCheckDataAvailability: function onCheckDataAvailability() {
            var filesArray = this.get('files');
            return filesArray.length > 0;
        },

        onClearData: function onClearData() {
            this.set('files', Ember['default'].A());
            this.hideDataErrorMessage();
        },

        onLanguageChanged: function onLanguageChanged() {
            this.onPrepareData();
            this.setErrorMessage();
        },

        actions: {
            onItemClicked: function onItemClicked(file) {
                this.set('fileObj', file);
                this.set('fileSelected', true);
            },
            onCloseSlide: function onCloseSlide() {
                this.set('fileSelected', false);
            },
            changeSelectedScat: function changeSelectedScat(value) {
                this.set('selectedScat', value);
            }
        }
    });

});