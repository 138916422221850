define('ir-app/controllers/ir-mobile/media-room-widgets/youtube', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/shared/communication-adapters/web-http-connection', 'ir-app/utils/utils', 'ir-app/config/app-config'], function (exports, Ember, BaseController, WebConnection, utils, AppConfig) {

    'use strict';

    /**
     * Created by banushas on 3/25/2016.
     */
    exports['default'] = BaseController['default'].extend({

        // TODO: [Banusha] Review the below hardcoded configs and optimise them
        apiKey: AppConfig['default'].googleAPIConfig.key,
        youtubeApiUrl: AppConfig['default'].googleAPIConfig.youtubeApiUrl,
        channelId: '',
        userID: '',
        maxResults: 20,
        itemCount: 0,
        channelDetails: undefined,
        playList: Ember['default'].A(),
        playListIDs: Ember['default'].A(),
        playListLoadComplete: false,
        channelNotAvailable: false,

        onLoadWidget: function onLoadWidget() {
            var that = this;

            try {
                if (utils['default'].validators.isAvailable(that.get('mediaURL'))) {
                    that._getUserID(that.get('mediaURL'));
                    that.set('playListLoadComplete', false);

                    if (utils['default'].validators.isAvailable(that.get('userID'))) {
                        that._getChannelId(that.youtubeApiUrl + 'channels?part=id&forUsername=' + that.get('userID') + '&key=' + that.apiKey);
                    } else if (utils['default'].validators.isAvailable(that.get('channelId'))) {
                        that._getChannelDetails(that.get('channelId'));
                    } else {
                        that.set('channelNotAvailable', true);
                    }
                } else {
                    that.set('channelNotAvailable', true);
                }
            } catch (e) {
                that.set('channelNotAvailable', true);
                utils['default'].logger.logError('[You-tube] onLoadWidget() ' + e);
            }

            this.setErrorMessage();
        },

        _getUserID: function _getUserID(mediaURL) {
            var that = this;
            var s = mediaURL.indexOf('/user/');
            if (s !== -1) {
                var youtubeUrl = mediaURL.match(/user\/([a-zA-Z0-9\-_]+)/);
                that.set('userID', youtubeUrl[1]);
            } else {
                s = mediaURL.indexOf('/channel/');
                if (s !== -1) {
                    var youtubeChannelUrl = mediaURL.match(/channel\/([a-zA-Z0-9\-_]+)/);
                    that.set('channelId', youtubeChannelUrl[1]);
                } else {
                    var youtubeRawlUrl = mediaURL.match(/\/([a-zA-Z0-9\-_]+)/);
                    that.set('userID', youtubeRawlUrl[1]);
                }
            }
        },

        _getChannelId: function _getChannelId(apiUrl) {
            var that = this;
            WebConnection['default'].sendAjaxRequest({
                url: apiUrl,

                onSuccess: function onSuccess(dataObj) {
                    that.set('channelId', dataObj.items[0].id);
                    that._getChannelDetails(dataObj.items[0].id);
                },

                onError: function onError() {
                    // TODO: [Banusha] Handle error on exchange data loading
                    that.set('channelNotAvailable', true);
                }
            });
        },

        _getChannelDetails: function _getChannelDetails(channelId) {
            var that = this;
            var apiProfileURL = that.youtubeApiUrl + 'channels?part=brandingSettings%2Csnippet%2Cstatistics%2CcontentDetails&id=' + channelId + '&key=' + this.apiKey;
            WebConnection['default'].sendAjaxRequest({
                url: apiProfileURL,

                onSuccess: function onSuccess(dataObj) {
                    that._processInformationData(dataObj);
                    that._getPlayLists(dataObj.items[0].contentDetails.relatedPlaylists.uploads);
                },

                onError: function onError() {
                    // TODO: [Banusha] Handle error on exchange data loading
                    that.set('channelNotAvailable', true);
                }
            });
        },

        _processInformationData: function _processInformationData(response) {
            try {
                var channelData = response.items[0];
                this.set('channelDetails', channelData);
            } catch (e) {
                utils['default'].logger.logError('[You-tube] _processInformationData() ' + e);
            }
        },

        _getPlayLists: function _getPlayLists(playlistID) {
            var that = this;
            var apiUploadURL = that.youtubeApiUrl + 'playlistItems?part=snippet&playlistId=' + playlistID + '&maxResults=' + that.maxResults + '&key=' + that.apiKey;

            WebConnection['default'].sendAjaxRequest({
                url: apiUploadURL,

                onSuccess: function onSuccess(response) {
                    that._processPlayLists(response);
                },

                onError: function onError() {
                    // TODO: Handle error on exchange data loading
                }
            });
        },

        _processPlayLists: function _processPlayLists(response) {
            var that = this;
            that.set('nextPageToken', response.nextPageToken);

            var playlistArray = response.items;

            for (var i = 0; i < playlistArray.length; i++) {
                if (!that.get('playListIDs').contains(playlistArray[i].id)) {
                    that.get('playListIDs').pushObject(playlistArray[i].id);
                    that.get('playList').pushObject(playlistArray[i]);
                }
            }

            that.set('playListLoadComplete', true);
        }
    });

});