define('ir-app/controllers/authentication/price-retail-authenticator', ['exports', 'ember', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/utils/utils'], function (exports, Ember, priceService, sharedService, utils) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend({
        resendSubscriptions: false,

        /* *
         * Checks whether application should be initialized for the given user
         * @param username User provided username
         * @param password User provided password
         * @param allowInit True for already logged-in users, false otherwise
         * @returns {boolean} True if current credentials equals to stored credentials, false otherwise
         * @private
         */
        isInitAllowed: function isInitAllowed(username, password, allowInit) {
            if (allowInit) {
                return true;
            } else {
                var storedUnm = sharedService['default'].userSettings.username;
                var storedPwd = sharedService['default'].userSettings.password;
                var isUserAvailable = utils['default'].validators.isAvailable(storedUnm) && utils['default'].validators.isAvailable(storedPwd);

                return isUserAvailable && storedUnm === username && storedPwd === password;
            }
        },

        /* *
         * Authenticate user
         * @param username Username
         * @param password Password
         * @param allowInit Allow application to initialize before login
         * @private
         */
        authenticateUser: function authenticateUser(username, password, allowInit, _authSuccess, authFail) {
            var that = this;
            var allowInitialize = this.isInitAllowed(username, password, allowInit);

            // Initialize application if and only if the given user is the last successfully logged-in user
            _authSuccess(username, password, allowInitialize);

            priceService['default'].authenticateWithUsernameAndPassword({
                username: username,
                password: password,
                resendSubscriptions: that.resendSubscriptions,
                authSuccess: function authSuccess() {
                    // Initialize application if and only if the given user is the last successfully logged-in user
                    _authSuccess(username, password, !allowInitialize);

                    // TODO: [Bashitha] Call secondary authentication service here, after finalizing auth protocol
                },
                authFailed: function authFailed(reason) {
                    that.resendSubscriptions = true;
                    authFail(reason, username, password);
                }
            });
        }
    }).create();

});