define('ir-app/models/shared/shared-initializers/shared-data-module-initializer', ['exports', 'ir-app/models/shared/shared-initializers/base-module-initializer', 'ir-app/models/shared/shared-initializers/language-loader', 'ir-app/models/shared/theme/theme-loader', 'ir-app/helpers/helpers', 'ir-app/utils/prototype-extensions', 'ir-app/models/shared/shared-service', 'ir-app/utils/utils', 'ir-app/config/app-config', 'ir-app/config/user-settings', 'ir-app/config/extended-settings'], function (exports, BaseModuleInitializer, languageLoader, themeLoader, Helpers, PrototypeExtensions, sharedService, utils, appConfig, userSettings, extendedSettings) {

    'use strict';

    // import Ember from 'ember';
    exports['default'] = BaseModuleInitializer['default'].extend({

        preInitialize: function preInitialize(application) {
            this._registerUtils(application);
        },

        manualInitializations: function manualInitializations(client) {
            utils['default'].configHelper.mergeConfigSettings(appConfig['default'], extendedSettings['default'].appConfig);
            var extendedUserSettings = extendedSettings['default'].userSettings ? extendedSettings['default'].userSettings.clients ? extendedSettings['default'].userSettings.clients[client] : undefined : undefined;
            utils['default'].configHelper.mergeConfigSettings(userSettings['default'], extendedUserSettings);
            languageLoader['default'].loadLanguageData();
            themeLoader['default'].loadThemeData();
        },

        _registerUtils: function _registerUtils(application) {
            try {
                application.register('utility:main', utils['default'], { instantiate: false });
                application.inject('controller', 'utils', 'utility:main');
            } catch (e) {
                utils['default'].logger.logError('Error in injecting  utilities : ' + e);
            }
        },

        _loadUserSettings: function _loadUserSettings() {
            var savedUser = utils['default'].webStorage.getObject(utils['default'].webStorage.getKey(sharedService['default'].userSettings.cacheKey));
            sharedService['default'].userSettings.setData(savedUser);
        }
    }).create();

});