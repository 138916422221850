define('ir-app/components/pi-chart', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    chart: {},
    elementId: '',
    dataSet: {},
    xDataSet: {},
    chartOptions: (function () {
      var that = this;
      return {
        chart: {
          type: 'pie',
          height: 200,
          options3d: {
            enabled: true,
            alpha: 45,
            beta: 0
          }
        },
        exporting: { enabled: false },
        title: {
          style: {
            fontWeight: 'bold',
            fontSize: '15px'
          }
        },
        tooltip: {
          useHTML: that.lang === 'AR' ? true : false,
          pointFormat: '{point.y}: ‫<b>‫ {point.percentage:.1f}%</b>'
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            depth: 35,
            dataLabels: {
              enabled: false,
              format: '{point.name} = {point.percentage:.1f}%'
            },
            showInLegend: true
          }
        },
        legend: {
          rtl: that.lang === 'AR' ? true : false,
          enabled: true,
          layout: 'vertical',
          align: 'left',
          verticalAlign: 'middle',
          labelFormat: '‫' + '{name} : {percentage:.1f}' + '‫' + '%' },
        // \u202B is RLE char for RTL support
        credits: {
          enabled: false
        },
        series: [{
          type: 'pie'
        }]
      };
    }).property(),

    generateChart: (function () {
      var chart = this.get('chartOptions');
      var currentDataSet = this.get('dataSet');
      var currentXDataSet = this.get('xDataSet');
      chart.series[0] = [];
      chart.title['text'] = '';
      if (currentDataSet && currentDataSet[0]) {
        chart.series[0]['name'] = currentDataSet.slice(0, 1);
        var data = [];
        var length = currentDataSet.length - 1;
        for (var j = 0; j < length; j++) {
          data.push({});
        }
        chart.series[0]['data'] = data;
        if (length > 0) {
          chart.title['text'] = currentDataSet[0];
        }
        for (var i = 0; i < length; i++) {
          chart.series[0]['data'][i].y = currentDataSet.slice(i + 1, i + 2).map(Number)[0];
          chart.series[0]['data'][i].name = currentXDataSet.slice(i + 1, i + 2)[0];
        }
      }
      Ember['default'].$('#' + this.get('elementId')).highcharts(chart);
    }).observes('dataSet'),

    didInsertElement: function didInsertElement() {
      this._super();
      this.generateChart();
    }
  });

});