define('ir-app/routes/application', ['exports', 'ember', 'ir-app/config/layout-config', 'ir-app/app-events', 'ir-app/models/shared/shared-service', 'ir-app/config/app-config', 'ir-app/utils/utils', 'ir-app/models/shared/shared-data-stores/theme-data-store', 'ir-app/models/shared/language/language-data-store', 'ir-app/models/shared/shared-initializers/shared-data-module-initializer', 'ir-app/controllers/price/initializers/price-ui-module-initializer', 'ir-app/models/price/price-service'], function (exports, Ember, layoutConfig, appEvents, sharedService, appConfig, utils, ThemeDataStore, LanguageDataStore, sharedDataModuleInitializer, priceUiModuleInitializer, priceService) {

    'use strict';

    exports['default'] = Ember['default'].Route.extend({
        widgetContainer: {},
        menuContent: {},
        appLayout: {},
        arrParams: [],
        applicationModeKeys: null,

        renderTemplate: function renderTemplate(controller, model) {
            this.checkServerVersion();
            this._super(controller, model); // Render the base template
            // Load the layout & handle cache
            if (!this._initializeApp()) {
                return;
            }
            if (appConfig['default'].applicationMode.ir_mobile.identifier === this.get('applicationMode')) {
                this.get('controller').showHideTopBar = 'display:block';
                this._loadMobileLayout();
            } else {
                if (this.get('appLayout').layout.mainContent.length > 1) {
                    this.get('controller').showHideTopBar = 'display:block';
                } else {
                    this.get('controller').showHideToplBar = 'display:none';
                }
                this._loadIrLayout();
            }
        },

        _initializeApp: function _initializeApp() {
            if (!this._loadAppMode()) {
                return false;
            }

            var layoutName = this._getAppLayout();

            if (utils['default'].validators.isAvailable(layoutName)) {
                this.set('appLayout', layoutConfig['default'][layoutName]);
            } else {
                return false;
            }

            // Load the symbol / exchange params and set the defaults
            var exg = this.get('arrParams').exg.toUpperCase();
            var symb = this.get('arrParams').sym.toUpperCase();

            sharedService['default'].userSettings.setCacheKey(this.get('applicationMode'), this.get('client'));
            sharedDataModuleInitializer['default']._loadUserSettings();

            if (exg !== sharedService['default'].userSettings.currentExchange || symb !== sharedService['default'].userSettings.currentSymbol || this.get('client') !== sharedService['default'].userSettings.currentCustomisation) {
                utils['default'].webStorage.clearCache();
                sharedService['default'].userSettings.currentSymbol = symb;
                sharedService['default'].userSettings.currentExchange = exg;
                sharedService['default'].userSettings.currentCustomisation = this.get('client');
                sharedService['default'].userSettings.save();
            }

            sharedDataModuleInitializer['default'].manualInitializations(this.get('client'));
            priceUiModuleInitializer['default'].manualInitializations();

            // Do some ir specific tasks
            if (this.get('applicationMode') === appConfig['default'].applicationMode.ir_page.identifier) {
                // Change the language
                var widgetLan = this.get('arrParams').lan.toUpperCase();
                if (widgetLan !== sharedService['default'].userSettings.currentLanguage) {
                    LanguageDataStore['default'].changeLanguage(widgetLan);
                }
            }

            this.get('controller').onPrepareApp();
            var appArgs = this.get('appLayout').args ? this.get('appLayout').args.args : undefined;
            if (appArgs) {
                this.get('controller').setArgs(appArgs);
            }
            return true;
        },

        _loadMobileLayout: function _loadMobileLayout() {
            var that = this;

            // set the mainContent for the title bar
            that.controllerFor('title-bar').setMenuStructure(that.get('appLayout').layout.mainContent);
            // var userWidgetPref = sharedService.userState.defaultWS;
            // this.set('storedArgs', userWidgetPref || {});

            that.addVisibilityChangeListener();

            var _getDefaultMenu = function _getDefaultMenu() {
                var defLayout = {};
                var savedMenuId = sharedService['default'].userState.lastMenu;
                var mainContent = that.get('appLayout').layout.mainContent;

                if (savedMenuId) {
                    savedMenuId = parseInt(savedMenuId, 10);
                } else {
                    savedMenuId = -1;
                }

                // TODO: [Amila] Bypass the Menu saving for the moment.
                savedMenuId = -1;

                if (mainContent && mainContent.length > 0) {
                    Ember['default'].$.each(mainContent, function (index, layout) {
                        if (savedMenuId > -1) {
                            if (layout.id === savedMenuId) {
                                defLayout = layout;
                                return false;
                            }
                        } else if (layout.def) {
                            defLayout = layout;
                            return false;
                        }
                    });
                }

                return defLayout;
            };

            // get the saved/default menu
            var menu = _getDefaultMenu();
            this.controllerFor('title-bar').onChangeMenu(menu);
            this.render('title-bar', {
                into: 'application',
                outlet: 'topBarOutlet'
            });
            this.renderMenuView(this, menu, true);
        },

        _loadIrLayout: function _loadIrLayout() {

            if (this.get('appLayout').layout.mainContent.length > 1) {
                this.controllerFor('title-bar').setMenuStructure(this.get('appLayout').layout.mainContent);

                var menu = this.get('appLayout').layout.mainContent[0];
                this.controllerFor('title-bar').onChangeMenu(menu);
                this.render('title-bar', {
                    into: 'application',
                    outlet: 'topBarOutlet'
                });
                this.renderMenuView(this, menu, true);
            } else {
                var mainContent = this.get('appLayout').layout.mainContent[0];
                this.renderMenuView(this, mainContent, true);
            }
        },

        _loadAppMode: function _loadAppMode() {
            this.set('arrParams', this.paramsFor('/'));

            if (appConfig['default'].applicationMode.ir_mobile.identifier === this.get('arrParams').mode) {
                this.set('applicationMode', appConfig['default'].applicationMode.ir_mobile.identifier);
                sharedService['default'].userSettings.currentAppMode = appConfig['default'].applicationMode.ir_mobile.identifier;
                this.set('client', this.get('arrParams').customisation);
            } else if (appConfig['default'].applicationMode.ir_page.identifier === this.get('arrParams').mode) {
                this.set('applicationMode', appConfig['default'].applicationMode.ir_page.identifier);
                sharedService['default'].userSettings.currentAppMode = appConfig['default'].applicationMode.ir_page.identifier;
                this.set('client', this.get('arrParams').customisation);
            } else {
                this._showError('Invalid application mode');
                return false;
            }

            return true;
        },

        _getAppLayout: function _getAppLayout() {
            var layoutName = '';

            if (this.get('applicationMode') === appConfig['default'].applicationMode.ir_mobile.identifier) {
                // Go for the "specific exchange - specific symbol" template type
                layoutName = this._generateMobileLayoutName(this.get('arrParams').exg, this.get('arrParams').sym);
                if (layoutConfig['default'][layoutName]) {
                    return layoutName;
                }

                // if not, go for the "any exchange - any symbol" template type
                // Below is added to avoid releasing application for new demos
                layoutName = this._generateMobileLayoutName('a', 'a');
                if (layoutConfig['default'][layoutName]) {
                    return layoutName;
                }
            } else if (this.get('applicationMode') === appConfig['default'].applicationMode.ir_page.identifier) {
                // Go for the "specific exchange - specific symbol" template type
                layoutName = this._generateIrLayoutName(this.get('arrParams').exg, this.get('arrParams').sym);
                if (layoutConfig['default'][layoutName]) {
                    return layoutName;
                }

                // if not, go for the "specific exchange - any symbol" template type
                layoutName = this._generateIrLayoutName(this.get('arrParams').exg, 'a');
                if (layoutConfig['default'][layoutName]) {
                    return layoutName;
                }

                // if not, go for the "any exchange - any symbol" template type
                layoutName = this._generateIrLayoutName('a', 'a');
                if (layoutConfig['default'][layoutName]) {
                    return layoutName;
                }
            } else {
                this._showError('Application mode not supported or not loaded properly : ' + this.get('applicationMode'));
            }

            return '';
        },

        _generateIrLayoutName: function _generateIrLayoutName(exg, sym) {
            // Replace the '`' mark if available
            var modifiedSym = sym.replace('`', '_sub_');

            return [this.get('arrParams').mode, '_', this.get('arrParams').customisation, '_', this.get('arrParams').page, '_', exg, '_', modifiedSym].join('');
        },

        _generateMobileLayoutName: function _generateMobileLayoutName(exg, sym) {
            // Replace the '`' mark if available
            var modifiedSym = sym.replace('`', '_sub_');

            return [this.get('arrParams').mode, '_', this.get('arrParams').customisation, '_', exg, '_', modifiedSym].join('');
        },

        _showError: function _showError(error) {
            /*eslint-disable */
            alert(error);
            /*eslint-enable */
        },

        renderMenuView: function renderMenuView(router, menuContent, isReRender) {
            // Not allowed to load same menu more than once consecutively
            if (this.menuContent && this.menuContent.id === menuContent.id && !isReRender) {
                return;
            }
            this.set('menuContent', menuContent);
            this.render(menuContent.layoutTemplate.wn, {
                into: 'application',
                outlet: 'mainContainerOutlet'
            });

            // Check and set the background
            if (menuContent.background) {
                ThemeDataStore['default'].showBackground(true);
            } else {
                ThemeDataStore['default'].showBackground(false);
            }

            if (this.widgetContainer && this.widgetContainer.closeContainer) {
                this.widgetContainer.closeContainer();
            }

            this.widgetContainer = this.controllerFor(menuContent.layoutTemplate.wn);

            // set container level configuration args
            var widgetArgs = this.get('appLayout').args;
            if (widgetArgs) {
                var containerArgs = widgetArgs[menuContent.id] ? widgetArgs[menuContent.id].args : undefined;

                if (containerArgs) {
                    this.widgetContainer.setArgs(containerArgs);
                }
            }

            this.widgetContainer.menuView(this, menuContent, this.get('appLayout').args, this.get('storedArgs'), isReRender);
        },

        addVisibilityChangeListener: function addVisibilityChangeListener() {
            if (document.addEventListener) {
                document.addEventListener('visibilitychange', function () {
                    appEvents['default'].onVisibilityChanged(document.hidden);
                });
            }
        },
        checkServerVersion: function checkServerVersion() {
            priceService['default'].sendServerVersionRequest(function () {
                if (utils['default'].webStorage.getObject(utils['default'].Constants.CacheKeys.ServerVersion) === undefined) {
                    utils['default'].webStorage.addObject(utils['default'].Constants.CacheKeys.ServerVersion, sharedService['default'].userSettings.serverVersion, utils['default'].Constants.StorageType.Local);
                } else if (utils['default'].webStorage.getObject(utils['default'].Constants.CacheKeys.ServerVersion) !== sharedService['default'].userSettings.serverVersion) {
                    utils['default'].webStorage.flush();
                    utils['default'].webStorage.addObject(utils['default'].Constants.CacheKeys.ServerVersion, sharedService['default'].userSettings.serverVersion, utils['default'].Constants.StorageType.Local);
                    location.reload();
                }
            }, function () {});
        },
        actions: {
            renderMenuItems: function renderMenuItems(menuContent) {
                // notify menu change to title bar
                this.controllerFor('title-bar').onChangeMenu(menuContent);
                this.renderMenuView(this, menuContent, false);

                Ember['default'].$('.slide-menu').removeClass('is-visible');
            },

            renderTabItems: function renderTabItems(tabContent) {
                var mainTabController = this.controllerFor(this.menuContent.layoutTemplate.wn);
                mainTabController.tabView(this, tabContent, this.menuContent);
            },

            changeTab: function changeTab(targetTabId) {
                // goto page top to feel a proper tab change behavior
                parent.postMessage('scrollTop', 'http://www.alarabiya.net');
                // Ember.$('html, body').animate({scrollTop: 0}, 'fast');
                var genArgs = Array.from(arguments).slice(1);
                var genArgObj;

                if (genArgs) {
                    genArgObj = {
                        generatedArgs: genArgs
                    };
                }

                var mainTabController = this.controllerFor(this.menuContent.layoutTemplate.wn);
                var tabList = this.menuContent.tab;
                var tabContent = tabList[0];

                Ember['default'].$.each(tabList, function (index, tab) {
                    if (tab.id === targetTabId) {
                        tabContent = tab;
                        return;
                    }
                });

                mainTabController.tabView(this, tabContent, this.menuContent, undefined, genArgObj);
            },

            changeExchange: function changeExchange(option) {
                sharedService['default'].userSettings.currentSymbol = option.sym.toUpperCase();
                sharedService['default'].userSettings.currentExchange = option.exg.toUpperCase();
                sharedService['default'].userSettings.save();

                var menuContent = this.get('menuContent');
                this.controllerFor('title-bar').onChangeMenu(menuContent);
                this.renderMenuView(this, menuContent, true); //false);

                Ember['default'].$('.slide-menu').removeClass('is-visible');
            }
        }
    });

});