define('ir-app/models/chart/chart-business-entities/ohlc-series', ['exports', 'ember', 'ir-app/models/chart/chart-business-entities/ohlc', 'ir-app/utils/utils'], function (exports, Ember, ohlc, utils) {

    'use strict';

    /**
     * Created by Amila on 7/10/15.
     */

    exports['default'] = Ember['default'].Object.extend({
        sym: '', // Symbol
        exg: '', // Exchange
        min: Number.MAX_VALUE, // Min val of the series
        arrOHLCObservers: [], // Array of OHLC data observers
        arrLastCandleObservers: [], // Array of Last Candle data observers
        ohlcDataPoints: null, // OHLC data points

        max: 0, // Max val of the series
        minVol: Number.MAX_VALUE, // Min volume of the series
        chartDataLevel: -1, // Chart data level

        maxVol: 0, // Max volume of the series

        stockRef: null, // Previous close

        init: function init() {
            this._super();

            this.set('ohlcDataPoints', []);
        },

        setData: function setData(ohlcMessage, isRealTime) {
            try {
                var ohlcArray = this.get('ohlcDataPoints');
                var count = ohlcArray.length;
                var currMin = this.get('min');
                var currMax = this.get('max');
                var currMinVol = this.get('minVol');
                var currMaxVol = this.get('maxVol');

                if (count === 0 || ohlcArray[count - 1].DT.getTime() < ohlcMessage.dt.getTime()) {
                    // Add elements to the end of array
                    var ohlcPoint = ohlc['default'].getOHLCObj(ohlcMessage);
                    ohlcArray[count] = ohlcPoint;

                    if (ohlcMessage.close < currMin) {
                        this.set('min', ohlcMessage.close);
                    }

                    if (ohlcMessage.close > currMax) {
                        this.set('max', ohlcMessage.close);
                    }

                    if (ohlcMessage.volume < currMinVol) {
                        this.set('minVol', ohlcMessage.volume);
                    }

                    if (ohlcMessage.volume > currMaxVol) {
                        this.set('maxVol', ohlcMessage.volume);
                    }

                    // Notify the observers
                    if (isRealTime) {
                        var arr = this.get('arrOHLCObservers');
                        for (var a = 0; a < arr.length; a++) {
                            arr[a].onDataFromRealTime(ohlcPoint, this.get('exg'), this.get('sym'));
                        }
                    }
                }

                /** else if (ohlcArray[0].TimeStamp > ohlcMessage.TimeStamp) {
                // Add elements to the begin of the array
                ohlcArray.unshift(ohlcPoint);
                } else {
                // If something comes in-between, can't help, go for the costly search and insert
                alert('comes in the middle');
                }*/
            } catch (ex) {
                utils['default'].logger.logError('Ohlc series set data is giving error ' + ex);
            }
        },

        setLastCandleData: function setLastCandleData(lastCandle, exg, sym) {
            var arr = this.get('arrLastCandleObservers');
            for (var a = 0; a < arr.length; a++) {
                arr[a].onDataForLastCandle(lastCandle, exg, sym);
            }
        },

        indexOfElement: function indexOfElement(key) {
            var ohlcArray = this.get('ohlcDataPoints');
            var lo = 0,
                hi = ohlcArray.length - 1,
                mid,
                element;

            while (lo <= hi) {
                mid = lo + hi >> 1;
                element = ohlcArray[mid];
                if (element.DT.getTime() < key) {
                    lo = mid + 1;
                } else if (element.DT.getTime() > key) {
                    hi = mid - 1;
                } else {
                    return mid;
                }
            }

            // If not found, return lo and the period will be calculated from that element
            return lo;
        },

        queryPointsForRange: function queryPointsForRange(fromTime, toTime) {
            if (fromTime && fromTime === -1) {
                return [];
            }

            var startIndex = this.indexOfElement(fromTime);
            var ohlcArray = this.get('ohlcDataPoints');

            if (toTime) {
                var endIndex = this.indexOfElement(toTime);
                return ohlcArray.slice(startIndex, endIndex);
            }

            return ohlcArray.slice(startIndex);
        },

        queryNearestPoint: function queryNearestPoint(time) {
            if (time === -1) {
                return null;
            }

            var index = this.indexOfElement(time) - 1;
            var ohlcArray = this.get('ohlcDataPoints');

            if (index === -1) {
                index = 0;
            }

            if (index === ohlcArray.length - 1 || time === ohlcArray[index].DT.getTime()) {
                return ohlcArray[index];
            } else {
                var loTime = ohlcArray[index].DT.getTime();
                var highTime = ohlcArray[index + 1].DT.getTime();

                if (time - loTime < highTime - time) {
                    return ohlcArray[index];
                } else {
                    return ohlcArray[index + 1];
                }
            }
        },
        queryNearestFuturePoint: function queryNearestFuturePoint(time) {
            if (time === -1) {
                return null;
            }

            var index = this.indexOfElement(time) - 1;
            var ohlcArray = this.get('ohlcDataPoints');

            if (index === -1) {
                index = 0;
            }
            return ohlcArray[index + 1];
        },
        queryNearestChartPoints: function queryNearestChartPoints(historyObject, numOfPoints) {
            var time = historyObject.DT.getTime();
            var numberOfPoints = numOfPoints;
            if (time === -1) {
                return null;
            }
            var index = this.indexOfElement(time);
            var startIndex = index;
            var endIndex = index;
            var ohlcArray = this.get('ohlcDataPoints');
            if (index === ohlcArray.length - 1) {
                // selected one is the last point
                if (ohlcArray.length < numberOfPoints) {
                    return ohlcArray;
                } else {
                    startIndex = index - (numberOfPoints - 1);
                    endIndex = index;
                }
            } else if (index === 0) {
                // selected one is the first point
                startIndex = index;
                endIndex = index + numberOfPoints;
            } else {
                if ((numberOfPoints - 1) % 2 === 1) {
                    // convert even to odd
                    numberOfPoints = numberOfPoints + 1;
                }
                var variant = (numberOfPoints - 1) / 2;
                startIndex = index - variant;
                endIndex = index + variant;
            }
            return ohlcArray.slice(startIndex, endIndex + 1);
        },

        registerForRealtimeData: function registerForRealtimeData(gdm) {
            if (gdm && Ember['default'].$.isFunction(gdm.onDataFromRealTime)) {
                var arrObservers = this.get('arrOHLCObservers');
                arrObservers[arrObservers.length] = gdm;
            }
        },

        registerForLastCandleUpdates: function registerForLastCandleUpdates(gdm) {
            if (gdm && Ember['default'].$.isFunction(gdm.onDataForLastCandle)) {
                var arrObservers = this.get('arrLastCandleObservers');
                arrObservers[arrObservers.length] = gdm;
            }
        },

        unregisterForRealtimeData: function unregisterForRealtimeData() {
            var arrObservers = this.get('arrOHLCObservers');
            arrObservers.clear();
        },

        unRegisterForLastCandleUpdates: function unRegisterForLastCandleUpdates() {
            var arrObservers = this.get('arrLastCandleObservers');
            arrObservers.clear();
        }
    });

});