define('ir-app/models/price/price-business-entities/individual-profile', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({

        //;contact Person Information
        id: '', // Individual Id
        proPic: '', // Profile Pic
        name: '', // person Name
        designation: '', // designation
        tel: '', // person Tel
        email: '', // person Email

        setData: function setData(personData) {
            var that = this;
            Ember['default'].$.each(personData, function (key, value) {
                that.set(key, value);
            });
        }
    });

});