define('ir-app/controllers/ir-widgets/foreign-ownership/foreign-ownership-limits', ['exports', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/models/price/price-constants'], function (exports, BaseController, priceService, sharedService, priceConstants) {

    'use strict';

    exports['default'] = BaseController['default'].extend({

        ownerShipData: undefined,
        isWidgetDisplay: false, // condition widget display or not

        onLoadWidget: function onLoadWidget() {
            this.setErrorMessage();
        },

        onPrepareData: function onPrepareData() {
            this.startLoadingProgress();
            this._loadForeignOwnershipData();
        },

        _loadForeignOwnershipData: function _loadForeignOwnershipData() {
            var that = this;

            // widget will display only for layout config exchange codes only
            var keyList = this.get('keys');
            if (keyList !== undefined && keyList.length > 0) {
                // Filter the keyList, and return true, if the requested market in the list
                that.set('isWidgetDisplay', keyList.filter(function (item) {
                    return item.exg === that.get('exg');
                }).length > 0);
            } else {
                // Initial requirement for display widget is 'TDWL' exg code only: can remove this else part if use args in layout config
                that.set('isWidgetDisplay', that.get('exg') !== undefined && that.get('exg').toUpperCase() === priceConstants['default'].WidgetDefaults.ForeignOwnershipExg);
            }

            if (that.get('isWidgetDisplay')) {
                var foreignOwnership = priceService['default'].ownershipLimitDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, function () {
                    that.onDataSuccess();
                }, function () {
                    that.onDataError();
                }, priceConstants['default'].DataTypes.ForeignOwnershipDataStore.FornLmt);

                that.set('ownerShipData', foreignOwnership);
            }
        },

        onCheckDataAvailability: function onCheckDataAvailability() {
            var ownershipLimitObj = priceService['default'].ownershipLimitDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage);

            if (ownershipLimitObj) {
                return this.utils.validators.isAvailable(ownershipLimitObj.qfiCrntDay);
            }
            return false;
        },

        onLanguageChanged: function onLanguageChanged() {
            this._loadForeignOwnershipData();
        },

        onClearData: function onClearData() {
            this.set('ownerShipData', '');
        }

    });

});