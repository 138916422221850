define('ir-app/models/chart/chart-data-stores/ohlc-data-store', ['exports', 'ember', 'ir-app/models/chart/chart-constants', 'ir-app/models/chart/chart-business-entities/ohlc-series', 'ir-app/models/price/price-data-stores/stock-data-store', 'ir-app/utils/utils'], function (exports, Ember, ChartConstants, ohlcSeries, stockDS, utils) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        intradayStore: {},
        historyStore: {},

        getOHLCSeries: function getOHLCSeries(exchange, symbol, chartCategory) {
            var ohlcStore = ChartConstants['default'].ChartCategory.Intraday.ID === chartCategory.ID ? this.get('intradayStore') : this.get('historyStore');
            var key = utils['default'].keyGenerator.getKey(exchange, symbol);

            var ohlcSeriesObj = ohlcStore[key];
            if (!ohlcSeriesObj) {
                ohlcSeriesObj = ohlcSeries['default'].create({
                    sym: symbol,
                    exg: exchange,
                    stockRef: stockDS['default'].getStock(exchange, symbol)
                });

                ohlcStore[key] = ohlcSeriesObj;
            }

            return ohlcSeriesObj;
        },

        removeOHLCSeries: function removeOHLCSeries(exchange, symbol, chartCategory) {
            // Todo [Ravindu] check request count before removing data stores
            var ohlcStore = ChartConstants['default'].ChartCategory.Intraday.ID === chartCategory.ID ? this.get('intradayStore') : this.get('historyStore');
            if (ohlcStore) {
                var key = utils['default'].keyGenerator.getKey(exchange, symbol);
                if (ohlcStore[key]) {
                    ohlcStore[key] = null;
                }
            }
        }
    }).create();

});