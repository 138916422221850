define('ir-app/controllers/ir-mobile/company-widgets/company-contact-us', ['exports', 'ir-app/controllers/base-controller', 'ir-app/models/shared/shared-service', 'ir-app/models/price/price-service', 'ir-app/models/price/price-constants', 'ir-app/utils/utils'], function (exports, BaseController, sharedService, priceService, priceConstants, utils) {

    'use strict';

    exports['default'] = BaseController['default'].extend({

        basicInfo: {},
        showContacts: false,
        indProObj: undefined,
        isButtonEnabled: true,

        // Base overrides
        onLoadWidget: function onLoadWidget() {
            this.setErrorMessage();
        },

        onPrepareData: function onPrepareData() {
            var that = this;
            var basicInfo = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, function () {
                that.set('showBasicInfo', true);
            }, function () {
                that.set('showBasicInfo', false);
                that.onDataError();
            }, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyBasicInfo);

            this.set('basicInfo', basicInfo);
            this._setLabels();
        },

        _setLabels: function _setLabels() {

            this.set('nameLabel', this.get('app').lang.labels.name + ' *:');
            this.set('emailLabel', this.get('app').lang.labels.email + ' *:');
            this.set('messageLabel', this.get('app').lang.labels.message + ' *:');
            this.set('phoneLabel', this.get('app').lang.labels.phn + ' *: ' + this.get('app').lang.labels.examplePhone);
        },
        _getMapAndContactPersonDetail: (function () {
            var that = this;
            // if true generate google map
            if (that.utils.validators.isAvailable(that.get('basicInfo.compID'))) {
                var mapUrl = that.get('googleMapUrl');
                var apiKey = that.get('mapApiKey');
                var lat = that.get('latitude');
                var lng = that.get('longitude');
                var url = that._generateUrl(mapUrl, apiKey, lat, lng);
                this.set('googleMapRender', url);
            }
            // get contact person details
            var compObj = that.get('basicInfo');
            if (compObj.compID && compObj.contactPersonId) {
                var indProObj = priceService['default'].indProfileDS.getStoredObject(compObj.contactPersonId, compObj.compID, sharedService['default'].userSettings.currentLanguage, function () {
                    that.onDataSuccess();
                }, function () {
                    that.onDataError();
                }, priceConstants['default'].DataTypes.IndividualProfile.CompanyContactPerson);

                this.set('indProObj', indProObj);
            } else if (compObj.compID && !compObj.contactPersonId) {
                this.onDataError();
            }
        }).observes('basicInfo.compID'),

        onCheckDataAvailability: function onCheckDataAvailability() {
            var compObj = this.get('basicInfo');
            var individualProfObj = priceService['default'].indProfileDS.getStoredObject(compObj.contactPersonId, compObj.compID, sharedService['default'].userSettings.currentLanguage);

            return this.utils.validators.isAvailable(individualProfObj.name);
        },

        setProfileErrorMessage: (function () {
            this.set('showError1', false);
            if (!this.get('showBasicInfo')) {
                this.set('showError1', true);
                this.set('errorMessage1', this.get('app').lang.messages.dataNotAvailable);
            }
        }).observes('showBasicInfo'),

        onLanguageChanged: function onLanguageChanged() {
            this.onPrepareData();
        },

        formattedAddress: (function () {
            var str = this.get('basicInfo.addr');
            if (this.utils.validators.isAvailable(str)) {
                var frmtedAdr = str.split(', ');
                return frmtedAdr;
            }
        }).property('basicInfo.addr'),
        indProPic: (function () {
            if (this.utils.validators.isAvailable(this.get('indProObj.proPic'))) {
                return this.utils.imageHelper.getImage(this.get('indProObj.proPic'));
            }
        }).property('indProObj.proPic'),

        emailSendMessage: function emailSendMessage() {
            if (this.get('isEmailSend') && this.get('isButtonEnabled')) {
                this.set('senderName', '');
                this.set('senderEmail', '');
                this.set('senderPone', '');
                this.set('senderMessage', '');
                this.set('ErrorFormMessage', '');
                document.getElementById("sendButton").disabled = false;
                this.set('SuccessFormMessage', this.get('app').lang.messages.mailSentSuccess);
            } else if (!this.get('isEmailSend') && this.get('isButtonEnabled')) {
                this.set('SuccessFormMessage', '');
                this.set('ErrorFormMessage', this.get('app').lang.messages.mailSentFail);
            }
        },

        _generateUrl: function _generateUrl(placeHoldingStr) {
            return utils['default'].formatters.applyPlaceHolders(placeHoldingStr, arguments);
        },

        _validateString: function _validateString(str) {
            var message = str ? str.trim() : '';

            if (message && message.length > 0) {
                this.set('ErrorFormText', '');
                return true;
            } else {
                this.set('ErrorFormText', this.get('app').lang.messages.invalidMessage);
                return false;
            }
        },

        _validateEnOrArNames: function _validateEnOrArNames(name) {
            var validName = name ? name.trim() : '';
            var contactName = /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_]+[,.]?[ ]?|['-]+$/i;
            if (contactName.test(validName)) {
                this.set('ErrorFormName', '');
                return true;
            } else {
                this.set('ErrorFormName', this.get('app').lang.messages.invalidName);
                return false;
            }
        },

        _validateEmail: function _validateEmail(mailAdr) {
            var validMail = mailAdr ? mailAdr.trim() : '';
            var eMail = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            if (eMail.test(validMail)) {
                this.set('ErrorFromEmail', '');
                return true;
            } else {
                this.set('ErrorFromEmail', this.get('app').lang.messages.invalidEmail);
                return false;
            }
        },

        _validateNumber: function _validateNumber(number) {
            var no = number ? number.trim() : '';
            //var phoneno = /^\d{10}$/;
            var phoneno = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
            if (no.match(phoneno) && no.length >= 10) {
                this.set('ErrorFromPhone', '');
                return true;
            } else {
                this.set('ErrorFromPhone', this.get('app').lang.messages.invalidPhone);
                return false;
            }
        },

        actions: {

            sendEmail: function sendEmail() {

                var isValidName = this._validateEnOrArNames(this.get('senderName'));
                var isValidEmail = this._validateEmail(this.get('senderEmail'));
                var isValidPhone = this._validateNumber(this.get('senderPone'));
                var isValidText = this._validateString(this.get('senderMessage'));
                this.set('SuccessFormMessage', '');
                if (this.get('isButtonEnabled') && isValidName && isValidEmail && isValidPhone && isValidText) {
                    var that = this;
                    this.set('isButtonEnabled', false);
                    this.set('SuccessFormMessage', this.get('app').lang.labels.sending);
                    document.getElementById("sendButton").disabled = true;
                    priceService['default'].sendEmailPostRequest({
                        name: this.get('senderName').trim(),
                        email: this.get('senderEmail').trim(),
                        clientId: this.get('clientId'),
                        phone: this.get('senderPone').trim(),
                        message: this.get('senderMessage').trim(),
                        reqSuccessFn: function reqSuccessFn() {
                            that.set('isEmailSend', true);
                            that.set('isButtonEnabled', true);
                            that.emailSendMessage();
                        },
                        reqFailureFn: function reqFailureFn() {
                            that.set('isEmailSend', false);
                            that.set('isButtonEnabled', true);
                            that.emailSendMessage();
                        }
                    });
                } else {
                    //this.set('ErrorFormMessage', this.get('app').lang.labels.invalidInputs);
                    this.set('isEmailSend', false);
                    this.set('isButtonEnabled', true);
                }
            }
        }

    });

});