define('ir-app/utils/analytics-service', ['exports', 'ir-app/config/app-config', 'ir-app/utils/utils'], function (exports, AppConfig, utils) {

    'use strict';

    exports['default'] = (function () {
        // Code snippet provided by Google Analytics
        /*eslint-disable */
        (function (i, s, o, g, r, a, m) {
            try {
                i['GoogleAnalyticsObject'] = r;
                i[r] = i[r] || function () {
                    (i[r].q = i[r].q || []).push(arguments);
                };
                i[r].l = 1 * new Date();
                a = s.createElement(o);
                m = s.getElementsByTagName(o)[0];
                a.async = 1;
                a.src = g;
                m.parentNode.insertBefore(a, m);
            } catch (e) {
                utils['default'].logger.logError('Google analytics initialization failed: ' + e);
            }
        })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');
        /*eslint-enable */

        try {
            window.ga('create', AppConfig['default'].googleAnalyticConfig.id, 'auto');
        } catch (e) {
            utils['default'].logger.logError('Google analytics creation failed: ' + e);
        }

        var trackPage = function trackPage(page, title) {
            try {
                if (window.ga) {
                    window.ga('send', 'pageview', { 'page': page, 'title': title });
                }
            } catch (e) {
                utils['default'].logger.logError('Analytics Service track page error: ' + e);
            }
        };

        var trackEvent = function trackEvent(category, action, label) {
            try {
                if (window.ga) {
                    window.ga('send', 'event', category, action, label);
                }
            } catch (e) {
                utils['default'].logger.logError('Analytics Service track event error: ' + e);
            }
        };

        return {
            trackPage: trackPage,
            trackEvent: trackEvent
        };
    })();

});