define('ir-app/controllers/ir-mobile/company-widgets/company-subsidiaries-info', ['exports', 'ember', 'ir-app/models/price/price-service', 'ir-app/models/shared/shared-service', 'ir-app/controllers/base-array-controller', 'ir-app/models/price/price-constants', 'ir-app/utils/utils'], function (exports, Ember, priceService, sharedService, BaseArrayController, PriceConstants, utils) {

    'use strict';

    exports['default'] = BaseArrayController['default'].extend({

        // Parameters for sorting
        sortProperties: ['subsiSherPrs'],
        sortAscending: false,

        // Default values. This can be overridden via the config
        subsidiaryType: PriceConstants['default'].SubsidiaryType.Subsidiary,
        showAllValues: false,
        langKey: 'subsidiaries',
        applyNaFlag: false,
        showCompanyLinks: false,

        widgetTitle: {},

        onLoadWidget: function onLoadWidget() {
            this.setErrorMessage();
        },

        onPrepareData: function onPrepareData() {
            var that = this;

            this.set('widgetTitle', this.get('app').lang.labels[this.get('langKey')]);

            this.startLoadingProgress();
            var subsInfo;
            if (this.utils.validators.isAvailable(this.get('companyId'))) {
                subsInfo = priceService['default'].companyProfileDS.getStoredObjectByCompanyId(this.get('companyId'), sharedService['default'].userSettings.currentLanguage, function () {
                    that.onDataSuccess();
                }, function () {
                    that.onDataError();
                }, PriceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyManagementInfo).compSubsidiaries;
            } else {
                subsInfo = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, function () {
                    that.onDataSuccess();
                }, function () {
                    that.onDataError();
                }, PriceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyManagementInfo).compSubsidiaries;
            }

            this.set('model', subsInfo);
        },

        onClearData: function onClearData() {
            this.set('model', null);
        },

        onCheckDataAvailability: function onCheckDataAvailability() {
            var companyProfObj;
            if (this.utils.validators.isAvailable(this.get('companyId'))) {
                companyProfObj = priceService['default'].companyProfileDS.getStoredObjectByCompanyId(this.get('companyId'), sharedService['default'].userSettings.currentLanguage);
            } else {
                companyProfObj = priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage);
            }

            return companyProfObj && companyProfObj.compSubsidiaries && companyProfObj.compSubsidiaries.length > 0;
        },

        setErrorMessage: function setErrorMessage() {
            if (this.getFilteredContent() && this.getFilteredContent().length > 0) {
                this.hideDataErrorMessage();
            } else if (!this.get('isLoading')) {
                this.showDataErrorMessage();
                this.set('errorMessage', this.get('app').lang.messages.dataNotAvailable);
            }
        },

        // show only subsidiary type
        filteredContent: (function () {
            this.setErrorMessage();
            return this.getFilteredContent();
        }).property('arrangedContent'),

        getFilteredContent: function getFilteredContent() {
            var content = this.get('arrangedContent');
            var subsidiaryType = this.get('subsidiaryType');
            var showAllValues = this.get('showAllValues');
            var showCompanyLinks = this.get('showCompanyLinks');

            if (content) {

                var filteredContent = content.filter(function (item) {
                    if (showAllValues) {
                        return item.type === subsidiaryType;
                    } else {
                        return item.type === subsidiaryType && item.subsiSherPrs && Number(item.subsiSherPrs) > 0;
                    }
                });

                if (showCompanyLinks) {
                    filteredContent.forEach(function (item) {
                        Ember['default'].set(item, 'companyName', utils['default'].formatters.formatString(item.subsiName) + '-' + item.compId);
                    });
                }

                return filteredContent;
            }
        },
        onLanguageChanged: function onLanguageChanged() {
            this.onPrepareData();
        }
    });

});