define('ir-app/models/decypha/decypha-protocols/decypha-request-handler', ['exports', 'ember', 'ir-app/models/shared/communication-adapters/web-http-connection', 'ir-app/models/decypha/decypha-protocols/decypha-response-handler', 'ir-app/models/decypha/decypha-protocols/decypha-request-generator', 'ir-app/utils/utils'], function (exports, Ember, WebConnection, ResponseHandler, RequestGenerator, Utils) {

    'use strict';

    /**
     * Created by udaras on 10/13/2016.
     */
    exports['default'] = (function () {

        var loadNewsSearchData = function loadNewsSearchData(params) {
            WebConnection['default'].sendAjaxRequest({
                url: RequestGenerator['default'].generateNewsSearchUrl(params),

                onSuccess: function onSuccess(dataObj) {
                    ResponseHandler['default'].processNewsSearchResponse(dataObj, params);
                },
                onError: function onError(e) {
                    if (Ember['default'].$.isFunction(params.reqFailureFn)) {
                        params.reqFailureFn();
                    }
                    Utils['default'].logger.logError('Error in send ajax request : ' + e);
                }
            });
        };

        var loadCompanyScreenerData = function loadCompanyScreenerData(params) {
            WebConnection['default'].sendAjaxRequest({
                url: RequestGenerator['default'].generateCompanyScreenerUrl(params),

                onSuccess: function onSuccess(dataObj) {
                    ResponseHandler['default'].processCompanyScreenerResponse(dataObj, params);
                },
                onError: function onError(e) {
                    if (Ember['default'].$.isFunction(params.reqFailureFn)) {
                        params.reqFailureFn();
                    }
                    Utils['default'].logger.logError('Error in send ajax request : ' + e);
                }
            });
        };

        return {
            loadNewsSearchData: loadNewsSearchData,
            loadCompanyScreenerData: loadCompanyScreenerData
        };
    })();

});