define('ir-app/controllers/ir-widgets/tab-layouts/stock-overview-layout', ['exports', 'ir-app/controllers/base-controller'], function (exports, BaseController) {

    'use strict';

    /**
     * Created by banushas on 5/11/2016.
     */
    exports['default'] = BaseController['default'].extend({

        init: function init() {
            this.setValueToBaseSymbolContainer('showBaseTitle', true);
        }

    });

});