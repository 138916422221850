define('ir-app/utils/config-helper', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = (function () {
        var maxLevel = 3;

        var mergeConfigSettings = function mergeConfigSettings(configInstance, changedSettings) {
            if (configInstance && changedSettings) {
                if (Ember['default'].$.type(changedSettings) === 'object') {
                    _scanSetting(configInstance, '', changedSettings, 1);
                }
            }
        };

        var _scanSetting = function _scanSetting(configInstance, settingsProp, settingsObj, level) {
            Ember['default'].$.each(settingsObj, function (prop, val) {
                if (Ember['default'].$.type(val) === 'object' && level <= maxLevel) {
                    if (!configInstance[prop]) {
                        configInstance[prop] = {};
                    }

                    _scanSetting(configInstance[prop], prop, val, level + 1);
                } else {
                    configInstance[prop] = val;
                }
            });
        };

        return {
            mergeConfigSettings: mergeConfigSettings
        };
    })();

});