define('ir-app/views/popup-container-view', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].ContainerView.extend({
        style: function style() {
            return 'position: absolute; height: 1px; max-height: 1px;';
        },

        load: (function () {
            // Need to find a better way
            Ember['default'].PopupContainerView = this;
        }).on('init'),

        addPopupView: function addPopupView(item) {
            // Ember.Logger.log('UBTEMP : BEFORE Push Object: ' + this.get('target'));
            this.pushObject(item);
            //  Ember.Logger.log('UBTEMP : AFTER Push Object ' + this.get('target'));
        }
    });

});