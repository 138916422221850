define('ir-app/models/shared/shared-service', ['exports', 'ir-app/models/shared/shared-business-entities/user-settings', 'ir-app/models/shared/shared-business-entities/user-state'], function (exports, userSettings, userState) {

    'use strict';

    exports['default'] = (function () {
        var serviceMap = {};

        var registerService = function registerService(type, service) {
            serviceMap[type] = service;
        };

        var getService = function getService(type) {
            return serviceMap[type];
        };

        return {
            registerService: registerService,
            getService: getService,
            userSettings: userSettings['default'],
            userState: userState['default']
        };
    })();

});