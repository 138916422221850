define('ir-app/models/price/price-protocols/web-socket/price-socket-response-handler', ['exports', 'ember', 'ir-app/models/shared/shared-protocols/web-socket/socket-response-handler', 'ir-app/models/price/price-constants', 'ir-app/models/price/price-service', 'ir-app/models/chart/chart-constants', 'ir-app/utils/utils'], function (exports, Ember, SocketResponseHandler, PriceConstants, priceService, ChartConstants, utils) {

    'use strict';

    /* global Queue */
    exports['default'] = SocketResponseHandler['default'].extend({
        init: function init() {
            var that = this;

            this._super();

            this.inputQueue = new Queue();

            this.processTimer = setTimeout(function () {
                that.processResponse();
            }, PriceConstants['default'].TimeIntervals.WebSocketInQueueProcessingInterval);
        },

        /* *
         * Processes message frames from the server
         */
        _processMessage: function _processMessage(message, onSocketReady) {
            // Fetch the response message type
            var type = message[PriceConstants['default'].ResponseType.MessageType];

            switch (type) {
                case PriceConstants['default'].ResponseType.Data.ResponseEquity:
                    this._processStockResponse(message);
                    break;

                case PriceConstants['default'].ResponseType.Data.ResponseIndex:
                    this._processIndexResponse(message);
                    break;

                case PriceConstants['default'].ResponseType.Data.ResponseExchange:
                    this._processExchangeResponse(message);
                    break;

                case PriceConstants['default'].ResponseType.Data.ResponseMarketDepthByPrice:
                    this._processMarketDepth(message, PriceConstants['default'].MarketDepthType.DepthByPrice);
                    break;

                case PriceConstants['default'].ResponseType.Data.ResponseMarketDepthByOrder:
                    this._processMarketDepth(message, PriceConstants['default'].MarketDepthType.DepthByOrder);
                    break;

                case PriceConstants['default'].ResponseType.Data.ResponseOHLC:
                    this._processOHLCResponse(message);
                    break;

                case PriceConstants['default'].ResponseType.Data.ResponseAnnouncement:
                case PriceConstants['default'].ResponseType.Data.ResponseNews:
                    this._processNewsAnnouncementResponse(message, type);
                    break;

                case PriceConstants['default'].ResponseType.Data.ResponseTopStocks:
                    this._processTopStockResponse(message);
                    break;

                case PriceConstants['default'].ResponseType.Authentication:
                    this._processAuthResponse(message, this.callbacks.auth, onSocketReady);
                    break;

                case PriceConstants['default'].ResponseType.Data.ResponseTimeAndSales:
                    this._processTimeAndSalesResponse(message);
                    break;

                case PriceConstants['default'].ResponseType.Pulse:
                    break;

                default:
                    utils['default'].logger.logWarning('Unsupported message type : ' + type);
                    break;
            }
        },

        _processStockResponse: function _processStockResponse(message) {
            var stockObj = priceService['default'].stockDS.getStock(message.exg, message.sym);

            if (stockObj !== null) {
                stockObj.setData(message);
            }

            // history last candle calculation
            var ohlcSeries = priceService['default'].ohlcDS.getOHLCSeries(message.exg, message.sym, ChartConstants['default'].ChartCategory.History);

            ohlcSeries.setLastCandleData({
                DT: stockObj.dateInMilSec,
                open: stockObj.open,
                high: stockObj.high,
                low: stockObj.low,
                close: stockObj.cls === 0 ? stockObj.prvCls : stockObj.cls,
                volume: stockObj.vol,
                turnover: stockObj.tovr
            }, message.exg, message.sym);
        },

        _processIndexResponse: function _processIndexResponse(message) {
            var indexObj = priceService['default'].stockDS.getStock(message.exg, message.sym);

            if (indexObj !== null) {
                indexObj.setData(message);
            }

            // history last candle calculation
            var ohlcSeries = priceService['default'].ohlcDS.getOHLCSeries(message.exg, message.sym, ChartConstants['default'].ChartCategory.History);

            ohlcSeries.setLastCandleData({
                DT: indexObj.dateInMilSec,
                open: indexObj.open,
                high: indexObj.high,
                low: indexObj.low,
                close: indexObj.cls === 0 ? indexObj.prvCls : indexObj.cls,
                volume: indexObj.vol,
                turnover: indexObj.tovr
            }, message.exg, message.sym);
        },

        _processExchangeResponse: function _processExchangeResponse(message) {
            var exchangeObj = priceService['default'].exchangeDS.getExchange(message.exg);

            if (exchangeObj !== null) {
                exchangeObj.setData(message);
            }
        },

        _processMarketDepth: function _processMarketDepth(message, type) {
            var depthByPriceObj = priceService['default'].marketDepthDS.getDepthItem(message.exg, message.sym, type);

            if (depthByPriceObj !== null) {
                depthByPriceObj.setData(message.D, type);
            }
        },

        _processOHLCResponse: function _processOHLCResponse(message) {
            var ohlcSeries = priceService['default'].ohlcDS.getOHLCSeries(message.exg, message.sym, ChartConstants['default'].ChartCategory.Intraday);

            // Load exchange object for obtaining the timezone
            var exgObj = priceService['default'].exchangeDS.getExchange(message.exg);

            var pt = utils['default'].formatters.convertToUTCTimestamp(parseInt(message.dt, 10) * PriceConstants['default'].UnixTimestampByMinutes * PriceConstants['default'].UnixTimestampByMilliSeconds);
            var date = utils['default'].formatters.convertToUTCDate(pt, exgObj.tzo);

            ohlcSeries.setData({
                dt: date,
                open: message.open,
                high: message.high,
                low: message.low,
                close: message.cls,
                volume: message.vol,
                turnover: message.tOvr
            }, true);
        },

        _processTimeAndSalesResponse: function _processTimeAndSalesResponse(message) {
            var timeAndSalesInstance = priceService['default'].timeAndSalesDS;
            var exchange = message.exg;
            var symbol = message.sym;
            var tradeObj = timeAndSalesInstance.getNewTrade(exchange, symbol);
            var prevTradeObj = timeAndSalesInstance.getLastTrade(exchange, symbol);

            if (tradeObj) {
                if (prevTradeObj) {
                    message.trp = message.trp || prevTradeObj.trp;
                    message.trq = message.trq || prevTradeObj.trq;
                    message.splits = message.splits || prevTradeObj.splits;
                    message.trdType = message.trdType || prevTradeObj.trdType;
                    message.tts = message.tts || prevTradeObj.tts;
                }

                tradeObj.setData(message);

                if (prevTradeObj) {
                    tradeObj.setTradeTick(prevTradeObj.trp);
                }

                timeAndSalesInstance.setLastTrade(exchange, symbol, tradeObj);
            }
        },

        _processNewsAnnouncementResponse: function _processNewsAnnouncementResponse(message, type) {
            var annObj = priceService['default'].announcementDS.createAnnouncement(message.id);

            if (annObj !== null) {
                annObj.setData(message);
                annObj.set('type', type);
                priceService['default'].announcementDS.addToOtherCollections(annObj);
            }

            if (type === PriceConstants['default'].ResponseType.Data.ResponseAnnouncement) {
                priceService['default'].stockDS.setAnnouncement(annObj);
            }
        },

        _processTopStockResponse: function _processTopStockResponse(message) {
            priceService['default'].topStockDS.createTopStocks(message.exg, message.tt, message.D);
        },

        _processAuthResponse: function _processAuthResponse(message, authCallbacks, onSocketReady) {
            var authSuccess = false;

            if (message.AUTHSTAT) {
                utils['default'].logger.logInfo('User authenticated successfully.');

                authSuccess = true;
                priceService['default'].userDS.setData(message);
                priceService['default'].userDS.save();

                if (Ember['default'].$.isFunction(authCallbacks.successFn)) {
                    authCallbacks.successFn();
                }

                if (Ember['default'].$.isFunction(authCallbacks.postSuccessFn)) {
                    authCallbacks.postSuccessFn();
                }
            } else {
                utils['default'].logger.logInfo('User authentication failed.' + message.AUTHMSG);

                if (Ember['default'].$.isFunction(authCallbacks.errorFn)) {
                    authCallbacks.errorFn(message.AUTHMSG);
                }
            }

            if (Ember['default'].$.isFunction(onSocketReady)) {
                onSocketReady(authSuccess);
            }
        }
    });

});